import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* getShippingUsers(action) {
    yield processApiRequest(ACTION.FETCH_SHIPPING_USER, () => apiService.getShippingUsers(action.payload), action.payload);
}

export function* getShippingUsersSuggest(action) {
    yield processApiRequest(ACTION.FETCH_SHIPPING_USER_SUGGEST, () => apiService.getShippingUsersSuggest(action.payload), action.payload);
}
