import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let packages = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGES.SUCCESS:
            return action.payload.packages.map(item => ({...item.package, ...lodash.omit(item, 'package')}));
        case ACTION.FETCH_PACKAGES.FAILED:
            return [];
        default:
            return state;
    }
};

let pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGES.SUCCESS:
            return action.payload.pagination;
        case ACTION.FETCH_PACKAGES.FAILED:
            return {
                total: 0
            };
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGES.REQUEST:
        case ACTION.EXPORT_PACKAGE_LIST.REQUEST:
            return true;

        case ACTION.FETCH_PACKAGES.SUCCESS:
        case ACTION.FETCH_PACKAGES.FAILED:
        case ACTION.EXPORT_PACKAGE_LIST.SUCCESS:
        case ACTION.EXPORT_PACKAGE_LIST.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    packages,
    pagination,
    loading,
});
