import React from "react"
import {withTranslation} from "react-i18next";
import lodash from "lodash";
import PropTypes from 'prop-types';
import {ReactComponent as BagIcon} from "../../../../resources/images/bag.svg";
import {Link} from "../../../../system/routing";
import {dateFormatter} from "../../../Common/services/format";

class BagHistory extends React.PureComponent {
    render() {
        const {t} = this.props;
        let bags = lodash.get(this.props.package, 'bags', []);
        return (
	        bags.length > 0 ?
                <ul className="a-history-bag">
                    {
                        bags.map(({bag}, index) => (
                            <li className={`a-history-bag__item ${bag.id === this.props.package.id_bag ? 'active' : ''}`}
                                key={index}>
                                <p className="time-status">{dateFormatter.full(bag.created_at)}, {t(`bag:statuses.${bag.status}`)}</p>
                                <Link to="bags.detail"
                                      rel="noopener noreferrer"
                                      isNewTab
                                      className="name-bag"
                                      params={{id: lodash.get(bag, 'code')}}><BagIcon
                                    className={"icon-bag"}/><span>{lodash.get(bag, 'code', '--')}</span></Link>
                            </li>
                        ))
                    }
                </ul>
		        : <span>{t('package:message.package_not_in_bag')}</span>
        )
    }
}

BagHistory.defaultProps = {
    package: {},
    loading: false,
};

BagHistory.propTypes = {
    package: PropTypes.object,
    loading: PropTypes.bool,
};

export default withTranslation()(BagHistory)
