import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import * as actions from './actions';
import * as selectors from './selectors';
import UpdateBagParam from './components/UpdateBagParam';
import authService from '../../Auth/authService';
import PERMISSION from '../../Auth/permissions';

const mapStateToProps = (state, props) => {
    return {
        bag: props.bag,
        version: props.bag.id + '.' + selectors.getState(state, 'version.' + props.param),
        loading: !!selectors.getState(state, 'loading.' + props.param),
        error: lodash.get(selectors.getError(state), props.param),
        canUpdate: props.canUpdate === false ? false : authService.can(PERMISSION.BAG_UPDATE)
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateBagParam: (value) => dispatch(actions.updateBagParam(props.bag.id, props.param, value)),
        resetError: () => dispatch(actions.resetError(props.param)),
        clearState: () => dispatch(actions.clearState(props.param)),
    };
};

class UpdateBagParamContainer extends React.Component {
    componentWillUnmount() {
        this.props.clearState();
    }

    updateBagParam = value => {
        const { updateBagParam } = this.props;

        updateBagParam(lodash.isUndefined(value) ? null : value);
    };

    render() {
        return <UpdateBagParam {...this.props} updateBagParam={this.updateBagParam} />;
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateBagParamContainer);

Container.defaultProps = {
    bag: {},
    param: null,
};

Container.propTypes = {
    bag: PropTypes.object,
    param: PropTypes.string,
};

export default Container;

