import * as ACTION from './constants'
import { notification } from 'antd'
import apiService from './apiService'
import lodash from 'lodash'
import { router } from '../../../system/routing'
import { t } from '../../../system/i18n'
import { processApiRequest } from '../../Common/saga'

export function* getBagDetail(action) {
    yield processApiRequest(ACTION.GET_BAG_PACKING_DETAIL, () => apiService.getBagPackingDetail(action.payload), action.payload)
}

export function* getBagDetailAfterPacking(action) {
    yield processApiRequest(ACTION.GET_BAG_PACKING_DETAIL, () => apiService.getBagPackingDetail({id:action.request.id}), action.payload)
}

export function* onFetchBagDetailFailed(action) {
    yield notification.error({ message: t('bag:detail.message_cant_load_bag') })
}
