import React, {Component} from 'react';
import {Layout} from 'antd';
import lodash from 'lodash';
import FormSearch from './FormSearch';
import Histories from './Histories';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import pageService from '../../../Common/Page/pageService';

const {Content} = Layout;

class HistoriesIndex extends Component {

    componentDidMount() {
        pageService.setTitle(this.props.t('barcode:label.title'));
        this.props.setMenuActive('barcode/scanning-history');
    }

    render() {
        return (
            <Layout className={"min-height-not-header aflex-1 "}>
                <Content className="a-content a-content--page-list aheight-100 ">
                    <FormSearch
                        input={this.props.filter}
                        inputVersion={lodash.toInteger(this.props.filter.i)}
                        loading={this.props.loading}
                        onSearch={filter => this.props.onSearch({...filter, page: 1})}
                    />
                    <Histories
                        histories={this.props.histories}
                        pagination={this.props.pagination}
                        filter={this.props.filter}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                    />
                </Content>
            </Layout>
        )
    }
}

HistoriesIndex.defaultProps = {
    histories: [],
    pagination: {},
    filter: {},
    loading: false,
    onSearch: () => {},
};

HistoriesIndex.propTypes = {
    histories: PropTypes.array,
    pagination: PropTypes.object,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(HistoriesIndex);