import { combineReducers } from 'redux';

import { CLEAR_SUGGEST_CUSTOMER, SET_SUGGEST_CUSTOMER_ID } from './../constants';

const suggestCustomer = (state = {}, action) => {
    switch (action.type) {
        case SET_SUGGEST_CUSTOMER_ID: {
            return {
                customer: {
                    id: action.payload
                }
            };
        }
        case CLEAR_SUGGEST_CUSTOMER: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    suggestCustomer
});
