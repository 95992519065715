import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateReceivedQuantity from './components/UpdateReceivedQuantity';

const mapStateToProps = (state, props) => {
    const { product } = props;

    return {
        input: {
            quantity: product.package_received_quantity,
            purchasedQuantity: product.purchase_quantity,
        },
        inputVersion: product.id
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSubmit: (input) => {
            if (props.onSubmit) {
                props.onSubmit({
                    packageId: props.packageId,
                    codeItem: props.product.code_item,
                    quantity: input.quantity
                });
            }
        },
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateReceivedQuantity);

Container.defaultProps = {
    packageId: null,
    product: {},
};

Container.propTypes = {
    packageId: PropTypes.number,
    product: PropTypes.object,
};

export default withTranslation()(Container);
