import React from 'react';
import * as _ from 'lodash';
import Page403 from '../../Common/components/403';

import deliveryNoteApiService from '../apiService';

export default (payload, next) => {
    const id = _.get(payload, 'request.match.params.id');
    deliveryNoteApiService.getDeliveryNote(id)
        .then(response => {
            let permissions = _.get(response, 'data.permissions');
            if(!permissions.can_update) {
                return payload.response.render(<Page403/>);
            }
            next(payload);
        });
}