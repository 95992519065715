import React from 'react';
import { Button, Checkbox, Form, Select } from 'antd';
import { omit, values } from 'lodash';
import { withTranslation } from 'react-i18next';

import { INVENTORY_TYPES } from './../../constants';
import authService from './../../../Auth/authService';
import pageService from './../../../Common/Page/pageService';

import ServicesSelect from './../../../Common/components/ServicesSelect/ServicesSelectContainer';
import ChooseWarehouse from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';

const { Option } = Select;

class InventoryScan extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            initialTypeValue: values(INVENTORY_TYPES).find(type => authService.can(type.permission)).key
        };
    }

    componentDidMount() {
        const { t, setMenuActive } = this.props;

        pageService.setTitle(t('menu.inventory'));
        setMenuActive('inventory/create');
        window.addEventListener('scroll', this.handleScroll);
    }

    handleSubmit = event => {
        const { form, createInventory } = this.props;

        event.preventDefault();

        form.validateFields((error, values) => {
            if (error) {
                return;
            }

            const formattedValues = {
                ...omit(values, ['ignore_services', 'services'])
            };

            if (values.ignore_services) {
                formattedValues.ignore_services = values.services;
            }

            createInventory(formattedValues);
        });
    };

    render() {
        const { initialTypeValue } = this.state;
        const { adding, consignServices, form, services, t } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        return (
            <Form className="abg-white a-content--empty inventory-scan flex-grow-1 ap-16" onSubmit={this.handleSubmit}>
                <img src={require('./../../../../resources/images/box2.svg')} alt="" className="inventory-img-scan" />
                <p className="inventory-title-scan">{t('inventory:label.inventory-title-scan')}</p>
                <Form.Item
                    className="a-form__item amb-16"
                    labelCol={{span: 24}}
                >
                    {getFieldDecorator('id_warehouse', {
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('inventory:warehouse')
                            })
                        }]
                    })(
                        <ChooseWarehouse
                            active={true}
                            className="a-select a-select--inventory-scan-warehouse mb-0 _id-warehouse"
                            emptyOption={false}
                            isPermission={true}
                            placeholder={t('inventory:placeholder.select_warehouse')}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    className="a-form__item"
                    labelCol={{span: 24}}
                >
                    {getFieldDecorator('type', {
                        initialValue: initialTypeValue,
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('inventory:type')
                            })
                        }]
                    })(
                        <Select
                            className="a-select w-200 _inventory-type"
                            dropdownClassName="_dropdown-inventory-type"
                            placeholder={t('inventory:type')}
                        >
                            {values(INVENTORY_TYPES).map(type => authService.can(type.permission) ? (
                                <Option className="_inventory-type-item" key={type.key} value={type.key}>
                                    {t(`inventory:inventory_types.${type.key}`)}
                                </Option>
                            ) : null)}
                        </Select>
                    )}
                </Form.Item>
                {getFieldValue('type') === INVENTORY_TYPES.PACKAGE.key ? (
                    <div className="amt-16 gflex galign-center a-text--nowrap">
                        <Form.Item
                            className="a-form__item"
                            labelCol={{span: 24}}
                        >
                            {getFieldDecorator('quick_mode')(
                                <Checkbox
                                    checked={getFieldValue('quick_mode')}
                                >
                                    {t('inventory:inventory_package_scanned')}
                                </Checkbox>
                            )}
                        </Form.Item>

                        <Form.Item
                            className="a-form__item"
                            labelCol={{span: 24}}
                        >
                            {getFieldDecorator('ignore_package_in_bag')(
                                <Checkbox
                                    checked={getFieldValue('ignore_package_in_bag')}
                                >
                                    {t('inventory:ignore_package_in_bag')}
                                </Checkbox>
                            )}
                        </Form.Item>

                        <Form.Item
                            className="a-form__item"
                            labelCol={{span: 24}}
                        >
                            {getFieldDecorator('ignore_services')(
                                <Checkbox
                                    checked={getFieldValue('ignore_services')}
                                >
                                    {t('inventory:ignore_services')}
                                </Checkbox>
                            )}
                        </Form.Item>

                        <Form.Item
                            className="a-form__item"
                            labelCol={{span: 24}}
                        >
                            {getFieldValue('ignore_services') && (
                                getFieldDecorator('services', {
                                    rules: [{
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t('service')
                                        })
                                    }]
                                })(
                                    <ServicesSelect
                                        allowClear
                                        className="_services aml-8 w-200 mhpx-200 overflow-auto"
                                        consignServices={consignServices}
                                        mode="multiple"
                                        optionFilterProp="children"
                                        placeholder={t('label.all')}
                                        services={services}
                                        showSearch
                                    />
                                )
                            )}
                        </Form.Item>
                    </div>
                ) : null}
                <Button
                    className="a-btn a-btn--primary a-btn--inventory-scan-warehouse primary-special"
                    htmlType="submit"
                    loading={adding}
                >
                    {t('inventory:button.create_inventory')}
                </Button>
            </Form>
        )
    }
}

export default withTranslation()(Form.create()(InventoryScan));
