import {api} from '../../system/api';

export default {
    getCustomerBagPackages: (customerId, params = {}) => api.get(`customers/${customerId}/bag-packages`, {
        params
    }),
    getDeliveryNote: (id) => api.get('delivery-notes/' + id, {singleRequest: true}),
    getDeliveryNotes: (params) => api.get('delivery-notes', {params}, {singleRequest: true}),
    scanBarcode: (type, barcode, params) => api.get(`barcode-scannings/${type}`, {
        params: {
            ...params,
            barcode
        }
    }),
    updateDelivery: (id, params = {}) => api.put(`delivery-notes/${id}/update`, params),
    removePackageFromDeliveryNote: (deliveryNoteId, packageIds) => api.put(`delivery-notes/${deliveryNoteId}/remove-pack`, {packages: packageIds}),
    removeBagFromDeliveryNote: (deliveryNoteId, bagIds) => api.put(`delivery-notes/${deliveryNoteId}/remove-pack`, {
        bags: bagIds
    }),
    place: id => api.put(`delivery-notes/${id}/placed`),
    getDomesticShippingDetail: id => api.get(`delivery-notes/${id}/last-mile-orders`),
    addLastMileOrder: (id, data) => api.post(`delivery-notes/${id}/last-mile-orders`, data),
    deleteLastMileOrder: (deliveryNoteId, orderId) => api.delete(`delivery-notes/${deliveryNoteId}/last-mile-orders/${orderId}`),
    printLastMileOrder: (deliveryNoteId, orderId) => api.get(`delivery-notes/${deliveryNoteId}/last-mile-orders/${orderId}/print`),
    updatePackageInfoDeliveryNoteReturn: (deliveryNoteId, packageId, data) => api.put(`/delivery-note-returns/${deliveryNoteId}/packages/${packageId}`, data),
    exportDeliveryNote: (params, data) => api.post(`/delivery-notes/export-excel`, data,{params, responseType: 'blob'}),
    getLoanByOrders: (customerId, params) => api.get(`/customers/${customerId}/credits/loans-by-order`, {params}),
    confirmDeliveryNote: (id) => api.put(`delivery-notes/${id}/confirm`)
};
