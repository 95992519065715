import React from 'react';
import {Button, Col, DatePicker, Form, Icon, Input, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import FormProcessing from '../../../../Common/components/FormProcessing';
import moment from 'moment';

import * as services from '../services';
import { DELIVERY_NOTE_STATUS } from './../../../constants';

import SelectUserContainer from "../../../../User/SelectUser/SelectUserContainer";
import SelectCreatePartnerContainer from "../../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer";
import SelectWarehouse from "../../../../Warehouse/SelectWarehouse/SelectWarehouseContainer";
import FormDeliveryTypeContainer from "../../../FormDeliveryType/FormDeliveryTypeContainer";

const { Search } = Input;

class FormSearch extends FormProcessing {
    constructor (props) {
        super(props);

        this.defaultInput = {
            status: undefined,
            id_warehouse: undefined,
            'created_at[from]': undefined,
            'created_at[to]': undefined,
            'exported_at[from]': undefined,
            'exported_at[to]': undefined,
            id_creator: undefined,
            id_exporter: undefined,
            id_shipping_partner: undefined,
            code: undefined

        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }


    /**
     * Get filter
     * @param filter
     * @returns {*}
     */
    getFilter (filter) {
        let page = this.props.page;
        if (page === 'list_delivery_note_pack') {
            filter.packing_complete = 0;
        }
        return filter;
    }

    onSubmit = event => {
        event.preventDefault();

        if (!this.props.loading) {
            let {input} = this.state;
            input.page = 1;
            this.props.onSearch(input);
        }
    };

    onReset = event => {
        event.preventDefault();
        if (!this.props.loading) {
            let filter = this.getFilter(this.defaultInput);
            this.changeInput(filter);
            this.props.onSearch(filter);
        }
    };

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onChangeMultiSelect (name, value) {
        this.changeInput({[name]: value});
    }

    render () {
        const { loading, t } = this.props;
        const input = services.converValue2Int({...this.state.input});

        return (
            <div className="a-content--search-header search-default">
                <Form onSubmit={this.onSubmit} className="a-form">
                    <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                        <Col lg={6}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("delivery_note:label.code_search_SHIPPING_PARTNER")}
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-inputonChangeInput--search _code"
                                    value={input.code}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={5} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:transport_warehouse.status')}
                                labelCol={{span: 24}}
                            >
                                <FormDeliveryTypeContainer
                                    allowClear
                                    className="a-select a-select--search-content _status"
                                    dropdownClassName="_dropdown-status"
                                    excludes={[DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING]}
                                    value={input.status}
                                    disabled={loading}
                                    placeholder={t('delivery_note:transport_warehouse.status')}
                                    onChange={status => this.changeInput({
                                        status
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.id_warehouse')}
                                labelCol={{span: 24}}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _id-warehouse"
                                    dropdownClassName="_dropdown-id-warehouse"
                                    isCustomWarehouse
                                    placeholder={t('delivery_note:label.id_warehouse')}
                                    value={input.id_warehouse}
                                    onChange={id_warehouse => this.changeInput({
                                        id_warehouse
                                        })}>
                                </SelectWarehouse>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('shipping_partner')}
                                labelCol={{span: 24}}>
                                <SelectCreatePartnerContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-shipping-partner"
                                    dropdownClassName="_dropdown_select_partner"
                                    placeholder={t('select_shipping_partner')}
                                    value={input.id_shipping_partner}
                                    onChange={id_shipping_partner => this.changeInput({
                                        id_shipping_partner: id_shipping_partner.value
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.created_at")}
                                labelCol={{span: 24}}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.exported_at")}
                                labelCol={{span: 24}}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[from]'] ? moment(input['exported_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[to]'] ? moment(input['exported_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                        <Col lg={7} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.id_creator")}
                                labelCol={{span: 24}}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-creator"
                                    dropdownClassName="_dropdown-creator'"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_creator')}
                                    value={input.id_creator}
                                    onChange={id_creator => this.changeInput({
                                        id_creator
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.id_exporter")}
                                labelCol={{span: 24}}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-exporter"
                                    dropdownClassName="_dropdown-exporter"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_exporter')}
                                    value={input.id_exporter}
                                    onChange={id_exporter => this.changeInput({id_exporter})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a href="/" className="link-reload" onClick={this.onReset}><Icon
                            type="sync"/>{t('label.refresh_filter')}</a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list"
                            loading={this.props.loading}
                        >{t('btn.search')}</Button>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: (input) => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(FormSearch);
