import { combineReducers } from 'redux';

import * as ACTION from './constants';

const loading = (state = false, action) => {
	const { type } = action;

	switch (type) {
		case ACTION.SCAN_PACKAGE.REQUEST: 
		case ACTION.SCAN_TRACKING_BILL.REQUEST: {
			return true;
		}
		case ACTION.SCAN_PACKAGE.SUCCESS:
		case ACTION.SCAN_PACKAGE.FAILED:
		case ACTION.SCAN_TRACKING_BILL.SUCCESS:
		case ACTION.SCAN_TRACKING_BILL.FAILED: {
			return false;
		}
		default: {
			return state;
		}
	}
};

export default combineReducers({
	loading
});
