import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Col, Collapse, Icon, Row,} from 'antd';

import { getVar } from './../../../../system/support/helpers';

let Panel = Collapse.Panel;

class PackageCustomer extends React.PureComponent {
    state = {
        expand: this.props.defaultExpand
    };

    onToggle() {
        this.setState(state => ({expand: !state.expand}));
    }

    render() {
        let {t} = this.props;
        let noneData = '--';
        let packageData = this.props.package || {};
        let customerData = lodash.get(packageData, 'customer', {}) || {};
        const customer = Object.keys(customerData).length > 0 ? (
            <span>
                {getVar(customerData, 'username', '--')} ({getVar(customerData, 'code', '--')})
            </span>
        ) : noneData;
        return (
            <Row gutter={48} className={"a-block-info pb-0 _block_package_customer"}>
                <Col lg={{span: 24}} xl={{span: 24}} className="info--title iscollapse">
                    <div className="title">
                        <h4>{t('package:detail.customer_info')}</h4>
                    </div>
                    <div className="title--collapse">
                        <span onClick={this.onToggle.bind(this)} className={"_btn_expand"}>
                                {t(this.state.expand ? 'btn.collapse' : 'btn.expand')}
                            <Icon type={this.state.expand ? 'shrink' : 'arrows-alt'} className="icon-collapse"/>
                        </span>
                    </div>
                </Col>
                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group _customer_name">
                    <label className="info--label _label">
                        {t('customer')}
                    </label>
                    <span className="info--value--small _value ">{customer}</span>
                </Col>
                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group _customer_received">
                    <label className="info--label _label">{t('package:detail.customer_received')}</label>
                    <span className="info--value--small _value">
                        {packageData.customer_receive || noneData}
                    </span>
                </Col>
                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                     className="info--group _customer_phone">
                    <label className="info--label _label">{t('package:detail.customer_phone')}</label>
                    <span className="info--value--small _value ">
                        {packageData.customer_phone_destination || noneData}
                    </span>
                </Col>
                {this.state.expand ? (
                    <>
                        <Col
                            className="info--group _warehouse_destination_address"
                            lg={{span: 8}}
                            xl={{span: 6}}
                        >
                            <label className="info--label _label">{t('package:detail.warehouse_destination_address')}</label>
                            <span className="info--value--small _value">
                                {lodash.get(packageData, 'destination_warehouse.code') || noneData}
                            </span>
                        </Col>
                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                            <Collapse defaultActiveKey={['1']}
                                    className="a-collapse--info-customer">
                                <Panel key="1"
                                    showArrow={false}
                                >
                                    <Row gutter={48}>
                                        <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                            className="info--group _country">
                                            <label className="info--label _label">{t('location:label.country')}</label>
                                            <span className="info--value--small _value ">
                                                {lodash.get(packageData, 'destination_country.label') || noneData}
                                            </span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                            className="info--group _customer_id_city_destination">
                                            <label
                                                className="info--label _label">{t('location:label.city')}</label>
                                            <span
                                                className="info--value--small _value ">{lodash.get(packageData, 'destination_city.label') || noneData}</span>
                                        </Col>
                                        <Col
                                            className="info--group _district"
                                            lg={{span: 8}}
                                            xl={{span: 6}}
                                        >
                                            <label className="info--label _label">{t('location:label.district')}</label>
                                            <span className="info--value--small _value">
                                                {lodash.get(packageData, 'destination_district.label') || noneData}
                                            </span>
                                        </Col>
                                        <Col
                                            className="info--group _ward"
                                            lg={{span: 8}}
                                            xl={{span: 6}}
                                        >
                                            <label className="info--label _label">{t('location:label.ward')}</label>
                                            <span className="info--value--small _value">
                                                {lodash.get(packageData, 'destination_ward.label') || noneData}
                                            </span>
                                        </Col>
                                        <Col
                                            className="info--group _customer_destination_address"
                                            lg={{span: 12}}
                                        >
                                            <label className="info--label _label">
                                                {t('package:detail.customer_destination_address')}
                                            </label>
                                            <span className="info--value--small _value">
                                                {packageData.customer_address_destination || noneData}
                                            </span>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 12}} xxl={{span: 12}}
                                            className="info--group _note_received">
                                            <label className="info--label _label">{t('package:label.note_received')}</label>
                                            <span className="info--value--small _value ">
                                                {packageData.customer_note || noneData}
                                            </span>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                    </>
                ) : null}
            </Row>
        );
    }
}

PackageCustomer.defaultProps = {
    package: {},
    defaultExpand: false,
};

PackageCustomer.propTypes = {
    package: PropTypes.object,
    defaultExpand: PropTypes.bool,
};

export default withTranslation()(PackageCustomer);
