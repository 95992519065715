import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Alert, Button, Col, Icon, Row } from 'antd'
import lodash from 'lodash'
import { dateFormatter } from '../../../Common/services/format'
import UpdateBagParam from '../../UpdateBagParam/UpdateBagParamContainer'
import { trans } from '../../../../system/i18n'
import { UpdateShippingType } from './UpdateShippingType'
import { PACKAGE_ACTION } from '../../constants'
import UpdateVolumeBag from './UpdateVolumeBag'
import ExportManifestButton from './ExportManifestButton'
import PrintBagStampButton from './PrintBagStampButtonContainer'
import AuthService from '../../../Auth/authService'
import PERMISSION from '../../../Auth/permissions'
import PrintBagStampFucButton from './PrintBagStampFucButtonContainer'
import { Link } from '../../../../system/routing'

class BagInfo extends React.PureComponent {
    state = {
        input: {
            bagType: '',
            id_customer: '',
            onEditType: false,
            onEditCustomer: false,
            id_warehouse_input: lodash.get(this.props.bag, 'id_warehouse_input'),
        },
        isCollapse: true,
    }

    onToggle = () => {
        this.setState(prevState => ({
            isCollapse: !prevState.isCollapse,
        }))
    }

    getBagStatus = value => {
        return !value
    }

    onCreateSimilarBag = bag => {
        this.props.createSimilarBag(bag.id)
    }

    onTogglePopupCreateTemplateBag = bag => {
        this.props.onTogglePopupCreateTemplateBag(bag.id)
    }

    onChangeValue = input => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                ...input,
            },
        }))
    }

    onExportBag = bag => {
        this.props.exportBag({
            barcode: bag.code,
            id_warehouse_scan: bag.id_warehouse_current,
            status_warehouse: PACKAGE_ACTION.OUT,
            barcode_type: 'BAG',
        })
    }

    componentDidUpdate(prevProps) {
        const { bag } = this.props

        if (!lodash.isEqual(bag, prevProps.bag)) {
            this.setState(prevState => ({
                input: {
                    ...prevState.input,
                    id_warehouse_input: lodash.get(bag, 'id_warehouse_input'),
                },
            }))
        }
    }

    render() {
        const { input } = this.state
        const { agencies, t, bag, bagAreas, exporting, permissions, requiredAgencyWhenCreateBag,packages } = this.props
        const { current_warehouse } = bag

        let bagWarnings = null

        if (bag.message_warnings && bag.message_warnings.length) {
            bagWarnings = bag.message_warnings.map(messageWarning => {
                let warning = null

                if (messageWarning.weight_diff) {
                    warning = trans('bag:message.weight_diff', {
                        weight_diff: messageWarning.weight_diff,
                    })
                } else if (messageWarning.attach_package_to_bag_than_amount_order) {
                    warning = trans('bag:message.attach_package_to_bag_than_amount_order')
                } else {
                    warning = trans(`bag:message.${lodash.isObject(messageWarning) ? Object.keys(messageWarning) : messageWarning}`)
                }

                return <div key={lodash.isObject(messageWarning) ? Object.keys(messageWarning) : messageWarning}>{warning}</div>
            })
        }

        const canExportManifest = AuthService.can(PERMISSION.EXPORT_MANIFEST)

        return (
            <div className="block--primary a-content--bag mr-0">
                <div className="title a-content--bag--title">
                    <div className="left">
                        <span>
                            <strong style={{ fontSize: '18px' }}>{bag.code}</strong>
                        </span> {'  '}
                        <span>{lodash.get(bag, 'receiver_name', '--')}</span>

                    </div>
                    <div className="right">
                        <PrintBagStampButton
                            bagId={lodash.get(bag, 'id')}
                            className="a-btn a-btn--transparent-white mr-3 _btn_print_bag_stamp"
                            ghost
                            type="button"
                        />
                         <PrintBagStampFucButton
                            bagId={lodash.get(bag, 'id')}
                            className="a-btn a-btn--transparent-white mr-3 _btn_print_bag_stamp"
                            ghost
                            type="button"
                            numberStamp={packages.length || 1}
                        />
                        {!!permissions.can_create && (
                            <Button
                                type="button"
                                className="a-btn a-btn--transparent-white mr-3 _btn_create_similar_bag"
                                ghost
                                onClick={this.onCreateSimilarBag.bind(this, bag)}>
                                {t('bag:btn.create_similar_bag')}
                            </Button>
                        )}
                        {!!permissions.can_create_template && (
                            <Button
                                type="button"
                                className="a-btn a-btn--transparent-white mr-3 _btn_create_template_bag"
                                ghost
                                onClick={this.onTogglePopupCreateTemplateBag.bind(this, bag)}>
                                {t('bag:btn.save_template_bag')}
                            </Button>
                        )}
                        {!!canExportManifest && <ExportManifestButton bag={bag} />}
                        <Button
                            type="button"
                            className="a-btn a-btn--transparent-white _btn_export_bag mr-3"
                            ghost
                            loading={exporting}
                            onClick={this.onExportBag.bind(this, bag)}>
                            {t('bag:btn.export_bag')}
                        </Button>
                        <Button type='link' className="a-btn a-btn--transparent-white">
                            <Link to={"bags.create"}> 
                                {t("bag:btn.create_new_bag")}
                            </Link>
                        </Button>
                        
                    </div>
                </div>
                <div className="body a-package__body ">
                    {!!bagWarnings && (
                        <Alert
                            className="mb-4"
                            message={bagWarnings}
                            type="warning"
                            showIcon
                        />
                    )}

                    <Row
                        gutter={{ lg: 32, xl: 48 }}
                        className="a-block-info">
                        <Col
                            lg={{ span: 15 }}
                            xl={{ span: 18 }}
                            xxl={{ span: 19 }}>
                            {this.state.isCollapse ? (
                                <div className="a-block-info bg-gray">
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 24 }}
                                            xl={{ span: 24 }}
                                            className="info--title">
                                            <h4>{t('bag:detail.bag_info')}</h4>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <UpdateShippingType
                                            {...this.props}
                                            className={'_shipping_type'}
                                            getBagStatus={this.getBagStatus}
                                        />
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="id_warehouse_destination"
                                            />
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group _created_at">
                                            <label className="info--label">{t('bag:column.created_at')}</label>
                                            <span className="info--value ">{dateFormatter.full(bag.created_at)}</span>
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group _time_scan">
                                            <label className="info--label">{t('bag:label.time_scan')}</label>
                                            <span className="info--value ">{dateFormatter.full(bag.time_scan)}</span>
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 12 }}
                                            className="info--group _status_warehouse">
                                            <label className="info--label">{t('bag:label.status_warehouse_current')}</label>
                                            <span className="info--value">
                                                {bag.status_warehouse ? t(`bag:status_warehouse.${bag.status_warehouse}`) : '--'}
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group _weight_real">
                                            <UpdateBagParam
                                                bag={bag}
                                                param="weight_real"
                                                status={this.getBagStatus(AuthService.can(PERMISSION.BAG_UPDATE_ALL))}
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group _weight_package">
                                            <label className="info--label">{t('bag:column.weight_package')}</label>
                                            <span className="info--value ">{bag.weight_package ? bag.weight_package + ' (kg)' : '--'}</span>
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 12 }}
                                            className="info--group">
                                            <UpdateVolumeBag
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                updateBag={this.props.updateBag}
                                                loading={this.props.loading}
                                                param="volume"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            className="info--group"
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}>
                                            <UpdateBagParam
                                                agencies={agencies}
                                                bag={bag}
                                                param="id_agency"
                                                required={requiredAgencyWhenCreateBag}
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                            />
                                        </Col>
                                        <Col
                                            className="info--group"
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}>
                                            <UpdateBagParam
                                                bag={bag}
                                                bagAreas={bagAreas}
                                                param="id_bag_area"
                                                required={requiredAgencyWhenCreateBag}
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className="a-block-info bg-gray">
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 24 }}
                                            xl={{ span: 24 }}
                                            className="info--title">
                                            <h4>{t('bag:detail.bag_info')}</h4>
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="type"
                                                input={this.state.input}
                                                onChangeValue={this.onChangeValue}
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="id_customer"
                                                onChangeValue={this.onChangeValue}
                                                input={this.state.input}
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group _status">
                                            <UpdateBagParam
                                                bag={bag}
                                                param="status"
                                                status={this.getBagStatus(this.props.permissions.can_change_transport_status)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0"
                                    />
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="id_bag_size"
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 24, offset: 0 }}
                                            xl={{ span: 17, offset: 1 }}
                                            xxl={{ span: 19, offset: 1 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="properties"
                                            />
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="id_warehouse_input"
                                                onChangeValue={this.onChangeValue}
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="id_warehouse_destination"
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group _weight_real">
                                            <UpdateBagParam
                                                bag={bag}
                                                param="weight_real"
                                                status={this.getBagStatus(AuthService.can(PERMISSION.BAG_UPDATE_ALL))}
                                            />
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0"
                                    />
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="receiver_name"
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="receiver_phone"
                                            />
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group _weight_package">
                                            <label className="info--label">{t('bag:column.weight_package')}</label>
                                            <span className="info--value ">{bag.weight_package ? bag.weight_package + ' (kg)' : '--'}</span>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <UpdateShippingType
                                            {...this.props}
                                            className="_shipping_type"
                                            getPartnersByWarehouse
                                            warehouseId={input.id_warehouse_input}
                                            getBagStatus={this.getBagStatus}
                                        />
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group _created_at">
                                            <label className="info--label">{t('bag:column.created_at')}</label>
                                            <span className="info--value ">{dateFormatter.full(bag.created_at)}</span>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}
                                            className="info--group _id_warehouse_current">
                                            <label className="info--label">{t('bag:column.id_warehouse_current')}</label>
                                            <span className="info--value ">{lodash.get(current_warehouse, 'code', '--')}</span>
                                        </Col>
                                        <Col
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}
                                            className="info--group _status_warehouse">
                                            <label className="info--label">{t('bag:label.status_warehouse_current')}</label>
                                            {bag.status_warehouse ? t(`bag:status_warehouse.${bag.status_warehouse}`) : '--'}
                                        </Col>
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 12 }}
                                            className="info--group _time_scan">
                                            <label className="info--label">{t('bag:label.time_scan')}</label>
                                            <span className="info--value ">{dateFormatter.full(bag.time_scan)}</span>
                                        </Col>
                                    </Row>
                                    <Row />
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 12 }}
                                            xxl={{ span: 12 }}
                                            className="info--group">
                                            <UpdateVolumeBag
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                updateBag={this.props.updateBag}
                                                loading={this.props.loading}
                                                param="volume"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="note"
                                            />
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            className="info--group"
                                            lg={{ span: 14 }}
                                            xl={{ span: 6 }}
                                            xxl={{ span: 4 }}>
                                            <UpdateBagParam
                                                agencies={agencies}
                                                bag={bag}
                                                param="id_agency"
                                                required={requiredAgencyWhenCreateBag}
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                            />
                                        </Col>
                                        <Col
                                            className="info--group"
                                            lg={{ span: 14, offset: 0 }}
                                            xl={{ span: 7, offset: 1 }}
                                            xxl={{ span: 6, offset: 1 }}>
                                            <UpdateBagParam
                                                bag={bag}
                                                bagAreas={bagAreas}
                                                param="id_bag_area"
                                                required={requiredAgencyWhenCreateBag}
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                            />
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="ml-0 mr-0">
                                        <Col
                                            lg={{ span: 14 }}
                                            xl={{ span: 10 }}
                                            xxl={{ span: 8 }}
                                            className="info--group">
                                            <UpdateBagParam
                                                className="_update_params_is_unchecked"
                                                status={this.getBagStatus(this.props.permissions.can_update)}
                                                bag={bag}
                                                param="is_unchecked"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <div
                                className="collapse--checkpackage"
                                onClick={this.onToggle}>
                                {this.state.isCollapse ? (
                                    <a
                                        href="/"
                                        onClick={event => event.preventDefault()}>
                                        {t('view_full_info')}{' '}
                                        <Icon
                                            type="arrows-alt"
                                            className="ml-1 _view_full_info"
                                        />
                                    </a>
                                ) : (
                                    <a
                                        href="/"
                                        onClick={event => event.preventDefault()}>
                                        {t('collapse_info')}{' '}
                                        <Icon
                                            type={`shrink`}
                                            className="ml-1 _view_compact_info"
                                        />
                                    </a>
                                )}
                            </div>
                        </Col>
                        <Col
                            lg={{ span: 9 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 5 }}
                            className="pl-0">
                            <UpdateBagParam
                                status={this.getBagStatus(this.props.permissions.can_update)}
                                bag={bag}
                                param="services"
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

BagInfo.defaultProps = {
    bag: {},
    permissions: {},
}

BagInfo.propTypes = {
    bag: PropTypes.object,
}

export default withTranslation()(BagInfo)
