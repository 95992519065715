import React, {Component} from 'react';
import {Button, Col, Form, Icon, Layout, Row, Switch} from "antd";
import {DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF} from "../../constants";
import {router} from "../../../../system/routing";
import PropTypes from "prop-types";
import lodash from 'lodash';
import {withTranslation} from "react-i18next";
import FormWarehouseStaffContainer from "../../FormWarehouseStaff/FormWarehouseStaffContainer";

const Content = Layout.Content;

class CreateWarehouseStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {...DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF, active: true, staff_id: 0}
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    componentDidMount() {
        this.props.setMenuActive('config/warehouse-staff')
    }

    onCreateWarehouseStaff = () => {
        let data = lodash.pickBy({...this.state}, lodash.identity);
        data.active = this.state.active ? 1 : 0;
        this.props.createWarehouseStaff(data);
    };

    onSubmit = (value) => {
        this.setState({
            ...this.state, ...value
        })
    };

    onChecker = (e) => {
        this.setState({active: e})
    };

    onBackConfigListWarehouse = () => {
        router.redirect('/config/warehouse-staff')
    };

    render() {
        let {adding, t, loading} = this.props;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <a href={"#top"} className={"a-text--gray _btn-back"} onClick={this.onBackConfigListWarehouse}><Icon
                        type="arrow-left" className={"mr-2 "}/>{t("warehouse_staff:label.back_warehouse_staff")}</a>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t("warehouse_staff:label.info")}</h3>
                        <div>
                            <label
                                className={"a-text--fz-12 a-text--gray mr-2"}>{t("warehouse_staff:label.status")}</label>
                            {!loading &&
                            <Switch defaultChecked size="small" onChange={this.onChecker}
                                    disabled={loading}
                                    loading={loading}
                                    className={'_switch-active'}
                                    checker={this.state.active}/>}
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <Form className="a-form ">
                            <FormWarehouseStaffContainer onSubmit={this.onSubmit}
                                                         errors={this.props.errors}/>
                            <Row gutter={48}>
                                <Col lg={{span: 24}} xl={{span: 24}}
                                     className="a-package--action only-action">
                                    <a className="a-btn a-btn--back-package _btn-cancel"
                                       href={"#top"}
                                       onClick={this.onBackConfigListWarehouse}>{t("common:btn.cancel")} </a>
                                    <Button type="primary"
                                            loading={adding}
                                            className="a-btn a-btn--primary a-btn--save-package _btn-save-create-warehouse-staff"
                                            onClick={this.onCreateWarehouseStaff}>{t("common:btn.add")} </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Content>
        );
    }
}

CreateWarehouseStaff.defaultProps = {
    errors: {},
    loading: false,
    users: [],
    warehouses: [],
    warehouseStaffDetail: {}
};

CreateWarehouseStaff.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    warehouseStaffDetail: PropTypes.object,
    warehouses: PropTypes.array
};

export default withTranslation()(CreateWarehouseStaff);
