import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {editWarehouseStaff} from "./actions";
import * as detailWarehouseStaffSelectors from '../DetailWarehouseStaff/selectors';
import {fetchDetailWarehouseStaff, clearState} from '../DetailWarehouseStaff/actions';
import SearchableComponent from "../../Common/components/SearchableComponent";
import EditWarehouseStaff from "./components"
import * as createWarehouseStaffSelectors from "../CreateWarehouseStaff/selectors";
import * as updateWarehouseStaffSelectors from "./selectors";
import {fetchUsers} from "../../User/actions";
import {fetchSuggestWarehouses} from "../../Warehouse/actions";
import {setMenuActive} from "../../Common/actions";

const mapStateToProps = (state, props) => ({
    warehouseStaffDetail: detailWarehouseStaffSelectors.getState(state, 'detail', {}),
    loading: detailWarehouseStaffSelectors.getState(state, 'loading', false) ||
        createWarehouseStaffSelectors.getState(state, 'loading', false) ||
        updateWarehouseStaffSelectors.getState(state, 'loading', false),
});

const mapDispatchToProps = (dispatch) => ({
    fetchDetailWarehouseStaff: (id) => dispatch(fetchDetailWarehouseStaff(id)),
    editWarehouseStaff: data => dispatch(editWarehouseStaff(data)),
    fetchUsers: () => dispatch(fetchUsers()),
    fetchListWarehouse: () => dispatch(fetchSuggestWarehouses()),
    clearState: () => dispatch(clearState()),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class EditWarehouseStaffContainer extends SearchableComponent {

    componentWillUnmount() {
        this.props.clearState();
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.fetchUsers();
        this.props.fetchListWarehouse();
        this.props.setMenuActive('config/warehouse-staff')
    }

    onChangeFilter(filter) {
        this.props.fetchDetailWarehouseStaff(filter.id)
    }

    render() {
        return <EditWarehouseStaff {...this.props}/>
    }
}

EditWarehouseStaffContainer.defaultProps = {
    warehouseStaffs: [],
    warehouseStaffDetail: {}
};

EditWarehouseStaffContainer.propTypes = {
    warehouseStaffs: PropTypes.array,
    warehouseStaffDetail: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditWarehouseStaffContainer);