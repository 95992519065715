import React, { Component } from 'react'
import { Typography } from 'antd'
import clsx from 'clsx'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'

import { currentFormatter } from './../../services/format'
import { finishLoadPrintTemplate, loadPrintTemplate } from './../actions'

import ProductDetail from './Print9710PackageStamp/ProductDetail'
import ShipmentDetail from './Print9710PackageStamp/ShipmentDetail'

const { Paragraph, Text } = Typography

class Print9710PackageStamp extends Component {
    constructor(props) {
        super()

        props.loadPrintTemplate()
    }

    handleDidMount = () => {
        const { finishLoadPrintTemplate, onDidMount } = this.props

        finishLoadPrintTemplate()
        onDidMount()
    }

    componentDidMount() {
        const { logo } = this.props
        const packageData = this.props.package
        const isBatch = get(packageData, 'is_batch', false)

        if (isBatch || !logo) {
            this.handleDidMount()
        }
    }

    render() {
        let {
            manifests,
            destination_warehouse,
            logo,
            max_value,
            shipping_partner,
            products,
            product_quantity,
            total_value,
            order,
            services,
            properties,
            warehouse_services,
            items,
        } = this.props
        const packageData = this.props.package
        const isBatch = get(packageData, 'is_batch', false)

        let totalAmount = total_value > 0 && total_value < max_value ? total_value : null
        if (max_value === null) {
            //nếu không cấu hình thì hiển thị đúng giá trị
            totalAmount = total_value
        }
        if (max_value == 0) {
            //Có thể giá trị = 0, khi giá trị cấu hình là 0 thì auto không hiển thị giá trị này
            totalAmount = null
        }
        let fromName = get(manifests, 'custom_delegation_name')
        let fromMobile = get(manifests, 'custom_delegation_tel')
        let fromAddress = get(manifests, 'custom_delegation_address')
        let toName = get(manifests, 'customer_receive_name')
        let toMobile = get(manifests, 'customer_receive_phone')
        let toAddress = get(manifests, 'customer_receive_address')
        let showSenderEcommerceStamps = get(manifests, 'show_sender_ecommerce_stamps')

        if (!fromName && get(shipping_partner, 'name')) {
            fromName = get(shipping_partner, 'name')
        }
        if (!fromMobile && get(shipping_partner, 'phone')) {
            fromMobile = get(shipping_partner, 'phone')
        }
        if (!fromAddress && get(shipping_partner, 'address')) {
            fromAddress = get(shipping_partner, 'address')
        }
        if (isEmpty(items)) {
            items = [get(packageData, 'code')]
        }

        return items.map((code, index) => (
            <div
                className="print-package-official-stamp print-package-official-stamp-custom"
                key={index}>
                {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
                <div className="a-flex gjustify-start galign-center mb-2">
                    {isBatch ? null : (
                        <div className="a-flex width-45-pc mx-4">
                            {logo ? (
                                <img
                                    alt=""
                                    className="partner-logo"
                                    src={logo}
                                    onLoad={this.handleDidMount}
                                    onError={this.handleDidMount}
                                />
                            ) : null}
                        </div>
                    )}
                    <div className="a-text--center a-text--fz-24 a-text--nowrap">
                        <strong>CN9710</strong>
                    </div>
                    <div
                        className={clsx('mx-4 a-text--pre-wrap', {
                            'width-100-pc': isBatch,
                            'width-60-pc order-no': !isBatch,
                        })}>
                        <div className="a-flex galign-center">
                            <div
                                className={clsx('a-text--fz-16', {
                                    'width-20-pc': isBatch,
                                    'width-30-pc': !isBatch,
                                })}>
                                {destination_warehouse}
                            </div>
                            <div
                                className={clsx('a-flex galign-center', {
                                    'width-60-pc': isBatch,
                                    'width-80-pc': !isBatch,
                                })}>
                                <span> Order no: </span>
                                <strong className="a-text--fz-24">{get(order, 'code')}</strong>
                            </div>
                            {isBatch ? (
                                <div
                                    className={clsx({
                                        'width-30-pc': isBatch,
                                    })}>
                                    {get(packageData, 'ref_shipment_code')}
                                </div>
                            ) : null}
                        </div>
                        <div className="a-flex galign-center">
                            <div
                                className={clsx({
                                    'width-20-pc': isBatch,
                                    'width-30-pc': !isBatch,
                                })}>
                                {get(order, 'id')}
                            </div>
                            <div
                                className={clsx('a-flex galign-center', {
                                    'width-60-pc': isBatch,
                                    'width-80-pc': !isBatch,
                                })}>
                                <span>User: </span>
                                <strong className="a-text--fz-24">{get(order, 'customer_username') || get(packageData, 'customer_username')}</strong>
                            </div>
                            {isBatch ? (
                                <div
                                    className={clsx({
                                        'width-30-pc': isBatch,
                                    })}>
                                    <strong className="mb-2 mt-1 a-text--fz-16">{get(packageData, 'ref_customer_code')}</strong>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="a-flex g-justify-center border-bottom pb-4 ">
                    <Barcode
                        copyStyles={true}
                        height={80}
                        margin={0}
                        value={code}
                        width={3.5}
                        fontSize={28}
                        fontOptions={'bold'}
                        font={'Roboto, sans-serif'}
                        textMargin={10}
                    />
                </div>

                <div className="row border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">From</Paragraph>
                    </div>
                    <div className="p-2 tile">
                        {showSenderEcommerceStamps && (
                            <div>
                                <Paragraph className="mb-0">Name: {fromName}</Paragraph>
                                <Paragraph className="mb-0">Mobile: {fromMobile}</Paragraph>
                                <Paragraph className="mb-0">Add: {fromAddress}</Paragraph>
                            </div>
                        )}
                    </div>
                </div>

                <div className="a-flex border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">To</Paragraph>
                    </div>
                    <div className="p-2 tile">
                        {(toName || toMobile || toAddress) && (
                            <div>
                                <Paragraph className="mb-0">Name: {toName}</Paragraph>
                                <Paragraph className="mb-0">Mobile: {toMobile}</Paragraph>
                                <Paragraph className="mb-0">Add: {toAddress}</Paragraph>
                            </div>
                        )}
                    </div>
                </div>

                <ShipmentDetail
                    packageData={packageData}
                    productQuantity={product_quantity}
                    services={services}
                    properties={properties}
                    warehouse_services={warehouse_services}
                />

                <ProductDetail products={products} />

                <div className="row">
                    <div className="a-flex galign-center gjustify-center gflex-direction-column a-text--center tile width-25-pc">
                        <Text className="a-text--fz-14">Payment COD:</Text>
                        <Text
                            className="a-text--fz-16"
                            strong>
                            {totalAmount ? currentFormatter.toLocaleStringCurrency(totalAmount) + ' VNĐ' : 'VNĐ'}
                        </Text>
                    </div>
                    <div className="a-flex gjustify-center gflex-direction-column tile width-75-pc">
                        <Text>Receiver's signature</Text>
                        <Text>(Confirm intact goods)</Text>
                    </div>
                </div>
            </div>
        ))
    }
}

Print9710PackageStamp.defaultProps = {
    onDidMount: () => {},
}

Print9710PackageStamp.propTypes = {
    onDidMount: PropTypes.func,
}

export default connect(undefined, {
    finishLoadPrintTemplate,
    loadPrintTemplate,
})(Print9710PackageStamp)
