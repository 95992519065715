import React, {Component} from 'react';
import {Button, Form, Modal} from 'antd';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';

import BagAreaFrom from './BagAreaForm';
import BagAreaFormLoading from "./bagAreaFormLoading";

class EditBagAreaModal extends Component {
    state = {
        formErrors: {}
    };

    resetFormErrors = () => {
        this.setState({
            formErrors: {}
        });
    };

    handleOnChange = () => {
        this.resetFormErrors();
    };

    handleOk = () => {
        const { form, onOk } = this.props;

        form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }

            try {
                await onOk(values);

                this.handleCancel();
            } catch (errors) {
                this.setState({
                    formErrors: errors
                });
            }
        });
    };

    handleCancel = () => {
        const { form, onCancel } = this.props;

        form.resetFields();
        this.resetFormErrors();
        onCancel();
    };

    render() {
        const { formErrors } = this.state;
        const { bagArea, form, loading, t, type, visible } = this.props;

        return (
            <Modal
                footer={[
                    <Button className="_btn_cancel" key="back" onClick={this.handleCancel}>
                        {t('btn.cancel')}
                    </Button>,
                    <Button
                        className={clsx({
                            '_btn_create': type === 'add',
                            '_btn_update': type === 'update'
                        })}
                        key="submit"
                        loading={loading}
                        type="primary"
                        onClick={this.handleOk}
                    >
                        {type === 'update' ? t('btn.update') : null}
                        {type === 'add' ? t('btn.add') : null}
                    </Button>
                ]}
                visible={visible}
                onCancel={this.handleCancel}
            >
                {loading ? (
                    <BagAreaFormLoading />
                ) : (
                    <BagAreaFrom
                        bagArea={bagArea}
                        form={form}
                        formErrors={formErrors}
                        onChange={this.handleOnChange}
                    />
                )}
            </Modal>
        );
    }
}

export default Form.create()(withTranslation()(EditBagAreaModal));
