import React, {Component} from 'react';
import {Button, Layout} from 'antd';
import {get} from 'lodash';

import BagAreasTable from './BagAreasTable';
import EditBagAreaModal from './EditBagAreaModal';
import Filter from './Filter';

const { Content } = Layout;

class BagArea extends Component {
    state = {
        bagAreaModalType: 'add',
        editBagAreaModalVisible: false,
        selectedBagArea: {}
    };

    handleCancelBagAreaModal = () => {
        this.setState({
            editBagAreaModalVisible: false,
            selectedBagArea: {}
        });
    };

    handleAddAreaButtonClick = () => {
        this.setState({
            bagAreaModalType: 'add',
            editBagAreaModalVisible: true
        });
    };

    updateOrCreateBagArea = async data => {
        const { onAddBagArea, onUpdateBagArea } = this.props;

        if (data.id) {
            await onUpdateBagArea(data);
        } else {
            await onAddBagArea(data);
        }
    };

    handleOnRowClick = bagArea => {
        this.setState({
            bagAreaModalType: 'update',
            selectedBagArea: bagArea,
            editBagAreaModalVisible: true,
        });
    };

    componentDidMount() {
        const { setMenuActive } = this.props;

        setMenuActive('config/bag-areas');
    }

    render() {
        const { bagAreaModalType, editBagAreaModalVisible, selectedBagArea } = this.state;
        const { adding, bagAreas, loadingBagAreas, pagination, t, updating, getCurrentFilter, onSearch } = this.props;

        return (
            <Content className="aml-24 aml-16-lg a-content-config height-100 gflex gflex-direction-column">
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>{t('bag_area:area_list')} ({get(pagination, 'total', 0)})</h3>
                        <div className="a-form__search-inline-title">
                            <Filter
                                filter={getCurrentFilter()}
                                onSearch={onSearch}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-bag-area"
                            type="primary"
                            onClick={this.handleAddAreaButtonClick}
                        >
                            {t('bag_area:add_area.main')}
                        </Button>
                    </div>
                </div>

                <div className="a-content__body bg-white a-content__body--page-list height-100">
                    <BagAreasTable
                        bagAreas={bagAreas}
                        loading={loadingBagAreas}
                        onRowClick={this.handleOnRowClick}
                    />
                </div>

                <EditBagAreaModal
                    bagArea={selectedBagArea}
                    loading={loadingBagAreas || adding || updating}
                    type={bagAreaModalType}
                    visible={editBagAreaModalVisible}
                    onOk={this.updateOrCreateBagArea}
                    onCancel={this.handleCancelBagAreaModal}
                />
            </Content>
        );
    }
}

export default BagArea;
