import { all, fork, takeLatest } from 'redux-saga/effects';

import { ADD_SHIPPING_FEE, GET_ROUTES, GET_SHIPPING_FEES, UPDATE_SHIPPING_FEE } from './constants';
import reExecutionWatcher from './re-execution/sagaWatcher';
import { addShippingFee, getRoutes, getShippingFees, successAddShippingFee, successUpdateShippingFee, updateShippingFee } from './saga';

export default function*() {
    yield all([
        yield takeLatest(GET_SHIPPING_FEES.REQUEST, getShippingFees),
        yield takeLatest(ADD_SHIPPING_FEE.REQUEST, addShippingFee),
        yield takeLatest(ADD_SHIPPING_FEE.SUCCESS, successAddShippingFee),
        yield takeLatest(UPDATE_SHIPPING_FEE.REQUEST, updateShippingFee),
        yield takeLatest(UPDATE_SHIPPING_FEE.SUCCESS, successUpdateShippingFee),
        yield takeLatest(GET_ROUTES.REQUEST, getRoutes),
        fork(reExecutionWatcher)
    ]);
}
