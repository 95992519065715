export const CREATE_PACKAGE_BY_SCAN = {
    REQUEST: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.REQUEST',
    SUCCESS: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.SUCCESS',
    FAILED: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.FAILED',
};

export const PACKAGE_ACTION = {
    IN: 'IN', // Nhập kho
    OUT: 'OUT', // Xuất kho
};

export const TYPE_PACKAGE = {
    RELATED: "RELATED",
    ALONE: "ALONE"
};

export const CAN_ADD_NOTE = 1;

export const BAG_TYPES = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
};

export const TYPES = {
    ALL: 'ALL',
    IN_A_DAY: 'IN_A_DAY'
};

export const STATUSES = {
    NEW: 'NEW',
    REQUEST_FOR_DELIVERY: 'REQUEST_FOR_DELIVERY',
    WAIT_FOR_TRANSPORTING: 'WAIT_FOR_TRANSPORTING',
    SHIPPING: 'SHIPPING',
    COMPLETED: 'COMPLETED',
    ARCHIVED: 'ARCHIVED'
};
