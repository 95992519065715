import { notification } from 'antd/lib/index';
import { forEach } from 'lodash';
import { put } from 'redux-saga/effects';

import * as ACTION from './constants';
import apiService from './../apiService';
import { setBarcodes } from './../../DeliveryNoteBarcode/actions';
import { processApiRequest } from './../../../Common/saga';
import { makeModelStateFromApiRes } from './../../../Common/services/helps';
import { BARCODE_TYPE } from './../../../Barcode/constants';
import { t } from './../../../../system/i18n';
import { router } from './../../../../system/routing';

export function* getDetail(action) {
    yield processApiRequest(ACTION.GET_DETAIL, () => apiService.detail(action.payload), action.payload);
}

export function* onSuccessGetDetail(action) {
    const barcodes = [];

    forEach(action.payload.packages, pkg => {
        const formattedPackage = makeModelStateFromApiRes(pkg, 'package');

        barcodes.push({
            code: formattedPackage.code,
            type: BARCODE_TYPE.PACKAGE,
            object: formattedPackage,
            order: formattedPackage.order,
            agency: pkg.agency
        });
    });

    yield put(setBarcodes(barcodes));
}

export function* onFailedGetDetail() {
    yield router.redirect('/404');

    yield  notification.error({
        message: t('delivery_note:message.INPUT_INVALID'),
    });
}
