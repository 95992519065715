import React, {Component} from 'react';
import {Button, Empty, notification, Table} from 'antd';
import {BAG_COLUMN} from '../constants';
import {PACKAGE_ACTION} from "../../constants";
import lodash from 'lodash';
import {withTranslation} from 'react-i18next';
import ListBagSettingContainer from '../ListBagSettingContainer';
import Pagination from './../../../Common/components/Pagination';
import {Link} from './../../../../system/routing';
import {t} from "../../../../system/i18n";
import authService from "../../../Auth/authService";
import PERMISSION from "../../../Auth/permissions";
import apiService from './../../apiService';
import {handleExportExcelError, handleExportManifestsError, isExportExcelToEmail, isExportManifestToEmail} from './../../helper';
import {convertBlobDataToExcel, showAliasAgency} from './../../../../system/support/helpers';

import ExportBagDetails from './ExportBagDetails';
import ExportManifestsModal from './ExportManifestsModal';
import UpdateShippingPartnerInBag from '../../../ShippingPartner/UpdateShippingPartnerInBag';
import { getState } from '../../../../system/store';
import permissions from '../../../Auth/permissions';

class ListBag extends Component {
    state = {
        activeColumns: [],
        selectedBags: [],
        exportingBagList: false,
        exportingExcel: false,
        exportingManifests: false,
        exportManifestModalVisible: false
    };

    onExportBag = (record) => {
        this.props.exportBag({
            barcode: record.code.props.children,
            id_warehouse_scan: record.id_warehouse_scan,
            status_warehouse: PACKAGE_ACTION.OUT
        });
    };

    getColumns() {
        let arrSort = [BAG_COLUMN.CREATE_TIME, BAG_COLUMN.SCAN_TIME, BAG_COLUMN.TOTAL_PACKAGES, BAG_COLUMN.TOTAL_PACKAGES_WEIGHT, BAG_COLUMN.BAG_WEIGHT, BAG_COLUMN.DIMENSION];
        let activeColumns = [...this.state.activeColumns];
        const showAlias = showAliasAgency(getState('setting.agencySetting'),  getState('setting.setting'), permissions.CONFIG_SHOW_AGENCY_ALIAS)

        let {filter} = this.props;
        if (filter.tab_on_day === "1") {
            activeColumns.push(BAG_COLUMN.EXPORT);
        } else {
            activeColumns = this.state.activeColumns;
        }
        return lodash.values(BAG_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    className: column === BAG_COLUMN.CODE ? `a-text--nowrap _bag_column _column_${column}` : `a-text--nowrap _column_${column}`,
                    key: column,
                    dataIndex: column,
                    title: column === BAG_COLUMN.EXPORT ? '' : this.props.t('bag:column.' + column),
                };

                if (column === BAG_COLUMN.EXPORT) {
                    res.render = (text, record) => {
                        if (record.status_warehouse === t('package:action.IN') && record.packing_complete === true) {
                            return (
                                <Button type="primary" icon="export" ghost disabled={this.props.loading}
                                        onClick={this.onExportBag.bind(this, record)}>{t('bag:btn.export_bag')}</Button>
                            )
                        }
                    }
                }

                if (column === BAG_COLUMN.AGENCIES) {
                    res.render = (text) => {
                       return <span className="a-text--nowrap _agency">
                        {lodash.get(text, 'name', '--')} {lodash.get(text, 'identify_name') && showAlias
                                            ? `(${lodash.get(text, 'identify_name')})` : null}
                    </span>
                    }
                }


                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by
                            ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : null,
                    }
                }
                return res;
            });
    }

    onChange(pagination, filters, sorter) {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            });
        }
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    exportExcel = async () => {
        const {selectedBags} = this.state;
        const {filter} = this.props;
        const bagKeys = selectedBags.map(bag => bag.key);
        const params = filter;

        this.setState({
            exportingExcel: true
        });

        if (bagKeys.length) {
            params.id_bags = bagKeys;
        }

        try {
            const response = await apiService.exportBagExcel(params);
            const reader = new FileReader();
            let message = t('message.export_excel_success');

            reader.addEventListener('loadend', (e) => {
                const text = e.target.result;

                try {
                    const data = JSON.parse(text);

                    if (isExportExcelToEmail(data)) {
                        message = t('message.export_csv_to_mail_success');
                    } else {
                        convertBlobDataToExcel(response.data, 'export_bags');
                    }

                    notification.success({
                        message
                    });
                } catch (e) {
                    convertBlobDataToExcel(response.data, 'export_bags');

                    notification.success({
                        message
                    });
                }
            });

            reader.readAsText(response.data);
        } catch (error) {
            handleExportExcelError(error);
        }

        this.setState({
            exportingExcel: false
        });
    };

    exportBagList = async () => {
        const { selectedBags } = this.state;
        const bagCodes = selectedBags.map(bag => bag.code.props.children);

        this.setState({
            exportingBagList: true
        });

        try {
            await apiService.exportBagList(bagCodes);

            notification['success']({
                message: t('bag:message.export_bag_success')
            });
        } catch (error) {
            let message = t('bag:message.export_bag_failed');

            const errors = lodash.get(error, 'response.data.data');

            if (lodash.has(errors, 'codes.required')) {
                message = t('bag:message.export_bag_list_errors.codes.required');
            } else if (lodash.has(errors, 'codes.exists')) {
                const codes = lodash.get(errors, 'codes.exists.bags') || [];

                message = t('bag:message.export_bag_list_errors.codes.exists', {
                    codes: codes.join(', ')
                });
            } else if (lodash.has(errors, 'codes.packing_not_completed')) {
                const codes = lodash.get(errors, 'codes.packing_not_completed.bags') || [];

                message = t('bag:message.export_bag_list_errors.codes.packing_not_completed', {
                    codes: codes.join(', ')
                });
            } else if (lodash.has(errors, 'codes.status_invalid')) {
                const codes = lodash.get(errors, 'codes.status_invalid.bags') || [];

                message = t('bag:message.export_bag_list_errors.codes.status_invalid', {
                    codes: codes.join(', ')
                });
            } else if (lodash.has(errors, 'codes.not_is_staff')) {
                const codes = lodash.get(errors, 'codes.not_is_staff.bags') || [];

                message = t('bag:message.export_bag_list_errors.codes.not_is_staff', {
                    codes: codes.join(', ')
                });
            }

            notification['error']({
                message
            });
        }

        this.setState({
            exportingBagList: false
        });
    };

    exportManifest = () => {
        this.setState({
            exportManifestModalVisible: true
        });
    };

    exportManifests = async (officialForm, language) => {
        const { selectedBags } = this.state;
        const { filter } = this.props;
        const bagIds = selectedBags.map(bag => bag.key);

        this.setState({
            exportingManifests: true
        });

        try {
            const response = await apiService.exportManifests({
                ...filter,
                id_bags: bagIds,
                language,
                official_form: officialForm
            });
            const reader = new FileReader();
            let message = t('message.export_manifest.success');

            reader.addEventListener('loadend', event => {
                const text = event.target.result;

                try {
                    const data = JSON.parse(text);

                    if (isExportManifestToEmail(data)) {
                        message = t('message.export_manifest.success_to_mail');
                    } else {
                        convertBlobDataToExcel(response.data, `export_manifests_${language}`);
                    }

                    notification.success({
                        message
                    });
                } catch (e) {
                    convertBlobDataToExcel(response.data, `export_manifests_${language}`);

                    notification.success({
                        message
                    });
                }
            });

            reader.readAsText(response.data);
        } catch (error) {
            handleExportManifestsError(error);
        }

        this.setState({
            exportingManifests: false
        });
    };

    onCloseExportManifestModal = () => {
        this.setState({
            exportManifestModalVisible: false
        });
    };

    onSelectRow = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedBags: selectedRows
        });
    };

    componentDidUpdate(prevProps) {
        const { filter } = this.props;
        const omittedFilterFields = [
            'i',
            'id_bags',
            'page',
            'per_page',
            'sort_by',
            'sort_direction'
        ];

        if (!lodash.isEqual(lodash.omit(filter, omittedFilterFields), lodash.omit(prevProps.filter, omittedFilterFields))) {
            this.setState({
                selectedBags: []
            });
        }
    }

    render() {
        const {exportingBagList, exportingExcel, exportingManifests, exportManifestModalVisible, selectedBags} = this.state;
        const {t, bags, pagination, filter, loading, page, object, isCurrentPage, isOpenListSetting, onOpenListSetting, exportBag} = this.props;
        const canExportBagList = authService.can(PERMISSION.BARCODE_SCAN) && page === 'list_bag';
        const canExportCsv = authService.can(PERMISSION.EXPORT_BAGS_LIST) && object !== 'IN_A_DAY';
        const canExportManifest = authService.can(PERMISSION.EXPORT_MANIFEST) && page !== 'list_bag_pack';
        const canExportPackages = authService.can(PERMISSION.EXPORT_PACKAGES_LIST_IN_BAG);

        return (
            <>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3>{t("common:menu." + page)} ({pagination.total})</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <UpdateShippingPartnerInBag />
                        {canExportManifest ? (
                            <>
                                <Button
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                                    ghost
                                    type="primary"
                                    onClick={this.exportManifest}
                                >
                                    {t('bag:btn.export_manifest_file')}
                                </Button>
                                <ExportManifestsModal
                                    exporting={exportingManifests}
                                    visible={exportManifestModalVisible}
                                    onCancel={this.onCloseExportManifestModal}
                                    onExport={this.exportManifests}
                                />
                            </>
                        ) : null}

                        {canExportCsv ? (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost
                                loading={exportingExcel}
                                type="primary"
                                onClick={this.exportExcel}
                            >
                                {t('btn.export_excel_file')}
                            </Button>
                        ) : null}

                        {canExportPackages && (
                            <ExportBagDetails
                                filter={{
                                    ...filter,
                                    id_bags: selectedBags.map(bag => bag.key)
                                }}
                            />
                        )}

                        {canExportBagList ? (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 _btn-export-bag-list"
                                ghost
                                loading={exportingBagList}
                                type="primary"
                                onClick={this.exportBagList}
                            >
                                {t('bag:btn.export_bag')}
                            </Button>
                        ) : null}

                        <ListBagSettingContainer
                            onChange={activeColumns => this.setState({activeColumns})}
                            page={page}
                            isOpenListSetting={isOpenListSetting}
                            onOpenListSetting={onOpenListSetting}
                            isCurrentPage={isCurrentPage}
                            type={filter.type}
                            object={object}/>

                        <Link to={"bags.create"}
                              className="a-btn a-btn--link-primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                        > {t("bag:label.create_bag")}
                        </Link>
                    </div>
                </div>

                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed ">
                    <div className="a-table--responsive">
                        <Table
                            loading={loading}
                            className={"a-table--page-list"}
                            rowClassName={record => `_row_${lodash.get(record, 'key')}`}
                            columns={this.getColumns()}
                            dataSource={bags}
                            pagination={false}
                            rowSelection={{
                                selectedRowKeys: selectedBags.map(bag => bag.key),
                                onChange: this.onSelectRow
                            }}
                            locale={{
                                emptyText: (
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        description={<span>{t("common:label.no_data")}</span>}
                                    >
                                    </Empty>)
                            }}
                            onChange={this.onChange.bind(this)}
                            exportBag={exportBag}
                        />
                    </div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        pageSizeOptions={[20, 50, 100, 200]}
                        total={pagination.total}
                        loading={loading}
                        onChange={this.onChangePage.bind(this)}/>
                </div>

            </>
        )
    }
}

export default withTranslation()(ListBag);
