import { get } from 'lodash';

export const getState = (state, param = null, defaultValue = null) => get(state, `boxSize${(param ? `.${param}` : '')}`, defaultValue);

export const getSuggestBoxSizes = (state, path, d = []) => {
    const data = get(state, 'boxSize.suggest', {});

    return get(data, path, d);
};

export const getLoading = (state) => {
    return get(state, 'boxSize.loading', false);
};
