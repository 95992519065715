import {takeLatest, all, fork} from 'redux-saga/effects';
import * as saga from './saga';
import {AUTH_SUCCESS, LOGOUT} from './constants';
import loginWatcher from './Login/sagaWatcher';

export default function*() {
    yield all([
        fork(loginWatcher),
    ]);
    
    yield takeLatest(AUTH_SUCCESS, saga.onAuthSuccess);
    yield takeLatest(LOGOUT, saga.onLogout);
}
