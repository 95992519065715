import React from 'react';
import { notification } from 'antd';
import { get, has, hasIn } from 'lodash';

import apiService from './../Create/apiService';
import { CREATE } from './constants';
import { processApiRequest } from './../../Common/saga';
import { trans } from './../../../system/i18n';
import { url } from './../../../system/routing';

export function* create(action) {
    yield processApiRequest(
        CREATE,
        () => apiService.create(action.payload),
        action.payload
    );
}

export function* successCreate(action) {
    yield notification.success({
        message: trans('delivery_note:message.create_delivery_note_success')
    });

    if (has(action, 'payload.warnings.warning_lack_of_package')) {
        yield setTimeout(() => {
            notification.warning({
                message: (
                    <span>
                        {trans('delivery_note:message.warning_lack_of_package', {
                            delivery_note: (
                                <b>{get(action, 'payload.warnings.warning_lack_of_package').toString()}</b>
                            )
                        })}
                    </span>
                )
            });
        }, 500);
    }

    url.redirectTo('delivery-notes.customer.detail', {
        id: get(action, 'payload.delivery_note.id', 0)
    });
}

export function* failedCreate(action) {
    const error = get(action, 'payload.data');
    if (hasIn(error, 'package.package_not_same_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_same_delivery_request')}</span>,
        });
    }

    if (hasIn(error, 'package.package_all_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_all_not_in_delivery_request')}</span>,
        });
    }

    if (hasIn(error, 'package.package_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_in_delivery_request')}</span>,
        });
    }
    if (get(error, 'warehouse')) {
        return yield notification.error({
            message: <span>{trans('message.warehouse_not_is_staff')}</span>,
        });
    }

    yield notification.error({
        message: trans('delivery_note:message.create_delivery_note_failed')
    });
}
