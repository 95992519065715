/* eslint-disable */

import React from 'react';
import ListDeliveryNote from './ListDeliveryNoteExport';
import InfoDeliveryNote from './InfoDeliveryNote';
import {Row} from "antd";
import Log from "../Log";

class DeliveryNoteEmployee extends React.Component {
    render() {
        return (
            <Row type="flex">
                <div className="a-content--scroll-content pt-0">
                    <InfoDeliveryNote/>
                    <ListDeliveryNote/>
                </div>
                <Log/>
            </Row>

        )
    }
}

export default DeliveryNoteEmployee;