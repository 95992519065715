import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cookies from 'react-cookies';
import { withTranslation } from 'react-i18next';

import apiService from './../apiService';
import pageService from './../../Common/Page/pageService';
import config from './../../../config/auth';

const Statistics = ({ t, setMenuActive }) => {
    const [authToken, setAuthToken] = useState();

    useEffect(() => {
        getAuthOnlyToken();
        setMenuActive('statistic');
        pageService.setTitle(t('statistic'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAuthOnlyToken = async () => {
        let authOnlyToken = cookies.load('auth_only_token');
        const isValidToken = await checkTokenBeforeCreate();

        if (!isValidToken) {
            try {
                const { data } = await apiService.creteAuthOnlyToken();
    
                authOnlyToken = data.token;
    
                cookies.save('auth_only_token', authOnlyToken, {
                    path: '/',
                    expires: config.tokenExpire ? moment().add(config.tokenExpire, 'minutes').toDate() : null,
                });
            } catch (error) {
            }
        }

        setAuthToken(authOnlyToken);
    };

    const checkTokenBeforeCreate = async () => {
        let authOnlyToken = cookies.load('auth_only_token');

        if (!authOnlyToken) {
            return false;
        }

        try {
            await apiService.checkAuthOnlyToken(authOnlyToken);

            return true;
        } catch (error) {
            return false
        }
    };

    if (!authToken) {
        return null;
    };

    return (
        <div className="aflex-1 amr-24 statistics">
            <iframe
                allowFullScreen
                frameBorder="0"
                src={`https://datastudio.google.com/embed/reporting/b91bb836-01b2-4b04-9029-800ca4db3b51/page/jYQnB?params={"1stPartner":"${authToken}","2ndPartner":"${authToken}","3rdPartner":"${authToken}","4thPartner":"${authToken}"}`}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 0
                }}
                title="statistic"
            />
        </div>
    );
};

export default withTranslation()(Statistics);
