import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { setMenuActive } from './../Common/actions'

import { get } from 'lodash'
import authService from '../Auth/authService'
import permissions from '../Auth/permissions'
import SearchableComponent from './../Common/components/SearchableComponent'
import CreatePackages from './components/CreatePackages'
import DistributionWarehouse from './DistributionWarehouse'
import ExpeditedProcessingTabs from './index'
import ExpeditedProcessingList from './List/Container'
import UnsettledLawsuitsContainer from './UnsettledLawsuits'


const mapStateToProps = state => {
    return {
        setting: get(state, 'setting.setting', []),
    }
}

class ExpeditedProcessing extends SearchableComponent {
    state = {
        tabs: [
            {
                component: <CreatePackages />,
                key: 'create-packages',
                title: this.props.t('package:create.title'),
                permission: authService.can(permissions.PACKAGE_EXPEDITED_PROCESSING_CREATE),
            },
            {
                component: <ExpeditedProcessingList {...this.props} />,
                key: 'expedited-list',
                title: this.props.t('package:label.package_expedited_list'),
                permission: authService.can(permissions.PACKAGE_VIEW),
            },
            {
                component: <DistributionWarehouse />,
                key: 'distribution_warehouse',
                title: this.props.t('package:label.distribution_warehouse'),
                permission: authService.can(permissions.PACKAGE_EXPEDITED_PROCESSING_DISTRIBUTE),
            },
            {
                component: <UnsettledLawsuitsContainer {...this.props}/>,
                key: 'unsettled_lawsuits',
                title: this.props.t('package:label.unsettled_lawsuits'),
                permission: authService.can(permissions.PACKAGE_EXPEDITED_PROCESSING_DISTRIBUTE),
            },
        ],
        defaultTab: 'create-packages',
    }

    onSearch = filter => {
        this.pushFilter(filter)
    }

    getCurrentFilter = () => {
        const { defaultTab, tabs } = this.state
        const { location } = this.props
        const filter = {
            ...this.getFilterFromLocation(location),
        }

        const tab = tabs.find(tab => tab.key === filter.tab)

        if (!tab) {
            filter.tab = defaultTab
        }

        return filter
    }

    render() {
        const { tabs } = this.state

        return (
            <ExpeditedProcessingTabs
                {...this.props}
                filter={this.getCurrentFilter()}
                tabs={tabs}
                onSearch={this.onSearch}
            />
        )
    }
}

export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(ExpeditedProcessing)
)
