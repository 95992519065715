export const PACKING_PACKAGE_LASTMILE = {
    REQUEST: 'BAGS.PACKING_PACKAGE_LASTMILE.REQUEST',
    SUCCESS: 'BAGS.PACKING_PACKAGE_LASTMILE.SUCCESS',
    FAILED: 'BAGS.PACKING_PACKAGE_LASTMILE.FAILED',
};


export const PACKING_PACKAGE_LASTMILE_DETAIL = {
    REQUEST: 'BAGS.PACKING_PACKAGE_LASTMILE_DETAIL.REQUEST',
    SUCCESS: 'BAGS.PACKING_PACKAGE_LASTMILE_DETAIL.SUCCESS',
    FAILED: 'BAGS.PACKING_PACKAGE_LASTMILE_DETAIL.FAILED',
};
