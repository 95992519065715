import React, { Component } from 'react';
import { Layout } from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import pageService from './../../../../Common/Page/pageService';

import FormSearch from './FormSearch';
import ListDeliveryNote from './ListListDeliveryNote';

const { Content } = Layout;

class ListDeliveryNoteIndex extends Component {
    componentDidMount() {
        const { object, t, setMenuActive } = this.props;

        pageService.setTitle(t('delivery_note:label.list_delivery_note'));
        setMenuActive(`delivery-notes/${lodash.lowerCase(object)}`);
    }

    render() {
        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <FormSearch
                        input={this.props.filter}
                        inputVersion={lodash.toInteger(this.props.filter.i)}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                    />
                    <ListDeliveryNote
                        deliveryNotes={this.props.deliveryNotes}
                        pagination={this.props.pagination}
                        filter={this.props.filter}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                        isOpenListSetting={this.props.isOpenListSetting}
                        onOpenListSetting={this.props.onOpenListSetting}
                        isCurrentPage={this.props.isCurrentPage}
                    />
                </Content>
            </Layout>
        )
    }
}

ListDeliveryNoteIndex.defaultProps = {
    deliveryNotes: [],
    loading: false,
    filter: {},
    isOpenListSetting : false
};

ListDeliveryNoteIndex.propTypes = {
    deliveryNotes: PropTypes.array,
    filter: PropTypes.object,
    onReset: PropTypes.func,
    setMenuActive: PropTypes.func,
    onSearch: PropTypes.func,
    loading: PropTypes.bool,
    isOpenListSetting: PropTypes.bool,
    onOpenListSetting: PropTypes.func,
    isCurrentPage : PropTypes.string
};

export default withTranslation()(ListDeliveryNoteIndex);
