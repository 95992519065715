import { Button, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import moment from 'moment'
import authService from '../../Auth/authService'
import FormProcessing from '../../Common/components/FormProcessing'
import InputNumber from '../../Common/components/InputNumber'
import { STATUS_TRANSPORT_PACKAGE } from '../../Package/ChangePackageTransportStatus/constants'
import { PACKAGE_ACTION } from '../../Package/constants'
import { ELEMENT } from '../../Package/CreatePackageViaScan/services/inputFocus/constants'
import SelectAgency from '../../Setting/Agency/SelectAgency'
import SelectWarehouseContainer from '../../Warehouse/SelectWarehouse/SelectWarehouseContainer'

import { converValue2Int } from '../../Package/ListPackage/services'

const Option = Select.Option
const Search = Input.Search

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props)

        this.defaultInput = {
            id_warehouse_input: undefined,
            id_warehouse_current: undefined,
            id_warehouse_destination: undefined,
            'created_at[from]': undefined,
            'created_at[to]': undefined,
            'weight_net[from]': undefined,
            'weight_net[to]': undefined,
            status_warehouse: undefined,
            status_transports: [],
            code: undefined,
            username: undefined,
            id_agency: undefined,
            others: [],
            
        }

        this.state = {
            input: { ...this.defaultInput },
            inputVersion: 0,
            changedParams: [],
        }
    }

    componentDidMount(){
        const newInput = {
            ...this.props.filter
        }

        this.changeInput(newInput)
    }

    onSubmit = event => {
        event.preventDefault()

        const { input } = this.state
        const { loading, onSearch } = this.props

        if (!loading) {
            onSearch({
                ...input,
                page: 1,
            })
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : '',
        })
    }

    onReset = event => {
        event.preventDefault()
        const { input, loading, onSearch } = this.props

        if (!loading) {
            const newInput = {
                ...this.defaultInput,
                is_shipment: input.is_shipment,
            }

            this.changeInput(newInput)
            onSearch(newInput)
        }
    }

    onChangeMultiSelect(name, value) {
        this.changeInput({ [name]: value })
    }



    toggleCollapseSearch = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }))
    }

    toggleCollapseSearch = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }))
    }

    renderCollapsedForm = input => {
        const { t } = this.props
        let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g'
        return (
            <Row
           
                gutter={{ lg: 12, xl: 24 }}
                type="flex">
                <Col
                    lg={6}
                    xl={6}
                    xxl={4}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t('package:label.code_search')}
                        labelCol={{ span: 24 }}>
                        <Search
                            value={input.code || ''}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            placeholder={t('package:placeholder.code_search')}
                            className="a-input--search _packages_search"
                        />
                    </Form.Item>
                </Col>
                <Col
                    lg={4}
                    xl={3}
                    xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.warehouse_input')}
                        labelCol={{ span: 24 }}>
                        <SelectWarehouseContainer
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-input-package"
                            dropdownClassName="_dropdown_id_warehouse_input"
                            placeholder={t('package:label.warehouse_input')}
                            value={input.id_warehouse_input}
                            onChange={id_warehouse_input => this.changeInput({ id_warehouse_input })}></SelectWarehouseContainer>
                    </Form.Item>
                </Col>
                <Col
                    lg={4}
                    xl={3}
                    xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.warehouse_current')}
                        labelCol={{ span: 24 }}>
                        <SelectWarehouseContainer
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-current-package"
                            dropdownClassName="_dropdown_id_warehouse_current"
                            placeholder={t('package:label.warehouse_current')}
                            value={input.id_warehouse_current}
                            onChange={id_warehouse_current => this.changeInput({ id_warehouse_current })}></SelectWarehouseContainer>
                    </Form.Item>
                </Col>
                <Col
                    lg={4}
                    xl={3}
                    xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.warehouse_destination')}
                        labelCol={{ span: 24 }}>
                        <SelectWarehouseContainer
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-destination-package"
                            dropdownClassName="_dropdown_id_warehouse_destination"
                            isEmpty
                            placeholder={t('package:label.warehouse_destination')}
                            value={input.id_warehouse_destination}
                            onChange={id_warehouse_destination => this.changeInput({ id_warehouse_destination })}></SelectWarehouseContainer>
                    </Form.Item>
                </Col>
                <Col
                    lg={6}
                    xl={4}
                    xxl={3}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.status_warehouse')}
                        labelCol={{ span: 24 }}>
                        <Select
                            allowClear
                            className="a-select a-select--search-content _a-select-status-warehouse-package"
                            dropdownClassName="_dropdown_status_warehouse"
                            value={input.status_warehouse}
                            showSearch={true}
                            optionFilterProp="children"
                            placeholder={t('package:label.status_warehouse')}
                            onChange={status_warehouse => this.changeInput({ status_warehouse })}>
                            {lodash.values(PACKAGE_ACTION).map(action => (
                                <Option
                                    className={`_a-select-status-warehouse-${action}`}
                                    key={action}
                                    value={action}>
                                    {t('package:action.' + action)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    lg={6}
                    xl={5}
                    xxl={3}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.status_transport')}
                        labelCol={{ span: 24 }}>
                        <Select
                            allowClear
                            className="a-select a-select--search-content _a-select-status-transport-package"
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={t('package:label.status_transport')}
                            showSearch={true}
                            value={input.status_transports}
                            onChange={status_transports => this.changeInput({ status_transports })}>
                            {lodash.values(STATUS_TRANSPORT_PACKAGE).map(
                                action =>
                                    action !== STATUS_TRANSPORT_PACKAGE.CIRCULATE && (
                                        <Option
                                            className={`_a-select-status-transport-${action}`}
                                            key={action}
                                            value={action}>
                                            {t(`package:status_transport.${action}`)}
                                        </Option>
                                    )
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    lg={10}
                    xxl={8}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.created_at')}>
                        <Row gutter={4}>
                            <Col span={12}>
                                <DatePicker
                                    className="width-100-pc _created-at-from"
                                    locale={locale}
                                    placeholder={t('label.time_start')}
                                    format="HH:mm:ss DD/MM/YYYY"
                                    showTime={{
                                        defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                    }}
                                    value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                    onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    className="width-100-pc _created-at-to"
                                    locale={locale}
                                    placeholder={t('label.time_end')}
                                    format="HH:mm:ss DD/MM/YYYY"
                                    showTime={{
                                        defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                    }}
                                    value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                    onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col lg={4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label="Agency"
                        labelCol={{ span: 24 }}>
                        <SelectAgency
                            allowClear
                            placeholder="Agency"
                            value={input.id_agency}
                            onChange={agencyId =>
                                this.changeInput({
                                    id_agency: agencyId,
                                })
                            }
                        />
                    </Form.Item>
                </Col>
                <Col lg={8}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        className="a-form__item search-content"
                        label={
                            t('package:label.weight') +
                            ' (' +
                            (configPackageUnitWeight === 'g' ? `${configPackageUnitWeight} x 100` : configPackageUnitWeight) +
                            ')'
                        }>
                        <InputNumber
                            name={ELEMENT.PACKAGE_NET_WEIGHT}
                            autoComplete="off"
                            precision={2}
                            min={0}
                            value={input['weight_net[from]'] || ''}
                            placeholder={t('package:label.weight_from')}
                            onChange={value => this.changeInput({ 'weight_net[from]': value || undefined })}
                            className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                        />

                        <InputNumber
                            className="ant-col ant-col-11 mr-2 pl-2"
                            style={{ marginLeft: '4px', paddingLeft: '5px', paddingRight: '5px' }}
                            name={ELEMENT.PACKAGE_NET_WEIGHT}
                            autoComplete="off"
                            precision={2}
                            min={0}
                            value={input['weight_net[to]'] || ''}
                            placeholder={t('package:label.weight_to')}
                            onChange={value => this.changeInput({ 'weight_net[to]': value || undefined })}
                        />
                    </Form.Item>
                </Col>
                <Col
                    lg={6}
                    xxl={4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('other')}
                        labelCol={{ span: 24 }}>
                        <Select
                            allowClear
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={t('other')}
                            value={input.others || []}
                            onChange={this.onChangeMultiSelect.bind(this, 'others')}>
                            {['not_in_bag', 'not_in_order', 'has_operate_order'].map(value => (
                                <Option
                                    key={value}
                                    value={value}>
                                    {t(`package:other_options.${value}`)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    render() {
        const { t } = this.props
        const input = converValue2Int({
            ...this.state.input
        }, ['status_transports', 'others']);

        return (
            <div className="a-content--search-header search-default">
                <Form
                    onSubmit={this.onSubmit}
                    className="a-form">
                    {this.renderCollapsedForm(input)}

                    <div className="gflex galign-center gjustify-space-between search--action">
                        <div></div>
                        <div className="gflex galign-center">
                            <a
                                className="link-reload _a-btn-reload-list-package mr-3"
                                href="/"
                                onClick={this.onReset}>
                                <Icon type="sync" /> {t('label.refresh_filter')}
                            </a>
                            <Button
                                className="a-btn a-btn--primary a-btn--search-list _a-btn-search-list-package"
                                htmlType="submit"
                                loading={this.props.loading}
                                type="primary">
                                {t('btn.search')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {},
}

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
}

export default withTranslation()(FormSearch)
