import React from 'react';
import { Table } from 'antd';
import { get, isString, uniqueId } from 'lodash';
import { withTranslation } from 'react-i18next';

const UploadResultTable = ({ t, uploadResult }) => (
    <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
        <div className="mb-4">
            {`${t('package:upload_file.success_total')}: ${get(uploadResult, 'total_success', 0)}`}
        </div>
        <div className="a-text--red mb-4">
            {`${t('package:upload_file.failed_total')}: ${get(uploadResult, 'total_fail', 0)}`}
        </div>
        <div className="a-table--responsive">
            <Table
                columns={[{
                    title: t('line'),
                    key: 'line',
                    dataIndex: 'line'
                }, {
                    title: t('package'),
                    key: 'code',
                    dataIndex: 'code'
                }, {
                    title: t('error'),
                    key: 'reason',
                    dataIndex: 'reason',
                    render: text => {
                        if (isString(text)) {
                            return t(`package:upload_file.fail_reason.mia.${text}`);
                        }

                        const key = Object.keys(text)[0];

                        return t(`package:upload_file.fail_reason.mia.${key}`, {
                            value: text[key]
                        });
                    }
                }]}
                dataSource={get(uploadResult, 'fails', [])}
                pagination={false}
                rowKey={() => uniqueId()}
            />
        </div>
    </div>
);

export default withTranslation()(UploadResultTable);
