import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchDomesticShippingPartnersSuggest, fetchShippingPartnersSuggest } from './../ListShippingPartner/actions';
import { getListShippingPartnerActive, getState } from './../ListShippingPartner/selectors';
import { isInternational } from './../helper';

import SelectCreatePartner from './components';

const mapStateToProps = (state, props) => ({
    ...props,
    bagShippingPartners: getListShippingPartnerActive(state, props.query),
    loading: lodash.get(props, 'loading', false) || getState(state, 'listShippingPartner.loadingSuggestList', false)
});

const mapDispatchToProps = {
    fetchBagShippingPartner: fetchShippingPartnersSuggest,
    fetchDomesticShippingPartnersSuggest
};

class SelectCreatePartnerContainer extends React.Component {
    componentDidUpdate(prevProps) {
        const { getPartnersByWarehouse, shippingPartnerType, warehouseId } = this.props;

        if (warehouseId !== prevProps.warehouseId && getPartnersByWarehouse) {
            this.getShippingPartnersSuggest(shippingPartnerType, {
                id_warehouse: warehouseId
            });
        } else if (shippingPartnerType !== prevProps.shippingPartnerType) {
            this.getShippingPartnersSuggest(shippingPartnerType);
        }
    }

    componentDidMount() {
        const { shippingPartnerType, warehouseId } = this.props;

        this.getShippingPartnersSuggest(shippingPartnerType, {
            id_warehouse: warehouseId
        });
    }

    getShippingPartnersSuggest = (shippingPartnerType, params) => {
        const { fetchBagShippingPartner, fetchDomesticShippingPartnersSuggest } = this.props;

        if (isInternational(shippingPartnerType)) {
            fetchBagShippingPartner(params);
        } else {
            fetchDomesticShippingPartnersSuggest();   
        }
    };

    render() {
        return (
            <SelectCreatePartner {...this.props} />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(SelectCreatePartnerContainer);

Container.defaultProps = {
    errors: {},
    autoSelect: false,
    disabled: false,
    placeholder : '',
    shippingPartnerType: 'international',
    onChange: () => {
    },
};

Container.propTypes = {
    value: PropTypes.any,
    errors: PropTypes.object,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    shippingPartnerType: PropTypes.string
};

export default Container;
