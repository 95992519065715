import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Layout, Modal, Spin, notification } from 'antd'
import { setMenuActive } from '../../Common/actions'
import { connect } from 'react-redux'
import ScanForm from './components/ScanForm'
import PackageList from './components/PackageList'
import pageService from '../../Common/Page/pageService'
import Sound from '../../Sound'
import { getVar } from '../../../system/support/helpers'
import apiService from '../apiService'
import { map, pick } from 'lodash'
import { url } from '../../../system/routing'
import moment from 'moment'
import { DOCUMENT_STATUS } from '../constants'

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

class UpdateDocumentReturnGoods extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scroll: false,
            loading: false,
            data: { scanLoading: false, updateLoading: false, loadingDeliveryNote: false },
            deliveryNote: {},
            canEdit: false,
        }
    }

    componentDidMount() {
        pageService.setTitle(this.props.t('return_goods:title.update_document'), <Sound />)
        this.props.setMenuActive('return-goods/create')
        window.addEventListener('scroll', this.handleScroll)
        this.fetchDetailDocument()
    }

    fetchDetailDocument() {
        const {
            t,
            match: { params },
        } = this.props
        const id = getVar(params, 'id', 0)
        if (id) {
            this.setState({ loading: true })
            apiService
                .detail(id)
                .then(res => {
                    const status = getVar(res, 'data.status')
                    if (status === DOCUMENT_STATUS.PENDING) {
                        this.setState({ canEdit: true })
                        const deliveryNote = getVar(res, 'data.delivery_note', getVar(res, 'data.delivery_request', {}))
                        const packages = getVar(res, 'data.packages.data', [])
                        this.handleUpdateData({ documentId: id, deliveryNote, packages })
                    } else {
                        url.redirectTo('403')
                        // notification.error({ message: t('return_goods:message.cannot_edit_document') })
                    }
                })
                .catch(err => {
                    notification.error({ message: t('message.server_error') })
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        let { scroll } = this.state
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({ scroll: true })
            }
        } else {
            if (scroll) {
                this.setState({ scroll: false })
            }
        }
    }

    handleUpdateData = input => {
        const { data } = this.state
        this.setState({
            data: {
                ...data,
                ...input,
            },
        })
    }

    handleCreate = () => {
        const { data } = this.state
        const { t } = this.props
        this.handleUpdateData({ updateLoading: true })
        const packages = getVar(data, 'packages', [])
        const id_packages = map(packages, 'package.id')
        const params = pick(data, ['id_warehouse', 'delivery_note_code'])
        apiService
            .create({ ...params, id_packages })
            .then(res => {
                notification.success({
                    message: t(`return_goods:message.create_success`),
                })
                url.redirectTo('return-goods.detail', {
                    id: getVar(res, 'data.id'),
                })
            })
            .catch(err => {
                notification.error({
                    message: t(`return_goods:message.create_failed`),
                })
            })
            .finally(() => {
                this.handleUpdateData({ updateLoading: false })
            })
    }

    handleConfirm = () => {
        const { t } = this.props
        const { data } = this.state
        this.handleUpdateData({ updateLoading: true })
        const packages = getVar(data, 'packages', [])
        const id_packages = map(packages, 'package.id')
        const params = pick(data, ['id_warehouse', 'delivery_note_code'])
        apiService
            .confirmCreate({ ...params, id_packages })
            .then(res => {
                this.handleCreate()
            })
            .catch(error => {
                const code = getVar(error, 'response.data.code')
                const data = getVar(error, 'response.data.data')
                if (code === 'INPUT_INVALID') {
                    Object.entries(data).forEach(([key, value]) => {
                        if (Object.keys(value)[0] === 'COUNT_PACKAGE_SCAN_NOT_ENOUGH' && key === 'package_valid') {
                            Modal.confirm({
                                title: t('return_goods:title.confirm_create'),
                                content: t('return_goods:message.content_confirm_create'),
                                onOk: () => {
                                    this.handleCreate()
                                },
                                okText: t('btn.ok'),
                                cancelText: t('btn.cancel'),
                            })
                        } else {
                            notification.error({
                                message: t(`return_goods:message.scan.error`),
                            })
                        }
                    })
                } else {
                    notification.error({
                        message: t(`return_goods:message.scan.error`),
                    })
                }
            })
            .finally(() => {
                this.handleUpdateData({ updateLoading: false })
            })
    }

    render() {
        const { t } = this.props
        let { scroll, data, loading } = this.state
        return (
            <Layout>
                {loading ? (
                    <Spin spinning={loading}></Spin>
                ) : (
                    <>
                        <ScanForm
                            scroll={scroll}
                            handleUpdateData={this.handleUpdateData}
                            data={data}
                        />
                        <PackageList
                            action={true}
                            title={t('return_goods:title.list_package_scan')}
                            scroll={scroll}
                            data={data}
                            handleCreate={this.handleConfirm}
                            handleUpdateData={this.handleUpdateData}
                        />
                        <PackageList
                            title={t('return_goods:title.list_package_of_delivery_note_or_delivery_request')}
                            scroll={scroll}
                            data={data}
                        />
                    </>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpdateDocumentReturnGoods))
