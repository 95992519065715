import { connect } from 'react-redux';

import * as selectors from './../selectors';
import * as actions from './../actions';

import ListTracking from './components';

const mapStateToProps = state => {
    return {
        list: selectors.getState(state, 'list'),
        pagination: selectors.getState(state, 'pagination', {}),
        loading: selectors.getState(state, 'loading', false)
    };
};

const mapDispatchToProps = (dispatch) => ({
    getNotes: () => dispatch(actions.getNotes())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListTracking);
