import React, { Component } from 'react'
import { Select } from 'antd'
import lodash from 'lodash'
import PropTypes from 'prop-types'

import apiService from './../../Agencies/apiService'
import { t } from './../../../system/i18n'

const { Option } = Select

class SelectAgency extends Component {
    state = {
        loading: false,
        agencies: [],
    }

    componentDidMount() {
        const { getDataWhenMounted } = this.props

        if (getDataWhenMounted) {
            this.fetchAgencies()
        }
    }

    fetchAgencies = () => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true })
            apiService
                .getAgencySuggest()
                .then(res => {
                    this.setState({
                        agencies: lodash.map(res.data.agencies, item => item.agency),
                    })

                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        })
    }

    render() {
        const { agencies, loading } = this.state
        const {
            allowClear,
            className,
            dropdownClassName,
            mode,
            optionFilterProp,
            placeholder,
            rules,
            disabled,
            value,
            onChange,
            showSearch = false,
        } = this.props
        const isMultipleMode = mode === 'multiple'

        return (
            <div>
                <Select
                    allowClear={allowClear}
                    className={className ? className : 'a-select a-select--w-100 _select_agency'}
                    mode={mode}
                    optionFilterProp={optionFilterProp}
                    placeholder={lodash.isNil(placeholder) ? t('agency:placeholder.choose_agency') : placeholder}
                    rules={rules}
                    value={value && !loading ? (isMultipleMode ? value : parseInt(value, 0)) : undefined}
                    disabled={disabled || loading}
                    dropdownClassName={dropdownClassName || '_dropdown-agency'}
                    showSearch={showSearch}
                    loading={loading}
                    onChange={onChange}>
                    {lodash.map(agencies, (agency, index) => (
                        <Option
                            className="_agency-item"
                            key={index}
                            value={agency.id}>
                            {agency.name}
                        </Option>
                    ))}
                </Select>
            </div>
        )
    }
}

SelectAgency.defaultProps = {
    getDataWhenMounted: true,
}

SelectAgency.propTypes = {
    getDataWhenMounted: PropTypes.bool,
}

export default SelectAgency
