import { takeLatest } from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(ACTION.UPDATE.REQUEST, saga.update);
    yield takeLatest(ACTION.UPDATE.SUCCESS, saga.successUpdate);
    yield takeLatest(ACTION.UPDATE.FAILED, saga.failedUpdate);
}
