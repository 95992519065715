import { Input, Modal, Table } from 'antd'
import { filter, get, map, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../system/i18n'
import apiServices from '../../TemplateBagLastmile/apiServices'

const { Search } = Input

const PopupSelectTemplateBag = ({ onCancel, onSelectTemplateBag }) => {
    const [bagTemplates, setBagTemplates] = useState([])
    const [loading, setLoading] = useState(false)
    const [label, setLabel] = useState('')

    useEffect(() => {
        fetchTemplateBags({})
    }, [])

    const fetchTemplateBags = params => {
        setLoading(true)
        apiServices
            .fetchTemplateBagsLastmile(params)
            .then(res => {
                setBagTemplates(filter(get(res, 'data.items', []), 'active'))
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const onSearch = label => {
        fetchTemplateBags({ label })
    }

    const onChangeSearch = e => {
        setLabel(e.target.value)
    }

    const onClickRow = record => {
        onSelectTemplateBag(record);
    }

    const columns = [
        {
            title: t('template_bag:columns.name'),
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => {
                return {
                    props: {
                        className: `a-notification-dot a-notification-dot--table ${!!get(record, 'active') ? 'active' : 'unactive'}`,
                    },
                    children: <span>{text}</span>,
                }
            },
        },
        {
            title: t('bag:label.customer'),
            dataIndex: 'customer',
            key: 'customer',
            render: text => (
                <span className={`customer customer-${text}`}>
                    {' '}
                    {get(text, 'full_name', '')} - {get(text, 'username', '')}
                </span>
            ),
        },
        {
            title: t('bag:label.id_lastmile_shipping_partner'),
            dataIndex: ['lastmile_shipping_partner', 'name'],
            key: 'lastmile_shipping_partner',
            render: text => <span className={`lastmile_shipping_partner lastmile_shipping_partner-${text}`}>{text}</span>,
        },
        {
            title: t('common:agency'),
            dataIndex: ['agency', 'name'],
            key: 'agency',
            render: text => <span className={`_agencies _agencies-${text}`}>{text}</span>,
        },
    ]

    return (
        <Modal
            visible={true}
            width={680}
            closable={false}
            onCancel={onCancel}
            className="a-modal a-modal--choose-template-bag"
            footer={null}
            centered={true}>
            <div className="choose-template-bag--title">
                <span className="title">{t('bag:label.choose_template_bag_lastmile')}</span>
                <Search
                    placeholder={t('bag:label.input_template_bag')}
                    className="a-input--search-template-bag"
                    onSearch={onSearch}
                    onChange={onChangeSearch}
                />
            </div>
            <div className="body">
                <Table
                    className={'a-table--antd'}
                    columns={columns}
                    dataSource={bagTemplates}
                    loading={loading}
                    rowClassName="a-text--cursor-pointer"
                    rowKey={row => get(row, 'bag_template.id', uniqueId())}
                    onRow={record => {
                        return {
                            onClick: ()=>onClickRow(record),
                        }
                    }}
                    pagination={false}
                />
            </div>
        </Modal>
    )
}

export default PopupSelectTemplateBag
