import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Collapse, Tag} from 'antd';
import lodash from 'lodash';
import FormPackageDetail from '../../FormPackageDetail/FormPackageDetailContainer';
import LinkOrderPackage from './LinkOrderPackageCreate';
import FormNotMapOrderPackageContainer from "../../FormNotMapOrderPackage/FormNotMapOrderPackageContainer";

import Link from './../../../../system/routing/Link';

const {Panel} = Collapse;

class PackageCollapse extends React.Component {
    render() {
        const {t} = this.props;
        const packageData = this.props.package;
        const destination_warehouse = lodash.get(packageData, 'destination_warehouse.code');
        return (
            <Collapse
                className={`a-collapse--package box-collapse--package-${this.props.index}`}
                bordered={false}
                activeKey={!packageData.weight_net ? packageData.type : (this.props.isOpen ? packageData.code : null)}
                onChange={this.props.onToggle}
            >
                <div
                    className="a-collapse--package__block-absolute-heading">
                    {this.renderPanelHeading(packageData)}
                </div>
                <Panel
                    key={!packageData.weight_net  ? packageData.type : packageData.code}
                    header={(
                        <div className="a-collapse--package__title ">
                            <span className="a-text--bold _warehouse_input_code">
                                {lodash.get(packageData, 'warehouse_input.code')}
                            </span>
                            {destination_warehouse ? <><span> |</span> <span
                                className="a-text--bold _destination_warehouse_code">{destination_warehouse}</span></> : null}
                            <span> |</span> <Link
                            className="a-text--white _code_package_collapse" isNewTab to="packages.detail"
                            params={{id: packageData.code}}> {packageData.type === "ALONE" && packageData.barcode === "" ? t("package:message.create_package_alone") : packageData.code}</Link>
                            {!!(packageData.order && (packageData.order.ref_operation_code || !lodash.isEmpty(packageData.order.operation_partner))) && (
                                <Tag className="ml-3">
                                    {[packageData.order.ref_operation_code, (!lodash.isEmpty(packageData.order.operation_partner) ? packageData.order.operation_partner.code : undefined)].filter(text => !!text).join(' - ')}
                                </Tag>
                            )}
                        </div>

                    )}
                    className="a-collapse--package__item"
                >{this.renderPanelBody(packageData)}</Panel>
            </Collapse>
        );
    }

    renderPanelHeading(packageData) {
        const {t, isOpen} = this.props;
        if (packageData.order) {
            return (
                <div className="info-matching_code _info_matching_code">
                    {t('package:label.order_matching_code')} - <span
                    className="_code_package_matching"> <a className="a-text--white" href={packageData.order.m1_url}
                                                           rel="noopener noreferrer"
                                                           target="_blank">{packageData.order.code}</a></span>
                </div>
            );
        }
        // Nếu ở page tạo mới kiện hoặc update kiện
        if (packageData.id || packageData.current_page) {
            return (
                <LinkOrderPackage
                    package={packageData}
                    onFocus={() => {
                        if (!isOpen) {
                            this.props.onToggle();
                        }
                    }}
                    fetchBarcodePackages={this.props.fetchBarcodePackages}
                    filter={this.props.filter}
                    className="a-input--matching-code--large"
                />
            );
        }

        return null;
    }

    fetchBarcodePackagesAgain(params) {
        this.props.fetchBarcodePackagesAgain({...this.props.filter, ...params})
    }

    /**
     * @param packageData
     * @returns {XML}
     */
    renderPanelBody(packageData) {
        return lodash.isNil(packageData.type) || packageData.type === '' || packageData.id === 0 || lodash.isNil(packageData.id) ?
            (
                <FormNotMapOrderPackageContainer packageData={packageData}/>
            ) :
            (
                <div className="a-collapse--package__body is_form pb-0 ">
                    <FormPackageDetail
                        fetchBarcodePackagesAgain={(params) => this.fetchBarcodePackagesAgain(params)}
                        package={packageData}
                    />
                </div>
            );

    }
}

PackageCollapse.defaultProps = {
    package: {},
    isOpen: false,
    onToggle: () => {
    },
};

PackageCollapse.propTypes = {
    package: PropTypes.object,
    input: PropTypes.object,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default withTranslation()(PackageCollapse);
