import {put} from 'redux-saga/effects';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {setPackagePage} from '../CreatePackageViaScan/actions';
import lodash from "lodash";
import React from "react";
import authService from "../../Auth/authService";
import {UNAUTHORIZED} from "../../Auth/constants";

export function* updatePackage(action) {
    const data = {
        ...action.payload.data
    };

    const configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g');

    if (configPackageUnitWeight === 'kg') {
        data.weight_net = parseFloat(data.weight_net) * 10;
    }

    yield processApiRequest(
        ACTION.UPDATE_PACKAGE,
        () => apiService.updateRelatedPackage(action.payload.id, data),
        action.payload
    );
}

export function* onUpdatePackageSuccess(action) {
    yield notification.success({
        message: t('message.update_success')
    });
    yield put(setPackagePage(action.request.id));

    if (lodash.isFunction(lodash.get(action.request, 'cb.successHandler'))) {
        yield action.request.cb.successHandler(action);
    }
}

export function* onUpdatePackageFailed(action) {
    let error = lodash.get(action, 'payload.data');

    if (lodash.isFunction(lodash.get(action.request, 'cb.errorHandler'))) {
        yield action.request.cb.errorHandler(error);
    }

    if (lodash.get(error, 'warehouse')) {
        return yield notification['error']({
            message: <span>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
    if (lodash.get(action, 'payload.code') === UNAUTHORIZED) {
        return yield notification['error']({
            message: <span>{t('package:message.package_not_update_permission')}</span>,
        });
    }

    return yield notification['error']({
        message: t('message.update_failed'),
    });
}
