import React, { PureComponent } from 'react';
import { Button, Icon, Input, Layout, Row } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ELEMENT } from './../../CreatePackageViaScan/services/inputFocus/constants';
import PERMISSION from './../../../Auth/permissions';
import ElementManager from './../../../Common/services/ElementManager';
import { MAX_WAREHOUSE } from './../../../Warehouse/constants';

import ChooseWarehouse from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';

const { Sider } = Layout;

class FormScanBarcode extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            barcode: '',
            warehouseId: 0,
        };

        this.elements = new ElementManager();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedWarehouse !== 0 && state.warehouseId === 0) {
            return {
                warehouseId: props.selectedWarehouse
            };
        }

        return null;
    }

    componentDidMount() {
        const { warehouses, getListWarehouse } = this.props;

        if (Array.isArray(warehouses) && warehouses.length === 0) {
            getListWarehouse();
        }

        this.handleFocusInput();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputFocusId !== this.props.inputFocusId) {
            this.handleFocusInput();
        }
    }

    handleFocusInput = () => {
        let element = this.props.inputFocus;

        if (element === ELEMENT.BARCODE) {
            this.elements.focus(element);
        }
    };

    /**
     * handle action select warehouse
     * @param warehouseId
     */
    selectWarehouse = ({ id_warehouse }) => {
        this.props.setSelectedWarehouse(id_warehouse);
        this.setState({
            warehouseId: id_warehouse
        });
    };

    submitForm = () => {
        const { barcode, warehouseId } = this.state;

        if (barcode !== '' && warehouseId !== 0) {
            const data = {
                barcode,
                id_warehouse: warehouseId
            };

            this.props.submitForm({
                data
            });

            this.setState({
                barcode: ''
            });
        }
    };

    /**
     * handle action change
     * @param event
     */
    changeCode = event => {
        this.setState({
            barcode: event.target.value
        });
    };

    beginCreateAlonePackage = () => {
        const { warehouseId } = this.state;

        const data = {
            barcode: '',
            id_warehouse: warehouseId,
            is_alone: 1
        };

        this.props.submitForm({
            data
        });
        this.props.beginCreateAlonePackage(warehouseId);
    };

    render() {
        const { barcode, warehouseId } = this.state;
        const { isLoadingPackages, permissions, scroll, t, warehouses } = this.props;

        const maxWarehouse = MAX_WAREHOUSE;
        const disabled_input = get(isLoadingPackages, 'createPackageByScan', false) ||
            isNil(localStorage.getItem('selectedWarehouse')) ||
            isNaN(localStorage.getItem('selectedWarehouse')) ||
            localStorage.getItem('selectedWarehouse') === 'NaN';

        return (
            <Sider
                className={clsx('a-sider-sub', {
                    scroll
                })}
            >
                <div className="a-btn--action-sider active">{t('package:label.create_scan')}</div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">{t('package:label.action_operate')}</h4>
                    <Row
                        gutter={8}
                        className={clsx('a-form--group-button--warehouse', {
                            'a-form--group-button--select': warehouses.length > maxWarehouse
                        })}
                    >
                        <ChooseWarehouse
                            value={warehouseId}
                            isPermission={true}
                            active={true}
                            onChange={id_warehouse => this.selectWarehouse({id_warehouse})}
                        />
                    </Row>
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">{t('package:create.scan')}</h4>
                    <div className="a-form--loading loading-input">
                        <Input
                            className={clsx('a-input--scan-code _scancode', {
                                'a-btn--disable': get(isLoadingPackages, 'createPackageByScan', false)
                            })}
                            disabled={disabled_input}
                            ref={this.elements.set.bind(this.elements, ELEMENT.BARCODE)}
                            value={barcode}
                            onChange={this.changeCode}
                            onPressEnter={this.submitForm}
                        />
                        {get(isLoadingPackages, 'createPackageByScan', false) ? (
                            <Icon type="loading-3-quarters" className="icon-loading" />
                        ) : null}
                    </div>
                </div>
                {permissions.includes(PERMISSION.PACKAGE_CREATE_ALONE) === true && (
                    <Button
                        className="a-btn a-btn--primary a-btn--create-independence-package _create-independence-package hidden"
                        disabled={parseInt(warehouseId) === 0 || isNaN(warehouseId)}
                        type="primary"
                        onClick={this.beginCreateAlonePackage}
                    >
                        {t('package:btn.create_alone_package')}
                    </Button>
                )}
            </Sider>
        );
    }
}


FormScanBarcode.defaultProps = {
    warehouses: [],
    selectedWarehouse: 0,
    isLoadingPackages: false
};

FormScanBarcode.propTypes = {
    warehouses: PropTypes.array,
    permissions: PropTypes.array,
    selectedWarehouse: PropTypes.number,
    isLoadingPackages: PropTypes.bool,
    beginCreateAlonePackage: PropTypes.func
};


export default withTranslation()(FormScanBarcode);
