import lodash from 'lodash';
import {transformListPackageBag} from "../BagPacking/selectors";
import {createSelector} from "reselect";
import * as propertySelectors from "./../../Property/selectors";

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'bag.bagDetail' + (param ? '.' + param : ''), defaultValue);


export const getPackageData = createSelector(
    state => getState(state, "packages", []),
    state => propertySelectors.getState(state, 'list'),
    (bagPackingDatas, properties) => transformListPackageBag(bagPackingDatas, properties)
);

export const getLoading = (state) => {
    return getState(state, 'loading', false);
};
