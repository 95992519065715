import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';
import {setCollectionItem} from '../../Common/services/helps';

let packages = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_PACKAGE.REQUEST:
            return setCollectionItem(state, payload.id, {loading: true, error: null});

        case ACTION.UPDATE_PACKAGE.SUCCESS:
            return setCollectionItem(state, action.request.id, {loading: false});

        case ACTION.UPDATE_PACKAGE.FAILED:
            return setCollectionItem(state, action.request.id, {loading: false, error: payload});

        case ACTION.RESET_ERROR:
            return setCollectionItem(state, payload.id, packageData => {
                let error = packageData.error;

                if (!error) {
                    return packageData;
                }

                return {
                    ...packageData,
                    error: {...error, data: lodash.omit(error.data, payload.params)}
                }
            });

        case ACTION.CLEAR_STATE:
            return state.filter(packageData => packageData.id !== payload.id);

        default:
            return state;
    }
};

export default combineReducers({
    packages,
});
