import {processApiRequest} from "../../Common/saga";
import * as ACTION from "./constants";
import apiService from "./apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import * as lodash from "lodash";
import React from "react";


export function* scanPackage(action) {
	yield processApiRequest(ACTION.SCAN_PACKAGE, () => apiService.scanPackage(action.payload), action.payload);
}

export function* scanPackageFailed(action) {
	const error = lodash.get(action, 'payload.data');

	if (lodash.get(error, 'warehouse')) {
		return yield notification.error({
			message: <span>{t('message.warehouse_not_is_staff')}</span>
		});
	}

	if (lodash.get(action, 'payload.data.id_inventory.status_invalid')) {
		return yield notification.error({
			message: t('inventory:message.error_scan_package_by_status_inventory_invalid')
		});
	}

	if (lodash.get(error, "barcode.message")) {
		const message = lodash.get(error, "barcode.message");
		const packages = lodash.get(error, "barcode.packages")
		return yield notification.error({
			message: t(`scan:error.${message}`, {packages: packages.join(", "), count: packages.length})
		});
	}

	if (action.status_code !== 403) {
		return yield notification.error({
			message: t('inventory:message.scan_inventory_failed')
		});
	}
}

export function* scanTrackingBill(action) {
	yield processApiRequest(ACTION.SCAN_TRACKING_BILL, () => apiService.scanTrackingBill(action.payload), action.payload);
}

export function* scanTrackingBillFailed(action) {
	const error = lodash.get(action, 'payload.data');

	if (lodash.get(error, 'warehouse')) {
		return yield notification.error({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}

	if (lodash.get(action, 'payload.data.id_inventory.status_invalid')) {
		return yield notification.error({
			message: t('inventory:message.error_scan_package_by_status_inventory_invalid'),
		});
	}

	if (action.status_code !== 403) {
		return yield notification.error({
			message: t('inventory:message.scan_inventory_failed')
		});
	}
}
