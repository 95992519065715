import React, { useCallback, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';

import { useDeepCompareEffect } from './../../../../../hooks';

const { Search } = Input;

const SearchForm = props => {
    const [filter, setFilter] = useState({});
    const delayedOnSearch = useCallback(debounce(props.onSearch, 350), [
        props.onSearch
    ]);

    const handleSearch = filter => {
        delayedOnSearch({
            ...filter,
            keyword: filter.keyword && filter.keyword.trim()
        });
    };

    const handleChange = (name, event) => {
        const value = event.target.value;

        const newFilter = {
            ...filter,
            [name]: value
        };

        setFilter(newFilter);
        handleSearch(newFilter);
    };

    useDeepCompareEffect(() => {
        setFilter(props.filter || {});
    }, [props.filter]);

    return (
        <Form
            className="a-content--search-header search-default pt-0 px-0 mb-0 mr-0 _search-form"
        >
            <Row gutter={{lg: 12, xl: 24}}>
                <Col span={24} lg={{span: 8}}>
                    <Form.Item>
                        <Search
                            allowClear
                            className="_keyword"
                            placeholder={props.t('shipping_partner:placeholder.search')}
                            value={filter.keyword}
                            onChange={handleChange.bind(this, 'keyword')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default withTranslation()(SearchForm);
