import React, { Component } from 'react';
import { Layout } from 'antd';
import clsx from 'clsx';

import FormDeliveryNote from './../../Create/components/FormDeliveryNote';
import FormTransportWarehouse from './../../Create/components/FormTransportWarehouseContainer';

class UpdateDeliveryNote extends Component {
    render () {
        const { bags, packages, deliveryNote, input, loading, scroll, clearBarcodes, getInput, onChangeInput, onScanBarcode, removeBarcodeByIndex  } = this.props;

        return (
            <Layout className={clsx({
                'a-scroll--is-sider': scroll
            })}>
                <FormDeliveryNote
                    input={{...getInput(), ...input}}
                    onChange={onChangeInput}
                    scanBarcode={onScanBarcode}
                    loading={loading}
                />
                <FormTransportWarehouse
                    bags={bags}
                    packages={packages}
                    deliveryNote={deliveryNote}
                    input={{...getInput(), ...input}}
                    loading={loading}
                    removeBarcode={removeBarcodeByIndex}
                    clearBarcodes={clearBarcodes}
                />
            </Layout>
        )
    }
}

export default UpdateDeliveryNote;
