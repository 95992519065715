import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let deliveryNotes = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_NOTES.SUCCESS:
            return action.payload.delivery_notes.map(item => ({...item.delivery_note, ...lodash.omit(item, 'delivery_note')}));
        case ACTION.RESET_LIST_DELIVERY_NOTE:
            return [];
        default:
            return state;
    }
};

let pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_NOTES.SUCCESS:
            return action.payload.pagination;
        case ACTION.RESET_LIST_DELIVERY_NOTE:
            return {};
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_NOTES.REQUEST:
            return true;
        case ACTION.RESET_LIST_DELIVERY_NOTE:
        case ACTION.FETCH_DELIVERY_NOTES.SUCCESS:
        case ACTION.FETCH_DELIVERY_NOTES.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    deliveryNotes,
    pagination,
    loading,
});
