import { combineReducers } from 'redux';

import { CLEAR_STATE, GET_DETAIL, SET_DETAIL } from './constants';

const deliveryNoteReturn = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_DETAIL.REQUEST:
        case GET_DETAIL.FAILED:
        case CLEAR_STATE: {
            return {};
        }
        case GET_DETAIL.SUCCESS:
        case SET_DETAIL: {
            return payload;
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case GET_DETAIL.REQUEST: {
            return true;
        }
        case GET_DETAIL.SUCCESS:
        case GET_DETAIL.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    deliveryNoteReturn,
    loading
});
