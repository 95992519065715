import React, { Component } from 'react';
import { Layout, Table } from 'antd';
import { debounce, isEqual, omit, snakeCase } from 'lodash';

import NoticeStageActionsSelectContainer from './../../../Common/components/NoticeStageActionsSelect/NoticeStageActionsSelectContainer';
import FormMultiProperties from './../../../Property/FormMultiProperties/FormMultiPropertiesContainer';
import FormMultiConsignServices from './../../../Service/FormMultiConsignServices/FormMultiConsignServicesContainer';
import FormMultiServices from './../../../Service/FormMultiServices/FormMultiServicesContainer';

class NoticeStages extends Component {
    state = {
        actions: {},
        properties: {},
        services: {},
        consignServices: {}
    };

    handleChange = (index, name, value) => {
        this.setState(prevState => {
            const { noticeStages } = this.props;
            const newValue = {
                ...prevState[name],
                [index]: value
            };

            this.doEmitOnChange({
                ...noticeStages[index],
                [snakeCase(name)]: newValue[index]
            });

            return {
                [name]: newValue
            };
        });
    };

    emitOnChange = data => {
        const { onChange } = this.props;
        const formattedData = omit({
            ...data,
            services: data.services.concat(data.consign_services)
        }, ['consign_services']);

        onChange(formattedData);
    };

    doEmitOnChange = debounce(this.emitOnChange, 500);

    handleChangeNoticeStages = noticeStages => {
        const actions = {};
        const properties = {};
        const services = {};
        const consignServices = {};

        for (let i = 0; i < noticeStages.length; i++) {
            actions[i] = noticeStages[i].actions;
            properties[i] = noticeStages[i].properties;
            services[i] = noticeStages[i].services;
            consignServices[i] = noticeStages[i].consign_services;
        };

        this.setState({
            actions,
            properties,
            services,
            consignServices
        });
    };

    componentDidUpdate(prevProps) {
        const { noticeStages } = this.props;

        if (!isEqual(noticeStages, prevProps.noticeStages)) {
            this.handleChangeNoticeStages(noticeStages);
        }
    }

    componentDidMount() {
        const { noticeStages, getActions, getNoticeStages, getPropertySuggests, setMenuActive } = this.props;

        setMenuActive('config/notice-stages');
        getActions();
        getNoticeStages();
        getPropertySuggests();

        this.handleChangeNoticeStages(noticeStages);
    }

    render() {
        const { actions, consignServices, properties, services } = this.state;
        const { loading, noticeStages, t } = this.props;

        return (
            <Layout className="aml-24 aml-16-lg a-content-config height-100 gflex gflex-direction-column">
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>{t('notice_stage')}</h3>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list height-100">
                    <Table
                        columns={[{
                            className: 'a-text--nowrap',
                            dataIndex: 'name',
                            key: 'name',
                            title: t('notice_stage')
                        }, {
                            dataIndex: 'services',
                            key: 'services',
                            title: t('service'),
                            render: (text, row, index) => (
                                <FormMultiServices
                                    className="width-100-pc _services"
                                    dropdownClassName="_services-dropdown"
                                    placeholder={t('select_service')}
                                    values={services[index]}
                                    onChange={this.handleChange.bind(undefined, index, 'services')}
                                />
                            ),
                            width: 250
                        }, {
                            dataIndex: 'consign_service',
                            key: 'consign-services',
                            title: t('consign_service'),
                            render: (text, row, index) => (
                                <FormMultiConsignServices
                                    className="width-100-pc _consign-services"
                                    dropdownClassName="_consign-services-dropdown"
                                    placeholder={t('select_service')}
                                    values={consignServices[index]}
                                    onChange={this.handleChange.bind(undefined, index, 'consignServices')}
                                />
                            ),
                            width: 250
                        }, {
                            dataIndex: 'properties',
                            key: 'properties',
                            title: t('property'),
                            render: (text, row, index) => (
                                <FormMultiProperties
                                    className="width-100-pc _properties"
                                    dropdownClassName="_properties-dropdown"
                                    placeholder={t('select_property')}
                                    values={properties[index]}
                                    onChange={this.handleChange.bind(undefined, index, 'properties')}
                                />
                            ),
                            width: 250
                        }, {
                            dataIndex: 'actions',
                            key: 'actions',
                            title: t('action'),
                            render: (text, row, index) => (
                                <NoticeStageActionsSelectContainer
                                    className="width-100-pc _actions"
                                    dropdownClassName="_actions-dropdown"
                                    mode="multiple"
                                    noticeStageId={row.id}
                                    optionFilterProp="children"
                                    showSearch
                                    value={actions[index]}
                                    onChange={this.handleChange.bind(undefined, index, 'actions')}
                                />
                            ),
                            width: 250
                        }]}
                        dataSource={noticeStages}
                        loading={loading}
                        pagination={false}
                        rowKey={row => row.id}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>
            </Layout>
        );
    }
}

export default NoticeStages;
