import React, { PureComponent } from 'react';
import { Col, Form, Row, Select } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { TRANSPORT_STATUS } from './../../../constants';
import { isAlonePackage, isLinkOrder } from './../../../helper';
import authService from './../../../../Auth/authService';
import PERMISSION from './../../../../Auth/permissions';

import LinkOrderPackage from './../../../LinkOrderPackage/LinkOrderPackageContainer';
import FormPropertiesContainer from './../../../../Property/FormProperties/FormPropertiesContainer';
import SelectAgency from './../../../../Setting/Agency/SelectAgency';

const { Option } = Select;

class PackageInfomation extends PureComponent {
    state = {
        idPackage: 0,
        status_transport: '',
        properties: [],
        id_agency: this.props.packageData.id_agency
    };

    static getDerivedStateFromProps(props, state) {
        const nextState = {...state};
        const { packageData } = props;

        if (get(props, 'packageData.id', 0) !== state.idPackage) {
            nextState.idPackage = packageData.id;
            nextState.properties = map(packageData.properties || [], 'id');
            nextState.status_transport = packageData.status_transport;
        }

        return nextState;
    }

    onChangeProperty(value) {
        this.setState({
            properties: value
        }, () => {
            this.props.onSubmit(this.state)
        });
    }

    changeTextValue(event) {
        let {name, value} = event.target;
        this.setState({[name]: value});
        this.props.onSubmit({...this.state, [name]: value});
        this.props.resetError(name);
    }

    changeSelectValue(name, value) {
        const { onSubmit, resetError } = this.props;
        const newState = {
            [name]: value
        };

        this.setState(newState, () => {
            onSubmit(this.state);
        });
        resetError(name);
    }

    handleChangeStatusTransport = value => {
        const { packageData, updateTransportStatus } = this.props;
        this.changeSelectValue('status_transport', value);

        if (!value) {
            updateTransportStatus({
                code: get(packageData, 'code'),
                status_transport: value
            });
        }
    };

    onSelectOrder = (orderItem) => {
        this.setState({id_agency: get(orderItem, 'order.id_agency')});
        this.props.onSelectOrder(orderItem)
    };

    componentDidUpdate(prevProps) {
        const { packageData } = this.props;

        if (get(packageData, 'status_transport') !== get(prevProps.packageData, 'status_transport')) {
            this.setState({
                status_transport: get(packageData, 'status_transport')
            });
        }
    }

    render() {
        const { id_agency } = this.state;
        const { errors, packageData, t } = this.props;
        const user = authService.user();
        const userPermissions = get(user, 'permissions', []) || [];
        // Check current_page === 'UpdateAlonePackage' ko có nghĩa đây là kiện độc lập nha, phải cẩn thận
        const canLinkOrder = (!!get(packageData, "permissions.can_link_order", false) === true
            && packageData.current_page === "UpdateAlonePackage") || (isAlonePackage(packageData) && includes(userPermissions, PERMISSION.PACKAGE_LINK_ORDER));
        const canUpdateTransportStatus = !!get(packageData, 'permissions.can_update_transport_status', false);

        return get(packageData, 'permissions.can_update') ? (
            <div className="a-block-info bg-gray info--detail info-form-package apl-16 apr-16">
                <div className="info--title">
                    <h4>{t("package:label.package_information")}</h4>
                </div>
                <Row gutter={{lg: 24, xl: 32}}>
                    <Col className="a-form--group" lg={{span: 7}}>
                        <Form.Item
                            label={t("package:label.choose_agency")}
                            className="a-form__item _agency-form-item"
                        >
                            <SelectAgency
                                disabled={isLinkOrder(packageData)}
                                placeholder={t("delivery_request:placeholder.select_agency")}
                                value={id_agency}
                                onChange={this.changeSelectValue.bind(this, 'id_agency')}
                            />
                        </Form.Item>
                    </Col>
                    {canLinkOrder && (
                        <Col className="a-form--group" lg={{span: 24}} xl={{span: 7}} xxl={{span: 5}} >
                            <Form.Item
                                className="a-form__item _order-form-item"
                                label={t("package:label.order")}
                                help={errors.order}
                                validateStatus={errors.order ? 'error' : null}
                                labelCol={{span: 24}}>
                                <LinkOrderPackage
                                    package={packageData}
                                    canLink={Boolean(canLinkOrder && (!isAlonePackage(packageData) || (isAlonePackage(packageData))))}
                                    forceCanLink={true}
                                    onFocus={() => {}}
                                    onSelectOrder={this.onSelectOrder}
                                    onChangeOrder={this.props.onChangeOrder}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={{lg: 24, xl: 32}}>
                    {packageData.current_page === 'UpdateAlonePackage' ? (
                        <Col lg={{span: 24}} xl={{span: 7}} xxl={{span: 5}} className="a-form--group clear-both">
                            <Form.Item
                                className="a-form__item"
                                label={t('package:label.status_transport')}
                                help={errors.status_transport}
                                validateStatus={errors.status_transport ? 'error' : null}
                                labelCol={{span: 24}}>
                                <Select
                                    className="a-select a-select--w-100 _status-transport"
                                    dropdownClassName="_dropdown-status-transport"
                                    name="status_transport"
                                    optionFilterProp="children"
                                    showSearch
                                    value={this.state.status_transport}
                                    onChange={this.handleChangeStatusTransport}
                                >
                                    {canUpdateTransportStatus && (
                                        <Option className="_status-transport-item a-text--green" value="">
                                            {t(`package:status_transport.${TRANSPORT_STATUS.CIRCULATE}`)}
                                        </Option>
                                    )}
                                    <Option className="_status-transport-item" value={packageData.status_transport}>
                                        {t(`package:status_transport.${packageData.status_transport}`)}
                                    </Option>
                                    {packageData.enable_transport_status.map(state => (
                                        <Option
                                            className={clsx('_status-transport-item', {
                                                'a-text--red': !state.apply_shipping_fee
                                            })}
                                            key={state.status}
                                            value={state.status}
                                        >
                                            {t(`package:status_transport.${state.status}`)
                                        }</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col className="a-form--group" span={24}>
                        <Form.Item
                            className="a-form__item"
                            label={t('package:label.properties')}
                            labelCol={{span: 24}}
                            help={errors.properties}
                            validateStatus={errors.properties ? 'error' : null}
                        >
                            <FormPropertiesContainer
                                onChange={this.onChangeProperty.bind(this)}
                                values={this.state.properties}
                                classUl="property--package _property_package"
                                disabled={!isNil(packageData.order_code)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        ) : null;
    }
}

PackageInfomation.defaultProps = {
    packageData: {},
    loading: false,
    errors: {},
    onSelectOrder: () => {},
    onChangeOrder: () => {}
};

PackageInfomation.propTypes = {
    packageData: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    onSubmit: PropTypes.func,
    resetError: PropTypes.func,
};

export default Form.create({})(withTranslation()(PackageInfomation));
