import { first, get } from 'lodash';
import { put } from 'redux-saga/effects';

import { fetchProvinces } from './actions';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../../system/i18n';

export function* fetchLocation(action) {
    if (action.payload.type === 'country') {
        yield processApiRequest(ACTION.FETCH_LIST_COUNTRY, () => apiService.getChildren(action.payload.id), action);
    }
    if (action.payload.type === 'province') {
        yield processApiRequest(ACTION.FETCH_LIST_PROVINCE, () => apiService.getChildren(action.payload.id), action);
    }
    if (action.payload.type === 'district') {
        yield processApiRequest(ACTION.FETCH_LIST_DISTRICT, () => apiService.getChildren(action.payload.id), action);
    }
    if (action.payload.type === 'ward') {
        yield processApiRequest(ACTION.FETCH_LIST_WARD, () => apiService.getChildren(action.payload.id), action);
    }
}

export function* successFetchCountryList(action) {
    const { payload, request } = action;

    if (get(request, 'payload.autoloadChildren')) {
        const firstLocation = first(payload.locations);

        yield put(fetchProvinces(get(firstLocation, 'location.id'), 'province'));
    }

    yield;
}

/**
 * Xử lý trường hợp fetch location fail
 */
export function* fetchLocationFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}
