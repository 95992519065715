import { combineReducers } from 'redux';
import { SCAN_BARCODE_FORM, SCAN_RECEIVED } from './constants';
import lodash from "lodash";

const isLoading = (state = false, action) => {
    switch (action.type) {
        case SCAN_BARCODE_FORM.REQUEST:
        case SCAN_RECEIVED.REQUEST: {
            return true;
        }
        case SCAN_BARCODE_FORM.SUCCESS:
        case SCAN_BARCODE_FORM.FAILED:
        case SCAN_RECEIVED.SUCCESS:
        case SCAN_RECEIVED.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const barcodeScanData =  (state = {}, action) => {
    switch (action.type) {
        case SCAN_BARCODE_FORM.REQUEST:
        case SCAN_RECEIVED.REQUEST:
        case SCAN_BARCODE_FORM.FAILED:
        case SCAN_RECEIVED.FAILED:{
            return {};
        }
        case SCAN_BARCODE_FORM.SUCCESS:
        case SCAN_RECEIVED.SUCCESS: {
            return lodash.get(action, 'payload.barcode_scanning', {});
        }
        default: {
            return state;
        }
    }
};

const barcodeScanDataPackage =  (state = {}, action) => {
    switch (action.type) {
        case SCAN_BARCODE_FORM.REQUEST:
        case SCAN_RECEIVED.REQUEST:
        case SCAN_BARCODE_FORM.FAILED:
        case SCAN_RECEIVED.FAILED:{
            return {};
        }
        case SCAN_BARCODE_FORM.SUCCESS:
        case SCAN_RECEIVED.SUCCESS: {
            return lodash.get(action, 'payload.package', {});
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    isLoading,
    barcodeScanData,
    barcodeScanDataPackage
});
