import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchConsignServiceGroups } from './../modules/Service/actions';
import { getActiveConsignServiceGroups, getState } from './../modules/Service/selectors';

const mapStateToProps = state => {
    return {
        consignServiceGroups: getActiveConsignServiceGroups(state),
        loadingConsignServiceGroups: getState(state, 'loadingConsignServiceGroups')
    };
};

const mapDispatchToProps = {
    fetchConsignServiceGroups
};

const withConsignServiceGroups = WrappedComponent => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(class extends Component {
        getConsignServiceGroups = () => {
            const { consignServiceGroups, loadingConsignServiceGroups, fetchConsignServiceGroups } = this.props;

            if (loadingConsignServiceGroups || (consignServiceGroups && consignServiceGroups.length)) {
                return;
            }

            fetchConsignServiceGroups();
        };

        componentDidMount() {
            this.getConsignServiceGroups();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    });
};

export default withConsignServiceGroups;
