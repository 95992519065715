import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Icon, Modal} from 'antd';

class DeletePackage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenDeleteConfirm: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.isOpenDeleteConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenDeleteConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmDelete = event => {
        event.preventDefault();

        const { t, beginDeletePackage } = this.props;

        Modal.confirm({
            title: t('package:label.warning'),
            content: <p>{t('bag:message.warning_confirm_delete')}</p>,
            okText: t('package:label.ok'),
            cancelText: t('package:label.cancel'),
            onOk: this.handleOnOk,
            onClose: this.handleOnClose,
            icon: <Icon type="question-circle" theme="filled" />
        });

        beginDeletePackage();
    };

    /**
     * handle action click ok to unlink order
     */
    handleOnOk = () => {
        const { bagId, packageData, page, onDeletePackage, onRemovePackage } = this.props;
        
        this.setState({
            isOpenDeleteConfirm: true
        });

        if (page === 'detail') {
            onRemovePackage(bagId, packageData.id);
        } else {
            onDeletePackage(bagId, packageData.id);
        }
    };

    /**
     * handle action close modal
     */
    handleOnClose = () => {
        this.props.endDeletePackage();
    }

    render() {
        const { loading, t, type } = this.props;

        return type === 'text' ? (
            <a
                className="a-text--nowrap a-action-hover-show _icon_confirm_delete"
                href="/"
                onClick={this.confirmDelete}
            >
                {t('bag:label.delete')}
            </a>
        ) : (
            <Icon
                className="_icon_close"
                type={loading ? 'loading' : 'close'}
                onClick={this.confirmDelete}
            />
        );
    }
}

DeletePackage.defaultProps = {
    packageData: {},
    isModalProcessing: false,
    loading: false,
    type: 'icon',
    onRemovePackage: () => {}
};

DeletePackage.propTypes = {
    packageData: PropTypes.object,
    onDeletePackage: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginDeletePackage: PropTypes.func,
    endDeletePackage: PropTypes.func,
    type: PropTypes.string
};

export default withTranslation()(DeletePackage);
