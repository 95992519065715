import { api } from './../../system/api';

const BASE_URL = '/box-sizes';

export default {
    fetchSuggestBoxSizes: params => api.get(`${BASE_URL}/suggest`, {
        params
    }),
    getSuggestsByVolume: params => api.get(`${BASE_URL}/suggest-by-volume`, {
        params
    })
};
