import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, Form, Icon, Input, Layout, Row, Select, Switch } from 'antd';
import { filter, get, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;

class FormShippingUser extends Component {
    state = {
        id: 0,
        full_name: undefined,
        username: undefined,
        phone: undefined,
        note: undefined,
        active: true
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const shippingUserDetail = nextProps.shippingUserDetail;
        const nextState = {
            ...prevState
        };

        if (get(shippingUserDetail, 'shipping_user.id') && get(shippingUserDetail, 'shipping_user.id') !== prevState.id) {
            nextState.id = shippingUserDetail.shipping_user.id;
            nextState.full_name = shippingUserDetail.shipping_user.full_name;
            nextState.username = shippingUserDetail.shipping_user.username;
            nextState.phone = shippingUserDetail.shipping_user.phone;
            nextState.note = shippingUserDetail.shipping_user.note;
            nextState.active = shippingUserDetail.shipping_user.active;
        }

        return nextState;
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    componentDidMount() {
        const { match: { params: { id } }, getShippingUserId } = this.props;

        if (id) {
            getShippingUserId(id);
        }
    }

    onCancel = () => {
        this.props.history.goBack()
    };

    changeTextValue = event => {
        const { resetError } = this.props;
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });
        resetError(name);
    };

    changeActiveValue = checked => {
        this.setState({
            active: checked
        });
    };

    onSubmit = () => {
        const state = this.state;
        const { shippingUserDetail, createShippingUser, editShippingUser } = this.props;
        const { active, note } = state;
        let data = {};

        Object.keys(this.state).forEach(function(key) {
            if (state[key]){
                data[key] = state[key];
            }
        });

        data = {
            ...data,
            active: active ? 1 : 0
        }

        if (isEmpty(shippingUserDetail)){
            createShippingUser(data);
        } else {
            data = {
                ...data,
                note
            };

            editShippingUser(data);
        }
    };

    onChangeSelect = (name, value) => {
        const { users, resetError } = this.props;
        const user = filter(users, { username: value })[0];

        if (user) {
            this.setState({
                username: value,
                full_name: user.name,
                phone: user.phone
            });
        } else {
            this.setState({
                username: undefined,
                full_name: undefined,
                phone: undefined
            });
        }

        resetError(name);
    };

    render() {
        const { active, full_name, note, phone, username } = this.state;
        const { errors, loading, shippingUserDetail, t, users } = this.props;

        return (
            <Content className="aml-16-lg aml-24 amr-24 amr-16-lg a-content-config">
                <div className="a-breadcrumb">
                    <Link to="/config/shipping-users" className="a-text--gray _btn-back">
                        <Icon
                            className="mr-2"
                            type="arrow-left"
                        />
                        {t('shipping_user:label.back_shipping_user')}
                    </Link>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t('shipping_user:label.info')}</h3>
                        <div>
                            <label className="a-text--fz-12 a-text--gray mr-2">
                                {t('shipping_user:label.status')}
                            </label>
                            <Switch
                                checked={active}
                                className="_switch-active"
                                size="small"
                                onChange={this.changeActiveValue}
                            />
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <Form className="a-form" onSubmit={this.handleSubmit}>
                            <Row className="a-block-info" gutter={{lg: 24, xl: 32}}>
                                <Col className="a-form--group" lg={{span: 6}}>
                                    <Form.Item
                                        className="a-form__item"
                                        help={errors.username}
                                        label={t('shipping_user:label.username')}
                                        labelCol={{span: 24}}
                                        validateStatus={errors.username ? 'error' : null}
                                    >
                                        <Select
                                            allowClear
                                            className="a-select _a-select-user"
                                            disabled={!isEmpty(shippingUserDetail)}
                                            optionFilterProp="children"
                                            showSearch={true}
                                            value={username}
                                            onChange={this.onChangeSelect.bind(this, 'username')}
                                        >
                                            {users.map((user) => (
                                                <Option
                                                    className={`_user _user-${user.id}`}
                                                    key={user.id}
                                                    value={user.username}
                                                >
                                                    {user.username}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="a-form--group" lg={{span: 6}}>
                                    <Form.Item
                                        className="a-form__item"
                                        help={errors.full_name}
                                        label={t('shipping_user:label.full_name')}
                                        labelCol={{span: 24}}
                                        required={true}
                                        validateStatus={errors.full_name ? 'error' : null}
                                    >
                                        <Input
                                            className="a-input _a-input-full-name"
                                            disabled={isEmpty(shippingUserDetail) ? !isNil(username) : false}
                                            name="full_name"
                                            placeholder={t('shipping_user:placeholder.full_name')}
                                            value={full_name}
                                            onChange={this.changeTextValue}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="a-form--group" lg={{span: 6}}>
                                    <Form.Item
                                        className="a-form__item"
                                        help={errors.phone}
                                        label={t("shipping_user:label.phone")}
                                        labelCol={{span: 24}}
                                        required={true}
                                        validateStatus={errors.phone ? 'error' : null}
                                    >
                                        <Input
                                            className="a-input _a-input-phone"
                                            disabled={isEmpty(shippingUserDetail) ? !isNil(username) : false}
                                            name="phone"
                                            placeholder={t('shipping_user:placeholder.phone')}
                                            value={phone}
                                            onChange={this.changeTextValue}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="a-form--group" lg={{span: 24}}>
                                    <Form.Item
                                        className="a-form__item"
                                        label={t('label.note')}
                                        labelCol={{span: 24}}
                                    >
                                        <TextArea
                                            autosize={{minRows: 1, maxRows: 3}}
                                            className="a-input--textarea _a-input-note"
                                            name="note"
                                            placeholder={t('label.note')}
                                            spellCheck={false}
                                            value={note}
                                            onChange={this.changeTextValue}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={48}>
                                <Col className="a-package--action only-action" lg={{span: 24}}>
                                    <Button
                                        className="a-btn a-btn--back-package _btn-cancel"
                                        disabled={loading}
                                        onClick={this.onCancel}
                                    >
                                        {t('btn.cancel')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        disabled={loading}
                                        className="a-btn a-btn--primary a-btn--save-package _btn-save"
                                        onClick={this.onSubmit}
                                        loading={loading}
                                    >
                                        {isEmpty(shippingUserDetail) ? t('btn.add') : t('btn.save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Content>
        );
    }
}

FormShippingUser.defaultProps = {
    errors: {},
    loading: false,
    users: [],
    shippingUserDetail: {}
};

FormShippingUser.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    shippingUserDetail: PropTypes.object
};

export default withTranslation()(FormShippingUser);
