export const GET_LIST_CUSTOMER = {
    REQUEST: 'CUSTOMER.GET_LIST.REQUEST',
    SUCCESS: 'CUSTOMER.GET_LIST.SUCCESS',
    FAILED: 'CUSTOMER.GET_LIST.FAILED',
};
export const GET_SUGGESTS = {
    REQUEST: 'CUSTOMER.GET_SUGGESTS.REQUEST',
    SUCCESS: 'CUSTOMER.GET_SUGGESTS.SUCCESS',
    FAILED: 'CUSTOMER.GET_SUGGESTS.FAILED',
};
export const GET_INIT_CUSTOMER_OF_SELECT = {
    REQUEST: 'CUSTOMER.GET_INIT_CUSTOMER_OF_SELECT.REQUEST',
    SUCCESS: 'CUSTOMER.GET_INIT_CUSTOMER_OF_SELECT.SUCCESS',
    FAILED: 'CUSTOMER.GET_INIT_CUSTOMER_OF_SELECT.FAILED',
};
export const CLEAR_SUGGESTS = 'CLEAR_SUGGESTS';
export const FETCH_DETAIL_CUSTOMER = {
    REQUEST: 'CUSTOMER.FETCH_DETAIL_CUSTOMER.REQUEST',
    SUCCESS: 'CUSTOMER.FETCH_DETAIL_CUSTOMER.SUCCESS',
    FAILED: 'CUSTOMER.FETCH_DETAIL_CUSTOMER.FAILED',
};
export const CLEAR_DETAIL_CUSTOMER_STATE = "CUSTOMER.CLEAR_DETAIL_CUSTOMER_STATE";
export const CLEAR_ERROR_DETAIL_CUSTOMER_STATE = "CUSTOMER.CLEAR_ERROR_DETAIL_CUSTOMER_STATE";
