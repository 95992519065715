import React from 'react';
import {connect} from 'react-redux';
import ListProperty from "./components"
import {setMenuActive} from "../../Common/actions";
import {fetchProperty} from "../actions";
import {getListProperty, getState} from "./selectors";
import SearchableComponent from "../../Common/components/SearchableComponent";

const mapStateToProps = (state, props) => ({
	listProperty: getListProperty(state, props.route.name),
	loading: getState(state, 'loading', false)
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	fetchListProperty: (filter) => dispatch(fetchProperty(filter)),
});

class ListPropertyContainer extends SearchableComponent {
	componentDidMount() {
		super.componentDidMount();
		this.props.setMenuActive('config/property')
	}

	onSearch(filter) {
		this.pushFilter({
			...filter,
			i: parseInt(filter.i || 0, 0) + 1,
		});
	}

	onChangeFilter(filter) {
		filter.sort_by = 'name';
		filter.sort_direction = 'asc';
		this.props.fetchListProperty(filter);
	}

	getCurrentFilter() {
		return {
			page: 1,
			sort_by: 'name',
			sort_direction: 'asc',
			...this.getFilterFromLocation(this.props.location),
		};
		;
	}

	render() {
		return <ListProperty
			{...this.props}
			filter={this.getCurrentFilter()}
			onSearch={this.onSearch.bind(this)}/>
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListPropertyContainer);
