import React from 'react';
import * as _ from 'lodash';
import {connect} from "react-redux";
import {Button, Icon, Layout, Modal, Table} from 'antd';
import {t} from "../../../system/i18n";
import SearchableComponent from "../../Common/components/SearchableComponent";
import * as actions from '../actions';
import {setMenuActive} from "../../Common/actions";
import Link from "../../../system/routing/Link";
import {router} from "../../../system/routing";
import {FormFilter} from "./FormFilter";

const Content = Layout.Content;

const mapStateToProps = (state, props) => {
    return {
        templateBags: _.get(state, 'templateBag.list.bag_templates', []),
        loading: _.get(state, 'templateBag.loading', false),
        pagination: _.get(state, 'templateBag.list.pagination', {})
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchTemplateBags: (params) => dispatch(actions.fetchTemplateBags(params)),
    createTemplateBag: (data) => dispatch(actions.createTemplateBag(data)),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class TemplateBagListContainer extends SearchableComponent {

    getCurrentFilter() {
        return {
            page: 1,
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    onSearch = (filter) => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    componentDidMount() {
        super.componentDidMount();
        this.props.setMenuActive("config/template-bags");
    }

    onChangeFilter(filter) {
        this.props.fetchTemplateBags(filter);
    }

    onConfirmedDelete = (item) => {
        // Call api delete.
    };

    onConfirm = (item) => {
        Modal.confirm({
            title: t('template_bag:confirm_delete_title'),
            content: t('template_bag:confirm_delete'),
            okText: t('template_bag:ok'),
            cancelText: t('template_bag:cancel'),
            zIndex: 1050,
            icon: <Icon type="close-circle" theme="filled" className={"a-text--red"}/>,
            onOk: this.onConfirmedDelete.bind(this, item)
        });
    };

    redirectToCreate = () => {
        router.redirect("/config/template-bags/create");
    };

    render() {
        const {templateBags, pagination, loading} = this.props;

        const bagTypes = [
            {key: 1, label: t('bag:label.multi_bag'), value: "MULTIPLE"},
            {key: 2, label: t('bag:label.single_bag'), value: "SINGLE"}
        ];

        const columns = [
            {
                title: t('template_bag:columns.name'),
                dataIndex: 'bag_template.name',
                key: 'name',
                render: (text, record) => {
                    return {
                        props: {
                            className: `a-notification-dot a-notification-dot--table ${!!_.get(record, 'bag_template.active') ? 'active' : 'unactive'}`
                        },
                        children: <Link to={"config.template-bags.edit"}
                                        params={{id: _.get(record, 'bag_template.id')}}
                                        className={"a-text--nowrap _name _name-" + _.get(record, 'bag_template.id')}>{text}</Link>
                    }
                },
            },
            {
                title: t('template_bag:columns.type'),
                dataIndex: 'bag_template.type',
                key: 'type',
                render: (text, record) => <span
                    className={'_type _type-' + _.get(record, 'bag_template.id')}>{_.get(_.find(bagTypes, item => item.value === text), 'label', '--')}</span>,
            },
            {
                title: t('template_bag:columns.size'),
                dataIndex: 'bag_size.name',
                key: 'size',
                render: (text, record) => <span
                    className={"_size _size-" + _.get(record, 'bag_template.id')}>{text}</span>,
            },
            {
                title: t('common:agency'),
                dataIndex: 'agencies',
                key: 'agencies',
                render: (text) => <span
                    className={`_agencies _agencies-${_.get(text, 'bag_template.id')}`}>{_.join(_.map(text, 'agency.name'), ', ')}</span>,
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: "a-text--right _action",
                // fixed: 'right',
                width: 24,
                render: (text, record) => (
                    <React.Fragment>
                        <Link className={'_btn-edit _btn-edit-' + _.get(record, 'bag_template.id')}
                              to={"config.template-bags.edit"}
                              params={{id: _.get(record, 'bag_template.id')}}><Icon type="edit"
                                                                                    className={"mr-3 ahover-pointer a-text--blue _icon-btn-edit"}/></Link>
                        {/*<Link to={"config.template-bags.edit"} params={{id: _.get(record, 'bag_template.id')}}><Icon type="delete" className={"ahover-pointer a-text--gray"} onClick={this.onConfirm.bind(this, record)}/></Link>*/}
                    </React.Fragment>
                )
            }
        ];

        return (
            <Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>{t('template_bag:template_bag_list')} ({_.get(pagination, 'total', 0)})</h3>
                        <div className={"a-form__search-inline-title"}>
                            <FormFilter
                                filter={this.getCurrentFilter()}
                                onSearch={this.onSearch}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-template-bag"
                            onClick={this.redirectToCreate}
                        >
                            {t('template_bag:create_template_bag')}
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  height-100">
                    <Table className={"a-table--antd a-table--antd-scroll-x _a-table-temple-bag"}
                           columns={columns}
                           dataSource={templateBags}
                           pagination={false}
                           scroll={{x: 'fit-content'}}
                           loading={loading}
                           rowKey={row => _.get(row, 'bag_template.id')}
                    />
                </div>
            </Content>

        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateBagListContainer);
