import React from 'react';
import {Menu} from 'antd';

class MenuConfig extends React.Component {
    render() {
        return (
            <Menu mode="vertical" className={"a-menu--configs height-100"}>
                <Menu.Item key="1">Kho</Menu.Item>
                <Menu.Item key="2">Đối tác vận chuyển</Menu.Item>
                <Menu.Item key="3">Nhân viên vận chuyển</Menu.Item>
                <Menu.Item key="4">Nhân viên kho</Menu.Item>
                <Menu.Item key="5">Đặc tính</Menu.Item>
                <Menu.Item key="6">Dịch vụ</Menu.Item>
                <Menu.Item key="7">Bao mẫu</Menu.Item>
                <Menu.Item key="8">Kích thước bao</Menu.Item>
            </Menu>
        );
    }
}

export default MenuConfig;