import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { clearState, create, deleteSuggest, getDeliveryCustomerRequests, createWithTrackingNo } from './actions';
import { getBarcodes, getSelectedBagIds, getSelectedPackageIds, getState } from './selectors';
import authService from './../../Auth/authService';
import permissions from './../../Auth/permissions';
import { setMenuActive } from './../../Common/actions';
import { getLastmileCarrierSuggest } from './../../ShippingPartner/ListShippingPartner/actions';
import { printIssue } from './../../Common/PrintStamp/actions';
import { showDetailDeliveryRequest } from './../../DeliveryRequest/DetailDeliveryRequest/actions';

import Create from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';

const mapStateToProps = (state) => {
    return {
        barcodes: getBarcodes(state),
        creatings: getState(state, 'creatings'),
        deliveryCustomerRequests: getState(state, 'deliveryCustomerRequests'),
        listPackageByBag: getState(state, 'listPackageByBag'),
        loadingDeliveryCustomerRequests: getState(state, 'loadingDeliveryCustomerRequests'),
        selectedBagIds: getSelectedBagIds(state),
        selectedPackageIds: getSelectedPackageIds(state),
        suggests: getState(state, 'suggests.suggests'),
        creatingWithTrackingNo: getState(state, 'creatingWithTrackingNo', false)
    };
};

const mapDispatchToProps = {
    clearState,
    create,
    deleteSuggest,
    getDeliveryCustomerRequests,
    printIssue,
    setMenuActive,
    showDeliveryRequestDetailModal: showDetailDeliveryRequest,
    getLastmileCarrierSuggest,
    createWithTrackingNo
};

class CreateContainer extends SearchableComponent {
    getInput = () => {
        const input = this.getFilterFromLocation(this.props.location);

        return input;
    };

    onChangeInput = input => {
        this.pushFilter(input);
    };

    onChangeFilter = filter => {
        const warehouseId = get(filter, 'id_warehouse');

        if (authService.can(permissions.DELIVERY_REQUEST_VIEW_LISTING)) {
            this.getDeliveryCustomerRequests(warehouseId);
        }
    };

    getDeliveryCustomerRequests = async warehouseId => {
        const { getDeliveryCustomerRequests } = this.props;

        getDeliveryCustomerRequests({
            id_warehouse: warehouseId
        });
    };

    handleCreateDeliveryNote = (data, auto) => {
        const { create,  createWithTrackingNo} = this.props;
        if (auto) {
            createWithTrackingNo(data)
        } else {
            create(data)
        }
    };

    handlePrint = event => {
        const { printIssue } = this.props;

        event.stopPropagation();
        printIssue();
    };

    checkOnbeforeUnload = () => {
        const { suggests, t } = this.props;

        const filteredSuggests = suggests.filter(suggest => !get(suggest, 'delivery_note.id'));

        if (filteredSuggests.length) {
            return t('message.confirm_when_leave');
        }

        return null;
    };

    render() {
        return (
            <Create
                {...this.props}
                input={this.getInput()}
                checkOnbeforeUnload={this.checkOnbeforeUnload}
                getDeliveryCustomerRequests={this.getDeliveryCustomerRequests}
                onChangeInput={this.onChangeInput}
                onCreateDeliveryNote={this.handleCreateDeliveryNote}
                onPrint={this.handlePrint}
            />
        );
    }
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateContainer));
