import { notification } from 'antd';
import { get, isObject } from 'lodash';

import { t } from './../../system/i18n';

export const handleAddReExecutionError = error => {
    let message = t('shipping_fee:add_re_execution.failed');
    const errorData = get(error, 'data', {});

    const firstErrorKey = Object.keys(errorData);

    if (firstErrorKey) {
        message += `: ${t(`shipping_fee:add_re_execution.errors.${firstErrorKey}.${isObject(errorData[firstErrorKey]) ? Object.keys(errorData[firstErrorKey])[0] : errorData[firstErrorKey]}`)}`;
    }

    notification.error({
        message
    });
};
