import React, { Component } from 'react';
import { Button } from 'antd/lib/index';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import withShippingReturnPartners from './../../../../hocs/withShippingReturnPartners';
import { url } from './../../../../system/routing';

import ShippingReturnPartnersSelect from './../../../Common/components/Selects/ShippingReturnPartnersSelect';

class FormSelectShippingReturnPartner extends Component {
    state = {
        idShippingReturnPartner: undefined,
    };

    changeSelectValue = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    onSearch = () => {
        const { idShippingReturnPartner } = this.state;
        const { input } = this.props;
        const routeName = this.props.routeName || 'delivery-notes.return.create';

        url.redirectTo(routeName, null, {
                id_shipping_return_partner: idShippingReturnPartner,
                id_warehouse : input.id_warehouse,
                package: input.package
            }
        );
    };

    render() {
        const { idShippingReturnPartner } = this.state;
        const { loadingShippingReturnPartners, shippingReturnPartners, t } = this.props;

        return (
            <div className="gflex gflex-direction-column">
                <div className="a-select a-select--export-scan">
                    <ShippingReturnPartnersSelect
                        loading={loadingShippingReturnPartners}
                        placeholder={t('select_shipping_return_partner')}
                        shippingReturnPartners={shippingReturnPartners}
                        value={idShippingReturnPartner}
                        onChange={this.changeSelectValue.bind(this, 'idShippingReturnPartner')}
                    />
                </div>
                <Button
                    className="a-btn a-btn--scan-export a-btn--primary mt-5 _btn-submit"
                    disabled={!idShippingReturnPartner}
                    loading={loadingShippingReturnPartners}
                    onClick={this.onSearch}
                >
                    {t('btn.choose')}
                </Button>
            </div>
        );
    }
}

FormSelectShippingReturnPartner.defaultProps = {
    loading: false,
    routeName: ''
};

FormSelectShippingReturnPartner.propTypes = {
    loading: PropTypes.bool,
    routeName: PropTypes.string
};


export default withTranslation()(withShippingReturnPartners(FormSelectShippingReturnPartner, {
    getDataWhenMounted: true
}));
