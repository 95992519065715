import { Button } from 'antd'
import { isArray, isEmpty, isNil } from 'lodash'
import React, { useRef, useState } from 'react'
import { t } from '../../../../system/i18n'
import { getVar } from '../../../../system/support/helpers'
import apiService from '../apiService'

const PrintStamp = ({ idBag }) => {
    const [loading, setLoading] = useState(false)

    const count = useRef(0) // Biến đếm số lần gọi API
    const intervalRef = useRef(null) // Đối tượng interval

    const getStamp = () => {
        apiService
            .printStampBagLastmile(idBag)
            .then(result => {
                const url = getVar(result, 'data.stamp_links', [])
                if (!isEmpty(url) && !isNil(url)) {
                    setLoading(false)
                    clearInterval(intervalRef.current)
                    if (isArray(url)) {
                        for (const element of url) {
                            setTimeout(function () {
                                window.open(element, '_blank')
                            }, 500)
                        }
                    } else {
                        window.open(url, '_blank').focus()
                    }
                }
            })
            .catch(err => {})
    }

    function fetchData() {
        getStamp()
        count.current += 1
        if (count.current === 2) {
            setLoading(false)
            clearInterval(intervalRef.current)
        }
    }

    function handleClick() {
        setLoading(true)
        count.current = 0 // Reset biến đếm
        getStamp()
        intervalRef.current = setInterval(fetchData, 30000) // Gọi function fetchData() mỗi 30 giây
    }

    return (
        <Button
            type="primary"
            onClick={handleClick}
            loading={loading}
            disabled={loading}>
            {t('print_stamp.title')}
        </Button>
    )
}

export default PrintStamp
