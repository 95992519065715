import React from 'react';
import { withTranslation } from 'react-i18next';

const EmptyPage = () => (
    <div className="a-content--empty">
        <img className="img-ads" src={require('./../../../../../resources/images/Group 2867.png')} alt="Log system" />
    </div>
);

export default withTranslation()(EmptyPage);
