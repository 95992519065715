import React from 'react';
import {get, isEqual, parseInt, sumBy, toString} from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import SearchableComponent from './../../Common/components/SearchableComponent';
import CheckingPackageProducts from './components/CheckingPackageProducts';

const sumQuantity = (products, field) => {
    products = products.filter(product => !!toString(get(product, field)));

    return products.length ? sumBy(products, product => parseInt(get(product, field))) : null;
};

class CheckingPackageProductsContainer extends SearchableComponent {
    state = {
        products: this.props.products,
        sumOrderReceivedQuantity: sumQuantity(this.props.products, 'order_received_quantity'),
        sumPackageReceivedQuantity: sumQuantity(this.props.products, 'package_received_quantity'),
        sumPurchasedQuantity: sumQuantity(this.props.products, 'purchase_quantity')
    };

    componentDidUpdate(prevProps) {
        const { products } = this.props;

        if (!isEqual(products, prevProps.products)) {
            this.setState({
                products,
                sumOrderReceivedQuantity: sumQuantity(products, 'order_received_quantity'),
                sumPackageReceivedQuantity: sumQuantity(products, 'package_received_quantity'),
                sumPurchasedQuantity: sumQuantity(products, 'purchase_quantity')
            });
        }
    }

    render() {
        const { products, sumOrderReceivedQuantity, sumPackageReceivedQuantity, sumPurchasedQuantity } = this.state;
        const { loading } = this.props;

        return (
            <CheckingPackageProducts
                {...this.props}
                loading={loading}
                products={products}
                sumOrderReceivedQuantity={sumOrderReceivedQuantity}
                sumPackageReceivedQuantity={sumPackageReceivedQuantity}
                sumPurchasedQuantity={sumPurchasedQuantity}
            />
        );
    }
}

const Container = CheckingPackageProductsContainer;

Container.defaultProps = {
    packageId: null,
};

Container.propTypes = {
    packageId: PropTypes.number,
};

export default withTranslation()(Container);
