import { api } from './../../system/api'

const BASE_URL = '/agencies'

export default {
    get: params =>
        api.get(BASE_URL, {
            params,
        }),
    getAgencySuggest: params =>
        api.get(`${BASE_URL}/suggest`, {
            params,
        }),
    detail: (id, params) =>
        api.get(`/agencies/${id}`, {
            params,
        }),
    update: (id, data) => api.put(`/agencies/${id}`, data),
    addUserToStaff: params => api.post(`${BASE_URL}/${params.id_agency}/staffs`, params),
    removeUserToStaff: params => api.put(`${BASE_URL}/${params.id_agency}/staffs/${params.id_staff}/remove`),
    removeStaffByUsers: params => api.post(`${BASE_URL}/${params.id_agency}/staffs/remove`, params),
    removeAllStaffByUserId: userId => api.post(`/users/${userId}/remove-all-staff`),
    getListFinanceTransaction: params => api.get(`/transactions`, { params }),
    addFinanceTransaction: (data) => api.post('/transactions', data)
}
