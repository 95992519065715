import { connect } from 'react-redux';
import { get } from 'lodash';

import { clearPrintState } from './../PrintStamp/actions';

import SiderX from './components/';

const mapStateToProps = (state) => {
    return ({
        permissions: get(state, 'auth.user.permissions', []),
        partner: get(state, 'auth.partner.meta', []),
        menuActive: get(state, 'common.menuActive', ''),
        setting: get(state, 'setting.setting')
    });
};

const mapDispatchToProps = {
    clearPrintState
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiderX);
