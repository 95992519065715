import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.CHANGE_PACKAGE_TRANSPORT_STATUS.REQUEST, saga.changePackageTransportStatus);
    yield takeLatest(ACTION.CHANGE_PACKAGE_TRANSPORT_STATUS.SUCCESS, saga.changePackageTransportStatusSuccess);
    yield takeLatest(ACTION.CHANGE_PACKAGE_TRANSPORT_STATUS.FAILED, saga.changePackageTransportStatusFailed);
    yield takeLatest(ACTION.UPDATE_TRANSPORT_STATUS.REQUEST, saga.updateTransportStatus);
    yield takeLatest(ACTION.UPDATE_TRANSPORT_STATUS.SUCCESS, saga.successUpdateTransportStatus);
    yield takeLatest(ACTION.UPDATE_TRANSPORT_STATUS.FAILED, saga.failedUpdateTransportStatus);
}
