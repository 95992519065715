/* eslint-disable */
import React, {Component} from 'react';
import { Select, Table, Pagination, Button, Menu, Dropdown, Icon, Checkbox,} from 'antd';

const Option = Select.Option;
const columns = [
    {
        title: 'Mã bao',
        dataIndex: 'bag',
        key: 'bag',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    }, {
        title: 'Thời gian tạo',
        dataIndex: 'timecreate',
        key: 'timecreate',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kho tạo',
        dataIndex: 'warehousecreate',
        key: 'packagecode',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kho đích',
        dataIndex: 'warehousefinish',
        key: 'warehousefinish',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kho hiện tại',
        dataIndex: 'warehousenow',
        key: 'warehousenow',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Trạng thái kho hiện tại',
        dataIndex: 'statuswarehousenow',
        key: 'statuswarehousenow',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Thời gian xuất/nhập',
        dataIndex: 'time',
        key: 'time',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Vận chuyển',
        dataIndex: 'transport',
        key: 'transport',
        className: "a-text--nowrap",
    }, {
        title: 'Đặc tính',
        dataIndex: 'attribute',
        key: 'contentscan',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Dịch vụ',
        dataIndex: 'service',
        key: 'service',
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Tổng số kiện',
        dataIndex: 'totalpackage',
        key: 'totalpackage',
        sorter: true,
        className: "a-text--nowrap",
    },
    {
        title: 'Tổng cân nặng kiện',
        dataIndex: 'totalweight',
        key: 'totalweight',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Cân nặng bao',
        dataIndex: 'weightbag',
        key: 'weightbag',
        sorter: true,
        className: "a-text--nowrap",
    },
    {
        title: 'Kích thước bao bao',
        dataIndex: 'sizebag',
        key: 'sizebag',
        sorter: true,
        className: "a-text--nowrap",
    }];

const data = [];
for (let i = 0; i < 50; i++) {
    data.push({
        bag: `H1234-75011909${i}`,
        timecreate: `15:00:00 29/02/2021`,
        attribute: `Hàng dễ nát`,
        service: `Đóng gỗ, CPN`,
        warehousecreate: `USNY`,
        warehousefinish: `VNHN`,
        warehousenow: `USCK`,
        statuswarehousenow: `Waiting`,
        time: `14:00:00 29/02/2021`,
        transport: `Airport`,
        weight: `${i}0kg`,
        totalpackage: `${i + 1} `,
        totalweight: `${i}0kg`,
        weightbag: `${i + 1} `,
        sizebag: `${i + 1}0kg`,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

class ListBag extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list">
                    <div className="a-content--page-list__title">
                        <h3>Danh Sách Bao - <span>32216</span></h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>Thiết lập cột thông tin
                            </Button>
                        </Dropdown>
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                        >Tạo bao
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list ">
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           pagination={false}
                    />
                    <div className="a-pagination">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show">
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListBag;