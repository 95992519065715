import { takeLatest } from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';
import * as CUSTOMER_ACTION from './../Customer/constants';
import * as ORDER_ACTION from './../Order/constants';

export default function* () {
    yield takeLatest(ACTION.GET_NOTES.REQUEST, saga.getNotes);
    yield takeLatest(CUSTOMER_ACTION.GET_SUGGESTS.SUCCESS, saga.getSuggestCustomerSuccess);
    yield takeLatest(ORDER_ACTION.GET_SUGGEST_ORDERS.SUCCESS, saga.getSuggestOrderSuccess);
    yield takeLatest(ACTION.FETCH_PACKAGE_DETAIL.REQUEST, saga.fetchPackageDetail);
    yield takeLatest(ACTION.FETCH_PACKAGE_DETAIL.FAILED, saga.fetchPackageDetailFailed);
    yield takeLatest(ACTION.FETCH_PACKAGE_DETAIL.SUCCESS, saga.fetchPackageDetailSuccess);
}
