import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t, trans} from '../../../system/i18n';
import * as lodash from "lodash";
import React from "react";

export function* unlinkOrder(action) {
    yield processApiRequest(ACTION.UNLINK_ORDER, () => apiService.unlinkOrder(action.payload), action.payload);
}

export function* unlinkOrderFailed(action) {
    if (action.payload) {
        let message = '';
        let codes = Object.keys(lodash.get(action, 'payload.data.code', []));

        if (lodash.get(action, 'payload.data.warehouse')) {
            message = <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>;
        }

        if (lodash.has(action, 'payload.data.code')) {
            lodash.forEach(codes, code => {
                switch (code) {
                    case 'package_in_single_bag' :
                        message = trans('package:message.unlink_failed_single_bag', {
                            code: <b>{lodash.get(action.payload, 'data.code.package_in_single_bag.code')}</b>
                        });
                        break;
                    default :
                        message = t('package:message.unlink_failed')

                }
            });
        }
        yield  notification['error']({message});
    }
}

export function* unlinkOrderSuccess() {
    yield  notification['success']({
        message: t('package:message.unlink_success'),
    });
}
