import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {setCollectionItem} from '../../Common/services/helps';
import lodash from 'lodash';


let packages = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_WEIGHT.REQUEST:
            return setCollectionItem(
                state,
                payload.params.packageCode,
                {[`weight_loading`]: true, [`weight_error`]: null},
                'code'
            );

        case ACTION.UPDATE_WEIGHT.SUCCESS:
            return setCollectionItem(
                state,
                lodash.get(action, 'request.params.packageCode', ''),
                {[`weight_loading`]: false},
                'code'
            );

        case ACTION.UPDATE_WEIGHT.FAILED:
            return setCollectionItem(
                state,
                lodash.get(action, 'request.params.packageCode', ''),
                {[`weight_loading`]: false, [`weight_error`]: payload},
                'code'
            );

        case ACTION.CLEAR_STATE:
            return state.filter(packageData => packageData.code !== payload.packageCode);

        default:
            return state;
    }
};

export default combineReducers({
    packages,
});
