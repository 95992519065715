/* eslint-disable */
import React from 'react';
import {Col, Row} from "antd";
import InlineEditSelect from "../../../../Common/InlineEdit/InlineEditSelect";
import InlineEditList from "../../../../Common/InlineEdit/InlineEditList";
import InlineEditInput from "../../../../Common/InlineEdit/InlineEditInput";

class InfoDeliveryNote extends React.Component{
    render(){
        return(
            <div className="a-block-info abg-white  amb-16 apt-24 apl-8 apr-8 pb-0" >
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Tên khách hàng</label>
                        <span className="info--value ">Lê Hoàng Thuỳ Trang (lehoangthuytrang999)</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Số điện thoại </label>
                        <span className="info--value ">033.333.3333</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Người nhận </label>
                        <span className="info--value ">Lê Hoàng Thuỳ Dung</span>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Người tạo</label>
                        <span className="info--value ">Nguyễn Hoàng Anh(giannan123)</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Thời gian tạo</label>
                        <span className="info--value ">01:10 14/04/2019</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Trạng thái phiếu xuất</label>
                        <span className="info--value ">Đang vận chuyển </span>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Người xuất</label>
                        <span className="info--value ">Nguyễn Hoàng Anh2(giannan123)</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Thời gian xuất</label>
                        <span className="info--value ">01:10 14/04/2019</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Kho xuât</label>
                        <span className="info--value ">CNGZ</span>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Thu hộ(VNĐ)</label>
                        <span className="info--value ">1.000.000.000</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Số lượng xuất </label>
                        <span className="info--value ">999</span>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <label className="info--label">Tổng cân nặng</label>
                        <span className="info--value ">9981 (kg)</span>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                         className="info--group">
                        <InlineEditInput inputType='textarea' label="Ghi chú "
                                         value="0989485958"/>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default InfoDeliveryNote;