/* eslint-disable */
import React from 'react';
import {Button, Row, Col, Input, Modal} from 'antd';
import InlineEditInput from '../../../../Common/InlineEdit/InlineEditInput';
import InlineEditSelect from '../../../../Common/InlineEdit/InlineEditSelect';
import InlineEditList from '../../../../Common/InlineEdit/InlineEditList';
import InlineEditService from '../../../../Common/InlineEdit/InlineEditService2'

class BagInfo extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            visible: false,
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = () => {
        this.setState({
            visible: false,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        return (
            <div className="block--primary a-content--bag mr-0">
                <div className="title a-content--bag--title">
                    <div className="left">
                        <span><strong>HHA123 - 12385937493</strong></span>
                    </div>
                    <div className="right">
                        <Button type="button" className="a-btn a-btn--transparent-white mr-3" ghost>Tạo bao
                            tương tự </Button>
                        <Button type="button" className="a-btn a-btn--transparent-white"
                                onClick={this.showModal} ghost>Lưu bao
                            mẫu </Button>
                        <Modal
                            visible={this.state.visible}
                            width={400}
                            closable={false}
                            className="a-modal a-modal--create-template-bag"
                            footer={null}
                            centered={true}
                        >
                            <div className="create-template-bag--title">
                                <span className="title">Tạo mẫu bao</span>
                            </div>
                            <div className="body">
                                <div className="group-input">
                                    <label>Tên bao mẫu</label>
                                    <Input className="a-input "/>
                                </div>
                                <div className="action">
                                    <Button type="primary" className="a-btn a-btn--primary mr-3"
                                            onClick={this.handleOk}>Lưu bao mẫu</Button>
                                    <Button className="a-btn a-btn--default"
                                            onClick={this.handleCancel}>Hủy</Button>
                                </div>
                            </div>

                        </Modal>
                    </div>
                </div>
                <div className="body a-package__body ">
                    <Row gutter={{lg: 24, xl: 48}} className="a-block-info">
                        <Col lg={{span: 15}} xl={{span: 18}} xxl={{span: 19}}>
                            <div className="a-block-info bg-gray  ">
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                        <h4>Thông tin bao hàng</h4>
                                    </Col>
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                                        <InlineEditSelect label="Kích thước"
                                                          value={<span>300 cm(<sup>3</sup>)</span>}
                                        />
                                    </Col>
                                    <Col lg={{span: 24, offset: 0}} xl={{span: 17, offset: 1}}
                                         xxl={{span: 19, offset: 1}}
                                         className="info--group">
                                        <InlineEditList label="Đặc tính"
                                                        value={<span>Dễ cháy / Dễ bay hơi / Dễ hỏng</span>}/>
                                    </Col>
                                </Row>
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                                        <InlineEditSelect label="Loại vận chuyển"
                                                          value={<span>Nhân viên</span>}
                                        />
                                    </Col>
                                    <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                                         xxl={{span: 6, offset: 1}}
                                         className="info--group">
                                        <InlineEditSelect label="Vận chuyển"
                                                          value={<span>Vũ Ánh Ngọc Dương</span>}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                                        <InlineEditInput label="Người nhận" value="Lê Hoàng Ngọc Anh"/>
                                    </Col>
                                    <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                                         xxl={{span: 6, offset: 1}}
                                         className="info--group">
                                        <InlineEditInput label="Số điện thoại nhận" value="0989485958"/>
                                    </Col>
                                </Row>
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}}
                                         className="info--group">
                                        <label className="info--label">Cân nặng bao</label>
                                        <span className="info--value ">2019(kg) </span>
                                    </Col>
                                    <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                                         xxl={{span: 6, offset: 1}}
                                         className="info--group">
                                        <label className="info--label">Cân nặng tổng số kiện</label>
                                        <span className="info--value ">0(kg)</span>
                                    </Col>
                                    <Col lg={{span: 14}} xl={{span: 10}} xxl={{span: 12}}
                                         className="info--group">
                                        <label className="info--label">Thời gian tạo</label>
                                        <span className="info--value ">Xuất kho ( 12:15:50 29/02/2019)</span>
                                    </Col>
                                </Row>
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}}
                                         className="info--group">
                                        <label className="info--label">Kho hiện tại</label>
                                        <span className="info--value ">VNHN</span>
                                    </Col>
                                    <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                                         xxl={{span: 6, offset: 1}}
                                         className="info--group">
                                        <label className="info--label">Trạng thái kho hiện tại</label>
                                        <span className="info--value ">Trong kho</span>
                                    </Col>
                                    <Col lg={{span: 14}} xl={{span: 10}} xxl={{span: 12}}
                                         className="info--group">
                                        <label className="info--label">Thời gian xuất/nhập</label>
                                        <span className="info--value ">Xuất kho ( 12:15:50 29/02/2019)</span>
                                    </Col>
                                </Row>
                                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                                        <InlineEditSelect label="Kho Tạo"
                                                          value={<span>USNY</span>}
                                        />
                                    </Col>
                                    <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                                         xxl={{span: 6, offset: 1}}
                                         className="info--group">
                                        <InlineEditSelect label="Kho đích"
                                                          value={<span>VNGS</span>}
                                        />
                                    </Col>
                                    <Col lg={{span: 14}} xl={{span: 10}} xxl={{span: 8}}
                                         className="info--group">
                                        <InlineEditInput inputType='textarea' label="Ghi chú "
                                                         value="0989485958"/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={{span: 9}} xl={{span: 6}} xxl={{span: 5}} className="pl-0">
                            <InlineEditService/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default BagInfo;