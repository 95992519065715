import React, {Component} from 'react';
import {connect} from 'react-redux';
import DetailDeliveryRequest from "./components";
import * as actions from "./actions";
import * as selectors from "./selectors"
import lodash from "lodash";


const mapStateToProps = (state,props) => {
    return {
        visible : selectors.getState(state, "showDetailDeliveryRequest", false),
        loading : selectors.getState(state, "loading.detail", false),
        detailDeliveryRequest : selectors.getState(state, "detail", {}),
        listOrderRequest: selectors.getListOrderRequest(state, lodash.get(props.route, 'name')),
        permissions : lodash.get(state, 'auth.user.permissions', []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDetailDeliveryRequest: (code) => dispatch(actions.fetchDetailDeliveryRequest(code)),
        hideDetailDeliveryRequest: (code) => dispatch(actions.hideDetailDeliveryRequest(code)),
        clearState: ()=> dispatch(actions.clearState())
    };
};

class DetailDeliveryRequestContainer extends Component {
    componentDidMount() {
        // this.props.fetchDetailDeliveryRequest(this.props.code)
    }

    componentWillUnmount() {
        this.props.clearState();
    }


    render() {
        return <DetailDeliveryRequest {...this.props}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailDeliveryRequestContainer);
