import React from 'react';
import { Typography } from 'antd';
import { get } from 'lodash';

const { Text } = Typography;

const ShipmentDetail = ({ bag }) => (
    <div className="shipment-detail">
        <div className="row tile">
            <Text>
                Shipment Detail
            </Text>
        </div>

        <div className="row">
            <div className="a-text--center a-flex galign-center gjustify-center tile width-25-pc">
                <Text>
                    Total number of packages
                </Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center tile width-25-pc">
                <Text>
                    Actual weight
                </Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center tile width-25-pc">
                <Text>
                    Volume
                </Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center tile width-25-pc">
                <Text>
                    Chargeable weight
                </Text>
            </div>
        </div>

        <div className="row">
            <div className="a-text--center tile width-25-pc">
                <Text strong>{get(bag, 'num_package')}</Text>
            </div>
            <div className="a-text--center tile width-25-pc">
                <Text strong>
                    {parseFloat(get(bag, 'weight_real', 0)) + parseFloat(get(bag, 'weight_box', 0))} kg
                </Text>
            </div>
            <div className="a-text--center tile width-25-pc">
                <Text strong>{get(bag, 'volume')} m3</Text>
            </div>
            <div className="a-text--center tile width-25-pc">
                <Text strong></Text>
            </div>
        </div>
    </div>
);

export default ShipmentDetail;
