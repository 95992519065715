import {combineReducers} from "redux";
import * as ACTION from '../constants';
import * as lodash from "lodash";

let list = (state = [], action) => {
	let {type, payload} = action;
	switch (type) {
		case ACTION.FETCH_LIST_WAREHOUSE_STAFF.SUCCESS:
			return lodash.get(payload, 'warehouse_staffs', []);
		case ACTION.RESET_LIST_WAREHOUSE_STAFF:
			return [];
		case ACTION.FETCH_LIST_WAREHOUSE_STAFF.REQUEST:
		case ACTION.FETCH_LIST_WAREHOUSE_STAFF.FAILED:
		default:
			return state;
	}
};
let loading = (state = false, action) => {
	let {type} = action;
	switch (type) {
		case  ACTION.FETCH_LIST_WAREHOUSE_STAFF.REQUEST:
			return true;
		case ACTION.FETCH_LIST_WAREHOUSE_STAFF.FAILED:
		case ACTION.FETCH_LIST_WAREHOUSE_STAFF.SUCCESS:
			return false;
		default:
			return state

	}
};
let pagination = (state = {}, action) => {
	switch (action.type) {
		case ACTION.FETCH_LIST_WAREHOUSE.SUCCESS:
			return action.payload.pagination;
		case ACTION.RESET_LIST_WAREHOUSE:
			return {};
		default:
			return state;
	}
};
export default combineReducers({loading, list, pagination})
