import React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { Button, Icon, Layout, Modal, Table } from 'antd'
import { t } from '../../../system/i18n'
import SearchableComponent from '../../Common/components/SearchableComponent'
import { setMenuActive } from '../../Common/actions'
import Link from '../../../system/routing/Link'
import { router } from '../../../system/routing'
import { FormFilter } from './FormFilter'
import apiServices from '../apiServices'
import Pagination from '../../Common/components/Pagination'
import { get, toInteger } from 'lodash'

const Content = Layout.Content

const mapStateToProps = (state, props) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

class TemplateBagLastmileListContainer extends SearchableComponent {
    state = {
        total: 0,
        templateBags: [],
        loading: false,
    }

    getCurrentFilter() {
        return {
            page: 1,
            ...this.getFilterFromLocation(this.props.location),
        }
    }

    fetchApi(filter) {
        this.setState({
            loading: true
        })
        apiServices
            .fetchTemplateBagsLastmile(filter)
            .then(res => {
                this.setState({
                    templateBags: get(res, 'data.items', []),
                    total: get(res, "data.total",0)
                })
            })
            .catch(() => {}).finally(()=>{
                this.setState({
                    loading: false
                })
            })
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    componentDidMount() {
        this.fetchApi()
        this.props.setMenuActive('config/template-bags-lastmile')
    }

    onChangeFilter(filter) {
        this.fetchApi(filter);
    }

    onChangePage(page, pageSize) {
        if (!this.state.loading) {
            const filter = this.getCurrentFilter()

            this.onSearch({
                ...filter,
                page,
                per_page: pageSize,
            })
        }
    }

    onConfirmedDelete = item => {
        // Call api delete.
    }

    onConfirm = item => {
        Modal.confirm({
            title: t('template_bag:confirm_delete_title'),
            content: t('template_bag:confirm_delete'),
            okText: t('template_bag:ok'),
            cancelText: t('template_bag:cancel'),
            zIndex: 1050,
            icon: (
                <Icon
                    type="close-circle"
                    theme="filled"
                    className={'a-text--red'}
                />
            ),
            onOk: this.onConfirmedDelete.bind(this, item),
        })
    }

    redirectToCreate = () => {
        router.redirect('/config/template-bags-lastmile/create')
    }

    render() {
        const { templateBags, loading, total } = this.state


  
        const columns = [
            {
                title: t('template_bag:columns.name'),
                dataIndex: 'label',
                key: 'label',
                render: (text, record) => {
                    return {
                        props: {
                            className: `a-notification-dot a-notification-dot--table ${!!_.get(record, 'active') ? 'active' : 'unactive'}`,
                        },
                        children: (
                            <Link
                                to={'config.template-bags-lastmile.edit'}
                                params={{ id: _.get(record, 'id') }}
                                className={'a-text--nowrap _name _name-' + _.get(record, 'id')}>
                                {text}
                            </Link>
                        ),
                    }
                },
            },
            {
                title: t('bag:label.customer'),
                dataIndex: 'customer',
                key: 'customer',
                render: text => <span className={`customer customer-${text}`}>  {get(text, 'full_name', '')} - {get(text, 'username', '')}</span>,
            },
            {
                title: t('bag:label.id_lastmile_shipping_partner'),
                dataIndex: ['lastmile_shipping_partner', 'name'],
                key: 'lastmile_shipping_partner',
                render: text => <span className={`lastmile_shipping_partner lastmile_shipping_partner-${text}`}>{text}</span>,
            },
            {
                title: t('common:agency'),
                dataIndex: ['agency', 'name'],
                key: 'agency',
                render: text => <span className={`_agencies _agencies-${text}`}>{text}</span>,
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: 'a-text--right _action',
                // fixed: 'right',
                width: 24,
                render: (text, record) => (
                    <React.Fragment>
                        <Link
                            className={'_btn-edit _btn-edit-' + _.get(record, 'id')}
                            to={'config.template-bags-lastmile.edit'}
                            params={{ id: _.get(record, 'id') }}>
                            <Icon
                                type="edit"
                                className={'mr-3 ahover-pointer a-text--blue _icon-btn-edit'}
                            />
                        </Link>
                        {/*<Link to={"config.template-bags.edit"} params={{id: _.get(record, 'id')}}><Icon type="delete" className={"ahover-pointer a-text--gray"} onClick={this.onConfirm.bind(this, record)}/></Link>*/}
                    </React.Fragment>
                ),
            },
        ]

        return (
            <Content className={'aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column'}>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>
                            {t('template_bag:template_bag_list_lastmile')} ({total || 0})
                        </h3>
                        <div className={'a-form__search-inline-title'}>
                            <FormFilter
                                filter={this.getCurrentFilter()}
                                onSearch={this.onSearch}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-template-bag"
                            onClick={this.redirectToCreate}>
                            {t('template_bag:create_template_bag')}
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  height-100">
                    <Table
                        className={'a-table--antd a-table--antd-scroll-x _a-table-temple-bag'}
                        columns={columns}
                        dataSource={templateBags}
                        pagination={false}
                        scroll={{ x: 'fit-content' }}
                        loading={loading}
                        rowKey={row => _.get(row, 'id')}
                    />
                    <Pagination
                        page={toInteger(this.getCurrentFilter().page) || 1}
                        pageSize={toInteger(this.getCurrentFilter().per_page)}
                        pageSizeOptions={[20, 50, 100, 200]}
                        total={total}
                        loading={loading}
                        onChange={this.onChangePage.bind(this)}
                    />
                </div>
            </Content>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBagLastmileListContainer)
