import { takeLatest} from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';

export default function* () {
	yield takeLatest(ACTION.SCAN_PACKAGE.REQUEST, saga.scanPackage);
	yield takeLatest(ACTION.SCAN_PACKAGE.FAILED, saga.scanPackageFailed);
	yield takeLatest(ACTION.SCAN_TRACKING_BILL.REQUEST, saga.scanTrackingBill);
	yield takeLatest(ACTION.SCAN_TRACKING_BILL.FAILED, saga.scanTrackingBillFailed);
}
