import React, { Component } from 'react';
import { Empty, Table } from 'antd';
import clsx from 'clsx';
import { get, toInteger, values } from 'lodash';
import { withTranslation } from 'react-i18next';

import { COLUMNS } from '../constants';
import { currentFormatter, dateFormatter } from './../../../../Common/services/format';

import Pagination from './../../../../Common/components/Pagination';
import Link from './../../../../../system/routing/Link';

class DeliveryNotesReturn extends Component {
	getColumns() {
        const { filter, t } = this.props;
        const sortableColumns = [COLUMNS.CREATE_AT, COLUMNS.EXPORTED_AT];

		return values(COLUMNS)
			.map(column => {
				let res = {
					className: clsx('a-text--nowrap', {
                        '_delivery-note-return-column': column === COLUMNS.CODE 
                    }),
					key: column,
					dataIndex: column,
					title: t(`delivery_note:column.${column}`),
				};

				if (sortableColumns.indexOf(column) > -1) {
					res = {
						...res,
						sorter: true,
						sortOrder: column === filter.sort_by
							? (filter.sort_direction === 'desc' ? 'descend' : 'ascend')
							: null,
					}
				}
				
				if (column === COLUMNS.CODE) {
					res.title = t('delivery_note_return_code');
					res.render = (text, row) => (
						<Link
							isNewTab
							params={{id: row.id}}
							to="delivery-notes.return.detail"
						>
							{text}
						</Link>
					);
				}

				if (column === COLUMNS.RETURN_FEE) {
					res.render = text => text ? currentFormatter.toLocaleStringCurrency(text) : '--'
				}

				if (column === COLUMNS.STATUS) {
					res.title = t('delivery_note_return_status');
					res.render = text => (
						res.title = t(`delivery_note:status.${text}`)
					);
				}

				if (column === COLUMNS.CREATE_AT) {
					res.render = text => dateFormatter.full(text)
				}

				if (column === COLUMNS.EXPORTED_AT) {
					res.render = text => text ? dateFormatter.full(text) : '--'
				}

				if (column === COLUMNS.ID_WAREHOUSE) {
					res.dataIndex = 'warehouse.code';
				}

				if (column === COLUMNS.ID_CREATOR) {
					res.render = (text, row) => `${get(row, 'creator.name', '--')} (${get(row, 'creator.username', '--')})`
				}

				if (column === COLUMNS.ID_EXPORTER) {
					res.render = (text, row) => get(row, 'exporter') ? `${get(row, 'exporter.name', '--')} (${get(row, 'exporter.username', '--')})` : '--'
				}

				return res;
			});
	}

	onChange = (pagination, filters, sorter) => {
		this.search({
			sort_by: sorter.field || 'created_at',
			sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
		});
	};

	search = input => {
        const { filter, loading, onSearch } = this.props;

		if (!loading) {
			onSearch({
				...filter,
				...input,
			});
		}
	}

	onChangePage = (page, pageSize) => {
        const { filter, loading, onSearch } = this.props;

		if (!loading) {
			onSearch({
				...filter,
				page,
				per_page: pageSize,
			});
		}
	};

	render() {
		const { deliveryNotesReturn, filter, loading, pagination, t } = this.props;

		return (
			<>
				<div className="a-content__top a-content__top--page-list mt-0">
					<div className="a-content--page-list__title">
						<h3>{t('delivery_note_return_list')} ({get(pagination, 'total', 0)})</h3>
					</div>
				</div>
				<div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
					<div className="a-table--responsive">
						<Table
							loading={loading}
							className={"a-table--page-list"}
							columns={this.getColumns()}
							dataSource={deliveryNotesReturn}
							pagination={false}
							locale={{
								emptyText: (
									<Empty
										image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
										description={<span>{t("common:label.no_data")}</span>}
									>
									</Empty>)
							}}
							onChange={this.onChange}
						/>
					</div>
					<Pagination
						page={toInteger(filter.page) || 1}
						pageSize={toInteger(filter.per_page)}
						total={pagination ? pagination.total : 0}
						loading={loading}
						onChange={this.onChangePage}
                    />
				</div>
			</>
		);
	}
}

export default withTranslation()(DeliveryNotesReturn);
