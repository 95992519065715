/* eslint-disable */
import React from 'react';
import {Form, Select, Button, Row, Col, Icon, Input, DatePicker} from 'antd';
const Option = Select.Option;
const Search = Input.Search;
const {RangePicker} = DatePicker;
class FormSearch extends React.PureComponent{
    render() {
        return(
            <div className="a-content--search-header search-default">
                <Row gutter={24}>
                    <Form className="a-form">
                        <Col lg={18} xl={17} xxl={18} className="search--left">
                            <Row gutter={{lg: 12, xl: 12, xxl: 24}}>
                                <Col lg={4} xl={3} xxl={3}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Kho tạo"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={4} xl={3} xxl={3}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Kho đích"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={4} xl={3} xxl={3}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Kho hiện tại"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={6} xxl={4}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Trạng thái kho hiện tại"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                defaultValue="Waiting">
                                            <Option value="Waiting">Waiting</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} xl={8} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Thời gian xuất/nhập"
                                        labelCol={{span: 24}}>
                                        <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} xl={7} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Thời gian tạo bao"
                                        labelCol={{span: 24}}>
                                        <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={8} xxl={6}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Vận chuyển"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={8} xxl={7}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Đặc tính"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                mode="multiple"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} xl={8} xxl={7}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Dịch vụ"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content"
                                                mode="multiple"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">Xuất kho theo bao</Option>
                                            <Option value="VNSG">Quét mã tạo kiện</Option>
                                            <Option value="VNHN">Quét mã vạch</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={7} xxl={6} className="search--right">
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label="Mã bao hàng"
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search"
                                />
                            </Form.Item>
                            <div className="search--action">
                                <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                                <Button type="primary" htmlType="submit"
                                        className="a-btn a-btn--primary a-btn--search-list ">Tìm
                                    kiếm</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>
        )
    }
}
export default FormSearch;