import React from 'react';
import {Button, Icon, Input, Upload} from "antd";
import lodash from 'lodash';
import {t} from '../../../../system/i18n';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class ChatInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            attachments: [],
            comment: "",
            fileList: []
        };

        this.refInput = React.createRef();
    }

    beforeUpload = (file) => {
        let {attachments, fileList} = this.state;
        attachments.push(file);
        getBase64(file, url => {
                fileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url,
                    thumbUrl: url
                });
                return this.setState({
                    attachments,
                    fileList
                })
            }
        );


        this.refInput.current.focus();
        return false;
    };

    onChangeInput = (e) => {
        this.setState({comment: e.target.value});
    };

    onRemoveFile = (file) => {
        let {attachments, fileList} = this.state;
        attachments = lodash.filter(attachments, item => {
            return item.uid !== file.uid;
        });
        fileList = lodash.filter(fileList, item => {
            return item.uid !== file.uid;
        });
        this.setState({attachments, fileList});
    };

    onSubmit = () => {
        this.setState({
            comment: "",
            attachments: [],
            fileList: []
        });
        this.props.onSubmit({
            comment: this.state.comment,
            attachments: this.state.attachments
        })
    };


    render() {
        const {comment, fileList} = this.state;
        return (
            <div className="a-chatbox--chat-input ">
                <Input
                    placeholder={t('package:comment.input')}
                    className="a-input a-input--chatbox apr-36 aflex-1"
                    onChange={this.onChangeInput}
                    value={comment}
                    onPressEnter={this.onSubmit}
                    ref={this.refInput}
                />
                <div className={"a-group--upload"}>
                    <Upload
                        name="image"
                        listType="picture"
                        action="/upload.do"
                        className="a-btn--upload"
                        fileList={fileList}
                        multiple={true}
                        beforeUpload={this.beforeUpload}
                        onRemove={this.onRemoveFile}
                    >
                        <Button className={"mr-4"}>
                            <Icon type="paper-clip"/>
                        </Button>
                        <Button>
                            <Icon type="camera"/>
                        </Button>
                    </Upload>
                </div>
            </div>
        )
    }
}

ChatInput.defaultProps = {
    onSubmit: (data) => {
    }
};

export {ChatInput}
