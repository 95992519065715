import React, { useMemo } from 'react';
import { Table } from 'antd';
import { get, map, find, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

const List = ({ deliveryNoteTransportWarehouse, t }) => {
    const columns = useMemo(() => [
        {
            title: t('table.#'),
            dataIndex: '#',
            key: '#',
            className: 'a-text--nowrap',
            render: (text, row, index) => index + 1
        },
        {
            title: t('delivery_note:label.export_code'),
            dataIndex: 'outside_bag.code',
            key: 'outside_bag.code',
            className: "a-text--nowrap"
        },
        {
            title: '',
            dataIndex: 'bag.code',
            key: 'bag.code',
            className: "a-text--nowrap",
            render: (text, record) => {
                const bagType =  get(record, "bag.type")
                const packageType = !isEmpty(get(record, 'package', {}));
                const barcodeTypeLabel = packageType ? t('package') : (bagType ? t(`bag:label.${bagType}`) : "") 
                return barcodeTypeLabel
            }
        }
    ], [t]);

    const isCustomWarehouse = get(deliveryNoteTransportWarehouse, 'warehouse.is_custom_warehouse', false);
    const packagesData = get(deliveryNoteTransportWarehouse, "packages", []);
    const bagsData = get(deliveryNoteTransportWarehouse, "bags", []);
    const bagCodes = map(bagsData, "bag.code")
    const data = [];
    get(deliveryNoteTransportWarehouse, 'outside_bags', []).map(item => {
        const code = get(item, "outside_bag.code")
        const bagInfo = find(bagsData, ['bag.code', code])
        let packageInfo = find(packagesData, ['package.code', code])
        if( isEmpty(packageInfo) ) {
            packageInfo = find(packagesData, ['package.tracking_no', code])
        }
        if (isEmpty(packageInfo)) {
            if (bagCodes.includes(code)){
                data.push({...item, ...bagInfo}) 
            } else {
                if (isCustomWarehouse) {
                    data.push({
                        ...item,
                        bag: isEmpty(bagInfo) ? item.outside_bag : get(bagInfo, "bag", {}), 
                    })
                }
            }
        } else {
            data.push({...item, ...packageInfo});
        }
        return true;
    })
    return (
        <div className="print-delivery-note--list">
            <div className="delivery-note-list--title gflex galign-center gflex-direction-column">
                <h2>{t('delivery_note:print.package_export_title')}</h2>
            </div>
            <Table
                className="a-table--antd"
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={row => get(row, 'package.id')}
            />
        </div>

    );
};

export default withTranslation()(List);
