import {combineReducers} from 'redux';
import CreateBag from './CreateBag/reducer';
import BagShippingPartner from '../ShippingPartner/reducer';
import listBag from './ListBag/reducer';
import bagPacking from './BagPacking/reducer';
import deletePackage from './DeletePackage/reducer';
import updateWeightReal from './UpdateWeightRealBag/reducer';
import completePackingBag from './CompletePackingBag/reducer';
import bagDetail from './BagDetail/reducer';
import listSameDayBags from './ListSameDayBags/reducer';
import updateBagParam from './UpdateBagParam/reducer';
import bagPackingAction from './BagPackingAction/reducer';
import bagPackingLastmileAction from './BagPackingLastmile/reducer';

export default combineReducers({
    CreateBag,
    completePackingBag,
    updateWeightReal,
    BagShippingPartner,
    listBag,
    bagPacking,
    deletePackage,
    bagDetail,
    listSameDayBags,
    updateBagParam,
    bagPackingAction,
    bagPackingLastmileAction
});
