import React from 'react';
import {Input, Pagination, Spin} from 'antd';
import {debounce, get} from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import {Link} from './../../../../system/routing';

const Search = Input.Search;

class OrderPackages extends React.PureComponent {
    state = {
        code: undefined
    };

    onSearch = event => {
        const { value } = event.target;

        this.setState({
            code: value
        });
        this.doEmitOnSearch('code', value.trim());
    };

    emitOnSearch = (name, value) => {
        const { onSearch } = this.props;

        onSearch(name, value);
    };

    doEmitOnSearch = debounce(this.emitOnSearch, 250);

    filterPackages = () => {
        const {packageCode} = this.state;
        const {packages} = this.props;

        return packages.filter(packageData => packageCode ? packageData.code === packageCode : true)
    };

    handleChangePage = page => {
        this.emitOnSearch('page', page);
    };

    render() {
        const { code } = this.state;
        const { currentPackageId, filter, loading, order, orderPackages, t } = this.props;

        return (
            <Spin spinning={loading}>
                <div className="list-order">
                    <div className="list-order--top">
                        <h3 className="title">{t('package:label.order')} <span>- {order.code}</span></h3>
                        <div className="list-order--top--right">
                            <Search
                                placeholder={t('btn.search')}
                                className="a-input--search-order"
                                value={code}
                                onChange={this.onSearch}
                            />
                            <Pagination
                                current={filter.page}
                                pageSize={filter.per_page}
                                total={filter.total || 0}
                                hideOnSinglePage={true}
                                onChange={this.handleChangePage}
                                className="a-pagination--order-package"
                            />
                        </div>
                    </div>
                    {orderPackages.length ? (
                        <ul className="list-order--detail">
                            {orderPackages.map(orderPackage => (
                                <li
                                    className={currentPackageId === get(orderPackage, 'id') ? 'active' : ''}
                                    key={get(orderPackage, 'id')}
                                >
                                    <Link
                                        params={{id: get(orderPackage, 'code')}}
                                        to="packages.detail"
                                    >
                                        <span>{get(orderPackage, 'code')}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div>{t('message.no_data')}</div>
                    )}
                </div>
            </Spin>
        );
    }
}

OrderPackages.defaultProps = {
    order: {},
    orderPackages: [],
    currentPackageId: null,
    loading: false
};

OrderPackages.propTypes = {
    order: PropTypes.object,
    orderPackages: PropTypes.array,
    currentPackageId: PropTypes.number,
    loading: PropTypes.bool
};

export default withTranslation()(OrderPackages);
