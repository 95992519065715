import React, { useEffect, useRef, useState } from 'react'
import { Input } from 'antd'
import { CURRENCY_REGEX, formatCurrency } from './numberFormat'
export default function CurrencyInput(props) {
    let { value, onChange, onBlur, onFocus, onPressEnter, currency } = props
    let [edit, setEdit] = useState(false)
    let [data, setData] = useState(value ? parseFloat(value) : '')
    const labelRef = useRef()
    const valueRef = useRef()

    useEffect(() => {
        setData(value ? value : '')
    }, [value])

    function _onChange(e) {
        let newValue = e.target.value

        if (CURRENCY_REGEX.test(newValue)) {
            setData(newValue)
            if (typeof onChange === 'function') onChange(newValue)
        }
    }

    function _onBlur() {
        setEdit(false)
        if (typeof onBlur === 'function') onBlur(data)
    }

    function _onFocus() {
        setEdit(true)
        if (typeof onFocus === 'function') onFocus(data)
    }

    function _onPressEnter() {
        valueRef.current.blur()
        if (typeof onPressEnter === 'function') onPressEnter(data)
    }

    return edit ? (
        <Input
            {...props}
            value={data}
            onChange={_onChange}
            onBlur={_onBlur}
            onPressEnter={_onPressEnter}
            ref={valueRef}
        />
    ) : (
        <Input
            style={props.style}
            placeholder={props.placeholder}
            className={props.className}
            onFocus={_onFocus}
            ref={labelRef}
            value={data ? formatCurrency(data, currency ? currency : null) : ''}
        />
    )
}
