/* eslint-disable */
import React, {Component} from 'react';
import {Checkbox, Icon, Input, Radio} from "antd";

class InlineEditService2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconedit: true,
            iconremove: false,
            iconsave: false,
            service:true,
        }
    }

    onClickEditInline = () => {
        this.setState({
            iconedit: !this.state.iconedit,
            iconremove: !this.state.iconremove,
            iconsave: !this.state.iconsave,
            service: !this.state.service,
        })
    }

    render() {
        const {iconedit, iconremove, iconsave,service} = this.state;
        return (
            <div className={`a-inline-edit ${service ? 'service-view' : 'service-edit'}`}>
                <label className="a-inline-edit--label">Dịch vụ sử dụng
                    <Icon type="edit" onClick={this.onClickEditInline}
                          className={`icon-inline ${iconedit ? '' : 'a-hidden'} `}/>
                    <Icon type="close" onClick={this.onClickEditInline} className="icon-inline"
                          className={`icon-inline ${iconremove ? '' : 'a-hidden'} `}/>
                    <Icon type="check" onClick={this.onClickEditInline} className="icon-inline ml-0"
                          className={`icon-inline ${iconsave ? '' : 'a-hidden'} `}/>
                </label>
                <div className={`a-inline-edit--value`}>
                    <div class={`a-service `}>
                        <div className={`service--body ${service ? '' : 'a-hidden'}`}>
                            <div className="service--block">
                                <div className="mt-3">
                            <span className="service-empty mt-4">
                                <Icon type="exclamation-circle" theme="filled" className="a-text--orange"/> Không có dịch vụ sử dụng</span>
                                </div>
                                <div className="service--block--body">
                                    <ul>
                                        <li>
                                            <span>Chuyển thẳng</span>
                                            <span>Đóng gỗ riêng </span>
                                            <span>Kiểm hàng </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div  className={`service--body ${service ? 'a-hidden' : ''}`}>
                            <div className="service--block">
                                <h4 className="title--block">
                                    Dịch vụ vận chuyển
                                    <div className="service--block--body">
                                        <ul>
                                            <li>
                                                <Radio className="label--service" checked>Chuyển
                                                    thẳng</Radio>
                                            </li>
                                            <li>
                                                <Radio className="label--service">Chuyển
                                                    phát nhanh</Radio>
                                            </li>
                                            <li>
                                                <Radio className="label--service">Chuyển
                                                    phát tiết kiệm</Radio>
                                            </li>
                                        </ul>
                                    </div>
                                </h4>
                            </div>
                            <div className="service--block">
                                <h4 className="title--block">
                                    Dịch vụ vận chuyển
                                    <div className="service--block--body">
                                        <div className="pb-5">
                                            <ul className="mt-5">
                                                <li>
                                                    <Checkbox className="label--service" checked>
                                                        Ký gửi</Checkbox>
                                                </li>
                                                <li>
                                                    <Checkbox className="label--service">Kiểm
                                                        hàng</Checkbox>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </h4>
                            </div>
                            <div className="service--block">
                                <h4 className="title--block">
                                    Dịch vụ vận chuyển
                                    <div className="service--block--body">
                                        <div className="pb-5">
                                            <ul className="mt-5">
                                                <li>
                                                    <Radio className="label--service"
                                                           checked>Đóng
                                                        gỗ
                                                        chung</Radio>
                                                </li>
                                                <li>
                                                    <Radio className="label--service">Đóng
                                                        gỗ
                                                        riêng</Radio>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default InlineEditService2;