import {connect} from 'react-redux';
import FormInputProperty from "./components";
import * as actions from "../actions";
import * as formInputSelectors from "./selectors";
import * as selectors from "../selectors";

const mapStateToProps = (state) => {
	return ({
		errors: formInputSelectors.getErrors(formInputSelectors.getState(state, "errors", {})),
		propertyData: selectors.getState(state, 'detail', {}),
		loading: selectors.getState(state, 'loading', false)
	})
};
const mapDispatchToProps = (dispatch) => ({
	resetError: (params) => dispatch(actions.resetError(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FormInputProperty);
