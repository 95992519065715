import {createSelector} from 'reselect';
import lodash from 'lodash';

export const getDetailShippingUser =  createSelector(
    state => lodash.get(state, "shippingUser.list.users"),
    state => lodash.get(state, 'shippingUser.edit.shippingUserId', 0),
    (shippingUsers, id) => filterShippingUser(shippingUsers, id)
);

function filterShippingUser(shippingUsers, id) {
    let arrShippingUsers = shippingUsers.filter(shippingUser => "" + shippingUser.shipping_user.id === id);
    return  arrShippingUsers.length > 0 ? arrShippingUsers[0] : {};
}
