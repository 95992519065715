import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Modal, notification } from 'antd'
import UploadFile from '../../../Upload/UploadFile'
import { get, isEmpty } from 'lodash'
import i18next from 'i18next'
import apiService from '../../apiService'
import { getVar } from '../../../../system/support/helpers'

function AddWarehouseArea(props) {
    const { t, warehouse, refetch } = props
    const [visible, setVisible] = useState(false)
    const [messages, setMessages] = useState({})

    function handleClick() {
        setVisible(true)
    }

    function renderError(errorLine) {
        const line = getVar(errorLine, 'line')
        const errors = getVar(errorLine, 'errors', {})
        const contentErrors = []
        Object.keys(errors).map(attribute => {
            const attributeValue = getVar(errorLine, attribute, '')
            const errorsOfAttribute = getVar(errors, attribute, {})
            Object.keys(errorsOfAttribute).map(error => {
                const label = t(`warehouse:label.${attribute}`)
                const value = getVar(errorsOfAttribute, `${error}.value`, '')
                return contentErrors.push(
                    t(`warehouse:message.${error}`, { attribute: `${label} ${attributeValue ? `"${attributeValue}"` : ''}`, value, min: 1 })
                )
            })
            return contentErrors
        })
        if (line) {
            return t('warehouse:message.line', { line, content: contentErrors.join(' ') })
        } else {
            Object.keys(errorLine).map(key => {
                contentErrors.push(t(`warehouse:message.${key}`))
            })
            return contentErrors.join(' ')
        }
    }

    function handleOk() {
        setMessages([])
        setVisible(false)
    }

    function handleCancel() {
        setMessages([])
        setVisible(false)
    }

    function handleUpload(file) {
        setMessages([])
        const filename = get(file, 'name', '')
        const warehouseId = getVar(warehouse, 'id')
        let formData = new FormData()

        formData.append('file', file)
        apiService
            .uploadWarehouseArea(warehouseId, formData)
            .then(() => {
                notification.success({ message: t('warehouse:message.upload_file.success', { filename }) })
                setMessages([])
                refetch()
                setVisible(false)
            })
            .catch(err => {
                const { response } = err
                const code = getVar(response, 'data.code')
                let errors = getVar(response, 'data.data.file', [])

                if (code === 'INPUT_INVALID') {
                    if (errors.length === 0) {
                        errors.push(getVar(response, 'data.data'))
                    }
                    setMessages(errors)
                } else if (code === 403) {
                    notification.error({ message: t('message.403_action') })
                } else {
                    notification.error({ message: t('warehouse:message.upload_file.failed', { filename }) })
                }
            })
    }

    return (
        <React.Fragment>
            <Button
                onClick={handleClick}
                icon="plus"
                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _a-btn-create-warehouse-staff">
                Thêm vị trí
            </Button>
            {visible && (
                <Modal
                    title={t('warehouse:title.upload_create_warehouse_area')}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    maskClosable={false}>
                    <p>
                        - {t('label.download_sample_file')}:
                        <strong>
                            <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/${i18next.language}_file_upload_warehouse_area.xlsx`}>
                                {' '}
                                {t('warehouse:label.file_upload_create_warehouse_area')}
                            </a>
                        </strong>
                    </p>
                    <p className="mb-3">
                        - {t('label.maximum_file_size')}: <strong>5MB</strong>
                    </p>
                    <UploadFile
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                        maxFileSize={5}
                        showUploadList={true}
                        name={t('warehouse:label.file_upload_create_warehouse_area')}
                    />
                    {!isEmpty(messages) && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="a-text--red">
                                        - {renderError(item)}
                                    </p>
                                )
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </React.Fragment>
    )
}
export default withTranslation()(AddWarehouseArea)
