import React, {Fragment} from 'react'
import {withTranslation} from "react-i18next";
import {Button, Modal,} from 'antd'
import PropTypes from 'prop-types';
import {trans} from "../../../../system/i18n";

const {confirm} = Modal;


class RemoveStaffInWarehouse extends React.Component {
    onOk(data) {

        this.props.removeStaffInWarehouse(data)
    }

    onRemoveStaff() {
        const {staff, warehouseData, t} = this.props;
        let data = {};
        data.id_staff = staff.id;
        data.id_warehouse = warehouseData.id;
        confirm({
            title: t('label.warning'),
            content: trans('config:message.remove_staff_in_warehouse', {
                staff: <b>{staff.name}</b>
            }),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            okButtonProps: {
                loading: this.props.loading,
                className: "_btn-ok-delete"
            },
            cancelButtonProps: {
                loading: this.props.loading,
                className: "_btn-cancel-delete"
            },
            onOk: this.onOk.bind(this, data),
            onCancel() {
            },
        });
    }

    render() {
        const {t} = this.props;
        return <Fragment>
            <Button onClick={this.onRemoveStaff.bind(this)}
                    className={"a-btn--button-link mr-auto a-text--blue a-btn--bg-transparent _btn-delete"}>{t("common:btn.delete")}</Button>
        </Fragment>
    }
}

RemoveStaffInWarehouse.defaultProps = {
    warehouseData: {},
    staff: {},
    loading: false,
    removeStaffInWarehouse: () => {
    },

};

RemoveStaffInWarehouse.propTypes = {
    warehouseData: PropTypes.object,
    loading: PropTypes.bool,
    staff: PropTypes.object,
    removeStaffInWarehouse: PropTypes.func

};

export default withTranslation()(RemoveStaffInWarehouse)
