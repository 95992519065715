import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getVar } from './../../../../../../system/support/helpers';

import Bags from './OrderDetail/Bags';
import OrderInfo from './OrderDetail/OrderInfo';
import Packages from './OrderDetail/Packages';

const OrderDetail = ({ customer, form, order, deliveryNote}) => (
    <PerfectScrollbar
        className="order-detail"
        options={{
            suppressScrollX: true,
            wheelPropagation: false
        }}
    >
        <OrderInfo
            deliveryNote={deliveryNote}
            className="mb-4"
            customer={customer}
            form={form}
            order={order}
        />

        {getVar(order, 'bag') ? (
            <Bags
                bags={[getVar(order, 'bag', {})]}
            />
        ) : (
            <Packages
                packages={getVar(order, 'packages', [])}
            />
        )}
    </PerfectScrollbar>
);

export default OrderDetail;
