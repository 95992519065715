import {connect} from 'react-redux';
import SiderX from './components/';
import lodash from 'lodash';
import {clearPrintState} from "../PrintStamp/actions";

const mapStateToProps = (state) => ({
    permissions: lodash.get(state, 'auth.user.permissions', []),
    menuActive: lodash.get(state, 'common.menuActive', '')
});

const mapDispatchToProps = (dispatch, props) => ({
    clearPrintState: () => dispatch(clearPrintState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiderX);
