import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import NoteLogMessage from './LogMessages/NoteLogMessage';
import PackageLogMessage from './LogMessages/PackageLogMessage';
import BagLogMessage from './LogMessages/BagLogMessage';
import DeliveryNote from './LogMessages/DeliveryNoteLogMessage';
import ShippingFeeLogMessage from './LogMessages/ShippingFeeLogMessage';
import StorageFeeLogMessage from './LogMessages/StorageFeeLogMessage';
import PackingRequestLogMessage from './LogMessages/PackingRequestLogMessage';

class LogMessage extends React.PureComponent {
    render() {
        const {log} = this.props;

        if (lodash.startsWith(log.action, 'NOTE')) {
            return <NoteLogMessage log={log} />;
        }

        if (lodash.startsWith(log.action, 'PACKAGE.')) {
            return <PackageLogMessage log={log}/>
        }

        if (lodash.startsWith(log.action, 'BAG.')) {
            return <BagLogMessage log={log}/>
        }

        if (lodash.startsWith(log.action, 'DELIVERY_NOTE.') || lodash.startsWith(log.action, 'DELIVERY_NOTE_RETURN.') || lodash.startsWith(log.action, 'DELIVERY_NOTE_TRANSPORT_WAREHOUSE.')) {
            return <DeliveryNote log={log}/>
        }

        if (lodash.startsWith(log.action, 'SHIPPING_FEE.')) {
            return <ShippingFeeLogMessage log={log} />;
        }

        if (lodash.startsWith(log.action, 'STORAGE_FEE.')) {
            return <StorageFeeLogMessage log={log} />;
        }

        if (lodash.startsWith(log.action, 'PACKAGING_REQUEST.')) {
            return <PackingRequestLogMessage log={log}/>
        }

        return log.action;
    }
}

LogMessage.defaultProps = {
    log: {},
};

LogMessage.propTypes = {
    log: PropTypes.object,
};

export default withTranslation()(LogMessage);
