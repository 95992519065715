export const LASTMILE_ORDER_COLUMN = {
    TRACKING_NO: 'tracking_no',
    PACKAGE_CODE: 'package_code',
    DELIVERY_REQUEST_CODE: 'delivery_request_code',
    DELIVERY_NOTE_CODE: 'delivery_note_code',
    CUSTOMER_USERNAME: 'customer_username',
    ID_LAST_MILE_CARRIER: 'id_last_mile_carrier',
    CREATE_AT: 'created_at',
    EXPORTED_AT: 'exported_at',
    DELIVERED_AT: 'delivered_at',
    RETURNED_AT: 'returned_at',
    STATUS: 'status',
    ID_AGENCY: 'id_agency',
    ID_WAREHOUSE: 'id_warehouse',
    ID_CREATOR: 'id_creator',
}

export const LASTMILE_TRACKING_NO_STATUS = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    DELIVERY_FAILED: 'DELIVERY_FAILED',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    CANCELLED: 'CANCELLED',
}
