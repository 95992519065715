/* eslint-disable */
import React, {Component} from 'react';
import {
    Layout,
    Form,
    Select,
    Table,
    Pagination,
    Button,
    Menu,
    Dropdown,
    Row,
    Col,
    Icon,
    Checkbox,
    Input,
    DatePicker
} from 'antd';

const {Content} = Layout;
const Option = Select.Option;
const Search = Input.Search;
const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'Mã quét',
        dataIndex: 'codescan',
        key: 'codescan',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    },{
        title: 'Thời gian quét',
        dataIndex: 'timescan',
        key: 'timescan',
        className: "a-text--nowrap",
        sorter: true,
    }, {
        title: 'Loại mã',
        dataIndex: 'codetype',
        key: 'codetype',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Nội dung quét',
        dataIndex: 'contentscan',
        key: 'contentscan',
        render: text => <span>{text}</span>,
    }, {
        title: 'Mã kiện',
        dataIndex: 'packagecode',
        key: 'packagecode',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    }, {
        title: 'Hành động',
        dataIndex: 'action',
        key: 'action',
        render: text => <span>{text}</span>,
    }
    , {
        title: 'Kho quét',
        dataIndex: 'warehousescan',
        key: 'warehousescan',
        className: "a-text--nowrap",
    }, {
        title: 'Kho đích',
        dataIndex: 'destinationwarehouse',
        key: 'destinationwarehouse',
        className: "a-text--nowrap",
    }, {
        title: 'Đơn hàng',
        dataIndex: 'order',
        key: 'order',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap"><img className="logo-shop"
                                                                                     src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                                                                     alt=""/>{text}</a>,
    }, {
        title: 'Người quét',
        dataIndex: 'personscan',
        key: 'personscan',
        render: text => <span className="a-text--nowrap">{text}</span>,

    }];

const data = [];
for (let i = 0; i < 20; i++) {
    data.push({
        packagecode: `030219956${i}`,
        codescan: `030219956${i}`,
        timescan: `00.00 03/02/1995`,
        contentscan: `Lorem scan isumpe`,
        order: `3221619951995${i}`,
        destinationwarehouse: `CNGZ${i}`,
        warehousescan:`CNGZ`,
        action: `Nhập`,
        codetype: `Mã Logistic`,
        personscan: `Lê Ngọc Yến Nhi`,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);
class HistoryBarcodeScan extends Component {
    constructor(props){
        super(props);
        this.state={
            heightwidow:0,
            heightheader:84,
            heightsearch:0,
            contentlist:0,
            titlesearch:0,
        }
    }

    render() {

        return (
            <Layout>
                <Content className="a-content a-content--page-list" >
                    <div className="a-content--search-header search-default" id="list-search">
                        <Row gutter={24}>
                            <Form className="a-form">
                                <Col lg={18} xl={17} xxl={18} className="search--left">
                                    <Row gutter={{lg: 12, xl: 12, xxl: 24}}>
                                        <Col lg={4} xl={3} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Hành động"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} xl={3} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Kho quét"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                    <Option value="CNGZ">CNGZ</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} xl={3} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Kho đích"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                    <Option value="CNGZ">CNGZ</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={7} xl={4} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Loại mã"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                    <Option value="793875938475938">793875938475938</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={9} xl={7} xxl={5}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Ngày quét"
                                                labelCol={{span: 24}}>
                                                <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} xl={6} xxl={5}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Nội dung quét"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="CNGZ">
                                                    <Option value="CNGZ">Xuất kho theo bao</Option>
                                                    <Option value="VNSG">Quét mã tạo kiện</Option>
                                                    <Option value="VNHN">Quét mã vạch</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={7} xl={6} xxl={5}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Người quét"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Lê Hoàng Huyền Trang">
                                                    <Option value="Lê Hoàng Huyền Trang">Lê Hoàng Huyền Trang</Option>
                                                    <Option value="Lê Đặng Hoàng Vũ">Lê Đặng Hoàng Vũ</Option>
                                                    <Option value="Lê Ngọc Ánh">Lê Ngọc Ánh</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} xl={7} xxl={6} className="search--right">
                                    <Form.Item
                                        className="a-form__item search-content item--special"
                                        label="Mã quét / kiện / đơn"
                                        labelCol={{span: 24}}>
                                        <Search
                                            placeholder="Mã quét / kiện / đơn"
                                            className="a-input--search"
                                        />
                                    </Form.Item>
                                    <div className="search--action">
                                        <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                                        <Button type="primary" htmlType="submit"
                                                className="a-btn a-btn--primary a-btn--search-list ">Tìm
                                            kiếm</Button>
                                    </div>
                                </Col>
                            </Form>
                        </Row>
                    </div>
                    <div className="a-content__top a-content__top--page-list">
                        <div className="a-content--page-list__title" id="title-search">
                            <h3>Danh sách lịch sử quét mã - <span>32216</span></h3>
                        </div>
                        <div className="a-content--page-list__top-action" id="content-list" >
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                    ghost>Xuất CSV
                            </Button>
                            <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                                <Button type="primary"
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                        ghost>Thiết lập cột thông tin
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="a-content__body bg-white a-content__body--page-list " >
                        <Table className={"a-table--antd"} columns={columns} dataSource={data}
                               pagination={false}
                        />
                        <div className="a-pagination">
                            <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                            <div className="a-pagination--select-number-item">
                                <span className="text-show">Hiển thị</span>
                                <Select defaultValue="10" className="a-select--select-number-show">
                                    <Option value="10">10</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        )
    }
}

export default HistoryBarcodeScan;