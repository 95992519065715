import React from 'react';

const Box3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(-0.348 -0.348)">
        <path className="c" d="M15.3,4.7a.348.348,0,0,0,.283-.55L13.078.639a.7.7,0,0,0-.567-.291H8.87A.348.348,0,0,0,8.522.7V4.348A.348.348,0,0,0,8.87,4.7Z" />
        <path className="c" d="M.7,5.739a.348.348,0,0,0-.348.348v9.565a.7.7,0,0,0,.7.7H15.652a.7.7,0,0,0,.7-.7V6.087A.348.348,0,0,0,16,5.739Zm8,6.783a.348.348,0,0,1,.348-.348h4.87a.348.348,0,0,1,.348.348v1.391a.348.348,0,0,1-.348.348H9.043a.348.348,0,0,1-.348-.348Z" />
        <path className="c" d="M7.478.7A.348.348,0,0,0,7.13.348H4.184a.7.7,0,0,0-.567.291l-2.5,3.506A.348.348,0,0,0,1.4,4.7H7.13a.348.348,0,0,0,.348-.348Z" />
      </g>
    </g>
  </svg>
);

export default Box3;
