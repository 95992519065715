import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Select} from 'antd';

const Option = Select.Option;

class FormDeliveryType extends React.Component {
    changeInput(input) {
        this.props.onChange(input.value);
    }

    render() {
        const {allowClear, deliveryNoteStatuses, disabled, dropdownClassName, loading, placeholder, value} = this.props;
        return (
            <React.Fragment>
                <Select
                    allowClear={allowClear}
                    dropdownClassName={dropdownClassName}
                    placeholder={placeholder}
                    loading={loading}
                    className="a-select a-select--w-100 _subject_shipping"
                    showSearch={true}
                    optionFilterProp="children"
                    value={loading ? undefined : value}
                    disabled={loading || disabled}
                    onChange={value => this.changeInput({value})}
                >
                    {deliveryNoteStatuses.map(deliveryNoteStatus => (
                        <Option className="_status_customer__item"
                                key={deliveryNoteStatus.id}
                                value={deliveryNoteStatus.id}>{deliveryNoteStatus.name}</Option>
                    ))}
                </Select>
            </React.Fragment>
        );
    }
}

FormDeliveryType.defaultProps = {
    emptyOption: true,
    deliveryNoteStatuses: [],
    disabled: false,
    onChange: () => {
    },
    loading: false
};

FormDeliveryType.propTypes = {
    deliveryNoteStatuses: PropTypes.array,
    loading: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    emptyOption: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withTranslation()(FormDeliveryType);