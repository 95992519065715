import { get, includes, isObject, map, omit, round } from 'lodash';
import { combineReducers } from 'redux';

import { CLEAR_STATE, CREATE, DELETE_SUGGEST, GET_DELIVERY_CUSTOMER_REQUESTS, RESET_ERROR, SET_ACTIVE_SUGGEST_KEYS, CREATE_WITH_TRACKING_NO } from './constants';
import { ADD_BARCODE, REMOVE_BARCODE, SCAN_BARCODE } from './../DeliveryNoteBarcode/constants';
import { addBarcode, removeBarcode } from './../DeliveryNoteBarcode/reducer';
import { CANCEL_DELIVERY_NOTE } from './../CancelDeliveryNote/constants';
import { DELIVERY_NOTE_EXPORT } from './../DeliveryNoteExport/constants';
import { PLACE_DELIVERY_NOTE } from './../PlaceDeliveryNote/constants';
import { BARCODE_TYPE } from './../../Barcode/constants';
import { STAGES, UPDATE_NOTE } from './../../Notes/constants';

const creatings = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case CREATE.REQUEST: {
            return {
                ...state,
                [payload.identity]: true
            }
        }
        case CREATE.FAILED:
        case CREATE.SUCCESS: {
            if (!request || !request.identity) {
                return state;
            }

            return {
                ...state,
                [request.identity]: false
            };
        }
        case CREATE.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const deliveryCustomerRequests = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case GET_DELIVERY_CUSTOMER_REQUESTS.SUCCESS: {
            return {
                ...state,
                [request.id_warehouse]: payload.customers
            };
        }
        default: {
            return state;
        }
    }
};

const loadingDeliveryCustomerRequests = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case GET_DELIVERY_CUSTOMER_REQUESTS.REQUEST: {
            return {
                ...state,
                [payload.id_warehouse]: true
            };
        }
        case GET_DELIVERY_CUSTOMER_REQUESTS.SUCCESS:
        case GET_DELIVERY_CUSTOMER_REQUESTS.FAILED: {
            return {
                ...state,
                [request.id_warehouse]: false
            };
        }
        default: {
            return state;
        }
    }
};

const errors = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case CREATE.REQUEST:
        case CLEAR_STATE: {
            return {};
        }
        case CREATE.FAILED: {
            const { identity } = request;

            return {
                ...state,
                [identity]: payload
            };
        }
        case RESET_ERROR: {
            if (!state[payload.identity] || !state[payload.identity].data) {
                return state;
            }

            return {
                ...state,
                [payload.identity]: {
                    ...state[payload.identity],
                    data: omit(state[payload.identity].data, payload.field)
                }
            };
        }
        default: {
            return state;
        }
    }
};

const suggests = (state = {
    activeSuggestKeys: [],
    suggests: []
}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case SCAN_BARCODE.SUCCESS: {
            // when a barcode has been scan successfully, we only continue processing
            // if that barcode has customer or we are using new create delivery note form (have multiple param in request = true)
            // then we find suggested delivery note of customer of scanned barcode and not created yet
            // if we found suggested delivery note, we add barcode to that delivery note
            // otherwise create new suggest delivery note and add barcode to that new delivery note
            const { customer } = payload;
            const { scanTime } = request;
            const customerId = get(customer, 'id');
            const multiple = get(request, 'params.multiple');
            let newActiveSuggestKeys = state.activeSuggestKeys;
            let newSuggests = state.suggests;
            let editedSuggest = undefined;
            let identity = `${customerId}_${scanTime}`;


            // skip if using old create delivery note form or barcode doesnt have customer
            if (!multiple || !customerId) {
                return state;
            }

            // find suggested delivery note of customer of scanned barcode and not created yet
            const index = newSuggests.findIndex(suggest => !get(suggest, 'delivery_note.id') && get(suggest, 'customer.id') === customerId);

            if (index === -1) {
                // if not found, create new delivery note
                editedSuggest = {
                    customer,
                    identity,
                    barcodes: [],
                };
                newActiveSuggestKeys = identity;
            } else if (request.code) {
                editedSuggest = {
                    ...newSuggests[index]
                };
            }

            let _type = BARCODE_TYPE.PACKAGE;
            let warning = payload.package_warning;
            const _code = action.request.code;

            const data = {
                type: _type,
                warning: warning,
                ...payload
            };

            // If scan bag code
            if (isObject(payload.bag) && payload.bag.code === _code) {
                data.type = BARCODE_TYPE.BAG;
                data.warning = payload.bag_warning;
            }

            editedSuggest.barcodes = addBarcode(editedSuggest.barcodes, _code, data);

            if (editedSuggest.barcodes.length === 1) {
                const barcode = editedSuggest.barcodes[0];
                const barcodeType = get(barcode, 'type', '').toLowerCase();

                editedSuggest = {
                    ...editedSuggest,
                    delivery_note: {
                        identity: 1,
                        length: get(payload, `${barcodeType}.length`) ? round(get(payload, `${barcodeType}.length`) * 100, 2) : undefined,
                        width: get(payload, `${barcodeType}.width`) ? round(get(payload, `${barcodeType}.width`) * 100, 2) : undefined,
                        height: get(payload, `${barcodeType}.height`) ? round(get(payload, `${barcodeType}.height`) * 100, 2) : undefined
                    }
                };
            } else {
                editedSuggest = {
                    ...editedSuggest,
                    delivery_note: {
                        identity: get(newSuggests[index], 'delivery_note.identity') ? newSuggests[index].delivery_note.identity + 1 : 1,
                        length: undefined,
                        width: undefined,
                        height: undefined
                    }
                };
            }

            if (index === -1) {
                newSuggests = [
                    ...newSuggests,
                    editedSuggest
                ];
            } else {
                newSuggests = [
                    ...newSuggests.slice(0, index),
                    editedSuggest,
                    ...newSuggests.slice(index + 1)
                ];
            }

            return {
                activeSuggestKeys: newActiveSuggestKeys,
                suggests: newSuggests
            };
        }
        case CREATE.SUCCESS: {
            const { customer } = payload;
            const customerId = get(customer, 'id');
            // find suggesting delivery note of customer and not created yet
            const index = state.suggests.findIndex(suggest => !get(suggest, 'delivery_note.id') && get(suggest, 'customer.id') === customerId);

            if (!customerId || index === -1) {
                return state;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    {
                        ...state.suggests[index],
                        ...payload,
                        identity: request.identity
                    },
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case CANCEL_DELIVERY_NOTE.SUCCESS:
        case DELIVERY_NOTE_EXPORT.SUCCESS: {
            const { identity } = payload;
            const index = state.suggests.findIndex(suggest => get(suggest, 'identity') === identity);

            if (index === -1) {
                return state;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    {
                        ...state.suggests[index],
                        ...payload
                    },
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case PLACE_DELIVERY_NOTE.SUCCESS: {
            const { identity } = payload;
            const index = state.suggests.findIndex(suggest => get(suggest, 'identity') === identity);

            if (index === -1) {
                return state;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    {
                        ...state.suggests[index],
                        ...payload
                    },
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case DELETE_SUGGEST: {
            const index = state.suggests.findIndex(suggest => suggest.identity === payload);

            if (index === -1) {
                return state;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case ADD_BARCODE: {
            const index = state.suggests.findIndex(suggest => suggest.identity === payload.identity);

            if (index === -1) {
                return state;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    {
                        ...state.suggests[index],
                        barcodes: addBarcode(state.suggests[index].barcodes, payload.code, payload.barcode)
                    },
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case REMOVE_BARCODE: {
            const index = state.suggests.findIndex(suggest => suggest.identity === payload.identity);

            if (index === -1) {
                return state;
            }

            const newSuggest = {
                ...state.suggests[index],
                barcodes: removeBarcode(state.suggests[index].barcodes, payload.code)
            };

            if (get(newSuggest, 'delivery_note.identity')) {
                newSuggest.delivery_note.identity += 1;
            }

            return {
                ...state,
                suggests: [
                    ...state.suggests.slice(0, index),
                    newSuggest,
                    ...state.suggests.slice(index + 1)
                ]
            };
        }
        case CLEAR_STATE: {
            return {
                activeSuggestKeys: [],
                suggests: []
            };
        }
        case SET_ACTIVE_SUGGEST_KEYS: {
            return {
                ...state,
                activeSuggestKeys: payload
            };
        }
        case UPDATE_NOTE.SUCCESS: {
            if (payload.stage !== STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE) {
                return state;
            }

            const newSuggests = state.suggests.map(suggest => {
                const barcodes = map(get(suggest, 'barcodes'), barcode => {
                    const index = get(barcode, 'notes', []).findIndex(note => note.id === get(payload, 'note.id'));

                    if (index === -1) {
                        return barcode;
                    }

                    const isRemoveNote = !includes(get(payload, 'note.stages'), STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE);
                    const newNotes = isRemoveNote ? [
                        ...barcode.notes.slice(0, index),
                        ...barcode.notes.slice(index + 1)
                    ] : [
                        ...barcode.notes.slice(0, index),
                        {
                            ...barcode.notes[index],
                            ...get(payload, 'note', {})
                        },
                        ...barcode.notes.slice(index + 1)
                    ];

                    return {
                        ...barcode,
                        notes: newNotes
                    }
                });

                return {
                    ...suggest,
                    barcodes
                };
            });

            return {
                ...state,
                suggests: newSuggests
            };
        }
        default: {
            return state;
        }
    }
};

const creatingWithTrackingNo = (state = false, action) => {
    switch (action.type) {
        case CREATE_WITH_TRACKING_NO.REQUEST: {
            return true;
        }
        case CREATE_WITH_TRACKING_NO.SUCCESS:
        case CREATE_WITH_TRACKING_NO.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    creatings,
    deliveryCustomerRequests,
    loadingDeliveryCustomerRequests,
    errors,
    suggests,
    creatingWithTrackingNo
});
