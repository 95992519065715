import { notification } from 'antd';
import lodash from 'lodash';

import { SCAN_BARCODE } from './constants';
import { getBarcodeError } from './selectors';
import apiService from './../apiService';
import { DELIVERY_OBJECT } from './../constants';
import { processApiRequest } from './../../Common/saga';
import { t } from './../../../system/i18n';

export function* scanBarcode(action) {
    const { payload } = action;

    // Gọi lên server check barcode
    let { params } = payload;

    if (params.id_warehouse) {
        params = {
            ...params,
            id_warehouse_scan: params.id_warehouse
        };
    }

    let url = 'export-customer';

    if (params.type === DELIVERY_OBJECT.RETURN) {
        url = 'export-return';
    }

    yield processApiRequest(
        SCAN_BARCODE,
        () => apiService.scanBarcode(url, payload.code, params),
        action.payload
    );
}

export function* scanBarcodeFailed(action) {
    if (action.payload) {
        const error = lodash.get(action, 'payload.data');

        if (lodash.get(error, 'warehouse')) {
            return yield notification.error({
                message: t('message.warehouse_not_is_staff')
            });
        }

        return yield notification.error({
            message: getBarcodeError(action.payload)
        });
    }
}
