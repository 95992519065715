import React, { Component } from 'react'
import { connect } from 'react-redux'
import lodash from 'lodash'
import { print9710Stamp } from '../../Common/PrintStamp/actions'
import { Button, Modal, notification } from 'antd'
import { t } from '../../../system/i18n'
import apiService from '../apiService'

const mapStateToProps = ({ common }) => {
    const { loadingPrintTemplate } = common

    return {
        loadingPrintTemplate,
    }
}

const mapDispatchToProps = dispatch => ({
    print9710Stamp: data => {
        dispatch(print9710Stamp(data))
    },
})

class Print9710StampButton extends Component {
    state = {
        loading: false,
        modal: undefined,
    }

    handlePrintStamp = () => {
        const { confirmBeforePrint, confirmTitle } = this.props

        if (confirmBeforePrint) {
            this.setState({
                modal: Modal.confirm({
                    cancelText: t('btn.cancel'),
                    okText: t('btn.ok'),
                    title: confirmTitle,
                    onOk: this.printOfficialStamp,
                }),
            })
        } else {
            this.printOfficialStamp()
        }
    }

    printOfficialStamp = async () => {
        const { modal } = this.state
        const { packageData } = this.props

        if (modal) {
            modal.destroy()
        }

        this.setState({
            loading: true,
        })

        try {
            const response = await apiService.getPackage(lodash.get(packageData, 'code'))
            const data = lodash.get(response, 'data')
            const printedOfficialStamp = lodash.get(data, 'package.package_detail.printed_official_stamp')

            if (data) {
                if (printedOfficialStamp) {
                    Modal.confirm({
                        cancelText: t('btn.cancel'),
                        okText: t('btn.ok'),
                        title: t('package:printed_confirm'),
                        onOk: this.doPrintOfficialStamp,
                    })
                } else {
                    this.doPrintOfficialStamp(data)
                }
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                })
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            })
        }

        this.setState({
            loading: false,
        })
    }

    doPrintOfficialStamp = async () => {
        const { packageData, print9710Stamp } = this.props

        this.setState({
            loading: true,
        })

        try {
            const response = await apiService.get9710StampData(lodash.get(packageData, 'code'))
            const data = lodash.get(response, 'data')

            if (data) {
                setTimeout(() => {
                    print9710Stamp({ ...data })
                }, 1500)
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                })
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            })
        }

        this.setState({
            loading: false,
        })
    }

    render() {
        const { loading } = this.state
        const { className, ghost, loadingPrintTemplate, packageData } = this.props

        if (!lodash.get(packageData, 'permissions.can_print_9710_stamp')) {
            return null
        }

        return (
            <Button
                className={className}
                ghost={ghost}
                loading={loading || loadingPrintTemplate}
                onClick={this.handlePrintStamp}>
                {t('package:btn.print_9710_stamp')}
            </Button>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Print9710StampButton)
