import React, {memo, useState} from 'react';
import {Icon} from 'antd';
import clsx from 'clsx';
import {isEqual, map, find, get, sumBy} from 'lodash';
import moment from 'moment';

import {BARCODE_TYPE} from '../../../../DeliveryNoteBarcode/constants';
import {useDeepCompareEffect} from './../../../../../../hooks';
import {getVar} from './../../../../../../system/support/helpers';

import CanDeliveryItem from './CanDeliveryItem';
import ChooseOrderChangeReadyForDeliveries from "./ChooseOrderChangeReadyForDeliveries";
import {DELIVERY_NOTE_ACTION} from "../../../../constants";
import { trans } from '../../../../../../system/i18n';

const CanDeliveries = (props) => {
    const {
        loading,
        canDeliveries,
        t,
        onOpenPackageListByBagPopup,
        onReload,
        showDeliveryRequestDetailModal,
        agency,
        settings
    } = props;

    const [scrollTable, setScrollTable] = useState(false);
    const [sortedCanDeliveries, setSortedCanDeliveries] = useState([]);

    useDeepCompareEffect(() => {
        const sortedCanDeliveries = canDeliveries ? canDeliveries.slice() : [];

        sortedCanDeliveries.sort((a, b) => {
            if (a.type === BARCODE_TYPE.PACKAGE && b.type === BARCODE_TYPE.BAG) {
                return -1;
            }

            if (b.type === BARCODE_TYPE.PACKAGE && a.type === BARCODE_TYPE.BAG) {
                return 1;
            }

            let compareValue1, compareValue2;

            if (a.type === BARCODE_TYPE.PACKAGE) {
                compareValue1 = moment(getVar(a, 'package.ready_for_delivery_at'));
                compareValue2 = moment(getVar(b, 'package.ready_for_delivery_at'));
            }

            if (a.type === BARCODE_TYPE.BAG) {
                compareValue1 = moment(getVar(a, 'bag.completed_at'));
                compareValue2 = moment(getVar(b, 'bag.completed_at'));
            }

            if (compareValue1.isAfter(compareValue2)) {
                return -1;
            }

            if (compareValue1.isBefore(compareValue2)) {
                return 1;
            }

            return 0;
        });

        setSortedCanDeliveries(sortedCanDeliveries);
    }, [canDeliveries]);

    const onScrollTable = event => {
        const element = event.target;

        if (element.scrollWidth - element.scrollLeft === element.clientWidth) {
            setScrollTable(false);
        } else {
            setScrollTable(true);
        }
    };

    const renderTable = () => {
        if (!sortedCanDeliveries || !sortedCanDeliveries.length) {
            return (
                <div className="content-list--empty">
                    <img alt="" src={require('./../../../../../../resources/images/boxempty.png')}/>
                    <span className="info a-text--uppercase">CHƯA CÓ MÃ QUÉT NÀO</span>
                </div>
            );
        }

        return (
            <div className="a-table--responsive" onScroll={onScrollTable}>
                <table className="a-table a-table--isdata--warning-error">
                    <thead>
                    <tr>
                        <th>{t('delivery_note:label.id_export')}</th>
                        <th>{t('delivery_note:label.code_delivery_request')}</th>
                        <th>{t('delivery_note:label.id_order')}</th>
                        <th>{t('delivery_note:label.weight')} / {t('delivery_note:label.size')}</th>
                        <th>{t('delivery_note:label.warehouse_area')}</th>            
                        <th className={clsx('sticky-right', {
                            'box-shadow': scrollTable
                        })}/>
                    </tr>
                    </thead>
                    <tbody>
                    {map(canDeliveries, canDelivery => {
                        const {type} = canDelivery;
                        let key;
                        let code;

                        if (type === BARCODE_TYPE.BAG) {
                            code = getVar(canDelivery, 'bag.code');
                            key = `bag_${code}`;
                        } else if (type === BARCODE_TYPE.PACKAGE) {
                            code = getVar(canDelivery, 'package.code');
                            key = `package_${code}`;
                        }

                        return (
                            <CanDeliveryItem
                                key={key}
                                canDelivery={canDelivery}
                                scrollTable={scrollTable}
                                onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                                showDeliveryRequestDetailModal={showDeliveryRequestDetailModal}
                                agency={agency}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    };
    const showOrderChangeToReadyForDeliveries = get(find(settings, ["code", "PACKAGE:MULTIPLE_SCANS"]), "value", false);
    const countWeight = sumBy(canDeliveries, o => {
        return get(o, 'package.weight_net', 0)
    }).toFixed(2)
   

    return (
        <div className="aheight-100 a-bgwhite can-deliveries">
            <div className="apy-16 border-bottom">
                <div className="a-content--page-list__title gw-100 gflex gjustify-space-between galign-center">
                    <h3>{t('delivery_note:can_delivery_list')} ({(canDeliveries && canDeliveries.length) || 0})</h3>
                    {
                        props.action === DELIVERY_NOTE_ACTION.CREATE && !!showOrderChangeToReadyForDeliveries &&
                        <ChooseOrderChangeReadyForDeliveries canDeliveries={canDeliveries} setBarcodes={props.setBarcodes} readyForDeliveries={props.readyForDeliveries}/>
                    }
                    {loading ? (
                        <Icon
                            className="icon-loading"
                            type="loading-3-quarters"
                        />
                    ) : (
                        <a
                            href="/"
                            onClick={onReload}
                        >
                            <Icon type="sync" className="mr-1"/>
                            {t('btn.reset')}
                        </a>
                    )}
                </div>
            </div>
            <div className="apy-16">
                <div className='a-text--right mb-1 a-text--bold'>{trans("package:label.total_weight_net",{weight: countWeight})} kg</div>
                {renderTable()}
            </div>
        </div>
    );
};

export default memo(CanDeliveries, isEqual);
