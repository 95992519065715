import React from 'react';
import { toInteger } from 'lodash';

import SearchForm from './SearchForm';
import DamagedPackageList from './DamagedPackageList';

const DamagedPackages = ({ consignServices, filter, loading, loadingPackageDamagedReasons, packageDamagedReasons, services, onSearch, ...other }) => (
    <div className="damaged-packages">
        <SearchForm
            consignServices={consignServices}
            input={filter}
            inputVersion={toInteger(filter.i)}
            loading={loading}
            loadingPackageDamagedReasons={loadingPackageDamagedReasons}
            packageDamagedReasons={packageDamagedReasons}
            services={services}
            onSearch={onSearch}
        />
        <DamagedPackageList
            filter={filter}
            loading={loading}
            onSearch={onSearch}
            {...other}
        />
    </div>
);

export default DamagedPackages;
