import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getErrors = error => {
    let data = {...error.data};
    if (lodash.get(data, "username.unique", null) !== null) {
        delete data.username;
        data.username = {};
        data.username.unique_shipping_user = [];
    }

    if (lodash.get(data, "full_name.required_without", null) !== null) {
        delete data.full_name;
        data.full_name = {};
        data.full_name.required = [];
    }

    if (lodash.get(data, "phone.required_without", null) !== null) {
        delete data.phone;
        data.phone = {};
        data.phone.required = [];
    }

    return lodash.mapValues(data, (rules, param) => {
        let attribute = t('shipping_user:label.' + param);
        let messages = translateValidationErrors(attribute, rules);
        return lodash.first(lodash.values(messages));
    });
};
