import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* getListWarehouse(action) {
    yield processApiRequest(ACTION.GET_LIST_WAREHOUSE, () => apiService.getListWarehouse(action.payload), action.payload);
}

export function* fetchSuggestWarehouses(action) {
    yield processApiRequest(ACTION.FETCH_WAREHOUSE_SUGGEST, () => apiService.fetchSuggestWarehouses(action.payload), action.payload);
}

export function* fetchSuggestWarehouseIsPermissions(action) {
    yield processApiRequest(ACTION.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION, () => apiService.fetchSuggestWarehouses(action.payload), action.payload);
}
