import React from 'react';

const Box2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(-1.128 0.5)">
        <rect className="c" width="4.5" height="4" rx="0.333" transform="translate(4.128 7.5)" />
        <rect className="c" width="4.5" height="4" rx="0.333" transform="translate(9.628 7.5)" />
        <rect className="c" width="10" height="4" rx="0.333" transform="translate(4.128 2.5)" />
        <path className="c" d="M17.013,13.007a.757.757,0,0,0-.373-.316A.2.2,0,0,1,16.5,12.5V1.579A1.591,1.591,0,0,0,14.9,0H3.356a1.591,1.591,0,0,0-1.6,1.579V12.5a.2.2,0,0,1-.139.188.76.76,0,0,0-.373.316.8.8,0,0,0-.033.767l.28.572A1.162,1.162,0,0,0,2.531,15H15.725a1.158,1.158,0,0,0,1.042-.654l.279-.569a.806.806,0,0,0-.033-.769ZM14.949,2.368v9.474a.783.783,0,0,1-.776.789H4.089a.783.783,0,0,1-.776-.789l.04-9.474a.783.783,0,0,1,.776-.789H14.173A.783.783,0,0,1,14.949,2.368Z" transform="translate(0 0)" />
      </g>
    </g>
  </svg>
);

export default Box2;
