import {processApiRequest} from "../Common/saga";
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {router} from '../../system/routing';
import {t} from '../../system/i18n';

export function* updateBagSize(action) {
    yield processApiRequest(ACTION.UPDATE_BAG_SIZE, () => apiService.updateBagSize(action.payload.id, action.payload.data), action.payload);
}

export function* updateBagSizeSuccess() {
    yield notification['success']({
        message: t('common:message.edit_bag_size_success'),
        className: '_edit_bag_size_success'
    });
    yield router.redirect('/config/bag-sizes');
}

export function* fetchBagSizes(action) {
    yield processApiRequest(ACTION.FETCH_BAG_SIZE, () => apiService.fetchBagSizes(action.payload.params), action.payload);
}

export function* fetchSuggestBagSize(action) {
    yield processApiRequest(ACTION.FETCH_BAG_SIZE_SUGGEST, () => apiService.fetchSuggestBagSize(action.payload.params), action.payload);
}

export function* fetchDetailBagSize(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_BAG_SIZE, () => apiService.fetchDetailBagSize(action.payload.id), action.payload);
}
