import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import CreatePackageSameBarcode from './components/CreatePackageSameBarcode';

const mapStateToProps = (state, props) => {
    return {
        ...state.package.createPackageSameBarcode,
        canCreate: props.canCreate,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onCreate: () => dispatch(actions.createPackageSameBarcode(props.input)),
        onReset: () => dispatch(actions.clearState()),
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePackageSameBarcode);

Container.defaultProps = {
    input: {},
    canCreate: false,
};

Container.propTypes = {
    input: PropTypes.object,
    canCreate: PropTypes.bool,
};

export default Container;

