import React, { useEffect, useState } from 'react'
import { Modal, Spin, Typography } from 'antd'
import { withTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import apiService from './../../../Auth/apiService'

const { Paragraph } = Typography

const ScanUploadCommentImagesLinkModal = ({ orderId, t, visible, onCancel, isTerminalComment }) => {
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [url, setUrl] = useState('')

    const getToken = async () => {
        setLoading(true)
        try {
            const { data } = await apiService.getLimitedTimeToken()
            setToken(data)
        } catch (error) {}
        setLoading(false)
    }

    useEffect(() => {
        if (visible) {
            getToken()
        }
    }, [visible])

    useEffect(() => {
        const url = `${window.location.origin}/packages/upload-comment-images?orderId=${orderId}&isTerminalComment=${isTerminalComment}&auth_token=${token}`

        setUrl(url)
    }, [orderId, token])

    return (
        <Modal
            footer={null}
            title={t('package:title.upload_comment_images')}
            visible={visible}
            onCancel={onCancel}
            className="checking-package__modal-upload_comment_imagesg">
            <div className="gflex amt-16">
                <div className="flex-shrink-0">
                    <Spin spinning={loading}>
                        <QRCode
                            size={200}
                            value={url}
                        />
                    </Spin>
                </div>
                <div className="aml-16">
                    <Paragraph className="mb-0">{t('package:scan_upload_comment_images_link_hints.1')}</Paragraph>
                    <Paragraph className="mb-0">{t('package:scan_upload_comment_images_link_hints.2')}</Paragraph>
                </div>
            </div>
        </Modal>
    )
}

export default withTranslation()(ScanUploadCommentImagesLinkModal)
