import React from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { get, isNil } from 'lodash';

import { trans } from './../../../../../system/i18n';
import { getVar } from './../../../../../system/support/helpers';
import { currentFormatter, dateFormatter } from './../../../../Common/services/format';

const { Paragraph, Text } = Typography;

const STORAGE_FEE_ACTIONS = {
    CREATE: 'STORAGE_FEE.CREATE',
    UPDATE: 'STORAGE_FEE.UPDATE'
};

const StorageFeeLogMessage = ({ log }) => {
    const action = get(log, 'action', '');
    const changeFields = get(log, 'payload.change_fields', {});

    const updatedFields = Object.keys(changeFields).map(field => {
        let oldValue, newValue, key;

        switch (field) {
            case 'applied_at': {
                oldValue = dateFormatter.full(changeFields[field].old);
                newValue = dateFormatter.full(changeFields[field].new);

                break;
            }
            case 'unit_price': {
                if (changeFields[field].old) {
                    oldValue = `${currentFormatter.toLocaleStringCurrency(changeFields[field].old)} VNĐ`;
                }

                if (changeFields[field].new) {
                    newValue = `${currentFormatter.toLocaleStringCurrency(changeFields[field].new)} VNĐ`;
                }

                break;
            }
            case 'weight_by': {
                oldValue = getVar(changeFields[field], 'old');
                newValue = getVar(changeFields[field], 'new');

                if (!isNil(oldValue)) {
                    oldValue = trans(`weight_by_options.${oldValue}`)
                }

                if (!isNil(newValue)) {
                    newValue = trans(`weight_by_options.${newValue}`)
                }

                break;
            }
            default: {
                oldValue = getVar(changeFields[field], 'old');
                newValue = getVar(changeFields[field], 'new');

                break;
            }
        }

        key = 'update';

        if (!oldValue) {
            key = 'add';
        } else if (!newValue) {
            key = 'remove';
        }

        return {
            key,
            translatedField: trans(`log:storage_fee.fields.${field}`),
            oldValue,
            newValue,
        };
    });

    switch (action) {
        case STORAGE_FEE_ACTIONS.CREATE: {
            return (
                <Paragraph className="mb-2">
                    {trans('log:storage_fee.actions.create')}
                </Paragraph>
            );
        }
        case STORAGE_FEE_ACTIONS.UPDATE: {
            return updatedFields.map((updatedField, index) => (
                <Paragraph
                    className={clsx({
                        'mb-0': index === updatedFields.length - 1,
                        'mb-2': index !== updatedFields.length - 1
                    })}
                    key={index}
                >
                    {trans(`log:${updatedField.key}`, {
                        field: (
                            <Text strong>{updatedField.translatedField}</Text>
                        ),
                        new: (
                            <Text strong>{updatedField.newValue}</Text>
                        ),
                        old: (
                            <Text strong>{updatedField.oldValue}</Text>
                        )
                    })}
                </Paragraph>
            ));
        }
        default: {
            return null;
        }
    }
};

export default StorageFeeLogMessage;
