import { Button, Empty, Icon, Table } from 'antd'
import { get, toInteger, values } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../system/i18n'
import Pagination from '../../Common/components/Pagination'
import { dateFormatter } from '../../Common/services/format'
import ListPackageSetting from '../../Package/ListPackage/ListPackageSettingContainer'
import { PACKAGE_COLUMN } from '../../Package/ListPackage/constants'
import authService from '../../Auth/authService'
import permissions from '../../Auth/permissions'
import { Link } from '../../../system/routing'

const List = ({
    pagination,
    loading,
    filter,
    onSearch,
    isOpenListSetting,
    onOpenListSetting,
    isCurrentPage,
    object,
    packages,
    exportPackageList,
    onSelectedPackages,
    selectedPackages,
}) => {
    const [activeColumns, setActiveColumns] = useState([])

    const onChangePage = (page, pageSize) => {
        if (!loading) {
            onSearch({
                ...filter,
                page,
                per_page: pageSize,
            })
        }
    }

    const onChange = (pagination, filters, sorter) => {
        search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        })
    }

    const search = input => {
        if (!loading) {
            onSearch({
                ...filter,
                ...input,
            })
        }
    }
    const selectRow = selectedRowKeys => {
        onSelectedPackages(selectedRowKeys)
    }

    const getColumns = () => {
        let arr = [PACKAGE_COLUMN.CREATE_TIME, PACKAGE_COLUMN.WEIGHT, PACKAGE_COLUMN.VOLUME]
        let reActiveColumns = [...activeColumns]
        return values(PACKAGE_COLUMN)
            .filter(column => reActiveColumns.includes(column))
            .map(column => {
                let res = {
                    key: column,
                    dataIndex: column,
                    title: t('package:column.' + column),
                }
                if (arr.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === filter.sort_by ? (filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                    }
                }

                return res
            })
    }

    const getDataSource = () => {
        return packages.map(packageData => ({
            key: packageData.id,
            [PACKAGE_COLUMN.CODE]: (
                <span className="a-text--nowrap _code">
                    <Link
                        isNewTab
                        params={{ id: packageData.code }}
                        to="packages.detail">
                        {packageData.code}
                    </Link>
                </span>
            ),
            [PACKAGE_COLUMN.DAMAGED_REASONS]: (
                <span className="a-text--nowrap _damaged-reasons">
                    {get(packageData, 'damaged_reasons.length') ? packageData.damaged_reasons.join(', ') : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.BARCODE]: <span className="a-text--nowrap _barcode">{get(packageData, 'barcode') || '--'}</span>,
            [PACKAGE_COLUMN.BAG_CODE]: (
                <span className="a-text--nowrap _code-bag">
                    <Link
                        isNewTab
                        params={{ id: packageData.code_bag }}
                        to="bags.detail">
                        {get(packageData, 'code_bag') || '--'}
                    </Link>
                </span>
            ),
            [PACKAGE_COLUMN.CREATE_TIME]: <span className="a-text--nowrap _created_at">{dateFormatter.dateTime(packageData.created_at)}</span>,
            [PACKAGE_COLUMN.ORDER_LINKED_AT]: (
                <span className="a-text--nowrap _order-linked-at">
                    {get(packageData, 'order_linked_at') ? dateFormatter.dateTime(packageData.order_linked_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.TRANSPORTING_AT]: (
                <span className="a-text--nowrap _transporting-at">
                    {get(packageData, 'transporting_at') ? dateFormatter.dateTime(packageData.transporting_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.READY_FOR_DELIVERY_AT]: (
                <span className="a-text--nowrap _ready-for-delivery-at">
                    {get(packageData, 'ready_for_delivery_at') ? dateFormatter.dateTime(packageData.ready_for_delivery_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.STATE]: (
                <span className="a-text--nowrap _status_warehouse">
                    {packageData.status_warehouse ? t('package:action.' + packageData.status_warehouse) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.STATUS_TRANSPORT]: (
                <span className="a-text--nowrap _status_transport">
                    {packageData.status_transport ? t('package:status_transport.' + packageData.status_transport) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.ORDER]: (
                <span className="a-text--nowrap _order">
                    {get(packageData, 'code_order') ? (
                        <a
                            className="info--value a-text--blue"
                            href={get(packageData, 'm2_url_order') || '#top'}
                            rel="noopener noreferrer"
                            target={get(packageData, 'm2_url_order') ? '_blank' : '_self'}>
                            {get(packageData, 'code_order', '--')}
                        </a>
                    ) : (
                        '--'
                    )}
                </span>
            ),
            [PACKAGE_COLUMN.AGENCY]: <span className="a-text--nowrap _agency">{get(packageData, 'agency', '--')}</span>,
            [PACKAGE_COLUMN.USERNAME]: <span className="a-text--nowrap _username">{get(packageData, 'username_customer', '--') || '--'}</span>,
            [PACKAGE_COLUMN.PROPERTIES]: <span className="a-text--nowrap _properties">{get(packageData, 'properties', []).join(', ') || '--'}</span>,
            [PACKAGE_COLUMN.PRINTED_OFFICIAL_STAMP]: (
                <span className="a-text--nowrap _printed-official-stamp">
                    {t(`package:printed_official_stamp.${!!get(packageData, 'is_printed_official_stamp')}`)}
                </span>
            ),
            [PACKAGE_COLUMN.BOX_SERVICES]: <span className="a-text--nowrap _box-services">{get(packageData, 'box_services', []).join(', ') || '--'}</span>,
            [PACKAGE_COLUMN.SHIPPING_SERVICES]: (
                <span className="a-text--nowrap _shipping-services">{get(packageData, 'shipping_services', []).join(', ') || '--'}</span>
            ),
            [PACKAGE_COLUMN.EXTEND_SERVICES]: (
                <span className="a-text--nowrap _extend-services">{get(packageData, 'extend_services', []).join(', ') || '--'}</span>
            ),
            [PACKAGE_COLUMN.INPUT_WAREHOUSE]: <span className="a-text--nowrap _warehouse_input">{get(packageData, 'code_warehouse_input', '--')}</span>,
            [PACKAGE_COLUMN.CURRENT_WAREHOUSE]: <span className="a-text--nowrap _current_warehouse">{get(packageData, 'code_warehouse_current', '--')}</span>,
            [PACKAGE_COLUMN.DESTINATION_WAREHOUSE]: (
                <span className="a-text--nowrap _destination_warehouse">{get(packageData, 'code_warehouse_destination', '--')}</span>
            ),
            [PACKAGE_COLUMN.SHIPPING_PARTNER]: <span className="a-text--nowrap _shipping-partner">{get(packageData, 'shipping_partner', '--')}</span>,
            [PACKAGE_COLUMN.WEIGHT]: <span className="a-text--nowrap _weight_net">{packageData.weight_net ? packageData.weight_net + ' kg' : '--'}</span>,
            [PACKAGE_COLUMN.VOLUME]: <span className="a-text--nowrap _volume">{packageData.volume ? packageData.volume + ' m3' : '--'}</span>,
            [PACKAGE_COLUMN.IS_CHECKED]: get(packageData, 'is_checked') ? t('package:label.checked') : t('package:label.unchecked'),
        }))
    }

    const exportPackageListExcel = () => {
        exportPackageList({
            ...filter,
            id_packages: selectedPackages,
        })
    }

    const canExportPackageList = authService.can(permissions.EXPORT_PACKAGES_LIST)

    return (
        <>
            {' '}
            <div className="a-content__top a-content__top--page-list mt-0">
                <div className="a-content--page-list__title">
                    <h3>
                        {t('package:label.list')} ({get(pagination, 'total', 0)})
                    </h3>
                </div>
                <div className="a-content--page-list__top-action">
                    {canExportPackageList && (
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                            ghost
                            loading={loading}
                            type="primary"
                            onClick={exportPackageListExcel}>
                            {t('btn.export_excel')}
                        </Button>
                    )}
                    <ListPackageSetting
                        onChange={activeColumns => setActiveColumns(activeColumns)}
                        object={object}
                        loading={loading}
                        isOpenListSetting={isOpenListSetting}
                        onOpenListSetting={onOpenListSetting}
                        isCurrentPage={isCurrentPage}
                    />
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                <div className="a-table--responsive">
                    <Table
                        className="a-table--page-list _a-table--page-list"
                        columns={getColumns()}
                        dataSource={getDataSource()}
                        loading={loading}
                        locale={{
                            emptyText: loading ? (
                                <Icon
                                    type="loading-3-quarters"
                                    className="icon-loading a-text--black "
                                />
                            ) : (
                                <Empty
                                    className="_empty"
                                    description={<span>Không có dữ liệu </span>}
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"></Empty>
                            ),
                        }}
                        pagination={false}
                        rowClassName={(record, index) => index.toString()}
                        rowKey={record => record.key}
                        rowSelection={{
                            selectedRowKeys: selectedPackages,
                            onChange: selectRow,
                        }}
                        onChange={onChange}
                    />
                </div>
                <Pagination
                    page={toInteger(filter.page) || 1}
                    pageSize={toInteger(filter.per_page)}
                    pageSizeOptions={[20, 50, 100, 200]}
                    total={toInteger(pagination.total)}
                    loading={loading}
                    onChange={onChangePage}
                    pagination={pagination}
                />
            </div>
        </>
    )
}

export default List
