import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {t, trans} from '../../../../../system/i18n';
import {Link} from '../../../../../system/routing';
import {currentFormatter} from "../../../services/format/index";

const DELIVERY_NOTE_ACTION = {
    CREATE: 'DELIVERY_NOTE.CREATE',
    ADD_PACKAGE: 'DELIVERY_NOTE.ADD_PACKAGE',
    UPDATE: 'DELIVERY_NOTE.UPDATE',
    EXPORT: 'DELIVERY_NOTE.EXPORT',
    CANCEL: 'DELIVERY_NOTE.CANCEL',
    PLACED: 'DELIVERY_NOTE.PLACED',
    ADDED_LAST_MILE_ORDER: 'DELIVERY_NOTE.ADDED_LAST_MILE_ORDER',
    PLACE_COMPLETED: 'DELIVERY_NOTE.PLACE_COMPLETED',
    RETURN: {
        CREATE: 'DELIVERY_NOTE_RETURN.CREATE',
        EXPORT: 'DELIVERY_NOTE_RETURN.EXPORT',
        UPDATE: 'DELIVERY_NOTE_RETURN.UPDATE'
    },
    TRANSPORT_WAREHOUSE: {
        CREATE: 'DELIVERY_NOTE_TRANSPORT_WAREHOUSE.CREATE',
        EXPORT: 'DELIVERY_NOTE_TRANSPORT_WAREHOUSE.EXPORT',
        UPDATE: 'DELIVERY_NOTE_TRANSPORT_WAREHOUSE.UPDATE'
    },
    CHARGED_SHIPPING_FEE: 'DELIVERY_NOTE.CHARGED_SHIPPING_FEE',
    CANCEL_LAST_MILE_ORDER: 'DELIVERY_NOTE.CANCEL_LAST_MILE_ORDER',
    CONFIRM: 'DELIVERY_NOTE.CONFIRM',
    CHANGE_STATUS_LM_SHIPMENT: 'DELIVERY_NOTE.CHANGE_STATUS_LM_SHIPMENT',
    PRINT_LAST_MILE: "DELIVERY_NOTE.PRINT_LAST_MILE"
};

class DeliveryNoteLogMessage extends React.PureComponent {
    render() {
        let {log} = this.props;
        switch (log.action) {
            case DELIVERY_NOTE_ACTION.CREATE:
                if (lodash.get(log, "payload.related_requests", []).length > 0) {
                    return trans('delivery_note:log.CREATE_BY_DELIVERY_REQUEST', {
                        requests : <b>{lodash.get(log, "payload.related_requests", []).toString()}</b>
                    });
                }else {
                    return t('delivery_note:log.CREATE');
                }

            case DELIVERY_NOTE_ACTION.EXPORT:
            case DELIVERY_NOTE_ACTION.RETURN.EXPORT:
            case DELIVERY_NOTE_ACTION.TRANSPORT_WAREHOUSE.EXPORT:
                return trans('delivery_note:log.EXPORT', {
                    creator: <b>{log.creator.name} ({log.creator.username})</b>
                });
            case DELIVERY_NOTE_ACTION.UPDATE:
            case DELIVERY_NOTE_ACTION.RETURN.UPDATE:
                return (
                    <span>
                        {trans('delivery_note:log.UPDATE')}
                        {this.renderMessageDetailUpdate(log)}
                    </span>
                );
            case DELIVERY_NOTE_ACTION.TRANSPORT_WAREHOUSE.UPDATE:
                return (
                    <span>
                        {trans('delivery_note:log.UPDATE')}
                        {this.renderMessageDetailUpdate({
                            ...log,
                            payload: lodash.omit(log.payload, 'change_bags')
                        })}
                        {this.renderChangeBagLogs(log)}
                    </span>
                );
            case DELIVERY_NOTE_ACTION.CANCEL:
                return (
                    <span>{trans('delivery_note:log.CANCEL')}</span>
                );

            case DELIVERY_NOTE_ACTION.ADD_PACKAGE:
                return trans('delivery_note:log.ADD_PACKAGE', {
                    creator: <b>{log.creator.name}</b>,
                    package_use: lodash.map(lodash.get(log, 'payload.change_packages.add', []), (package_use, i) => <b
                        key={i}>{package_use}</b>)
                });
            case DELIVERY_NOTE_ACTION.RETURN.CREATE: {
                return trans('delivery_note:log.RETURN.CREATE');
            }
            case DELIVERY_NOTE_ACTION.PLACED: {
                return trans('delivery_note:log.PLACED');
            }
            case DELIVERY_NOTE_ACTION.ADDED_LAST_MILE_ORDER: {
                return trans('delivery_note:log.ADDED_LAST_MILE_ORDER', {
                    order: <b>{log.payload.last_mile_order}</b>
                });
            }
            case DELIVERY_NOTE_ACTION.CANCEL_LAST_MILE_ORDER: {
                return trans('delivery_note:log.CANCEL_LAST_MILE_ORDER', {
                    order: <b>{log.payload.last_mile_order}</b>
                });
            }
            case DELIVERY_NOTE_ACTION.PLACE_COMPLETED: {
                return trans('delivery_note:log.PLACE_COMPLETED');
            }
            case DELIVERY_NOTE_ACTION.CHARGED_SHIPPING_FEE: {
                return trans(`delivery_note:log.CHARGED_SHIPPING_FEE.${log.payload.success}`);
            }
            case DELIVERY_NOTE_ACTION.TRANSPORT_WAREHOUSE.CREATE: {
                return trans('delivery_note:log.TRANSPORT_WAREHOUSE.CREATE');
            }
            case DELIVERY_NOTE_ACTION.CONFIRM: {
                return trans('delivery_note:log.CONFIRM');
            }
            case DELIVERY_NOTE_ACTION.CHANGE_STATUS_LM_SHIPMENT: {
                return trans('delivery_note:log.CHANGE_STATUS_LM_SHIPMENT', {
                    tracking_no: <b>{lodash.get(log, "payload.tracking_no", "")}</b>,
                    to_status: <b>{trans(`delivery_note:LAST_MILE_SHIPMENT_STATUS.${lodash.get(log, "payload.to_status", "")}`)}</b>
                });
            }
            case DELIVERY_NOTE_ACTION.PRINT_LAST_MILE: {
                return trans('delivery_note:log.PRINT_LAST_MILE', {
                    tracking_no: <b>{lodash.get(log, "payload.tracking_no", "")}</b>,
                });
            }
            default:
                return log.action;
        }
    }


    linkToPackage({code_package} = {}) {
        return lodash.map(code_package.split(', '), (code, i) => <Link to="packages.detail"
                                                                       key={i}
                                                                       params={{id: code}}>{i > 0 && ','}{code}</Link>);
    }

    /**
     * Render ra log cập nhật thông tin bao
     * @param log
     * @returns {string}
     */
    renderMessageDetailUpdate(log) {
        let {payload} = log;
        let package_use = {};
        let change_fields = [];
        let oldValue = null;
        let newValue = null;
        let field = null;
        change_fields = lodash.get(payload, 'change_fields', []);
        change_fields = lodash.mapValues(change_fields, function (o, key) {
            let unit = null;
            newValue = null;
            field = null;
            switch (key) {

                case 'weight_real':
                    oldValue = lodash.get(o, 'old') || 0;
                    newValue = lodash.get(o, 'new') || 0;
                    unit = 'kg';
                    field = trans('delivery_note:label.' + key).toLowerCase();
                    break;
                case 'status':
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    oldValue = oldValue ? trans('delivery_note:state.' + lodash.get(o, 'old')) : null;
                    newValue = newValue ? trans('delivery_note:state.' + lodash.get(o, 'new')) : null;
                    field = trans('delivery_note:label.' + key).toLowerCase();
                    break;
                default:
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    field = trans('delivery_note:label.' + key).toLowerCase();
                    break;
            }
            return {old: oldValue, new: newValue, unit: unit}
        });
        lodash.map(lodash.get(payload, 'change_packages', []), (item, key) => {
            oldValue = null;
            newValue = null;

            if (key === 'add' && item.length > 0) {
                newValue = (lodash.get(item, '0.code', '')) ? lodash.get(item, '0.code', '') : lodash.join(lodash.values(item), ', ');
                package_use = {...package_use, ...{new: newValue, unit: null}};
            }
            if (key === 'remove' && item.length > 0) {
                oldValue = (lodash.get(item, '0.code', '')) ? lodash.get(item, '0.code', '') : lodash.join(lodash.values(item), ', ');
                package_use = {...package_use, ...{old: oldValue, unit: null}};
            }
        });
        if (!lodash.isEmpty(package_use)) {
            change_fields.package_use = package_use;
        }

        // Log thao tác của bao
        const change_bags = lodash.get(payload, 'change_bags', {});
        let logBags = {};
        lodash.map(change_bags, (item, key) => {
            let codes = [];
            let codeFormatted;
            lodash.forEach(item, (value) => {
                if (lodash.isObject(value)) {
                    codes.push(value.code);
                } else {
                    codes.push(value);
                }
            });

            let keyTrans;
            if (key === 'add') {
                keyTrans = 'delivery_note:log.add_bag';
            } else if (key === 'remove') {
                keyTrans = 'delivery_note:log.remove_bag';
            }

            codeFormatted = lodash.map(codes, (code) => {
                return <Link key={lodash.uniqueId() + Math.random()} to={`bags.detail`}
                             params={{id: code}}><b>{code}</b></Link>
            });

            logBags = {
                ...logBags,
                [key]: {
                    ...{
                        keyTrans: keyTrans, code: codeFormatted
                    }
                }
            };
        });
        if (!lodash.isEmpty(logBags)) {
            change_fields.logBags = logBags;
        }

        return Object.keys(change_fields).map(param => {
            let data = change_fields[param] || {};
            let langKey = param === "package_use" ? 'delivery_note:log.update_param_package' : 'delivery_note:log.update_param';
            let upperCaseField = true;

            if (!data.old) {
                langKey = param === "package_use" ? 'delivery_note:log.set_para_package' : 'delivery_note:log.set_param';
            } else if (!data.new) {
                langKey = 'delivery_note:log.remove_param';
            }

            if (param === 'cod') {
                upperCaseField = false;
            }

            let code;
            let dataOld;
            let dataNew;
            if (data.old) {
                switch (param) {
                    case 'package_use' :
                        dataOld = <b>{this.linkToPackage({code_package: data.old})}</b>;
                        break;
                    case 'amount_collect':
                    case 'domestic_shipping_fee':
                    case 'negative_balance':
                    case 'cod':
                        dataOld =
                            <span><b>{currentFormatter.toLocaleStringCurrency(parseFloat(data.old))}</b> VNĐ</span>;
                        break;
                    default :
                        dataOld = <b>{data.old}{data.unit}</b>
                }
            }
            if (data.new) {
                switch (param) {
                    case 'package_use' :
                        dataNew = <b>{this.linkToPackage({code_package: data.new})}</b>;
                        break;
                    case 'amount_collect':
                    case 'domestic_shipping_fee':
                    case 'negative_balance':
                    case 'cod':
                        dataNew =
                            <span><b>{currentFormatter.toLocaleStringCurrency(parseFloat(data.new))}</b> VNĐ</span>;
                        break;
                    default :
                        dataNew = <b>{data.new}{data.unit}</b>
                }
            }

            field = trans('delivery_note:label.' + param);

            if (param === 'logBags') {
                const messages = Object.keys(change_fields[param]).map(action => {
                    langKey = change_fields[param][action].keyTrans;
                    code = change_fields[param][action].code;

                    return trans(langKey, {
                        param: !data.new && upperCaseField ? field.toLowerCase() : field,
                        old_value: dataOld,
                        new_value: dataNew,
                        code: code
                    });
                });

                return (
                    <span className="ml-1" key={Math.random() + '_' + Math.random()}>
                        {messages.map((message, index) => (
                            <Fragment key={index}>
                                {message}
                                {index === messages.length - 1 ? null : ', '}
                            </Fragment>
                        ))}
                    </span>
                );
            }

            return (
                <span className="ml-1" key={Math.random() + '_' + Math.random()}>
                    {trans(langKey, {
                        param: !data.new && upperCaseField ? field.toLowerCase() : field,
                        old_value: dataOld,
                        new_value: dataNew,
                        code: code
                    })}
                </span>
            );
        });

    }

    renderChangeBagLogs = log => {
        const changeBags = lodash.get(log, 'payload.change_bags', {});
        const actions = Object.keys(changeBags);

        return actions.map(action => {
            return changeBags[action].length > 0 ? (
                <span className="ml-1" key={lodash.uniqueId()}>
                    {trans(`log:delivery_note.change_bags.${action}`, {
                        bags: changeBags[action].map((bag, index) => (
                            <Fragment key={bag}>
                                <Link
                                    isNewTab
                                    params={{id: bag}}
                                    to="bags.detail"
                                >
                                    {bag}
                                </Link>
                                {index !== changeBags[action].length - 1 && ', '}
                            </Fragment>
                        ))
                    })}
                </span>
            ) : null;
        });
    };
}

DeliveryNoteLogMessage.defaultProps = {
    log: {},
};

DeliveryNoteLogMessage.propTypes = {
    log: PropTypes.object,
};

export default withTranslation()(DeliveryNoteLogMessage);
