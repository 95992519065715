import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.ON_CANCEL_DELIVERY_REQUEST.REQUEST, saga.cancelDeliveryRequest);
    yield takeLatest(ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS, saga.cancelDeliveryRequestSuccess);
    yield takeLatest(ACTION.ON_CANCEL_DELIVERY_REQUEST.FAILED, saga.cancelDeliveryRequestFailed);

}
