import React from 'react';
import { Col, Skeleton, Row } from 'antd';

const InfoShippingPartnerDeliveryNoteLoading = () => (
    <div className="a-block-info abg-white  amb-16 apt-24 apl-8 apr-8 pb-0">
        <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
            <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
            </Col>
        </Row>
    </div>
);

export default InfoShippingPartnerDeliveryNoteLoading;
