import 'regenerator-runtime/runtime';
import {all, fork} from 'redux-saga/effects';
import commonWatcher from './modules/Common/sagaWatcher';
import authWatcher from './modules/Auth/sagaWatcher';
import customerWatcher from './modules/Customer/sagaWatcher';
import packageWatcher from './modules/Package/sagaWatcher';
import bagWatcher from './modules/Bag/sagaWatcher';
import bagSizeWatcher from './modules/BagSize/sagaWatcher';
import warehouseWatcher from './modules/Warehouse/sagaWatcher';
import settingWatcher from './modules/Setting/sagaWatcher';
import serviceWatcher from './modules/Service/sagaWatcher';
import locationWatcher from './modules/Location/sagaWatcher';
import propertyWatcher from './modules/Property/sagaWatcher';
import orderWatcher from './modules/Order/sagaWatcher';
import scanBarcodeFormWatcher from './modules/ScanBarcode/ScanBarcodeForm/sagaWatcher';
import scanHistoryWatcher from './modules/ScanBarcode/ScanHistory/sagaWatcher';
import barcodeWatcher from './modules/Barcode/sagaWatcher';
import userWatcher from './modules/User/sagaWatcher';
import deliveryNoteWatcher from './modules/DeliveryNote/sagaWatcher';
import deliveryNoteCancelWatcher from './modules/DeliveryNote/CancelDeliveryNote/sagaWatcher';
import deliveryRequestWatcher from './modules/DeliveryRequest/sagaWatcher';
import boxSizeWatcher from './modules/BoxSize/sagaWatcher';
import sound from './modules/Sound/sagaWatcher';
import createInventory from './modules/Inventory/CreateInventory/sagaWatcher';
import inventoryScan from './modules/Inventory/InventoryScan/sagaWatcher';
import inventoryWatcher from './modules/Inventory/sagaWatcher';
import Language from './modules/Common/Language/sagaWatcher';
import shippingPartnerWatcher from './modules/ShippingPartner/sagaWatcher';
import configWarehouse from './modules/WarehouseConfig/sagaWatcher';
import shippingUserWatcher from './modules/ShippingUser/sagaWatcher';
import templateBagWatcher from './modules/TemplateBag/sagaWatcher';
import notesWatcher from './modules/Notes/sagaWatcher';
import noticeStageWatcher from './modules/NoticeStage/sagaWatcher';
import shippingFeeWatcher from './modules/ShippingFee/sagaWatcher';
import agenciesWatcher from './modules/Agencies/sagaWatcher';

export default function* start() {
    yield all([
        fork(commonWatcher),
        fork(customerWatcher),
        fork(authWatcher),
        fork(packageWatcher),
        fork(warehouseWatcher),
        fork(settingWatcher),
        fork(locationWatcher),
        fork(serviceWatcher),
        fork(propertyWatcher),
        fork(orderWatcher),
        fork(scanHistoryWatcher),
        fork(scanBarcodeFormWatcher),
        fork(barcodeWatcher),
        fork(userWatcher),
        fork(bagWatcher),
        fork(bagSizeWatcher),
        fork(deliveryNoteWatcher),
        fork(deliveryNoteCancelWatcher),
        fork(deliveryRequestWatcher),
        fork(boxSizeWatcher),
        fork(templateBagWatcher),
	    fork(configWarehouse),
        fork(Language),
        fork(sound),
        fork(createInventory),
        fork(inventoryScan),
        fork(inventoryWatcher),
	    fork(shippingPartnerWatcher),
	    fork(shippingUserWatcher),
        fork(notesWatcher),
        fork(noticeStageWatcher),
        fork(shippingFeeWatcher),
        fork(agenciesWatcher)
    ]);
}
