export const FETCH_BARCODE_PACKAGES = {
    REQUEST: 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.FETCH_BARCODE_PACKAGES.REQUEST',
    SUCCESS: 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.FETCH_BARCODE_PACKAGES.SUCCESS',
    FAILED: 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.FETCH_BARCODE_PACKAGES.FAILED',
};

export const RESET_BARCODE = 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.RESET_BARCODE';

export const SET_INPUT_FOCUS = 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.SET_INPUT_FOCUS';

export const SET_PACKAGE = 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.SET_PACKAGE';

export const SET_PACKAGE_PAGE = 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.SET_PACKAGE_PAGE';

export const CLEAR_STATE = 'PACKAGE.CREATE_PACKAGE_VIA_SCAN.CLEAR_STATE';

export const DEFAULT_PACKAGE = {
    permissions: {
        can_create_package: 1,
        can_delete: 1,
        can_link_order: 0,
        can_print_barcode: 0,
        can_unlink_order: 0,
        can_update: 1
    },
    barcode: "",
    code: "",
    customer_address_destination: "",
    customer_id_city_destination: 0,
    customer_id_country_destination: 0,
    customer_id_district_destination: 0,
    customer_name: "",
    customer_note: "",
    customer_phone_destination: "",
    customer_receive: "",
    customer_username: "",
    height: 0,
    id: 0,
    id_bag: 0,
    id_creator: 0,
    id_customer: 0,
    id_order: 0,
    id_warehouse_destination: "",
    id_warehouse_input: 0,
    is_deleted: false,
    is_info_complete: false,
    length: 0,
    note: "",
    type: "",
    volume: 0,
    weight: 0,
    weight_converted: 0,
    weight_net: 0,
    width: 0,
    id_agency: 0
};
