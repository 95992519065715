/* eslint-disable */
import React, {Component} from 'react';
import {Select, Table, Pagination, Button, Menu, Dropdown, Icon, Checkbox,} from 'antd';

const Option = Select.Option;
const columns = [
    {
        title: 'Mã phiếu xuất ',
        dataIndex: 'codeinvoice',
        key: 'codeinvoice',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    },
    {
        title: 'Trạng thái phiếu xuất',
        dataIndex: 'statusinvoice',
        key: 'statusinvoice',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Thời gian tạo',
        dataIndex: 'timecreate',
        key: 'timecreate',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Thời gian xuất',
        dataIndex: 'timeexport',
        key: 'timeexport',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Nhân viên vận chuyển(Mã NV)',
        dataIndex: 'persontransport',
        key: 'persontransport',
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Số lượng xuât',
        dataIndex: 'totalexport',
        key: 'totalexport',
        sorter: true,
        className: "a-text--nowrap",
    },
    {
        title: 'Tổng cân nặng',
        dataIndex: 'totalweight',
        key: 'totalweight',
        sorter: true,
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kho xuất',
        dataIndex: 'warehouseexport',
        key: 'warehouseexport',
        className: "a-text--nowrap",
    },
    {
        title: 'Người tạo ',
        dataIndex: 'personcreate',
        key: 'personcreate',
        className: "a-text--nowrap",
    },
    {
        title: 'Người xuất ',
        dataIndex: 'personexport',
        key: 'personexport',
        className: "a-text--nowrap",
    }
];

const data = [];
for (let i = 0; i < 50; i++) {
    data.push({
        codeinvoice: `K1234-75011909${i}`,
        statusinvoice: `Đang vận chuyển`,
        timecreate: `15:00:00 29/02/2021`,
        timeexport: `15:00:00 29/02/2021`,
        persontransport: `Lê Hoàng Thuỳ Trang(nhtt12)`,
        totalexport: `8569`,
        warehouseexport: `VNHN`,
        totalweight: `${i}0kg`,
        personcreate: `Lê Ngọc Dương(NDNV1)`,
        personexport: `Lê Ngọc Dương(NDNV1)`,
        iswarning: true,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

class ListDeliveryNote extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list">
                    <div className="a-content--page-list__title">
                        <h3>Danh sách phiếu xuất cho nhân viên (78)</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>Thiết lập cột thông tin
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           pagination={false}
                           rowClassName={(record) => record.iswarning ? 'row-warning' : ''}
                    />
                    <div className="a-pagination a-pagination--fixed ">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show">
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListDeliveryNote;