import { Button } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { DELIVERY_OBJECT } from './../../../constants';

import BarcodeItem from './BarcodeItem';
import DeliveryNoteExport from './../../../DeliveryNoteExport/DeliveryNoteExportContainer';
import Link from './../../../../../system/routing/Link';

class ListDeliveryNoteBarcode extends React.Component {
    state = {
        scrolltable: false,
    };

    componentWillUnmount() {
        this.props.clearState();
    }

    countSuccessBarcode = () => {
        return this.props.barcodes.filter(barcode => !!barcode.object).length;
    };

    onScrollTable = e => {
        let element = e.target;
        if (element.scrollWidth - 1 - element.scrollLeft === element.clientWidth) {
            this.setState({ scrolltable: false });
        } else {
            this.setState({ scrolltable: true });
        }
    };

    render() {
        const { barcodes, className, deliveryNoteReturn, isDetailPage, t, onPrint, onSuccessExportDeliveryNote } = this.props;
        const status = get(deliveryNoteReturn, 'delivery_note_return.status');
        const canUpdate = !!get(deliveryNoteReturn, 'permissions.can_update');
        const canExport = !!get(deliveryNoteReturn, 'permissions.can_export');
        const deliveryNoteData = get(deliveryNoteReturn, 'delivery_note_return', {});
        return (
            <div className={clsx('a-bgwhite aheight-100', className)}>
                <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0 mt-0">
                    <div className="a-content--page-list__title">
                        <h3>
                            {t('delivery_note:label.list_package_export')} ({barcodes.length || 0})
                        </h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        {status === 'EXPORTED' && (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3"
                                type="primary"
                                onClick={onPrint}
                            >
                                {t('delivery_note:label.print')}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list apt-16 mr-0">{this.renderBarcodes()}</div>
                {(isDetailPage && (canUpdate || canExport)) && (
                    <div className="a-pagination a-pagination--fixed a-pagination--height-action gflex gjustify-space-between p-5">
                        {canUpdate && (
                            <Button className="a-btn a-btn--button-link a-text--blue _btn-edit">
                                <Link
                                    params={{
                                        id: get(deliveryNoteReturn, 'delivery_note_return.id', 0),
                                    }}
                                    to="delivery-notes.return.edit"
                                >
                                    {t('delivery_note:label.edit')}
                                </Link>
                            </Button>
                        )}
                        <div>
                            {canExport && (
                                <DeliveryNoteExport
                                    bagTotal={0}
                                    className="ant-btn a-btn a-btn--primary a-btn--save-export _btn-export"
                                    deliveryNoteData={{...deliveryNoteData, barcodes}}
                                    type={DELIVERY_OBJECT.RETURN}
                                    packageTotal={get(deliveryNoteReturn, 'packages.length')}
                                    onSuccessExportDeliveryNote={onSuccessExportDeliveryNote}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderBarcodes = () => {
        const { scrolltable } = this.state;
        const { barcodes, t, removeBarcode, deliveryNoteReturn,  } = this.props;

        if (!barcodes.length) {
            return (
                <div className="content-list--empty">
                    <img alt="" src={require('../../../../../resources/images/boxempty.png')} />
                    <span className="info a-text--uppercase">{t('not_have_scan_code_yet')}</span>
                </div>
            );
        }
        const isShowBtnRenew = !get(deliveryNoteReturn, 'delivery_note_return.id');
        return (
            <div className="a-table--responsive" onScroll={this.onScrollTable}>
                <table className="a-table a-table--isdata--warning-error">
                    <thead>
                        <tr>
                            <th>{t('delivery_note:label.id_export')}</th>
                            <th>{t('delivery_note:label.id_order')}</th>
                            <th>{t('delivery_note:label.weight')}</th>
                            <th>{t('delivery_note:label.size')}</th>
                            {!isShowBtnRenew && (
                                <>
                                    <th>{t('delivery_note:label.tracking_bill')}</th>
                                    <th>{t('delivery_note:label.return_fee')}</th>
                                    <th>{t('delivery_note:label.return_address')}</th>
                                </>
                            )}
                            <th
                                className={clsx('sticky-right', {
                                    'box-shadow': scrolltable,
                                })}
                            />
                        </tr>
                    </thead>
                    {barcodes.map(barcode => (
                        <BarcodeItem
                            {...this.props}
                            key={barcode.code}
                            barcode={barcode}
                            onRemove={removeBarcode.bind(undefined, barcode)}
                            scrollTable={scrolltable}
                        />
                    ))}
                </table>
            </div>
        );
    };
}

ListDeliveryNoteBarcode.defaultProps = {
    barcodes: [],
    removeBarcode: () => {},
    clearState: () => {},
};

ListDeliveryNoteBarcode.propTypes = {
    barcode: PropTypes.array,
    removeBarcode: PropTypes.func,
    clearState: PropTypes.func,
};

export default withTranslation()(ListDeliveryNoteBarcode);
