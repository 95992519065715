import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row, Modal, notification, Spin} from 'antd';
import * as lodash from 'lodash';
import {withTranslation} from 'react-i18next';
import InputNumber from '../../Common/components/InputNumber';
import {ELEMENT} from '../../Package/CreatePackageViaScan/services/inputFocus/constants';
import authService from '../../Auth/authService';
import apiPackage from '../../Package/apiService';
import {convertUndefinedPropertyToNull} from '../../../system/support/helpers';
import {pick} from "lodash";
import packageService from "../../Package/packageService";
import apiService from "../apiService";
import * as selectors from "../../Package/UpdatePackageParams/selectors";

function UpdatePackageInfo(props) {
    const {form, t, closeModal, visible, dimensionSetting, barcodeScanData} = props;
    const [input, setInput] = useState({
        weight_net: null,
        length: null,
        width: null,
        height: null,
    });
    const [errors, setErrors] = useState({});
    const inputWeightNetRef = useRef();
    const [packageInfo, setPackageInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const {getFieldDecorator} = form;
    let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';

    useEffect(() => {
        if (!lodash.isEmpty(barcodeScanData)) {
            fetchPackageInfo();
        }
    }, [barcodeScanData]);

    useEffect(() => {
        if (!lodash.isEmpty(packageInfo)) {
            setInput(makeFormInput());
            if (inputWeightNetRef.current) {
                inputWeightNetRef.current.focus();
            }
        }
    }, [packageInfo]);

    function makeFormInput() {
        const input = pick(packageInfo, [
            'weight_net',
            'length',
            'width',
            'height',
            'volume',
            'id_box_size',
            'weight_box',
            'volume_box',
        ]);

        if (input.id_box_size === 0) {
            delete input.id_box_size
        }

        if (input.width === 0 && input.height === 0 && input.length === 0) {
            input.volume = null
        }

        return packageService.makeFormInput(input);
    }

    function fetchPackageInfo() {
        setLoading(true);
        const {code_package} = barcodeScanData;
        apiPackage.getPackage(code_package).then(res => {
            setPackageInfo(lodash.get(res, 'data.package', {}));
        }).catch(err => {
            notification.error({message: 'Lấy thông tin kiện thất bại'});
        }).finally(() => setLoading(false));
    }


    function getInputStatusClass(hasError, hasWarning) {
        if (hasError) {
            return 'a-input--error';
        }

        if (hasWarning) {
            return 'a-input--warning';
        }

        return '';
    }

    function getWarningParams(param) {
        let warning = {};
        let warningValue = 0;
        let maxValue = 0;
        let valueState = 0;
        warningValue = dimensionSetting[param + '_warning'] || null;
        maxValue = dimensionSetting[param + '_max'] || null;
        switch (param) {
            case 'length':
            case 'width':
            case 'height':
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
                valueState = input[param];
                if (warningValue && valueState > warningValue) {
                    warning['status'] = 'warning';
                    warning['message'] = t('package:message.warning_value',
                        {
                            attribute: t('package:label.' + param),
                            value: maxValue + 'cm',
                        });
                    warning['value'] = maxValue;
                }

                if (maxValue && valueState > maxValue) {
                    warning['status'] = 'error';
                    warning['message'] = t('package:message.max_value',
                        {
                            attribute: t('package:label.' + param),
                            value: maxValue + 'cm',
                        });
                    warning['value'] = maxValue;
                }
                break;
            case 'weight':
                let weight_net = input['weight_net'];
                if (warningValue && weight_net > maxValue) {
                    warning['status'] = 'error';
                    warning['message'] = 'package:message.update_weight_max_numeric';
                    warning['value'] = maxValue;
                } else if (warningValue && weight_net > warningValue) {
                    warning['status'] = 'warning';
                    warning['message'] = 'package:message.update_weight_warning';
                    warning['value'] = maxValue;
                } else if (!weight_net || lodash.isNil(weight_net) || lodash.isUndefined(weight_net)) {
                    warning['status'] = 'error';
                    warning['message'] = 'package:message.update_weight_placeholder';
                    warning['value'] = '';
                } else if (parseFloat(weight_net) === 0) {
                    warning['status'] = 'error';
                    warning['message'] = 'package:message.update_weight_greater';
                    warning['value'] = '';
                } else {
                    warning['status'] = '';
                    warning['message'] = '';
                    warning['value'] = '';
                }
                break;
            default:
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
        }

        return warning;
    }

    function onChangeInput(param, value) {
        form.setFieldsValue({
            [param]: value
        });

        setInput(prevState => {
            return {
                ...prevState,
                [param]: value,
            };
        });
        if (['length', 'width', 'height'].includes(param) && errors.size_drc) {
            setErrors(prevState => ({
                ...prevState,
                size_drc: undefined
            }))
        }

        if (errors[param]) {
            setErrors(prevState => ({
                ...prevState,
                [param]: undefined
            }))
        }

    }

    function onSubmit() {
        setUpdating(true);
        const data = convertUndefinedPropertyToNull(input);
        const {code_package} = barcodeScanData;
        let {weight_net} = data;
        if (configPackageUnitWeight === 'kg' && !lodash.isNil(weight_net)) {
            weight_net *= 10;
        }
        const formattedData = {
            ...data,
            weight_net
        };

        apiService.updateWeightVolume(code_package, formattedData).then(() => {
            closeModal();
            setErrors({});
            notification.success({message: "Cập nhật thông tin kiện thành công!"})
        }).catch(err => {
            const {response} = err;
            if (response.status === 400) {
                const {data} = response;
                setErrors(selectors.getErrors(data || {}));
            }
            notification.error({message: "Cập nhật thông tin kiện thất bại!"})
        }).finally(() => setUpdating(false))
    }

    let isShowVolumeByWeightBox = (lodash.isNil(input.width) || input.width === 0)
        && (lodash.isNil(input.height) || input.height === 0)
        && (lodash.isNil(input.length) || input.length === 0);
    let helpWeightNet = t(getWarningParams('weight')['message'], {value: getWarningParams('weight')['value'] ? this.getWarningParams('weight')['value'] / 10 : ''});

    let localVolume = (parseFloat(input.width || 0) * parseFloat(input.height || 0) * parseFloat(input.length || 0));
    const require_volume = lodash.get(packageInfo, 'required_contents', []).includes('volume');
    const weight_net = lodash.get(packageInfo, 'weight_net', 0);

    return <Modal
        title={t('package:detail.package_param')}
        visible={visible && !lodash.isEmpty(packageInfo) && !weight_net}
        footer={null}
        closable={true}
        onCancel={closeModal}
        width={800}
    >
        <Spin spinning={loading}>
            {
                !lodash.isEmpty(packageInfo) &&
                <Row gutter={{lg: 24, xl: 32}} className={'body a-package__body bg-gray apb-16'}>
                    <Form onSubmit={onSubmit}
                          className="ant-form ant-form-horizontal a-form form--package form-package-lg">
                        <Col span={24}>
                            <Row gutter={{lg: 24, xl: 32}} type="flex">
                                <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 6}} className="pr-0 ">
                                    <Form.Item
                                        labelCol={{span: 24}}
                                        className="a-form__item a-form--loading loading--volumn-package _weight-net-form-item"
                                        label={t('package:label.weight') + ' (' + configPackageUnitWeight + ')'}
                                        validateStatus={getWarningParams('weight')['status']}
                                        help={helpWeightNet}
                                        required={true}
                                    >
                                        <InputNumber
                                            ref={inputWeightNetRef}
                                            name={ELEMENT.PACKAGE_NET_WEIGHT}
                                            autoComplete="off"
                                            precision={2}
                                            min={0}
                                            value={input.weight_net || ''}
                                            placeholder={t('package:label.weight_net')}
                                            className="a-input a-input--bill-code-small _package-weight"
                                            onChange={(value) => onChangeInput('weight_net', value)}
                                            onPressEnter={onSubmit}
                                        />
                                        {configPackageUnitWeight === 'g' &&
                                        <span className="a-text--black-45 ml-3">x 100</span>}

                                    </Form.Item>
                                </Col>
                                <Col lg={{span: 12}} xl={{span: 16}} xxl={{span: 18}} className="pr-0 ">
                                    <div className="a-package__body--group-size">
                                        <Form.Item
                                            className="a-form__item _package-size-form-item"
                                            label={t('package:label.dimension') + ' (cm)'}
                                            labelCol={{span: 24}}
                                            required={require_volume}
                                            help={errors.volume || errors.size_drc}
                                            validateStatus={
                                                errors.volume ||
                                                errors.size_drc ||
                                                getWarningParams('length')['status'] === 'error' ||
                                                getWarningParams('width')['status'] === 'error' ||
                                                getWarningParams('height')['status'] === 'error' ? 'error' : null}
                                        >
                                            <div className="group--input-bill-code-small">
                                                {['length', 'width', 'height'].map(param => (
                                                    <div key={param}
                                                         className="a-form--loading loading-input mr-4">
                                                        <InputNumber
                                                            value={input[param] === 0 ? '' : (input[param] || '') || ''}
                                                            autoComplete="off"
                                                            placeholder={t('package:placeholder.' + param)}
                                                            title={t('package:label.' + param)}
                                                            className={`a-input a-input--package-size _package-size`  +
                                                            getInputStatusClass(getWarningParams(param)['status'] === 'error',
                                                                getWarningParams(param)['status'] === 'warning')}
                                                            precision={1}
                                                            min={0}
                                                            onChange={(value) => onChangeInput(param, value)}
                                                            onPressEnter={onSubmit}
                                                        />
                                                    </div>
                                                ))}
                                                {localVolume && !isShowVolumeByWeightBox ?
                                                    <span
                                                        className="bill-code-sub _volume">= {localVolume} (cm<sup>3</sup>)</span> : null}

                                            </div>

                                            {['length', 'width', 'height'].map(param => getWarningParams(param)['status'] === 'error' ? (
                                                <p key={param}
                                                   className="ant-form-explain mb-0 mt-1">{getWarningParams(param)['message']}</p>
                                            ) : null)}

                                            {['length', 'width', 'height'].map(param => getWarningParams(param)['status'] === 'warning' ? (
                                                <p key={param}
                                                   className="ant-form-explain mb-1 mt-1">{getWarningParams(param)['message']}</p>
                                            ) : null)}
                                            <div className="mt-2">
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Form>
                </Row>
            }
        </Spin>
    </Modal>
}

export default withTranslation()(Form.create()(UpdatePackageInfo));
