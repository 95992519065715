import React from 'react';
import { Table } from 'antd';
import { get, round } from 'lodash';
import { withTranslation } from 'react-i18next';

const List = ({ deliveryNoteReturn, t }) => {
    const columns = [
        {
            title: t('table.#'),
            dataIndex: '#',
            key: '#',
            className: 'a-text--nowrap',
            render: (text, row, index) => index + 1
        },
        {
            title: t('delivery_note:print.package_columns.codeinvoice'),
            dataIndex: 'package.code',
            key: 'package.code',
            className: "a-text--nowrap"
        },
        {
            title: t('delivery_note:print.package_columns.codeorder'),
            dataIndex: 'order.code',
            key: 'order'
        },
        {
            title: t('delivery_note:print.package_columns.totalweight'),
            dataIndex: 'package.weight_net',
            key: 'weight_net',
            className: 'a-text--nowrap',
            render: text => `${round(text, 4)} (kg)`
        }, {
            title: t('delivery_note:print.package_columns.size'),
            dataIndex: 'package.volume',
            key: 'volume',
            className: 'a-text--nowrap',
            render: text => `${round(text * 1000000, 3)} (cm3)`
        }
    ];

    const packages = get(deliveryNoteReturn, 'packages', []) || [];

    return (
        <div className="print-delivery-note--list">
            <div className="delivery-note-list--title gflex galign-center gflex-direction-column">
                <h2>{t('delivery_note:print.package_export_title')}</h2>
            </div>
            <Table
                className="a-table--antd"
                columns={columns}
                dataSource={packages}
                pagination={false}
                rowKey={row => get(row, 'package.id')}
            />
        </div>

    );
};

export default withTranslation()(List);
