import React from 'react';
import PropTypes from 'prop-types';
import lodash, { get } from 'lodash';
import { connect } from 'react-redux';
import { Icon, Skeleton, Input, InputNumber, notification } from 'antd';
import { BARCODE_TYPE } from '../../constants';
import Package from '../../../../Package/models/Package';
import Link from '../../../../../system/routing/Link';
import { DELIVERY_OBJECT } from '../../../constants';
import { ReactComponent as IconExport } from '../../../../../resources/images/Iconremove.svg';
import { ReactComponent as BagIcon } from '../../../../../resources/images/bag.svg';
import { ReactComponent as BagIconList } from '../../../../../resources/images/icontotalpackage.svg';
import { formatVolume, getVar } from '../../../../../system/support/helpers';

import Notices from './../../../../Common/components/notices/Notices';
import { currentFormatter } from '../../../../Common/services/format';
import apiService from '../../../apiService';
import { t } from '../../../../../system/i18n';
import { updateBarcode } from '../../actions';
import { linkHTC } from '../../../../Package/helper';

const mapDispatchToProps = {
    updateBarcode
};

class BarcodeItem extends React.Component {
    state = {
        packageId: getVar(this.props, 'barcode.object.id', undefined),
        infoUpdate: {
            tracking_bill: getVar(this.props, 'barcode.object.return_info.tracking_bill', undefined),
            return_fee: getVar(this.props, 'barcode.object.return_info.return_fee', undefined),
            return_address: getVar(this.props, 'barcode.object.return_info.return_address', undefined),
        },
        editables: {}
    };

    render = () => {
        const { barcode } = this.props;

        return barcode.scanning ? this.renderLoading() : this.renderBarcode(barcode);
    };

    renderLoading = () => {
        const { deliveryNoteReturn } = this.props;
        const deliveryNoteReturnId = get(deliveryNoteReturn, 'delivery_note_return.id');

        const skeleton = <Skeleton active paragraph={{ rows: 0 }} className="askeleton-item-table" />;

        return (
            <tbody>
                <tr>
                    <td>{skeleton}</td>
                    <td>{skeleton}</td>
                    <td>{skeleton}</td>
                    <td>{skeleton}</td>
                    {deliveryNoteReturnId && (
                        <>
                            <td>{skeleton}</td>
                            <td>{skeleton}</td>
                            <td>{skeleton}</td>
                        </>
                    )}
                    <td className="action-remove" />
                </tr>
            </tbody>
        );
    };

    renderBarcode = barcode => {
        const { canRemove, deliveryNoteReturn } = this.props;
        let object = this.getBarcodeObject(barcode);
        let message = barcode.error || barcode.warning;
        let rowClassName = '';
        if (barcode.error) rowClassName = 'error';
        if (barcode.warning) rowClassName = 'warning';
        const notices = lodash.get(barcode, 'notices', []) || [];
        const deliveryNoteReturnId = get(deliveryNoteReturn, 'delivery_note_return.id');

        return (
            <>
                <tbody className={rowClassName}>
                    <tr className="data editable-row">
                        <td>{object.code}</td>
                        <td>{object.order}</td>
                        <td>{object.weight ? object.weight : '--'}</td>

                        <td>{lodash.get(object, 'size', '--')}</td>
                        {deliveryNoteReturnId && (
                            <>
                                <td>{object.tracking_bill}</td>
                                <td>{object.return_fee}</td>
                                <td>{object.return_address}</td>
                            </>
                        )}

                        <td className={`sticky-right ${this.props.scrollTable ? 'box-shadow' : ''}`}>
                            <div className="gflex gjustify-end">
                                {!barcode.error && barcode.type === 'BAG' && (
                                    <span className="pointer mr-3">
                                        <BagIconList
                                            className={'abg-white'}
                                            onClick={this.props.togglePopupListPackageByBag.bind(this, barcode.object)}
                                        />
                                    </span>
                                )}

                                {canRemove && (
                                    <span className={'action-remove'}>
                                        {barcode.error ? (
                                            <Icon type="close" onClick={this.props.onRemove} />
                                        ) : (
                                            <IconExport onClick={this.props.onRemove} />
                                        )}
                                    </span>
                                )}
                            </div>
                        </td>
                    </tr>

                    {message && (
                        <tr className="message">
                            <td colSpan={deliveryNoteReturnId ? 8 : 5}>
                                {message && (
                                    <p className="mb-2 a-text--black-45">
                                        <Icon
                                            type="info-circle"
                                            theme="filled"
                                            className={
                                                barcode.error
                                                    ? 'a-text--red mr-2 '
                                                    : barcode.warning
                                                    ? 'a-text--orange mr-2 '
                                                    : ''
                                            }
                                        />{' '}
                                        {message}
                                    </p>
                                )}
                            </td>
                        </tr>
                    )}

                    {!!notices.length && (
                        <tr>
                            <td className="p-0" colSpan={deliveryNoteReturnId ? 8 : 5}>
                                <Notices className="aradius--0 border--none" displayIcon notices={notices} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </>
        );
    };

    onChangeInput = e => {
        const { name, value } = e.target;
        this.setState(state => ({
            ...state,
            infoUpdate: {
                ...state.infoUpdate,
                [name]: value,
            },
        }));
    };

    onChange = (name, value) => {
        this.setState(state => ({
            ...state,
            infoUpdate: {
                ...state.infoUpdate,
                [name]: value,
            },
        }));
    };

    onUpdate = e => {
        const { name } = e.target;
        const { packageId, infoUpdate } = this.state;
        const { barcode, updateBarcode } = this.props;
        const deliveryNoteReturnId = lodash.get(this.props, 'deliveryNoteReturn.delivery_note_return.id', undefined);

        this.toggleEditable(name);

        if (deliveryNoteReturnId) {
            apiService
                .updatePackageInfoDeliveryNoteReturn(deliveryNoteReturnId, packageId, { [name]: infoUpdate[name] })
                .then(response => {
                    updateBarcode({
                        ...barcode,
                        object: {
                            ...barcode.object,
                            return_info: response.data
                        }
                    });

                    notification.success({
                        message: t('delivery_note:message.update_delivery_return_package_info_success', {
                            attribute: t(`delivery_note:label.${name}`),
                        }),
                    });
                })
                .catch(() => {
                    notification.error({
                        message: t('delivery_note:message.update_delivery_return_package_info_fail', {
                            attribute: t(`delivery_note:label.${name}`),
                        }),
                    });
                });
        }
    };

    toggleEditable = field => {
        this.setState(prevState => ({
            editables: {
                ...prevState.editables,
                [field]: !prevState.editables[field]
            }
        }));
    };

    getBarcodeObject(barcode) {
        const { editables } = this.state;
        const { t } = this.props;

        let orderFromPackage = '';
        switch (barcode.type) {
            case BARCODE_TYPE.PACKAGE: {
                let packageData = new Package(barcode.object);
                const agencyCode = getVar(barcode, 'agency.code', '')
                const orderCode =  lodash.get(barcode, 'order.code', '--')
                const linkHtc = linkHTC(agencyCode)

                return {
                    code: (
                        <Link isNewTab params={{ id: packageData.code }} to="packages.detail">
                            <div className="icon-code mr-1" />
                            {packageData.code}
                        </Link>
                    ),
                    order: linkHtc ?     
                        <Link href={linkHtc + "/order/detail/default/" + orderCode} isNewTab>
                            {orderCode}
                        </Link> : lodash.get(barcode, 'order.m1_url') ? (
                        <Link href={barcode.order.m1_url} isNewTab>
                            {orderCode}
                        </Link>
                    ) : 
                        orderCode
                    ,
                    weight: packageData.weightNetDisplay(),
                    volume: packageData.volumeDisplay,
                    size: packageData.volumeDisplay,
                    tracking_bill: editables.tracking_bill ? (
                        <Input
                            autoFocus
                            onChange={this.onChangeInput}
                            placeholder={t('delivery_note:placeholder.tracking_bill')}
                            name="tracking_bill"
                            className="_tracking-bill"
                            value={this.state.infoUpdate.tracking_bill}
                            onBlur={this.onUpdate}
                            onPressEnter={this.onUpdate}
                        />
                    ) : (
                        <span className="editable-cell-value-wrap" onClick={this.toggleEditable.bind(this, 'tracking_bill')}>{this.state.infoUpdate.tracking_bill}</span>
                    ),
                    return_fee: editables.return_fee ? (
                        <InputNumber
                            autoFocus
                            onChange={value => this.onChange('return_fee', value)}
                            value={this.state.infoUpdate.return_fee}
                            className="_return-fee"
                            formatter={value => currentFormatter.toLocaleStringCurrency(value)}
                            name="return_fee"
                            placeholder={t('delivery_note:placeholder.return_fee')}
                            onBlur={this.onUpdate}
                            onPressEnter={this.onUpdate}
                        />
                    ) : (
                        <span className="editable-cell-value-wrap" onClick={this.toggleEditable.bind(this, 'return_fee')}>
                            {
                                (this.state.infoUpdate.return_fee == null || this.state.infoUpdate.return_fee === undefined) ? '' : currentFormatter.toLocaleStringCurrency(this.state.infoUpdate.return_fee)
                            }
                        </span>
                    ),
                    return_address: editables.return_address ? (
                        <Input.TextArea
                            autoFocus
                            onChange={this.onChangeInput}
                            autosize={{ minRows: 1, maxRows: 3 }}
                            name="return_address"
                            className="_return-address"
                            placeholder={t('delivery_note:placeholder.return_address')}
                            value={this.state.infoUpdate.return_address}
                            onBlur={this.onUpdate}
                        />
                    ) : (
                        <span className="editable-cell-value-wrap" onClick={this.toggleEditable.bind(this, 'return_address')}>{this.state.infoUpdate.return_address}</span>
                    ),
                };
            }

            case BARCODE_TYPE.BAG: {
                let bag = barcode.object;
                orderFromPackage = lodash.get(bag, 'order.code', '');
                return {
                    code: (
                        <Link isNewTab to="bags.detail" params={{ id: bag.code }}>
                            <div className="icon-code mr-1">
                                <BagIcon />
                            </div>
                            {bag.code}
                        </Link>
                    ),
                    order: orderFromPackage === '' ? barcode.order : orderFromPackage,
                    weight: `${bag.weight_real} kg`,
                    volume: formatVolume(bag.volume),
                    size: t(`bag:column.size_${lodash.get(bag, 'bag_size.code')}`),
                };
            }

            case BARCODE_TYPE.PACKAGE_ERROR: {
                let packageData = new Package(barcode.object_error);
                return {
                    code: (
                        <Link isNewTab to="packages.detail" params={{ id: packageData.code }}>
                            <div className="icon-code mr-1" />
                            {packageData.code}
                        </Link>
                    ),
                    order: lodash.get(packageData, 'order.m1_url') ? (
                        <Link href={packageData.order.m1_url} isNewTab>
                            {packageData.order.code}
                        </Link>
                    ) : (
                        lodash.get(packageData, 'order.code', '--')
                    ),
                    weight: packageData.weightNetDisplay(),
                    volume: packageData.volumeDisplay,
                    size: packageData.volumeDisplay,
                    tracking_bill: packageData.tracking_bill,
                    return_fee: packageData.return_fee,
                    return_address: packageData.return_address,
                };
            }

            default: {
                return {
                    code: barcode.code,
                    order: null,
                    weight: null,
                    volume: null,
                    tracking_bill: null,
                    return_fee: null,
                    return_address: null,
                };
            }
        }
    }
}

BarcodeItem.defaultProps = {
    package: {},
    canRemove: false,
    onRemove: () => {},
    object: DELIVERY_OBJECT.CUSTOMER,
    togglePopupListPackageByBag: () => {},
};

BarcodeItem.propTypes = {
    canRemove: PropTypes.bool,
    object: PropTypes.string,
    package: PropTypes.object,
    onRemove: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(BarcodeItem);
