import React, { PureComponent } from 'react';
import { Button, notification } from 'antd';

import api from './../../apiService';
import { handleExportBagDetailError } from './../../helper';
import { t } from './../../../../system/i18n';
import { convertBlobDataToExcel, isExportToMail } from './../../../../system/support/helpers';

class ExportBagDetails extends PureComponent {
    state = {
        loading: false
    };

    handleExport = async () => {
        this.setState({
            loading: true
        });

        try {
            const { filter } = this.props;
            const { data } = await api.exportBagDetails(filter);
            const reader = new FileReader();
            let message = t('export_bag_detail.success');

            reader.addEventListener('loadend', event => {
                const text = event.target.result;

                try {
                    const data = JSON.parse(text);

                    if (isExportToMail(data)) {
                        message = t('export_bag_detail.success_to_mail');
                    } else {
                        convertBlobDataToExcel(data, 'export_bag_details');
                    }
                } catch (e) {
                    convertBlobDataToExcel(data, 'export_bag_details');
                }

                notification.success({
                    message
                });
            });

            reader.readAsText(data);
        } catch (error) {
            handleExportBagDetailError(error);
        }

        this.setState({
            loading: false
        });
    };

    render() {
        return (
            <Button
                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2 _btn-export-excel"
                disabled={this.state.loading}
                ghost
                loading={this.state.loading}
                type="primary"
                onClick={this.handleExport}
            >
                {t('export_bag_detail.main')}
            </Button>
        );
    }

}

export default ExportBagDetails;
