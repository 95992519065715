import React from 'react';
import { Typography } from 'antd';
import { get } from 'lodash';

const { Text } = Typography;

const ProductDetail = ({ product }) => {
    return (
        <div className="product-detail">
            <div className="row tile">
                <Text>
                    Product Detail
                </Text>
            </div>

            <div className="row">
                <div className="tile width-75-pc">
                    <Text>
                        Name: {get(product, 'translated_name')}
                    </Text>
                </div>
                <div className="tile width-25-pc p-0">
                    <div className="row">
                        <div className="tile a-text--center width-100-pc">
                            <Text>
                                Product quantity
                            </Text>
                        </div>
                    </div>
                    <div className="row">
                        <div className="tile a-text--center width-100-pc">
                            <Text>
                                {get(product, 'quantity')}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
