import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';

import { BARCODE_TYPE } from '../../../../../Barcode/constants';
import { formatVolume, getVar } from '../../../../../../system/support/helpers';

import Link from './../../../../../../system/routing/Link';

import { ReactComponent as BagIconList } from './../../../../../../resources/images/totalpackage.svg';
import { ReactComponent as BagIcon } from './../../../../../../resources/images/bag.svg';

const CanDeliveryItem = ({ canDelivery, scrollTable, onOpenPackageListByBagPopup, showDeliveryRequestDetailModal, agency }) => {
    const { type } = canDelivery;
    let code,
        delivery_request_code = getVar(canDelivery, 'delivery_request.code', null);

    if (canDelivery.type === BARCODE_TYPE.BAG) {
        code = getVar(canDelivery, 'bag.code');
    }

    if (canDelivery.type === BARCODE_TYPE.PACKAGE) {
        code = getVar(canDelivery, 'package.code');
    }

    return (
        <tr>
            <td className="_code-column">
                <Link
                    isNewTab
                    params={{id: code}}
                    to={`${type === BARCODE_TYPE.BAG ? 'bags' : 'packages'}.detail`}
                >
                    {type === BARCODE_TYPE.BAG && (
                        <div className="icon-code mr-1">
                            <BagIcon />
                        </div>
                    )}
                    {code}
                </Link>
            </td>
            <td className="_code-delivery-request-column">
                {
                    delivery_request_code && agency ?
                        <span
                            className="a-text--link cursor-pointer"
                            onClick={showDeliveryRequestDetailModal.bind(undefined, {
                                code: delivery_request_code,
                                agency: agency.code
                            })}
                        >{delivery_request_code}</span> : '--'
                }
            </td>
            <td className="_order-column">
                {getVar(canDelivery, 'order.code') ? (
                    <Link
                        href={canDelivery.order.m1_url}
                        isNewTab
                    >
                        {canDelivery.order.code}
                    </Link>
                ) : '--'}
            </td>
            <td className="_weight-volume-column">
                {type === BARCODE_TYPE.BAG ? (
                    getVar(canDelivery, 'bag.weight_real') ? `${canDelivery.bag.weight_real} kg` : '--'
                ) : (
                    getVar(canDelivery, 'package.weight_net') ? `${canDelivery.package.weight_net} kg` : '--'
                )}
                {'/ '}
                {type === BARCODE_TYPE.BAG ? (
                    getVar(canDelivery, 'bag.volume') ? formatVolume(canDelivery.bag.volume) : '--'
                ) : (
                    getVar(canDelivery, 'package.volume') ? formatVolume(canDelivery.package.volume) : '--'
                )}
            </td>
            <td className="_warehouse-area-column">
                {getVar(canDelivery, 'warehouse_shelf.code', '--')}
            </td>
            <td className={clsx('_actions-column a-text--center sticky-right', {
                'box-shadow': scrollTable
            })}>
                {type === BARCODE_TYPE.BAG && (
                    <Button type="link">
                        <BagIconList onClick={onOpenPackageListByBagPopup.bind(this, {
                            bag: canDelivery.bag,
                            packages: canDelivery.packages
                        })} />
                    </Button>
                )}
            </td>
        </tr>
    );
};

export default CanDeliveryItem;
