import { withTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Row, Input, Layout, notification } from 'antd'
import ChooseWarehouseContainer from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'

import clsx from 'clsx'
import authService from '../../../Auth/authService'
import { getVar } from '../../../../system/support/helpers'
import apiService from '../../apiService'
import { find, isEmpty, get } from 'lodash'
import { dispatch } from '../../../../system/store'
import { success, error as errorSound } from '../../../Sound/actions'
import { trans } from '../../../../system/i18n'
const { Sider } = Layout

function ScanForm({ t, scroll, data, handleUpdateData }) {
    const [input, setInput] = useState({})
    const barcodeInputRef = React.useRef()
    const user_id = authService.user('id')

    const onChangeInput = (key, value) => {
        const inputNew = {
            ...input,
            [key]: value,
        }
        setInput(inputNew)
        handleUpdateData(inputNew)
    }

    const onSubmitForm = () => {
        const documentId = getVar(data, 'documentId')
        const packages = getVar(data, 'packages', [])
        const packageCode = getVar(input, 'package_code')
        const packageInfo = find(packages, ['package.code', packageCode])
        if (isEmpty(packageInfo)) {
            handleUpdateData({ scanLoading: true })
            apiService
                .addPackageToDocument(documentId, { code: packageCode })
                .then(res => {
                    handleUpdateData({ packages: getVar(res, 'data.packages.data', []) })
                    dispatch(success())
                    notification.success({
                        message: t(`return_goods:message.add_package_to_document.success`),
                    })
                })
                .catch(error => {
                    dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
                    const code = getVar(error, 'response.data.code')
                    const data = getVar(error, 'response.data.data')
                    if (code === 'INPUT_INVALID') {
                        if (getVar(data, 'package.message')) {
                            const errorMessage = getVar(data, 'package.message')
                            notification.error({
                                message: t(`return_goods:message.package_code.${errorMessage}`),
                            })
                        } else {
                            Object.entries(data).forEach(([key, value]) => {
                                if (key === 'package') {
                                    key = 'package_code'
                                }
                                if (Object.keys(value).length > 1) {
                                    const code = get(value, 'code', get(value, 'message'))
                                    const bag_lastmile = get(value, 'bag_lastmile')
                                    const packages = get(value, 'packages', [])
                                    return notification.error({
                                        message: trans(`scan:error.${code}`, {
                                            bag_code: <b>{bag_lastmile}</b>,
                                            packages: packages.join(', '),
                                            count: packages.length,
                                        }),
                                    })
                                } else {
                                    notification.error({
                                        message: t(`return_goods:message.${key}.${Object.keys(value)[0]}`),
                                    })
                                }
                            })
                        }
                    } else {
                        notification.error({
                            message: t(`return_goods:message.add_package_to_document.failed`),
                        })
                    }
                })
                .finally(() => {
                    setInput({
                        ...input,
                        package_code: undefined,
                    })
                    if (barcodeInputRef) {
                        barcodeInputRef.current.focus()
                    }
                    handleUpdateData({ scanLoading: false })
                })
        } else {
            dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
            setInput({
                ...input,
                package_code: undefined,
            })
            if (barcodeInputRef) {
                barcodeInputRef.current.focus()
            }
            notification.error({
                message: t(`return_goods:message.package_code.already_exist`),
            })
        }
    }

    return (
        <Sider
            className={clsx('a-sider-sub a-sider-sub--radius', {
                scroll,
            })}>
            <div className="a-btn--action-sider active">{t('return_goods:title.update_document')}</div>
            <div className="a-sider-sub__block">
                <h4 className="a-sider-sub__block__title">{t('return_goods:label.warehouse_process')}</h4>
                <Row
                    gutter={8}
                    className="a-form--group-button--warehouse">
                    <ChooseWarehouseContainer
                        buttonClassName="_select-create-warehouse"
                        isPermission={true}
                        value={getVar(input, 'id_warehouse')}
                        selectClassName="_select-create-warehouse"
                        onChange={value => onChangeInput('id_warehouse', value)}
                        active={true}
                        keyLocalStorage={`selectedWarehouse_${user_id}`}
                        disabled={!isEmpty(getVar(data, 'deliveryNote', {}))}
                    />
                </Row>
            </div>
            <div className="a-sider-sub__block">
                <h4 className="a-sider-sub__block__title">{t('return_goods:label.delivery_note_or_delivery_request_code')}</h4>
                <div className="a-form--loading loading-input">
                    <Input
                        placeholder={t('return_goods:placeholder.delivery_note_or_delivery_request_code')}
                        value={getVar(data, 'deliveryNote.code')}
                        disabled={!isEmpty(getVar(data, 'deliveryNote', {}))}
                    />
                </div>
            </div>
            <div className="a-sider-sub__block">
                <h4 className="a-sider-sub__block__title">{t('return_goods:label.scan_package_code')}</h4>
                <div className="a-form--loading loading-input">
                    <Input
                        ref={barcodeInputRef}
                        className="a-input--scan-code _scancode"
                        placeholder={t('return_goods:placeholder.scan_package_code')}
                        value={getVar(input, 'package_code')}
                        onChange={e => onChangeInput('package_code', e.target.value)}
                        onPressEnter={onSubmitForm}
                        disabled={isEmpty(getVar(data, 'deliveryNote', {}))}
                    />
                </div>
            </div>
        </Sider>
    )
}

export default withTranslation()(ScanForm)
