import { api } from './../../system/api'

export default {
    getBarcodePackages: (barcode, params = null) => api.get(`packages/barcode/${barcode}`, { params, singleRequest: true }),
    createByBarcode: params => api.post('packages/barcode', { ...params }, { singleRequest: true }),
    createPackageSameBarcode: params => api.post(`packages/barcode/${params.barcode}`, params),
    createPackageSameCode: params => api.post(`packages/${params.code}/create-same`, params),
    linkOrderPackage: (packageCode, params) => api.post(`packages/${packageCode}/link-order`, params),
    updateDimension: (packageCode, data) => api.put(`packages/${packageCode}/update-volume`, data),
    updateWeightPackage: params => api.put(`packages/${params.packageCode}/update-weight`, { weight_net: params.weight_net }),
    updateRelatedPackage: (id, data) => api.put(`packages/${id}/update`, data),
    updateWeightVolume: (id, data) => api.put(`packages/${id}/update-weight-volume`, data),
    getPackages: params => api.get('packages', { params }, { singleRequest: true }),
    getPackagePrint: id => api.get(`packages/${id}/print-barcode`, { singleRequest: true }),
    getPackage: (id, field = null) => api.get(`packages/${id}`, { params: { field }, singleRequest: true }),
    getPackageProducts: packageId => api.get(`packages/${packageId}/checking-items`),
    updatePackageProduct: (packageId, productId, data) => api.put(`packages/${packageId}/items/${productId}`, data, { singleRequest: true }),
    updatePackageProducts: (packageId, data) =>
        api.put(`packages/${packageId}/items`, data, {
            singleRequest: true,
        }),
    exportPackageList: params =>
        api.get(`packages/export`, {
            params,
            responseType: 'blob',
        }),
    getDataPrintOfficialPackageStamp: packageCode => api.get(`packages/${packageCode}/official-stamp`),
    getUnOfficialStampData: code => api.get(`packages/${code}/unofficial-stamp`),
    downloadTransportStatus: params =>
        api.get('packages/download-transport-status', {
            params,
            responseType: 'blob',
        }),
    getDetachSuggest: (code, params) => api.get(`packages/${code}/detach`, { params }),
    detach: (code, data) => api.post(`packages/${code}/detach`, data),
    downloadMiaTemplate: () =>
        api.get('/packages/upload/mia-template', {
            responseType: 'blob',
        }),
    importMia: file => {
        const data = new FormData()

        data.append('file', file)

        return api.post('/packages/upload/import-mia', data)
    },
    downloadCreatePackageTemplate: () =>
        api.get('/packages/upload/create-package-template', {
            responseType: 'blob',
        }),
    importCreatePackage: payload => {
        const data = new FormData()

        if (payload) {
            Object.keys(payload).forEach(key => data.append(key, payload[key]))
        }

        return api.post('/packages/upload/import-create-package', data)
    },
    updateDamagedProperty: (packageId, reasons) =>
        api.put(`/packages/${packageId}/damaged`, {
            reasons,
        }),
    uploadDamagedImages: (packageId, data) => api.post(`/packages/${packageId}/damaged-images`, data),
    finishChecking: packageId => api.put(`/packages/${packageId}/checked`),
    getComplaintSellerReasons: agencyId => api.get(`/packages/${agencyId}/complaint-seller-reasons`),
    addComplaintSeller: data => api.post(`/packages/complaint-seller`, data),
    changeDamagedStatus: data => api.put(`/packages/change-damaged-status`, data),
    getDamagedReasons: () => api.get('package-status-reasons/error/suggest'),
    getSuggestProducts: keyword =>
        api.get('/items/suggest', {
            params: {
                query: keyword,
            },
        }),
    getSuggestProductUnits: () => api.get('/items/suggest-unit'),
    createShipmentOrder: code => api.post(`/packages/${code}/create-shipment-order`),
    updateItems: (packageId, data) => api.put(`/packages/${packageId}/update-items`, data),
    updateAlonePackage: (packageId, data) => api.put(`packages/${packageId}/alone-packages/update`, data, { singleRequest: true }),
    getComplaintSeller: (packageId, complaintSellerId) => api.get(`/packages/${packageId}/complaint-seller/${complaintSellerId}`),
    getVTAndTAPStampData: (code, params) => api.get(`packages/${code}/unofficial-stamp`, { params }, { singleRequest: true }),
    getBatchStampData: (code, params) => api.get(`/packages/${code}/batch-stamp`, { params }, { singleRequest: true }),
    getPackageStatusConfig: () => api.get('/packages/status', { singleRequest: true }),
    downloadExpeditedProcessingTemplate: () =>
        api.get('/packages/expedited-processing/template', {
            responseType: 'blob',
        }),
    getDeliveryRequest: params => api.get(`/delivery-requests/group-by-midmile-shipping-partner`, { params }, { singleRequest: true }),
    syncToAgency: data => api.post(`/packages/sync-to-agency`, data),
    handleProcessTaskId: taskId => api.get(`/tasks/${taskId}`),
    getInfoPrintStampMultiSamePackage: (originPackageId, taskId) => api.get(`/packages/${originPackageId}/print-barcode-same-packages/${taskId}`),
    exportManifests: params =>
        api.get('/packages/export-manifests', {
            params,
            responseType: 'blob',
        }),
    uploadInternalCommentImages: (orderId, data) => api.post(`/orders/${orderId}/internal-comment-images`, data),
    uploadCommentImages: (orderId, data) => api.post(`/orders/${orderId}/comment-images`, data),
    getTemplateUploadCreatePackage: () =>
        api.get(`/packages/upload-packages-template`, {
            responseType: 'blob',
        }),
    validateUploadCreatePackage: data => api.post(`/packages/valid-upload-packages`, data),
    uploadCreatePackage: data => api.post(`/packages/upload-packages`, data),
    expeditedProcessingCreatePackage: (data) => api.post(`/packages/expedited-processing/create-packages`,data),
    get9710StampData: (code, params) => api.get(`packages/${code}/9710-stamp`, { params }, { singleRequest: true }),
}
