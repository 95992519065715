import lodash from 'lodash';

export default class ElementManager {
    constructor(elements = {}) {
        this.elements = elements;
    }

    set(element, dom) {
        this.elements[element] = dom;
    }

    remove(element) {
        this.elements = lodash.omit(this.elements, element);
    }

    focus(element) {
        if (this.elements[element]) {
            this.elements[element].focus();
        }
    }
}
