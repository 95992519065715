import React from 'react';
import { Icon } from 'antd';
import { get } from 'lodash';

import { dateFormatter } from './../../../Common/services/format';
import systemAvatar from './../../../../resources/images/log-sys.svg';

const isImage = (type) => {
    return type.indexOf('image') >= 0;
};

class ChatItem extends React.Component {

    renderAttachmentItem = (item) => {
        if (isImage(item.type)) {
            return (
                <a
                    className="attachment-item"
                    href={item.url}
                    key={item.hash}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img alt={item.name} src={item.url} className="img"/>
                </a>
            );
        }

        return (
            <a
                className="attachment-item"
                href={item.url}
                key={item.hash}
                rel="noopener noreferrer"
                target="_blank"
            >
                <Icon type="paper-clip"/>{get(item, 'name')}
            </a>
        );
    };

    render() {
        const {item} = this.props;
        const chatName = get(item, 'author.fullname') || get(item, 'author.username');
        const chatTime = dateFormatter.full(item.timestamp);
        const chatAvatar = get(item, 'author.avatar', systemAvatar) || systemAvatar;
        const attachments = get(item, 'attachments', []) || [];

        return (
            <div className="a-chatbox--log user" key={item.id}>
                <img className="img-ads" src={chatAvatar}
                     alt="Log system"/>
                <div className="a-chatbox--log--detail">
                    <p className="title">{chatName}, {chatTime}</p>
                    <span className="detail">{item.content}</span>
                    {attachments.length > 0 && (
                        <div className="attachments">
                            {attachments.map(a => this.renderAttachmentItem(a))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

ChatItem.defaultProps = {
    item: {}
};

export {ChatItem}
