import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const OrderList = ({ orders, selectedOrderIndex, t, onSelect }) => (
    <PerfectScrollbar
        className="order-list"
        component="ul"
        options={{
            suppressScrollX: true,
            wheelPropagation: false
        }}
    >
        {orders.map((order, index) => {
            return (
                <li
                    className={clsx('order-item', {
                        active: selectedOrderIndex === index
                    })}
                    key={get(order, 'id') || `order-${index}`}
                    onClick={onSelect.bind(undefined, index)}
                >
                    <div className="gflex galign-center gjustify-space-between">
                        {get(order, 'id') ? get(order, 'tracking_no') : t('delivery_note:not_tracked_yet')}
                    </div>
                </li>
            )
        })}
    </PerfectScrollbar>
);

OrderList.propTypes = {
    orders: PropTypes.array
};

OrderList.defaultProps = {
    orders: []
};

export default withTranslation()(OrderList);
