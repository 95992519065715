import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'package.updatePackageParams' + (param ? '.' + param : ''), defaultValue);

export const getPackageState = (state, id) => lodash.find(getState(state, 'packages', []), {id});

export const getDimensionSetting = setting => {
    let result = {};

    ['length', 'width', 'height', 'weight'].map(param => {
        result[param + '_warning'] = lodash.parseInt(setting['PACKAGE:' + param.toUpperCase() + '_WARNING']);
        result[param + '_max'] = lodash.parseInt(setting['PACKAGE:' + param.toUpperCase() + '_ALERT']);
        return null;
    });

    return result;
};

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let messages = translateValidationErrors(t('package:label.' + param), rules);
        return lodash.first(lodash.values(messages));
    });
};
