/* eslint-disable */
import React, {Component} from 'react';
import lodash from 'lodash';
import {Layout} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import List from "./List"
import FormSearch from "./FormSearch"

const Content = Layout.Content;

class ListWarehouse extends Component {
	componentDidMount() {
		this.props.setMenuActive('config/warehouse');
	}

	state = {visible: false};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = e => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};


	render() {
		const {listWarehouse, loading, onSearch, filter} = this.props;
		return (
			<Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
				<FormSearch input={filter}
				            listWarehouse={listWarehouse}
				            inputVersion={lodash.toInteger(filter.i)}
				            loading={loading}
				            onSearch={onSearch}/>
				<List listWarehouse={listWarehouse} loading={loading}/>
			</Content>

		)
	}
}

ListWarehouse.defaultProps = {
	listWarehouse: [],
	filter: {},
	loading: false,
	fetchListWarehouse: () => {
	},
	onSearch: () => {
	}
};

ListWarehouse.propTypes = {
	listWarehouse: PropTypes.array,
	fetchListWarehouse: PropTypes.func,
	onSearch: PropTypes.func,
	filter: PropTypes.object,
	loading: PropTypes.bool,

};
export default withTranslation()(ListWarehouse);
