import React, {Component} from 'react';
import {convertArrayToCSV} from 'convert-array-to-csv';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {fetchScanHistory, clearScanHistory} from './actions';
import * as selectors from './selectors';
import authService from './../../Auth/authService';
import * as selectorWarehouse from './../../Warehouse/selectors';

import ScanHistory from './components';
import lodash from "lodash";
import { BARCODE_TYPE, SCAN_ACTION } from '../../Barcode/constants';
import { getVar } from '../../../system/support/helpers';

const mapStateToProps = (state, props) => ({
    ...props,
    dataList: selectors.listDataScanHistory(state),
    user_id: authService.user('id'),
    warehouses: selectorWarehouse
});

const mapDispatchToProps = (dispatch) => ({
    onFetchScanHistory: params => dispatch(fetchScanHistory(params)),
    onClearScanHistory: params => dispatch(clearScanHistory(params)),
});

class ScanHistoryContainer extends Component {
    state = {
        exporting: false
    };

    handleExportCsv = () => {
        const {dataList, t} = this.props;
        const data = [];
        dataList.filter(datum => datum.success).map(datum => {
            const bag_codes = lodash.get(datum, "bag_codes", []);
            if (bag_codes.length > 0) {
                bag_codes.map(item => {
                    data.push({
                        [t('scan_code')]: item,
                        [t('barcode:scanning_history.column.barcode_type')]: t(`scan:label.${datum.barcode_type}`),
                        [t('time_scan')]: datum.created_at,
                        [t('action')]: t(`scan:label.${datum.status_warehouse}`),
                        [t('scan_warehouse')]: datum.code_warehouse_scan
                    })
                })
            } else {
                data.push({
                    [t('scan_code')]: datum.barcode,
                    [t('barcode:scanning_history.column.barcode_type')]: t(`scan:label.${datum.barcode_type}`),
                    [t('time_scan')]: datum.created_at,
                    [t('action')]: t(`scan:label.${datum.status_warehouse}`),
                    [t('scan_warehouse')]: datum.code_warehouse_scan
                })
            }
        });

        let csvContent = convertArrayToCSV(data);
        csvContent = `data:text/csv;charset=utf-8,\ufeff${csvContent}`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'scan_history.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleExportWarehouseStorageCsv = () => {
        const {dataList, t} = this.props;
        const data = [];
        dataList.filter(datum => (datum.success && !lodash.isEmpty(datum.packageRoutings) && datum.barcode_type === BARCODE_TYPE.PACKAGE) && datum.status_warehouse === SCAN_ACTION.IN ).map(datum => {
            const packageRoutings = getVar(datum, "packageRoutings", {})
            let hasDistributingWarehouseStorage = false;
            Object.keys(packageRoutings).map(key => {
                if (key === "storage_distributed_warehouse"  && packageRoutings[key].action === "yes") {
                    hasDistributingWarehouseStorage = true;
                }
            })
            if (hasDistributingWarehouseStorage) {
                data.push({
                    [t('package:label.code')]: datum.barcode,
                    [t('time_scan')]: datum.created_at,
                    [t('action')]: t(`scan:label.${datum.status_warehouse}`),
                    [t('scan_warehouse')]: datum.code_warehouse_scan,
                    [t('config:column.username')]: getVar(datum, 'customer.username', '---'),
                })
            }
        });

        let csvContent = convertArrayToCSV(data);
        csvContent = `data:text/csv;charset=utf-8,\ufeff${csvContent}`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'distributing_warehouse_storage.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <ScanHistory
                {...this.props}
                onExportCsv={this.handleExportCsv}
                onExportWarehouseStorageCsv={this.handleExportWarehouseStorageCsv}
            />
        );
    }
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanHistoryContainer));
