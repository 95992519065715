/* eslint-disable */
import React from 'react';
import {Layout, Select, Button} from "antd";
const Option = Select.Option;

class InventoryScanContainer extends React.PureComponent {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <Layout className="abg-white a-content--height-not-header a-content--empty inventory-scan">
                <img src={require('../../../../resources/images/box2.svg')} alt="" className="inventory-img-scan"/>
                <p className="inventory-title-scan">Hãy chọn kho để tạo kiểm kê</p>
                <Select className="a-select a-select--inventory-scan-warehouse"
                        defaultValue="CNPX">
                    <Option value="CNPX">CNPX</Option>
                    <Option value="CNGZ">CNGZ</Option>
                </Select>
                <Button className="a-btn a-btn--primary a-btn--inventory-scan-warehouse">Tạo kiểm kê</Button>
            </Layout>
        )
    }
}

export default InventoryScanContainer;