import React from 'react'
import { Icon, Layout, Modal, notification, Switch } from 'antd'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { EditForm } from '../../TemplateBag/Edit/EditForm'
import Link from '../../../system/routing/Link'
import { t } from '../../../system/i18n'
import * as actions from '../../TemplateBag/actions'
import * as selectors from '../../TemplateBag/selectors'
import * as shippingPartnerSelectors from '../../ShippingPartner/selectors'
import * as shippingPartnerActions from '../../ShippingPartner/ListShippingPartner/actions'
import * as shippingUserSelectors from '../../ShippingUser/selectors'
import * as shippingUserActions from '../../ShippingUser/actions'
import * as warehouseSelectors from '../../Warehouse/selectors'
import { getBagSizes } from '../../BagSize/selectors'
import pageService from '../../Common/Page/pageService'
import { setMenuActive } from '../../Common/actions'
import apiService from '../../TemplateBag/apiService'
import { get } from 'lodash'

const Content = Layout.Content

const mapStateToProps = state => {
    return {
        updating: state.templateBag.updating,
        errors: selectors.getErrors(_.get(state, 'templateBag.updateErrors', {})),
        shippingPartners: shippingPartnerSelectors.getShippingPartnersSuggest(state),
        shippingUsers: shippingUserSelectors.getShippingUsersSuggest(state),
        loadingShippingPartner: shippingPartnerSelectors.getLoadingList(state),
        loadingShippingUser: shippingUserSelectors.getLoading(state),
        templateBag: selectors.getTemplateBagDetail(state),
        warehouses: warehouseSelectors.getState(state, 'suggest', []),
        bagSizes: getBagSizes(state),
    }
}

const mapDispatchToProps = dispatch => ({
    // updateTemplateBag: (id, data) => dispatch(actions.updateTemplateBag(id, data)),
    resetError: field => dispatch(actions.resetError(field)),
    fetchShippingPartners: () => dispatch(shippingPartnerActions.fetchShippingPartnersSuggest()),
    fetchShippingUsers: () => dispatch(shippingUserActions.fetchShippingUsersSuggest()),
    fetchTemplateBagDetail: (id, params) => dispatch(actions.fetchTemplateBagDetail(id, params)),
    clearStateTemplateBag: () => dispatch(actions.clearStateTemplateBag()),
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

class ModalEditTemplateBag extends React.Component {
    state = {
        active: null,
        visible: false,
    }

    static getDerivedStateFromProps(props, state) {
        let newState = { ...state }
        if (state.active === null && _.has(props, 'templateBag.bag_template.active')) {
            newState.active = _.get(props, 'templateBag.bag_template.active')
            return { ...newState }
        }
        return null
    }

    componentDidMount() {
        this.props.fetchShippingPartners()
        this.props.fetchShippingUsers()
        this.props.fetchTemplateBagDetail(this.getId())
    }

    componentWillUnmount() {
        this.props.resetError('all')
        this.props.clearStateTemplateBag()
    }

    getId = () => {
        const { id } = this.props
        return id
    }

    onChangeStatus = checked => {
        this.setState({ active: checked })
    }
    

    updateTemplateBag = (id, data) => {
        apiService
            .updateTemplateBag(id, data)
            .then(res => {
                // this.props.fetchTemplateBagDetail(this.getId())
                this.props.handleCancel()
                this.props.suggestRef.current.fetchTemplateBag()
                notification.success({
                    message: t('template_bag:update_success'),
                })
            })
            .catch(err => {
                const code = get(err, 'response.data.code')
                if (code !== 'INPUT_INVALID') {
                    notification.error({
                        message: t('template_bag:update_failed'),
                    })
                }
            })
    }

    render() {
        const { active } = this.state

        return (
            <>
               

                    <Modal
                        title={t('template_bag:info')}
                        visible={true}
                        onCancel={this.props.handleCancel}
                        width={1200}
                        bodyStyle={{ padding: 0 }}
                        okButtonProps={{ hidden: true }}
                        cancelText={t('btn.cancel')}>
                        <Content className={'aml-16-lg aml-24'}>
                            <div className="a-block-config amt-16">
                                <div className="a-block-config__title gflex gjustify-space-between">
                                    <div></div>
                                    <div>
                                        <label className={'a-text--fz-12 a-text--gray mr-2'}>{t('template_bag:status')}</label>
                                        <Switch
                                            checked={!!active}
                                            size="small"
                                            className={'_switch-active'}
                                            onChange={this.onChangeStatus}
                                        />
                                    </div>
                                </div>
                                <div className="a-block-config__body">
                                    <EditForm
                                        {...this.props}
                                        updateTemplateBag={this.updateTemplateBag}
                                        id={this.getId()}
                                        active={active}
                                        inModal
                                    />
                                </div>
                            </div>
                        </Content>
                    </Modal>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditTemplateBag)
