import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.CREATE_BAG.REQUEST, saga.createBag);
    yield takeLatest(ACTION.CREATE_BAG.SUCCESS, saga.createBagSuccess);
    yield takeLatest(ACTION.CREATE_BAG.FAILED, saga.createBagFailed);

    yield takeLatest(ACTION.GET_TEMPLATE_BAGS.REQUEST, saga.getTemplateBags);
}
