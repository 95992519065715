import React, {Fragment} from 'react';
import clsx from 'clsx';
import {get, isEqual} from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Alert, Row, Typography} from 'antd';
import pageService from '../../../Common/Page/pageService';
import BagInfo from './BagInfo';
import ListBagPackages from './ListBagPackages';
import BagBlockRight from './BagBlockRight';
import {PopupCreateTemplateBag} from "./PopupCreateTemplateBag";
import {PACKAGE_ACTION} from './../../constants';
import Link from './../../../../system/routing/Link';
import {trans} from './../../../../system/i18n';

const { Paragraph } = Typography;

class BagDetail extends React.Component {
    state = {
        warningsSyncBagPackageTemplate: null
    };

    componentDidMount() {
        this.props.setMenuActive('bags');
        pageService.setTitle(this.props.t('bag:detail.title'));
    }

    componentDidUpdate(prevProps, prevState) {
        const { warningsSyncBagPackageTemplate } = this.state;
        const { bag } = this.props;

        if (!isEqual(bag, prevProps.bag)) {
            this.updateWarningsSyncBagPackageTemplate(bag);
        }

        if (!isEqual(warningsSyncBagPackageTemplate, prevState.warningsSyncBagPackageTemplate) &&  document.getElementsByClassName('warnings-sync-bag-package')[0]) {
            document.getElementsByClassName('warnings-sync-bag-package')[0].scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    onTogglePopupCreateTemplateBag = (bag) => {
        this.props.togglePopupCreateTemplateBag(bag.id);
    };

    updateWarningsSyncBagPackageTemplate = bag => {
        const { warningsSyncBagPackageRef } = this.state;

        if (!bag || !bag.warnings_sync_bag_package || !Object.keys(bag.warnings_sync_bag_package).length) {
            this.setState({
                warningsSyncBagPackageTemplate: null
            });

            return;
        }

        const warnings = [];

        if (bag.warnings_sync_bag_package.async_current_warehouses) {
            for (const asyncCurrentWarehouse of bag.warnings_sync_bag_package.async_current_warehouses) {
                warnings.push(trans('bag:warnings_sync_bag_package.async_current_warehouses', {
                    bag_warehouse: get(bag, 'current_warehouse.code'),
                    packages: asyncCurrentWarehouse.packages.map((pkg, index) => (
                        <Fragment key={pkg}>
                            <Link
                                isNewTab
                                params={{id: pkg}}
                                to="packages.detail"
                            >
                                {pkg}
                            </Link>
                            {index !== asyncCurrentWarehouse.packages.length - 1 && ', '}
                        </Fragment>
                    )),
                    packages_warehouse: asyncCurrentWarehouse.warehouse
                }));
            }
        }

        if (bag.warnings_sync_bag_package.async_status_warehouses) {
            warnings.push(trans('bag:warnings_sync_bag_package.async_status_warehouses', {
                bag_status: trans(`bag:status_warehouse.${get(bag, 'status_warehouse')}`),
                packages: bag.warnings_sync_bag_package.async_status_warehouses.map((pkg, index) => (
                    <Fragment key={pkg}>
                        <Link
                            isNewTab
                            params={{id: pkg}}
                            to="packages.detail"
                        >
                            {pkg}
                        </Link>
                        {index !== bag.warnings_sync_bag_package.async_status_warehouses.length - 1 && ', '}
                    </Fragment>
                )),
                packages_status: trans(`bag:status_warehouse.${get(bag, 'status_warehouse') === PACKAGE_ACTION.IN ? PACKAGE_ACTION.OUT : PACKAGE_ACTION.IN}`)
            }))
        }

        if (bag.warnings_sync_bag_package.async_status_transports) {
            warnings.push(trans('bag:warnings_sync_bag_package.async_status_transports', {
                packages: bag.warnings_sync_bag_package.async_status_transports.map((pkg, index) => (
                    <Fragment key={pkg}>
                        <Link
                            isNewTab
                            params={{id: pkg}}
                            to="packages.detail"
                        >
                            {pkg}
                        </Link>
                        {index !== bag.warnings_sync_bag_package.async_status_transports.length - 1 && ', '}
                    </Fragment>
                ))
            }))
        }

        this.setState({
            warningsSyncBagPackageTemplate: (
                <Alert
                    className="amt-20 warnings-sync-bag-package"
                    message={warnings.map((warning, index) => (
                        <Paragraph
                            className={clsx({
                                'mb-0': index === warnings.length - 1,
                                'mb-2': index !== warnings.length - 1
                            })}
                            key={index}
                        >
                            {warning}
                        </Paragraph>
                    ))}
                    ref={warningsSyncBagPackageRef}
                    showIcon
                    type="warning"
                />
            )
        });
    };

    renderWarningsSyncBagPackage = () => {
        const { warningsSyncBagPackageTemplate } = this.state;

        return warningsSyncBagPackageTemplate;
    };

    render() {
        let bag = this.props.bag;
        let permissions = this.props.bag ? this.props.bag.permissions : "";
        let settings = this.props.settings;

        if (!bag) {
            return null;
        }

        // Comment
        const {agencies, bagAreaSuggests, exporting, isShowPopupCreateTemplateBag, requiredAgencyWhenCreateBag} = this.props;

        return (
            <Row type="flex">
                {this.props.loading ? <div className="a-loading-page__icon"/> : null}

                <div className="a-content--ischatbox a-package--detail pt-0">
                    <BagInfo
                        agencies={agencies}
                        bag={bag}
                        bagAreas={bagAreaSuggests}
                        exporting={exporting}
                        settings={settings}
                        permissions={permissions}
                        requiredAgencyWhenCreateBag={requiredAgencyWhenCreateBag}
                        createSimilarBag={this.props.createSimilarBag}
                        onTogglePopupCreateTemplateBag={this.onTogglePopupCreateTemplateBag}
                        exportBag={this.props.exportBag}
                        updateBag={this.props.updateBag}
                        packages={this.props.packages}
                    />

                    {this.renderWarningsSyncBagPackage()}

                    <ListBagPackages
                        fetchBag={this.props.fetchBag}
                        bag={this.props.bag}
                        loading={this.props.loading}
                        packages={this.props.packages}
                    />
                </div>
                <BagBlockRight bag={bag}/>

                {isShowPopupCreateTemplateBag && (
                    <PopupCreateTemplateBag
                        {...this.props}
                    />
                )}
            </Row>
        );
    }
}

BagDetail.defaultProps = {
    bag: {},
    packages: [],
    loading: false,
};

BagDetail.propTypes = {
    bag: PropTypes.object,
    loading: PropTypes.bool,
    packages: PropTypes.array
};

export default withTranslation()(BagDetail);
