import React from 'react'
import { Typography } from 'antd'
import { get, round } from 'lodash'

const { Text } = Typography

const ShipmentDetail = ({ packageData, productQuantity, services, properties, warehouse_services }) => (
    <div className="shipment-detail">
        <div className="row tile">
            <Text
                strong
                className={'a-text--fz-14 a-text--bold'}>
                Shipment Detail
            </Text>
        </div>

        <div className="row">
            <div className="tile width-25-pc">
                <Text>Property</Text>
            </div>
            <div className="tile width-75-pc">
                <Text className="a-text--bold">{(properties || []).join(', ')}</Text>
            </div>
        </div>

        <div className="row">
            <div className="tile width-25-pc">
                <Text>Service</Text>
            </div>
            <div className="tile width-75-pc">
                <Text className="a-text--bold">{([...services, ...warehouse_services] || []).join(', ')}</Text>
            </div>
        </div>

        <div className="row">
            <div className="a-text--center a-flex galign-center gjustify-center px-0 tile width-25-pc">
                <Text>Product Quantity</Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center px-0 tile width-25-pc">
                <Text>Actual weight</Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center px-0 tile width-25-pc">
                <Text>Volume</Text>
            </div>
            <div className="a-text--center a-flex galign-center gjustify-center px-0 tile width-25-pc">
                <Text>Chargeable weight</Text>
            </div>
        </div>

        <div className="row">
            <div className="a-text--center tile width-25-pc">
                <Text
                    strong
                    className={'a-text--fz-13 a-text--bold'}>
                    {productQuantity}
                </Text>
            </div>
            <div className="a-text--center tile width-25-pc">
                <Text
                    strong
                    className={'a-text--fz-13 a-text--bold'}>
                    {round(parseFloat(get(packageData, 'weight_net')) + parseFloat(get(packageData, 'weight_box')), 4)}kg
                </Text>
            </div>
            <div className="a-text--center tile width-25-pc">
                <Text
                    strong
                    className={'a-text--fz-13 a-text--bold'}>
                    {get(packageData, 'volume')}m3
                </Text>
            </div>
            <div className="a-text--center tile width-25-pc"></div>
        </div>
    </div>
)

export default ShipmentDetail
