import React, {Fragment} from 'react'
import { Affix, Table } from 'antd';
import clsx from 'clsx';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LIST_STAFF_IN_WAREHOUSE_COLUMN } from './../../constants';

import AddStaffInWarehouse from './../../AddStaffInWarehouse/AddStaffInWarehouseContainer';

class ListUserWarehouse extends React.Component {
	componentDidMount() {
		this.props.setMenuActive('config/warehouse');
	}

	getColumns = () => {
        return values(LIST_STAFF_IN_WAREHOUSE_COLUMN).map(column => {
			return {
				key: column,
				dataIndex: column,
				title: column !== LIST_STAFF_IN_WAREHOUSE_COLUMN.ACTION ? this.props.t(`config:column.${column}`) : '',
				render: (text, row) => {
					let active = row.active ? 'a-notification-dot a-notification-dot--table active' : 'a-notification-dot a-notification-dot--table unactive';
					let listClass = {
						code_warehouse: active,
						action: 'a-text--right'
					};
					return {
						children: text,
						props: {
							className: clsx(listClass[column], `_row-${column} a-text--nowrap`)
						}
					};
				}
			};
		});
	};

	render() {
		const {t, warehouseData, staffWarehouseData, loading} = this.props;
		return <Fragment>
			<div className={"amt-16"}>
				<Affix>
					<div className="a-content__top a-content__top--page-list mr-0 mt-0">
						<div className="a-content--page-list__title gflex galign-center">
							<h3 className="a-text--upppercase _label-list-warehouse-staff">
                                {t('config:label.list_warehouse_staff')} ({staffWarehouseData.length || 0})</h3>
						</div>
						<div className="a-content--page-list__top-action gflex ">
							{/*<Button type="primary"*/}
							{/*        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2"*/}
							{/*        ghost>Xuất CSV*/}
							{/*</Button>*/}
							{warehouseData.active ? <AddStaffInWarehouse/> : null}
						</div>
					</div>
				</Affix>
				<div className="a-content__body bg-white a-content__body--page-list  height-100 mr-0">
					<Table className={"a-table--antd __a-table-staff-in-warehouse"} columns={this.getColumns()} dataSource={staffWarehouseData}
                           pagination={false}
                           loading={loading}
                           scroll={{x: 'fit-content'}}
					/>
				</div>
			</div>
		</Fragment>
	}
}

ListUserWarehouse.defaultProps = {
	warehouseData: {},
	staffWarehouseData: [],
	loading: false
};

ListUserWarehouse.propTypes = {
	warehouseData: PropTypes.object,
	loading: PropTypes.bool,
	staffWarehouseData: PropTypes.array,

};
export default withTranslation()(ListUserWarehouse)
