import { get, isNumber, round } from 'lodash';

import { DAMAGED_STATUSES } from './../constants';
import authService from './../../Auth/authService';
import Model from './../../Common/base/Model';

class Package extends Model {
    get volumeAsCm3() {
        return round(this.volume * 1000000, 3);
    }

    weightNetDisplay(bySetting) {
        const packageUnitWeightConfig = get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        let weightNet = this.weight_net;

        if (!bySetting) {
            return `${round(weightNet, 4)} kg`;
        }

        if (packageUnitWeightConfig === 'g') {
            weightNet *= 10;
        }

        return `${round(weightNet, 4)} ${packageUnitWeightConfig}`;
    }

    get volumeDisplay() {
        return `${round(this.volume * 1000000, 3)} cm3`;
    }

    get canClearDamagedReasons() {
        return get(this.package_detail, 'status_damaged') === DAMAGED_STATUSES.CHECKING;
    }

    get realWeightNetWhenUpdate() {
        const packageUnitWeightConfig = get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        let weightNet = this.weight_net;

        if (!weightNet && !isNumber(weightNet)) {
            return weightNet;
        }

        if (packageUnitWeightConfig === 'g') {
            weightNet *= 10;
        }

        return weightNet;
    }
}

export default Package;
