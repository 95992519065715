/* eslint-disable */
import React from 'react';
import {Form, Select,Button, Row, Col, Icon,Input,DatePicker} from 'antd';

const Search = Input.Search;
class FormSearch extends React.PureComponent{
    render() {
        return(
            <div className="a-content--search-header search-default" id="list-search">
                <Row gutter={24}>
                    <Form className="a-form">
                        <Col lg={18} xl={17} xxl={18} className="a-block-info  pb-0 pt-2">
                            <Row gutter={{lg: 12, xl: 12, xxl: 24}}>
                                <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 3}}
                                     className="info--group mb-0 amb-16-lg">
                                    <label className="info--label">Kho kiểm kê </label>
                                    <span className="info--value ">VNSGZ</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                     className="info--group mb-0 amb-16-lg">
                                    <label className="info--label">Người kiểm kê </label>
                                    <span className="info--value ">Lê Hoàng Thuỳ Trang</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                     className="info--group mb-0 amb-16-lg">
                                    <label className="info--label">Thời gian kiểm kê</label>
                                    <span className="info--value ">12:15 29/02/2019</span>
                                </Col>
                                <Col lg={{span: 5}} xl={{span: 4}} xxl={{span: 4}}
                                     className="info--group mb-0 amb-16-lg">
                                    <label className="info--label">Thời gian đối soát</label>
                                    <span className="info--value ">12:15 29/02/2019</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 3}}
                                     className="info--group mb-0">
                                    <label className="info--label">Đã quét</label>
                                    <span className="info--value ">839</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 3}}
                                     className="info--group mb-0">
                                    <label className="info--label">Số kiện thiếu </label>
                                    <span className="info--value ">102</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 3}}
                                     className="info--group mb-0">
                                    <label className="info--label">Số kiện thừa</label>
                                    <span className="info--value ">104</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={7} xxl={6} className="search--right inventory-detail">
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label="Mã kiểm kê"
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search"
                                />
                            </Form.Item>
                            <div className="search--action">
                                <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                                <Button type="primary" htmlType="submit"
                                        className="a-btn a-btn--primary a-btn--search-list ">Tìm
                                    kiếm</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>
        )
    }
}
export default FormSearch;