import {combineReducers} from 'redux';
import listShippingPartner from './ListShippingPartner/reducer';
import formShippingPartner from './FormShippingPartner/reducer';
import editShippingPartner from './EditShippingPartner/reducer';

export default combineReducers({
    listShippingPartner,
    formShippingPartner,
    editShippingPartner
});
