import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import { finishLoadPrintTemplate, loadPrintTemplate } from '../actions'
import { getVar } from '../../../../system/support/helpers'

class PrintWarehouseAreaStamp extends Component {
    constructor(props) {
        super()

        props.loadPrintTemplate()
    }

    componentDidMount() {
        const { finishLoadPrintTemplate, onDidMount } = this.props

        finishLoadPrintTemplate()
        onDidMount()
    }

    render() {
        const data = getVar(this.props, 'data', [])

        return data.map((item, index) => (
            <div key={index}>
                {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
                <div className={`a-stamp stamp-print`}>
                    <div id="a-stamp--print">
                        <div className="a-stamp--barcode">
                            <Barcode
                                width={2.4}
                                height={50}
                                displayValue={false}
                                value={getVar(item, 'code')}
                                copyStyles={true}
                            />
                        </div>
                        <div className="a-stamp--print--bottom">
                            <div className="a-stamp--print--block gjustify-center a-text--bold a-text--uppercase">{getVar(item, 'code')}</div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }
}

PrintWarehouseAreaStamp.defaultProps = {
    onDidMount: () => {},
}

PrintWarehouseAreaStamp.propTypes = {
    onDidMount: PropTypes.func,
}

export default connect(undefined, {
    finishLoadPrintTemplate,
    loadPrintTemplate,
})(PrintWarehouseAreaStamp)
