import React, { Fragment } from 'react';
import { notification } from 'antd';
import { get } from 'lodash';

import { trans } from './../../system/i18n';

import Link from './../../system/routing/Link';

export const handleDeliveringDeliveryRequestError = error => {
    const errorData = get(error, 'response.data');
    let errorMessage = trans('delivery_request:delivering.failed');

    if (get(errorData, 'data.unprocess_packages')) {
        errorMessage = trans('delivery_request:delivering.errors.unprocess_packages', {
            packages: errorData.data.unprocess_packages.map((pkg, index) => (
                <Fragment key={pkg}>
                    <Link isNewTab params={{id: pkg}} to="packages.detail">
                        {pkg}
                    </Link>
                    {index !== (errorData.data.length - 1) && ', '}
                </Fragment>
            ))
        })
    }

    notification.error({
        message: errorMessage
    });
};
