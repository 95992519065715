import {message} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {inputFocusHandler} from './services';
import {getState} from '../../../system/store';

export function* fetchBarcodePackages(action) {
    yield processApiRequest(ACTION.FETCH_BARCODE_PACKAGES, () => apiService.getBarcodePackages(action.payload.barcode, action.payload), action.payload);
}

export function* onFetchBarcodePackagesFailed(action) {
    if (action.payload) {
        yield message.error(t('package:create.message_cant_load_barcode_packages'));
    }
}

/**
 * Xử lý autofocus khi thay đổi danh sách kiện
 */
export function* onChangePackages() {
    yield setTimeout(() => {
        inputFocusHandler.onChangePackages(getState('package.createPackageViaScan.packages', []), getState('package.createPackageViaScan.orderSuggests', []));
    }, 10);
}

