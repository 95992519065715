import { Layout, Tabs } from 'antd'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import pageService from '../Common/Page/pageService'
import Page403 from '../Common/components/403'

const { Content } = Layout
const { TabPane } = Tabs

const ExpeditedProcessingTabs = props => {
    const { filter, tabs, onSearch, setMenuActive, t } = props

    useEffect(() => {
        pageService.setTitle(t('common:menu.expedited_processing'))
        setMenuActive('packages/expedited-processing')
    }, [])

    const onChangeTabs = tab => {
        const newFilter = {}
        newFilter.tab = tab
        onSearch(newFilter)
    }

    return (
        <Layout>
            <Content className="a-content a-content--page-list">
                <Tabs
                    activeKey={filter.tab}
                    animated={false}
                    className="a-tabs a-tabs--container"
                    onChange={onChangeTabs}>
                    {tabs.map(tab =>
                        tab.permission ? (
                            <TabPane
                                key={tab.key}
                                tab={tab.title}>
                                {get(filter, 'tab') === tab.key && tab.component}
                            </TabPane>
                        ) : (
                            <TabPane
                                key={tab.key}
                                tab={tab.title}>
                                 <div className="a-content--search-header">
                                    <Page403 />
                                </div>
                            </TabPane>
                        )
                    )}
                </Tabs>
            </Content>
        </Layout>
    )
}

export default ExpeditedProcessingTabs
