import lodash from 'lodash';
import {createSelector} from 'reselect';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'bag.updateBagParam' + (param ? '.' + param : ''), defaultValue);

export const getError = createSelector(
    (state) => getState(state, 'error'),
    (error) => lodash.mapValues(error, (error, param) => {
        if (param === 'type') {
            param = 'id_customer'
        }
        let messages = translateValidationErrors(t('bag:label.' + param), lodash.isPlainObject(error.data) ? error.data[param] : {});
        let message = lodash.first(lodash.values(messages));
        if (!lodash.isNil(lodash.get(error, 'data.packing_complete'))) {
            message = t('bag:message.packing_complete')
        }
        return !message && error ? t('message.update_failed') : message;
    }),
);
