export const FETCH_SHIPPING_USER = {
    REQUEST: 'FETCH_SHIPPING_USER.REQUEST',
    SUCCESS: 'FETCH_SHIPPING_USER.SUCCESS',
    FAILED: 'FETCH_SHIPPING_USER.FAILED'
};

export const FETCH_SHIPPING_USER_SUGGEST = {
    REQUEST: 'FETCH_SHIPPING_USER_SUGGEST.REQUEST',
    SUCCESS: 'FETCH_SHIPPING_USER_SUGGEST.SUCCESS',
    FAILED: 'FETCH_SHIPPING_USER_SUGGEST.FAILED'
};
