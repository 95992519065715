import React from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';

const Actions = ({ addingOrder, order, printing, selectedOrderIndex, t, onDelete, onPrint }) => (
    <div className="actions a-tile">
        {selectedOrderIndex !== -1 && (
            <>
                <Button className="mr-3" type="link" onClick={onDelete}>
                    {t('delete_order.title')}
                </Button>
                {!(order && order.id) && (
                    <Button htmlType="submit" loading={addingOrder}>
                        {t('delivery_note:place.title')}
                    </Button>
                )}
                {!!(order && order.id) && (
                    <Button loading={printing} onClick={onPrint}>
                        {t('print_stamp.title')}
                    </Button>
                )}
            </>
        )}
    </div>
);

export default withTranslation()(Actions);
