import lodash from 'lodash';

import {t} from '../../../system/i18n';

/**
 * Merge data collection item
 *
 * @param {object} currentData
 * @param {object|function} newData
 * @return {object}
 */
const mergeCollectionItemData = (currentData, newData) => {
    let data = lodash.isFunction(newData) ? newData(currentData) : newData;

    return {...currentData, ...data};
};

/**
 * Update data của item trong collection
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const updateCollectionItem = (collection, itemId, data, idParam = 'id') => {
    itemId = lodash.isArray(itemId) ? itemId : [itemId];

    return collection.map(item => itemId.includes(item[idParam]) ? mergeCollectionItemData(item, data) : item);
};

/**
 * Gán data cho item trong collection (nếu collection không tồn tại item thì sẽ thêm mới)
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const setCollectionItem = (collection, itemId, data, idParam = 'id') => {
    let item = lodash.find(collection, {[idParam]: itemId});

    return item
        ? updateCollectionItem(collection, itemId, data, idParam)
        : [...collection, mergeCollectionItemData({[idParam]: itemId}, data)];
};

/**
 * Translate validation errors
 *
 * @param {string} attribute
 * @param {object} errors
 * @param {boolean} ignoreCustomRule
 * @return {object}
 */
export const translateValidationErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        const messageKey = `validation:${rule}`;

        if (lodash.has(data, 'other')) {
            data = {...data, other: t('bag:label.' + data.other).toLowerCase()}
        }

        if (lodash.has(data, 'values')) {
            data = {
                ...data,
                values: t('common:validation_fields.' + data.values).toLowerCase()
            };
        }

        const message = t(messageKey, {...data, attribute});

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

/**
 * Tạo state cho các đối tượng từ api response
 * VD: {package: {id: 123, ...}, order: {id: 456, ...}} => {id: 123, order: {id: 456, ...}, ...}
 *
 * @param {Object} apiRes
 * @param {String} param
 * @return {Object}
 */
export const makeModelStateFromApiRes = (apiRes, param) => {
    return {...apiRes[param], ...lodash.omit(apiRes, param)}
};


export const formatCurrency = (value, dec_point = ',') => {
    if (lodash.isNaN(value)) {
        return 0;
    }

    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, dec_point);
};

export const replaceText = (text) => {
    return text.toString().replace(/\$\s?|(,*)/g, '');
}

export const removeDotFromNumber = (text) => {
    return text.toString().replace(/\./g, '');
}

/**
 *
 * @param input
 * @returns {*}
 * @private
 */
export const converValue2Int = (input, excludes = []) => {
    Object.keys(input).forEach(function (property) {
            if (!excludes.includes(property) && Array.isArray(input[property])) {
                input[property] = input[property].map(value => {
                    return parseInt(value, 10);
                })
            }

            if (input[property] === 'false') {
                input[property] = false;
            }

            if (input[property] === 'true') {
                input[property] = true;
            }

            return property;
        }
    );
    return input;
}
