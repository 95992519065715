import React from 'react';
import {t} from '../../../../system/i18n';
import {Col, Icon} from "antd";
import lodash from "lodash";

import * as selectors from '../selectors';
import * as actions from '../actions';

import {connect} from "react-redux";
import FormBagShippingTypeContainer from "../../BagShippingType/FormBagShippingType/FormBagShippingTypeContainer";
import {BAG_SHIPPING_TYPE} from "../../BagShippingType/constants";
import SelectCreatePartnerContainer from "../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer";
import SelectCreateShippingUserContainer from "../../../ShippingUser/Select/SelectCreate";
import UpdateBagParamContainer from "../../UpdateBagParam/UpdateBagParamContainer";

const mapStateToProps = state => {
    return {
        loading: selectors.getLoading(state),
        error: ''
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateBag: (id, data, onCallback) => dispatch(actions.updateBag(id, data, onCallback))
    };
};

const getRawBagObject = (model) => {
    return {
        ...model,
        shippingType: model.shippingType,
        shipping_partner: model.shipping_partner,
        shipping_user: model.shipping_user
    };
};

class UpdateShippingType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            bag: null,
            oldBag: null,
            loading: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.bag === null && lodash.isObject(nextProps.bag) && !lodash.isEmpty(nextProps.bag)) {
            let dataBag = getRawBagObject(nextProps.bag);
            return {
                bag: {...dataBag},
                oldBag: {...dataBag}
            }
        }

        if (!prevState.editing && JSON.stringify(prevState.bag) !== JSON.stringify(getRawBagObject(nextProps.bag))) {
            return {
                bag: {...getRawBagObject(nextProps.bag)},
                oldBag: {...getRawBagObject(nextProps.bag)}
            }
        }

        return null;
    }

    onToggleEdit = () => {
        this.setState({editing: !this.state.editing});
    };

    onCloseEdit = () => {
        this.setState({
            editing: false,
            bag: getRawBagObject(this.props.bag)
        });
    };
    onCallback = (e) => {
        this.setState({loading: false});
    };
    onSubmit = () => {
        const {bag} = this.state;
        const bagId = lodash.get(this.props.bag, 'id', 0);
        let data = {
            subject_shipping: bag.shippingType,
        };
        if (bag.shippingType === BAG_SHIPPING_TYPE.USER) {
            data.id_shipping_user = bag.id_shipping_user;
        } else {
            data.id_shipping_partner = bag.id_shipping_partner;
        }
        this.setState({loading: true});
        this.props.updateBag(bagId, data, this.onCallback);

        if (!this.props.loading) {
            this.setState({
                editing: false
            });
        }
    };

    onChangeShippingType = value => {
        this.setState(prevState => ({
            bag: {
                ...prevState.bag,
                shippingType: value
            }
        }));
    };

    onChangeShippingUser = (value) => {
        let {bag} = this.state;
        bag.id_shipping_user = lodash.has(value, 'value') ? lodash.get(value, 'value') : value;
        this.setState({bag});
    };

    onChangeShippingPartner = (value) => {
        let {bag} = this.state;
        bag.id_shipping_partner = lodash.has(value, 'value') ? lodash.get(value, 'value') : value;
        this.setState({bag});
    };

    renderActions() {
        const {bag} = this.state;
        if (!this.props.permissions.can_update) {
            return null;
        }

        if (this.state.loading) {
            return <Icon type="loading-3-quarters" className="icon-loading ml-3"/>
        }

        if (this.state.editing) {
            return <React.Fragment>
                <Icon type="close" className="icon-inline" onClick={this.onCloseEdit}/>
                {(bag.id_shipping_user > 0 || bag.id_shipping_partner > 0) && (
                    <Icon type="check" className="icon-inline" onClick={this.onSubmit}/>
                )}
            </React.Fragment>
        }

        return <Icon type="edit" className="icon-inline" onClick={this.onToggleEdit}/>
    }

    renderSelectObjectShipping = () => {
        const { bag } = this.state;
        const { getPartnersByWarehouse, warehouseId } = this.props;

        let defaultPlaceholder = '';
        if (bag.shippingType === BAG_SHIPPING_TYPE.USER) {
            defaultPlaceholder = t('shipping_user:placeholder.id_shipping_user');
            let full_name = lodash.get(bag, "shipping_user.full_name", "--");
            let user_name = lodash.get(bag, "shipping_user.full_name", "--") + "(" + lodash.get(bag, "shipping_user.username", "--") + ")";
            let name = lodash.get(bag, 'shipping_user.username') ? user_name : full_name;
            let placeholderShippingUser = bag.shipping_user ? name : defaultPlaceholder;
            return <SelectCreateShippingUserContainer
                placeholder={placeholderShippingUser}
                autoSelect
                emptyOption={false}
                disabled={this.state.loading}
                loading={this.state.loading}
                className="a-select a-select--search-content _id_shipping_user"
                value={bag.id_shipping_user > 0 ? bag.id_shipping_user : null}
                onChange={this.onChangeShippingUser}
                selectProps={{style: {width: "100%"}}}
            />;
        } else {
            defaultPlaceholder = t('shipping_partner:placeholder.id_shipping_partner');
            let placeholderShippingPartner = bag.shipping_partner ? lodash.get(bag, "shipping_partner.name", "--") : defaultPlaceholder;
            return (
                <SelectCreatePartnerContainer
                    autoSelect
                    className="a-select a-select--search-content _id_shipping_partner"
                    disabled={false}
                    placeholder={placeholderShippingPartner}
                    getPartnersByWarehouse={getPartnersByWarehouse}
                    selectProps={{style: {width: "100%"}}}
                    value={bag.id_shipping_partner}
                    warehouseId={warehouseId}
                    onChange={this.onChangeShippingPartner}
                />
            );
        }
    };

    renderDefaultControlShippingObject = () => {
        const { bag } = this.state;
        const { getPartnersByWarehouse, warehouseId } = this.props;
        bag.id = lodash.get(this.props.bag, 'id');
        if (bag.shippingType === BAG_SHIPPING_TYPE.USER) {
            return <UpdateBagParamContainer
                status={this.props.getBagStatus(this.props.permissions.can_update)}
                bag={bag}
                param="id_shipping_user"
            />;
        } else if (bag.shippingType === BAG_SHIPPING_TYPE.PARTNER) {
            return (
                <UpdateBagParamContainer
                    bag={bag}
                    getPartnersByWarehouse={getPartnersByWarehouse}
                    param="id_shipping_partner"
                    status={this.props.getBagStatus(this.props.permissions.can_update)}
                    warehouseId={warehouseId}
                />
            );
        } else {
            return (
                <UpdateBagParamContainer
                    bag={bag}
                    canUpdate={false}
                    className={'_shipping_name'}
                    getPartnersByWarehouse={getPartnersByWarehouse}
                    param="shipping_name"
                    status={this.props.getBagStatus(this.props.permissions.can_update)}
                    warehouseId={warehouseId}
                />
            );
        }
    };

    render() {
        const {bag, editing, loading} = this.state;
        // const {loading} = this.props;
        let value = lodash.get(bag, "shippingType") === 'PARTNER' ? t('bag:label.id_shipping_partner') : lodash.get(bag, "shippingType") === 'USER' ? t('bag:label.id_shipping_user') : "--";
        return (
            <>
                <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                    <div className={'a-inline-edit ' + this.props.className}>
                        <label className="a-inline-edit--label">
                            {t('bag:label.shipping_type')}
                            {this.renderActions()}
                        </label>
                        {!!editing && this.props.permissions.can_update ? (
                            <div className="a-inline-edit--input">
                                <React.Fragment>
                                    <FormBagShippingTypeContainer
                                        value={bag.shippingType}
                                        disabled={false}
                                        autoSelect={true}
                                        onChange={this.onChangeShippingType}
                                        dropdownClassName={'_dropdown_subject_shipping'}
                                        noForm={true}
                                        loading={loading}
                                    />
                                    {this.props.error ? <p className="a-text--error mt-1">{this.props.error}</p> : null}
                                </React.Fragment>
                            </div>
                        ) : (
                            <div className="a-inline-edit--value">{value}</div>
                        )}
                    </div>
                </Col>

                <Col lg={{span: 14, offset: 0}} xl={{span: 7, offset: 1}}
                     xxl={{span: 6, offset: 1}}
                     className="info--group">
                    {editing === true && (
                        <div className={'a-inline-edit ' + this.props.className}>
                            <label className="a-inline-edit--label">
                                {bag.shippingType === BAG_SHIPPING_TYPE.USER ? t('bag:label.id_shipping_user') : t('bag:label.id_shipping_partner')}
                            </label>
                            <div className="a-inline-edit--input">
                                {this.renderSelectObjectShipping()}
                            </div>
                        </div>
                    )}

                    {editing === false && this.renderDefaultControlShippingObject()}

                </Col>
            </>
        );
    }
}

UpdateShippingType.defaultProps = {
    permissions: {}
};

UpdateShippingType = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateShippingType);

export {UpdateShippingType}
