import { flatten, get, map } from 'lodash'
import { combineReducers } from 'redux'

import { PACKING_PACKAGE } from './constants'
import { SOUND_CONSTANT } from './../../Sound/constants'

const failedPackedPackageCount = (state = 0, action) => {
    switch (action.type) {
        case PACKING_PACKAGE.SUCCESS: {
            let newState = state

            const packing_barcode_type = get(action, 'request.packing_barcode_type')

            if (packing_barcode_type === 'bag_last_mile_freight_bill') {
                const listSound = flatten(map(get(action,"payload.bagPackings", []), 'notices.sound', []))
              
                for (const notice of listSound) {
                    if (notice.includes(SOUND_CONSTANT.PACKAGED.toLowerCase())) {
                        newState += 1
                    }
                }
            } else {
                const soundNotices = get(action.payload, 'notices.sound', [])

                for (const notice of soundNotices) {
                    if (notice.includes(SOUND_CONSTANT.PACKAGED.toLowerCase())) {
                        newState += 1
                    }
                }
            }

            return newState
        }
        case PACKING_PACKAGE.FAILED: {
            return state + 1
        }
        default: {
            return state
        }
    }
}

export default combineReducers({
    failedPackedPackageCount,
})
