/* eslint-disable */

import React from 'react';
import Search from './components/Customer'
import SiderBagPack from '../Sidebar/modules/ScanExport';
import {Layout} from "antd";
class ScanExportContainer extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {scroll: false};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false});
            }
        }
    };

    render() {
        const {scroll} = this.state;
        return(
            <Layout>
                <SiderBagPack scroll={scroll}/>
                <Search scroll={scroll}/>
                {/*<div className="a-content--empty">*/}
                {/*    <img className="img-ads" src={require('../../../resources/images/Group 2867.png')}*/}
                {/*         alt="Log system"/>*/}
                {/*         <span className="a-text--uppercase description"> HÃY LỰA CHỌN ĐỐI TƯỢNG ĐỂ QUÉT MÃ XUẤT KHO</span>*/}
                {/*</div>*/}
            </Layout>
        )
    }
}
export default ScanExportContainer;