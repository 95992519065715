import React from 'react';
import {Button, Col, Icon, Layout, Row} from "antd";
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

const Content = Layout.Content;

class DetailBagSize extends React.Component {

    render() {
        let {t, bagSizeDetail} = this.props;
        let {id} = this.props.match.params;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <Link to="/config/bag-sizes" className={"a-text--gray _btn-back"}>
                        <Icon type="arrow-left" className={"mr-2"}/>{t("bag_size:label.back_bag_size")}</Link>
                </div>
                <div className="a-block-config amt-16 a-form">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t("bag_size:label.info")}</h3>
                        <div className={`a-notification-dot ${bagSizeDetail.active ? 'active': 'unactive'} pl-5`}>
                            <span
                                className={"_info-active"}>{bagSizeDetail.active ? t("common:label.working") : t("common:label.stop")}</span>
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <Row gutter={{lg: 24, xl: 32}} className="a-block-info info--detail">
                            <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 7}} className="info--group">
                                <label className="info--label">{t("bag_size:label.name")}</label>
                                <span className="info--value _name">{bagSizeDetail.name}</span>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 3}} className="info--group">
                                <label className="info--label">{`${t("bag_size:label.length")} (cm)`}</label>
                                <span className="info--value _info-length">{bagSizeDetail.length * 100}</span>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 3}} className="info--group">
                                <label className="info--label">{`${t("bag_size:label.width")} (cm)`}</label>
                                <span className="info--value _info-width">{bagSizeDetail.width * 100}</span>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 3}} className="info--group">
                                <label className="info--label">{`${t("bag_size:label.height")} (cm)`}</label>
                                <span className="info--value _info-height">{bagSizeDetail.height * 100}</span>
                            </Col>
                            <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}} className="info--group">
                                <label className="info--label">{`${t("bag_size:label.weight_min")} (kg)`}</label>
                                <span className="info--value _info-weight-min">{bagSizeDetail.weight_min}</span>
                            </Col>
                            <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}} className="info--group">
                                <label className="info--label">{`${t("bag_size:label.weight_max")} (kg)`}</label>
                                <span className="info--value _info-weight-max">{bagSizeDetail.weight_max}</span>
                            </Col>
                            <Col lg={{span: 12}} xl={{span: 24}} xxl={{span: 24}} className="info--group">
                                <label className="info--label">{t("bag_size:label.note")}</label>
                                <span className="info--value _info-note">{bagSizeDetail.note}</span>
                            </Col>
                        </Row>

                        <Row gutter={48} className={"ml-0 mr-0"}>
                            <Col lg={{span: 24}} xl={{span: 24}} className="a-package--action only-action pl-0 pr-0 gflex gjustify-end">
                                <Button type="primary"
                                        className="a-btn a-btn--primary a-btn--save-package _btn-edit-bag-size">
                                    <Link to={`/config/bag-sizes/edit/${id}`}>{t("common:btn.edit")}</Link>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        )
    }
}

export default withTranslation()(DetailBagSize);
