import React from 'react';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';

import { NOTE_TYPES } from './../../constants';
import pageService from './../../../Common/Page/pageService';

import FormSearch from './../../components/FormSearch';
import List from './../../components/List';

const { Content } = Layout;

class ListTracking extends React.PureComponent {
    componentDidMount() {
        pageService.setTitle(this.props.t('note:label.list_note_tracking'));
    }

    render() {
        return (
            <Content className="a-content a-content--page-list">
                <FormSearch {...this.props} object={NOTE_TYPES.TRACKING} />
                <List {...this.props}/>
            </Content>
        );
    }
}

export default withTranslation()(ListTracking);
