import React from 'react';
import { Button, Icon, Modal } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { INVENTORY_STATUS_CANCELLED, INVENTORY_STATUS_CHECKING, INVENTORY_STATUS_PROCESSING } from './../../constants';

class CheckingInventory extends React.PureComponent {
	onCancelInventory = () => {
		const { inventoryChecking, inventoryProcessing, page, updateStatusInventory } = this.props;
		const data = {
			id: page === INVENTORY_STATUS_CHECKING ? inventoryChecking.id : inventoryProcessing.id,
			status: INVENTORY_STATUS_CANCELLED
		};

		updateStatusInventory(data);
	};

	onCheckCancelInventory = code => {
		const { t } = this.props;

		Modal.confirm({
			cancelText: t('btn.cancel'),
			content: (
				<p>{t(`inventory:message.${code}`)}</p>
			),
			icon: <Icon type="question-circle" theme="filled" />,
			okText: t('btn.ok'),
			title: t('label.warning'),
			onOk: this.onCancelInventory
		});
	};

	render() {
		const { inventoryChecking, inventoryProcessing, loading, page, t } = this.props;

		return page === INVENTORY_STATUS_CHECKING ? (
			<Button
				className="a-btn a-btn--button-link a-text--regular a-text--blue ml-3 _btn-cancel-checking"
				disabled={get(inventoryChecking, 'status') !== INVENTORY_STATUS_CHECKING}
				loading={loading}
				type="button"
				onClick={this.onCheckCancelInventory.bind(this, 'cancel_checking_inventory')}
			>
				{t('inventory:btn.cancel_checking_inventory')}
			</Button>
		) : (
			<Button
				className="a-btn a-btn--button-link a-text--regular a-text--blue  mt-5 amb-16 _btn-cancel-processing"
				disabled={get(inventoryProcessing, 'status') !== INVENTORY_STATUS_PROCESSING}
				type="button"
				onClick={this.onCheckCancelInventory.bind(this, 'cancel_processing_inventory')}
			>
				{t('inventory:btn.cancel_processing_inventory')}
			</Button>
		);
	}
}

export default withTranslation()(CheckingInventory);
