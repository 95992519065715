import lodash from 'lodash';
import * as ACTION from './constants';
import apiService from '../apiService';
import {processApiRequest} from '../../Common/saga';
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {convertBlobDataToExcel, isExportToMail} from './../../../system/support/helpers';

export function* fetchPackages(action) {
    yield processApiRequest(ACTION.FETCH_PACKAGES, () => apiService.getPackages(action.payload), action.payload);
}

export function* exportPackageList(action) {
    yield processApiRequest(ACTION.EXPORT_PACKAGE_LIST, () => apiService.exportPackageList(action.payload), action.payload);
}

export function* exportPackageListSuccess(action) {
    const { payload, request } = action;
    const reader = new FileReader();
    let message = t('message.export_excel_success');

    reader.addEventListener('loadend', event => {
        const text = event.target.result;
        const fileName = lodash.get(request, 'is_shipment', false) ? 'shipment-package-list' : 'package-list'

        try {
            const data = JSON.parse(text);

            if (isExportToMail(data)) {
                message = t('message.export_excel.success_to_mail');
            } else {
                convertBlobDataToExcel(payload, fileName);
            }
        } catch (e) {
            convertBlobDataToExcel(payload, fileName);
        }

        notification.success({
            message
        });
    });

    yield reader.readAsText(payload);
}

export function* exportPackageListFailed(action) {
    if(action.status_code !== 403) {
        const errors = lodash.get(action.payload, 'data');
        let message = t('message.export_excel_failed');
        if (lodash.has(errors, 'created_at.required')) {
            message = t('package:message.export_package_list_errors.created_at.required');
        } else if (lodash.has(errors, 'created_at.max_1_month')) {
            message = t('package:message.export_package_list_errors.created_at.max_1_month');
        }
        yield  notification['error']({
            message: message,
        });
    }
}
