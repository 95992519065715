import React from 'react';
import { Table, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

import { trans } from './../../../../../../../system/i18n';
import { formatWeight } from './../../../../../../../system/support/helpers';

import Link from './../../../../../../../system/routing/Link';

const { Text } = Typography;

const Packages = ({ packages }) => {
    return (
        <div className="a-tile packages">
            <div className="a-tile__header gflex galign-center gjustify-space-between">
                <Text strong>
                    {`${trans('package_list_in_order')} (${packages.length})`}
                </Text>
            </div>

            <div className="a-tile__body">
                <Table
                    columns={[{
                        dataIndex: 'code',
                        key: 'code',
                        title: trans('delivery_note:label.code_package'),
                        render: text => (
                            <Link
                                className="a-text--blue"
                                isNewTab
                                params={{
                                    id: text
                                }}
                                to="packages.detail"
                            >
                                {text}
                            </Link>
                        )
                    }, {
                        dataIndex: 'code_order',
                        key: 'code_order',
                        title: trans('order_code'),
                        render: (text, row) => text ? (
                            <Link
                                className="a-text--blue"
                                href={row.url_order}
                                isNewTab
                            >
                                {text}
                            </Link>
                        ) : '--'
                    }, {
                        dataIndex: 'weight_net',
                        key: 'weight_net',
                        title: trans('weight'),
                        render: text => formatWeight(text)
                    }, {
                        dataIndex: 'customer_address',
                        key: 'customer_address',
                        title: trans('delivery_note:label.address'),
                        render: text => text || '--'
                    }]}
                    dataSource={packages}
                    pagination={false}
                    rowKey={row => row.id}
                />
            </div>
        </div>
    );
};

Packages.defaultProps = {
    packages: []
};

export default withTranslation()(Packages);
