import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import lodash, { get } from 'lodash';
import { t } from '../../../../system/i18n';
import ElementManager from '../../../Common/services/ElementManager';
import { inputFocusParser } from '../../CreatePackageViaScan/services';
import { ELEMENT } from '../../CreatePackageViaScan/services/inputFocus/constants';
import apiService from './../../apiService';

class PrintPackageStamp extends React.PureComponent {
    constructor() {
        super();
        this.elements = new ElementManager();

        this.state = {
            loading: false,
            modal: undefined,
        };
    }

    componentDidMount() {
        this.handleFocusInput();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputFocusId !== this.props.inputFocusId) {
            this.handleFocusInput();
        }
    }

    handleFocusInput() {
        let element = inputFocusParser.parse(this.props.inputFocus, this.props.packageData.id);

        if (element === ELEMENT.PACKAGE_BTN_PRINT_BARCODE) {
            setTimeout(() => this.elements.focus(element));
        }
    }

    setElement(element) {
        if (element && element.buttonNode) {
            this.elements.set(ELEMENT.PACKAGE_BTN_PRINT_BARCODE, element.buttonNode);
            this.props.onRef(element);
        }
    }

    doPrintStamp = async () => {
        const { modal } = this.state;
        const { packageData, numberStamp, printPackageStamp, operatePackage, permissions } = this.props;

        if (modal) {
            modal.destroy();
        }

        this.setState({
            loading: true,
        });

        try {
            const response = await apiService.getPackagePrint(get(packageData, 'id'));
            let data = get(response, 'data', {});
            // let data = get(response, 'data.origin_package', {});
            
            let packageInfo = get(response, 'data.origin_package.package', {});
            let warehouseCode = get(response.data, 'origin_package.destination_warehouse.code', '');
            if (operatePackage) {
                data = get(response, 'data.operate_package', {});
                packageInfo = get(response, 'data.operate_package.package', {});
                warehouseCode = get(response.data, 'operate_package.destination_warehouse.code', '');
            }
            
            const newData =  {
                packageData: {
                    ...data,
                    ...get(data, 'origin_package', {}),
                    permissions
                },
                
                warehouseCode,
                numberStamp: numberStamp,
            }

             // {
            //     packageData: {
            //         ...data,
            //         // ...packageInfo,
            //         // operatePackage: operatePackage || false,
            //     },
            //     ...get(data, 'origin_package', {}),
            //     warehouseCode,
            //     numberStamp: numberStamp,
            // }
            printPackageStamp(newData);
        } catch (error) {}

        this.setState({
            loading: false,
        });
    };

    onPrintStamp(event) {
        event.preventDefault();

        const { confirmBeforePrint, confirmTitle } = this.props;

        if (confirmBeforePrint) {
            this.setState({
                modal: Modal.confirm({
                    cancelText: t('btn.cancel'),
                    okText: t('btn.ok'),
                    title: confirmTitle,
                    onOk: this.doPrintStamp,
                }),
            });
        } else {
            this.doPrintStamp();
        }
    }

    render() {
        const { loading } = this.state;
        const { type, packageData, numberStamp, disabledPrintStamp, className, operatePackage } = this.props;

        const classButtonDisabled = 'a-btn--button-link a-btn--button-link--disabled';

        return get(packageData, 'permissions.can_print_barcode') ? (
            operatePackage ? (
                <Button
                    type={type}
                    disabled={disabledPrintStamp}
                    htmlType="button"
                    loading={loading}
                    onClick={this.onPrintStamp.bind(this)}
                    className={clsx('a-btn _print_stamp', className, {
                        'a-btn--primary': type === 'primary',
                    })}
                    ref={this.setElement.bind(this)}
                >
                    {t('print_operation_package')}
                </Button>
            ) : numberStamp === 4 ? (
                <Button
                    className={disabledPrintStamp ? classButtonDisabled : 'a-btn--button-link a-text--blue _print_four_stamp'}
                    onClick={this.onPrintStamp.bind(this)}
                    disabled={disabledPrintStamp}
                >
                    {t('package:label.print_four_stamp')}
                </Button>
            ) : (
                <Button
                    type={type}
                    disabled={disabledPrintStamp}
                    htmlType="button"
                    loading={loading}
                    onClick={this.onPrintStamp.bind(this)}
                    className={clsx('a-btn _print_stamp', className, {
                        'a-btn--primary': type === 'primary',
                    })}
                    ref={this.setElement.bind(this)}
                >
                    {t('package:label.print_stamp')}
                </Button>
            )
        ) : null;
    }
}

PrintPackageStamp.defaultProps = {
    packageData: {},
    numberStamp: 1,
    printPackageStamp: () => {},
    onRef: () => {},
    operatePackage: false,
};

PrintPackageStamp.propTypes = {
    packageData: PropTypes.object,
    printPackageStamp: PropTypes.func,
    numberStamp: PropTypes.number,
    operatePackage: PropTypes.bool,
};

export default PrintPackageStamp;
