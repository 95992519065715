import React, { Fragment, useCallback, useState } from 'react'
import { Button, Col, Icon, Modal, notification, Row, Select } from 'antd'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import apiService from './../../apiService'
import { useComplaintSellersDisplay } from './../../hooks'
import Package from './../../models/Package'
import { currentFormatter } from './../../../Common/services/format'
import { useDeepCompareEffect } from './../../../../hooks'
import CustomIcon from './../../../../components/icons/Icon'
import { getVar } from './../../../../system/support/helpers'
import withPackageDamagedReasons from './../../../../hocs/withPackageDamagedReasons'

import AlertSplitPackage from './AlertSplitPackage'
import ScanUploadDamageImagesLinkModal from './ScanUploadDamagedImagesLinkModal'
import BoxSizesSuggestByVolume from './../../components/BoxSizesSuggestByVolume'
import CreateComplaintSellerButton from './../../components/CreateComplaintSellerButton'
import PrintOfficialStamp from './../../PrintOfficialStamp'
import PictureWall from './../../UploadDamagedImages/components/PictureWall'
import CreateComplaintSellerModal from './../../../Common/components/Modals/CreateComplaintSeller'
import { Link } from './../../../../system/routing'
import PrintTAPStampButton from '../../components/PrintTAPStampButton'
import PrintBatchStampButton from '../../components/PrintBatchStampButton'
import { PACKAGE_ROUTINGS } from '../../PackageDetail/constants'
import config from './../../../../config/app'
import { auth } from '../../../../system/api'
import { TRANSPORT_STATUS } from '../../constants'
import Print9710StampButton from '../../components/Print9710StampButton'

const { Option } = Select

const PackageInfo = props => {
    const { linkHtc, orderCode } = props
    const [isCollapse, setIsCollapse] = useState(true)
    const [isDamage, setIsDamage] = useState(Boolean(lodash.get(props.package, 'package_detail.is_damage')))
    const [modalVisibles, setModalVisibles] = useState({
        scanUploadDamagedImagesLink: false,
        createComplaintSeller: false,
    })
    const [damagedReasons, setDamagedReasons] = useState(lodash.get(props.package, 'package_detail.damaged_reasons') || [])

    const { boxSizesSuggestByVolume, loadingPackageDamagedReasons, packageDamagedReasons, t, totalAmount, warnings, onSuccessUpdateDamagedProperty } = props
    const packageData = lodash.get(props, 'package')
    const canDetachPackage = !!lodash.get(packageData, 'permissions.can_detach_package')
    const complaintSellersDisplay = useComplaintSellersDisplay({
        className: 'amt-16 mb-0',
        complaintSellers: lodash.get(packageData, 'package_detail.id_m5_complaint_sellers'),
        packageId: lodash.get(packageData, 'id'),
    })
    const packageRoutings = lodash.get(packageData, 'package_routings', {})
    const packageRoutingsContent = lodash.map(
        lodash.filter(packageRoutings, item => item.data.action === 'yes'),
        'code'
    )
    const hasDirectToConsumer = packageRoutingsContent.includes(PACKAGE_ROUTINGS.DIRECT_TO_CONSUMER)
    const trackingNoLastMile = lodash.get(packageData, 'last_mile_shipment.tracking_no')
    const canUpdateTransportStatus = !!getVar(packageData, 'permissions.can_update_transport_status', false)
    const canUpdatePackage = getVar(packageData, 'permissions.can_update')

    const onToggle = () => {
        setIsCollapse(!isCollapse)
    }

    const handleOpenModal = modal => {
        const newModalVisibles = {
            ...modalVisibles,
            [modal]: true,
        }

        setModalVisibles(newModalVisibles)
    }

    const handleCancelModal = modal => {
        const newModalVisibles = {
            ...modalVisibles,
            [modal]: false,
        }

        setModalVisibles(newModalVisibles)
    }

    const updateDamagedProperty = async reasons => {
        const packageId = lodash.get(packageData, 'id')

        try {
            const { data } = await apiService.updateDamagedProperty(packageId, reasons)

            onSuccessUpdateDamagedProperty(data)
        } catch (error) {}
    }

    const doUpdateDamagedProperty = useCallback(lodash.debounce(updateDamagedProperty, 350), [])

    const handleChangeDamagedReasons = reasons => {
        const pkg = new Package(packageData)

        if (reasons.length) {
            confirmChangeDamagedReasons(reasons)
        } else {
            if (pkg.canClearDamagedReasons) {
                Modal.confirm({
                    cancelText: t('btn.cancel'),
                    okText: t('btn.ok'),
                    title: t('package:clear_damaged_reasons.confirm'),
                    onOk: confirmChangeDamagedReasons.bind(undefined, reasons),
                })
            } else {
                notification.error({
                    message: t('package:clear_damaged_reasons.invalid_status'),
                })
            }
        }
    }

    const confirmChangeDamagedReasons = reasons => {
        setDamagedReasons(reasons)

        doUpdateDamagedProperty(reasons)
    }

    useDeepCompareEffect(() => {
        const damagedReasons = lodash.get(packageData, 'package_detail.damaged_reasons')

        setDamagedReasons(damagedReasons || [])
    }, [packageData.package_detail.damaged_reasons])

    useDeepCompareEffect(() => {
        const isDamage = lodash.get(packageData, 'package_detail.is_damage')

        setIsDamage(Boolean(isDamage))
    }, [packageData.package_detail.is_damage])

    const handleChangeStatusTransport = value => {
        if (value) {
            apiService
                .updateRelatedPackage(packageData.id, {
                    status_transport: value,
                    code: packageData.code,
                    type: 'RELATED',
                })
                .then(() => {
                    notification.success({ message: t('message.update_success', { attribute: t('package:label.status_transport') }) })
                })
                .catch(() => {
                    notification.error({ message: t('message.update_failed', { attribute: t('package:label.status_transport') }) })
                })
        }
    }

    return (
        <div className="amb-14 pt-0 block--primary info-package-checkpackage">
            <CreateComplaintSellerModal
                agencyId={lodash.get(packageData, 'agency.id')}
                packages={[packageData]}
                visible={modalVisibles.createComplaintSeller}
                onCancel={handleCancelModal.bind(this, 'createComplaintSeller')}
                onOk={handleCancelModal.bind(this, 'createComplaintSeller')}
            />
            <ScanUploadDamageImagesLinkModal
                packageCode={packageData.code}
                visible={modalVisibles.scanUploadDamagedImagesLink}
                onCancel={handleCancelModal.bind(this, 'scanUploadDamagedImagesLink')}
            />
            <div className="title create-bag">
                <div className="left">
                    <span className="a-text--bold a-text--fz-16">
                        {t('package:column.code')} -{' '}
                        {
                            <Link
                                className="a-text--white checking-package__package-data-code"
                                isNewTab
                                params={{ id: packageData.code }}
                                to="packages.detail">
                                {packageData.code}
                            </Link>
                        }{' '}
                        - {t(`package:checking_statuses.${lodash.get(packageData, 'package_detail.status_checking')}`)}
                    </span>
                </div>
                <div className="right gflex">
                    {/*<PrintVTStampButton*/}
                    {/*    className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2"*/}
                    {/*    ghost*/}
                    {/*    packageData={packageData}*/}
                    {/*/>*/}
                    <PrintTAPStampButton
                        className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 checking-package__print-aps_stamp-button"
                        ghost
                        packageData={packageData}
                    />
                    {/*<PrintUnOfficialStampButton*/}
                    {/*    className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4"*/}
                    {/*    ghost*/}
                    {/*    packageData={packageData}*/}
                    {/*/>*/}
                    {props.allowPrintOfficalStamp && (
                        <PrintOfficialStamp
                            className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 checking-package__print-official_stamp-button"
                            ghost
                            packageData={packageData}
                        />
                    )}

                    <Print9710StampButton
                        className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 checking-package__print-9710_stamp-button"
                        ghost
                        packageData={packageData}
                    />

                    {!!lodash.get(packageData, 'permissions.can_print_batch_stamp', false) && (
                        <PrintBatchStampButton
                            className="a-btn a-btn--h-25 aradius--2 ml-4 a-btn--primary ant-btn-primary _print-batch-stamp"
                            packageData={packageData}
                        />
                    )}

                    {hasDirectToConsumer && trackingNoLastMile && (
                        <Button
                            target="_blank"
                            ghost={true}
                            onClick={e => {
                                e.preventDefault()
                                window.open(
                                    `${config.apiUrl}/packages/${lodash.get(packageData, 'id')}/last-mile-shipment-stamp?token=${auth.getToken()}`,
                                    '_blank'
                                )
                            }}
                            className="ant-btn a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 _btn_print_last_mile_shipment_stamp">
                            {t('package:btn.print_last_mile_shipment_stamp')}
                        </Button>
                    )}
                    <CreateComplaintSellerButton
                        className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 checking-package__print-complaint_stamp-button"
                        ghost
                        isDamage={isDamage}
                        onClick={handleOpenModal.bind(this, 'createComplaintSeller')}
                    />

                    {!!lodash.get(packageData, 'permissions.can_create_delivery_note_return', false) && (
                        <Button
                            className="a-btn a-btn--transparent-white a-btn--h-25 aradius--2 ml-4 checking-package__can_create_delivery_note_return"
                            ghost>
                            <Link
                                queries={{
                                    package: lodash.get(packageData, 'code', '--'),
                                }}
                                to="delivery-notes.return.create">
                                {t('create_delivery_note_return')}
                            </Link>
                        </Button>
                    )}
                </div>
            </div>

            <div className="body not-boxshadow">
                <Row className="a-block-info">
                    <div className="gflex galign-center">
                        <label className="mr-2 a-text--nowrap">{`${t('package:select_reasons_if_package_is_damaged')}:`}</label>
                        <Select
                            allowClear
                            className="_damaged-reasons width-100-pc"
                            dropdownClassName="_dropdown-damaged-reasons"
                            loading={loadingPackageDamagedReasons}
                            mode="multiple"
                            optionFilterProp="children"
                            value={loadingPackageDamagedReasons ? [] : damagedReasons}
                            onChange={handleChangeDamagedReasons}>
                            {packageDamagedReasons.map(damagedReason => (
                                <Option
                                    className="_damage-reason-item"
                                    key={damagedReason.id}
                                    value={damagedReason.id}>
                                    {damagedReason.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {complaintSellersDisplay}
                    {!!(damagedReasons && damagedReasons.length) && (
                        <div className="amt-16">
                            <Button
                                className="a-btn a-btn--link a-btn--h-25 aradius--2 p-0 amb-16 _btn-scan-upload-damaged-images-link"
                                type="link"
                                onClick={handleOpenModal.bind(this, 'scanUploadDamagedImagesLink')}>
                                {t('package:get_qr_code_to_upload_images_from_cellphone')}
                            </Button>
                            <PictureWall
                                damagedImages={lodash.get(packageData, 'package_detail.damaged_images', [])}
                                packageId={packageData.id}
                            />
                        </div>
                    )}
                </Row>

                <Row
                    gutter={48}
                    className="a-block-info pb-0"
                    type="flex">
                    {canDetachPackage && (
                        <Col
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}>
                            <AlertSplitPackage
                                packageData={packageData}
                                warnings={warnings}
                            />
                        </Col>
                    )}
                    <Col lg={{ span: 24 }}>
                        <div className="info--title mt-0 border--bottom a-text--medium">
                            <h4>{t('info')}</h4>
                        </div>
                    </Col>
                    <Col
                        className="info--group amb-14"
                        lg={{ span: 8 }}>
                        <label className="info--label">{t('package:label.order')}</label>
                        <a
                            className="info--value--large  a-text--blue d-inline _checking-package__info-code"
                            href={linkHtc ? linkHtc + '/order/detail/default/' + orderCode : getVar(packageData, 'order.m1_url', '/') || '/'}
                            rel="noopener noreferrer"
                            target="_blank">
                            {getVar(packageData, 'order.code', '--')}
                        </a>
                    </Col>

                    <Col
                        className="info--group amb-14"
                        lg={{ span: 8 }}>
                        <label className="info--label">{t('package:label.order_total_value')}(VND)</label>
                        <span className="info--value--small _checking-package__info-total-value">{currentFormatter.toLocaleStringCurrency(totalAmount)}</span>
                    </Col>

                    <Col
                        className="info--group amb-14"
                        lg={{ span: 8 }}>
                        <label className="info--label">{t('package:label.order_note')}</label>
                        <span className="info--value--large _checking-package__customer-node">{getVar(packageData, 'order.customer_note', '--')}</span>
                    </Col>

                    <Col
                        className="info--group amb-14"
                        lg={{ span: 8 }}>
                        <label className="info--label">{t('freight_bill')}</label>
                        <span className="info--value--small _checking-package__freight-bill">{getVar(packageData, 'barcode', '--')}</span>
                    </Col>
                    <Col
                        className="info--group amb-14"
                        lg={{ span: 8 }}>
                        <label className="info--label _label">{t('package:label.handling_indicate')}</label>
                        <span className="info--value _value">{packageRoutingsContent.map(key => t(`package:package_routing.${key}`)).join(', ')}</span>
                    </Col>

                    {canUpdatePackage && (
                        <Col
                            className="info--group amb-14"
                            lg={{ span: 8 }}>
                            <label className="info--label">{t('package:label.status_transport')}</label>
                            <Select
                                className="a-select a-select--w-100 _status-transport info--value--large"
                                dropdownClassName="_dropdown-status-transport"
                                name="status_transport"
                                optionFilterProp="children"
                                placeholder={t('package:placeholder.status_transport')}
                                showSearch
                                defaultValue={packageData.status_transport}
                                onChange={handleChangeStatusTransport}>
                                {canUpdateTransportStatus && (
                                    <Option
                                        className="_status-transport-item a-text--green"
                                        value="">
                                        {t(`package:status_transport.${TRANSPORT_STATUS.CIRCULATE}`)}
                                    </Option>
                                )}
                                <Option
                                    className="_status-transport-item"
                                    value={packageData.status_transport}>
                                    {t(`package:status_transport.${packageData.status_transport}`)}
                                </Option>
                                {packageData.enable_transport_status.map(state => (
                                    <Option
                                        className={clsx('_status-transport-item', {
                                            'a-text--red': !state.apply_shipping_fee,
                                        })}
                                        key={state.status}
                                        value={state.status}>
                                        {t(`package:status_transport.${state.status}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    <Col
                        className={`info--group amb-14 ${isCollapse ? 'a-hidden' : ''}`}
                        span={24}>
                        {!lodash.get(packageData, 'id_box_size') && lodash.get(boxSizesSuggestByVolume, 'length') && lodash.get(packageData, 'volume') ? (
                            <BoxSizesSuggestByVolume
                                boxSizes={boxSizesSuggestByVolume}
                                hintParams={{
                                    length: lodash.parseInt(lodash.get(packageData, 'length', 0) * 100),
                                    width: lodash.parseInt(lodash.get(packageData, 'width', 0) * 100),
                                    height: lodash.parseInt(lodash.get(packageData, 'height', 0) * 100),
                                }}
                                className="_checking-package__suggest-volume"
                            />
                        ) : null}
                    </Col>
                </Row>
                <Row
                    gutter={48}
                    className={`a-block-info pb-0 ${isCollapse ? 'a-hidden' : ''}`}>
                    <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}>
                        <div className="info--title border--bottom a-text--medium ">
                            <h4 className="_checking-package__parameter">{t('package:label.parameter')}</h4>
                        </div>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group amb-14">
                        <label className="info--label">{t('package:label.weight')}</label>
                        <span className="info--value _checking-package__weight">
                            {lodash.round(lodash.get(packageData, 'weight_net', 0), 4) || '--'}
                            {lodash.get(packageData, 'weight_net') ? 'kg' : ''}
                        </span>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group amb-14">
                        <label className="info--label">{t('package:label.volume')}</label>
                        <span className="info--value _checking-package__volume">
                            {lodash.round(lodash.get(packageData, 'volume', 0) * 1000000, 9) || '--'}
                            {lodash.get(packageData, 'volume') ? 'cm3' : ''}
                        </span>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group amb-14 _checking-package__weight_converted">
                        <label className="info--label">{t('package:label.weight_converted')}</label>
                        {lodash.round(lodash.get(packageData, 'weight_converted', 0), 4) || '--'}
                        {lodash.get(packageData, 'weight_converted') ? 'kg' : ''}
                    </Col>
                </Row>
                <Row
                    gutter={48}
                    className={'a-block-info pb-0'}>
                    <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}>
                        <div className="info--title border--bottom a-text--medium">
                            <h4>{t('package:label.service_use')}</h4>
                        </div>
                    </Col>
                    <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        xxl={{ span: 24 }}
                        className="info--group amb-14">
                        <span className="info--value--small _checking-package__service">
                            {packageData.services && packageData.services.length
                                ? lodash.map(packageData.services, (service, index) => (
                                      <Fragment key={service.id}>
                                          <CustomIcon
                                              className="mr-2"
                                              type={service.icon}
                                          />
                                          <span
                                              className={clsx(`_checking-package__service-${service.type}`, {
                                                  'a-text--green': service.type === 'consign',
                                              })}>
                                              {service.name}
                                          </span>
                                          {index === packageData.services.length - 1 ? null : ' / '}
                                      </Fragment>
                                  ))
                                : '--'}
                        </span>
                    </Col>
                </Row>
            </div>
            <div
                className="collapse--checkpackage"
                onClick={onToggle}>
                {isCollapse ? (
                    <a
                        href="/"
                        onClick={event => event.preventDefault()}>
                        {t('view_full_info')}{' '}
                        <Icon
                            type="arrows-alt"
                            className="ml-1 btn-view__full"
                        />
                    </a>
                ) : (
                    <a
                        href="/"
                        onClick={event => event.preventDefault()}>
                        {t('collapse_info')}{' '}
                        <Icon
                            type="shrink"
                            className="ml-1 btn-collapse__full"
                        />
                    </a>
                )}
            </div>
        </div>
    )
}

PackageInfo.defaultProps = {
    package: {
        package_detail: {
            isDamage: false,
            damaged_reasons: [],
        },
    },
    allowPrintOfficalStamp: false,
}

PackageInfo.propTypes = {
    package: PropTypes.object,
}

export default withTranslation()(
    withPackageDamagedReasons(PackageInfo, {
        getDataWhenMounted: true,
    })
)
