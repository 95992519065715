export const GET_LIST_WAREHOUSE = {
    REQUEST: 'WAREHOUSE.GET_LIST.REQUEST',
    SUCCESS: 'WAREHOUSE.GET_LIST.SUCCESS',
    FAILED: 'WAREHOUSE.GET_LIST.FAILED',
}
export const FETCH_WAREHOUSE_SUGGEST = {
    REQUEST: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST.REQUEST',
    SUCCESS: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST.SUCCESS',
    FAILED: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST.FAILED',
}
export const FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION = {
    REQUEST: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION.REQUEST',
    SUCCESS: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION.SUCCESS',
    FAILED: 'WAREHOUSE.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION.FAILED',
}

export const MAX_WAREHOUSE = 5

export const WARNING_TO_STOWING_KEY = {
    OVER_MAX_WEIGHT: 'OVER_MAX_WEIGHT',
    OVER_MAX_CAPACITY: 'OVER_MAX_CAPACITY',
}

export const LOCAL_STORAGE_TO_STOWING_KEY = "scan_to_stowing_data"
