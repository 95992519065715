import {connect} from 'react-redux';
import CompletePackingBag from './components/';
import {completePackingBag} from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    bag: props.bag || {},
	loading: props.loading || lodash.get(state, 'bag.completePackingBag.loading', false),
});

const mapDispatchToProps = dispatch => ({
    completePackingBag: (param) => {
        dispatch(completePackingBag(param))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompletePackingBag);
