import { Modal, Form, Input, notification, Spin, Select, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import apiService from '../../apiService'
import { get, map } from 'lodash'
import { t } from '../../../../../system/i18n'
import { getVar } from '../../../../../system/support/helpers'
import { PROVIDER_LAST_MILE_CARRIER } from '../../constants'
import { connect } from 'react-redux'
import { fetchSuggestWarehouses } from '../../../../Warehouse/actions'
import { fetchSuggestAgencies } from '../../../../Agencies/actions'
const mapStateToProps = (state, props) => {
    return {
        ...props,
        warehouses: getVar(state, 'warehouse.suggest', []),
        loadingWarehouse: getVar(state, 'warehouse.loading', false),
        agencies: getVar(state, 'agencies.suggest', []),
        loadingAgency: getVar(state, 'agencies.loading', false),
    }
}

const mapDispatchToProps = dispatch => ({
    fetchWarehouses: params => dispatch(fetchSuggestWarehouses(params)),
    fetchAgencies: params => dispatch(fetchSuggestAgencies(params)),
})

function UpdateLastmileCarrier(props) {
    const { shippingPartnerId, visible, handleCancel, form, warehouses, agencies, loadingWarehouse, loadingAgency, fetchWarehouses, fetchAgencies } = props
    const { getFieldDecorator } = form
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        if (shippingPartnerId) {
            fetchDetail()
        }
    }, [shippingPartnerId])

    useEffect(() => {
        fetchWarehouses()
        fetchAgencies()
    }, [])

    const fetchDetail = () => {
        setLoadingDetail(true)
        apiService
            .detailLastmileCarrier(shippingPartnerId)
            .then(res => {
                const data = getVar(res, 'data', {})
                form.setFieldsValue({
                    provider: get(data, 'provider'),
                    code: get(data, 'code'),
                    name: get(data, 'name'),
                    id_warehouses: map(get(data, 'warehouses.data'), 'id'),
                    id_agencies: map(get(data, 'agencies.data'), 'id'),
                })
            })
            .catch(error => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => setLoadingDetail(false))
    }

    const handleSubmit = () => {
        form.validateFields((errors, values) => {
            if (errors) {
                return
            }
            setUpdating(true)
            const apiProcess = shippingPartnerId
                ? apiService.updateLastmileCarrier(shippingPartnerId, { ...values })
                : apiService.addLastmileCarrier({ ...values })

            apiProcess
                .then(res => {
                    notification.success({ message: t('message.update_success') })
                    handleCancel(true)
                })
                .catch(err => {
                    const { response } = err
                    const code = getVar(response, 'data.code')
                    const data = getVar(response, 'data.data', {})
                    if (code === 'INPUT_INVALID') {
                        Object.keys(data).map(attribute => {
                            const contentErrors = []
                            const errorsOfAttribute = getVar(data, attribute, {})
                            Object.keys(errorsOfAttribute).map(error => {
                                const label = t(`shipping_partner:label.${attribute}`)
                                contentErrors.push(t(`shipping_partner:message.${error}`, { attribute: label }))
                            })
                            form.setFields({
                                [attribute]: {
                                    value: values[attribute],
                                    errors: [new Error(contentErrors.join(','))],
                                },
                            })
                        })
                    } else {
                        notification.error({ message: t('message.server_error') })
                    }
                })
                .finally(() => setUpdating(false))
        })
    }

    const handleSelectAll = (name, value) => {
        form.setFieldsValue({ [name]: value })
    }

    return (
        <Modal
            title={`${shippingPartnerId ? t('label.update') : t('label.add')} ${t('partner')}`.toUpperCase()}
            visible={visible}
            onCancel={() => handleCancel(false)}
            onOk={handleSubmit}
            okButtonProps={{ loading: updating }}
            cancelText={t('btn.cancel')}
            okText={t('btn.confirm')}>
            <div>
                <Spin spinning={loadingDetail}>
                    <Form>
                        <Form.Item label={t(`shipping_partner:label.provider`)}>
                            {getFieldDecorator('provider', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: 'Provider',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    placeholder={t(`shipping_partner:placeholder.provider`)}
                                    disabled={!!shippingPartnerId}>
                                    {Object.values(PROVIDER_LAST_MILE_CARRIER).map(provider => {
                                        return (
                                            <Select.Option
                                                key={provider}
                                                value={provider}>
                                                {t(`shipping_partner:domestic_shipping_partner_types.${provider}`)}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={t(`shipping_partner:label.code`)}>
                            {getFieldDecorator('code', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t(`shipping_partner:label.code`),
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    disabled={!!shippingPartnerId}
                                    placeholder={t(`shipping_partner:placeholder.code`)}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={t(`shipping_partner:label.name`)}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t(`shipping_partner:label.name`),
                                        }),
                                    },
                                ],
                            })(<Input placeholder={t(`shipping_partner:placeholder.name`)} />)}
                        </Form.Item>
                        <Form.Item label={t(`shipping_partner:label.id_warehouses`)}>
                            {warehouses.length > 0 && (
                                <Button
                                    className="mr-2"
                                    size="small"
                                    onClick={() => handleSelectAll('id_warehouses', map(warehouses, 'id'))}>
                                    Chọn tất cả
                                </Button>
                            )}
                            {getFieldDecorator('id_warehouses')(
                                <Select
                                    loading={loadingWarehouse}
                                    allowClear
                                    className="_a-select-warehouse-input-package"
                                    dropdownClassName="_dropdown_id_warehouse_input"
                                    mode="multiple"
                                    placeholder={t(`shipping_partner:placeholder.id_warehouses`)}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {warehouses.map(warehouse => {
                                        return (
                                            <Select.Option
                                                className="_warehouse__item"
                                                key={warehouse.id}
                                                value={warehouse.id}>
                                                {warehouse.code}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={t(`shipping_partner:label.id_agencies`)}>
                            {agencies.length > 0 && (
                                <Button
                                    className="mr-2"
                                    size="small"
                                    onClick={() => handleSelectAll('id_agencies', map(agencies, 'id'))}>
                                    Chọn tất cả
                                </Button>
                            )}

                            {getFieldDecorator('id_agencies')(
                                <Select
                                    loading={loadingAgency}
                                    mode="multiple"
                                    allowClear
                                    className="_id-agency"
                                    dropdownClassName="_dropdown-id-agency"
                                    placeholder={t('shipping_partner:placeholder.id_warehouses')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {agencies.map(agency => {
                                        return (
                                            <Select.Option
                                                className="_warehouse__item"
                                                key={agency.id}
                                                value={agency.id}>
                                                {agency.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        </Modal>
    )
}
export default connect(mapStateToProps, mapDispatchToProps, undefined, {
    forwardRef: true,
})(Form.create()(UpdateLastmileCarrier))
