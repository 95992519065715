import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocationWarehouseForm from './components/';
import {
	fetchCountry,
	fetchDistrict,
	fetchLocation,
	fetchProvince,
	fetchWard,
	setCurrentCity,
	setCurrentCountry,
	setCurrentDistrict
} from '../actions';
import {fetchPropertySuggest} from './../../Property/actions';
import lodash from 'lodash';
import {getDefaultCountry} from "../selectors";

const mapStateToProps = (state, props) => ({
	errors: lodash.get(props, "errors", {}),
	warehouseData: props.warehouseData,
	countries: [...state.location.listCountry],
	cities: [...state.location.listProvince],
	districts: [...state.location.listDistrict],
	wards: [...state.location.listWard],
	isLoadingLocation: lodash.get(state, "location.loading", false),
	isLoadingProperty: lodash.get(state, "property.loading", false),
	properties: lodash.get(state, 'property.list', []),
	defaultCountry: getDefaultCountry(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	fetchLocation: () => {
		dispatch(fetchLocation({iChooseDefault: true}));
	},
	fetchCountry: (id) => {
		dispatch(fetchCountry({id: id, iChooseDefault: true}));
	},
	fetchProvince: (id) => {
		dispatch(fetchProvince({id: id, iChooseDefault: true}));
	},
	fetchDistrict: (id) => {
		dispatch(fetchDistrict({id: id, iChooseDefault: true}));
	},
	fetchWard: (id) => {
		dispatch(fetchWard({id: id, iChooseDefault: true}));
	},
	fetchProperty: () => {
		dispatch(fetchPropertySuggest());
	},
	setCurrentCountry: (idCountry) => {
		dispatch(setCurrentCountry(idCountry));
	},
	setCurrentCity: (idCity) => {
		dispatch(setCurrentCity(idCity));
	},
	setCurrentDistrict: (idDistrict) => {
		dispatch(setCurrentDistrict(idDistrict));
	}
});
class LocationWarehouseFormContainer extends Component {
	render() {
		return(
			<LocationWarehouseForm {...this.props}/>
		)
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LocationWarehouseFormContainer);
