/* eslint-disable */
import React, {Component} from 'react';
import {Select, Table, Pagination, Button, Icon, Checkbox,} from 'antd';

const columns = [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
    },
    {
        title: 'Mã kiện',
        dataIndex: 'bag',
        key: 'bag',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    }, {
        title: 'Trạng thái kiện',
        dataIndex: 'warehousecheck',
        key: 'warehousecheck',
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 42,
        render: text => <div className="group-action-cross-check"><Icon type="select" className="action"/> </div>
    }];

const data = [];
for (let i = 0; i < 15; i++) {
    data.push({
        stt:`${i + 1}`,
        bag: `75011909${i}`,
        warehousecheck: (
            i % 2 ? <span> CNGZ <span className="a-text--blue ml-3 mr-3 ">Xuất</span> <span>9:28 20/03/2019</span></span>
                :
                <span> CNGZ <span className="a-text--green ml-3 mr-3 ">Nhập</span> <span>9:28 20/03/2019</span></span>
        ),
    });
}
class Excess extends Component {
    render() {
        const rowSelection = {
            onChange: this.onSelectChange,
            fixed: true,
        };
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3 className="a-text--medium a-text--fz-16">DANH SÁCH KIỆN THỪA (210) <span className="a-text--regular a-text--fz-12 a-text--blue"> - Đã chọn 29</span></h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary" className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular primary-special " disabled  icon="select">Nhập kho
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list aheight-100 aoverflow-y pb-0">
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           rowSelection={rowSelection}
                           pagination={false}
                           scroll={{ x: 'fit-content'}}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default Excess;