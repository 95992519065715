import {combineReducers} from 'redux';
import * as DELETE_ACTION from './constants';

let isProcessing = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case DELETE_ACTION.BEGIN_DELETE_PACKAGE:
            return true;
        case DELETE_ACTION.DELETE_PACKAGE.SUCCESS:
        case DELETE_ACTION.DELETE_PACKAGE.FAILED:
        case DELETE_ACTION.END_DELETE_PACKAGE:
            return false;

        case DELETE_ACTION.BEGIN_REMOVE_PACKAGE:
            return true;
        case DELETE_ACTION.REMOVE_PACKAGE.SUCCESS:
        case DELETE_ACTION.REMOVE_PACKAGE.FAILED:
        case DELETE_ACTION.END_REMOVE_PACKAGE:
            return false;

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case DELETE_ACTION.DELETE_PACKAGE.REQUEST:
            return true;
        case DELETE_ACTION.DELETE_PACKAGE.SUCCESS:
        case DELETE_ACTION.DELETE_PACKAGE.FAILED:
            return false;

        case DELETE_ACTION.REMOVE_PACKAGE.REQUEST:
            return true;
        case DELETE_ACTION.REMOVE_PACKAGE.SUCCESS:
        case DELETE_ACTION.REMOVE_PACKAGE.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    isProcessing,
    loading
});
