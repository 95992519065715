import React from 'react'
import { notification, Spin, Button } from 'antd'
import { each, get } from 'lodash'
import { withTranslation } from 'react-i18next'

import orderApi from './../../../Order/apiService'

import { ChatInput } from './ChatInput'
import { ChatItem } from './ChatItem'

class PackageChat extends React.PureComponent {
    state = {
        loading: false,
        errorLoadComment: false,
        comments: [],
        pagination: {
            total: 0,
        },
    }

    async componentDidMount() {
        const { orderId } = this.props

        if (!orderId) {
            return
        }

        try {
            const response = await this.fetchComments()

            this.setStateFromCommentsResponse(response)
        } catch (e) {}
    }

    async componentDidUpdate(prevProps) {
        const { orderId } = this.props

        if (!orderId || !(orderId !== prevProps.orderId)) {
            return
        }

        try {
            const response = await this.fetchComments()

            this.setStateFromCommentsResponse(response)
        } catch (e) {}
    }

    fetchComments = async params => {
        const { orderId } = this.props

        this.setState({
            loading: true,
        })

        try {
            const response = await orderApi.getInternalCommentsById(orderId, params)
            this.setState({
                loading: false,
                errorLoadComment: false,
            })

            return response
        } catch (e) {
            this.setState({
                loading: false,
                errorLoadComment: true,
            })

            throw e
        }
    }

    setStateFromCommentsResponse = response => {
        this.setState({
            comments: get(response.data, 'comments.data'),
            pagination: get(response.data, 'comments.pagination'),
        })
    }

    onSubmitComment = data => {
        const { orderId, t } = this.props

        if (data.comment || data.attachments.length) {
            const attachments = get(data, 'attachments', []) || []
            let formData = new FormData()
            formData.append('comment', data.comment)
            each(attachments, file => {
                formData.append(`attachments[]`, file)
            })

            orderApi
                .addInternalCommentById(orderId, formData)
                .then(() => {
                    this.fetchComments().then(response => {
                        this.setStateFromCommentsResponse(response)
                    })

                    notification.success({
                        message: t('package:message.package_chat_success'),
                    })
                })
                .catch(err => {
                    if (err.message !== 'Network Error') {
                        notification.error({
                            message: t('package:message.package_chat_error'),
                        })
                    }
                })
        } else {
            notification.error({
                message: t('package:message.package_chat_required'),
            })
        }
    }

    render() {
        const { comments, loading, errorLoadComment } = this.state
        const { t, handleOpenUploadImage, isCheckingPackage } = this.props

        return (
            <React.Fragment>
                <Spin
                    spinning={loading}
                    wrapperClassName="list-chat-spinner list-chat-spinner__internal-chat _chat_component_internal_chat">
                    <div className="chat-heading">{t('package:label.internal_chat')}</div>
                    {isCheckingPackage && (
                        <Button
                            className="a-btn a-btn--link a-btn--h-25 aradius--2 m-0 p-0 pl-4 _btn-scan-upload-damaged-images-link"
                            type="link"
                            style={{ whiteSpace: 'pre-wrap' }}
                            onClick={() => handleOpenUploadImage(1)}>
                            {t('package:get_qr_code_to_upload_comment_images_from_cellphone.internal')}
                        </Button>
                    )}

                    <ChatInput onSubmit={this.onSubmitComment} />
                    <div className="list-chat-checking-package">
                        {comments.map(comment => (
                            <ChatItem
                                key={comment.id}
                                item={comment}
                            />
                        ))}
                        {errorLoadComment && <p style={{ color: 'red' }}>Tải nội dung chat không thành công</p>}
                    </div>
                </Spin>
            </React.Fragment>
        )
    }
}

export default withTranslation()(PackageChat)
