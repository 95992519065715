import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import authService from './../../Auth/authService';
import permissions from './../../Auth/permissions';

const CreateComplaintSellerButton = ({ className, isDamage, t, ...btnProps }) => {
    if (!isDamage || !authService.can(permissions.PACKAGE_CREATE_COMPLAINT_SELLER)) {
        return null;
    }

    return (
        <Button
            className={clsx('_btn-create-complaint-seller', className)}
            {...btnProps}
        >
            {t('create_complaint_seller.title')}
        </Button>
    );
}

export default withTranslation()(CreateComplaintSellerButton);
