import React from 'react';
import { Button } from 'antd';
import { filter, get, includes, map } from 'lodash';

import { INVENTORY_STATUS_CANCELLED, INVENTORY_STATUS_CHECKING, INVENTORY_STATUS_PROCESSING, INVENTORY_TYPES } from './../../constants';
import { dateFormatter } from './../../../Common/services/format';

import ListAll from './ListAll';
import SettingTableColumns from './SettingTableColumns';
import Link from './../../../../system/routing/Link';

class ListInventory extends React.Component {
    constructor(props) {
        super();

        const { t } = props;
        let cachedColumns = localStorage.getItem('inventory_list');
        if (cachedColumns) {
            cachedColumns = JSON.parse(cachedColumns);
        }

        const defaultColumns = [
            {
                title: t('inventory:code_check'),
                dataIndex: 'inventory.code',
                key: 'code',
                render: (text, record) => {
                    let link = '';
                    switch (record.inventory.status) {
                        case INVENTORY_STATUS_CHECKING:
                            link = 'inventory.scan';
                            break;
                        case INVENTORY_STATUS_PROCESSING:
                            link = 'inventory.process';
                            break;
                        case INVENTORY_STATUS_CANCELLED:
                            link = 'inventory.create';
                            break;
                        default :
                            link = 'inventory.process';
                            break;
                    }
                    return <Link to={link} params={{id: record.inventory.id}}
                                 className="a-text--nowrap a-text--blue">{text}</Link>
                },
            },
            {
                title: t('inventory:type'),
                dataIndex: 'inventory.type',
                key: 'type',
                render: text => [INVENTORY_TYPES.PACKAGE.key, INVENTORY_TYPES.RECEIVED.key].includes(text) ? t(`inventory:types.${text}`) : '--'
            },
            {
                title: t('inventory:check_time'),
                dataIndex: 'inventory.created_at',
                key: 'created_at',
                className: "a-text--nowrap",
                sorter: true,
                sortOrder: props.filter.sort_by ? (props.filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                render: (text) => <span>{text ? dateFormatter.dateTime(text) : '--'}</span>
            },
            {
                title: t('inventory:reconciliation_time'),
                dataIndex: 'inventory.inventoried_at',
                key: 'inventoried_at',
                render: text => <span>{text ? dateFormatter.dateTime(text) : '--'}</span>,
            },
            {
                title: t('inventory:remainder'),
                key: 'remainder',
                render: (text, record) => {
                    const inventoryType = get(record, 'inventory.type');

                    if (inventoryType === INVENTORY_TYPES.PACKAGE.key) {
                        return get(record, 'count_inventory_packages.package_remainder');
                    }

                    if (inventoryType === INVENTORY_TYPES.RECEIVED.key) {
                        return get(record, 'count_inventory_tracking_bills.tracking_bill_remainder');
                    }

                    return 0;
                }
            },
            {
                title: t('inventory:disappear'),
                key: 'disappear',
                render: (text, record) => {
                    const inventoryType = get(record, 'inventory.type');

                    if (inventoryType === INVENTORY_TYPES.PACKAGE.key) {
                        return get(record, 'count_inventory_packages.package_disappear');
                    }

                    if (inventoryType === INVENTORY_TYPES.RECEIVED.key) {
                        return get(record, 'count_inventory_tracking_bills.tracking_bill_disappear');
                    }

                    return 0;
                }
            },
            {
                title: t('inventory:unresolved'),
                key: 'unresolved',
                render: (text, record) => {
                    const inventoryType = get(record, 'inventory.type');

                    if (inventoryType === INVENTORY_TYPES.PACKAGE.key) {
                        return get(record, 'count_inventory_packages.package_unresolved');
                    }

                    if (inventoryType === INVENTORY_TYPES.RECEIVED.key) {
                        return get(record, 'count_inventory_tracking_bills.tracking_bill_unresolved');
                    }

                    return 0;
                },
                className: "a-text--nowrap",
            },
            {
                title: t('scanned_number'),
                key: 'scanned',
                render: (text, record) => {
                    const inventoryType = get(record, 'inventory.type');

                    if (inventoryType === INVENTORY_TYPES.PACKAGE.key) {
                        return get(record, 'count_inventory_packages.package_scanned');
                    }

                    if (inventoryType === INVENTORY_TYPES.RECEIVED.key) {
                        return get(record, 'count_inventory_tracking_bills.tracking_bill_scanned');
                    }

                    return 0;
                },
                className: "a-text--nowrap",
            },
            {
                title: t('inventory:warehouse'),
                dataIndex: 'warehouse.code',
                key: 'warehouse',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: t('inventory:status'),
                dataIndex: 'inventory.status',
                key: 'status',
                render: (text) => <span className="a-text--nowrap">{t(`inventory:inventory_status_${text}`)}</span>
            },
            {
                title: t('inventory:creator'),
                dataIndex: 'creator.username',
                key: 'creator',
                render: (text, record) => <span className="a-text--nowrap">{`${record.creator.name}(${record.creator.username})`}</span>,

            }
        ];

        let selectedColumns = map(defaultColumns, (item) => item.key);
        let columns = defaultColumns;

        if (Array.isArray(cachedColumns)) {
            columns = filter(defaultColumns, (item) => {
                return includes(cachedColumns, item.key);
            });
            selectedColumns = cachedColumns;
        }

        this.state = {
            defaultColumns: defaultColumns,
            columns: columns,
            selectedColumns: selectedColumns
        };
    }

    onChangeSettingTableColumns = (values) => {
        let { columns, defaultColumns } = this.state;

        columns = filter(defaultColumns, (item) => {
            return includes(values, item.key);
        });
        this.setState({
            columns,
            selectedColumns: values
        });

        localStorage.setItem('inventory_list', JSON.stringify(values));
    };

    componentDidUpdate(prevProps) {
        const { filter } = this.props;

        if (filter.sort_by !== prevProps.filter.sort_by || filter.sort_direction !== prevProps.filter.sort_direction) {
            this.setState(prevState => {
                const createdAtColumnIndex = prevState.columns.findIndex(column => column.key === 'created_at');

                if (createdAtColumnIndex === -1) {
                    return null;
                }

                return {
                    columns: [
                        ...prevState.columns.slice(0, createdAtColumnIndex),
                        {
                            ...prevState.columns[createdAtColumnIndex],
                            sortOrder: filter.sort_by ? (filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                        },
                        ...prevState.columns.slice(createdAtColumnIndex + 1),
                    ]
                };
            });
        }
    }

    render() {
        const { defaultColumns, columns, selectedColumns } = this.state;
        const { t } = this.props;

        return (
            <>
                <div className="a-content__top a-content__top--page-list action-inventory-history mt-0">
                    <div className="a-content--page-list__top-action " id="content-list">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost
                        >
                            {t('inventory:export_csv')}
                        </Button>

                        <SettingTableColumns
                            onOpenSetting={() => {}}
                            options={defaultColumns}
                            values={selectedColumns}
                            onChange={this.onChangeSettingTableColumns}
                        />

                        <Link to="inventory.create">
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3"
                                type="primary"
                            >
                                {t('inventory:create_inventory')}
                            </Button>
                        </Link>
                    </div>
                </div>

                <ListAll
                    {...this.props}
                    columns={columns}
                />
            </>
        );
    }
}

export default ListInventory;
