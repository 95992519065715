import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Icon} from 'antd';

import CustomIcon from './../../../components/icons/Icon';

class ListUsingServices extends React.PureComponent {
    render() {
        let {t, services} = this.props;

        return (
            <div className="a-service">
                <div className="service--body">
                    <div className="service--block">
                        {services.length ? (
                            <div className="service--block--body">
                                <ul className="_service_list">
                                    {services.map(service => (
                                        <li
                                            className={clsx('mb-0', {
                                                '_consign-service a-text--green': service.type === 'consign',
                                                '_normal-service': service.type === 'normal',
                                            })}
                                            key={service.id}
                                        >
                                            <span>
                                                <CustomIcon type={service.icon} />
                                                {service.name}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div className="_service-empty service-empty mt-3">
                                <Icon
                                    type="exclamation-circle"
                                    theme="filled"
                                    className="a-text--orange"
                                /> {t('service:label.using_services_empty')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ListUsingServices.defaultProps = {
    services: [],
};

ListUsingServices.propTypes = {
    services: PropTypes.array,
};

export default withTranslation()(ListUsingServices);