import {combineReducers} from 'redux';
import * as _ from 'lodash';
import * as ACTION from './constants';
import * as lodash from "lodash";
import formInput from "./FormInputProperty/reducer"

let loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_PROPERTY.REQUEST:
        case ACTION.FETCH_DETAIL_PROPERTY.REQUEST:
        case ACTION.CREATE_PROPERTY.REQUEST:
        case ACTION.UPDATE_PROPERTY.REQUEST:
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.REQUEST:
            return true;
        case ACTION.FETCH_LIST_PROPERTY.SUCCESS:
        case ACTION.FETCH_DETAIL_PROPERTY.SUCCESS:
        case ACTION.FETCH_LIST_PROPERTY.FAILED:
        case ACTION.FETCH_DETAIL_PROPERTY.FAILED:
        case ACTION.CREATE_PROPERTY.FAILED:
        case ACTION.UPDATE_PROPERTY.FAILED:
        case ACTION.CLEAR_STATE:
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.SUCCESS:
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.FAILED:
            return false;
        default:
            return state;
    }
};
let list = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_PROPERTY.REQUEST:
        case ACTION.FETCH_LIST_PROPERTY.FAILED:
        case ACTION.CLEAR_STATE:
            return [];
        case ACTION.FETCH_LIST_PROPERTY.SUCCESS:
            if(Array.isArray(action.payload)) {
                return [...action.payload];
            }
            return [..._.get(action, 'payload.properties', [])];
        default:
            return state;
    }
};
let suggest = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.REQUEST:
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.FAILED:
        case ACTION.CLEAR_STATE:
            return [];
        case ACTION.FETCH_LIST_PROPERTY_SUGGEST.SUCCESS:
            return lodash.map(lodash.get(action, 'payload.properties', []), (property) => property.property);

        default:
            return state;
    }
};
let detail = (state = {}, action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_PROPERTY.REQUEST:
        case ACTION.FETCH_DETAIL_PROPERTY.FAILED:
        case ACTION.CLEAR_STATE:
            return {};
        case ACTION.FETCH_DETAIL_PROPERTY.SUCCESS:
            return lodash.get(payload, 'property', {});
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    list,
    formInput,
    suggest,
    detail
});
