import {processApiRequest} from '../Common/saga';
// import {put} from 'redux-saga/effects';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../system/i18n';
// import {setCurrentCountry} from './actions';
// import {filterCountry} from './selectors';
// import lodash from 'lodash';

export function* fetchLocation(action) {
    yield processApiRequest(ACTION.FETCH_LIST_LOCATION, () => apiService.fetchLocation(action.payload), action.payload);
}

export function* fetchLocationFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}

export function* fetchCountry(action) {
    yield processApiRequest(ACTION.FETCH_COUNTRY, () => apiService.fetchCountry(action.payload.id), action.payload);
}

export function* fetchCountryFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}

// export function* fetchCountrySuccess(action) {
//     if( lodash.get(action, 'request.iChooseDefault', false) === true) {
//         let countries = filterCountry(action.payload);
//         if (Array.isArray(countries) && countries.length > 0 ){
//             yield put(setCurrentCountry(countries[0].location.id));
//         }
//
//     }
// }
export function* fetchProvince(action) {
    yield processApiRequest(ACTION.FETCH_PROVINCE, () => apiService.fetchCountry(action.payload.id), action.payload);
}

export function* fetchProvinceFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}

// export function* fetchProvinceSuccess(action) {
//
//     if( lodash.get(action, 'request.iChooseDefault', false) === true) {
//         let countries = filterCountry(action.payload);
//         if (Array.isArray(countries) && countries.length > 0 ){
//             yield put(setCurrentCountry(countries[0].location.id));
//         }
//
//     }
// }

export function* fetchDistrict(action) {
    yield processApiRequest(ACTION.FETCH_DISTRICT, () => apiService.fetchCountry(action.payload.id), action.payload);
}

export function* fetchDistrictFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}
export function* fetchWard(action) {
    yield processApiRequest(ACTION.FETCH_WARD, () => apiService.fetchCountry(action.payload.id), action.payload);
}

export function* fetchWardFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_location_fail'),
        });
    }
}
