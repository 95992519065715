export const UPDATE_ALONE_PACKAGE = {
    REQUEST: 'UPDATE_ALONE_PACKAGE.REQUEST',
    SUCCESS: 'UPDATE_ALONE_PACKAGE.SUCCESS',
    FAILED: 'UPDATE_ALONE_PACKAGE.FAILED',
};

export const CREATE_ALONE_PACKAGE = {
    REQUEST: 'CREATE_ALONE_PACKAGE.REQUEST',
    SUCCESS: 'CREATE_ALONE_PACKAGE.SUCCESS',
    FAILED: 'CREATE_ALONE_PACKAGE.FAILED',
};

export const RESET_ERROR = "UPDATE_ALONE_PACKAGE.RESET_ERROR";
export const CLEAR_STATE = "UPDATE_ALONE_PACKAGE.CLEAR_STATE";