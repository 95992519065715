import React, {Component} from 'react';
import {Button, Col, Form, Layout, Row, notification} from 'antd';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import CustomerInfo from "./CustomerInfo";
import lodash, { find, get } from 'lodash';
import CustomerFormInput from "./CustomerFormInput";
import {DEFAULT_STATE_FORM_CUSTOMER, DELIVERY_NOTE_ACTION, DELIVERY_NOTE_SETTING_KEY, MAXIMUM_COUNT_WAIT_INTERVAL} from "../../constants";
import {url} from '../../../../system/routing';
import FormCustomerLoading from "./FormCustomerLoading";
import {getCandeliveries} from './../../helper';

import PromptLeavePage, { checkLeavePage } from './PromptLeavePage';
import CanDeliveries from './../../DetailInfoForm/components/CanDeliveries/CanDeliveriesContainer';
import ReadyForDeliveries from './../../DetailInfoForm/components/ReadyForDeliveries/ReadyForDeliveriesContainer';
import WarehouseAreaList from '../../Create/components/WarehouseAreaList';
import authService from '../../../Auth/authService';
import permissions from '../../../Auth/permissions';
import apiService from '../../Create/apiService';
import isEmpty from 'lodash/isEmpty';

const {Content} = Layout;

const paramConfirmInputs = ['customer_receiver', 'note', 'amount_collect', 'bags', 'packages', 'cod', 'negative_balance', 'shipping_fee'];

class FormCustomer extends Component {
    state = {
        ...DEFAULT_STATE_FORM_CUSTOMER,
        canDeliveries: [],
        submit: false,
        id_last_mile_carrier: undefined,
        loadingPreSubmit: '',
        intervalOld: 0,
        newReadyForDeliveries: []
    };

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        let idCustomer = !lodash.get(props.deliveryNote, 'delivery_note.id') ? lodash.get(props.customerDetailInfo, 'customer.id', 0) : lodash.get(props, 'deliveryNote.id_customer', '');
        const deliveryNoteId = lodash.get(props.deliveryNote, 'delivery_note.id');

        if (state.id_customer !== idCustomer && idCustomer !== 0) {
            nextState = lodash.isNil(props.deliveryNote) ? {...DEFAULT_STATE_FORM_CUSTOMER} : {
                note: lodash.get(props.deliveryNote, 'delivery_note.note', ''),
                amount_collect: lodash.get(props.deliveryNote, 'delivery_note.amount_collect', '')
            };
            nextState.id_customer = idCustomer;
            nextState.id_warehouse = lodash.get(props, 'input.id_warehouse', 0);
            nextState.customer_receiver = !lodash.get(props.deliveryNote, 'delivery_note.id') ?
                lodash.get(props.customerDetailInfo, 'customer.full_name', "") : lodash.get(props.deliveryNote, 'delivery_note.customer_receiver', '');

            if (deliveryNoteId) {
                nextState.negative_balance = lodash.get(props.deliveryNote, 'delivery_note.negative_balance');
            } else if (lodash.get(props.customerDetailInfo, 'customer_finance.amount_remaining') < 0) {
                nextState.negative_balance = Math.abs(props.customerDetailInfo.customer_finance.amount_remaining);
                nextState.cod = nextState.negative_balance;
            }
        }

        if (!lodash.isEqual(state.deliveryNote, props.deliveryNote)) {
            nextState.deliveryNote = props.deliveryNote;
            nextState.length = lodash.get(props.deliveryNote, 'delivery_note.length');
            nextState.width = lodash.get(props.deliveryNote, 'delivery_note.width');
            nextState.height = lodash.get(props.deliveryNote, 'delivery_note.height');
        }

        nextState.packages = props.packages;
        nextState.bags = props.bags;

        const newReadyForDeliveries = []
        props.barcodes.map(item => {
            const packageCodeReadyDelivery = get(item, 'package.code')
            const packageInfo = find(state.newReadyForDeliveries, ['package.code', packageCodeReadyDelivery])
            if (!isEmpty(packageInfo)) {
                newReadyForDeliveries.push({ ...item, ...packageInfo })
            } else {
                newReadyForDeliveries.push(item)
            }
        })

        nextState.newReadyForDeliveries = newReadyForDeliveries

        return nextState;
    }

    checkOnbeforeunload = () => {
        const { submit, t } = this.state;
        const { deliveryNote, paramConfirmInputs } = this.props;
        alert(checkLeavePage(deliveryNote, paramConfirmInputs, submit, this.state));

        if (checkLeavePage(deliveryNote, paramConfirmInputs, submit, this.state)) {
            return t('message.confirm_when_leave');
        }

        return null;
    };

    handleReloadCanDeliveries = event => {
        event.preventDefault();

        const { input, getCustomerBagPackages } = this.props;
        const customerId = lodash.get(input, 'id_customer');

        if (customerId) {
            getCustomerBagPackages(input);
        }
    };

    componentDidMount() {
        const { input, getCustomerDetailInfo, getCustomerBagPackages } = this.props;
        const customerId = lodash.get(input, 'id_customer');

        if (customerId) {
            getCustomerDetailInfo(customerId);
            getCustomerBagPackages(input);
        }

        window.onbeforeunload = this.checkOnbeforeunload;
    }

    componentDidUpdate(prevProps) {
        const { bags, customerBagPackages, deliveryNote, packages } = this.props;

        if (!lodash.isEqual(bags, prevProps.bags) || !lodash.isEqual(customerBagPackages, prevProps.customerBagPackages) || !lodash.isEqual(packages, prevProps.packages)) {
            this.setState({
                canDeliveries: getCandeliveries(customerBagPackages, bags, packages)
            });
        }

        if (lodash.get(deliveryNote, 'delivery_note.id_last_mile_carrier') !== lodash.get(prevProps.deliveryNote, 'delivery_note.id_last_mile_carrier')) {
            this.setState({
                id_last_mile_carrier: lodash.get(deliveryNote, 'delivery_note.id_last_mile_carrier')
            });
        }
    }

    componentWillUnmount() {
        const { clearState } = this.props;

        clearState();
        window.onbeforeunload = null;
    }

    redirectRenew = () => {
        url.redirectTo('delivery-notes.customer.create', undefined, {
            id_warehouse: lodash.get(this.props, 'input.id_warehouse', 0)
        });
    };

    onUpdate = params => {
        if (params.packages) {
            delete params.packages;
            delete params.id_customer;
            delete params.id_warehouse;
        }

        this.setState({
            ...this.state,
            ...params,
            submit: false
        });
    };

    handleCreateTaskCheckPayment = (data, auto) => {
        const {id_customer, packages, bags} = this.state;
        const { t, onSubmit, barcodes: readyForDeliveries} = this.props;
        this.setState({loadingPreSubmit: auto ? 'create-with-tracking-no' : 'create_delivery_note'})
        apiService.createTaskCheckPaymentStatus({id_customer, id_packages: packages, id_bags: bags})
        .then(res => {
            const taskId = get(res, 'data.task._id', '')
            const ignore_payment_confirmation = get(res, 'data.ignore_payment_confirmation', false)
            if (ignore_payment_confirmation) {
                this.setState({loadingPreSubmit: ''})
                    const newReadyForDeliveries = [];
                    readyForDeliveries.map(item => {
                        let paymentStatus = 1;
                        newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                    })
                    this.setState({newReadyForDeliveries})
                onSubmit({...data, task_id: taskId}, auto)
            } else {
                this.handleInterval(taskId, data, auto)
            }
        }).catch(() => {
            notification.error({message: t("message.server_error")})
            this.setState({loadingPreSubmit: ''})
        })
    }

    handleInterval = (taskId, data, auto) => {
        const { t, onSubmit, barcodes: readyForDeliveries} = this.props;
        let stt = 0
        const {intervalOld} = this.state;
        const interval = setInterval(async () => {
            clearInterval(intervalOld)
            stt++
            try {
                const res = await apiService.handleProcessTaskId(taskId)
                const status = get(res, "data.task.status");
                const readyForExport = get(res, "data.task.ready_for_export", false)
                const errors = get(res, "data.task.errors", [])
                const packages = get(res, "data.task.packages", [])
                if (status === "FINISH" && errors.length === 0 && readyForExport) {
                    clearInterval(interval)
                    this.setState({loadingPreSubmit: ''})
                    const newReadyForDeliveries = [];
                    readyForDeliveries.map(item => {
                        let paymentStatus = 1;
                        newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                    })
                    this.setState({newReadyForDeliveries})
                    onSubmit({...data, task_id: taskId}, auto)
                    
                } else {
                    if (errors.length > 0) {
                        clearInterval(interval)
                        this.setState({loadingPreSubmit: ''})
                        const newReadyForDeliveries = [];
                        readyForDeliveries.map(item => {
                            let paymentStatus = 1;
                            const packageCodeReadyDelivery = get(item, "package.code");
                            const packageInfo = find(packages, ['code', packageCodeReadyDelivery])
                            if (!isEmpty(packageInfo)){
                                paymentStatus =  get(packageInfo, "payment_status", "") === "success" ? 1 : 0;
                            }
                            newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                        })
                        this.setState({newReadyForDeliveries})
                        const messages = errors.map(error => t(`delivery_note:message.${error}`))
                        notification.error({
                            message: messages.join(", "),
                        })
                    }
                }
                
            } catch (error) {
                this.setState({loadingPreSubmit: ''})
                clearInterval(interval)
                notification.error({message: t("message.server_error")})
            }
            if (stt >= MAXIMUM_COUNT_WAIT_INTERVAL) {
                this.setState({loadingPreSubmit: ''})
                clearInterval(interval)
                notification.error({
                    message: t('delivery_note:message.over_time_allow'),
                })
            }
            
        }, 8000)
        this.setState({
            intervalOld: interval
        })
    }


    onSubmit = (auto = false) => {
        const { creating, deliveryNote, input, onSubmit, agencySetting, globalSetting, customerDetailInfo} = this.props;
        const { intervalOld } = this.state;     
        clearInterval(intervalOld)
        this.setState({ intervalOld: 0 })
        const { agency } = customerDetailInfo;
        const id_agency = get(agency, "id")
        
        if (creating) {
            return;
        }
        this.setState({
            ...this.state,
            submit: true
        });

        const data = lodash.omit({
            ...this.state,
            id_warehouse: input.id_warehouse
        }, ['local_delivery_charge', 'local_delivery_charge_note', 'deliveryNote', 'canDeliveries', 'submit', 'newReadyForDeliveries']);

        data.length = lodash.isNil(data.length) ? 0 : data.length;
        data.width = lodash.isNil(data.width) ? 0 : data.width;
        data.height = lodash.isNil(data.height) ? 0 : data.height;
        data.negative_balance = lodash.isNil(data.negative_balance) ? 0 : data.negative_balance;
        data.domestic_shipping_fee = lodash.isNil(data.domestic_shipping_fee) ? 0 : data.domestic_shipping_fee;
        data.cod = lodash.isNil(data.cod) ? 0 : data.cod;
        data.id = lodash.get(deliveryNote, 'delivery_note.id', 0);
        
        const ignoreCallPayment = get(find(agencySetting, {code: DELIVERY_NOTE_SETTING_KEY.DELIVERY_NOTE_IGNORE_CALL_PAYMENT, id_agency}), "value", get(find(globalSetting, ["code", DELIVERY_NOTE_SETTING_KEY.DELIVERY_NOTE_IGNORE_CALL_PAYMENT]), "value", false));
        if (ignoreCallPayment) {
            onSubmit(data, auto);
        } else {
            this.handleCreateTaskCheckPayment(data, auto)
        }
    };

    render() {
        const { canDeliveries, newReadyForDeliveries } = this.state;
        const {
            bags,
            barcodes,
            t,
            customerDetailInfo,
            loading,
            errors,
            loadingCustomerDetailInfo,
            deliveryNote,
            loadingCustomerBagPackages,
            creating,
            packages,
            onOpenPackageListByBagPopup,
            removeBarcode,
            input,
            creatingWithTrackingNo
        } = this.props;
        const isShowBtnRenew = !lodash.get(deliveryNote, 'delivery_note.id');
        const isShowBtnSave = !lodash.get(deliveryNote, 'delivery_note.id');
        const {customer, customer_finance, agency} = customerDetailInfo;
        const isLastmileShipmentAutoCreate = authService.can(permissions.LASTMILE_SHIPMENT_AUTOMATION_CREATE)
        
        return (
            <Content className="form-customer a-sub-main gflex gflex-direction-column">
                <PromptLeavePage
                    stateData={this.state}
                    deliveryNote={deliveryNote}
                    params={paramConfirmInputs}
                    submit={this.state.submit}
                />
                <Form className="a-block amb-16 bg-white">
                    <div className="block--primary">
                        <div className="title">
                            <div className="left">
                                <span
                                    className="a-text--medium a-text--fz-16">{t("delivery_note:label.title_create_page")}</span>
                            </div>
                            <div className="right gflex galign-center gjustify-space-between">
                                <WarehouseAreaList customerInfos={[customerDetailInfo]} warehouseId={input.id_warehouse} selectedPackageIds={packages} />
                                <p className="mb-0 a-text--fz-16 a-text--nowrap ml-4">{t("delivery_note:label.customer")} -
                                    <span
                                        className="a-text--medium ml-1 a-text--uppercase _employee_code">{" " + (customer.username || "--")}</span>
                                </p>
                            </div>
                        </div>
                        <div className="ap-16">
                            <Row gutter={{lg: 24, xl: 24}}>
                                <div className="a-block-info pb-0 ">
                                    {loadingCustomerDetailInfo ?
                                        <FormCustomerLoading/> :
                                        <CustomerInfo customer={customer} customer_finance={customer_finance}/>
                                    }
                                    <CustomerFormInput
                                        bags={bags}
                                        customer={customer}
                                        customerFinance={customer_finance}
                                        customerInfo={lodash.get(deliveryNote, 'customer_info')}
                                        deliveryNote={lodash.get(deliveryNote, 'delivery_note')}
                                        errors={errors}
                                        id_customer={lodash.get(customerDetailInfo, 'customer.id')}
                                        loading={loading}
                                        packages={packages}
                                        onUpdate={this.onUpdate}
                                        resetError={this.props.resetError}
                                        readyForDeliveries={newReadyForDeliveries}
                                    />
                                </div>
                            </Row>
                        </div>
                    </div>
                </Form>
                <Row gutter={16} className="aheight-100 gflex aflex-1 apx-16 bg-white amx-1">
                    <Col className="aheight-100" lg={{span: 12}} span={24}>
                        <CanDeliveries
                            action={DELIVERY_NOTE_ACTION.CREATE}
                            canDeliveries={canDeliveries}
                            loading={loadingCustomerBagPackages}
                            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                            onReload={this.handleReloadCanDeliveries}
                            agency={agency}
                            readyForDeliveries={newReadyForDeliveries}
                        />
                    </Col>
                    <Col className="aheight-100 min-w-0" lg={{span: 12}} span={24}>
                        <ReadyForDeliveries
                            canUpdate={!(creatingWithTrackingNo || creating || !!this.state.loadingPreSubmit) }
                            readyForDeliveries={newReadyForDeliveries}
                            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                            onRemove={removeBarcode}
                            agency={agency}
                        />
                    </Col>
                </Row>
                <div className="a-content--action-fixed-bottom">
                    {isShowBtnRenew && (
                        <Button
                            className={"a-btn--button-link mr-auto a-text--blue"}
                            onClick={this.redirectRenew}
                        >
                            {t('btn.create_new')}
                        </Button>
                    )}

                    {
                        isShowBtnSave && isLastmileShipmentAutoCreate && 
                        <Button 
                            loading={creatingWithTrackingNo || this.state.loadingPreSubmit === 'create-with-tracking-no'} 
                            onClick={() => this.onSubmit(true)} 
                            size="large" 
                            className='mr-2 a-btn a-btn--primary a-btn--auto'>
                            {t('delivery_note:btn.create_lastmile_shipment_and_print')}
                        </Button>
                    }

                    {isShowBtnSave && (
                        <Button
                            className="a-btn a-btn--primary a-btn--save-export"
                            loading={creating || this.state.loadingPreSubmit === 'create_delivery_note'}
                            size="large"
                            onClick={() => this.onSubmit(false)}
                        >
                            {t('btn.save')}
                        </Button>
                    )}
                </div>
            </Content>
        )
            ;
    }
}

FormCustomer.defaultProps = {
    loading: false,
    loadingCustomerDetailInfo: false,
    customerDetailInfo: {
        customer: {},
        customer_finance: {},
    },
    input: {},
    packages: [],
    errors: {},
    deliveryNote: {},
    customerInfo: {}
};

FormCustomer.propTypes = {
    loading: PropTypes.bool,
    loadingCustomerDetailInfo: PropTypes.bool,
    customerDetailInfo: PropTypes.object,
    input: PropTypes.object,
    packages: PropTypes.array,
    errors: PropTypes.object,
    resetError: PropTypes.func,
    createDeliveryNoteCreateForCustomer: PropTypes.func,
    updateDeliveryNoteCreateForCustomer: PropTypes.func,
    deliveryNote: PropTypes.object
};

export default withTranslation()(FormCustomer);

