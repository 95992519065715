import { Select } from 'antd'
import { map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../system/i18n'
import apiService from '../../Agencies/apiService'

const { Option } = Select

const SelectAgency = props => {
    const [loading, setLoading] = useState(false)
    const [agencies, setAgencies] = useState([])

    useEffect(() => {
        fetchAgencies()
    }, [])

    const fetchAgencies = () => {
        setLoading(true)
        apiService
            .getAgencySuggest()
            .then(res => {
                setAgencies(processData(res.data))
            })
            .catch(error => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const processData = data => {
        const newData = map(data.agencies, item => item.agency)

        return newData
    }

    return (
        <Select
            {...props}
            className={'a-select a-select--w-100 _select_agency'}
            placeholder={t('agency:placeholder.choose_agency')}
            dropdownClassName={'_dropdown-agency'}
            showSearch={false}
            loading={loading}>
            {map(agencies, (agency, index) => (
                <Option
                    className="_agency-item"
                    key={index}
                    value={agency.id}>
                    {agency.name}
                </Option>
            ))}
        </Select>
    )
}
export default SelectAgency
