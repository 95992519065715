import React, { Component } from 'react';
import { Input, Modal, Table } from 'antd';
import * as _ from 'lodash';

const Search = Input.Search;
class PopupListTemplateBag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: undefined
        };

        this.onSearch = _.debounce(this.onSearch, 400);
    }

    componentDidMount() {
        this.props.fetchTemplateBags();
    }

    onSearch = (name) => {
        this.props.fetchTemplateBags({
            name: name
        });
    };

    onChangeSearch = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    onClickRow = (record) => {
        this.props.onSelectTemplateBag(record);
    };

    render() {
        const {onCancel, dataTemplateBags, t, loading} = this.props;
        const columns = [{
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            render: text => <span className="a-text--nowrap">{text}</span>,
        }, {
            title: 'Tên',
            dataIndex: 'bag_template.name',
            key: 'name',
            className: "a-text--nowrap",
            render: (text) => <span className="a-text--nowrap">{text || '--'}</span>
        }, {
            title: 'Kích thước',
            dataIndex: 'bag_size.name',
            key: 'size',
            render: (text) => <span className="a-text--nowrap">{text || '--'}</span>,
        },{
            title: t('common:agency'),
            dataIndex: 'agencies',
            key: 'agencies',
            className: "a-text--nowrap",
            render: (text) => <span className="a-text--nowrap">{_.map(text, 'agency.name').join(', ')}</span>
        }, {
            title: 'Đặc tính',
            dataIndex: 'properties',
            key: 'properties',
            className: "a-text--nowrap",
            render: (text) => <span className="a-text--nowrap">{_.map(text, 'name').join(', ')}</span>
        }];
        const bagTemplates = _.get(dataTemplateBags, 'bag_templates', []);

        _.forEach(bagTemplates, (item, index) => {
            item.stt = index + 1;
        });

        return (
            <Modal
                visible={true}
                width={644}
                closable={false}
                onCancel={onCancel}
                className="a-modal a-modal--choose-template-bag"
                footer={null}
                centered={true}
            >
                <div className="choose-template-bag--title">
                    <span className="title">{t('bag:label.choose_template_bag')}</span>
                    <Search
                        placeholder={t('bag:label.input_template_bag')}
                        className="a-input--search-template-bag"
                        onSearch={this.onSearch}
                        onChange={this.onChangeSearch}
                    />
                </div>
                <div className="body">
                    <Table
                        className={"a-table--antd"}
                        columns={columns}
                        dataSource={bagTemplates}
                        loading={loading}
                        rowClassName="a-text--cursor-pointer"
                        rowKey={row => _.get(row, 'bag_template.id', _.uniqueId())}
                        onRow={(record) => {
                            return {
                                onClick: this.onClickRow.bind(this, _.get(record, 'bag_template.id'))
                            }
                        }}
                        pagination={false}
                    />
                </div>
            </Modal>
        )
    }
}

export {PopupListTemplateBag};
