import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';

import api from '../../../Customer/apiService';
import { debounce, get, split } from 'lodash';
import { getVar } from '../../../../system/support/helpers';
import { t } from '../../../../system/i18n';



const { Option } = Select;
let timeout;
const SelectSuggestCustomer = (props) => {
    const { value, onChange } = props

    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)

    const [keyword, setKeyword] = useState('')

    const getCustomer = async (newValue) => {
        setLoading(true)
        try {
            const response = await api.getSuggests({
                username: newValue
            });
            const data = get(response, 'data.customers', [])
            setCustomers(data)
        } catch (error) {
        } finally {
            setLoading(false)
        }

    }

    const debounceFn = useCallback(debounce(getCustomer, 300), []);


    const handleSearch = (newValue) => {
        if (newValue) {
            debounceFn(newValue);
        } else {
            setCustomers([]);
        }
    };

    return (
        <Select
            onSearch={handleSearch}
            onChange={onChange}
            className="a-select _customer w-100"
            disable={loading}
            filterOption={(input, option) => {
                return (option.props.label ? option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) || (option.props.code ? option.props.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
            }}
            loading={loading}
            name="username"
            optionFilterProp="children"
            optionLabelProp="label"
            placeholder={t('delivery_note:placeholder.choose_customer')}
            showSearch
            allowClear
            value={value ? split(value,";")[0] : undefined}
        >
            {customers.map(customer => (
                <Option code={getVar(customer, 'customer.code', '--')} key={get(customer, 'customer.id')} label={getVar(customer, 'customer.username', '--')} value={`${get(customer, 'customer.id')};${get(customer, 'customer.username')};${get(customer, 'agency.code')}`}>
                    <span className="a-text--black">{getVar(customer, 'customer.username', '--')}</span>
                    <br />
                    <span className="a-text--fz-12 a-text--gray-70">{getVar(customer, 'customer.code', '--')} ({getVar(customer, 'agency.code') || getVar(customer, 'customer.agency.code') || '--'})</span>
                </Option>
            ))}


        </Select>
    );
};

export default SelectSuggestCustomer