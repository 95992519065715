import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import packageApiService from './../../apiService';
import { TRANSPORT_STATUS } from './../../constants';
import { isShipment } from './../../helper';
import Package from './../../models/Package';
import { notifyErrorFromServer } from './../../../../system/support/helpers';

import UpdateProductsModal from './UpdateProducts/UpdateProductsModal';

const UpdateProducts = ({ code, isUnLinkOrderSuccess, loading, packageItems, pkg, t, fetchPackage, onSuccessUpdate }) => {
    const [updateProductsModalVisible, setUpdateProductsModalVisible] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [creating, setCreating] = useState(false);
    const [units, setUnits] = useState([]);

    const handleUpdateProductButtonClick = () => {
        setUpdateProductsModalVisible(true);
    };

    const handleCancelUpdateProductsModalVisible = () => {
        setUpdateProductsModalVisible(false);
        setCreating(false);
        setUpdating(false);
    };

    const updateProduct = async data => {
        const packageModel = new Package(pkg);
        return await apiService.updateItems(packageModel.id, {
            ...data,
            weight_net: packageModel.realWeightNetWhenUpdate
        })
    };

    const createOrder = async () => {
        await apiService.createShipmentOrder(code).then(() => {
            notification.success({
                message: t('package:create_shipment_order.success')
            });
        }).catch(err => {
            notifyErrorFromServer(err, t('package:create_shipment_order.failed'), 'package:create_shipment_order.errors');
        }).finally(() => {
            setCreating(false);
        });
    };

    const handleUpdate = async (data, isCreateOrder) => {
        isCreateOrder ? setCreating(true) : setUpdating(true);

        try {
            if (isEmpty(get(data, "items", []))) {
                notification.error({
                    message: t('package:checking.product_items_required')
                });
            } else {
                const response = await updateProduct(data);
                notification.success({
                    message: t('message.update_success', {
                        attribute: t('package:label.product').toLowerCase()
                    })
                });

                if (isCreateOrder) {
                    await createOrder();
                }

                onSuccessUpdate(response.data);
            }
        } catch (err) {
            notification.error({
                message: t('message.update_failed', {
                    attribute: t('package:label.product').toLowerCase()
                })
            });
        } finally {
            isCreateOrder ? setCreating(false) : setUpdating(false);
        };
    };

    const getProductUnits = async () => {
        try {
            const {data} = await packageApiService.getSuggestProductUnits();
            setUnits(data);
        } catch (error) {
            setUnits([]);
        }
    };

    async function updatePackageService(services) {
        const packageModel = new Package(pkg);
        try {
            await apiService.updateAlonePackage(packageModel.id, {services});
            notification.success({
                message: t('message.update_success', {attribute: t('service').toLowerCase()})
            });
            fetchPackage(code, true);
        } catch (error) {
            notification.error({
                message: t('message.update_failed', {attribute: t('service').toLowerCase()})
            });
        }
    }

    useEffect(() => {
        if (isUnLinkOrderSuccess) {
            fetchPackage(code, true)
        }
    }, [code, fetchPackage, isUnLinkOrderSuccess]);

    useEffect(() => {
        getProductUnits();
    }, []);

    if (![TRANSPORT_STATUS.PUTAWAY, TRANSPORT_STATUS.TRANSPORTING].includes(get(pkg, 'status_transport'))) {
        return null;
    }

    if (!get(pkg, 'order.allow_update_item', true)) {
        return null;
    }

    return (
        <div className="amb-14 gflex gjustify-center">
            <Button className="_btn-update-products a-btn a-btn--primary" loading={loading}
                    onClick={handleUpdateProductButtonClick}>
                {t('update_product')}
            </Button>
            
            <UpdateProductsModal
                loading={loading}
                packageItems={packageItems}
                pkg={pkg}
                units={units}
                updating={updating}
                creating={creating}
                visible={updateProductsModalVisible}
                onCancel={handleCancelUpdateProductsModalVisible}
                onOk={handleUpdate}
                updatePackageService={updatePackageService}
            />
        </div>
    );
};

export default withTranslation()(UpdateProducts);
