/* eslint-disable */
import React, {Component} from 'react';
import {Layout, Button, Icon, Alert} from 'antd';

const {Content} = Layout;

const Sort = <div className="sort"><Icon type="caret-up" className="active"/><Icon type="caret-down"/></div>;

class ListBag extends Component {
    render() {
        let {scroll} = this.props;
        return (
                <Content className={"a-content a-barcode-scan " + (scroll ? 'scroll-is-sider' : '')}>
                    <div className="a-content__top">
                        <div className="a-content__top--page-list--title">
                            <h3>Danh sách kiện trong bao <span style={{fontSize: '1.2rem'}}> - 32216</span></h3>
                        </div>
                        {/*<div className="a-barcode-scan__top-action">*/}
                        {/*    <Button type="primary"*/}
                        {/*            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular "*/}
                        {/*            ghost>Xuất CSV*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                    <div
                        className="a-content__body bg-white a-barcode-scan__body a-content__body--page-list list-is-sidebar ">
                        <Alert className="mb-4" message="Warning" type="warning" showIcon/>
                        <div className="a-table--responsive">
                            <table className="a-table a-table--barcode-scan">
                                <thead>
                                <tr>
                                    <th>Mã Kiện</th>
                                    <th>Thời Gian Quét {Sort}</th>
                                    <th>Đặc tính</th>
                                    <th>Mã đơn hàng</th>
                                    <th>Cân nặng</th>
                                    <th>Kích thước</th>
                                    <th className="action-remove"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody className="error">
                                <tr className="data">
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="7">
                                                <span className="a-text--black-45">
                                                <Icon type='close-circle' theme="filled" className="a-text--red mr-2"/>
                                                Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody className="warning">
                                <tr className="data">
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="7">
                                                <span className="a-text--black-45">
                                                    <Icon type='info-circle' theme="filled"
                                                          className="a-text--orange mr-2"/>
                                                    Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap">987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span >Hàng dễ vỡ</span></td>
                                    <td>294-2492-034924</td>
                                    <td>180(kg)</td>
                                    <td>180(cm3)</td>
                                    <td className="action-remove"><Icon type="close"/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </Content>
        );
    }
}

export default ListBag;
