import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';


export default function*() {
    yield takeEvery(ACTION.UPDATE_WEIGHT.REQUEST, saga.updateWeightPackage);
    yield takeLatest(ACTION.UPDATE_WEIGHT.SUCCESS, saga.updateWeightPackageSuccess);
    yield takeLatest(ACTION.UPDATE_WEIGHT.FAILED, saga.updateWeightPackageFailed);
}
