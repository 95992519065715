import React, { useState } from 'react'
import { Button, Modal, Form, Input, Select, notification } from 'antd'
import { withTranslation } from 'react-i18next'
import apiService from '../../apiService'
import CurrencyInput from '../../../Common/components/CurrencyInput'
import SelectAllAgency from '../../../Setting/Agency/SelectAllAgency'
import lodash from 'lodash'
import { AGENCY_FINANCE_TRANSACTION_TYPE } from '../../constants'

function AddFinanceAgency(props) {
    const { form, t, fetchData, filter } = props
    const { getFieldDecorator } = form
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = e => {
        form.validateFieldsAndScroll(async (errors, values) => {
            setLoading(true)
            if (errors) {
                setLoading(false)
                return
            }
            apiService
                .addFinanceTransaction(values)
                .then(res => {
                    const agency = lodash.get(res, 'data.agency.name', '')
                    notification.success({ message: t('agency:message.finance.add_transaction_success', { agency }) })
                    fetchData({ i: parseInt(filter.i || 0, 0) + 1 })
                    handleCancel()
                })
                .catch(() => {
                    setLoading(false)
                    notification.error({ message: t('agency:message.finance.add_transaction_failed', { agency: lodash.get(values, "agency", "") }) })
                })
        })
    }

    const handleCancel = e => {
        setVisible(false)
        setLoading(false)
        form.resetFields()
    }
    return (
        <>
            <div>
                <Button
                    type="primary"
                    onClick={showModal}>
                    Tạo giao dịch
                </Button>
                <Modal
                    title={t('agency:title.finance_add_transaction')}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={{ loading }}
                    okText={t('btn.confirm')}
                    cancelText={t('btn.cancel')}>
                    <Form>
                        <Form.Item label={t('agency:label.transaction_type')}>
                            {getFieldDecorator('type', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t('agency:label.transaction_type'),
                                        }),
                                    },
                                ],
                            })(
                                <Select placeholder={t('agency:placeholder.transaction_type')}>
                                    {AGENCY_FINANCE_TRANSACTION_TYPE.map(item => {
                                        return (
                                            <Select.Option
                                                value={item}
                                                key={item}>
                                                {t(`agency:agency_transaction_type.${item}`)}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={t('agency:label.currency')}>
                            {getFieldDecorator('currency', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t('agency:label.currency'),
                                        }),
                                    },
                                ],
                                initialValue: 'CNY'
                            }, )(
                                <Select placeholder={t('agency:placeholder.currency')}>
                                    <Select.Option value="CNY">CNY</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={t('agency:label.amount_change')}>
                            {getFieldDecorator('amount', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t('agency:label.amount_change'),
                                        }),
                                    },
                                    {
                                        validator: async (rule, value, callback) => {
                                            if (Number.isInteger(value) && value <= 0) {
                                                callback(
                                                    t('validation:gt', {
                                                        attribute: t('agency:label.amount_change'),
                                                        value: 0,
                                                    })
                                                )
                                            } else {
                                                callback()
                                            }
                                        },
                                    },
                                ],
                            })(
                                <CurrencyInput
                                    value={form.getFieldValue('amount')}
                                    style={{ width: '100%' }}
                                    className="text-right"
                                    onBlur={value => form.setFieldsValue({ amount: value })}
                                    placeholder={t('agency:placeholder.amount_change')}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label={t('agency:label.reason')}>
                            {getFieldDecorator('reason', {
                                rules: [
                                    {
                                        required: true,
                                        transform: value => (value ? value.trim() : value),
                                        message: t('validation:required', {
                                            attribute: t('agency:label.reason'),
                                        }),
                                    },
                                ],
                            })(
                                <Input.TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    placeholder={t('agency:placeholder.reason')}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label={t('agency:label.agency_apply')}>
                            {getFieldDecorator('agency_id', {
                                rules: [
                                    {
                                        required: true,
                                        message: t('validation:required', {
                                            attribute: t('agency:label.agency_apply'),
                                        }),
                                    },
                                ],
                            })(
                                <SelectAllAgency
                                    allowClear
                                    className="_id-agency"
                                    dropdownClassName="_dropdown-id-agency"
                                    placeholder={t('agency:placeholder.agency_apply')}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    )
}
export default withTranslation()(Form.create()(AddFinanceAgency))
