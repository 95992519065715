import { all, fork } from 'redux-saga/effects';

import list from './List/sagaWatcher';
import create from './Create/sagaWatcher';
import detail from './Detail/sagaWatcher';
import edit from './Edit/sagaWatcher';

export default function*() {
    yield all([
        fork(list),
        fork(create),
        fork(detail),
        fork(edit)
    ]);
}
