import { Button, notification } from 'antd';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { createElement, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { successCreateShipmentOrder } from '../actions';
import apiService from '../apiService';
import { isLinkOrder } from '../helper';
import { getSettingItemByCode } from '../../Setting/selectors';
import { notifyErrorFromServer } from '../../../system/support/helpers';

const createShipmentGenarator = getSettingItemByCode('ORDER:CREATE_SHIPMENT', false);

const CreateShipmentOrderButton = ({ canCreateShipment, pkg, wrapper, successCreateShipmentOrder }) => {
    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState()
    const { t } = useTranslation();
    const code = get(pkg, 'code');
    useEffect(() => {
        setCustomerId(get(pkg, 'customer.id'))
    }, [pkg]);
    const createShipmentOrder = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await apiService.createShipmentOrder(code);

            notification.success({
                message: t('package:create_shipment_order.success')
            });
            successCreateShipmentOrder(data);
        } catch (error) {
            notifyErrorFromServer(error, t('package:create_shipment_order.failed'), 'package:create_shipment_order.errors');
        }

        setLoading(false);
    }, [code, t, successCreateShipmentOrder]);

    if (!canCreateShipment || !customerId || isLinkOrder(pkg)) {
        return null;
    }

    if (!wrapper) {
        return (
            <Button
                className="a-btn a-btn--primary _btn-create-shipment-order"
                loading={loading}
                type="primary"
                onClick={createShipmentOrder}
            >
                {t('create_order_by_customer')}
            </Button>
        );
    }

    return createElement(wrapper, null, (
        <Button
            className="a-btn a-btn--primary _btn-create-shipment-order"
            loading={loading}
            type="primary"
            onClick={createShipmentOrder}
        >
            {t('create_order_by_customer')}
        </Button>
    ));
}

CreateShipmentOrderButton.propTypes = {
    wrapper: PropTypes.string
};

const mapStateToProps = state => ({
    canCreateShipment: createShipmentGenarator(state)
});

const mapDispatchToProps = {
    successCreateShipmentOrder
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateShipmentOrderButton);
