import * as saga from './saga';
import {takeLatest, takeEvery} from 'redux-saga/effects';
import * as ACTION from "./constants";

export default function*() {
    yield takeLatest(ACTION.FETCH_TEMPLATE_BAGS.REQUEST, saga.fetchTemplateBags);

    yield takeLatest(ACTION.CREATE_TEMPLATE_BAG.REQUEST, saga.createTemplateBag);
    yield takeEvery(ACTION.CREATE_TEMPLATE_BAG.SUCCESS, saga.createTemplateSuccess);
    yield takeLatest(ACTION.CREATE_TEMPLATE_BAG.FAILED, saga.createTemplateFailed);

    yield takeLatest(ACTION.UPDATE_TEMPLATE_BAG.REQUEST, saga.updateTemplateBag);
    yield takeEvery(ACTION.UPDATE_TEMPLATE_BAG.SUCCESS, saga.updateTemplateBagSuccess);
    yield takeLatest(ACTION.UPDATE_TEMPLATE_BAG.FAILED, saga.updateTemplateBagFailed);

    yield takeLatest(ACTION.FETCH_TEMPLATE_BAG_DETAIL.REQUEST, saga.fetchTemplateBagDetail);
}

