import React from 'react';
import lodash from "lodash";
import systemAvatar from "../../../../resources/images/log-sys.svg";
import {dateFormatter} from "../../../Common/services/format";
import {Icon} from "antd";

const isImage = (type) => {
    return type.indexOf('image') >= 0;
};

class ChatItem extends React.Component {

    renderAttachmentItem = (item) => {
        if (isImage(item.type)) {
            return (
                <a key={lodash.uniqueId()} target="_blank" rel="noopener noreferrer" href={item.url} className="attachment-item _attachment_item_image">
                    <img alt={item.name} src={item.url} className="img"/>
                </a>
            );
        }

        return (
            <a key={lodash.uniqueId()} target="_blank" href={item.url} rel="noopener noreferrer" className="attachment-item _attachment_item_file">
                <Icon type="paper-clip"/>{lodash.get(item, 'name')}
            </a>
        );
    };

    render() {
        const {item} = this.props;
        const chatName = lodash.get(item, 'author.fullname') || lodash.get(item, 'author.username');
        const chatTime = dateFormatter.full(item.timestamp);
        const chatAvatar = lodash.get(item, 'author.avatar', systemAvatar) || systemAvatar;
        const attachments = lodash.get(item, 'attachments', []) || [];
        return (
            <div className="a-chatbox--log user _chat_item" key={item.id}>
                <img className="img-ads _chat_avatar" src={chatAvatar}
                     alt="Log system"/>
                <div className="a-chatbox--log--detail">
                    <p className="title">{chatName}, {chatTime}</p>
                    <span className="detail">{item.content}</span>
                    {attachments.length > 0 && (
                        <div className="attachments">
                            {attachments.map(a => this.renderAttachmentItem(a))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

ChatItem.defaultProps = {
    item: {}
};

export {ChatItem}
