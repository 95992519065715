import React, { Component } from 'react';
import { Button, Layout } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';

import authService from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import { url } from './../../../../system/routing';

import International from './International';
import Tabs from './../../../Common/components/Tabs/Tabs';
import TabPane from './../../../Common/components/Tabs/TabPane';
import LastmileCarrier from './LastmileCarrier';

const { Content } = Layout;

class ListShippingPartner extends Component {
    state = {
        domestic: 0
    }
    handleChangeTab = tab => {
        url.redirectTo('config.shipping-partners.list', {
            type: tab
        });
    };

    handleCreateButtonClick = () => {
        const {
            match: { params }
        } = this.props;

        url.redirectTo('config.shipping-partners.create', {
            type: params.type
        });
    };

    componentDidMount() {
        const { setMenuActive } = this.props;

        setMenuActive('config/shipping-partners');
    }

    render() {
        const {
            match: { params },
            paginations,
            t,
        } = this.props;

        return (
            <Content className="aml-24 aml-16-lg a-content-config height-100 gflex gflex-direction-column">
                <Tabs
                    activeKey={['international', 'domestic'].includes(params.type) ? params.type : 'international'}
                    className="a-tabs a-tabs--container"
                    defaultActiveKey="international"
                    keepMounted={false}
                    rightTopBarContent={params.type === 'international' && (
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-shipping-partner"
                            type="primary"
                            onClick={this.handleCreateButtonClick}
                        >
                            {t('btn.create_new')}
                        </Button>
                    )}
                    onChange={this.handleChangeTab}
                >
                    {authService.can(permissions.DELIVERY_PARTNER_MANAGE) && (
                        <TabPane
                            key="international"
                            tab={(
                                <>
                                    {t('shipping_partner:international_shipping_partner')}
                                    <span className={clsx('ml-2', {
                                        invisible: params.type !== 'international'
                                    })}>
                                        ({get(paginations, 'international.total', 0)})
                                    </span>
                                </>
                            )}
                        >
                            <International />
                        </TabPane>
                    )}

                    {authService.can(permissions.DOMESTIC_DELIVERY_PARTNER_MANAGE) && (
                        <TabPane
                            key="domestic"
                            tab={(
                                <>
                                    {t('shipping_partner:domestic_shipping_partner')}
                                    <span className={clsx('ml-2', {
                                        invisible: params.type !== 'domestic'
                                    })}>
                                        ({this.state.domestic})
                                    </span>
                                </>
                            )}
                        >
                            <LastmileCarrier setTotal={(value) => this.setState({domestic: value })} />
                        </TabPane>
                    )}
                </Tabs>
            </Content>
        );
    }
}

export default ListShippingPartner;
