import React, {Component} from 'react';
import {Col, Button, Form, Icon, Input, Row, Select, Skeleton} from "antd";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF} from "../../constants";
import lodash from "lodash";
import { STATUS_TRANSPORT_PACKAGE } from '../../../Package/ChangePackageTransportStatus/constants';

const Option = Select.Option;
const TextArea = Input.TextArea;

class FormWarehouseStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {...DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let warehouseStaffDetail = nextProps.warehouseStaffDetail;
        let nextState = {...prevState};
        let id_staff = !lodash.isNil(warehouseStaffDetail) ? lodash.get(warehouseStaffDetail, 'user.id', 0) : 0;
        if (id_staff !== prevState.staff_id && id_staff !== 0) {
            nextState.id = warehouseStaffDetail.id;
            nextState.id_warehouses = lodash.map(warehouseStaffDetail.warehouses, 'id');
            nextState.package_status =lodash.get(warehouseStaffDetail, "package_status");
            nextState.id_user = warehouseStaffDetail.user.id;
            nextState.note = warehouseStaffDetail.user.note_warehouse_staff;
            nextState.active = warehouseStaffDetail.user.active_warehouse_staff;
            nextState.staff_id = id_staff
        }
        return nextState;
    }

    changeTextValue(event, valueInput = 0) {
        let value = event.target ? event.target.value : valueInput;
        let name = event.target ? event.target.name : event;
        this.setState({[name]: value});
        this.props.onSubmit({...this.state, [name]: value});
        if (this.props.errors[name]) {
            this.props.resetError(name);
        }
    }

    onSubmit = (values) => {
        this.setState({...values});
        this.props.onSubmit(values)
    };

    onChangeSelect(name, value) {
        this.setState({
            id_user: value,
        });
        this.props.onSubmit({...this.state, id_user: value});
        this.props.resetError(name);
    }

    onChangeSelectWarehouse(name, value) {
        let warehouses = this.props.warehouses;
        let warehouse = lodash.filter(warehouses, {name: value[0]});
        if (warehouse) {
            this.setState({
                id_warehouses: value
            });
            this.props.onSubmit({...this.state, id_warehouses: value})
        }
        this.props.resetError(name);
    }

    onChangeSelectTransportStatus(value) { 
        this.setState({
            package_status: value
        }, () => {
            this.props.onSubmit({...this.state, package_status: value})  
        });
        this.props.resetError("package_status");
    }


    handleSelectAllWarehousesButtonClick = () => {
        const { warehouses, onSubmit } = this.props;

        this.setState({
            id_warehouses: lodash.map(warehouses, 'id')
        }, () => {
            onSubmit({
                ...this.state
            });
        });
    };

    handleRemoveAllWarehousesButtonClick = () => {
        const { onSubmit } = this.props;

        this.setState({
            id_warehouses: []
        }, () => {
            onSubmit({
                ...this.state
            });
        });
    };

    componentWillUnmount() {
        const { clearState } = this.props;

        clearState();
    }

    render() {
        let {errors, t, loading, users, warehouses, warehouseStaffDetail} = this.props;
        if(loading){
            return <React.Fragment>
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group">
                        <Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group">
                        <Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                         className="a-form--group">
                        <Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
                    </Col>

                </Row>
            </React.Fragment>
        }
        return <React.Fragment>
            <Form onSubmit={this.onSubmit} className="a-form ">
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <Col className="a-form--group" lg={{span: 6}}>
                        <Form.Item className="a-form__item " label={t("warehouse_staff:label.username")}
                                   labelCol={{span: 24}} required={true}
                                   help={errors.id_user}
                                   validateStatus={errors.id_user ? 'error' : null}>
                            <Select
                                className="a-select  _a-select-user"
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={this.state.id_user === 0 ? "" : this.state.id_user}
                                disabled={lodash.keys(warehouseStaffDetail).length > 0 || loading}
                                onChange={this.onChangeSelect.bind(this, "id_user")}
                                suffixIcon={
                                    <Icon
                                        type="down"
                                        className="icon__dropdown__select"
                                        onChange={this.handleSelectChange}
                                    />}
                            >
                                {users.map((user) => <Option
                                    key={user.id}
                                    className={'_user _user-' + user.id}
                                    value={user.id}>{user.username}
                                </Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" span={24}>
                        <Form.Item
                            className="a-form__item "
                            label={t("warehouse_staff:label.warehouse")}
                            required={true}
                            help={errors.id_warehouses}
                            validateStatus={errors.id_warehouses ? 'error' : null}
                        >
                            <div className="gflex galign-center">
                                <Button
                                    className="_btn-select-all-warehouses mr-3"
                                    onClick={this.handleSelectAllWarehousesButtonClick}
                                >
                                    {t('all_warehouse')}
                                </Button>

                                <Button
                                    className="_btn-remove-all-warehouses mr-3"
                                    onClick={this.handleRemoveAllWarehousesButtonClick}
                                >
                                    {t('remove_all_warehouse')}
                                </Button>
                                <Select
                                    className="a-select _a-select-warehouse"
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={loading}
                                    value={this.state.id_warehouses}
                                    onChange={this.onChangeSelectWarehouse.bind(this, "id_warehouses")}
                                    mode="multiple"
                                    suffixIcon={
                                        <Icon
                                            type="down"
                                            className="icon__dropdown__select"
                                            onChange={this.handleSelectChange}
                                        />}
                                >
                                    {warehouses.map((warehouse) => <Option
                                        key={warehouse.id}
                                        className={"_a-warehouse _warehouse-" + warehouse.id}
                                        value={warehouse.id}>{warehouse.name}
                                    </Option>)}
                                </Select>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" span={24}>
                        <Form.Item
                            className="a-form__item "
                            label={t("warehouse_staff:label.transport_status")}
                            help={errors.package_status}
                            validateStatus={errors.package_status ? 'error' : null}
                        >
                            <div className="gflex galign-center"> 
                            <Select
                                allowClear
                                className="a-select a-select--search-content _a-select-status-transport-package"
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder={t('package:label.status_transport')}
                                showSearch={true}
                                value={this.state.package_status}
                                onChange={this.onChangeSelectTransportStatus.bind(this)}
                            >
                                {lodash.values(STATUS_TRANSPORT_PACKAGE).map(action => action !== STATUS_TRANSPORT_PACKAGE.CIRCULATE && (
                                    <Option
                                        className={`_a-select-status-transport-${action}`}
                                        key={action}
                                        value={action}
                                    >
                                        {t(`package:status_transport.${action}`)}
                                    </Option>
                                ))}
                        </Select>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                         className="a-form--group">
                        <Form.Item className="a-form__item  " label={t("warehouse_staff:label.note")}
                                   labelCol={{span: 24}}>
                            < TextArea className="a-input--textarea _a-input-note"
                                       autosize={{minRows: 1, maxRows: 3}}
                                       allowClear={true}
                                       spellCheck={false}
                                       name="note"
                                       value={this.state.note}
                                       onChange={this.changeTextValue.bind(this)}
                                       placeholder={"Ghi chú"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>

    }
}

FormWarehouseStaff.defaultProps = {
    errors: {},
    loading: false,
    users: [],
    warehouses: [],
    warehouseStaffDetail: {}
};

FormWarehouseStaff.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    warehouseStaffDetail: PropTypes.object,
    warehouses: PropTypes.array
};

export default withTranslation()(FormWarehouseStaff);
