import React, { Component } from 'react';
import { connect } from 'react-redux';

import apiService from './../modules/Location/apiService';
import { setCountries, setProvinces, setDistricts, setWards } from './../modules/Location/actions';

const mapStateToProps = ({ location }) => {
    const {
        countries,
        provinces,
        districts,
        wards
    } = location;

    return {
        countries,
        provinces,
        districts,
        wards
    };
};

const withLocations = WrappedComponent => {
    return connect(
        mapStateToProps,
        {
            setCountries,
            setProvinces,
            setDistricts,
            setWards
        }
    )(class extends Component {
        state = {
            loadingCountries: false,
            loadingProvinces: 0,
            loadingDistricts: false,
            loadingWards: false,
            vietNamProvinces: []
        };

        getCountries = async () => {
            const { loadingCountries } = this.state;
            const { countries, setCountries } = this.props;
            let newCountries = countries;

            if (!loadingCountries && !countries.length) {
                this.setState({
                    loadingCountries: true
                });

                try {
                    const response = await apiService.fetchCountry(0);
                    newCountries = response.data.locations;

                    setCountries(newCountries);
                } catch (error) {
                }

                this.setState({
                    loadingCountries: false
                });
            }

            return newCountries;
        };

        getProvinces = async countryId => {
            const { provinces, setProvinces } = this.props;

            if (!countryId) {
                return [];
            }

            if (provinces[countryId]) {
                return provinces[countryId];
            }

            this.setState(prevState => ({
                loadingProvinces: prevState.loadingProvinces + 1
            }));

            try {
                const response = await apiService.fetchCountry(countryId);

                const newProvinces = {
                    ...provinces,
                    [countryId]: response.data.locations
                };

                setProvinces(newProvinces);

                this.setState(prevState => ({
                    loadingProvinces: prevState.loadingProvinces - 1
                }));

                return response.data.locations;
            } catch (error) {
                this.setState(prevState => ({
                    loadingProvinces: prevState.loadingProvinces - 1
                }));
            }
        };

        getDistricts = async provinceId => {
            const { districts, setDistricts } = this.props;

            if (!provinceId) {
                return [];
            }

            if (districts[provinceId]) {
                return districts[provinceId];
            }

            this.setState(prevState => ({
                loadingDistricts: prevState.loadingDistricts + 1
            }));

            try {
                const response = await apiService.fetchCountry(provinceId);

                const newDistricts = {
                    ...districts,
                    [provinceId]: response.data.locations
                };

                setDistricts(newDistricts);

                this.setState(prevState => ({
                    loadingDistricts: prevState.loadingDistricts - 1
                }));

                return response.data.locations;
            } catch (error) {
                this.setState(prevState => ({
                    loadingDistricts: prevState.loadingDistricts - 1
                }));
            }
        };

        getWards = async districtId => {
            const { wards, setWards } = this.props;

            if (!districtId) {
                return [];
            }

            if (wards[districtId]) {
                return wards[districtId];
            }

            this.setState(prevState => ({
                loadingWards: prevState.loadingWards + 1
            }));

            try {
                const response = await apiService.fetchCountry(districtId);

                const newWards = {
                    ...wards,
                    [districtId]: response.data.locations
                };

                setWards(newWards);

                this.setState(prevState => ({
                    loadingWards: prevState.loadingWards - 1
                }));

                return response.data.locations;
            } catch (error) {
                this.setState(prevState => ({
                    loadingWards: prevState.loadingWards - 1
                }));
            }
        };

        getVietNamProvinces = async () => {
            const countries = await this.getCountries();
            const vietNamCountry = countries.find(country => country.location.code === 'vietnam');

            if (vietNamCountry) {
                const vietNamProvinces = await this.getProvinces(vietNamCountry.location.id);

                this.setState({
                    vietNamProvinces
                });
            }
        };

        render() {
            const { loadingProvinces, loadingDistricts, loadingWards, vietNamProvinces } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    loadingDistricts={loadingDistricts}
                    loadingProvinces={loadingProvinces}
                    loadingWards={loadingWards}
                    vietNamProvinces={vietNamProvinces}
                    getCountries={this.getCountries}
                    getDistricts={this.getDistricts}
                    getProvinces={this.getProvinces}
                    getVietNamProvinces={this.getVietNamProvinces}
                    getWards={this.getWards}
                />
            );
        }
    });
};

export default withLocations;
