import { takeLatest } from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(ACTION.ADD.REQUEST, saga.add);
    yield takeLatest(ACTION.ADD.SUCCESS, saga.successAdd);
    yield takeLatest(ACTION.ADD.FAILED, saga.failedAdd);
}
