/* eslint-disable */
import React from 'react';
import {Button, Checkbox, Dropdown, Icon, Pagination, Select, Table, Menu, Drawer, Row, Col, Tabs} from "antd";
import ListRequestDeliveryNote from './ListRequestDeliveryNote';

const TabPane = Tabs.TabPane;

const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiểm kê </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian kiểm kê</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho kiểm kê</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian đối soát</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thất lạc </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nhập kho</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đã xử lý</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Chờ xử lý</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Chưa xử lý</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người kiểm kê</Checkbox>
        </Menu.Item>
    </Menu>
);

class ListDeliveryNoteTab extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list action-inventory-history mt-0">
                    <div className="a-content--page-list__top-action " id="content-list">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>Thiết lập cột thông tin
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <Tabs defaultActiveKey="1" className="a-tabs a-tabs--inventory-history">
                    <TabPane tab="Mới (68593)" key="1">
                        <ListRequestDeliveryNote/>
                    </TabPane>
                    <TabPane tab="Đang xử lý (43232)" key="2">
                        <ListRequestDeliveryNote/>
                    </TabPane>
                    <TabPane tab="Đã xử lý (43293)" key="3">
                        <ListRequestDeliveryNote/>
                    </TabPane>
                    <TabPane tab="Huỷ (89595) " key="4"> <ListRequestDeliveryNote/></TabPane>
                </Tabs>
            </React.Fragment>
        );
    }
}

export default ListDeliveryNoteTab;