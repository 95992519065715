import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Form, Input, Select } from 'antd'
import lodash from 'lodash'
import UpdateWeightRealBagContainer from '../../UpdateWeightRealBag/UpdateWeightRealBagContainer'
import CompletePackingBagContainer from '../../CompletePackingBag/CompletePackingBagContainer'

const { Option } = Select
class BagPackingAction extends React.Component {
    state = {
        id: 0,
        code_package: '',
        weight_real: 0,
        request_id: 1,
        packageCodeInputRef: createRef(),
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = { ...state }
        if (!lodash.isEmpty(props.bag) && props.bag.id !== state.id) {
            nextState.id = props.bag.id
            nextState.weight_real = props.bag.weight_real
        }

        return nextState
    }

    changeInput(event) {
        this.setState({ [event.target.name]: event.target.value.trim() })
    }

    onPackingPackage = () => {
        if (this.state.code_package !== '') {
            this.props.packingPackage({
                code_package: this.state.code_package,
                id: this.props.bag.id,
                request_id: this.state.request_id,
                stage: 'PACKING_BAG',
                packing_barcode_type: this.props.typeScan,
            })

            this.setState(state => {
                return {
                    request_id: state.request_id + 1,
                    code_package: '',
                }
            })
        }
    }

    onChangeInputWeightReal(value) {
        value = value || null
        let currentValue = this.state.weight_real || null
        if (currentValue !== value) {
            this.setState({ weight_real: value })
        }
    }

    componentDidUpdate(prevProps) {
        const { packageCodeInputRef,  } = this.state
        const { failedPackedPackageCount } = this.props

        if (failedPackedPackageCount !== prevProps.failedPackedPackageCount) {
            packageCodeInputRef.current.blur()
        }
    }

    render() {
        const { packageCodeInputRef,  } = this.state
        const { canUpdate, t, loading, bag, packingData, typeScan, handleChangeType } = this.props
        const weightReal = lodash.get(bag, 'weight_real', 0)

        return (
            <div className="bag-pack--block">
                <Form className="a-form a-form--sider-in-bag-pack ">
                    <Form.Item
                        className="a-form__item item--bag-pack label-special "
                        label={t('scan:label.barcode_type')}
                        labelCol={{ span: 24 }}>
                        <Select
                            value={typeScan}
                            onChange={handleChangeType}>
                            <Option value="package_code">{t('bag:packing.code_package')}</Option>
                            <Option value="bag_last_mile_freight_bill">{t('bag:packing.freight_bill_lastmile')}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        className="a-form__item item--bag-pack label-special "
                        label={typeScan === 'bag_last_mile_freight_bill' ? t('bag:packing.freight_bill_lastmile') : t('bag:label.scan_package')}
                        labelCol={{ span: 24 }}>
                        <Input
                            onPressEnter={this.onPackingPackage}
                            disabled={loading || Object.keys(bag).length === 0}
                            name={'code_package'}
                            onChange={this.changeInput.bind(this)}
                            className="a-input a-select--auto-complete--bag-pack _input_package_code"
                            ref={packageCodeInputRef}
                            value={this.state.code_package}
                        />
                    </Form.Item>

                    <UpdateWeightRealBagContainer
                        bag={bag}
                        canUpdate={canUpdate}
                        loading={loading}
                        onChangeInputWeightReal={this.onChangeInputWeightReal.bind(this)}
                    />
                    <CompletePackingBagContainer
                        bag={bag}
                        disabled={weightReal === 0 || packingData.length === 0 || bag.packing_complete === true}
                        loading={loading}
                    />
                </Form>
            </div>
        )
    }
}

BagPackingAction.defaultProps = {
    bag: {},
    packingData: [],
    loading: false,
    errors: {},
}

BagPackingAction.propTypes = {
    loading: PropTypes.bool,
    bag: PropTypes.object,
    packingPackage: PropTypes.func,
    errors: PropTypes.object,
    packingData: PropTypes.array,
}

export default withTranslation()(BagPackingAction)
