import {combineReducers} from 'redux';
import page from './Page/reducer';
import * as COMMON_ACTION from './constants';
import * as PRINT_ACTION from './PrintStamp/constants';
import log from './Log/reducer';

const menuActive = (state = '', action) => {
    const {type} = action;

    switch (type) {
        case COMMON_ACTION.SET_MENU_ACTIVE:
            return action.payload;
        default:
            return state;
    }
};

/**
 * state phuc vu phan in tem. State nay chua cac propeties sau: {packageData, warehouseCode, typePrint, numberStamp}
 * @param state
 * @param action
 * @returns {{}}
 */
const dataPrint = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case PRINT_ACTION.PRINT_PACKAGE_STAMP: {
            return {
                packageData: action.payload.packageData,
                warehouseCode: action.payload.warehouseCode,
                typePrint: PRINT_ACTION.TYPE_PRINT.PACKAGE,
                numberStamp: action.payload.numberStamp,
            };
        }
        case PRINT_ACTION.PRINT_MULTI_PACKAGE_STAMP: {
            return {
                ...action.payload,
                packageData: action.payload.packageData,
                warehouseCode: action.payload.warehouseCode,
                typePrint: action.payload.typePrint,
                items: action.payload.items,
                stamp_type: action.payload.stamp_type
            };
        }
        case PRINT_ACTION.CLEAR_PRINT_STATE: {
            return {};
        }
        case PRINT_ACTION.PRINT_ISSUE: {
            return {
                typePrint: PRINT_ACTION.TYPE_PRINT.ISSUE,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_FBU_STAMP: {
            return {
                typePrint: PRINT_ACTION.TYPE_PRINT.FBU_STAMP,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_DELIVERY_NOTE_RETURN: {
            return {
                typePrint: PRINT_ACTION.TYPE_PRINT.DELIVERY_NOTE_RETURN,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
            return {
                typePrint: PRINT_ACTION.TYPE_PRINT.DELIVERY_NOTE_TRANSPORT_WAREHOUSE,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_OFFICIAL_PACKAGE_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_OFFICIAL_PACKAGE_STAMP,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_PACKAGE_UNOFFICIAL_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_PACKAGE_UNOFFICIAL_STAMP,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_BAG_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_BAG_STAMP,
                ...action.payload
            };
        }
        case PRINT_ACTION.PRINT_IFRAME: {
            return {
                typePrint: PRINT_ACTION.PRINT_IFRAME,
                ...action.payload
            }
        }
        case PRINT_ACTION.PRINT_BATCH_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_BATCH_STAMP,
                ...action.payload
            }
        }

        case PRINT_ACTION.PRINT_9710_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_9710_STAMP,
                ...action.payload
            }
        }
        case PRINT_ACTION.PRINT_WAREHOUSE_AREA_STAMP: {
            return {
                typePrint: PRINT_ACTION.PRINT_WAREHOUSE_AREA_STAMP,
                ...action.payload
            }
        }
        default: {
            return state;
        }
    }
};

const loadingPrintTemplate = (state = false, action) => {
    const {type} = action;

    switch (type) {
        case PRINT_ACTION.LOAD_PRINT_TEMPLATE: {
            return true;
        }
        case PRINT_ACTION.FINISH_LOAD_PRINT_TEMPLATE: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    page,
    menuActive,
    dataPrint,
    log,
    loadingPrintTemplate
});
