import {url} from '../../../system/routing';
import lodash from "lodash";

export function* onFetchDetailCustomerSuccess(action) {
    if (action.request.from === 'SELECT_DELIVERY_CUSTOMER') {
        yield url.redirectTo('delivery-notes.customer.create', null, {
            ...action.request.params,
            id_customer: lodash.get(action, 'payload.customer.id',0)
        });
    }
}
