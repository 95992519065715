import React, { Component } from 'react'
import { Alert, Col, Form, Modal, Select } from 'antd'
import { find, map } from 'lodash'
import { withTranslation } from 'react-i18next'

import apiService from '../../OfficialForm/apiService'
import SelectLanguage from '../../OfficialForm/components/SelectLanguage'

const { Option } = Select

class ExportManifestsModal extends Component {
    state = {
        officialForms: [],
        selectedForm: undefined,
    }

    componentDidMount() {
        this.getOfficialFormList()
    }

    getOfficialFormList = async () => {
        try {
            const response = await apiService.suggest()
            const { data } = response

            const selectedForm = find(data, officialForm => officialForm.name === 'otong')

            this.setState({
                officialForms: data,
                selectedForm,
            })
        } catch (error) {}
    }

    handleCancel = () => {
        const { officialForms } = this.state
        const { onCancel } = this.props

        const selectedForm = find(officialForms, officialForm => officialForm.name === 'otong')

        this.setState({
            selectedForm,
        })

        onCancel()
    }

    handleSelectOfficialForm = form => {
        const { officialForms } = this.state

        const selectedForm = find(officialForms, officialForm => officialForm.name === form)

        this.setState({
            selectedForm,
        })
    }

    handleSelectLanguage = language => {
        const { selectedForm } = this.state
        const { onExport } = this.props

        onExport(selectedForm.name, language)
    }

    render() {
        const { officialForms, selectedForm } = this.state
        const { exporting, t, visible } = this.props

        return (
            <Modal
                footer={null}
                title={t('btn.export_manifest_file')}
                visible={visible}
                onCancel={this.handleCancel}>
                {selectedForm ? (
                    <Alert
                        className="mb-6"
                        message={t('official_form:package_export_manifest_list_warning', {
                            form: selectedForm.name
                        })}
                        type="warning"
                    />
                ) : null}
                <div className="a-block-config__title--block-child amb-16">
                    <span className="title">{t('official_form:select_official_form_template')}</span>
                </div>
                <div className="a-block-info">
                    <Col>
                        <Form.Item
                            className="a-form__item"
                            label={t('official_form:official_form_template')}>
                            <Select
                                className="width-100-pc"
                                placeholder={t('official_form:select_official_form_template')}
                                value={selectedForm && selectedForm.name}
                                onChange={this.handleSelectOfficialForm}>
                                {map(officialForms, officialForm => (
                                    <Option
                                        key={officialForm.name}
                                        value={officialForm.name}>
                                        {officialForm.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </div>

                {selectedForm ? (
                    <SelectLanguage
                        languages={selectedForm.manifest}
                        loading={exporting}
                        onSelectLanguage={this.handleSelectLanguage}
                    />
                ) : null}
            </Modal>
        )
    }
}

export default withTranslation()(ExportManifestsModal)
