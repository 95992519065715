import React, { Children, cloneElement, Component } from 'react';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

class Tabs extends Component {
    state = {
        activeKey: this.props.activeKey || this.props.defaultActiveKey,
        inkBarStyle: {}
    };

    componentDidMount() {
        const { activeKey } = this.state;

        if (this.refs[activeKey]) {
            this.setState({
                inkBarStyle: {
                    display: 'block',
                    transform: `translate3d(${this.refs[activeKey].offsetLeft}px, 0px, 0px)`,
                    width: this.refs[activeKey].offsetWidth
                }
            });
        }
    }

    handleChangeTab = tab => {
        const { onChange } = this.props;

        this.setState(prevState => ({
            activeKey: tab,
            inkBarStyle: {
                ...prevState.inkBarStyle,
                transform: `translate3d(${this.refs[tab].offsetLeft}px, 0px, 0px)`,
                width: this.refs[tab].offsetWidth
            }
        }));

        if (isFunction(onChange)) {
            onChange(tab);
        }
    };

    render() {
        const { activeKey, inkBarStyle } = this.state;
        const { className, children, keepMounted, rightTopBarContent } = this.props;

        return (
            <div className={clsx('ant-tabs ant-tabs-top ant-tabs-line ant-tabs-no-animation custom-tab', className)}>
                <div className="ant-tabs-bar ant-tabs-top-bar">
                    <div className="ant-tabs-nav-container">
                        <div className="ant-tabs-nav-wrap">
                            <div className="gflex galign-center gjustify-space-between">
                                <div className="ant-tabs-nav-scroll">
                                    <div className="ant-tabs-nav ant-tabs-nav-animated">
                                        <div>
                                            {Children.map(children, child => {
                                                if (!child) {
                                                    return null;
                                                }

                                                return (
                                                    <div
                                                        className={clsx('ant-tabs-tab', {
                                                            'ant-tabs-tab-active': activeKey === child.key
                                                        })}
                                                        key={child.key}
                                                        ref={child.key}
                                                        onClick={this.handleChangeTab.bind(undefined, child.key)}
                                                    >
                                                        {child.props.tab}
                                                    </div>
                                                );
                                            })}

                                            <div
                                                className="ant-tabs-ink-bar ant-tabs-ink-bar-no-animated"
                                                style={inkBarStyle}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="apr-16">
                                    {rightTopBarContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ant-tabs-content ant-tabs-content-no-animated ant-tabs-top-content">
                    {Children.map(children, child => {
                        if (!child || (child.key !== activeKey && !keepMounted)) {
                            return null;
                        }

                        return cloneElement(child, {
                            className: child.key === activeKey ? 'ant-tabs-tabpane-active' : 'ant-tabs-tabpane-inactive'
                        });
                    })}
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    keepMounted: PropTypes.bool
};

Tabs.defaultProps = {
    keepMounted: true
};

export default Tabs;
