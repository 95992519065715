import React, { PureComponent } from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchShippingUserSuggest } from '../List/actions.js';
import { shippingUsersSuggestActive } from './selectors';

import SelectShippingUser from './components';

const mapStateToProps = (state, props) => {
    return {
        users: shippingUsersSuggestActive(state),
        loading: lodash.get(props, 'loading', false) || lodash.get(state, 'shippingUser.loading'),
    };
};

const mapDispatchToProps = {
    fetchShippingUserSuggest
};

class SelectCreateShippingUserContainer extends PureComponent {
    componentDidMount() {
        if (!this.props.users.length) {
            this.props.fetchShippingUserSuggest();
        }
    }

    render() {
        return (
            <SelectShippingUser {...this.props} />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(SelectCreateShippingUserContainer);

Container.defaultProps = {
    autoSelect: false,
    onChange: () => {
    },
};

Container.propTypes = {
    autoSelect: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default Container;
