import React from 'react';
import { Form } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import BagStorageFees from './components';

const BagStorageFeesContainer = props => {
    return (
        <BagStorageFees
            {...props}
        />
    );
};

export default withTranslation()(withRouter(Form.create()(BagStorageFeesContainer)));
