import { Form, Icon, Layout, Spin, Switch } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from '../../../system/routing'
import { setMenuActive } from '../../Common/actions'
import apiServices from '../apiServices'
import EditForm from './EditForm'

const mapStateToProps = (state, props) => {
    return {}
}

const Content = Layout.Content

const TemplateBagEditLastmile = ({ t, match }) => {
    const [detail, setDetail] = useState({})
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)

    const { id } = match.params

    useEffect(() => {
        const fetchDetail = () => {
            setLoading(true)
            apiServices
                .fetchDetailTemplateBagsLastmile(id)
                .then(res => {
                    setDetail(get(res, 'data.bag_lastmile_template', {}))
                    setActive(get(res, 'data.bag_lastmile_template.active', false))
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false)
                })
        }

        fetchDetail()
    }, [])

    return (
        <Spin spinning={loading}>
            <Content className={'aml-16-lg aml-24 amr-24 amr-16-lg a-content-config '}>
                <div className="a-breadcrumb">
                    <Link
                        to="config.template-bags-lastmile.list"
                        className={'a-text--gray _btn-back'}>
                        <Icon
                            type="arrow-left"
                            className={'mr-2 '}
                        />
                        {t('template_bag:back_to_list')}
                    </Link>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t('template_bag:info')}</h3>
                        <div>
                            <label className={'a-text--fz-12 a-text--gray mr-2'}>{t('template_bag:status')}</label>
                            <Switch
                                checked={active}
                                size="small"
                                className={'_switch-active'}
                                onChange={checked => setActive(checked)}
                            />
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <EditForm active={active} detail={detail} idBag={id}/>
                    </div>
                </div>
            </Content>
        </Spin>
    )
}
export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(TemplateBagEditLastmile)
)
