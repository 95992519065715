import {combineReducers} from 'redux';
import * as DELETE_ACTION from './constants';

let isProcessing = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case DELETE_ACTION.BEGIN_CANCEL_DELIVERY_NOTE:
            return true;
        case DELETE_ACTION.CANCEL_DELIVERY_NOTE.SUCCESS:
        case DELETE_ACTION.CANCEL_DELIVERY_NOTE.FAILED:
        case DELETE_ACTION.END_CANCEL_DELIVERY_NOTE:
            return false;
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case DELETE_ACTION.CANCEL_DELIVERY_NOTE.REQUEST:
            return true;
        case DELETE_ACTION.CANCEL_DELIVERY_NOTE.SUCCESS:
        case DELETE_ACTION.CANCEL_DELIVERY_NOTE.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    isProcessing,
    loading
});
