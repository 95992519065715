import React, {memo} from 'react';
import { Icon } from 'antd';
import { withTranslation } from 'react-i18next';

const ErrorItem = ({ item, index, t }) => {
	const errorMessage = t('inventory:error.exists');

	return (
		<tbody key={index} className="error">
			<tr className="data">
				<td>{index + 1}</td>
				<td>{item.inventory_tracking_bill.id}</td>
				<td>--</td>
				<td>--</td>
			</tr>
			<tr className="message">
				<td colSpan="4">
					<span className="a-text--black-45">
						<Icon type="close-circle" theme="filled" className="a-text--red mr-2" />
						{errorMessage}
					</span>
				</td>
			</tr>
		</tbody>
	);
};

export default memo(withTranslation()(ErrorItem));
