import {message} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import loading from '../../Common/services/loading';
import * as ACTION from './constants';
import apiService from '../apiService';

export function* login(action) {
    loading.open();
    yield processApiRequest(ACTION.LOGIN, () => apiService.login(action.payload), action.payload);
}

export function* onLoginSuccess(action) {
    loading.close();
    yield window.location = action.payload.url;
}

export function* onLoginFailed() {
    loading.close();
    yield message.error(t('message.server_connection_error'), 0);
}
