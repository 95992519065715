import lodash from 'lodash';
import * as ACTION from './constants';
import * as ACTION_HISTORY from './../ScanHistory/constants';
import apiService from './../apiService';
import {processApiRequest} from '../../Common/saga';
import authService from './../../Auth/authService';
import {dispatch} from './../../../system/store';
import {fetchScanHistory} from './../ScanHistory/actions';
import settingHistory from './settingHistory';
import {notification} from "antd";
import {t} from "../../../system/i18n";
import React from "react";

export function* scanBarcode(action) {
    yield processApiRequest(ACTION.SCAN_BARCODE_FORM, () => apiService.scanBarcode(action.payload), action.payload);

    settingHistory.addItemsToDataHistory({
        ...action.payload,
        ...{
            is_loading: true,
            success: true
        }
    });

    setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanBarcodeFailed(action) {
    let code_error = null;
    let barcode_scanning = lodash.get(action, 'payload.barcode_scanning', {});
    let request_id = lodash.get(action, 'request.request_id', null);
    let exists_warehouse = lodash.get(action, 'payload.data.id_warehouse_scan', {});
    let listDataLocalStorage = settingHistory.getDataHistory();
    let listDataLocalStorageNew = [];
    let error = lodash.get(action, 'payload.data');
    if (action.payload === false || typeof action.payload === 'undefined' || lodash.get(error, 'warehouse')) {
        listDataLocalStorage.shift();
        listDataLocalStorageNew = [...listDataLocalStorage];
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });

    } else {
        listDataLocalStorageNew = listDataLocalStorage.map(listDataLocalStorageItem => {
            if (listDataLocalStorageItem.request_id === request_id) {
                if (!lodash.isEmpty(exists_warehouse)) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_WAREHOUSE_NOT_EXIST
                    }];
                }

                if (lodash.get(error, 'status_action.value_invalid')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_STATUS_ACTION.VALUE_INVALID
                    }];
                }

                if (lodash.get(error, 'barcode.exists')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_BARCODE_NOT_EXIST
                    }];
                }

                if (lodash.get(error, 'barcode.more_than_one_process_shipment_package')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE
                    }];
                }

                if (lodash.get(error, 'barcode.not_shipment_package')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_NOT_SHIPMENT_PACKAGE
                    }];
                }

                if (lodash.get(error, 'barcode.package_not_weight')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_PACKAGE_NOT_WEIGHT
                    }];
                }

                if (lodash.get(error, 'barcode.scan_completed_bag')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_SCAN_COMPLETED_BAG
                    }];
                }

                if (lodash.get(error, 'barcode.scan_uncompleted_packing_bag')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_SCAN_UNCOMPLETED_PACKING_BAG
                    }];
                }

                if (lodash.get(action.payload, 'data.id_warehouse_destination.required') || lodash.get(action.payload, 'data.id_warehouse_destination.exists')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_REQUIRED_WAREHOUSE_DESTINATION
                    }];
                }

                if (lodash.get(error, 'barcode.message')) {
                    const message = lodash.get(error, 'barcode.message').toUpperCase()
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_MESSAGE[message],
                        packages: lodash.get(error, "barcode.packages", [])
                    }];
                }

                barcode_scanning = {
                    ...barcode_scanning, ...{code_error},
                    is_loading: false,
                    success: false
                };
                listDataLocalStorageItem = {...listDataLocalStorageItem, ...barcode_scanning};
            }
            return listDataLocalStorageItem;
        });
    }
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanBarcodeSuccess(action) {
    const bag = lodash.get(action, 'payload.bag', {});
    const barcodeScanning = lodash.get(action, 'payload.barcode_scanning', {});
    const notes = lodash.get(action, 'payload.notes', []) || [];
    const notices = lodash.get(action, 'payload.notices', []) || [];
    const requestId = lodash.get(action, 'request.request_id', null);
    const customer = lodash.get(action, 'payload.customer', null);
    const packageRoutings = lodash.get(action, "payload.package_routings", {})

    const listDataLocalStorage = settingHistory.getDataHistory();
    const listDataLocalStorageNew = listDataLocalStorage.map(listDataLocalStorageItem => {
        if (listDataLocalStorageItem.request_id === requestId) {
            listDataLocalStorageItem = {
                ...listDataLocalStorageItem,
                ...{
                    ...barcodeScanning,
                    ...{
                        is_loading: false
                    }
                },
                bag,
                notes,
                notices,
                customer,
                packageRoutings
            };
        }
        return listDataLocalStorageItem;
    });
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceived(action) {
    yield processApiRequest(ACTION.SCAN_RECEIVED, () => apiService.scanReceive(action.payload), action.payload);

    settingHistory.addItemsToDataHistory({
        ...action.payload,
        is_loading: true,
        success: true
    });

    setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceivedSuccess(action) {
    const barcodeScanning = lodash.get(action, 'payload.barcode_scanning', {});
    const notes = lodash.get(action, 'payload.notes', []) || [];
    const notices = lodash.get(action, 'payload.notices', []) || [];
    const requestId = lodash.get(action, 'request.request_id', null);
    const listDataLocalStorage = settingHistory.getDataHistory();
    const listDataLocalStorageNew = listDataLocalStorage.map(listDataLocalStorageItem => {
        if (listDataLocalStorageItem.request_id === requestId) {
            listDataLocalStorageItem = {
                ...listDataLocalStorageItem,
                ...{
                    ...lodash.omit(barcodeScanning, 'code_error'),
                    is_loading: false
                },
                notes,
                notices
            };
        }
        return listDataLocalStorageItem;
    });
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceivedFailed(action) {
    const listDataLocalStorage = settingHistory.getDataHistory();
    const error = lodash.get(action, 'payload.data');
    let listDataLocalStorageNew = [];

    if (action.payload === false || typeof action.payload === 'undefined' || lodash.get(error, 'warehouse')) {
        listDataLocalStorage.shift();
        listDataLocalStorageNew = [...listDataLocalStorage];
        yield notification.error({
            message: (
                <span className="_scan_package_failed">{t('message.warehouse_not_is_staff')}</span>
            )
        });

    }
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceivedBag(action) {
    yield processApiRequest(ACTION.SCAN_RECEIVED_BAG, () => apiService.scanReceiveBag(action.payload), action.payload);

    settingHistory.addItemsToDataHistory({
        ...action.payload,
        ...{
            is_loading: true,
            success: true
        }
    });

    setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceivedBagSuccess(action) {
    const bag = lodash.get(action, 'payload.bag', {});
    const barcodeScanning = lodash.get(action, 'payload.barcode_scanning', {});
    const notes = lodash.get(action, 'payload.notes', []) || [];
    const notices = lodash.get(action, 'payload.notices', []) || [];
    const requestId = lodash.get(action, 'request.request_id', null);
    const customer = lodash.get(action, 'payload.customer', null);

    const listDataLocalStorage = settingHistory.getDataHistory();
    const listDataLocalStorageNew = listDataLocalStorage.map(listDataLocalStorageItem => {
        if (listDataLocalStorageItem.request_id === requestId) {
            listDataLocalStorageItem = {
                ...listDataLocalStorageItem,
                ...{
                    ...barcodeScanning,
                    ...{
                        is_loading: false
                    }
                },
                bag,
                notes,
                notices,
                customer
            };
        }
        return listDataLocalStorageItem;
    });
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}

export function* scanReceivedBagFailed(action) {
    let code_error = null;
    let barcode_scanning = lodash.get(action, 'payload.barcode_scanning', {});
    let request_id = lodash.get(action, 'request.request_id', null);
    let exists_warehouse = lodash.get(action, 'payload.data.id_warehouse_scan', {});
    let listDataLocalStorage = settingHistory.getDataHistory();
    let listDataLocalStorageNew = [];
    let error = lodash.get(action, 'payload.data');

    if (action.payload === false || typeof action.payload === 'undefined' || lodash.get(error, 'warehouse')) {
        listDataLocalStorage.shift();
        listDataLocalStorageNew = [...listDataLocalStorage];
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });

    } else {
        listDataLocalStorageNew = listDataLocalStorage.map(listDataLocalStorageItem => {
            if (listDataLocalStorageItem.request_id === request_id) {
                if (!lodash.isEmpty(exists_warehouse)) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_WAREHOUSE_NOT_EXIST
                    }];
                }

                if (lodash.get(error, 'status_action.value_invalid')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_STATUS_ACTION.VALUE_INVALID
                    }];
                }

                if (lodash.get(error, 'barcode.exists')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_BARCODE_NOT_EXIST
                    }];
                }

                if (lodash.get(error, 'bag_code.exists')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_BARCODE_NOT_EXIST
                    }];
                }

                if (lodash.get(error, 'barcode.more_than_one_process_shipment_package')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE
                    }];
                }

                if (lodash.get(error, 'barcode.not_shipment_package')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_NOT_SHIPMENT_PACKAGE
                    }];
                }

                if (lodash.get(error, 'barcode.package_not_weight')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_PACKAGE_NOT_WEIGHT
                    }];
                }

                if (lodash.get(error, 'barcode.scan_completed_bag')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_SCAN_COMPLETED_BAG
                    }];
                }

                if (lodash.get(error, 'barcode.scan_uncompleted_packing_bag')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_SCAN_UNCOMPLETED_PACKING_BAG
                    }];
                }

                if (lodash.get(action.payload, 'data.id_warehouse_destination.required') || lodash.get(action.payload, 'data.id_warehouse_destination.exists')) {
                    code_error = [{
                        code: ACTION_HISTORY.ERROR_REQUIRED_WAREHOUSE_DESTINATION
                    }];
                }

                barcode_scanning = {
                    ...barcode_scanning, ...{code_error},
                    is_loading: false,
                    success: false
                };
                listDataLocalStorageItem = {...listDataLocalStorageItem, ...barcode_scanning};
            }
            return listDataLocalStorageItem;
        });
    }
    settingHistory.updateDataHistory(listDataLocalStorageNew);
    yield setTimeout(() => dispatch(fetchScanHistory({user_id: authService.user('id')})), 50);
}
