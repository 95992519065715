import React from 'react';
import PropTypes from 'prop-types';
import historySettingService from './historySettingService';
import HistorySetting from './components/HistorySetting';

class HistorySettingContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            setting: [],
        };
    }

    componentDidMount() {
        let setting = historySettingService.get();
        this.updateSetting(setting);
    }

    onChangeSetting(setting) {
        historySettingService.set(setting);
        this.updateSetting(setting);
    }
    
    updateSetting(setting) {
        this.setState({setting});
        this.props.onChange(setting);
    }

    render() {
        return <HistorySetting
            options={historySettingService.options}
            values={this.state.setting}
            onChange={this.onChangeSetting.bind(this)}
        />
    }
}

HistorySettingContainer.defaultProps = {
    onChange: (setting) => {},
};

HistorySettingContainer.propTypes = {
    onChange: PropTypes.func,
};

export default HistorySettingContainer;

