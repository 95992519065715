import { Form, notification } from 'antd'
import { get, isNil } from 'lodash'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import InputNumber from '../../../Common/components/InputNumber'
import apiService from '../../../TemplateBag/apiService'

const UpdateValue = ({ form, loading, disabled, bag, setBag, keyName, keyMax, notCore }) => {
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const { getFieldDecorator } = form
    const [errors, setErrors] = useState({})

    const onPressEnterUpdate = (name, value) => {
        setBag({ ...bag, [name]: name === 'weight_real' ? value : value / 100 })
        if (!value) {
            setErrors({
                [name]: t('validation:required', {
                    attribute: t(`template_bag:label.${name.includes('_real') ? name : name + '_real'}`),
                }),
            })
            return
        } else {
            const maxVal = !isNil(get(bag, keyMax)) ? get(bag, keyMax) * 100 : get(bag, keyMax, 999999999)

            if (+value > maxVal) {
                setErrors({
                    [name]: t(`bag:lastmile.message.out_range_value`, { attribute: t(`bag:lastmile.label.${name}`) }),
                })
                return
            } else {
                setErrors({
                    [name]: undefined,
                })
            }
        }

        setLoadingUpdate(true)

        apiService
            .updateBagLastmile(bag.id, { [name]: name === 'weight_real' ? value : value / 100 })
            .then(result => {
                setBag({ ...bag, [name]: get(result, `data.bag_lastmile.${name}`) })
                notification.success({ message: t('message.save_change_success') })
            })
            .catch(err => {
                notification.error({ message: t('message.save_change_fail') })
            })
            .finally(() => setLoadingUpdate(false))
        // })
    }

    const returnMaxValue = name => {
        return !isNil(get(bag, name)) ? get(bag, name) : 99999999
    }

    const returnInitValue = keyName => {
        if (notCore) {
            return get(bag, keyName)
        }

        return !isNil(get(bag, keyName)) ? get(bag, keyName) * 100 : undefined
    }

    return (
        <Form form={form}>
            <Form.Item
                className="a-form__item item--bag-pack mb-4"
                label={t(`template_bag:label.${keyName.includes('_real') ? keyName : keyName + '_real'}`)}
                labelCol={{ span: 24 }}
                required
                help={get(errors, keyName)}
                validateStatus={get(errors, keyName) ? 'error' : undefined}>
                {getFieldDecorator(keyName, {
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: t('validation:required', {
                    //             attribute: t('template_bag:label.length_real'),
                    //         }),
                    //     },
                    //     // {
                    //     //     max: returnMaxValue('length_max'),
                    //     //     message: 'Vượt quá chiều dài tối đa',
                    //     // },
                    // ],
                    initialValue: returnInitValue(keyName),
                })(
                    <InputNumber
                        placeholder={t(`template_bag:label.${keyName.includes('_real') ? keyName : keyName + '_real'}`)}
                        loading={loadingUpdate}
                        disabled={loading || disabled || loadingUpdate}
                        min={0}
                        // max={returnMaxValue(keyMax)}
                        name={keyName}
                        onPressEnter={e => onPressEnterUpdate(keyName, e.target.value)}
                        className="a-input a-input--w-100 _input_bag_weight"
                        // value={get(inputVal, keyName)}
                        // onChange={value => onChangeValue(keyName, value)}
                    />
                )}
            </Form.Item>
        </Form>
    )
}

export default Form.create()(UpdateValue)
