import {api} from '../../../system/api';

export default {

	/**
	 * Quét kiện mã kiểm kê
	 * @param code
	 * @returns {AxiosPromise<any> | IDBRequest<IDBValidKey> | Promise<void>}
	 */
	detailInventory: (id) => api.get(`/inventories/` + id)
};
