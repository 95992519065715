import { combineReducers } from 'redux';

import { GET_ACTIONS } from './constants';

const actions = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_ACTIONS.SUCCESS: {
            return payload;
        }
        default: {
            return state;
        }
    }
};

const loadingActions = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case GET_ACTIONS.REQUEST: {
            return true;
        }
        case GET_ACTIONS.SUCCESS:
        case GET_ACTIONS.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    actions,
    loadingActions
});
