import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import lodash from 'lodash'
import { Button, Empty, Icon, Table, Tooltip, Typography, notification } from 'antd'
import { Link } from './../../../../system/routing'
import Pagination from './../../../Common/components/Pagination'
import { dateFormatter } from '../../../Common/services/format'
import ListPackageSetting from '../ListPackageSettingContainer'
import { LIST_PACKAGE_OBJECT, PACKAGE_COLUMN } from '../constants'
import authService from '../../../Auth/authService'
import PERMISSION from '../../../Auth/permissions'
import { convertBlobDataToExcel, getVar, showAliasAgency } from '../../../../system/support/helpers'
import { getState } from '../selectors'
import permissions from '../../../Auth/permissions'
import ExportManifestsModal from '../../components/ExportManifestsModal'
import { handleExportManifestsError, isExportManifestToEmail } from '../../helper'
import apiService from '../../apiService'

const { Paragraph } = Typography
class ListPackage extends React.PureComponent {
    state = {
        activeColumns: [],
        selectedPackages: [],
    }

    getClassNameIdentifier(column) {
        switch (column) {
            case PACKAGE_COLUMN.CODE:
                return 'a-text--nowrap _package_column_code'
            case PACKAGE_COLUMN.ORDER:
                return '_package_column_order'
            default:
                return '_row' + column
        }
    }

    getColumns() {
        let arr = [PACKAGE_COLUMN.CREATE_TIME, PACKAGE_COLUMN.WEIGHT, PACKAGE_COLUMN.VOLUME]
        let activeColumns = this.state.activeColumns
        return lodash
            .values(PACKAGE_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    key: column,
                    dataIndex: column,
                    title: this.props.t('package:column.' + column),
                    className: this.getClassNameIdentifier(column),
                }
                if (arr.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                    }
                }

                return res
            })
    }

    getDataSource() {
        let { t } = this.props
        const showAlias = showAliasAgency(getState('setting.agencySetting'), getState('setting.setting'), permissions.CONFIG_SHOW_AGENCY_ALIAS)
        return this.props.packages.map(packageData => ({
            key: packageData.id,
            [PACKAGE_COLUMN.CODE]: (
                <span className="a-text--nowrap _code">
                    <Link
                        isNewTab
                        params={{ id: packageData.code }}
                        to="packages.detail">
                        {packageData.code}
                    </Link>
                </span>
            ),
            [PACKAGE_COLUMN.DAMAGED_REASONS]: (
                <span className="a-text--nowrap _damaged-reasons">
                    {lodash.get(packageData, 'damaged_reasons.length') ? packageData.damaged_reasons.join(', ') : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.BARCODE]: <span className="a-text--nowrap _barcode">{lodash.get(packageData, 'barcode') || '--'}</span>,
            [PACKAGE_COLUMN.BAG_CODE]: <span className="a-text--nowrap _code-bag">{lodash.get(packageData, 'code_bag') || '--'}</span>,
            [PACKAGE_COLUMN.NOTE]: lodash.get(packageData, 'note') ? (
                <Tooltip title={lodash.get(packageData, 'note')}>
                    <Paragraph
                        ellipsis={true}
                        style={{ width: 120 }}>
                        {lodash.get(packageData, 'note')}{' '}
                    </Paragraph>
                </Tooltip>
            ) : (
                '--'
            ),
            [PACKAGE_COLUMN.CREATE_TIME]: <span className="a-text--nowrap _created_at">{dateFormatter.dateTime(packageData.created_at)}</span>,
            [PACKAGE_COLUMN.ORDER_LINKED_AT]: (
                <span className="a-text--nowrap _order-linked-at">
                    {lodash.get(packageData, 'order_linked_at') ? dateFormatter.dateTime(packageData.order_linked_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.TRANSPORTING_AT]: (
                <span className="a-text--nowrap _transporting-at">
                    {lodash.get(packageData, 'transporting_at') ? dateFormatter.dateTime(packageData.transporting_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.READY_FOR_DELIVERY_AT]: (
                <span className="a-text--nowrap _ready-for-delivery-at">
                    {lodash.get(packageData, 'ready_for_delivery_at') ? dateFormatter.dateTime(packageData.ready_for_delivery_at) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.STATE]: (
                <span className="a-text--nowrap _status_warehouse">
                    {packageData.status_warehouse ? t('package:action.' + packageData.status_warehouse) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.STATUS_TRANSPORT]: (
                <span className="a-text--nowrap _status_transport">
                    {packageData.status_transport ? t('package:status_transport.' + packageData.status_transport) : '--'}
                </span>
            ),
            [PACKAGE_COLUMN.ORDER]: (
                <>
                    <span className="a-text--nowrap _order">
                        {lodash.get(packageData, 'code_order') ? (
                            <a
                                className="info--value a-text--blue"
                                href={lodash.get(packageData, 'm2_url_order') || '#top'}
                                rel="noopener noreferrer"
                                target={lodash.get(packageData, 'm2_url_order') ? '_blank' : '_self'}>
                                {lodash.get(packageData, 'code_order', '--')}
                            </a>
                        ) : (
                            '--'
                        )}
                    </span>
                    {lodash.get(packageData, 'agency_identity_code') && (
                        <span className="a-text--nowrap a-text--gray a-text--fz-12">({lodash.get(packageData, 'agency_identity_code', '--')})</span>
                    )}
                </>
            ),
            [PACKAGE_COLUMN.AGENCY]: (
                <span className="a-text--nowrap _agency">
                    {lodash.get(packageData, 'agency_data.name', '--')}{' '}
                    {lodash.get(packageData, 'agency_data.identify_name') && showAlias ? `(${lodash.get(packageData, 'agency_data.identify_name')})` : null}
                </span>
            ),
            [PACKAGE_COLUMN.USERNAME]: <span className="a-text--nowrap _username">{lodash.get(packageData, 'username_customer', '--') || '--'}</span>,
            [PACKAGE_COLUMN.PROPERTIES]: <span className="a-text--nowrap _properties">{lodash.get(packageData, 'properties', []).join(', ') || '--'}</span>,
            [PACKAGE_COLUMN.PRINTED_OFFICIAL_STAMP]: (
                <span className="a-text--nowrap _printed-official-stamp">
                    {t(`package:printed_official_stamp.${!!lodash.get(packageData, 'is_printed_official_stamp')}`)}
                </span>
            ),
            [PACKAGE_COLUMN.BOX_SERVICES]: (
                <span className="a-text--nowrap _box-services">{lodash.get(packageData, 'box_services', []).join(', ') || '--'}</span>
            ),
            [PACKAGE_COLUMN.SHIPPING_SERVICES]: (
                <span className="a-text--nowrap _shipping-services">{lodash.get(packageData, 'shipping_services', []).join(', ') || '--'}</span>
            ),
            [PACKAGE_COLUMN.EXTEND_SERVICES]: (
                <span className="a-text--nowrap _extend-services">{lodash.get(packageData, 'extend_services', []).join(', ') || '--'}</span>
            ),
            [PACKAGE_COLUMN.INPUT_WAREHOUSE]: <span className="a-text--nowrap _warehouse_input">{lodash.get(packageData, 'code_warehouse_input', '--')}</span>,
            [PACKAGE_COLUMN.CURRENT_WAREHOUSE]: (
                <span className="a-text--nowrap _current_warehouse">{lodash.get(packageData, 'code_warehouse_current', '--')}</span>
            ),
            [PACKAGE_COLUMN.DESTINATION_WAREHOUSE]: (
                <span className="a-text--nowrap _destination_warehouse">{lodash.get(packageData, 'code_warehouse_destination', '--')}</span>
            ),
            [PACKAGE_COLUMN.SHIPPING_PARTNER]: <span className="a-text--nowrap _shipping-partner">{lodash.get(packageData, 'shipping_partner', '--')}</span>,
            [PACKAGE_COLUMN.WEIGHT]: <span className="a-text--nowrap _weight_net">{packageData.weight_net ? packageData.weight_net + ' kg' : '--'}</span>,
            [PACKAGE_COLUMN.VOLUME]: <span className="a-text--nowrap _volume">{packageData.volume ? packageData.volume + ' m3' : '--'}</span>,
            [PACKAGE_COLUMN.IS_CHECKED]: lodash.get(packageData, 'is_checked') ? t('package:label.checked') : t('package:label.unchecked'),
            [PACKAGE_COLUMN.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_CODE]: getVar(packageData, 'delivery_note_transport_warehouses', []).join(", "),
        }))
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            })
        }
    }

    onChange(pagination, filters, sorter) {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        })
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            })
        }
    }

    exportPackageList = () => {
        const { selectedPackages } = this.state
        const { filter, exportPackageList } = this.props

        exportPackageList({
            ...filter,
            id_packages: selectedPackages,
        })
    }

    downloadTransportStatuses = () => {
        const { selectedPackages } = this.state
        const { onDownloadTransportStatuses } = this.props

        onDownloadTransportStatuses(selectedPackages)
    }

    selectRow = selectedRowKeys => {
        this.setState({
            selectedPackages: selectedRowKeys,
        })
    }

    componentDidUpdate(prevProps) {
        const { filter } = this.props
        const omittedFilterFields = ['i', 'page', 'per_page', 'sort_by', 'sort_direction']

        if (!lodash.isEqual(lodash.omit(filter, omittedFilterFields), lodash.omit(prevProps.filter, omittedFilterFields))) {
            this.setState({
                selectedPackages: [],
            })
        }
    }

    exportManifest = () => {
        this.setState({
            exportManifestModalVisible: true,
        })
    }

    exportManifests = async (officialForm, language) => {
        const { selectedPackages } = this.state
        const { filter, t } = this.props
        this.setState({
            exportingManifests: true,
        })

        try {
            const response = await apiService.exportManifests({
                ...filter,
                id_packages: selectedPackages,
                language,
                official_form: officialForm,
            })
            const reader = new FileReader()
            let message = t('message.export_manifest.success')

            reader.addEventListener('loadend', event => {
                const text = event.target.result

                try {
                    const data = JSON.parse(text)

                    if (isExportManifestToEmail(data)) {
                        message = t('message.export_manifest.success_to_mail')
                    } else {
                        convertBlobDataToExcel(response.data, `export_manifests_${language}`)
                    }

                    notification.success({
                        message,
                    })
                } catch (e) {
                    convertBlobDataToExcel(response.data, `export_manifests_${language}`)

                    notification.success({
                        message,
                    })
                }
            })

            reader.readAsText(response.data)
        } catch (error) {
            handleExportManifestsError(error)
        }

        this.setState({
            exportingManifests: false,
        })
    }

    onCloseExportManifestModal = () => {
        this.setState({
            exportManifestModalVisible: false,
        })
    }

    render() {
        const { selectedPackages, exportingManifests, exportManifestModalVisible } = this.state
        const { downloadingTransportStatus, filter, loading, object, pagination, t } = this.props
        const canExportPackageList = authService.can(PERMISSION.EXPORT_PACKAGES_LIST)
        const canDownloadTransportStatuses = authService.can(PERMISSION.PACKAGE_CHANGE_TRANSPORT_STATUS)
        const canExportManifest = authService.can(PERMISSION.EXPORT_MANIFEST)
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3>
                            {t('package:label.list')} ({lodash.get(pagination, 'total', 0)})
                        </h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        {object === LIST_PACKAGE_OBJECT.NOT_IN_BAG && canExportManifest && (
                            <>
                                <Button
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                                    ghost
                                    type="primary"
                                    onClick={this.exportManifest}>
                                    {t('bag:btn.export_manifest_file')}
                                </Button>
                                <ExportManifestsModal
                                    exporting={exportingManifests}
                                    visible={exportManifestModalVisible}
                                    onCancel={this.onCloseExportManifestModal}
                                    onExport={this.exportManifests}
                                />
                            </>
                        )}
                        {canDownloadTransportStatuses ? (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                                ghost
                                loading={downloadingTransportStatus}
                                type="primary"
                                onClick={this.downloadTransportStatuses}>
                                {t('btn.export_excel_package_status')}
                            </Button>
                        ) : null}

                        {canExportPackageList && (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                                ghost
                                loading={loading}
                                type="primary"
                                onClick={this.exportPackageList}>
                                {t('btn.export_excel')}
                            </Button>
                        )}

                        <ListPackageSetting
                            onChange={activeColumns => this.setState({ activeColumns })}
                            object={object}
                            loading={loading}
                            isOpenListSetting={this.props.isOpenListSetting}
                            onOpenListSetting={this.props.onOpenListSetting}
                            isCurrentPage={this.props.isCurrentPage}
                        />
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <div className="a-table--responsive">
                        <Table
                            className="a-table--page-list _a-table--page-list"
                            columns={this.getColumns()}
                            dataSource={this.getDataSource()}
                            loading={loading}
                            locale={{
                                emptyText: loading ? (
                                    <Icon
                                        type="loading-3-quarters"
                                        className="icon-loading a-text--black "
                                    />
                                ) : (
                                    <Empty
                                        className="_empty"
                                        description={<span>Không có dữ liệu </span>}
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"></Empty>
                                ),
                            }}
                            pagination={false}
                            rowClassName={(record, index) => index.toString()}
                            rowKey={record => record.key}
                            rowSelection={{
                                selectedRowKeys: selectedPackages,
                                onChange: this.selectRow,
                            }}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        pageSizeOptions={[20, 50, 100, 200]}
                        total={pagination.total}
                        loading={this.props.loading}
                        onChange={this.onChangePage.bind(this)}
                    />
                </div>
            </React.Fragment>
        )
    }
}

ListPackage.defaultProps = {
    filter: {},
    loading: false,
    packages: [],
    pagination: {},
    onSearch: () => {},
}

ListPackage.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    packages: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
}

export default withTranslation()(ListPackage)
