/* eslint-disable */

import React from 'react';
import DeliveryNoteEmployee from './components/Employee';
class DeliveryNOteDetail extends React.Component{
    render() {
        return(
           <DeliveryNoteEmployee/>
        )
    }
}
export  default DeliveryNOteDetail;