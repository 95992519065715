import {combineReducers} from 'redux';
import auth from './modules/Auth/reducer';
import packageReducer from './modules/Package/reducer';
import warehouse from './modules/Warehouse/reducer';
import customer from './modules/Customer/reducer';
import common from './modules/Common/reducer';
import setting from './modules/Setting/reducer';
import location from './modules/Location/reducer';
import service from './modules/Service/reducer';
import property from './modules/Property/reducer';
import order from './modules/Order/reducer';
import scanBarcodeForm from './modules/ScanBarcode/ScanBarcodeForm/reducer';
import scanHistory from './modules/ScanBarcode/ScanHistory/reducer';
import barcode from './modules/Barcode/reducer';
import user from './modules/User/reducer';
import bag from './modules/Bag/reducer';
import bagSize from './modules/BagSize/reducer';
import deliveryNote from './modules/DeliveryNote/reducer';
import deliveryRequest from './modules/DeliveryRequest/reducer';
import shippingPartner from './modules/ShippingPartner/reducer'
import configWarehouse from './modules/WarehouseConfig/reducer'
import shippingUser from './modules/ShippingUser/reducer';
import templateBag from './modules/TemplateBag/reducer'
import sound from './modules/Sound/reducer'
import inventory from './modules/Inventory/reducer'
import notes from './modules/Notes/reducer';
import boxSize from './modules/BoxSize/reducer';
import noticeStage from './modules/NoticeStage/reducer';
import shippingFee from './modules/ShippingFee/reducer';
import agencies from './modules/Agencies/reducer';

const rootReducer = combineReducers({
    deliveryNote,
    auth,
    setting,
    service,
    order,
    common,
    'package': packageReducer,
    warehouse,
    location,
    property,
    customer,
    scanHistory,
    scanBarcodeForm,
    barcode,
    user,
    bag,
    bagSize,
    shippingPartner,
    boxSize,
    shippingUser,
    deliveryRequest,
    sound,
    inventory,
    templateBag,
	configWarehouse,
    notes,
    noticeStage,
    shippingFee,
    agencies
});

export default rootReducer;
