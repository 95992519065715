import { combineReducers } from 'redux';

import { ADD_RE_EXECUTION, GET_RE_EXECUTIONS } from './constants';

const loading = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case GET_RE_EXECUTIONS.REQUEST: {
            return true;
        }
        case GET_RE_EXECUTIONS.SUCCESS:
        case GET_RE_EXECUTIONS.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const pagination = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_RE_EXECUTIONS.REQUEST: {
            return {
                total: state.total || 0,
                ...action.payload
            };
        }
        case GET_RE_EXECUTIONS.SUCCESS: {
            return {
                ...state,
                ...payload.pagination
            };
        }
        default: {
            return state;
        }
    }
};

const reExecutions = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_RE_EXECUTIONS.SUCCESS: {
            return payload.process_shipping_fees;
        }
        default: {
            return state;
        }
    }
};

const adding = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ADD_RE_EXECUTION.REQUEST: {
            return true;
        }
        case ADD_RE_EXECUTION.SUCCESS:
        case ADD_RE_EXECUTION.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    loading,
    pagination,
    reExecutions,
    adding
});
