import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import { currentFormatter } from './../../../Common/services/format';
import { Link } from './../../../../system/routing';
import { formatWeight } from './../../../../system/support/helpers';

import DeliveryNoteExport from './../../DeliveryNoteExport/DeliveryNoteExportContainer';

const { Text, Title } = Typography;

const TopContent = ({ customer, deliveryNote, domesticShippingInfo, className, remainingPackages, t, onCreate, onSuccessExportDeliveryNote }) => {
    return (
        <section className={clsx('top-content', className)}>
            <Row gutter={16} type="flex">
                <Col lg={{ span: 19 }}>
                    <div className="a-tile height-100">
                        <div className="a-tile__header gflex galign-center gjustify-space-between">
                            <Title className="a-text--fz-14 mb-0 apy-3" level={3}>
                                {t('delivery_note_info')}
                                {get(deliveryNote, 'delivery_note.code') ? (
                                    <>
                                        <span className="mx-2">-</span>
                                        <Link
                                            params={{
                                                id: deliveryNote.delivery_note.id
                                            }}
                                            to="delivery-notes.customer.detail"
                                        >
                                            {deliveryNote.delivery_note.code}
                                        </Link>
                                    </>
                                ) : null}
                            </Title>
                            {!!get(deliveryNote, 'permissions.can_export') && (
                                <DeliveryNoteExport
                                    bagTotal={get(deliveryNote, 'delivery_note_bags.length')}
                                    deliveryNoteData={get(deliveryNote, 'delivery_note')}
                                    packageTotal={get(deliveryNote, 'delivery_note_packages.length')}
                                    onSuccessExportDeliveryNote={onSuccessExportDeliveryNote}
                                />
                            )}
                        </div>
                        <div className="a-tile__body">
                            <Row className="gjustify-space-between" type="flex">
                                <Col>
                                    <Text type="secondary">{t('shipping_partner')}</Text>
                                    <br />
                                    <Text strong>
                                        {get(deliveryNote, 'last_mile_carrier.name', "--")}
                                    </Text>
                                </Col>
                                <Col>
                                    <Text type="secondary">{t('customer')}</Text>
                                    <br />
                                    <Text strong>
                                        {get(domesticShippingInfo, 'customer.full_name')} ({get(domesticShippingInfo, 'customer.username')})
                                    </Text>
                                </Col>
                                <Col>
                                    <Text type="secondary">{t('phone_number')}</Text>
                                    <br />
                                    <Text strong>{get(domesticShippingInfo, 'customer.phone')}</Text>
                                </Col>
                                <Col>
                                    <Text type="secondary">
                                        {t('amount_balance')}
                                    </Text>
                                    <br />
                                    <Text strong>
                                        {currentFormatter.toLocaleStringCurrency(get(customer, 'customer_finance.amount_remaining'))} VNĐ
                                    </Text>
                                </Col>
                                <Col>
                                    <Text type="secondary">
                                        {t('total_package')}
                                    </Text>
                                    <br />
                                    <Text strong>{get(domesticShippingInfo, 'package_number')}</Text>
                                </Col>
                                <Col>
                                    <Text type="secondary">
                                        {t('total_weight')}
                                    </Text>
                                    <br />
                                    <Text strong>{formatWeight(get(domesticShippingInfo, 'total_weight'), 2)}</Text>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col lg={{ span: 5 }}>
                    <div className="a-tile height-100">
                        <div className={clsx('a-tile__header gflex galign-center', {
                            'gjustify-space-between': remainingPackages.length,
                            'gjustify-center': !remainingPackages.length
                        })}>
                            <Text strong>
                                {t('remaining_packages')}
                            </Text>
                            {remainingPackages.length ? (
                                <Button
                                    className="apx-16"
                                    size="small"
                                    type="primary"
                                    onClick={onCreate}
                                >
                                    {t('create_order')}
                                </Button>
                            ) : null}
                        </div>

                        <div className="a-tile__body gflex galign-center gjustify-center">
                            <Text className="a-text--fz-36 a-text--line-height-1" strong>
                                {remainingPackages.length}
                            </Text>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default withTranslation()(TopContent);
