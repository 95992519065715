import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../../system/i18n';


export function* deletePackage(action) {
    yield processApiRequest(ACTION.DELETE_PACKAGE, () => apiService.deletePackage(action.payload.bagId, action.payload.packingId), action.payload);
}

export function* deletePackageFailed(action) {
    if (action.payload) {
        yield  notification['error']({
            message: t('bag:message.delete_failed'),
        });
    }
}

export function* deletePackageSuccess() {
    yield  notification['success']({
        message: t('bag:message.delete_success'),
    });
}


export function* removePackage(action) {
    yield processApiRequest(ACTION.REMOVE_PACKAGE, () => apiService.removePackage(action.payload.bagId, action.payload.packageId), action.payload);
}

export function* removePackageFailed(action) {
    if (action.payload) {
        yield  notification['error']({
            message: t('bag:message.delete_failed'),
        });
    }
}

export function* removePackageSuccess() {
    yield  notification['success']({
        message: t('bag:message.delete_success'),
    });
}
