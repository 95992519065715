import * as ACTION from './constants';
import apiService from './apiService';
import {processApiRequest} from '../../Common/saga';
import * as actions from "./actions";
import {put} from 'redux-saga/effects';

export function* showDetailDeliveryRequest(action) {
    yield put(actions.fetchDetailDeliveryRequest(action.payload));
}
export function* hideDetailDeliveryRequest() {
    yield put(actions.clearState());
}
export function* fetchDetailDeliveryRequest(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_DELIVERY_REQUEST, () => apiService.getDetailDeliveryRequest(action.payload), action.payload);
}