import {connect} from 'react-redux';
import PrintStamp from './components/PrintStamp';
import lodash from 'lodash';

import {clearPrintState} from './../../Common/PrintStamp/actions';

const mapStateToProps = (state) => {
    return {
        dataPrint: lodash.get(state, 'common.dataPrint', {})
    };
};

const mapDispatchToProps = {
    clearPrintState,
    // fetchSetting
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintStamp)