import React from 'react';
import { connect } from 'react-redux';

import { setActiveSuggestKeys } from './../../../actions';
import { getState } from './../../../selectors';

import DeliveryNoteList from './components';

const DeliveryNoteListContainer = props => (
    <DeliveryNoteList
        {...props}
    />
);

const mapStateToProps = state => {
    return {
        activeSuggestKeys: getState(state, 'suggests.activeSuggestKeys'),
        creatings: getState(state, 'creatings'),
    };
};

const mapDispatchToProps = {
    setActiveSuggestKeys
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryNoteListContainer);
