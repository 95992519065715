import React from 'react';
import { connect } from 'react-redux';

import { getAgencies } from './../../../../Agencies/actions';
import { getState } from './../../../../Agencies/selectors';

import Agencies from './components';

const AgenciesContainer = props => {
    return (
        <Agencies {...props} />
    );
};

const mapStateToProps = state => {
    return {
        agencies: getState(state, 'agencies'),
        loading: getState(state, 'loading')
    };
};

const mapDispatchToProps = {
    getAgencies
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgenciesContainer);
