import {processApiRequest} from "../../Common/saga";
import * as ACTION from "./constants";
import apiService from "./apiService";
import * as lodash from "lodash";
import React from "react"
import {router} from "../../../system/routing";
import {t} from "../../../system/i18n"
import {Modal, notification} from "antd"
import {INVENTORY_STATUS_CHECKING} from "../constants";

export function handleOnOk(code_error) {
	switch (code_error.status) {
		case INVENTORY_STATUS_CHECKING :
			router.redirect("/inventory/scan/" + code_error.inventory);
			break;
		default :
			router.redirect(`/inventory/` + code_error.inventory + '/process');
			break
	}
}

export function handleOnClose() {

}

export function* createInventoryRequest(action) {
	yield processApiRequest(ACTION.CREATE_INVENTORY, () => apiService.createInventory(action.payload), action.payload);
}

export function* createInventorySuccess(action) {
	yield router.redirect("/inventory/scan/" + lodash.get(action, 'payload.inventory.code', 0));
}

export function* createInventoryFailed(action) {
	let error = lodash.get(action, 'payload.data');
	if (lodash.get(error, 'warehouse')) {
		return yield notification['error']({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}
	let code_error = lodash.get(action, 'payload.data.id_warehouse.inventory_is_open_in_warehouse', '');
	if (code_error !== '') {
		return yield  Modal.confirm({
			title: t("label.warning"),
			content:
				<p>{t("inventory:message" + (code_error.status === INVENTORY_STATUS_CHECKING ? '.inventory_is_open_in_warehouse' : '.checking_inventory_is_open_in_warehouse'))}</p>,
			okText: t("btn.ok"),
			cancelText: t("btn.cancel"),
			onOk: () => handleOnOk(code_error),
			onClose: () => handleOnClose(),
		});
	}
}
