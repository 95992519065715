import get from 'lodash/get';
import includes from 'lodash/includes';
import { combineReducers } from 'redux';

import * as ACTION from './constants';
import { SCAN_TYPE } from './../../Barcode/constants';
import { STAGES, UPDATE_NOTE } from './../../Notes/constants';
import settingHistory from './../ScanBarcodeForm/settingHistory';


const listData = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_SCAN_BARCODE_HISTORY.REQUEST: {
            return settingHistory.getDataHistory();
        }
        case ACTION.CLEAR_SCAN_BARCODE_HISTORY.REQUEST: {
            return [];
        }
        case UPDATE_NOTE.SUCCESS: {
            if (get(payload, 'stage') !== STAGES.SCANNING_PACKAGE) {
                return state;
            }

            const newState = state.map(item => {
                if (item.type_scan !== SCAN_TYPE.BARCODE_SCAN) {
                    return item;
                }

                const index = (item.notes || []).findIndex(note => note.id === get(payload, 'note.id'));

                if (index === -1) {
                    return state;
                }

                const isRemoveNote = !includes(get(payload, 'note.stages'), STAGES.SCANNING_PACKAGE);
                const newNotes = isRemoveNote ? [
                    ...item.notes.slice(0, index),
                    ...item.notes.slice(index + 1)
                ] : [
                    ...item.notes.slice(0, index),
                    {
                        ...item.notes[index],
                        ...get(payload, 'note')
                    },
                    ...item.notes.slice(index + 1)
                ];

                return {
                    ...item,
                    notes: newNotes
                };
            });

            settingHistory.updateDataHistory(newState);

            return newState;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    listData,
});
