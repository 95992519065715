import {processApiRequest} from "../../Common/saga";
import * as ACTION from '../constants';
import apiService from '../apiService';
import {notification} from "antd";
import {router} from "../../../system/routing";
import {t} from '../../../system/i18n';

export function* createWarehouseStaff(action) {
    yield processApiRequest(ACTION.CREATE_WAREHOUSE_STAFF, () => apiService.createWarehouseStaff(action.payload), action.payload);
}

export function* createWarehouseStaffSuccess() {
    yield notification['success']({
        message: t('common:message.create_warehouse_staff_success')
    });
    yield router.redirect('/config/warehouse-staff');
}