import React, { Component } from 'react'
import { Layout, Row, Checkbox } from 'antd'
import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import ScanBarcode from './ScanBarcode'
import ChooseWarehouse from './../../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'
import { BARCODE_TYPE } from '../../../DeliveryNoteBarcode/constants'

const { Sider } = Layout

class FormDeliveryNote extends Component {
    render() {
        const { deliveryNote, t, input, onChange, scanBarcode } = this.props

        return (
            <Sider className={`a-sider-sub a-sider-sub--radius`}>
                <div className="a-sider-sub__block mt-5">
                    <h4 className="a-sider-sub__block__title">{t('delivery_note:label.warehouse')}</h4>
                    <Row
                        gutter={8}
                        className={'a-form--group-button--warehouse'}>
                        <ChooseWarehouse
                            isCustomWarehouse
                            isPermission
                            value={isNaN(input.id_warehouse) ? 0 : input.id_warehouse}
                            active={true}
                            disabled={deliveryNote || !isNil(input.id_shipping_partner)}
                            onChange={id_warehouse => onChange('id_warehouse', id_warehouse)}
                        />
                    </Row>
                </div>

                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">{t('delivery_note:label.scan_barcode')}</h4>
                    <div className="a-sider-sub__block pl-2">
                        <Checkbox
                            disabled={isNil(input.id_shipping_partner)}
                            checked={input.barcode_type === BARCODE_TYPE.BAG}
                            className="_checkbox-input-bag"
                            onChange={() => onChange('barcode_type', BARCODE_TYPE.BAG)}>
                            {t('bag')}
                        </Checkbox>
                        <Checkbox
                            disabled={isNil(input.id_shipping_partner)}
                            checked={input.barcode_type === BARCODE_TYPE.PACKAGE}
                            className="_checkbox-input-package"
                            onChange={() => onChange('barcode_type', BARCODE_TYPE.PACKAGE)}>
                            {t('package')}
                        </Checkbox>
                    </div>

                    <ScanBarcode
                        canScan={!isNil(input.id_shipping_partner)}
                        value={input.code}
                        scanBarcode={scanBarcode}
                        onChange={code => onChange('code', code)}
                    />
                </div>
            </Sider>
        )
    }
}

FormDeliveryNote.defaultProps = {
    input: {},
    deliveryNote: null,
    onChange: () => {},
}

FormDeliveryNote.propTypes = {
    input: PropTypes.object,
    deliveryNote: PropTypes.any,
    onChange: PropTypes.func,
}

export default withTranslation()(FormDeliveryNote)
