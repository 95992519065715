import React, { Component, createRef } from 'react'
import { Button, Layout, Modal, notification, Row, Spin, Switch } from 'antd'
import { find, get, isEmpty, map, omit, round } from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import apiService from './../apiService'
import { fetchSuggestBoxSizes } from './../../BoxSize/actions'
import { getLoading, getSuggestBoxSizes } from './../../BoxSize/selectors'
import pageService from './../../Common/Page/pageService'
import { getErrors } from './../../../system/support/helpers'

import PackageList from './PackageList'
import CheckingPackageLogs from './../CheckingPackage/components/CheckingPackageLogs'
import { printBatchStamp, printOfficialPackageStamp, printPackageStamp } from '../../Common/PrintStamp/actions'
import { t, trans } from '../../../system/i18n'
import { router } from '../../../system/routing'
import AdjustSuggest from '../SplitPackage/AdjustSuggest'
import apiServices from '../../ShippingPartner/DetailShippingPartner/apiServices'
import scanAutoService from '../ScanSplitPackage/ScanService'
import moment from 'moment'
import Attentions from '../../Common/components/Attentions'

const { Content } = Layout

class SplitPackage extends Component {
    state = {
        detachSuggest: {},
        loading: false,
        packages: [],
        stateProgress: 1,
        originPackage: {},
        groupByProduct: false,
        adjustNum: 0,
        loadingDetachAuto: false,
        countPackaged: 0,
        packageCreatedIds: [],
        packageSplitting: 1,
        attentions: [],
    }

    updatePageTitle = () => {
        const { t } = this.props

        pageService.setTitle(t('package:detail.split_package'), this.state.loading ? <Spin /> : null)
    }

    getDetachSuggest = async (updateOriginPackageOnly = false, notChangeAdjust, params = {}, notCall) => {
        const {
            match: {
                params: { id },
            },
            fetchSuggestBoxSizes,
        } = this.props
        const { detachSuggest: detachSuggestData } = this.state

        this.setState({
            loading: true,
        })

        const urlParams = new URLSearchParams(window.location.search)
        const myParam = { without_origin: urlParams.get('without_origin') === 'true', number: urlParams.get('number') }
        const withoutOrigin = urlParams.get('without_origin') === 'true'

        try {
            const response = await apiService.getDetachSuggest(id, { ...myParam, ...params })

            const detachSuggest = response.data
            const formattedPackageItems = map(get(detachSuggest, 'package_items', []), packageItem => omit(packageItem, 'unit_price'))
            const services = get(detachSuggest, 'services', [])
            const orderItems = map(
                map(get(detachSuggest, 'order_items', []), packageItem => omit(packageItem, 'unit_price')),
                item => ({ ...item, received_quantity: item.purchase_quantity, name_zh: item.original_name })
            )
            const attentions = get(detachSuggest, 'attentions', [])

            if (!isEmpty(services)) {
                fetchSuggestBoxSizes({ id_services: map(services, 'id'), only_active: true })
            }

            this.setState(prevState => {
                const { packages } = prevState
                const originPackage = {
                    ...detachSuggest.package,
                    items: !isEmpty(formattedPackageItems) ? formattedPackageItems : orderItems,
                    length: round(get(detachSuggest, 'package.length', 0) * 100, 6),
                    width: round(get(detachSuggest, 'package.width', 0) * 100, 6),
                    height: round(get(detachSuggest, 'package.height', 0) * 100, 6),
                    type: 'origin',
                    weight: get(detachSuggest, 'package.weight_net'),
                    permissions: get(detachSuggest, 'permissions'),
                    required_contents: get(detachSuggest, 'required_contents', []),
                }
                let newPackages = packages

                if (updateOriginPackageOnly) {
                    newPackages = [originPackage, ...prevState.packages.slice(1)]
                } else {
                    newPackages = [originPackage, ...detachSuggest.suggested_packages]
                }

                if (get(detachSuggestData, 'suggested_packages', []).length !== get(detachSuggest, 'suggested_packages').length) {
                    if (!notCall) {
                        const firstPackage = get(newPackages, '0')
                        const packageItems = get(detachSuggest, 'package_items', [])

                        apiServices
                            .fetchDetailShippingPartner(get(firstPackage, 'id_shipping_partner'))
                            .then(result => {
                                const maxValueShipping = get(result, 'data.shipping_partner.max_value_package') || 0

                                const underValueItem = find(packageItems, item => item.unit_price > maxValueShipping)
                                if (!isEmpty(underValueItem)) {
                                    Modal.warning({
                                        title: '',
                                        content: trans('package:message.warning_under_unit_price', {
                                            code_item: <b>{get(underValueItem, 'code_item', '')}</b>,
                                        }),
                                        okText: t('btn.close'),
                                    })
                                }
                            })
                            .catch(err => {})
                    }
                    this.handleChangeLoadingDetachAuto(false)
                }
                if (notChangeAdjust) {
                    return {
                        detachSuggest,
                        originPackage,
                        packages: newPackages,
                        countPackaged: withoutOrigin ? newPackages.length - 1 : newPackages.length,
                        attentions,
                    }
                } else {
                    return {
                        detachSuggest,
                        originPackage,
                        packages: newPackages,
                        adjustNum: get(detachSuggest, 'suggested_packages').length,
                        countPackaged: withoutOrigin ? newPackages.length - 1 : newPackages.length,
                        attentions,
                    }
                }
            })
        } catch (error) {
            const code = get(error, 'response.data.code')
            const data = get(error, 'response.data.data')
            if (code === 'INPUT_INVALID') {
                Object.entries(data).forEach(([key, value]) => {
                    notification.error({
                        message: t(`package:create_shipment_order.errors.${key}.${Object.keys(value)[0]}`),
                    })
                })
            }
            this.setState({
                detachSuggest: {},
            })
        }

        this.setState({
            loading: false,
        })
    }

    addPackage = async (index, data) => {
        const  { groupByProduct } = this.state
        const {
            match: {
                params: { id },
            },
            t,
        } = this.props

        try {
            const timeData = {
                name: 'fill_input',
                before_api_call: {
                    timestamp: moment().unix(),
                    time_date: moment().format('L h:mm:ss.SSS'),
                },
                after_api_call: '',
                state_process: {
                    before: '',
                    after: '',
                },
            }

            const response = await apiService.detach(id, {...data, group_product_detach: +groupByProduct})
            
            timeData.after_api_call = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }
       

            notification.success({
                message: t('package:message.split_package_success'),
            })

            timeData.state_process.before = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }
            timeData.state_process.after = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }
            this.setState(prevState => ({
                packages: [
                    ...prevState.packages.slice(0, index),
                    {
                        ...prevState.packages[index],
                        ...data,
                        ...get(response, 'data.detach_package.package'),
                        id: get(response, 'data.detach_package.package.id'),
                        code: get(response, 'data.detach_package.package.code'),
                        weight_net: get(response, 'data.detach_package.package.weight_net'),
                        weight: get(response, 'data.detach_package.package.weight_net'),
                        permissions: get(response, 'data.detach_package.permissions'),
                        length: round(get(response, 'data.detach_package.package.length', 0) * 100, 6),
                        width: round(get(response, 'data.detach_package.package.width', 0) * 100, 6),
                        height: round(get(response, 'data.detach_package.package.height', 0) * 100, 6),
                        box_size: get(response, 'data.detach_package.box_size'),
                        weight_converted: get(response, 'data.detach_package.package.weight_converted'),
                        weight_box: get(response, 'data.detach_package.package.weight_box'),
                    },
                    ...prevState.packages.slice(index + 1),
                ],
                packageCreatedIds: [...prevState.packageCreatedIds, index],
            }))
            // this.handleKeyPress(index)

            scanAutoService.addEventDataPackage(id, timeData)

            this.handlePrint(get(response, 'data.detach_package'), index)

            // this.doPrintStamp(get(response, 'data.detach_package.package'), index)
            // this.getDetachSuggest(true)
        } catch (error) {
            const errors = get(error, 'response.data', {})

            if (Object.keys(errors.data).includes('weight_net')) {
                const inputCurr = document.querySelector(`#weight_net_${index}`)
                if (inputCurr) {
                    setTimeout(function () {
                        inputCurr.focus()
                    }, 1000)
                }
                throw error
            }

            const translatedErrors = getErrors(errors)

            const message = `${t('package:message.split_package_error')}: ${translatedErrors[Object.keys(translatedErrors)[0]]}`

            notification.error({
                message,
            })
        }
    }

    doPrintStamp = async (packageData, index, isCreateList) => {
        const {
            match: {
                params: { id },
            },
            t,
        } = this.props

        const timeData = {
            name: 'api_print_stamp',
            before_api_call: {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            },
            after_api_call: '',
        }

        try {
            const response = await apiService.getPackagePrint(get(packageData, 'id'))

            let data = get(response, 'data', {})
            // let data = get(response, 'data.origin_package', {});
            timeData.after_api_call = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }

            scanAutoService.addEventDataPackage(id, timeData)

            let packageInfo = get(response, 'data.origin_package.package', {})
            let warehouseCode = get(response.data, 'origin_package.destination_warehouse.code', '')

            const newData = {
                packageData: {
                    ...data,
                    ...get(data, 'origin_package', {}),
                },

                warehouseCode,
                numberStamp: 1,
            }

            // {
            //     packageData: {
            //         ...data,
            //         // ...packageInfo,
            //         // operatePackage: operatePackage || false,
            //     },
            //     ...get(data, 'origin_package', {}),
            //     warehouseCode,
            //     numberStamp: numberStamp,
            // }

            this.props.printPackageStamp(newData)
            this.getDetachSuggest(true)
            this.handleKeyPress(index, isCreateList)
        } catch (error) {}
    }

    doPrintOfficialStamp = async (packageData, index, isCreateList) => {
        const {
            match: {
                params: { id },
            },
            t,
        } = this.props

        const timeData = {
            name: 'api_print_stamp',
            before_api_call: {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            },
            after_api_call: '',
        }

        try {
            const response = await apiService.getDataPrintOfficialPackageStamp(get(packageData, 'code'))
            const data = get(response, 'data')

            timeData.after_api_call = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }

            scanAutoService.addEventDataPackage(id, timeData)

            if (data) {
                this.props.printOfficialPackageStamp(data)
                this.getDetachSuggest(true)
                this.handleKeyPress(index, isCreateList)
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                })
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            })
        }
    }

    handleKeyPress = (currentIndex, isCreateList) => {
        let nextIndex = currentIndex + 1 > this.state.packages.length ? currentIndex : currentIndex + 1

        // const nextInput = get(this.state.packages, `${nextIndex}.ref.current`)
        const nextInput = document.querySelector(`#weight_net_${nextIndex}`)
        const { packages } = this.state

        if (currentIndex === 0) {
            if (isCreateList) {
                window.open(window.location.origin + '/packages', '_blank')
            } else {
                setTimeout(() => {
                    router.redirect(`/packages/scan-split-package`)
                }, 3000)
            }
            this.setState({
                packageSplitting: 0,
            })
            return
        } else {
            this.setState({
                stateProgress: nextIndex,
            })
            if (nextInput) {
                nextInput.focus()

                const currentEle = document.querySelector(`.package-item._package-item-${currentIndex}`)

                if (currentEle) {
                    currentEle.classList.remove('package-focus')
                    currentEle.classList.add('package-created')
                }

                const el = document.querySelector(`.package-item._package-item-${nextIndex}`)

                if (el) {
                    el.classList.add('package-focus')
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                this.setState({
                    packageSplitting: nextIndex,
                })
            }

            if (nextIndex === packages.length) {
                const nextInput = document.querySelector(`#weight_net_${0}`)

                if (nextInput) {
                    nextInput.focus()

                    const currentEle = document.querySelector(`.package-item._package-item-${currentIndex}`)

                    if (currentEle) {
                        currentEle.classList.remove('package-focus')
                        currentEle.classList.add('package-created')
                    }

                    const el = document.querySelector(`.package-item._package-item-${0}`)

                    if (el) {
                        el.classList.add('package-focus')
                        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }
                    this.setState({
                        packageSplitting: 0,
                    })
                }
            }
        }
    }

    updateOriginPackage = async (data, index, isOpenPackages) => {
        const { packages } = this.state
        const { t } = this.props

        try {
            const response = await apiService.updateWeightVolume(data.id, data)

            const dataPrint = { permissions: get(packages, `${index}.permissions`), package: get(packages, `${index}`) }

            this.handlePrint(dataPrint, index, isOpenPackages)

            notification.success({
                message: t('package:message.update_weight_success'),
            })

            return response
        } catch (error) {
            throw error
        }
    }

    handleUpdatePackage = async (index, data, isOpenPackages) => {
        const { packages } = this.state

        if (!packages[index]) {
            return
        }

        try {
            if (data.id) {
                await this.updateOriginPackage(data, index, isOpenPackages)
            } else {
                await this.addPackage(index, data)
                this.handleDecreaseCountPackage()
            }
        } catch (error) {
            throw error
        }
    }

    handleRemovePackage = index => {
        this.setState(prevState => ({
            packages: [...prevState.packages.slice(0, index), ...prevState.packages.slice(index + 1)],
            packageCreatedIds: prevState.packageCreatedIds.filter(item => item !== index),
        }))

        this.handleDecreaseCountPackage()
        this.setState({
            stateProgress: index,
        })
        // const nextInput = get(this.state.packages, `${index}.ref.current`)
        const nextInput = document.querySelector(`#weight_net_${index}`)

        if (nextInput) {
            nextInput.focus()

            const el = document.querySelector(`.package-item._package-item-${index}`)

            if (el) {
                el.classList.add('package-focus')
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
            this.setState({
                packageSplitting: index,
            })
        }
    }

    handleAddDetachPackageButtonClick = () => {
        const pkg = this.getNewPackage()
        this.setState(prevState => {
            return { packages: [...prevState.packages, pkg] }
        })
        this.handleIncreaseCountPackage()
    }

    getNewPackage = () => {
        const {
            detachSuggest: { order_items, package_items },
        } = this.state
        const items = []

        if (Array.isArray(order_items)) {
            for (const orderItem of order_items) {
                const packageItem = find(package_items, packageItem => packageItem.code_item === orderItem.code_item)

                if (packageItem && packageItem.received_quantity) {
                    items.push({
                        ...orderItem,
                        received_quantity: undefined,
                    })
                }
            }
        }

        return {
            weight: undefined,
            items,
            ref: createRef(),
        }
    }

    handlePrint(detachPackage, index, isCreateList) {
        const packageData = get(detachPackage, 'package', [])
        const permissions = get(detachPackage, 'permissions', [])
        const urlParams = new URLSearchParams(window.location.search)
        const typeStamp = urlParams.get('typeStamp')

        if (typeStamp === 'print_official_stamp' && get(permissions, 'can_print_official_stamp')) {
            this.doPrintOfficialStamp(packageData, index, isCreateList)
        }

        if (typeStamp === 'print_stamp' && get(permissions, 'can_print_barcode')) {
            this.doPrintStamp(packageData, index, isCreateList)
        }

        if (typeStamp === 'print_batch_stamp' && get(permissions, 'can_print_batch_stamp')) {
            this.handlePrintBatch(packageData, index, isCreateList)
        }
    }

    handlePrintBatch = async (packageData, index, isCreateList) => {
        const {
            match: {
                params: { id },
            },
            t,
        } = this.props

        const timeData = {
            name: 'api_print_stamp',
            before_api_call: {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            },
            after_api_call: '',
        }

        try {
            const response = await apiService.getBatchStampData(get(packageData, 'code'), {})
            const data = get(response, 'data')
            timeData.after_api_call = {
                timestamp: moment().unix(),
                time_date: moment().format('L h:mm:ss.SSS'),
            }

            scanAutoService.addEventDataPackage(id, timeData)

            if (!isEmpty(data)) {
                printBatchStamp(data)
                this.getDetachSuggest(true)
                this.handleKeyPress(index, isCreateList)
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                })
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            })
        }
    }

    componentDidMount() {
        this.updatePageTitle()
        this.getDetachSuggest()
        const urlParams = new URLSearchParams(window.location.search)
        this.setState({
            groupByProduct: urlParams.get('group_by_product') === 'true',
        })
    }
    handleChangeLoadingDetachAuto = value => {
        this.setState({
            loadingDetachAuto: value,
        })
    }

    handleChangeCountPackage = value => {
        this.setState({
            countPackaged: value,
        })
    }

    handleChangeGroupByProduct = checked => {
        this.setState({
            groupByProduct: checked,
        })
    }
    handleDecreaseCountPackage = () => {
        const { countPackaged } = this.state
        this.setState({
            countPackaged: countPackaged - 1,
        })
    }

    handleIncreaseCountPackage = () => {
        const { countPackaged } = this.state
        this.setState({
            countPackaged: countPackaged + 1,
        })
    }

    render() {
        const {
            detachSuggest,
            loading,
            originPackage,
            packages,
            stateProgress,
            groupByProduct,
            adjustNum,
            loadingDetachAuto,
            packageCreatedIds,
            packageSplitting,
            attentions,
        } = this.state
        const { boxSizes, loadingBoxSizes, t, history, location } = this.props

        return (
            <Spin spinning={loading || loadingDetachAuto}>
                <Content className="screen-split-package _screen_split_package a-content pl-0 gflex gflex-direction-column min-height-not-header a-content--height-not-header">
                    <Row
                        type="flex"
                        className="aposition-reletive height-100 a-flex-1">
                        <div
                            className="a-content--scroll-is-box-chat"
                            style={{ paddingBottom: 200 }}>
                            <div
                                className="gflex galign-center gjustify-space-between mb-3  asticky-top a-zindex--10 pb-2"
                                style={{ backgroundColor: '#f0f2f5', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px' }}>
                                <div className="gflex galign-center">
                                    <div>
                                        <Switch
                                            checked={groupByProduct}
                                            onChange={this.handleChangeGroupByProduct}
                                        />
                                        <span className="ml-2">{t('package:label.group_by_product')}</span>
                                    </div>
                                    <Attentions
                                        className="attention-package ml-2"
                                        notes={attentions}
                                        notices={[]}
                                    />
                                </div>
                                <div className="gflex galign-center gjustify-space-between">
                                    <AdjustSuggest
                                        adjustNum={adjustNum}
                                        packages={packages}
                                        getDetachSuggest={this.getDetachSuggest}
                                        handleChangeLoadingDetachAuto={this.handleChangeLoadingDetachAuto}
                                        loadingDetachAuto={loadingDetachAuto}
                                        countPackaged={packages.length}
                                        history={history}
                                        location={location}
                                    />
                                    {/* <h3 className="a-text--fz-18 ap-16 a-text--right">
                                        <span className="mr-1"> {t('package:label.number_separated_package')} : </span>
                                        {get(detachSuggest, 'suggested_packages', []).length}
                                    </h3> */}
                                </div>
                            </div>
                            {get(detachSuggest, 'package.id') ? (
                                <PackageList
                                    boxSizes={boxSizes}
                                    loadingBoxSizes={loadingBoxSizes}
                                    orderItems={get(detachSuggest, 'order_items', [])}
                                    originPackage={originPackage}
                                    packages={packages}
                                    pkg={get(detachSuggest, 'package', {})}
                                    suggestedPackages={get(detachSuggest, 'suggested_packages', [])}
                                    onUpdate={this.handleUpdatePackage}
                                    onRemovePackage={this.handleRemovePackage}
                                    handleKeyPress={this.handleKeyPress}
                                    stateProgress={stateProgress}
                                    groupByProduct={groupByProduct}
                                    detachSuggest={detachSuggest}
                                    packageCreatedIds={packageCreatedIds}
                                    packageSplitting={packageSplitting}
                                />
                            ) : null}

                            <div className="a-text--center mt-8">
                                <Button
                                    className="a-btn a-btn--primary _btn-add-detach-package"
                                    type="primary"
                                    onClick={this.handleAddDetachPackageButtonClick}>
                                    {t('package:btn.add_detach_package')}
                                </Button>
                            </div>
                        </div>

                        {get(detachSuggest, 'package.id') ? (
                            <CheckingPackageLogs
                                packageId={get(detachSuggest, 'package.id')}
                                orderId={get(detachSuggest, 'package.id_order')}
                            />
                        ) : null}
                    </Row>
                </Content>
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    const boxSizes = getSuggestBoxSizes(state, 'box_sizes', [])

    return {
        boxSizes,
        loadingBoxSizes: getLoading(state),
    }
}

const mapDispatchToProps = dispatch => ({
    printPackageStamp: datas => {
        dispatch(printPackageStamp(datas))
    },
    printOfficialPackageStamp: data => {
        dispatch(printOfficialPackageStamp(data))
    },
    printBatchStamp: data => {
        dispatch(printBatchStamp(data))
    },
    fetchSuggestBoxSizes,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SplitPackage))
