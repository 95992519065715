import {connect} from "react-redux";
import * as actions from './actions';
import * as selectors from './selectors';
import * as detailWarehouseStaffSelectors from '../DetailWarehouseStaff/selectors';
import * as createWarehouseStaffSelectors from '../CreateWarehouseStaff/selectors';
import * as updateWarehouseStaffSelectors from '../EditWarehouseStaff/selectors';
import {fetchUsers} from '../../User/actions';
import {fetchSuggestWarehouses} from "../../Warehouse/actions";
import FormWarehouseStaff from './components';
import * as commonAction from '../../Common/actions';
import * as selectorsWarehouse from '../../Warehouse/selectors';

const mapStateToProps = (state, props) => {
    return ({
        errors: selectors.getErrors(selectors.getState(state, "errors", {})),
        users: state.user.users,
        warehouses: selectorsWarehouse.getListWarehouseActive(state),
        warehouseStaffDetail: detailWarehouseStaffSelectors.getState(state, 'detail', {}),
        loading: detailWarehouseStaffSelectors.getState(state, 'loading', false) ||
            createWarehouseStaffSelectors.getState(state, 'loading', false) ||
            updateWarehouseStaffSelectors.getState(state, 'loading', false)
    })
};

const mapDispatchToProps = dispatch => {

    return ({
        clearState: () => dispatch(actions.clearState()),
        resetError: (params) => dispatch(actions.resetError(params)),
        setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
        fetchUsers: () => dispatch(fetchUsers()),
        fetchListWarehouse: () => dispatch(fetchSuggestWarehouses()),
    })
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormWarehouseStaff);