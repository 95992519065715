import React, { useEffect, useState } from 'react'
import { Collapse, notification, Button, Table, Tooltip, Icon } from 'antd'
import { trans, t } from '../../../../system/i18n'
import apiService from '../apiService'
import UpdateLastmileCarrier from './LastmileCarrier/UpdateLastmileCarrier'
import { PROVIDER_LAST_MILE_CARRIER } from '../constants'
import { getVar } from '../../../../system/support/helpers'
import { flatten, isEmpty, map } from 'lodash'

const { Panel } = Collapse

function LastmileCarrier(props) {
    const { setTotal } = props
    const [activeKey, setActiveKey] = useState(Object.values(PROVIDER_LAST_MILE_CARRIER))
    const [shippingPartnersByProvider, setShippingPartnersByProvider] = useState({})
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [shippingPartnerId, setShippingPartnerId] = useState()

    useEffect(() => {
        fetchListLastmileCarrier()
    }, [])

    const fetchListLastmileCarrier = () => {
        setLoading(true)
        apiService
            .getListLastmileCarrier({ sort_by: 'provider' })
            .then(res => {
                const shippingPartnersByProvider = {}
                const response = getVar(res, 'data.data', [])
                response.map(item => {
                    const provider = getVar(item, 'provider')
                    if (Object.values(PROVIDER_LAST_MILE_CARRIER).includes(provider)) {
                        const data = getVar(shippingPartnersByProvider, provider, [])
                        if (isEmpty(data)) {
                            shippingPartnersByProvider[provider] = [item]
                        } else {
                            shippingPartnersByProvider[provider] = [...data, item]
                        }
                    }
                })
                setShippingPartnersByProvider(shippingPartnersByProvider)
                setTotal(flatten(Object.values(shippingPartnersByProvider)).length)
            })
            .catch(err => {
                notification.error({ message: trans('message.server_error') })
            })
            .finally(() => setLoading(false))
    }

    const handleOpenModal = event => {
        event.stopPropagation()
        setVisible(true)
        setShippingPartnerId()
    }

    const handleCancelModal = (hasUpdated = false) => {
        setVisible(false)
        setShippingPartnerId()
        if (hasUpdated) {
            fetchListLastmileCarrier()
        }
    }

    const columns = [
        {
            title: t(`shipping_partner:label.code`),
            dataIndex: 'code',
            key: 'code',
            width: '10%',
        },
        {
            title: t(`shipping_partner:label.name`),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: t(`shipping_partner:label.id_warehouses`),
            dataIndex: 'warehouses.data',
            key: 'warehouses',
            width: '30%',
            render: text => {
                return map(text, 'name').join(', ')
            },
        },

        {
            title: t(`shipping_partner:label.id_agencies`),
            dataIndex: 'agencies.data',
            key: 'agencies',
            width: '30%',
            render: text => {
                return map(text, 'name').join(', ')
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Tooltip title={t('btn.edit')}>
                        <Icon
                            type="edit"
                            onClick={() => {
                                setVisible(true)
                                setShippingPartnerId(getVar(record, 'id'))
                            }}
                        />
                    </Tooltip>
                )
            },
        },
    ]

    return (
        <div className="a-content__body bg-white a-content__body--page-list mt-0 height-100">
            <div className="gflex galign-center gjustify-end mb-3">
                <Button
                    type="primary"
                    icon="plus-circle"
                    onClick={e => handleOpenModal(e)}>
                    {t('shipping_partner:btn.create_partner')}
                </Button>
            </div>
            <Collapse
                activeKey={activeKey}
                onChange={setActiveKey}>
                {Object.values(PROVIDER_LAST_MILE_CARRIER).map(provider => {
                    const shippingPartners = getVar(shippingPartnersByProvider, provider, [])
                    return (
                        <Panel
                            className="_panel-item-lastmile-carrier"
                            header={trans(`shipping_partner:domestic_shipping_partner_types.${provider}`)}
                            key={provider}>
                            <Table
                                loading={loading}
                                rowKey={record => record.id}
                                pagination={false}
                                dataSource={shippingPartners}
                                columns={columns}
                            />
                        </Panel>
                    )
                })}
            </Collapse>
            {visible && (
                <UpdateLastmileCarrier
                    shippingPartnerId={shippingPartnerId}
                    visible={visible}
                    handleCancel={handleCancelModal}
                />
            )}
        </div>
    )
}
export default LastmileCarrier
