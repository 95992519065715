import {api} from '../../system/api';

const BASE_URL = '/customers';

export default {
    getListCustomer: (params) => api.get(BASE_URL, {params: {per_page: 100, ...params}}),
    getSuggests: (params) => api.get(`${BASE_URL}/suggest`, {
        params: {
            per_page: 100,
            ...params
        }
    }),
    fetchDetailCustomer: (customerId, params) => api.get('customers/' + customerId, {params}),
};
