import {api} from '../../../system/api'

export default {
    fetchDetailShippingPartner: id => api.get(`shipping-partners/${id}`, {
        singleRequest: true
    }),
    fetchDetailShippingDomesticPartner: id => api.get(`shipping-domestic-partners/${id}`, {
        singleRequest: true
    })
};
