import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'antd'

import { debounce, find, get, isEmpty } from 'lodash'
import apiService from '../../Bag/apiService'
import { t } from '../../../system/i18n'
import { getVar } from '../../../system/support/helpers'

const { Option } = Select
const SuggestBagList = props => {
    const { value, onChange, form, valueId, disabled } = props

    const [bags, setBags] = useState([])
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        if (get(value, 'length', 0) >= 3) {
            getBag(value)
        }
    }, [value])

    const getBag = async newValue => {
        setLoading(true)
        try {
            const response = await apiService.getBags({ code: newValue })
            const data = get(response, 'data.bags', [])
            form.setFieldsValue({
                id_bag: get(
                    find(data, item => get(item, 'bag.code') === value),
                    'bag.id'
                ),
            })
            setBags(data)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const debounceFn = useCallback(debounce(getBag, 300), [])

    const handleSearch = newValue => {
        if (newValue.length >= 3) {
            debounceFn(newValue)
        } else {
            setBags([])
        }
        setKeyword(newValue)
    }

    return (
        <>
            <Select
                onSearch={handleSearch}
                onChange={onChange}
                className="a-select _bags w-100"
                disabled={loading || disabled}
                loading={loading}
                filterOption={false}
                name="bags"
                optionFilterProp="children"
                optionLabelProp="label"
                placeholder={t('bag:label.code')}
                showSearch
                value={value ? value : undefined}>
                {bags.map(bag => (
                    <Option
                        key={getVar(bag, 'bag.id')}
                        label={getVar(bag, 'bag.code', '--')}
                        value={valueId ? getVar(bag, 'bag.id') : getVar(bag, 'bag.code')}>
                        <span className="a-text--fz-12 a-text--gray-70">
                            {getVar(bag, 'bag.code', '--')} ({getVar(bag, 'agency.code', '--')})
                        </span>
                    </Option>
                ))}
            </Select>
        </>
    )
}

export default SuggestBagList
