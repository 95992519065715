import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../system/i18n';

export function* fetchProperty(action) {
    yield processApiRequest(ACTION.FETCH_LIST_PROPERTY, () => apiService.fetchProperty(action.payload), action.payload);
}

export function* fetchDetailProperty(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_PROPERTY, () => apiService.fetchDetailProperty(action.payload.id), action.payload);
}

/**
 * Xử lý trường hợp fetch property fail
 */
export function* fetchPropertyFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('common:message.fetch_property_fail'),
        });
    }
}

export function* fetchDetailPropertyFailed(action) {
    if (action.payload) {
        yield notification['error']({
            message: t('config:message.fetch_detail_property_fail'),
        });
    }
}

export function* fetchPropertySuggest(action) {
    yield processApiRequest(ACTION.FETCH_LIST_PROPERTY_SUGGEST, () => apiService.fetchPropertySuggest(action.payload), action.payload);
}


