import * as ACTION from './constants';
import apiService from './apiService';
import apiPackageService from '../Package/apiService';
import {processApiRequest} from '../Common/saga';
import {notification} from "antd";
import {t} from "../../system/i18n";
import _ from "lodash"

export function* getNotes(action) {
    yield processApiRequest(ACTION.GET_NOTES, () => apiService.getNotes(action.payload), action.payload);
}

export function* getSuggestOrderSuccess(action) {
    const orders = _.get(action, 'payload.orders', []) || [];
    

    if (!orders.length) {
        yield notification.error({
            message: t('note:message.order_code_not_found')
        });
    } else if (action.request.callback) {
        yield action.request.callback();
    }
}

export function* getSuggestCustomerSuccess(action) {
    const orders = _.get(action, 'payload.customers', []) || [];
    

    if (!orders.length) {
        yield notification.error({
            message: t('note:message.customer_not_found')
        });
    } else if (action.request.callback) {
        yield action.request.callback();
    }
}

export function* fetchPackageDetail(action) {
    yield processApiRequest(ACTION.FETCH_PACKAGE_DETAIL, () => apiPackageService.getPackage(action.payload.code), action.payload);

}

export function* fetchPackageDetailSuccess(action) {
    if (action.request.callback) {
        yield action.request.callback();
    }
}

export function* fetchPackageDetailFailed(action) {
    if (action.payload) {
        yield  notification.error({
            message: t('note:message.package_already_not_exist'),
        });
    }
}
