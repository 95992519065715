import React from "react"
import lodash from "lodash";
import {createSelector} from "reselect";
import {LIST_WAREHOUSE_COLUMN} from "../constants";
import {Icon} from "antd";
import Link from "../../../system/routing/Link";

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'configWarehouse.listWarehouse' + (param ? '.' + param : ''), defaultValue);
export const getListWarehouse = createSelector(
	(state, page) => getState(state, 'list', []),
	(warehouses) => lodash.map(warehouses, warehouse => {
		return {
			key: lodash.get(warehouse, 'warehouse.id', 0),
			active: lodash.get(warehouse, 'warehouse.active', true),
			[LIST_WAREHOUSE_COLUMN.CODE]: <Link to={'config.warehouse.update'}
												params={{id: lodash.get(warehouse, 'warehouse.id', 0)}}>{lodash.get(warehouse, 'warehouse.code', '--') || '--'}</Link>
			,
			[LIST_WAREHOUSE_COLUMN.NAME]: <span
			>{lodash.get(warehouse, 'warehouse.name', '--') || '--'}</span>,
			[LIST_WAREHOUSE_COLUMN.PHONE]: <span
			>{lodash.get(warehouse, 'warehouse.phone', '--') || '--'}</span>,
			[LIST_WAREHOUSE_COLUMN.ADDRESS]: <span>{lodash.get(warehouse, 'warehouse.address', '--') || '--'}</span>,
			[LIST_WAREHOUSE_COLUMN.ACTION]: <Link to={'config.warehouse.update'}
			                                      params={{id: lodash.get(warehouse, 'warehouse.id', 0)}}><Icon
				type="edit"
				className={"mr-3 ahover-pointer a-text--blue a-text--right _a-btn-update-warehouse"}/></Link>

		}
	})
);
