import lodash, * as _ from 'lodash';

export const getBagSizes = (state) => {
    return _.get(state, 'bagSize.bagSizes.bag_sizes', []);
};
export const getSuggestBagSizes = (state) => {
    return _.get(state, 'bagSize.suggestBagSizes.bag_sizes', []);
};

export const getDetailBagSize = (state) => {
    return lodash.get(state, 'bagSize.detail', {});
};
export const getLoading = (state) => {
    return _.get(state, 'bagSize.loading', false);
};
