import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.FETCH_CONSIGN_SERVICES.REQUEST, saga.fetchConsignServices);
    yield takeLatest(ACTION.FETCH_SERVICES.REQUEST, saga.fetchServices);
    yield takeLatest(ACTION.FETCH_CONSIGN_SERVICE_GROUPS.REQUEST, saga.fetchConsignServiceGroups);
    yield takeLatest(ACTION.FETCH_SERVICE_GROUPS.REQUEST, saga.fetchServiceGroups);
}
