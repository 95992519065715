import { api } from './../../system/api';

const BASE_URL = '/bag-areas';

export default {
    list: (params = {}) => api.get(BASE_URL, {
        params: {
            ...params,
            sort_by: 'active',
            sort_direction: 'desc'
        }
    }),
    suggest: (params = {}) => api.get(`${BASE_URL}/suggest`, {
        params: {
            ...params,
            sort_by: 'active',
            sort_direction: 'desc'
        }
    }),
    add: data => api.post(BASE_URL, data),
    update: (id, data) => api.put(`${BASE_URL}/${id}`, data)
};
