import { connect } from 'react-redux';

import * as selectors from './../selectors';
import * as actions from './../actions';
import * as orderActions from './../../Order/actions';

import ListOrder from './components';

const mapStateToProps = state => {
    return {
        suggestOrder: selectors.getState(state, 'listOrder.suggestOrder'),
        list: selectors.getState(state, 'list'),
        pagination: selectors.getState(state, 'pagination', {}),
        loading: selectors.getState(state, 'loading', false),
        loadingSuggestOrder: selectors.getState(state, 'listOrder.loadingSuggestOrder')
    };
};

const mapDispatchToProps = (dispatch) => ({
    getNotes: () => dispatch(actions.getNotes()),
    getSuggestOrders: data => dispatch(orderActions.getSuggestOrders(data)),
    clearSuggestOrder: () => dispatch(actions.clearSuggestOrder()),
    setSuggestOrderId: (data) => dispatch(actions.setSuggestOrderId(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOrder);
