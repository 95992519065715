import { find, get } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../Package/ListPackage/actions'
import * as selectors from '../../Package/ListPackage/selectors'
import SearchableComponent from '../../Common/components/SearchableComponent'
import { LIST_PACKAGE_OBJECT } from '../../Package/ListPackage/constants'
import FormSearch from './FormSearch'
import List from './List'

const mapStateToProps = state => {
    return {
        ...selectors.getState(state),
        setting: get(state, 'setting.setting', []),
    
    }
}

const mapDispatchToProps = dispatch => ({
    fetchPackages: filter => dispatch(actions.fetchPackages(filter)),
    exportPackageList: params => dispatch(actions.exportPackageList(params)),
})

class UnsettledLawsuitsContainer extends SearchableComponent {
    state = {
        loading: false,
        isCurrentPage: LIST_PACKAGE_OBJECT.ALL,
        isOpenListSetting: false,
        defaultFilter: {
            tab: 'unsettled_lawsuits',
            flow: 'flow_expedited_processing',
            was_paid: false,
        },
        selectedPackages: [],
    }

    componentDidMount() {
        this.props.fetchPackages(this.getCurrentFilter())
    }

    onSearch = filter => {
        const newFilter = {
            ...filter,
            tab: 'unsettled_lawsuits',
            flow: 'flow_expedited_processing',
            was_paid: false,
            i: parseInt(filter.i || 0, 0) + 1,
        }

        let newestFilter = newFilter
        let configPackageUnitWeight = get(find(this.props.setting, ['code', 'PACKAGE:UNIT_WEIGHT']), 'value', 'g')

        if (get(filter, 'weight_net[from]') && configPackageUnitWeight === 'kg') {
            const weightNetFrom = get(filter, 'weight_net[from]')
            newestFilter['weight_net[from]'] = Number(weightNetFrom) * 10
        }
        if (get(filter, 'weight_net[to]') && configPackageUnitWeight === 'kg') {
            const weightNetTo = get(filter, 'weight_net[to]')
            newestFilter['weight_net[to]'] = Number(weightNetTo) * 10
        }
        this.setState({ selectedPackages: [] })
        this.pushFilter(newestFilter)

        this.props.fetchPackages(newestFilter)
    }

    getCurrentFilter = () => {
        const { location } = this.props

        const filterLocation = this.getFilterFromLocation(window.location)

        const newFilter = filterLocation.tab !== 'unsettled_lawsuits' ? {} : filterLocation

        const filter = {
            ...newFilter,
            tab: 'unsettled_lawsuits',
            flow: 'flow_expedited_processing',
            was_paid: false,
        }

        return filter
    }

    onOpenListSetting = (isOpenListSetting, isCurrentPage) => {
        this.setState({ isOpenListSetting, isCurrentPage })
    }

    onSelectedPackages = rowKeys => {
        this.setState({ selectedPackages: rowKeys })
    }

    render() {
        const { loading, defaultFilter, isOpenListSetting, isCurrentPage, selectedPackages } = this.state

        return (
            <>
                <FormSearch
                    filter={this.getCurrentFilter()}
                    onSearch={this.onSearch}
                    loading={loading}
                    defaultFilter={defaultFilter}
                />
                <List
                    {...this.props}
                    filter={this.getCurrentFilter()}
                    object={'ALL'}
                    isOpenListSetting={isOpenListSetting}
                    onOpenListSetting={this.onOpenListSetting}
                    onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
                    isCurrentPage={isCurrentPage}
                    onSearch={this.onSearch}
                    onSelectedPackages={this.onSelectedPackages}
                    selectedPackages={selectedPackages}
                />
            </>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UnsettledLawsuitsContainer)
