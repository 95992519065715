import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function* () {
    yield takeLatest(ACTION.GET_LIST_CUSTOMER.REQUEST, saga.getListCustomer);
    yield takeLatest(ACTION.GET_SUGGESTS.REQUEST, saga.getSuggests);
    yield takeLatest(ACTION.GET_INIT_CUSTOMER_OF_SELECT.REQUEST, saga.getInitCustomersOfSelect);
    yield takeLatest(ACTION.FETCH_DETAIL_CUSTOMER.REQUEST, saga.fetchDetailCustomer);
    yield takeLatest(ACTION.FETCH_DETAIL_CUSTOMER.SUCCESS, saga.fetchDetailCustomerSuccess);
}
