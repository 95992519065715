import React, { useEffect, useState } from 'react'
import { Table, Empty } from 'antd'
import { withTranslation } from 'react-i18next'
import Pagination from '../../../Common/components/Pagination'
import pageService from '../../../Common/Page/pageService'
import lodash from 'lodash'
import AddFinanceAgency from '../Add'
import authService from '../../../Auth/authService'
import permissions from '../../../Auth/permissions'
import { formatCurrency } from '../../../Common/components/numberFormat'
import { dateFormatter } from '../../../Common/services/format'

function AgencyFinanceList(props) {
    const { t, setMenuActive, data, filter, loading, onSearch } = props
    const [dataSource, setDataSource] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    useEffect(() => {
        setMenuActive('agency_finance')
        pageService.setTitle(t('agency:title.finance'))
    }, [])

    useEffect(() => {
        setDataSource(lodash.get(data, 'transactions', []))
        setTotalPage(lodash.get(data, 'total', 0))
    }, [data])

    const columns = [
        {
            title: t('agency:label.transaction_type'),
            dataIndex: 'transaction.type',
            key: 'transaction_name',
            render: text => t(`agency:agency_transaction_type.${text}`),
        },
        {
            title: t('agency:label.amount_change'),
            dataIndex: 'transaction.amount',
            key: 'amount',
            render: text => {
                return formatCurrency(text)
            },
        },
        {
            title: t('agency:label.reason'),
            dataIndex: 'transaction.metadata.reason',
            key: 'reason',
            width: '30%',
            render: text => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
        },
        {
            title: t('agency:label.agency_apply'),
            dataIndex: 'agency.name',
            key: 'agency',
        },
        // {
        //     title: t('status'),
        //     dataIndex: 'transaction.status',
        //     key: 'status',
        //     render: text => t(`agency:agency_transaction_status.${text}`),
        // },
        {
            title: t('agency:label.time_apply'),
            dataIndex: 'transaction.created_at',
            key: 'transaction.created_at',
            render: text => {
                return dateFormatter.dateTime(text)
            },
        },
    ]

    const onChangePage = (page, pageSize) => {
        onSearch({
            ...filter,
            page,
            per_page: pageSize,
        })
    }

    return (
        <>
            <div className="a-content__top a-content__top--page-list mt-0">
                <div className="a-content--page-list__title">
                    <h3>
                        {t('agency:title.finance_list_header')} ({totalPage})
                    </h3>
                </div>
                <div className="a-content--page-list__top-action">
                    {authService.can(permissions.FINANCE_ADJUST_CREATE) && (
                        <AddFinanceAgency
                            fetchData={onSearch}
                            filter={filter}
                        />
                    )}
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed ">
                <div className="a-table--responsive">
                    <Table
                        rowKey={record => record.transaction.id}
                        loading={loading}
                        className={'a-table--page-list'}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('common:label.no_data')}</span>}></Empty>
                            ),
                        }}
                    />
                </div>
                <Pagination
                    page={lodash.toInteger(filter.page) || 1}
                    pageSize={lodash.toInteger(filter.per_page)}
                    pageSizeOptions={[20, 50, 100, 200]}
                    total={totalPage}
                    loading={loading}
                    onChange={onChangePage}
                />
            </div>
        </>
    )
}
export default withTranslation()(AgencyFinanceList)
