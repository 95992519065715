import React from 'react';
import { flatMap, get, map } from 'lodash';
import { createSelector } from 'reselect';

import { ORDER_REQUEST_COLUMN } from './constants';
import { formatWeight } from './../../../system/support/helpers';

import Link from './../../../system/routing/Link';
import { linkHTC } from '../../Package/helper';

export const getState = (state, param = null, defaultValue = null) => get(state, `deliveryRequest.detailDeliveryRequest${(param ? `.${param}` : '')}`, defaultValue);

export const getListOrderRequest = createSelector(
    (state) => {
        return getState(state, 'list', [])
    },
    (orderRequests) => {
        const packages = flatMap(map(orderRequests, 'packages'));
        const agencyCode = get(orderRequests, '0.agency.code')
        const linkHtc = linkHTC(agencyCode)

        return packages.map(pkg => {
            const packageWeight = get(pkg, 'weight_net', 0) + get(pkg, 'weight_box', 0);

            return {
                key: get(pkg, 'id'),
                [ORDER_REQUEST_COLUMN.PACKAGE_CODE]: (
                    <Link
                        isNewTab
                        params={{id: get(pkg, 'code')}}
                        to="packages.detail"
                    >
                        {get(pkg, 'code')}
                    </Link>
                ),
                [ORDER_REQUEST_COLUMN.ODER_CODE]: get(pkg, 'order.code') ? (
                    <Link
                        href={ linkHtc ? linkHtc + "/order/detail/default/" + get(pkg, 'order.code') :get(pkg, 'order.m1_url')}
                        isNewTab
                    >
                        {get(pkg, 'order.code')}
                    </Link>
                ) : '--',
                [ORDER_REQUEST_COLUMN.BAG_CODE]: get(pkg, 'bag.code') ? (
                    <Link
                        isNewTab
                        params={{id: get(pkg, 'bag.code')}}
                        to="bags.detail"
                    >
                        {get(pkg, 'bag.code')}
                    </Link>
                ) : '--',
                [ORDER_REQUEST_COLUMN.WEIGHT]: formatWeight(packageWeight, 1),
                [ORDER_REQUEST_COLUMN.WAREHOUSE_AREA]: get(pkg, "warehouse_shelf.code", "---")
            };
        });
    }
);
