import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.FETCH_LIST_LOCATIONS.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_COUNTRY.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_PROVINCE.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_DISTRICT.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_WARD.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_LOCATIONS.FAILED, saga.fetchLocationFailed);
    yield takeLatest(ACTION.FETCH_LIST_COUNTRY.SUCCESS, saga.successFetchCountryList);
}
