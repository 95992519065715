import { combineReducers } from 'redux';

import { UPDATE } from './constants';

const updating = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case UPDATE.REQUEST: {
            return true;
        }
        case UPDATE.SUCCESS:
        case UPDATE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    updating
});
