export const FETCH_LIST_PROPERTY = {
    REQUEST: 'FETCH_LIST_PROPERTY.REQUEST',
    SUCCESS: 'FETCH_LIST_PROPERTY.SUCCESS',
    FAILED: 'FETCH_LIST_PROPERTY.FAILED',
};
export const FETCH_LIST_PROPERTY_SUGGEST = {
    REQUEST: 'FETCH_LIST_PROPERTY_SUGGEST.REQUEST',
    SUCCESS: 'FETCH_LIST_PROPERTY_SUGGEST.SUCCESS',
    FAILED: 'FETCH_LIST_PROPERTY_SUGGEST.FAILED',
};
export const FETCH_DETAIL_PROPERTY = {
    REQUEST: 'FETCH_DETAIL_PROPERTY.REQUEST',
    SUCCESS: 'FETCH_DETAIL_PROPERTY.SUCCESS',
    FAILED: 'FETCH_DETAIL_PROPERTY.FAILED',
};
export const CREATE_PROPERTY = {
    REQUEST: 'CONFIG.CREATE_PROPERTY.REQUEST',
    SUCCESS: 'CONFIG.CREATE_PROPERTY.SUCCESS',
    FAILED: 'CONFIG.CREATE_PROPERTY.FAILED',
};
export const UPDATE_PROPERTY = {
    REQUEST: 'CONFIG.UPDATE_PROPERTY.REQUEST',
    SUCCESS: 'CONFIG.UPDATE_PROPERTY.SUCCESS',
    FAILED: 'CONFIG.UPDATE_PROPERTY.FAILED',
};
export const LIST_PROPERTY_COLUMN = {
    NAME: 'name_property',
    ALIAS: 'alias',
    NOTE: 'note',
    ICON: 'icon',
    ACTION: 'action'
};
export const DEFAULT_STATE_FORM_INPUT_PROPERTY = {
    name: "",
    alias: "",
    note: "",
    icon: undefined
};
export const CLEAR_STATE = "CLEAR_STATE";
export const RESET_ERROR = "RESET_ERROR";

