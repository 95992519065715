import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Col, Form, Select} from 'antd';
import lodash from 'lodash';
import ChooseWarehouseContainer from "../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer";

const Option = Select.Option;

class LocationPackageForm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			idPackage: 0,
			customer_id_country_destination: 11935,
			customer_id_city_destination: 0,
			customer_id_district_destination: 0,
			customer_id_ward_destination: 0,
			id_warehouse_destination: 0,
		};
	}

	static getDerivedStateFromProps(props, state) {
		const nextState = {...state};

		if (lodash.get(props, 'packageData.id', 0) !== state.idPackage) {
			nextState.idPackage = props.packageData.id;
			nextState.customer_id_country_destination = props.packageData.customer_id_country_destination || 0;

			if (nextState.customer_id_country_destination) {
				props.fetchProvince(nextState.customer_id_country_destination);
			}

			nextState.customer_id_city_destination = props.packageData.customer_id_city_destination || 0;

			if (nextState.customer_id_city_destination) {
				props.fetchDistrict(nextState.customer_id_city_destination);
			}

			nextState.customer_id_district_destination = props.packageData.customer_id_district_destination || 0;

			if (nextState.customer_id_district_destination) {
				props.fetchWard(nextState.customer_id_district_destination);
			}

			nextState.customer_id_ward_destination = props.packageData.customer_id_ward_destination || 0;
			nextState.id_warehouse_destination = props.packageData.id_warehouse_destination === 0 ? 0 : props.packageData.id_warehouse_destination;
		}

		// Trường hợp tạo kiện độc lập mà muốn khớp đơn
		if (lodash.get(props, 'packageData.id', 0) === 0 &&
			!lodash.isNil(lodash.get(props, 'packageData.order_code')) && lodash.isNil(state.order_code)) {
			nextState.order_code = lodash.get(props, 'packageData.order_code');
			nextState.customer_id_country_destination = props.packageData.customer_id_country_destination || 0;

			if (nextState.customer_id_country_destination) {
				props.fetchProvince(nextState.customer_id_country_destination);
			}

			nextState.customer_id_city_destination = props.packageData.customer_id_city_destination || 0;

			if (nextState.customer_id_city_destination) {
				props.fetchDistrict(nextState.customer_id_city_destination);
			}

			nextState.customer_id_district_destination = props.packageData.customer_id_district_destination || 0;

			if (nextState.customer_id_district_destination) {
				props.fetchWard(nextState.customer_id_district_destination);
			}

			nextState.customer_id_ward_destination = props.packageData.customer_id_ward_destination || 0;
			nextState.id_warehouse_destination = props.packageData.id_warehouse_destination === 0 ? 0 : props.packageData.id_warehouse_destination;
		}

		return nextState;
	}

	changeSelectValue(name, value) {
		this.setState({[name]: value});
		this.props.onSubmit({...this.state, [name]: value === 0 ? '' : value});
		this.props.resetError(name);
	}

	/**
	 * handle action change country
	 * @param value
	 */
	onChangeCountry = value => {
		const { fetchProvince, onSubmit, resetError, setCurrentCountry } = this.props;

		const newState = {
			customer_id_country_destination: value,
			customer_id_district_destination: 0,
			customer_id_city_destination: 0,
			customer_id_ward_destination: 0,
			id_warehouse_destination: undefined
		};

		this.setState(newState);
		setCurrentCountry(value);
		onSubmit({
			...this.state,
			...newState
		});
		fetchProvince(value);
		resetError('customer_id_country_destination');
		resetError('customer_id_city_destination');
		resetError('customer_id_district_destination');
		resetError('customer_id_ward_destination');
	};

	/**
	 * handle action change city
	 * @param value
	 */
	onChangeCity = value => {
		const { cities, fetchDistrict, onSubmit, resetError, setCurrentCity } = this.props;

		const city = lodash.find(cities, {location: {id: value}});
		let newState = this._suggestWarehouse(this.state, this, city && city.warehouses ? city.warehouses : [], city);
		newState = {
			...newState,
			customer_id_city_destination: value,
			customer_id_district_destination: 0,
			customer_id_ward_destination: 0
		};

		this.setState(newState);
		setCurrentCity(value);
		onSubmit({
			...this.state,
			...newState
		});
		fetchDistrict(value);
		resetError('customer_id_city_destination');
		resetError('customer_id_district_destination');
		resetError('customer_id_ward_destination');
	};

	/**
	 * handle action change district
	 * @param value
	 */
	onChangeDistrict = value => {
		const { districts, fetchWard, onSubmit, resetError, setCurrentDistrict } = this.props;

		const district = lodash.find(districts, {
			location: {
				id: value
			}
		});
		let newState = this._suggestWarehouse(this.state, this, district && district.warehouses ? district.warehouses : [], district);
		newState = {
			...newState,
			customer_id_district_destination: value,
			customer_id_ward_destination: 0
		};

		this.setState(newState);
		setCurrentDistrict(value);
		onSubmit({
			...this.state,
			...newState
		});
		fetchWard(value);
		resetError('customer_id_district_destination');
		resetError('customer_id_ward_destination');
	};

	onChangeWard = value => {
		const { wards, onSubmit, resetError } = this.props;

		const ward = lodash.find(wards, {
			location: {
				id: value
			}
		});
		let newState = this._suggestWarehouse(this.state, this, ward && ward.warehouses ? ward.warehouses : [], ward);
		newState = {
			...newState,
			customer_id_ward_destination: value
		};

		this.setState(newState);
		onSubmit({
			...this.state,
			...newState
		});
		resetError('customer_id_ward_destination');
	};

	/**
	 *
	 * @param newState
	 * @param thatClass
	 * @param warehouses
	 * @param objs
	 * @private
	 */
	_suggestWarehouse(newState, thatClass, warehouses, objs) {
		if (typeof warehouses === 'object') {
			warehouses = Object.keys(warehouses).map(function (key) {
				return warehouses[key];
			});
		}

		if (objs && Array.isArray(warehouses)) {
			if (warehouses.length > 0) {
				warehouses = lodash.orderBy(warehouses, ({sort_location}) => sort_location || '', ['desc']);
				newState = {
					...newState,
					id_warehouse_destination: warehouses[0].id
				};
				thatClass.props.resetError('id_warehouse_destination');
			} else {
				newState = {
					...newState,
					id_warehouse_destination: 0
				};

				if (objs.location.type === 'DISTRICT') {
					let city = lodash.find(this.props.cities, {location: {code: lodash.get(objs, 'location.code_parent')}});
					newState = this._suggestWarehouse(newState, this, city.warehouses, city);
				}

				if (objs.location.type === 'WARD') {
					let district = lodash.find(this.props.districts, {location: {code: lodash.get(objs, 'location.code_parent')}});
					newState = this._suggestWarehouse(newState, this, district.warehouses, district);
				}
			}
		}

		return newState;
	}

	handleChangePackageData = packageData => {
		this.setState({
			id_warehouse_destination: lodash.get(packageData, 'id_warehouse_destination'),
		});
	};

	componentDidUpdate(prevProps) {
		const { packageData } = this.props;

		if (!lodash.isEqual(lodash.get(packageData, 'id_warehouse_destination'), lodash.get(prevProps.packageData, 'id_warehouse_destination'))) {
			this.handleChangePackageData(packageData);
		}
	}

	componentDidMount() {
		const { customer_id_city_destination, customer_id_country_destination } = this.state;
		const { cities, countries, defaultCountry, packageData, submitWhenDidMount, fetchCountry, fetchProvince, onSubmit, setCurrentCity, setCurrentCountry } = this.props;

		if (Array.isArray(countries) && countries.length === 0) {
			fetchCountry(0);
		}

		if (Array.isArray(cities) && cities.length === 0 && customer_id_country_destination) {
			fetchProvince(customer_id_country_destination);
		}

		if (packageData.id !== 0 && submitWhenDidMount) {
			onSubmit({
				...this.state
			});

			const idCountry = customer_id_country_destination;

			setCurrentCountry(idCountry !== 0 && !lodash.isNil(idCountry) ? idCountry : defaultCountry.id);

			if (customer_id_city_destination !== 0 && !lodash.isNil(customer_id_city_destination)) {
				setCurrentCity(customer_id_city_destination);
			}
		}
	}

	render() {
		const { cities, countries, disabled, districts, errors, isLoadingLocation, packageData, t, wards } = this.props;
		const isAlonePackage = packageData.type === 'ALONE' && packageData.barcode === '';
		const required = lodash.isNil(this.props.required) ? !(packageData.id === 0 || lodash.isNil(packageData.id) || isAlonePackage) : this.props.required;

		return (
			<React.Fragment>
				<Col
					lg={{span: 8, offset: 0}} xl={{span: 6}}
					xxl={{span: 4, offset: 1}}
					className="info--group clear-both--lg">
					<Form.Item
						required={required}
						className="a-form__item "
						label={t("package:label.id_warehouse_destination")}
						labelCol={{span: 24}}
						help={errors.id_warehouse_destination}
						validateStatus={errors.id_warehouse_destination ? 'error' : null}>
                        <ChooseWarehouseContainer
							allowClear={!required}
							autoSelected={false}
                            isSelect
                            emptyOption={false}
                            isPermission={false}
                            active
                            className="a-select a-select--search-content _id_warehouse_destination"
                            value={this.state.id_warehouse_destination || undefined}
                            onChange={this.changeSelectValue.bind(this, 'id_warehouse_destination')}
                            dropdownClassName={'_dropdown__id_warehouse_input'}
                        />
					</Form.Item>
				</Col>
				<Col
				    className="info--group"
					lg={{span: 8, offset: 0}}
					xl={{span: 6}}
				    xxl={{span: 4, offset: 1}}
				>
					<Form.Item
						className="a-form__item"
						help={errors.customer_id_country_destination}
						label={t('location:label.country')}
						labelCol={{span: 24}}
						required={required}
						validateStatus={errors.customer_id_country_destination ? 'error' : null}
					>
						<Select
							allowClear={!required}
							className="a-select a-select--w-100 _country"
							disabled={disabled || isLoadingLocation || !countries.length}
							loading={!countries.length && isLoadingLocation}
							optionFilterProp="children"
							placeholder={t('location:placeholder.country')}
							showSearch
							value={this.state.customer_id_country_destination === 0 ? undefined : (countries.length ? this.state.customer_id_country_destination : undefined)}
							onChange={this.onChangeCountry}
						>
							{countries.map(country => (
								<Option
									className="_country__item"
									key={country.location.id}
									value={country.location.id}
								>
									{country.location.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col
					className="info--group"
					lg={{span: 8}}
					xl={{span: 6}}
					xxl={{span: 4}}
				>
					<Form.Item
						className="a-form__item"
						help={errors.customer_id_city_destination}
						label={t('location:label.city')}
						labelCol={{span: 24}}
						required={required}
						validateStatus={errors.customer_id_city_destination ? 'error' : null}
					>
						<Select
							allowClear={!required}
							className="a-select a-select--w-100 _city"
							disabled={disabled || !this.state.customer_id_country_destination || isLoadingLocation || !countries.length}
							loading={!districts.length && isLoadingLocation}
							optionFilterProp="children"
							placeholder={t('location:placeholder.city')}
							showSearch
							value={this.state.customer_id_city_destination === 0 ? undefined : (countries.length && cities.length ? this.state.customer_id_city_destination : undefined)}
							onChange={this.onChangeCity}
						>
							{cities.map(city => (
								<Option
									className="_city__item"
									key={city.location.id}
									value={city.location.id}
								>
									{city.location.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col
				    className="info--group"
					lg={{span: 8, offset: 0}}
					xl={{span: 6}}
				    xxl={{span: 4, offset: 1}}
				>
					<Form.Item
						className="a-form__item"
						help={errors.customer_id_district_destination}
						label={t('location:label.district')}
						required={required}
						validateStatus={errors.customer_id_district_destination ? 'error' : null}
					>
						<Select
							allowClear={!required}
							className="a-select a-select--w-100 _district"
							disabled={disabled || !this.state.customer_id_city_destination || isLoadingLocation || !cities.length}
							loading={isLoadingLocation}
							optionFilterProp="children"
							placeholder={t('location:placeholder.district')}
							showSearch
							value={this.state.customer_id_district_destination === 0 ? undefined : (countries.length && cities.length && districts.length ? this.state.customer_id_district_destination : undefined)}
							onChange={this.onChangeDistrict}
						>
							{districts.map(district => (
								<Option
									className="_district__item"
									key={district.location.id}
									value={district.location.id}
								>
									{district.location.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col
					className="info--group"
					lg={{span: 8, offset: 0}}
					xl={{span: 6}}
					xxl={{span: 4, offset: 1}}
				>
					<Form.Item
						className="a-form__item" label={t('location:label.ward')}
						required={required}
						help={errors.customer_id_ward_destination}
						validateStatus={errors.customer_id_ward_destination ? 'error' : null}
					>
						<Select
							allowClear={!required}
							optionFilterProp="children"
							className="a-select a-select--w-100 _ward"
							placeholder={t('location:placeholder.ward')}
							showSearch={true}
							disabled={disabled || this.state.customer_id_district_destination === 0 || isLoadingLocation || districts.length === 0}
							value={this.state.customer_id_ward_destination === 0 ? undefined : (!wards.length || !districts.length || !cities.length) ? undefined : this.state.customer_id_ward_destination}
							loading={isLoadingLocation}
							onChange={this.onChangeWard}
						>
							{wards.map(ward => (
								<Option
									className="_ward__item"
									key={ward.location.id}
									value={ward.location.id}
								>
									{ward.location.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</React.Fragment>
		);
	}
}

LocationPackageForm.defaultProps = {
	packageData: {},
	isLoadingProperty: false,
	isLoadingLocation: false,
	countries: [],
	cities: [],
	districts: [],
	errors: {},
	disabled: false,
	submitWhenDidMount: true
};

LocationPackageForm.propTypes = {
	packageData: PropTypes.object,
	isLoadingLocation: PropTypes.bool,
	countries: PropTypes.array,
	cities: PropTypes.array,
	districts: PropTypes.array,
	setCurrentCountry: PropTypes.func,
	setCurrentCity: PropTypes.func,
	onSubmit: PropTypes.func,
	resetError: PropTypes.func,
	errors: PropTypes.object,
	submitWhenDidMount: PropTypes.bool
};

export default Form.create({})(withTranslation()(LocationPackageForm));
