import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import lodash from 'lodash';

export function* deletePackage(action) {
    const packageCode = lodash.get(action.payload, 'packageId');
    const params = lodash.get(action.payload, 'params');
    yield processApiRequest(ACTION.DELETE_PACKAGE, () => apiService.deletePackage(packageCode, params), action.payload);
}

export function* deletePackageFailed(action) {
    if (action.payload) {
        if (Array.isArray(lodash.get(action, 'payload.data.id_bag.not_required', null))) {
            yield  notification['error']({
                message: t('package:message.delete_failed_in_bag'),
            });
        } else {
            yield  notification['error']({
                message: t('package:message.delete_failed'),
            });
        }

    }
}

export function* deletePackageSuccess() {
    yield  notification['success']({
        message: t('package:message.delete_success'),
    });
}
