/* eslint-disable */
import React, {Component} from 'react';
import { Select, Table, Pagination, Button, Menu, Dropdown, Icon, Checkbox,} from 'antd';

const Option = Select.Option;
const columns = [
    {
        title: 'Mã kiện',
        dataIndex: 'package',
        key: 'package',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    },{
        title: 'Mã đơn',
        dataIndex: 'codeorder',
        key: 'codeorder',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    },
    {
        title: 'Thời gian',
        dataIndex: 'time',
        key: 'time',
        className: "a-text--nowrap",
    },{
        title: 'Số sản phẩm',
        dataIndex: 'numberproduct',
        key: 'numberproduct',
        className: "a-text--nowrap",
    },{
        title: 'Người kiểm',
        dataIndex: 'personcheck',
        key: 'personcheck',
        className: "a-text--nowrap",
    }];

const data = [];
for (let i = 0; i < 50; i++) {
    data.push({
        package: `89375011909${i}`,
        codeorder: `9340909345030934`,
        time: `14:00:00 29/02/2021`,
        numberproduct: `${i + 1} `,
        personcheck: `Nguyễn Hoàng Thuỳ Trang`,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

class ListPackage extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list">
                    <div className="a-content--page-list__title">
                        <h3>Danh Sách Bao - <span>32216</span></h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>Thiết lập cột thông tin
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list ">
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           pagination={false}
                    />
                    <div className="a-pagination">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show">
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListPackage;