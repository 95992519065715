import {processApiRequest} from "../../Common/saga";
import * as ACTION from "../constants";
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {router} from "../../../system/routing";

export function* createProperty(action) {
	yield processApiRequest(ACTION.CREATE_PROPERTY, () => apiService.createProperty(action.payload), action.payload);
}

export function* createPropertySuccess() {
	yield notification.success({message: t('config:message.create_property_success')});
	router.redirect("/config/property");
}
