import {combineReducers} from 'redux';
import * as ACTION from './constants';
import lodash from 'lodash';

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_ALONE_PACKAGE.REQUEST:
        case ACTION.CREATE_ALONE_PACKAGE.REQUEST:
            return true;
        case ACTION.UPDATE_ALONE_PACKAGE.SUCCESS:
        case ACTION.CREATE_ALONE_PACKAGE.SUCCESS:
        case ACTION.UPDATE_ALONE_PACKAGE.FAILED:
        case ACTION.CREATE_ALONE_PACKAGE.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

let errors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_ALONE_PACKAGE.REQUEST:
        case ACTION.CREATE_ALONE_PACKAGE.REQUEST:
        case ACTION.UPDATE_ALONE_PACKAGE.SUCCESS:
        case ACTION.CREATE_ALONE_PACKAGE.SUCCESS:
        case ACTION.CLEAR_STATE:
            return {};
        case ACTION.UPDATE_ALONE_PACKAGE.FAILED:
        case ACTION.CREATE_ALONE_PACKAGE.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};
export default combineReducers({
    loading,
    errors
});
