import React from "react";
import {t} from "../../../../system/i18n"
import {Col, Icon, Row} from "antd";
import InputNumber from "../../../Common/components/InputNumber";
import * as lodash from "lodash";

class UpdateVolumeBag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            editing: false,
            version: null,
            input: {
                width: 0,
                height: 0,
                length: 0
            }
        }
    }

    getWarningParams(param) {
        let warning = {};
        switch (param) {
            case 'length':
            case 'width':
            case 'height':
            default:
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
        }

        return warning;
    }

    onCancel = () => {
        this.setState({
            editing: false
        })
    };

    onCallback = (e) => {
        this.setState({
            loading: false,
            editing: !e,
        })
    };
    onSubmit = () => {
        let bagId = lodash.get(this.props.bag, 'id');
        this.setState({
            loading: true
        });
        this.props.updateBag(bagId, this.state.input, this.onCallback)
    };
    onEdit = () => {
        let {bag} = this.props;
        this.setState({
            editing: true,
            input: {
                width: lodash.get(bag, 'width', 0) * 100,
                height: lodash.get(bag, 'height', 0) * 100,
                length: lodash.get(bag, 'length', 0) * 100
            }
        })
    };

    renderActions() {
        if (this.props.status) {
            return null
        }
        if (this.state.loading) {
            return <Icon type="loading-3-quarters" className="icon-loading ml-3"/>
        }
        if (this.state.editing) {
            return <React.Fragment>
                <Icon type="close" className="icon-inline" onClick={this.onCancel}/>
                <Icon type="check" className="icon-inline" onClick={this.onSubmit}/>
            </React.Fragment>
        }

        return <Icon type="edit" className="icon-inline" onClick={this.onEdit}/>
    }

    getInputStatusClass(hasError, hasWarning) {
        if (hasError) {
            return 'a-input--error';
        }

        if (hasWarning) {
            return 'a-input--warning';
        }

        return '';
    }

    onChangeInput(param, value) {
        if (lodash.isObject(value) && value.hasOwnProperty('target')) {
            value = value.target.value;
        }
        let currentValue = this.state.input[param];
        if (currentValue !== value) {
            this.setState({
                input: {
                    ...this.state.input, [param]: value
                }
            })
        }
    }

    render() {
        let {input, editing, loading} = this.state;
        let volume = lodash.get(this.props.bag, 'volume', 0) * 1000000;
        let inputVolume = input.height * input.width * input.length;
        return (
            <div className={'_update_volume_bag'}>
                <label className="a-inline-edit--label">
                    {editing ? (t('bag:label.dimension') + ' (cm)') : t('bag:label.volume')}
                    {this.renderActions()}
                </label>
                {this.state.editing ? (
                    <div className="a-form form--package form-package-lg">
                        <Row gutter={{lg: 24, xl: 32}}>
                            <Col lg={{span: 24}} xl={{span: 18}} xxl={{span: 16}} className="pr-0 ">
                                <div className="a-package__body--group-size">
                                    <div className="group--input-bill-code-small">
                                        {['length', 'width', 'height'].map(param => (
                                            <div key={param}
                                                 className="a-form--loading loading-input mr-4 ">
                                                <InputNumber
                                                    name={param}
                                                    placeholder={t('package:placeholder.' + param)}
                                                    title={t('package:label.' + param)}
                                                    className={'a-input a-input--package-size _package_size ' +
                                                    this.getInputStatusClass(this.getWarningParams(param)['status'] === 'error',
                                                        this.getWarningParams(param)['status'] === 'warning')}
                                                    value={input[param] === 0 ? '' : (input[param] || '') || ''}
                                                    precision={1}
                                                    min={0}
                                                    onChange={this.onChangeInput.bind(this, param)}
                                                    // onPressEnter={this.onPressEnter.bind(this)}
                                                />
                                                {loading ? <Icon type="loading-3-quarters"
                                                                 className="icon-loading"/> : null}
                                            </div>
                                        ))}
                                        {!!inputVolume ?
                                            <span
                                                className="bill-code-sub _volume">= {inputVolume} (cm<sup>3</sup>)</span> : null}

                                        {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'error' ? (
                                            <p key={param}
                                               className="a-text--error mb-0 mt-1">{this.getWarningParams(param)['message']}</p>
                                        ) : null)}

                                        {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'warning' ? (
                                            <p key={param}
                                               className="a-text--warning mb-1 mt-1">{this.getWarningParams(param)['message']}</p>
                                        ) : null)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (volume ?
                        <div className="a-inline-edit--value">{volume} cm<sup>3</sup></div> :
                        <div className="a-inline-edit--value">--</div>
                )}
            </div>
        );
    }

}

export default UpdateVolumeBag
