import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchSuggestBagSize } from './../actions';
import { getLoading, getSuggestBagSizes } from './../selectors';

import SelectBagSize from './components';

const mapStateToProps = (state) => {
    let bagSizes = getSuggestBagSizes(state);
    bagSizes = lodash.map(bagSizes, (item) => lodash.get(item, 'bag_size'));
    bagSizes = lodash.filter(bagSizes, value => !!value);
    bagSizes = lodash.filter(bagSizes, item => item.active);

    return {
        bagSizes: bagSizes,
        loading: getLoading(state)
    };
};

const mapDispatchToProps = {
    fetchBagSize: fetchSuggestBagSize,
};

class SelectBagSizeContainer extends React.Component {
    componentDidMount() {
        if (!this.props.bagSizes.length) {
            this.props.fetchBagSize();
        }
    }

    render() {
        return (
            <SelectBagSize {...this.props} />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(SelectBagSizeContainer);

Container.defaultProps = {
    errors: {},
    autoSelect: false,
    disabled: false,
    placeholder: '',
    onChange: () => {
    },
};

Container.propTypes = {
    value: PropTypes.number,
    errors: PropTypes.object,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default Container;
