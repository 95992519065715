import React, { useState } from 'react';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import apiService from './../apiService';
import { DELIVERY_NOTE_STATUS } from './../constants';
import { handlePlaceError } from './../helper';
import { url } from './../../../system/routing';

import PlaceDeliveryNote from './components';

const PlaceDeliveryNoteContainer = props => {
    const [placing, setPlacing] = useState(false);

    const onPlace = async () => {
        const { deliveryNote } = props;
        const deliveryNoteId = get(deliveryNote, 'delivery_note.id');
        const status = get(deliveryNote, 'delivery_note.status');

        if (placing) {
            return;
        }

        setPlacing(true);

        let redirectUrl = url.to('delivery-notes.customer.domestic-shipping-orders', {
            id: deliveryNoteId
        });

        if ([DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(status)) {
            props.onSuccessPlaceDeliveryNote();

            if (props.isNewTab) {
                window.open(redirectUrl, '_blank');
            } else {
                url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
                    id: deliveryNoteId
                });   
            }
        } else {
            try {
                const { data } = await apiService.place(deliveryNoteId);

                props.onSuccessPlaceDeliveryNote(data);

                if (props.isNewTab) {
                    window.open(redirectUrl, '_blank');
                } else {
                    url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
                        id: deliveryNoteId
                    });   
                }
            } catch (error) {
                handlePlaceError(error);
            }
        }

        setPlacing(false);
    };

    return (
        <PlaceDeliveryNote
            {...props}
            placing={placing}
            onPlace={onPlace}
        />
    );
};

export default withTranslation()(PlaceDeliveryNoteContainer);
