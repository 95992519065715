import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { withTranslation } from 'react-i18next';

import { Link } from './../../../../system/routing';

import CreateComplaintSellerButton from './../../components/CreateComplaintSellerButton';
import CreateComplaintSellerModal from './../../../Common/components/Modals/CreateComplaintSeller';
import SyncAgency from './SyncAgency';

class HeaderPackage extends React.PureComponent {
    state = {
        modalVisibles: {
            createComplaintSeller: false
        }
    };

    handleOpenModal = modal => {
        this.setState(prevState => ({
            modalVisibles: {
                ...prevState.modalVisibles,
                [modal]: true
            }
        }));
    };

    handleCancelModal = modal => {
        this.setState(prevState => ({
            modalVisibles: {
                ...prevState.modalVisibles,
                [modal]: false
            }
        }));
    };

    render() {
        const { modalVisibles } = this.state;
        const { noneData, t } = this.props;
        const packageData = this.props.package || {};

        return (
            <div className="title">
                <CreateComplaintSellerModal
                    agencyId={lodash.get(packageData, 'agency.id')}
                    packages={[packageData]}
                    visible={modalVisibles.createComplaintSeller}
                    onCancel={this.handleCancelModal.bind(this, 'createComplaintSeller')}
                    onOk={this.handleCancelModal.bind(this, 'createComplaintSeller')}
                />
                <div className="left">
                    <span className="block-mr-50">
                        <strong>{t('package:detail.barcode')}</strong> | {packageData.code || noneData}
                    </span>
                    <span>
                        {t('package:label.current_warehouse')}: <strong>{lodash.get(packageData, 'current_warehouse.code') || noneData}</strong> / {t('package:detail.warehouse_destination_address')}: <strong>{lodash.get(packageData, 'destination_warehouse.code') || noneData}</strong>
                    </span>
                </div>
                <div className="right gflex galign-center">
                    {
                        !!lodash.get(packageData, 'allow_sync_agency', false) && 
                        <SyncAgency packageData={packageData}/>
                    }
                    <a className="a-btn a-btn--transparent-white a-btn--history-in-package _btn-scanning-history"
                       href={'/barcode/scanning-history?barcode=' + packageData.code + '&i=1&page=1&sort_by=created_at&sort_direction=desc'}>
                        {t('package:detail.history_scan')}
                    </a>
                    <CreateComplaintSellerButton
                        className="a-btn a-btn--transparent-white ml-4"
                        ghost
                        isDamage={lodash.get(packageData, 'package_detail.is_damage')}
                        onClick={this.handleOpenModal.bind(this, 'createComplaintSeller')}
                    />
                    {!!lodash.get(packageData, 'permissions.can_create_delivery_note_return', false) && (
                        <Button
                            className="a-btn a-btn--transparent-white ml-4"
                            ghost
                        >
                            <Link
                                queries={{
                                    package: lodash.get(packageData, 'code', '--')
                                }}
                                to="delivery-notes.return.create"
                            >
                                {t('create_delivery_note_return')}
                            </Link>
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

HeaderPackage.defaultProps = {
    packageData: {},
    noneData: '--'
};

HeaderPackage.propTypes = {
    packageData: PropTypes.object,
    noneData: PropTypes.string,
};

export default withTranslation()(HeaderPackage);
