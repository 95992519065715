import {combineReducers} from 'redux';
import * as ACTION from "./constants";
import * as _ from "lodash";
import list from './List/reducer';
import form from './Form/reducer';
import edit from './Edit/reducer';

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.REQUEST:
            return true;
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.SUCCESS:
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.FAILED:
            return false;

        default:
            return state;
    }
};

let suggest = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.SUCCESS:
            return _.get(payload, 'shipping_users', []);
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    suggest,
	list,
    form,
    edit
});
