import {connect} from 'react-redux';
import ScanBarcodePage from './components/';
import authService from './../../Auth/authService';
import {setMenuActive} from './../../Common/actions';

const mapStateToProps = (state) => {
    return {
        user_id: authService.user('id')
    };
}


const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanBarcodePage);
