import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* getBagShippingPartners(action) {
    yield processApiRequest(ACTION.FETCH_BAG_SHIPPING_PARTNER, () => apiService.getBagShippingPartners(action.payload), action.payload);
}
export function* getDetailShippingPartners(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_SHIPPING_PARTNER, () => apiService.getDetailShippingPartner(action.payload), action.payload);
}

export function* getShippingPartnersSuggest(action) {
    yield processApiRequest(ACTION.FETCH_SHIPPING_PARTNER_SUGGEST, () => apiService.getShippingPartnersSuggest(action.payload), action.payload);
}

export function* getInternationals(action) {
    yield processApiRequest(ACTION.GET_INTERNATIONALS, () => apiService.getInternationals(action.payload), action.payload);
}

export function* getDomestics(action) {
    yield processApiRequest(ACTION.GET_DOMESTICS, () => apiService.getDomestics(action.payload), action.payload);
}

export function* getDomesticSuggests(action) {
    yield processApiRequest(ACTION.GET_DOMESTIC_SUGGESTS, () => apiService.getDomesticSuggests(action.payload), action.payload);
}

export function* updateDomestic(action) {
    yield processApiRequest(ACTION.UPDATE_DOMESTIC, () => apiService.updateDomestic(action.payload), action.payload);
}

export function* getLastmileCarrierSuggest(action) {
    yield processApiRequest(ACTION.GET_LASTMILE_CARRIER_SUGGEST, () => apiService.getLastmileCarrierSuggest(action.payload), action.payload);
}
