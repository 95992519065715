import React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Icon, Input, Row, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import {withTranslation} from 'react-i18next';
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';
import SelectProperties from '../../../Property/FormMultiProperties/FormMultiPropertiesContainer';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {BAG_TYPES, PACKAGE_ACTION} from '../../constants';
import FormProcessing from '../../../Common/components/FormProcessing';
import moment from 'moment';
import SelectSortPartnerUserContainer from "../../../ShippingPartner/SelectSortPartnerUser/SelectSortPartnerContainer";
import {converValue2Int} from './../services';
import {BAG_SHIPPING_TYPE} from "../../BagShippingType/constants";
import SelectAgency from './../../../Setting/Agency/SelectAgency';
import {STATUSES} from './../../constants';

import BagTemplatesSelect from '../../../Common/components/Selects/BagTemplatesSelect';
import ServicesSelectContainer from '../../../Common/components/ServicesSelect/ServicesSelectContainer';
import CommonLocation from './../../../Location/CommonLocation/CommonLocationContainer';
import withWarehouses from './../../../../hocs/withWarehouses';
import authService from '../../../Auth/authService';
import permissions from '../../../Auth/permissions';

const Option = Select.Option;
const Search = Input.Search;

class FormSearch extends FormProcessing {

    constructor(props) {
        super(props);

        this.defaultInput = {
            id_warehouse_input: undefined,
            id_warehouse_current: undefined,
            id_warehouse_destination: undefined,
            customer_code: '',
            properties: [],
            services: [],
            'created_at[from]': '',
            'created_at[to]': '',
            'time_scan[from]': '',
            'time_scan[to]': '',
            status_warehouse: undefined,
            code: '',
            id_shipping_user: 0,
            id_shipping_partner: 0,
            subject_shipping: undefined,
            type: undefined,
            tab_on_day: 0,
            package_complete: undefined,
            id_agencies: []
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
            collapsed: true
        };
    }


    /**
     * Get filter
     * @param filter
     * @returns {*}
     */
    getFilter(filter) {
        let page = this.props.page;
        if (page === 'list_bag_pack') {
            filter.packing_complete = 0;
        }
        return filter;
    }

    onSubmit(event) {
        event.preventDefault();
        if (!this.props.loading) {
            let {input} = this.state;
            let {tab_on_day} = this.props.filter;
            input.page = 1;
            input.tab_on_day = tab_on_day;
            if (this.props.page === 'list_bag_pack') {
                input.packing_complete = 0
            }

            this.props.onSearch(input);
        }
    }

    onReset = (event) => {
        event.preventDefault();
        if (!this.props.loading) {
            let {tab_on_day} = this.props.filter;
            let filter = this.getFilter(this.defaultInput);
            filter.tab_on_day = tab_on_day;
            this.changeInput(filter);
            this.props.onSearch(filter);
        }
    };

    /**
     *
     * @param name
     * @param date
     */
    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    changeShipping(input) {
        if (lodash.startsWith(input.value, ("id_shipping_user_"))) {
            this.changeInput({
                id_shipping_user: lodash.split(input.value, "id_shipping_user_")[1],
                id_shipping_partner: lodash.isNil(input.value) ? undefined : 0,
                subject_shipping: lodash.isNil(input.value) ? undefined : BAG_SHIPPING_TYPE.USER
            });
        } else {
            this.changeInput({
                id_shipping_partner: lodash.split(input.value, "id_shipping_partner_")[1],
                id_shipping_user:lodash.isNil(input.value) ? undefined : 0,
                subject_shipping: lodash.isNil(input.value) ? undefined : BAG_SHIPPING_TYPE.PARTNER
            });
        }
    }

    onChangeMultiSelect(name, value) {
        this.changeInput({[name]: value});
    }

    handleChangeCheckbox = (field, e) => {
        this.changeInput({[field]: e.target.checked});
    };

    toggleCollapseSearch = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    };

    renderCollapsedForm = input => {
        const { bagTemplates, filter, loadingWarehouses, t, warehouses } = this.props;

        return (
            <Row
                gutter={{lg: 12, xl: 24}}
                type="flex"
            >
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t("bag:label.code_search")}
                        labelCol={{span: 24}}>
                        <Search
                            value={input.code || ''}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            placeholder={t("bag:label.code_search")}
                            className="a-input--search  _bags_search"
                        />
                    </Form.Item>
                </Col>
                <Col lg={Number(filter.tab_on_day || 0) === 0 ? 4 : 5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_warehouse_input")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _id-warehouse-input"
                            dropdownClassName="_dropdown_id_warehouse_input"
                            getDataWhenMounted={false}
                            loading={loadingWarehouses}
                            placeholder={t('bag:label.id_warehouse_input')}
                            value={input.id_warehouse_input}
                            warehouses={warehouses}
                            onChange={id_warehouse_input => this.changeInput({id_warehouse_input})}
                        /> 
                    </Form.Item>
                </Col>
                <Col lg={10} xxl={8}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_warehouse_destination")}
                        labelCol={{span: 24}}
                    >
                        <Row gutter={4}>
                            <Col span={9}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _id-warehouse-destination"
                                    dropdownClassName="_dropdown_id_warehouse_destination"
                                    getDataWhenMounted={false}
                                    loading={loadingWarehouses}
                                    placeholder={t('bag:label.id_warehouse_destination')}
                                    value={input.id_warehouse_destination}
                                    warehouses={warehouses}
                                    onChange={id_warehouse_destination => this.changeInput({
                                        id_warehouse_destination
                                    })}
                                />
                            </Col>
                            <Col span={15}>
                                <CommonLocation
                                    autoload
                                    filter={province => ['Thành phố Hà Nội', 'Thành phố Hồ Chí Minh', 'Thành phố Đà Nẵng'].includes(lodash.get(province, 'location.label'))}
                                    selectProps={{
                                        allowClear: true
                                    }}
                                    type="province"
                                    value={input.wh_desc_location}
                                    onChange={wh_desc_location => this.changeInput({
                                        wh_desc_location
                                    })}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col lg={Number(filter.tab_on_day || 0) === 0 ? 5 : 4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_shipping_partner")}
                        labelCol={{span: 24}}>
                        <SelectSortPartnerUserContainer
                            allowClear
                            dropdownClassName="_dropdown_form_bag_shipping_container"
                            placeholder={t('bag:label.id_shipping_partner')}
                            id_shipping_user={parseInt(input.id_shipping_user, 10)}
                            id_shipping_partner={parseInt(input.id_shipping_partner, 10)}
                            onChange={this.changeShipping.bind(this)}
                        />
                    </Form.Item>
                </Col>
                {filter.tab_on_day !== '1' ? (
                    <Col lg={9} xxl={7}>
                        <Form.Item className="a-form__item search-content" label={t('bag:label.created_at')}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('transport_status')}
                        labelCol={{span: 24}}
                    >
                        <Select
                            allowClear
                            className="_status"
                            placeholder={t('select_transport_status')}
                            value={input.status}
                            onChange={status => this.changeInput({
                                status
                            })}
                        >
                            {lodash.values(STATUSES).map(status => (
                                <Option key={status} value={status}>
                                    {t(`bag:statuses.${status}`)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('bag_template')}
                        labelCol={{span: 24}}
                    >
                        <BagTemplatesSelect
                            allowClear
                            showSearch
                            bagTemplates={bagTemplates}
                            className="_id-bag-template"
                            placeholder={t('bag:label.choose_template_bag')}
                            value={input.id_bag_template}
                            onChange={id_bag_template => this.changeInput({
                                id_bag_template
                            })}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    renderExpandedForm = input => {
        const { bagTemplates, consignServices, filter, loadingWarehouses, page, services, t, warehouses } = this.props;

        return (
            <Row
                gutter={{lg: 12, xl: 24}}
                type="flex"
            >
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t("bag:label.code_search")}
                        labelCol={{span: 24}}>
                        <Search
                            value={input.code || ''}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            placeholder={t("bag:label.code_search")}
                            className="a-input--search  _bags_search"
                        />
                    </Form.Item>
                </Col>
                <Col lg={Number(filter.tab_on_day || 0) === 0 ? 4 : 5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_warehouse_input")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _id-warehouse-input"
                            dropdownClassName="_dropdown_id_warehouse_input"
                            getDataWhenMounted={false}
                            loading={loadingWarehouses}
                            placeholder={t('bag:label.id_warehouse_input')}
                            value={input.id_warehouse_input}
                            warehouses={warehouses}
                            onChange={id_warehouse_input => this.changeInput({id_warehouse_input})}
                        />
                    </Form.Item>
                </Col>
                <Col lg={10} xxl={8}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_warehouse_destination")}
                        labelCol={{span: 24}}
                    >
                        <Row gutter={4}>
                            <Col span={9}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _id-warehouse-destination"
                                    dropdownClassName="_dropdown_id_warehouse_destination"
                                    getDataWhenMounted={false}
                                    loading={loadingWarehouses}
                                    placeholder={t('bag:label.id_warehouse_destination')}
                                    value={input.id_warehouse_destination}
                                    warehouses={warehouses}
                                    onChange={id_warehouse_destination => this.changeInput({
                                        id_warehouse_destination
                                    })}
                                />
                            </Col>
                            <Col span={15}>
                                <CommonLocation
                                    autoload
                                    filter={province => ['Thành phố Hà Nội', 'Thành phố Hồ Chí Minh', 'Thành phố Đà Nẵng'].includes(lodash.get(province, 'location.label'))}
                                    selectProps={{
                                        allowClear: true
                                    }}
                                    type="province"
                                    value={input.wh_desc_location}
                                    onChange={wh_desc_location => this.changeInput({
                                        wh_desc_location
                                    })}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col lg={Number(filter.tab_on_day || 0) === 0 ? 5 : 4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_shipping_partner")}
                        labelCol={{span: 24}}>
                        <SelectSortPartnerUserContainer
                            allowClear
                            dropdownClassName="_dropdown_form_bag_shipping_container"
                            placeholder={t('bag:label.id_shipping_partner')}
                            id_shipping_user={parseInt(input.id_shipping_user, 10)}
                            id_shipping_partner={parseInt(input.id_shipping_partner, 10)}
                            onChange={this.changeShipping.bind(this)}
                        />
                    </Form.Item>
                </Col>
                {filter.tab_on_day !== '1' ? (
                    <Col lg={9} xxl={7}>
                        <Form.Item className="a-form__item search-content" label={t('bag:label.created_at')}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('transport_status')}
                        labelCol={{span: 24}}
                    >
                        <Select
                            allowClear
                            className="_status"
                            placeholder={t('select_transport_status')}
                            value={input.status}
                            onChange={status => this.changeInput({
                                status
                            })}
                        >
                            {lodash.values(STATUSES).map(status => (
                                <Option key={status} value={status}>
                                    {t(`bag:statuses.${status}`)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('bag_template')}
                        labelCol={{span: 24}}
                    >
                        <BagTemplatesSelect
                            allowClear
                            showSearch
                            bagTemplates={bagTemplates}
                            className="_id-bag-template"
                            placeholder={t('bag:label.choose_template_bag')}
                            value={input.id_bag_template}
                            onChange={id_bag_template => this.changeInput({
                                id_bag_template
                            })}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                </Col>
                {page !== 'list_bag_pack' && (
                    <Col lg={6} xl={5} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("bag:label.status_warehouse_current")}
                            labelCol={{span: 24}}>
                            <Select allowClear
                                    className="a-select a-select--search-content _status_warehouse"
                                    dropdownClassName="_dropdown_status_transport"
                                    value={input.status_warehouse}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    placeholder={t('bag:label.status_warehouse_current')}
                                    onChange={status_warehouse => this.changeInput({status_warehouse})}>
                                {lodash.values(PACKAGE_ACTION).map(action => (
                                    <Option key={action}
                                            value={action}
                                            className={"_status_warehouse__item"}>{t('package:action.' + action)}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col lg={6} xl={5}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t("bag:label.customer_code")}
                        labelCol={{span: 24}}>
                        <Search
                            value={input.customer_code || ''}
                            onChange={this.onChangeInput.bind(this, 'customer_code')}
                            placeholder={t("bag:label.customer_code")}
                            className="a-input--search  _customer_search"
                            disabled={input.type === "MULTIPLE"}
                        />
                    </Form.Item>
                </Col>
                
                <Col lg={page === 'list_bag_pack' ? 5 : 4}>
                    <Form.Item className="a-form__item search-content"
                                label={t('bag:label.bag_type')}
                                labelCol={{span: 24}}
                    >
                        <Select
                            allowClear
                            className="a-select a-select--search-content _bag_type"
                            dropdownClassName="_dropdown_bag_type"
                            placeholder={t('bag:label.bag_type')}
                            value={input.type}
                            showSearch={true}
                            optionFilterProp="children"
                            onChange={type => this.changeInput({type})}>
                            {lodash.values(BAG_TYPES).map(action => (
                                <Option key={action}
                                        value={action}
                                        className={"_bag_type__item"}>{t('bag:action.' + action)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {page !== "list_bag_pack" && <Col lg={6} xl={5} xxl={4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.id_warehouse_current")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _id-warehouse-current"
                            dropdownClassName="_dropdown_id_warehouse_current"
                            getDataWhenMounted={false}
                            loading={loadingWarehouses}
                            placeholder={t('bag:label.id_warehouse_current')}
                            value={input.id_warehouse_current}
                            warehouses={warehouses}
                            onChange={this.onChangeMultiSelect.bind(this, 'id_warehouse_current')}
                            selectProps={{
                                mode: 'multiple'
                            }}
                        />
                    </Form.Item>
                </Col>}
                <Col lg={page === 'list_bag_pack' ? 4 : 5} xxl={page === 'list_bag_pack' ? 5 : 4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.properties")}
                        labelCol={{span: 24}}>
                        <SelectProperties
                            placeholder={t("common:label.all")}
                            className="a-select a-select--search-content _properties"
                            dropdownClassName="_dropdown_properties"
                            values={input.properties || []}
                            onChange={this.onChangeMultiSelect.bind(this, "properties")}>
                        </SelectProperties>
                    </Form.Item>
                </Col>
                <Col lg={5} xxl={page === 'list_bag_pack' ? 4 : 5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("bag:label.services")}
                        labelCol={{span: 24}}>
                        <ServicesSelectContainer
                            allowClear
                            className="_services"
                            consignServices={consignServices}
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={t('label.all')}
                            services={services}
                            showSearch
                            value={input.services}
                            onChange={this.onChangeMultiSelect.bind(this, 'services')}
                        />
                    </Form.Item>
                </Col>
                {page !== 'list_bag_pack' && (
                    <Col lg={9} xxl={7}>
                        <Form.Item className="a-form__item search-content" label={t('bag:label.time_scan')}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _time-scan-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['time_scan[from]'] ? moment(input['time_scan[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'time_scan[from]')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _time-scan-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['time_scan[to]'] ? moment(input['time_scan[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'time_scan[to]')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                )}
                {page === 'list_bag_pack' ? null : (
                    <Col lg={5}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('bag:label.bagged_status')}
                            labelCol={{span: 24}}>
                            <Select
                                allowClear
                                className="_packing-complete"
                                dropdownClassName="_dropdown-packing-complete"
                                placeholder={t('bag:label.bagged_status')}
                                value={lodash.isNil(input.packing_complete) ? input.packing_complete : Number(input.packing_complete)}
                                onChange={status => this.changeInput({
                                    packing_complete: status
                                })}
                            >
                                <Option className="_packing-complete-item" value={1}>{t('bag:label.bagged')}</Option>
                                <Option className="_packing-complete-item" value={0}>{t('bag:label.not_bagged_yet')}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                <Col lg={5}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('agency')}
                        labelCol={{span: 24}}>
                        <SelectAgency
                            allowClear
                            mode="multiple"
                            optionFilterProp="children"
                            value={input.id_agencies}
                            onChange={idAgencies => this.changeInput({
                                id_agencies: idAgencies
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col lg={5} xxl={4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={<span style={{opacity: 0}}>{t("bag:label.unchecked")}</span>}
                        labelCol={{span: 24}}>
                        <Checkbox
                            className="_is-unchecked"
                            checked={input.is_unchecked === true || input.is_unchecked === 'true'}
                            onChange={this.handleChangeCheckbox.bind(this, 'is_unchecked')}
                        >{t("bag:label.unchecked")}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    render() {
        const { collapsed } = this.state;
        const { loading, t } = this.props;
        const input = converValue2Int({...this.state.input});

        return (
            <div className="a-content--search-header search-default">
                <Form onSubmit={this.onSubmit.bind(this)} className="a-form">
                    {collapsed ? this.renderCollapsedForm(input) : this.renderExpandedForm(input)}

                    <div className="gflex galign-center gjustify-space-between search--action">
                        <div className="gflex galign-center">
                            <Button
                                className="a-text--link px-0"
                                type="link"
                                onClick={this.toggleCollapseSearch}
                            >
                                {collapsed ? (
                                    <>
                                        <Icon className="mr-1" type="down" />
                                        {t('expand_search')}
                                    </>
                                ) : (
                                    <>
                                        <Icon className="mr-1" type="up" />
                                        {t('collapse_search')}
                                    </>
                                )}
                            </Button>
                        </div>
                        <div className="gflex galign-center">
                            <a
                                className="link-reload _btn-refresh-filter"
                                href="/"
                                onClick={this.onReset}
                            >
                                    <Icon type="sync" />{t('label.refresh_filter')}
                            </a>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon="search"
                                className="a-btn a-btn--primary a-btn--search-list _btn-search"
                                loading={loading}
                            >{t('btn.search')}</Button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    filter: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(withWarehouses(FormSearch, {
    getDataWhenMounted: true
}));
