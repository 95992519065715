import {notification} from 'antd';
import {get, has} from 'lodash';

import {HTC_ORDER_DETAIL_URL_BG, HTC_ORDER_DETAIL_URL_NH, PACKAGE_ACTION, TYPE_PACKAGE} from './constants';
import {t} from './../../system/i18n';
import {handleBlobErrorResponse} from './../../system/support/helpers';

export const isCreateAlonePackage = packageData => {
    return get(packageData, 'id', 0) === 0;
};

export const isAlonePackage = packageData => {
    return get(packageData, 'type') === TYPE_PACKAGE.ALONE;
};

export const isUnlinkOrder = packageData => {
    return !(get(packageData, 'id_order'));
};

export const isLinkOrder = packageData => {
    return !!get(packageData, 'id_order');
};

export const isCheckedPackage = packageData => {
    return Boolean(get(packageData, 'is_checked'));
};

export const isCreateAlonePackageAndLinkOrder = packageData => {
    return isCreateAlonePackage(packageData) && isLinkOrder(packageData);
};

export const isAlonePackageAndLinkOrder = packageData => {
    return isAlonePackage(packageData) && isLinkOrder(packageData);
};

export const isShipment = packageData => Boolean(get(packageData, 'package_detail.is_shipment'));

export const renderLengthWidthHeight = ({length, width, height}) => {
    let lengthConvert = parseFloat(length*100).toFixed(3);
    let widthConvert = parseFloat(width*100).toFixed(3);
    let heightConvert = parseFloat(height*100).toFixed(3);
    if (lengthConvert.substring(lengthConvert.indexOf('.')) === '.000') {
        lengthConvert = lengthConvert.substring(0, lengthConvert.indexOf('.'));
    }
    if (widthConvert.substring(widthConvert.indexOf('.')) === '.000') {
        widthConvert = widthConvert.substring(0, widthConvert.indexOf('.'));
    }
    if (heightConvert.substring(heightConvert.indexOf('.')) === '.000') {
        heightConvert = heightConvert.substring(0, heightConvert.indexOf('.'));
    }
    return `${lengthConvert}x${widthConvert}x${heightConvert}`;
};

export const getSplitPackageWarningMessage = warnings => {
    if (has(warnings, 'outsize_value') && has(warnings, 'outsize_weight')) {
        return t('package:message.split_package.errors.outsize_value_and_weight');
    }

    if (has(warnings, 'outsize_value')) {
        return t('package:message.split_package.errors.outsize_value');
    }

    if (has(warnings, 'outsize_weight')) {
        return t('package:message.split_package.errors.outsize_weight');
    }

    return '';
};

export const getSplitPackageWarningTranslatedKeys = warnings => {
    return Object.keys(warnings).map(key => t(`package:message.success_split_package_modal.outsize_property.${key}`));
};

export const canPrintOfficialStamp = packageData => {
    let canPrintOfficialStamp = get(packageData, 'permissions.can_print_official_stamp');

    const required_volume = get(packageData, 'required_contents', []).includes('volume');

    if (required_volume && get(packageData, 'volume') <= 0) {
        canPrintOfficialStamp = false;
    }

    return canPrintOfficialStamp;
};

export const handleDownloadTransportStatusError = error => {
    handleBlobErrorResponse(error, event => {
        const text = event.srcElement.result;
        let message = t('package:message.download_transport_status.failed');

        try {
            const errors = JSON.parse(text).data;

            if (has(errors, 'created_at.required')) {
                message = t('package:message.download_transport_status.errors.created_at.required');
            } else if (has(errors, 'created_at.max_3_month')) {
                message = t('package:message.download_transport_status.errors.created_at.max_3_month');
            } else if (has(errors, 'length_of_transport_status.required')) {
                message = t('package:message.download_transport_status.errors.length_of_transport_status.required');
            }

            notification.error({
                message
            });
        } catch (e) {
            notification.error({
                message
            });
        }
    });
};

export const isRequirePackageField = (pkg, field) => {
    return get(pkg, 'required_contents', []).includes(field);
};

export const isRequireWeightBox = pkg => {
    return isRequirePackageField(pkg, 'weight_box');
};

export const getOppositeAction = action => {
    if (action === PACKAGE_ACTION.OUT) {
        return PACKAGE_ACTION.IN;
    }

    if (action === PACKAGE_ACTION.INT) {
        return PACKAGE_ACTION.OUT;
    }

    return '';
};



export const linkHTC = (agencyCode)=>{

    if(agencyCode === "nhaphang"){
        return "http://admin.nhaphang.com"

    }

    if(agencyCode === "baogam"){
        return "http://admin.baogam.com"
    }

    return ""
}

export const isExportManifestToEmail = data => get(data, 'message') === 'send_mail';

export const handleExportManifestsError = error => {
    const reader = new FileReader();
    let message = t('package:message.error_export_manifest');

    reader.addEventListener('loadend', event => {
        const text = event.target.result;

        try {
            const parsedError = JSON.parse(text).data;

            if (get(parsedError, 'message') === 'limited') {
                message = t('package:message.export_file_errors.limit', {
                    limit: get(parsedError, 'limit')
                });
            }
        } catch (e) {
        }

        notification.error({
            message
        });
    });

    reader.readAsText(get(error, 'response.data'));
};