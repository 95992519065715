import {all, fork, takeEvery, takeLatest} from 'redux-saga/effects';
import createBagSizeWatcher from './CreateBagSize/sagaWatcher';
import * as saga from './saga';
import * as ACTION from './constants';

export default function* () {
    yield all([
        fork(createBagSizeWatcher),
    ]);
    yield takeLatest(ACTION.FETCH_DETAIL_BAG_SIZE.REQUEST, saga.fetchDetailBagSize);
    yield takeLatest(ACTION.UPDATE_BAG_SIZE.REQUEST, saga.updateBagSize);
    yield takeEvery(ACTION.UPDATE_BAG_SIZE.SUCCESS, saga.updateBagSizeSuccess);
    yield takeLatest(ACTION.FETCH_BAG_SIZE.REQUEST, saga.fetchBagSizes);
    yield takeLatest(ACTION.FETCH_BAG_SIZE_SUGGEST.REQUEST, saga.fetchSuggestBagSize);
}
