import React from 'react'
import { Col, Row, Typography, Avatar } from 'antd'
import { find, get, isObject, replace } from 'lodash'
import moment from 'moment'

import { CODE_PARTNER } from './../../../Auth/constants'

import InfoDeliveryNotePrint from './delivery-note-print-for-customer/InfoDeliveryNote'
import ListDeliveryNotePrint from './delivery-note-print-for-customer/ListDeliveryNote'

const { Paragraph, Title } = Typography

const DeliveryNotePrint = ({ deliveryNote, t, agencySetting, setting, partner }) => {
    const deliveryNoteDetail = get(deliveryNote, 'delivery_note', {})
    const day = moment().format('D')
    const month = moment().format('M')
    const year = moment().format('YYYY')
    let createdAtFormat = t('delivery_note:print.created_at_locale_format')
    createdAtFormat = replace(createdAtFormat, '%day%', day)
    createdAtFormat = replace(createdAtFormat, '%month%', month)
    createdAtFormat = replace(createdAtFormat, '%year%', year)

    let companyName = get(
        find(partner, partnerData => partnerData.code === CODE_PARTNER.ORGANIZATION_NAME),
        'value',
        ''
    )
    let companyAddr = get(
        find(partner, partnerData => partnerData.code === CODE_PARTNER.ORGANIZATION_ADDRESS),
        'value',
        ''
    )
    let organizationLogo = get(
        find(partner, partnerData => partnerData.code === CODE_PARTNER.LOGO),
        'value'
    )
    let organizationDomain = window.location.hostname
    let allowShowDomain = get(find(setting, ['code', 'DELIVERY_NOTE:DISPLAY_DOMAIN']), 'value', false)
    if (isObject(deliveryNote.customer)) {
        companyName = get(
            find(agencySetting, item => item.id_agency === get(deliveryNote.customer, 'id_agency') && item.code === 'META:ORGANIZATION_NAME'),
            'value',
            ''
        )
        companyAddr = get(
            find(agencySetting, item => item.id_agency === get(deliveryNote.customer, 'id_agency') && item.code === 'META:ORGANIZATION_ADDRESS'),
            'value',
            ''
        )

        allowShowDomain = get(
            find(agencySetting, item => item.id_agency === get(deliveryNote.customer, 'id_agency') && item.code === 'DELIVERY_NOTE:DISPLAY_DOMAIN'),
            'value',
            get(find(setting, ['code', 'DELIVERY_NOTE:DISPLAY_DOMAIN']), 'value', false)
        )
    }

    return (
        <div className="print-delivery-note-for-customer abg-white">
            <div className="print-delivery-note-for-customer--header gflex gjustify-space-between">
                <div className="header-left">
                    <div className="gflex gjustify-start">
                        {!!allowShowDomain && (
                            <Avatar
                                className="mr-2"
                                shape={'square'}
                                src={organizationLogo}
                            />
                        )}
                        <div>
                            <div>
                                <h2 className="a-text--uppercase print-header-title">{companyName}</h2>
                                <p>{!!allowShowDomain ? (companyName ? ` (${organizationDomain})` : organizationDomain) : ''}</p>
                            </div>
                            <p className="print-header-description">{companyAddr}</p>
                        </div>
                    </div>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <td>
                            <div className="page-header-space"></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="a-text--center">
                                <div>
                                    <h2 className="a-text--uppercase print-header-title">{deliveryNoteDetail.code}</h2>
                                    <p className="print-header-description">{createdAtFormat}</p>
                                </div>
                                <Title
                                    className="title"
                                    level={1}>
                                    {t('delivery_note:print.invoice_title')}
                                </Title>
                                <Paragraph className="export-type">({t(`delivery_note:print.export_for_${deliveryNoteDetail.type}`)})</Paragraph>
                            </div>

                            <Row gutter={8}>
                                <Col span={12}>
                                    <ListDeliveryNotePrint
                                        agencySetting={agencySetting}
                                        globalSetting={setting}
                                        deliveryNote={deliveryNote}
                                        t={t}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InfoDeliveryNotePrint
                                        deliveryNote={deliveryNote}
                                        t={t}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DeliveryNotePrint
