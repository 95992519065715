import React, { useCallback, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { debounce, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { formatPrice } from '../../../../../../system/support/helpers';

const EditForm = ({ agency, form, t, onChange }) => {
    const [exchangeRate, setExchangeRate] = useState(get(agency, "exchange_cny"))
    const { getFieldDecorator } = form;
    const doEmitChange = useCallback(debounce(onChange, 350), []);

    const handleChange = (field, event) => {
        doEmitChange(field, event.target.value);
    };

    const handleExchangeRate = (e) => {
        let raw = typeof e === 'string' ? e : '';
        raw = raw.replace(/[^\d,]/g, "");
        if (raw.indexOf(',') < 0 && raw.length > 0)
            raw = parseInt(raw);
        else if (raw.indexOf(',') === 0) raw = '';
        else if (raw.indexOf(',') > 0) {
            let temp = raw.split(',');
            let firstNumber = parseInt(temp[0]).toString();
            let secondNumber = temp[1];
            if (secondNumber.length > 4) {
                secondNumber = secondNumber.substring(0, 4)
            }
            raw = `${firstNumber},${secondNumber}`;
        }
        setExchangeRate(raw.toString())
    };

    const handleUpdateExchangeRate = () => {
        if (!exchangeRate || exchangeRate.replace(/[^1-9]/g, "") === '') return;
        let value = exchangeRate.replace(/[.]/g, '');
        value = value.replace(/[,]/g, '.');
        doEmitChange("exchange_cny", value);
    }

    return (
        <Row gutter={{lg: 24, xl: 32}}>
            <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                <Form.Item
                    className="a-form__item"
                    label={t('organization_or_company_name')}
                    labelCol={{span: 24}}
                >
                    {getFieldDecorator('agency_organization_name', {
                        initialValue: agency.organization_name
                    })(
                        <Input
                            className="a-input _organization-name"
                            placeholder={t('input.organization_or_company_name')}
                            onChange={handleChange.bind(this, 'agency_organization_name')}
                        />
                    )}
                </Form.Item>
            </Col>

            <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                <Form.Item
                    className="a-form__item"
                    label={t('agency:label.alias')}
                    labelCol={{span: 24}}
                >
                    {getFieldDecorator('agency_identify_name', {
                        initialValue: agency.identify_name
                    })(
                        <Input
                            className="a-input _identify-name"
                            placeholder={t('agency:input.alias')}
                            onChange={handleChange.bind(this, 'agency_identify_name')}
                        />
                    )}
                </Form.Item>
            </Col>

            <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                <Form.Item
                    className="a-form__item"
                    label={t('agency:label.phone')}
                    labelCol={{span: 24}}
                >
                    {getFieldDecorator('agency_organization_phone', {
                        initialValue: agency.organization_phone,
                    })(
                        <Input
                            className="a-input _organization-phone"
                            placeholder={t('agency:input.phone')}
                            onChange={handleChange.bind(this, 'agency_organization_phone')}
                        />
                    )}
                </Form.Item>
            </Col>

            <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                <Form.Item
                    className="a-form__item"
                    label={t('agency:label.exchange_rate')}
                    labelCol={{span: 24}}
                >
                    <Input
                        value={formatPrice(exchangeRate)}
                        className="a-input _exchange_rate"
                        placeholder={t('agency:input.exchange_rate')}
                        onChange={(e) => handleExchangeRate(e.target.value)}
                        onPressEnter={handleUpdateExchangeRate}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default withTranslation()(Form.create()(EditForm));
