import {all, fork, takeLatest} from 'redux-saga/effects';
import orderPackages from './OrderPackages/sagaWatcher';
import * as ACTION from "./constants";
import * as saga from "./saga";

export default function*() {
    yield all([
        fork(orderPackages),
    ]);

    yield takeLatest(ACTION.GET_SUGGEST_ORDERS.REQUEST, saga.getSuggestOrders);
}
