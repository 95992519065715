import React, { Component } from 'react';
import { Button, Empty } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import apiService from './../../apiService';
import { DELIVERY_NOTE_STATUS, LIST_PROPERTY_ALLOW_DISPLAY } from './../../constants';
import { handlePlaceError } from './../../helper';
import { successCancelDeliveryNote } from './../../CancelDeliveryNote/actions';
import { successExportDeliveryNote } from './../../DeliveryNoteExport/actions';
import { url } from './../../../../system/routing';

import ItemBagDeliveryNoteExport from './ItemBagDeliveryNoteExport';
import ItemDeliveryNoteExport from './ItemDeliveryNoteExport';
import CancelDeliveryNoteContainer from './../../CancelDeliveryNote/CancelDeliveryNoteContainer';
import DeliveryNoteExportContainer from './../../DeliveryNoteExport/DeliveryNoteExportContainer';

class ListDeliveryNoteExport extends Component {
    state = {
        visible: false,
        placing: false
    }

    onEdit = () => {
        url.redirectTo('delivery-notes.customer.edit', {
            id: lodash.get(this.props.deliveryNote, 'delivery_note.id', 0)
        });
    };

    onPrint = () => {
        this.props.printIssue();
    };

    handleSuccessExportDeliveryNote = data => {
        const { successExportDeliveryNote } = this.props;

        successExportDeliveryNote(data);
    };

    handleSuccessCancelDeliveryNote = data => {
        const { successCancelDeliveryNote } = this.props;

        successCancelDeliveryNote(data);
    };

    place = async () => {
        const { placing } = this.state;
        const { deliveryNote } = this.props;
        const deliveryNoteId = lodash.get(deliveryNote, 'delivery_note.id');
        const status = lodash.get(deliveryNote, 'delivery_note.status');

        if (placing) {
            return;
        }

        this.setState({
            placing: true
        });

        if ([DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING, DELIVERY_NOTE_STATUS.EXPORTED].includes(status)) {
            url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
                id: deliveryNoteId
            });
        } else {
            try {
                await apiService.place(deliveryNoteId);

                url.redirectTo('delivery-notes.customer.domestic-shipping-orders', {
                    id: deliveryNoteId
                });
            } catch (error) {
                handlePlaceError(error);
            }
        }

        this.setState({
            placing: false
        });
    };

    render() {
        const { placing } = this.state;
        let { deliveryNote, t, bags, customer, globalSetting } = this.props;
        const isLastmileShipping = lodash.get(lodash.find(globalSetting, ["code", "CONFIG:IS_LASTMILE_SHIPPING"]), "value", );
        const packages = lodash.get(deliveryNote, 'delivery_note_packages') || [];
        bags = bags || [];
        const permissions = lodash.get(deliveryNote, 'permissions');
        const status = lodash.get(deliveryNote, 'delivery_note.status');
        const canPrint = lodash.get(permissions, 'can_print');
        const isShowAddress = lodash.get(deliveryNote, 'customer_info.address', []).length > 1;
        const total = lodash.get(deliveryNote, 'delivery_note.total_number');
        let hasPropertyAllowDisplay = false;
        if (!lodash.isEmpty(packages)) {
            for (let i = 0; i < packages.length; i++) {
                const properties = lodash.map(lodash.get(packages[i], "properties", []), "code").map(x => x.toUpperCase())
                hasPropertyAllowDisplay = properties.some(property => LIST_PROPERTY_ALLOW_DISPLAY.includes(property))
                if (hasPropertyAllowDisplay) break;
              }    
        }
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0 mt-0">
                    <div className="a-content--page-list__title">
                        <h3>{t('delivery_note:label.list_package_export')} ({total})</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                    {
                        status === "EXPORTED" && isLastmileShipping && <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                                    onClick={this.props.printFBU}
                            >{t('delivery_note:label.print_FBU')}
                            </Button>
                    }
                        {status === "EXPORTED" && canPrint === 1 && (
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                                    onClick={this.onPrint}
                            >{t('delivery_note:label.print')}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  mr-0 aflex-1" style={{marginBottom: "2.5rem"}}>
                    <div className="a-table--responsive">
                        {packages.length || bags.length ?
                            <table className="a-table a-table--isdata--warning-error">
                                <thead>
                                <tr>
                                    <th>{t('delivery_note:label.export_code')}</th>
                                    <th>{t('delivery_note:label.delivery_request_code')}</th>
                                    <th>{t('delivery_note:label.order_code')}</th>
                                    <th>{t('delivery_note:label.address')}</th>
                                    <th>{t('delivery_note:label.weight')}</th>
                                    <th>{t('delivery_note:label.size')}</th>
                                    {
                                        hasPropertyAllowDisplay && 
                                        <th>{t('package:label.properties')}</th>
                                    }
                                    <th />
                                </tr>
                                </thead>

                                {bags.map((object, index) => (
                                    <ItemBagDeliveryNoteExport
                                        {...this.props}
                                        key={index}
                                        object={object}
                                        size={object.bag_size}
                                        onClickIconList={this.props.togglePopupListPackageByBag}
                                        isShowAddress={isShowAddress}
                                        customer={customer}
                                    />
                                ))}

                                {packages.map((object, index) => (
                                    <ItemDeliveryNoteExport
                                        {...this.props}
                                        key={index}
                                        object={object}
                                        onShowLog={this.onShowLog}
                                        isShowAddress={isShowAddress}
                                        customer={customer}
                                    />
                                ))}
                            </table> :
                            <div className={"mb-3"}>
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('common:message.no_data')}</span>}
                                >
                                </Empty></div>}

                    </div>
                </div>
                <div>
                    {(!!permissions.can_update || !!permissions.can_export || !!permissions.can_cancel || !!permissions.can_place || [DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(status) || !!(DELIVERY_NOTE_STATUS.EXPORTED === status && lodash.get(deliveryNote, 'delivery_note.id_last_mile_carrier'))) && (
                        <div
                            style={{zIndex: 9999}}
                            className={clsx('a-pagination a-pagination--fixed a-pagination--height-action gflex p-5', {
                                'gjustify-space-between': !!permissions.can_update,
                                'gjustify-start': !!(!permissions.can_place && !permissions.can_cancel && !permissions.can_export && ([DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(status) || (DELIVERY_NOTE_STATUS.EXPORTED === status && lodash.get(deliveryNote, 'delivery_note.id_last_mile_carrier')))),
                                'gjustify-end': !!(!permissions.can_update && permissions.can_place)
                            })}
                        >
                            <ul className="bottom-action-list">
                                {!!permissions.can_update && (
                                    <li>
                                        <Button
                                            onClick={this.onEdit}
                                            className="a-btn a-btn--button-link a-text--blue ">{t('delivery_note:label.edit')}
                                        </Button>
                                    </li>
                                )}
                                {!!([DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(status) || (DELIVERY_NOTE_STATUS.EXPORTED === status && lodash.get(deliveryNote, 'delivery_note.id_last_mile_carrier'))) && (
                                    <li>
                                        <Button
                                            className="a-btn a-btn--button-link a-text--blue _btn-shipping-order-list"
                                            onClick={this.place}
                                        >
                                            {t('shipping_order_list')}
                                        </Button>
                                    </li>
                                )}
                            </ul>
                            <div>
                                {!!permissions.can_cancel && (
                                    <CancelDeliveryNoteContainer
                                        deliveryNoteData={lodash.get(deliveryNote, 'delivery_note')}
                                        onSuccessCancelDeliveryNote={this.handleSuccessCancelDeliveryNote}
                                    />
                                )}

                                {!!permissions.can_place && (
                                    <Button
                                        className={clsx('a-btn a-btn--primary a-btn--save-export _btn-place', {
                                            'mr-4': !!permissions.can_export
                                        })}
                                        loading={placing}
                                        size="large"
                                        type="primary"
                                        onClick={this.place}
                                    >
                                        {t('delivery_note:place.title')}
                                    </Button>
                                )}
                                {!!permissions.can_export && (
                                    <DeliveryNoteExportContainer
                                        bagTotal={bags.length}
                                        className="ant-btn a-btn a-btn--primary a-btn--save-export"
                                        deliveryNoteData={lodash.get(deliveryNote, 'delivery_note')}
                                        packageTotal={packages.length}
                                        size="large"
                                        type="primary"
                                        onSuccessExportDeliveryNote={this.handleSuccessExportDeliveryNote}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

ListDeliveryNoteExport.defaultProps = {
    permission: []
};

ListDeliveryNoteExport.propTypes = {
    permission: PropTypes.array
};

const mapDispatchToProps = {
    successCancelDeliveryNote,
    successExportDeliveryNote
};


export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(ListDeliveryNoteExport));
