import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import apiService from './../../../apiService';
import { handleAddReExecutionError } from './../../../helper';
import { getReExecutions } from './../../../re-execution/actions';
import { getState, getTableDisplayReExecutions } from './../../../re-execution/selectors';

import EditReExecutionModal from './EditReExecutionModal';
import ReExecutionTable from './ReExecutionTable';
import SearchForm from './SearchForm';
import SearchableComponent from './../../../../Common/components/SearchableComponent';

class ReExecutions extends SearchableComponent {
    state = {
        adding: false,
        editModalVisible: false,
        filter: {},
        selectedReExecution: {}
    };

    handleChangePage = pagination => {
        const { filter } = this.state;

        this.pushFilter({
            ...filter,
            page: pagination.current,
            per_page: pagination.pageSize
        });
    };

    onChangeFilter = filter => {
        const { getReExecutions, location } = this.props;

        getReExecutions(filter);

        this.setState({
            filter: this.getFilterFromLocation(location)
        });
    };

    handleSearch = filter => {
        const { pagination } = this.props;

        this.pushFilter({
            ...filter,
            page: pagination.page ? 1 : undefined,
            per_page: pagination.per_page
        });

        this.setState({
            filter
        });
    };

    handleRefreshFilter = () => {
        this.setState({
            filter: {}
        });

        this.pushFilter();
    };

    handleCreateButtonClick = () => {
        this.setState({
            editModalVisible: true
        });
    };

    handleCancelEditReExecution = () => {
        this.setState({
            editModalVisible: false,
            selectedReExecution: {}
        });
    };

    handleSubmit = async values => {
        const { adding, filter } = this.state;
        const { getReExecutions, pagination } = this.props;

        if (adding) {
            return;
        }

        try {
            this.setState({
                adding: true
            });

            await apiService.addReExecution(values);

            this.handleCancelEditReExecution();
            getReExecutions({
                ...filter,
                ...pagination
            });
        } catch (error) {
            handleAddReExecutionError(get(error, 'response.data'));
        }

        this.setState({
            adding: false
        });
    };

    componentDidUpdate(prevProps) {
        const { editModalVisible } = this.props;

        super.componentDidUpdate(prevProps);

        if (editModalVisible !== prevProps.editModalVisible) {
            this.handleCreateButtonClick();
        }
    }

    render() {
        const { adding, editModalVisible, filter, selectedReExecution } = this.state;
        const { loading, pagination, tableDisplayReExecutions } = this.props;

        return (
            <div className="a-content__body bg-white a-content__body--page-list mt-0 height-100">
                <SearchForm
                    filter={filter}
                    onRefresh={this.handleRefreshFilter}
                    onSearch={this.handleSearch}
                />

                <EditReExecutionModal
                    loading={adding}
                    reExecution={selectedReExecution}
                    visible={editModalVisible}
                    onCancel={this.handleCancelEditReExecution}
                    onOk={this.handleSubmit}
                />

                <ReExecutionTable
                    loading={loading}
                    pagination={pagination}
                    reExecutions={tableDisplayReExecutions}
                    onChangePage={this.handleChangePage}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: getState(state, 'loading'),
        pagination: getState(state, 'pagination'),
        tableDisplayReExecutions: getTableDisplayReExecutions(state),
    };
};

const mapDispatchToProps = {
    getReExecutions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReExecutions);
