import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {Checkbox, Form, Input, Select} from 'antd';
import InlineEdit from '../../../Common/components/InlineEdit';
import FormProperties from '../../../Property/FormProperties/FormPropertiesContainer';
import SelectBagSizeContainer from "../../../BagSize/SelectBagSize/SelectBagSizeContainer";
import ChooseWarehouse from "../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer";
import ListUsingServices from '../../../Service/components/ListUsingServices';
import FormServicesContainer from "../../../Service/FormServices/FormServicesContainer";
import FormBagShippingTypeContainer from "../../BagShippingType/FormBagShippingType/FormBagShippingTypeContainer";
import SelectCreatePartnerContainer from "../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer";
import SelectCreateShippingUserContainer from "../../../ShippingUser/Select/SelectCreate";
import SelectCustomerContainer from "../../../Customer/SelectCustomer/SelectCustomerContainer";
import Icon from './../../../../components/icons/Icon';
import {formatWeight, showAliasAgency} from './../../../../system/support/helpers';

import {STATUSES} from './../../constants'
import { getState } from '../../../../system/store';
import permissions from '../../../Auth/permissions';

const TextArea = Input.TextArea;
const Option = Select.Option;

class UpdateBagParam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version: null,
            editing: false,
            value: null,
            input: props.input
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.version === nextProps.version) {
            if (nextProps.param === 'id_customer') {
                let editing = (nextProps.input.onEditCustomer || nextProps.input.onEditType) ? (nextProps.input.bagType === 'SINGLE') : false;
                let value = nextProps.input.bagType === 'SINGLE' ? ((lodash.isNil(prevState.value) || prevState.value === '') ? nextProps.bag.id_customer : prevState.value) : '';
                return {
                    editing,
                    value,
                    input: {
                        ...nextProps.input,
                    },
                }
            }
            return null;
        }
        if (nextProps.param === 'type') {
            nextProps.onChangeValue({
                bagType: nextProps.input.bagType === "" ? nextProps.bag.type : nextProps.input.bagType,
                onEditType: false
            })
        }
        if (nextProps.param === 'id_customer') {
            nextProps.onChangeValue({
                onEditCustomer: false
            })
        }
        return {
            version: nextProps.version,
            editing: false,
            value: UpdateBagParam.getParamValue(nextProps.bag, nextProps.param),
        };
    }

    static getParamValue(bag, param) {
        switch (param) {
            case 'properties':
                return lodash.map(bag.properties, 'id');
            case 'services':
                return lodash.map(bag.services, 'id');
            default:
                return bag[param];
        }
    }

    changeValue = (value, params) => {
        this.setState({
            value
        });

        if (params === 'type') {
            this.props.onChangeValue({
                ...this.props.input,
                bagType: value,
                id_customer: value === 'SINGLE' ? UpdateBagParam.getParamValue(this.props.bag, 'id_customer') : ''
            })
        }
        if (params === 'id_customer' || params === 'id_warehouse_input') {
            this.props.onChangeValue({
                ...this.props.input,
                [params]: value
            });
        }
        if (this.props.error) {
            this.props.resetError();
        }
    };

    onEdit() {
        this.setState({editing: !this.state.editing});
        if (this.props.param === 'shipping_type') {

        }
        if (this.props.param === 'type') {
            this.props.onChangeValue({
                bagType: UpdateBagParam.getParamValue(this.props.bag, 'type'),
                id_customer: UpdateBagParam.getParamValue(this.props.bag, 'id_customer'),
                onEditType: true
            })
        }
        if (this.props.param === 'id_customer') {
            this.props.onChangeValue({
                ...this.props.input,
                onEditCustomer: true
            })
        }
    }

    onSubmit(event) {
        event.preventDefault();
        this.submitForm();
    }

    submitForm() {
        const { form, loading } = this.props;

        if (loading) {
            return;
        }

        form.validateFields((error, values) => {
            if (error) {
                return;
            }

            const { value } = this.state;
            const { bag, param, input, updateBagParam } = this.props;
            let data = {};

            switch (param) {
                case 'type': {
                    if (value === "SINGLE") {
                        data.id_customer = input.id_customer === 0 ? null : input.id_customer
                    }
                    data.type = value;
                    data = lodash.pickBy(data, lodash.identity);
                    updateBagParam(data);
                    break;
                }
                case  'id_customer': {
                    data.id_customer = value;
                    updateBagParam(data);
                    break;
                }
                case 'status': {
                    const { status } = values;

                    if (status === lodash.get(bag, param)) {
                        this.onCancel();
                    } else {
                        updateBagParam(status);
                    }

                    break;
                }
                default: {
                    updateBagParam(this.state.value);
                    break;
                }
            }
        });
    }

    onCancel() {
        if (this.props.loading) {
            return;
        }
        if (this.props.param === 'id_customer') {
            this.props.onChangeValue({
                ...this.props.input,
                onEditCustomer: false,
                id_customer: UpdateBagParam.getParamValue(this.props.bag, this.props.param)
            })
        }
        if (this.props.param === 'type') {
            this.props.onChangeValue({
                bagType: UpdateBagParam.getParamValue(this.props.bag, 'type'),
                id_customer: UpdateBagParam.getParamValue(this.props.bag, 'id_customer'),
                onEditType: false
            })
        }

        this.setState({
            editing: false,
            value: UpdateBagParam.getParamValue(this.props.bag, this.props.param),
        });

        if (this.props.error) {
            this.props.resetError();
        }
    }

    render() {
        let {param} = this.props;
        let className = [];
        className.push(`_${param}`);
        if (this.props.param === 'services') {
            if (this.state.editing) {
                className.push('service-edit');
            } else {
                className.push('service-view');
            }
        }

        if (this.props.className) {
            className.push(this.props.className);
        }

        className = lodash.uniq(className).join(' ');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <InlineEdit
                    className={className}
                    label={this.props.t('bag:label.' + (this.props.param === 'type' ? 'bag_type' : this.props.param === 'id_shipping_user' || this.props.param === 'id_shipping_partner' ? 'shipping_name' : this.props.param))}
                    value={this.renderValue()}
                    form={() => (
                        <React.Fragment>
                            {this.renderForm()}
                            {this.props.error ? <p className="a-text--error mt-1">{this.props.error}</p> : null}
                        </React.Fragment>
                    )}
                    param={param}
                    editing={this.state.editing}
                    loading={this.props.loading}
                    canEdit={this.props.canUpdate}
                    status={this.props.status}
                    onEdit={this.onEdit.bind(this)}
                    onSubmit={this.submitForm.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    bag={this.props.bag}
                    input={this.props.input}
                />
            </Form>
        );
    }

    renderValue() {
        let {bag, param, t} = this.props;
        const showAlias = showAliasAgency(getState('setting.agencySetting'),  getState('setting.setting'), permissions.CONFIG_SHOW_AGENCY_ALIAS)

        switch (param) {
            case 'properties':
                return lodash.map(bag.properties, (property, index) => (
                    <Fragment key={property.id}>
                        <Icon className="mr-2" type={property.icon} />
                        {property.name}
                        {index === bag.properties.length - 1 ? null : ' / '}
                    </Fragment>
                ) || '--');
            case 'id_bag_size':
                return lodash.get(bag, 'bag_size.name') || '--';
            case 'id_warehouse_input':
                return lodash.get(bag, 'warehouse_input.code') || '--';
            case 'id_warehouse_destination':
                return lodash.get(bag, 'destination_warehouse.code') || '--';
            case 'services':
                return <ListUsingServices services={bag.services}/>;
            case 'shipping_type':
                return lodash.get(bag, "shippingType") === 'PARTNER' ? t('bag:label.id_shipping_partner') : lodash.get(bag, "shippingType") === 'USER' ? t('bag:label.id_shipping_user') : "--";
            case 'id_shipping_user':
                let username = " (" + (!lodash.isNil(lodash.get(bag, 'shipping_user.username')) ? lodash.get(bag, 'shipping_user.username', '--') : '--') + ")";
                return !lodash.isNil(bag.shipping_user) ? (lodash.get(bag, 'shipping_user.full_name', '--') + username) : '--';
            case 'id_shipping_partner':
                let code = " (" + (!lodash.isNil(lodash.get(bag, 'shipping_partner.name')) ? lodash.get(bag, 'shipping_partner.code', '--') : '--') + ")";
                return !lodash.isNil(bag.shipping_partner) ? (lodash.get(bag, 'shipping_partner.name', '--') + code) : '--';
            case 'type':
                return bag.type ? t('bag:label.' + bag.type.toLowerCase() + '_bag') : '--';
            case 'id_customer':
                return this.props.input.onEditType ? (this.props.input.bagType === 'MULTIPLE' ? '--' : `${lodash.get(bag.customer, 'username', '--')} (${lodash.get(bag.customer, 'code', '--')})`) : `${lodash.get(bag.customer, 'username', '--')} (${lodash.get(bag.customer, 'code', '--')})`;
            case 'is_unchecked':
                return (
                    <Checkbox
                        checked={bag.is_unchecked}
                    />
                );
            case 'id_agency': {
  

                return <span>
                {lodash.get(bag, 'agency.name', '--')} {lodash.get(bag, 'agency.identify_name') && showAlias
                ? `(${lodash.get(bag, 'agency.identify_name')})` : null}</span>;
            }
            case 'id_bag_area': {
                const bagAreaName = lodash.get(bag, 'bag_area.name');
                const bagAreaCode = lodash.get(bag, 'bag_area.code');

                if (bagAreaName) {
                    return `${bagAreaName} (${bagAreaCode})`;
                }

                return '--';
            }
            case 'status': {
                const status = lodash.get(bag, 'status');

                if (status) {
                    return t(`bag:statuses.${status}`);
                }

                return '--';
            }
            case 'weight_real': {
                const weightReal = lodash.get(bag, 'weight_real');

                return weightReal ? formatWeight(weightReal) : '--';
            }
            default:
                return bag[param] || '--';
        }
    }

    renderForm() {
        const { agencies, bag, bagAreas, form, getPartnersByWarehouse, param, required, t, warehouseId } = this.props;
        const bagType = [
            {key: 1, label: t('bag:label.multi_bag'), value: "MULTIPLE"},
            {key: 2, label: t('bag:label.single_bag'), value: "SINGLE"}
        ];
        const {getFieldDecorator} = form;
        let defaultPlaceholder = "";
        switch (param) {
            case 'shipping_type':
                return <FormBagShippingTypeContainer
                    value={this.state.value}
                    disabled={false}
                    autoSelect={true}
                    onChange={value => this.changeValue(value)}
                    dropdownClassName={'_dropdown_subject_shipping'}
                    noForm={true}
                />;
            case 'id_shipping_user':
                defaultPlaceholder = t('shipping_user:placeholder.id_shipping_user');
                let full_name = lodash.get(bag, "shipping_user.full_name", "--");
                let user_name = lodash.get(bag, "shipping_user.full_name", "--") + "(" + lodash.get(bag, "shipping_user.username", "--") + ")";
                let name = lodash.get(bag, 'shipping_user.username') ? user_name : full_name;
                let placeholderShippingUser = bag.shipping_user ? name : defaultPlaceholder;
                return <SelectCreateShippingUserContainer
                    placeholder={placeholderShippingUser}
                    autoSelect={false}
                    emptyOption={false}
                    disabled={this.props.loading}
                    loading={this.props.loading}
                    className="a-select a-select--search-content _id_shipping_user"
                    value={this.state.value}
                    onChange={value => this.changeValue(value)}
                />;
            case 'id_shipping_partner':
                defaultPlaceholder = t('shipping_partner:placeholder.id_shipping_partner');
                let placeholderShippingPartner = bag.shipping_partner ? lodash.get(bag, "shipping_partner.name", "--") : defaultPlaceholder;
                return (
                    <SelectCreatePartnerContainer
                        autoSelect={false}
                        disabled={false}
                        getPartnersByWarehouse={getPartnersByWarehouse}
                        placeholder={placeholderShippingPartner}
                        value={this.state.value || ''}
                        warehouseId={warehouseId}
                        onChange={shipping_partner => this.changeValue(shipping_partner.value)}
                    />
                );
            case 'services':
                return <FormServicesContainer
                    disabled={this.props.loading}
                    loading={this.props.loading}
                    values={this.state.value}
                    onChange={services => this.changeValue(services)}
                />;
            case 'id_bag_size':
                return <SelectBagSizeContainer
                    value={this.state.value || ''}
                    autoSelect={false}
                    disabled={false}
                    loading={false}
                    onChange={size => this.changeValue(size.value)}
                />;

            case 'properties':
                return <FormProperties
                    values={this.state.value}
                    onChange={value => this.changeValue(value)}
                />;

            case 'id_warehouse_input':
                return <ChooseWarehouse
                    emptyOption={false}
                    isSelect
                    active={true}
                    className="a-select a-select--search-content"
                    value={this.state.value || ''}
                    onChange={value => this.changeValue(value, 'id_warehouse_input')}
                />;
            case 'id_warehouse_destination':
                return <ChooseWarehouse
                    emptyOption={false}
                    isPermission={false}
                    isSelect
                    active={true}
                    className="a-select a-select--search-content"
                    value={this.state.value || ''}
                    onChange={value => this.changeValue(value)}
                />;

            case 'note':
                return <TextArea
                    className="a-input--textarea"
                    autosize={{minRows: 1, maxRows: 3}}
                    allowClear={true}
                    spellCheck={false}
                    value={this.state.value}
                    onChange={event => this.changeValue(event.target.value)}
                />;
            case 'type':
                let placeholderBagType = lodash.get(bag, 'type', '--');
                return <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={value => this.changeValue(value, 'type')}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={bagType[0].value}
                    value={this.state.value}
                    placeholder={placeholderBagType}
                    className="_type-bag"
                    dropdownClassName={'_dropdown_type-bag'}
                >
                    {bagType.map(type => (
                        <Option
                            key={type.key}
                            value={type.value}
                            className={"_type-bag__item"}
                        >{type.label}</Option>
                    ))}
                </Select>;
            case 'id_customer':
                return <SelectCustomerContainer
                    autoSelect={true}
                    emptyOption={false}
                    is_shipper={true}
                    showName={true}
                    className="a-select a-select--search-content _id_customer"
                    value={this.state.value || ''}
                    onChange={value => this.changeValue(value, 'id_customer')}
                    dropdownClassName={'_dropdown_id_customer'}
                />;

            case 'is_unchecked':
                return (
                    <Checkbox
                        checked={this.state.value || false}
                        onChange={e => this.changeValue(e.target.checked)}
                    />
                );
            case 'id_agency': {
                return (
                    <Form.Item>
                        {getFieldDecorator('id_agency', {
                            initialValue: this.state.value || undefined,
                            rules: [{
                                required,
                                message: t('validation:required', {
                                    attribute: t('bag:label.agency')
                                })
                            }]
                        })(
                            <Select
                                allowClear
                                className="_bag-agencies"
                                dropdownClassName="_dropdown_agencies"
                                // mode="multiple"
                                optionFilterProp="children"
                                onChange={this.changeValue}
                            >
                                {agencies.map(agency => (
                                    <Option
                                        className={`_dropdown_option_${agency.agency.id}`}
                                        key={agency.agency.id}
                                        value={agency.agency.id}
                                    >
                                        {agency.agency.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                );
            }
            case 'id_bag_area': {
                return (
                    <Form.Item>
                        {getFieldDecorator('id_bag_area', {
                            initialValue: this.state.value || undefined,
                            rules: [{
                                required,
                                message: t('validation:required', {
                                    attribute: t('bag:label.bag_area')
                                })
                            }]
                        })(
                            <Select
                                allowClear
                                className="_bag-area"
                                dropdownClassName="_dropdown_bag_area"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                optionFilterProp="children"
                                showSearch
                                onChange={this.changeValue}
                            >
                                {bagAreas.map(area => (
                                    <Option
                                        className={`_dropdown_option_${area.bag_area.id}`}
                                        key={area.bag_area.id}
                                        value={area.bag_area.id}
                                    >
                                        {`${lodash.get(area, 'bag_area.name')} (${lodash.get(area, 'bag_area.code')})`}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                );
            }
            case 'status': {
                return (
                    <Form.Item>
                        {getFieldDecorator('status', {
                            initialValue: this.state.value || STATUSES.ARCHIVED,
                            rules: [{
                                required,
                                message: t('validation:required', {
                                    attribute: t('bag:label.status')
                                })
                            }]
                        })(
                            <Select
                                className="_status"
                                dropdownClassName="_dropdown-status"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                optionFilterProp="children"
                                showSearch
                                onChange={this.changeValue}
                            >
                                {lodash.values(STATUSES).map(status => [this.state.value, STATUSES.ARCHIVED].includes(status) ? (
                                    <Option className="_status-item" key={status} value={status}>
                                        {t(`bag:statuses.${status}`)}
                                    </Option>
                                ) : null)}
                            </Select>
                        )}
                    </Form.Item>
                );
            }

            default:
                return <Input
                    className={`_${param}-input`}
                    value={this.state.value || ''}
                    onChange={event => this.changeValue(event.target.value)}
                />;
        }
    }
}

UpdateBagParam.defaultProps = {
    className: "",
    version: null,
    bag: {},
    param: null,
    loading: false,
    error: null,
    canUpdate: false,
    status: false,
    input: {},
    onUpdate: () => {
    },
    onChangeValue: () => {
    },

};

UpdateBagParam.propTypes = {
    version: PropTypes.any,
    bag: PropTypes.object,
    param: PropTypes.string,
    input: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    canUpdate: PropTypes.bool,
    onUpdate: PropTypes.func,
    onChangeValue: PropTypes.func,
};

export default withTranslation()(Form.create()(UpdateBagParam));
