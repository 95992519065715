export const FETCH_HISTORIES = {
    REQUEST: 'BARCODE.SCANNING_HISTORY.FETCH_HISTORIES.REQUEST',
    SUCCESS: 'BARCODE.SCANNING_HISTORY.FETCH_HISTORIES.SUCCESS',
    FAILED: 'BARCODE.SCANNING_HISTORY.FETCH_HISTORIES.FAILED',
};

export const HISTORY_COLUMN = {
    BARCODE: 'barcode',
    SCAN_TIME: 'created_at',
    BARCODE_TYPE: 'barcode_type',
    TYPE_SCAN: 'type_scan',
    PACKAGE: 'package',
    ACTION: 'action',
    WAREHOUSE_SCAN: 'warehouse_scan',
    WAREHOUSE_DESTINATION: 'warehouse_destination',
    ORDER: 'order',
    CREATOR: 'creator',
};
