import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Menu, Dropdown, Icon, Checkbox} from 'antd';
import {TYPES} from "../../constants";

class ListBagSetting extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {isOpen: false, object: props.object, isCurrentPage: props.isCurrentPage};
    }

    onChangeOption(optionKey, event) {
        let values = event.target.checked
            ? [...this.props.values, optionKey]
            : this.props.values.filter(value => value !== optionKey);

        this.props.onChange(values);
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        if (nextState.object !== props.object) {
            nextState.object = props.object;
            nextState.isOpen = false
        } else {
            nextState.isOpen = nextState.object === props.isCurrentPage ? props.isOpenListSetting : false;
            nextState.isCurrentPage = props.isCurrentPage
        }
        return nextState;
    }
    render() {
        const { t } = this.props;

        return (
            <Dropdown
                visible={this.state.isOpen}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field"
            >
                <Button
                    onClick={() => this.props.onOpenListSetting(true, this.state.object)}
                    type="primary"
                    icon="setting"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost
                >{t('setting_info_column')}</Button>
            </Dropdown>
        );
    }

    renderMenu() {
        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={() => this.props.onOpenListSetting(false, this.state.object)} type="close"/>
                </div>
                {this.props.options.map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={this.props.values.includes(option.key)}
                            disabled={option.disabled}
                            onChange={this.onChangeOption.bind(this, option.key)}
                        >{this.props.t('bag:column.' + option.key)}</Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
}

ListBagSetting.defaultProps = {
    options: [],
    values: [],
    isOpenListSetting: false,
    isCurrentPage: TYPES.ALL,
    onOpenListSetting: () => {
    },
    onChange: (values) => {},
};

ListBagSetting.propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    onOpenListSetting: PropTypes.func,
    isCurrentPage: PropTypes.string,
    isOpenListSetting: PropTypes.bool
};

export default withTranslation()(ListBagSetting);
