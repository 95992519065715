import * as ACTION from './constants';
import apiService from '../apiService';
import {processApiRequest} from '../../Common/saga';
import {notification} from "antd";
import {t} from "../../../system/i18n";

export function* fetchBags(action) {
    yield processApiRequest(ACTION.FETCH_BAGS, () => apiService.getBags(action.payload), action.payload);
}

export function* exportBags(action) {
    yield processApiRequest(ACTION.EXPORT_BAGS_CSV, () => apiService.exportBagCsv(action.payload), action.payload);
}

export function* exportBagsSuccess() {
    yield  notification['success']({
        message: t('message.export_csv_success'),
    });
}

export function* exportBagsFailed(action) {
    if (action.status_code !== 403) {
        const message = t('message.export_csv_failed');

        yield  notification['error']({
            message: message
        });
    }
}
