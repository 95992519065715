import {combineReducers} from "redux";
import * as ACTION from '../constants';
import lodash from 'lodash';

let errors = (state = [], action) => {
	let {type, payload} = action;
	switch (type) {
		case  ACTION.CREATE_PROPERTY.REQUEST:
		case  ACTION.UPDATE_PROPERTY.REQUEST:
		case  ACTION.CLEAR_STATE:
			return {};
		case ACTION.CREATE_PROPERTY.FAILED:
		case ACTION.UPDATE_PROPERTY.FAILED:
			return {...payload};
		case ACTION.RESET_ERROR:
			let error = {...state};
			return {...error, data: lodash.omit(error.data, payload.params)};
		default:
			return state

	}
};
export default combineReducers({errors})
