import {api} from '../../system/api';

export default {
    createBag: (params) => api.post('bags', {...params}, {singleRequest: true}),
    getBags: (params) => api.get('bags', {params}, {singleRequest: true}),
    getBag: (id) => api.get(`bags/${id}`, {singleRequest: true}),
    getSameDayBags: (id) => api.get(`bags/${id}/bag-in-day`, {singleRequest: true}),
    updateBag: (id, data) => api.put(`bags/${id}`, data),
    detachPackage: (bagId, packageId) => api.put(`bags/${bagId}/packages/${packageId}/remove`),
    createSimilarBag: (id) => api.post(`/bags/${id}/create-same-bag`),
    getTemplateBags: (params) => api.get('bag-templates/suggest', {params}),
    createTemplateBag: (payload) => api.post(`/bag-templates/create-from-bag`, {...payload}),
    exportBagExcel: (params) => api.get(`bags/export`, {
        params,
        responseType: 'blob'
    }),
    getInternalCommentsByCode: (bagId) => api.get(`bags/${bagId}/comments`),
    addInternalCommentByCode: (bagId, data) => api.post(`bags/${bagId}/comments`, data, {headers: {'Content-Type': 'multipart/form-data'}}),
    exportManifest: (bagId, language) => api.get(`/bags/${bagId}/export-manifest/${language}`, {
        responseType: 'blob'
    }),
    exportManifests: (params) => api.get('/bags/export-manifests', {
        params,
        responseType: 'blob'
    }),
    importManifest: (data) => api.post(`/bags/import-manifest`, data, {headers: {'content-type': 'application/x-www-form-urlencoded'}}),
    exportPackages: (code) => api.get(`/bags/${code}/export-packages`, {
        responseType: 'blob'
    }),
    exportBagDetails: params => api.get(`/bags/export-packages-list`, {
        params,
        responseType: 'blob'
    }),
    exportBagList: (codes) => api.post('bags/exportation', {
        codes
    }),
    printBarcode: (bagId) => api.get(`bags/${bagId}/print-barcode`),
    changePackageToDelivering: (bagId) => api.put(`bags/${bagId}/receiving-package`),
    sendNoticeToCustomer: (bagId, data) => api.post(`bags/${bagId}/comment-to-customer`, data),
    updateShippingPartner: (data) => api.post(`/bags/import-update-shipping-partner`, data),
    getBaggingRequestList: (params) => api.get(`/packaging-requests`, {params, singleRequest: true}),
    getBagRequestDetail: (id) => api.get(`/packaging-requests/${id}`, {singleRequest: true}),
    getPackagesInBagRequest: (requestId, params) => api.get(`/packaging-requests/${requestId}/packages`, {params, singleRequest: true}),
    processPackingRequest: (requestId) => api.post(`/packaging-requests/${requestId}/to-processing`),
    completePackingRequest: (requestId) => api.post(`/packaging-requests/${requestId}/to-completed`),
    downloadPackageListPackingRequest: (requestId) => api.get(`/packaging-requests/${requestId}/packages/download`, { responseType: 'blob'})
};
