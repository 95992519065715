import React, {Component} from 'react';
import {Icon, Modal} from 'antd';
import lodash from "lodash";
import {withTranslation} from 'react-i18next';
import Link from "../../../../system/routing/Link";
import {getVar} from "../../../../system/support/helpers";
import {showDetailDeliveryRequest} from "../../../DeliveryRequest/DetailDeliveryRequest/actions";
import {connect} from "react-redux";
import { linkHTC } from '../../../Package/helper';
import get from 'lodash/get';
import { LIST_PROPERTY_ALLOW_DISPLAY } from '../../constants';

class ItemDeliveryNoteExport extends Component {

    onRemove = (item) => {
        const {t} = this.props;
        Modal.confirm({
            title: t("package:label.warning"),
            content: <p>{t("package:message.warning_confirm_delete")}</p>,
            okText: t("package:label.ok"),
            cancelText: t("package:label.cancel"),
            onOk: this.confirmRemove.bind(this, item),
            onClose: this.closeRemove.bind(this),
            icon: <Icon type="question-circle" theme="filled"/>
        });
    };

    confirmRemove = (item) => {
        const {delivery_note} = this.props;
        this.props.removePackageFromDeliveryNote(delivery_note.id, [item.id]);
    };

    closeRemove = () => {
        // Do something
    };

    render() {
        let {object, isShowAddress, showDeliveryRequestDetailModal,customer} = this.props;
        let {message, delivery_request, properties} = object;
        
        let agency = getVar(this.props, "customer.agency");
        const _package = object.package || {};
        let tbodyClassName = [];
        if (message.length > 0) {
            tbodyClassName.push('error');
        }

        const linkHtc = linkHTC(get(customer, 'agency.code'))

        let propertyAllowDisplay = [];
        if (!lodash.isEmpty(properties)) {
            lodash.map(properties, "code").map(propertyCode => {
                const propertyInfo = lodash.find(properties, ['code', propertyCode])
               if(LIST_PROPERTY_ALLOW_DISPLAY.includes(propertyCode.toUpperCase()) && !lodash.isEmpty(propertyInfo)) {
                    propertyAllowDisplay.push(getVar(propertyInfo, "name", ""));  
               }
               return true;
            })
        }

        return (
            <tbody className={tbodyClassName.join(' ')}>
                <tr className="data">
                    <td>
                        <Link isNewTab to="packages.detail" params={{id: _package.code}}>{_package.code}</Link>
                    </td>
                    <td>
                        {
                            delivery_request && agency && delivery_request.code ?
                                <span
                                    className="a-text--link cursor-pointer"
                                    onClick={showDeliveryRequestDetailModal.bind(undefined, {
                                        code: delivery_request.code,
                                        agency: agency.code
                                    })}
                                >{delivery_request.code}</span> : '--'
                        }
                    </td>
                    <td>{object.orderCode !== '--' ? <a href={linkHtc ? linkHtc + "/order/detail/default/" +  object.orderCode : lodash.get(object, 'order.m1_url') || '#top'}
                                                        rel="noopener noreferrer"
                                                        target={lodash.get(object, 'order.m1_url') ? '_blank' : '_self'}>{object.orderCode}</a> : '--'}</td>
                    <td className="a-text--pre-wrap">{lodash.get(_package, 'customer_address_destination') || '--'}</td>
                    <td>{object.netWeightFormat}</td>
                    <td>{object.volumneFormat}</td>
                    {
                        propertyAllowDisplay.length > 0 && 
                        <td>{propertyAllowDisplay.join(", ")}</td>
                    }
                    {isShowAddress && <td>{lodash.get(object, 'order.customer_destination_address', "--") || "--"}</td>}
                    <td>
                        <div className="gflex gjustify-end">
                            {message.length > 0 && (
                                <span className={"action-remove"} >
                                <Icon type="close" onClick={this.onRemove.bind(this, _package)}/>
                            </span>
                            )}
                        </div>

                    </td>
                </tr>

                {message.length > 0 ? (
                    <tr className="message">
                        <td colSpan="5">
                            <span className="a-text--black-45 gflex">
                                <Icon type='info-circle'
                                      theme="filled"
                                      className={'a-text--red galign-baseline mr-2 mt-1'}
                                />
                                 <div className="a-flex-1">
                                    {message.map((mes, index) => {
                                        return (<span key={index}>{mes} <br/></span>)
                                    })}
                             </div>
                            </span>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        )
    }
}

const mapDispatchToProps = {
    showDeliveryRequestDetailModal: showDetailDeliveryRequest
}

export default withTranslation()(connect(null, mapDispatchToProps)(ItemDeliveryNoteExport));
