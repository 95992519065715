import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {url} from '../../../system/routing';
import {t} from '../../../system/i18n';

export function* editShippingPartner(action) {
    yield processApiRequest(ACTION.EDIT_SHIPPING_PARTNER, () => apiService.editShippingPartner(action.payload), action.payload);
}

export function* editShippingPartnerSuccess() {
    yield notification.success({
        message: t('message.edit_shipping_partner_success'),
        className:'_edit_shipping_partner_success'
    });

    yield url.redirectTo('config.shipping-partners.list', {
        type: 'international'
    });
}

export function* editShippingDomesticPartner(action) {
    yield processApiRequest(ACTION.EDIT_SHIPPING_DOMESTIC_PARTNER, () => apiService.editShippingDomesticPartner(action.payload), action.payload);
}

export function* editShippingDomesticPartnerSuccess() {
    yield notification.success({
        message: t('message.edit_shipping_partner_success'),
        className:'_edit_shipping_partner_success'
    });

    yield url.redirectTo('config.shipping-partners.list', {
        type: 'domestic'
    });
}
