import React, { useEffect, useState } from 'react';
import { Col, notification, Row, Typography } from 'antd';
import { get, map } from 'lodash';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { useComplaintSellersDisplay } from './../../hooks';
import authService from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import { useDeepCompareEffect } from './../../../../hooks';

import PictureWall from './../../UploadDamagedImages/components/PictureWall';
import DamagedStatusesSelect from './../../../Common/components/Selects/Package/DamagedStatusesSelect';

const { Paragraph } = Typography;

const DamagedInfo = props => {
    const [damagedReasonsDisplay, setDamagedReasonsDisplay] = useState(null);
    const [status, setStatus] = useState();
    const [updating, setUpdating] = useState(false);
    const complaintSellersDisplay = useComplaintSellersDisplay({
        className: 'amt-16 mb-0',
        complaintSellers: get(props.package, 'package_detail.id_m5_complaint_sellers'),
        packageId: get(props.package, 'id')
    });

    const handleChangeDamagedStatus = async status => {
        if (updating) {
            return;
        }

        setStatus(status);
        setUpdating(true);

        try {
            const { data } = await apiService.changeDamagedStatus({
                status,
                packages: [get(props.package, 'id')]
            });

            notification.success({
                message: props.t('package:change_damaged_status.success')
            });

            props.onSuccessChangeDamagedStatus(data[0]);
        } catch (error) {
            setStatus(props.statusDamaged);

            notification.error({
                message: props.t('package:change_damaged_status.failed')
            });
        }

        setUpdating(false);
    };

    useEffect(() => {
        setStatus(props.statusDamaged);
    }, [props.statusDamaged]);

    useDeepCompareEffect(() => {
        const damagedReasons = get(props.package, 'damaged_reasons', []);
        const damagedReasonsDisplay = map(damagedReasons, reason => reason.name).join(', ');

        setDamagedReasonsDisplay(damagedReasonsDisplay);
    }, [props.packageDamagedReasons, props.package]);

    if (!get(props.package, 'package_detail.is_damage')) {
        return null;
    }

    return (
        <div className="damaged-info a-block-info bg-gray ap-16 amb-16">
            <Row type="flex">
                <Col className="apr-16 border-right" lg={{span: 11}}>
                    <div className="gflex galign-center">
                        <Paragraph className="_damaged-status-title mb-0 mr-4">
                            {props.t('package:detail.damaged_status_title')}
                        </Paragraph>
                        {authService.can(permissions.PACKAGE_CHANGE_DAMAGED_STATUS) ? (
                            <DamagedStatusesSelect
                                className="w-200"
                                loading={updating}
                                value={status}
                                onChange={handleChangeDamagedStatus}
                            />
                        ) : (
                            <Paragraph className="_damaged-status mb-0">
                                {props.t(`package:damaged_statuses.${status}`)}
                            </Paragraph>
                        )}
                    </div>

                    {!!damagedReasonsDisplay && (
                        <div className="mb-0 amt-16">
                            <Paragraph className="mb-0">
                                {props.t('error_reason')}: {damagedReasonsDisplay}
                            </Paragraph>
                        </div>
                    )}

                    {complaintSellersDisplay}
                </Col>
                <Col className="apl-16" lg={{span: 13}}>
                    <PictureWall
                        damagedImages={get(props.package, 'package_detail.damaged_images', [])}
                        packageId={get(props.package, 'id')}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(DamagedInfo);
