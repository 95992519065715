import React from 'react';
import lodash from 'lodash';
import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t, trans} from '../../system/i18n';

export function* createByBarcode(action) {
    yield processApiRequest(ACTION.CREATE_PACKAGE_BY_SCAN, () => apiService.createByBarcode(action.payload.data), action.payload);
}

/**
 * Xử lý trường hợp scan barcode fail
 */
export function* createByBarcodeFailed(action) {
    if (action.payload && action.payload.code !== "exception") {
        let error = lodash.get(action, 'payload.data');
        if (lodash.get(error, 'barcode.barcode_of_cancel_order')) {
            return yield notification['error']({
                message: <span
                    className={"_scan_package_failed"}>{trans('package:message.scan_failed_code',
                    {
                        code: <b>{lodash.get(error, 'barcode.barcode_of_cancel_order.code')}</b>,
                        status:
                            <b>{t('package:status_transport.' + lodash.get(error, 'barcode.barcode_of_cancel_order.status'))}</b>
                    })}</span>,
            });

        }
        if (lodash.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
            });
        }
        let id_warehouse = lodash.get(action, 'payload.data.id_warehouse', {});
        if (lodash.includes(Object.keys(id_warehouse), 'exists')) {
            return yield notification['error']({
                message: t('package:message.scan_required_warehouse'),
            });
        }
        return yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('package:message.scan_failed')}</span>,
        });

    }
}

/**
 * Xử lý trường hợp scan barcode thành công
 */
export function* createByBarcodeSuccess(action) {
    if (!lodash.isNil(lodash.get(action, 'payload.orders')) && lodash.isFunction(action.request.onCallback)) {
        action.request.onCallback(lodash.get(action, 'payload.orders', []))
    }
    yield notification['success']({
        message: <span className={"_create_package_success"}>{t('package:message.scan_success')}</span>,
    });
}
