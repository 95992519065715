import {Component} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import lodash from 'lodash';

import {isEmptyObject} from './../../../system/support/helpers';

class SearchableComponent extends Component {
    componentDidMount() {
        this.prepareFilter(this.props);
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.prepareFilter(this.props);
        }
    }

    prepareFilter(props) {
        this.onChangeFilter({
            ...this.getFilterFromLocation(props.location),
            ...props.match.params,
        });
    }

    getFilterFromLocation(location) {
        return queryString.parse(location.search, {arrayFormat: 'bracket'});
    }

    onChangeFilter() {
    }

    pushFilterToHistory(filter) {
        let pathname = this.props.location.pathname ? this.props.location.pathname : this.props.history.location.pathname;
        this.props.history.push(pathname + '?' + queryString.stringify(filter, {arrayFormat: 'bracket'}));
    }

    pushFilter(filter) {
        filter = lodash.omitBy(filter, value => value === '' || isEmptyObject(value));

        this.pushFilterToHistory(filter);
    }
}

SearchableComponent.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default SearchableComponent;
