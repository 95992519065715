import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* fetchUsers(action) {
    yield processApiRequest(ACTION.FETCH_USERS, () => apiService.getUsers(action.payload), action.payload);
}

export function* fetchDetailUser(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_USERS, () => apiService.fetchDetailUser(action.payload.userId), action.payload);
}



