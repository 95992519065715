import React from 'react';
import pageService from "../Page/pageService";
import {withTranslation} from "react-i18next";

class Home extends React.PureComponent {
    componentDidMount() {
        pageService.setTitle(this.props.t('title.home'));
    }
    render() {
        return null;
    }
}

export default withTranslation()(Home);
