import React, { memo, useState, useEffect } from 'react'
import { Input, Layout, Typography } from 'antd'
import { debounce, flatten, get, isEqual, toLower, trim, isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'

import { useDeepCompareEffect } from './../../../../../hooks'

import DeliveryNoteList from './DeliveryNoteList/DeliveryNoteListContainer'
import WarehouseAreaList from '../WarehouseAreaList'

const { Search } = Input
const { Content } = Layout
const { Title } = Typography

const DeliveryNotes = ({
    selectedBagIds,
    selectedPackageIds,
    suggests,
    t,
    warehouseId,
    onCreateDeliveryNote,
    onDelete,
    onOpenPackageListByBagPopup,
    onPrint,
    creatingWithTrackingNo
}) => {
    const [filteredSuggests, setFilteredSuggests] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [customerDetailInfos, setCustomerDetailInfos] = useState({})
    const [suggestForms, setSuggestForms] = useState({})
    const [customerInfos, setCustomerInfos] = useState([])

    const handleSearch = event => {
        const { value } = event.target

        setSearchKeyword(value)
        doFilterSuggests(value)
    }

    const filterSuggests = customerName => {
        customerName = trim(toLower(customerName))

        if (!customerName) {
            setFilteredSuggests(suggests)

            return
        }

        const filteredSuggests = suggests.filter(
            suggest =>
                toLower(trim(get(suggest, 'customer.full_name'))).includes(customerName) ||
                toLower(trim(get(suggest, 'customer.username'))).includes(customerName)
        )

        setFilteredSuggests(filteredSuggests)
    }

    const doFilterSuggests = debounce(filterSuggests, 250)

    const handleCreateDeliveryNoteButtonClick = (data, auto = false) => {
        onCreateDeliveryNote(data, auto)
    }

    const handleChangeCustomerDetailInfo = (identity, customerDetailInfo) => {
        setCustomerDetailInfos({
            ...customerDetailInfos,
            [identity]: customerDetailInfo,
        })
    }

    useDeepCompareEffect(() => {
        filterSuggests(searchKeyword)
        const suggestForms = {}

        for (const suggest of suggests) {
            const suggestForm = {}

            suggestForm.bags = selectedBagIds[suggest.identity]
            suggestForm.packages = selectedPackageIds[suggest.identity]
            suggestForm.identity = suggest.identity
            suggestForm.id_customer = suggest.customer.id
            suggestForms[suggest.identity] = suggestForm
        }

        setSuggestForms(suggestForms)
    }, [suggests])

    useEffect(() => {
        const customerInfos = []
        Object.keys(selectedPackageIds).map(key => {
            if (!isEmpty(customerDetailInfos[key])) {
                customerInfos.push(customerDetailInfos[key])
            }
        })
        setCustomerInfos(customerInfos)
    }, [selectedPackageIds, customerDetailInfos])

    return (
        <Content className="a-sub-main bg-white gflex gflex-direction-column">
            <section className="a-sub-main__header gflex galign-center gjustify-space-between has-border-bottom">
                <div className="gflex galign-center">
                    <Title
                        className="title"
                        level={4}>
                        {t('delivery_note_list')}
                    </Title>

                    <div className="a-form__search-inline-title">
                        <Search
                            className="a-input--search-inline-title"
                            placeholder={t('search_by_customer')}
                            value={searchKeyword}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                <WarehouseAreaList
                    customerInfos={customerInfos}
                    warehouseId={warehouseId}
                    selectedPackageIds={flatten(Object.values(selectedPackageIds))}
                />
            </section>

            <section className="a-sub-main__body">
                <DeliveryNoteList
                    creatingWithTrackingNo={creatingWithTrackingNo}
                    customerDetailInfos={customerDetailInfos}
                    deliveryNotes={filteredSuggests}
                    deliveryNoteForms={suggestForms}
                    selectedBagIds={selectedBagIds}
                    selectedPackageIds={selectedPackageIds}
                    warehouseId={warehouseId}
                    onChangeCustomerDetailInfo={handleChangeCustomerDetailInfo}
                    onCreateDeliveryNote={handleCreateDeliveryNoteButtonClick}
                    onDelete={onDelete}
                    onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                    onPrint={onPrint}
                />
            </section>
        </Content>
    )
}

export default memo(withTranslation()(DeliveryNotes), isEqual)
