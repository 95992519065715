import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchDeliveryNote = createAction(ACTION.FETCH_DELIVERY_NOTE.REQUEST, (id) => ({id}));
export const updateDeliveryNote = createAction(ACTION.UPDATE_DELIVERY_NOTE.REQUEST);
export const clearState = createAction(ACTION.CLEAR_STATE);
export const addBarcode = createAction(ACTION.ADD_BARCODE);
export const removeBarcode = createAction(ACTION.REMOVE_BARCODE);
export const setBarcodes = createAction(ACTION.SET_BARCODES);
export const getCustomerBagPackages = createAction(ACTION.GET_CUSTOMER_BAG_PACKAGES.REQUEST);
export const getCustomerDetailInfo = createAction(ACTION.GET_CUSTOMER_DETAIL_INFO.REQUEST);
export const resetError = createAction(ACTION.RESET_ERROR);
