import React, { Fragment } from 'react';
import { notification } from 'antd';
import { get, map } from 'lodash';

import { trans } from './../../system/i18n';
import { Link } from './../../system/routing';

import { BARCODE_TYPE } from './DeliveryNoteBarcode/constants';

export const getCandeliveries = (customerBagPackages, selectedBagIds, selectedPackageIds) => {
    const bags = get(customerBagPackages, 'bags', []);
    const packages = get(customerBagPackages, 'packages', []);
    const unselectedBags = bags.filter(bag => selectedBagIds && !selectedBagIds.includes(get(bag, 'bag.id')));
    const unselectedPackages = packages.filter(pkg => selectedPackageIds && !selectedPackageIds.includes(get(pkg, 'package.id')));

    return [
        ...map(unselectedPackages, pkg => ({
            ...pkg,
            type: BARCODE_TYPE.PACKAGE
        })),
        ...map(unselectedBags, bag => ({
            ...bag,
            type: BARCODE_TYPE.BAG
        }))
    ];
};

export const handlePlaceError = error => {
    let message = trans('delivery_note:place.failed');

    const errors = get(error, 'response.data.data');

    if (errors && Object.keys(errors).length) {
        const firstErrorKey = Object.keys(errors)[0];
        const translatedErrors = [];

        for (const error of Object.keys(errors[firstErrorKey])) {
            const payload = Array.isArray(errors[firstErrorKey][error]) ? errors[firstErrorKey][error].map((item, index) => (
                <Fragment key={index}>
                    <Link
                        isNewTab
                        params={{id: item}}
                        to={error.includes('bag') ? 'bags.detail' : 'packages.detail'}
                    >
                        {item}
                    </Link>
                    {index !== errors[firstErrorKey][error].length - 1 && ', '}
                </Fragment>
            )) : errors[firstErrorKey][error];
            translatedErrors.push(trans(`delivery_note:place.errors.${firstErrorKey}.${error}`, {
                bags: payload,
                packages: payload
            }));
        }

        message = translatedErrors.map((translatedError, index) => (
            <Fragment key={index}>
                {translatedError}
                {index !== translatedErrors.length - 1 && <br />}
            </Fragment>   
        ));
    }

    notification.error({
        message
    });
};
