import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import PackageDetail from './PackageDetail';
import UpdateRelatedPackage from './../../UpdateRelatedPackage/UpdateRelatedPackageContainer';
import UpdateAlonePackageContainer from './../../UpdateAlonePackage/UpdateNotMapOrderPackageContainer';

class FormPackageDetail extends React.PureComponent {
    render() {
        const packageData = this.props.package;

        switch (packageData.current_page) {
            case 'UpdateAlonePackage': {
                return (
                    <UpdateAlonePackageContainer packageData={packageData} />
                );
            }
            case 'UpdateRelatedPackage': {
                return (
                    <UpdateRelatedPackage package={packageData} />
                );
            }
            default: {
                return (
                    <PackageDetail validateWhenMounted={true} {...this.props} />
                );
            }
        }
    }
}

FormPackageDetail.defaultProps = {
    package: {},
};

FormPackageDetail.propTypes = {
    package: PropTypes.object,
};

export default withTranslation()(FormPackageDetail);
