import React from 'react';
import {Empty} from "antd";
import {t} from '../../../system/i18n';

class EmptyData extends React.Component {

    render() {
        return (
            <Empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                description={<span>{t("common:label.no_data")}</span>}
            >
            </Empty>
        )
    }
}

export {EmptyData};