import React from 'react';
import { Col, Modal, Row } from 'antd';
import { withTranslation } from 'react-i18next';

import { getVar } from './../../../../system/support/helpers';

import Orders from './BottomContent/Orders';
import RightBottomContent from './BottomContent/RightBottomContent';

const BottomContent = ({ addingOrder, domesticShippingInfo, orders, printing, selectedOrder, selectedOrderIndex, t, onCreateOrder, onDeleteOrder, onPrint, onSelectOrder, deliveryNote }) => {
    const handleSelectOrder = index => {
        onSelectOrder(index);
    };

    const handleDelete = () => {
        Modal.confirm({
            cancelButtonProps: {
                className: '_btn-cancel'
            },
            cancelText: t('btn.cancel'),
            className: '_confirm-delete-modal',
            okButtonProps: {
                className: '_btn-ok'
            },
            okText: t('btn.ok'),
            title: t('delete_order.confirm'),
            onOk: onDeleteOrder
        });
    };

    return (
        <section>
            <Row className="height-100" type="flex">
                <Col className="height-100" lg={{ span: 4 }}>
                    <Orders
                        orders={orders}
                        selectedOrderIndex={selectedOrderIndex}
                        onSelect={handleSelectOrder}
                    />
                </Col>
                <Col className="height-100" lg={{ span: 20 }}>
                    <RightBottomContent
                        addingOrder={addingOrder}
                        customer={getVar(domesticShippingInfo, 'customer', {})}
                        order={selectedOrder}
                        printing={printing}
                        selectedOrderIndex={selectedOrderIndex}
                        onDelete={handleDelete}
                        onPrint={onPrint}
                        onSubmit={onCreateOrder}
                        deliveryNote={deliveryNote}
                    />
                </Col>
            </Row>
        </section>
    );
};

export default withTranslation()(BottomContent);
