import React from 'react';
import { Typography } from 'antd';
import { withTranslation } from 'react-i18next';

import OrderList from './Orders/OrderList';

const { Text } = Typography;

const Orders = ({ orders, selectedOrderIndex, t, onSelect }) => (
    <div className="a-tile orders">
        <div className="a-tile__header">
            <Text strong>{t('order_list')}</Text>
        </div>
        <div>
            <OrderList
                orders={orders}
                selectedOrderIndex={selectedOrderIndex}
                onSelect={onSelect}
            />
        </div>
    </div>
);

Orders.defaultProps = {
    orders: []
};

export default withTranslation()(Orders);
