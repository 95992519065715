import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {setCollectionItem} from '../../Common/services/helps';

let packages = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_DIMENSION.REQUEST:
            return setCollectionItem(
                state,
                payload.packageCode,
                {[`${payload.param}_loading`]: true, [`${payload.param}_error`]: null},
                'code'
            );

        case ACTION.UPDATE_DIMENSION.SUCCESS:
            return setCollectionItem(
                state,
                action.request.packageCode,
                {[`${action.request.param}_loading`]: false},
                'code'
            );

        case ACTION.UPDATE_DIMENSION.FAILED:
            return setCollectionItem(
                state,
                action.request.packageCode,
                {[`${action.request.param}_loading`]: false, [`${action.request.param}_error`]: payload},
                'code'
            );

        case ACTION.RESET_ERROR:
            return setCollectionItem(
                state,
                payload.packageCode,
                {[`${payload.param}_error`]: null},
                'code'
            );

        case ACTION.CLEAR_STATE:
            return state.filter(packageData => packageData.code !== payload.packageCode);

        default:
            return state;
    }
};

export default combineReducers({
    packages,
});
