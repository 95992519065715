import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../DetailDeliveryNote/actions';
import * as selectors from '../DetailDeliveryNote/selectors';
import {setMenuActive} from './../../Common/actions';
import DeliveryNotePrint from './components/DeliveryNotePrintForCustomer';
import authService from "../../Auth/authService";
import {withTranslation} from "react-i18next";
import * as deliveryNotePrintAction from './actions';
import * as deliveryNotePrintSelector from './selectors';

const mapStateToProps = (state) => {
    return {
        loggedUser: authService.user(),
        partner: authService.partner(),
        deliveryNote: selectors.getDeliveryNoteDetail(state),
        loading: selectors.getState(state, 'loading'),
        agencySetting: deliveryNotePrintSelector.getAgencySetting(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeliveryNote: (id) => dispatch(actions.fetchDeliveryNoteDetail(id)),
        clearState: () => dispatch(actions.clearState()),
        setMenuActive: (menu) => dispatch(setMenuActive(menu)),
        fetchSetting: () => dispatch(deliveryNotePrintAction.fetchSetting())
    };
};

class DetailDeliveryNotePrintTestContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    getId = () => {
        return this.props.match.params.id;
    };

    componentDidMount() {
        this.props.fetchSetting();
        this.props.fetchDeliveryNote(this.getId());
        document.getElementsByTagName('body')[0].classList.add('A5');

    }

    render() {
        return (
            <>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"/>
                <section className="sheet">
                    <DeliveryNotePrint
                        {...this.props}
                        {...this.state}
                    />
                </section>
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(DetailDeliveryNotePrintTestContainer));
