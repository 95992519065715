import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchPropertySuggest } from './../modules/Property/actions';
import { getState } from './../modules/Property/selectors';

const mapStateToProps = state => ({
    properties: getState(state, 'suggest', []),
    loadingProperties: getState(state, 'loading', false)
});

const mapDispatchToProps = {
    getPropertySuggests: fetchPropertySuggest
};

const withProperties = WrappedComponent => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(class extends Component {
        getPropertySuggests = () => {
            const { loadingProperties, getPropertySuggests } = this.props;

            if (loadingProperties) {
                return;
            }

            getPropertySuggests();
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getPropertySuggest={this.getPropertySuggests}
                />
            );
        }
    });
};

export default withProperties;
