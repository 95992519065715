import React from 'react';
import { notification } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import apiService from './../../BagArea/apiService';
import { setMenuActive } from './../../Common/actions';
import { getFormErrors } from './../../../system/support/helpers';

import BagArea from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';
import withBagAreas from './../../../hocs/withBagAreas';

class BagAreaContainer extends SearchableComponent {
    state = {
        adding: false,
        bagAreas: [],
        updating: false
    };

    getCurrentFilter = () => ({
        page: 1,
        ...this.getFilterFromLocation(this.props.location),
    });

    handleOnSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter = filter => {
        const { getBagAreas } = this.props;

        getBagAreas(filter);
    };

    handleAddBagArea = async data => {
        const { t, getBagAreas } = this.props;

        this.setState({
            adding: true
        });

        try {
            await apiService.add(data);

            getBagAreas();

            notification.success({
                message: t('bag_area:add_area.success')
            });

            this.setState({
                adding: false
            });
        } catch (error) {
            const errors = get(error, 'response.data');

            notification.error({
                message: t('bag_area:add_area.failed')
            });
            this.setState({
                adding: false
            });

            throw getFormErrors(errors);
        }
    };

    handleUpdateBagArea = async data => {
        const { bagAreas, t, setBagAreas } = this.props;

        this.setState({
            updating: true
        });

        try {
            await apiService.update(data.id, data);

            const index = bagAreas.findIndex(bagArea => get(bagArea, 'bag_area.id') === data.id);
            let newBagAreas = bagAreas;

            if (index !== -1) {
                newBagAreas = [
                    ...bagAreas.slice(0, index),
                    {
                        ...bagAreas[index],
                        bag_area: {
                            ...bagAreas[index].bag_area,
                            ...data
                        }
                    },
                    ...bagAreas.slice(index + 1),
                ];
            }

            setBagAreas(newBagAreas);

            notification.success({
                message: t('bag_area:update_area.success')
            });

            this.setState({
                updating: false
            });
        } catch (error) {
            const errors = get(error, 'response.data');

            notification.error({
                message: t('bag_area:update_area.failed')
            });
            this.setState({
                updating: false
            });

            throw getFormErrors(errors);
        }
    };

    render() {
        const { adding, updating } = this.state;
        const { bagAreas, loading, pagination } = this.props;

        return (
            <BagArea
                {...this.props}
                adding={adding}
                bagAreas={bagAreas}
                loading={loading}
                pagination={pagination}
                updating={updating}
                getCurrentFilter={this.getCurrentFilter}
                onAddBagArea={this.handleAddBagArea}
                onUpdateBagArea={this.handleUpdateBagArea}
                onSearch={this.handleOnSearch}
            />
        );
    }
}

export default withTranslation()(connect(
    undefined,
    {
        setMenuActive
    }
)(withBagAreas(BagAreaContainer)));
