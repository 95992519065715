import {combineReducers} from 'redux';
import * as ACTION from './constants';

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.CREATE_PACKAGE_SAME_BARCODE.REQUEST:
            return true;

        case ACTION.CREATE_PACKAGE_SAME_BARCODE.SUCCESS:
        case ACTION.CREATE_PACKAGE_SAME_BARCODE.FAILED:
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    loading,
});
