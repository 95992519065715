import React, { Component } from 'react'
import { Button, Empty, Table } from 'antd'
import lodash from 'lodash'

import { withTranslation } from 'react-i18next'
import Pagination from '../../../Common/components/Pagination'
import ColumnSetting from './ColumnSetting'
import { LASTMILE_ORDER_COLUMN } from '../../constants'
import settingService from './settingService'
class LastmileOrderList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeColumns: [],
        }
    }

    getColumns() {
        let arrSort = [LASTMILE_ORDER_COLUMN.CREATE_AT]
        return lodash
            .values(LASTMILE_ORDER_COLUMN)
            .filter(column => this.state.activeColumns.includes(column))
            .map(column => {
                let res = {
                    className: column === LASTMILE_ORDER_COLUMN.TRACKING_NO ? 'a-text--nowrap _tracking_no_column' : 'a-text--nowrap',
                    key: column,
                    dataIndex: column,
                    title: this.props.t('lastmile_order:column.' + column),
                }

                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                    }
                }
                return res
            })
    }

    onChange(pagination, filters, sorter) {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        })
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            })
        }
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            })
        }
    }

    render() {
        const { filter, loading, pagination, t, exporting, data, handleExport, isOpenListSetting, onOpenListSetting } = this.props
        return (
            <>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3>
                            {t('lastmile_order:title.list')} ({lodash.get(pagination, 'total', 0)})
                        </h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2"
                            loading={exporting}
                            type="primary"
                            onClick={() => handleExport(filter)}>
                            Tải file excel
                        </Button>
                        <ColumnSetting
                            options={settingService.options}
                            onChange={activeColumns => this.setState({ activeColumns })}
                            loading={loading}
                            isOpenListSetting={isOpenListSetting}
                            onOpenListSetting={onOpenListSetting}
                        />
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <div className="a-table--responsive">
                        <Table
                            loading={loading}
                            className={'a-table--page-list'}
                            columns={this.getColumns()}
                            dataSource={data}
                            pagination={false}
                            rowKey={record => record.key}
                            locale={{
                                emptyText: (
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        description={<span>{t('common:label.no_data')}</span>}></Empty>
                                ),
                            }}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        total={pagination ? pagination.total : 0}
                        loading={this.props.loading}
                        onChange={this.onChangePage.bind(this)}
                    />
                </div>
            </>
        )
    }
}

export default withTranslation()(LastmileOrderList)
