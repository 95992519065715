/* eslint-disable */
import React from 'react';
import {Button, Icon, Input, Row, Tabs, Upload} from "antd";

const TabPane = Tabs.TabPane;

class BagBlockRight extends React.PureComponent {
    render() {
        return (
            <div className="a-chatbox checkpackage ">
                <div className="a-chatbox--input ">
                    <Input placeholder="Nhập thông tin cần hỗ trợ..." className="a-input a-input--chatbox apr-36 aflex-1"/>
                    <Upload name="logo" action="/upload.do" listType="picture" className="a-btn--chat-upload">
                        <Button>
                            <Icon type="camera"/>
                        </Button>
                    </Upload>
                    <Button type="primary" htmlType="submit"
                            className="a-btn a-btn--primary a-btn--submit-chatbox ">Gửi</Button>
                </div>
                <div className="a-chatbox--body">
                    <Tabs className="a-tabs a-tabs--chatbox-tab">
                        <TabPane className="a-tabs--chatbox--panel" tab="Thông tin hệ thống" key="3">
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng  123123131<span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">23423424242 </span> sang <span
                                        className="a-text--medium">Vận chuyển 23232</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">23423424242 </span> sang <span
                                        className="a-text--medium">Vận chuyển 23232</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log user">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">23423424242 </span> sang <span
                                        className="a-text--medium">Vận chuyển 23232</span></span>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane className="a-tabs--chatbox--panel" tab="Thông tin hệ thống" key="2">
                            <div className="a-chatbox--log system">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>
                            <div className="a-chatbox--log system">
                                <img className="img-ads" src={require('../../../../resources/images/log-sys.svg')}
                                     alt="Log system"/>
                                <div className="a-chatbox--log--detail">
                                    <p className="title">Hệ thống, 16:54 CH</p>
                                    <span className="detail">Chuyển trạng thái kiện hàng <span
                                        className="a-text--medium">492</span> sang <span
                                        className="a-text--medium">Vận chuyển</span></span>
                                </div>
                            </div>

                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default BagBlockRight;