import React, { forwardRef } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const { Option } = Select;

const RoutesSelect = forwardRef(({
    className,
    consignServices,
    disabled,
    dropdownClassName,
    routes,
    value,
    ...other
}, ref) => {
    let formattedValue = value;

    if (other.loading) {
        if (other.mode === 'multiple') {
            formattedValue = [];
        } else {
            formattedValue = undefined;
        }
    }

    if (other.mode === 'multiple' && (!value || !value.length)) {
        formattedValue = [];
    }

    return (
        <Select
            {...other}
            className={clsx(className, '_routes-select')}
            disabled={disabled || other.loading}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-routes')}
            ref={ref}
            value={formattedValue}
        >
            {routes.map(route => (
                <Option className="_route-item" key={route.id} value={route.id}>
                    {route.label}
                </Option>
            ))}
        </Select>
    );
});

RoutesSelect.defaultProps = {
    consignServices: [],
    services: []
};

RoutesSelect.propTypes = {
    consignServices: PropTypes.array,
    services: PropTypes.array
};

export default RoutesSelect;
