import React, { Component } from 'react';
import { Button, Col, Icon, Form, InputNumber, Row, Select } from 'antd';
import clsx from 'clsx';
import { find, get, has, includes, isEqual, isNumber, round, sumBy } from 'lodash';
import { withTranslation } from 'react-i18next';

import authService from './../../../Auth/authService';
import { currentFormatter } from './../../../Common/services/format';
import Package from './../../../Package/models/Package';
import { getFormErrors, mergeObjectArrayByKey } from './../../../../system/support/helpers';

import ProductList from './../ProductList';
import PrintOfficialStamp from './../../../Package/PrintOfficialStamp';
import PrintPackageStampContainer from './../../../Package/PrintPackageStamp/PrintPackageStampContainer';
import PrintBatchStampButton from "../../components/PrintBatchStampButton";

const { Option } = Select;

class PackageItem extends Component {
    state = {
        errors: {},
        isOriginPackage: false,
        products: [],
        totalProductAmount: 0,
        updating: false,
        volume: 0,
        submitted: false
    };

    getTotalProductAmount = products => {
        return sumBy(products, product => {
            return product.unit_price * (product.received_quantity || 0);
        });
    };

    removePackage = () => {
        const { onRemovePackage } = this.props;

        onRemovePackage();
    };

    handleOnChange = field => {
        const { errors } = this.state;

        if (errors && errors[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    [field]: undefined
                }
            }));
        }
    };

    handleSubmit = event => {
        const { products } = this.state;
        const { form, index, pkg } = this.props;
        const packageUnitWeightConfig = get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';

        event.preventDefault();

        this.setState({
            submitted: true
        });

        form.validateFields((error, values) => {
            if (error) {
                return;
            }

            let weightNet = values[`weight_net_${index}`];

            if (packageUnitWeightConfig === 'kg') {
                weightNet = weightNet * 10;
            }

            const data = {
                id: pkg.id,
                weight_net: weightNet,
                length: has(values, `[length_${index}]`) ? (values[`length_${index}`] || 0) : pkg.length,
                width: has(values, `[width_${index}]`) ? (values[`width_${index}`] || 0) : pkg.width,
                height: has(values, `[height_${index}]`) ? (values[`height_${index}`] || 0) : pkg.height,
                id_box_size: has(values, `[id_box_size_${index}]`) ? (values[`id_box_size_${index}`] || 0) : pkg.id_box_size,
                weight_box: has(values, `[weight_box_${index}]`) ? (values[`weight_box_${index}`] || 0) : pkg.weight_box
            };

            if (!pkg.id) {
                data.items = products
                    .map((product, productIndex) => ({
                        code_item: product.code_item,
                        received_quantity: values[`received_quantity_${index}_${productIndex}`] || 0
                    }));
            }

            this.handleUpdatePackage(data);
        });
    };

    handleUpdatePackage = async data => {
        const { onUpdate } = this.props;

        this.setState({
            updating: true
        });

        try {
            await onUpdate(data);
        } catch (error) {
            this.setState({
                errors: getFormErrors(error.response.data)
            });
        }

        this.setState({
            updating: false
        });
    };

    handleUpdateProduct = (index, value) => {
        this.setState(prevState => {
            const newProducts = [
                ...prevState.products.slice(0, index),
                {
                    ...prevState.products[index],
                    received_quantity: value
                },
                ...prevState.products.slice(index + 1)
            ];

            return {
                products: newProducts,
                totalProductAmount: this.getTotalProductAmount(newProducts)
            };
        });
    };

    resetDimension = () => {
        const { form, index } = this.props;

        form.setFieldsValue({
            [`length_${index}`]: undefined,
            [`width_${index}`]: undefined,
            [`height_${index}`]: undefined
        });
    };

    getWarningParams = param => {
        const { submitted, volume } = this.state
        const { originPackage } = this.props;
        const requiredContents = get(originPackage, 'required_contents', []);

        switch (param) {
            case 'volume': {
                if (submitted && requiredContents.includes('volume') && !volume) {
                    return {
                        status: 'error'
                    };
                }

                return {
                };
            }
            default: {
                return {
                };
            }
        }
    };

    handleChangeBoxSize = id => {
        const { boxSizes, form, index } = this.props;

        const boxSize = find(boxSizes, boxSize => get(boxSize, 'box_size.id') === id);

        if (boxSize) {
            form.setFieldsValue({
                [`weight_box_${index}`]: get(boxSize, 'box_size.weight')
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { form, index, orderItems, products } = this.props;

        if (!isEqual(products, prevProps.products)) {
            const formattedProducts = mergeObjectArrayByKey(orderItems, products, 'code_item');

            this.setState({
                products: formattedProducts,
                totalProductAmount: this.getTotalProductAmount(formattedProducts)
            });
        }

        if (form !== prevProps.form) {
            const formValue = form.getFieldsValue();
            const length = parseFloat(formValue[`length_${index}`] || 0);
            const width = parseFloat(formValue[`width_${index}`] || 0);
            const height = parseFloat(formValue[`height_${index}`] || 0);
            const volume = round(length * width * height, 2);

            this.setState({
                volume
            });
        }
    }

    componentDidMount() {
        const { orderItems, pkg, products } = this.props;
        const formattedProducts = mergeObjectArrayByKey(orderItems, products, 'code_item');

        this.setState({
            isOriginPackage: pkg.type === 'origin',
            products: formattedProducts,
            totalProductAmount: this.getTotalProductAmount(formattedProducts)
        });
    }

    render() {
        const { errors, isOriginPackage, products, totalProductAmount, updating, volume } = this.state;
        const { boxSizes, form, haveSplittingPackages, index, loadingBoxSizes, originPackage, pkg, t, type } = this.props;
        const { getFieldDecorator } = form;
        const packageUnitWeightConfig = get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        const packageModel = new Package(pkg);
        return (
            <li className={`package-item _package-item-${index}`}>
                <Form onSubmit={this.handleSubmit}>
                    <section className="header">
                        <div className="gflex galign-center">
                            <div className="mr-4 _package-code">
                                {pkg.code ? (
                                    `${isOriginPackage ? t('package:label.original_package') : t('package:label.splitted_package')}: ${pkg.code}`
                                ) : (
                                    `${t('package:label.splitted_package')} ${index}`
                                )}
                            </div>
                            <div className="gflex galign-center mr-4">
                                <span className="a-text--nowrap mr-2">{`${t('package:label.weight_net')} (${packageUnitWeightConfig === 'g' ? 'x100 g' : packageUnitWeightConfig}): `}</span>
                                <Form.Item
                                    help={errors.weight_net}
                                    validateStatus={errors.weight_net ? 'error' : undefined}
                                >
                                    {getFieldDecorator(`weight_net_${index}`, {
                                        initialValue: packageModel.realWeightNetWhenUpdate,
                                        rules: [{
                                            required: true,
                                            message: t('validation:required', {
                                                attribute: t('package:label.weight_net')
                                            })
                                        }, {
                                            type: 'number',
                                            message: t('validation:numeric', {
                                                attribute: t('package:label.weight_net')
                                            })
                                        }, {
                                            validator: async (rule, value, callback) => {
                                                if (isNumber(value) && value <= 0) {
                                                    throw new Error(t('validation:gt', {
                                                        attribute: t('package:label.weight_net'),
                                                        value: 0
                                                    }))
                                                }

                                                callback();
                                            }
                                        }]
                                    })(
                                        <InputNumber
                                            className="_weight-net"
                                            disabled={updating}
                                            onChange={this.handleOnChange.bind(undefined, 'weight_net')}
                                            onPressEnter={this.onSubmit}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className="ml-4 _price-value">
                                {t('package:label.price')}: <b>{currentFormatter.toLocaleStringCurrency(totalProductAmount)} VNĐ</b>
                            </div>
                        </div>

                        <div>
                            {get(pkg, 'permissions.can_print_official_stamp') ? (
                                <PrintOfficialStamp
                                    confirmBeforePrint={haveSplittingPackages && isOriginPackage}
                                    confirmTitle={t('package:message.print_confirm')}
                                    packageData={pkg}
                                />
                            ) : null}
                            {get(pkg, 'permissions.can_print_barcode') ? (
                                <PrintPackageStampContainer
                                    className={clsx({
                                        'ml-4': get(pkg, 'permissions.can_print_official_stamp')
                                    })}
                                    confirmBeforePrint={haveSplittingPackages && isOriginPackage}
                                    confirmTitle={t('package:message.print_confirm')}
                                    numberStamp={1}
                                    packageData={pkg}
                                />
                            ) : null}
                            {get(pkg, 'permissions.can_print_batch_stamp') ? (
                                <PrintBatchStampButton
                                    className="a-btn a-btn--primary ant-btn-primary _print-batch-stamp a-btn--h-25 aradius--2 ml-4"
                                    packageData={pkg}
                                />
                            ) : null}
                            {pkg.id ? null : (
                                <Button
                                    className="a-btn _btn-create-package"
                                    htmlType="submit"
                                    loading={updating}
                                >
                                    {t('package:create.title')}
                                </Button>
                            )}
                            {isOriginPackage ? null : (
                                <Button className={clsx('a-text--white p-0', {
                                    'ml-4': !pkg.id || get(pkg, 'permissions.can_print_official_stamp') || get(pkg, 'permissions.can_print_barcode')
                                })} type="link" onClick={this.removePackage}>
                                    <Icon type="close" />
                                </Button>
                            )}
                        </div>
                    </section>

                    <section className="body">
                        <ProductList
                            canEdit={!pkg.id}
                            form={form}
                            isOriginPackage={isOriginPackage}
                            originPackage={originPackage}
                            packageIndex={index}
                            products={products}
                            type={type}
                            onChange={this.handleUpdateProduct}
                        />

                        <hr className="border-bottom border-top-none" />

                        <div className="bottom-info bg-white ap-16">
                            <Row gutter={8}>
                                <Col lg={8}>
                                    <Form.Item
                                        className="a-form__item _package-size-form-item gflex gflex-direction-column"
                                        help={null}
                                        label={`${t('package:label.dimension')} (cm)`}
                                        labelCol={{ span: 24 }}
                                        validateStatus={this.getWarningParams('volume')['status']}
                                    >
                                        {isOriginPackage || pkg.id ? (
                                            `${pkg.length}x${pkg.width}x${pkg.height}`
                                        ) : (
                                            <>
                                                <div className="gflex galign-center">
                                                    {['length', 'width', 'height'].map(param => (
                                                        <div key={param} className="a-form--loading loading-input mr-4">
                                                            {getFieldDecorator(`${param}_${index}`, {
                                                                initialValue: pkg[param],
                                                                rules: [{
                                                                    required: includes(get(originPackage, 'required_contents'), 'volume')
                                                                }, {
                                                                    validator: async (rule, value, callback) => {
                                                                        if (includes(get(originPackage, 'required_contents'), 'volume') && isNumber(value) && value <= 0) {
                                                                            callback(t('validation:gt', {
                                                                                attribute: t(`package:placeholder.${param}`),
                                                                                value: 0
                                                                            }));
                                                                        } else {
                                                                            callback();
                                                                        }
                                                                    }
                                                                }]
                                                            })(
                                                                <InputNumber
                                                                    autoComplete="off"
                                                                    className={`_${param}`}
                                                                    min={0}
                                                                    placeholder={t(`package:placeholder.${param}`)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}

                                                    {volume ? (
                                                        <span className="_volume">= {volume} (cm<sup>3</sup>)</span>
                                                    ) : null}
                                                </div>

                                                {!pkg.id && (
                                                    <div className="mt-2">
                                                        <Button
                                                            className="_btn-reset-dimension p-0"
                                                            type="link"
                                                            onClick={this.resetDimension}
                                                        >
                                                            <Icon type="close" />{t('package:btn.reset_dimension')}
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col lg={4}>
                                    <Form.Item className="a-form__item _weight-converted" label={`${t('package:label.weight_converted')} (kg)`}>
                                        <div className={clsx('gflex galign-center', {
                                            'h-32': !isOriginPackage && !pkg.id
                                        })}>
                                            {round(get(pkg, 'weight_converted', 0), 4) || '--'}
                                        </div>
                                    </Form.Item>
                                </Col>

                                {boxSizes.length > 0 && (
                                    <>
                                        <Col lg={6}>
                                            <Form.Item
                                                className="a-form__item _weight-box"
                                                help={null}
                                                label={t('package:label.id_box_size')}
                                            >
                                                {isOriginPackage || pkg.id ? get(pkg, 'box_size.code', '--') : (
                                                    getFieldDecorator(`id_box_size_${index}`, {
                                                        initialValue: pkg.weight_box,
                                                        rules: [{
                                                            required: includes(get(originPackage, 'required_contents'), 'weight_box')
                                                        }]
                                                    })(
                                                        <Select
                                                            allowClear
                                                            className="a-select _id-box-size"
                                                            disabled={loadingBoxSizes}
                                                            dropdownClassName="_dropdown-id-box-size"
                                                            loading={loadingBoxSizes}
                                                            placeholder={t('package:label.id_box_size')}
                                                            onChange={this.handleChangeBoxSize}
                                                        >
                                                            {boxSizes && boxSizes.length ? boxSizes.map(item => (
                                                                <Option
                                                                    className="_id-box-size-item"
                                                                    key={item.box_size.id}
                                                                    value={item.box_size.id}
                                                                >
                                                                    {item.box_size.code}
                                                                </Option>
                                                            )) : null}
                                                        </Select>
                                                    )
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Item
                                                className="a-form__item _weight-box"
                                                help={null}
                                                label={`${t('package:label.weight_box')} (kg)`}
                                            >
                                                {isOriginPackage || pkg.id ? pkg.weight_box : (
                                                    getFieldDecorator(`weight_box_${index}`, {
                                                        initialValue: pkg.weight_box,
                                                        rules: [{
                                                            required: includes(get(originPackage, 'required_contents'), 'weight_box')
                                                        }, {
                                                            validator: async (rule, value, callback) => {
                                                                if (includes(get(originPackage, 'required_contents'), 'weight_box') && isNumber(value) && value <= 0) {
                                                                    callback(t('validation:gt', {
                                                                        attribute: t('package:label.weight_box'),
                                                                        value: 0
                                                                    }));
                                                                } else {
                                                                    callback();
                                                                }
                                                            }
                                                        }]
                                                    })(
                                                        <InputNumber
                                                            autoComplete="off"
                                                            className="w-200 _weight-box"
                                                            placeholder={t('package:label.weight_box')}
                                                            min={0}
                                                        />
                                                    )
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </div>
                    </section>
                </Form>
            </li>
        );
    }
}

export default Form.create({
    onValuesChange: (props, changedValues, allValues) => {
        const { boxSizes, form, index } = props;
        const width = parseFloat(get(allValues, `width_${index}`, 0)),
            height = parseFloat(get(allValues, `height_${index}`, 0)),
            length = parseFloat(get(allValues, `length_${index}`, 0));

        const volume = (width * height * length) / 1000000;
        const box = find(boxSizes, (value, index) => {
            const boxSize = value.box_size || {};
            return boxSize.volume >= volume && get(boxSizes[index - 1], 'box_size.volume', 0) < volume
        });

        if (box && Object.keys(changedValues).some(key => [`width_${index}`, `height_${index}`, `length_${index}`].includes(key))) {
            form.setFieldsValue({
                [`id_box_size_${index}`]: get(box, 'box_size.id'),
                [`weight_box_${index}`]: get(box, 'box_size.weight')
            });
        }
    }
})(withTranslation()(PackageItem));
