export const FETCH_DELIVERY_NOTE_DETAIL = {
    REQUEST: 'DELIVERY_NOTE.DELIVERY_NOTE_DETAIL.FETCH_DELIVERY_NOTE_DETAIL.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.DELIVERY_NOTE_DETAIL.FETCH_DELIVERY_NOTE_DETAIL.SUCCESS',
    FAILED: 'DELIVERY_NOTE.DELIVERY_NOTE_DETAIL.FETCH_DELIVERY_NOTE_DETAIL.FAILED',
};

export const UPDATE_DELIVERY_NOTE_DETAIL = {
    REQUEST: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE_DETAIL.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE_DETAIL.SUCCESS',
    FAILED: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE_DETAIL.FAILED',
};

export const CLEAR_STATE_FETCH_DELIVERY_NOTE_DETAIL = 'DELIVERY_NOTE.DELIVERY_NOTE_DETAIL.CLEAR_STATE';

export const REMOVE_PACKAGE_FROM_DELIVERY_NOTE = {
    REQUEST: 'DELIVERY_NOTE.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.FAILED',
};

export const REMOVE_BAG_FROM_DELIVERY_NOTE = {
    REQUEST: 'DELIVERY_NOTE.REMOVE_BAG_FROM_DELIVERY_NOTE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.REMOVE_BAG_FROM_DELIVERY_NOTE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.REMOVE_BAG_FROM_DELIVERY_NOTE.FAILED',
};
