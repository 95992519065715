import lodash from 'lodash';
import {createSelector} from 'reselect';
import * as warehouseSelector from './../../Warehouse/selectors';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'package.createPackageViaScan' + (param ? '.' + param : ''), defaultValue);

export const getInputFocus = createSelector(
    state => getState(state),
    state => lodash.pick(state, ['inputFocus', 'inputFocusId'])
);

export const canCreatePackageSameBarcode = createSelector(
    state => getState(state, 'packages', []),
    packages => packages.length && lodash.every(packages, {is_info_complete: true})
);


export const getPackage = createSelector(
    state => getState(state, 'packages', []),
    state => warehouseSelector.getState(state, 'list', []),
    (packages, warehouses) => {
        return packages.map(packageData => {
            if (lodash.isNil(packageData.warehouse_input) && warehouses.length > 0 && packageData.id_warehouse_input !== 0) {
                let inputWarehouses = warehouses.filter(warehouse => {
                    return warehouse.id === parseInt(packageData.id_warehouse_input, 10);
                });

                packageData = {...packageData, warehouse_input: inputWarehouses.length > 0 ? inputWarehouses[0] : {}};
            }

            return packageData;
        });
    }
);

export const getOrderSuggestCodes = createSelector(
    state => getState(state, 'orderSuggests', []),
    orderSuggests => lodash.map(orderSuggests, 'order.code')
);
