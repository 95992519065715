import React from "react"
import lodash from "lodash";
import {createSelector} from "reselect";
import {Icon} from "antd";
import Link from "../../../system/routing/Link";
import {LIST_PROPERTY_COLUMN} from "../constants";

import CustomIcon from './../../../components/icons/Icon';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'property' + (param ? '.' + param : ''), defaultValue);
export const getListProperty = createSelector(
	(state) => getState(state, 'list', []),
	(properties) => lodash.map(properties, property => {
		const icon = lodash.get(property, 'property.icon');

		return {
			key: lodash.get(property, 'property.id', 0),
			active: lodash.get(property, 'property.active', true),
			[LIST_PROPERTY_COLUMN.NAME]: <div>{lodash.get(property, 'property.name', '--') || '--'}</div>,
			[LIST_PROPERTY_COLUMN.ALIAS]: <span
			>{lodash.get(property, 'property.alias', '--') || '--'}</span>,
			[LIST_PROPERTY_COLUMN.NOTE]: <span>{lodash.get(property, 'property.note', '--') || '--'}</span>,
			[LIST_PROPERTY_COLUMN.ICON]: <span><CustomIcon type={icon} /></span>,
			[LIST_PROPERTY_COLUMN.ACTION]: <Link to={'config.property.update'}
			                                     params={{id: lodash.get(property, 'property.id', 0)}}><Icon
				type="edit"
				className={"mr-3 ahover-pointer a-text--blue a-text--right"}/></Link>

		}
	})
);
