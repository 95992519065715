import React, { useState } from 'react';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import lodash from 'lodash';

import * as actions from './actions';
import apiService from './apiService';

import CancelDeliveryNote from './components';

const mapStateToProps = (state, props) => ({
    deliveryNoteData: props.deliveryNoteData,
    packageTotal: props.packageTotal,
    isModalProcessing: lodash.get(state, 'deliveryNote.deliveryNoteCancel.isProcessing', false),
    loading: lodash.get(state, 'deliveryNote.deliveryNoteCancel.loading', false),
    disabled: lodash.get(state, 'deliveryNote.deliveryNoteExport.isProcessing', false),
});

const mapDispatchToProps = (dispatch) => ({
    beginCancelDeliveryNote: () => {
        dispatch(actions.beginCancelDeliveryNote());
    },
    endCancelDeliveryNote: () => {
        dispatch(actions.endCancelDeliveryNote());
    }
});

const CancelDeliveryNoteContainer = props => {
    const [loading, setLoading] = useState(false);

    const cancelDeliveryNote = async deliveryNoteId => {
        setLoading(true);

        try {
            const { data } = await apiService.cancelDeliveryNote(deliveryNoteId);

            notification.success({
                message: props.t('delivery_note:message.destroy_success'),
            });

            if (lodash.isFunction(props.onSuccessCancelDeliveryNote)) {
                props.onSuccessCancelDeliveryNote(data);
            }
        } catch (error) {
            const errorData = error && error.response && error.response.data;

            if (lodash.get(errorData, 'warehouse')) {
                return notification.error({
                    message: props.t('message.warehouse_not_is_staff')
                });
            }

            return notification.error({
                message: props.t('delivery_note:message.destroy_failed'),
            });
        }

        setLoading(false);
    };

    return (
        <CancelDeliveryNote
            {...props}
            loading={loading}
            cancelDeliveryNote={cancelDeliveryNote}
        />
    );
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelDeliveryNoteContainer));
