import { api } from './../../../system/api';

const BASE_URL = '/delivery-note-returns';

export default {
    getList: params => api.get(BASE_URL, {
        params
    }),
    add: (data = {}) => api.post(BASE_URL, data, {
        singleRequest: true
    }),
    update: (data = {}) => api.put(`${BASE_URL}/${data.id}`, data, {
        singleRequest: true
    }),
    detail: id => api.get(`${BASE_URL}/${id}`, {}, {
        singleRequest: true
    }),
    export: id => api.put(`${BASE_URL}/${id}/export`)
};
