/* eslint-disable */
import React, {Component} from 'react';
import {
    Layout, Button, Collapse, Input, Row, Col, Form, Radio, Modal, Icon, Alert, AutoComplete, Checkbox, Select
} from 'antd';
import SiderPackage from '../Sidebar/modules/SiderPackage';

const {TextArea} = Input;
const Option = Select.Option;
const {Content} = Layout;
const Panel = Collapse.Panel;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
/*Khai báo component heading*/
const heading = (props) => (
    <h4 className="a-collapse--package__title">BT-CNGZ <span>/ 012837372845</span></h4>
);

/*End*/
function confirm() {
    Modal.confirm({
        title: 'CẢNH BÁO',
        content: 'Bạn có chắc chắn muốn bỏ khớp đơn này ?',
        okText: 'Đồng ý',
        cancelText: 'Hủy',
        zIndex: 1050,
        icon: <Icon type="question-circle" theme="filled"/>
    });
}

class EditLinkPackage extends Component {
    state = {
        selectedItems: [],
    };

    handleChange = selectedItems => {
        this.setState({selectedItems});
    };

    render() {
        const {selectedItems} = this.state;
        const filteredOptions = OPTIONS.filter(o => !selectedItems.includes(o));
        return (
            <Layout>
                <SiderPackage/>
                <Content className="a-content a-content--package">
                    <div className="a-content__top">
                        <div className="a-content__top__title">
                            <h3>Mã Vận Đơn - <a href="#" className="">216321995</a></h3>
                        </div>
                        <Button type="primary" htmlType="submit"
                                className="a-btn a-btn--primary a-btn--create-package-same-  a-btn--disable">Tạo
                            kiện cùng vận đơn</Button>
                    </div>
                    <div
                        className="a-content__body a-content__block-scroll a-package__body a-package__body a-package__body--scroll">
                        <Collapse accordion className="a-collapse--package" bordered={false} defaultActiveKey={['1']}>
                            <div className="a-collapse--package__block-absolute-heading">
                                <AutoComplete
                                    placeholder={'Mã khớp đơn'}
                                    className="a-input a-input--matching-code a-hidden"
                                />
                                <p className="info-matching_code">Mã khớp đơn <span>- 213219950302</span></p>
                            </div>
                            <Panel header={heading()} key="1" className="a-collapse--package__item">
                                <div className="a-collapse--package__body is_form">
                                    <Form onSubmit={this.handleSubmit} className="a-form form--package">
                                        <Row gutter={48}>
                                            <Col lg={{span: 17}} xl={{span: 19}} xxl={{span: 18}}>
                                                <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray info--detail">
                                                    <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                                        <h4>Thông tin kiện hàng</h4>
                                                    </Col>
                                                    <Col lg={{span: 14}} xl={{span: 6}} xxl={{span: 4}}
                                                         className="a-form--group">
                                                        <Form.Item className="a-form__item " label="Tình trạng"
                                                                   labelCol={{span: 24}}>
                                                            <Select
                                                                className="a-select a-select--w-100"
                                                                showSearch={true}
                                                                defaultValue="Lưu kho"
                                                                suffixIcon={
                                                                    <Icon
                                                                        type="down"
                                                                        className="icon__dropdown__select"
                                                                        onChange={this.handleSelectChange}
                                                                    />}
                                                            >
                                                                <Option value='1'><Checkbox>Checkbox</Checkbox></Option>
                                                                <Option value='2'><Checkbox>Checkbox</Checkbox></Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={{span: 14, offset: 0}} xl={{span: 6, offset: 2}}
                                                         xxl={{span: 4, offset: 2}}
                                                         className="a-form--group">
                                                        <Form.Item className="a-form__item reason--save-warehouse "
                                                                   label="Lý do lưu kho"
                                                                   labelCol={{span: 24}}>
                                                            <Select
                                                                className="a-select a-select-rease--warehouse"
                                                                showSearch={true}
                                                                defaultValue="Không rõ nguồn gốc"
                                                                suffixIcon={
                                                                    <Icon
                                                                        type="down"
                                                                        className="icon__dropdown__select"
                                                                        onChange={this.handleSelectChange}
                                                                    />}
                                                            >
                                                                <Option value="Không rõ nguồn gốc">Không rõ nguồn
                                                                    gốc</Option>
                                                                <Option value="Khác">Khác</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={{span: 10}} xl={{span: 9}}
                                                         xxl={{span: 12}}
                                                         className="a-form--group input-rease-warehouse">
                                                        <Form.Item className="a-form__item reason--save-warehouse "
                                                                   label=""
                                                                   labelCol={{span: 24}}>
                                                            <Input placeholder={"Nhập lý do khác"}
                                                                   className="a-input--reaseon-save-warehouse a-input--disable"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={{span: 24}} xl={{span: 24}}
                                                         xxl={{span: 24}}
                                                         className="a-form--group">

                                                        <Form.Item className="a-form__item  "
                                                                   label="Đặc tính"
                                                                   labelCol={{span: 24}}>
                                                            <ul className="property--package">
                                                                <li className="list active">
                                                                    <a href="#" className="link">Điện tử</a>
                                                                </li>
                                                                <li className="list">
                                                                    <a href="#" className="link">Dễ vỡ</a>
                                                                </li>
                                                                <li className="list">
                                                                    <a href="#" className="link">Hóa chất</a>
                                                                </li>
                                                                <li className="list">
                                                                    <a href="#" className="link">Giá trị cực cực cao</a>
                                                                </li>
                                                            </ul>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={{span: 24}} xl={{span: 24}}
                                                         xxl={{span: 24}}
                                                         className="a-form--group">
                                                        <Form.Item className="a-form__item  " label="Ghi chú"
                                                                   labelCol={{span: 24}}>
                                                            < TextArea className="a-input--textarea"
                                                                       autosize={{minRows: 1, maxRows: 3}}
                                                                       allowClear={true}
                                                                       spellCheck={false}
                                                                       placeholder={"Ghi chú"}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray mt-4">
                                                    <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                                        <h4>Thông số kiện hàng</h4>
                                                    </Col>
                                                    <Form onSubmit={this.handleSubmit} className="a-form form--package">
                                                        <Col lg={{span: 24}} xl={{span: 6}} xxl={{span: 6}} className={"pl-0"}>
                                                            <Form.Item
                                                                className="a-form__item a-form--loading loading--volumn-package"
                                                                label={<span
                                                                    className="label-require">Cân nặng (g)</span>}
                                                                labelCol={{span: 24}}>
                                                                <Input placeholder="Cân nặng tịnh"
                                                                       className="a-input a-input--bill-code-small"/>
                                                                <Icon type="loading-3-quarters"
                                                                      className="icon-loading "/>
                                                                <span className="a-text--black-45 ml-3">x 100</span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col lg={{span: 24}} xl={{span: 18}} xxl={{span: 18}}>
                                                            <div className="a-package__body--group-size">
                                                                <Form.Item
                                                                    className="a-form__item"
                                                                    label={<span>Kích thước(cm)</span>}
                                                                    labelCol={{span: 24}}>
                                                                    <div className="group--input-bill-code-small">
                                                                        <div
                                                                            className="a-form--loading loading-input mr-4">
                                                                            <Input placeholder="Dài"
                                                                                   className="a-input a-input--package-size a-input--warning "/>
                                                                            <Icon type="loading-3-quarters"
                                                                                  className="icon-loading"/>
                                                                        </div>
                                                                        <div
                                                                            className="a-form--loading loading-input mr-4">
                                                                            <Input placeholder="Rộng"
                                                                                   className="a-input a-input--package-size "/>
                                                                            <Icon type="loading-3-quarters"
                                                                                  className="icon-loading"/>
                                                                        </div>
                                                                        <div
                                                                            className="a-form--loading loading-input mr-4">
                                                                            <Input placeholder="Cao"
                                                                                   className="a-input a-input--package-size"/>
                                                                            <Icon type="loading-3-quarters"
                                                                                  className="icon-loading"/>
                                                                        </div>
                                                                        <span
                                                                            className="bill-code-sub">= 102384 (cm<sup>3</sup>)</span>
                                                                    </div>
                                                                    <p className="a-text--warning mb-0 mt-1 a-hidden">abcde</p>
                                                                    <p className="a-text--warning mb-0 mt-1 a-hidden">abcde</p>
                                                                    <div className="mt-4">
                                                                        <a href="#" className="link-reload "><Icon type="close" />Xóa kích thước</a>
                                                                    </div>
                                                                </Form.Item>
                                                                <div className="info--group package--block-volumn">
                                                                    <label className="info--label">Cân nặng quy đổi
                                                                        (kg)</label>
                                                                    <a href="#" className="info--value ">983</a>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Form>
                                                </Row>
                                            </Col>
                                            <Col lg={{span: 7}} xl={{span: 5}} xxl={{span: 6}}
                                                 className="a-package--service only-view">
                                                <h2 className="title">Dịch vụ sử dụng</h2>
                                                <div className="service--body ">
                                                    <div className="service--block">
                                                        <div className="service--block--body">
                                                            <ul>
                                                                <li>
                                                                    <span>Chuyển thẳng</span>
                                                                    <span>Đóng gỗ riêng </span>
                                                                    <span>Kiểm hàng </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={48} className={"a-block-info"}>
                                            <Col lg={{span: 24}} xl={{span: 24}} className="info--title iscollapse">
                                                <div className="title">
                                                    <h4>Thông tin khách hàng</h4>
                                                </div>
                                                <div className="title--collapse">
                                                    <span>Thu gọn<Icon type="shrink" className="icon-collapse"/></span>
                                                </div>

                                            </Col>
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group">
                                                <label className="info--label">Username</label>
                                                <span className="info--value--small ">tronglevan</span>
                                            </Col>
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group">
                                                <label className="info--label">Tên khách hàng</label>
                                                <span className="info--value--small ">Lê Văn Trọng</span>
                                            </Col>
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                 className="info--group">
                                                <label className="info--label">Số điện thoại</label>
                                                <span className="info--value--small ">03639977112</span>
                                            </Col>
                                            <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                                                <Row gutter={48}>
                                                    <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                         className="info--group">
                                                        <label className="info--label">Quốc gia</label>
                                                        <span className="info--value--small ">Việt Nam</span>
                                                    </Col>
                                                    <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                         className="info--group">
                                                        <label className="info--label">Tỉnh thành</label>
                                                        <span className="info--value--small ">Hà Nội</span>
                                                    </Col>
                                                    <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                         className="info--group">
                                                        <label className="info--label">Quận huyện</label>
                                                        <span className="info--value--small ">Nam Từ
                                                                Liêm</span>
                                                    </Col>
                                                    <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                         className="info--group">
                                                        <label className="info--label">Kho đích</label>
                                                        <span className="info--value--small ">CNPZ</span>
                                                    </Col>
                                                    <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                                         className="info--group">
                                                        <label className="info--label">Địa chỉ nhận</label>
                                                        <span className="info--value--small ">Hà Nội</span>
                                                    </Col>
                                                    <Col lg={{span: 24}} xl={{span: 12}} xxl={{span: 12}}
                                                         className="info--group">
                                                        <label className="info--label">Ghi chú</label>
                                                        <span className="info--value--small ">Nam Từ
                                                                Liêm</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={48}>
                                            <Col lg={{span: 24}} xl={{span: 24}}
                                                 className="a-package--action only-action">
                                                <a className="a-btn a-btn--back-package ">Thoát</a>
                                                <Button type="primary" htmlType="submit"
                                                        className="a-btn a-btn--primary a-btn--save-package ">Lưu</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Content>
            </Layout>
        );
    }
}


export default EditLinkPackage;
