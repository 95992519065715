import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE_DETAIL.REQUEST, saga.fetchDeliveryNoteDetail);
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE_DETAIL.FAILED, saga.onFetchDeliveryNoteDetailFailed);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE_DETAIL.REQUEST, saga.updateDelivery);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE_DETAIL.SUCCESS, saga.updateDeliverySuccess);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE_DETAIL.FAILED, saga.updateDeliveryFailed);

    yield takeLatest(ACTION.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.REQUEST, saga.removePackageFromDeliverNote);
    yield takeLatest(ACTION.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.FAILED, saga.removePackageFromDeliverNoteFailed);
    yield takeEvery(ACTION.REMOVE_PACKAGE_FROM_DELIVERY_NOTE.SUCCESS, saga.removePackageFromDeliverNoteSuccess);

    yield takeLatest(ACTION.REMOVE_BAG_FROM_DELIVERY_NOTE.REQUEST, saga.removeBagFromDeliverNote);
    yield takeLatest(ACTION.REMOVE_BAG_FROM_DELIVERY_NOTE.FAILED, saga.removeBagFromDeliverNoteFailed);
    yield takeLatest(ACTION.REMOVE_BAG_FROM_DELIVERY_NOTE.SUCCESS, saga.removeBagFromDeliverNoteSuccess);
}
