import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {router} from '../../../system/routing';
import {t} from '../../../system/i18n';

export function* createBagSize(action) {
    yield processApiRequest(ACTION.CREATE_BAG_SIZE, () => apiService.createBagSize(action.payload), action.payload);
}

export function* createBagSizeSuccess() {
    yield notification['success']({
        message: t('common:message.create_bag_size_success'),
        className:'_create_bag_size_success'
    });
    yield router.redirect('/config/bag-sizes');
}