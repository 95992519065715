import React, { PureComponent } from 'react';
import { Button, notification } from 'antd';
import { get } from 'lodash';

import { INVENTORY_STATUS_CHECKING } from './../../constants';
import { isPackageInventory, isReceivePackageInventory } from './../../helper';
import { trans } from './../../../../system/i18n';

class CheckingInventory extends PureComponent {
	handleComparing = () => {
		const { inventory, t, checkingInventory, comparingTrackingBill } = this.props;
		let action = () => {};

		if (isPackageInventory(inventory)) {
			action = checkingInventory;
		}

		if (isReceivePackageInventory(inventory)) {
			action = comparingTrackingBill;
		}

		if (inventory.status === INVENTORY_STATUS_CHECKING) {
			action(get(inventory, 'id', 0));
		} else {
			notification.error({
				message: trans('inventory:message.error_checking_inventory_by_status_inventory', {
					code: t(`inventory:status_inventory.${inventory.status}`).toLowerCase()
				})
			});
		}
	}

	render() {
		const { comparing, inventory, loading, t } = this.props;

		return (
			<Button
				className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 _btn-comparing"
				disabled={comparing || get(inventory, 'status') !== INVENTORY_STATUS_CHECKING}
				loading={loading || comparing}
				type="primary"
				onClick={this.handleComparing}
			>
				{t('inventory:btn.control')}
			</Button>
		);
	}
}

export default CheckingInventory;
