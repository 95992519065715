import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { setMenuActive } from './../../Common/actions';
import { getActions } from './../../NoticeStage/actions';
import apiService from './../../NoticeStage/apiService';
import withProperties from './../../../hocs/withProperties';
import withConsignServices from './../../../hocs/withConsignServices';
import withServices from './../../../hocs/withServices';

import NoticeStages from './components';

class NoticeStagesContainer extends Component {
    state = {
        loading: false,
        noticeStages: []
    };

    getNoticeStages = async () => {
        this.setState({
            loading: true
        });

        try {
            const response = await apiService.list();

            this.setState({
                noticeStages: response.data
            });
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };

    handleChange = async noticeStage => {
        try {
            await apiService.update(noticeStage.id, noticeStage);
        } catch (error) {
        }
    };

    render() {
        const { loading, noticeStages } = this.state;

        return (
            <NoticeStages
                {...this.props}
                loading={loading}
                noticeStages={noticeStages}
                getNoticeStages={this.getNoticeStages}
                onChange={this.handleChange}
            />
        );
    }
}

export default withTranslation()(connect(
    undefined,
    {
        getActions,
        setMenuActive
    }
)(withProperties(withConsignServices(withServices(NoticeStagesContainer)))));
