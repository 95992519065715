import React from 'react';

import CustomerInformation from './components';

const CustomerInformationContainer = props => {
    const onSubmit = data => {
        const formattedData = {
            ...data,
            id_customer: data.id_customer || 0,
            id_warehouse_destination: data.id_warehouse_destination || 0,
            customer_id_country_destination: data.customer_id_country_destination || 0,
            customer_id_city_destination: data.customer_id_city_destination || 0,
            customer_id_district_destination: data.customer_id_district_destination || 0,
            customer_id_ward_destination: data.customer_id_ward_destination || 0
        };

        props.onSubmit(formattedData);
    };

    return (
        <CustomerInformation {...props} onSubmit={onSubmit} />
    );
};

export default CustomerInformationContainer;
