import { withTranslation } from 'react-i18next'
import React from 'react'
import { Row, Col, Button, Modal } from 'antd'
import { getVar } from '../../../../system/support/helpers'
import { isNil } from 'lodash'
import { dateFormatter } from '../../../Common/services/format'
import { DOCUMENT_STATUS } from '../../constants'
import EditNote from './EditNote'
function DocumentInfo({ t, data, loadingConfirm, confirmDocument, cancelDocument, loadingCancel, loadingUpdate, updateDocument }) {
    const createdAt = getVar(data, 'created_at')
    const status = getVar(data, 'status')
    const id = getVar(data, 'id')
    const handleConfirm = () => {
        Modal.confirm({
            title: t('return_goods:message.warning_confirm'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => {
                confirmDocument(id)
            },
        })
    }

    const handleCancel = () => {
        Modal.confirm({
            title: t('return_goods:message.warning_cancel'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => {
                cancelDocument(id)
            },
        })
    }

    return (
        <div className="block--primary a-block-info abg-white detail-delivery-note aflex-1 gflex gflex-direction-column">
            <div className="title gflex galign-center gjustify-space-between">
                <span>{t('return_goods:title.document_info')}</span>
                <div className="a-barcode-scan__top-action">
                    {status === DOCUMENT_STATUS.PENDING && (
                        <>
                            <Button
                                ghost
                                className="a-btn a-btn--transparent-white"
                                loading={loadingConfirm}
                                onClick={handleConfirm}>
                                {t('btn.confirm')}
                            </Button>

                            <Button
                                ghost
                                className="ml-2 a-btn a-btn--transparent-white"
                                loading={loadingCancel}
                                onClick={handleCancel}>
                                {t('btn.cancel')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className="body">
                <Row
                    className="mx-0"
                    gutter={{ lg: 24, xl: 32 }}
                    type="flex">
                    <Col
                        className="info--group"
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}>
                        <label className="info--label">{t('return_goods:label.document_code')}</label>
                        <span className="info--value ">{getVar(data, 'code', '---')}</span>
                    </Col>
                    <Col
                        className="info--group"
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}>
                        <label className="info--label">{t('return_goods:label.status')}</label>
                        <span className="info--value ">{getVar(data, 'status') ? t(`return_goods:status.${getVar(data, 'status')}`) : '---'}</span>
                    </Col>
                    <Col
                        className="info--group"
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}>
                        <label className="info--label">{t('return_goods:label.warehouse_process')}</label>
                        <span className="info--value ">{getVar(data, 'warehouse.name', '---')}</span>
                    </Col>
                    <Col
                        className="info--group"
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}>
                        <label className="info--label">{t('return_goods:label.delivery_note_or_delivery_request_code')}</label>
                        <span className="info--value ">{getVar(data, 'delivery_note.code', getVar(data, 'delivery_request.code', '---'))}</span>
                    </Col>
                    <Col
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group">
                        <label className="info--label">{t('return_goods:label.created_at')}</label>
                        <span className="info--value ">{isNil(createdAt) ? '--' : dateFormatter.dateTime(createdAt)}</span>
                    </Col>

                    <Col
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group">
                        <label className="info--label">{t('return_goods:label.id_creator')}</label>
                        <span className="info--value ">{getVar(data, 'creator.username', '---')}</span>
                    </Col>

                    <Col
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group">
                        <label className="info--label">{t('return_goods:label.id_confirmation_agent')}</label>
                        <span className="info--value ">{getVar(data, 'confirmation_agent.username', '---')}</span>
                    </Col>

                    <Col
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                        className="info--group">
                        <EditNote
                            value={getVar(data, 'note')}
                            id={data.id}
                            loadingUpdate={loadingUpdate}
                            updateDocument={updateDocument}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default withTranslation()(DocumentInfo)
