import {connect} from 'react-redux';
import {clearDetailCustomer, clearErrorDetailCustomer, fetchDetailCustomer,} from "../../Customer/actions";
import * as customerSelectors from '../../Customer/selectors';
import SelectCustomerDeliveryNote from './components/FormSelectCustomer';

const mapStateToProps = (state, props) => {
    return {
        ...props,
        loading: customerSelectors.getState(state, 'loading.detail'),
        errorDetail: customerSelectors.getState(state, 'errorDetail.code'),
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    clearDetailCustomer: () => dispatch(clearDetailCustomer()),
    fetchDetailCustomer: (customerId) => dispatch(fetchDetailCustomer(customerId, {
        from: 'SELECT_DELIVERY_CUSTOMER',
        params: {
            ...props.input,
            id_customer: customerId
        },
    })),
    clearErrorDetailCustomer: (data) => dispatch(clearErrorDetailCustomer(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectCustomerDeliveryNote);
