import { combineReducers } from 'redux';
import * as lodash from 'lodash';

import * as ACTION_SCAN from './../InventoryBarcode/constants';
import * as ACTION from './../InventoryScan/constants';

const inventoryPackages = (state = [], action) => {
	const { payload, type } = action;
	let newInventoryPackages = [
		...state
	];

	switch (type) {
		case ACTION.FETCH_INVENTORY.SUCCESS: {
			return payload.inventory_packages;
		}
		case ACTION_SCAN.SCAN_PACKAGE.REQUEST: {
			newInventoryPackages.splice(0, 0, {
				inventory_package: {
					loading: true
				}
			});

			return newInventoryPackages;
		}
		case ACTION_SCAN.SCAN_PACKAGE.SUCCESS: {
			const index = lodash.findIndex(state, values => values.inventory_package.id === payload.inventory_package.id);

			if (index >= 0) {
				newInventoryPackages.splice(index, 1, payload);
				lodash.remove(newInventoryPackages, (values, index) => index === 0);
			} else {
				newInventoryPackages.splice(0, 1, payload);
			}

			return newInventoryPackages;
		}
		case ACTION_SCAN.SCAN_PACKAGE.FAILED: {
			if (lodash.get(payload, 'data.id_package', '')) {
				newInventoryPackages.splice(0, 1, {
					inventory_package: {
						id: action.request.code_package,
						situation: 'exists',
						payload
					}
				})
			}

			return newInventoryPackages;
		}
		default: {
			return state;
		}
	}
};

const inventoryTrackingBills = (state = [], action) => {
	const { payload, type } = action;
	let newInventoryTrackingBills = [
		...state
	];

	switch (type) {
		case ACTION.FETCH_INVENTORY.SUCCESS: {
			const formatedInventoryTrackingBills = (payload.inventory_tracking_bills || []).map(inventoryTrackingBill => {
				const warnings = lodash.get(inventoryTrackingBill, 'warnings', []);
				const filteredWarnings = warnings.filter(warning => !warning.package);

				return {
					...inventoryTrackingBill,
					warnings: filteredWarnings
				};
			});

			return formatedInventoryTrackingBills;
		}
		case ACTION_SCAN.SCAN_TRACKING_BILL.REQUEST: {
			newInventoryTrackingBills.splice(0, 0, {
				inventory_tracking_bill: {
					loading: true
				}
			});

			return newInventoryTrackingBills;
		}
		case ACTION_SCAN.SCAN_TRACKING_BILL.SUCCESS: {
			const index = lodash.findIndex(state, values => values.inventory_tracking_bill.id === payload.inventory_tracking_bill.id);
			const trackingBill = {
				...payload
			};

			trackingBill.warnings = trackingBill.warnings.filter(warning => !warning.package);

			if (index >= 0) {
				trackingBill.warnings = [
					'duplicate',
					...trackingBill.warnings,
				];
			}

			newInventoryTrackingBills.splice(0, 1, trackingBill);

			return newInventoryTrackingBills;
		}
		case ACTION_SCAN.SCAN_TRACKING_BILL.FAILED: {
			newInventoryTrackingBills.splice(0, 1, {
				inventory_tracking_bill: {
					id: action.request.tracking_bill,
					situation: 'exists',
					payload
				}
			});

			return newInventoryTrackingBills;
		}
		default: {
			return state;
		}
	}
};

export default combineReducers({
	inventoryPackages,
	inventoryTrackingBills
});
