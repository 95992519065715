import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton} from 'antd';
import {withTranslation} from 'react-i18next';
import authService from '../../../Auth/authService';
import PERMISSION from '../../../Auth/permissions';
import Link from "../../../../system/routing/Link";
import lodash from "lodash";
import {PACKAGE_COLUMN} from "../constants";
import DetachPackage from './DetachPackage';
import TablePackage from "../../ListBagPackage/components/TablePackage";
import ExportExcel from "./ExportExcel";
import ChangePackageToDelivering from "./ChangePackageToDelivering";

class ListBagPackages extends React.PureComponent {
    getColumns() {
        return lodash.values(PACKAGE_COLUMN)
            .map(column => ({
                key: column,
                dataIndex: column,
                title: (column !== PACKAGE_COLUMN.DELETE) ? this.props.t('bag:packing.' + column) : '',
            }));
    }

    getDataSource() {
        return this.props.packages.map((packageData, i) => {
            if (packageData.loading) {
                let columns = lodash.mapKeys(PACKAGE_COLUMN, (value) => value.toLowerCase());

                return lodash.mapValues(columns, () => <Skeleton active paragraph={{rows: 0}}/>);
            }

            return {
                [PACKAGE_COLUMN.INDEX]: i + 1,
                [PACKAGE_COLUMN.CODE_PACKAGE]: <Link to="packages.detail"
                                                     params={{id: packageData.code}}>{packageData.code}</Link>,
                [PACKAGE_COLUMN.CODE_ORDER]: lodash.get(packageData, 'order.code') || '--',
                [PACKAGE_COLUMN.WEIGHT]: packageData.weight_net ? packageData.weight_net + " (kg)" : '--',
                [PACKAGE_COLUMN.VOLUME]: packageData.volume ? lodash.round(packageData.volume * 1000000, 3) + " (cm3)" : '--',
                [PACKAGE_COLUMN.DELETE]: this.props.canDelete ? <DetachPackage
                    package={packageData}
                    canDelete={this.props.canDelete}
                    onDetach={() => this.props.onDetach(packageData.id)}
                /> : '',
            };
        });
    }


    render() {
        let {t, packages, bag, fetchBag} = this.props;
        const {permissions} = bag;
        const can_change_package_to_delivering = lodash.get(permissions, "can_change_package_to_delivering", true);
        let canExportExcel = authService.can(PERMISSION.EXPORT_PACKAGES_LIST_IN_BAG);

        return (
            <div className="list-package-in-bag">
                <div className="a-content__top a-content__top--page-list  mr-0">
                    <div className="a-content--page-list__title" id="title-search">
                        <h3>{t('bag:packing.package_list')} ({packages.length})</h3>
                    </div>
                    <div className="a-content--page-list__top-action" id="content-list">
                        {
                            !!can_change_package_to_delivering && <ChangePackageToDelivering bagId={bag.id} fetchBag={fetchBag}/>
                        }
                        {canExportExcel && (
                            <ExportExcel {...this.props} />
                        )}
                        {!!lodash.get(bag, 'permissions.can_packing') && (
                            <Link to={"bags.packing"}
                                  params={{id: bag.id}}
                                  className="a-btn a-btn--link-primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 _btn-scan-package-to-tag"
                            >{t("bag:label.scan_package_in_bag")}
                            </Link>
                        )}
                    </div>
                </div>
                {packages.length > 0 ?
                    <TablePackage
                        page={"detail"}
                        bag={bag}
                        canDelete={!!lodash.get(bag, 'permissions.can_packing')}
                        loading={this.props.loading}
                        packingData={packages}
                    /> :
                    <div className="content-list-package-in-bag--empty">
                        <img src={require('../../../../resources/images/boxempty.png')} alt={"boxempty"}/>
                        <span className="info">CHƯA CÓ KIỆN NÀO TRONG BAO. “<a
                            href={"/bags/packing/" + bag.id}>ĐÓNG BAO</a>” NGAY</span>
                    </div>
                }
            </div>
        )
    }
}

ListBagPackages.defaultProps = {
    bag: {},
    packages: [],
    onDetach: () => {
    },
};

ListBagPackages.propTypes = {
    bag: PropTypes.object,
    packages: PropTypes.array,
    onDetach: PropTypes.func,
};

export default withTranslation()(ListBagPackages);
