import React from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import { values } from 'lodash';
import { withTranslation } from 'react-i18next';

import { DAMAGED_STATUSES } from './../../../../Package/constants';

const { Option } = Select;

const DamagedStatusesSelect = ({ className, dropdownClassName, value, t, ...other }) => {
    return (
        <Select
            {...other}
            className={clsx(className, '_damaged-statuses-select')}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-damaged-statuses')}
            value={value}
        >
            {values(DAMAGED_STATUSES).map(status => (
                <Option
                    className="_damaged-statuses-item"
                    key={status}
                    value={status}
                >
                    {t(`package:damaged_statuses.${status}`)}
                </Option>
            ))}
        </Select>
    );
};

export default withTranslation()(DamagedStatusesSelect);
