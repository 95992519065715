export const LANGUAGE = {
	vi: 'vi',
	zh: 'zh',
	// en: 'en'
};

export const FLAG = {
	vi: require("../../../resources/images/flag-vietnam.svg"),
	zh: require("../../../resources/images/flag-cn.jpg"),
	// en: require("../../resources/img/flags-icons/us.png")
};
export const CHANGE_LANGUAGE = {
	REQUEST: 'CHANGE_LANGUAGE.REQUEST',
	SUCCESS: 'CHANGE_LANGUAGE.SUCCESS',
	FAILED: 'CHANGE_LANGUAGE.FAILED',
}
