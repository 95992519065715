import Router from './Router';
import UrlGenerator from './UrlGenerator';
import RouteNormalizer from './RouteNormalizer';
import Link from './Link';
import routes from '../../routes';

let router = new Router({...routes, routes: (new RouteNormalizer()).nomalize(routes.routes)});
let url = new UrlGenerator(router);

export {router, url, Link};
