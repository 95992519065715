import { notification } from 'antd';
import { get } from 'lodash';

import * as ACTION from './constants';
import apiService from './../apiService';
import { processApiRequest } from './../../../Common/saga';
import { t } from './../../../../system/i18n';
import { router } from './../../../../system/routing';

export function* add(action) {
    yield processApiRequest(
        ACTION.ADD,
        () => apiService.add(action.payload),
        action.payload
    );
}

export function* successAdd(action) {
    yield notification.success({
        message: t('delivery_note:return.create.success')
    });

    router.redirect(`/delivery-notes/return/${get(action, 'payload.delivery_note_return.id', 0)}`);
}

export function* failedAdd(action) {
    const error = get(action, 'payload.data');

    if (get(error, 'warehouse')) {
        return yield notification.error({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (get(error, 'id_warehouse.required')) {
        return yield notification.error({
            message: t('validation:required', {
                attribute: t('delivery_note:label.id_warehouse')
            })
        });
    }

    return yield notification.error({
        message: t('delivery_note:return.create.failed')
    });
}
