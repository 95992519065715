import * as ACTION from './constants';
import apiService from './apiService';
import {processApiRequest} from '../Common/saga';
import {notification} from "antd";
import {t} from "../../system/i18n";

export function* fetchSetting(action) {
    yield processApiRequest(ACTION.FETCH_SETTING, () => apiService.getSetting(), action.payload);
}

export function* fetchDetailSetting(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_SETTING, () => apiService.getDetailSetting(), action.payload);
}

export function* updateSetting(action) {
    yield processApiRequest(ACTION.UPDATE_SETTING, () => apiService.updateSetting(action.payload), action.payload);
}

export function* updateSettingSuccess() {
    // setTimeout(() => {
    //     window.location.reload()
    // }, 1000);
    yield notification.success({message: t('config:message.update_setting_success')});
}
