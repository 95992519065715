import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getBarcodeWarning } from './../DeliveryNoteBarcode/selectors';
import { BARCODE_TYPE } from './../../Barcode/constants';

export const getState = (state, param = null, defaultValue = null) => get(state, `deliveryNote.formCustomer${(param ? `.${param}` : '')}`, defaultValue);

export const getBarcodes = createSelector(
    state => getState(state, 'deliveryNote.barcodes', []),
    barcodes => barcodes.map(barcode => ({
        ...barcode,
        warning: getBarcodeWarning(barcode.warning)
    }))
);

export const getBags = createSelector(
    getBarcodes,
    barcodes => barcodes.filter(barcode => barcode.type === BARCODE_TYPE.BAG)
);

export const getPackages = createSelector(
    getBarcodes,
    barcodes => barcodes.filter(barcode => barcode.type === BARCODE_TYPE.PACKAGE)
);

export const getBagIds = createSelector(
    getBags,
    bags => bags.map(bag => get(bag, 'bag.id'))
);

export const getPackageIds = createSelector(
    getPackages,
    packages => packages.map(pkg => get(pkg, 'package.id'))
);
