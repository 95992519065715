import { compact } from 'lodash';

import { MAX_ITEM_PER_PAGE } from './constants';

class SettingHistory {
    getDataHistory() {
        const listDataLocaStorage = localStorage.getItem('listDataScan_');

        return JSON.parse(listDataLocaStorage) || [];
    }
    updateDataHistory(data) {
        localStorage.setItem('listDataScan_', JSON.stringify(data));
    }
    addItemsToDataHistory(items) {
        let listDataLocalStorage = this.getDataHistory();

        if (!Array.isArray(items)) {
            items = [items];
        }

        //  Nếu lớn hơn 200 bản ghi thì xóa bản ghi cuối đi
        if (listDataLocalStorage.length >= MAX_ITEM_PER_PAGE) {
            listDataLocalStorage = listDataLocalStorage.slice(0, MAX_ITEM_PER_PAGE - items.length);
        }

        listDataLocalStorage = compact(items.concat(listDataLocalStorage));
        this.updateDataHistory(listDataLocalStorage);
    }
}

const settingHistory = new SettingHistory();

export default settingHistory;
