import React from 'react';
import { Form } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import PackageStorageFees from './components';

const PackageStorageFeesContainer = props => {
    return (
        <PackageStorageFees
            {...props}
        />
    );
};

export default withTranslation()(withRouter(Form.create()(PackageStorageFeesContainer)));
