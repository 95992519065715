import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { get, isEqual } from 'lodash';

import { TABLE_COLUMNS } from './../../../constants';
import { t } from './../../../../../system/i18n';

class ShippingFeeTable extends Component {
    state = {
        columns: [{
            className: '_shipping-partner-column',
            title: t('shipping_partner'),
            dataIndex: TABLE_COLUMNS.SHIPPING_PARTNER,
            key: TABLE_COLUMNS.SHIPPING_PARTNER
        }, {
            className: '_creator-name-column',
            title: t('creator'),
            dataIndex: TABLE_COLUMNS.CREATOR,
            key: TABLE_COLUMNS.CREATOR
        }, {
            className: '_route-column',
            title: t('route'),
            dataIndex: TABLE_COLUMNS.ROUTE,
            key: TABLE_COLUMNS.ROUTE
        }, {
            className: '_service-column',
            title: t('service'),
            dataIndex: TABLE_COLUMNS.SERVICE,
            key: TABLE_COLUMNS.SERVICE
        }, {
            className: '_applied-at-column',
            title: t('application_time'),
            dataIndex: TABLE_COLUMNS.APPLIED_AT,
            key: TABLE_COLUMNS.APPLIED_AT
        }, {
            className: '_unit-price-column',
            title: `${t('fee')} (VNĐ)`,
            dataIndex: TABLE_COLUMNS.UNIT_PRICE,
            key: TABLE_COLUMNS.UNIT_PRICE
        }, {
            className: '_divided-conversion-column',
            title: t('shipping_fee:divided_conversion'),
            dataIndex: TABLE_COLUMNS.DIVIDED_CONVERSION,
            key: TABLE_COLUMNS.DIVIDED_CONVERSION
        }, {
            className: '_weight-by-column',
            title: t('weight_by'),
            dataIndex: TABLE_COLUMNS.WEIGHT_BY,
            key: TABLE_COLUMNS.WEIGHT_BY
        }, {
            className: '_actions-column',
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            width: 48,
            render: (text, row, index) => (
                <div>
                    <Button
                        className="a-text--link p-0 _edit-btn"
                        type="link"
                        onClick={this.props.onEditButtonClick.bind(undefined, index)}
                    >
                        {t('common:edit')}
                    </Button>
                    <span className="a-text--link mx-2">|</span>
                    <Button
                        className="a-text--link p-0 _log-btn"
                        type="link"
                        onClick={this.props.onViewLogButtonClick.bind(undefined, index)}
                    >
                        {t('common:log')}
                    </Button>
                </div>
            )
        }],
        current: Number(this.props.pagination.page) || 1,
        pageSize: Number(this.props.pagination.per_page) || 20
    };

    handleChangePage = pagination => {
        const { onChangePage } = this.props;
        const { current } = pagination;

        this.setState({
            current
        });

        onChangePage(pagination);
    };

    componentDidUpdate(prevProps) {
        const { pagination } = this.props;

        if (!isEqual(pagination, prevProps.pagination)) {
            this.setState({
                current: Number(pagination.page) || 1,
                pageSize: Number(pagination.per_page) || 20
            });
        }
    }

    render() {
        const { columns, current, pageSize } = this.state;
        const { loading, shippingFees, pagination } = this.props;

        return (
            <Table
                className="a-table--antd a-table--antd-scroll-x a-text--nowrap _a-table-shipping-fee"
                columns={columns}
                dataSource={shippingFees}
                loading={loading}
                pagination={{
                    current,
                    pageSize,
                    total: pagination.total
                }}
                rowKey={(record => get(record, 'id'))}
                scroll={{
                    x: 'fit-content'
                }}
                onChange={this.handleChangePage}
            />
        );
    }
}

export default ShippingFeeTable;