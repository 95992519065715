import {api} from '../../../system/api';

export default {

	/**
	 * Tạo kiểm kê
	 * @param code
	 * @returns {AxiosPromise<any> | IDBRequest<IDBValidKey> | Promise<void>}
	 */
	createInventory: payload => api.post(`/inventories`, payload)
};
