import {combineReducers} from "redux";
import * as ACTION from '../constants';

let loading = (state = false, action) => {
	let {type} = action;
	switch (type) {
		case  ACTION.CREATE_WAREHOUSE.REQUEST:
			return true;
		case ACTION.CREATE_WAREHOUSE.FAILED:
		case ACTION.CREATE_WAREHOUSE.SUCCESS:
			return false;
		default:
			return state

	}
};
export default combineReducers({loading})
