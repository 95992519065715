import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import FormServices from './components/FormServices';

const mapStateToProps = (state, props) => ({
    ...props,
    consignServiceGroups: selectors.getActiveConsignServiceGroups(state),
    serviceGroups: selectors.getActiveServiceGroups(state),
    loading: selectors.getState(state, 'loadingConsignServiceGroups') || selectors.getState(state, 'loadingServiceGroups'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchServiceGroups: () => dispatch(actions.fetchServiceGroups()),
});

class FormServicesContainer extends React.Component {
    componentDidMount() {
        if (!this.props.serviceGroups.length) {
            this.props.fetchServiceGroups();
        }
    }

    render() {
        const { consignServiceGroups, disabled, loading, serviceGroups, singleType, t, types, values, onChange } = this.props;

        return (
            <FormServices
                loading={loading}
                consignServiceGroups={consignServiceGroups}
                serviceGroups={serviceGroups}
                singleType={singleType}
                t={t}
                types={types}
                values={values}
                onChange={onChange}
                disabled={disabled}
            />
        );
    }
}

const Container = withTranslation(undefined, {
    withRef: true
})(connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(FormServicesContainer));

Container.defaultProps = {
    values: [],
    onChange: () => {
    },
    disabled: false
};

Container.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default Container;
