import { Icon, Modal, notification } from 'antd'
import { get } from 'lodash'
import React, { useState } from 'react'
import { t, trans } from '../../../../system/i18n'
import apiService from '../apiService'
import TableChildPackage from './TableChildPackage'
import { getVar } from '../../../../system/support/helpers'
import { linkHTC } from '../../../Package/helper'

const BagItem = ({ item, bag , getBagPackingDetail}) => {
    const [collapse, setCollapse] = useState(true)

    const onCollapse = () => {
        setCollapse(prev => !prev)
    }

    const onDelete = (idLastMileBag, lastmile_code) => {
        const idBag = get(bag, 'id')
        Modal.confirm({
            title: trans('bag:lastmile.message.delete_bag_from_package', { lastmile_code: <b>{lastmile_code}</b> }),
            content: '',
            onOk() {
                return apiService
                    .deleteBagLastmile(idBag, idLastMileBag)
                    .then(result => {
                        getBagPackingDetail(idBag)
                        notification.success({ message: trans('bag:lastmile.message.delete_bag_success', { lastmile_code: <b>{lastmile_code}</b> }) })
                    })
                    .catch(err => {
                        notification.error({ message: trans('bag:lastmile.message.delete_bag_fail', { lastmile_code: <b>{lastmile_code}</b> }) })
                    })
                    .finally(() => {
                    })
            },
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
        })
    }

    return (
        <>
            <tr className="data">
                <td className="_code">
                    <Icon
                        type={collapse ? 'up' : 'down'}
                        className="a-text--cursor-pointer"
                        onClick={onCollapse}
                    />

                    <span> {get(item, 'bag_lastmile.code', '---')}</span>
                </td>
                <td className="_code">{get(item, 'bag_lastmile.time_scan', '---')}</td>
                <td className="_freight_code">{get(item, 'bag_lastmile.freight_bill_code', '---')}</td>
                <td className="_weight_real">{get(item, 'bag_lastmile.weight_real', '---')} (kg)</td>
                <td>
                    <Icon
                        className="_icon_close"
                        type={'close'}
                        onClick={() => onDelete(get(item, 'bag_lastmile.id'), get(item, 'bag_lastmile.code', '---'))}
                    />
                </td>
            </tr>
            {collapse && (
                <tr>
                    <td
                        colSpan={9}
                        className="p-0">
                        <TableChildPackage packageData={get(item, 'packing_package', [])} bag={bag}/>
                    </td>
                </tr>
            )}
        </>
    )
}

export default BagItem
