import React from 'react';
import {Icon} from "antd";
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import * as lodash from "lodash";

class InlineEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editing: props.editing
        }
    }
    render() {
        return (
            <div className={'a-inline-edit ' + this.props.className}>
                <label className="a-inline-edit--label">
                    {this.props.label}
                    {this.renderActions()}
                </label>
                {this.props.canEdit && this.props.editing ? (
                    <div className="a-inline-edit--input">{this.props.form()}</div>
                ) : (
                    <div className="a-inline-edit--value">{this.props.value}</div>
                )}
            </div>
        );
    }

    renderActions() {
        if(this.props.status){
            return null;
        }
        if (this.props.param === 'id_customer' && (this.props.input.bagType !== 'SINGLE' || this.props.input.onEditType)) {
            return null;
        }
        if (this.props.param === 'type' && lodash.get(this.props.bag, 'packing_complete', false)) {
            return null;
        }

        if (!this.props.canEdit) {
            return null;
        }

        if (this.props.loading) {
            return <Icon type="loading-3-quarters" className="icon-loading ml-3"/>
        }

        if (this.props.editing) {
            return <React.Fragment>
                <Icon type="close" className="icon-inline" onClick={this.props.onCancel}/>
                <Icon type="check" className="icon-inline" onClick={this.props.onSubmit}/>
            </React.Fragment>
        }

        return <Icon type="edit" className="icon-inline" onClick={this.props.onEdit}/>
    }
}

InlineEdit.defaultProps = {
    label: null,
    value: null,
    form: () => {},
    editing: false,
    loading: false,
    canEdit: false,
    status: false,
    className: '',
    onEdit: () => {},
    onSubmit: () => {},
    onCancel: () => {
    },
    bag: {},
};

InlineEdit.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    form: PropTypes.func,
    editing: PropTypes.bool,
    loading: PropTypes.bool,
    canEdit: PropTypes.bool,
    className: PropTypes.string,
    onEdit: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    bag: PropTypes.object,
};

export default withTranslation()(InlineEdit);
