import { combineReducers } from 'redux'
import first from 'lodash/first'
import get from 'lodash/get'

import * as ACTION from './../constants'
import * as ORDER_ACTION from './../../Order/constants'

const suggestOrder = (state = null, action) => {
    switch (action.type) {

        case ORDER_ACTION.GET_SUGGEST_ORDERS.SUCCESS:
            return first(get(action, 'payload.orders')) || null
        case ACTION.SET_SUGGEST_ORDER_ID:
            return {
                order: {
                    id: action.payload,
                },
            }
        case ACTION.CLEAR_SUGGEST_ORDER:
            return null

        default:
            return state
    }
}

const loadingSuggestOrder = (state = false, action) => {
    switch (action.type) {
        case ORDER_ACTION.GET_SUGGEST_ORDERS.REQUEST:
            return true
        case ORDER_ACTION.GET_SUGGEST_ORDERS.SUCCESS:
            return false
        case ORDER_ACTION.GET_SUGGEST_ORDERS.FAILED:
            return false
        default:
            return state
    }
}

export default combineReducers({
    suggestOrder,
    loadingSuggestOrder,
})
