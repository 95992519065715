import React from 'react';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';

import Log from './../../../../Common/Log/LogContainer';

const ShippingFeeLogsModal = ({ t, shippingFeeId, visible, onCancel }) => (
    <Modal
        bodyStyle={{
            height: '60vh',
            overflow: 'auto'
        }}
        centered
        title={t('common:label.log')}
        visible={visible}
        footer={false}
        onCancel={onCancel}
    >
        <Log object="shipping-fees" objectId={shippingFeeId} delayTime={0} />
    </Modal>
);

export default withTranslation()(ShippingFeeLogsModal);
