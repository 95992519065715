import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import FormBagSizeContainer from '../FormBagSize/FormBagSizeContainer';

const mapDispatchToProps = (dispatch) => ({
    createBagSize: (data) => dispatch(actions.createBagSize(data)),
});

class CreateBagSizeContainer extends React.Component {
    
    render() {
        return <FormBagSizeContainer {...this.props}/>
    }
}

export default connect(
    null,
    mapDispatchToProps
)(CreateBagSizeContainer);
