import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import lodash from "lodash";
import React from "react";

export function* updateDimension(action) {
    yield processApiRequest(
        ACTION.UPDATE_DIMENSION,
        () => {
            let {param, value} = action.payload;
            let data = param === 'dimension'
                ? {length: value, width: value, height: value}
                : {[param]: value};

            return apiService.updateDimension(action.payload.packageCode, data);
        },
        action.payload
    );
}

export function* onUpdateDimensionSuccess() {
    yield notification.success({message: t('message.update_success')});
}

export function* onUpdateDimensionFailed(action) {
    let error = lodash.get(action, 'payload.data');
    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}
