import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Spin} from 'antd';
import Link from '../../../../system/routing/Link';

class ListSameDayBags extends React.PureComponent {
    render() {
        let {t, bags, bagId} = this.props;

        return (
            <div className="list-bag-today">
                <span className="title-block">{t('bag:detail.list_same_day_bags')}</span>
                <ul className="list-bag-today--list">
                    <Spin spinning={this.props.loading}>
                        {bags.map(bag => (
                            <li key={bag.id} className={'list-bag-today--item ' + (bag.id === bagId ? 'active' : '')}>
                                <Link className="list-bag-today--link" to="bags.detail"
                                      params={{id: bag.code}}>{bag.code}</Link>
                            </li>
                        ))}
                    </Spin>
                </ul>
            </div>
        );
    }
}

ListSameDayBags.defaultProps = {
    bags: [],
    loading: false,
    error: null,
};

ListSameDayBags.propTypes = {
    bags: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
};

export default withTranslation()(ListSameDayBags);
