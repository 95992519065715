import {connect} from 'react-redux';
import CancelDeliveryRequest from './components';
import * as actions from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    page: props.page,
    packageData: props.packageData,
    isModalProcessing: lodash.get(state, 'deliveryRequest.cancelDeliveryRequest.isProcessing', false),
    loading: lodash.get(state, 'deliveryRequest.cancelDeliveryRequest.loading', false),
    type: props.type
});

const mapDispatchToProps = (dispatch, props) => ({
    beginCancelDeliveryRequest: () => {
        dispatch(actions.beginCancelDeliveryRequest());
    },
    endCancelDeliveryRequest: () => {
        dispatch(actions.endCancelDeliveryRequest());
    },
    onCancelDeliveryRequest: data => {
        dispatch(actions.cancelDeliveryRequest({
            ...data,
            routeName: lodash.get(props.route, 'name')
        }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelDeliveryRequest);
