import React from 'react';
import {Button, Col, DatePicker, Form, Icon, Input, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import clsx from 'clsx';
import moment from 'moment';
import ChooseWarehouse from '../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';
import * as services from "../services";
import {withTranslation} from "react-i18next";
import FormProcessing from "../../../Common/components/FormProcessing";
import PropTypes from 'prop-types';
import {DELIVERY_REQUEST_STATUS} from "../constants";
import SelectAgency from "../../../Setting/Agency/SelectAgency";
import {dateFormatter} from './../../../Common/services/format';

import {url} from './../../../../system/routing';

import Link from './../../../../system/routing/Link';
import SelectCustomer from "./SelectCustomer";
import lodash from 'lodash';

const Search = Input.Search;

class FormSearch extends FormProcessing {
    constructor() {
        super();
        this.defaultInput = {
            id_warehouse: undefined,
            code: '',
            code_package: '',
            code_order: '',
            customer: '',
            id_agency: null,
            'created_at[from]': '',
            'created_at[to]': '',
        };
        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    getFilter(filter) {
        let page = this.props.page;
        if (page === 'list_delivery_note_pack') {
            filter.packing_complete = 0;
        }
        return filter;
    }

    onSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        if (!this.props.loading.loadingDeliveryRequest && !this.props.loading.loadingDeliveryCustomerRequest) {
            let {input} = this.state;
            input.page = 1;
            input.type = this.props.object;
            input.status = this.props.input.status;
            this.props.onSearch(input);
            localStorage.setItem("selectedDeliveryRequestWarehouse_" + this.props.user_id, input.id_warehouse);
        }
    }

    onReset = event => {
        event.preventDefault();
        if (!this.props.loading.loadingDeliveryRequest && !this.props.loading.loadingDeliveryCustomerRequest) {
            let filter = this.getFilter(this.defaultInput);
            filter.type = this.props.object;
            filter.status = this.props.input.status;
            filter.sort_by = 'created_at';
            filter.from = 'delivery_request';
            filter.sort_direction = this.props.input.status === DELIVERY_REQUEST_STATUS.STATUS_NEW ? "asc" : "desc";
            filter.id_warehouse = localStorage.getItem("selectedDeliveryRequestWarehouse_" + this.props.user_id);
            this.changeInput(filter);
            this.props.onSearch(filter);
        }
    };

    changeWarehouse({id_warehouse}) {
        this.changeInput({id_warehouse})
    }

    onChangeAgency({id_agency}) {
        this.changeInput({id_agency})
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onChange = (field, value) => {
        const {input} = this.state;

        if (input[field] === value) {
            this.changeInput({
                [field]: undefined
            });
        } else {
            this.changeInput({
                [field]: value
            });
        }
    };

    getQuickCreatedAtFilterUrl = date => {
        const {input} = this.state;

        const newQuery = {
            ...input,
            i: input.i ? Number(input.i) + 1 : 1,
            page: 1,
            'created_at[from]': date,
            'created_at[to]': undefined
        };

        return `/delivery-requests/?${url.stringify(newQuery)}`;
    };
    onChangeCustomer = (customer, isCustomerInfo = false) => {
        const {input} = this.state;
        if (isCustomerInfo) {
            const username = lodash.get(customer, "customer.username", undefined);
            const id_agency = lodash.get(customer, "agency.id", undefined);
            this.changeInput({customer: username, id_agency});
            this.props.onSearch({
                ...input,
                customer: username,
                id_agency
            });
        } else {
            this.changeInput({
                ...input,
                customer
            });
        }
    };

    render() {
        let {t, loading} = this.props;
        let input = services.converValue2Int({...this.state.input});

        return (
            <div className="a-content--search-header search-default" id="list-search">
                <Form className="a-form " onSubmit={this.onSubmit.bind(this)}>
                    <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_request:label.warehouse")}
                                required={true}
                                labelCol={{span: 24}}>
                                <ChooseWarehouse
                                    isPermission
                                    loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                                    emptyOption={false}
                                    placeholder={t("delivery_request:placeholder.select_warehouse")}
                                    className="a-select a-select--search-content _id-warehouse"
                                    value={input.id_warehouse}
                                    onChange={id_warehouse => this.changeWarehouse({id_warehouse})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_request:label.search_customer")}
                                labelCol={{span: 24}}>
                                <SelectCustomer
                                    value={input.customer || ''}
                                    onChange={this.onChangeCustomer}
                                    className="a-select a-select--search-content _customer"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_request:label.agency")}
                                labelCol={{span: 24}}>
                                <SelectAgency
                                    allowClear
                                    loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                                    emptyOption={false}
                                    placeholder={t("delivery_request:placeholder.select_agency")}
                                    className="a-select a-select--search-content _id-agency"
                                    value={input.id_agency}
                                    onChange={id_agency => this.onChangeAgency({id_agency})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("delivery_request:label.code_delivery_request")}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.code || ''}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                    className="a-input--search _code-delivery-request"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("delivery_request:label.code_search_package")}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.code_package || ''}
                                    onChange={this.onChangeInput.bind(this, 'code_package')}
                                    className="a-input--search _code-package"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("order_id_code")}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.code_order || ''}
                                    onChange={this.onChangeInput.bind(this, 'code_order')}
                                    className="a-input--search _code-search-order"
                                />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                        <Col lg={12} xl={10}>
                            <Form.Item className="a-form__item" label={t('delivery_request:label.createdTo')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action gjustify-space-between">
                        <div>
                            <Button
                                className={clsx('a-btn mr-4 _btn-current-day', {
                                    'a-btn--default': dateFormatter.date(moment()) !== dateFormatter.date(input['created_at[from]']),
                                    'a-btn--primary': dateFormatter.date(moment()) === dateFormatter.date(input['created_at[from]'])
                                })}
                                loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                            >
                                <Link href={this.getQuickCreatedAtFilterUrl(dateFormatter.shortStandard(moment()))}>
                                    {dateFormatter.date(moment())}
                                </Link>
                            </Button>

                            <Button
                                className={clsx('a-btn mr-4 _btn-yesterday', {
                                    'a-btn--default': dateFormatter.date(moment().subtract(1, 'days')) !== dateFormatter.date(input['created_at[from]']),
                                    'a-btn--primary': dateFormatter.date(moment().subtract(1, 'days')) === dateFormatter.date(input['created_at[from]'])
                                })}
                                loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                            >
                                <Link
                                    href={this.getQuickCreatedAtFilterUrl(dateFormatter.shortStandard(moment().subtract(1, 'days')))}>
                                    {dateFormatter.date(moment().subtract(1, 'days'))}
                                </Link>
                            </Button>

                            <Button
                                className={clsx('a-btn mr-4 _btn-two-days-ago', {
                                    'a-btn--default': dateFormatter.date(moment().subtract(2, 'days')) !== dateFormatter.date(input['created_at[from]']),
                                    'a-btn--primary': dateFormatter.date(moment().subtract(2, 'days')) === dateFormatter.date(input['created_at[from]'])
                                })}
                                loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                            >
                                <Link
                                    href={this.getQuickCreatedAtFilterUrl(dateFormatter.shortStandard(moment().subtract(2, 'days')))}>
                                    {dateFormatter.date(moment().subtract(2, 'days'))}
                                </Link>
                            </Button>
                        </div>
                        <div>
                            <a
                                className="link-reload _btn-reset"
                                href="#top"
                                onClick={this.onReset}
                            >
                                <Icon type="sync"/>{t('label.refresh_filter')}
                            </a>
                            <Button
                                className="a-btn a-btn--primary a-btn--search-list _btn-search"
                                htmlType="submit"
                                loading={loading.loadingDeliveryCustomerRequest || loading.loadingDeliveryRequest}
                                type="primary"
                            >
                                {t('btn.search')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.object,
    onSearch: PropTypes.func,
};

export default withTranslation()(FormSearch);
