import React from 'react'
import { connect } from 'react-redux'
import * as commonAction from '../../modules/Common/actions'
import AgencyFinanceList from '../Agencies/Finances/List'
import SearchableComponent from '../Common/components/SearchableComponent'
import apiService from './apiService'
import lodash from 'lodash'
import { notification } from 'antd'
import { t } from '../../system/i18n'
import { AGENCY_FINANCE_TRANSACTION_TYPE } from './constants'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
})

class AgencyFinanceContainer extends SearchableComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: {},
        }
    }
    getCurrentFilter = () => {
        const filter = {
            page: 1,
            ...this.getFilterFromLocation(this.props.location),
        }

        return filter
    }

    onChangeFilter = filter => {
        this.setState({ loading: true })
        apiService
            .getListFinanceTransaction({ ...filter, type: AGENCY_FINANCE_TRANSACTION_TYPE })
            .then(res => {
                this.setState({ data: lodash.get(res, 'data', {}) })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    render() {
        return (
            <AgencyFinanceList
                {...this.props}
                loading={this.state.loading}
                filter={this.getCurrentFilter()}
                onSearch={this.onSearch}
                data={this.state.data}
            />
        )
    }
}

AgencyFinanceContainer.defaultProps = {}

AgencyFinanceContainer.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyFinanceContainer)
