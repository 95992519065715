import {combineReducers} from 'redux';
import * as ACTION from './constants';

const listCountry = (state = [], action) => {
    const {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_COUNTRY.REQUEST:
        case ACTION.FETCH_LIST_COUNTRY.FAILED:
            return [];
        case ACTION.FETCH_LIST_COUNTRY.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
const listProvince = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_PROVINCE.REQUEST:
        case ACTION.FETCH_LIST_PROVINCE.FAILED:
            return [];
        case ACTION.FETCH_LIST_PROVINCE.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
const listDistrict = (state = [], action) => {
    const {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_DISTRICT.REQUEST:
        case ACTION.FETCH_LIST_DISTRICT.FAILED:
            return [];
        case ACTION.FETCH_LIST_DISTRICT.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
const listWard = (state = [], action) => {
    const {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_WARD.REQUEST:
        case ACTION.FETCH_LIST_WARD.FAILED:
            return [];
        case ACTION.FETCH_LIST_WARD.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
const currentCountry = (state = 0, action) => {
    const {type} = action;
    switch (type) {
        case ACTION.CHANGE_CURRENT_COUNTRY:
            return action.payload;
        default:
            return state;
    }
};
const loadingCountry = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_LIST_COUNTRY.REQUEST: {
            return true;
        }
        case ACTION.FETCH_LIST_COUNTRY.SUCCESS:
        case ACTION.FETCH_LIST_COUNTRY.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};
const loadingProvince = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_LIST_PROVINCE.REQUEST: {
            return true;
        }
        case ACTION.FETCH_LIST_PROVINCE.SUCCESS:
        case ACTION.FETCH_LIST_PROVINCE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};
const loadingDistrict = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_LIST_DISTRICT.REQUEST: {
            return true;
        }
        case ACTION.FETCH_LIST_DISTRICT.SUCCESS:
        case ACTION.FETCH_LIST_DISTRICT.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};
export default combineReducers({
    listCountry,
    listProvince,
    listDistrict,
    listWard,
    currentCountry,
    loadingCountry,
    loadingProvince,
    loadingDistrict
});
