import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {router} from '../../../system/routing';
import {t} from '../../../system/i18n';

export function* createShippingUser(action) {
    yield processApiRequest(ACTION.CREATE_SHIPPING_USER, () => apiService.createShippingUser(action.payload), action.payload);
}

export function* createShippingUserSuccess() {
    yield notification['success']({
        message: t('common:message.create_shipping_user_success')
    });
    yield router.redirect('/config/shipping-users');
}