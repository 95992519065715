import React from 'react';
import { Tabs } from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ListContentDeliveryNote from './ListContentDeliveryNote';
import settingService from './../settingService';
import { LIST_DELIVERY_OBJECT } from './../../constants';
import authService from './../../../Auth/authService';

const TabPane = Tabs.TabPane;

class ListDeliveryNote extends React.PureComponent {
    state = {
        isOpenListSetting: false
    }

    onOpenListSetting = isOpenListSetting => {
        this.setState({
            isOpenListSetting
        });
    };

    componentDidMount() {
        const setting = settingService.getPageSetting(authService.user('id'), LIST_DELIVERY_OBJECT.CUSTOMER);

        settingService.updateUserSetting(authService.user('id'), setting, LIST_DELIVERY_OBJECT.CUSTOMER);
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    render() {
        let {t, filter, deliveryNotes} = this.props;
        return (
            <Tabs
                animated={false}
                defaultActiveKey={filter.type}
                className="a-tabs a-tabs--container"
            >
                {lodash.map(LIST_DELIVERY_OBJECT, (tab, i) => (
                    <TabPane tab={t("delivery_note:tab." + tab)} key={tab}>
                        <ListContentDeliveryNote
                            key={i}
                            deliveryNotes={deliveryNotes}
                            object={tab}
                            pagination={this.props.pagination}
                            filter={this.props.filter}
                            loading={this.props.loading}
                            onSearch={this.props.onSearch}
                            setMenuActive={this.props.setMenuActive}
                            isOpenListSetting={this.state.isOpenListSetting}
                            onOpenListSetting={this.onOpenListSetting}
                        />
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}


ListDeliveryNote.defaultProps = {
    deliveryNotes: [],
    loading: false,
    filter: {},
};

ListDeliveryNote.propTypes = {
    deliveryNotes: PropTypes.array,
    filter: PropTypes.object,
    onReset: PropTypes.func,
    setMenuActive: PropTypes.func,
    onSearch: PropTypes.func,
    loading: PropTypes.bool,
};

export default withTranslation()(ListDeliveryNote);
