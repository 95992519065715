import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';

class SelectLanguage extends Component {
    state = {
        checkedLanguages: [],
        noCheckLanguages: [],
        aggregateLanguages: []
    };

    classifyLanguages = languages => {
        const checkedLanguages = languages.filter(language => !language.toLowerCase().includes('nocheck') && !language.toLowerCase().includes('aggregate'));
        const noCheckLanguages = languages.filter(language => language.toLowerCase().includes('nocheck'));
        const aggregateLanguages = languages.filter(language => language.toLowerCase().includes('aggregate'));

        this.setState({
            checkedLanguages,
            noCheckLanguages,
            aggregateLanguages
        });
    };

    componentDidUpdate(prevProps) {
        const { languages } = this.props;

        if (!isEqual(languages, prevProps.languages)) {
            this.classifyLanguages(languages);
        }
    }

    componentDidMount() {
        const { languages } = this.props;

        this.classifyLanguages(languages);
    }

    render() {
        const { checkedLanguages, noCheckLanguages, aggregateLanguages } = this.state;
        const { loading, t, onSelectLanguage } = this.props;

        return (
            <>
                {checkedLanguages && checkedLanguages.length && checkedLanguages.length > 0 ? (
                    <>
                        <div className="a-block-config__title--block-child amb-16">
                            <span className="title">{t('bag:label.checking')}</span>
                        </div>
                        <Form.Item
                            className="a-form__item a-form--group amt-16"
                            label={t('bag:label.language')}
                        >
                            <Row gutter={{lg: 24, xl: 32}}>
                                {checkedLanguages.map(language => (
                                    <Col key={language} xs={{span: 6}}>
                                        <Button
                                            className="_btn_select_language _manifest_check"
                                            loading={loading}
                                            onClick={onSelectLanguage.bind(undefined, language)}
                                        >
                                            {t(`language:locales.${language}`)}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Form.Item>
                    </>
                ) : null}
                {noCheckLanguages && noCheckLanguages.length && noCheckLanguages.length > 0 ? (
                    <>
                        <div className="a-block-config__title--block-child amb-16">
                            <span className="title">{t('bag:label.no_checking')}</span>
                        </div>
                        <Form.Item
                            className="a-form__item a-form--group amt-16"
                            label={t('bag:label.language')}
                        >
                            <Row gutter={{lg: 24, xl: 32}}>
                                {noCheckLanguages.map(language => (
                                    <Col key={language} xs={{span: 6}}>
                                        <Button
                                            className="_btn_select_language _manifest_nocheck"
                                            loading={loading}
                                            onClick={onSelectLanguage.bind(undefined, language)}
                                        >
                                            {t(`language:locales.${language.replace('_nocheck', '')}`)}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Form.Item>
                    </>
                ) : null}
                {aggregateLanguages && aggregateLanguages.length && aggregateLanguages.length > 0 ? (
                    <>
                        <div className="a-block-config__title--block-child amb-16">
                            <span className="title">{t('bag:label.aggregate')}</span>
                        </div>
                        <Form.Item
                            className="a-form__item a-form--group amt-16"
                            label={t('bag:label.language')}
                        >
                            <Row gutter={{lg: 24, xl: 32}}>
                                {aggregateLanguages.map(language => (
                                    <Col key={language} xs={{span: 6}}>
                                        <Button
                                            className="_btn_select_language _manifest_aggregate"
                                            loading={loading}
                                            onClick={onSelectLanguage.bind(undefined, language)}
                                        >
                                            {t(`language:locales.${language.replace('_aggregate', '')}`)}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Form.Item>
                    </>
                ) : null}
            </>
        );
    }
}

export default withTranslation()(SelectLanguage);
