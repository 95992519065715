export const CHECKING_PACKAGE = {
    SUCCESS: 'PACKAGE.CHECKING_PACKAGE.SUCCESS',
    FAILED: 'PACKAGE.CHECKING_PACKAGE.FAILED'
};

export const UPDATE_RECEIVE_QUANTITY = {
    SUCCESS: 'PACKAGE.CHECKING_PACKAGE.UPDATE_RECEIVE_QUANTITY.SUCCESS'
};

export const FINISH_CHECKING_PACKAGE = {
    REQUEST: 'PACKAGE.CHECKING_PACKAGE.FINISH_CHECKING_PACKAGE.REQUEST',
    SUCCESS: 'PACKAGE.CHECKING_PACKAGE.FINISH_CHECKING_PACKAGE.SUCCESS',
    FAILED: 'PACKAGE.CHECKING_PACKAGE.FINISH_CHECKING_PACKAGE.FAILED'
};
