/* eslint-disable */
import React, {Component} from 'react';
import lodash from 'lodash';
import {Table} from 'antd';
import {LIST_WAREHOUSE_COLUMN} from "../../constants"
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class List extends Component {
	getColumns() {
		let width = {
			action: 24,
			address: '25%'
		};

		return lodash.values(LIST_WAREHOUSE_COLUMN).map(column => {
			return {
				key: column,
				dataIndex: column,
				title: column !== LIST_WAREHOUSE_COLUMN.ACTION ? this.props.t('config:column.' + column) : '',
				width: width[column],
				fixed: column === LIST_WAREHOUSE_COLUMN.ACTION && 'right',
				render: (text, row) => {
					let active = row.active ? 'a-notification-dot a-notification-dot--table active' : 'a-notification-dot a-notification-dot--table unactive';
					let listClass = {
						code_warehouse: active,
						action: 'a-text--right'
					};
					return {
						children: text,
						props:
							{
								className: listClass[column] ? (listClass[column] + ' _row-' + column) : (' _row-' + column)
							}
						,
					}
						;
				}
			}

		})
	}

	render() {
		const {listWarehouse, loading} = this.props;
		return (
			<div className="a-content__body bg-white a-content__body--page-list  height-100">
				<Table className={"a-table--antd a-table--antd-scroll-x"}
				       columns={this.getColumns()}
				       dataSource={listWarehouse}
				       pagination={false}
				       loading={loading}
				       rowKey={(record) => {
					       return record.key
				       }}
				       scroll={{x: 'fit-content'}}
				/>
			</div>
		)
	}
}

List.defaultProps = {
	listWarehouse: [],
	loading: false
};

List.propTypes = {
	listWarehouse: PropTypes.array,
	loading: PropTypes.bool,
};
export default withTranslation()(List);
