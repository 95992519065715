import React from 'react';

const BrokenGlass = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0)">
        <path className="c" d="M12.667,5.386V5.364L12.037.58A.667.667,0,0,0,11.375,0H8.221a.43.43,0,0,0-.3.126L7.258.785a.166.166,0,0,0,0,.235l.626.627a.833.833,0,0,1,0,1.178l-1.1,1.1a.5.5,0,1,1-.707-.707l.862-.862a.166.166,0,0,0,0-.235l-.626-.627A.834.834,0,0,1,6.344.285.167.167,0,0,0,6.226,0h-1.6a.667.667,0,0,0-.661.579s-.631,4.74-.631,4.755A4.667,4.667,0,0,0,7.193,9.924a.169.169,0,0,1,.141.165V14.5a.167.167,0,0,1-.167.167H6A.667.667,0,1,0,6,16h4a.667.667,0,1,0,0-1.333H8.833a.167.167,0,0,1-.167-.167V10.089a.168.168,0,0,1,.141-.165,4.591,4.591,0,0,0,3.859-4.538Z" />
      </g>
    </g>
  </svg>
);

export default BrokenGlass;
