import React, { useState } from 'react';
import { Button, Col, Form, Icon, Row } from 'antd';
import { withTranslation } from 'react-i18next';

import SelectAgency from './../../../../../Setting/Agency/SelectAgency';
import { useDeepCompareEffect } from './../../../../../../hooks';

const SearchForm = props => {
    const [filter, setFilter] = useState({});

    useDeepCompareEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);

    const handleChange = (field, value) => {
        setFilter({
            ...filter,
            [field]: value
        });
    };

    const handleSearch = event => {
        event.preventDefault();

        props.onSearch(filter);
    };

    const handleRefreshFilter = event => {
        event.preventDefault();

        props.onRefresh();
    };

    return (
        <Form className="a-content--search-header search-default pt-0 px-0 mb-0 mr-0" onSubmit={handleSearch}>
            <Row gutter={{lg: 12, xl: 24}}>
                <Col lg={6} xl={8}>
                    <Form.Item
                        label={props.t('agency')}
                    >
                        <SelectAgency
                            allowClear
                            className="_search-id-agency"
                            dropdownClassName="_search-dropdown-id-agency"
                            isFieldDecorator
                            value={filter.id_agency ? Number(filter.id_agency) : filter.id_agency}
                            onChange={handleChange.bind(undefined, 'id_agency')}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <div className="search--action">
                <a href="/" className="_btn-refresh-filter link-reload" onClick={handleRefreshFilter}>
                    <Icon type="sync" />{props.t('label.refresh_filter')}
                </a>
                <Button
                    className="a-btn a-btn--primary a-btn--search-list _btn-search"
                    htmlType="submit"
                    icon="search"
                    type="primary"
                >
                    {props.t('btn.search')}
                </Button>
            </div>
        </Form>
    );
};

export default withTranslation()(SearchForm);
