import React from 'react';
import { notification } from 'antd';
import { get, isEqual, map, reduce, find, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DeliveryNoteDetail from './components';
import apiService from './../apiService';
import { setMenuActive } from './../../../Common/actions';
import { fetchLogs } from './../../../Common/Log/actions';
import { printDeliveryNoteTransportWarehouse } from './../../../Common/PrintStamp/actions';

import SearchableComponent from './../../../Common/components/SearchableComponent';
import { MAXIMUM_COUNT_WAIT_INTERVAL } from '../../../Package/constants';

const mapDispatchToProps = {
    fetchLogs,
    print: printDeliveryNoteTransportWarehouse,
    setMenuActive
};

class DetailContainer extends SearchableComponent {
    state = {
        loading: false,
        deliveryNote: {},
        cancelling: false,
        exporting: false,
        updating: false,
        bags: [],
        packages: [],
        intervalOld: 0,
        dataTask: {}
    };

    getDeliveryNoteDetail = async id => {
        this.setState({
            loading: true
        });

        try {
            const response = await apiService.detail(id);
            const taskId = get(response, "data.task._id");
            const taskStatus = get(response, "data.task.status", "")
            this.setState({
                deliveryNote: response.data,
                dataTask: get(response, "data.task")
            });
            if (taskId && taskStatus !== "FINISH") {
                this.handleInterval(taskId)
            }
            
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };

    componentWillUnmount() {
        const {intervalOld} = this.state;
        clearInterval(intervalOld);
    }

    handleInterval = taskId => {
        const {intervalOld} = this.state;
        let stt = 0
        const interval = setInterval(async () => {
            clearInterval(intervalOld)
            stt++
            try {
                const res = await apiService.handleProcessTaskId(taskId)
                this.setState({
                    dataTask: get(res, "data.task")
                })
                if (get(res, "data.task.status") === "FINISH") {
                    clearInterval(interval) 
                }
            } catch (error) {
                clearInterval(interval) 
            } 
            if (stt >= MAXIMUM_COUNT_WAIT_INTERVAL) {
                clearInterval(interval)
            } 
        }, 8000)
        
        this.setState({
            intervalOld: interval
        })
    }

    onChangeFilter = filter => {
        this.getDeliveryNoteDetail(filter.id);
    };

    handleCancel = async () => {
        const { deliveryNote } = this.state;
        const { t, fetchLogs } = this.props;
        const deliveryNoteId = get(deliveryNote, 'delivery_note_transport_warehouse.id');

        this.setState({
            cancelling: true
        });

        try {
            const response = await apiService.cancel(deliveryNoteId);

            this.setState({
                deliveryNote: response.data
            });

            notification.success({
                message: t('delivery_note:message.destroy_success')
            });

            fetchLogs('delivery-notes', deliveryNoteId);
        } catch (error) {
            notification.error({
                message: t('delivery_note:message.destroy_failed')
            });
        }

        this.setState({
            cancelling: false
        });
    };

    handleExport = async () => {
        const { deliveryNote } = this.state;
        const { t, fetchLogs } = this.props;
        const deliveryNoteId = get(deliveryNote, 'delivery_note_transport_warehouse.id');

        this.setState({
            exporting: true
        });

        try {
            const response = await apiService.export(deliveryNoteId);

            this.setState({
                deliveryNote: response.data
            });

            notification.success({
                message: t('delivery_note:message.export_success')
            });

            fetchLogs('delivery-note-transport-warehouses', deliveryNoteId);
        } catch (error) {
            notification.error({
                message: t('delivery_note:message.export_failed')
            });
        }

        this.setState({
            exporting: false
        });
    };

    handleUpdateDelivery = async (id, payload) => {
        const { t } = this.props;
        const field = Object.keys(payload)[0];

        const data = {
            [field]: payload[field]
        };

        this.setState({
            updating: true
        });

        try {
            const response = await apiService.update(id, data);

            this.setState({
                deliveryNote: response.data
            });
        } catch (error) {
            notification.error({
                message: t('delivery_note:message.update_delivery_note_failed')
            });
        }

        this.setState({
            updating: false
        });
    };

    handlePrint = () => {
        const { deliveryNote } = this.state;
        const { print } = this.props;

        print({
            deliveryNoteTransportWarehouse: deliveryNote
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const { deliveryNote } = this.state;

        if (!isEqual(get(deliveryNote, 'bags'), get(prevState.deliveryNote, 'bags'))) {
            const bags = get(deliveryNote, 'bags', []);

            this.setState({
                bags
            });
        }

        if (!isEqual(get(deliveryNote, 'packages'), get(prevState.deliveryNote, 'packages'))) {
            const packages = get(deliveryNote, 'packages', []);
            this.setState({
                packages
            });
        }

        if (!isEqual(get(deliveryNote, 'outside_bags'), get(prevState.deliveryNote, 'outside_bags'))) {
            const packages = get(deliveryNote, 'packages', []);
            const bagsData = get(deliveryNote, 'bags', []);
            const packageCodes = map(packages, 'package.code')
            const packageTrackingNos = map(packages, "package.tracking_no")
            const bags = reduce(get(deliveryNote, 'outside_bags'), (result, item) => {
                const code = get(item, "outside_bag.code")
                const bagInfo = find(bagsData, ['bag.code', code])
                if (!packageCodes.includes(code) &&  !packageTrackingNos.includes(code)) {
                    result.push({
                        ...item,
                        bag: isEmpty(bagInfo) ? item.outside_bag : get(bagInfo, "bag", {}),
                        
                    }) 
                } 
            return result
        }, []);
            
            this.setState({
                bags,
                packages
            });
        }
    }

    render() {
        const { cancelling, deliveryNote, exporting, loading, bags, updating, packages, dataTask} = this.state;

        return (
            <DeliveryNoteDetail
                {...this.props}
                dataTask={dataTask}
                bags={bags}
                packages={packages}
                cancelling={cancelling}
                deliveryNote={deliveryNote}
                exporting={exporting}
                loading={loading}
                updating={updating}
                onCancel={this.handleCancel}
                onExport={this.handleExport}
                onPrint={this.handlePrint}
                updateDelivery={this.handleUpdateDelivery}
            />
        );
    }
}

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(DetailContainer));
