import {combineReducers} from 'redux';
import * as ACTION from './constants';

const logs = (state = {}, action) => {
    const {type, payload, request} = action;

    switch (type) {
        case ACTION.FETCH_LOGS.SUCCESS:
            const {object} = request;

            return {
                ...state,
                [object]: payload.items || []
            };

        case ACTION.CLEAR_STATE:
            return {
                ...state,
                [payload]: []
            };

        default:
            return state;
    }
};

const logsError = (state = null, action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_LOGS.FAILED:
            return payload;

        case ACTION.FETCH_LOGS.REQUEST:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

const logsLoading = (state = false, action) => {
    const {type} = action;

    switch (type) {
        case ACTION.FETCH_LOGS.REQUEST:
            return true;

        case ACTION.FETCH_LOGS.SUCCESS:
        case ACTION.FETCH_LOGS.FAILED:
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    logs,
    logsError,
    logsLoading,
});
