import React from 'react';
import ListDeliveryNote from './ListDeliveryNoteExport';
import InfoCustomerDeliveryNote from './InfoCustomerDeliveryNote';
import InfoCustomerDeliveryNoteLoading from './InfoCustomerDeliveryNoteLoading';
import {Row, Spin} from "antd";
import Log from "../../../Common/Log/LogContainer";
import PropTypes from 'prop-types';
import pageService from '../../../Common/Page/pageService';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {DELIVERY_OBJECT} from '../../constants';
import {PopupListPackageByBag} from "./PopupListPackageByBag";
import DetailDeliveryRequest from "../../../DeliveryRequest/DetailDeliveryRequest/DetailDeliveryRequestContainer";

class DeliveryNoteEmployee extends React.Component {
    state = {
        isShowPopupListPackageByBag: false,
        bag: {},
        iconCollapse: true,
    };

    componentDidMount() {
        this.updatePageTitle();
        this.props.setMenuActive('to_deliver');
    }

    componentDidUpdate(prevProps) {
        const { deliveryNote, loading, fetchDetailCustomer } = this.props;

        if (
            lodash.get(deliveryNote, 'delivery_note.id') !== lodash.get(prevProps.deliveryNote, 'delivery_note.id')
            || loading !== prevProps.loading
        ) {
            this.updatePageTitle();

            if (lodash.get(deliveryNote, 'customer.id')) {
                fetchDetailCustomer(deliveryNote.customer.id);
            }
        }
    }

    updatePageTitle() {
        let {t, deliveryNote} = this.props;
        pageService.setTitle(t("menu.detail_delivery_note") + " - " + (deliveryNote.delivery_note.code ? deliveryNote.delivery_note.code : ""));
    }

    /**
     * Hiển thị thông tin chi tiết phiếu theo đối tượng
     * @returns {*}
     */
    renderInfo() {
        let {deliveryNote, loading} = this.props;
        switch (deliveryNote.delivery_note.type) {
            case DELIVERY_OBJECT.CUSTOMER: {
                return loading ? <InfoCustomerDeliveryNoteLoading/> :
                    <InfoCustomerDeliveryNote
                        {...this.props}
                        delivery_note={deliveryNote.delivery_note}
                        permissions={deliveryNote.permissions}
                    />
            }
            default:
                return null;
        }
    }

    togglePopupListPackageByBag = (bag) => {
        this.setState({
            bag: bag,
            isShowPopupListPackageByBag: !this.state.isShowPopupListPackageByBag
        });
    };

    render() {
        let {deliveryNote, loading, t, customer, route} = this.props;
        const {bag, isShowPopupListPackageByBag} = this.state;
        const bags = deliveryNote.delivery_note_bags || [];
        let packageListByBag = [];
        for (let item of bags) {
            if (item.bag.id === bag.id) {
                packageListByBag = [...item.packages];
                break;
            }
        }
        if (loading) {
            return (
                <Spin />
            );
        }

        if (!deliveryNote.permissions) {
            return null;
        }
        let check_per = !!deliveryNote.permissions.can_update ||
            !!deliveryNote.permissions.can_export ||
            !!deliveryNote.permissions.can_cancel ||
            (deliveryNote.delivery_note.status !== "EXPORTED" &&
                deliveryNote.delivery_note.status !== "CANCELED");
                
        return (
            <Row type={"flex"}>
                <div
                    className={
                        check_per ? "a-content--scroll-content pt-0 gflex gflex-direction-column" : "a-content--scroll-content pt-0 gflex gflex-direction-column not-navigation-fixed"}>

                    {this.renderInfo()}

                    <ListDeliveryNote
                        {...this.props}
                        bags={bags}
                        deliveryNote={deliveryNote}
                        loading={loading}
                        printIssue={this.props.printIssue}
                        togglePopupListPackageByBag={this.togglePopupListPackageByBag}
                        customer={customer}
                    />
                </div>
                <div
                    className={
                        check_per ? "aml-24 alog " : "aml-24 alog not-navigation-fixed"}>
                    <div className="title">
                        <span>{t("delivery_note:label.log_title")}</span>
                    </div>
                    <Log object="delivery-notes" objectId={deliveryNote.delivery_note.id} delayTime={0}/>
                </div>
                <PopupListPackageByBag
                    {...this.props}
                    bag={bag}
                    packages={packageListByBag}
                    visible={isShowPopupListPackageByBag}
                    onCancel={this.togglePopupListPackageByBag}
                />
                <DetailDeliveryRequest
                    route={route}
                    onSuccessDeliveringDeliveryRequest={() => {}}
                />
            </Row>
        )
    }
}

DeliveryNoteEmployee.defaultProps = {
    loading: false,
    deliveryNote: {delivery_note: {}, delivery_note_packages: []},
    customers: [],
    users: [],
    warehouses: [],
};

DeliveryNoteEmployee.propTypes = {
    loading: PropTypes.bool,
    deliveryNote: PropTypes.object,
    customers: PropTypes.array,
    users: PropTypes.array,
    warehouses: PropTypes.array,
};

export default withTranslation()(DeliveryNoteEmployee);
