export const BARCODE_TYPE = {
    PACKAGE: 'PACKAGE',
    BAG: 'BAG',
    FREIGHT_BILL: 'FREIGHT_BILL',
    ENTRY_CODE_PACKAGE: 'ENTRY_CODE_PACKAGE'
};

export const SCAN_ACTION = {
    IN: 'IN',
    OUT: 'OUT',
    RECEIVED: 'RECEIVED',
    CHECKING: 'CHECKING',
    RECEIVED_BAG: 'RECEIVED_BAG'
};

export const SCAN_TYPE = {
    BARCODE_SCAN: 'SCAN_PACKAGE',
    SCAN_CREATE_PACKAGE: 'CREATE_PACKAGE',
    SCAN_PACKAGE_BAG: 'SCAN_PACKAGE_BAG',
    SCAN_PACKAGE_DELIVERY_NOTE: 'SCAN_PACKAGE_DELIVERY_NOTE',
    SCAN_BAG_DELIVERY_NOTE: 'SCAN_BAG_DELIVERY_NOTE',
    SCAN_PACKAGE_INVENTORY: 'SCAN_PACKAGE_INVENTORY',
    SCAN_INVENTORY: 'SCAN_INVENTORY',
    SCAN_RECEIVED: 'SCAN_RECEIVED',
    SCAN_PACKAGE_CHECKING: 'SCAN_PACKAGE_CHECKING',
    SCAN_RECEIVED_BAG: 'SCAN_RECEIVED_BAG'
};

