import { notification, Select } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../../system/i18n'

import api from '../../../Bag/apiService'

const { Option } = Select

const SuggestBag = React.forwardRef((props, ref) => {
    const [bags, setBags] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchTemplateBag()
    }, [])

    const fetchTemplateBag = () => {
        setLoading(true)
        api.getTemplateBags()
            .then(res => {
                setBags(res.data.bag_templates)
            })
            .catch(err => {
                notification.error({
                    message: t('message.server_error'),
                })
            })
            .finally(() => setLoading(false))
    }

    React.useImperativeHandle(ref, () => ({
        fetchTemplateBag: fetchTemplateBag
    }))

    return (
        <Select
            {...props}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={loading}
            disabled={loading || get(props, 'disabled', false)}
            placeholder={t('bag:placeholder.select_bag')}>
            {bags.map((bag, index) => (
                <Option
                    className="_bag__item"
                    key={index}
                    value={bag.bag_template.id}>
                    {bag.bag_template.name}
                </Option>
            ))}
        </Select>
    )
})

export default SuggestBag
