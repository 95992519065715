import React from 'react';
import {connect} from 'react-redux';
import lodash, { keyBy, merge, values, unionBy, orderBy } from 'lodash';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import CreatePackage from './components/CreatePackage';
import * as commonAction from './../../Common/actions';
import {inputFocusHandler} from './services';
import {beginCreateAlonePackage} from "../FormScanBarcode/actions";
import * as linkOrderActions from "../LinkOrderPackage/actions"

import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups';
import { fetchConsignServiceGroups } from '../../Service/actions';

const mapStateToProps = (state, ownProps) => {
    const {loadingPackages, barcode, paginationPackages} = selectors.getState(state);
    const newPackages = selectors.getPackage(state);
    const oldPackages = lodash.get(ownProps, "packages", [])
    const packages = oldPackages.concat(newPackages)
    return {
        packages,
        loadingPackages,
        barcode,
        activePackageId: packages.length ? lodash.first(packages).id : null,
        canCreatePackageSameBarcode: selectors.canCreatePackageSameBarcode(state),
        canCreatePackageSameCode: selectors.canCreatePackageSameBarcode(state),
        paginationPackages
    };
};

const mapDispatchToProps = (dispatch) => ({
    beginCreateAlonePackage: (idWarehouse) => dispatch(beginCreateAlonePackage(idWarehouse)),
    fetchBarcodePackages: params => dispatch(actions.fetchBarcodePackages(params)),
    fetchConsignServiceGroups: params => dispatch(fetchConsignServiceGroups(params)),
    resetBarcode: () => dispatch(actions.resetBarcode()),
    clearState: () => dispatch(actions.clearState()),
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    onLink: (data) => dispatch(linkOrderActions.linkOrderPackage(data.packageCode, data.orderCode, {
        packageId: data.packageId,
        current_page: data.current_page
    }))
});

class CreatePackageContainer extends SearchableComponent {
    state = {
        packages: [],
        activePackageId: null
    }
    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.packages) !== JSON.stringify(prevProps.packages)
        ) {
            // const packages = uniqBy([...this.state.packages, ...this.props.packages], 'id')
            const newPackages = [...this.props.packages]
            const currentPackages = unionBy([...this.props.packages], [...this.state.packages] , 'id');
            const obj1 = keyBy(currentPackages, 'id');
            const mergedObj = merge(obj1, keyBy(newPackages, 'id'));
            const sortData = orderBy(currentPackages, ['id'], ['desc'])
            const packages = currentPackages.length > 0 ?  sortData : newPackages
            const activePackageId = packages.length ? lodash.first(packages).id : null
            this.setState({packages,  activePackageId});
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onChangeFilter(filter) {
        // Case vào page tạo kiện nhưng chưa quét mã
        if (!filter.barcode) {
            inputFocusHandler.onInit();
            if (lodash.isNil(filter.is_alone)) {
                this.props.resetBarcode();
            } else {
                if (this.props.packages.length === 0) {
                    this.props.beginCreateAlonePackage(filter.id_warehouse);
                }
            }
            return;
        }

        // Case sau khi quét mã và reload hoặc back về page trước
        if (filter.barcode !== this.props.barcode) {
            this.props.fetchBarcodePackages(filter);
        }
    }

    getInput() {
        return this.getFilterFromLocation(this.props.location);
    }

    onScan(input) {
        this.setState({packages: []})
        this.pushFilter(input);
    }


    getCurrentFilter() {
        const { location } = this.props

        const filter = {
            ...this.getFilterFromLocation(location),
        }

        return filter
    }

    fetchBarcodePackagesAgain = (params) => {
        this.setState({packages: []})
        this.props.fetchBarcodePackages(params)
    }

    render() {
        return (
            <CreatePackage
                {...this.props}
                {...this.state}
                input={this.getInput()}
                onScan={this.onScan.bind(this)}
                filter={this.getCurrentFilter()}
                fetchBarcodePackagesAgain={this.fetchBarcodePackagesAgain}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withConsignServiceGroups(CreatePackageContainer));
