import React, { memo } from 'react';
import { forEach, get, isEqual, isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

const PromptLeavePage = ({ when, t }) => (
    <Prompt
        when={when}
        message={() => t('delivery_note:create.confirm_leave_page')}
    />
);

/**
 * Kiểm tra có confirm khi chuyển trang hay không
 * @returns {boolean}
 */
export const checkLeavePage = function (deliveryNote, params, submit, stateData) {
    if (submit) {
        return false
    };

    const barcodes = get(deliveryNote, 'barcodes', []);
    let confirm = false;

    forEach(params, key => {
        const value = (key === 'packages') ? get(stateData, key, []) : get(stateData, key, '');

        if (isNil(deliveryNote)) {
            if ((key === 'packages' && value.length) || (key !== 'packages' && value)) {
                confirm = true;
            }
        } else {
            if (
                (key === 'packages' && !isEqual(barcodes.sort(), value.sort())) ||
                (key !== 'packages' && (get(deliveryNote, key, '') !== value))
            ) {
                confirm = true;
            }
        }
    });

    return confirm;
};

export default memo(withTranslation()(PromptLeavePage));
