import React from 'react';

import { DELIVERY_OBJECT } from './../constants';
import deliveryService from './../deliveryService';

import Page403 from './../../Common/components/403';

export default (payload, next) => {
    const canCreate = deliveryService.canCrete(DELIVERY_OBJECT.CUSTOMER);

    if (!canCreate) {
        return payload.response.render(<Page403/>);
    }

    next(payload);
}