import React, { PureComponent } from 'react';
import { Icon, Input, Spin } from 'antd';
import get from 'lodash/get';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ScanBarcode extends PureComponent {
    state = {
        code: undefined,
    };

    onSubmit = event => {
        const { code } = this.state;
        const { scanBarcode } = this.props;

        event.preventDefault();

        if (code) {
            scanBarcode(code, 'ADD_DELIVERY_NOTE');
            this.setCode();
        }
    };

    setCode = code => {
        this.setState({
            code: trim(code)
        });
    };

    componentDidMount() {
        const { canScan, params, scanBarcode } = this.props;

        if (canScan && get(params, 'package')) {
            scanBarcode(params.package);
        }
    }

    componentDidUpdate(prevProps) {
        const { canScan, params, scanBarcode } = this.props;

        if (canScan && get(params, 'package') && (canScan !== prevProps.canScan || params.package !== get(prevProps.params, 'package'))) {
            scanBarcode(params.package);
        }
    }

    render() {
        const { code } = this.state;
        const { canScan, scanning } = this.props;

        return (
            <form className="a-form--loading loading-input" onSubmit={this.onSubmit}>
                <Input
                    autoFocus
                    className="a-input a-input--scan-code"
                    disabled={!canScan || scanning}
                    suffix={scanning ? (
                        <Spin
                            indicator={(
                                <Icon type="loading" />
                            )}
                        />
                    ) : null}
                    value={code}
                    onChange={event => this.setCode(event.target.value)}
                />
            </form>
        );
    }
}

ScanBarcode.defaultProps = {
    canScan: true,
    scanBarcode: () => {},
};

ScanBarcode.propTypes = {
    canScan: PropTypes.bool,
    scanBarcode: PropTypes.func,
};

export default withTranslation()(ScanBarcode)
