import {combineReducers} from 'redux';
import * as ACTION from './constants';
import lodash from "lodash";

let newBag = (state = {}, action) => {
    switch (action.type) {
        case ACTION.CREATE_BAG.REQUEST:
        case ACTION.CREATE_BAG.FAILED:
        case ACTION.CLEAR_STATE:
            return {};
        case ACTION.CREATE_BAG.SUCCESS:
            return {...action.payload};
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.CREATE_BAG.REQUEST:
        case ACTION.GET_TEMPLATE_BAGS.REQUEST:
            return true;

        case ACTION.CREATE_BAG.SUCCESS:
        case ACTION.GET_TEMPLATE_BAGS.SUCCESS:
            return false;

        case ACTION.CREATE_BAG.FAILED:
        case ACTION.GET_TEMPLATE_BAGS.FAILED:
            return false;

        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

let errors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.CREATE_BAG.REQUEST:
        case ACTION.CREATE_BAG.SUCCESS:
        case ACTION.CLEAR_STATE:
            return {};
        case ACTION.CREATE_BAG.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};

const dataTemplateBags = (state = [], action) => {
    let {type} = action;

    switch (type) {
        case ACTION.GET_TEMPLATE_BAGS.REQUEST:
        case ACTION.GET_TEMPLATE_BAGS.FAILED:
        default:
            return state;
        case ACTION.GET_TEMPLATE_BAGS.SUCCESS:
            return {...action.payload};
    }
};

export default combineReducers({
    newBag,
    loading,
    errors,
    dataTemplateBags
});
