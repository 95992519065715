import {combineReducers} from 'redux';
import * as ACTION from './constants';
import lodash from 'lodash';

let version = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case ACTION.UPDATE_BAG_PARAM.SUCCESS:
            return {...state, [action.request.param]: (state[action.request.param] || 0) + 1};

        case ACTION.CLEAR_STATE:
            return lodash.omit(state, payload.param);

        default:
            return state;
    }
};

let loading = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case ACTION.UPDATE_BAG_PARAM.REQUEST:
            return {...state, [payload.param]: true};

        case ACTION.UPDATE_BAG_PARAM.SUCCESS:
        case ACTION.UPDATE_BAG_PARAM.FAILED:
            return lodash.omit(state, action.request.param);

        case ACTION.CLEAR_STATE:
            return lodash.omit(state, payload.param);

        default:
            return state;
    }
};

let error = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case ACTION.UPDATE_BAG_PARAM.REQUEST:
        case ACTION.RESET_ERROR:
        case ACTION.CLEAR_STATE:
            return lodash.omit(state, payload.param);

        case ACTION.UPDATE_BAG_PARAM.FAILED:
            return {...state, [action.request.param]: payload};

        default:
            return state;
    }
};

export default combineReducers({
    version,
    loading,
    error,
});
