import React, { Component } from 'react'
import { Button, Checkbox, Empty, Input, Modal, Skeleton, Tag } from 'antd'
import lodash from 'lodash'
import * as MD5 from 'md5.js'
import api from '../apiService'
import { t } from '../../../system/i18n'
import PropTypes from 'prop-types'

const { Search } = Input

class AddUserToStaff extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            code: [],
            visible: false,
            id_users: [],
            listUserSearch: [],
            selected_agencies: [props.agencyId],
        }
    }

    componentWillUnmount() {
        this.setState({
            users: [],
        })
    }

    fetchUsers = () => {
        const { agencyId } = this.props

        return new Promise((resolve, reject) => {
            this.setState({ loading: true })
            api.getUsers({
                ignore_agency: agencyId,
            })
                .then(res => {
                    let users = lodash.get(res, 'data.users', []) || []
                    users = lodash.map(users, userData => lodash.get(userData, 'user', {}))
                    this.setState({ users })
                    resolve(users)
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => this.setState({ loading: false }))
        })
    }
    onAddStaffInWarehouse = () => {
        this.fetchUsers().then(res => {
            this.setState({ visible: true })
        })
    }
    onCancel = () => {
        this.setState({
            users: [],
            code: [],
            visible: false,
            id_users: [],
            listUserSearch: [],
            selected_agencies: [this.props.agencyId],
        })
    }
    onSearch = e => {
        let { users } = this.state
        let { listUserSearch } = this.state
        let value = e.target.value
        if (value.lenght > 0) {
            listUserSearch = []
        } else {
            listUserSearch = lodash.filter(users, user => {
                return user.username === value || lodash.includes(user.name.toLowerCase(), value.toLowerCase())
            })
        }
        this.setState({ listUserSearch, code: value })
    }

    onChecker(key, id) {
        const stateValue = lodash.get(this.state, key, [])
        if (lodash.includes(stateValue, id)) {
            lodash.remove(stateValue, user => user === id)
        } else {
            stateValue.push(id)
        }
        this.setState({ [key]: stateValue })
    }

    onSubmit = () => {
        const { id_users, selected_agencies } = this.state
        this.props.onAddStaff(id_users, selected_agencies).then(res => {
            this.onCancel()
        })
    }

    render() {
        const { users, code, visible, listUserSearch, selected_agencies } = this.state
        const { agencies, agencyId } = this.props
        const list = code.length > 0 ? listUserSearch : users
        const loading = this.props.loading || this.state.loading
        return (
            <React.Fragment>
                <Button
                    type="primary"
                    onClick={this.onAddStaffInWarehouse}
                    loading={loading}
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _a-btn-create-warehouse-staff">
                    {t('config:btn.create_warehouse_staff')}
                </Button>
                <Modal
                    visible={visible}
                    onCancel={this.onCancel}
                    className={'a-modal--add-employee'}
                    closable={false}
                    footer={false}>
                    <div className={'a-form--select-double-column'}>
                        <div className="a-form--select-double-column__body">
                            <div className="block-left">
                                <div className="title">
                                    <h2>{this.props.title ? this.props.title : t('config:label.list_user')}</h2>
                                </div>
                                <div className="body-left">
                                    <div className="apr-16">
                                        <Search
                                            placeholder={t('config:placeholder.search_staff_in_warehouse')}
                                            value={code}
                                            className={'_input-search'}
                                            onChange={this.onSearch}
                                        />
                                    </div>
                                    {list.length > 0 ? (
                                        <ul className="list-user">
                                            {loading ? (
                                                <li>
                                                    <Checkbox disabled />
                                                    <div className="info ">
                                                        <Skeleton
                                                            avatar
                                                            active
                                                            paragraph={{ rows: 1 }}
                                                            className="askeleton-avatar ml-2"
                                                        />
                                                    </div>
                                                </li>
                                            ) : (
                                                lodash.map(list, user => {
                                                    const md5Avatar = new MD5().update(user.email).digest('hex')
                                                    return (
                                                        <li
                                                            key={user.id}
                                                            className={'_list-user'}>
                                                            <Checkbox
                                                                checked={lodash.includes(this.state.id_users, user.id)}
                                                                className={'_checkbox-active-user'}
                                                                onChange={this.onChecker.bind(this, 'id_users', user.id)}
                                                            />
                                                            <img
                                                                src={`https://www.gravatar.com/avatar/${md5Avatar}`}
                                                                alt=""
                                                            />
                                                            <div className="info">
                                                                <p className="info-name _info-name">
                                                                    {(user.name || '--') + '(' + (user.username || '--') + ')'}{' '}
                                                                </p>
                                                                <span className="info-phone _info-phone">{lodash.get(user, 'phone', '--') || '--'}</span>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            )}
                                        </ul>
                                    ) : (
                                        <ul>
                                            <Empty
                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                description={<span>{t('common:label.no_data')}</span>}></Empty>
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="block-middle">
                                <div className="title">
                                    <h2>{t('config:label.selected_staff_warehouse')}</h2>
                                </div>
                                <div className="body-middle">
                                    {this.state.id_users.map(id => (
                                        <Tag
                                            key={id}
                                            closable
                                            onClose={this.onChecker.bind(this, id)}
                                            className={'a-tag--user _tag-user _tag-' + id}>
                                            {lodash.get(
                                                lodash.find(users, user => user.id === id),
                                                'name'
                                            ) ||
                                                lodash.get(
                                                    lodash.find(users, user => user.id === id),
                                                    'username'
                                                )}
                                        </Tag>
                                    ))}
                                </div>
                            </div>
                            <div className="block-right">
                                <div className="title">
                                    <h2>{t('config:label.agency_apply')}</h2>
                                </div>
                                <div className="body-right">
                                    <ul className="list-agency">
                                    <li
                                                        key={0}
                                                        className={'_list-user'}>
                                                        <Checkbox
                                                            disabled={0 === agencyId}
                                                            checked={lodash.includes(selected_agencies, 0)}
                                                            className={'_checkbox-active-user'}
                                                            onChange={this.onChecker.bind(this, 'selected_agencies', 0)}
                                                        />
                                                        <div className="info ml-2">
                                                            <p className="info-name _info-name">{t('agency:label.free_agency')} </p>
                                                        </div>
                                                    </li>
                                        {agencies.length > 0 &&
                                            lodash.map(agencies, agency => {
                                                const agencyInfo = lodash.get(agency, 'agency', {})
                                                return (
                                                    <li
                                                        key={agencyInfo.id}
                                                        className={'_list-user'}>
                                                        <Checkbox
                                                            disabled={agencyInfo.id === agencyId}
                                                            checked={lodash.includes(selected_agencies, agencyInfo.id)}
                                                            className={'_checkbox-active-user'}
                                                            onChange={this.onChecker.bind(this, 'selected_agencies', agencyInfo.id)}
                                                        />
                                                        <div className="info ml-2">
                                                            <p className="info-name _info-name">{agencyInfo.name || '--'} </p>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="a-form--select-double-column__footer bg-color-white">
                            <Button
                                type="primary"
                                loading={loading}
                                onClick={this.onCancel}
                                className="a-btn a-btn--default  a-text--regular mr-2 _btn-cancel">
                                {t('btn.cancel')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={this.onSubmit}
                                loading={loading}
                                disabled={this.state.id_users.length === 0 || loading}
                                className="a-btn a-btn--primary  a-text--regular apr-24 apl-24 _btn-save">
                                {t('btn.save')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

AddUserToStaff.propTypes = {
    onAddStaff: PropTypes.func,
    title: PropTypes.string,
    agencies: PropTypes.array,
}
AddUserToStaff.defaultProps = {
    onAddStaff: () => {},
    title: '',
    agencies: [],
}

export default AddUserToStaff
