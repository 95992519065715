import React, {Component} from 'react';
import {Button, Col, Form, Icon, Layout, Row, Switch} from "antd";
import lodash from "lodash";
import {DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF} from "../../constants";
import {router} from "../../../../system/routing";
import {withTranslation} from "react-i18next";
import FormWarehouseStaffContainer from "../../FormWarehouseStaff/FormWarehouseStaffContainer";

const Content = Layout.Content;

class EditWarehouseStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {...DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF, staff_id: 0}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        let warehouseStaffDetail = nextProps.warehouseStaffDetail;
        let staff_id = !lodash.isNil(warehouseStaffDetail) ? lodash.get(warehouseStaffDetail, 'user.id', 0) : 0;
        if (staff_id !== prevState.staff_id && staff_id !== 0) {
            nextState.id = warehouseStaffDetail.id;
            nextState.id_warehouses = lodash.map(warehouseStaffDetail.warehouses, 'id');
            nextState.package_status =lodash.get(warehouseStaffDetail, "package_status", []);
            nextState.id_user = warehouseStaffDetail.user.id;
            nextState.note = warehouseStaffDetail.user.note_warehouse_staff;
            nextState.active = warehouseStaffDetail.user.active_warehouse_staff;
            nextState.staff_id = staff_id;
        }
        return nextState;
    }

    onUpdateWarehouseStaff = () => {
        let data = this.state;
        data.active = this.state.active ? 1 : 0;
        data.note = this.state.note ? this.state.note : null;
        this.props.editWarehouseStaff(data);
    };

    onSubmit = (value) => {
        this.setState({
            ...this.state, ...value
        })
    };

    onChecker = (e) => {
        this.setState({active: e})
    };

    onBackConfigListWarehouse = () => {
        router.redirect('/config/warehouse-staff')
    };

    render() {
        let {t, loading, warehouseStaffDetail} = this.props;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <a href={"#top"} className={"a-text--gray _btn-back"} onClick={this.onBackConfigListWarehouse}><Icon
                        type="arrow-left" className={"mr-2 "}/>{t("warehouse_staff:label.back_warehouse_staff")}</a>
                </div>
                {Object.keys(warehouseStaffDetail).length > 0 ?
                    <div className="a-block-config amt-16">
                        <div className="a-block-config__title gflex gjustify-space-between">
                            <h3 className="title">{t("warehouse_staff:label.info")}</h3>
                            {!loading && <div>
                                <label
                                    className={"a-text--fz-12 a-text--gray mr-2"}>{t("warehouse_staff:label.status")}</label>
                                <Switch defaultChecked={this.state.active} size="small" onChange={this.onChecker}
                                        disabled={loading}
                                        loading={loading}
                                        className={"_switch-active"}
                                        checker={this.state.active}/>
                            </div>}
                        </div>
                        <div className="a-block-config__body">
                            <Form className="a-form ">
                                <React.Fragment>
                                    <FormWarehouseStaffContainer onSubmit={this.onSubmit}
                                                                 errors={this.props.errors}/>
                                </React.Fragment>
                                <Row gutter={48}>
                                    <Col lg={{span: 24}} xl={{span: 24}}
                                         className="a-package--action only-action">
                                        <a className="a-btn a-btn--back-package _btn-cancel"
                                           href={"#top"}
                                           onClick={this.onBackConfigListWarehouse}>{t("common:btn.cancel")} </a>
                                        <Button type="primary"
                                                loading={loading}
                                                className="a-btn a-btn--primary a-btn--save-package _btn-save"
                                                onClick={this.onUpdateWarehouseStaff}>{t("common:btn.save")} </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div> : null}
            </Content>
        );
    }
}

export default withTranslation()(EditWarehouseStaff);
