export const FETCH_USERS = {
    REQUEST: 'USER.FETCH_USERS.REQUEST',
    SUCCESS: 'USER.FETCH_USERS.SUCCESS',
    FAILED: 'USER.FETCH_USERS.FAILED',
};
export const FETCH_DETAIL_USERS = {
    REQUEST: 'USER.FETCH_DETAIL_USERS.REQUEST',
    SUCCESS: 'USER.FETCH_DETAIL_USERS.SUCCESS',
    FAILED: 'USER.FETCH_DETAIL_USERS.FAILED',
};

export const LIST_STAFF_COLUMN = {
    NAME: 'name_staff',
    PHONE: "phone_staff",
    CREATE_AT: 'create_at',
    CREATOR: 'creator',
    ACTION: 'action',
};

export  const  CLEAR_DETAIL_USER_STATE = "CLEAR_DETAIL_USER_STATE";
export const CLEAR_STATE = "USER_CLEAR_STATE";
