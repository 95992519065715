import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import * as actions from '../actions';
import * as selectors from '../selectors';
import FormBagSizeContainer from '../FormBagSize/FormBagSizeContainer';

const mapStateToProps = (state) => {
    const bagSizeDetail = selectors.getDetailBagSize(state);
    return {
        bagSizeDetail: _.get(bagSizeDetail, 'bag_size', {}),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchDetailBagSize: id => dispatch(actions.fetchDetailBagSize(id)),
    editBagSize: (id, data) => dispatch(actions.updateBagSize(id, data)),
    clearStateDetailBagSize: () => dispatch(actions.clearStateDetailBagSize())
});

class EditBagSizeContainer extends React.Component {

    componentDidMount() {
        this.props.fetchDetailBagSize(this.getId());
    }

    componentWillUnmount() {
        this.props.clearStateDetailBagSize();
    }

    getId = () => {
        return this.props.match.params.id;
    };

    render() {
        return <FormBagSizeContainer {...this.props}/>
    }
}

EditBagSizeContainer.defaultProps = {
    bagSizeDetail: {}
};

EditBagSizeContainer.propTypes = {
    bagSizeDetail: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditBagSizeContainer);
