import React from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Col, DatePicker, Form, Icon, Input, Row, Select} from 'antd';
import FormProcessing from '../../Common/components/FormProcessing';
import moment from 'moment';
import * as services from '../ListPackage/services';
import {DELETE_REASONS} from "../DeletePackage/constants"
import lodash from "lodash"
import authService from '../../Auth/authService';
import InputNumber from '../../Common/components/InputNumber';
import { ELEMENT } from '../CreatePackageViaScan/services/inputFocus/constants';
import permissions from '../../Auth/permissions';

const { Search } = Input;
const { Option } = Select;
const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
const sixMonthsAgo = moment(currentDate).subtract(2, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
class FormSearch extends FormProcessing {
    constructor() {
        super();

        this.defaultInput = {
            id_warehouse_input: '',
            id_warehouse_current: '',
            id_warehouse_destination: '',
            'created_at[from]': sixMonthsAgo,
            'created_at[to]': undefined,
            'deleted_at[from]': undefined,
            'deleted_at[to]': undefined,
            'order_linked_at[from]': undefined,
            'order_linked_at[to]': undefined,
            'weight_net[from]': undefined,
            'weight_net[to]': undefined,
            code: '',
            username: '',
            delete_reason: [],
            deleted_username: ''
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();
        if (!this.props.loading) {
            this.props.onSearch({...this.state.input, page: 1});
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onReset(event) {
        event.preventDefault();
        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    onChangeMultiSelect(name, value) {
        this.changeInput({[name]: value});
    }

    render() {
        let {t, loading} = this.props;
        let input = services.converValue2Int({...this.state.input});
        let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        return (
            <div className="a-content--search-header search-default">
                <Form onSubmit={this.onSubmit.bind(this)} className="a-form">
                    <Row gutter={{lg: 12, xl: 24, xxl: 24}} type="flex">
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("package:label.code_search")}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.code || ''}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                    placeholder={t("package:placeholder.code_search")}
                                    className="a-input--search _packages_search"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={9} xxl={7}>
                            <Form.Item className="a-form__item search-content" label={t('package:label.deleted_at')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _deleted-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['deleted_at[from]'] ? moment(input['deleted_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'deleted_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc __deleted-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['deleted_at[to]'] ? moment(input['deleted_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'deleted_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={9} xxl={7}>
                            <Form.Item className="a-form__item search-content" label={t('package:label.created_at')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={9} xxl={7}>
                            <Form.Item className="a-form__item search-content" label={t('package:label.order_linked_at')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _order-linked-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['order_linked_at[from]'] ? moment(input['order_linked_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'order_linked_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _order-linked-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['order_linked_at[to]'] ? moment(input['order_linked_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'order_linked_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        {/* {
                            authService.can(permissions.CUSTOMER_VIEW_INFO) &&  */}
                            <Col lg={5} xl={5} xxl={3}>
                                <Form.Item
                                    className="a-form__item search-content"
                                    label={t("package:label.customer_username")}
                                    labelCol={{span: 24}}>
                                    <Search
                                        value={input.username || ''}
                                        onChange={this.onChangeInput.bind(this, 'username')}
                                        placeholder={t("package:placeholder.customer_username")}
                                        className="a-input--search  _username_search"
                                    />
                                </Form.Item>
                            </Col>
                        {/* } */}
                        <Col lg={5} xl={5} xxl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("package:label.deleted_username")}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.deleted_username || ''}
                                    onChange={this.onChangeInput.bind(this, 'deleted_username')}
                                    placeholder={t("package:placeholder.deleted_username")}
                                    className="a-input--search  _deleted_username_search"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={5} xl={5} xxl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("package:label.delete_reason")}
                                labelCol={{span: 24}}>
                                <Select mode="multiple"
                                        loading={loading}
                                        placeholder={t("package:placeholder.delete_reason")}
                                        className={"_select_delete_reason"}
                                        value={this.state.input.delete_reason || []}
                                        onChange={this.onChangeMultiSelect.bind(this, 'delete_reason')}>
                                    {lodash.values(DELETE_REASONS).map((reason, index) =>
                                        <Option
                                            className={"_value-delete-reason _value-delete-reason-" + reason.key}
                                            key={index} value={reason.key}>{reason.label}</Option>
                                    )}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={8}>
                            <Form.Item
                                labelCol={{span: 24}}
                                className="a-form__item search-content"
                                label={t('package:label.weight') + ' (' + (configPackageUnitWeight === 'g' ?  `${configPackageUnitWeight} x 100` : configPackageUnitWeight )+  ')'}
                            >
                                <InputNumber
                                    name={ELEMENT.PACKAGE_NET_WEIGHT}
                                    autoComplete="off"
                                    precision={2}
                                    min={0}
                                    value={input['weight_net[from]'] || ''}
                                    placeholder={t('package:label.weight_from')}
                                    onChange={value => this.changeInput({'weight_net[from]': value})}
                                    className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                                />

                                <InputNumber
                                    className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                                    style={{marginLeft: '4px', paddingLeft: '5px', paddingRight: '5px'}}
                                    name={ELEMENT.PACKAGE_NET_WEIGHT}
                                    autoComplete="off"
                                    precision={2}
                                    min={0}
                                    value={input['weight_net[to]'] || ''}
                                    placeholder={t('package:label.weight_to')}
                                    onChange={value => this.changeInput({'weight_net[to]': value})}
                                />

                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a href="/" className="link-reload _a-btn-reload-list-package"
                           onClick={this.onReset.bind(this)}><Icon
                            type="sync"/> {t('common:label.refresh_filter')}</a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list _a-btn-search-list-package"
                            loading={this.props.loading}
                        >{t('btn.search')}</Button>
                    </div>

                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(FormSearch);
