import React, { useState } from 'react';
import { get, values } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TABLE_COLUMNS } from './constants';
import { renderColumn } from './helper';
import { setMenuActive } from './../../Common/actions';
import apiService from './../../StorageFees/apiService';
import { BAG_TYPE, PACKAGE_TYPE } from './../../StorageFees/constants';

import StorageFees from './components';

const StorageFeesContainer = props => {
    const [loadings, setLoadings] = useState({
        [BAG_TYPE]: false,
        [PACKAGE_TYPE]: false
    });
    const [paginations, setPaginations] = useState({
        [BAG_TYPE]: {
            total: 0
        },
        [PACKAGE_TYPE]: {
            total: 0
        }
    });
    const [tableDisplayStorageFees, setTableDisplayStorageFees] = useState({
        [BAG_TYPE]: [],
        [PACKAGE_TYPE]: []
    });
    const [storageFees, setStorageFees] = useState({
        [BAG_TYPE]: [],
        [PACKAGE_TYPE]: []
    });

    const getStorageFees = async (type, params) => {
        setLoadings({
            ...loadings,
            [type]: true
        });

        try {
            const { data } = await apiService.getStorageFees({
                ...params,
                object: type
            });

            setStorageFees({
                ...storageFees,
                [type]: data.storage_fee
            });
            setTableDisplayStorageFees({
                ...tableDisplayStorageFees,
                [type]: data.storage_fee.map(storageFee => {
                    const formattedStorageFee = {};

                    formattedStorageFee.id = get(storageFee, 'storage_fee.id');
                    values(TABLE_COLUMNS[type]).forEach(column => {
                        formattedStorageFee[column] = renderColumn(column, storageFee);
                    });

                    return formattedStorageFee;
                })
            });
            setPaginations({
                ...paginations,
                [type]: data.pagination
            });
        } catch (error) {
        }

        setLoadings({
            ...loadings,
            [type]: false
        });
    };

    return (
        <StorageFees
            {...props}
            loadings={loadings}
            paginations={paginations}
            storageFees={storageFees}
            tableDisplayStorageFees={tableDisplayStorageFees}
            getStorageFees={getStorageFees}
        />
    );
};

const mapDispatchToProps = {
    setMenuActive
};

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(StorageFeesContainer));
