import {processApiRequest} from '../saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* fetchLogs(action) {
    const {payload} = action;
    const {object} = payload;
    
    yield processApiRequest(ACTION.FETCH_LOGS, () => {
        if (payload.object === 'package-tracking') {
            return apiService.fetchPackageCheckingLogs(payload.object_id);
        }

        return apiService.fetchLogs(payload.object, payload.object_id, payload.filter);
    }, {
        object
    });
}
