import React from 'react';
import { Layout, Spin, List } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import pageService from './../../../Common/Page/pageService';

import PackageCollapse from './PackageCollapse';
import CreatePackageSameBarcode from './../../CreatePackageSameBarcode/CreatePackageSameBarcodeContainer';
import FormScanBarcode from './../../FormScanBarcode/FormScanBarcodeContainer';
import { find } from 'lodash';

const { Content } = Layout;

class CreatePackage extends React.PureComponent {
    state = {
        // Package đang được active hiện tại
        activePackageId: null,
        // Package cuối được yêu cầu active từ parent
        lastRequestActivePackageId: null,
        // Input hiện tại của form tạo kiện quét mã
        formScanBarcodeInput: this.props.input || {},
        scroll: false,
        hasMore: true,
    };

    static getDerivedStateFromProps(props, state) {
        let nextState = {};

        // Nếu props activePackageId thay đổi thì update lại active package trong state
        if (props.activePackageId !== state.lastRequestActivePackageId) {
            nextState = {
                ...nextState,
                activePackageId: props.activePackageId,
                lastRequestActivePackageId: props.activePackageId,
            };
            const packageCurrentInfo = find(props.packages, ["id",  props.activePackageId]);
            if (get(packageCurrentInfo, "customer.id_agency")) {
                props.fetchConsignServiceGroups({id_agency: get(packageCurrentInfo, "customer.id_agency")})
            }
        }
        if (!state.hasMore && props.packages.length >= props.paginationPackages.total) {
            nextState = {
                ...nextState,
                hasMore: true
            }
        }
        return nextState;
    }

    componentDidMount() {
        const { t, setMenuActive } = this.props;

        pageService.setTitle(t('package:create.title'));
        setMenuActive('packages/create');
        window.addEventListener('scroll', this.handleScroll);
    }

    onTogglePackage = packageId => {
        this.setState({
            activePackageId: this.state.activePackageId === packageId ? null : packageId,
        });
        const packageCurrentInfo = find(this.props.packages, ["id",  packageId]);
            if (get(packageCurrentInfo, "customer.id_agency")) {
                this.props.fetchConsignServiceGroups({id_agency: get(packageCurrentInfo, "customer.id_agency")})
            }
    };

    onFormScanBarcodeChange = input => {
        this.setState({formScanBarcodeInput: {...this.state.formScanBarcodeInput, ...input}});
    }

    onFormScanBarcodeSubmit = input => {
        const newFormScanBarcodeInput = {
            ...this.state.formScanBarcodeInput,
            ...input
        };

        this.setState({
            formScanBarcodeInput: newFormScanBarcodeInput
        });
        this.props.onScan(input);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false});
            }
        }
    };

    handleInfiniteOnLoad = () => {
        const {paginationPackages, filter, fetchBarcodePackages, packages} = this.props;
        if (packages.length >= paginationPackages.total) {
            this.setState({
                hasMore: false,
            });
            return;
        } else {
            fetchBarcodePackages({...filter, page: paginationPackages.page + 1 })
        }
    };

    render() {
        const { activePackageId, formScanBarcodeInput, scroll } = this.state;
        const { canCreatePackageSameBarcode, input, loadingPackages, packages, t, paginationPackages } = this.props;

        return (
            <Layout>
                <FormScanBarcode
                    input={input}
                    onChange={this.onFormScanBarcodeChange}
                    onSubmit={this.onFormScanBarcodeSubmit}
                    scroll={scroll}
                />
                {packages.length === 0 ? (
                    <Content className="a-content a-content--package a-package__scan-empty">
                        <img src={require('../../../../resources/images/bar-code.png')} alt=""/>
                        <span>Chưa có dữ liệu <br/> quét mã</span>
                    </Content>
                ) : (
                    
                        <Content
                            className={clsx('a-content a-content--package', {
                                'scroll-is-sider': scroll
                            })}
                         >
                            {input.barcode ? (
                                <Spin spinning={get(loadingPackages, 'fetchBarcodePackage', false)}>
                                    <div className="a-content__top">
                                        <div className="a-content__top__title">
                                            <h3 className="_code_package_in_title">{t('package:create.barcode')} - {input.barcode} ({get(paginationPackages, 'total', 0)} {t("package")})</h3>
                                        </div>
                                        <CreatePackageSameBarcode
                                            input={formScanBarcodeInput}
                                            canCreate={!!canCreatePackageSameBarcode}
                                        />
                                    </div>
                                </Spin>
                            ) : null}
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={this.handleInfiniteOnLoad}
                            hasMore={!get(loadingPackages, 'fetchBarcodePackage', false) && this.state.hasMore}
                            useWindow={true}
                        >
                            <List
                                className="a-content__body a-content__block-scroll a-package__body"
                                dataSource={packages}
                                renderItem={packageData => (
                                    <PackageCollapse
                                        input={formScanBarcodeInput}
                                        key={packageData.id}
                                        package={packageData}
                                        index={packageData.id}
                                        isOpen={packageData.id === activePackageId}
                                        onToggle={this.onTogglePackage.bind(this, packageData.id)}
                                        fetchBarcodePackages={this.props.fetchBarcodePackages}
                                        fetchBarcodePackagesAgain={this.props.fetchBarcodePackagesAgain}
                                        filter={this.props.filter}
                                    />
                                    )
                                }
                            >
                                { get(loadingPackages, 'fetchBarcodePackage', false) && this.state.hasMore && (
                                    <div className='gflex galign-center gjustify-center'>
                                        <Spin />
                                    </div>
                                )}
                            </List>
                        </InfiniteScroll>
                    </Content>
                   
                )}
            </Layout>
        );
    }
}

CreatePackage.defaultProps = {
    packages: [],
    activePackageId: null,
};

CreatePackage.propTypes = {
    packages: PropTypes.array,
    activePackageId: PropTypes.any,
};

export default withTranslation()(CreatePackage);
