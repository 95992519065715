import { get, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { BARCODE_TYPE } from './../../Barcode/constants';
import { getBarcodeWarning } from './../DeliveryNoteBarcode/selectors';

export const getState = (state, param = null, defaultValue = null) => get(state, `deliveryNote.create${(param ? `.${param}` : '')}`, defaultValue);

export const getBarcodes = createSelector(
    state => getState(state, 'suggests.suggests', []),
    suggests => {
        const barcodes = {};

        for (const suggest of suggests) {
            barcodes[suggest.identity] = getFormattedBarcodes(suggest.barcodes);
        }

        return barcodes;
    }
);

export const getBarcodesOfSuggest = createSelector(
    (state, props) => get(props, 'deliveryNote.barcodes', []),
    barcodes => getFormattedBarcodes(barcodes)
);

const getFormattedBarcodes = barcodes => barcodes.map(barcode => ({
    ...barcode,
    warning: getBarcodeWarning(barcode.warning)
}));

export const getPackages = createSelector(
    getBarcodes,
    barcodes => mapValues(
        barcodes,
        customerBarcodes => customerBarcodes.filter(barcode =>
            barcode.type === BARCODE_TYPE.PACKAGE
        )
    )
);

export const getBags = createSelector(
    getBarcodes,
    barcodes => mapValues(
        barcodes,
        customerBarcodes => customerBarcodes.filter(barcode =>
            barcode.type === BARCODE_TYPE.BAG
        )
    )
);

export const getSelectedPackageIds = createSelector(
    getPackages,
    packages => mapValues(packages, packagesOfCustomer => {
        return packagesOfCustomer.map(pkg => get(pkg, 'package.id'))
    })
);

export const getSelectedBagIds = createSelector(
    getBags,
    bags => mapValues(bags, bagsOfCustomer => {
        return bagsOfCustomer.map(bag => get(bag, 'bag.id'))
    })
);
