import React, { Component } from 'react'
import { Button, Checkbox, Col, Form, Input, notification, Row, Select } from 'antd'
import clsx from 'clsx'
import { get, isEmpty, isNil, omitBy, find, isObject } from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { DEFAULT_STATE_CREATE_BAG } from './../constants'
import { BAG_SHIPPING_TYPE } from './../../BagShippingType/constants'
import agencyApiService from './../../../Agencies/apiService'
import pageService from './../../../Common/Page/pageService'
import bagTemplateApiService from './../../../TemplateBag/apiService'
import { Link } from './../../../../system/routing'

import { PopupListTemplateBag } from './PopupListTemplateBag'
import ViewBag from './ViewBag'
import PrintBagStampButton from './../../BagDetail/components/PrintBagStampButtonContainer'
import FormBagShippingTypeContainer from './../../BagShippingType/FormBagShippingType/FormBagShippingTypeContainer'
import SelectBagSizeContainer from './../../../BagSize/SelectBagSize/SelectBagSizeContainer'
import SelectCustomerContainer from './../../../Customer/SelectCustomer/SelectCustomerContainer'
import FormPropertiesContainer from './../../../Property/FormProperties/FormPropertiesContainer'
import FormServices from './../../../Service/FormServices/FormServicesContainer'
import SelectCreatePartnerContainer from './../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer'
import SelectCreateShippingUserContainer from './../../../ShippingUser/Select/SelectCreate'
import ChooseWarehouse from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'
import authService from "../../../Auth/authService";

const { TextArea } = Input
const { Option } = Select

class CreateBag extends Component {
    state = {
        ...DEFAULT_STATE_CREATE_BAG,
        isShowPopupListTemplateBag: false,
        showNewBag: false,
        agencies: [],
        errorsIdCustomer: undefined,
    }

    onSubmit = (showNewBag, redirect) => {
        const { id_bag_template, id_customer } = this.state
        const { form, onSubmit, t } = this.props
        let customerValidated = true
        const type = form.getFieldValue('type')
        if (type === 'SINGLE' && !id_customer) {
            customerValidated = false
            this.setState({
                errorsIdCustomer: t('validation:required', { attribute: t('bag:label.customer') }),
            })
        }

        form.validateFields((error, values) => {
            if (error) {
                return
            }
            if (!customerValidated) {
                return
            }
            const payload = {
                ...values,
                redirect,
                id_customer
            }

            if (payload.id_shipping_partner) {
                payload.subject_shipping = BAG_SHIPPING_TYPE.PARTNER

                delete payload.id_shipping_user
            } else if (payload.id_shipping_user) {
                payload.subject_shipping = BAG_SHIPPING_TYPE.USER

                delete payload.id_shipping_partner
            }

            payload.id_bag_template = id_bag_template

            this.setState({
                showNewBag: !!showNewBag,
            })

            onSubmit(omitBy(payload, isNil))
        })
    }

    fetchAgencies = async () => {
        try {
            const response = await agencyApiService.getAgencySuggest()

            this.setState({
                agencies: get(response, 'data.agencies'),
            })
        } catch (error) {}
    }

    /**
     * Change input text
     * @param event
     */
    changeTextValue = event => {
        const { errors, resetError } = this.props
        const { name } = event.target

        if (errors && errors[name]) {
            resetError(name)
        }
    }

    /**
     * change select value
     * @param input
     * @param name
     */

    onChangeSelectValue = (name, input) => {
        const { type } = this.state
        const { errors, form, resetError, t } = this.props
        let value = input && input.value ? input.value : input

        if (name === 'id_customer') {
            if (type === 'MULTIPLE') {
                form.setFieldsValue({
                    [name]: undefined,
                })
                value = undefined
            } else {
                let agencyId = undefined
                if (isObject(input)) {
                    value = get(input, 'customer.id')
                    agencyId = get(input, 'agency.id')
                }
                form.setFieldsValue({
                    id_customer: value,
                    id_agency: agencyId,
                })
            }
            this.setState({
                id_customer: value,

                errorsIdCustomer: value ? undefined : t('validation:required', { attribute: t('bag:label.customer') }),
            })
        }

        if (name === 'id_shipping_partner') {
            form.setFieldsValue({
                id_shipping_user: undefined,
            })
        }

        if (name === 'id_shipping_user') {
            form.setFieldsValue({
                id_shipping_partner: undefined,
            })
        }

        if (name === 'type' && value === 'MULTIPLE') {
            form.setFieldsValue({
                id_customer: undefined,
            })
        }

        if (errors && errors[name]) {
            resetError(name)
        }
    }

    onReset = () => {
        this.props.onReset()
    }

    togglePopupListTemplateBag = () => {
        this.setState(prevState => ({
            isShowPopupListTemplateBag: !prevState.isShowPopupListTemplateBag,
        }))
    }

    onSelectTemplateBag = async bagTemplateId => {
        const { form, t, onReset } = this.props

        this.setState({
            loadingBagTemplate: true,
        })

        try {
            const { data } = await bagTemplateApiService.fetchTemplateBagDetail(bagTemplateId)

            const bagTemplate = get(data, 'bag_template', {})
            const agency = get(data, 'agency')

            this.togglePopupListTemplateBag()

            let fieldsValue = {
                id_bag_size: bagTemplate.id_bag_size,
                id_warehouse_input: bagTemplate.id_warehouse_input,
                id_warehouse_destination: bagTemplate.id_warehouse_destination,
                receiver_name: bagTemplate.receiver_name,
                receiver_phone: bagTemplate.receiver_phone,
                note: undefined,
                services: bagTemplate.id_services || [],
                properties: bagTemplate.id_properties || [],
                id_customer: bagTemplate.id_customer,
                type: bagTemplate.type,
                is_unchecked: bagTemplate.is_unchecked,
                id_agency: get(agency, 'id'),
            }
            if (bagTemplate.id_shipping_partner > 0) {
                fieldsValue.subject_shipping = BAG_SHIPPING_TYPE.PARTNER
                fieldsValue.id_shipping_partner = bagTemplate.id_shipping_partner
            } else if (bagTemplate.id_shipping_user > 0) {
                fieldsValue.subject_shipping = BAG_SHIPPING_TYPE.USER
                fieldsValue.id_shipping_user = bagTemplate.id_shipping_user
            }

            form.setFieldsValue(fieldsValue)

            this.setState({
                id_bag_template: bagTemplateId,
            })

            onReset()
        } catch (error) {
            notification.error({
                message: t('template_bag:get_detail.failed'),
            })
        }

        this.setState({
            loadingBagTemplate: false,
        })
    }

    componentDidMount() {
        pageService.setTitle(this.props.t('bag:create.title'))
        this.props.setMenuActive('bags/create')
        this.fetchAgencies()
    }

    componentWillUnmount() {
        this.props.onReset()
    }

    render() {
        const { agencies, isShowPopupListTemplateBag, loadingBagTemplate, showNewBag, id_customer, errorsIdCustomer } = this.state
        const { bagAreaSuggests, bagSizeRequired, errors, form, isLoadingProperty, loading, newBag, t } = this.props
        const configPartnerRequireAgencyWhenCreateBag = get(authService.getPartnerConfigSetting('setting', 'BAG:REQUIRED_AGENCY_WHEN_CREATE_BAG'), 'value', false) || false;
        const bagType = [
            { key: 1, label: t('bag:label.multi_bag'), value: 'MULTIPLE' },
            { key: 2, label: t('bag:label.single_bag'), value: 'SINGLE' },
        ]
        const { getFieldDecorator } = form

        return (
            <Form className="a-block">
                <div className="block--primary a-content--bag bag-create">
                    <div className="title create-bag">
                        <div className="left">
                            <span className="a-text--bold">
                                {isEmpty(newBag) ? (
                                    t('bag:new')
                                ) : (
                                    <Link
                                        className="a-text--white"
                                        params={{ id: newBag.bag.code }}
                                        to="bags.detail">
                                        {newBag.bag.code}
                                    </Link>
                                )}
                            </span>
                        </div>
                        {!isEmpty(newBag) && showNewBag ? (
                            <div className="right">
                                <PrintBagStampButton
                                    bagId={get(newBag, 'bag.id')}
                                    className="a-btn a-btn--transparent-white _btn-print-bag-stamp"
                                    ghost
                                    type="button"
                                />
                            </div>
                        ) : null}
                        {isEmpty(newBag) && (
                            <div className="right">
                                <Button
                                    className="a-btn a-btn--transparent-white _btn-create-temple-bag"
                                    ghost
                                    type="button"
                                    onClick={this.togglePopupListTemplateBag}>
                                    {t('bag:label.choose_template_bag')}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="body">
                        {isEmpty(newBag) || !showNewBag ? (
                            <Row gutter={{ lg: 24, xl: 48 }}>
                                <Col
                                    lg={{ span: 18 }}
                                    xl={{ span: 19 }}
                                    xxl={{ span: 19 }}>
                                    <div className="a-block-info pb-0 block-create-bag">
                                        <div className="block-create-bag-title">
                                            <h3>{t('bag:create_bag.info_bag')}</h3>
                                        </div>
                                        <Row
                                            gutter={{ lg: 24, xl: 32 }}
                                            className="a-form apt-16 mx-0">
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 0 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 0 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.type}
                                                    label={t('bag:label.bag_type')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.type ? 'error' : undefined}>
                                                    {getFieldDecorator('type')(
                                                        <Select
                                                            allowClear
                                                            className="_type-bag"
                                                            dropdownClassName="_dropdown_type-bag"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            optionFilterProp="children"
                                                            showSearch
                                                            onChange={this.onChangeSelectValue.bind(this, 'type')}>
                                                            {bagType.map(bag => (
                                                                <Option
                                                                    className="_type-bag__item"
                                                                    key={bag.key}
                                                                    value={bag.value}>
                                                                    {bag.label}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 2 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errorsIdCustomer}
                                                    label={t('bag:label.customer')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errorsIdCustomer ? 'error' : undefined}
                                                    required={form.getFieldValue('type') === 'SINGLE'}>
                                                    <SelectCustomerContainer
                                                        value={id_customer}
                                                        allowClear
                                                        autoSelect
                                                        className="a-select a-select--search-content _id_customer"
                                                        disabled={form.getFieldValue('type') !== 'SINGLE'}
                                                        dropdownClassName="_dropdown_id_customer"
                                                        emptyOption={false}
                                                        is_shipper
                                                        placeholder={t('bag:label.customer_placeholder')}
                                                        showName
                                                        dispatchObject={true}
                                                        onChange={value => this.onChangeSelectValue('id_customer', value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row
                                            gutter={{ lg: 24, xl: 32 }}
                                            className="a-form mx-0">
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8 }}
                                                xl={{ span: 6 }}
                                                xxl={{ span: 5 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_bag_size}
                                                    label={t('bag:label.size')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_bag_size ? 'error' : undefined}>
                                                    {getFieldDecorator('id_bag_size', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.size'),
                                                                }),
                                                                required: bagSizeRequired,
                                                            },
                                                        ],
                                                    })(
                                                        <SelectBagSizeContainer
                                                            allowClear
                                                            autoSelect={bagSizeRequired}
                                                            disabled={false}
                                                            dropdownClassName="_dropdown_id_bag_size"
                                                            isFieldDecorator
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_bag_size')}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 24, offset: 0 }}
                                                xl={{ span: 17, offset: 0 }}
                                                xxl={{ span: 18, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    label={t('bag:label.properties')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.properties ? 'error' : undefined}>
                                                    {getFieldDecorator('properties')(
                                                        <FormPropertiesContainer
                                                            classUl="property--package _properties"
                                                            errors={errors.properties}
                                                            values={form.getFieldValue('properties')}
                                                            onChange={this.onChangeSelectValue.bind(this, 'properties')}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="a-form mx-0"
                                            gutter={{ lg: 24, xl: 32 }}>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8 }}
                                                xl={{ span: 6 }}
                                                xxl={{ span: 5 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_warehouse_input}
                                                    label={t('bag:label.warehouse_input')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_warehouse_input ? 'error' : undefined}>
                                                    {getFieldDecorator('id_warehouse_input', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.warehouse_input'),
                                                                }),
                                                                required: true,
                                                            },
                                                        ],
                                                    })(
                                                        <ChooseWarehouse
                                                            active
                                                            allowClear
                                                            autoSelected={false}
                                                            className="a-select a-select--search-content _id_warehouse_input"
                                                            dropdownClassName="_dropdown__id_warehouse_input"
                                                            emptyOption={false}
                                                            isPermission
                                                            isSelect
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_input')}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 2 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_warehouse_destination}
                                                    label={t('bag:label.warehouse_destination')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_warehouse_destination ? 'error' : undefined}>
                                                    {getFieldDecorator('id_warehouse_destination', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.warehouse_destination'),
                                                                }),
                                                                required: true,
                                                            },
                                                        ],
                                                    })(
                                                        <ChooseWarehouse
                                                            active
                                                            allowClear
                                                            autoSelected={false}
                                                            className="a-select a-select--search-content _id_warehouse_destination"
                                                            dropdownClassName="_dropdown_id_warehouse_destination"
                                                            emptyOption={false}
                                                            isPermission={false}
                                                            isSelect
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_destination')}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 0 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.receiver_name}
                                                    label={t('bag:label.receiver_name')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.receiver_name ? 'error' : undefined}>
                                                    {getFieldDecorator('receiver_name', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.receiver_name'),
                                                                }),
                                                                required: true,
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            className="a-input _receiver_name"
                                                            name="receiver_name"
                                                            placeholder={t('bag:label.receiver_name_placeholder')}
                                                            onChange={this.changeTextValue}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 2 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.receiver_phone}
                                                    label={t('bag:label.receiver_phone')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.receiver_phone ? 'error' : undefined}>
                                                    {getFieldDecorator('receiver_phone', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.receiver_phone'),
                                                                }),
                                                                required: true,
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            className="a-input _receiver_phone"
                                                            name="receiver_phone"
                                                            placeholder={t('bag:label.receiver_phone_placeholder')}
                                                            onChange={this.changeTextValue}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="a-form mx-0"
                                            gutter={{ lg: 24, xl: 32 }}>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8 }}
                                                xl={{ span: 6 }}
                                                xxl={{ span: 5 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.subject_shipping}
                                                    label={t('bag:label.shipping_type')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.subject_shipping ? 'error' : undefined}>
                                                    {getFieldDecorator('subject_shipping', {
                                                        initialValue: BAG_SHIPPING_TYPE.PARTNER,
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.shipping_type'),
                                                                }),
                                                                required: true,
                                                            },
                                                        ],
                                                    })(
                                                        <FormBagShippingTypeContainer
                                                            allowClear
                                                            disabled={false}
                                                            dropdownClassName="_dropdown_subject_shipping"
                                                            noForm
                                                            onChange={this.onChangeSelectValue.bind(this, 'subject_shipping')}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 2 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 1 }}>
                                                <Form.Item
                                                    className={clsx('a-form__item', {
                                                        'a-hidden': form.getFieldValue('subject_shipping') !== BAG_SHIPPING_TYPE.USER,
                                                    })}
                                                    help={errors.id_shipping_user}
                                                    label={t('bag:label.shippingPartner')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_shipping_user ? 'error' : undefined}>
                                                    {getFieldDecorator('id_shipping_user', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.shippingPartner'),
                                                                }),
                                                                required: form.getFieldValue('subject_shipping') === BAG_SHIPPING_TYPE.USER,
                                                            },
                                                        ],
                                                    })(
                                                        <SelectCreateShippingUserContainer
                                                            allowClear
                                                            className="a-select a-select--search-content _id_shipping_user"
                                                            dropdownClassName="_dropdown__id_shipping_user"
                                                            emptyOption={false}
                                                            isFieldDecorator
                                                            placeholder={t('bag:label.id_shipping_user_placeholder')}
                                                            selectProps={{
                                                                dropdownClassName: '_dropdown__id_shipping_user',
                                                            }}
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_user')}
                                                        />
                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    className={clsx('a-form__item', {
                                                        'a-hidden': form.getFieldValue('subject_shipping') !== BAG_SHIPPING_TYPE.PARTNER,
                                                    })}
                                                    help={errors.id_shipping_partner}
                                                    label={t('bag:label.shippingPartner')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_shipping_partner ? 'error' : undefined}>
                                                    {getFieldDecorator('id_shipping_partner', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.shippingPartner'),
                                                                }),
                                                                required: form.getFieldValue('subject_shipping') === BAG_SHIPPING_TYPE.PARTNER,
                                                            },
                                                        ],
                                                    })(
                                                        <SelectCreatePartnerContainer
                                                            allowClear
                                                            dropdownClassName="_dropdown_id_shipping_partner"
                                                            getPartnersByWarehouse
                                                            isFieldDecorator
                                                            placeholder={t('bag:label.id_shipping_partner_placeholder')}
                                                            warehouseId={form.getFieldValue('id_warehouse_input')}
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_partner')}
                                                        />
                                                    )}
                                                </Form.Item>

                                                {form.getFieldValue('subject_shipping') !== BAG_SHIPPING_TYPE.PARTNER &&
                                                form.getFieldValue('subject_shipping') !== BAG_SHIPPING_TYPE.USER ? (
                                                    <Form.Item
                                                        className="a-form__item"
                                                        label={t('bag:label.shippingPartner')}
                                                        labelCol={{ span: 24 }}>
                                                        <Select disabled />
                                                    </Form.Item>
                                                ) : null}
                                            </Col>
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 24, offset: 0 }}
                                                xl={{ span: 12, offset: 0 }}
                                                xxl={{ span: 11, offset: 1 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.note}
                                                    label={t('bag:label.note')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.note ? 'error' : undefined}>
                                                    {getFieldDecorator('note')(
                                                        <TextArea
                                                            autosize={{
                                                                minRows: 1,
                                                                maxRows: 3,
                                                            }}
                                                            className="a-input--textarea _note"
                                                            name="note"
                                                            placeholder={t('bag:label.note_placeholder')}
                                                            spellCheck={false}
                                                            onChange={this.changeTextValue}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row
                                            gutter={{ lg: 24, xl: 32 }}
                                            className="a-form mx-0">
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 0 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 0 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.is_unchecked}
                                                    label={<span className="" />}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.is_unchecked ? 'error' : undefined}>
                                                    {getFieldDecorator('is_unchecked')(
                                                        <Checkbox
                                                            checked={form.getFieldValue('is_unchecked')}
                                                            className="_is_unchecked"
                                                            onChange={this.onChangeSelectValue.bind(this, 'is_unchecked')}>
                                                            {t('bag:label:unchecked')}
                                                        </Checkbox>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row
                                            gutter={{ lg: 24, xl: 32 }}
                                            className="a-form mx-0">
                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 0 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 0 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_agency}
                                                    label={t('bag:label.agency')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_agency ? 'error' : undefined}>
                                                    {getFieldDecorator('id_agency')(
                                                        <Select
                                                            allowClear
                                                            className="_agencies"
                                                            dropdownClassName="_dropdown_agencies"
                                                            // mode="multiple"
                                                            optionFilterProp="children"
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_agency')}>
                                                            {agencies
                                                                .map(item => item.agency)
                                                                .map(agency => (
                                                                    <Option
                                                                        className={`_dropdown_option_${get(agency, 'id')}`}
                                                                        key={get(agency, 'id')}
                                                                        value={get(agency, 'id')}>
                                                                        {get(agency, 'name')}
                                                                    </Option>
                                                                ))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                className="a-form--group"
                                                lg={{ span: 8, offset: 0 }}
                                                xl={{ span: 6, offset: 0 }}
                                                xxl={{ span: 5, offset: 0 }}>
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_bag_area}
                                                    label={t('bag:label.bag_area')}
                                                    labelCol={{ span: 24 }}
                                                    validateStatus={errors.id_bag_area ? 'error' : undefined}>
                                                    {getFieldDecorator('id_bag_area', {
                                                        rules: [
                                                            {
                                                                message: t('validation:required', {
                                                                    attribute: t('bag:label.bag_area'),
                                                                }),
                                                                required: configPartnerRequireAgencyWhenCreateBag,
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            allowClear
                                                            className="_bag_areas"
                                                            dropdownClassName="_dropdown_areas"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            optionFilterProp="children"
                                                            showSearch
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_bag_area')}>
                                                            {bagAreaSuggests.map(bagArea => (
                                                                <Option
                                                                    className={`_dropdown_option_${get(bagArea, 'bag_area.id')}`}
                                                                    key={get(bagArea, 'bag_area.id')}
                                                                    value={get(bagArea, 'bag_area.id')}>
                                                                    {`${get(bagArea, 'bag_area.name')} (${get(bagArea, 'bag_area.code')})`}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col
                                    lg={{ span: 6 }}
                                    xl={{ span: 5 }}
                                    xxl={{ span: 5 }}
                                    className="pl-0">
                                    <div className="a-inline-edit service-edit">
                                        <div className="a-inline-edit--input">
                                            {getFieldDecorator('services')(<FormServices values={form.getFieldValue('services')} />)}
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    className="a-package--action only-action create-bag amt-24 pb-0"
                                    lg={{ span: 24 }}>
                                    {isEmpty(newBag) ? (
                                        <div>
                                            <Button
                                                className="a-btn a-btn--primary _save_bag_btn"
                                                disabled={loading && !showNewBag}
                                                loading={(loading && showNewBag) || isLoadingProperty}
                                                size="large"
                                                type="primary"
                                                onClick={this.onSubmit.bind(undefined, true, false)}>
                                                {t('btn.save')}
                                            </Button>
                                            <Button
                                                className="a-btn a-btn--primary _save_bag_packing_btn aml-16"
                                                disabled={loading && showNewBag}
                                                loading={(loading && !showNewBag) || isLoadingProperty}
                                                size="large"
                                                type="primary"
                                                onClick={this.onSubmit.bind(undefined, false, true)}>
                                                {t('bag:btn.save_and_close')}
                                            </Button>
                                        </div>
                                    ) : null}
                                </Col>
                            </Row>
                        ) : (
                            <ViewBag newBag={newBag} />
                        )}
                    </div>
                </div>
                <div className="action-width-fixed pb-5">
                    {isEmpty(newBag) ? null : (
                        <Button
                            className="a-btn a-btn--primary a-btn--width-fixed -redirect_btn"
                            type="button"
                            onClick={this.onReset}>
                            {t('btn.create_new')}
                        </Button>
                    )}
                </div>

                {isShowPopupListTemplateBag && (
                    <PopupListTemplateBag
                        {...this.props}
                        loading={loading || loadingBagTemplate}
                        onSelectTemplateBag={this.onSelectTemplateBag}
                        onCancel={this.togglePopupListTemplateBag}
                    />
                )}
            </Form>
        )
    }
}

CreateBag.defaultProps = {
    errors: {},
    newBag: {},
    loading: false,
    bagSizes: [],
    bagTypes: [],
    listShippingUser: [],
}

CreateBag.propTypes = {
    errors: PropTypes.object,
    newBag: PropTypes.object,
    loading: PropTypes.bool,
    bagSizes: PropTypes.array,
    bagTypes: PropTypes.array,
    listShippingUser: PropTypes.array,
}

export default withTranslation()(Form.create()(CreateBag))
