import { Tabs } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import LogContainer from '../../../Common/Log/LogContainer'

function Log(props) {
    const { t, objectId } = props
    return (
        <div className="a-chatbox checkpackage">
            <div className="a-chatbox--body">
                <Tabs
                    defaultActiveKey={'chatlog'}
                    className="a-tabs a-tabs--chatbox-tab _a-tabs-chatbox-tab">
                    <Tabs.TabPane
                        className="a-tabs--chatbox--panel _a-tabs-chatbox--panel-log"
                        tab={t('package:label.log_title')}
                        key="1">
                        <LogContainer
                            object="packaging-requests"
                            objectId={objectId}
                            delayTime={0}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}
export default withTranslation()(Log)
