import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import lodash from "lodash";
import PropTypes from 'prop-types';
import {Prompt} from "react-router-dom";

/**
 * Kiểm tra có confirm khi chuyển trang hay không
 * @returns {boolean}
 */
const checkLeavePage = function (deliveryNote, params, submit, stateData) {

    if(submit) return false;
    let barcodes = lodash.get(deliveryNote, 'barcodes', []);
    let confirm = false;
    lodash.forEach(params, (key) => {
        let value = (key === 'packages') ? lodash.get(stateData, key, []) : lodash.get(stateData, key, '');
        if(lodash.isNil(deliveryNote)) {
            if((key === 'packages' && value.length) || (key !== 'packages' && value)) {
                confirm = true;
            }
        } else {
            if(
                (key === 'packages' && !lodash.isEqual(barcodes.sort(), value.sort())) ||
                (key !== 'packages' && (lodash.get(deliveryNote, key, '') !== value))
            ) {
                confirm = true;
            }
        }
    })

    return confirm;
}

class PromptLeavePage extends Component {

    render() {
        let {deliveryNote, params, submit, stateData, t} = this.props;
        return (
            <Prompt
                when={checkLeavePage(deliveryNote, params, submit, stateData)}
                message={() => t('message.confirm_when_leave')}
            />
        );
    }
}

PromptLeavePage.defaultProps = {
    submit: false,
    params: {},
    deliveryNote: {},
};

PromptLeavePage.propTypes = {
    submit: PropTypes.bool,
    params: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    deliveryNote: PropTypes.object
};

export default withTranslation()(PromptLeavePage);
export {checkLeavePage};