import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import {
    fetchCountry,
    fetchDistrict,
    fetchLocation,
    fetchProvince,
    fetchWard,
    setCurrentCity,
    setCurrentCountry,
    setCurrentDistrict
} from './../actions';
import { getDefaultCountry } from './../selectors';
import { fetchPropertySuggest } from './../../Property/actions';

import LocationShippingPartnerForm from './components';

const mapStateToProps = (state, props) => ({
    errors: get(props, 'errors', {}),
    warehouseData: props.warehouseData,
    countries: [...state.location.listCountry],
    cities: [...state.location.listProvince],
    districts: [...state.location.listDistrict],
    wards: [...state.location.listWard],
    isLoadingLocation: get(state, 'location.loading', false),
    isLoadingProperty: get(state, 'property.loading', false),
    properties: get(state, 'property.list', []),
    defaultCountry: getDefaultCountry(state),
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchLocation: () => {
        dispatch(fetchLocation({iChooseDefault: true}));
    },
    fetchCountry: (id) => {
        dispatch(fetchCountry({id: id, iChooseDefault: true}));
    },
    fetchProvince: (id) => {
        dispatch(fetchProvince({id: id, iChooseDefault: true}));
    },
    fetchDistrict: (id) => {
        dispatch(fetchDistrict({id: id, iChooseDefault: true}));
    },
    fetchWard: (id) => {
        dispatch(fetchWard({id: id, iChooseDefault: true}));
    },
    fetchProperty: () => {
        dispatch(fetchPropertySuggest());
    },
    setCurrentCountry: (idCountry) => {
        dispatch(setCurrentCountry(idCountry));
    },
    setCurrentCity: (idCity) => {
        dispatch(setCurrentCity(idCity));
    },
    setCurrentDistrict: (idDistrict) => {
        dispatch(setCurrentDistrict(idDistrict));
    }
});

const LocationShippingPartnerFormContainer = props => (
    <LocationShippingPartnerForm {...props} />
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationShippingPartnerFormContainer);
