import React, { useState } from 'react'
import { Button, Form, Icon, Modal, notification, Upload } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import i18next from 'i18next'
import apiService from '../Bag/apiService'
import authService from '../Auth/authService'
import { t } from '../../system/i18n'
import UploadFile from '../Upload/UploadFile'
import permissions from '../Auth/permissions'

const ERRORS = [
    'not_exist',
    'already_exist',
    'required',
    'sku_already_exist',
    'order_already_exist',
    'duplicated',
    'invalid',
    'gte',
    'gt',
    'greater',
    'numeric',
    'empty',
    'exists',
    'user_merchant',
    'in',
    'finished_without_freight_bill',
    'finished_with_freight_bill',
    'status_invalid',
    'fail_when_auto_inspection',
    'receiver_country_invalid',
    'receiver_province_invalid',
    'receiver_district_invalid',
    'receiver_ward_invalid',
    'not_to_access_order',
]
export default function UpdateShippingPartnerInBag() {
    const [visible, setVisible] = useState(false)
    const [messages, setMessages] = useState({})

    function showModal(e) {
        e.preventDefault()
        setVisible(true)
    }

    function renderError(line, error) {
        const content = lodash.map(error, 'code')
        let messages = []
        content.forEach(value => {
            messages.push(t(`bag:message.upload_file.${value}`))
        })

        if (messages.length > 0) return t('bag:message.upload_file.line', { line: line, content: messages.join(' ') })
        return t('product:message.upload_file.unknown_error')
    }

    function handleOk() {
        setMessages([])
        setVisible(false)
    }

    function handleCancel() {
        setMessages([])
        setVisible(false)
    }

    function handleUpload(file) {
        setMessages([])
        const filename = lodash.get(file, 'name', '')
        let formData = new FormData()

        formData.append('file', file)
        apiService
            .updateShippingPartner(formData)
            .then(res => {
                const data = lodash.get(res, 'data', {})
                if (lodash.isEmpty(data)) {
                    handleCancel()
                    notification.success({ message: t('bag:message.upload_file.update_shipping_partner_success') })
                } else {
                    setMessages(data)
                }
            })
            .catch(err => {
                const { response } = err
                if (response.status === 400) {
                    notification.error({ message: t('bag:message.upload_file.failed', { filename }) })
                }
            })
    }
    
    return (
        <>
            {(authService.can(permissions.BAG_UPDATE) || authService.can(permissions.BAG_UPDATE_ALL)) && (
                <Button
                    type="primary"
                    className="a-btn a-btn--link-primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                    onClick={showModal}>
                    <Icon type="cloud-upload" /> {t('bag:btn.update_shipping_partner')}
                </Button>
            )}
            <Modal
                title={t('bag:label.update_shipping_partner').toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}>
                <p>
                    - {t('label.latest_at_update_sample')}: <strong>{moment(new Date()).format('DD/MM/YYYY')}</strong>
                </p>
                <p>
                    - {t('label.download_sample_file')}:
                    <strong>
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/${i18next.language}_file_update_shipping_partner.xlsx`}>
                            {' '}
                            {t('bag:label.file_update_shipping_partner')}
                        </a>
                    </strong>
                </p>
                <p className="mb-3">
                    - {t('label.maximum_file_size')}: <strong>5MB</strong>
                </p>
                <UploadFile
                    onChange={handleUpload}
                    accept=".xls,.xlsx"
                    fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    maxFileSize={5}
                    showUploadList={true}
                />
                {!lodash.isEmpty(messages) > 0 && (
                    <div className="pt-3">
                        {Object.keys(messages).map((key, index) => {
                            return (
                                <p
                                    key={index}
                                    className="a-text--red">
                                    - {renderError(key, messages[key])}
                                </p>
                            )
                        })}
                    </div>
                )}
            </Modal>
        </>
    )
}
