import {combineReducers} from 'redux';
import * as ACTION from './constants';

const consignServices = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_CONSIGN_SERVICES.SUCCESS: {
            return payload.services;
        }
        default: {
            return state;
        }
    }
};

const loadingConsignServices = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_CONSIGN_SERVICES.REQUEST: {
            return true;
        }
        case ACTION.FETCH_CONSIGN_SERVICES.SUCCESS:
        case ACTION.FETCH_CONSIGN_SERVICES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const services = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_SERVICES.SUCCESS: {
            return payload.services;
        }
        default: {
            return state;
        }
    }
};

const loadingServices = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SERVICES.REQUEST: {
            return true;
        }
        case ACTION.FETCH_SERVICES.SUCCESS:
        case ACTION.FETCH_SERVICES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const consignServiceGroups = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_CONSIGN_SERVICE_GROUPS.SUCCESS:
            return payload.service_groups;

        default:
            return state;
    }
};

const loadingConsignServiceGroups = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_CONSIGN_SERVICE_GROUPS.REQUEST:
            return true;

        case ACTION.FETCH_CONSIGN_SERVICE_GROUPS.SUCCESS:
        case ACTION.FETCH_CONSIGN_SERVICE_GROUPS.FAILED:
            return false;

        default:
            return state;
    }
};

const serviceGroups = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_SERVICE_GROUPS.SUCCESS:
            return payload.service_groups;

        default:
            return state;
    }
};

const loadingServiceGroups = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SERVICE_GROUPS.REQUEST:
            return true;

        case ACTION.FETCH_SERVICE_GROUPS.SUCCESS:
        case ACTION.FETCH_SERVICE_GROUPS.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    consignServices,
    loadingConsignServices,
    services,
    loadingServices,
    consignServiceGroups,
    loadingConsignServiceGroups,
    serviceGroups,
    loadingServiceGroups
});
