export const PACKING_PACKAGE = {
    REQUEST: 'BAGS.PACKING_PACKAGE.REQUEST',
    SUCCESS: 'BAGS.PACKING_PACKAGE.SUCCESS',
    FAILED: 'BAGS.PACKING_PACKAGE.FAILED',
};

export const UPDATE_WEIGHT_REAL = {
    REQUEST: 'BAGS.UPDATE_WEIGHT_REAL.REQUEST',
    SUCCESS: 'BAGS.UPDATE_WEIGHT_REAL.SUCCESS',
    FAILED: 'BAGS.UPDATE_WEIGHT_REAL.FAILED',
};
