export const FETCH_TEMPLATE_BAGS = {
    REQUEST: "FETCH_TEMPLATE_BAGS.REQUEST",
    SUCCESS: "FETCH_TEMPLATE_BAGS.SUCCESS",
    FAILED: "FETCH_TEMPLATE_BAGS.FAILED",
};

export const CREATE_TEMPLATE_BAG = {
    REQUEST: "CREATE_TEMPLATE_BAG.REQUEST",
    SUCCESS: "CREATE_TEMPLATE_BAG.SUCCESS",
    FAILED: "CREATE_TEMPLATE_BAG.FAILED",
};

export const UPDATE_TEMPLATE_BAG = {
    REQUEST: "UPDATE_TEMPLATE_BAG.REQUEST",
    SUCCESS: "UPDATE_TEMPLATE_BAG.SUCCESS",
    FAILED: "UPDATE_TEMPLATE_BAG.FAILED",
};

export const FETCH_TEMPLATE_BAG_DETAIL = {
    REQUEST: "FETCH_TEMPLATE_BAG_DETAIL.REQUEST",
    SUCCESS: "FETCH_TEMPLATE_BAG_DETAIL.SUCCESS",
    FAILED: "FETCH_TEMPLATE_BAG_DETAIL.FAILED",
};

export const RESET_ERROR = "RESET_ERROR";
export const CLEAR_STATE_TEMPLATE_BAG = "CLEAR_STATE_TEMPLATE_BAG";