import React from 'react';

const Mechanic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0)">
        <path className="c" d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.667A6.667,6.667,0,1,1,14.667,8,6.667,6.667,0,0,1,8,14.667Z" />
        <path className="c" d="M10.667,3.381A.667.667,0,1,0,10,4.533,4.017,4.017,0,0,1,12,8a.667.667,0,1,0,1.333,0,5.353,5.353,0,0,0-2.667-4.619Z" />
        <path className="c" d="M10.061,11.429A4,4,0,1,1,5.939,4.571a.667.667,0,1,0-.689-1.141,5.334,5.334,0,1,0,5.5,9.142.667.667,0,1,0-.689-1.142Z" />
        <path className="c" d="M8.667,3A.667.667,0,0,0,7.333,3V5.65a.168.168,0,0,1-.111.157,2.318,2.318,0,0,0-.733.423.166.166,0,0,1-.191.017l-.275-.158a.333.333,0,0,0-.455.121l-.333.578a.333.333,0,0,0,.122.455L5.63,7.4a.165.165,0,0,1,.08.174,2.3,2.3,0,0,0,0,.845.167.167,0,0,1-.081.175l-.277.16a.333.333,0,0,0-.121.455l.333.578a.333.333,0,0,0,.455.121L6.3,9.751a.167.167,0,0,1,.192.018,2.291,2.291,0,0,0,.73.426.168.168,0,0,1,.111.157v.315A.333.333,0,0,0,7.662,11h.667a.333.333,0,0,0,.338-.333V10.35a.168.168,0,0,1,.111-.157A2.325,2.325,0,0,0,9.5,9.774.167.167,0,0,1,9.7,9.757l1.971,1.15A.6.6,0,0,0,12,11a.667.667,0,0,0,.333-1.24L10.367,8.6a.167.167,0,0,1-.079-.174,2.3,2.3,0,0,0,0-.844.168.168,0,0,1,.081-.175l.278-.161a.333.333,0,0,0,.121-.455l-.333-.578a.333.333,0,0,0-.455-.121l-.277.16a.167.167,0,0,1-.192-.018A2.313,2.313,0,0,0,8.764,5.8a.147.147,0,0,1-.1-.137ZM8,9A1,1,0,1,1,9,8,1.011,1.011,0,0,1,8,9Z" />
      </g>
    </g>
  </svg>
);

export default Mechanic;
