import React, { Component } from 'react';
import { Layout } from 'antd';

import { DELIVERY_OBJECT } from './../../../constants';
import pageService from './../../../../Common/Page/pageService';
import Sound from './../../../../Sound';
import { t } from './../../../../../system/i18n';

import FormDeliveryNote from './../../../Create/components/FormDeliveryNote';
import FormDeliveryObject from './../../../Create/components/FormDeliveryObject';

class Create extends Component {
    handleChangeInput = data => {
        const { input, onChangeInput } = this.props;

        onChangeInput({
            ...input,
            ...data
        });
    };

    componentDidMount() {
        const { checkOnbeforeUnload, setMenuActive } = this.props;

        pageService.setTitle(t('create_delivery_note_return'), <Sound />);
        setMenuActive('delivery-notes/return/create');

        window.onbeforeunload = checkOnbeforeUnload;
    }

    componentWillUnmount() {
        this.props.clearState();
        window.onbeforeunload = null;
    }

    render() {
        const {
            barcodes,
            input
        } = this.props;

        return (
            <Layout className="create-delivery-note has-sub-sider">
                <FormDeliveryNote
                    canChangeInput={!barcodes.length}
                    input={input}
                    scanBarcodeByCustomer={!!input.object}
                    type={DELIVERY_OBJECT.RETURN}
                    onChange={this.handleChangeInput}
                />

                <FormDeliveryObject
                    input={input}
                    object={DELIVERY_OBJECT.RETURN}
                />
            </Layout>
        );
    }
}

export default Create;
