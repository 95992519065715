import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let histories = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_HISTORIES.SUCCESS:
            return action.payload.barcode_scannings.map(item => ({...item.barcode_scanning, ...lodash.omit(item, 'barcode_scanning')}));

        default:
            return state;
    }
};

let pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_HISTORIES.SUCCESS:
            return action.payload.pagination;

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_HISTORIES.REQUEST:
            return true;

        case ACTION.FETCH_HISTORIES.SUCCESS:
        case ACTION.FETCH_HISTORIES.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    histories,
    pagination,
    loading,
});
