import React, {Component} from "react";
import {connect} from 'react-redux';
import LocationPackageForm from './components/';
import {fetchCountry, fetchDistrict, fetchProvince, fetchWard, setCurrentCity, setCurrentCountry, setCurrentDistrict} from '../actions';
import {fetchPropertySuggest} from './../../Property/actions';
import lodash from 'lodash';
import {getDefaultCountry} from "../selectors";


const mapStateToProps = (state, props) => ({
    errors: lodash.get(props, "errors", {}),
    packageData: props.packageData,
    countries: [...state.location.listCountry],
    cities: [...state.location.listProvince],
    districts: [...state.location.listDistrict],
    wards: [...state.location.listWard],
    isLoadingLocation: lodash.get(state, "location.loading", false),
    isLoadingProperty: lodash.get(state, "property.loading", false),
    properties: lodash.get(state, 'property.list', []),
    defaultCountry: getDefaultCountry(state)
});

const mapDispatchToProps = dispatch => ({
    fetchCountry: id => {
        dispatch(fetchCountry({
            id,
            iChooseDefault: true
        }));
    },
    fetchProvince: id => {
        dispatch(fetchProvince({
            id,
            iChooseDefault: true
        }));
    },
    fetchDistrict: id => {
        dispatch(fetchDistrict({
            id,
            iChooseDefault: true
        }));
    },
    fetchWard: id => {
        dispatch(fetchWard({
            id,
            iChooseDefault: true
        }));
    },
    fetchProperty: () => {
        dispatch(fetchPropertySuggest());
    },
    setCurrentCountry: idCountry => {
        dispatch(setCurrentCountry(idCountry));
    },
    setCurrentCity: idCity => {
        dispatch(setCurrentCity(idCity));
    },
    setCurrentDistrict: idDistrict => {
        dispatch(setCurrentDistrict(idDistrict))
    }
});
class LocationPackageFormContainer extends Component {
    render() {
        return (
            <LocationPackageForm {...this.props}/>
        )
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationPackageFormContainer);
