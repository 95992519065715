import React, { useEffect, useState } from 'react'
import { Layout, Spin } from 'antd'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { router } from '../../../../system/routing'

import PictureWall from './PictureWall'
import { withTranslation } from 'react-i18next'

const UploadCommentImages = ({ location, t }) => {
    const [orderId, setOrderId] = useState()
    const [isTerminalComment, setIsTerminalComment] = useState()
    useEffect(() => {
        const locationQuery = router.getLocationQuery()
        setOrderId(locationQuery.orderId)
        setIsTerminalComment(Number(locationQuery.isTerminalComment))
        router.replace(`${location.pathname}?orderId=${locationQuery.orderId}&isTerminalComment=${locationQuery.isTerminalComment}`)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout className="upload-damaged-images">
            {orderId && isTerminalComment !== undefined ? (
                <PerfectScrollbar
                    options={{
                        wheelPropagation: false,
                    }}>
                    <PictureWall
                        orderId={orderId}
                        isTerminalComment={isTerminalComment}
                    />
                </PerfectScrollbar>
            ) : (
                t('package:order_invalid')
            )}
        </Layout>
    )
}

export default withTranslation()(UploadCommentImages)
