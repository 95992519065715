/* eslint-disable */
import React, {Component} from 'react';
import {Alert, Button, Icon, Layout, Select} from 'antd';

const {Content} = Layout;
const Option = Select.Option;
const Sort = <div className="sort"><Icon type="caret-up" className="active"/><Icon type="caret-down"/></div>;

class Inventory extends Component {

    render() {
        return (
            <Content
                className={`a-content pl-0 a-barcode-scan a-content--page-list min-height-not-header gflex gflex-direction-column`}>

                <div className="a-content a-content--page-list gflex gflex-direction-column a-flex-1">
                    <div className="a-content__top a-content__top--page-list">
                        <div className="a-content--page-list__title">
                            <h3>Lịch sử quét mã(233)</h3>
                        </div>
                        <div className="a-content--page-list__top-action">
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost><Icon type="sync"/>Làm mới lịch sử quét</Button>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3">Đối
                                soát</Button>
                        </div>
                    </div>
                    <div className="a-content__body bg-white a-content__body--page-list ">
                        <Alert className="mb-4" message="Warning" type="warning" showIcon/>
                        <div className="a-table--responsive">
                            <table className="a-table a-table--barcode-scan">
                                <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã kiện</th>
                                    <th>Thời Gian Kiểm kê {Sort}</th>
                                    <th>Kho kiểm kê</th>
                                    <th>Kho đích</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody className="error">
                                <tr className="data">
                                    <td>4</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="5">
                                                <span className="a-text--black-45">
                                                <Icon type='close-circle' theme="filled" className="a-text--red mr-2"/>
                                                Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>6</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>7</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>8</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>9</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody className="warning">
                                <tr className="data">
                                    <td>10</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="5">
                                                <span className="a-text--black-45">
                                                    <Icon type='info-circle' theme="filled"
                                                          className="a-text--orange mr-2"/>
                                                    Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>11</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>12</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>13</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>14</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>15</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>16</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>17</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>18</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>19</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>20</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>21</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>22</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>23</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>24</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Trường hợp empty chưa chọn kho
                    <div className="a-content a-content--package a-package__scan-empty a-flex-1 ml-0">
                        <img src={Background} alt=""/>
                        <span>Chưa có dữ liệu <br/>quét mã</span>
                    </div>
                    */}
                </div>

            </Content>

        );
    }
}

export default Inventory;
