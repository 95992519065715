import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import lodash, { get, isEmpty, map } from 'lodash'
import { withTranslation } from 'react-i18next'
import { Col, Divider, Row, Icon as IconAntd, Button } from 'antd'

import { dateFormatter } from './../../../Common/services/format'
import { linkHTC, renderLengthWidthHeight } from './../../helper'
import authService from './../../../Auth/authService'
import PERMISSIONS from './../../../Auth/permissions'
import { NOTE_TYPES } from './../../../Notes/constants'

import PackageServices from './../../FormPackageDetail/components/PackageServices'
import EditNoteModal from './../../../Notes/components/EditNoteModal'
import Link from './../../../../system/routing/Link'

import Icon from './../../../../components/icons/Icon'
import { getVar, showAliasAgency } from '../../../../system/support/helpers'
import { getState } from '../../../../system/store'
import permissions from './../../../Auth/permissions'
import { HTC_ORDER_DETAIL_URL_NH } from '../../constants'
import { PACKAGE_ROUTINGS } from '../constants'

class OrderInfo extends React.PureComponent {
    state = {
        editNoteModalVisible: false,
        isExpand: false,
    }

    renderLengthWidthHeight = () => {
        const { packageData } = this.props
        if (packageData.length && packageData.width && packageData.height) {
            return renderLengthWidthHeight(packageData)
        }
        return '--'
    }

    getTranslatedStatusDamaged = status => this.props.t(`package:damaged_statuses.${status}`)

    handleCreateNoteButtonClick = () => {
        this.setState({
            editNoteModalVisible: true,
        })
    }

    handleCancelEditNoteModal = () => {
        this.setState({
            editNoteModalVisible: false,
        })
    }

    handleSuccessAddNote = () => {
        const { onSuccessAddNote } = this.props

        onSuccessAddNote()
        this.handleCancelEditNoteModal()
    }

    render() {
        const { editNoteModalVisible, isExpand } = this.state
        const { packageData, noneData, t } = this.props
        const order = lodash.get(packageData, 'order', {})
        let services = lodash.get(packageData, 'services', [])
        services.map(serviceItem => serviceItem.alias)
        services = lodash.compact(services)
        const shipping_partner = packageData.shipping_partner
        const status_package_warehouse = packageData.status_warehouse
            ? `${t(`package:action.${lodash.get(packageData, 'status_warehouse', '')}`)} (${dateFormatter.dateTime(lodash.get(packageData, 'time_scan', ''))})`
            : '--'
        const showAlias = showAliasAgency(getState('setting.agencySetting'), getState('setting.setting'), permissions.CONFIG_SHOW_AGENCY_ALIAS)
        const linkHtc = linkHTC(get(packageData, 'agency.code'))
        const orderCode = get(packageData, 'order.code', '')
        const packageRoutings = lodash.get(packageData, 'package_routings', []).filter(item => item.data.action === 'yes')
        const packageRoutingsContent = map(packageRoutings, item => t(`package:package_routing.${item.code}`))
        const trackingNoLastMile = get(packageData, 'last_mile_shipment.tracking_no')
        const lastMileCarrier = get(packageData, 'last_mile_carrier', {})
        const estimatePackages = getVar(packageData, 'batch_order.estimate_packages', [])
        let totalEstimatedQuantityPackage = 0
        estimatePackages.map(item => (totalEstimatedQuantityPackage += Number(getVar(item, 'quantity', 0))))
        return (
            <Row
                gutter={{ lg: 24, xl: 48 }}
                className="a-block-info pb-3">
                <Col
                    lg={{ span: 16 }}
                    xl={{ span: 19 }}
                    xxl={{ span: 18 }}>
                    <div className="a-block-info bg-gray info--detail pb-0">
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="ml-0 mr-0"
                            type="flex">
                            <Col
                                lg={{ span: 24 }}
                                className="info--title gflex gjustify-space-between">
                                <h4>{t('package:detail.package_info')}</h4>
                                {authService.can(PERMISSIONS.ATTENTION_MANAGE) && (
                                    <span
                                        className="a-text--cursor-pointer a-text--blue a-text--underline _btn-create-note"
                                        onClick={this.handleCreateNoteButtonClick}>
                                        {t('package:create.note')}
                                    </span>
                                )}
                            </Col>
                            <Col
                                className="info--group _code-entry"
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}>
                                <label className="info--label _label">{t('package:code_entry')}</label>
                                <span className="info--value _value">{lodash.get(packageData, 'package_detail.code_entry') || '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _barcode">
                                <label className="info--label _label">{t('package:create.barcode')}</label>
                                <span className="info--value _value">{lodash.get(packageData, 'barcode') || '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _order">
                                <label className="info--label _label">{t('package:label.order')}</label>
                                <span className={'_value'}>
                                    {lodash.get(order, 'code') ? (
                                        <a
                                            href={linkHtc ? linkHtc + '/order/detail/default/' + orderCode : lodash.get(order, 'm1_url') || '#top'}
                                            rel="noopener noreferrer"
                                            target={lodash.get(order, 'm1_url') ? '_blank' : '_self'}>
                                            {lodash.get(order, 'code')}
                                        </a>
                                    ) : (
                                        '--'
                                    )}
                                </span>

                                {lodash.get(order, 'agency_identity_code') && (
                                    <div className="a-text--nowrap a-text--gray a-text--fz-12">({lodash.get(order, 'agency_identity_code', '--')})</div>
                                )}
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _agency">
                                <label className="info--label _label">Agency ({t('package:label.agency')})</label>
                                <span className="info--value _value">
                                    {lodash.get(packageData, 'agency.name') || '--'}{' '}
                                    {lodash.get(packageData, 'agency.identify_name') && showAlias
                                        ? `(${lodash.get(packageData, 'agency.identify_name')})`
                                        : null}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _id_order">
                                <label className="info--label _label">{t('package:label.id_order')}</label>
                                <span className="info--value _value">
                                    {lodash.get(order, 'code') ? (
                                        <a
                                            href={lodash.get(order, 'm1_url') || '#top'}
                                            rel="noopener noreferrer"
                                            target={lodash.get(order, 'm1_url') ? '_blank' : '_self'}>
                                            {lodash.get(packageData, 'order.id_order_m2')}
                                        </a>
                                    ) : (
                                        '--'
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="ml-0 mr-0"
                            type="flex">
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _status_package_warehouse">
                                <label className="info--label _label">{t('package:label.status_package_warehouse')}</label>
                                <span className="info--value _value">{status_package_warehouse}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _status_transport">
                                <label className="info--label _label">{t('package:label.status_transport')}</label>
                                <span className="info--value _value">
                                    {packageData.status_transport ? t('package:status_transport.' + lodash.get(packageData, 'status_transport', '')) : '--'}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _bag">
                                <label className="info--label _label">{t('package:label.bag')}</label>
                                <span className="info--value _value">
                                    <Link
                                        to={'bags.detail'}
                                        params={{ id: lodash.get(packageData, 'bag.code') }}
                                        isNewTab={true}>
                                        {lodash.get(packageData, 'bag.code', '')}
                                    </Link>
                                </span>
                            </Col>
                        </Row>
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="ml-0 mr-0"
                            type="flex">
                            <Col
                                className="info--group _create_time"
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}>
                                <label className="info--label _label">{t('package:label.create_time')}</label>
                                <span className="info--value _value">{dateFormatter.dateTime(lodash.get(packageData, 'created_at', ''))}</span>
                            </Col>
                            {!lodash.isNil(shipping_partner) && (
                                <Col
                                    className="info--group _shipping_partner"
                                    lg={{ span: 12 }}
                                    xl={{ span: 16 }}>
                                    <label className="info--label _label_shipping_partner">{t('package:label.shipping_partner')}</label>
                                    <span className={'info--value _shipping_partner _shipping_partner_' + lodash.get(shipping_partner, 'id')}>
                                        {lodash.get(shipping_partner, 'name') + ' (' + lodash.get(shipping_partner, 'code') + ')' || '--'}
                                    </span>
                                </Col>
                            )}
                        </Row>
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="ml-0 mr-0"
                            type="flex">
                            <Col
                                className="info--group _note-status"
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}>
                                <label className="info--label _label">{t('status_note')}</label>
                                <span className="info--value _value">{lodash.get(packageData, 'package_detail.note_status', '--')}</span>
                            </Col>
                            <Col
                                className="info--group _return-address"
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}>
                                <label className="info--label _label">{t('return_address')}</label>
                                <span className="info--value _value">{lodash.get(packageData, 'package_detail.return_address', '--')}</span>
                            </Col>
                        </Row>
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="ml-0 mr-0"
                            type="flex">
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _brand_product">
                                <label className="info--label _label">{t('package:detail.brand_product')}</label>
                                <span className="info--value _value">{lodash.get(packageData, 'product_group.name')}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _attribute">
                                <label className="info--label _label">{t('package:label.attribute')}</label>
                                <span className="info--value _value">
                                    {lodash.map(packageData.properties, (property, index) => (
                                        <Fragment key={property.id}>
                                            <Icon
                                                className="mr-2"
                                                type={property.icon}
                                            />
                                            {property.name}
                                            {index === packageData.properties.length - 1 ? null : ', '}
                                        </Fragment>
                                    )) || noneData}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                className="info--group _handling_indicate">
                                <label className="info--label _label">{t('package:label.handling_indicate')}</label>
                                <span className="info--value _value">{packageRoutingsContent.join(', ')}</span>
                            </Col>
                            {!!trackingNoLastMile && (
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="info--group _lastmile_tracking_no">
                                    <label className="info--label _label">{t('package:label.lastmile_tracking_no')}</label>
                                    <span className="info--value _value">{trackingNoLastMile}</span>
                                </Col>
                            )}
                            {!isEmpty(lastMileCarrier) && (
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="info--group _last_mile_carrier">
                                    <label className="info--label _label">{t('package:label.last_mile_carrier')}</label>
                                    <span className="info--value _value">{`${get(lastMileCarrier, 'name')} (${get(lastMileCarrier, 'code')})`}</span>
                                </Col>
                            )}

                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 16 }}
                                className="info--group _note">
                                <label className="info--label _label">{t('package:label.note')}</label>
                                <span className="info--value _value">
                                    {lodash.get(packageData, 'note', '') ? lodash.get(packageData, 'note', '') : noneData}
                                </span>
                            </Col>
                        </Row>
                        <div className="info--title iscollapse gflex galign-center gjustify-center mt-0">
                            <div
                                className="title--collapse"
                                style={{ backgroundColor: '#FBFBFB' }}>
                                <span
                                    onClick={() => {
                                        this.setState({ isExpand: !isExpand })
                                    }}
                                    className={'_btn_expand'}>
                                    {t(isExpand ? 'btn.collapse' : 'btn.expand')}
                                    <IconAntd
                                        type={isExpand ? 'shrink' : 'arrows-alt'}
                                        className="icon-collapse"
                                    />
                                </span>
                            </div>
                        </div>
                        {isExpand && (
                            <Row
                                gutter={{ lg: 24, xl: 32 }}
                                className="ml-0 mr-0"
                                type="flex">
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="info--group _estimate_weight_order">
                                    <label className="info--label _label">HSCode</label>
                                    <span className="info--value _value">{getVar(packageData, 'order.hs_code', '---')}</span>
                                </Col>
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="info--group _estimate_weight_order">
                                    <label className="info--label _label">{t('package:detail.estimate_weight_order')}</label>
                                    <span className="info--value _value">{getVar(packageData, 'batch_order.estimate_weight_order', '---')}</span>
                                </Col>
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="info--group _estimate_package_total">
                                    <label className="info--label _label">{t('package:detail.estimate_package_total')}</label>
                                    <span className="info--value _value">{lodash.isEmpty(estimatePackages) ? '---' : totalEstimatedQuantityPackage}</span>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <Row
                        gutter={{ lg: 24, xl: 32 }}
                        className="a-block-info bg-gray info--detail amt-16">
                        <Col
                            lg={{ span: 24 }}
                            className="info--title">
                            <h4>{t('package:detail.package_param')}</h4>
                        </Col>
                        <Col
                            lg={{ span: 12 }}
                            xl={{ span: 6 }}
                            className="info--group _weight">
                            <label className="info--label _label">{t('package:label.weight')}</label>
                            <span className="info--value _value">
                                {lodash.round(lodash.get(packageData, 'weight_net', 0), 4) || '--'}
                                {lodash.get(packageData, 'weight_net') ? 'kg' : ''}
                            </span>
                        </Col>
                        <Col
                            lg={{ span: 12 }}
                            xl={{ span: 6 }}
                            className="info--group _volume">
                            <label className="info--label _label">{t('package:label.volume')}</label>
                            <span className="info--value _value">
                                {lodash.get(packageData, 'volume', '0') * 1000000 || '--'}
                                {lodash.get(packageData, 'volume') ? 'cm3' : ''}
                            </span>
                        </Col>
                        <Col
                            lg={{ span: 12 }}
                            xl={{ span: 6 }}
                            className="info--group _weight_converted">
                            <label className="info--label _label">{t('package:label.weight_converted')}</label>
                            <span className="info--value _value">
                                {lodash.round(lodash.get(packageData, 'weight_converted', 0), 4) || '--'}
                                {lodash.get(packageData, 'weight_converted') ? 'kg' : ''}
                            </span>
                        </Col>
                        <Col
                            lg={{ span: 12 }}
                            xl={{ span: 6 }}
                            className="info--group _weight_box">
                            <label className="info--label _label">{t('package:label.weight_box')}</label>
                            <span className="info--value _value">
                                {lodash.get(packageData, 'weight_box', '--') || '--'}
                                {lodash.get(packageData, 'weight_box') ? 'kg' : ''}
                            </span>
                        </Col>
                        <Col lg={{ span: 24 }}>
                            <Row gutter={{ lg: 24, xl: 32 }}>
                                <Col
                                    lg={{ span: 12 }}
                                    xl={{ span: 6 }}
                                    className="info--group _id_box_size">
                                    <label className="info--label _label">{t('package:label.id_box_size')}</label>
                                    <span className="info--value _value">{lodash.get(packageData, 'box_size.code', '--')}</span>
                                </Col>
                                <Col
                                    className="info--group _size_drc"
                                    lg={{ span: 12 }}
                                    xl={{ span: 6 }}
                                    xxl={{ span: 6 }}>
                                    <label className="info--label _label">{t('package:label.size_drc')}</label>
                                    <span className="info--value _value">{this.renderLengthWidthHeight()}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg={{ span: 8 }}
                    xl={{ span: 5 }}
                    xxl={{ span: 6 }}
                    className="a-package--service _services only-view">
                    <PackageServices services={services} />
                </Col>

                <EditNoteModal
                    id={lodash.get(packageData, 'id')}
                    object={NOTE_TYPES.PACKAGE}
                    visible={editNoteModalVisible}
                    onCancel={this.handleCancelEditNoteModal}
                    onOk={this.handleSuccessAddNote}
                />
            </Row>
        )
    }
}

OrderInfo.defaultProps = {
    package: {},
    loading: false,
}

OrderInfo.propTypes = {
    package: PropTypes.object,
    loading: PropTypes.bool,
}

export default withTranslation()(OrderInfo)
