import React, { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';

const { Option } = Select;

const NoticeStageActionsSelect = ({ actions, noticeStageId, t, ...other }) => {
    const [filteredActions, setFilteredActions] = useState([]);

    useEffect(() => {
        const filteredActions = actions.filter(action => action.id_notice_stage === noticeStageId);

        setFilteredActions(filteredActions);
    }, [actions, noticeStageId]);

    return (
        <Select {...other} optionLabelProp="label">
            {filteredActions.map(action => (
                <Option
                    key={action.id}
                    label={(
                        <Tooltip title={t(`notice:action_descriptions.${action.code_description}`)}>
                            {t(`notice:actions.${action.action}`)}
                        </Tooltip>
                    )}
                    value={action.id}
                >
                    {t(`notice:actions.${action.action}`)}
                </Option>
            ))}
        </Select>
    );
};

export default NoticeStageActionsSelect;
