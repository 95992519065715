/* eslint-disable */

import React from 'react';
import FormSearch from './components/FormSearch';
import ListPackage from './components/ListPackage';
import {Layout} from "antd";
const Content = Layout.Content;
class HistoryCheckPackageContainer extends React.Component{
    render() {
        return (
            <Content>
                <FormSearch/>
                <ListPackage/>
            </Content>
        );
    }
}
export default HistoryCheckPackageContainer;