/* eslint-disable */
import React, {Component} from 'react';
import {Icon} from "antd";
import {Form} from "antd/lib/form";
class InlineEditList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconedit: true,
            iconremove: false,
            iconsave: false,
            labelvalue: true,
            input: false
        }
    }

    onClickEditInline = () => {
        this.setState({
            iconedit: !this.state.iconedit,
            iconremove: !this.state.iconremove,
            iconsave: !this.state.iconsave,
            labelvalue: !this.state.labelvalue,
            input: !this.state.input,
        })
    }

    render() {
        const {iconedit, iconremove, iconsave, labelvalue, input} = this.state;
        const {label,value} = this.props;
        return (
            <div className="a-inline-edit">
                <label className="a-inline-edit--label">{label}
                    <Icon type="edit" onClick={this.onClickEditInline}
                          className={`icon-inline ${iconedit ? '' : 'a-hidden'} `}/>
                    <Icon type="close" onClick={this.onClickEditInline} className="icon-inline"
                          className={`icon-inline ${iconremove ? '' : 'a-hidden'} `}/>
                    <Icon type="check" onClick={this.onClickEditInline} className="icon-inline ml-0"
                          className={`icon-inline ${iconsave ? '' : 'a-hidden'} `}/>
                </label>
                <div className={`a-inline-edit--value ${labelvalue ? '' : 'a-hidden'}`}>{value}</div>
                <div className={`a-inline-edit--input ${input ? '' : 'a-hidden'}`}>
                    <ul className="property--package">
                        <li className="list active">
                            <a href="#" className="link">Điện tử</a>
                        </li>
                        <li className="list">
                            <a href="#" className="link">Dễ vỡ</a>
                        </li>
                        <li className="list">
                            <a href="#" className="link">Hóa chất</a>
                        </li>
                        <li className="list active">
                            <a href="#" className="link">Giá trị </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default InlineEditList;