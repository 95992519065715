import {all, fork} from 'redux-saga/effects';
import createPackageViaScanWatcher from './CreatePackageViaScan/sagaWatcher';
import createPackageSameBarcodeWatcher from './CreatePackageSameBarcode/sagaWatcher';
import createPackageSameCodeWatcher from './CreatePackageSameCode/sagaWatcher';
import unlinkOrderWatcher from './UnlinkOrder/sagaWatcher';
import deletePackageWatcher from './DeletePackage/sagaWatcher';
import linkOrderPackageWatcher from './LinkOrderPackage/sagaWatcher';
import updatePackageDimensionWatcher from './UpdatePackageDimension/sagaWatcher';
import updateWeightPackageWatcher from './UpdateWeightPackage/sagaWatcher';
import updateAlonePackage from './UpdateAlonePackage/sagaWatcher';
import updateRelatedPackageWatcher from './UpdateRelatedPackage/sagaWatcher';
import linkStampPackageWatcher from './LinkStamp/sagaWatcher';
import listPackageWatcher from './ListPackage/sagaWatcher';
import packageDetailWatcher from './PackageDetail/sagaWatcher';
import updatePackageParamsWatcher from './UpdatePackageParams/sagaWatcher';
import changePackageTransportStatus from './ChangePackageTransportStatus/sagaWatcher';
import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield all([
        fork(createPackageViaScanWatcher),
        fork(createPackageSameBarcodeWatcher),
        fork(createPackageSameCodeWatcher),
        fork(unlinkOrderWatcher),
        fork(deletePackageWatcher),
        fork(linkOrderPackageWatcher),
        fork(updatePackageDimensionWatcher),
        fork(updateAlonePackage),
        fork(updateWeightPackageWatcher),
        fork(updateRelatedPackageWatcher),
        fork(linkStampPackageWatcher),
        fork(updatePackageParamsWatcher),
        fork(listPackageWatcher),
        fork(packageDetailWatcher),
        fork(changePackageTransportStatus),
    ]);
    yield takeLatest(ACTION.CREATE_PACKAGE_BY_SCAN.REQUEST, saga.createByBarcode);
    yield takeLatest(ACTION.CREATE_PACKAGE_BY_SCAN.FAILED, saga.createByBarcodeFailed);
    yield takeLatest(ACTION.CREATE_PACKAGE_BY_SCAN.SUCCESS, saga.createByBarcodeSuccess);
}
