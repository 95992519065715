import { Select } from 'antd'
import { map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../system/i18n'
import apiService from '../apiService'

const { Option } = Select

const SelectShippingLastmile = props => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        fetchdata()
    }, [])

    const fetchdata = () => {
        setLoading(true)
        apiService
            .getShippingLastmile()
            .then(res => {
                setData(res.data.lastmile_partners)
            })
            .catch(error => {})
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Select
            {...props}
            className={'a-select a-select--w-100 _select_shipping_lastmile a-select a-select--search-content'}
            // placeholder={t('agency:placeholder.choose_agency')}
            dropdownClassName={'_dropdown-lastmile'}
            showSearch={false}
            loading={loading}>
            {map(data, (shipping, index) => (
                <Option
                    className="_agency-item"
                    key={index}
                    value={shipping.id}>
                    {shipping.name}
                </Option>
            ))}
        </Select>
    )
}
export default SelectShippingLastmile
