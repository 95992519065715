import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as selectors from './selectors';
import * as commonActions from './../../Common/actions';

import ListShippingPartner from './components';

const mapStateToProps = state => ({
    paginations: {
        international: {
            total: selectors.getState(state, 'internationals.internationals.length')
        },
        domestic: {
            total: selectors.getState(state, 'domestics.domestics.carriers.length', 0) + selectors.getState(state, 'domestics.domestics.direct_partners.length', 0)
        }
    }
});

const mapDispatchToProps = {
    setMenuActive: commonActions.setMenuActive
};

const ListShippingPartnerContainer = props => (
    <ListShippingPartner {...props} />
);

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListShippingPartnerContainer));
