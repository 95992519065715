/* eslint-disable */
import React, {Component} from 'react';
import lodash from 'lodash';
import {Table} from 'antd';
import {LIST_PROPERTY_COLUMN} from "../../constants"
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class List extends Component {
	getColumns() {
		let width = {
			action: 24,
		};

		return lodash.values(LIST_PROPERTY_COLUMN).map(column => {
			return {
				key: column,
				dataIndex: column,
				title: column !== LIST_PROPERTY_COLUMN.ACTION ? this.props.t('config:column.' + column) : '',
				width: width[column],
				render: (text, row) => {
					let active = row.active ? 'a-notification-dot a-notification-dot--table active' : 'a-notification-dot a-notification-dot--table unactive';
					let listClass = {
						[LIST_PROPERTY_COLUMN.NAME]: active,
						action: 'a-text--right'
					};
					return {
						children: text,
						props:
							{
                                className: listClass[column] ? (listClass[column] + ' _row-' + column) : (' _row-' + column)
							}
						,
					}
						;
				}
			}

		})
	}

	render() {
		const {listProperty, loading} = this.props;
		return (
			<div className="a-content__body bg-white a-content__body--page-list  height-100">
                <Table className={"a-table--antd a-table--antd-scroll-x _a-table-property"}
                       columns={this.getColumns()}
                       dataSource={listProperty}
                       pagination={false}
                       loading={loading}
                       rowKey={(record) => {
					       return record.key
				       }}
                       scroll={{x: 'fit-content'}}
				/>
			</div>
		)
	}
}

List.defaultProps = {
	listProperty: [],
	loading: false
};

List.propTypes = {
	listProperty: PropTypes.array,
	loading: PropTypes.bool,
};
export default withTranslation()(List);
