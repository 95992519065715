import React, {useEffect, useState} from 'react';
import {Input, notification} from "antd";
import lodash from "lodash";
import apiService from "../../apiService";

function CustomerNotice(props) {
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [noteToCustomer, setNoteToCustomer] = useState('');
    useEffect(() => {
        setNoteToCustomer(lodash.get(props, "bag.note_to_customer"))
    }, [props]);

    function handleChangeInput(e) {
        setText(e.target.value);
    }

    function sendNotice() {
        const bagId = lodash.get(props, "bag.id");
        setLoading(true);
        apiService.sendNoticeToCustomer(bagId, {comment: text}).then(res => {
            notification.success({message: "Thêm thông báo cho khách hàng thành công!"})
            setNoteToCustomer(noteToCustomer + " " + text);
            setText('');
        }).catch((err) => {
            notification.error({message: "Thêm thông báo cho khách hàng không thành công!"})
        }).finally(() => setLoading(false));
    }

    return (
        <>
            <div className="a-chatbox--chat-input  mt-5">
                <Input
                    placeholder={"Nhập vào nội dung thông báo"}
                    className="a-input a-input--chatbox apr-36 aflex-1 _chat_input"
                    onChange={handleChangeInput}
                    value={text}
                    onPressEnter={sendNotice}
                    loading={loading.toString()}
                    disabled={loading}
                />
            </div>
            {
                noteToCustomer && <div className="list-chat-checking-bag pb-5">
                    <div className="a-chatbox--log user _chat_item">
                        <div className="a-chatbox--log--detail">
                            <span className="detail">{noteToCustomer}</span>
                        </div>
                    </div>
                    <div className={'empty-row'}/>
                </div>
            }
        </>
    )
}

export default CustomerNotice;