export const NOTE_TYPES = {
    CUSTOMER: 'customer',
    ORDER: 'order',
    PACKAGE: 'package',
    TRACKING: 'tracking'
};
export const GET_NOTES = {
    REQUEST: 'NOTE.GET_NOTES.REQUEST',
    SUCCESS: 'NOTE.GET_NOTES.SUCCESS',
    FAILED: 'NOTE.GET_NOTES.FAILED'
};
export const ADD_NOTE = {
    REQUEST: 'NOTE.ADD.REQUEST',
    SUCCESS: 'NOTE.ADD.SUCCESS',
    FAILED: 'NOTE.ADD.FAILED',
};
export const UPDATE_NOTE = {
    REQUEST: 'NOTE.UPDATE.REQUEST',
    SUCCESS: 'NOTE.UPDATE.SUCCESS',
    FAILED: 'NOTE.UPDATE.FAILED',
};
export const DELETE_NOTE = {
    REQUEST: 'NOTE.DELETE.REQUEST',
    SUCCESS: 'NOTE.DELETE.SUCCESS',
    FAILED: 'NOTE.DELETE.FAILED',
};
export const RESET_NOTES = 'RESET_NOTES';
export const FETCH_PACKAGE_DETAIL = {
    REQUEST: 'FOLLOW.FETCH_PACKAGE_DETAIL.REQUEST',
    SUCCESS: 'FOLLOW.FETCH_PACKAGE_DETAIL.SUCCESS',
    FAILED: 'FOLLOW.FETCH_PACKAGE_DETAIL.FAILED',
};

export const CLEAR_SUGGEST_ORDER = 'CLEAR_SUGGEST_ORDER';
export const SET_SUGGEST_CUSTOMER_ID = 'SET_SUGGEST_CUSTOMER_ID';
export const SET_SUGGEST_ORDER_ID = 'SET_SUGGEST_ORDER_ID';
export const CLEAR_SUGGEST_CUSTOMER = 'CLEAR_SUGGEST_CUSTOMER';

export const STAGES = {
    CREATE_PACKAGE_BY_BARCODE: 'create_package_by_barcode', // Tạo kiện
    SCANNING_PACKAGE: 'scanning_package', // Xuất nhập kho (QC, Kho PP)
    CHECKING_PACKAGE: 'checking_package', // Kiểm hàng
    ATTACH_PACKAGE_TO_BAG: 'attach_package_to_bag', // Đóng bao
    ATTACH_PACKAGE_TO_DELIVERY_NOTE: 'attach_package_to_delivery_note', // Kho phân phối
    DETACH_PACKAGE: 'detach_package', // Kho phân phối
};
