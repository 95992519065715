import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { trans } from '../../../../../system/i18n'
import { getVar } from '../../../../../system/support/helpers'
import { isEmpty } from 'lodash'

const PACKING_REQUEST_ACTION = {
    CREATE: 'PACKAGING_REQUEST.CREATE',
    CHANGE_STATUS: 'PACKAGING_REQUEST.CHANGE_STATUS',
    UPDATE: 'PACKAGING_REQUEST.UPDATE',
}

class PackingRequestLogMessage extends React.PureComponent {
    render() {
        const { log } = this.props
        const { payload } = log
        switch (log.action) {
            case PACKING_REQUEST_ACTION.CREATE:
                return trans('bag:log.PACKAGING_REQUEST_CREATE')
            case PACKING_REQUEST_ACTION.CHANGE_STATUS:
                return trans('bag:log.PACKAGING_REQUEST_CHANGE_STATUS', {
                    status: <b>{trans(`bag:bag_request_status.${getVar(payload, 'status')}`)}</b>,
                })
            case PACKING_REQUEST_ACTION.UPDATE:
                const shippingPartner = getVar(payload, 'shipping_partner', {})
                if (isEmpty(shippingPartner)) {
                    return trans('bag:log.PACKAGING_REQUEST_UPDATE_PACKAGE')
                } else {
                    return trans('bag:log.PACKAGING_REQUEST_UPDATE_SHIPPING_PARTNER')
                }

            default:
                return log.action
        }
    }
}

PackingRequestLogMessage.defaultProps = {
    log: {},
}

PackingRequestLogMessage.propTypes = {
    log: PropTypes.object,
}

export default withTranslation()(PackingRequestLogMessage)
