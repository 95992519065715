import React from 'react';
import clsx from 'clsx';
import { Button, Layout } from 'antd';
import { get } from 'lodash';

import Main from './Main';
import ReExecutions from './ReExecutions';
import SearchableComponent from './../../../Common/components/SearchableComponent';
import Tabs from './../../../Common/components/Tabs/Tabs';
import TabPane from './../../../Common/components/Tabs/TabPane';

const { Content } = Layout;

class ShippingFee extends SearchableComponent {
    state = {
        editModalVisibles: {
            main: 0,
            're-execution': 0
        }
    };

    handleCreateButtonClick = () => {
        let { match: { params: { type } } } = this.props;

        if (!['main', 're-execution'].includes(type)) {
            type = 'main';
        }

        this.setState(prevState => ({
            editModalVisibles: {
                ...prevState.editModalVisibles,
                [type]: (prevState.editModalVisibles[type] || 0) + 1
            }
        }));
    };

    handleChangeTab = tab => {
        const { history } = this.props;

        history.push(`/config/shipping-fees/${tab === 're-execution' ? tab : ''}`);
    };

    componentDidMount() {
        const { setMenuActive } = this.props;
    
        setMenuActive('config/shipping-fees');
    }

    render() {
        const { editModalVisibles } = this.state;
        const {
            match: { params },
            paginations,
            t,
        } = this.props;

        return (
            <Content className="aml-24 aml-16-lg a-content-config height-100 gflex gflex-direction-column">
                <Tabs
                    activeKey={['main', 're-execution'].includes(params.type) ? params.type : 'main'}
                    className="a-tabs a-tabs--container"
                    defaultActiveKey="main"
                    keepMounted={false}
                    rightTopBarContent={(
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-shipping-fee"
                            type="primary"
                            onClick={this.handleCreateButtonClick}
                        >
                            {t('btn.create_new')}
                        </Button>
                    )}
                    onChange={this.handleChangeTab}
                >
                    <TabPane
                        key="main"
                        tab={(
                            <>
                                {t('shipping_fee:shipping_fee_list')}
                                <span className={clsx('ml-2', {
                                    invisible: params.type === 're-execution'
                                })}>
                                    ({get(paginations, 'main.total', 0)})
                                </span>
                            </>
                        )}
                    >
                        <Main
                            {...this.props}
                            editModalVisible={editModalVisibles.main}
                        />
                    </TabPane>

                    <TabPane
                        key="re-execution"
                        tab={(
                            <>
                                {t('shipping_fee:re_execution_list')}
                                <span className={clsx('ml-2', {
                                    invisible: params.type !== 're-execution'
                                })}>
                                    ({get(paginations, 'reExecution.total', 0)})
                                </span>
                            </>
                        )}
                    >
                        <ReExecutions
                            {...this.props}
                            editModalVisible={editModalVisibles['re-execution']}
                        />
                    </TabPane>
                </Tabs>
            </Content>
        );
    }
}

export default ShippingFee;
