export const DOCUMENT_STATUS = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
}

export const LIST_COLUMNS = {
    CODE: 'code',
    DELIVERY_NOTE_CODE: 'delivery_note_code',
    ID_CREATOR: 'id_creator',
    ID_CONFIRMATION_AGENT: 'id_confirmation_agent',
    STATUS: 'status',
    ID_WAREHOUSE: 'id_warehouse',
    CREATE_AT: 'created_at',
    CONFIRM_TIME: 'confirmation_time',
    WEIGHT: 'weight',
    TOTAL_PACKAGE: 'total_package_in_document',
    SCANNED_AT: 'scanned_at'
}
