import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {Button, Empty, notification, Table} from 'antd';
import {Link} from './../../../../system/routing';
import {getVar} from './../../../../system/support/helpers';
import Pagination from './../../../Common/components/Pagination';
import {dateFormatter} from '../../../Common/services/format';
import HistorySetting from '../HistorySettingContainer';
import {HISTORY_COLUMN} from '../constants';
import {BARCODE_TYPE, SCAN_TYPE} from '../../constants';
import apiService from './../../../ScanBarcode/apiService';
import { convertBlobDataToExcel } from './../../../../system/support/helpers';

import { trans } from './../../../../system/i18n';

class Histories extends React.PureComponent {
    state = {
        activeColumns: [],
        exporting: false
    };

    getColumns() {
        return lodash.values(HISTORY_COLUMN)
            .filter(column => this.state.activeColumns.length ? this.state.activeColumns.includes(column) : true)
            .map(column => {
                let res = {
                    key: column,
                    dataIndex: column,
                    title: this.props.t('barcode:scanning_history.column.' + column),
                    className: "_" + column
                };

                if (column === HISTORY_COLUMN.SCAN_TIME) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by
                            ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : ['ascend', 'descend'],
                    };
                }

                return res;
            });
    }

    getDataSource() {
        let {t} = this.props;

        return this.props.histories.map(history => ({
            key: history.id,
            [HISTORY_COLUMN.BARCODE]: (
                <span className="a-text--nowrap _barcode">
                    {this.renderHistoryBarcode(history)}
                </span>
            ),
            [HISTORY_COLUMN.SCAN_TIME]: (
                <span className="a-text--nowrap _scan-time">
                    {dateFormatter.dateTime(history.created_at)}
                </span>
            ),
            [HISTORY_COLUMN.BARCODE_TYPE]: (
                <span className="a-text--nowrap _barcode-type">
                    {history.barcode_type ? t(`barcode:scanning_history.column.${history.barcode_type}`) : '--'}
                </span>
            ),
            [HISTORY_COLUMN.TYPE_SCAN]: (
                <span className="a-text--nowrap _type-scan">
                    {trans(`barcode:scanning_history.column.${history.type_scan}`, {
                        code: this.renderCodeRelated(history)
                    })}
                </span>
            ),
            [HISTORY_COLUMN.PACKAGE]: (
                <span className="a-text--nowrap">
                    {history.id_package ? (
                        <Link
                            isNewTab
                            params={{
                                id: history.code_package
                            }}
                            to="packages.detail"
                        >
                            {history.code_package}
                        </Link>
                    ) : '--'}
                </span>
            ),
            [HISTORY_COLUMN.ACTION]: (
                <span className="_action-scan">
                    {!history.status_warehouse || history.type_scan === 'SCAN_INVENTORY' ? '--' : t(`barcode:scanning_history.column.${history.status_warehouse}`)}
                </span>
            ),
            [HISTORY_COLUMN.WAREHOUSE_SCAN]: (
                <span className="_warehouse-scan">
                    {getVar(history, 'code_warehouse_scan', '--')}
                </span>
            ),
            [HISTORY_COLUMN.WAREHOUSE_DESTINATION]: (
                <span className="_warehouse_destination">
                    {getVar(history, 'code_warehouse_destination', '--')}
                </span>
            ),
            [HISTORY_COLUMN.ORDER]: (
                <span className="a-text--nowrap _code_order">
                    {getVar(history, 'code_order', '--')}
                </span>
            ),
            [HISTORY_COLUMN.CREATOR]: (
                <span className="_creator">
                    {getVar(history, 'username_creator', '--')}
                </span>
            ),
        }));
    }

    renderHistoryBarcode(history) {
        if (history.barcode_type) {
            switch (history.barcode_type) {
                case BARCODE_TYPE.PACKAGE:
                    return <Link isNewTab to="packages.detail" params={{id: history.barcode}}>{history.barcode}</Link>;
                case BARCODE_TYPE.BAG:
                    return <Link isNewTab to="bags.detail" params={{id: history.barcode}}>{history.barcode}</Link>;
                default:
                    return history.barcode;
            }
        } else {
            let barcode = '';
            lodash.forEach(history, (value, key) => {
                switch (key) {
                    case 'code_package':
                        barcode = <Link isNewTab to="packages.detail" params={{id: value}}>{value}</Link>;
                        break;
                    case 'code_bag':
                        barcode = <Link isNewTab to="bags.detail" params={{id: value}}>{value}</Link>;
                        break;
                    default :
                        return;
                }
            });
            return barcode;
        }

    }

    renderCodeRelated(history) {
        if (history.code_related && history.type_scan) {
            switch (history.type_scan.toLocaleUpperCase()) {
                case SCAN_TYPE.SCAN_PACKAGE_DELIVERY_NOTE:
                    return <Link isNewTab
                                 to="delivery-notes.customer.detail"
                                 params={{id: history.code_related}}>{history.code_related}</Link>;
                case SCAN_TYPE.SCAN_BAG_DELIVERY_NOTE:
                    return <Link isNewTab
                                 to="delivery-notes.customer.detail"
                                 params={{id: history.code_related}}>{history.code_related}</Link>;
                case SCAN_TYPE.SCAN_PACKAGE_BAG:
                    return <Link isNewTab to="bags.detail" params={{id: history.code_related}}>{history.code_related}</Link>;
                case SCAN_TYPE.SCAN_PACKAGE_INVENTORY:
                    return "";
                default:
                    return history.code_related;
            }
        }
    }

    onTableChange(pagination, filter, sorter) {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    }

    onChangePage(page, pageSize) {
        this.search({
            page,
            per_page: pageSize,
        });
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            });
        }
    }

    handleExportExcel = async () => {
        const { filter, t } = this.props;

        this.setState({
            exporting: true
        });

        try {
            const { data } = await apiService.export(filter);

            convertBlobDataToExcel(data, 'export_barcode_scannings');

            notification.success({
                message: t('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_failed')
            });
        }

        this.setState({
            exporting: false
        });
    };

    render() {
        const { exporting } = this.state;
        const { filter, pagination, t } = this.props;

        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3>{t('scanning_history_list')} ({pagination ? pagination.total : 0})</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2 _btn-export-excel"
                            ghost
                            loading={exporting}
                            type="primary"
                            onClick={this.handleExportExcel}
                        >
                            {t('btn.export_excel')}
                        </Button>
                        <HistorySetting
                            onChange={activeColumns => this.setState({activeColumns})}
                        />
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed aheight-100">
                    <div className="a-table--responsive">
                        <Table
                            className={"a-table--page-list"}
                            columns={this.getColumns()}
                            dataSource={this.getDataSource()}
                            pagination={false}
                            onChange={this.onTableChange.bind(this)}
                            locale={{
                                emptyText: (
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        description={<span>Không có dữ liệu </span>}
                                    >
                                    </Empty>)
                            }}
                        />
                    </div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        total={pagination.total}
                        loading={this.props.loading}
                        onChange={this.onChangePage.bind(this)}/>
                </div>
            </React.Fragment>
        )
    }
}

Histories.defaultProps = {
    filter: {},
    loading: false,
    histories: [],
    pagination: {},
    onSearch: (filter) => {
    },
};

Histories.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    histories: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
};

export default withTranslation()(Histories);
