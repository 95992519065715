import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.REQUEST, saga.fetchDeliveryCustomerRequests);
    yield takeLatest(ACTION.FETCH_DELIVERY_REQUESTS.REQUEST, saga.fetchDeliveryRequests);
    yield takeLatest(ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.REQUEST, saga.fetchDeliveryRequestsByCustomer);
    yield takeLatest(ACTION.FETCH_DELIVERY_REQUESTS.SUCCESS, saga.fetchDeliveryRequestsSuccess);
    yield takeLatest(ACTION.FETCH_LIST_PACKAGE_EXPORT.SUCCESS, saga.onFetchPackageExportSuccess);
    yield takeLatest(ACTION.FETCH_LIST_PACKAGE_EXPORT.REQUEST, saga.onFetchPackageExportRequest);
}
