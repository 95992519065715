import React, { forwardRef } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { t } from './../../../../../system/i18n';

const { Option, OptGroup } = Select;

const ServicesSelect = forwardRef(({
    className,
    consignServices,
    disabled,
    dropdownClassName,
    services,
    value,
    ...other
}, ref) => {
    let formattedValue = value;

    if (other.loading) {
        if (other.mode === 'multiple') {
            formattedValue = [];
        } else {
            formattedValue = undefined;
        }
    }

    if (other.mode === 'multiple' && (!value || !value.length)) {
        formattedValue = [];
    }

    return (
        <Select
            {...other}
            className={clsx(className, '_services-select')}
            disabled={disabled || other.loading}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-services')}
            optionLabelProp="label"
            ref={ref}
            value={formattedValue}
        >
            <OptGroup className="_normal-services" label={t('normal_service')}>
                {services.map(service => (
                    <Option
                        className="_service-item _normal-service-item"
                        key={service.id}
                        label={service.name}
                        value={service.id}
                    >
                        {service.name}
                    </Option>
                ))}
            </OptGroup>
            <OptGroup className="_consign-services" label={t('consign_service')}>
                {consignServices.map(service => (
                    <Option
                        className="_service-item _consign-service-item"
                        key={service.id}
                        label={<span className="a-text--green">{service.name}</span>}
                        value={service.id}
                    >
                        {service.name}
                    </Option>
                ))}
            </OptGroup>
        </Select>
    );
});

ServicesSelect.defaultProps = {
    consignServices: [],
    services: []
};

ServicesSelect.propTypes = {
    consignServices: PropTypes.array,
    services: PropTypes.array
};

export default ServicesSelect;
