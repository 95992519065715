/* eslint-disable */
import React from 'react';
import {Layout} from "antd";
import FormSearch from './components/FormSearch';
import ListDeliveryNoteTab from './components/ListDeliveryNoteTab';
const {Content} = Layout;

class RequestDeliveryNoteContainer extends React.PureComponent {
    render() {
        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <FormSearch/>
                    <ListDeliveryNoteTab/>
                </Content>
            </Layout>

        )
    }
}

export default RequestDeliveryNoteContainer;