import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Checkbox, Dropdown, Icon, Menu} from 'antd';
import {LIST_PACKAGE_OBJECT} from "../constants";


class ListPackageSetting extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            isOpen: false,
            object: props.object,
            isCurrentPage: props.isCurrentPage
        };
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};

        if (nextState.object !== props.object) {
            nextState.object = props.object;
            nextState.isOpen = false
        } else {
            nextState.isOpen = nextState.object === props.isCurrentPage ? props.isOpen : false;
            nextState.isCurrentPage = props.isCurrentPage
        }
        return nextState;
    }

    onChangeOption(optionKey, event) {
        let values = event.target.checked
            ? [...this.props.values, optionKey]
            : this.props.values.filter(value => value !== optionKey);

        this.props.onChange(values);
    }

    render() {
        const { t } = this.props;

        return (
            <Dropdown
                visible={this.state.isOpen}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field"
            >
                <Button
                    onClick={() => this.props.onOpenSetting(true, this.state.object)}
                    type="primary"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost
                >
                    {t('setting_info_column')}
                </Button>
            </Dropdown>
        );
    }

    renderMenu() {
        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={() => this.props.onOpenSetting(false, this.state.object)} type="close"/>
                </div>
                {this.props.options.map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={this.props.values.includes(option.key)}
                            disabled={option.disabled}
                            onChange={this.onChangeOption.bind(this, option.key)}
                        >{this.props.t('package:column.' + option.key)}</Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
}

ListPackageSetting.defaultProps = {
    options: [],
    values: [],
    onChange: () => {
    },
    isOpen: false,
    object: LIST_PACKAGE_OBJECT.ALL,
    isCurrentPage: LIST_PACKAGE_OBJECT.ALL


};

ListPackageSetting.propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    onOpenSetting: PropTypes.func,
    object: PropTypes.string,
    isCurrentPage: PropTypes.string
};


export default withTranslation()(ListPackageSetting);
