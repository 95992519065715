import React from 'react';
import {connect} from 'react-redux';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import ListDeliveryRequest from './components/index';
import * as actions from './actions';
import * as actionsDetailDeliveryRequest from '../DetailDeliveryRequest/actions';
import {setMenuActive} from "../../Common/actions";
import authService from "../../Auth/authService";
import {DELIVERY_REQUEST_STATUS} from "./constants";
import lodash from "lodash";


const mapStateToProps = (state) => {
    return {
        ...selectors.getState(state),
        user_id: authService.user('id'),
        deliveryRequests: selectors.getState(state, 'deliveryRequests'),
        listDeliveryCustomerRequest: selectors.getState(state, 'deliveryCustomerRequests.list', []),
        permissions: lodash.get(state, 'auth.user.permissions', []),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchDeliveryRequests: (filter) => dispatch(actions.fetchDeliveryRequests(filter)),
    fetchDeliveryRequestsByCustomer: (filter) => dispatch(actions.fetchDeliveryRequestsbyCustomer(filter)),
    fetchDeliveryCustomerRequests: (filter) => dispatch(actions.fetchDeliveryCustomerRequests(filter)),
    fetchListPackageExport: (data) => dispatch(actions.fetchListPackageExport(data)),
    showDetailDeliveryRequest: (code, agency) => dispatch(actionsDetailDeliveryRequest.showDetailDeliveryRequest({
        code,
        agency
    })),
    onResetDeliveryCustomerRequest: () => dispatch(actions.onResetDeliveryCustomerRequest()),
    onResetDeliveryRequest: () => dispatch(actions.onResetDeliveryRequest()),
    onSuccessUpdateDeliveryRequest: response => dispatch(actions.successUpdateDeliveryRequest(response)),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class ListDeliveryRequestContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter = filter => {
        const { user_id, fetchDeliveryCustomerRequests, fetchDeliveryRequests } = this.props;
        const selectedWarehouse = localStorage.getItem(`selectedDeliveryRequestWarehouse_${user_id}`);

        filter.id_warehouse = selectedWarehouse;

        if (!filter.status) {
            filter.status = DELIVERY_REQUEST_STATUS.STATUS_NEW;
        }
        if (filter.status === DELIVERY_REQUEST_STATUS.STATUS_NEW) {

            filter.sort_direction = 'asc';
            if (selectedWarehouse) {
                fetchDeliveryCustomerRequests(filter);
            }

        } else {
            filter.sort_direction = 'desc';
            if (selectedWarehouse) {
                fetchDeliveryRequests(filter);
            }
        }
    };

    getCurrentFilter = () => {
        let filter = {
            page: 1,
            sort_by: 'created_at',
            sort_direction: 'desc',
            from: 'delivery_request',
            ...this.getFilterFromLocation(this.props.location),
        };

        if (!filter.status) {
            filter.status = DELIVERY_REQUEST_STATUS.STATUS_NEW;
        }
        if (filter.status === DELIVERY_REQUEST_STATUS.STATUS_NEW) {
            filter.sort_direction = 'asc';
        }
        return filter;
    };

    render() {
        return <ListDeliveryRequest
            {...this.props}
            filter={this.getCurrentFilter()}
            onChangeFilter={this.onChangeFilter}
            onSearch={this.onSearch.bind(this)}
        />
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListDeliveryRequestContainer)
