import {combineReducers} from 'redux';
import lodash from "lodash";
import * as ACTION from './constants';
import {CREATE_SHIPPING_PARTNER} from '../CreateShippingPartner/constants';
import {EDIT_SHIPPING_PARTNER, EDIT_SHIPPING_DOMESTIC_PARTNER} from '../EditShippingPartner/constants';

export const loading = (state = false, action) => {
    switch (action.type) {
        case CREATE_SHIPPING_PARTNER.REQUEST:
        case EDIT_SHIPPING_PARTNER.REQUEST:
        case EDIT_SHIPPING_DOMESTIC_PARTNER.REQUEST:
            return true;
        case CREATE_SHIPPING_PARTNER.SUCCESS:
        case CREATE_SHIPPING_PARTNER.FAILED:
        case EDIT_SHIPPING_PARTNER.SUCCESS:
        case EDIT_SHIPPING_PARTNER.FAILED:
        case EDIT_SHIPPING_DOMESTIC_PARTNER.SUCCESS:
        case EDIT_SHIPPING_DOMESTIC_PARTNER.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

export const errors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case CREATE_SHIPPING_PARTNER.REQUEST:
        case CREATE_SHIPPING_PARTNER.SUCCESS:
        case EDIT_SHIPPING_PARTNER.REQUEST:
        case EDIT_SHIPPING_PARTNER.SUCCESS:
        case ACTION.CLEAR_STATE:
            return {};
        case CREATE_SHIPPING_PARTNER.FAILED:
        case EDIT_SHIPPING_PARTNER.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    errors
});
