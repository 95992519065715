import React, {useEffect, useState} from 'react';
import {Button, Empty, Icon, Table, Tooltip} from 'antd';

import authService from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import {t} from './../../../../system/i18n';
import {getVar} from './../../../../system/support/helpers';

import DetailDeliveryRequest from './../../../DeliveryRequest/DetailDeliveryRequest/DetailDeliveryRequestContainer';

const DeliveryCustomerRequests = ({
                                      deliveryCustomerRequests,
                                      customerId,
                                      loading,
                                      route,
                                      warehouseId,
                                      showDeliveryRequestDetailModal,
                                      onSuccessDeliveringDeliveryRequest
                                  }) => {
    const [expandedRowkeys, setExpandedRowKeys] = useState([]);

    const columns = [{
        className: 'a-text--cursor-pointer _customer-column',
        dataIndex: 'customer',
        key: 'customer',
        title: t('delivery_request:column.id_customer'),
        render: text => (
            <>
                <Icon type="up" className="a-text--gray-70 mr-2"/>
                {`${getVar(text, 'full_name')} (${getVar(text, 'username', '--')})`}
            </>
        )
    }, {
        className: 'a-text--cursor-pointer a-text--nowrap _agency-column',
        dataIndex: 'agency.name',
        key: 'agency',
        title: t('delivery_request:column.agency')
    }];

    const onExpand = (expanded, record) => {
        setExpandedRowKeys(expanded ? [getVar(record, 'customer.id')] : []);
    };

    const expandedRowRender = record => {
        return (
            <Table
                columns={[{
                    className: '_code-column',
                    dataIndex: 'delivery_request.code',
                    key: 'code',
                    title: t('delivery_request:column.id_delivery_request'),
                    render: (text, childRecord) => {
                        return (
                            <>
                                <Tooltip title={getVar(childRecord, "delivery_request.note", "")}>
                                    <Button

                                        type="link"
                                        onClick={showDeliveryRequestDetailModal.bind(undefined, {
                                            code: text,
                                            agency: getVar(record, 'agency.code')
                                        })}
                                    >
                                        {text}
                                    </Button>
                                    <span>
                                    {`${getVar(childRecord, 'delivery_request.address')}${getVar(childRecord, 'delivery_request.location.detail') ? `, ${childRecord.delivery_request.location.detail}` : ''}`}
                                </span>
                                </Tooltip>
                            </>
                        )
                    }
                }]}
                dataSource={getVar(record, 'delivery_requests', [])}
                pagination={false}
                rowKey={record => getVar(record, 'delivery_request.id')}
                showHeader={false}
            />
        );
    };

    useEffect(() => {
        setExpandedRowKeys([Number(customerId)]);
    }, [customerId]);

    if (!authService.can(permissions.DELIVERY_REQUEST_VIEW_LISTING)) {
        return null;
    }

    return (
        <div className="a-sider-sub__block _delivery-customer-request-list">
            <h4 className="a-sider-sub__block__title">
                {t('delivery_note:customer_requesting_delivery_list')}
            </h4>

            <Table
                columns={columns}
                dataSource={getVar(deliveryCustomerRequests, warehouseId, [])}
                expandedRowKeys={expandedRowkeys}
                expandedRowRender={expandedRowRender}
                expandIconAsCell={false}
                expandRowByClick
                loading={getVar(loading, warehouseId, false)}
                locale={{
                    emptyText: (
                        <Empty
                            description={<span>{t('label.no_data')}</span>}
                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                        />
                    )
                }}
                pagination={false}
                rowKey={record => getVar(record, 'customer.id')}
                onExpand={onExpand}
            />

            <DetailDeliveryRequest
                route={route}
                onSuccessDeliveringDeliveryRequest={onSuccessDeliveringDeliveryRequest}
            />
        </div>
    );
};

export default DeliveryCustomerRequests;
