import {combineReducers} from 'redux';
import * as CANCEL_ACTION from './constants';

let isProcessing = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case CANCEL_ACTION.BEGIN_CANCEL_DELIVERY_REQUEST:
            return true;
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS:
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.FAILED:
        case CANCEL_ACTION.END_CANCEL_DELIVERY_REQUEST:
            return false;
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.REQUEST:
            return true;
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS:
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.FAILED:
            return false;
            
        default:
            return state;
    }
};

export default combineReducers({
    isProcessing,
    loading
});
