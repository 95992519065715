import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearSuggests, getInitCustomersOfSelect, getSuggests } from '../actions';
import * as selectors from '../selectors';
import SelectCustomer from './components/SelectCustomer';
import { debounce, get } from 'lodash';

const mapStateToProps = (state, props) => ({
    ...props,
    loading: selectors.getState(state, 'loadingSuggests'),
    customers: selectors.getState(state, 'suggests'),
    initCustomersOfSelects: selectors.getState(state, 'initCustomersOfSelects'),
    loadingInitCustomersOfSelects: selectors.getState(state, 'loadingInitCustomersOfSelects')
});

const mapDispatchToProps = {
    clearSuggests,
    getInitCustomersOfSelect,
    getSuggests
};

class SelectCustomerContainer extends React.PureComponent {
    getInitialCustomers = customerId => {
        const { getInitCustomersOfSelect, initCustomersOfSelects } = this.props;

        if (!initCustomersOfSelects || !initCustomersOfSelects[customerId]) {
            getInitCustomersOfSelect(customerId);
        }
    };

    handleSearch = value => {
        const { clearSuggests } = this.props;

        if (value) {
            this.doGetSuggests({
                username: value
            });
        } else {
            clearSuggests();
        }
    };

    handleChange = value => {
        const { onChange } = this.props;

        if (value) {
            return;
        }

        onChange(value);
    };

    handleSelect = value => {
        const { customers, dispatchObject, initCustomersOfSelects, onChange } = this.props;
        const formattedCustomers = customers && customers.length ? customers : (value ? (initCustomersOfSelects && initCustomersOfSelects[value]) : []);
        const customer = formattedCustomers.find(formattedCustomer => get(formattedCustomer, 'customer.id') === value);

        onChange(dispatchObject ? customer : value);
    };

    doGetSuggests = debounce(this.props.getSuggests, 250);

    componentDidUpdate(prevProps) {
        const { customers, initCustomersOfSelects, loading, value, getInitCustomersOfSelect } = this.props;

        if (value && Number(value) !== Number(prevProps.value)) {
            const customer = customers.find(comparedCustomer => Number(get(comparedCustomer, 'customer.id')) === Number(value));

            if ((!customer || !initCustomersOfSelects[value]) && !loading) {
                getInitCustomersOfSelect(value);
            }
        }
    }

    render() {
        return (
            <SelectCustomer
                {...this.props}
                loading={this.loadingDetail}
                getInitialCustomers={this.getInitialCustomers}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                onSelect={this.handleSelect}
            />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(SelectCustomerContainer);

Container.defaultProps = {
    onChange: () => {
    },
};

Container.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default Container;
