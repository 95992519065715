import {combineReducers} from "redux";
import * as ACTION from '../constants';
import * as lodash from "lodash";

let detail = (state = {}, action) => {
	let {type, payload} = action;
	switch (type) {
		case  ACTION.FETCH_DETAIL_WAREHOUSE.SUCCESS:
			let id_country = "";
			let id_city = "";
			let id_district = "";
			let id_ward = "";
			let warehouse = {...lodash.get(payload, 'warehouse', {})};
			let location = lodash.get(payload, 'location', {}) || {};
			switch (location.type) {
				case "DISTRICT":
					id_district = location.id;
					id_city = lodash.get(location, 'parent.id', '');
					id_country = lodash.get(location, 'parent.parent.id', '');
					break;
				case "WARD":
					id_ward = location.id;
					id_district = lodash.get(location, 'parent.id', '');
					id_city = lodash.get(location, 'parent.parent.id', '');
					id_country = lodash.get(location, 'parent.parent.parent.id', '');
					break;
				default :
					id_country = "";
					id_city = "";
					id_district = "";
					id_ward = "";
			}
			warehouse.id_country = id_country;
			warehouse.id_city = id_city;
			warehouse.id_district = id_district;
			warehouse.id_ward = id_ward;
			return warehouse;
		case ACTION.FETCH_DETAIL_WAREHOUSE.FAILED:
		case ACTION.FETCH_DETAIL_WAREHOUSE.REQUEST:
		case ACTION.CLEAR_STATE:
			return {};
		default:
			return state

	}
};
let staff = (state = [], action) => {
	let {type, payload} = action;
	switch (type) {
		case ACTION.FETCH_DETAIL_WAREHOUSE.SUCCESS:
			return lodash.get(payload, 'warehouse_staffs', []);
		case ACTION.FETCH_DETAIL_WAREHOUSE.FAILED:
		case ACTION.CLEAR_STATE:
			return [];
		default:
			return state

	}
};

let loading = (state = false, action) => {
	let {type} = action;
	switch (type) {
		case  ACTION.FETCH_DETAIL_WAREHOUSE.REQUEST:
			return true;
		case ACTION.FETCH_DETAIL_WAREHOUSE.FAILED:
		case ACTION.FETCH_DETAIL_WAREHOUSE.SUCCESS:
			return false;
		default:
			return state

	}
};
export default combineReducers({loading, detail, staff})
