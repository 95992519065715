import { connect } from 'react-redux';

import SelectShippingPartnerDeliveryNote from './components';
import { getState } from './../../ShippingPartner/ListShippingPartner/selectors';

const mapStateToProps = state => {
    return {
        loading: getState(state, 'listShippingPartner.loading.list',false),
    };
};

export default connect(
    mapStateToProps
)(SelectShippingPartnerDeliveryNote);
