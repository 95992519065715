import React from 'react';
import { Pagination, Select, Table } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class ListAll extends React.Component {
    state = {
        visible: false,
        halfwindow: 0,
        iconClose: false,
        pageSize: 20,
    };

    onClose = () => {
        this.setState({
            visible: false,
            iconClose: false
        });
    };

    componentDidMount() {
        const halfwindow = window.innerWidth / 2;

        this.setState({
            halfwindow
        });
    }

    onChangePerPage = value => {
        const { filter, onSearch } = this.props;

        this.setState({
            pageSize: value
        });

        onSearch({
            ...filter,
            per_page: value
        });
    };

    onChangePage = page => {
        const { pageSize } = this.state;
        const { filter, onSearch } = this.props;

        onSearch({
            ...filter,
            page,
            per_page: pageSize
        });
    };

    handleChange = (pagination, filters, sorter) => {
        const { filter, onSearch } = this.props;

        onSearch({
            ...filter,
            sort_by: 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    };

    render() {
        const { pageSize } = this.state;
        const { columns, filter, inventories, loading, pagination, t } = this.props;

        return (
            <div className="a-content__body bg-white a-content__body--page-list inventory-history is-pagination-fixed">
                <Table
                    className="a-table--antd"
                    columns={columns}
                    dataSource={inventories}
                    loading={loading}
                    pagination={false}
                    rowKey={row => row.inventory.id}
                    onChange={this.handleChange}
                />
                <div className="a-pagination a-pagination--fixed">
                    <Pagination
                        defaultCurrent={1}
                        current={parseInt(filter.page)}
                        pageSize={pageSize}
                        total={get(pagination, 'total', 0) || 0}
                        className="a-pagination--detail"
                        onChange={this.onChangePage}
                    />
                    <div className="a-pagination--select-number-item">
                        <span className="text-show">{t('inventory:display')}</span>
                        <Select defaultValue="20" onChange={this.onChangePerPage} className="a-select--select-number-show" getPopupContainer={trigger => trigger.parentNode}>
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ListAll);
