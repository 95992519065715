import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.FETCH_SETTING.REQUEST, saga.fetchSetting);
    yield takeLatest(ACTION.FETCH_DETAIL_SETTING.REQUEST, saga.fetchDetailSetting);
    yield takeLatest(ACTION.UPDATE_SETTING.REQUEST, saga.updateSetting);
    yield takeLatest(ACTION.UPDATE_SETTING.SUCCESS, saga.updateSettingSuccess);
}
