import React, {Component} from 'react';
import {Icon} from 'antd';
import clsx from 'clsx';
import {get} from 'lodash';

import {getSplitPackageWarningMessage} from './../../helper';
import {t} from '../../../../system/i18n';
import {Link} from '../../../../system/routing';

class AlertSplitPackage extends Component {

    render() {
        const {packageData, warnings} = this.props;
        const isShowAlertMessage = !!get(packageData, 'permissions.can_detach_package');

        return (
            <div
                className={clsx('alert-split-package mb-5', {
                    'has-message': isShowAlertMessage
                })}
            >
                <span className="alert-message">
                    {isShowAlertMessage &&  (
                        <>
                            <Icon type="medicine-box" className="icon" />
                            <span>{getSplitPackageWarningMessage(warnings)}</span>
                        </>
                    )}
                </span>

                <Link to="packages.split_package" params={{id: packageData.code}} className="btn-split-package">
                    <span>{t("package:btn.split_package")}</span>
                </Link>
            </div>
        );
    }
}

AlertSplitPackage.defaultProps = {
    packageData: {}
};

export default AlertSplitPackage;