import React, { useEffect, useState } from 'react'
import { Dropdown, Button, Menu, Icon, Checkbox } from 'antd'
import { withTranslation } from 'react-i18next'
import { values, isEmpty } from 'lodash'
import { LIST_COLUMNS } from '../../constants'
import authService from '../../../Auth/authService'

const defaultSetting = values(LIST_COLUMNS).map(column => ({
    key: column,
    disabled: [LIST_COLUMNS.CODE, LIST_COLUMNS.DELIVERY_NOTE_CODE].includes(column),
    selected: true,
}))

function Setting(props) {
    const user = authService.user()
    const [options, setOptions] = useState(defaultSetting)
    const { t, setOpenSetting, openSetting, setColumsActive } = props

    useEffect(() => {
        let options = localStorage.getItem('list_return_goods_setting_' + user.id)
        if (!isEmpty(options)) {
            setOptions(JSON.parse(options))
        }
    }, [])

    useEffect(() => {
        const columns = []
        options.map(option => {
            if (option.selected) {
                columns.push(option.key)
            }
            return true
        })
        setColumsActive(columns)
    }, [options])

    function onChangeSetting(key, selected) {
        const newOptions = values(options).map(option => ({
            ...option,
            selected: key === option.key ? !selected : option.selected,
        }))
        localStorage.setItem('list_return_goods_setting_' + user.id, JSON.stringify(newOptions))
        setOptions(newOptions)
    }

    function renderMenu() {
        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon
                        onClick={() => setOpenSetting(false)}
                        type="close"
                    />
                </div>
                {options.map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item">
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={option.selected}
                            disabled={option.disabled}
                            onChange={() => onChangeSetting(option.key, option.selected)}>
                            {t('return_goods:column_setting.' + option.key)}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
    return (
        <div>
            <Dropdown
                visible={openSetting}
                overlay={renderMenu}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field">
                <Button
                    onClick={() => setOpenSetting(true)}
                    type="primary"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost>
                    {t('setting_info_column')}
                </Button>
            </Dropdown>
        </div>
    )
}
export default withTranslation()(Setting)
