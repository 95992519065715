import config from './../../config/app';

const baseImageURL = config.imageUrl;
const imageUrl = `${baseImageURL}images`;

export default {
    shippo: {
        src: `${imageUrl}/logo-shippo.png`,
    },
}
