import { combineReducers } from 'redux';

import { ADD_SHIPPING_FEE, CLEAR_FORM_ERRORS, GET_SHIPPING_FEES, UPDATE_SHIPPING_FEE } from './constants';
import routes from './routesReducer';
import reExecution from './re-execution/reducer';
import { getFormErrors } from './../../system/support/helpers';

const loading = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case GET_SHIPPING_FEES.REQUEST: {
            return true;
        }
        case GET_SHIPPING_FEES.SUCCESS:
        case GET_SHIPPING_FEES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const pagination = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_SHIPPING_FEES.REQUEST: {
            return {
                total: state.total || 0,
                ...action.payload
            };
        }
        case GET_SHIPPING_FEES.SUCCESS: {
            return {
                ...state,
                ...payload.pagination
            };
        }
        default: {
            return state;
        }
    }
};

const shippingFees = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_SHIPPING_FEES.SUCCESS: {
            return payload.shipping_fees;
        }
        default: {
            return state;
        }
    }
};

const adding = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ADD_SHIPPING_FEE.REQUEST: {
            return true;
        }
        case ADD_SHIPPING_FEE.SUCCESS:
        case ADD_SHIPPING_FEE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const formErrors = (state = null, action) => {
    const { payload, type } = action;

    switch (type) {
        case ADD_SHIPPING_FEE.SUCCESS:
        case UPDATE_SHIPPING_FEE.SUCCESS:
        case CLEAR_FORM_ERRORS: {
            return null;
        }
        case ADD_SHIPPING_FEE.FAILED:
        case UPDATE_SHIPPING_FEE.FAILED: {
            return getFormErrors(payload);
        }
        default: {
            return state;
        }
    }
};

const updating = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_SHIPPING_FEE.REQUEST: {
            return true;
        }
        case UPDATE_SHIPPING_FEE.SUCCESS:
        case UPDATE_SHIPPING_FEE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    adding,
    formErrors,
    loading,
    pagination,
    reExecution,
    routes,
    shippingFees,
    updating
});
