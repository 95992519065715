import React from 'react';
import { Button, Col, Icon, Modal, Row, Tag, Typography } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../Common/services/format';
import { getVar } from './../../../system/support/helpers';

import Link from './../../../system/routing/Link';

const { Text } = Typography;

const ComplaintSellerModal = ({ className, complaintSeller, t, visible, onCancel }) => {
    return (
        <Modal
            className={clsx(className, '_complaint-seller-modal')}
            footer={null}
            title={(
                <div className="gflex galign-center">
                    {t('complaint_info')}
                    <Tag className="aml-12 a-text--uppercase">
                        {getVar(complaintSeller, 'status_label', '--')}
                    </Tag>
                </div>
            )}
            visible={visible}
            width={800}
            onCancel={onCancel}
        >
            <Row gutter={8}>
                <Col className="amb-16" span={24}>
                    {t('reason')}: <Text className="a-text--blue">{getVar(complaintSeller, 'reason_label', '--')}</Text>
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    <Icon type="calendar" /> {t('create')}: {complaintSeller.created_at ? dateFormatter.full(complaintSeller.created_at) : '--'}
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    {t('package:complaint_seller_info_modal.receive')}: {complaintSeller.receive_at ? dateFormatter.full(complaintSeller.receive_at) : '--'}
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    {t('success')}: {complaintSeller.success_at ? dateFormatter.full(complaintSeller.success_at) : '--'}
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    {t('failure')}: {complaintSeller.failure_at  ? dateFormatter.full(complaintSeller.failure_at ) : '--'}
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    {t('creator')}: {getVar(complaintSeller, 'creator.username', '--')}
                </Col>
                <Col className="amb-16" lg={{span: 6}}>
                    {t('handler')}: {getVar(complaintSeller, 'handler.username', '--')}
                </Col>
                <Col className="amb-16" lg={{span: 12}}>
                    {t('processing_term')}: {complaintSeller.expire_time ? dateFormatter.date(complaintSeller.expire_time) : '--'}
                </Col>
                <Col className="amb-16" lg={{span: 24}}>
                    {t('package:complaint_seller_info_modal.complaint_letter')}: <Text className="a-text--blue a-text--bold">{getVar(complaintSeller, 'order_code', '--')}</Text>
                </Col>
                <Col lg={{span: 24}}>
                    {t('package_list')}: {isEmpty(complaintSeller.packages) ? '--' : map(complaintSeller.packages, pkg => (
                        <Button key={pkg} type="link">
                            <Link
                                isNewTab
                                params={{id: pkg}}
                                to="packages.detail"
                            >
                                {pkg}
                            </Link>
                        </Button>
                    ))}
                </Col>
            </Row>
        </Modal>
    );
};

export default withTranslation()(ComplaintSellerModal);
