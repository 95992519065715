import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getSettingItemByCode } from './../../Setting/selectors';

import DetailInfoForm from './components';
import { getState } from '../../../system/store';

const usingShippingDomesticPartner = getSettingItemByCode('CONFIG:USING_SHIPPING_DOMESTIC_PARTNER', false);
const defaultShippingDomesticPartner = getSettingItemByCode('CONFIG:DEFAULT_SHIPPING_DOMESTIC_PARTNER');

const DetailInfoFormContainer = props => (
    <DetailInfoForm {...props} />
);

const mapStateToProps = state => {
    return {
        usingShippingDomesticPartner: usingShippingDomesticPartner(state),
        defaultShippingDomesticPartner: defaultShippingDomesticPartner(state),
        agencySetting: getState('setting.agencySetting'),
        globalSetting: getState('setting.setting'),
    };
};

export default withTranslation()(connect(
    mapStateToProps
)(DetailInfoFormContainer));
