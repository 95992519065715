import React from 'react';
import {Button, Col, Form, Icon, Layout, Row, Switch} from "antd";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {DEFAULT_STATE_FORM_INPUT_PROPERTY} from "../../constants";
import FormInputProperty from "../../FormInputProperty/FormInputPropertyContainer"
import {router} from "../../../../system/routing";
import * as lodash from "lodash";
import {DEFAULT_STATE_FORM_INPUT_WAREHOUSE} from "../../../WarehouseConfig/constants";

const Content = Layout.Content;

class UpdateWarehouse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {...DEFAULT_STATE_FORM_INPUT_PROPERTY, active: true, id_property: 0}
	}

	static getDerivedStateFromProps(props, state) {
		let nextState = {...state};
		let id_property = !lodash.isNil(props.propertyData) ? lodash.get(props, 'propertyData.id', 0) : 0;
		if (state.id_property !== id_property && id_property !== 0) {
			nextState = lodash.isNil(props.propertyData) ? {...DEFAULT_STATE_FORM_INPUT_WAREHOUSE} : {
				name: lodash.get(props, 'propertyData.name', ""),
				alias: lodash.get(props, 'propertyData.alias', ""),
				note: lodash.get(props, 'propertyData.note', ""),
				icon: lodash.get(props, 'propertyData.icon', null),
				active: lodash.get(props, 'propertyData.active', true),
			};
			nextState.id_property = id_property;
		}

		return nextState;
	}
	onUpdateProperty = () => {
		let data = {...this.state};
		data.active = this.state.active ? 1 : 0;
		data = lodash.mapValues(data, value => lodash.isUndefined(value) ? '' : value);
		this.props.updateProperty(data)
	};
	onSubmit = (value) => {
		this.setState({
			...this.state, ...value
		})
	};
	onChecker = (e) => {
		this.setState({active: e})
	};
	onBackConfigListProperty = () => {
		router.redirect('/config/property')
	};

	render() {
		const {t, loading} = this.props;
		return (
			<Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
				<div className="a-breadcrumb">
                    <a href="#top" className={"a-text--gray _btn-back"} onClick={this.onBackConfigListProperty}><Icon
						type="arrow-left" className={"mr-2 "}/>{t('config:btn.back_config_list_property')}</a>
				</div>
				<div className="a-block-config amt-16">
					<div className="a-block-config__title gflex gjustify-space-between">
						<h3 className="title">{t('config:label.information_property')}</h3>
						<div>
							{!loading && <div>
								<label className={"a-text--fz-12 a-text--gray mr-2"}>{t('config:label.status')}</label>

								<Switch defaultChecked={this.state.active} size="small" onChange={this.onChecker}
                                        disabled={loading}
                                        loading={loading}
                                        className={"_switch-active"}
                                        checker={this.state.active}/>

							</div>}
						</div>
					</div>
					<div className="a-block-config__body">
						<Form className="a-form ">
							<FormInputProperty onSubmit={this.onSubmit}
							                   errors={this.props.errors}/>
							<Row gutter={48}>
								<Col lg={{span: 24}} xl={{span: 24}}
								     className="a-package--action only-action">
                                    <a className="a-btn a-btn--back-package _btn-cancel"
                                       href={'#top'}
                                       onClick={this.onBackConfigListProperty}>{t('btn.cancel')} </a>
									<Button type="primary" onClick={this.onUpdateProperty}
                                            disabled={loading}
                                            loading={loading}
                                            className="a-btn a-btn--primary a-btn--save-package primary-special _btn-update">{t('btn.update')} </Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</Content>
		)
	}
}

UpdateWarehouse.defaultProps = {
	loading: false,
	propertyData: {},
	updateProperty: () => {
	}
};

UpdateWarehouse.propTypes = {
	loading: PropTypes.bool,
	propertyData: PropTypes.object,
	updateProperty: PropTypes.func
};
export default withTranslation()(UpdateWarehouse);
