/* eslint-disable */
import React from 'react';
import ViewBag from './components';
class ViewBagContainer extends React.PureComponent{
    render() {
        return(
            <ViewBag/>
        )
    }
}
export default ViewBagContainer;