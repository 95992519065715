import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchDeliveryRequests = createAction(ACTION.FETCH_DELIVERY_REQUESTS.REQUEST, (params) => params);
export const fetchDeliveryRequestsbyCustomer = createAction(ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.REQUEST, (params) => params);
export const fetchDeliveryCustomerRequests = createAction(ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.REQUEST, (params) => params);
export const onResetDeliveryCustomerRequest = createAction(ACTION.RESET_LIST_DELIVERY_CUSTOMER_REQUEST, () => {});
export const onResetDeliveryRequest = createAction(ACTION.RESET_LIST_DELIVERY_REQUEST, () => {});
export const fetchListPackageExport = createAction(ACTION.FETCH_LIST_PACKAGE_EXPORT.REQUEST, (data) => ({...data}));
export const successUpdateDeliveryRequest = createAction(ACTION.UPDATE.SUCCESS);
