import React, {Component} from 'react';
import {connect} from 'react-redux';
import {printBatchStamp} from "../actions";
import PrintBatchStamp from "./PrintBatchStamp";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    printBatchStamp: (data) => {
        dispatch(printBatchStamp(data));
    }
});

class PrintBatchStampTest extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"/>
                <section className="sheet">
                    <PrintBatchStamp onDidMount={() => window.print()}/>
                </section>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintBatchStampTest);