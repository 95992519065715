import React from 'react';
import { notification } from 'antd';
import * as lodash from 'lodash';

import apiService from './apiService';
import * as ACTION from './constants';
import { processApiRequest } from './../../Common/saga';
import { t } from './../../../system/i18n';
import { router } from './../../../system/routing';

export function* checkingInventoryRequest(action) {
	yield processApiRequest(ACTION.CHECKING_INVENTORY, () => apiService.checkingInventory(action.payload), action.payload);
}

export function* checkingInventorySuccess(action) {
	yield router.redirect(`/inventory/${action.payload.inventory.id}/process`);
}

export function* checkingInventoryFailed(action) {
	const error = lodash.get(action, 'payload.data');

	if (lodash.get(error, 'warehouse')) {
		return yield notification['error']({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}

	if (action.status_code !== 403) {
		return yield notification.error({
			message: t('inventory:message.checking_inventory_failed')
		});
	}
}

export function* comparingTrackingBill(action) {
	yield processApiRequest(ACTION.COMPARING_TRACKING_BILL, () => apiService.comparingTrackingBill(action.payload), action.payload);
}

export function* comparingTrackingBillSuccess(action) {
	yield router.redirect(`/inventory/${action.payload.inventory.id}/process`);
}

export function* comparingTrackingBillFailed(action) {
	const error = lodash.get(action, 'payload.data');

	if (lodash.get(error, 'warehouse')) {
		return yield notification['error']({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}

	if (action.status_code !== 403) {
		return yield notification.error({
			message: t('inventory:message.checking_inventory_failed')
		});
	}
}
