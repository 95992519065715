import { combineReducers } from 'redux';

import * as ACTION from './constants';

const comparing = (state = false, action) => {
	const { type } = action;

	switch (type) {
		case ACTION.CHECKING_INVENTORY.REQUEST: {
			return true;
		}
		case ACTION.CHECKING_INVENTORY.SUCCESS:
		case ACTION.CHECKING_INVENTORY.FAILED: {
			return false;
		}
		default: {
			return state;
		}
	}
};

const comparingTrackingBill = (state = false, action) => {
	const { type } = action;

	switch (type) {
		case ACTION.COMPARING_TRACKING_BILL.REQUEST: {
			return true;
		}
		case ACTION.COMPARING_TRACKING_BILL.SUCCESS:
		case ACTION.COMPARING_TRACKING_BILL.FAILED: {
			return false;
		}
		default: {
			return state;
		}
	}
};

export default combineReducers({
	comparing,
	comparingTrackingBill
});
