import React from 'react';
import { Col, Form, Icon, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import * as lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { inputFocusParser } from '../../CreatePackageViaScan/services';
import { ELEMENT } from './../../CreatePackageViaScan/services/inputFocus/constants';
import { isRequireWeightBox } from './../../helper';
import authService from './../../../Auth/authService';
import ElementManager from './../../../Common/services/ElementManager';
import { convertUndefinedPropertyToNull } from './../../../../system/support/helpers';

import BoxSizesSuggestByVolume from './../../components/BoxSizesSuggestByVolume';
import FormProcessing from './../../../Common/components/FormProcessing';
import InputNumber from './../../../Common/components/InputNumber';

const  { Option } = Select;
const { TextArea } = Input;

class PackageParams extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                weight_net: null,
                length: null,
                width: null,
                height: null,
                volume_box: null,
                note: undefined
            },
            inputVersion: 0,
            changedParams: [],
        };
        this.elements = new ElementManager();
    }

    onChangeInput(param, value, cb) {
        const { boxSizes, errors, form, isWapper, onSubmit, resetError, resetErrorUpdatePackage } = this.props;

        if (lodash.isObject(value) && value.hasOwnProperty('target')) {
            value = value.target.value;
        }
        let currentValue = this.state.input[param];

        if (currentValue !== value) {
            this.changeInput({
                [param]: value
            }, () => {
                form.setFieldsValue({
                    [param]: value
                });

                if (isWapper && (!['id_box_size', 'weight_box', 'volume_box'].includes(param) || (['id_box_size', 'weight_box', 'volume_box'].includes(param) && boxSizes && boxSizes.length))) {
                    onSubmit({
                        ...this.state.input,
                        [param]: value
                    });
                }
    
                if (param === 'id_box_size') {
                    this.onIdBoxSizeChange(value);
                    const boxSize = this.getBoxSizeById(value);
                    onSubmit({
                        ...this.state.input,
                        weight_box: lodash.get(boxSize, 'weight', 0),
                        volume_box:  lodash.get(boxSize, 'volume', 0),
                        [param]: value
                    });
                }

                if (lodash.isFunction(cb)) {
                    cb();
                };

                if (['length', 'width', 'height'].includes(param)) {
                    this.doPackingPackage();

                    if (!lodash.get(boxSizes, 'length')) {
                        const { input: { length, width, height } } = this.state;

                        this.doGetSuggestsByVolume({
                            length,
                            width,
                            height
                        });
                    }
                }
            });
        }

        if (['length', 'width', 'height'].includes(param) && errors.size_drc) {
            resetError('size_drc');
        }

        if (errors[param]) {
            resetError(param);
        }

        resetErrorUpdatePackage(param);
    }

    doGetSuggestBoxSizes = lodash.debounce(this.props.fetchSuggestBoxSizes, 300);
    doGetSuggestsByVolume = lodash.debounce(this.props.getSuggestsByVolume, 300);

    componentDidMount() {
        const { input } = this.state;
        const { boxSizes, services, clearSuggestBoxSizes, fetchSuggestBoxSizes, getSuggestsByVolume } = this.props;

        this.handleFocusInput();

        if (!input.id_box_size) {
            this.packingPackage();
        }

        if (!lodash.get(boxSizes, 'length')) {
            getSuggestsByVolume({
                length: input.length,
                width: input.width,
                height: input.height
            });
        }

        if (services && services.length) {
            fetchSuggestBoxSizes({
                id_services: services,
                successHandler: () => {
                    if (!input.id_box_size) {
                        this.packingPackage();
                    }
                }
            });
        } else {
            clearSuggestBoxSizes();
        }
    }

    componentDidUpdate(prevProps) {
        const { inputFocusId, services } = this.props;

        if (prevProps.inputFocusId !== inputFocusId) {
            this.handleFocusInput();
        }

        if (!lodash.isEqual(services, prevProps.services)) {
            this.onChangeInput('id_box_size', undefined);

            this.doGetSuggestBoxSizes({
                id_services: services,
                successHandler: () => {
                    this.packingPackage();
                }
            });
        }
    }

    onIdBoxSizeChange = idBoxSize => {
        const boxSize = this.getBoxSizeById(idBoxSize);

        this.onChangeInput('weight_box', lodash.get(boxSize, 'weight', 0), () => {
            this.onChangeInput('volume_box', lodash.get(boxSize, 'volume', 0));
        });
    };


    getBoxSizeById = id => {
        const { boxSizes } = this.props;

        return lodash.get(lodash.find(boxSizes, item => lodash.get(item, 'box_size.id') === id), 'box_size');
    };

    handleFocusInput() {
        let element = inputFocusParser.parse(this.props.inputFocus, this.props.package.id);

        let elements = [
            ELEMENT.PACKAGE_NET_WEIGHT,
            ELEMENT.PACKAGE_LENGTH,
            ELEMENT.PACKAGE_WIDTH,
            ELEMENT.PACKAGE_HEIGHT,
            ELEMENT.PACKAGE_WEIGHT_BOX
        ];
        if (elements.includes(element)) {
            this.elements.focus(element);
        }
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    onPressEnter = event => {
        const {input} = this.state;
        const {boxSizes, isNeedSubmit, onPressEnter, onSubmit} = this.props;
        const data = convertUndefinedPropertyToNull(input);

        if (!boxSizes || !boxSizes.length) {
            data.id_box_size = null;
            data.weight_box = 0;
        }

        onPressEnter(event.target.name);
        onSubmit({
            ...data,
            isNeedSubmit: isNeedSubmit !== false
        });
    };

    onResetDimension(event) {
        event.preventDefault();
        let { input } = this.state;
        const { form, clearSuggestByVolumeBoxSizes } = this.props;

        input = {
            ...input,
            ...{
                length: 0,
                width: 0,
                height: 0,
                id_box_size: null,
                weight_box: 0
            }
        };

        form.setFieldsValue({
            id_box_size: undefined,
            weight_box: undefined
        });
        this.changeInput({
            length: 0,
            width: 0,
            height: 0,
            id_box_size: undefined,
            weight_box: undefined
        }, () => {
            clearSuggestByVolumeBoxSizes();
        });

        const { isWapper, isNeedSubmit } = this.props;

        if (isWapper) {
            this.props.onSubmit({...input, isNeedSubmit: isNeedSubmit !== false});
        } else {
            this.props.onUpdatePackageParams(input);
        }
    }

    getWarningParams(param) {
        const configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        const {setting, t, validate} = this.props;

        let warning = {};
        let warningValue = 0;
        let maxValue = 0;
        let valueState = 0;
        warningValue = setting[`${param}_warning`] || null;
        maxValue = setting[`${param}_max`] || null;
        const require_volume = lodash.get(this.props.package, 'required_contents', []).includes("volume");
        switch (param) {
            case 'length':
            case 'width':
            case 'height':
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
                valueState = this.state.input[param];

                if (require_volume && Number(valueState) === 0) {
                    warning['status'] = validate ? 'error' : '';
                    warning['message'] = validate ? t(`package:message.update_${param}_greater`) : '';
                    warning['value'] = '';
                } else if (warningValue && valueState > warningValue) {
                    warning['status'] = 'warning';
                    warning['message'] = t('package:message.warning_value',
                        {
                            attribute: t('package:label.' + param),
                            value: maxValue + 'cm'
                        });
                    warning['value'] = maxValue;
                } else if (maxValue && valueState > maxValue) {
                    warning['status'] = 'error';
                    warning['message'] = t('package:message.max_value',
                        {
                            attribute: t('package:label.' + param),
                            value: maxValue + 'cm'
                        });
                    warning['value'] = maxValue;
                }
                break;
            case 'weight':
                let weight_net = this.state.input['weight_net'];
                if (configPackageUnitWeight === 'kg') {
                    weight_net = weight_net * 10
                }
                if (warningValue && weight_net > maxValue) {
                    warning['status'] = 'error';
                    warning['message'] = 'package:message.update_weight_max_numeric';
                    warning['value'] = maxValue;
                } else if (warningValue && weight_net > warningValue) {
                    warning['status'] = 'warning';
                    warning['message'] = 'package:message.update_weight_warning';
                    warning['value'] = maxValue;
                } else if (!weight_net || lodash.isNil(weight_net) || lodash.isUndefined(weight_net)) {
                    warning['status'] = validate ? 'error' : '';
                    warning['message'] = validate ? 'package:message.update_weight_placeholder' : '';
                    warning['value'] = '';
                } else if (parseFloat(weight_net) === 0) {
                    warning['status'] = validate ? 'error' : '';
                    warning['message'] = validate ? 'package:message.update_weight_greater' : '';
                    warning['value'] = '';
                } else {
                    warning['status'] = '';
                    warning['message'] = '';
                    warning['value'] = '';
                }
                break;
            default:
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
        }

        return warning;
    }


    getInputStatusClass(hasError, hasWarning) {
        if (hasError) {
            return 'a-input--error';
        }

        if (hasWarning) {
            return 'a-input--warning';
        }

        return '';
    }

    packingPackage = () => {
        const { boxSizes } = this.props;

        const input = {...this.state.input};
        const volume = (parseFloat(input.width || 0) * parseFloat(input.height || 0) * parseFloat(input.length || 0)) / 1000000;
        const box = lodash.find(boxSizes, (value, index) => {
            const boxSize = value.box_size || {};

            return boxSize.volume >= volume && lodash.get(boxSizes[index - 1], 'box_size.volume', 0) < volume
        });
        this.onChangeInput('id_box_size', lodash.get(box, 'box_size.id'));
    };

    doPackingPackage = lodash.debounce(this.packingPackage, 350);

    render() {
        let {className, t, form, loading, boxSizes, boxSizesSuggestByVolume, loadingBoxSizes, services, validate, errors} = this.props;
        let packageData = this.props.package;
        let {input} = this.state;
        let isShowVolumeByWeightBox = (lodash.isNil(input.width) || input.width === 0)
            && (lodash.isNil(input.height) || input.height === 0)
            && (lodash.isNil(input.length) || input.length === 0);
        let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        let helpWeightNet = t(this.getWarningParams('weight')['message'], {value: this.getWarningParams('weight')['value'] ? this.getWarningParams('weight')['value'] / 10 : ''});

        let localVolume = (parseFloat(input.width || 0) * parseFloat(input.height || 0) * parseFloat(input.length || 0));
        const require_volume = lodash.get(packageData, 'required_contents', []).includes("volume");
        const {getFieldDecorator} = form;

        return (
            <Row gutter={{lg: 24, xl: 32}} className={clsx('a-block-info bg-gray apb-16', className)}>
                <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                    <h4>{t('package:detail.package_param')}</h4>
                </Col>
                <Form onSubmit={this.props.onSubmit} className="a-form form--package form-package-lg">
                    <Col span={24}>
                        <Row gutter={{lg: 24, xl: 32}} type="flex">
                            <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 6}} className="pr-0 ">
                                <Form.Item
                                    labelCol={{span: 24}}
                                    className="a-form__item a-form--loading loading--volumn-package _weight-net-form-item"
                                    label={t('package:label.weight') + ' (' + configPackageUnitWeight + ')'}
                                    required={validate}
                                    validateStatus={this.getWarningParams('weight')['status']}
                                    help={helpWeightNet}
                                >
                                    <InputNumber
                                        tabIndex={1}
                                        size="large"
                                        autoComplete="off"
                                        name={ELEMENT.PACKAGE_NET_WEIGHT}
                                        precision={2}
                                        min={0}
                                        disabled={loadingBoxSizes || !lodash.get(packageData, 'permissions.can_update', false)}
                                        value={input.weight_net || ''}
                                        placeholder={t('package:label.weight_net')}
                                        className="a-input a-input--bill-code-large _package-weight"
                                        onChange={this.onChangeInput.bind(this, 'weight_net')}
                                        onPressEnter={this.onPressEnter}
                                        ref={this.elements.set.bind(this.elements, ELEMENT.PACKAGE_NET_WEIGHT)}
                                    />
                                    {loading ? <Icon type="loading-3-quarters"
                                                     className="icon-loading"/> : null}
                                    {configPackageUnitWeight === 'g' &&
                                    <span className="a-text--black-45 ml-3">x 100</span>}

                                </Form.Item>
                            </Col>
                            <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 12}} className="pr-0 ">
                                <div className="a-package__body--group-size">
                                    <Form.Item
                                        className="a-form__item _package-size-form-item"
                                        label={t('package:label.dimension') + ' (cm)'}
                                        required={require_volume}
                                        help={errors.volume || errors.size_drc}
                                        validateStatus={
                                            errors.volume ||
                                            errors.size_drc ||
                                            this.getWarningParams('length')['status'] === 'error' ||
                                            this.getWarningParams('width')['status'] === 'error' ||
                                            this.getWarningParams('height')['status'] === 'error' ? 'error' : null}
                                        labelCol={{span: 24}}>
                                        <div className="group--input-bill-code-small">
                                            {['length', 'width', 'height'].map((param, index) => (
                                                <div key={param} className="a-form--loading loading-input mr-4">
                                                    <InputNumber
                                                        tabIndex={index+2}
                                                        size="large"
                                                        autoComplete="off"
                                                        ref={this.elements.set.bind(this.elements, param)}
                                                        name={param}
                                                        disabled={loadingBoxSizes || !lodash.get(packageData, 'permissions.can_update', false)}
                                                        placeholder={t('package:placeholder.' + param)}
                                                        title={t('package:label.' + param)}
                                                        className={`a-input a-input--package-size a-input--package-size--large _package-size order-${index+2} ` +
                                                        this.getInputStatusClass(this.getWarningParams(param)['status'] === 'error',
                                                            this.getWarningParams(param)['status'] === 'warning') + param}
                                                        value={input[param] === 0 ? '' : (input[param] || '') || ''}
                                                        precision={1}
                                                        min={0}
                                                        onChange={this.onChangeInput.bind(this, param)}
                                                        onPressEnter={this.onPressEnter}
                                                    />
                                                    {loading ? <Icon type="loading-3-quarters"
                                                                     className="icon-loading"/> : null}
                                                </div>
                                            ))}

                                            {localVolume && !isShowVolumeByWeightBox ?
                                                <span
                                                    className="bill-code-sub _volume">= {localVolume} (cm<sup>3</sup>)</span> : null}
                                        </div>

                                        {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'error' ? (
                                            <p key={param}
                                               className="ant-form-explain mb-0 mt-1">{this.getWarningParams(param)['message']}</p>
                                        ) : null)}

                                        {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'warning' ? (
                                            <p key={param}
                                               className="ant-form-explain mb-1 mt-1">{this.getWarningParams(param)['message']}</p>
                                        ) : null)}
                                        {!loadingBoxSizes && !!lodash.get(packageData, 'permissions.can_update', false) && (
                                            <div className="mt-2">
                                                <a onClick={this.onResetDimension.bind(this)} href="/" className="link-reload _reload-parameter-package">
                                                    <Icon type={loading.dimension ? 'loading' : 'close'} />
                                                    {t('package:btn.reset_dimension')}
                                                </a>
                                            </div>
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 6}} className="pr-2 ">
                                <div className="info--group package--block-volumn _weight-converted">
                                    <label className="info--label _label">{t('package:label.weight_converted')}</label>
                                    <span className="info--value _value">
                                        {lodash.round(lodash.get(this.props.package, 'weight_converted', 0), 4) || '--'}
                                        {lodash.get(this.props.package, 'weight_converted') ? 'kg' : ''}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col className={clsx({
                        'a-hidden': !lodash.get(boxSizes, 'length')
                    })} span={24}>
                        <Row gutter={{lg: 24, xl: 32}}>
                            <Col lg={{span: 24}} xl={{span: 8}} xxl={{span: 6}}>
                                <Form.Item
                                    labelCol={{span: 24}}
                                    className="a-form__item a-form--loading loading--volumn-package _id-box-size-form-item"
                                    label={t('package:label.id_box_size')}
                                >
                                    {getFieldDecorator('id_box_size', {
                                        defaultValue: !loadingBoxSizes && input.id_box_size > 0 ? input.id_box_size : undefined,
                                        initialValue: !loadingBoxSizes && input.id_box_size > 0 ? input.id_box_size : undefined,
                                        rules: [{
                                            required: isRequireWeightBox(packageData) && boxSizes && boxSizes.length && services && services.length,
                                            message: t('package:message.update_box_size_warning')
                                        }]
                                    })(
                                        <Select
                                            allowClear
                                            className="a-select _id-box-size"
                                            dropdownClassName="_dropdown-id-box-size"
                                            onChange={this.onChangeInput.bind(this, 'id_box_size')}
                                            placeholder={t('package:label.id_box_size')}
                                            loading={loadingBoxSizes}
                                            disabled={loadingBoxSizes || !lodash.get(packageData, 'permissions.can_update', false) || !(boxSizes && boxSizes.length)}
                                        >
                                            {boxSizes && boxSizes.length ? boxSizes.map(item => (
                                                <Option
                                                    className="_id-box-size-item"
                                                    key={item.box_size.id}
                                                    value={item.box_size.id}
                                                >
                                                    {item.box_size.code}
                                                </Option>
                                            )) : null}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 24}} xl={{span: 8}} xxl={{span: 12}}>
                                <Form.Item
                                    className="a-form__item box-weight-control _weight-box-form-item"
                                    label={`${t('package:label.weight_box')} (kg)`}
                                    required={isRequireWeightBox(packageData)}
                                >
                                    {getFieldDecorator('weight_box', {
                                        defaultValue: !lodash.isNil(input.weight_box) ? input.weight_box : '',
                                        initialValue: !lodash.isNil(input.weight_box) ? input.weight_box : '',
                                        rules: [{
                                            validator: async (rule, value, callback) => {
                                                const {setting, t} = this.props;
                                                const warningValue = setting.weight_warning || null;
                                                const maxValue = setting.weight_max || null;

                                                if (!Number(value) && isRequireWeightBox(packageData)) {
                                                    throw new Error(t('package:message.update_weight_box_warning'));
                                                }

                                                if (value && warningValue && value > maxValue) {
                                                    throw new Error(t('package:message.update_weight_box_max_numeric', {
                                                        value: maxValue ? maxValue / 10 : ''
                                                    }));
                                                }

                                                callback();
                                            }
                                        }]
                                    })(
                                        <InputNumber
                                            className="_weight-box"
                                            placeholder={t('package:label.weight_box')}
                                            disabled={!lodash.get(packageData, 'permissions.can_update', false) || !(boxSizes && boxSizes.length)}
                                            onChange={this.onChangeInput.bind(this, 'weight_box')}
                                            ref={this.elements.set.bind(this.elements, 'weight_box')}
                                            onPressEnter={this.onPressEnter}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    {lodash.get(boxSizesSuggestByVolume, 'length') && !lodash.get(boxSizes, 'length') && input.length && input.width && input.height ? (
                        <Col span={24}>
                            <BoxSizesSuggestByVolume
                                boxSizes={boxSizesSuggestByVolume}
                                className="amb-16"
                                hintParams={{
                                    length: input.length,
                                    width: input.width,
                                    height: input.height
                                }}
                            />
                        </Col>
                    ) : null}

                    <Col span={24}>
                        <Form.Item
                            className="a-form__item a-form--loading loading-texarea _note-form-item"
                            help={errors.note}
                            label={t('package:label.note')}
                            labelCol={{span: 24}}
                            validateStatus={errors.note ? 'error' : null}
                        >
                            <TextArea
                                autosize={{minRows: 1, maxRows: 3}}
                                className="a-input--textarea _note-package"
                                disabled={!lodash.get(packageData, 'permissions.can_update', false)}
                                name={ELEMENT.NOTE}
                                placeholder={t('package:placeholder.note')}
                                spellCheck={false}
                                value={input.note}
                                onChange={this.onChangeInput.bind(this, 'note')}
                                onPressEnter={this.onPressEnter}
                            />
                            {loading ? (
                                <Icon type="loading-3-quarters" className="icon-loading" />
                            ) : null}
                        </Form.Item>
                    </Col>
                </Form>
            </Row>
        );
    }
}

PackageParams.defaultProps = {
    form: {
        getFieldDecorator: () => component => component
    },
    input: {},
    errors: {},
    onChange: () => {
    },
    isWapper: false,
    setting: {},
    loading: false,
    inputFocus: null,
    inputFocusId: 0,
    validate: true
};

PackageParams.propTypes = {
    input: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    isWapper: PropTypes.bool,
    setting: PropTypes.object,
    onPressEnter: PropTypes.func,
    validate: PropTypes.bool,

};

export default withTranslation()(PackageParams);
