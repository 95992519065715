import React from 'react';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { dateFormatter } from './../../../Common/services/format';
import { getVar } from './../../../../system/support/helpers';

const Logs = ({ logs, t }) => {
    const renderLogMessage = log => {
        const { payload } = log;

        switch (log.action) {
            case 'PACKAGE.CHECKING': {
                return t('package:log.PACKAGE_TRACKING_SHORT', {
                    quantity: getVar(payload, 'package_item.received_quantity')
                });
            }
            default: {
                return null;
            }
        }
    };

    return (
        <PerfectScrollbar
            className="mhpx-200"
            options={{
                suppressScrollX: true,
                wheelPropagation: false
            }}
        >
            {logs.map(log => (
                <div className="a-text--fz-12" key={log.id}>
                    <span className="a-text--black-45">{dateFormatter.full(log.created_at)} - {getVar(log, 'creator.username', '--')}</span>: <b>{renderLogMessage(log)}</b>
                </div>
            ))}
        </PerfectScrollbar>
    );
};

export default withTranslation()(Logs);
