import {all, fork} from 'redux-saga/effects';
import listShippingPartnerWatcher from './ListShippingPartner/sagaWatcher';
import createShippingPartnerWatcher from './CreateShippingPartner/sagaWatcher';
import editShippingPartnerWatcher from './EditShippingPartner/sagaWatcher';

export default function*() {
    yield all([
        fork(listShippingPartnerWatcher),
        fork(createShippingPartnerWatcher),
        fork(editShippingPartnerWatcher)
    ]);
}
