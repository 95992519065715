import React, { Component, Fragment } from 'react';
import { Button, Icon, Table } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';

import { INVENTORY_STATUS_PROCESSING } from './../../../constants';
import { t } from '../../../../../system/i18n';

import { Link } from '../../../../../system/routing';

class Remainder extends Component {
    state = {
        selectedRowKeys: []
    };

    handleResolveInventoryTrackingBill = trackingBill => {
        const { inventory, resolveInventoryTrackingBill } = this.props;
        const inventoryStatus = get(inventory, 'status');
        const isResolved = get(trackingBill, 'inventory_tracking_bill.is_resolved');
        const canProcessInventory = inventoryStatus === INVENTORY_STATUS_PROCESSING;

        if (!canProcessInventory || trackingBill.loading || isResolved) {
            return;
        }

        const data = {
            id: get(trackingBill, 'inventory_tracking_bill.id'),
            is_resolved: true
        };

        resolveInventoryTrackingBill(data);
    };

    handleResolveInventoryTrackingBillsButtonClick = data => {
        const { inventory, resolvingInventoryTrackingBills, resolveInventoryTrackingBills } = this.props;

        if (resolvingInventoryTrackingBills || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return;
        }

        resolveInventoryTrackingBills(data);
    };

    handleChangeSelectedRowKeys = keys => {
        this.setState({
            selectedRowKeys: keys
        });
    };

    render() {
        const { selectedRowKeys } = this.state;
        const { inventory, loading, remainders, resolvingInventoryTrackingBills } = this.props;
        const columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:column.code.receive_package'),
            dataIndex: 'inventory_tracking_bill.tracking_bill',
            key: 'tracking_bill'
        }, {
            title: t('package_list'),
            dataIndex: 'inventory_tracking_bill.related_packages',
            key: 'related_packages',
            render: packages => packages && packages.length ? packages.map((pkg, index) => (
                <Fragment key={pkg}>
                    <Link isNewTab params={{id: pkg}} to="packages.detail">{pkg}</Link>
                    {index < packages.length - 1 ? ', ' : null}
                </Fragment>
            )) : '--'
        }, {
            title: (
                <Button
                    className="_btn-processes a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryTrackingBillsButtonClick.bind(this, {
                        ids: selectedRowKeys,
                        is_resolved: true
                    })}
                >
                    <Icon type={resolvingInventoryTrackingBills ? 'loading' : 'check'} />
                    <span className="ml-2">{t('inventory:processed')}</span>
                </Button>
            ),
            key: 'processed',
            render: (text, record) => {
                const { inventory } = this.props;
                const isResolved = get(record, 'inventory_tracking_bill.is_resolved');
                const inventoryStatus = get(inventory, 'status');
                const canProcessInventory = inventoryStatus === INVENTORY_STATUS_PROCESSING;

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action', {
                                active: isResolved,
                                'disable-active': !canProcessInventory  && isResolved,
                                'disable-unactive': !canProcessInventory && !isResolved
                            })}
                            onClick={this.handleResolveInventoryTrackingBill.bind(this, record)}
                            type={record.loading ? 'loading' : 'check'}
                        />
                    </div>
                );
            }
        }];

        return (
            <Table
                className="a-table--antd a-table--antd-scroll-x"
                columns={columns}
                loading={loading}
                dataSource={remainders}
                pagination={false}
                rowSelection={{
                    selectedRowKeys,
                    onChange: this.handleChangeSelectedRowKeys
                }}
                scroll={{ x: 'fit-content' }}
                rowKey={row => get(row, 'inventory_tracking_bill.id')}
            />
        );
    }
}

export default Remainder;
