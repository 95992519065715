import React from 'react';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from '../../../../../Common/services/format';

import QuickEditUpdateDelivery from './QuickEditUpdateDelivery';
import QuickEditTrackingNumber from './QuickEditTrackingNumber';

const InfoShippingPartnerDeliveryNote = props => {
    const { deliveryNote, permissions, t } = props;

    return (
        <div className="block--primary a-block-info abg-white detail-delivery-note">
            <div className="title">
                <span>{t('transport_warehouse_delivery_note_info')}</span>
            </div>
            <div className="body">
            <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('partner')}</label>
                    <span className="info--value">
                        {get(deliveryNote, 'shipping_partner.name', '--')}
                    </span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.creator')}</label>
                    <span className="info--value">{get(deliveryNote, 'creator.name')}</span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.created_at')}</label>
                    <span className="info--value">
                        {dateFormatter.dateTime(get(deliveryNote, 'delivery_note_transport_warehouse.created_at'))}
                    </span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.status')}</label>
                    <span className="info--value">
                        {get(deliveryNote, 'delivery_note_transport_warehouse.status') ? t(`delivery_note:status.${get(deliveryNote, 'delivery_note_transport_warehouse.status')}`) : '--'}
                    </span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.exporter')}</label>
                    <span className="info--value">{get(deliveryNote, 'exporter.name', '--')}</span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.exported_at')}</label>
                    <span className="info--value">
                        {get(deliveryNote, 'delivery_note_transport_warehouse.exported_at') ? dateFormatter.dateTime(get(deliveryNote, 'delivery_note_transport_warehouse.exported_at')) : '--'}
                    </span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.warehouse')}</label>
                    <span className="info--value">{get(deliveryNote, 'warehouse.code', '--')}</span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <QuickEditTrackingNumber
                        {...props}
                        id={get(deliveryNote, 'delivery_note_transport_warehouse.id')}
                        permissions={get(permissions, 'can_update_direction')}
                        value={get(deliveryNote, 'delivery_note_transport_warehouse.partner_tracking_number')}
                    />
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <label className="info--label">{t('delivery_note:label.car_number')}</label>
                    <span className="info--value">{get(deliveryNote, 'delivery_note_transport_warehouse.car_number', '--')}</span>
                </Col>
                <Col lg={{span: 12}} xl={{span: 8}} className="info--group">
                    <QuickEditUpdateDelivery
                        {...props}
                        value={get(deliveryNote, 'delivery_note_transport_warehouse.note')}
                        permissions={get(permissions, 'can_update_direction')}
                        id={get(deliveryNote, 'delivery_note_transport_warehouse.id')}
                    />
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default withTranslation()(InfoShippingPartnerDeliveryNote);
