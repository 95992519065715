import React, { useCallback, useState } from 'react';
import { Button, Typography } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import apiService from './apiService';

import ComplaintSellerModal from './components/ComplaintSellerModal';

const { Paragraph } = Typography;

const ComplaintSeller = ({ complaintSeller, loading, onClick }) => {
    return (
        <Button onClick={onClick} loading={loading} type="link">
            {`CS_${complaintSeller}`}
        </Button>
    );
};

export const useComplaintSellersDisplay = ({ className, complaintSellers, displayLabel  = true, packageId }) => {
    const [loadings, setLoadings] = useImmer({});
    const [complaintSeller, setComplaintSeller] = useState({});
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const handleCloseComplaintSellerModal = useCallback(() => {
        setComplaintSeller({});
        setVisible(false);
    }, []);

    const handleComplaintSellerButtonClick = async index => {
        setLoadings(loadings => {
            loadings[index] = true;
        });

        try {
            const response = await apiService.getComplaintSeller(packageId, complaintSellers[index]);

            setComplaintSeller(get(response, 'data.data'));
            setVisible(true);
        } catch (error) {
        }

        setLoadings(loadings => {
            loadings[index] = false;
        });
    };

    if (isEmpty(complaintSellers)) {
        return null;
    }

    return (
        <>
            <Paragraph className={className}>
            {displayLabel ? `${t('complaint_seller')}: ` : null}{isEmpty(complaintSellers) ? null : map(complaintSellers, (complaintSeller, index) => (
                <ComplaintSeller
                    complaintSeller={complaintSeller}
                    key={complaintSeller}
                    loading={!!loadings[index]}
                    onClick={handleComplaintSellerButtonClick.bind(this, index)}
                />
            ))}
        </Paragraph>
            <ComplaintSellerModal complaintSeller={complaintSeller} visible={visible} onCancel={handleCloseComplaintSellerModal} />
        </>
    );
};
