import React, { useEffect } from 'react';
import { Layout, Spin } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { router } from './../../../../system/routing';

import PictureWall from './PictureWall';

const UploadDamageImages = ({ loading, location, history, fetchPackageDetail, t, ...other }) => {
    useEffect(() => {
        const locationQuery = router.getLocationQuery();

        router.replace(`${location.pathname}?code=${locationQuery.code}`);

        fetchPackageDetail(locationQuery.code);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isDamage = get(other.package, 'package_detail.is_damage');

    return (
        <Layout className={clsx('upload-damaged-images', {
            'gflex galign-center gjustify-center ap-16': !isDamage
        })}>
            <Spin spinning={loading}>
                {loading ? null : isDamage ? (
                    <PerfectScrollbar
                        options={{
                            wheelPropagation: false
                        }}
                    >
                        <PictureWall
                            damagedImages={get(other.package, 'package_detail.damaged_images', [])}
                            packageId={get(other.package, 'id')}
                            sorted
                        />
                    </PerfectScrollbar>
                ) : t('package:package_is_not_damaged')}
            </Spin>
        </Layout>
    );
};

export default UploadDamageImages;
