/* eslint-disable */
import React from 'react';
import {Layout} from "antd";
import FormSearch from './components/FormSearch';
import ListBag from './components/ListBag';
const {Content} = Layout;

class ListBagContainer extends React.PureComponent{
    render() {
        return(
            <Layout>
                <Content className="a-content a-content--page-list">
                    <FormSearch/>
                    <ListBag/>
                </Content>
            </Layout>
        )
    }
}
export default ListBagContainer;