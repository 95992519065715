import React, { Component, createRef } from 'react';
import { Layout, Row } from 'antd';
import { get, isEqual, parseInt } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { STAGES } from './../../../Notes/constants';

import CheckingPackageLogs from './CheckingPackageLogs';
import FormScan from './FormScan';
import PackageInfo from './PackageInfo';
import UpdateProducts from './UpdateProducts';
import CheckingPackageProducts from './../../CheckingPackageProducts/CheckingPackageProductsContainer';
import Attentions from './../../../Common/components/Attentions';

import Background from './../../../../resources/images/bar-code.png';
import { linkHTC } from '../../helper';

const { Content } = Layout;

class CheckingPackage extends Component {
    state = {
        scanInput: createRef()
    };

    handleReceiveAll = () => {
        const { onReceiveAll } = this.props;

        onReceiveAll();
    };

    handleReceiveAllLastProduct = () => {
        const { scanInput } = this.state;

        if (scanInput.current) {
            scanInput.current.focus();
            scanInput.current.select();
        }
    };

    handleSuccessUpdateProducts = data => {
        const { onSuccessUpdateProducts } = this.props;

        onSuccessUpdateProducts(data);
    };

    render() {
        const { scanInput } = this.state;
        const { filter, loading, onScan } = this.props;

        return (
            <Content className={`a-content pl-0 gflex gflex-direction-column min-height-not-header ${!filter.code ? 'a-content--height-not-header' :'' }`}>
                {loading ? <div className="a-loading-page__icon"/> : null}
                <FormScan
                    input={filter}
                    inputRef={scanInput}
                    inputVersion={filter.code}
                    onSubmit={onScan}
                />
                {this.renderContent()}
            </Content>
        )
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.package, prevProps.package)) {
            const { getSuggestsByVolume } = this.props;

            getSuggestsByVolume({
                length: parseInt(get(this.props.package, 'length', 0) * 100),
                width: parseInt(get(this.props.package, 'width', 0) * 100),
                height: parseInt(get(this.props.package, 'height', 0) * 100)
            });
        }
    }

    renderContent() {
        const {
            boxSizesSuggestByVolume,
            filter,
            notes,
            notices,
            totalAmount,
            packageItems,
            partnerSplitPackage,
            successUpdateReceivedQuantitiesCount,
            loadingProducts,
            products,
            warnings,
            onSuccessFinishCheckingPackage,
            onSuccessUpdateDamagedProperty,
            onSuccessUpdateNote,
            onSuccessUpdateReceivedQuantities,
        } = this.props;
            const linkHtc = linkHTC(get(this, 'props.package.agency.code'))

        if (!filter.code) {
            return (
                <div className="a-content--empty scanpackage a-bgwhite height-100 w100-24 aflex-1">
                    <img src={Background} alt="" />
                    <span>Chưa có dữ liệu <br/>quét mã</span>
                </div>
            );
        }

        if (this.props.package && this.props.package.id) {
            const placeOfficalStamp = get(this.props.package, "permissions.place_display_official_stamp", []);
            return (
                <Row type="flex" className="aposition-reletive height-100 a-flex-1">
                    <div className="a-content--scroll-is-box-chat">
                        <Attentions
                            className="amb-14 checking-package__attentions"
                            notes={notes}
                            notices={get(notices, 'text', [])}
                            stage={STAGES.CHECKING_PACKAGE}
                            onSuccessUpdateNote={onSuccessUpdateNote}
                        />
                        <PackageInfo
                            allowPrintOfficalStamp={placeOfficalStamp.includes("package_checking")}
                            boxSizesSuggestByVolume={boxSizesSuggestByVolume}
                            totalAmount={totalAmount}
                            package={this.props.package}
                            partnerSplitPackage={partnerSplitPackage}
                            warnings={warnings}
                            onSuccessUpdateDamagedProperty={onSuccessUpdateDamagedProperty}
                            orderCode={get(this.props.package, 'order.code')}
                            linkHtc={linkHtc}
                        />
                        <UpdateProducts
                            code={filter.code}
                            fetchPackage={this.props.fetchPackage}
                            isUnLinkOrderSuccess={this.props.isUnLinkOrderSuccess}
                            loading={loadingProducts}
                            packageItems={packageItems}
                            pkg={this.props.package}
                            onSuccessUpdate={this.handleSuccessUpdateProducts}
                        />
                        <CheckingPackageProducts
                            {...this.props}
                            loading={loadingProducts}
                            packageId={this.props.package.id}
                            package={this.props.package}
                            products={products}
                            successUpdateReceivedQuantitiesCount={successUpdateReceivedQuantitiesCount}
                            onReceiveAll={this.handleReceiveAll}
                            onReceiveAllLastProduct={this.handleReceiveAllLastProduct}
                            onSuccessFinishCheckingPackage={onSuccessFinishCheckingPackage}
                            onSuccessUpdateReceivedQuantities={onSuccessUpdateReceivedQuantities}
                        />
                    </div>
                    <CheckingPackageLogs
                        packageId={this.props.package.id}
                        orderId={get(this.props.package, 'order.id')}
                        orderCode={get(this.props.package, 'order.code')}
                        linkHtc={linkHtc}
                    />
                </Row>
            );
        }

        return null;
    }
}

CheckingPackage.defaultProps = {
    filter: {},
    package: {},
    loading: false,
    onScan: () => {},
    onReceiveAllLastProduct: () => {},
    checkingItems: {}
};

CheckingPackage.propTypes = {
    filter: PropTypes.object,
    package: PropTypes.object,
    loading: PropTypes.bool,
    onScan: PropTypes.func,
};

export default withTranslation()(CheckingPackage);
