import auth from '../authService';

class UserSettingService {
    constructor(auth) {
        this.auth = auth;
    }

    get(param, defaultValue = null) {
        let setting = localStorage.getItem(param + '_' + this.auth.user('id'));

        return setting ? JSON.parse(setting) : defaultValue;
    }

    set(param, value) {
        localStorage.setItem(this.getStorageKey(param), JSON.stringify(value));
    }

    getStorageKey(param) {
        return param + '_' + this.auth.user('id');
    }
}

let userSettingService = new UserSettingService(auth);

export default userSettingService;