import { get } from 'lodash';

import authService from '../authService';

/**
 * Middleware kiểm tra nếu client chưa login thì sẽ redirect đên page logic
 */
export default (payload, next) => {
    if (!authService.isLogged()) {
        const location = get(payload, 'request.location');

        authService.login(location);

        return;
    }

    next(payload);
};