import React, { Component } from 'react';
import { Button, Icon, Skeleton } from 'antd';
import { withTranslation } from 'react-i18next';
import { get, map } from 'lodash';

import { INVENTORY_STATUS_CHECKING } from './../../../constants';
import { trans } from './../../../../../system/i18n';

import ErrorItem from './ErrorItem';
import SuccessItem from './SuccessItem';
import WarningItem from './WarningItem';
import CheckingInventory from './../../../CheckingInventory/CheckingInventoryContainer';
import UpdateStatusInventory from './../../../UpdateStatusInventory/UpdateStatusInventoryContainer';

import Background from './../../../../../resources/images/bar-code.png';

const Sort = (
	<div className="sort">
		<Icon type="caret-up" className="active"/><Icon type="caret-down"/>
	</div>
);

class Packages extends Component {
	renderRow = (item, index) => {
		if (item.inventory_tracking_bill.loading) {
			return null;
		}

		if (item.warnings && item.warnings.length) {
			return (
				<WarningItem
					index={index}
					item={item}
					key={index}
				/>
			);
		}

		switch (item.inventory_tracking_bill.situation) {
			case 'exists': {
				return (
					<ErrorItem
						index={index}
						item={item}
						key={index}
					/>
				);
			}
			case 'duplicate': {
				return (
					<WarningItem
						index={index}
						item={item}
						key={index}
					/>
				);
			}
			default: {
				return (
					<SuccessItem
						index={index}
						item={item}
						key={index}
					/>
				);
			}
		}
	};

	render() {
		const { inventory: { inventory }, t, loading, scanLoading, inventoryTrackingBills } = this.props;
		const histories = inventoryTrackingBills.slice(0, 300);

		return (
			<div className="a-content a-content--page-list gflex gflex-direction-column a-flex-1">
				<div className="a-content__top a-content__top--page-list">
					<div className="a-content--page-list__title">
						<h3>
							{trans('inventory:title.history_scan', {
								count: histories.length
							})}
						</h3>
					</div>

					<div className="a-content--page-list__top-action">
						<Button
							className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
							disabled={loading || get(inventory, 'status') !== INVENTORY_STATUS_CHECKING}
							ghost
							loading={loading}
							type="primary"
							onClick={() => this.props.fetchInventory(inventory.id)}
						>
							<Icon type="sync" />{t('inventory:btn.history_refresh')}
						</Button>
						<CheckingInventory />
						<UpdateStatusInventory page={INVENTORY_STATUS_CHECKING} />
					</div>
				</div>
				{histories.length > 0 ? (
					<div className="a-content__body bg-white a-content__body--page-list">
						<div className="a-table--responsive">
							<table className="a-table a-table--barcode-scan">
								<thead>
									<tr>
										<th>{t('inventory:column.order_number')}</th>
										<th>{t('inventory:column.code.receive_package')}</th>
										<th>{t('inventory:column.created_at')} {Sort}</th>
										<th>{t('package_list')}</th>
									</tr>
								</thead>

								{loading ? (
									<tbody>
										<tr>
											<td><Skeleton active paragraph={{rows: 0}}/></td>
											<td><Skeleton active paragraph={{rows: 0}}/></td>
											<td><Skeleton active paragraph={{rows: 0}}/></td>
											<td><Skeleton active paragraph={{rows: 0}}/></td>
										</tr>
									</tbody>
								) : (
									<>
										{scanLoading ? (
											<tbody>
												<tr>
													<td><Skeleton active paragraph={{rows: 0}}/></td>
													<td><Skeleton active paragraph={{rows: 0}}/></td>
													<td><Skeleton active paragraph={{rows: 0}}/></td>
													<td><Skeleton active paragraph={{rows: 0}}/></td>
												</tr>
											</tbody>
										): null}
										{map(histories, (history, index) => this.renderRow(history, index))}
									</>
								)}
							</table>
						</div>
					</div>
				) : (
					<div className="a-content a-content--package a-package__scan-empty a-flex-1 ml-0">
						<img src={Background} alt="" />
						<span>{trans('inventory:label.empty_history')} <br/>{t('inventory:label.scan')}</span>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation()(Packages);
