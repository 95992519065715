import React from 'react';
import PropTypes from 'prop-types';
import authService from '../../Auth/authService';
import settingService from './settingService';
import ListBagSetting from './components/ListBagSetting';

class ListBagSettingContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            setting: [],
            page : props.page
        };
    }

    componentDidMount() {
        let setting = settingService.getUserSetting(this.getUserId(), this.getPage(), this.props.object);
        this.setState({setting});
        this.props.onChange(setting);
    }

    onChangeSetting(setting) {
        settingService.updateUserSetting(this.getUserId(), setting, this.getPage(), this.props.object);
        this.setState({setting});
        this.props.onChange(setting);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextState.page !== nextProps.page) {
            nextState.page = nextProps.page;
            settingService.getOption(nextProps.page, nextProps.object);
            let setting = settingService.getPageSetting(authService.user('id'), nextProps.page, nextProps.object);
            nextProps.onChange(setting);
        }
        return nextState
    }

    getUserId() {
        return authService.user('id');
    }

    getPage() {
        return this.props.page
    }

    render() {
        return <ListBagSetting
            options={settingService.options}
            values={this.state.setting}
            onChange={this.onChangeSetting.bind(this)}
            object={this.props.object}
            filter={this.props.filter}
            isOpenListSetting={this.props.isOpenListSetting}
            onOpenListSetting={this.props.onOpenListSetting}
            isCurrentPage={this.props.isCurrentPage}

        />
    }
}

ListBagSettingContainer.defaultProps = {
    onChange: (setting) => {
    },
};

ListBagSettingContainer.propTypes = {
    onChange: PropTypes.func,
};

export default ListBagSettingContainer;
