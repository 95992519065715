import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { setMenuActive } from './../../Common/actions';

import UploadPackageList from './components';
import LostPackagesTab from './components/LostPackagesTab';
import NewPackagesTab from './components/NewPackagesTab';
import SearchableComponent from './../../Common/components/SearchableComponent';

class UploadPackageListContainer extends SearchableComponent {
    state = {
        tabs: [{
            component: <LostPackagesTab />,
            key: 'lost-packages',
            title: this.props.t('package:lost_packages')
        }, {
            component: <NewPackagesTab />,
            key: 'new-packages',
            title: this.props.t('package:create.title')
        }],
        defaultTab: 'lost-packages'
    };

    onSearch = filter => {
        this.pushFilter(filter);
    }

    getCurrentFilter = () => {
        const { defaultTab, tabs } = this.state;
        const { location } = this.props;
        const filter = {
            ...this.getFilterFromLocation(location)
        };

        const tab = tabs.find(tab => tab.key === filter.tab);

        if (!tab) {
            filter.tab = defaultTab;
        }

        return filter;
    };

    render() {
        const { tabs } = this.state;

        return (
            <UploadPackageList
                {...this.props}
                filter={this.getCurrentFilter()}
                tabs={tabs}
                onSearch={this.onSearch}
            />
        );
    }
}

export default withTranslation()(connect(
    undefined,
    {
        setMenuActive
    }
)(UploadPackageListContainer));
