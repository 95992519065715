import React from 'react';
import PropTypes from 'prop-types';

import settingService from './settingService';
import { DELIVERY_OBJECT } from './../constants';
import authService from './../../Auth/authService';

import ListDeliveryNoteSetting from './components/ListContentDeliveryNote/ListListDeliveryNoteSetting';

class ListDeliveryNoteSettingContainer extends React.PureComponent {
    state = {
        setting: [],
        page: this.props.page
    };

    componentDidMount() {
        const setting = settingService.getUserSetting(this.getUserId(), DELIVERY_OBJECT.CUSTOMER);

        this.setState({
            setting
        });
        this.props.onChange(setting);
    }

    onChangeSetting = setting => {
        settingService.updateUserSetting(this.getUserId(), setting, DELIVERY_OBJECT.CUSTOMER);
        this.setState({setting});
        this.props.onChange(setting);
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextState.object !== nextProps.object) {
            nextState.object = nextProps.object;
            settingService.getOption(nextProps.object);
            let setting = settingService.getPageSetting(authService.user('id'), nextProps.object);
            nextProps.onChange(setting);
        }
        return nextState
    }

    getUserId() {
        return authService.user('id');
    }


    render() {
        return (
            <ListDeliveryNoteSetting
                options={settingService.options}
                values={this.state.setting}
                isOpen={this.props.isOpenListSetting}
                onChange={this.onChangeSetting}
                onOpenSetting={this.props.onOpenListSetting}
            />
        );
    }
}

ListDeliveryNoteSettingContainer.defaultProps = {
    onChange: () => {
    },
    isOpenListSetting: false
};

ListDeliveryNoteSettingContainer.propTypes = {
    onChange: PropTypes.func,
    onOpenListSetting: PropTypes.func,
    isOpenListSetting: PropTypes.bool
};

export default ListDeliveryNoteSettingContainer;

