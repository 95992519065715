export const GET_AGENCIES = {
    REQUEST: 'AGENCIES.GET_AGENCIES.REQUEST',
    SUCCESS: 'AGENCIES.GET_AGENCIES.SUCCESS',
    FAILED: 'AGENCIES.GET_AGENCIES.FAILED',
}
export const FETCH_SUGGEST_AGENCIES = {
    REQUEST: 'AGENCIES.FETCH_SUGGEST_AGENCIES.REQUEST',
    SUCCESS: 'AGENCIES.FETCH_SUGGEST_AGENCIES.SUCCESS',
    FAILED: 'AGENCIES.FETCH_SUGGEST_AGENCIES.FAILED',
}

export const AGENCY_FINANCE_TRANSACTION_TYPE = ['DEPOSIT', 'COLLECT']
