import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchBagShippingPartner = createAction(ACTION.FETCH_BAG_SHIPPING_PARTNER.REQUEST);
export const fetchDetailShippingPartner = createAction(ACTION.FETCH_DETAIL_SHIPPING_PARTNER.REQUEST);
export const clearDetailShippingPartner = createAction(ACTION.CLEAR_DETAIL_SHIPPING_PARTNER_STATE);
export const fetchShippingPartnersSuggest = createAction(ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.REQUEST);
export const fetchDomesticShippingPartnersSuggest = createAction(ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.REQUEST, () => ({
    type: ['domestic']
}));
export const getInternationals = createAction(ACTION.GET_INTERNATIONALS.REQUEST);
export const getDomestics = createAction(ACTION.GET_DOMESTICS.REQUEST);
export const getDomesticSuggests = createAction(ACTION.GET_DOMESTIC_SUGGESTS.REQUEST);
export const updateDomestic = createAction(ACTION.UPDATE_DOMESTIC.REQUEST);
export const getLastmileCarrierSuggest = createAction(ACTION.GET_LASTMILE_CARRIER_SUGGEST.REQUEST);

