import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { get, toInteger, filter, isEmpty } from 'lodash'
import { Empty, Icon, Table, Button, notification } from 'antd'
import Pagination from '../../../Common/components/Pagination'
import { dateFormatter } from '../../../Common/services/format'
import Setting from './Setting'
import pageService from '../../../Common/Page/pageService'
import { LIST_COLUMNS } from '../../constants'
import apiService from '../../apiService'
import { convertBlobDataToExcel } from '../../../../system/support/helpers'
import { Link } from '../../../../system/routing'
import DetailDeliveryRequestContainer from '../../../DeliveryRequest/DetailDeliveryRequest/DetailDeliveryRequestContainer'
import { showDetailDeliveryRequest } from '../../../DeliveryRequest/DetailDeliveryRequest/actions'
import { dispatch } from '../../../../system/store'

function List(props) {
    const { pagination, t, loading, data, currentFilter, onSearch, setMenuActive } = props
    const [openSetting, setOpenSetting] = useState(false)
    const [columnsActive, setColumsActive] = useState([])
    const [exportingExcel, setExportingExcel] = useState(false)

    useEffect(() => {
        pageService.setTitle(t('return_goods:title.list'))
        setMenuActive('return_goods')
    }, [])

    function downloadExcel() {
        setExportingExcel(true)
        apiService
            .exportReturnGoodsList(currentFilter)
            .then(res => {
                const data = get(res, 'data')
                convertBlobDataToExcel(data, 'return_goods_list')

                notification.success({
                    message: t('message.export_excel_success'),
                })
            })
            .catch(err => {
                notification.success({
                    message: t('message.export_excel_failed'),
                })
            })
            .finally(() => setExportingExcel(false))
    }
    const columns = [
        {
            title: t('return_goods:label.document_code'),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                const id = get(record, 'id')
                return (
                    <Link
                        isNewTab
                        params={{ id: id }}
                        to="return-goods.detail">
                        {text}
                    </Link>
                )
            },
        },
        {
            title: t('return_goods:label.delivery_note_or_delivery_request_code'),
            dataIndex: ['delivery_note', 'code'],
            key: 'delivery_note_code',
            render: (text, record) => {
                const id = get(record, 'delivery_note.id', get(record, 'delivery_request.id'))
                const delivery_request = get(record, 'delivery_request', {})
                const agencyCode = get(delivery_request, 'agency_code')

                return !isEmpty(text) ? (
                    <Link
                        isNewTab
                        params={{ id: id }}
                        to="delivery-notes.customer.detail">
                        {text}
                    </Link>
                ) : (
                    <span
                        className="a-text--link cursor-pointer"
                        onClick={() =>
                            dispatch(
                                showDetailDeliveryRequest({
                                    code: delivery_request.code,
                                    agency: agencyCode,
                                })
                            )
                        }>
                        {delivery_request.code}
                    </span>
                )
            },
        },
        {
            title: t('return_goods:label.id_confirmation_agent'),
            dataIndex: ['confirmation_agent', 'username'],
            key: 'id_confirmation_agent',
            render: text => (text ? text : '---'),
        },
        {
            title: t('return_goods:label.id_creator'),
            dataIndex: ['creator', 'username'],
            key: 'id_creator',
            render: text => (text ? text : '---'),
        },
        {
            title: t('return_goods:label.id_warehouse'),
            dataIndex: ['warehouse', 'name'],
            key: 'id_warehouse',
        },
        {
            title: t('return_goods:label.status'),
            dataIndex: 'status',
            key: 'status',
            render: text => t(`return_goods:status.${text}`),
        },
        {
            title: t('creation_time'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => (text ? dateFormatter.dateTime(text) : '---'),
        },
        {
            title: t('return_goods:label.confirmation_time'),
            dataIndex: 'confirmed_at',
            key: 'confirmation_time',
            render: text => (text ? dateFormatter.dateTime(text) : '---'),
        },
        {
            title: t('total_weight'),
            dataIndex: 'weight',
            key: 'weight',
            render: text => `${text ? text : 0} kg`,
        },
        {
            title: t('return_goods:label.total_package_in_document'),
            dataIndex: 'packages.data',
            key: 'total_package_in_document',
            render: text => text.length,
        },
    ]

    return (
        <React.Fragment>
            <div className="a-content__top a-content__top--page-list mt-0">
                <div className="a-content--page-list__title">
                    <h3>
                        {t('return_goods:title.list')} ({get(pagination, 'total', 0)})
                    </h3>
                </div>
                <div className="a-content--page-list__top-action gflex galign-center">
                    <Button
                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                        ghost
                        loading={exportingExcel}
                        type="primary"
                        onClick={downloadExcel}>
                        {t('btn.export_excel_file')}
                    </Button>
                    <Setting
                        openSetting={openSetting}
                        setOpenSetting={setOpenSetting}
                        setColumsActive={setColumsActive}
                    />
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                <div className="a-table--responsive">
                    <Table
                        className="a-table--page-list _a-table--page-list"
                        columns={filter(columns, column => columnsActive.includes(column.key))}
                        dataSource={data}
                        loading={loading}
                        locale={{
                            emptyText: loading ? (
                                <Icon
                                    type="loading-3-quarters"
                                    className="icon-loading a-text--black "
                                />
                            ) : (
                                <Empty
                                    className="_empty"
                                    description={<span>Không có dữ liệu </span>}
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"></Empty>
                            ),
                        }}
                        pagination={false}
                        rowKey={record => record.key}
                    />
                </div>
                <Pagination
                    page={toInteger(currentFilter.page) || 1}
                    pageSize={toInteger(currentFilter.per_page)}
                    pageSizeOptions={[20, 50, 100, 200]}
                    total={pagination.total}
                    loading={loading}
                    onChange={(page, pageSize) =>
                        onSearch({
                            ...currentFilter,
                            page,
                            per_page: pageSize,
                        })
                    }
                />
            </div>
            <DetailDeliveryRequestContainer
                route={props.route}
                onSuccessDeliveringDeliveryRequest={() => {}}
            />
        </React.Fragment>
    )
}
export default withTranslation()(List)
