import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LIST_BAG_SHIPPING_TYPE } from './../constants';

import FormBagShippingType from './components/FormBagShippingType';

class FormBagShippingTypeContainer extends React.Component {
    render() {
        const bagShippingTypes = [
            ...LIST_BAG_SHIPPING_TYPE
        ];

        return (
            <FormBagShippingType {...this.props} bagShippingTypes={bagShippingTypes} />
        );
    }
}

const Container = connect(
    undefined,
    undefined,
    undefined,
    {
        forwardRef: true
    }
)(FormBagShippingTypeContainer);

Container.defaultProps = {
    bagShippingTypes: [],
    errors: {},
    autoSelect: false,
    disabled: false,
    onChange: () => {
    }
};

Container.propTypes = {
    bagShippingTypes: PropTypes.array,
    value: PropTypes.any,
    errors: PropTypes.object,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Container;
