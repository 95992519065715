import React from 'react';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'antd';
import lodash from 'lodash';
import UnLinkComponent from './../../UnlinkOrder/UnlinkOrderContainer';
import DeletePackageComponent from './../../DeletePackage/DeletePackageContainer';
import ChangePackageTransportStatus from './../../ChangePackageTransportStatus/ChangePackageTransportStatusContainer';
import PrintPackageStampContainer from './../../PrintPackageStamp/PrintPackageStampContainer';
import {url} from './../../../../system/routing/index';
import {STATUS_TRANSPORT_PACKAGE} from '../../ChangePackageTransportStatus/constants';
import PrintOfficialStamp from '../../PrintOfficialStamp';

import authService from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import PrintBatchStampButton from "../../components/PrintBatchStampButton";
import { PACKAGE_ROUTINGS } from '../constants';
import { auth } from '../../../../system/api';
import config from "./../../../../config/app"
import Print9710StampButton from '../../components/Print9710StampButton';
class BottomAction extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            followPackage: false,
        };
    }

    FollowPackage = () => {
        this.setState({followPackage: !this.state.followPackage});
    };

    onEditPackage() {
        let packageData = this.props.package;
        if (packageData.order) {
            this.props.setPackagePage('UpdateRelatedPackage', packageData.id);
        } else {
            this.props.setPackagePage('UpdateAlonePackage', packageData.id);
        }
    }

    createSamePackage() {
        this.props.createPackage();
    }

    render() {
        let {t, allowPrintOfficalStamp} = this.props;
        let packageData = this.props.package;
        let order = lodash.get(packageData, 'order', {});
        let classButtonDisabled = 'a-btn--button-link a-btn--button-link--disabled';
        let classButton = 'a-btn--button-link a-text--blue';
        let disabledPrintStamp = lodash.get(packageData, 'weight_net') <= 0;
        let canPrintOfficialStamp = lodash.get(packageData, 'permissions.can_print_official_stamp');
        const required_volume = lodash.get(this.props.package, 'required_contents', []).includes('volume');
        if (required_volume && lodash.get(packageData, 'volume') <= 0) {
            disabledPrintStamp = true;
            canPrintOfficialStamp = false;
        }

        const id_operate_package = lodash.get(packageData, 'package_detail.id_operate_package', undefined);
        const canPrintBatchStamp = lodash.get(packageData, "permissions.can_print_batch_stamp", false);
        const permissions = lodash.get(packageData, "permissions", false);
        const canPrintOperationSupport = lodash.get(packageData, "permissions.can_use_label_from_operation_support", false);
        const packageRoutings = lodash.get(packageData, "package_routings", []).filter(item => item.data.action === "yes")
        const packageRoutingsContent = lodash.map(packageRoutings, "code")
        const hasDirectToConsumer = packageRoutingsContent.includes(PACKAGE_ROUTINGS.DIRECT_TO_CONSUMER);
        const trackingNoLastMile = lodash.get(packageData, "last_mile_shipment.tracking_no")
        return (
            <Row gutter={48} className="_package_bottom_action">
                <Col lg={{span: 24}} xl={{span: 24}} className="a-package--action">
                    <ul className="list-action list-action--left">
                        {lodash.get(packageData, 'permissions.can_update') ? (
                            <li>
                                <Button
                                    onClick={this.onEditPackage.bind(this)}
                                    className="a-btn--button-link a-text--blue _btn_edit_package"
                                >
                                    {t('package:btn.update_package')}
                                </Button>
                            </li>
                        ) : null}

                        {lodash.get(packageData, 'permissions.can_delete') ? (
                            <li>
                                <DeletePackageComponent packageData={packageData}/>
                            </li>
                        ) : null}
                        {!!(
                            (lodash.get(packageData, 'permissions.can_change_transport_status_to_mia') ||
                                authService.can(permissions.PACKAGE_CHANGE_MIA_TRANSPORT_STATUS)) &&
                            lodash.get(packageData, 'status_transport') !== STATUS_TRANSPORT_PACKAGE.MIA
                        ) && (
                            <li>
                                <ChangePackageTransportStatus
                                    packageData={packageData}
                                    status_transport={STATUS_TRANSPORT_PACKAGE.MIA}
                                />
                            </li>
                        )}

                        {lodash.isEmpty(order) ? null : (
                            <li>
                                <UnLinkComponent packageData={packageData}/>
                            </li>
                        )}
                    </ul>
                    <ul className="list-action list-action--right">
                        {
                            hasDirectToConsumer && trackingNoLastMile &&
                            <li>
                                <a
                                    target='_blank'
                                    href={`${config.apiUrl}/packages/${lodash.get(packageData, "id")}/last-mile-shipment-stamp?token=${auth.getToken()}`}
                                    className="a-btn--button-link a-text--blue _btn_print_last_mile_shipment_stamp"
                                    
                                >
                                    {t('package:btn.print_last_mile_shipment_stamp')}
                                </a>
                            </li>

                        }
                        <li>
                            <Button
                                className={clsx({
                                    [`_btn_create_same_package ${classButton}`]: lodash.get(
                                        packageData,
                                        'permissions.can_create_package'
                                    ),
                                    'a-hidden': !lodash.get(packageData, 'permissions.can_create_package'),
                                })}
                                onClick={this.createSamePackage.bind(this)}
                            >
                                {t('package:btn.create_same_bag')}
                            </Button>
                        </li>

                        <li>
                            <Print9710StampButton
                                className="a-btn--button-link a-text--blue print_stamp_9710"
                                ghost
                                packageData={packageData}
                            />
                        </li>

                        {lodash.get(packageData, 'permissions.can_print_barcode') ? (
                            <li>
                                <PrintPackageStampContainer
                                    disabledPrintStamp={disabledPrintStamp}
                                    numberStamp={4}
                                    packageData={packageData}
                                />
                            </li>
                        ) : null}

                        {!!canPrintOfficialStamp && allowPrintOfficalStamp && (
                            <li>
                                <PrintOfficialStamp
                                    className="a-btn--button-link a-text--blue _btn_print_official_stamp"
                                    packageData={packageData}
                                />
                            </li>
                        )}
                        {lodash.get(packageData, 'permissions.can_print_barcode') ? (
                            <>
                                <li className="not-line-space">
                                    {disabledPrintStamp ? (
                                        <Button className={classButtonDisabled} disabled={disabledPrintStamp}>
                                            {t('package:label.link_print_stamp')}
                                        </Button>
                                    ) : (
                                        <a
                                            className={'a-btn--button-link a-text--blue _link_stamp'}
                                            target="_blank"
                                            href={url.to('packages.link_stamp', {id: packageData.id, code: packageData.code})}
                                            rel="noopener noreferrer"
                                        >
                                            {t('package:label.link_print_stamp')}
                                        </a>
                                    )}
                                </li>
                                <li>
                                    <PrintPackageStampContainer
                                        disabledPrintStamp={disabledPrintStamp}
                                        numberStamp={1}
                                        packageData={packageData}
                                        type="primary"
                                        permissions={permissions}
                                    />
                                </li>

                                {!canPrintOperationSupport && !!id_operate_package && (
                                    <li>
                                        <PrintPackageStampContainer
                                            disabledPrintStamp={disabledPrintStamp}
                                            numberStamp={1}
                                            packageData={packageData}
                                            operatePackage={true}
                                            type="primary"
                                        />
                                    </li>
                                )}
                            </>
                        ) : null}

                        {
                            !!canPrintBatchStamp &&
                            <li>
                                <PrintBatchStampButton
                                    className="a-btn--primary ant-btn-primary _print-batch-stamp"
                                    packageData={packageData}
                                />
                            </li>
                        }
                    </ul>
                </Col>
            </Row>
        );
    }
}

BottomAction.defaultProps = {
    package: {},
};

BottomAction.propTypes = {
    package: PropTypes.object,
    setPackagePage: PropTypes.func,
};

export default withTranslation()(BottomAction);
