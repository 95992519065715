import React, {Component} from 'react';
import {notification, Modal} from 'antd';
import {withTranslation} from 'react-i18next';
import {get, has, isFunction, isEmpty, find} from 'lodash';

import apiService from './apiService';
import {DELIVERY_OBJECT} from './../constants'
import returnApiService from './../Return/apiService';
import {trans} from './../../../system/i18n';

import DeliveryNoteExport from './components';
import {Link} from './../../../system/routing';

class DeliveryNoteExportContainer extends Component {
    state = {
        isModalProcessing: false,
        disabled: false,
        loading: false
    };

    beginExportDeliveryNote = () => {
        this.setState({
            isModalProcessing: true
        });
    };

    endExportDeliveryNote = () => {
        this.setState({
            isModalProcessing: false
        });
    };

    handleExportDeliveryNote = async data => {
        const {onSuccessExportDeliveryNote, t, deliveryNoteData} = this.props;
        const barcodes = get(deliveryNoteData, "barcodes", []);

        this.setState({
            loading: true
        });

        try {
            let cb = apiService.exportDeliveryNote;

            if (data.type === DELIVERY_OBJECT.RETURN) {
                cb = returnApiService.export;
            }

            const response = await cb(data.id);

            notification.success({
                message: t('delivery_note:message.export_success'),
            });

            if (isFunction(onSuccessExportDeliveryNote)) {
                onSuccessExportDeliveryNote(response.data);
            }

            if (get(response, 'data.warnings.length')) {
                for (const warning of response.data.warnings) {
                    notification.warning({
                        message: t(`delivery_note:export.warnings.${warning}`)
                    });
                }
            }
        } catch (error) {
            const errorData = error && error.response && error.response.data;

            if (errorData) {
                if (get(errorData, 'warehouse')) {
                    return notification.error({
                        message: <span>{t('message.warehouse_not_is_staff')}</span>,
                    });
                }

                if (has(errorData, 'bags_packages.required')) {
                    notification.error({
                        message: t('delivery_note:message.bags_package_required'),
                    });
                } else if (get(errorData, 'bags.0') === 'bag_multiple_is_completed') {
                    notification.error({
                        message: t('scan:error.bag_multiple_is_completed'),
                    });
                } else if (has(errorData, 'bags.bag_not_in_warehouse')) {
                    notification.error({
                        message: t('delivery_note:message.bag_not_in_warehouse', {
                            bag_code: get(errorData, 'bags.bag_not_in_warehouse', []).map((err, i) => {
                                return i > 0 ? ' ' + err : err
                            })
                        }),
                    });
                } else if (has(errorData, 'packages.package_in_bag_not_completed')) {
                    notification.error({
                        message: t('scan:error.export_delivery_note_package_in_bag_not_completed'),
                    });
                } else if (has(errorData, 'customer.not_found_customer_phone')) {
                    notification.error({
                        message: t('delivery_note:message.not_found_customer_phone'),
                    });
                } else if (has(errorData, 'customer.not_found_customer_address')) {
                    notification.error({
                        message: t('delivery_note:message.not_found_customer_address'),
                    });
                } else if (has(errorData, 'data.packages.package_not_of_customer')) {
                    notification.error({
                        message: trans('delivery_note:export.errors.packages.package_not_of_customer', {
                            packages: errorData.data.packages.package_not_of_customer.map((pkg, index) => (
                                <Link
                                    isNewTab
                                    params={{id: pkg}}
                                    to="packages.detail"
                                >
                                    {pkg}
                                    {index !== errorData.data.packages.package_not_of_customer.length - 1 && ', '}
                                </Link>
                            ))
                        })
                    });
                } else if (get(errorData, 'data.packages.package_not_in_warehouse')) {
                    notification.error({
                        message: trans('delivery_note:export.errors.packages.package_not_in_warehouse', {
                            packages: errorData.data.packages.package_not_in_warehouse.map((pkg, index) => (
                                <Link
                                    isNewTab
                                    params={{id: pkg}}
                                    to="packages.detail"
                                >
                                    {pkg}
                                    {index !== errorData.data.packages.package_not_in_warehouse.length - 1 && ', '}
                                </Link>
                            ))
                        })
                    });
                } else if (!isEmpty(get(errorData, 'data.missing_return_info'))) {
                    const missingReturnInfo = get(errorData, 'data.missing_return_info');
                    if (barcodes.length > 0) {
                        Modal.error({
                            width: 650,
                            title: trans('delivery_note:export.errors.missing_return_info'),
                            content: <div>
                                {
                                    missingReturnInfo.map((item, index) => {
                                        const packageInfo = find(barcodes, ["object.id", item.id_package]);
                                        if (!isEmpty(packageInfo)) {
                                            const missingInfo = get(item, 'missing', []);
                                            return <p key={index}> - {trans('delivery_note:message.missing_return_info', {
                                                exportId: <strong>{packageInfo.code}</strong>,
                                                returnInfo: missingInfo.map(item => t(`delivery_note:label.${item}`)).join(", ")
                                            })} </p>
                                        }
                                        return false;
                                    })
                                }
                            </div>,
                        });
                    } else {
                        notification.error({
                            message: trans('delivery_note:export.errors.missing_return_info')
                        });
                    }
                } else {
                    notification.error({
                        message: t('delivery_note:message.export_failed'),
                    });
                }
            }
        }

        this.setState({
            loading: false,
            isModalProcessing: false
        });
    };

    render() {
        return (
            <DeliveryNoteExport
                {...this.props}
                {...this.state}
                beginExportDeliveryNote={this.beginExportDeliveryNote}
                endExportDeliveryNote={this.endExportDeliveryNote}
                onExportDeliveryNote={this.handleExportDeliveryNote}
            />
        );
    }
}

export default withTranslation()(DeliveryNoteExportContainer);
