import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { PACKAGE_TYPE } from '../../../../../StorageFees/constants';
import { useDeepCompareEffect } from '../../../../../../hooks';

import { TABLE_COLUMNS } from '../../../constants';

const StorageFeesTable = ({
    loading,
    pagination,
    storageFees,
    t,
    onChangePage,
    onEditButtonClick,
    onViewLogButtonClick
}) => {
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    useDeepCompareEffect(() => {
        setCurrent(Number(pagination.page) || 1);
        setPageSize(Number(pagination.per_page) || 20)
    }, [pagination]);

    const handleChangePage = pagination => {
        const { current } = pagination;

        setCurrent(current);
        onChangePage(pagination);
    };

    const columns = [{
        className: '_agency-column',
        title: t('agency'),
        dataIndex: TABLE_COLUMNS[PACKAGE_TYPE].AGENCY,
        key: TABLE_COLUMNS[PACKAGE_TYPE].AGENCY
    }, {
        className: '_unit-price-column',
        title: t('storage_fee:unit_price'),
        dataIndex: TABLE_COLUMNS[PACKAGE_TYPE].UNIT_PRICE,
        key: TABLE_COLUMNS[PACKAGE_TYPE].UNIT_PRICE
    }, {
        className: '_weight_by-column',
        title: t('weight_by'),
        dataIndex: TABLE_COLUMNS[PACKAGE_TYPE].WEIGHT_BY,
        key: TABLE_COLUMNS[PACKAGE_TYPE].WEIGHT_BY
    }, {
        className: '_applied-at-column',
        title: t('applied_at'),
        dataIndex: TABLE_COLUMNS[PACKAGE_TYPE].APPLIED_AT,
        key: TABLE_COLUMNS[PACKAGE_TYPE].APPLIED_AT
    }, {
        className: '_day-free-column',
        title: t('storage_fee:day_free'),
        dataIndex: TABLE_COLUMNS[PACKAGE_TYPE].DAY_FREE,
        key: TABLE_COLUMNS[PACKAGE_TYPE].DAY_FREE
    }, {
        className: '_actions-column',
        dataIndex: 'action',
        fixed: 'right',
        key: 'action',
        width: 48,
        render: (text, row, index) => (
            <div>
                <Button
                    className="a-text--link p-0 _edit-btn"
                    type="link"
                    onClick={onEditButtonClick.bind(undefined, index)}
                >
                    {t('common:edit')}
                </Button>
                <span className="a-text--link mx-2">|</span>
                <Button
                    className="a-text--link p-0 _log-btn"
                    type="link"
                    onClick={onViewLogButtonClick.bind(undefined, index)}
                >
                    {t('common:log')}
                </Button>
            </div>
        )
    }];

    return (
        <Table
            className="a-table--antd a-table--antd-scroll-x a-text--nowrap _storage-fees-table"
            columns={columns}
            dataSource={storageFees}
            loading={loading}
            pagination={{
                current,
                pageSize,
                total: pagination.total
            }}
            rowKey={(record => get(record, 'id'))}
            scroll={{
                x: 'fit-content'
            }}
            onChange={handleChangePage}
        />
    );
};

export default withTranslation()(StorageFeesTable);
