import {combineReducers} from "redux";
import formWarehouse from "./FormWarehouse/reducer"
import createWarehouse from "./CreateWarehouse/reducer"
import formWarehouseStaff from "./FormWarehouseStaff/reducer";
import listWarehouse from "./ListWarehouse/reducer"
import listWarehouseStaff from "./ListWarehouseStaff/reducer"
import detailWarehouse from "./DetailWarehouse/reducer"
import detailWarehouseStaff from "./DetailWarehouseStaff/reducer"
import updateWarehouse from "./UpdateWarehouse/reducer"
import updateWarehouseStaff from "./EditWarehouseStaff/reducer";
import addStaffInWarehouse from "./AddStaffInWarehouse/reducer"
import removeStaffInWarehouse from "./RemoveStaffInWarehouse/reducer"
import createWarehouseStaff from './CreateWarehouseStaff/reducer';

export default combineReducers({
	createWarehouse,
	formWarehouse,
	formWarehouseStaff,
	listWarehouse,
	detailWarehouse,
	detailWarehouseStaff,
	updateWarehouse,
	listWarehouseStaff,
	updateWarehouseStaff,
	addStaffInWarehouse,
	removeStaffInWarehouse,
	createWarehouseStaff
})
