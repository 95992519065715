import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearState, getDetail, setDetail } from './actions';
import { getState } from './selectors';
import { printDeliveryNoteReturn } from './../../../Common/PrintStamp/actions';
import { clearState as clearBarcodeState } from './../../DeliveryNoteBarcode/actions';

import Detail from './components';

const DetailContainer = props => {
    const clearState = () => {
        props.clearBarcodeState();
        props.clearState();
    };

    return (
        <Detail
            {...props}
            clearState={clearState}
        />
    );
};

const mapStateToProps = state => {
    return {
        deliveryNoteReturn: getState(state, 'deliveryNoteReturn'),
        loading: getState(state, 'loading')
    };
};

const mapDispatchToProps = {
    clearBarcodeState,
    clearState,
    getDetail,
    setDetail,
    printDeliveryNoteReturn
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailContainer));
