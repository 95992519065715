import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {CLEAR_STATE} from '../Form/constants';

export const shippingUserId = (state = '', action) => {
    let { type, payload } = action;

    switch (type) {
        case ACTION.GET_SHIPPING_USER_ID:
            return payload
        case CLEAR_STATE:
            return ''
        default:
            return state;
    }
};

export default combineReducers({
    shippingUserId
})
