export const CREATE = {
    REQUEST: 'DELIVERY_NOTE.CREATE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.CREATE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.CREATE.FAILED'
};

export const CLEAR_STATE = 'DELIVERY_NOTE.CREATE.CLEAR_STATE';

export const RESET_ERROR = 'DELIVERY_NOTE.CREATE.RESET_ERROR';

export const SET_INPUT_FOCUS = 'SET_INPUT_FOCUS';

export const SET_ACTIVE_SUGGEST_KEYS = 'SET_ACTIVE_SUGGEST_KEYS';

export const GET_DELIVERY_CUSTOMER_REQUESTS = {
    REQUEST: 'DELIVERY_NOTE.CREATE.GET_DELIVERY_CUSTOMER_REQUESTS.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.CREATE.GET_DELIVERY_CUSTOMER_REQUESTS.SUCCESS',
    FAILED: 'DELIVERY_NOTE.CREATE.GET_DELIVERY_CUSTOMER_REQUESTS.FAILED'
};

export const DELETE_SUGGEST = 'DELETE_SUGGEST';

export const CREATE_WITH_TRACKING_NO = {
    REQUEST: 'DELIVERY_NOTE.CREATE_WITH_TRACKING_NO.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.CREATE_WITH_TRACKING_NO.SUCCESS',
    FAILED: 'DELIVERY_NOTE.CREATE_WITH_TRACKING_NO.FAILED'
};

export const CONFIRM_CREATE_LASTMILE_SHIPMENT = {
    REQUEST: 'DELIVERY_NOTE.CONFIRM_CREATE_LASTMILE_SHIPMENT.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.CONFIRM_CREATE_LASTMILE_SHIPMENT.SUCCESS',
    FAILED: 'DELIVERY_NOTE.CONFIRM_CREATE_LASTMILE_SHIPMENT.FAILED'
};