import {api} from '../../system/api';

export default {
    fetchInventoryDetail: (id) => api.get(`inventories/${id}`, {}),
    updateInventoryStatus: (id, status) => api.put(`inventories/${id}/update-status`, {status}),
    updateInventoryPackageStatus: (data) => api.put(`inventory-packages/${data.id}/resolve`, data),
    resolveDisappearInventoryPackages: data => api.put('inventory-packages/resolve-disappears', data),
    resolveRemainderInventoryPackages: data => api.put('inventory-packages/resolve-remainders', data),
    resolveInventoryTrackingBill: payload => api.put(`/inventory-tracking-bills/${payload.id}/resolve`, payload),
    resolveInventoryTrackingBills: payload => api.put('/inventory-tracking-bills/resolve-bills', payload),
    fetchInventories: (filter) => api.get('inventories', {params: {...filter}}, {singleRequest: true}),
    downloadDisappearPackages: id => api.get(`/inventories/${id}/download-disappear-packages`, {
        responseType: 'blob'
    }),
    downloadScanningPackages: id => api.get(`/inventories/${id}/download-scanning-packages`, {
        responseType: 'blob'
    }),
    downloadDisappearTrackingBills: id => api.get(`/inventories/${id}/download-disappear-tracking-bills`, {
        responseType: 'blob'
    }),
    downloadScanningTrackingBills: id => api.get(`/inventories/${id}/download-scanning-tracking-bills`, {
        responseType: 'blob'
    })
};
