import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import {setMenuActive} from './../../Common/actions';
import PackageDetail from './components/PackageDetail';
import {setPackagePage} from '../CreatePackageViaScan/actions';
import {createPackageSameCode} from './../../Package/CreatePackageSameCode/actions';
import {fetchOrderPackages} from './../../Order/OrderPackages/actions';
import lodash from 'lodash';
import {getLoadings} from '../../Package/CreatePackageSameCode/selectors';
import {clearPrintState} from "../../Common/PrintStamp/actions";
import authService from "../../Auth/authService";

import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups';

const mapStateToProps = (state) => {
    let createCode = lodash.get(state, 'package.createPackageSameCode', []);
    return {
        user: authService.user(),
        package: selectors.getState(state, 'package'),
        loading: selectors.getState(state, 'loading'),
        loading_data: getLoadings(createCode)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearPrintState: () => dispatch(clearPrintState()),
        fetchPackage: (packageId) => dispatch(actions.fetchPackageDetail(packageId)),
        setPackage: data => dispatch(actions.setPackage(data)),
        setPackagePage: (page, packageId) => dispatch(setPackagePage(packageId, page)),
        clearState: () => dispatch(actions.clearState()),
        setMenuActive: (menu) => dispatch(setMenuActive(menu)),
        createPackageCode: (data) => {
            data.is_redirect = true;
            dispatch(clearPrintState());
            dispatch(createPackageSameCode(data));
        },
        fetchOrderPackages: (id) => dispatch(fetchOrderPackages(id)),
    };
};

class PackageDetailContainer extends SearchableComponent {
    componentWillUnmount() {
        this.props.clearState();
        this.props.setPackagePage(null, lodash.get(this.props.package, 'id', 0));
    }

    onChangeFilter(filter) {
        this.props.fetchPackage(filter.id);
    }

    render() {
        return (
            <PackageDetail {...this.props} />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withConsignServiceGroups(PackageDetailContainer));
