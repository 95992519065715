import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getDetail } from './../Detail/actions';
import { getState } from './../Detail/selectors';

import Print from './components';

const mapStateToProps = (state) => {
    return {
        deliveryNoteReturn: getState(state, 'deliveryNoteReturn')
    };
};

const mapDispatchToProps = {
    getDetail
};

class PrintContainer extends Component {
    componentDidMount() {
        const { deliveryNoteReturn, getDetail, onDidMount } = this.props;

        window.onafterprint = () => {
            getDetail(deliveryNoteReturn.delivery_note_return.id);
        };

        onDidMount();
    }

    render() {
        return (
            <Print
                {...this.props}
            />
        );
    }
}

PrintContainer.defaultProps = {
    onDidMount: () => {}
};

PrintContainer.propTypes = {
    onDidMount: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(PrintContainer));
