import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Modal} from 'antd';
import {router} from './';
import {t} from '../i18n';

export default () => (
    <BrowserRouter
        basename={""}
        getUserConfirmation={(message, callback) => {
            Modal.confirm({
                title: message,
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk() {
                    callback(true);
                },
                onCancel() {
                    callback(false);
                },
            });
        }}
    >{router.render()}</BrowserRouter>
);