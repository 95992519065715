import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Select} from 'antd';

const {Option, OptGroup} = Select;

class SelectSortPartnerUser extends React.Component {
    changeInput(input) {
        this.props.onChange(input);
    }

    render() {
        let {t, allowClear, bagShippingPartners, users, id_shipping_user, id_shipping_partner, loading} = this.props;
        let value = id_shipping_user === 0 ? id_shipping_partner : id_shipping_user;
        value = value === 0 || isNaN(value) ? undefined : (id_shipping_user === 0 ? "id_shipping_partner_" + id_shipping_partner : "id_shipping_user_" + id_shipping_user);
        return (
            <React.Fragment>
                <Select
                    allowClear={allowClear}
                    loading={loading}
                    className="a-select a-select--w-100 _id_shipping_partner"
                    showSearch={true}
                    optionFilterProp="children"
                    value={loading ? undefined : value}
                    disabled={this.props.disabled || loading}
                    onChange={value => this.changeInput({value})}
                    placeholder={this.props.placeholder}
                    dropdownClassName={this.props.dropdownClassName}
                >
                    <OptGroup label={t("bag:label.id_shipping_partner")}>
                        {bagShippingPartners.map(bagShippingPartner => (
                            <Option className="_id_shipping_partner__item"
                                    key={"id_shipping_partner_" + bagShippingPartner.shipping_partner.id}
                                    value={"id_shipping_partner_" + bagShippingPartner.shipping_partner.id}>{bagShippingPartner.shipping_partner.name}</Option>
                        ))}
                    </OptGroup>
                    <OptGroup label={t("bag:label.id_shipping_user")}>
                        {users.map(user => (
                            <Option className="_id_shipping_user__item"
                                    key={"id_shipping_user_" + user.shipping_user.id}
                                    value={"id_shipping_user_" + user.shipping_user.id}>{user.shipping_user.full_name + (user.shipping_user.username ? ' (' + user.shipping_user.username + ')' : '')}</Option>
                        ))}
                    </OptGroup>
                </Select>
            </React.Fragment>
        );
    }
}

SelectSortPartnerUser.defaultProps = {
    bagShippingPartners: [],
    users: [],
    id_shipping_user: 0,
    id_shipping_partner: 0,
    disabled: false,
    onChange: () => {
    },
    loading: false
};

SelectSortPartnerUser.propTypes = {
    loading: PropTypes.bool,
    bagShippingPartners: PropTypes.array,
    users: PropTypes.array,
    id_shipping_user: PropTypes.number,
    id_shipping_partner: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withTranslation()(SelectSortPartnerUser);
