import {all, fork} from 'redux-saga/effects';
import listDeliveryRequest from './ListDeliveryRequest/sagaWatcher';
import cancelDeliveryRequest from './CancelDeliveryRequest/sagaWatcher';
import detailDeliveryRequest from './DetailDeliveryRequest/sagaWatcher';

export default function*() {
    yield all([
        fork(listDeliveryRequest),
        fork(cancelDeliveryRequest),
        fork(detailDeliveryRequest),
    ]);
}
