import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as DELETE_ACTION from "../DeliveryNoteExport/constants";
import * as DELETE_ACTION_CANCEL from "../CancelDeliveryNote/constants";
import lodash from 'lodash';

let deliveryNote = (state = {delivery_note: {}, delivery_note_packages: [], delivery_note_bags: []}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.SUCCESS:
        case DELETE_ACTION.DELIVERY_NOTE_EXPORT.SUCCESS:
            return {...payload};
        case DELETE_ACTION_CANCEL.CANCEL_DELIVERY_NOTE.SUCCESS:
            return {...payload};

        case ACTION.UPDATE_DELIVERY_NOTE_DETAIL.SUCCESS:
            return {...payload};

        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.REQUEST:
        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.FAILED:
        case ACTION.CLEAR_STATE_FETCH_DELIVERY_NOTE_DETAIL:
            return {delivery_note: {}, delivery_note_packages: [], delivery_note_bags: []};
        case DELETE_ACTION.DELIVERY_NOTE_EXPORT.FAILED:
        case DELETE_ACTION_CANCEL.CANCEL_DELIVERY_NOTE.FAILED:
            let errors = lodash.get(payload, 'data', []);
            return {...state, errors};
        default:
            return {...state};
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.REQUEST:
            return true;

        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.SUCCESS:
        case ACTION.FETCH_DELIVERY_NOTE_DETAIL.FAILED:
        case ACTION.CLEAR_STATE_FETCH_DELIVERY_NOTE_DETAIL:
            return false;

        default:
            return state;
    }
};

let loadingQuickUpdate = (state = false, action) => {
    switch (action.type) {

        case ACTION.UPDATE_DELIVERY_NOTE_DETAIL.REQUEST:
            return true;
        case ACTION.UPDATE_DELIVERY_NOTE_DETAIL.SUCCESS:
        case ACTION.UPDATE_DELIVERY_NOTE_DETAIL.FAILED:
            return false;

        default:
            return state;
    }
};

let updateDelivery = (state = {delivery_note: {}, delivery_note_packages: []}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_DELIVERY_NOTE_DETAIL.FAILED:
            let errors = lodash.get(payload, 'data', []);
            return {...state, errors};
        default:
            return state;
    }
};

export default combineReducers({
    deliveryNote,
    loading,
    updateDelivery,
    loadingQuickUpdate
});
