import React, { useState } from 'react'
import { Alert, Icon, Skeleton } from 'antd'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { STAGES } from './../../../Notes/constants'
import { getVar } from './../../../../system/support/helpers'

import WarehouseStatusCard from './WarehouseStatusCard'
import DeletePackageContainer from './../../DeletePackage/DeletePackageContainer'
import { renderNotices, useNotes } from './../../../Common/components/Attentions'
import { linkHTC } from '../../../Package/helper'

const Package = ({ bag, canDelete, packageData, page }) => {
    const agencyCode = getVar(bag, 'agency.code', '')
    const notes = getVar(packageData, 'notes', [])
    const noteElements = useNotes(notes, STAGES.ATTACH_PACKAGE_TO_BAG)
    const notices = getVar(packageData, 'notices.text', []) || []
    const haveNotes = !!(Array.isArray(notes) && notes.length)
    const haveNotices = !!(Array.isArray(notices) && notices.length)
    let tbodyClass = ''

    if (haveNotes || haveNotices) {
        tbodyClass = 'warning'
    }
    if (packageData.class_error) {
        tbodyClass = packageData.class_error
    }
    const isLocal = getVar(packageData, 'isLocal')
    const linkHtc = linkHTC(agencyCode)
    return (
        <tbody className={tbodyClass}>
            {packageData.loading ? (
                <tr className="data">
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    {page === 'packing' ? (
                        <td>
                            <Skeleton
                                active
                                paragraph={{ rows: 0 }}
                            />
                        </td>
                    ) : null}
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td>
                        <Skeleton
                            active
                            paragraph={{ rows: 0 }}
                        />
                    </td>
                    <td></td>
                </tr>
            ) : (
                <tr
                    className="data"
                    key={packageData.id}>
                    <td className="_package_code">{packageData.code_package_text}</td>
                    {page === 'packing' ? <td className="_package_time_scan_display">{packageData.time_scan_display}</td> : null}
                    <td className={'_package_order_code'}>
                        {getVar(packageData, 'code_order') ? (
                            <a
                                href={getVar(packageData, 'order_url') || '#top'}
                                rel="noopener noreferrer"
                                target={getVar(packageData, 'order_url') ? '_blank' : '_self'}>
                                {getVar(packageData, 'code_order')}
                            </a>
                        ) : (
                            '--'
                        )}
                    </td>{' '}
                    {page === 'packing' ? null : <td>{getVar(packageData, 'customer_username', '--')}</td>}
                    <td className={'_package_weight'}>{packageData.weight_package_text}</td>
                    <td className={'_package_volume'}>{packageData.volume_package_text}</td>
                    <td className={'_package_volume'}>{`${getVar(packageData, "agency.name", "")} (${getVar(packageData, "agency.code", "")})`}</td>
                    <td className={'_package_volume'}>{getVar(packageData, "alias_properties", []).join(", ")}</td>
                    <td className={'_package_volume'}>{getVar(packageData, "alias_services", []).join(", ")}</td>
                    {page === 'detail' && <td className={'_package_current_warehouse_code'}>{getVar(packageData, 'current_warehouse.code', '--')}</td>}
                    <td className={'_package_destination_warehouse_code'}>{getVar(packageData, 'destination_warehouse.code', '--')}</td>
                    {page === 'detail' && (
                        <td className={'_package_status_warehouse'}>
                            <WarehouseStatusCard
                                status={getVar(packageData, 'status_warehouse')}
                                packageData={packageData}
                            />
                        </td>
                    )}
                    <td className="action-remove _action_remove">
                        {canDelete && !isLocal && (
                            <DeletePackageContainer
                                bagId={getVar(bag, 'id')}
                                page={page}
                                packageData={packageData}
                            />
                        )}
                    </td>
                </tr>
            )}

            {!!(haveNotes || haveNotices || packageData.message_error) && (
                <tr className="message">
                    <td
                        colSpan={page === 'packing' ? 10 : 12}
                        className="_box_message_error">
                        {!(haveNotes || haveNotices) && packageData.message_error && (
                            <p className="mb-2 a-text--black-45 a-flex _package_message_error">
                                {packageData.class_error === 'warning' ?
                                    <Icon type='info-circle' theme="filled"
                                        className="a-text--orange mr-2"/>
                                    : <Icon type='close-circle' theme="filled"
                                            className="a-text--red mr-2"/>
                                }
                                {packageData.message_error}
                            </p>
                        )}
                        {(haveNotes || haveNotices) && (
                            <>
                                <div
                                    className={clsx({
                                        'mb-2': notices && notices.length,
                                    })}>
                                    {noteElements}
                                </div>
                                {renderNotices(notices)}
                            </>
                        )}
                    </td>
                </tr>
            )}
        </tbody>
    )
}

const TablePackage = ({ t, packingData, bag, canDelete, page }) => {
    const [sort, setSort] = useState('down')

    const sortBy = type => {
        setSort(type)
    }

    /**
     * get bag packing data
     * @param packingData
     */
    const getBagPackings = packingData => {
        if (packingData.length) {
            if (sort === 'up') {
                packingData = lodash.orderBy(packingData, ['time_scan'], ['asc'])
            } else {
                packingData = lodash.orderBy(packingData, ['time_scan'], ['desc'])
            }
        } else {
            return []
        }

        return packingData
    }

    const bag_packings = getBagPackings(packingData)

    return (
        <div
            className={
                'a-content__body bg-white a-barcode-scan__body aheight-100 ' +
                (page === 'detail' ? 'content-list-package-in-bag' : ' list-is-sidebar a-content__body--page-list')
            }>
            {bag.message_warnings && bag.message_warnings !== '' && page === 'packing' ? (
                <Alert
                    className="mb-4"
                    message={bag.message_warnings}
                    showIcon
                    type="warning"
                />
            ) : null}
            <div className="a-table--responsive a-table--antd">
                <table className="a-table a-table--barcode-scan _table_list_package">
                    <thead>
                        <tr>
                            <th>{t('bag:packing.code_package')}</th>
                            {page === 'packing' ? (
                                <>
                                    <th>
                                        {t('bag:packing.time_scan')}
                                        <div className="sort">
                                            <Icon
                                                type="caret-up"
                                                onClick={sortBy.bind(this, 'up')}
                                                className={sort === 'up' ? 'active' : ''}
                                            />
                                            <Icon
                                                type="caret-down"
                                                onClick={sortBy.bind(this, 'down')}
                                                className={sort === 'down' ? 'active' : ''}
                                            />
                                        </div>
                                    </th>
                                </>
                            ) : null}
                            <th>{t('bag:packing.code_order')}</th>
                            {page === 'packing' ? null : <th>{t('customer')}</th>}
                            <th>{t('bag:packing.weight')}</th>
                            <th>{t('bag:packing.volume')}</th>
                            <th>{t('bag:label.agency')}</th>
                            <th>{t('bag:label.properties')}</th>
                            <th>{t('bag:label.services')}</th>

                            {page === 'detail' && <th>{t('bag:packing.warehouse')}</th>}
                            <th>{t('bag:packing.destination_warehouse')}</th>
                            {page === 'detail' && <th className="a-text--center">{t('bag:packing.status_warehouse')}</th>}

                            <th className="action-remove"></th>
                        </tr>
                    </thead>

                    {bag_packings.length
                        ? bag_packings.map(packageData => (
                              <Package
                                  bag={bag}
                                  canDelete={canDelete}
                                  packageData={packageData}
                                  page={page}
                              />
                          ))
                        : null}
                </table>
            </div>
        </div>
    )
}

TablePackage.defaultProps = {
    bag: {},
    packingData: [],
    loading: false,
    canDelete: true,
    page: '',
}

TablePackage.propTypes = {
    page: PropTypes.string,
    bag: PropTypes.object,
    packingData: PropTypes.array,
    loading: PropTypes.bool,
    canDelete: PropTypes.bool,
}

export default withTranslation()(TablePackage)
