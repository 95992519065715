import React from 'react';
import {Button, Icon, Input, Modal, notification, Upload} from "antd";
import lodash from 'lodash';
import {t} from '../../../../system/i18n';

const UPLOAD_MAX_SIZE = 10 * 1024 * 1024; // 10MB -> Bytes

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class ChatInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            attachments: [],
            comment: "",
            fileList: []
        };

        this.refInput = React.createRef();
    }

    beforeUpload = (file) => {
        let {attachments, fileList} = this.state;
        if (file.size <= UPLOAD_MAX_SIZE) {
            attachments.push(file);
            getBase64(file, url => {
                fileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url,
                    thumbUrl: url
                });
                return this.setState({
                    attachments,
                    fileList
                })
            });

            this.refInput.current.focus();
        } else {
            notification.error({
                message: t("package:message.upload_max_size")
            })
        }
        return false;
    };

    onChangeInput = (e) => {
        this.setState({comment: e.target.value});
    };

    onRemoveFile = (file) => {
        let {attachments, fileList} = this.state;
        attachments = lodash.filter(attachments, item => {
            return item.uid !== file.uid;
        });
        fileList = lodash.filter(fileList, item => {
            return item.uid !== file.uid;
        });
        this.setState({attachments, fileList});
    };

    onSubmit = () => {
        this.setState({
            comment: "",
            attachments: [],
            fileList: []
        });
        this.props.onSubmit({
            comment: this.state.comment,
            attachments: this.state.attachments
        })
    };

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };
    handleCancel = () => this.setState({previewVisible: false});

    render() {
        const {comment, fileList, previewVisible, previewImage} = this.state;
        const {loading} = this.props;
        return (
            <div className="a-chatbox--chat-input  mt-5">
                <Input
                    placeholder={t('package:comment.input')}
                    className="a-input a-input--chatbox apr-36 aflex-1 _chat_input"
                    onChange={this.onChangeInput}
                    value={comment}
                    onPressEnter={this.onSubmit}
                    ref={this.refInput}
                    loading={loading.toString()}
                    disabled={loading}
                />
                <div className={"a-group--upload"}>
                    <Upload
                        name="image"
                        listType="picture"
                        action="/upload.do"
                        className="a-btn--upload _upload"
                        fileList={fileList}
                        multiple={true}
                        beforeUpload={this.beforeUpload}
                        onRemove={this.onRemoveFile}
                        disabled={loading}
                        onPreview={this.handlePreview}
                    >
                        {/*<Button className={"mr-4 _btn_upload_file"} disabled={loading}>*/}
                        {/*    {loading ? <Icon type="loading"/> : <Icon type="paper-clip"/>}*/}
                        {/*</Button>*/}
                        <Button className={"_btn_upload_image"} disabled={loading}>
                            {loading ? <Icon type="loading"/> : <Icon type="camera"/>}
                        </Button>
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                    </Modal>
                </div>
            </div>
        )
    }
}

ChatInput.defaultProps = {
    onSubmit: (data) => {
    }
};

export {ChatInput}
