import React from 'react';
import {notification} from 'antd';
import lodash from 'lodash';
import {dispatch} from '../../system/store';
import {t} from '../../system/i18n';
import {HTTP_STATUS} from '../../system/api/constants';

/**
 * Xử lý api request
 */
export function processApiRequest(actionType, handler, request = {}) {
    let api = handler();

    api.then(response => {
        const data = response ? response.data : {};

        dispatch({
            type: actionType.SUCCESS,
            payload: data,
            request
        });

        if (lodash.isFunction(request.successHandler)) {
            request.successHandler(data);
        }
    });

    api.catch(error => {
        let response = error.response;
        if (lodash.get(response, 'data.code') === 'exception') {
            notification.error({
                message: <span className={"_create_package_error"}>{t('message.server_error')}</span>
            });
        }

        dispatch({
            type: actionType.FAILED,
            payload: response && response.status === HTTP_STATUS.BAD_REQUEST && response.data,
            message: error.message,
            request,
            status_code: lodash.get(response, 'status')
        });
    });
}

/**
 * Thông báo common error
 */
export function* onApiRequestError(action) {
    let status = lodash.get(action.payload, 'response.status');
    switch (status) {
        case HTTP_STATUS.BAD_REQUEST:
        case HTTP_STATUS.ENTITY_TOO_LARGE:
        case HTTP_STATUS.UNAUTHENTICATED:
        case HTTP_STATUS.NOT_FOUND:
            return;
        case HTTP_STATUS.UNAUTHORIZED:
            return yield notification.error({
                message: <span className={"_create_package_not_unauthorized"}>{t('message.unauthorized')}</span>
            });
        default:
            return yield notification.error({
                message: <span className={"_create_package_error"}>{t('message.server_error')}</span>
            });
    }
}

