import React from 'react';
import { withTranslation } from 'react-i18next';

import CanDeliveries from './components';
import {showDetailDeliveryRequest} from "../../../../DeliveryRequest/DetailDeliveryRequest/actions";
import {connect} from "react-redux";
import * as actions from '../../../../DeliveryNote/FormCustomer/actions'
import lodash from "lodash";

const CanDeliveriesContainer = props => (
    <CanDeliveries
        {...props}
    />
);
const mapStateToProps = (state, props) => {
   return {
       ...props,
       settings: lodash.get(state, 'setting.setting', []),
   }
};
const mapDispatchToProps = (dispatch) => {
    return {
        showDeliveryRequestDetailModal: showDetailDeliveryRequest,
        setBarcodes: (barcodes) => dispatch(actions.setBarcodes(barcodes))
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CanDeliveriesContainer));
