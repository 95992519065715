import React, {useState} from 'react';
import {AutoComplete, Input, Icon} from "antd";
import apiService from "../../../Customer/apiService";
import lodash from 'lodash';
import {getVar} from "../../../../system/support/helpers";

const {Option} = AutoComplete;
export default function SelectCustomer(props) {
    const {value, className} = props;
    const [options, setOptions] = useState([]);

    const renderSuggestCustomerOption = option => (
        <Option
            className="_customer__item"
            key={getVar(option, 'customer.id')}
            value={getVar(option, 'customer.code')}
        >
            <span className="a-text--black">{getVar(option, 'customer.username', '--')}</span>
            <br/>
            <span className="a-text--fz-12 a-text--gray-70">{getVar(option, 'customer.code', '--')} ({getVar(option, 'agency.code') || getVar(option, 'customer.agency.code') || '--'})</span>
        </Option>
    );

    const handleSelect = (value) => {
        const customerInfo = lodash.find(options, ["customer.code", value]);
        const isCustomerInfo = !lodash.isEmpty(customerInfo);
        const customer = isCustomerInfo ? customerInfo : value;
        props.onChange(customer, isCustomerInfo);
    };

    const handleSearch = (value) => {
        props.onChange(value);
        getSuggestCustomers(value);
    };

    const getSuggestCustomers = lodash.debounce(async (keyword) => {
        if (keyword.length >= 3) {
            try {
                const {data} = await apiService.getSuggests({username: keyword});
                const options = lodash.get(data, "customers", []);
                setOptions(options);
            } catch (error) {
                setOptions([]);
            }
        }
    }, 350);

    return (
        <AutoComplete
            className={className || ""}
            dataSource={(options || []).map(renderSuggestCustomerOption)}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={value}
        >
            <Input suffix={<Icon type="search"/>}/>
        </AutoComplete>
    );
}