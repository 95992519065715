import React from 'react';
import { Icon } from 'antd';

import Box1 from './../components/icons/Box1';
import Box2 from './../components/icons/Box2';
import Box3 from './../components/icons/Box3';
import Box4 from './../components/icons/Box4';
import Box5 from './../components/icons/Box5';
import Box6 from './../components/icons/Box6';
import BrokenGlass from './../components/icons/BrokenGlass';
import Chip from './../components/icons/Chip';
import Deposit from './../components/icons/Deposit';
import Mechanic from './../components/icons/Mechanic';
import Scale from './../components/icons/Scale';
import Truck1 from './../components/icons/Truck1';
import Truck2 from './../components/icons/Truck2';

export default {
    'truck-1': <Icon component={Truck1} />,
    'truck-2': <Icon component={Truck2} />,
    'box-1': <Icon component={Box1} />,
    'box-2': <Icon component={Box2} />,
    'box-3': <Icon component={Box3} />,
    'box-4': <Icon component={Box4} />,
    'box-5': <Icon component={Box5} />,
    'box-6': <Icon component={Box6} />,
    'deposit': <Icon component={Deposit} />,
    'broken-glass': <Icon component={BrokenGlass} />,
    'chip': <Icon component={Chip} />,
    'scale': <Icon component={Scale} />,
    'mechanic': <Icon component={Mechanic} />,
};
