import {processApiRequest} from '../../Common/saga';
import * as _ from 'lodash';
import * as ACTION from './constants';
import apiService from '../apiService';
import {router} from '../../../system/routing';
import {notification} from "antd/lib/index";
import {t} from "../../../system/i18n";
import React from "react";

export function* fetchDeliveryNoteDetail(action) {
    yield processApiRequest(ACTION.FETCH_DELIVERY_NOTE_DETAIL, () => apiService.getDeliveryNote(action.payload), action.payload);
}

export function* updateDelivery(action) {
    yield processApiRequest(ACTION.UPDATE_DELIVERY_NOTE_DETAIL, () => apiService.updateDelivery(action.payload.id, action.payload), action.payload);
}

export function* updateDeliveryFailed(action) {
    let error = _.get(action, 'payload.data');
    if (_.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}

export function* updateDeliverySuccess(action) {
    if (_.isFunction(action, 'request.cb')) {
        action.request.cb();
    }
    yield notification.success({
        message: t('delivery_note:message.update_delivery_note_success')
    });
}

export function* onFetchDeliveryNoteDetailFailed(action) {
    if (action.payload.code === 'INPUT_INVALID') {
        yield router.redirect("/404");
        yield  notification['error']({
            message: t('delivery_note:message.INPUT_INVALID'),
        });
    }
}

export function* removePackageFromDeliverNote(action) {
    yield processApiRequest(ACTION.REMOVE_PACKAGE_FROM_DELIVERY_NOTE, () => apiService.removePackageFromDeliveryNote(action.payload.deliveryNoteId, action.payload.packageIds));
}

export function* removePackageFromDeliverNoteSuccess(action) {

    yield notification.success({
        message: t('delivery_note:message.remove_package_from_delivery_success')
    });

    yield fetchDeliveryNoteDetail({
        payload: {
            id: _.get(action.payload, 'delivery_note.id')
        }
    })
}

export function* removePackageFromDeliverNoteFailed(action) {
    yield notification.error({
        message: t('delivery_note:message.remove_package_from_delivery_failed')
    });
}

export function* removeBagFromDeliverNote(action) {
    yield processApiRequest(ACTION.REMOVE_BAG_FROM_DELIVERY_NOTE, () => apiService.removeBagFromDeliveryNote(action.payload.deliveryNoteId, action.payload.bagIds));
}

export function* removeBagFromDeliverNoteSuccess(action) {
    yield notification.success({
        message: t('delivery_note:message.remove_bag_from_delivery_success')
    });

    yield fetchDeliveryNoteDetail({
        payload: {
            id: _.get(action.payload, 'delivery_note.id')
        }
    })
}

export function* removeBagFromDeliverNoteFailed(action) {
    yield notification.error({
        message: t('delivery_note:message.remove_bag_from_delivery_failed')
    });
}
