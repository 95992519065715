import { isEmpty, isNil } from 'lodash'

import CurrencyFormat from './CurrencyFormat'

export const CURRENCY_REGEX = /^[]?\d*\.?\d*$/

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 */
export const formatNumber = (number, currentCurrency = null) => {
    let { thousands_separator, decimal_separator, precision: n } = currentCurrency || CurrencyFormat.currency()

    let re = '\\d(?=(\\d{3})+' + (n > 0 ? '\\D' : '$') + ')',
        num = number.toFixed(Math.max(0, ~~n))

    return (decimal_separator ? num.replace('.', decimal_separator) : num).replace(new RegExp(re, 'g'), '$&' + (thousands_separator || ','))
}

/**
 * Với các input có định dạng tiền tệ theo seller thay đổi liên tục
 * thì truyền vào currentFormat mỗi lần thay đổi để định dạng lại
 * @param number currency: số tiền
 * @param object currentFormat: định dạng tiền tệ
 */
export const formatCurrency = (currency, currentCurrency = null) => {
    let { format } = !isEmpty(currentCurrency) ? currentCurrency : CurrencyFormat.currency()
    currency = formatNumber(parseFloat(isNil(currency) ? 0 : currency))
    return format.replace('{amount}', currency)
}

export const formatCurrencyStandard = (currency, currentCurrency = null) => {
    let { format } = !isEmpty(currentCurrency) ? currentCurrency : CurrencyFormat.currency()
    return format.replace('{amount}', currency)
}
