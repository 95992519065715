export const FETCH_INVENTORY = {
	REQUEST: 'FETCH_INVENTORY.REQUEST',
	SUCCESS: 'FETCH_INVENTORY.SUCCESS',
	FAILED: 'FETCH_INVENTORY.FAILED'
};
export const SCAN_BARCODE = {
	REQUEST: 'SCAN_BARCODE.REQUEST',
	SUCCESS: 'SCAN_BARCODE.SUCCESS',
	FAILED: 'SCAN_BARCODE.FAILED'
};
