import React, { Component } from 'react';
import { Button, Form, Icon, Upload } from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { getVar, isExcelFile } from './../../../../../system/support/helpers';

import SelectWarehouse from './../../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';

const { Dragger } = Upload;

class UploadForm extends Component {
    state = {
        fileList: []
    };

    handleChangeFile = event => {
        const {handleUpdateState} = this.props;
        const { file, fileList } = event;

        this.setState({
            fileList: fileList.length ? [file] : []
        });
        handleUpdateState()
    };

    beforeUpload = () => false;

    render() {
        const { fileList } = this.state;
        const { downloadingCreatePackageTemplate, form, t, uploadingCreatePackage, onDownloadCreatePackageTemplate, hasError, beforeUpload, onUploadCreatePackage, loading } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form className="a-content--search-header">
                <Button
                    className="mb-4"
                    loading={downloadingCreatePackageTemplate}
                    onClick={onDownloadCreatePackageTemplate}
                >
                    <Icon className={clsx({
                        'ml-3': downloadingCreatePackageTemplate
                    })} type="download" />
                    {t('package:download_sample_file.main')}
                </Button>
                <Form.Item className="mb-4 width-25-pc">
                    {getFieldDecorator('id_warehouse_input', {
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('package:label.warehouse_input')
                            })
                        }]
                    })(
                        <SelectWarehouse
                            className="_a-select-warehouse-input-package"
                            dropdownClassName="_dropdown_id_warehouse_input"
                            placeholder={t('package:label.warehouse_input')}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('file', {
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: 'File'
                            })
                        }, {
                            validator: async (rule, value, callback) => {
                                if (value) {
                                    const { file, fileList } = value;
                                    const { name } = file;

                                    if (fileList.length && !isExcelFile(name)) {
                                        throw new Error(t('validation:mimes', {
                                            attribute: 'File',
                                            values: 'xlsx, xls'
                                        }))
                                    }
                                }

                                callback();
                            }
                        }]
                    })(
                        <Dragger
                            accept=".xlsx, .xls"
                            fileList={fileList}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChangeFile}
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">{t('dragger_form.hint1')}</p>
                            <p className="ant-upload-hint">{t('dragger_form.hint2')}</p>
                        </Dragger>
                    )}
                </Form.Item>

                {
                    hasError && 
                    <div>
                        {t("package:message.warning_error_upload_create_package")}
                    </div>
                }

                <div className="gflex gjustify-center mt-4">
                    {
                        hasError ? <>
                            <Button onClick={onUploadCreatePackage.bind(undefined, form, false)} loading={getVar(loading, "byPass", false)} type="primary">
                                {t('btn.by_pass').toUpperCase()}
                            </Button>
                            <Button onClick={onUploadCreatePackage.bind(undefined, form, true)} className='ml-2' loading={getVar(loading, "continue", false)} type="primary">
                                {t('package:btn.create_continue').toUpperCase()}
                            </Button>
                        </> : 
                        <Button onClick={beforeUpload.bind(undefined, form)} loading={uploadingCreatePackage} type="primary">
                            {t('upload')}
                        </Button>
                    }
                    
                </div>
            </Form>
        );
    }
}

export default withTranslation()(Form.create()(UploadForm));
