import {connect} from 'react-redux';
import FormNotMapOrderPackage from './components/';

const mapStateToProps = (state, props) => ({
    packageData: props.packageData,
});

export default connect(
    mapStateToProps
)(FormNotMapOrderPackage);
