import React from 'react';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getInternationals } from './../actions';
import { getState } from './../selectors';

import List from './International/List';
import SearchForm from './International/SearchForm';
import SearchableComponent from './../../../Common/components/SearchableComponent';

class International extends SearchableComponent {
    state = {
        filter: {},
        filteredInternationals: []
    };

    filterInternationals = (internationals, filter) => {
        if (!filter || !filter.keyword || !internationals) {
            return internationals;
        }

        return internationals.filter(international => international.shipping_partner.name.toLowerCase().includes(filter.keyword.toLowerCase()) || international.shipping_partner.code.toLowerCase().includes(filter.keyword.toLowerCase()));
    };

    onChangeFilter = () => {
        const { location } = this.props;

        this.setState({
            filter: this.getFilterFromLocation(location)
        });
    };

    handleSearch = filter => {
        this.pushFilter(filter);
    };

    componentDidMount() {
        const { internationals, location, getInternationals } = this.props;
        const filter = this.getFilterFromLocation(location);

        super.componentDidMount();
        getInternationals();

        this.setState({
            filter,
            filteredInternationals: this.filterInternationals(internationals, filter)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { filter } = this.state;
        const { internationals } = this.props;

        super.componentDidUpdate(prevProps);

        if (!isEqual(filter, prevState.filter) || !isEqual(internationals, prevProps.internationals)) {
            this.setState({
                filteredInternationals: this.filterInternationals(internationals, filter)
            });
        }
    };

    render() {
        const { filter, filteredInternationals } = this.state;
        const { loading } = this.props;

        return (
            <div className="a-content__body bg-white a-content__body--page-list mt-0 height-100">
                <SearchForm
                    filter={filter}
                    onSearch={this.handleSearch}
                />

                <List
                    loading={loading}
                    shippingPartners={filteredInternationals}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        internationals: getState(state, 'internationals.internationals'),
        loading: getState(state, 'internationals.loading')
    };
};

const mapDispatchToProps = {
    getInternationals
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(International));
