import React, { useState, useEffect } from 'react'
import { Select, notification } from 'antd'
import { debounce, get, isEmpty, find } from 'lodash'
import orderApi from '../../Order/apiService'
import { t } from '../../../system/i18n'
import { getVar } from '../../../system/support/helpers'

const Option = Select.Option

export default function SelectOrderCreateNote(props) {
    const { isClearDataSearch, allowClear, className, disabled, dropdownClassName, error, placeholder, selectProps, onChange, onSelect, value } = props
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [keyword, setKeyword] = useState()

    useEffect(() => {
        if (isClearDataSearch) {
            setOrders([])
        }
    }, [isClearDataSearch])

    const onSearch = keyword => {
        setKeyword(keyword)
        if (!keyword) {
            return
        }
        doFindOrders(keyword)
    }

    const findOrders = keyword => {
        setLoading(true)
        setOrders([])
        orderApi
            .suggest({ code: keyword })
            .then(res => {
                const orders = get(res, 'data.orders', []) || []

                setOrders(
                    orders.map(item => {
                        const agency = get(item, 'agency', {})
                        return {
                            value: item.order.id,
                            order_id: '' + item.order.id,
                            order_code: item.order.code,
                            info: item,
                            text:
                                (item.order.code !== keyword ? `${item.order.code}(${keyword}))` : item.order.code) +
                                `${isEmpty(agency) ? '' : ' (' + get(agency, 'name', '') + ' )'}`,
                        }
                    })
                )
            })

            .catch(error => {
                setOrders([])
                if (error.response) {
                    notification.error({ message: t('package:link_order.message_cant_load_orders') })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const doFindOrders = debounce(findOrders, 250)
    const formattedSelectProps = {
        allowClear,
        className: className,
        disabled,
        dropdownClassName: dropdownClassName,
        help: error,
        loading: loading,
        optionFilterProp: 'children',
        placeholder: placeholder ? placeholder : t('order_code'),
        showSearch: true,
        validateStatus: error ? 'error' : null,
        filterOption: (input, option) => {
            return (
                (option.props.label ? option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                (option.props.code ? option.props.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
            )
        },
        value: value && !loading ? parseInt(value, 0) : undefined,
        onChange: value => {
            onChange(value)
        },
        onSearch,
        onSelect,
        ...selectProps,
    }
    
    return (
        <Select
            {...formattedSelectProps}
            optionLabelProp="label">
            {orders.map(order => (
                <Option
                    className="_customer__item"
                    code={getVar(order, 'value')}
                    key={getVar(order, 'value')}
                    label={getVar(order, 'text', '---')}
                    value={getVar(order, 'value')}>
                    <span className="a-text--black">{getVar(order, 'text', '---')}</span>
                </Option>
            ))}
        </Select>
    )
}
