import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function* () {
    yield takeLatest(ACTION.GET_LIST_WAREHOUSE.REQUEST, saga.getListWarehouse);
    yield takeLatest(ACTION.FETCH_WAREHOUSE_SUGGEST.REQUEST, saga.fetchSuggestWarehouses);
    yield takeLatest(ACTION.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION.REQUEST, saga.fetchSuggestWarehouseIsPermissions);
}

