import {combineReducers} from 'redux';
import * as ACTION from './constants';

let status = (state = true, action) => {
	let {type, payload} = action;

	switch (type) {
		case ACTION.CHANGE_STATUS_SOUND:
			return payload.status;
		default:
			return state;
	}
};

export default combineReducers({
	status,
});
