import {api} from './../../../system/api';

export default {
    add: (data = {}) => api.post('delivery-note-transport-warehouses', data),
    detail: id => api.get(`delivery-note-transport-warehouses/${id}`),
    update: (id, data = {}) => api.put(`delivery-note-transport-warehouses/${id}`, data),
    list: (params = {}) => api.get(`delivery-note-transport-warehouses`, {params}),
    cancel: id => api.put(`delivery-note-transport-warehouses/${id}`),
    export: id => api.put(`delivery-note-transport-warehouses/${id}/export`),
    exportExcelForTransportWarehouses: params => api.get('delivery-note-transport-warehouses/export', {
        params,
        responseType: 'blob'
    }),
    exportExcelForTransportWarehouse: (id, typeExport) => api.get(`delivery-note-transport-warehouses/${id}/download-excel`, {
        params: { type_export: typeExport},
        responseType: 'blob'
    }),
    removePack: (id, data) => api.post(`delivery-note-transport-warehouses/${id}/remove-pack`, data),
    addPack: (id, data) => api.post(`delivery-note-transport-warehouses/${id}/add-pack`, data),
    handleProcessTaskId: (taskId) => api.get(`/tasks/${taskId}`),
};
