import React from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {Button, Col, DatePicker, Form, Icon, Input, Row, Select} from 'antd';
import FormProcessing from '../../../Common/components/FormProcessing';
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';
import SelectUser from '../../../User/SelectUser/SelectUserContainer';
import moment from 'moment';
import {BARCODE_TYPE, SCAN_ACTION, SCAN_TYPE} from '../../constants';

const Option = Select.Option;
const Search = Input.Search;

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            status_warehouse: undefined,
            id_warehouse_scan: undefined,
            id_warehouse_destination: undefined,
            barcode_type: undefined,
            'created_at[from]': '',
            'created_at[to]': '',
            type_scan: undefined,
            id_creator: undefined,
            barcode: '',
            sort_by: 'created_at',
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch(this.state.input);
        }
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    onChangeDateTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    render() {
        let {t} = this.props;
        let {input} = this.state;

        return (
            <div className="a-content--search-header search-default" id="list-search">
                <Form onSubmit={this.onSubmit.bind(this)} className="a-form">
                    <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                        <Col lg={5} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t('scan_package_order_code')}
                                labelCol={{span: 24}}>
                                <Search
                                    value={input.barcode || ''}
                                    onChange={this.onChangeInput.bind(this, 'barcode')}
                                    placeholder={t('scan_package_order_code')}
                                    className="a-input--search _code_shbarcode"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={3} xl={3} xxl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('action')}
                                labelCol={{span: 24}}
                            >
                                <Select
                                    allowClear
                                    className="a-select a-select--search-content _action_shbarcode"
                                    showSearch={true}
                                    value={input.status_warehouse}
                                    onChange={status_warehouse => this.changeInput({status_warehouse})}
                                >
                                    {lodash.values(SCAN_ACTION).map(action => (
                                        <Option
                                            key={action}
                                            value={action}
                                            className="_action_shbarcode__item"
                                        >
                                            {t(`barcode:scanning_history.column.${action}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('scan_warehouse')}
                                labelCol={{span: 24}}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _warehohuse_shbarcode"
                                    value={input.id_warehouse_scan}
                                    onChange={id_warehouse_scan => this.changeInput({id_warehouse_scan})}>
                                </SelectWarehouse>
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('destination_warehouse')}
                                labelCol={{span: 24}}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _warehohuse_destination_shbarcode"
                                    value={input.id_warehouse_destination}
                                    onChange={id_warehouse_destination => this.changeInput({id_warehouse_destination})}>
                                </SelectWarehouse>
                            </Form.Item>
                        </Col>
                        <Col lg={5} xl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('code_type')}
                                labelCol={{span: 24}}
                            >
                                <Select
                                    allowClear
                                    className="a-select a-select--search-content _code_shbarcode"
                                    emptyOption={false}
                                    showSearch={true}
                                    value={input.barcode_type}
                                    onChange={barcode_type => this.changeInput({barcode_type})}
                                >
                                    {lodash.values(BARCODE_TYPE).map(action => (
                                        <Option
                                            key={action}
                                            value={action}
                                            className="_code_shbarcode__item"
                                        >
                                            {t(`barcode:scanning_history.type.${action}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={5} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('scan_content')}
                                labelCol={{span: 24}}
                            >
                                <Select
                                    allowClear
                                    className="a-select a-select--search-content _content_shbarcode"
                                    emptyOption={false}
                                    showSearch={true}
                                    value={input.type_scan || undefined}
                                    onChange={type_scan => this.changeInput({type_scan})}
                                >
                                    {lodash.values(SCAN_TYPE).map(action => (
                                        <Option
                                            key={action}
                                            value={action}
                                            className="_content_shbarcode__item"
                                        >
                                            {t(`barcode:label.SCAN_TYPE.${action}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={5} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('scanner')}
                                labelCol={{span: 24}}>
                                <SelectUser
                                    allowClear
                                    className="a-select a-select--search-content _person_shbarcode"
                                    emptyOption={false}
                                    value={input.id_creator}
                                    onChange={id_creator => this.changeInput({id_creator})}>
                                </SelectUser>
                            </Form.Item>
                        </Col>
                        <Col lg={10} xxl={8}>
                            <Form.Item className="a-form__item" label={t('time_scan')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeDateTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeDateTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a href="#top" className="link-reload" onClick={this.onReset.bind(this)}>
                            <Icon type="sync" /> {t('refresh_filter')}
                        </a>

                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list _action_search_shbarcode"
                            loading={this.props.loading}
                        >
                            {t('search')}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: (input) => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(FormSearch);
