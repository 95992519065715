import { DELIVERY_NOTE_STATUS } from './../constants';
import { t } from './../../../system/i18n';

export const LIST_DELIVERY_NOTE_STATUS = [{
    id: DELIVERY_NOTE_STATUS.NEW,
    name: t(`delivery_note:status.${DELIVERY_NOTE_STATUS.NEW}`)
}, {
    id: DELIVERY_NOTE_STATUS.PENDING,
    name: t(`delivery_note:status.${DELIVERY_NOTE_STATUS.PENDING}`)
}, {
    id: DELIVERY_NOTE_STATUS.TRACKING,
    name: t(`delivery_note:status.${DELIVERY_NOTE_STATUS.TRACKING}`)
}, {
    id: DELIVERY_NOTE_STATUS.EXPORTED,
    name: t(`delivery_note:status.${DELIVERY_NOTE_STATUS.EXPORTED}`)
}, {
    id: DELIVERY_NOTE_STATUS.CANCELED,
    name: t(`delivery_note:status.${DELIVERY_NOTE_STATUS.CANCELED}`)
}];
