import { createAction } from 'redux-actions';

import * as ACTION from './constants';
import { DELIVERY_OBJECT } from './../constants';

export const fetchDeliveryNotes = createAction(ACTION.FETCH_DELIVERY_NOTES.REQUEST, (params) => ({
    ...params,
    type: DELIVERY_OBJECT.CUSTOMER
}));
export const onReset = createAction(ACTION.RESET_LIST_DELIVERY_NOTE, () => {});
