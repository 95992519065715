import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {router} from '../../../system/routing';
import lodash from 'lodash';


export function* createPackageSameBarcode(action) {
    yield processApiRequest(
        ACTION.CREATE_PACKAGE_SAME_CODE,
        () => apiService.createPackageSameCode(action.payload),
        action.payload
    );
}

export function* onCreatePackageSameBarcodeSuccess(action) {
    if (lodash.get(action, 'request.is_redirect', false)) {
        router.redirect('/packages/detail/' + lodash.get(action, 'payload.package.code', ''));
        window.location.reload();
    } else {
        yield notification.success({message: t('package:create_same_code.message_create_package_success')});
    }
}

export function* onCreatePackageSameBarcodeFailed() {
    yield notification.error({message: t('package:create_same_code.message_create_package_failed')});
}
