import {connect} from 'react-redux';
import FormWarehouse from "./components"

import * as selectors from "./selectors";
import * as detailWarehouseSelectors from "../DetailWarehouse/selectors";
import * as createWarehouseSelectors from "../CreateWarehouse/selectors";
import * as updateWarehouseSelectors from "../UpdateWarehouse/selectors";
import * as actions from "./actions";

const mapStateToProps = (state, props) => ({
	errors: selectors.getErrors(selectors.getState(state, "errors", {})),
	warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
	loading: detailWarehouseSelectors.getState(state, 'loading', false) ||
		createWarehouseSelectors.getState(state, 'loading', false) ||
		updateWarehouseSelectors.getState(state, 'loading', false)

});
const mapDispatchToProps = (dispatch) => ({
	resetError: (params) => dispatch(actions.resetError(params)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FormWarehouse);
