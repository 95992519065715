import { api } from './../../system/api';

const BASE_URL = '/shipping-return-partners';

export default {
    getSuggests: params => api.get(`${BASE_URL}/suggest`, {
        params
    }),
    detail: id => api.get(`${BASE_URL}/${id}`)
}
