import { createAction } from 'redux-actions';

import * as ACTION from './constants';
import { DELIVERY_REQUEST_STATUS } from './../../DeliveryRequest/ListDeliveryRequest/constants';

export const create = createAction(ACTION.CREATE.REQUEST);
export const setInputFocus = createAction(ACTION.SET_INPUT_FOCUS, input => ({input}));
export const clearState = createAction(ACTION.CLEAR_STATE);
export const resetError = createAction(ACTION.RESET_ERROR);
export const setActiveSuggestKeys = createAction(ACTION.SET_ACTIVE_SUGGEST_KEYS);
export const getDeliveryCustomerRequests = createAction(ACTION.GET_DELIVERY_CUSTOMER_REQUESTS.REQUEST, params => ({
    ...params,
    status: DELIVERY_REQUEST_STATUS.STATUS_NEW
}));
export const deleteSuggest = createAction(ACTION.DELETE_SUGGEST);
export const createWithTrackingNo = createAction(ACTION.CREATE_WITH_TRACKING_NO.REQUEST);
export const confirmCreateLastmileOrder = createAction(ACTION.CONFIRM_CREATE_LASTMILE_SHIPMENT.REQUEST)
