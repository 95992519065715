import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, notification, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import get from 'lodash/get';
import moment from 'moment';

import apiService from './../apiService';
import { NOTE_TYPES } from './../constants';
import { dateFormatter } from './../../Common/services/format';
import { useDeepCompareEffect } from './../../../hooks';
import { trans } from './../../../system/i18n';
import { Link } from './../../../system/routing';

import StageWarnings from './../StageWarnings';

const { TextArea } = Input;
const { Text } = Typography;
const currentDate = moment();
const defaultExpiredAt = moment(currentDate).add(3, 'month').endOf('day');

const EditNoteModal = ({ creator, form, id, note, object, visible, onCancel, onOk }) => {
    const [editing, setEditing] = useState(false);
    const { getFieldDecorator } = form;
    const [linkToObject, setLinkToObject] = useState('');
    const [formattedObject, setFormattedObject] = useState('');
    const [formattedNote, setFormattedNote] = useState({});

    const disabledDate = currentDate => {
        const date = new Date();

        return moment(currentDate).startOf('day').valueOf() <= moment(date).startOf('day').valueOf();
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleOk = () => {
        form.validateFields((error, values) => {
            if (error)  {
                return;
            }

            updateOrCreateNote({
                ...values,
                id_object: id,
                object,
                expired_at: dateFormatter.standard(values.expired_at)
            });
        });
    };

    const updateOrCreateNote = async data => {
        setEditing(true);
        const key = data.id ? 'update_note' : 'add_note';
        const fn = data.id ? apiService.update : apiService.add;

        try {
            const response = await fn(data);

            notification.success({
                message: trans(`note:${key}.success`)
            });

            onOk(response.data);
        } catch (error) {
            notification.error({
                message: trans(`note:${key}.failed`)
            });
        }

        setEditing(false);
    };

    const mapNoteToForm = note => {
        if (!note) {
            return;
        }

        form.setFieldsValue({
            id: note.id,
            content: note.content,
            expired_at: moment(note.expired_at),
            stages: note.stages || []
        });
    };

    const getNoteDetail = async id => {
        if (!id) {
            return;
        }

        try {
            const { data } = await apiService.detail(id);

            mapNoteToForm(data.note);
            setFormattedNote(data.note);
            setFormattedObject(data.note.object);
        } catch (error) {
        }
    };

    useDeepCompareEffect(() => {
        if (id) {
            mapNoteToForm(note);
            setFormattedNote(note);
        } else {
            getNoteDetail(get(note, 'id'));
        }
    }, [note]);

    useDeepCompareEffect(() => {
        if (get(formattedNote, 'id')) {
            if (formattedObject === NOTE_TYPES.PACKAGE) {
                setLinkToObject((
                    <Link
                        isNewTab
                        params={{
                            id: get(formattedNote, 'object_data.code')
                        }}
                        to="packages.detail"
                    >
                        {get(formattedNote, 'object_data.code')}
                    </Link>
                ));
            } else if (formattedObject === NOTE_TYPES.CUSTOMER) {
                setLinkToObject(get(formattedNote, 'object_data.username'));
            } else {
                setLinkToObject(get(formattedNote, 'object_data.code'));
            }
        } else {
            setLinkToObject('');
        }
    }, [formattedNote, formattedObject]);

    useEffect(() => {
        if (visible) {
            mapNoteToForm(note);
        } else {
            form.resetFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (object) {
            setFormattedObject(object);
        }
    }, [object])

    return (
        <Modal
            className="_edit-note-modal"
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {trans('btn.cancel')}
                </Button>,
                <Button htmlType="submit" key="submit" type="primary" loading={editing} onClick={handleOk}>
                    {trans('btn.ok')}
                </Button>
            ]}
            title={(
                <>
                    <Text>
                        {trans(`note:attention_on.${formattedObject}`, {
                            code: linkToObject
                        })}
                    </Text>
                    <br />
                    {get(creator, 'id') ? (
                        <Text className="a-text--fz-14 a-text--regular">
                            {`${trans('create_by')}: ${get(creator, 'username')}`} <i className="a-text--fz-12 a-text--black-65">{trans('note:edit_note_modal.title.at_time', {
                                time: dateFormatter.full(get(formattedNote, 'created_at'))
                            })}</i>
                        </Text>
                    ) : null}
                </>
            )}
            visible={visible}
            onCancel={handleCancel}
        >
            <Form>
                <Form.Item className="a-form__item a-hidden">
                    {getFieldDecorator('id')(
                        <Input className="_id" />
                    )}
                </Form.Item>

                <Form.Item className="a-form__item mb-4" label={trans('note:label.attention')}>
                    {getFieldDecorator('content', {
                        rules: [{
                            required: true,
                            message: trans('validation:required', {
                                attribute: trans('note:label.attention')
                            })
                        }]
                    })(
                        <TextArea className="_content" />
                    )}
                </Form.Item>

                <Form.Item className="a-form__item mb-4" label={trans('note:stage_warning')}>
                    {getFieldDecorator('stages')(
                        <StageWarnings className="_stages" />
                    )}
                </Form.Item>

                <Form.Item className="a-form__item w-200" label={trans('expired_time')}>
                    {getFieldDecorator('expired_at', {
                        initialValue: defaultExpiredAt,
                        rules: [{
                            required: true,
                            message: trans('validation:required', {
                                attribute: trans('expired_time')
                            })
                        }]
                    })(
                        <DatePicker
                            className="width-100-pc _exipired-at"
                            disabledDate={disabledDate}
                            format="HH:mm:ss DD/MM/YYYY"
                            locale={locale}
                            showTime={{
                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                            }}
                            placeholder={trans('placeholder.expired_time')}
                        />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Form.create()(EditNoteModal);
