import * as _ from 'lodash';

export const getState = (state, param = null, defaultValue = null) => _.get(state, 'shippingUser' + (param ? '.' + param : ''), defaultValue);
export const getShippingUsersSuggest = (state) => {
    return _.get(state, 'shippingUser.suggest', []);
};

export const getLoading = (state) => {
    return _.get(state, 'shippingUser.loading', []);
};
