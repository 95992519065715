export const DELIVERY_OBJECT = {
    CUSTOMER: 'CUSTOMER',
    RETURN: 'RETURN'
};

export const LIST_DELIVERY_OBJECT = {
    CUSTOMER: 'CUSTOMER'
};

export const CURRENT_PAGE = {
    CUSTOMER: 'CUSTOMER',
    CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
    RETURN: 'RETURN',
    RETURN_DETAIL: 'RETURN_DETAIL'
};

export const DEFAULT_STATE_FORM_CUSTOMER = {
    id_customer: 0,
    id_warehouse: 0,
    customer_receiver: '',
    note: '',
    packages: [],
    bags: [],
    domestic_shipping_fee: undefined,
    negative_balance: undefined,
    cod: undefined,
    length: undefined,
    width: undefined,
    height: undefined,
    lastmile_operation_fee: undefined
};

export const DELIVERY_NOTE_STATUS = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    TRACKING: 'TRACKING',
    EXPORTED: 'EXPORTED',
    CANCELED: 'CANCELED'
};

export const DEFAULT_STATE_FORM_USER = {
    id_shipping_user: 0,
    id_warehouse: 0,
    note: '',
    amount_collect: 0,
    packages: []
};

export const DEFAULT_STATE_FORM_PARTNER = {
    id_shipping_partner: undefined,
    id_warehouse: 0,
    partner_tracking_number: undefined,
    note: undefined,
    amount_collect: 0,
    packages: []
};

export const DEFAULT_STATE_FORM_RETURN = {
    id_shipping_return_partner: 0,
    id_warehouse: 0,
    partner_tracking_number: undefined,
    note: undefined,
    packages: []
};

export const DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE = {
    id_shipping_partner: undefined,
    id_warehouse: 0,
    partner_tracking_number: undefined,
    car_number: undefined,
    note: undefined,
    bags: []
};

export const DELIVERY_NOTE_ACTION = {
    CREATE: 'CREATE',
    EDIT: 'EDIT'
};

export const LIST_PROPERTY_ALLOW_DISPLAY = ["1500_FREESHIP"] // nhớ viết hoa toàn bộ mã khi thêm vào
export const DELIVERY_NOTE_SETTING_KEY = {
    DELIVERY_NOTE_IGNORE_CALL_PAYMENT: "DELIVERY_NOTE:IGNORE_CALL_PAYMENT"
}

export const MAXIMUM_COUNT_WAIT_INTERVAL = 15; //8s với 15 lần => 2 phút
