import React, { memo } from 'react';
import { Icon, Tooltip } from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { BARCODE_TYPE } from './../../../../../Barcode/constants';
import { renderNotices, useNotes } from './../../../../../Common/components/Attentions';
import { STAGES } from './../../../../../Notes/constants';
import { formatVolume, getVar } from './../../../../../../system/support/helpers';

import Link from './../../../../../../system/routing/Link';

import { ReactComponent as BagIcon } from './../../../../../../resources/images/bag.svg';
import { ReactComponent as BagIconList } from './../../../../../../resources/images/icontotalpackage.svg';
import { ReactComponent as IconRemove } from './../../../../../../resources/images/Iconremove.svg';

const ReadyForDeliveryItem = ({ canUpdate, readyForDelivery, scrollTable, t, onOpenPackageListByBagPopup, onRemove, showDeliveryRequestDetailModal, agency }) => {
    const getFormattedReadyForDelivery = readyForDelivery => {
        let code,
            delivery_request_code = getVar(readyForDelivery, 'delivery_request.code', null);

        if (readyForDelivery.type === BARCODE_TYPE.BAG) {
            code = getVar(readyForDelivery, 'bag.code');
        }

        if (readyForDelivery.type === BARCODE_TYPE.PACKAGE) {
            code = getVar(readyForDelivery, 'package.code');
        }

        switch (readyForDelivery.type) {
            case BARCODE_TYPE.PACKAGE: {
                return {
                    code: (
                        <Link
                            isNewTab
                            params={{id: code}}
                            to="packages.detail"
                        >
                            <div className="icon-code mr-1" />
                            {code}
                        </Link>
                    ),
                    order: getVar(readyForDelivery, 'order.code') ? (
                        <Link
                            href={readyForDelivery.order.m1_url}
                            isNewTab
                        >
                            {readyForDelivery.order.code}
                        </Link>
                    ) : '--',
                    size: formatVolume(getVar(readyForDelivery, 'package.volume')),
                    volume: formatVolume(getVar(readyForDelivery, 'package.volume')),
                    weight: getVar(readyForDelivery, 'package.weight_net'),
                    delivery_request_code
                };
            }
            case BARCODE_TYPE.BAG: {
                return {
                    code: (
                        <Link
                            isNewTab
                            params={{id: code}}
                            to="bags.detail"
                        >
                            <div className="icon-code mr-1"><BagIcon /></div>
                            {code}
                        </Link>
                    ),
                    order: getVar(readyForDelivery, 'order.code') ? (
                        <Link
                            href={readyForDelivery.order.m1_url}
                            isNewTab
                        >
                            {readyForDelivery.order.code}
                        </Link>
                    ) : '--',
                    weight: getVar(readyForDelivery, 'bag.weight_real'),
                    volume: formatVolume(getVar(readyForDelivery, 'bag.volume')),
                    size: t(`bag:column.size_${getVar(readyForDelivery, 'bag.bag_size.code')}`),
                    delivery_request_code
                };
            }
            case BARCODE_TYPE.PACKAGE_ERROR: {
                return {
                    code: (
                        <Link
                            isNewTab
                            params={{id: code}}
                            to="packages.detail"
                        >
                            <div className="icon-code mr-1" />
                            {code}
                        </Link>
                    ),
                    order: getVar(readyForDelivery, 'order.code') ? (
                        <Link
                            href={readyForDelivery.order.m1_url}
                            isNewTab
                        >
                            {readyForDelivery.order.code}
                        </Link>
                    ) : '--',
                    weight: getVar(readyForDelivery, 'package.weight_net'),
                    volume: getVar(readyForDelivery, 'package.volume'),
                    size: getVar(readyForDelivery, 'package.volume'),
                    delivery_request_code
                };
            }
            default: {
                return {
                    code,
                    order: null,
                    weight: null,
                    volume: null,
                    delivery_request_code
                };
            }
        }
    };

    const formattedReadyForDelivery = getFormattedReadyForDelivery(readyForDelivery);
    const message = readyForDelivery.error || readyForDelivery.warning;
    let code;

    if (readyForDelivery.type === BARCODE_TYPE.BAG) {
        code = getVar(readyForDelivery, 'bag.code');
    }

    if (readyForDelivery.type === BARCODE_TYPE.PACKAGE) {
        code = getVar(readyForDelivery, 'package.code');
    }

    const notes = getVar(readyForDelivery, 'notes', []);
    const notices = getVar(readyForDelivery, 'notices.text', []);
    const noteElements = useNotes(notes, STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE);
    const paymentStatus = getVar(readyForDelivery, "payment_status")

    return (
        <>
            <tbody className={clsx({
                'warning': readyForDelivery.warning || notes.length || notices.length,
                'error': readyForDelivery.error
            })}>
                <tr className="data">
                    <td className="_code-column">
                        {formattedReadyForDelivery.code} 
                        <span className='ml-2'>{(paymentStatus !== undefined &&  paymentStatus !== null) ? !!paymentStatus ? <Tooltip title="Tất toán đơn thành công"><Icon type="check-circle" style={{color: "green"}}/></Tooltip> : <Tooltip title="Tất toán đơn thất bại"><Icon type="close-circle" style={{color: "red"}}/></Tooltip> : "" }</span>
                    </td>
                    <td className="_code-delivery-request-column">
                        {
                            formattedReadyForDelivery.delivery_request_code && agency ?
                                <span
                                    className="a-text--link cursor-pointer"
                                    onClick={showDeliveryRequestDetailModal.bind(undefined, {
                                        code: formattedReadyForDelivery.delivery_request_code,
                                        agency: agency.code
                                    })}
                                >{formattedReadyForDelivery.delivery_request_code}</span> : '--'
                        }
                    </td>
                    <td className="_order-column">{getVar(formattedReadyForDelivery, 'order', '--')}</td>
                    <td className="_weight-volume-column">
                        {formattedReadyForDelivery.weight ? formattedReadyForDelivery.weight + ' kg' : '--'}{formattedReadyForDelivery.volume ? (
                            <span>/ {formattedReadyForDelivery.volume} </span>
                        ) : '/ --'}
                    </td>

                    <td className={clsx('_actions-column sticky-right', {
                        'box-shadow': scrollTable
                    })}>
                        <div className="gflex gjustify-end">
                            {!readyForDelivery.error && readyForDelivery.type === BARCODE_TYPE.BAG && (
                                <span className="pointer mr-3">
                                    <BagIconList
                                        className="abg-white"
                                        onClick={onOpenPackageListByBagPopup.bind(this, {
                                            bag: readyForDelivery,
                                            packages: readyForDelivery.packages
                                        })}
                                    />
                                </span>
                            )}
                            {canUpdate && (
                                <span className="action-remove">
                                    {readyForDelivery.error ? (
                                        <Icon type="close" onClick={onRemove.bind(undefined, code)} />
                                    ) : (
                                        <IconRemove onClick={onRemove.bind(undefined, code)} />
                                    )}
                                </span>
                            )}
                        </div>
                    </td>
                    
                </tr>

                {!!(message || notes.length || notices.length) && (
                    <tr className="message">
                        <td colSpan="5">
                            {message && (
                                <p className="mb-2 a-text--black-45">
                                    <Icon
                                        className={clsx('mr-2', {
                                            'a-text--red': readyForDelivery.error,
                                            'a-text--orange': readyForDelivery.warning
                                        })}
                                        theme="filled"
                                        type="info-circle"
                                    /> {message}
                                </p>
                            )}

                            <div
                                className={clsx({
                                    'mb-2': notices && notices.length
                                })}
                            >
                                {noteElements}
                            </div>
                            {renderNotices(notices)}
                        </td>
                    </tr>
                )}
            </tbody>
        </>
    );
};

export default memo(withTranslation()(ReadyForDeliveryItem));
