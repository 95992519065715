import React from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import { find, first, get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class SelectCreatePartner extends React.Component {
    constructor(props) {
        super(props);
        this.autoSelected = false;
    }

    componentDidMount() {
        this.autoSelect();
    }

    componentDidUpdate() {
        this.autoSelect();
    }

    /**
     * Xử lý tự động select reason đầu tiên khi không truyền vào reason hiện tại
     */
    autoSelect = () => {
        const { autoSelect, value, bagShippingPartners } = this.props;

        if (autoSelect && !this.autoSelected && !value && bagShippingPartners.length) {
            this.autoSelected = true;

            this.changeInput({
                value: get(first(bagShippingPartners), 'shipping_partner.id')
            });
        }
    };

    changeInput = (input) => {
        const { bagShippingPartners, isFieldDecorator, onChange } = this.props;
        input = {
            ...input,
            ...find(bagShippingPartners, sp => get(sp, 'shipping_partner.id') === input.value)
        };

        if (isFieldDecorator) {
            onChange(input.value);
        } else {
            onChange(input);
        }
    };

    render() {
        const {
            allowClear,
            bagShippingPartners,
            className,
            clearIcon,
            defaultOptionLabel,
            disabled,
            dropdownClassName,
            hasDefaultOption,
            loading,
            placeholder,
            t,
            value
        } = this.props;
        const formattedSelectProps = {
            allowClear,
            className: clsx(className, 'a-select a-select--w-100 _id_shipping_partner'),
            clearIcon,
            defaultValue: this.autoSelected ? get(first(bagShippingPartners), 'shipping_partner.name') : (placeholder ? undefined : ''),
            disabled: disabled || loading,
            dropdownClassName,
            loading,
            placeholder,
            showSearch: true,
            optionFilterProp: 'children',
            value: value && !loading ? (bagShippingPartners.some(item => item.shipping_partner.id === Number(value)) ? parseInt(value, 0) : undefined) : (loading ? undefined : value),
            onChange: (value, i) => this.changeInput({
                value,
                i
            })
        };

        return (
            <Select {...formattedSelectProps}>
                {hasDefaultOption ? (
                    <Option
                        className="_id_shipping_partner__item _id-shipping-partner _id-shipping-partner-0"
                        value={0}
                    >
                        {defaultOptionLabel || t('select_partner')}
                    </Option>
                ) : null}
                {bagShippingPartners.map(bagShippingPartner => (
                    <Option
                        className={clsx(`_id_shipping_partner__item _id-shipping-partner _id-shipping-partner-${bagShippingPartner.shipping_partner.id}`, {
                            'a-text--green': bagShippingPartner.shipping_partner.is_suggestion
                        })}
                        key={bagShippingPartner.shipping_partner.id}
                        value={bagShippingPartner.shipping_partner.id}
                    >
                        {bagShippingPartner.shipping_partner.name}
                    </Option>
                ))}
            </Select>
        );
    }
}

SelectCreatePartner.defaultProps = {
    bagShippingPartners: [],
    autoSelect: false,
    disabled: false,
    placeholder: '',
    onChange: () => {
    },
    loading: false
};

SelectCreatePartner.propTypes = {
    loading: PropTypes.bool,
    bagShippingPartners: PropTypes.array,
    value: PropTypes.any,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default withTranslation()(SelectCreatePartner);
