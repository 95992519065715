import React, { Component } from 'react';
import { notification } from 'antd';

import apiService from './../modules/BagArea/apiService';
import { t } from './../system/i18n';

const withBagAreas = WrappedComponent => {
    return class extends Component {
        state = {
            bagAreas: [],
            loadingBagAreas: false,
            pagination: {},
            bagAreaSuggests: [],
            loadingBagAreaSuggests: false
        };

        getBagAreas = async filter => {
            this.setState({
                loadingBagAreas: true
            });
   
            try {
                const response = await apiService.list(filter);
    
                this.setState({
                    bagAreas: response.data.bag_areas,
                    pagination: response.data.pagination
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }

            this.setState({
                loadingBagAreas: false
            });
        };

        getActiveBagAreas = async (filter = {}) => {
            this.getBagAreas({
                ...filter,
                active: true
            });
        };

        getBagAreaSuggests = async filter => {
            this.setState({
                loadingBagAreaSuggests: true
            });
    
            try {
                const response = await apiService.suggest(filter);
    
                this.setState({
                    bagAreaSuggests: response.data
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }
    
            this.setState({
                loadingBagAreaSuggests: false
            });
        };

        getActiveBagAreaSuggests = async (filter = {}) => {
            this.getBagAreaSuggests({
                ...filter,
                active: true
            });
        };

        setBagAreas = bagAreas => {
            this.setState({
                bagAreas
            });
        };

        render() {
            const { bagAreas, loadingBagAreas, loadingBagAreaSuggests, pagination, bagAreaSuggests } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    bagAreas={bagAreas}
                    loadingBagAreas={loadingBagAreas}
                    loadingBagAreaSuggests={loadingBagAreaSuggests}
                    pagination={pagination}
                    bagAreaSuggests={bagAreaSuggests}
                    getActiveBagAreas={this.getActiveBagAreas}
                    getActiveBagAreaSuggests={this.getActiveBagAreaSuggests}
                    getBagAreas={this.getBagAreas}
                    getBagAreaSuggests={this.getBagAreaSuggests}
                    setBagAreas={this.setBagAreas}
                />
            );
        }
    };
};

export default withBagAreas;
