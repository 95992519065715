import React, {memo} from 'react';
import { Icon } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

const ErrorItem = ({ index, item, t }) => {
	let errorMessage = t('inventory:error.exists');

	if (get(item, 'inventory_package.payload.data.id_package.status_invalid')) {
		errorMessage = t('inventory:error.status_invalid', {
			type: t('inventory:types.package')
		});
	}

	return (
		<tbody key={index} className="error">
			<tr className="data">
				<td>{index + 1}</td>
				<td>{item.inventory_package.id}</td>
				<td>--</td>
				<td>--</td>
				<td>--</td>
			</tr>
			<tr className="message">
				<td colSpan="5">
					<span className="a-text--black-45">
						<Icon type="close-circle" theme="filled" className="a-text--red mr-2" />
						{errorMessage}
					</span>
				</td>
			</tr>
		</tbody>
	);
};

export default memo(withTranslation()(ErrorItem));
