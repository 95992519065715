export const CREATE_PACKAGE_BY_SCAN = {
    REQUEST: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.REQUEST',
    SUCCESS: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.SUCCESS',
    FAILED: 'PACKAGE.CREATE_PACKAGE_BY_SCAN.FAILED',
};

export const CREATE_SHIPMENT_ORDER = {
    REQUEST: 'PACKAGE.CREATE_SHIPMENT_ORDER.REQUEST',
    SUCCESS: 'PACKAGE.CREATE_SHIPMENT_ORDER.SUCCESS',
    FAILED: 'PACKAGE.CREATE_SHIPMENT_ORDER.FAILED',
};

export const BEGIN_CREATE_ALONE_PACKAGE = "BEGIN_CREATE_ALONE_PACKAGE";

export const PACKAGE_ACTION = {
    IN: 'IN', // Nhập kho
    OUT: 'OUT', // Xuất kho
};

export const TYPE_PACKAGE = {
    RELATED: "RELATED",
    ALONE: "ALONE"
};

export const CAN_ADD_NOTE = 1;

export const TRANSPORT_STATUS = {
    PUTAWAY: 'PUTAWAY',
    WAIT_FOR_TRANSPORTING: "WAIT_FOR_TRANSPORTING",
    TRANSPORTING: 'TRANSPORTING',
    REQUEST_FOR_DELIVERY: 'REQUEST_FOR_DELIVERY',
    READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
    INACTIVE: 'INACTIVE',
    MIA: 'MIA',
    WAIT_FOR_RETURN: 'WAIT_FOR_RETURN',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    CIRCULATE: 'CIRCULATE'
};

export const ARR_TRANSPORT_STATUS = [
    TRANSPORT_STATUS.PUTAWAY,
    TRANSPORT_STATUS.WAIT_FOR_TRANSPORTING,
    TRANSPORT_STATUS.TRANSPORTING,
    TRANSPORT_STATUS.REQUEST_FOR_DELIVERY,
    TRANSPORT_STATUS.READY_FOR_DELIVERY,
    TRANSPORT_STATUS.INACTIVE,
    TRANSPORT_STATUS.MIA,
    TRANSPORT_STATUS.WAIT_FOR_RETURN,
    TRANSPORT_STATUS.RETURNING,
    TRANSPORT_STATUS.RETURNED,
];

export const PARAMETER_PACKAGE_ARR = [
    "weight_net",
    "height",
    "length",
    "width",
];

export const DEFAULT_STATE_ALONE_PACKAGE = {
    idPackage: 0,
    customer_id_country_destination: 0,
    customer_id_city_destination: 0,
    customer_id_district_destination: 0,
    id_warehouse_destination: "",
    note: "",
    customer_sent: "",
    customer_receive: "",
    customer_phone_destination: "",
    customer_address_destination: "",
    services: [],
    properties: [],
    id_customer: 0,
    note_status: "",
    id_agency: 0
};

export const DAMAGED_STATUSES = {
    CHECKING: 'checking',
    CONTINUE: 'continue',
    REFUSE: 'refuse',
    COMPLAINT: 'complaint',
    LIQUIDATE: 'liquidate',
    RETURN: 'return',
    SWAP: 'swap'
};

export const CHECKING_STATUSES = {
    UNCHECKED: 'unchecked',
    CHECKING: 'checking',
    CHECKED: 'checked'
};

export const HTC_ORDER_DETAIL_URL_NH =`http://admin.nhaphang.com/order/detail/default/`
export const HTC_ORDER_DETAIL_URL_BG =`http://admin.baogam.com/order/detail/default/`
export const MAXIMUM_COUNT_WAIT_INTERVAL = 75