import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import {fetchLocation, setCurrentCity, setCurrentDistrict} from '../../Location/actions';
import {getCityLocations, getDistrictLocations, getListCountry, getWardLocations} from '../../Location/selectors';
import FormShippingPartner from './components';
import * as commonAction from "../../Common/actions";
import * as selectorSetting from "../../Setting/selectors"

const mapStateToProps = (state) => ({
    errors: selectors.getErrors(lodash.get(state, "shippingPartner.formShippingPartner.errors", {})),
    loading: !!lodash.get(state, "shippingPartner.formShippingPartner.loading", false),
    countries: getListCountry(state),
    cities: getCityLocations(state),
    districts: getDistrictLocations(state),
    wards: getWardLocations(state),
    isLoadingLocation: lodash.get(state, "location.loading", false),
    detailSetting: selectorSetting.getState(state, 'detail', {}),
});

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(actions.clearState()),
    resetError: (params) => dispatch(actions.resetError(params)),
    fetchLocation: () => dispatch(fetchLocation({iChooseDefault: true})),
    setCurrentCity: (idCity) => dispatch(setCurrentCity(idCity)),
    setCurrentDistrict: (idWard) => dispatch(setCurrentDistrict(idWard)),
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu))
});

class FormShippingPartnerContainer extends React.Component {

    componentDidMount() {
        this.props.setMenuActive("config/shipping-partners");
    }

    render() {
        return <FormShippingPartner {...this.props}/>
    }
}

FormShippingPartnerContainer.defaultProps = {
    errors: {},
    loading: false,
    countries: [],
    cities: [],
    district: [],
    wards: [],
    isLoadingLocation: false,
};

FormShippingPartnerContainer.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    countries: PropTypes.array,
    cities: PropTypes.array,
    district: PropTypes.array,
    wards: PropTypes.array,
    isLoadingLocation: PropTypes.bool
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormShippingPartnerContainer);
