import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getState } from './../../../NoticeStage/selectors';

import NoticeStageActionsSelect from './components/NoticeStageActionsSelect';

const mapStateToProps = state => {
    return {
        actions: getState(state, 'actions'),
        loading: getState(state, 'loadingActions')
    };
};

const NoticeStageActionsSelectContainer = props => {
    return (
        <NoticeStageActionsSelect {...props} />
    );
};

export default withTranslation()(connect(
    mapStateToProps
)(NoticeStageActionsSelectContainer));
