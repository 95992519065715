import {connect} from 'react-redux';
import UnlinkOrder from './components';
import * as actions from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    packageData: props.packageData,
    isModalProcessing: lodash.get(state, 'package.unlinkOrder.isProcessing', false),
    loading: lodash.get(state, 'package.unlinkOrder.loading', false),
});

const mapDispatchToProps = (dispatch) => ({
    beginUnlinkOrder: () => {
        dispatch(actions.beginUnlinkOrder());
    },
    endUnlinkOrder: () => {
        dispatch(actions.endUnlinkOrder());
    },
    onUnlinkOrder: (code) => {
        dispatch(actions.unlinkOrder(code));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnlinkOrder);
