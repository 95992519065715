import React from 'react'
import { put } from 'redux-saga/effects'
import { notification } from 'antd'
import lodash from 'lodash'
import { t, trans } from '../../../system/i18n'
import { processApiRequest } from '../../Common/saga'
import * as ACTION from './constants'
import apiService from '../apiService'
import { setPackagePage } from '../CreatePackageViaScan/actions'

export function* linkOrderPackage(action) {
    yield processApiRequest(
        ACTION.LINK_ORDER_PACKAGE,
        () => {
            let params = action.payload.orderCode
            if (!lodash.isObject(action.payload.orderCode)) {
                params = { order_code: action.payload.orderCode }
            }
            return apiService.linkOrderPackage(action.payload.packageCode, params)
        },
        {
            ...action.payload,
            orderCode: lodash.isObject(action.payload.orderCode) ? action.payload.orderCode.order_code : action.payload.orderCode,
        }
    )
}

export function* onLinkOrderPackageSuccess(action) {
    let { packageCode, orderCode, packageId } = action.request

    yield notification.success({
        message: trans('package:link_order.message_link_order_success', {
            package: <b>{packageCode}</b>,
            order: <b>{orderCode}</b>,
        }),
    })

    yield put(setPackagePage(packageId))
}

export function* onLinkOrderPackageFailed(action) {
    if (!action.payload) {
        return
    }

    let { packageCode, orderCode } = action.request
    let error = action.payload.data

    if (lodash.get(error, 'order_code.exists')) {
        return yield notification.error({
            message: trans('package:link_order.message_order_exists', {
                order: <b>{orderCode}</b>,
            }),
        })
    }

    if (lodash.get(error, 'code.linked')) {
        return yield notification.error({
            message: trans('package:link_order.message_package_linked', {
                package: <b>{packageCode}</b>,
            }),
        })
    }
    if (lodash.get(error, 'order_code.order_is_cancel')) {
        return yield notification['error']({
            message: (
                <span className={'_scan_package_failed'}>
                    {trans('package:message.scan_failed_code', {
                        code: <b>{lodash.get(error, 'order_code.order_is_cancel.code')}</b>,
                        status: <b>{t('package:status_transport.' + lodash.get(error, 'order_code.order_is_cancel.status'))}</b>,
                    })}
                </span>
            ),
        })
    }
    return yield notification.error({
        message: trans('package:link_order.message_link_order_failed', {
            package: <b>{packageCode}</b>,
            order: <b>{orderCode}</b>,
        }),
    })
}
