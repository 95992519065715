export const DELETE_PACKAGE = {
    REQUEST: 'BAG.DELETE_PACKAGE.REQUEST',
    SUCCESS: 'BAG.DELETE_PACKAGE.SUCCESS',
    FAILED: 'BAG.DELETE_PACKAGE.FAILED',
};

export const BEGIN_DELETE_PACKAGE = "BAG_BEGIN_DELETE_PACKAGE";
export const END_DELETE_PACKAGE = "BAG_END_DELETE_PACKAGE";

export const REMOVE_PACKAGE = {
    REQUEST: 'BAG.REMOVE_PACKAGE.REQUEST',
    SUCCESS: 'BAG.REMOVE_PACKAGE.SUCCESS',
    FAILED: 'BAG.REMOVE_PACKAGE.FAILED',
};

export const BEGIN_REMOVE_PACKAGE = "BAG_BEGIN_REMOVE_PACKAGE";
export const END_REMOVE_PACKAGE = "BAG_END_REMOVE_PACKAGE";