import React, { Component } from 'react';
import { Table } from 'antd';
import { get, isEqual } from 'lodash';

import { TABLE_COLUMNS } from './../../../re-execution/constants';
import { t } from './../../../../../system/i18n';

class ReExecutionTable extends Component {
    state = {
        columns: [{
            className: '_shipping-partner-column',
            title: t('shipping_partner'),
            dataIndex: TABLE_COLUMNS.SHIPPING_PARTNER,
            key: TABLE_COLUMNS.SHIPPING_PARTNER
        }, {
            className: '_agency-column',
            title: 'Agency',
            dataIndex: TABLE_COLUMNS.AGENCY,
            key: TABLE_COLUMNS.AGENCY
        }, {
            className: '_started-at-column',
            title: t('label.time_start'),
            dataIndex: TABLE_COLUMNS.STARTED_AT,
            key: TABLE_COLUMNS.STARTED_AT
        }, {
            className: '_finished-at-column',
            title: t('label.time_end'),
            dataIndex: TABLE_COLUMNS.FINISHED_AT,
            key: TABLE_COLUMNS.FINISHED_AT
        }, {
            className: '_creator-name-column',
            title: t('creator'),
            dataIndex: TABLE_COLUMNS.CREATOR,
            key: TABLE_COLUMNS.CREATOR
        }, {
            className: '_created-at-column',
            title: t('creation_time'),
            dataIndex: TABLE_COLUMNS.CREATED_AT,
            key: TABLE_COLUMNS.CREATED_AT
        }, {
            className: '_state-column',
            title: t('state'),
            dataIndex: TABLE_COLUMNS.STATE,
            key: TABLE_COLUMNS.STATE
        }],
        current: Number(this.props.pagination.page) || 1,
        pageSize: Number(this.props.pagination.per_page) || 20
    };

    handleChangePage = pagination => {
        const { onChangePage } = this.props;
        const { current } = pagination;

        this.setState({
            current
        });

        onChangePage(pagination);
    };

    componentDidUpdate(prevProps) {
        const { pagination } = this.props;

        if (!isEqual(pagination, prevProps.pagination)) {
            this.setState({
                current: Number(pagination.page) || 1,
                pageSize: Number(pagination.per_page) || 20
            });
        }
    }

    render() {
        const { columns, current, pageSize } = this.state;
        const { loading, reExecutions, pagination } = this.props;

        return (
            <Table
                className="a-table--antd a-table--antd-scroll-x a-text--nowrap _a-table-shipping-fee"
                columns={columns}
                dataSource={reExecutions}
                loading={loading}
                pagination={{
                    current,
                    pageSize,
                    total: pagination.total
                }}
                rowKey={(record => get(record, 'id'))}
                scroll={{
                    x: 'fit-content'
                }}
                onChange={this.handleChangePage}
            />
        );
    }
}

export default ReExecutionTable;