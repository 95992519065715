import {api} from '../../../system/api';

export default {
    deletePackage: (bagId, packingId) => api.put("bags/" + bagId+ "/bag-packings/"+packingId+"/delete", {}, {singleRequest: true}),

    /**
     * Xóa package đã đc đóng vào bao, áp dụng với chi tiết bao
     * @param bagId
     * @param packageId
     * @returns {AxiosPromise<any> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    removePackage: (bagId, packageId) => api.put("bags/" + bagId+ "/packages/"+packageId+"/remove", {}, {singleRequest: true})
};
