import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as PACKING_ACTION  from "./../BagPacking/constants";

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.END_PACKING_BAG.REQUEST:
            return true;
        case ACTION.END_PACKING_BAG.SUCCESS:
        case ACTION.END_PACKING_BAG.FAILED:
        case PACKING_ACTION.CLEAR_PACKING_BAG_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
});
