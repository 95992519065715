import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getState } from '../selectors';

import FormProperties from './components/FormMultiProperties';

const mapStateToProps = state => ({
    loading: getState(state, 'loading'),
    properties: getState(state, 'suggest', [])
});

const FormMultiPropertiesContainer = props => (
    <FormProperties {...props} />
);

FormMultiPropertiesContainer.defaultProps = {
    values: [],
    onChange: () => {},
};

FormMultiPropertiesContainer.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default connect(
    mapStateToProps
)(FormMultiPropertiesContainer);
