import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';
import {CREATE_WAREHOUSE_STAFF} from "../constants";
import {UPDATE_WAREHOUSE_STAFF} from "../constants";

export const loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case CREATE_WAREHOUSE_STAFF.REQUEST:
        case UPDATE_WAREHOUSE_STAFF.REQUEST:
            return true;
        case CREATE_WAREHOUSE_STAFF.SUCCESS:
        case CREATE_WAREHOUSE_STAFF.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

export const errors = (state = {}, action) => {
    let {type} = action;
    switch (type) {
        case CREATE_WAREHOUSE_STAFF.REQUEST:
        case CREATE_WAREHOUSE_STAFF.SUCCESS:
        case UPDATE_WAREHOUSE_STAFF.REQUEST:
        case UPDATE_WAREHOUSE_STAFF.SUCCESS:
        case ACTION.CLEAR_STATE:
            return {};
        case CREATE_WAREHOUSE_STAFF.FAILED:
        case UPDATE_WAREHOUSE_STAFF.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    errors
});