export const END_PACKING_BAG = {
    REQUEST: 'BAGS.END_PACKING_BAG.REQUEST',
    SUCCESS: 'BAGS.END_PACKING_BAG.SUCCESS',
    FAILED: 'BAGS.END_PACKING_BAG.FAILED',
};

export const ACTION_COMPLETE_PACKING = {
    END_PACKING: "end_packing",
    END_PACKING_CREATE_NEW_BAG: "end_packing_create_new_bag",
    END_PACKING_GO_DETAIL: "end_packing_go_detail"
};
