import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from './../../../system/i18n';
import lodash from "lodash";
import React from "react";

export function* updateWeightReal(action) {
    yield processApiRequest(ACTION.UPDATE_WEIGHT_REAL, () => apiService.updateWeightReal(action.payload), action.payload);
}

export function* updateWeightRealFailed(action) {
    if (action.payload) {
        let error = lodash.get(action, 'payload.data');
        if (lodash.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
            });
        }
        return yield notification['error']({
            message: t('common:message.update_failed'),
        });
    }

}

export function* updateWeightRealSuccess() {
    yield notification['success']({
        message: t('common:message.update_weight_real_bag_success'),
    });
}