import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as CREATE_ACTION from './constants';
import {BEGIN_CREATE_ALONE_PACKAGE, CREATE_PACKAGE_BY_SCAN} from './../constants';
import * as UNLINK_ACTION from './../UnlinkOrder/constants';
import {LINK_ORDER_PACKAGE} from '../LinkOrderPackage/constants';
import {UPDATE_WEIGHT} from '../UpdateWeightPackage/constants';
import {UPDATE_DIMENSION} from '../UpdatePackageDimension/constants';
import {UPDATE_PACKAGE as UPDATE_RELATED_PACKAGE} from '../UpdateRelatedPackage/constants';
import {CREATE_PACKAGE_SAME_BARCODE} from '../CreatePackageSameBarcode/constants';
import {CREATE_PACKAGE_SAME_CODE} from '../CreatePackageSameCode/constants';
import {updateCollectionItem} from '../../Common/services/helps';
import {UPDATE_ALONE_PACKAGE} from "../UpdateAlonePackage/constants"
import {UPDATE_PACKAGE_PARAM} from "./../UpdatePackageParams/constants";
import {DELETE_PACKAGE} from "../DeletePackage/constants";
import {UPDATE_TRANSPORT_STATUS} from './../ChangePackageTransportStatus/constants';
import {CREATE_SHIPMENT_ORDER} from './../constants';
import {STAGES, UPDATE_NOTE} from './../../Notes/constants';

const makePackageState = (packageApiRes) => {
    const state = {
        identity: packageApiRes.identity ? packageApiRes.identity + 1 : 1,
        ...packageApiRes.package,
        ...lodash.omit(packageApiRes, 'package')
    };

    return state;
};

const packages = (state = [], action) => {
    const { payload, request, type } = action;

    switch (type) {
        case UPDATE_ALONE_PACKAGE.SUCCESS:
        case UPDATE_TRANSPORT_STATUS.SUCCESS:
            return updateCollectionItem(state, payload.package.id, makePackageState(payload));
        case UPDATE_DIMENSION.SUCCESS:
        case UPDATE_PACKAGE_PARAM.SUCCESS:
        case CREATE_SHIPMENT_ORDER.SUCCESS:
            return updateCollectionItem(state, lodash.get(payload, 'package.id'), makePackageState(payload));
        case UPDATE_PACKAGE_PARAM.FAILED: {
            if (lodash.get(request, 'data.identity') && lodash.get(request, 'id')) {
                return updateCollectionItem(state, request.id, makePackageState({
                    identity: request.data.identity + 1
                }));
            }

            return state
        }
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.SUCCESS:
        case CREATE_PACKAGE_BY_SCAN.SUCCESS:
            if (lodash.isObject(payload.packages)) {
                return payload.packages.map(makePackageState);
            }
            return lodash.isObject(payload) ? payload.map(makePackageState) : null;
        case BEGIN_CREATE_ALONE_PACKAGE:
            let defaultPackage = {...CREATE_ACTION.DEFAULT_PACKAGE};
            defaultPackage.id_warehouse_input = action.payload;
            defaultPackage.type = "ALONE";
            return [defaultPackage];
        case CREATE_PACKAGE_BY_SCAN.REQUEST:
        case CREATE_ACTION.RESET_BARCODE:
        case CREATE_ACTION.CLEAR_STATE:
            return [];

        case LINK_ORDER_PACKAGE.SUCCESS:
        case UNLINK_ACTION.UNLINK_ORDER.SUCCESS:
        case UPDATE_RELATED_PACKAGE.SUCCESS:
            return updateCollectionItem(state, payload.package.id, makePackageState(payload));
        case UPDATE_WEIGHT.SUCCESS:
            return updateCollectionItem(
                state,
                payload.package.id,
                lodash.pick(payload.package, ['weight_net', 'is_info_complete'])
            );
        case CREATE_PACKAGE_SAME_BARCODE.SUCCESS:
            return [makePackageState(payload), ...state];

        case CREATE_PACKAGE_SAME_CODE.SUCCESS:
            return [makePackageState(payload), ...state];

        case DELETE_PACKAGE.SUCCESS:
            lodash.remove(state, {
                id: lodash.get(payload, 'package.id')
            });
            return [...state];
        case CREATE_ACTION.SET_PACKAGE:
            return updateCollectionItem(state, lodash.get(payload, 'package.id'), action.payload);
        case CREATE_ACTION.SET_PACKAGE_PAGE:
            return updateCollectionItem(state, payload.packageId, {
                current_page: payload.page
            });
        case UPDATE_NOTE.SUCCESS: {
            if (lodash.get(payload, 'stage') !== STAGES.CREATE_PACKAGE_BY_BARCODE) {
                return state;
            }

            const newState = state.map(item => {
                const index = (item.notes || []).findIndex(note => note.id === lodash.get(payload, 'note.id'));

                if (index === -1) {
                    return state;
                }

                const isRemoveNote = !lodash.includes(lodash.get(payload, 'note.stages'), STAGES.CREATE_PACKAGE_BY_BARCODE);
                const newNotes = isRemoveNote ? [
                    ...item.notes.slice(0, index),
                    ...item.notes.slice(index + 1)
                ] : [
                    ...item.notes.slice(0, index),
                    {
                        ...item.notes[index],
                        ...lodash.get(payload, 'note')
                    },
                    ...item.notes.slice(index + 1)
                ];

                return {
                    ...item,
                    notes: newNotes
                };
            });

            return newState;
        }
        default:
            return state;
    }
};

const defaultIsLoading = {
    fetchBarcodePackage: false,
    createPackageByScan: false,
};

const loadingPackages = (state = defaultIsLoading, action) => {
    const {type} = action;

    switch (type) {
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.REQUEST:
            return {...state, ...{fetchBarcodePackage: true}};
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.SUCCESS:
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.FAILED:
            return {...state, ...{fetchBarcodePackage: false}};

        case CREATE_PACKAGE_BY_SCAN.REQUEST:
            return {...state, ...{createPackageByScan: true}};

        case CREATE_ACTION.CLEAR_STATE:
        case CREATE_PACKAGE_BY_SCAN.SUCCESS:
        case CREATE_PACKAGE_BY_SCAN.FAILED:
            return {...state, ...{createPackageByScan: false}};

        default:
            return state;
    }
};

const inputFocus = (state = null, action) => {
    switch (action.type) {
        case CREATE_ACTION.SET_INPUT_FOCUS:
            return action.payload.input;

        case CREATE_ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

const inputFocusId = (state = 0, action) => {
    switch (action.type) {
        case CREATE_ACTION.SET_INPUT_FOCUS:
            return state + 1;

        case CREATE_ACTION.CLEAR_STATE:
            return 0;

        default:
            return state;
    }
};

const barcode = (state = null, action) => {
    switch (action.type) {
        case CREATE_PACKAGE_BY_SCAN.REQUEST:
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.REQUEST:
            return action.payload.barcode || lodash.get(action, 'payload.data.barcode');

        case CREATE_ACTION.RESET_BARCODE:
        case CREATE_ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

const orderSuggests = (state = [], action) => {
    switch (action.type) {
        case CREATE_PACKAGE_BY_SCAN.REQUEST: {
            return [];
        }
        case CREATE_PACKAGE_BY_SCAN.SUCCESS: {
            const { payload } = action;

            return lodash.get(payload, 'orders_suggest', []);
        }
        default: {
            return state;
        }
    }
};

const paginationPackages = (state = [], action) => {
    switch (action.type) {
        case CREATE_PACKAGE_BY_SCAN.REQUEST: {
            return {page: 1, total: 0};
        }
        case CREATE_PACKAGE_BY_SCAN.SUCCESS: {
            const { payload } = action;
            return { page: 1, total: lodash.get(payload, 'total_packages', 0)}
        }
        case CREATE_ACTION.FETCH_BARCODE_PACKAGES.SUCCESS:{
            const { payload, request } = action;
            return { page: lodash.get(request, 'page', 1), total: lodash.get(payload, 'pagination', 0)}
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    packages,
    loadingPackages,
    inputFocus,
    inputFocusId,
    barcode,
    orderSuggests,
    paginationPackages
});
