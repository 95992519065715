import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const getListCustomer = createAction(ACTION.GET_LIST_CUSTOMER.REQUEST, (params) => params);
export const getSuggests = createAction(ACTION.GET_SUGGESTS.REQUEST);
export const clearSuggests = createAction(ACTION.CLEAR_SUGGESTS);
export const getInitCustomersOfSelect = createAction(ACTION.GET_INIT_CUSTOMER_OF_SELECT.REQUEST);
export const fetchDetailCustomer = createAction(ACTION.FETCH_DETAIL_CUSTOMER.REQUEST, (customerId, payload = {}) => ({
    ...payload,
    customerId
}));
export const clearDetailCustomer = createAction(ACTION.CLEAR_DETAIL_CUSTOMER_STATE, () => {
});
export const clearErrorDetailCustomer = createAction(ACTION.CLEAR_ERROR_DETAIL_CUSTOMER_STATE, () => {
});
