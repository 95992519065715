import * as ACTION from './constants';
import * as saga from './saga';
import {all, fork, takeLatest} from 'redux-saga/effects';
import createProperty from "./CreateProperty/sagaWatcher";
import updateProperty from "./UpdateProperty/sagaWatcher";

export default function*() {
    yield takeLatest(ACTION.FETCH_LIST_PROPERTY.REQUEST, saga.fetchProperty);
    yield takeLatest(ACTION.FETCH_LIST_PROPERTY.FAILED, saga.fetchPropertyFailed);
    yield takeLatest(ACTION.FETCH_LIST_PROPERTY_SUGGEST.REQUEST, saga.fetchPropertySuggest);
    yield takeLatest(ACTION.FETCH_LIST_PROPERTY_SUGGEST.FAILED, saga.fetchPropertyFailed);
    yield takeLatest(ACTION.FETCH_DETAIL_PROPERTY.REQUEST, saga.fetchDetailProperty);
    yield takeLatest(ACTION.FETCH_DETAIL_PROPERTY.FAILED, saga.fetchDetailPropertyFailed);
    yield all([
        fork(createProperty),
        fork(updateProperty),
    ]);

}
