import {connect} from 'react-redux';
import UpdateNotMapOrderPackage from './components/';
import packageService from '../packageService';
import lodash from 'lodash';
import {getDefaultCountry} from './../../Location/selectors';
import {clearState, createAlonePackage, resetError, updateAlonePackage} from './actions';
import * as selector from './selectors';
import {setPackagePage} from "../CreatePackageViaScan/actions";
import * as boxSizeActions from "../../BoxSize/actions";
import * as boxSizeSelectors from "../../BoxSize/selectors";

const makeFormInputVolumeBox = (boxSizes, id) => {
    let boxSize = lodash.find(boxSizes, item => item.box_size.id === id);
    return lodash.get(boxSize, 'box_size.volume', 0);
};
const makeFormInput = (packageData) => {
    const input = {
        ...lodash.pick(packageData, [
            'weight_net',
            'length',
            'width',
            'height',
        ]),
        properties: lodash.map(packageData.properties || [], 'id'),
    };

    return packageService.makeFormInput(input);
};

const mapStateToProps = (state, props) => {
    let packageData = {...props.packageData};
    let input = makeFormInput(packageData);
    let boxSizes = boxSizeSelectors.getSuggestBoxSizes(state, 'box_sizes', []);
    if (!lodash.isNil(packageData.id_box_size) && packageData.id_box_size !== 0) {
        let volume_box = makeFormInputVolumeBox(boxSizes, packageData.id_box_size);
        input.volume_box = volume_box;
        packageData.volume_box = volume_box;
    }
    return ({
        packageData,
        boxSizes,
        input,
        isLoadingProperty: lodash.get(state, "property.loading", false),
        defaultCountry: getDefaultCountry(state),
        loading: !!lodash.get(state, "package.updateNotMapOrderPackage.loading", false),
        errors: selector.getErrors(lodash.get(state, "package.updateNotMapOrderPackage.errors", {}))
    })
};

const mapDispatchToProps = (dispatch, props) => ({
    onSubmit: (params) => {
        dispatch(updateAlonePackage(params));
    },
    onCreateAlonePackage: (params) => {
        dispatch(createAlonePackage(params));
    },
    onBack: () => {
        dispatch(setPackagePage(props.packageData.id));
    },
    onReset: () => dispatch(clearState(props.packageData.id)),
    resetError: (params) => {
        dispatch(resetError(props.packageData.id, params));
    },
    fetchSuggestBoxSizes: params => dispatch(boxSizeActions.fetchSuggestBoxSizes({
        ...params,
        only_active: true
    })),
    clearSuggestBoxSizes: () => dispatch(boxSizeActions.clearSuggestBoxSizes())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateNotMapOrderPackage);
