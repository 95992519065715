import React from 'react';
import { Layout } from 'antd';
import { toInteger } from 'lodash';

import pageService from './../../../../Common/Page/pageService';

import DeliveryNotesReturn from './DeliveryNotesReturn';
import SearchForm from './SearchForm';
import SearchableComponent from './../../../../Common/components/SearchableComponent';

const { Content } = Layout;

class List extends SearchableComponent {
    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter = filter => {
        this.props.getReturns(filter);
    };

    getCurrentFilter = () => {
        const { location } = this.props;
        const filter = {
            page: 1,
            ...this.getFilterFromLocation(location),
        };

        return filter;
    };

    handleSearch = filter => {
        this.pushFilter({
            ...filter,
            i: toInteger(filter.i || 0) + 1,
        });
    };

    componentDidMount() {
        const { t, setMenuActive } = this.props;

        super.componentDidMount();

        pageService.setTitle(t('delivery_note_return_list'));
        setMenuActive('delivery-notes/return');
    }

    componentWillUnmount() {
        const { clearState } = this.props;

        clearState();
    }

    render() {
        const { deliveryNotesReturn, loading, pagination } = this.props;
        const filter = this.getCurrentFilter();

        return (
            <Layout>
                <Content className="a-content a-content--page-list gflex gflex-direction-column">
                    <SearchForm
                        input={filter}
                        inputVersion={toInteger(filter.i)}
                        loading={loading}
                        onSearch={this.handleSearch}
                    />

                    <DeliveryNotesReturn
                        deliveryNotesReturn={deliveryNotesReturn}
                        filter={filter}
                        loading={loading}
                        pagination={pagination}
                        onSearch={this.handleSearch}
                    />
                </Content>
            </Layout>
        );
    }
}

export default List;
