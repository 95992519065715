import React, { Component, Fragment } from 'react';
import { Table } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { Link } from '../../../../../system/routing';

class Normal extends Component {
    render() {
        const { normals, loading, t } = this.props;
        let columns = [];

        columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:column.code.receive_package'),
            dataIndex: 'inventory_tracking_bill.tracking_bill',
            key: 'tracking_bill'
        }, {
            title: t('package_list'),
            dataIndex: 'inventory_tracking_bill.related_packages',
            key: 'related_packages',
            render: packages => packages && packages.length ? packages.map((pkg, index) => (
                <Fragment key={pkg}>
                    <Link isNewTab params={{id: pkg}} to="packages.detail">{pkg}</Link>
                    {index < packages.length - 1 ? ', ' : null}
                </Fragment>
            )) : '--'
        }];

        return (
            <Table
                className="a-table--antd a-table--antd-scroll-x"
                columns={columns}
                loading={loading}
                dataSource={normals}
                pagination={false}
                scroll={{ x: 'fit-content' }}
                rowKey={row => get(row, 'inventory_tracking_bill.id')}
            />
        );
    }
}

export default withTranslation()(Normal);
