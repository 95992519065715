import {combineReducers} from 'redux';
import createPackageViaScan from './CreatePackageViaScan/reducer';
import unlinkOrder from './UnlinkOrder/reducer';
import deletePackage from './DeletePackage/reducer';
import linkOrderPackage from './LinkOrderPackage/reducer';
import updatePackageDimension from './UpdatePackageDimension/reducer';
import updateWeightPackage from './UpdateWeightPackage/reducer';
import createPackageSameBarcode from './CreatePackageSameBarcode/reducer';
import createPackageSameCode from './CreatePackageSameCode/reducer';
import updateNotMapOrderPackage from './UpdateAlonePackage/reducer';
import updateRelatedPackage from './UpdateRelatedPackage/reducer';
import LinkStampPackage from './LinkStamp/reducer';
import listPackage from './ListPackage/reducer';
import packageDetail from './PackageDetail/reducer';
import updatePackageParams from './UpdatePackageParams/reducer';
import changePackageTransportStatus from './ChangePackageTransportStatus/reducer';

export default combineReducers({
    updateNotMapOrderPackage,
    createPackageViaScan,
    createPackageSameBarcode,
    createPackageSameCode,
    unlinkOrder,
    deletePackage,
    linkOrderPackage,
    updatePackageDimension,
    updateWeightPackage,
    updateRelatedPackage,
    LinkStampPackage,
    listPackage,
    packageDetail,
    updatePackageParams,
    changePackageTransportStatus,
});
