import {processApiRequest} from "../../Common/saga";
import * as ACTION from "./constants";
import apiService from "./apiService";
import React from "react";
import * as lodash from "lodash";
import {router} from "../../../system/routing";
import {INVENTORY_STATUS_PROCESSING} from "../constants";
import {Modal, notification} from "antd";
import {t, trans} from "../../../system/i18n";

export function handleOnOk(inventory) {
	router.redirect(`/inventory/` + inventory.id + '/process');
}

export function* fetchInventoryRequest(action) {
	yield processApiRequest(ACTION.FETCH_INVENTORY, () => apiService.detailInventory(action.payload.id), action.payload);
}

export function* fetchInventorySuccess(action) {
	if (lodash.get(action, 'payload.inventory.status', '') === INVENTORY_STATUS_PROCESSING) {
		yield  Modal.confirm({
			title: t("label.warning"),
			content:
				<p>{trans("inventory:message.inventory_is_status_processing", {code: t('inventory:status_inventory.' + action.payload.inventory.status).toLowerCase()})}</p>,
			okText: t("btn.ok"),
			cancelText: t("btn.cancel"),
			onOk: () => handleOnOk(action.payload.inventory),
		});
	}
}

export function* fetchInventoryFailed(action) {
	let error = lodash.get(action, 'payload.data');
	if (lodash.get(error, 'warehouse')) {
		return yield notification['error']({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}
	if (lodash.get(action, 'payload.data.id_inventory', '') !== '') {
		return yield router.redirect("/404");
	}
}
