import { combineReducers } from 'redux';

import * as ACTION from './../constants';

const adding = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.CREATE_WAREHOUSE_STAFF.REQUEST: {
            return true;
        }
        case ACTION.CREATE_WAREHOUSE_STAFF.SUCCESS:
        case ACTION.CREATE_WAREHOUSE_STAFF.FAILED:
        case ACTION.CLEAR_STATE: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    adding
});
