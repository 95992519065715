import lodash, { get } from 'lodash';
import {createSelector} from "reselect";
import {DELIVERY_NOTE_COLUMN} from "./constants";
import {Link} from './../../../system/routing';
import {dateFormatter} from '../../Common/services/format';
import {t} from './../../../system/i18n';
import React from 'react';
import * as warehouseSelectors from './../../Warehouse/selectors';
import * as customerSelector from './../../Customer/selectors';
import * as shippingUserSelector from './../../ShippingUser/selectors';
import * as UserSelector from './../../User/selectors';
import { Tooltip, Typography } from 'antd';

const { Paragraph } = Typography;

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'deliveryNote.listDeliveryNote' + (param ? '.' + param : ''), defaultValue);

export const getListDeliveryNote = createSelector(
    (state) => getState(state, "deliveryNotes", []),
    state => customerSelector.getState(state, 'list', []),
    (state) => warehouseSelectors.getState(state, 'suggest'),
    (state) => shippingUserSelector.getShippingUsersSuggest(state),
    (state) => UserSelector.getState(state, 'users', []),
    (deliveryNotes, customers, warehouses, shipping_users, users) => deliveryNotes.map((deliveryNoteData) => {
        let warehouse = lodash.find(warehouses, {id: lodash.get(deliveryNoteData, 'id_warehouse', 0)});
        let customer = get(deliveryNoteData, 'customer');
        let agency = get(deliveryNoteData, 'agency');
        let creator = lodash.find(users, {id: lodash.get(deliveryNoteData, 'id_creator', 0)});
        let exporter = lodash.find(users, {id: lodash.get(deliveryNoteData, 'id_exporter', 0)});
        let shipping_user = lodash.find(shipping_users, user => lodash.get(user, "shipping_user.id", 0) === lodash.get(deliveryNoteData, 'id_shipping_user', 0));
        const shippingPartner = lodash.get(deliveryNoteData, 'last_mile_carrier');
        return {
            key: deliveryNoteData.id,
            [DELIVERY_NOTE_COLUMN.CODE]: <Link to={"delivery-notes.customer.detail"}
                                               params={{id: deliveryNoteData.id}}>{deliveryNoteData.code}</Link>,
            [DELIVERY_NOTE_COLUMN.STATUS]: t("delivery_note:status." + deliveryNoteData.status),
            [DELIVERY_NOTE_COLUMN.CREATE_AT]: deliveryNoteData[DELIVERY_NOTE_COLUMN.CREATE_AT] ? dateFormatter.dateTime(deliveryNoteData[DELIVERY_NOTE_COLUMN.CREATE_AT]) : '--',
            [DELIVERY_NOTE_COLUMN.EXPORTED_AT]: deliveryNoteData[DELIVERY_NOTE_COLUMN.EXPORTED_AT] ? dateFormatter.dateTime(deliveryNoteData[DELIVERY_NOTE_COLUMN.EXPORTED_AT]) : '--',
            [DELIVERY_NOTE_COLUMN.NOTE]: deliveryNoteData.note ?<Tooltip title={deliveryNoteData.note}>
                    <Paragraph ellipsis={true} style={{width: 120}}>{ deliveryNoteData.note} </Paragraph>
                </Tooltip> : "--",
            [DELIVERY_NOTE_COLUMN.ID_CUSTOMER]: customer ? customer.full_name + ' - ' + customer.username + " (" + customer.code + ")" : "--",
            [DELIVERY_NOTE_COLUMN.ID_SHIPPING_PARTNER]: shippingPartner ? `${shippingPartner.name}${shippingPartner.carrier ? ` (${shippingPartner.carrier})` : ''}` : '--',
            [DELIVERY_NOTE_COLUMN.ID_SHIPPING_USER]: !lodash.isNil(shipping_user) ? (shipping_user.shipping_user.username ? shipping_user.shipping_user.full_name + " (" + shipping_user.shipping_user.username + ")" : shipping_user.shipping_user.full_name) : "--",
            [DELIVERY_NOTE_COLUMN.PARTNER_TRACKING_NUMBER]: deliveryNoteData.partner_tracking_number ? deliveryNoteData.partner_tracking_number : '--',
            [DELIVERY_NOTE_COLUMN.PACKAGE_NUMBER]: deliveryNoteData[DELIVERY_NOTE_COLUMN.PACKAGE_NUMBER] ? deliveryNoteData[DELIVERY_NOTE_COLUMN.PACKAGE_NUMBER] : '--',
            [DELIVERY_NOTE_COLUMN.PACKAGE_WEIGHT]: deliveryNoteData[DELIVERY_NOTE_COLUMN.PACKAGE_WEIGHT] ? deliveryNoteData[DELIVERY_NOTE_COLUMN.PACKAGE_WEIGHT] + " kg" : '--',
            [DELIVERY_NOTE_COLUMN.ID_WAREHOUSE]: warehouse ? warehouse.code : "--",
            [DELIVERY_NOTE_COLUMN.ID_CREATOR]: creator ? creator.name + " (" + creator.username + ")" : "--",
            [DELIVERY_NOTE_COLUMN.ID_EXPORTER]: exporter ? exporter.name + " (" + exporter.username + ")" : "--",
            [DELIVERY_NOTE_COLUMN.ID_AGENCY]: lodash.isEmpty(agency)? "--" : lodash.get(agency, "name", lodash.get(agency, "id", "--"))
        }
    })
);
