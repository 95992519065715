import React from 'react';

const Box4 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 -0.007)">
        <path className="c" d="M15.555,12.922l-1.781-.879a.167.167,0,0,1-.093-.149V6.34a.667.667,0,0,0-.394-.609L8.943,3.765a.168.168,0,0,1-.1-.152V1.335a.834.834,0,1,0-1.668,0v2.28a.167.167,0,0,1-.1.152L2.728,5.732a.667.667,0,0,0-.392.608v5.554a.168.168,0,0,1-.092.149l-1.782.888a.834.834,0,0,0,.745,1.494l2-1a.073.073,0,0,1,.063,0L7.8,15.471h.006a.484.484,0,0,0,.4,0h0l4.528-2.047a.077.077,0,0,1,.067,0l2,.989a.835.835,0,0,0,.745-1.494ZM4.96,6.492a.167.167,0,0,1,0-.3L7.941,4.84a.17.17,0,0,1,.137,0l3,1.348a.167.167,0,0,1,0,.3l-3,1.348a.168.168,0,0,1-.137,0Z" />
      </g>
    </g>
  </svg>
);

export default Box4;
