import React from 'react';
import {Row, Col, Typography, Divider} from 'antd';
import {find, forEach, get, omit, isEmpty, map} from 'lodash';
import QRCode from "react-qr-code";
import config from "../../../../../config/app";
import {getVar} from "../../../../../system/support/helpers";
import { LIST_PROPERTY_ALLOW_DISPLAY } from '../../../constants';

const { Text, Title, Paragraph } = Typography;

const ListDeliveryNotePrint = ({ t, deliveryNote, agencySetting, globalSetting}) => {
    const packages = [];
    const deliveryNoteBags = get(deliveryNote, 'delivery_note_bags', []) || [];
    const deliveryNotePackages = get(deliveryNote, 'delivery_note_packages', []) || [];
    const deliveryNoteDetail = get(deliveryNote, 'delivery_note', {})
    const domainApiUrl     = config.apiUrl.replace("v1", "");
    const urlQrcode =  domainApiUrl + 'lastmile-info-delivery-requests/' + deliveryNoteDetail.id;

    const agency = get(deliveryNote, 'agency', {})
    const id_agency = (agency) ? agency.id : 0;
    const hasPermission = getVar(find(agencySetting, {code: "CONFIG:LASTMILE_DELIVERY_REQUEST", id_agency}), "value", get(find(globalSetting, ["code", "CONFIG:LASTMILE_DELIVERY_REQUEST"]), "value", ));

    forEach(deliveryNoteBags, item => {
        forEach(item.packages, pkg => {
            packages.push({...pkg, ...omit(item, 'packages')});
        });
    });

    forEach(deliveryNotePackages, pkg => packages.push(pkg));

    return (
        <div className="print-delivery-note-for-customer--list">
            <Title className="title" level={2}>
                {t('delivery_note:print.package_export_title')}
            </Title>
            <Row className="package-list" gutter={8}>
                {packages.map((pkg, index) => {
                    let propertyAllowDisplay = [];
                    const properties = get(pkg, "properties", [])
                    if (!isEmpty(properties)) {
                        map(properties, "code").map(propertyCode => {
                            const propertyInfo = find(properties, ['code', propertyCode])
                            if(LIST_PROPERTY_ALLOW_DISPLAY.includes(propertyCode.toUpperCase()) && !isEmpty(propertyInfo)) {
                                propertyAllowDisplay.push(getVar(propertyInfo, "alias", ""));  
                            }
                            return true;
                        })
                    }
                    return <Col className="package-item" key={get(pkg, 'package.id') + index} span={12}>
                        <Paragraph> 
                            <Text className="dpl-inline-block w-20">{index + 1}.</Text> 
                            {get(pkg, 'package.code')} ({get(pkg, 'order.code')}) - {get(pkg, 'package.weight_net')}kg{`${propertyAllowDisplay.length > 0 ? " - " + propertyAllowDisplay.join(", ") : ""}`}
                        </Paragraph>  
                    </Col>
                })}
            </Row>

            <Row className="delivery-note-qrcode" gutter={8}>
                <Divider />
                <div>
                    {hasPermission ?  (
                        <QRCode size={128} value={urlQrcode} />
                    ) : ''}
                </div>
            </Row>
        </div>
    );
};

export default ListDeliveryNotePrint;
