import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { Table, notification, Input, Form, Icon, Modal, Button } from 'antd'
import AddWarehouseArea from '../components/AddWarehouseArea'
import * as detailWarehouseSelectors from '../../DetailWarehouse/selectors'
import { setMenuActive } from '../../../Common/actions'
import { connect } from 'react-redux'
import apiService from '../../apiService'
import { getVar } from '../../../../system/support/helpers'
import Pagination from '../../../Common/components/Pagination'
import { toInteger, isEmpty, filter } from 'lodash'
import { t } from '../../../../system/i18n'
import { printWarehouseAreaStamp } from '../../../Common/PrintStamp/actions'
const { Search } = Input
const { confirm } = Modal

const mapStateToProps = state => {
    return {
        warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
    }
}

const mapDispatchToProps = dispatch => ({
    setMenuActive,
    printStamp: data => dispatch(printWarehouseAreaStamp(data)),
})

const EditableContext = React.createContext()

class EditableCell extends React.Component {
    renderCell = form => {
        const { editing, dataIndex, title, rules, record, handleUpdate, index, children, ...restProps } = this.props
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {form.getFieldDecorator(dataIndex, {
                            rules: rules,
                            initialValue: record[dataIndex],
                        })(<Input onPressEnter={() => handleUpdate(form, record.id)} />)}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        )
    }

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    }
}
const initFilter = { page: 1, per_page: 20 }
const rulesOfDataIndex = {
    shelf_code: [
        {
            required: true,
            message: t('validation:required', { attribute: t('warehouse:label.shelf_code') }),
        },
        {
            pattern: /^[a-zA-Z0-9_-]+$/,
            message: t('validation:alpha_dash_underscore', { attribute: t('warehouse:label.shelf_code') }),
        },
    ],
    max_weight: [
        {
            pattern: /^[1-9]\d*$/,
            message: t('warehouse:message.integer', { attribute: t('warehouse:label.max_weight') }),
        },
    ],
    max_capacity: [
        {
            pattern: /^[1-9]\d*$/,
            message: t('warehouse:message.integer', { attribute: t('warehouse:label.max_capacity') }),
        },
    ],
}
class ListWarehouseArea extends React.Component {
    state = {
        loading: false,
        data: [],
        pagination: {},
        params: { ...initFilter },
        editableKey: '',
        selectedRowKeys: [],
        dataPrint: [],
    }

    columns = [
        {
            title: `${t('warehouse:label.shelf_code')} (*)`,
            dataIndex: 'shelf_code',
            key: 'shelf_code',
            editable: true,
        },
        {
            title: t('warehouse:label.max_weight'),
            dataIndex: 'max_weight',
            key: 'max_weight',
            editable: true,
        },
        {
            title: t('warehouse:label.max_capacity'),
            dataIndex: 'max_capacity',
            key: 'max_capacity',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                const id = getVar(record, 'id')
                const { editableKey } = this.state
                const editable = editableKey === id
                return (
                    <div className="a-text--center">
                        {editable ? (
                            <>
                                <EditableContext.Consumer>
                                    {form => (
                                        <Icon
                                            type="check"
                                            className="icon-inline  a-text--blue"
                                            onClick={() => this.handleUpdate(form, id)}
                                        />
                                    )}
                                </EditableContext.Consumer>
                                <Icon
                                    type="close"
                                    className="icon-inline  a-text--red ml-2"
                                    onClick={() => this.setState({ editableKey: 0 })}
                                />
                            </>
                        ) : (
                            <Icon
                                type="edit"
                                className="icon-inline  a-text--blue"
                                onClick={() => this.setState({ editableKey: id })}
                            />
                        )}

                        <Icon
                            type="delete"
                            className="icon-inline ml-2  a-text--blue"
                            onClick={() => this.handleDelete(id)}
                        />
                    </div>
                )
            },
        },
    ]

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        const { params } = this.state
        const { warehouseData } = this.props
        if (!isEmpty(warehouseData) && JSON.stringify(params) !== JSON.stringify(prevState.params)) {
            this.fetchData()
        }
    }

    handleDelete = id => {
        const { warehouseData } = this.props
        const { selectedRowKeys } = this.state
        const warehouseId = getVar(warehouseData, 'id')

        confirm({
            title: t('warehouse:message.delete_warehouse_area.confirm_title'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => {
                apiService
                    .deleteWarehouseArea(warehouseId, id)
                    .then(() => {
                        notification.success({ message: t('warehouse:message.delete_warehouse_area.success') })
                        this.fetchData()
                        this.setState({ selectedRowKeys: filter(selectedRowKeys, item => item !== id) })
                    })
                    .catch(() => {
                        notification.error({ message: t('warehouse:message.delete_warehouse_area.failed') })
                    })
            },
        })
    }

    fetchData = () => {
        const { t, warehouseData } = this.props
        const { params } = this.state
        this.setState({ loading: true })
        const warehouseId = getVar(warehouseData, 'id')
        apiService
            .getListWarehouseArea(warehouseId, params)
            .then(res => {
                const data = getVar(res, 'data.data', [])
                const pagination = getVar(res, 'data.meta.pagination', {})
                this.setState({ data, pagination })
            })
            .catch(() => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => this.setState({ loading: false }))
    }

    onChangePage = (page, pageSize) => {
        const params = this.state.params
        this.setState({ params: { ...params, page, per_page: pageSize }, selectedRowKeys: [] })
    }

    handleUpdate = (form, key) => {
        const { warehouseData } = this.props
        const warehouseId = getVar(warehouseData, 'id')
        form.validateFields((error, values) => {
            if (error) {
                return
            }
            apiService
                .updateWarehouseArea(warehouseId, key, values)
                .then(res => {
                    notification.success({ message: t(`warehouse:message.update_warehouse_area.success`) })
                    this.fetchData()
                    this.setState({ editableKey: '' })
                })
                .catch(err => {
                    const { response } = err
                    const code = getVar(response, 'data.code')
                    const data = getVar(response, 'data.data', {})
                    if (code === 'INPUT_INVALID') {
                        Object.keys(data).map(attribute => {
                            const contentErrors = []
                            const errorsOfAttribute = getVar(data, attribute, {})
                            Object.keys(errorsOfAttribute).map(error => {
                                const label = t(`warehouse:label.${attribute}`)
                                contentErrors.push(t(`warehouse:message.${error}`, { attribute: label }))
                            })
                            form.setFields({
                                [attribute]: {
                                    value: values[attribute],
                                    errors: [new Error(contentErrors.join(','))],
                                },
                            })
                        })
                    } else {
                        notification.error({ message: t(`warehouse:message.update_warehouse_area.failed`) })
                    }
                })
        })
    }

    handleSelectedChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, dataPrint: selectedRows })
    }

    handleOnChangeSearch = e => {
        if (e.type === 'click' && !this.state.loading) {
            this.setState({ params: { ...initFilter, shelf_code: undefined } })
        }
    }

    render() {
        const { data, params, loading, pagination, editableKey, selectedRowKeys, dataPrint } = this.state
        const { t, warehouseData } = this.props
        const rowSelection = {
            selectedRowKeys,
            onChange: this.handleSelectedChange,
        }
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    rules: rulesOfDataIndex[col.dataIndex],
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: record.id === editableKey,
                    handleUpdate: this.handleUpdate,
                }),
            }
        })
        return (
            <Fragment>
                <EditableContext.Provider value={this.props.form}>
                    <div className={'amt-16'}>
                        <div className="a-content__top a-content__top--page-list mr-0 mt-0">
                            <div className="a-content--page-list__title gflex galign-center">
                                <h3 className="a-text--upppercase _label-list-warehouse-staff">
                                    {t('config:label.list_warehouse_area')} ({pagination.total || 0})
                                </h3>

                                <div className={'a-form__search-inline-title'}>
                                    <Search
                                        allowClear={true}
                                        defaultValue={getVar(params, 'shelf_code')}
                                        placeholder={t('warehouse:placeholder.shelf_code')}
                                        className={'a-input--search-inline-title _input-search'}
                                        onSearch={value => this.setState({ params: { ...initFilter, shelf_code: value } })}
                                        onChange={this.handleOnChangeSearch}
                                    />
                                </div>
                            </div>
                            <div className="a-content--page-list__top-action gflex ">
                                {warehouseData.active ? (
                                    <AddWarehouseArea
                                        warehouse={warehouseData}
                                        refetch={this.fetchData}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed mr-0">
                            {selectedRowKeys.length > 0 && (
                                <Button
                                    className="mb-2"
                                    type="primary"
                                    loading={loading}
                                    onClick={() => this.props.printStamp({ data: dataPrint })}>
                                    {t('print_stamp.title')}
                                </Button>
                            )}

                            <div className="a-table--responsive">
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    rowSelection={rowSelection}
                                    rowKey={record => record.id}
                                    className={'a-table--antd a-table--antd-scroll-x _a-table-temple-bag'}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    loading={loading}
                                    scroll={{ x: 'fit-content' }}
                                />
                            </div>
                            <Pagination
                                page={toInteger(params.page) || 1}
                                pageSize={toInteger(params.per_page)}
                                pageSizeOptions={[20, 50, 100, 200]}
                                total={pagination.total}
                                loading={loading}
                                onChange={this.onChangePage.bind(this)}
                            />
                        </div>
                    </div>
                </EditableContext.Provider>
            </Fragment>
        )
    }
}
export default withTranslation()(Form.create()(connect(mapStateToProps, mapDispatchToProps)(ListWarehouseArea)))
