import React from 'react';
import { Table, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

import { trans } from './../../../../../../../system/i18n';
import { formatWeight } from './../../../../../../../system/support/helpers';

import Link from './../../../../../../../system/routing/Link';

const { Text } = Typography;

const Bags = ({ bags }) => {
    return (
        <div className="a-tile bags">
            <div className="a-tile__header gflex galign-center gjustify-space-between">
                <Text strong>
                    {`${trans('bag_list_in_order')} (${bags.length})`}
                </Text>
            </div>

            <div className="a-tile__body">
                <Table
                    columns={[{
                        dataIndex: 'code',
                        key: 'code',
                        title: trans('bag_code'),
                        render: text => (
                            <Link
                                className="a-text--blue"
                                isNewTab
                                params={{
                                    id: text
                                }}
                                to="bags.detail"
                            >
                                {text}
                            </Link>
                        )
                    }, {
                        dataIndex: 'weight_real',
                        key: 'weight_real',
                        title: trans('weight'),
                        render: text => formatWeight(text)
                    }, {
                        dataIndex: 'warehouse_destination',
                        key: 'destination_warehouse',
                        title: trans('destination_warehouse'),
                        render: text => text || '--'
                    }]}
                    dataSource={bags}
                    pagination={false}
                    rowKey={row => row.id}
                />
            </div>
        </div>
    );
};

Bags.defaultProps = {
    bags: []
};

export default withTranslation()(Bags);
