import React from 'react';
import {FLAG, LANGUAGE} from "../constants";
import {useDispatch} from 'react-redux';
import {changeLanguage as change} from "../actions"
import {withTranslation} from "react-i18next";

const changeLanguage = (lng, setFlag, setLanguage, dispatch, user) => {
	dispatch(change({language: lng, userId: user.id}));
	setFlag(FLAG[lng]);
	setLanguage(LANGUAGE[lng]);
};

const Content = ({setFlag, setLanguage, t, user}) => {
	const dispatch = useDispatch();
	return <ul className={"list-language"}>
		{['vi', 'zh'].map(locale => (
			<li className={"action-item"}>
				<a href={'#top'} className='ant-dropdown-link'
				   onClick={() => changeLanguage(locale, setFlag, setLanguage, dispatch, user)}>
					<img src={FLAG[locale]} alt="ImageFlag" className='img__flag-language'/>
					{t('language:locales.' + locale)}
				</a>
			</li>
		))}
	</ul>
};

export default withTranslation()(Content);
