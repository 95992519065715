import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'configWarehouse.formWarehouseStaff' + (param ? '.' + param : ''), defaultValue);

export const getErrors = error => {
    let data = {...error.data};
    if (lodash.get(data, "username.required_without", null) !== null) {
        delete data.username;
        data.username = {};
        data.username.required = [];
    }

    if (lodash.get(data, "username.unique", null) !== null) {
        delete data.username;
        data.username = {};
        data.username.unique_warehouse_staff = [];
    }

    return lodash.mapValues(data, (rules, param) => {
        let attribute = t('warehouse_staff:label.' + param);
        let messages = translateValidationErrors(attribute, rules);
        return lodash.first(lodash.values(messages));
    });
};
