import * as SoundConstants from '../../modules/Sound/constants';
import config from '../../config/app';
import i18n from "../../system/i18n/i18n";

const baseSoundURL = config.soundUrl;
const languageGlobal = i18n.language || 'vi';
const SoundUrl = baseSoundURL + 'sounds/' + languageGlobal;
const soundUrlWithoutLanguage = `${baseSoundURL}sounds`;

export default {
    [SoundConstants.SOUND_CONSTANT.SUCCESS]: {
        src: [`${SoundUrl}/success.mp3`],
    },
    [SoundConstants.SOUND_CONSTANT.ERROR]: {
        src: [`${SoundUrl}/error.mp3`],
    },
    [SoundConstants.SOUND_CONSTANT.NETWORK_ERROR]: {
        src: [`${SoundUrl}/network_error.mp3`],
    },

    // notices
    [`${SoundConstants.SOUND_CONSTANT.ATTENTION}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/attention.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BUBBLE_WRAPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CANCELLED}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/cancelled.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CARGO_INSURANCE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/cargo_insurance.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CHECKED}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/checked.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.COMMITMENT_TRANSPORT_TIME}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/commitment_transport_time.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DETACH_PACKAGE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/detach_package.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DIRECT_DELIVERY}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/direct_delivery.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DUPLICATE_PACKAGE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/duplicate_package.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.ECO_SHIPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/eco_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.ECOMMERCE_SHIPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/ecommerce_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.EXPORTED_WAREHOUSE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/exported_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.EXPRESS_SHIPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/express_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FRAGILE_BUBBLE_WRAPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/fragile_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FRAGILE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/fragile.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FREIGHT_SHIPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/freight_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.IMPORTED_WAREHOUSE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/imported_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.INSPECTION}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/inspection.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOHA}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/loha.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOHI}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/lohi.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOST}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/lost.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.MISSING_ORDER}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/missing_order.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NOT_IN_BAG}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/not_in_bag.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NOT_MATCH}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/not_match.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.OUT_OF_STOCK}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/out_of_stock.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.PACKAGED}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/packaged.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SEA_SHIPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/sea_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SHIPMENT}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/shipment.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SPECIAL_BUBBLE_WRAPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/special_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SPECIAL_PACKAGING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/special_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STANDARD_BUBBLE_WRAPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/standard_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STANDARD_PACKAGING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/standard_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STORAGE_TOO_LONG}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/storage_too_long.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SUFFICIENT_ECOMMERCE_CONDITION}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/sufficient_ecommerce_condition.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SUPER_FRAGILE_BUBBLE_WRAPPING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/super_fragile_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.THAN_AMOUNT_ORDER}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/than_amount_order.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.UNPROCESSED}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/unprocessed.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WOOD_PACKAGING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wood_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_BAG}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_bag.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_CUSTOMER}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_customer.mp3`],
    },[`${SoundConstants.SOUND_CONSTANT.WRONG_SHIPPING_PARTNER}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_shipping_partner.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_WAREHOUSE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_warehouse.mp3`],
    },

    [`${SoundConstants.SOUND_CONSTANT.ATTENTION}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/attention.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BUBBLE_WRAPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CANCELLED}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/cancelled.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CARGO_INSURANCE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/cargo_insurance.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.CHECKED}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/checked.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.COMMITMENT_TRANSPORT_TIME}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/commitment_transport_time.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DETACH_PACKAGE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/detach_package.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DIRECT_DELIVERY}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/direct_delivery.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DUPLICATE_PACKAGE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/duplicate_package.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.ECO_SHIPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/eco_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.ECOMMERCE_SHIPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/ecommerce_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.EXPORTED_WAREHOUSE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/exported_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.EXPRESS_SHIPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/express_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FRAGILE_BUBBLE_WRAPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/fragile_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FRAGILE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/fragile.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.FREIGHT_SHIPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/freight_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.IMPORTED_WAREHOUSE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/imported_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.INSPECTION}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/inspection.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOHA}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/loha.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOHI}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/lohi.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.LOST}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/lost.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.MISSING_ORDER}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/missing_order.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NOT_IN_BAG}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/not_match.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NOT_MATCH}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/not_match.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.OUT_OF_STOCK}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/out_of_stock.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.PACKAGED}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/packaged.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SEA_SHIPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/sea_shipping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SHIPMENT}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/shipment.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SPECIAL_BUBBLE_WRAPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/special_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SPECIAL_PACKAGING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/special_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STANDARD_BUBBLE_WRAPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/standard_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STANDARD_PACKAGING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/standard_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.STORAGE_TOO_LONG}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/storage_too_long.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SUFFICIENT_ECOMMERCE_CONDITION}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/sufficient_ecommerce_condition.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SUPER_FRAGILE_BUBBLE_WRAPPING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/super_fragile_bubble_wrapping.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.THAN_AMOUNT_ORDER}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/than_amount_order.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.UNPROCESSED}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/unprocessed.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WOOD_PACKAGING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wood_packaging.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_BAG}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_bag.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_CUSTOMER}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_customer.mp3`],
    },[`${SoundConstants.SOUND_CONSTANT.WRONG_SHIPPING_PARTNER}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_shipping_partner.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_WAREHOUSE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.IN_SOURCE_WAREHOUSE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/in_source_warehouse.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_FLOW}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_flow.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_FLOW}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_flow.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BIFIN_ADVANCE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/bifin_advance.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BIFIN_ADVANCE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/bifin_advance.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.RESTRICT_ALERT}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/restrict_alert.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.RESTRICT_ALERT}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/restrict_alert.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DISTRIBUTING_WAREHOUSE_STORAGE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/distributing_warehouse_storage.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.DISTRIBUTING_WAREHOUSE_STORAGE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/distributing_warehouse_storage.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BARCODE_SCANNING_DUPLICATE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/barcode_scanning_duplicate.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.BARCODE_SCANNING_DUPLICATE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/barcode_scanning_duplicate.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_AGENCY}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/wrong_agency.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.WRONG_AGENCY}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/wrong_agency.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SBM_REAL_ECOM}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/sbm_real_ecom.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.SBM_REAL_ECOM}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/sbm_real_ecom.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NO_CHECKING}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/no_checking.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.NO_CHECKING}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/no_checking.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.INACTIVE}_VI`]: {
        src: [`${soundUrlWithoutLanguage}/vi/inactive.mp3`],
    },
    [`${SoundConstants.SOUND_CONSTANT.INACTIVE}_ZH`]: {
        src: [`${soundUrlWithoutLanguage}/zh/inactive.mp3`],
    }
}
