import { filter, first, get, mapValues, values } from 'lodash';
import { createSelector } from 'reselect';

import { translateValidationErrors } from './../Common/services/helps';
import { t } from './../../system/i18n';
import { isPackageInventory, isReceivePackageInventory } from './helper';

export const getState = (state, param = null, defaultValue = null) => get(state, 'inventory' + (param ? '.' + param : ''), defaultValue);

export const getErrors = error => {
    return mapValues(error.data, (rules, param) => {
        let messages = translateValidationErrors(t('inventory:' + param), rules);
        return {text: first(values(messages)), id: error.id};
    });
};

export const remainders = createSelector(
    state => getState(state, 'detail'),
    inventory => {
        const inventoryDetail = get(inventory, 'inventory');
        const inventoryPackages = get(inventory, 'inventory_packages');
        const inventoryTrackingBills = get(inventory, 'inventory_tracking_bills');

        if (isPackageInventory(inventoryDetail)) {
            return filter(inventoryPackages, inventoryPackage => get(inventoryPackage, 'inventory_package.situation') === 'remainder');
        }

        if (isReceivePackageInventory(inventoryDetail)) {
            return filter(inventoryTrackingBills, inventoryTrackingBill => get(inventoryTrackingBill, 'inventory_tracking_bill.situation') === 'remainder');
        }

        return [];
    }
);

export const disappears = createSelector(
    state => getState(state, 'detail'),
    inventory => {
        const inventoryDetail = get(inventory, 'inventory');
        const inventoryPackages = get(inventory, 'inventory_packages');
        const inventoryTrackingBills = get(inventory, 'inventory_tracking_bills');

        if (isPackageInventory(inventoryDetail)) {
            return filter(inventoryPackages, inventoryPackage => get(inventoryPackage, 'inventory_package.situation') === 'disappear');
        }

        if (isReceivePackageInventory(inventoryDetail)) {
            return filter(inventoryTrackingBills, inventoryTrackingBill => get(inventoryTrackingBill, 'inventory_tracking_bill.situation') === 'disappear');
        }

        return [];
    }
);

export const normals = createSelector(
    state => getState(state, 'detail'),
    inventory => {
        const inventoryDetail = get(inventory, 'inventory');
        const inventoryPackages = get(inventory, 'inventory_packages');
        const inventoryTrackingBills = get(inventory, 'inventory_tracking_bills');

        if (isPackageInventory(inventoryDetail)) {
            return filter(inventoryPackages, inventoryPackage => {
                const situtation = get(inventoryPackage, 'inventory_package.situation');

                return situtation !== 'disappear' && situtation !== 'remainder';
            });
        }

        if (isReceivePackageInventory(inventoryDetail)) {
            return filter(inventoryTrackingBills, inventoryTrackingBill => {
                const situtation = get(inventoryTrackingBill, 'inventory_tracking_bill.situation');

                return situtation !== 'disappear' && situtation !== 'remainder';
            });
        }

        return [];
    }
);
