import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'property.formInput' + (param ? '.' + param : ''), defaultValue);

export const getErrors = error => {
	return lodash.mapValues(error.data, (rules, param) => {
		let messages = translateValidationErrors(t('config:label.' + param + '_property'), rules);
		return lodash.first(lodash.values(messages));
	});
};
