import React from 'react';
import PropTypes from 'prop-types';

class PageTitle extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <h3 className="title mr-3 _title">{this.props.title || 'Global Logistic'}</h3>
                {this.props.description}
            </React.Fragment>
        );
    }
}

PageTitle.defaultProps = {
    title: null,
    description: null,
};

PageTitle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

export default PageTitle;
