import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.UPDATE_ALONE_PACKAGE.REQUEST, saga.updateAlonePackage);
    yield takeLatest(ACTION.CREATE_ALONE_PACKAGE.REQUEST, saga.createAlonePackage);
    yield takeLatest(ACTION.UPDATE_ALONE_PACKAGE.SUCCESS, saga.updateAlonePackageSuccess);
    yield takeLatest(ACTION.CREATE_ALONE_PACKAGE.SUCCESS, saga.createAlonePackageSuccess);
    yield takeLatest(ACTION.UPDATE_ALONE_PACKAGE.FAILED, saga.updateAlonePackageFailed);
    yield takeLatest(ACTION.CREATE_ALONE_PACKAGE.FAILED, saga.createAlonePackageFailed);

}
