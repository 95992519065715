import React from 'react';
import { Col, Form, Select } from 'antd';
import { first, get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const Option = Select.Option;

class FormBagShippingType extends React.Component {
    constructor(props) {
        super(props);
        this.autoSelected = false;
    }

    componentDidMount() {
        this.autoSelect();
    }

    componentDidUpdate() {
        this.autoSelect();
    }

    /**
     * Xử lý tự động select reason đầu tiên khi không truyền vào reason hiện tại
     */
    autoSelect = () => {
        let {value, bagShippingTypes} = this.props;

        if (this.props.autoSelect && !this.autoSelected && !value && bagShippingTypes.length) {
            this.autoSelected = true;
            this.changeInput({value: get(first(bagShippingTypes), 'id')});
        }
    };

    changeInput = (input) => {
        this.props.onChange(input.value);
    };

    render() {
        const { allowClear, bagShippingTypes, disabled, dropdownClassName, errors, loading, noForm, t, value } = this.props;
        const formattedSelectProps = {
            allowClear,
            className: 'a-select a-select--w-100 _subject_shipping',
            defaultValue: this.autoSelected ? get(first(bagShippingTypes), 'name') : undefined,
            disabled,
            dropdownClassName,
            loading,
            optionFilterProp: 'children',
            showSearch: true,
            value: !value ? (this.autoSelected ? get(first(bagShippingTypes), 'name') : undefined) : value,
            onChange: value => this.changeInput({
                value
            })
        };

        return noForm ? (
            <Select {...formattedSelectProps}>
                {bagShippingTypes.map(bagShippingType => (
                    <Option
                        className="_subject_shipping__item"
                        key={bagShippingType.id}
                        value={bagShippingType.id}
                    >
                        {bagShippingType.name}
                    </Option>
                ))}
            </Select>
        ) : (
            <Col
                className="a-form--group"
                lg={{span: 8}}
                xl={{span: 6}}
                xxl={{span: 5}}
            >
                <Form.Item
                    className="a-form__item"
                    help={errors.value}
                    label={t('bag:label.shipping_type')}
                    labelCol={{span: 24}}
                    validateStatus={errors.value ? 'error' : null}
                >
                    <Select {...formattedSelectProps}>
                        {bagShippingTypes.map(bagShippingType => (
                            <Option
                                className="_subject_shipping__item"
                                key={bagShippingType.id}
                                value={bagShippingType.id}
                            >
                                {bagShippingType.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        );
    }
}

FormBagShippingType.defaultProps = {
    errors: {},
    autoSelect: false,
    disabled: false,
    onChange: () => {
    },
    loading: false
};

FormBagShippingType.propTypes = {
    loading: PropTypes.bool,
    value: PropTypes.any,
    errors: PropTypes.object,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withTranslation()(FormBagShippingType);