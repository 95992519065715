import {getConfig} from '../system/support/helpers';

export default {
    /**
     * The current language
     */
    language: getConfig('LANG', 'vi'),

    /**
     * The fallback language
     */
    fallbackLanguage: getConfig('LANG_FALLBACK', 'vi'),
};