import * as Action from "./constants";
import apiService from "./apiService";
import {processApiRequest} from "../saga";
import i18n from '../../../system/i18n/i18n';
export function* changeLanguageRequest(action) {
	yield processApiRequest(Action.CHANGE_LANGUAGE, () => apiService.changeLanguage(action.payload), action.payload);
}

export function* changeLanguageSuccess(action) {
	i18n.changeLanguage(action.request.language);
	yield  window.location.reload();
}
