import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Input} from 'antd';

class InputNumber extends React.PureComponent {
    constructor(props) {
        super(props);
        this.element = null;
    }

    focus() {
        if (this.element) {
            this.element.focus();
        }
    }

    /**
     * Xử lý khi input changed
     *
     * @param e
     */
    onChange(e) {
        let value = this.normalizeNumberInput(e.target.value || '');

        if (value === '' || this.makeRegExp().test(value)) {
            this.props.onChange(value);
        }
    }

    /**
     * Chuẩn hóa lại input theo format của number
     *
     * @param {string} value
     * @return {string}
     */
    normalizeNumberInput(value) {
        if (!value) {
            return value;
        }

        const {min, type} = this.props;

        // Nếu không cho nhập số âm thì loại bỏ ký tự '-'
        if (lodash.isNumber(min) && min >= 0) {
            value = value.replace(/-+/, '');
        }

        let normalizedValue = value.replace(/[^0-9.]+/, '') // Loại bỏ các ký tự không hợp lệ
            .replace(/^(-?)\./, '$10.') // '.' => '0.'
            .replace(/^(-?)0+/, '$10') // 000 => 0
            .replace(/^(-?)0([1-9]+)/, '$1$2');

        if (type === 'integer') {
            normalizedValue = normalizedValue.replace(/\.+/, '');
        }

        return normalizedValue;
    }

    /**
     * Tạo regex để kiểm tra format của input
     *
     * @return {RegExp}
     */
    makeRegExp() {
        let {min, precision} = this.props;

        let pattern = (!lodash.isNumber(min) || min < 0) ? '-?[0-9]*' : '[0-9]+';

        if (precision !== 0) {
            let decimalRepeat = precision ? `{0,${precision}}` : '*';
            pattern += '(.[0-9]' + decimalRepeat + ')?';
        }

        return new RegExp('^' + pattern + '$');
    }

    /**
     * Xử lý number theo props khi blur input
     */
    onBlur() {
        const {value, onBlur, onChange} = this.props;
        const processedValue = this.processNumber(value);

        if (processedValue !== parseFloat(value)) {
            onChange(processedValue);
        }

        if (onBlur) {
            onBlur();
        }
    }

    /**
     * Xử lý number theo props
     *
     * @param {string} value
     * @return {number}
     */
    processNumber(value) {
        let {precision, min, max} = this.props;

        if (value === '' || lodash.isNil(value)) {
            return value;
        }

        if (lodash.isString(value) && (value.charAt(value.length - 1) === '.' || value === '-')) {
            value = value.slice(0, -1);
        }

        value = parseFloat(value) || 0;

        if (lodash.isNumber(precision)) {
            value = lodash.round(value, precision);
        }

        if (lodash.isNumber(min)) {
            value = lodash.max([value, min]);
        }

        if (lodash.isNumber(max)) {
            value = lodash.min([value, max]);
        }

        return value;
    }

    convertMaxMin(value) {
        let {min, max} = this.props;
        if (lodash.isNumber(min) && !lodash.isNil(value)) {
            value = lodash.max([value, min]);
        }

        if (lodash.isNumber(max) && !lodash.isNil(value)) {
            value = lodash.min([value, max]);
        }

        return value;
    }

    render() {
        const {loading} = this.props;

        return (
            <Input
                placeholder={this.props.placeholder}
                disabled={loading}
                {...lodash.omit(this.props, ['precision', 'min', 'max', 'type', 'loading'])}
                value={this.convertMaxMin(this.props.value)}
                onChange={this.onChange.bind(this)}
                onBlur={this.onBlur.bind(this)}
                ref={element => this.element = element}
            />
        );
    }
}

InputNumber.defaultProps = {
    value: null,
    disabled: false,
    precision: null,
    min: null,
    max: null,
    onChange: () => {
    },
};

InputNumber.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    disabled: PropTypes.bool,
    precision: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
};

export default InputNumber;
