import i18n from 'i18next';
import config from '../../config/i18n';
import vi from '../../locales/vi';
import zh from '../../locales/zh';

i18n.init({
    resources: {vi, zh},
    lng: config.language,
    fallbackLng: config.fallbackLanguage,
    defaultNS: 'common',
    interpolation: {
        escapeValue: false, // not needed for react!!
        // prefix: '{',
        // suffix: '}',
    },
    react: {
        wait: true, // set to true if you like to wait for loaded in every translated hoc
    },
});

export default i18n;
