import React from 'react';
import { withTranslation } from 'react-i18next';

import ReadyForDeliveries from './components';
import {showDetailDeliveryRequest} from "../../../../DeliveryRequest/DetailDeliveryRequest/actions";
import {connect} from "react-redux";

const ReadyForDeliveriesContainer = props => (
    <ReadyForDeliveries {...props} />
);

const mapDispatchToProps = {
    showDeliveryRequestDetailModal: showDetailDeliveryRequest
}

export default withTranslation()(connect(null, mapDispatchToProps)(ReadyForDeliveriesContainer));
