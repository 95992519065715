/* eslint-disable */
import React, {Component} from 'react';
import {
    Button,
    Input,
    Row,
    Col,
    Icon,
    Tabs,
    Pagination, Collapse, Form, Upload, Radio, Checkbox, AutoComplete, Select
} from 'antd';

const Search = Input.Search;
const TextArea = Input.TextArea;
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;


const datas = [];
for (let i = 0; i < 6; i++) {
    datas.push({
        id: i,
        code: `322161995195${i}`,
    });
}

class EditDetailPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeOrder: datas[0].code
        }
    }

    onchageOrder = (codeOrder) => {
        this.setState({codeOrder})
    }

    render() {
        const {codeOrder} = this.state;
        return (
            <Row>
                <div className="a-content--ischatbox a-package--detail">
                    <div className="list-order">
                        <div className="list-order--top">
                            <h3 className="title">Đơn hàng <span>- 1231285894</span></h3>
                            <div className="list-order--top--right">
                                <Search
                                    placeholder="Tìm kiếm"
                                    className="a-input--search-order"
                                />
                                <Pagination defaultCurrent={1} total={40} className="a-pagination--order-package"/>
                            </div>
                        </div>
                        <ul className="list-order--detail">
                            {
                                datas.map((data, key) =>
                                    <li onClick={e => this.onchageOrder(data.code)}
                                        className={codeOrder === data.code ? "active" : ""}
                                    >
                                        <span>{data.code}</span>
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                    <div className="block--primary a-package pb-4">
                        <div className="title">
                            <div className="left">
                                <span className="block-mr-50"><strong>Mã kiện</strong> | 12385948459</span>
                                <span>Kho hiện tại: <strong>BT-CNGZ</strong> / Kho đích: <strong>HN-VNHH</strong></span>
                            </div>
                            <div className="right">
                                <Button type="button" className="a-btn a-btn--transparent-white" ghost>Lịch sử
                                    quét </Button>
                            </div>
                        </div>
                        <div className="body a-package__body ">
                            <Row gutter={{lg: 24, xl: 48}} className="a-block-info">
                                <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                    <h4>Thông tin kiện hàng</h4>
                                </Col>
                                <Col lg={{span: 16}} xl={{span: 19}} xxl={{span: 19}}>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray info--detail">
                                        <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                            <h4>Thông tin kiện hàng</h4>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Mã vận đơn</label>
                                            <a href="#" className="info--value a-text--blue">798273948274982</a>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Trạng thái</label>
                                            <span className="info--value ">Lưu kho( 12:15:56 29/02/2019)</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Đơn hàng</label>
                                            <a href="#" className="info--value a-text--blue a-hidden">Khớp đơn</a>
                                            <AutoComplete
                                                placeholder={'Mã khớp đơn'}
                                                className="a-input a-input--matching-code detail-package"
                                            />
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Thời gian tạo</label>
                                            <span className="info--value ">12:15 29/02/2019</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 16}} xxl={{span: 16}}
                                             className="info--group">
                                            <label className="info--label">Nhóm ngành hàng</label>
                                            <span className="info--value ">XYZ</span>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 7}} xxl={{span: 4}}
                                             className="a-form--group">
                                            <Form.Item className="a-form__item " label="Tình trạng"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select--w-100"
                                                    showSearch={true}
                                                    defaultValue="Lưu kho"
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value='1'><Checkbox>Checkbox</Checkbox></Option>
                                                    <Option value='2'><Checkbox>Checkbox</Checkbox></Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24, offset: 1}} xl={{span: 5, offset: 1}}
                                             xxl={{span: 4, offset: 4}}
                                             className="a-form--group">
                                            <Form.Item className="a-form__item reason--save-warehouse "
                                                       label="Lý do lưu kho"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select-rease--warehouse a-select--w-100"
                                                    showSearch={true}
                                                    defaultValue="Không rõ nguồn gốc"
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value="Không rõ nguồn gốc">Không rõ nguồn
                                                        gốc</Option>
                                                    <Option value="Khác">Khác</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 9}}
                                             xxl={{span: 6}}
                                             className="a-form--group input-rease-warehouse">
                                            <Form.Item className="a-form__item reason--save-warehouse "
                                                       label=""
                                                       labelCol={{span: 24}}>
                                                <Input placeholder={"Nhập lý do khác"}
                                                       className="a-input--reaseon-save-warehouse a-input--disable"/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 24}}
                                             xxl={{span: 24}}
                                             className="a-form--group">

                                            <Form.Item className="a-form__item  "
                                                       label="Đặc tính"
                                                       labelCol={{span: 24}}>
                                                <ul className="property--package">
                                                    <li className="list active">
                                                        <a href="#" className="link">Điện tử</a>
                                                    </li>
                                                    <li className="list">
                                                        <a href="#" className="link">Dễ vỡ</a>
                                                    </li>
                                                    <li className="list">
                                                        <a href="#" className="link">Hóa chất</a>
                                                    </li>
                                                    <li className="list">
                                                        <a href="#" className="link">Giá trị cực cực cao</a>
                                                    </li>
                                                </ul>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 24}}
                                             xxl={{span: 24}}
                                             className="a-form--group">
                                            <Form.Item className="a-form__item  " label="Ghi chú"
                                                       labelCol={{span: 24}}>
                                                            < TextArea className="a-input--textarea"
                                                                       autosize={{minRows: 1, maxRows: 3}}
                                                                       allowClear={true}
                                                                       spellCheck={false}
                                                                       placeholder={"Ghi chú"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray mt-4">
                                        <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                            <h4>Thông số kiện hàng</h4>
                                        </Col>
                                        <Form onSubmit={this.handleSubmit} className="a-form form--package">
                                            <Col lg={{span: 24}} xl={{span: 7}} xxl={{span: 6}}>
                                                <Form.Item
                                                    className="a-form__item a-form--loading loading--volumn-package"
                                                    label={<span
                                                        className="label-require">Cân nặng (g)</span>}
                                                    labelCol={{span: 24}}>
                                                    <Input placeholder="Cân nặng tịnh"
                                                           className="a-input a-input--bill-code-small"/>
                                                    <Icon type="loading-3-quarters"
                                                          className="icon-loading "/>
                                                    <span className="a-text--black-45 ml-3">x 100</span>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 24}} xl={{span: 17}} xxl={{span: 18}}>
                                                <div className="a-package__body--group-size">
                                                    <Form.Item
                                                        className="a-form__item"
                                                        label={<span>Kích thước(cm)</span>}
                                                        labelCol={{span: 24}}>
                                                        <div className="group--input-bill-code-small">
                                                            <div
                                                                className="a-form--loading loading-input mr-4">
                                                                <Input placeholder="Dài"
                                                                       className="a-input a-input--package-size a-input--warning "/>
                                                                <Icon type="loading-3-quarters"
                                                                      className="icon-loading"/>
                                                            </div>
                                                            <div
                                                                className="a-form--loading loading-input mr-4">
                                                                <Input placeholder="Rộng"
                                                                       className="a-input a-input--package-size "/>
                                                                <Icon type="loading-3-quarters"
                                                                      className="icon-loading"/>
                                                            </div>
                                                            <div
                                                                className="a-form--loading loading-input mr-4">
                                                                <Input placeholder="Cao"
                                                                       className="a-input a-input--package-size"/>
                                                                <Icon type="loading-3-quarters"
                                                                      className="icon-loading"/>
                                                            </div>
                                                            <span
                                                                className="bill-code-sub">= 1000 (cm<sup>3</sup>)</span>
                                                        </div>
                                                        <p className="a-text--warning mb-0 mt-1 a-hidden">abcde</p>
                                                        <p className="a-text--warning mb-0 mt-1 a-hidden">abcde</p>
                                                        <div className="mt-4">
                                                            <a href="#" className="link-reload "><Icon type="close" />Xóa kích thước</a>
                                                        </div>
                                                    </Form.Item>
                                                    <div className="info--group package--block-volumn">
                                                        <label className="info--label">Cân nặng quy đổi
                                                            (kg)</label>
                                                        <a href="#" className="info--value ">1000</a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form>
                                    </Row>
                                </Col>
                                <Col lg={{span: 8}} xl={{span: 5}} xxl={{span: 5}} className="pl-0">
                                    <div className="a-package--service">
                                        <h2 className="title">Lựa Chọn Dịch Vụ</h2>
                                        <div className="service--body">
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <ul>
                                                            <li>
                                                                <Radio className="label--service" checked>Chuyển
                                                                    thẳng</Radio>
                                                            </li>
                                                            <li>
                                                                <Radio className="label--service">Chuyển
                                                                    phát nhanh</Radio>
                                                            </li>
                                                            <li>
                                                                <Radio className="label--service">Chuyển
                                                                    phát tiết kiệm</Radio>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </h4>
                                            </div>
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <div className="pb-5">
                                                            <ul className="mt-5">
                                                                <li>
                                                                    <Checkbox className="label--service"
                                                                              checked>Ký
                                                                        gửi</Checkbox>
                                                                </li>
                                                                <li>
                                                                    <Checkbox className="label--service">Kiểm
                                                                        hàng</Checkbox>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </h4>
                                            </div>
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <div className="pb-5">
                                                            <ul className="mt-5">
                                                                <li>
                                                                    <Radio className="label--service"
                                                                           checked>Đóng
                                                                        gỗ
                                                                        chung</Radio>
                                                                </li>
                                                                <li>
                                                                    <Radio className="label--service">Đóng
                                                                        gỗ
                                                                        riêng</Radio>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </h4>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={{lg: 24, xl: 48}} className={"a-block-info mt-5"}>
                                <Col lg={{span: 24}} xl={{span: 24}} className="info--title iscollapse">
                                    <div className="title">
                                        <h4>Thông tin khách hàng</h4>
                                    </div>
                                    <div className="title--collapse">
                                        <span>Thu gọn<Icon type="shrink" className="icon-collapse"/></span>
                                    </div>

                                </Col>
                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                                    <Form.Item className="a-form__item " label="Người gửi"
                                               labelCol={{span: 24}}>
                                        <Select
                                            className="a-select a-select--w-100"
                                            showSearch={true}
                                            defaultValue="Lưu kho"
                                            suffixIcon={
                                                <Icon
                                                    type="down"
                                                    className="icon__dropdown__select"
                                                    onChange={this.handleSelectChange}
                                                />}
                                        >
                                            <Option value='1'>ABC</Option>
                                            <Option value='2'>DEF</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={{span: 8, offset: 0}} xl={{span: 6}} xxl={{span: 4, offset: 1}}
                                     className="info--group">
                                    <Form.Item className="a-form__item " label="Người nhận"
                                               labelCol={{span: 24}}>
                                        <Input placeholder="Nhập người nhận "/>
                                    </Form.Item>
                                </Col>
                                <Col lg={{span: 8, offset: 0}} xl={{span: 6}} xxl={{span: 4, offset: 1}}
                                     className="info--group">
                                    <Form.Item className="a-form__item " label="Số điện thoại"
                                               labelCol={{span: 24}}>
                                        <Input placeholder="Nhập số điện thoại "/>
                                    </Form.Item>
                                </Col>
                                <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                                    <Row gutter={{lg: 24, xl: 48}}>
                                        <Col lg={{span: 8, offset: 0}} xl={{span: 6}} xxl={{span: 4}}
                                             className="info--group">
                                            <Form.Item className="a-form__item " label="Quốc gia"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select--w-100"
                                                    showSearch={true}
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value='1'>ABC</Option>
                                                    <Option value='2'>DEF</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 8, offset: 0}} xl={{span: 6}}
                                             xxl={{span: 4, offset: 1}}
                                             className="info--group">
                                            <Form.Item className="a-form__item " label="Tỉnh thành"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select--w-100"
                                                    showSearch={true}
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value='1'>ABC</Option>
                                                    <Option value='2'>DEF</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 8, offset: 0}} xl={{span: 6}}
                                             xxl={{span: 4, offset: 1}}
                                             className="info--group">
                                            <Form.Item className="a-form__item " label="Quận huyện"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select--w-100"
                                                    showSearch={true}
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value='1'>ABC</Option>
                                                    <Option value='2'>DEF</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 8, offset: 0}} xl={{span: 6}}
                                             xxl={{span: 4, offset: 1}}
                                             className="info--group">
                                            <Form.Item className="a-form__item " label="Kho đích"
                                                       labelCol={{span: 24}}>
                                                <Select
                                                    className="a-select a-select--w-100"
                                                    showSearch={true}
                                                    suffixIcon={
                                                        <Icon
                                                            type="down"
                                                            className="icon__dropdown__select"
                                                            onChange={this.handleSelectChange}
                                                        />}
                                                >
                                                    <Option value='1'>ABC</Option>
                                                    <Option value='2'>DEF</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24, offset: 0}} xl={{span: 12}} xxl={{span: 9}}
                                             className="info--group">
                                            <Form.Item className="a-form__item  "
                                                       label="Địa chỉ nhận"
                                                       labelCol={{span: 24}}>
                                                            < TextArea className="a-input--textarea"
                                                                       autosize={{minRows: 1, maxRows: 3}}
                                                                       allowClear={true}
                                                                       spellCheck={false}
                                                                       placeholder={"Ghi chú"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24, offset: 0}} xl={{span: 12}}
                                             xxl={{span: 14, offset: 1}}
                                             className="info--group">
                                            <Form.Item className="a-form__item  "
                                                       label="Ghi chú nhận"
                                                       labelCol={{span: 24}}>
                                                            < TextArea className="a-input--textarea"
                                                                       autosize={{minRows: 1, maxRows: 3}}
                                                                       allowClear={true}
                                                                       spellCheck={false}
                                                                       placeholder={"Ghi chú"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={48}>
                                <Col lg={{span: 24}} xl={{span: 24}}
                                     className="a-package--action only-action">
                                    <a className="a-btn a-btn--back-package ">Thoát</a>
                                    <Button type="primary" htmlType="submit"
                                            className="a-btn a-btn--primary a-btn--save-package ">Lưu</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="a-chatbox">
                    <div className="a-chatbox--input a-hidden">
                        <Input placeholder="Nhập thông tin cần hỗ trợ..." className="a-input a-input--chatbox"/>
                        <Upload name="logo" action="/upload.do" listType="picture" className="a-btn--chat-upload">
                            <Button>
                                <Icon type="camera"/>
                            </Button>
                        </Upload>
                        <Button type="primary" htmlType="submit"
                                className="a-btn a-btn--primary a-btn--submit-chatbox ">Gửi</Button>
                    </div>
                    <div className="a-chatbox--body">
                        <Tabs className="a-tabs a-tabs--chatbox">
                            <TabPane className="a-tabs--chatbox--panel" tab="Thông tin hệ thống" key="3">
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>

                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Row>
        )
    }
}

export default EditDetailPackage;