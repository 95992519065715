import React from 'react';
import {Icon, Tabs} from 'antd';
import ListSameDayBags from '../../ListSameDayBags/ListSameDayBagsContainer';
import Log from '../../../Common/Log/LogContainer';
import {t} from "../../../../system/i18n"
import BagChat from "./BagChat";
import CustomerNotice from "./CustomerNotice";
import authService from "../../../Auth/authService";
import permissions from "../../../Auth/permissions";

class BagBlockRight extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
            iconCollapse: false,
            lbag_today: 0,
            hheader: 104,
            halfwindowinfo: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true, lbag_today: this.state.lbag_today + this.state.hheader});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false, lbag_today: this.state.lbag_today - this.state.hheader});
            }
        }
    };

    onClickIconCollapse = (iconCollapse) => {
        let warehousescroll;
        warehousescroll = window.innerHeight / 2 - 130;
        if (iconCollapse) {
            this.setState({iconCollapse, lbag_today: this.state.lbag_today + warehousescroll});
        } else {
            this.setState({iconCollapse, lbag_today: this.state.lbag_today - warehousescroll});
        }
    };

    render() {
        let {scroll, iconCollapse} = this.state;
        let {bag} = this.props;

        return (
            <div className={"info-right-detail-bag checkpackage " + (scroll ? 'scroll' : '')}>
                <div className={`block bag-today ${iconCollapse ? 'log-system-collapse' : ''}`}
                     ref="bag_today">
                    <ListSameDayBags bagId={bag.id}/>
                </div>

                <div
                    className={` block log-system ${iconCollapse ? 'collapse' : ''}`}>
                    <div className="title-block ">
                            <span>
                                <Icon type="arrows-alt" onClick={e => this.onClickIconCollapse(true)}
                                      className={`icon-collapse ${iconCollapse ? 'a-hidden' : ''}`}/>
                                <Icon type="shrink" onClick={e => this.onClickIconCollapse(false)}
                                      className={`icon-collapse ${!iconCollapse ? 'a-hidden' : ''}`}/></span>
                    </div>
                    <div className="a-chatbox a-chatbox-height  ml-0">
                        <div className="a-chatbox--body">
                            <Tabs defaultActiveKey={'log'}
                                  className="a-tabs a-tabs--chatbox-tab  _a-tabs-chatbox-tab">
                                <Tabs.TabPane className="a-tabs--chatbox--panel-bag-log _a-tabs-chatbox--panel-log"
                                              tab={t('bag:label.log')} key="log">
                                    <Log object="bags" objectId={bag.id}/>
                                </Tabs.TabPane>
                                {
                                    authService.can(permissions.BAG_NOTICE_CUSTOMER) &&
                                    <Tabs.TabPane className="a-tabs--chatbox--panel-chat _a-tabs-chatbox--panel-chat"
                                                  tab={t('bag:label.notice_to_customer')} key="notice">
                                        <CustomerNotice bag={bag}/>

                                    </Tabs.TabPane>
                                }
                                <Tabs.TabPane className="a-tabs--chatbox--panel-chat _a-tabs-chatbox--panel-chat"
                                              tab={t('bag:label.chat')} key="chat">
                                    <BagChat
                                        bag={bag}
                                    />

                                </Tabs.TabPane>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BagBlockRight;
