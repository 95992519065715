import React, { useState } from 'react';
import { notification } from 'antd';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';

import apiService from './../apiService';
import customerApiService from './../../Customer/apiService';
import { HTTP_STATUS } from './../../../system/api/constants';
import { getVar, notifyErrorFromServer } from './../../../system/support/helpers';
import { router } from './../../../system/routing';

import CreateDomesticShippingOrder from './components';

const CreateDomesticShippingOrderContainer = props => {
    const [customer, setCustomer] = useState({});
    const [deliveryNote, setDeliveryNote] = useState({});
    const [domesticShippingInfo, setDomesticShippingInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [remainingPackages, setRemainingPackages] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [selectedOrderIndex, setSelectedOrderIndex] = useState(-1);
    const [addingOrder, setAddingOrder] = useState(false);
    const [printing, setPrinting] = useState(false);

    const getDeliveryNote = async id => {
        try {
            const { data } = await apiService.getDeliveryNote(id);

            setDeliveryNote(data);
        } catch (error) {
        }
    };

    const handleChangeDomesticShippingInfo = data => {
        const orders = [
            ...getVar(data, 'shipping_orders.suggestions', []),
            ...getVar(data, 'shipping_orders.trackings', [])
        ];

        setOrders(orders);
        setSelectedOrder(orders.length ? orders[0] : {});
        setSelectedOrderIndex(orders.length ? 0 : -1);
    };


    const getDomesticShippingDetail = async id => {
        setLoading(true);

        try {
            const { data } = await apiService.getDomesticShippingDetail(id);

            setDomesticShippingInfo(data);
            handleChangeDomesticShippingInfo(data);
        } catch (error) {
            if (get(error, 'response.data.data.agency.not_is_staff')) {
                router.redirect('/403');

                return notification.error({
                    message: props.t('delivery_note:message.message_not_is_staff')
                });
            }

            if (get(error, 'response.data.code') === HTTP_STATUS.UNAUTHORIZED) {
                return router.redirect('/403');
            }
        }

        setLoading(false);
    };

    const getCustomer = async id => {
        if (customer && customer.customer && customer.customer.id && customer.customer.id === id) {
            return;
        }

        try {
            const { data } = await customerApiService.fetchDetailCustomer(id);

            setCustomer(data);
        } catch (error) {
            setCustomer({});
        }
    };

    const addPackages = packages => {
        const newRemainingPackages = remainingPackages.concat(packages);

        setRemainingPackages(newRemainingPackages);
    };

    const handleCreateOrder = async data => {
        if (printing) {
            return;
        }

        setAddingOrder(true);

        try {
            const response = await apiService.addLastMileOrder(getVar(domesticShippingInfo, 'id'), data);
            const newSelectedOrder = {
                ...orders[selectedOrderIndex],
                ...response.data.last_mile_order
            };

            const newOrders = [
                ...orders.slice(0, selectedOrderIndex),
                newSelectedOrder,
                ...orders.slice(selectedOrderIndex + 1)
            ];

            setOrders(newOrders);
            setSelectedOrder(newSelectedOrder);
            getDeliveryNote(get(deliveryNote, 'delivery_note.id'));

            notification.success({
                message: props.t('delivery_note:place.success')
            });
        } catch (error) {
            notifyErrorFromServer(error, props.t('delivery_note:place.failed'), 'delivery_note:place.errors');
        }

        setAddingOrder(false);
    };

    const handleCreateSuggestOrder = (packageIds, packages) => {
        if (!packageIds.length) {
            return;
        }

        let address = packages[0].customer_address;
        let location = packages[0].location;
        const isSameAddress = packages.slice(1).every(pkg => pkg.customer_address === address);
        const isSameLocation = packages.slice(1).every(pkg => isEqual(pkg.location, location));
        const newRemainingPackages = remainingPackages.filter(remainingPackage => !packageIds.includes(remainingPackage.id));
        const order = {
            packages,
            customer_address: isSameAddress ? address : undefined,
            location: isSameLocation ? location : undefined
        };

        const newOrders = [
            order,
            ...orders
        ];

        setRemainingPackages(newRemainingPackages);
        setOrders(newOrders);
        setSelectedOrder(newOrders.length ? newOrders[0] : {});
        setSelectedOrderIndex(newOrders.length ? 0 : -1);
    };

    const handleDeleteOrder = async () => {
        if (printing) {
            return;
        }

        const index = selectedOrderIndex;
        const order = orders[index];
        const newOrders = [
            ...orders.slice(0, index),
            ...orders.slice(index + 1)
        ];

        if (order.bag) {
            newOrders.unshift({
                bag: order.bag
            });
        }

        const handleSuccessDeleteOrder = () => {
            if (!order.bag) {
                const packages = getVar(order, 'packages', []).map(pkg => ({
                    ...pkg,
                    location: order.location
                }));

                addPackages(packages);
            }

            setOrders(newOrders);
            setSelectedOrder(newOrders.length ? newOrders[0] : {});
            setSelectedOrderIndex(newOrders.length ? 0 : -1);
        };

        if (order && order.id) {
            try {
                await apiService.deleteLastMileOrder(domesticShippingInfo.id, order.id);

                handleSuccessDeleteOrder();
            } catch (error) {
                notifyErrorFromServer(error, props.t('delivery_note:delete_last_mile_order.failed'), 'delivery_note:delete_last_mile_order.errors');
            }
        } else {
            handleSuccessDeleteOrder();
        }
    };

    const handleSelectOrder = index => {
        if (printing) {
            return;
        }

        setSelectedOrder(orders[index]);
        setSelectedOrderIndex(index);
    };

    const handlePrint = async () => {
        setPrinting(true);

        try {
            const { data } = await apiService.printLastMileOrder(domesticShippingInfo.id, selectedOrder.id);

            window.open(data, '_blank');
        } catch (error) {
            notifyErrorFromServer(error, props.t('delivery_note:print_last_mile_order.failed'), 'delivery_note:print_last_mile_order.errors');
        }

        setPrinting(false);
    };

    const handleSuccessExportDeliveryNote = data => {
        setDeliveryNote(data);
    };
    return (
        <CreateDomesticShippingOrder
            {...props}
            addingOrder={addingOrder}
            customer={customer}
            deliveryNote={deliveryNote}
            domesticShippingInfo={domesticShippingInfo}
            loading={loading}
            orders={orders}
            printing={printing}
            remainingPackages={remainingPackages}
            selectedOrder={selectedOrder}
            selectedOrderIndex={selectedOrderIndex}
            getCustomer={getCustomer}
            getDeliveryNote={getDeliveryNote}
            getDomesticShippingDetail={getDomesticShippingDetail}
            onCreateOrder={handleCreateOrder}
            onCreateSuggestOrder={handleCreateSuggestOrder}
            onDeleteOrder={handleDeleteOrder}
            onPrint={handlePrint}
            onSelectOrder={handleSelectOrder}
            onSuccessExportDeliveryNote={handleSuccessExportDeliveryNote}
        />
    );
};

export default withTranslation()(CreateDomesticShippingOrderContainer);
