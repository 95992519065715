import React, {useState} from 'react';
import {connect} from "react-redux";
import {printBatchStamp} from "../../Common/PrintStamp/actions";
import {t} from "../../../system/i18n";
import {Button, notification} from "antd";
import apiService from "../apiService";
import lodash from "lodash";

const mapStateToProps = ({common}) => {
    const {loadingPrintTemplate} = common;

    return {
        loadingPrintTemplate,
    };
};

const mapDispatchToProps = dispatch => ({
    printBatchStamp: data => {
        dispatch(printBatchStamp(data));
    },
});

function PrintBatchStampButton(props) {
    const {className, printBatchStamp, packageData, type} = props;
    const [loading, setLoading] = useState(false);

    async function handlePrint() {
        setLoading(true);
        try {
            const response = await apiService.getBatchStampData(lodash.get(packageData, 'code'), {});
            const data = lodash.get(response, 'data');
            if (data) {
                setTimeout(() => {
                   printBatchStamp(data);
                }, 1500);
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                });
            }
        } catch (error) {
            notification.error({
                message: t('common:message.server_error'),
            });
        }
        setLoading(false);
    }

    return <Button
        className={className}
        loading={loading}
        onClick={handlePrint}
    >
        {t('package:label.print_batch_stamp')}
    </Button>
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintBatchStampButton)