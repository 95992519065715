import {api} from '../../../system/api';

export default {

    /**
     * Hủy yêu cầu giao , thay đổi trạng thái của yêu cầu g
     * @param code
     * @returns {AxiosPromise<any> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    cancelDeliveryNote: (data) => api.put(`/delivery-requests/${data.code}/cancel`, {agency : data.agency})
};
