import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { DELIVERY_OBJECT } from './../../constants';

class DeliveryNoteExport extends PureComponent {
    state = {
        isOpenExportConfirm: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            ...prevState
        };

        if (prevState.isOpenExportConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenExportConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmExport = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { t, beginExportDeliveryNote } = this.props;

        Modal.confirm({
            title: t('delivery_note:label.warning'),
            content: <p>{t('delivery_note:label.warning_confirm_export')}</p>,
            okText: t('delivery_note:label.ok'),
            cancelText: t('delivery_note:label.cancel'),
            onOk: this.handleOnOk,
            onCancel: this.handleOnClose,
        });

        beginExportDeliveryNote();
    };

    /**
     * handle action click ok to unlink order
     */
    handleOnOk = () => {
        const { deliveryNoteData, type, onExportDeliveryNote } = this.props;

        this.setState({
            isOpenExportConfirm: true
        });

        onExportDeliveryNote({
            id: deliveryNoteData.id,
            type
        });
    };

    /**
     * handle action close modal
     */
    handleOnClose = () => {
        this.props.endExportDeliveryNote();
    };

    disableButtonWhenCancel = () => {
        if (this.props.disabled === false) {
            return true;
        }

        return false;
    };

    render() {
        const { bagTotal, className, ghost, loading, packageTotal, size, t, type } = this.props;

        return (
            <Button
                className={clsx('_btn-export', className)}
                disabled={(!((packageTotal || bagTotal) && this.disableButtonWhenCancel()))}
                ghost={ghost}
                loading={loading}
                type={type}
                size={size}
                onClick={this.confirmExport}
            >
                {t('delivery_note:label.export_warehouse')}
            </Button>
        );
    }
}

DeliveryNoteExport.defaultProps = {
    deliveryNoteData: {},
    isModalProcessing: false,
    loading: false,
    type: DELIVERY_OBJECT.CUSTOMER
};

DeliveryNoteExport.propTypes = {
    deliveryNoteData: PropTypes.object,
    onDeliveryNoteExport: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginDeliveryNoteExport: PropTypes.func,
    endDeliveryNoteExport: PropTypes.func,
    type: PropTypes.string
};

export default withTranslation()(DeliveryNoteExport);
