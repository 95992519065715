import React from 'react';

const Box1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0.008 0.004)">
        <path className="c" d="M1.213,5.661H7.7a.333.333,0,0,0,.246-.109l2.4-2.641a.147.147,0,0,0-.109-.246H3.083a.333.333,0,0,0-.259.125L.953,5.119a.333.333,0,0,0,.26.541Z" />
        <path className="c" d="M7.659,13.319V6.66a.333.333,0,0,0-.333-.333H.286A.286.286,0,0,0,0,6.612v6.152a.886.886,0,0,0,.883.888H7.326A.333.333,0,0,0,7.659,13.319ZM5.994,9.989a.666.666,0,0,1-.666.666H2a.666.666,0,0,1-.666-.666V8.657A.666.666,0,0,1,2,7.992h3.33a.666.666,0,0,1,.666.666Z" />
        <path className="c" d="M3.279,2h6.762a.284.284,0,0,0,.282-.286V1.427A1.58,1.58,0,0,0,8.632,0H4.688A1.58,1.58,0,0,0,3,1.427v.285A.284.284,0,0,0,3.279,2Z" />
        <path className="c" d="M8.325,6.251v3.8a.333.333,0,0,0,.424.32,1.5,1.5,0,0,1,.408-.057,1.036,1.036,0,0,1,.137.006.333.333,0,0,0,.363-.333v-.17a1.5,1.5,0,0,1,1.5-1.5.962.962,0,0,1,.133.006A.333.333,0,0,0,11.651,8V7.825a1.494,1.494,0,0,1,1.316-1.484.194.194,0,0,0,.114-.333L11.143,3.6a.333.333,0,0,0-.506-.015L8.411,6.027A.333.333,0,0,0,8.325,6.251Z" />
        <path className="c" d="M15.484,9.656H14.76a.166.166,0,0,1-.118-.284l.2-.2a.5.5,0,0,0-.706-.706l-.2.2a.166.166,0,0,1-.284-.118V7.825a.5.5,0,0,0-1,0V9.811a.333.333,0,0,1-.1.236l-.617.617a.166.166,0,0,1-.284-.118V9.823a.5.5,0,0,0-1,0v1.986a.333.333,0,0,1-.1.236l-.617.617a.166.166,0,0,1-.284-.118v-.723a.5.5,0,1,0-1,0v1.986a.333.333,0,0,1-.1.236L7.472,15.131a.5.5,0,1,0,.706.706l1.088-1.088a.333.333,0,0,1,.236-.1h1.986a.5.5,0,1,0,0-1h-.723a.166.166,0,0,1-.118-.284l.617-.617a.333.333,0,0,1,.236-.1h1.986a.5.5,0,1,0,0-1h-.723a.166.166,0,0,1-.118-.284l.617-.617a.333.333,0,0,1,.236-.1h1.986a.5.5,0,0,0,0-1Z" />
      </g>
    </g>
  </svg>
);

export default Box1;
