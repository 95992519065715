import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import * as actions from './actions';
import * as selectors from './selectors';
import packageService from '../packageService';
import {setPackagePage} from '../CreatePackageViaScan/actions';
import UpdateRelatedPackage from './components/UpdateRelatedPackage';
import * as boxSizeSelectors from "../../BoxSize/selectors";

const makeFormInputVolumeBox = (boxSizes, id) => {
    let boxSize = lodash.find(boxSizes, item => item.box_size.id === id);
    return lodash.get(boxSize, 'box_size.volume', 0);
};
const makeFormInput = (packageData) => {
    const input = {
        ...lodash.pick(packageData, [
            'status_transport',
            'type',
            'note',
            'code',
            'weight_net',
            'length',
            'width',
            'height',
            'volume',
        ]),
    };

    return packageService.makeFormInput(input);
};

const mapStateToProps = (state, props) => {
    let packageState = selectors.getPackageState(state, props.package.id) || {};
    let packageData = {...props.package};
    let input = makeFormInput(packageData);
    let boxSizes = boxSizeSelectors.getSuggestBoxSizes(state, 'box_sizes', []);
    if (!lodash.isNil(packageData.id_box_size) && packageData.id_box_size !== 0) {
        let volume_box = makeFormInputVolumeBox(boxSizes, packageData.id_box_size);
        input.volume_box = volume_box;
        packageData.volume_box = volume_box;
    }
    return {
        boxSizes,
        package: props.package,
        input,
        inputVersion: props.package.id,
        errors: selectors.getErrors(packageState.error || {}),
        loading: !!packageState.loading,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let packageId = props.package.id;
    return {
        onUpdate: (data) => dispatch(actions.updatePackage(packageId, data)),
        onExit: () => dispatch(setPackagePage(packageId)),
        onReset: () => dispatch(actions.clearState(packageId)),
        resetError: (params) => dispatch(actions.resetError(packageId, params)),
    };
};

class UpdateRelatedPackageContainer extends Component {
    state = {
        inputVersion: this.props.inputVersion
    };

    componentDidUpdate(prevProps) {
        const { inputVersion } = this.props;

        if (inputVersion !== prevProps.inputVersion) {
            this.setState({
                inputVersion
            });
        }

        if (!lodash.isEqual(this.props.package, prevProps.package)) {
            this.setState(prevState => ({
                inputVersion: prevState.inputVersion + 1
            }));
        }
    }

    render() {
        const { inputVersion } = this.state;

        return (
            <UpdateRelatedPackage
                {...this.props}
                inputVersion={inputVersion}
            />
        );
    }
};

UpdateRelatedPackageContainer.defaultProps = {
    package: {},
};

UpdateRelatedPackageContainer.propTypes = {
    package: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateRelatedPackageContainer);

