import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Col, Form, Select} from 'antd';
import lodash from 'lodash';
const Option = Select.Option;

class LocationWarehouseForm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			idWarehouse: 0,
			id_country: "",
			id_location: "",
			id_city: "",
			id_ward: "",
			id_district: "",
		};
	}

	static getDerivedStateFromProps(props, state) {
		let nextState = {...state};
		if (lodash.get(props, "warehouseData.id", 0) !== state.idWarehouse) {
			nextState.idWarehouse = props.warehouseData.id;
			nextState.id_country = props.warehouseData.id_country;
			if(nextState.id_country !== '' && nextState.id_country !== undefined) {
				props.fetchProvince(props.warehouseData.id_country);
			}
			nextState.id_city = props.warehouseData.id_city;
			if(nextState.id_city !== '' && nextState.id_city !== undefined) {
				props.fetchDistrict(props.warehouseData.id_city);
			}
			nextState.id_district = props.warehouseData.id_district;
			if(nextState.id_district !== '' && nextState.id_district !== undefined) {
				props.fetchWard(props.warehouseData.id_district);
			}
			nextState.id_ward = props.warehouseData.id_ward;
		}
		return nextState;
	}


	/**
	 * handle action change country
	 * @param value
	 */
	onChangeCountry(value) {
        let {errors} = this.props;
        if (lodash.get(errors, 'id_location.id_country')) {
            this.props.resetError("id_location");

        }
		let newCountryState = {
			id_country: value,
			id_city: "",
			id_district: "",
			id_ward: "",
		};

        this.setState({...newCountryState});
		this.props.setCurrentCountry(value);
		this.props.onSubmit({...this.state, id_location: value});
		this.props.fetchProvince(value);
	}

	/**
	 * handle action change city
	 * @param value
	 */
	onChangeCity(value) {
        let {errors} = this.props;
        if (lodash.get(errors, 'id_location.id_city')) {
            this.props.resetError("id_location");
        }
		this.props.setCurrentCity(value);
		let newState = {...this.state};
		newState = {...newState,
			id_city: value,
			id_location: value,
			id_district: "",
			id_ward: "",
		};
		this.setState({...newState});
		this.props.onSubmit({...newState});
		this.props.fetchDistrict(value);
	}

	/**
	 * handle action change district
	 * @param value
	 */
	onChangeDistrict(value) {
        let {errors} = this.props;
        if (lodash.get(errors, 'id_location.id_district')) {
            this.props.resetError("id_location");
        }
		this.props.setCurrentDistrict(value);
		let newState = {...this.state};
		newState = {...newState,
			id_district: value,
			id_ward: "",
			id_location: value};
		this.setState({...newState});
		this.props.onSubmit(newState);
		this.props.fetchWard(value);

	}

	onChangeWard(value) {
        let {errors} = this.props;
        if (lodash.get(errors, 'id_location.id_ward')) {
            this.props.resetError("id_location");
        }
		let newState = {...this.state};
		newState = {...newState,
			id_ward: value,
			id_location: value};
		this.setState({...newState});
		this.props.onSubmit(newState);

	}

	componentDidMount() {
		let {countries} = this.props;
		if (Array.isArray(countries) && countries.length === 0) {
			this.props.fetchCountry(0);
		}
	}

	render() {
		let {t, countries, cities, districts, isLoadingLocation, errors, wards} = this.props;
		let required = true;
		return (
			<React.Fragment>
				<Col lg={{span: 6}} xl={{span: 6}}
					 xxl={{span: 6}}
					 className="info--group"
				>
					<Form.Item
						required={required}
						className="a-form__item "
						labelCol={{span: 24}}
						label={t("package:label.country")}
						help={lodash.get(errors, 'id_location.id_country')}
						validateStatus={lodash.get(errors, 'id_location.id_country') ? 'error' : null}
					>
						<Select
							optionFilterProp="children"
							className="a-select a-select--w-100 _country"
							showSearch={true}
							placeholder={t("package:placeholder.country")}
							loading={cities.length === 0 && isLoadingLocation}
							disabled={cities.length !== 0 && isLoadingLocation}
							value={this.state.id_country === "" ? undefined : countries.length === 0 ? undefined : this.state.id_country}
							onChange={this.onChangeCountry.bind(this)}
						>
							{countries.map((country) => <Option
								value={country.location.id}
								key={country.location.id}
								className={"_country__item"}>{country.location.label}</Option>)}
						</Select>
					</Form.Item>
				</Col>
				<Col lg={{span: 6}} xl={{span: 6}}
					 xxl={{span: 6}}
					 className="info--group"
				>
					<Form.Item
						required={required}
						className="a-form__item "
						labelCol={{span: 24}}
						label={t("location:label.city")}
						help={lodash.get(errors, 'id_location.id_city')}
						validateStatus={lodash.get(errors, 'id_location.id_city') ? 'error' : null}
					>
						<Select
							className="a-select a-select--w-100 _city"
							placeholder={t("location:placeholder.city")}
							showSearch={true}
							loading={districts.length === 0 && isLoadingLocation}
							optionFilterProp="children"
							disabled={this.state.id_country === "" || isLoadingLocation || countries.length === 0 }
							value={this.state.id_city === "" ? undefined : cities.length === 0 ? undefined : this.state.id_city}
							onChange={this.onChangeCity.bind(this)}
						>
							{cities.map((city) => <Option
								value={city.location.id}
								key={city.location.id}
								className={"_city__item"}>{city.location.label}</Option>)}
						</Select>
					</Form.Item>
				</Col>
				<Col lg={{span: 6}} xl={{span: 6}}
					 xxl={{span: 6}}
					 className="info--group"
				>
					<Form.Item
						className="a-form__item "
						label={t("location:label.district")}
						required={required}
						labelCol={{span: 24}}
						help={lodash.get(errors, 'id_location.id_district')}
						validateStatus={lodash.get(errors, 'id_location.id_district') ? 'error' : null}
					>
						<Select
							optionFilterProp="children"
							className="a-select a-select--w-100 _district"
							placeholder={t("location:placeholder.district")}
							showSearch={true}
							disabled={this.state.id_country === "" || this.state.id_city === "" || isLoadingLocation || cities.length === 0}
							value={this.state.id_district === "" ? undefined : (districts.length === 0 || cities.length === 0) ? undefined : this.state.id_district}
							loading={isLoadingLocation}
							onChange={this.onChangeDistrict.bind(this)}
						>
							{districts.map(district => <Option
								value={district.location.id}
								key={district.location.id}
								className={"_district__item"}>{district.location.label}</Option>)}
						</Select>
					</Form.Item>
				</Col>
				<Col
					lg={{span: 6}} xl={{span: 6}}
					xxl={{span: 6}}
					className="info--group clear-both--lg">
					<Form.Item
						className="a-form__item "
						label={t("location:label.ward")}
						labelCol={{span: 24}}>
						<Select
							optionFilterProp="children"
							className="a-select a-select--w-100 _ward"
							placeholder={t("location:placeholder.ward")}
							showSearch={true}
							disabled={isLoadingLocation || this.state.id_city === "" || this.state.id_district === "" || districts.length === 0}
							value={
								isLoadingLocation ? undefined : this.state.id_ward === ""
									? undefined : (wards.length === 0 || districts.length === 0 || countries.length === 0)
										? undefined : this.state.id_ward}
							loading={isLoadingLocation}
							onChange={this.onChangeWard.bind(this)}
						>
							{wards.map(ward => <Option
                                value={ward.location.id}
                                key={ward.location.id}
                                className={"_ward__item"}>{ward.location.label}</Option>)}
						</Select>

					</Form.Item>
				</Col>
			</React.Fragment>
		);
	}
}

LocationWarehouseForm.defaultProps = {
	warehouseData: {},
	isLoadingProperty: false,
	isLoadingLocation: false,
	countries: [],
	cities: [],
	districts: [],
	errors: {}
};

LocationWarehouseForm.propTypes = {
	warehouseData: PropTypes.object,
	isLoadingLocation: PropTypes.bool,
	countries: PropTypes.array,
	cities: PropTypes.array,
	districts: PropTypes.array,
	setCurrentCountry: PropTypes.func,
	setCurrentCity: PropTypes.func,
	onSubmit: PropTypes.func,
	resetError: PropTypes.func,
	errors: PropTypes.object,
};

export default Form.create({})(withTranslation()(LocationWarehouseForm));
