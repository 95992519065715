import React, { Component, Fragment } from 'react';
import { Button, Icon, Table } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { INVENTORY_STATUS_PROCESSING } from './../../../constants';

import { Link } from '../../../../../system/routing';

class Disappear extends Component {
    state = {
        selectedRowKeys: []
    };

    handleResolveInventoryTrackingBill = ({ item, is_resolved, loading, current_is_resolved }) => {
        const { inventory, resolveInventoryTrackingBill } = this.props;
        const inventoryStatus = inventory.status;
        const canProcessInventory = inventoryStatus === INVENTORY_STATUS_PROCESSING;

        if (loading || !canProcessInventory || current_is_resolved === is_resolved) {
            return;
        }

        const data = {
            id: item.id,
            is_resolved,
        };

        resolveInventoryTrackingBill(data);
    };

    handleResolveInventoryTrackingBillsButtonClick = data => {
        const { inventory, resolvingInventoryTrackingBills, resolveInventoryTrackingBills } = this.props;

        if (resolvingInventoryTrackingBills || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return;
        }

        resolveInventoryTrackingBills(data);
    };

    handleChangeSelectedRowKeys = keys => {
        this.setState({
            selectedRowKeys: keys
        });
    };

    render() {
        const { selectedRowKeys } = this.state;
        const {
            disappears,
            downloadingDisappearTrackingBills,
            inventory,
            loading,
            resolvingInventoryTrackingBills,
            t,
            onDownloadDisappearTrackingBillsButtonClick
        } = this.props;

        const columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:column.code.receive_package'),
            dataIndex: 'inventory_tracking_bill.tracking_bill',
            key: 'tracking_bill'
        }, {
            title: t('package_list'),
            dataIndex: 'inventory_tracking_bill.related_packages',
            key: 'related_packages',
            render: packages => packages && packages.length ? packages.map((pkg, index) => (
                <Fragment key={pkg}>
                    <Link isNewTab params={{id: pkg}} to="packages.detail">{pkg}</Link>
                    {index < packages.length - 1 ? ', ' : null}
                </Fragment>
            )) : '--'
        }, {
            title: (
                <Button
                    className="_btn-processes a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryTrackingBillsButtonClick.bind(this, {
                        ids: selectedRowKeys,
                        is_resolved: true
                    })}
                >
                    <Icon type={resolvingInventoryTrackingBills ? 'loading' : 'check'} />
                    <span className="ml-2">{t('inventory:processed')}</span>
                </Button>
            ),
            key: 'processed',
            render: (text, record) => {
                const { inventory } = this.props;
                const isResolved = record.inventory_tracking_bill.is_resolved;
                const inventoryStatus = inventory.status;
                const canProcessInventory = inventoryStatus === INVENTORY_STATUS_PROCESSING;
                const data = {
                    item: record.inventory_tracking_bill,
                    loading: record.loading,
                    current_is_resolved: record.inventory_tracking_bill.is_resolved
                };

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action', {
                                active: isResolved,
                                'disable-active': !canProcessInventory && isResolved,
                                'disable-unactive': !canProcessInventory && !isResolved
                            })}
                            type={record.loading ? 'loading' : 'check'}
                            onClick={this.handleResolveInventoryTrackingBill.bind(this, {
                                ...data,
                                is_resolved: true
                            })}
                        />
                    </div>
                );
            }
        }, {
            title: (
                <Button
                    className="_btn-disappears a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryTrackingBillsButtonClick.bind(this, {
                        ids: selectedRowKeys,
                        is_resolved: false
                    })}
                >
                    <Icon type={resolvingInventoryTrackingBills ? 'loading' : 'question'} />
                    <span className="ml-2">{t('inventory:lost')}</span>
                </Button>
            ),
            key: 'disappear',
            render: (text, record) => {
                const { inventory } = this.props;
                const isResolved = record.inventory_tracking_bill.is_resolved;
                const inventoryStatus = inventory.status;
                const canProcessInventory = inventoryStatus === INVENTORY_STATUS_PROCESSING;
                const data = {
                    item: record.inventory_tracking_bill,
                    loading: record.loading,
                    current_is_resolved: record.inventory_tracking_bill.is_resolved
                };

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action', {
                                active: isResolved === false,
                                'disable-active': !canProcessInventory && isResolved === false,
                                'disable-unactive': !canProcessInventory && isResolved !== false
                            })}
                            type={record.loading ? 'loading' : 'question'}
                            onClick={this.handleResolveInventoryTrackingBill.bind(this, {
                                ...data,
                                is_resolved: false
                            })}
                        />
                    </div>
                );
            }
        }];

        return (
            <>
                <div className="a-content__top a-content__top--page-list mt-0 mr-0">
                    <div className="width-100-pc a-content--page-list__title gflex galign-center gjustify-space-between">
                        <h3 className="a-text--medium a-text--fz-16">
                            {t('inventory:disappear_list.receive_package')} ({disappears.length})
                        </h3>
                        <Button
                            loading={downloadingDisappearTrackingBills}
                            onClick={onDownloadDisappearTrackingBillsButtonClick}
                        >
                            {t('btn.export_excel')}
                        </Button>
                    </div>
                </div>
                <div className="aflex-1 a-content__body bg-white a-content__body--page-list mr-0 aoverflow-y pb-0">
                    <Table
                        className="a-table--antd a-table--antd-scroll-x a-table--error-fixed"
                        columns={columns}
                        dataSource={disappears}
                        loading={loading}
                        pagination={false}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: this.handleChangeSelectedRowKeys
                        }}
                        scroll={{ x: 'fit-content' }}
                        rowKey={row => get(row, 'inventory_tracking_bill.id')}
                    />
                </div>
            </>
        );
    }
}

export default withTranslation()(Disappear);
