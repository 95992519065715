export const FETCH_PACKAGE_DETAIL = {
    REQUEST: 'PACKAGE.PACKAGE_DETAIL.FETCH_PACKAGE_DETAIL.REQUEST',
    SUCCESS: 'PACKAGE.PACKAGE_DETAIL.FETCH_PACKAGE_DETAIL.SUCCESS',
    FAILED: 'PACKAGE.PACKAGE_DETAIL.FETCH_PACKAGE_DETAIL.FAILED',
};

export const CLEAR_STATE = 'PACKAGE.PACKAGE_DETAIL.CLEAR_STATE';

export const SET_PACKAGE = 'PACKAGE.PACKAGE_DETAIL.SET_PACKAGE';

export const PACKAGE_ROUTINGS = {
    STORAGE_DISTRIBUTED_WAREHOUSE: "storage_distributed_warehouse",
    DIRECT_TO_CONSUMER: "direct_to_consumer"
}
