import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash, { get } from 'lodash';
import {t, trans} from '../../../../../system/i18n';
import {Link} from '../../../../../system/routing';
import {DELETE_REASONS} from "../../../../Package/DeletePackage/constants";
import {getOppositeAction} from './../../../../Package/helper';
import { getVar } from '../../../../../system/support/helpers';

const ORDER_ACTION = {
    CREATE_PACKAGE: 'PACKAGE.CREATE',
    PACKAGE_LINK_ORDER: 'PACKAGE.LINK_ORDER',
    PACKAGE_UNLINK_ORDER: 'PACKAGE.UNLINK_ORDER',
    PACKAGE_UPDATE_WEIGHT_VOLUME: 'PACKAGE.UPDATE_WEIGHT_VOLUME',
    PACKAGE_UPDATE_ALONE_PACKAGE: 'PACKAGE.UPDATE_ALONE_PACKAGE',
    PACKAGE_UPDATE_RELATED_PACKAGE: 'PACKAGE.UPDATE_RELATED_PACKAGE',
    PACKAGE_SCAN: 'PACKAGE.SCAN',
    PACKAGE_DELETE: 'PACKAGE.DELETE',
    PACKAGE_TRACKING: 'PACKAGE.CHECKING',
    CHANGE_TRANSPORT_STATUS: 'PACKAGE.CHANGE_TRANSPORT_STATUS',
    ADD_ATTENTION: "PACKAGE.ADD_ATTENTION",
    UPDATE_ATTENTION: "PACKAGE.UPDATE_ATTENTION",
    PACKAGE_SCAN_TO_BAG: "PACKAGE.SCAN_TO_BAG",
    PACKAGE_REMOVE_FROM_BAG: "PACKAGE.REMOVE_FROM_BAG",
    PACKAGE_VIEW_CHECKING_SCREEN: "PACKAGE.VIEW_CHECKING_SCREEN",
    REMOVE_ATTENTION: "PACKAGE.REMOVE_ATTENTION",
    PACKAGE_ADD_DELIVERY_NOTE: 'PACKAGE.ADD_DELIVERY_NOTE',
    PACKAGE_REMOVE_DELIVERY_NOTE: 'PACKAGE.REMOVE_DELIVERY_NOTE',
    PACKAGE_SCAN_INVENTORY: 'PACKAGE.SCAN_INVENTORY',
    PACKAGE_RECEIVE: 'PACKAGE.RECEIVE',
    PACKAGE_PRINT_STAMP: 'PACKAGE.PRINT_STAMP',
    PACKAGE_PRINT_OFFICIAL_STAMP: 'PACKAGE.PRINT_OFFICIAL_STAMP',
    PACKAGE_PRINT_UNOFFICIAL_STAMP: 'PACKAGE.PRINT_UNOFFICIAL_STAMP',
    PACKAGE_VOIDED_SERVICE: 'PACKAGE.VOIDED_SERVICE',
    PACKAGE_RE_SYNC: 'PACKAGE.RE_SYNC',
    PACKAGE_DAMAGED: 'PACKAGE.DAMAGED',
    PACKAGE_DAMAGED_IMAGES: 'PACKAGE.DAMAGED_IMAGES',
    PACKAGE_CREATE_COMPLAINT_SELLER: 'PACKAGE.CREATE_COMPLAINT_SELLER',
    PACKAGE_UPDATE: 'PACKAGE.UPDATE',
    PACKAGE_CHANGE_STATUS_TRANSPORT_WHEN_EXPORT_RETURN: 'PACKAGE.CHANGE_STATUS_TRANSPORT_WHEN_EXPORT_RETURN',
    PACKAGE_ADD_DELIVERY_NOTE_RETURN: 'PACKAGE.ADD_DELIVERY_NOTE_RETURN',
    PACKAGE_CREATE_SHIPMENT_ORDER: 'PACKAGE.CREATE_SHIPMENT_ORDER',
    PACKAGE_UPDATE_ITEMS: 'PACKAGE.UPDATE_ITEMS',
    PACKAGE_UPDATE_RETURN_ADDRESS: 'PACKAGE.UPDATE_RETURN_ADDRESS',
    PACKAGE_UPDATE_NOTE_STATUS: 'PACKAGE.UPDATE_NOTE_STATUS',
    PACKAGE_UPDATE_DAMAGED_STATUS: 'PACKAGE.UPDATE_DAMAGED_STATUS',
    PACKAGE_UPDATE_RETURN_INFO: 'PACKAGE.UPDATE_RETURN_INFO',
    PACKAGE_PRINT_BATCH_STAMP: 'PACKAGE.PRINT_BATCH_STAMP',
    PACKING_LASTMILE: "PACKAGE.PACKING_LASTMILE",
    REMOVE_PACKING_LASTMILE: "PACKAGE.REMOVE_PACKING_LASTMILE",
    PACKAGE_CREATE_ORDER_FROM_CUSTOMER_CODE: "PACKAGE.CREATE_ORDER_FROM_CUSTOMER_CODE",
    PACKAGE_CHANGE_CUSTOMER: "PACKAGE.CHANGE_CUSTOMER",
    PACKAGE_VENDOR_SORT: "PACKAGE.VENDOR_SORT",
    PACKAGE_VENDOR_PRINT: "PACKAGE.VENDOR_PRINT",
    PACKAGE_ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: "PACKAGE.ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE",
    PACKAGE_REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: "PACKAGE.REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE",
    PACKAGE_ADD_TO_RETURN_NOTE: "PACKAGE.ADD_TO_RETURN_NOTE",
    PACKAGE_REMOVE_FROM_RETURN_NOTE: "PACKAGE.REMOVE_FROM_RETURN_NOTE",
    PACKAGE_STOWING: "PACKAGE.STOWING",
    PACKAGE_VENDOR_PRINT_LAST_MILE: "PACKAGE.VENDOR_PRINT_LAST_MILE",
    PACKAGE_PRINT_9710_STAMP: 'PACKAGE.PRINT_9710_STAMP',
    PACKAGE_PRINT_LAST_MILE: 'PACKAGE.PRINT_LAST_MILE', // in tem lastmile
    PACKAGE_VENDOR_PRINT_ECOMMERCE: 'PACKAGE.VENDOR_PRINT_ECOMMERCE', // Kiện được in tem e-commerce tự động theo máy
};

class PackageLogMessage extends React.PureComponent {
    render() {
        const { log, t } = this.props;
        const { payload } = log;
        switch (log.action) {
            case ORDER_ACTION.CREATE_PACKAGE:
                if (lodash.get(log, 'payload.package.is_shipment')) {
                    if (lodash.get(log, 'payload.package.warehouse_input')) {
                        return trans('package:log.CREATE_SHIPMENT_PACKAGE_FROM_WAREHOUSE', {
                            barcode: <b>{lodash.get(payload, 'package.barcode')}</b>,
                            warehouse_input: <b>{lodash.get(payload, 'package.warehouse_input', '--')}</b>
                        });
                    }

                    return trans('package:log.CREATE_SHIPMENT_PACKAGE');
                }

                if (lodash.get(log, 'payload.package.barcode')) {
                    const dimension = [lodash.get(payload, 'package.length', undefined) * 100, lodash.get(payload, 'package.width', undefined) * 100, lodash.get(payload, 'package.height', 0) * 100]
                    const weightNet = lodash.get(payload, 'package.weight_net', 0)
                    return trans('package:log.CREATE_PACKAGE_FROM_BARCODE', {
                        barcode: <b>{lodash.get(payload, 'package.barcode')}</b>,
                        warehouse_input: <b>{lodash.get(payload, 'package.warehouse_input')}</b>,
                        info: `${[weightNet? `${trans('label.dimension')} ${weightNet}kg`  : undefined, dimension.length > 0 ? `${trans('label.dimension')} ${dimension.join("x")} (cm)`: undefined, ].join(", ")}`
                    }) 
                }

                if (lodash.get(payload, 'create_same_package')) {
                    const packageData = {
                        id: lodash.get(payload, 'create_same_package', 0),
                        code: lodash.get(payload, 'create_same_package', '--')
                    };

                    return trans('package:log.CREATE_PACKAGE_SAME_BAG', {
                        warehouse_input: lodash.get(payload, 'warehouse_input'),
                        create_same_package: this.linkToPackage(lodash.get(packageData, 'code'))
                    });
                }

                if (lodash.get(payload, 'package.warehouse_input')) {
                    return trans('package:log.CREATE_PACKAGE_FROM_WAREHOUSE', {
                        package: this.linkToPackage(lodash.get(log, 'object.package.code')),
                        warehouse_input: <b>{lodash.get(payload, 'package.warehouse_input', '--')}</b>,
                    });
                }

                if (lodash.get(payload, 'warehouse_input')) {
                    const detachPackage = lodash.get(payload, 'detach_package');
                    if (detachPackage) {
                        return trans('package:log.CREATE_PACKAGE_BY_DETACH', {
                            warehouse_input: <b>{lodash.get(payload, 'warehouse_input', '--')}</b>,
                            detach_package: <b>{detachPackage}</b>
                        });
                    } else {
                        return trans('package:log.CREATE_PACKAGE_FROM_WAREHOUSE', {
                            warehouse_input: <b>{lodash.get(payload, 'warehouse_input', '--')}</b>,
                        });
                    }
                }

                return trans('package:log.CREATE_PACKAGE', {
                    package: this.linkToPackage(lodash.get(log, 'object.package.code')),
                });
            case ORDER_ACTION.PACKAGE_LINK_ORDER:
                return trans('package:log.PACKAGE_LINK_ORDER', {
                    order_code: lodash.get(payload, 'order.code'),
                });
            case ORDER_ACTION.REMOVE_ATTENTION:
                return t('package:log.REMOVE_ATTENTION');
            case ORDER_ACTION.PACKAGE_UNLINK_ORDER:
                return trans('package:log.PACKAGE_UNLINK_ORDER', {
                    order_code: lodash.get(payload, 'order.code'),
                });
            case ORDER_ACTION.PACKAGE_UPDATE_WEIGHT_VOLUME:
            case ORDER_ACTION.PACKAGE_UPDATE_ALONE_PACKAGE:
            case ORDER_ACTION.PACKAGE_UPDATE_RELATED_PACKAGE:
            case ORDER_ACTION.PACKAGE_UPDATE:
            case ORDER_ACTION.PACKAGE_UPDATE_NOTE_STATUS:
            case ORDER_ACTION.PACKAGE_UPDATE_RETURN_ADDRESS:
            case ORDER_ACTION.PACKAGE_UPDATE_DAMAGED_STATUS:
            case ORDER_ACTION.PACKAGE_UPDATE_RETURN_INFO:
                return (
                    <span>
                        {trans('package:log.PACKAGE_UPDATE')}
                        {this.renderMessageDetailUpdate(log)}
                    </span>
                );
            case ORDER_ACTION.PACKAGE_UPDATE_ITEMS:
                if(lodash.isArray(payload)) {
                    const info = payload.map(product => {
                        return trans("package:product_of_package_info", {
                            zh_name: <b>{getVar(product, "name_zh", "")}</b>,
                            vi_name: <b>{getVar(product, "name_vi", "")}</b>,
                            received_quantity: <b>{getVar(product, "received_quantity", "")}</b>,
                            unit_price_origin: <b>{getVar(product, "unit_price_origin", "")}</b>,
                            new_line: <br/>
                        })
                    });
                    return trans('package:log.PACKAGE_UPDATE_PRODUCT', {info});
                } else {
                    return trans('package:log.PACKAGE_UPDATE_ITEMS');
                }
            case ORDER_ACTION.CHANGE_TRANSPORT_STATUS:
                return (
                    <span>
                        {trans('package:log.CHANGE_TRANSPORT_STATUS', {})}
                        {this.renderMessageDetailUpdate(log)}
                    </span>
                );
            case ORDER_ACTION.PACKAGE_SCAN:
                let action_by = false;
                let key;

                if (lodash.get(payload, 'package.warehouse_next')) {
                    key = 'PACKAGE_SCAN_WITH_WAREHOUSE_NEXT';
                } else if (lodash.get(payload, 'package.delivery_note')) {
                    key = 'PACKAGE_SCAN_DELIVERY_NOTE';
                } else if (lodash.get(payload, 'package.delivery_note_return')) {
                    key = 'PACKAGE_SCAN_WITH_DELIVERY_NOTE_RETURN';
                } else if (lodash.get(payload, 'package.inventory', '')) {
                    if (lodash.get(payload, 'package.warehouse_current')) {
                        key = 'PACKAGE_SCAN_INVENTORY_WITH_WAREHOUSE_CURRENT';
                    } else {
                        key = 'PACKAGE_SCAN_INVENTORY';
                    }
                } else {
                    key = 'PACKAGE_SCAN';
                }

                if (lodash.get(payload, 'package.bag')) {
                    key += '_BAG';
                }

                return (
                    <span>
                        {trans(`package:log.${key}`, {
                            action_scan: t(`scan:label.${lodash.get(payload, 'package.status_warehouse', '')}`),
                            warehouse: <b>{lodash.get(payload, 'package.warehouse_current', '')}</b>,
                            warehouse_next: <b>{lodash.get(payload, 'package.warehouse_next')}</b>,
                            opposite_action_scan: t(`scan:label.${getOppositeAction(lodash.get(payload, 'package.status_warehouse'))}`),
                            action_by,
                            inventory: (
                                <Link
                                    isNewTab
                                    params={{id: lodash.get(payload, 'package.inventory')}}
                                    to="inventory.scan"
                                >
                                    {lodash.get(payload, 'package.inventory')}
                                </Link>
                            ),
                            delivery_note: (
                                <Link
                                    isNewTab
                                    params={{id: lodash.get(payload, 'package.delivery_note', '')}}
                                    to="delivery-notes.customer.detail"
                                >
                                    {lodash.get(payload, 'package.delivery_note', '')}
                                </Link>
                            ),
                            delivery_note_return: <b>{lodash.get(payload, 'package.delivery_note_return', '')}</b>,
                            bag: (
                                <Link
                                    isNewTab
                                    params={{id: lodash.get(payload, 'package.bag', '')}}
                                    to="bags.detail"
                                >
                                    {lodash.get(payload, 'package.bag', '')}
                                </Link>
                            )
                        })}
                    </span>
                );
            case ORDER_ACTION.PACKAGE_DELETE:
                const deleteReason = lodash.get(log, 'payload.package.delete_reason');
                const deleteReasonDetail = lodash.get(log, 'payload.package.delete_reason_detail');
                if (deleteReason) {
                    if (deleteReason === DELETE_REASONS.OTHER.key && deleteReasonDetail) {
                        return trans("package:log.PACKAGE_DELETE_WITH_REASON", {
                            reason: deleteReasonDetail
                        });
                    } else {
                        return trans("package:log.PACKAGE_DELETE_WITH_REASON", {
                            reason: t(`package:delete_reason.${deleteReason}`)
                        })
                    }
                } else {
                    return trans("package:log.PACKAGE_DELETE");
                }

            case ORDER_ACTION.PACKAGE_TRACKING:
                
                if (lodash.get(payload, 'detach_package')) {
                    return trans('package:log.PACKAGE_TRACKING_WITH_DETACH_PACKAGE', {
                        quantity: lodash.get(payload, 'package_item.received_quantity'),
                        item: lodash.get(payload, 'package_item.code_item'),
                        package: this.linkToPackage(lodash.get(payload, 'detach_package'))
                    });
                }

                const group_product_detach = get(payload, 'group_product_detach')

                return  trans(`package:log.${group_product_detach ? "PACKAGE_TRACKING_FROM_ORIGIN" :"PACKAGE_TRACKING" }`, {
                    quantity: lodash.get(payload, 'package_item.received_quantity'), 
                    item: lodash.get(payload, 'package_item.code_item')
                }) 
            case ORDER_ACTION.ADD_ATTENTION:
                return trans("package:log.PACKAGE_ADD_ATTENTION", {
                    attention: lodash.get(payload, 'attention')
                });
            case ORDER_ACTION.UPDATE_ATTENTION:
                return <span>
                        {trans('package:log.PACKAGE_UPDATE_ATTENTION', {})}
                    {this.renderMessageDetailUpdate(log)}
                    </span>;
            case ORDER_ACTION.PACKAGE_SCAN_TO_BAG:
                return trans("package:log.PACKAGE_SCAN_TO_BAG", {
                    bag: (
                        <Link
                            isNewTab
                            params={{id: lodash.get(payload, 'package.bag', '')}}
                            to="bags.detail"
                        >
                        {lodash.get(payload, 'package.bag', '')}
                    </Link>
                    )
                });
            case ORDER_ACTION.PACKAGE_REMOVE_FROM_BAG:
                if (lodash.has(log, 'payload.package.delivery_note')) {
                    return trans("package:log.PACKAGE_REMOVE_FROM_BAG_BY_DELIVERY_NOTE", {
                        bag: (
                            <Link
                                isNewTab
                                params={{id: lodash.get(payload, 'package.bag', '')}}
                                to="bags.detail"
                            >
                                {lodash.get(payload, 'package.bag', '')},
                            </Link>
                        ),
                        delivery_note: (
                            <Link
                                isNewTab
                                params={{id: lodash.get(payload, 'package.delivery_note', '')}}
                                to="delivery-notes.customer.detail"
                            >
                                {lodash.get(payload, 'package.delivery_note', '')}
                            </Link>
                        )
                    });
                }
                return trans("package:log.PACKAGE_REMOVE_FROM_BAG", {
                    bag: (
                        <Link
                            isNewTab
                            params={{id: lodash.get(payload, 'package.bag', '')}}
                            to="bags.detail"
                        >
                            {lodash.get(payload, 'package.bag', '')}
                        </Link>
                    )
                });
            case ORDER_ACTION.PACKAGE_VIEW_CHECKING_SCREEN:
                return trans("package:log.PACKAGE_VIEW_CHECKING_SCREEN", {
                    actor: lodash.get(log, 'creator.name')
                });
            case ORDER_ACTION.PACKAGE_ADD_DELIVERY_NOTE:
                return trans('package:log.PACKAGE_ADD_DELIVERY_NOTE', {
                    code: (
                        <Link
                            isNewTab
                            params={{id: lodash.get(payload, 'delivery_note.code')}}
                            to="delivery-notes.customer.detail"
                        >
                            {lodash.get(payload, 'delivery_note.code')}
                        </Link>
                    )
                });
            case ORDER_ACTION.PACKAGE_REMOVE_DELIVERY_NOTE:
                return trans('package:log.PACKAGE_REMOVE_DELIVERY_NOTE', {
                    code: (
                        <Link
                            isNewTab
                            params={{id: lodash.get(payload, 'delivery_note.code')}}
                            to="delivery-notes.customer.detail"
                        >
                            {lodash.get(payload, 'delivery_note.code')}
                        </Link>
                    )
                });
            case ORDER_ACTION.PACKAGE_SCAN_INVENTORY: {
                return trans('package:log.PACKAGE_SCAN_INVENTORY', {
                    code: (
                        <Link
                            isNewTab
                            params={{id: lodash.get(payload, 'inventory')}}
                            to="inventory.scan"
                        >
                            {lodash.get(payload, 'inventory')}
                        </Link>
                    )
                });
            }
            case ORDER_ACTION.PACKAGE_RECEIVE: {
                return trans('package:log.PACKAGE_RECEIVE', {
                    barcode: lodash.get(payload, 'package.barcode'),
                });
            }
            case ORDER_ACTION.PACKAGE_PRINT_UNOFFICIAL_STAMP:{
                let temp = getVar(payload, 'temp', undefined);
                let action = log.action.replace('.', '_');
                if (temp) {
                    action = (temp === 'vt') ? "PACKAGE_PRINT_VT_STAMP" : (temp === 'kk' ? "PACKAGE_PRINT_KK_STAMP" : "PACKAGE_PRINT_TAP_STAMP");
                }
                return trans(`package:log.${action}`);
            }
            case ORDER_ACTION.PACKAGE_PRINT_STAMP:
            case ORDER_ACTION.PACKAGE_PRINT_OFFICIAL_STAMP:
            case ORDER_ACTION.PACKAGE_PRINT_BATCH_STAMP:
            case ORDER_ACTION.PACKAGE_VOIDED_SERVICE:
            case ORDER_ACTION.PACKAGE_RE_SYNC:
            case ORDER_ACTION.PACKAGE_CREATE_COMPLAINT_SELLER:
            case ORDER_ACTION.PACKAGE_CHANGE_STATUS_TRANSPORT_WHEN_EXPORT_RETURN:
            case ORDER_ACTION.PACKAGE_ADD_DELIVERY_NOTE_RETURN:
            case ORDER_ACTION.PACKAGE_CREATE_SHIPMENT_ORDER: {
                let formattedPayload;

                if (lodash.get(payload, 'void_services.length')) {
                    formattedPayload = payload.void_services.join(', ');
                } else if (lodash.get(payload, 'package.delivery_note_return')) {
                    formattedPayload = <b>{lodash.get(payload, 'package.delivery_note_return')}</b>;
                } else if (lodash.get(payload, 'order.code')) {
                    formattedPayload = <b>{lodash.get(payload, 'order.code')}</b>
                }

                return trans(`package:log.${log.action.replace('.', '_')}`, {
                    payload: formattedPayload,
                    old: <b>{trans(`package:status_transport.${lodash.get(payload, 'change_fields.status_transport.old')}`)}</b>,
                    new: <b>{trans(`package:status_transport.${lodash.get(payload, 'change_fields.status_transport.new')}`)}</b>
                });
            }
            case ORDER_ACTION.PACKAGE_VENDOR_PRINT:
                const creator = lodash.get(payload, "creator");
                return trans(`package:log.${creator ? "PACKAGE_VENDOR_PRINT_BY" : "PACKAGE_VENDOR_PRINT"}`, {
                    creator: <b>{creator}</b>
                });
            case ORDER_ACTION.PACKAGE_DAMAGED: {
                return trans(`package:log.${log.action.replace('.', '_')}.${!!lodash.get(payload, 'is_damaged')}`);
            }
            case ORDER_ACTION.PACKAGE_DAMAGED_IMAGES: {
                let key = 'add';

                if (lodash.get(payload, 'remove.length')) {
                    key = 'remove';
                }

                return trans(`package:log.${log.action.replace('.', '_')}.${key}`);
            }

            case ORDER_ACTION.PACKING_LASTMILE:

            return trans(`package:log.PACKING_LASTMILE`, {lastmile_code:<b>{ get(payload, 'bag_lastmile.code', '---')}</b>})

            case ORDER_ACTION.REMOVE_PACKING_LASTMILE:

            return trans(`package:log.REMOVE_PACKING_LASTMILE`, {lastmile_code:<b>{ get(payload, 'bag_lastmile.code', '---')}</b>})

            case ORDER_ACTION.PACKAGE_CREATE_ORDER_FROM_CUSTOMER_CODE: 

                return trans("package:log.PACKAGE_CREATE_ORDER_FROM_CUSTOMER_CODE", {
                    bill_code: get(payload, 'customer_code')
                })
            case ORDER_ACTION.PACKAGE_CHANGE_CUSTOMER: 

                return trans("package:log.PACKAGE_CHANGE_CUSTOMER", {
                    from: get(payload, 'from'),
                    to: get(payload, 'to')
                })
            case ORDER_ACTION.PACKAGE_VENDOR_SORT: 
                return trans("package:log.PACKAGE_VENDOR_SORT", {
                    lane: get(payload, 'lane'),
                    machine: get(payload, 'machine')
                })
            case ORDER_ACTION.PACKAGE_ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
                return trans('package:log.ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE')
            }
            case ORDER_ACTION.PACKAGE_REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
                return trans('package:log.REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE')
            }
            case ORDER_ACTION.PACKAGE_ADD_TO_RETURN_NOTE: {
                return trans('package:log.PACKAGE_ADD_TO_RETURN_NOTE', {
                    code:<b>{get(payload, 'return_note')}</b>,
                })
            }
            case ORDER_ACTION.PACKAGE_REMOVE_FROM_RETURN_NOTE: {
                return trans('package:log.PACKAGE_REMOVE_FROM_RETURN_NOTE', {
                    code: <b>{get(payload, 'return_note')}</b>,
                })
            }
            case ORDER_ACTION.PACKAGE_STOWING: {
                return trans('package:log.PACKAGE_STOWING', {
                    warehouse_shelf: <b>{get(payload, 'warehouse_shelf')}</b>,
                })
            }
            case ORDER_ACTION.PACKAGE_VENDOR_PRINT_LAST_MILE:
            case ORDER_ACTION.PACKAGE_VENDOR_PRINT_ECOMMERCE:
            case ORDER_ACTION.PACKAGE_PRINT_LAST_MILE: {
                const creator = lodash.get(payload, "creator", "");
                return trans(`package:log.${log.action.replace('.', '_')}`, {
                    trackingNo: <b>{get(payload, 'tracking_no')}</b>,
                    creator
                })    
            }
            case ORDER_ACTION.PACKAGE_PRINT_9710_STAMP: {
                return trans('package:log.PACKAGE_PRINT_9710_STAMP')
            }
            default:
                return log.action;
        }
    }

    linkToPackage = packageCode => (
        <Link isNewTab params={{id: packageCode}} to="packages.detail">{packageCode}</Link>
    );

    renderMessageDetailUpdate(log) {
        let {payload} = log;
        let properties = {};
        let service_use = {};
        let change_fields = [];
        let oldValue = null;
        let newValue = null;
        let field = null;
        change_fields = lodash.get(payload, 'change_fields', []);
        change_fields = lodash.mapValues(change_fields, function (o, key) {
            let unit = null;
            newValue = null;
            field = null;
            switch (key) {
                case 'weight_net':
                case 'weight_box':
                case 'weight_converted':
                    oldValue = lodash.get(o, 'old') || 0;
                    newValue = lodash.get(o, 'new') || 0;
                    unit = 'kg';
                    field = trans('package:label.' + key).toLowerCase();
                    break;
                case 'length':
                case 'height':
                case 'width':
                    oldValue = 100 * lodash.get(o, 'old') || 0;
                    newValue = 100 * lodash.get(o, 'new') || 0;
                    oldValue = Number((oldValue).toFixed(3));
                    newValue = Number((newValue).toFixed(3));
                    unit = 'cm';
                    field = trans('package:label.' + key).toLowerCase();
                    break;
                case 'status':
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    oldValue = oldValue ? trans('package:state.' + lodash.get(o, 'old')) : null;
                    newValue = newValue ? trans('package:state.' + lodash.get(o, 'new')) : null;
                    field = trans('package:label.' + key).toLowerCase();
                    break;
                case 'status_transport':
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    oldValue = oldValue ? trans('package:status_transport.' + lodash.get(o, 'old')) : null;
                    newValue = newValue ? trans('package:status_transport.' + lodash.get(o, 'new')) : null;
                    field = trans('package:label.' + key).toLowerCase();
                    break;
                case 'status_checking':
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    oldValue = oldValue ? trans(`package:checking_statuses.${lodash.get(o, 'old')}`).toLowerCase() : null;
                    newValue = newValue ? trans(`package:checking_statuses.${lodash.get(o, 'new')}`).toLowerCase() : null;
                    field = trans(`package:${key}`).toLowerCase();
                    break;
                case 'status_damaged': {
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    oldValue = oldValue && trans(`package:damaged_statuses.${oldValue}`);
                    newValue = newValue && trans(`package:damaged_statuses.${newValue}`);

                    break;
                }
                default:
                    oldValue = lodash.get(o, 'old');
                    newValue = lodash.get(o, 'new');
                    field = trans('package:label.' + key).toLowerCase();
                    break;
            }
            return {old: oldValue, new: newValue, unit: unit}
        });


        lodash.map(lodash.get(payload, 'change_properties', []), (item, key) => {
            oldValue = null;
            newValue = null;
            if (key === 'add') {
                newValue = lodash.join(lodash.values(item), ', ');
                properties = {...properties, ...{new: newValue, unit: null}};
            }
            if (key === 'remove') {
                oldValue = lodash.join(lodash.values(item), ', ');
                properties = {...properties, ...{old: oldValue, unit: null}};
            }
        });

        if (!lodash.isEmpty(properties)) {
            change_fields.properties = properties;
        }

        lodash.map(lodash.get(payload, 'change_services', []), (item, key) => {
            oldValue = null;
            newValue = null;
            if (key === 'add') {
                newValue = lodash.join(lodash.values(item), ', ');
                service_use = {...service_use, ...{new: newValue, unit: null}};
            }
            if (key === 'remove') {
                oldValue = lodash.join(lodash.values(item), ', ');
                service_use = {...service_use, ...{old: oldValue, unit: null}};
            }
        });
        if (!lodash.isEmpty(service_use)) {
            change_fields.service_use = service_use;
        }

        return Object.keys(change_fields).map(param => {
            let data = change_fields[param] || {};
            let langKey = 'package:log.update_param';
            if (!data.old) {
                langKey = 'package:log.set_param';
            } else if (!data.new) {
                langKey = 'package:log.remove_param';
            }

            let by = '';
            let delivery = '';
            if (param === 'status_transport') {
                delivery =
                    <b>{lodash.get(log, 'payload.relate.delivery_note', '') || lodash.get(log, 'payload.relate.delivery_request', '')}</b>;
                if (lodash.get(log, 'payload.relate.delivery_note', '') !== '') {
                    by = t('package:label.by') + ' ' + t('delivery_note:log.' + lodash.get(log, 'payload.relate.action').toUpperCase()).toLowerCase();

                } else if (lodash.get(log, 'payload.relate.delivery_request', '') !== '') {
                    by = t('package:label.by') + ' ' + t('delivery_request:log.' + lodash.get(log, 'payload.relate.action').toUpperCase()).toLowerCase();
                }
            }
            switch (param) {
                case 'weight_net':
                    field = trans('package:label.' + param);
                    break;
                case 'length':
                case 'height':
                case 'width':
                    field = trans('package:label.' + param);
                    break;
                case 'status':
                    field = trans('package:label.' + param);
                    break;
                case 'status_checking':
                    field = trans(`package:${param}`);
                    break;
                default:
                    field = trans('package:label.' + param);
                    break;
            }

            return !['customer_note', 'note', 'customer_address_destination', 'status_reason_note'].includes(data.unit) ?(
                <span className="ml-1" key={Math.random() + '_' + Math.random()}>
                    {trans(langKey, {
                        param: !data.new ? field.toLowerCase() : field,
                        old_value: data.old ? <b>{data.old}{data.unit}</b> : null,
                        new_value: <b>{data.new}{data.unit}</b>,
                        by,
                        delivery
                    })}
                </span>
            ) : null;
        });

    }
}

PackageLogMessage.defaultProps = {
    log: {},
};

PackageLogMessage.propTypes = {
    log: PropTypes.object,
};

export default withTranslation()(PackageLogMessage);
