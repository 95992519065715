import React, { Component } from 'react';
import { Layout, Modal } from 'antd';
import { get, omit } from 'lodash';

import { DELIVERY_OBJECT } from './../../constants';
import pageService from './../../../Common/Page/pageService';
import Sound from './../../../Sound';
import { t } from './../../../../system/i18n';

import DeliveryNoteSuggests from './DeliveryNoteSuggests';
import FormDeliveryNote from './FormDeliveryNote';
import FormDeliveryObject from './FormDeliveryObject';
import PopupListPackageByBag from './PopupListPackageByBag';
import PromptLeavePage from './PromptLeavePage';

class Create extends Component {
    state = {
        isShowPackageListByBagPopup: false,
        selectedBag: {},
        disablePromptLeavePage: false
    };

    handleChangeInput = data => {
        const { input, onChangeInput } = this.props;

        onChangeInput({
            ...input,
            ...data
        });
    };

    handleOpenPackageListByBagPopup = bag => {
        this.setState({
            isShowPackageListByBagPopup: true,
            selectedBag: bag
        });
    };

    handleCancelPackageListByBagPopup = () => {
        this.setState({
            isShowPackageListByBagPopup: false,
            selectedBag: {}
        });
    };

    handleCreateDeliveryNote = (data, auto = false) => {
        const { onCreateDeliveryNote } = this.props;
        const formattedData = omit(data, ['customerBagPackages']);
        if (auto) {
            this.setState({
                disablePromptLeavePage: true
            })
        }
        onCreateDeliveryNote(formattedData, auto);
    };

    handleDeleteSuggest = (identity, event) => {
        event.stopPropagation();

        Modal.confirm({
            cancelText: t('btn.cancel'),
            centered: true,
            okText: t('btn.ok'),
            title: t('delivery_note:create.confirm_delete_suggest'),
            onOk: this.confirmDeleteSuggest.bind(this, identity)
        });
    };

    confirmDeleteSuggest = identity => {
        const { deleteSuggest } = this.props;

        deleteSuggest(identity);
    };

    handleSuccessDeliveringDeliveryRequest = () => {
        const { input, getDeliveryCustomerRequests } = this.props;

        getDeliveryCustomerRequests(input.id_warehouse);
    };

    componentDidMount() {
        const { checkOnbeforeUnload, setMenuActive } = this.props;
        pageService.setTitle(t('menu.create_delivery_note'), <Sound />);
        setMenuActive('delivery-notes/customer/create');
        window.onbeforeunload = checkOnbeforeUnload;
    }

    componentWillUnmount() {
        this.props.clearState();
        window.onbeforeunload = null;
        this.setState({
            disablePromptLeavePage: false
        })
    }

    render() {
        const { isShowPackageListByBagPopup, selectedBag, disablePromptLeavePage} = this.state;
        const {
            deliveryCustomerRequests,
            input,
            loadingDeliveryCustomerRequests,
            route,
            selectedBagIds,
            selectedPackageIds,
            suggests,
            onPrint,
            showDeliveryRequestDetailModal,
            getLastmileCarrierSuggest
        } = this.props;

        return (
            <Layout className="create-delivery-note has-sub-sider">
                <FormDeliveryNote
                    canChangeInput={!suggests.length}
                    deliveryCustomerRequests={deliveryCustomerRequests}
                    input={input}
                    multiple={!input.object}
                    loadingDeliveryCustomerRequests={loadingDeliveryCustomerRequests}
                    route={route}
                    scanBarcodeByCustomer={!!input.object}
                    showDeliveryCustomerRequests
                    onChange={this.handleChangeInput}
                    showDeliveryRequestDetailModal={showDeliveryRequestDetailModal}
                    onSuccessDeliveringDeliveryRequest={this.handleSuccessDeliveringDeliveryRequest}
                />

                {input.object ? (
                    <FormDeliveryObject
                        getLastmileCarrierSuggest={getLastmileCarrierSuggest}
                        input={input}
                        object={DELIVERY_OBJECT.CUSTOMER}
                        onOpenPackageListByBagPopup={this.handleOpenPackageListByBagPopup}
                    /> // remove in future
                ) : (
                    <DeliveryNoteSuggests
                        creatingWithTrackingNo={this.props.creatingWithTrackingNo}
                        selectedBagIds={selectedBagIds}
                        selectedPackageIds={selectedPackageIds}
                        suggests={suggests}
                        warehouseId={input.id_warehouse}
                        onCreateDeliveryNote={this.handleCreateDeliveryNote}
                        onDelete={this.handleDeleteSuggest}
                        onPrint={onPrint}
                        onOpenPackageListByBagPopup={this.handleOpenPackageListByBagPopup}
                    />
                )}

                <PopupListPackageByBag
                    bag={selectedBag}
                    visible={isShowPackageListByBagPopup}
                    onCancel={this.handleCancelPackageListByBagPopup}
                />

                {
                    !disablePromptLeavePage && 
                    <PromptLeavePage
                        when={suggests.filter(suggest => !get(suggest, 'delivery_note.id')).length > 0}
                    />
                }
                
            </Layout>
        );
    }
}

export default Create;
