import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ScanBarcode extends React.PureComponent {
    constructor (props) {
        super(props);

        this.state = {
            code: '',
        };
    }

    onSubmit = (event) => {
        event.preventDefault();

        const { value, scanBarcode } = this.props;

        if (value) {
            scanBarcode(value);
            this.setCode();
        }
    };

    setCode (code = '') {
        this.props.onChange((code || '').trim());
    }

    render () {
        const { value } = this.props;

        return (
            <form onSubmit={this.onSubmit} className="a-form--loading loading-input">
                <Input
                    value={value}
                    onChange={event => this.setCode(event.target.value)}
                    disabled={!this.props.canScan}
                    className={'a-input a-input--scan-code'}
                />
            </form>
        );
    }
}

ScanBarcode.defaultProps = {
    canScan: true,
    scanBarcode: () => {
    },
};

ScanBarcode.propTypes = {
    canScan: PropTypes.bool,
    scanBarcode: PropTypes.func,
};

export default withTranslation()(ScanBarcode)
