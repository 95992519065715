import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import DetailBagSize from './components';
import * as commonAction from "../../Common/actions";
import * as actions from '../actions';
import * as selectors from '../selectors';

const mapStateToProps = (state) => ({
    bagSizeDetail: _.get(selectors.getDetailBagSize(state), 'bag_size')
});

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    fetchDetailBagSize: id => dispatch(actions.fetchDetailBagSize(id))
});

class DetailBagSizeContainer extends React.Component {

    componentDidMount(){
        this.props.setMenuActive("config/bag-sizes");
        this.props.fetchDetailBagSize(this.getId());
    }

    getId = () => {
        return this.props.match.params.id;
    };

    render() {
        return (
            <DetailBagSize {...this.props}/>
        )
    }
}

DetailBagSizeContainer.defaultProps = {
    bagSizeDetail: {}
};

DetailBagSizeContainer.propTypes = {
    bagSizeDetail: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailBagSizeContainer);
