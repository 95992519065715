import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Modal} from 'antd';

class CancelDeliveryRequest extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenCancelConfirm: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.isOpenCancelConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenCancelConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmCancel(event) {
        event.preventDefault();

        let {t, loading} = this.props;
        Modal.confirm({
            title: t("delivery_request:label.warning"),
            content: <p>{t("delivery_request:message.warning_confirm_cancel")}</p>,
            okText: t("delivery_request:label.ok"),
            cancelText: t("delivery_request:label.cancel"),
            okButtonProps: {loading, className: "_on-ok-delete-delivery_request"},
            cancelButtonProps: {loading, className: "_on-cancel-delete-delivery_request"},
            onOk: this.handleOnOk.bind(this),
            onClose: this.handleOnClose.bind(this),
        });
        this.props.beginCancelDeliveryRequest();
    }

    /**
     * handle action click ok to unlink order
     */
    handleOnOk() {
        let {code, agency} = this.props.detailDeliveryRequest;
        let data = {agency: typeof agency === "string" ? agency : agency.code, code};
        this.props.onCancelDeliveryRequest(data);
    }

    /**
     * handle action close modal
     */
    handleOnClose() {
        this.props.endCancelDeliveryRequest();
    }

    render() {
        let {t, type, loading} = this.props;
        return type === 'detail' ? <Button onClick={this.confirmCancel.bind(this)}
                                           loading={loading}
                                           className="a-btn a-btn--button-link a-text--blue _cancel-delivery-request">{t("delivery_request:label.cancel_detail")}</Button> :
            <a href="/" className={`a-text--nowrap a-text--blue _cancel-delivery-request`}
               onClick={this.confirmCancel.bind(this)}>{t("delivery_request:label.cancel")}</a>
    }
}

CancelDeliveryRequest.defaultProps = {
    deliveryRequestData: {},
    isModalProcessing: false,
    loading: false,
    type: 'icon',
};

CancelDeliveryRequest.propTypes = {
    deliveryRequestData: PropTypes.object,
    onCancelDeliveryRequest: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginCancelDeliveryRequest: PropTypes.func,
    endCancelDeliveryRequest: PropTypes.func,
    type: PropTypes.string
};

export default withTranslation()(CancelDeliveryRequest);
