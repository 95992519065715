import React, { Component } from 'react'
import { Button, Icon, Input, Table, Typography } from 'antd'
import clsx from 'clsx'
import lodash from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import { NOTE_TYPES } from './../constants'
import { dateFormatter } from './../../Common/services/format'
import { t, trans } from './../../../system/i18n'

import ActionAttention from './../ActionAttention/ActionAttentionContainer'
import ConfigTableColumn from './../components/ConfigTableColumn'
import StageCard from './../components/StageCard'
import EditNoteModal from './../components/EditNoteModal'
import Logs from './../Logs/LogsContainer'
import Pagination from './../../Common/components/Pagination'
import { Link } from './../../../system/routing'

import SelectCustomerContainer from './../../Customer/SelectCustomer/SelectCustomerContainer'

import { ReactComponent as IconNote } from './../../../resources/images/24px_notes.svg'
import SelectOrderCreateNote from './SelectOrder'

const { Text } = Typography

const now = moment()

class List extends Component {
    constructor(props) {
        super(props)

        let defaultColumns = this.getConfigColumns()
        let selectedColumns = lodash.map(defaultColumns, item => item.key)
        let cachedColumns = localStorage.getItem(`${props.object.toLowerCase()}_notes`)
        if (cachedColumns) {
            selectedColumns = JSON.parse(cachedColumns)
        }

        this.state = {
            code: undefined,
            selectedColumns,
            isShowPopupLog: false,
            selectedNoteId: undefined,
            editNoteModalVisible: false,
        }
    }

    getConfigColumns = () => {
        const { object } = this.props

        let codeTitle

        if (object === NOTE_TYPES.ORDER) {
            codeTitle = t('order_code')
        } else if (object === NOTE_TYPES.TRACKING) {
            codeTitle = t('freight_bill')
        } else if (object === NOTE_TYPES.PACKAGE) {
            codeTitle = t('package_code')
        } else if (object === NOTE_TYPES.CUSTOMER) {
            codeTitle = 'Username'
        }

        return [
            {
                dataIndex: object === NOTE_TYPES.CUSTOMER ? 'note.object_data' : 'note.object_data.code',
                disabled: true,
                key: 'object_code',
                title: codeTitle,
                render: (text, record) => {
                    if (!text) {
                        return '--'
                    }

                    if (object === NOTE_TYPES.PACKAGE) {
                        return (
                            <Link
                                isNewTab
                                params={{
                                    id: record.note.object_data.code,
                                }}
                                to="packages.detail">
                                {text}
                            </Link>
                        )
                    }
                    if (object === NOTE_TYPES.ORDER) {
                        return (
                            <>
                                <span className={'_order'}>{text}</span>

                                {lodash.get(record, 'note.object_data.agency_identity_code') && (
                                    <div className="a-text--nowrap a-text--gray a-text--fz-12">
                                        ({lodash.get(record, 'note.object_data.agency_identity_code', '---')})
                                    </div>
                                )}
                            </>
                        )
                    }

                    if (object === NOTE_TYPES.CUSTOMER) {
                        return `${lodash.get(text, 'username', '--')} (${lodash.get(text, 'agency', '--')})`
                    }

                    return text
                },
            },
            {
                dataIndex: 'note.created_at',
                disabled: true,
                key: 'created_at',
                sorter: true,
                sortOrder: this.getSortOrder('created_at'),
                title: t('note:order.label.attention_created_time'),
                render: text => (text ? dateFormatter.full(text) : '--'),
            },
            {
                dataIndex: 'note.expired_at',
                key: 'expired_at',
                sorter: true,
                sortOrder: this.getSortOrder('expired_at'),
                title: t('expired_time'),
                render: text => (text ? dateFormatter.full(text) : '--'),
            },
            {
                dataIndex: 'note.stages',
                key: 'stages',
                title: t('note:column.stages'),
                render: (text, record) =>
                    text
                        ? text.map((stage, index) => (
                              <StageCard
                                  className={clsx({
                                      'mb-2': index !== record.note.stages.length - 1,
                                  })}
                                  key={stage}
                                  stage={stage}
                              />
                          ))
                        : '--',
            },
        ]
    }

    handleChangeCode = e => {
        this.setState({
            code: e.target.value,
        })
    }

    handleSubmitCode = () => {
        const { code } = this.state
        const { object, getSuggestObject } = this.props

        if (!code) {
            return
        }

        if (object === NOTE_TYPES.TRACKING) {
            this.setState({
                editNoteModalVisible: true,
            })
        } else {
            getSuggestObject({
                code,
                callback: () => {
                    this.setState({
                        editNoteModalVisible: true,
                    })
                },
            })
        }
    }

    onShowLog = id => {
        this.setState({
            isShowPopupLog: true,
            selectedNoteId: id,
        })
    }

    onCloseLog = () => {
        this.setState({
            isShowPopupLog: false,
            selectedNoteId: undefined,
        })
    }

    renderExpandRowIcon = props => {
        return (
            <span
                className="expand-row-icon"
                onClick={e => props.onExpand(props.record, e)}
                dangerouslySetInnerHTML={{ __html: '' }}
                style={{ color: 'blue', cursor: 'pointer' }}
            />
        )
    }

    renderExpandedRow = record => {
        const { logsLoading, object, t } = this.props

        return (
            <>
                <div className="list-attention">
                    <div className="attention__item">
                        <div className="attention__item__info-top">
                            <span className="info-top-user">
                                {' '}
                                {lodash.get(record, 'creator.name')},&nbsp;
                                <span className="info-top-time">{dateFormatter.dateTime(lodash.get(record, 'note.created_at'))}</span>
                            </span>
                            <ActionAttention
                                className="ml-2"
                                creator={lodash.get(record, 'creator')}
                                id={lodash.get(record, 'note.id_object')}
                                note={record.note}
                                object={object}
                                successDelete={this.handleSuccessDeleteNote}
                            />
                            <span className="a-text--gray mx-2">|</span>
                            {logsLoading === lodash.get(record, 'note.id') ? (
                                <Icon type="loading" />
                            ) : (
                                <span
                                    onClick={this.onShowLog.bind(this, lodash.get(record, 'note.id'))}
                                    className="a-text--blue a-text--cursor-pointer a-text--nowrap __log-action">
                                    {t('note:btn.history_log_follow')}
                                </span>
                            )}
                        </div>

                        <p
                            className="attention__item__desc"
                            key={lodash.get(record, 'note.id')}>
                            <span
                                className={clsx('package-note__icon note-icon', {
                                    'note-icon--deleted': !!lodash.get(record, 'note.is_deleted', '--'),
                                    'note-icon--warning': !now.is_deleted && now.isAfter(lodash.get(record, 'note.expired_at')),
                                })}>
                                <IconNote />
                            </span>{' '}
                            <Text delete={!!lodash.get(record, 'note.is_deleted', '--')}>{lodash.get(record, 'note.content', '--')}</Text>
                        </p>
                    </div>
                </div>
            </>
        )
    }

    onChangePageNumber = (page, pageSize) => {
        const { filter } = this.props
        this.props.onSearch({
            ...filter,
            page: page,
            per_page: pageSize,
        })
    }

    onChangeConfigTableColumn = values => {
        const { object } = this.props

        this.setState({ selectedColumns: values }, () => {
            localStorage.setItem(`${object.toLowerCase()}_notes`, JSON.stringify(this.state.selectedColumns))
        })
    }

    getSortOrder = field => {
        const { filter } = this.props

        if (filter.sort_by === field) {
            return filter.sort_direction === 'asc' ? 'ascend' : 'descend'
        }

        return null
    }

    onChangeTable = (pagination, filters, sorter) => {
        const { filter, object, onSearch } = this.props

        onSearch({
            ...filter,
            object,
            sort_by: sorter.columnKey,
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        })
    }

    handleCancelEditNoteModal = () => {
        const { clearSuggestObject } = this.props

        if (lodash.isFunction(clearSuggestObject)) {
            clearSuggestObject()
        }

        this.setState({
            code: undefined,
            editNoteModalVisible: false,
        })
    }

    handleSuccessAddNote = () => {
        const { filter, onChangeFilter } = this.props

        onChangeFilter(filter)
        this.handleCancelEditNoteModal()
    }

    handleSuccessDeleteNote = () => {
        const { filter, onChangeFilter } = this.props

        onChangeFilter(filter)
    }

    getNoteObjectId = () => {
        const { code } = this.state
        const { object, suggestObject } = this.props

        if (object === NOTE_TYPES.ORDER) {
            return lodash.get(suggestObject, 'order.id')
        }

        if (object === NOTE_TYPES.PACKAGE) {
            return lodash.get(suggestObject, 'package.id')
        }

        if (object === NOTE_TYPES.TRACKING) {
            return code
        }

        if (object === NOTE_TYPES.CUSTOMER) {
            return lodash.get(suggestObject, 'customer.id')
        }
    }

    handleSelectObject = objectId => {
        const { setSuggestObjectId } = this.props
        setSuggestObjectId(objectId)
        this.setState({
            editNoteModalVisible: true,
        })
    }

    componentDidMount() {
        const { filter, object } = this.props

        if (object === NOTE_TYPES.ORDER && lodash.get(filter, 'order')) {
            this.setState(
                {
                    code: filter.order,
                },
                () => {
                    this.handleSubmitCode()
                }
            )
        }
    }

    render() {
        const { t, list, object, pagination, loading, filter, loadingSuggestObject } = this.props
        const { code, editNoteModalVisible, isShowPopupLog, selectedColumns, selectedNoteId } = this.state
        const defaultColumns = this.getConfigColumns()
        const columns = lodash.filter(defaultColumns, item => lodash.includes(selectedColumns, item.key))

        return (
            <>
                <div className="a-content__top a-content__top--page-list">
                    <div className="a-content--page-list__title">
                        <h3>{trans(`note:label.list_${object.toLowerCase()}`, { count: pagination.total || 0 })}</h3>
                    </div>
                    <div className="a-content--page-list__top-action gflex galign-center">
                        <Button
                            type="primary"
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 a-hidden"
                            ghost>
                            {t('note:btn.export_csv')}
                        </Button>
                        <ConfigTableColumn
                            {...this.props}
                            options={defaultColumns}
                            values={selectedColumns}
                            onChange={this.onChangeConfigTableColumn}
                        />

                        <div className="a-group--input-inline ml-5 w-200">
                            {object === NOTE_TYPES.CUSTOMER ? (
                                <SelectCustomerContainer
                                    allowClear
                                    className="a-select a-select--w-100 _code"
                                    emptyOption={false}
                                    onChange={this.handleSelectObject}
                                />
                            ) : object === NOTE_TYPES.ORDER ? (
                                <SelectOrderCreateNote
                                    placeholder={t('order_id_code')}
                                    value={lodash.get(this.props.suggestObject, 'order.id')}
                                    allowClear
                                    className="a-select a-select--w-100 _code"
                                    onChange={value => {
                                        this.handleSelectObject(value)
                                    }}
                                    isClearDataSearch={!!editNoteModalVisible}
                                />
                            ) : (
                                <Input
                                    className="a-input a-input--h-25 a-input--small-placeholder _code"
                                    size="small"
                                    placeholder={
                                        object === NOTE_TYPES.TRACKING
                                            ? t('freight_bill')
                                            : object === NOTE_TYPES.PACKAGE
                                            ? t('package_code')
                                            : t('customer_username')
                                    }
                                    value={code}
                                    onChange={this.handleChangeCode}
                                    onPressEnter={this.handleSubmitCode}
                                />
                            )}
                            {![NOTE_TYPES.CUSTOMER, NOTE_TYPES.ORDER].includes(object) && (
                                <Button
                                    className="a-btn--h-25 a-btn--primary _btn-create"
                                    disabled={!code}
                                    icon="plus"
                                    loading={loadingSuggestObject}
                                    type="primary"
                                    onClick={this.handleSubmitCode}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <div className="a-table--responsive">
                        <Table
                            className="a-table--antd a-table--follow"
                            columns={columns}
                            dataSource={list}
                            expandedRowRender={this.renderExpandedRow}
                            expandedRowKeys={lodash.map(list, item => lodash.get(item, 'note.id'))}
                            expandIcon={this.renderExpandRowIcon}
                            expandIconAsCell={false}
                            pagination={false}
                            rowKey={record => lodash.get(record, 'note.id')}
                            loading={loading}
                            onChange={this.onChangeTable}
                            rowClassName={'__row-cl-follow'}
                        />
                    </div>

                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.get(filter, 'per_page', 20)}
                        total={pagination ? pagination.total : 0}
                        loading={this.props.loading}
                        onChange={this.onChangePageNumber}
                    />
                </div>

                <EditNoteModal
                    id={this.getNoteObjectId()}
                    object={object}
                    visible={editNoteModalVisible}
                    onCancel={this.handleCancelEditNoteModal}
                    onOk={this.handleSuccessAddNote}
                />

                <Logs
                    objectId={selectedNoteId}
                    visible={isShowPopupLog}
                    onCloseLog={this.onCloseLog}
                />
            </>
        )
    }
}

const mapStateToProps = ({ common }) => {
    return {
        logsLoading: common.log.logsLoading,
    }
}

export default connect(mapStateToProps)(List)
