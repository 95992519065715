import React from 'react';
import { Button, Col, Form, Icon, Input, Layout, notification, Row, Upload } from 'antd';
import lodash from 'lodash';
import { withTranslation } from 'react-i18next';

import api from './../../apiService';
import { DEFAULT_STATE_FORM_GENERAL } from './../../constants';
import { HTTP_STATUS } from './../../../../system/api/constants';
import { t } from './../../../../system/i18n';
import { getErrors } from './../../../../system/support/helpers';

import Agencies from './Agencies/AgenciesContainer';
import FormInformationLoading from './FormInformationLoading';

const Content = Layout.Content;

class General extends React.Component {
    state = {
        ...DEFAULT_STATE_FORM_GENERAL,
        id_general: 0,
        loading: false
    };

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        const id = lodash.get(props.settings, 'id', 0);
        const id_general = lodash.get(props.settings, 'id_general', 0);

        if (state.id !== id && id !== 0) {
            nextState = lodash.isNil(props.settings) ? {
                ...DEFAULT_STATE_FORM_GENERAL
            } : {
                partner_organization_address: lodash.get(props, 'settings.information.partner_organization_address'),
                partner_organization_name: lodash.get(props, 'settings.information.partner_organization_name'),
                partner_organization_phone: lodash.get(props, 'settings.information.partner_organization_phone'),
                partner_logo_preview: lodash.get(props, 'settings.information.partner_logo')
            };
            nextState.id = id;
            nextState.id_general = id_general;
        }

        return nextState;
    }

    onChangeInput = (event, valueInput = 0) => {
        const value = event.target ? event.target.value : valueInput;
        const name = event.target ? event.target.name : event;

        this.setState({
            [name]: value
        }, this.doSubmit);
    };

    handleSubmit = async (changeImage = false) => {
        const { setDetail } = this.props;

        try {
            const formData = new FormData();
            lodash.keys(DEFAULT_STATE_FORM_GENERAL).forEach((key) => {
                formData.append(key, lodash.isNil(this.state[key]) ? null : this.state[key]);
            });

            if (!changeImage) {
                formData.delete('partner_logo');
            }

            const response = await api.updateSetting(formData);

            setDetail(response.data.setting_config_general);
        } catch (error) {
            const response = error.response;

            if (lodash.get(response, 'data.code') === 'exception') {
                notification.error({
                    message: (
                        <span className="_update_setting_error">{t('message.server_error')}</span>
                    )
                });
            } else if (lodash.get(response, 'data.data.partner_first_number_code.already_exist')) {
                const translatedErrors = getErrors(response.data);

                notification.error({
                    message: (
                        <span className="_notification_error_partner_first_number_code">
                            {translatedErrors.partner_first_number_code}
                        </span>
                    )
                });
            } else if (lodash.get(response, 'status') !== HTTP_STATUS.UNAUTHORIZED) {
                notification.error({
                    message: (
                        <span className="_notification_error_no_service">{t('message.no_service')}</span>
                    )
                });
            }
        }
    };

    doSubmit = lodash.debounce(this.handleSubmit, 350);

    onPreview = () => {
        return false;
    };

    handleCancel = () => this.setState({
        previewVisible: false
    });

    handleChange = (name, data) => {
        const { file, fileList } = data;

        this.setState({
            [name]: fileList.length > 0 ? file : '', [`disable_${name}`]: file
        }, this.handleSubmit.bind(undefined, true));
    };

    beforeUpload = () => {
        return false;
    };

    onRemoveImage = name => {
        this.setState({
            [name]: undefined
        });
    };

    render() {
        const { t } = this.props;
        const loading = this.state.loading || this.props.loading;

        return (
            <Content className="aml-16-lg aml-24 amr-24 amr-16-lg a-content-config">
                <div className="a-block-config">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t('config:title.general')}</h3>
                    </div>
                    <div className="a-block-config__body">
                        <Form className="a-form">
                            <div className="a-block-config__title--block-child">
                                <span className="title">{t('config:title.information_partner')}</span>
                            </div>
                            {loading ? (
                                <FormInformationLoading />
                            ) : (
                                <Row gutter={{lg: 24, xl: 32}} className="a-block-info">
                                    <Col lg={{span: 24}} className="a-form--group">
                                        <Form.Item
                                            className="a-form__item "
                                            label={t('config:label.partner_logo')}
                                            labelCol={{span: 24}}
                                        >
                                            <Upload
                                                listType="picture"
                                                multiple={false}
                                                showUploadList={false}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleChange.bind(this, 'partner_logo')}
                                                onPreview={this.onPreview}
                                                onRemove={this.onRemoveImage.bind(this, 'partner_logo')}
                                            >
                                                <Button>
                                                    <Icon type="upload"/> {t('config:label.upload')}
                                                </Button>
                                            </Upload>

                                        </Form.Item>
                                        {this.state.partner_logo_preview && (
                                            <div className="partner-image-container">
                                                <img
                                                    alt=""
                                                    className="image-partner"
                                                    src={this.state.partner_logo_preview}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                                        <Form.Item
                                            className="a-form__item"
                                            label={t('organization_or_company_name')}
                                            labelCol={{span: 24}}
                                        >
                                            <Input
                                                className="a-input _partner_organization_name"
                                                disabled={loading}
                                                name="partner_organization_name"
                                                placeholder={t('input.organization_or_company_name')}
                                                value={this.state.partner_organization_name}
                                                onChange={this.onChangeInput}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                                        <Form.Item
                                            className="a-form__item"
                                            label={t('config:label.partner_organization_phone')}
                                            labelCol={{span: 24}}
                                        >
                                            <Input
                                                className="a-input _partner_organization_phone"
                                                disabled={loading}
                                                name="partner_organization_phone"
                                                placeholder={t("config:placeholder.partner_organization_phone")}
                                                value={this.state.partner_organization_phone}
                                                onChange={this.onChangeInput}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="a-form--group" lg={{span: 24}} xl={{span: 12}}>
                                        <Form.Item
                                            className="a-form__item"
                                            label={t('config:label.partner_organization_address')}
                                            labelCol={{span: 24}}
                                        >
                                            <Input
                                                className="a-input _partner_organization_address"
                                                disabled={loading}
                                                name="partner_organization_address"
                                                placeholder={t('config:placeholder.partner_organization_address')}
                                                value={this.state.partner_organization_address}
                                                onChange={this.onChangeInput}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}

                            <Agencies />
                        </Form>
                    </div>
                </div>
            </Content>
        );
    }
}

export default withTranslation()(Form.create()(General));
