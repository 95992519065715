import React, { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { withTranslation } from 'react-i18next';

const { TextArea } = Input;

const ProcessedReasonModal = ({ processedReason, t, visible, onCancel, onOk }) => {
    const [reason, setReason] = useState();

    const onChangeProcessedReason = event => {
        setReason(event.target.value);
    };

    const handleCancel = () => {
        onCancel();
        setReason(undefined);
    };

    const handleOk = () => {
        onOk(reason);
        setReason(undefined);
    };

    useEffect(() => {
        setReason(processedReason);
    }, [processedReason]);

    return (
        <Modal
            cancelText={t('btn.cancel')}
            okText={t('btn.ok')}
            visible={visible}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Form.Item className="amt-16">
                <TextArea
                    className="a-inline-edit--input"
                    placeholder={t('inventory:reason')}
                    value={reason}
                    onChange={onChangeProcessedReason}
                />
            </Form.Item>
        </Modal>
    );
};

export default withTranslation()(ProcessedReasonModal);
