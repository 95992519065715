import React, { forwardRef } from 'react';
import { connect } from 'react-redux';

import { getState } from './../../../../ShippingFee/selectors';

import RoutesSelect from './components';

const mapStateToProps = (state, props) => {
    return {
        loading: props.loading || getState(state, 'routes.loading')
    };
};

const RoutesSelectContainer = forwardRef((props, ref) => {
    return (
        <RoutesSelect {...props} ref={ref} />
    );
});

export default connect(
    mapStateToProps,
    undefined,
    undefined,
    {
        forwardRef: true
    }
)(RoutesSelectContainer);
