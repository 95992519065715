import { takeLatest } from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(ACTION.GET_DETAIL.REQUEST, saga.getDetail);
    yield takeLatest(ACTION.GET_DETAIL.SUCCESS, saga.onSuccessGetDetail);
    yield takeLatest(ACTION.GET_DETAIL.FAILED, saga.onFailedGetDetail);
}
