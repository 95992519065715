import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {withTranslation} from 'react-i18next';
import {Form, Row, Spin} from 'antd';
import pageService from '../../../Common/Page/pageService';
import HeaderPackage from './HeaderPackage';
import OrderInfo from './OrderInfo';
import PackageLog from './PackageLog';
import PackageCustomer from './../../FormPackageDetail/components/PackageCustomer';
import UpdateRelatedPackage from './../../UpdateRelatedPackage/UpdateRelatedPackageContainer';
import UpdateAlonePackageContainer from './../../UpdateAlonePackage/UpdateNotMapOrderPackageContainer';
import BottomAction from './BottomAction';
import OrderPackages from '../../../Order/OrderPackages/OrderPackagesContainer';
import {NOTE_TYPES} from "../../../Notes/constants";

import DamagedInfo from './DamagedInfo';
import Attentions from './../../../Common/components/Attentions';
class PackageDetail extends React.PureComponent {
    componentDidMount() {
        const { setMenuActive } = this.props;

        setMenuActive('packages/create');
        this.updatePageTitle();
    }

    componentWillUnmount() {
        this.props.clearPrintState();
    }

    componentDidUpdate(prevProps) {
        if (
            lodash.get(this.props.package, 'id') !== lodash.get(prevProps.package, 'id')
            || this.props.loading !== prevProps.loading
        ) {
            this.updatePageTitle();
        }
    }

    updatePageTitle() {
        pageService.setTitle(
            this.props.t('package:detail.title') + (this.props.package ? ` - ${this.props.package.code}` : ''),
            this.props.loading ? <Spin/> : null
        );
    }

    createSamePackage() {
        this.props.createPackageCode({
            ...this.props.package,
            id_warehouse: lodash.get(this.props.package, 'id_warehouse_current', '')
        });
    }

    handleSuccessChangeDamagedStatus = data => {
        const { setPackage } = this.props;

        setPackage({
            ...this.props.package,
            ...data.package,
            ...lodash.omit(data, 'package')
        });
    };

    handleSuccessAddNote = () => {
        const { fetchPackage } = this.props;

        fetchPackage(lodash.get(this.props.package, 'code'));
    };

    render() {
        let packageData = this.props.package;
        let {noneData, loading_data, user} = this.props;

        if (!packageData) {
            return null;
        }

        const attentions = lodash.get(packageData, 'attentions', []) || [];
        let isShowBtnAddAttention = true;
        for (let item of attentions) {
            if (lodash.get(item, 'creator.id') === user.id && lodash.get(item, 'attention.type') === NOTE_TYPES.PACKAGE) {
                isShowBtnAddAttention = false;
                break;
            }
        }

        const placeOfficalStamp = lodash.get(packageData,"permissions.place_display_official_stamp", []);

        return (
            <Row type="flex" className={`_screen_${packageData.current_page ? packageData.current_page : '_package_detail'}`}>
                <div className="a-content--ischatbox a-package--detail">
                    {packageData.order ? (
                        <OrderPackages
                            currentPackageId={packageData.id}
                            order={packageData.order}
                        />
                    ) : null}

                    <div className="block--primary a-package package-detail">
                        <HeaderPackage package={packageData} noneData={noneData} />
                        <div className="body a-package__body ">
                            <Form className="a-form form--package">

                                {
                                    (packageData.current_page === 'UpdateAlonePackage') ?
                                        <UpdateAlonePackageContainer packageData={packageData}/> :
                                        (
                                            packageData.current_page === 'UpdateRelatedPackage' ?
                                                <UpdateRelatedPackage package={packageData}/> :
                                                <div>
                                                    <Attentions
                                                        className="amb-16"
                                                        notes={lodash.get(packageData, 'notes')}
                                                        notices={lodash.get(packageData, 'notices.text')}
                                                    />
                                                    <DamagedInfo
                                                        package={packageData}
                                                        statusDamaged={lodash.get(packageData, 'package_detail.status_damaged')}
                                                        onSuccessChangeDamagedStatus={this.handleSuccessChangeDamagedStatus}
                                                    />
                                                    <OrderInfo
                                                        packageData={packageData}
                                                        noneData={noneData}
                                                        isShowBtnAddAttention={isShowBtnAddAttention}
                                                        onSuccessAddNote={this.handleSuccessAddNote}
                                                    />
                                                    <PackageCustomer package={packageData} noneData={noneData}/>
                                                    <BottomAction
                                                        allowPrintOfficalStamp={placeOfficalStamp.includes("package_detail")}
                                                        package={packageData}
                                                        loading_data={loading_data}
                                                        setPackagePage={this.props.setPackagePage}
                                                        createPackage={this.createSamePackage.bind(this)}
                                                    />
                                                </div>
                                        )
                                }
                            </Form>
                        </div>
                    </div>
                </div>
                <PackageLog package={packageData}/>
            </Row>
        );
    }
}

PackageDetail.defaultProps = {
    package: {},
    noneData: '--',
    loading: false,
};

PackageDetail.propTypes = {
    package: PropTypes.object,
    noneData: PropTypes.string,
    loading: PropTypes.bool,
    setPackagePage: PropTypes.func,
    clearPrintState: PropTypes.func,
};

export default withTranslation()(PackageDetail);
