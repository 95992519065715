/* eslint-disable */

import React from 'react';
import ScanPackage from './components/DetailPackageChecked'

class CheckPackageContainer extends React.Component {
    render() {
        return (
            <ScanPackage/>
        );
    }
}

export default CheckPackageContainer;