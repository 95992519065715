import React, { Fragment, PureComponent } from 'react';
import { Alert, Layout, Typography } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';

import pageService from './../../../Common/Page/pageService';
import Sound from './../../../Sound';
import { trans } from './../../../../system/i18n';

import InventoryBarcode from './../../InventoryBarcode/InventoryBarcodeContainer';
import HistoryList from './../../HistoryList/HistoryListContainer';

const { Content } = Layout;
const { Paragraph } = Typography;

class InventoryScan extends PureComponent {
	updatePageTitle = () => {
		const { inventory } = this.props;
		const code = get(inventory, 'inventory.code', '');
		const inventoryType = get(inventory, 'inventory.type');

		pageService.setTitle(`${trans(inventoryType ? `inventory:inventory_types.${inventoryType}` : 'menu.inventory')} - ${code}`, <Sound/>);
	};

	componentDidMount() {
		this.updatePageTitle();
		this.props.setMenuActive('inventory/create');
		window.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(prevProps) {
		const { inventory, loading } = this.props;

		if (get(inventory, 'inventory.id') !== get(prevProps.inventory, 'inventory.id') || loading !== prevProps.loading) {
			this.updatePageTitle();
		}
	}

	render() {
		const { inventory } = this.props;

		return (
			<Layout>
				<Content
					className="a-content pl-0 a-barcode-scan a-content--page-list min-height-not-header gflex gflex-direction-column">
					<InventoryBarcode scanBarcode={this.props.scanBarcode} />
					{!!(get(inventory, 'inventory.ignore_package_in_bag') || get(inventory, 'inventory.ignore_services.length')) && (
						<Alert
							className="a-alert--warning amr-24 amt-16"
							type="warning"
							message={(
								<>
									{!!get(inventory, 'inventory.ignore_package_in_bag') && (
										<Paragraph className="mb-0">
											{trans('inventory:ignore_package_in_bag')}
										</Paragraph>
									)}
									{!!get(inventory, 'inventory.ignore_services.length') && (
										<Paragraph className="mb-0">
											{trans('inventory:ignore_services_detail', {
												services: inventory.inventory.ignore_services.map((service, index) => (
													<Fragment key={service.id}>
														<span className={clsx({
															'a-text--green': service.type === 'consign'
														})}>
															{service.name}
														</span>
														{index !== inventory.inventory.ignore_services.length - 1 && (
															<span>
																{', '}
															</span>
														)}
													</Fragment>
												))
											})}
										</Paragraph>
									)}
								</>
							)}
						/>
					)}
					<HistoryList />
				</Content>
			</Layout>
		);
	}
}

export default InventoryScan;
