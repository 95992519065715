import { takeLatest } from 'redux-saga/effects';

import * as ACTION from './constants';
import * as saga from './saga';
import { CREATE_PACKAGE_BY_SCAN } from '../constants';
import { LINK_ORDER_PACKAGE } from '../LinkOrderPackage/constants';
import { UNLINK_ORDER } from './../UnlinkOrder/constants';
import { UPDATE_ALONE_PACKAGE} from './../UpdateAlonePackage/constants';
import { UPDATE_PACKAGE_PARAM } from './../UpdatePackageParams/constants';

export default function* () {
    yield takeLatest(ACTION.FETCH_BARCODE_PACKAGES.REQUEST, saga.fetchBarcodePackages);
    yield takeLatest(ACTION.FETCH_BARCODE_PACKAGES.FAILED, saga.onFetchBarcodePackagesFailed);

    yield takeLatest([
        ACTION.FETCH_BARCODE_PACKAGES.SUCCESS,
        CREATE_PACKAGE_BY_SCAN.SUCCESS,
        LINK_ORDER_PACKAGE.SUCCESS,
        UNLINK_ORDER.SUCCESS,
        UPDATE_ALONE_PACKAGE.SUCCESS,
        UPDATE_PACKAGE_PARAM.FAILED
    ], saga.onChangePackages);
}
