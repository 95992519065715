import {notification} from 'antd';
import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {router} from '../../../system/routing';

export function* fetchPackageDetail(action) {
    yield processApiRequest(ACTION.FETCH_PACKAGE_DETAIL, () => apiService.getPackage(action.payload.id), action.payload);
}

export function* onFetchPackageDetailFailed(action) {
    if (lodash.has(action, 'payload.data.agency.not_is_staff')) {

        setTimeout(() => {
            router.redirect("/403")
        }, 500);
        return yield notification.error({message: t('package:message.message_not_is_staff')});
    }
    if (action.payload) {
        return yield notification.error({message: t('package:detail.message_cant_load_package')});
    }
}
