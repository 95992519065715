/* eslint-disable */
import React from 'react';
import ListBag from './components/ListBag';
import SiderBagPack from '../../Sidebar/modules/BagPack';
import {Layout} from "antd";

class BagPackContainer extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {scroll: false};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false});
            }
        }
    };

    render() {
        const {scroll} = this.state;
        return(
            <Layout>
                <SiderBagPack scroll={scroll}/>
                <ListBag scroll={scroll}/>
            </Layout>
        )
    }
}
export default BagPackContainer;