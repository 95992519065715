import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import {ACTION_COMPLETE_PACKING} from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t, trans} from './../../../system/i18n';
import lodash from "lodash";
import {router} from '../../../system/routing';
import React from "react";

export function* completePackingBag(action) {
    yield processApiRequest(ACTION.END_PACKING_BAG, () => apiService.completePackingBag(action.payload), action.payload);
}

export function* completePackingBagFailed(action) {
    if (action.payload) {
        let error = lodash.get(action, 'payload.data');

        if (lodash.get(error, 'error.message') === "package_lost_or_inactive") {
            const packages = lodash.get(error, "error.packages", [])
            return yield notification.error({
                message: (
                    <span className="_packing-bag-failed">
                        {trans('bag:packing.errors.package_lost_or_inactive', {packages: <b>{packages.join(", ")}</b> })}
                    </span>
                )
            });
        }

        if (lodash.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
            });
        }

        if (lodash.get(error, 'packing_not_completed.limit_order_package_in_bag')) {
            return yield notification.error({
                message: (
                    <span className="_packing-bag-failed">
                        {t(`bag:packing.errors.limit_order_package_in_bag`, {
                            limit: error.packing_not_completed.limit_order_package_in_bag
                        })}
                    </span>
                )
            });
        }

        if (lodash.get(error, 'error.could_not_create_shipment_order')) {
            return yield notification.error({
                message: (
                    <span className="_packing-bag-failed">
                        {t('bag:packing.errors.could_not_create_shipment_order')}
                    </span>
                )
            });
        }

        

        return yield notification['error']({
            message: t('common:message.complete_packing_bag_fail'),
        });
    }
}

export function* completePackingBagSuccess(action) {
    let actionRequest = lodash.get(action, "request.action", ACTION_COMPLETE_PACKING.END_PACKING_CREATE_NEW_BAG);
    yield notification['success']({
        message: t('common:message.complete_packing_bag_success'),
    });
    switch (actionRequest){
        case ACTION_COMPLETE_PACKING.END_PACKING_CREATE_NEW_BAG:
            router.redirect("/bags/create");
            break;
        case ACTION_COMPLETE_PACKING.END_PACKING_GO_DETAIL:
            router.redirect("/bags/list-pack?packing_complete=0");
            break;
        default:
            router.redirect(`/bags/detail/${lodash.get(action, "payload.bag.code")}`);
    }
}