import {connect} from 'react-redux';
import ListStaffInWarehouse from "./components"
import {setMenuActive} from "../../Common/actions";
import * as detailWarehouseSelectors from "../DetailWarehouse/selectors";
import * as updateWarehouseSelectors from "../UpdateWarehouse/selectors";
import {getListWarehouseStaff} from "./selectors"
import lodash from "lodash";

const mapStateToProps = (state, props) => ({
	warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
	staffWarehouseData: getListWarehouseStaff(state),
	loading: detailWarehouseSelectors.getState(state, 'loading', false) ||
		updateWarehouseSelectors.getState(state, 'loading', false) ||
		lodash.get(state, "location.loading", false),
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu))
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListStaffInWarehouse);
