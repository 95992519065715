import React from 'react';

const Chip = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <rect className="c" width="4" height="8.667" rx="0.167" transform="translate(6 3.667)" />
      <path className="c" d="M13.333,7.333a.667.667,0,0,0,0-1.333H12.5a.167.167,0,0,1-.167-.167V5.167A.167.167,0,0,1,12.5,5h.833a.667.667,0,0,0,0-1.333H12.5a.167.167,0,0,1-.167-.167V2.333a1,1,0,0,0-1-1h-.167A.167.167,0,0,1,11,1.167v-.5a.667.667,0,1,0-1.333,0v.5a.167.167,0,0,1-.167.167H8.833a.167.167,0,0,1-.167-.167v-.5a.667.667,0,0,0-1.333,0v.5a.167.167,0,0,1-.167.167H6.5a.167.167,0,0,1-.167-.167v-.5A.667.667,0,0,0,5,.667v.5a.167.167,0,0,1-.167.167H4.667a1,1,0,0,0-1,1V3.5a.167.167,0,0,1-.167.167H2.667A.667.667,0,1,0,2.667,5H3.5a.167.167,0,0,1,.167.167v.667A.167.167,0,0,1,3.5,6H2.667a.667.667,0,1,0,0,1.333H3.5a.167.167,0,0,1,.167.167v1a.167.167,0,0,1-.167.167H2.667a.667.667,0,1,0,0,1.333H3.5a.167.167,0,0,1,.167.167v.667A.167.167,0,0,1,3.5,11H2.667a.667.667,0,0,0,0,1.333H3.5a.167.167,0,0,1,.167.167v1.167a1,1,0,0,0,1,1h.167A.167.167,0,0,1,5,14.833v.5a.667.667,0,0,0,1.333,0v-.5a.167.167,0,0,1,.167-.167h.667a.167.167,0,0,1,.167.167v.5a.667.667,0,0,0,1.333,0v-.5a.167.167,0,0,1,.167-.167H9.5a.167.167,0,0,1,.167.167v.5a.667.667,0,0,0,1.333,0v-.5a.167.167,0,0,1,.167-.167h.167a1,1,0,0,0,1-1V12.5a.167.167,0,0,1,.167-.167h.833a.667.667,0,0,0,0-1.333H12.5a.167.167,0,0,1-.167-.167v-.667A.167.167,0,0,1,12.5,10h.833a.667.667,0,0,0,0-1.333H12.5a.167.167,0,0,1-.167-.167v-1a.167.167,0,0,1,.167-.167ZM11,13a.333.333,0,0,1-.333.333H5.333A.333.333,0,0,1,5,13V3a.333.333,0,0,1,.333-.333h5.333A.333.333,0,0,1,11,3Z" />
    </g>
  </svg>
);

export default Chip;
