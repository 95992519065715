import React, { PureComponent } from 'react';
import { Affix, Input, notification } from 'antd/lib/index';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { INVENTORY_STATUS_CHECKING, INVENTORY_TYPES } from './../../constants';
import { isReceivePackageInventory } from './../../helper';
import { trans } from './../../../../system/i18n';

class FormBarcode extends PureComponent {
	state = {
		code: ''
	};

	onSubmit = event => {
		const { code } = this.state;
		const { inventory, t, scanPackage, scanTrackingBill } = this.props;

		event.preventDefault();

		if (code && inventory.status === INVENTORY_STATUS_CHECKING) {
			const action = isReceivePackageInventory(inventory) ? scanTrackingBill : scanPackage;
			const payload = {
				id: get(inventory, 'id', 0)
			};

			if (isReceivePackageInventory(inventory)) {
				payload.tracking_bill = code
			} else {
				payload.code_package = code;
			}

			action(payload);

			this.setCode();
		} else {
			notification.error({
				message: trans('inventory:message.error_scan_package_by_status_inventory', {
					code: t(`inventory:status_inventory.${inventory.status}`)
				})
			});
		}
	};

	setCode = (code = '') => {
		this.setState({
			code: (code || '').trim()
		});
	};

	render() {
		const { code } = this.state;
		const { inventory, loading, t } = this.props;
		const inventoryType = get(inventory, 'type');

		return (
			<Affix>
				<div className="a-content__top">
					<div className="a-barcode-scan__title">
						<h3 className="a-text--uppercase a-text--bold">
							{inventoryType === INVENTORY_TYPES.RECEIVED.key ? t('inventory:label.scan_receive_package_inventory') : t('inventory:label.scan_inventory')}
						</h3>
					</div>
					<div className="a-barcode-scan__top-action">
						<form onSubmit={this.onSubmit} className="a-form--loading loading-input">
							<Input
								className="a-input a-input--inventory-scan"
								disabled={loading || get(inventory, 'status') !== INVENTORY_STATUS_CHECKING}
								placeholder={t('inventory:placeholder.scan_inventory')}
								value={code}
								onChange={event => this.setCode(event.target.value)}
							/>
						</form>
					</div>
				</div>
			</Affix>
		);
	}
}

FormBarcode.defaultProps = {
	inventory: {},
	scanBarcode: () => {
	},
};

FormBarcode.propTypes = {
	scanBarcode: PropTypes.func,
	inventory: PropTypes.object
};
export default withTranslation()(FormBarcode)
