export const FETCH_LIST_LOCATION = {
    REQUEST: 'FETCH_LIST_LOCATION.REQUEST',
    SUCCESS: 'FETCH_LIST_LOCATION.SUCCESS',
    FAILED: 'FETCH_LIST_LOCATION.FAILED',
};
export const FETCH_COUNTRY = {
    REQUEST: 'FETCH_COUNTRY.REQUEST',
    SUCCESS: 'FETCH_COUNTRY.SUCCESS',
    FAILED: 'FETCH_COUNTRY.FAILED',
};
export const FETCH_PROVINCE = {
    REQUEST: 'FETCH_PROVINCE.REQUEST',
    SUCCESS: 'FETCH_PROVINCE.SUCCESS',
    FAILED: 'FETCH_PROVINCE.FAILED',
};
export const FETCH_DISTRICT = {
    REQUEST: 'FETCH_DISTRICT.REQUEST',
    SUCCESS: 'FETCH_DISTRICT.SUCCESS',
    FAILED: 'FETCH_DISTRICT.FAILED',
};
export const FETCH_WARD = {
    REQUEST: 'FETCH_WARD.REQUEST',
    SUCCESS: 'FETCH_WARD.SUCCESS',
    FAILED: 'FETCH_WARD.FAILED',
};

export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY';
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';
export const SET_CURRENT_DISTRICT = 'SET_CURRENT_DISTRICT';
export const SET_CURRENT_WARD = 'SET_CURRENT_WARD';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_PROVINCES = 'SET_PROVINCES';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_WARDS = 'SET_WARDS';
