import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {notification} from 'antd';
import {debounce, find, first, get, isEmpty} from 'lodash';
import {t} from '../../../system/i18n';
import * as actions from './actions';
import orderApi from '../../Order/apiService';
import {getInputFocus} from '../CreatePackageViaScan/selectors';
import LinkOrderPackage from './components/LinkOrderPackage';

class LinkOrderPackageContainer extends React.PureComponent {
    state = {
        searching: false,
        orderCodes: [],
        orderItem: {},
        orderCode: ''
    };

    componentWillUnmount() {
        this.props.onReset();
    }

    onSearch = (keyword) => {
        this.setState({
            orderCode: keyword,
            orderCodes: []
        });

        if (!keyword) {
            return;
        }

        this.doFindOrders(keyword);
    };

    findOrders = (keyword) => {
        this.setState({
            searching: true
        });

        orderApi.suggest({code: keyword}).then(res => {
            const orders = get(res, 'data.orders', []) || [];
            
            this.setState({
                orderCodes: orders.map(item => {
                    const agency = get(item, 'agency', {})
                    return {
                        value: item.order.id,
                        order_id: '' + item.order.id,
                        order_code: item.order.code,
                        info: item,
                        text: item.order.code + `${isEmpty(agency) ? '' : ' (' + get(agency, 'name', '') + ' )'}`,
                    }
                }),
                orderItem: orders.length ? first(orders) : {}
            });
        }).catch((error) => {
            this.setState({
                orderCodes: []
            });

            if (error.response) {
                notification.error({message: t('package:link_order.message_cant_load_orders')});
            }
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    };

    doFindOrders = debounce(this.findOrders, 250);

    onLink = (data) => {
        const {id_order} = data;
        const {orderItem, orderCodes} = this.state;
        const { onLink, onSelectOrder } = this.props;
        const orderInfo = find(orderCodes, ['order_id', id_order])
        this.setState({orderCodes: []});
        if (this.props.package.id > 0) {
            onLink(data);
        } else {
            onSelectOrder(get(orderInfo, "info", {}));
        }
    };

    handleFocus = () => {
        const { onFocus } = this.props;

        onFocus();
    };

    render() {
        const { orderCode } = this.state;

        return (
            <LinkOrderPackage
                inputFocus={this.props.inputFocus}
                inputFocusId={this.props.inputFocusId}
                orderCodes={this.state.orderCodes}
                orderSuggestCodes={this.props.orderSuggestCodes}
                package={this.props.package}
                canLink={this.props.forceCanLink ? this.props.canLink : (this.props.canLink || !!get(this.props.package, 'permissions.can_link_order'))}
                showWarning={this.props.showWarning}
                searching={this.state.searching}
                linking={this.props.linking}
                orderCode={orderCode}
                onSearch={this.onSearch}
                onLink={this.onLink}
                onFocus={this.handleFocus}
                onChange={this.props.onChangeOrder}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const packageCode = props.package.code;
    const data = find(get(state, 'package.linkOrderPackage.packages', []), {code: packageCode}) || {};
    const orderSuggestCodes = get(state, 'package.createPackageViaScan.orderSuggests', [])
    return {
        ...getInputFocus(state),
        linking: !!data.loading,
        orderSuggestCodes: orderSuggestCodes.map(item => {
            const agency = get(item, 'agency', {})
            return {
                value: item.order.id,
                order_id: '' + item.order.id,
                order_code: item.order.code,
                info: item,
                text: item.order.code + `${isEmpty(agency) ? '' : ' (' + get(agency, 'name', '') + ' )'}`,
            }
        }),
        package: props.package,
        onFocus: props.onFocus,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const packageCode = props.package.code;

    return {
        onLink: (orderCode) => dispatch(actions.linkOrderPackage(packageCode, orderCode, {
            packageId: props.package.id,
            current_page: props.package.current_page
        })),
        onReset: () => dispatch(actions.clearState(packageCode)),
    };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkOrderPackageContainer);

Container.defaultProps = {
    package: {},
    onFocus: () => {
    },
    showWarning: true,
    canLink: false,
    forceCanLink: false,
    onSelectOrder: () => {
    },
    onChangeOrder: () => {
    }
};

Container.propTypes = {
    package: PropTypes.object,
    onFocus: PropTypes.func,
};

export default Container;

