import {connect} from 'react-redux';
import ListBagPackage from './components/';

const mapStateToProps = (state, props) => ({
    ...props
});

export default connect(
    mapStateToProps
)(ListBagPackage);
