/* eslint-disable */

import React from 'react';
import FormSearch from "./FormSearch";
import ListDeliveryNoteCustomer from "./ListDeliveryNote";
import {Layout} from "antd";
const {Content} = Layout;

class DeliveryNoteCustomer extends React.PureComponent{
    render() {
        return(
            <Content className="a-content a-content--page-list">
                <FormSearch/>
                <ListDeliveryNoteCustomer/>
            </Content>
        )
    }
}
export default DeliveryNoteCustomer;