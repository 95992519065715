export const FETCH_SUGGEST_BOX_SIZES = {
    REQUEST: 'FETCH_SUGGEST_BOX_SIZES.REQUEST',
    SUCCESS: 'FETCH_SUGGEST_BOX_SIZES.SUCCESS',
    FAILED: 'FETCH_SUGGEST_BOX_SIZES.FAILED',
};

export const GET_SUGGESTS_BY_VOLUME = {
    REQUEST: 'BOX_SIZE.GET_SUGGESTS_BY_VOLUME.REQUEST',
    SUCCESS: 'BOX_SIZE.GET_SUGGESTS_BY_VOLUME.SUCCESS',
    FAILED: 'BOX_SIZE.GET_SUGGESTS_BY_VOLUME.FAILED',
};

export const CLEAR_SUGGEST_BOX_SIZES = 'BOX_SIZES.CLEAR_SUGGEST';

export const CLEAR_SUGGEST_BY_VOLUME_BOX_SIZES = 'BOX_SIZES.CLEAR_SUGGEST_BY_VOLUME_BOX_SIZES';
