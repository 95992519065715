import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {Button, notification} from "antd";
import apiService from "../../apiService";

function ChangePackageToDelivering(props) {
    const {t, bagId, fetchBag} = props;
    const [loading, setLoading] = useState(false);
    function handleChangePackageToDelivering() {
        setLoading(true);
        apiService.changePackageToDelivering(bagId).then(res => {
            notification.success({message: t("bag:message.change_package_to_delivering.success")});
            fetchBag(bagId);
        }).catch(err => {
            notification.error({message: t("bag:message.change_package_to_delivering.failed")})
        }).finally(() => {
            setLoading(false);
        })
    }
    return <Button
        icon="login"
        loading={loading}
        onClick={handleChangePackageToDelivering}
        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-5 _btn-change-package-to-delivering"
    >
        {t("bag:btn.change_package_to_delivering")}
    </Button>
}

export default withTranslation()(ChangePackageToDelivering);