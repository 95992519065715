import React, { Component } from 'react';
import { Layout, Tabs } from 'antd';

import pageService from './../../../Common/Page/pageService';

const { Content } = Layout;
const { TabPane } = Tabs;

class UploadPackageList extends Component {
    componentDidMount() {
        const { t, setMenuActive } = this.props;

        pageService.setTitle(t('common:menu.upload_package_list'));
        setMenuActive('packages/upload');
    }

    onChangeTabs = tab => {
        const { onSearch } = this.props;
        const newFilter = {};

        newFilter.tab = tab;

        onSearch(newFilter);
    };

    render() {
        const { filter, tabs } = this.props;

        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <Tabs
                        activeKey={filter.tab}
                        animated={false}
                        className="a-tabs a-tabs--container"
                        onChange={this.onChangeTabs}
                    >
                        {tabs.map(tab => (
                            <TabPane key={tab.key} tab={tab.title}>
                                {tab.component}
                            </TabPane>
                        ))}
                    </Tabs>
                </Content>
            </Layout>
        );
    }
}

export default UploadPackageList;
