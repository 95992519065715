import {takeLatest} from 'redux-saga/effects';
import {dispatch} from './../../../system/store';
import * as saga from './saga';
import * as actionLog from '../../Common/Log/actions';
import * as ACTION from './constants';
import lodash from "lodash";
import { SCAN_BARCODE } from './../DeliveryNoteBarcode/constants';

export default function* () {
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE.REQUEST, saga.fetchDeliveryNote);
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE.SUCCESS, saga.onFetchDeliveryNoteSuccess);
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE.FAILED, saga.onFetchBagDetailFailed);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE.REQUEST, saga.updateDeliveryNote);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE.SUCCESS, saga.updateDeliveryNoteSuccess);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE.FAILED, saga.updateDeliveryNoteFailed);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE.SUCCESS, function* fetchLogUpdate(action) {
        return yield dispatch(actionLog.fetchLogs( "delivery-notes", lodash.get(action,"payload.delivery_note.id"), {}));
    });
    yield takeLatest(ACTION.FETCH_DELIVERY_NOTE.SUCCESS, saga.setPageTitle);
    yield takeLatest(ACTION.UPDATE_DELIVERY_NOTE.SUCCESS, function* fetchLogUpdate(action) {
        return yield dispatch(actionLog.fetchLogs( "delivery-notes", lodash.get(action,"payload.delivery_note.id"), {}));
    });
    yield takeLatest(ACTION.GET_CUSTOMER_BAG_PACKAGES.REQUEST, saga.getCustomerBagPackages);
    yield takeLatest(ACTION.GET_CUSTOMER_DETAIL_INFO.REQUEST, saga.getCustomerDetailInfo);
    yield takeLatest(SCAN_BARCODE.SUCCESS, saga.succcessScanBarcode);
}
