import React, { useEffect, useState } from 'react';
import { Modal, Spin, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import apiService from './../../../Auth/apiService';

const { Paragraph } = Typography;

const ScanUploadDamagedImagesLinkModal = ({ packageCode, t, visible, onCancel }) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [url, setUrl] = useState('');

    const getToken = async () => {
        setLoading(true);

        try {
            const { data } = await apiService.getLimitedTimeToken();

            setToken(data);
        } catch (error) {
        }

        setLoading(false);
    };

    useEffect(() => {
        if (visible) {
            getToken();
        }
    }, [visible]);

    useEffect(() => {
        const url = `${window.location.origin}/packages/upload-damaged-images?code=${packageCode}&auth_token=${token}`;

        setUrl(url);
    }, [packageCode, token]);

    return (
        <Modal
            footer={null}
            title={t('package:upload_damaged_product_images')}
            visible={visible}
            onCancel={onCancel}
            className="checking-package__modal-upload_damage_product-img"
        >
            <div className="gflex amt-16">
                <div className="flex-shrink-0">
                    <Spin spinning={loading}>
                        <QRCode size={200} value={url} />
                    </Spin>
                </div>
                <div className="aml-16">
                    <Paragraph className="mb-0">
                        {t('package:scan_upload_damaged_images_link_hints.1')}
                    </Paragraph>
                    <Paragraph className="mb-0">
                        {t('package:scan_upload_damaged_images_link_hints.2')}
                    </Paragraph>
                </div>
            </div>
        </Modal>
    );
};

export default withTranslation()(ScanUploadDamagedImagesLinkModal);
