import React from 'react';
import {Layout} from "antd";
import MenuLeft from "../modules/Common/MenuLeft/MenuLeftContainer";
import MenuConfig from "../modules/Common/MenuConfig/MenuConfigContainer";
import Header from '../modules/Common/components/Header';
import PrintStampContainer from "../modules/Common/PrintStamp/PrintStampContainer";
import pageService from '../modules/Common/Page/pageService';
import {withTranslation} from 'react-i18next';
import {trans} from '../system/i18n';

const Content = Layout.Content;

class ConfigLayout extends React.Component {
    componentDidMount(){
        pageService.setTitle(trans("common:title.config"));
    }
    render(){
        return (
            <div>
                <div id="page-content">
                    <Layout>
                        <MenuLeft/>
                        <Layout className="a-main _a-main">
                            <Header/>
                            <Content className={'a-flex aflex-direction-row a-flex-1 min-height-not-header'}>
                                <MenuConfig/>
                                {this.props.children}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <div id="print-content" className="print-container">
                    <PrintStampContainer/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ConfigLayout)
