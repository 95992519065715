import React from "react"
import {ReactComponent as IconSoundOn} from "../../resources/images/sound-on.svg";
import {ReactComponent as IconSoundOff} from "../../resources/images/sound-off.svg";
import {useDispatch, useSelector} from 'react-redux';
import {changeStatusSound as change} from "./actions"
import * as lodash from "lodash";
function Sound() {
	const dispatch = useDispatch();
	const props = useSelector(state => {
		return {
			sound: state.sound
		}
	});

	function changeCurrentStatusSound(status) {
		dispatch(change({status}));
	}
	return (
		<div className={`icon-sound`}>{lodash.get(props, 'sound.status', true) ?
			<IconSoundOn onClick={() => changeCurrentStatusSound(false)}/> :
			<IconSoundOff onClick={() => changeCurrentStatusSound(true)}/>}</div>)
}

export default Sound
