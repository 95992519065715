import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';
import {EXPORT_BAGS_CSV} from './constants';
import {EXPORT_BAG} from "../BagDetail/constants";

let bags = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_BAGS.SUCCESS:
            return action.payload.bags.map(item => ({...item.bag, ...lodash.omit(item, 'bag')}));
        case ACTION.RESET_LIST_BAG:
            return [];
        default:
            return state;
    }
};

let pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_BAGS.SUCCESS:
            return action.payload.pagination;
        case ACTION.RESET_LIST_BAG:
            return {};
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.SWITCH_TAB:
        case ACTION.FETCH_BAGS.REQUEST:
        case EXPORT_BAG.REQUEST:
        case EXPORT_BAGS_CSV.REQUEST:
            return true;
        case ACTION.RESET_LIST_BAG:
        case ACTION.FETCH_BAGS.SUCCESS:
        case ACTION.EXPORT_BAGS_CSV.SUCCESS:
        case ACTION.EXPORT_BAGS_CSV.FAILED:
        case ACTION.FETCH_BAGS.FAILED:
        case EXPORT_BAG.SUCCESS:
        case EXPORT_BAG.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    bags,
    pagination,
    loading,
});
