import React, {PureComponent} from 'react';
import {
    Row,
    Col,
} from 'antd/lib/index';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {currentFormatter} from "../../../Common/services/format/index";

class CustomerInfo extends PureComponent {

    render() {
        let {t, customer, customer_finance} = this.props;
        return (
            <Row gutter={{lg: 24, xl: 24}} className="info-customer-view pl-5 pr-5 ">
                <Col lg={{span: 7}} xl={{span: 7}} xxl={{span: 7}}>
                    <div className="info">
                        <div className="avatar">
                            <img
                                src={customer.avatar || ""} alt={"avatar"} onError={(e)=>{e.target.onerror = null; e.target.src="https://png.pngtree.com/svg/20161027/631929649c.svg"}}/>
                        </div>
                        <div className="info-detail">
                            <p className="name">{customer.full_name || "--"}</p>
                            <span className="phonenumber">{customer.phone || "--"}</span>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 17}} xl={{span: 17}} xxl={{span: 17}}>
                    <Row gutter={{lg: 16, xl: 32}}>
                        <Col lg={{span: 6}} xl={{span: 7}} xxl={{span: 8}}
                             className="info--group mb-0">
                            <label className="info--label a-text--regular">
                                {t("delivery_note:label.amount_remaining")}</label>
                            <span
                                className="info--value a-text--blue a-text--bold">
                                {customer_finance ? currentFormatter.toLocaleStringCurrencyVND(customer_finance.amount_remaining) : t('delivery_note:message.can_not_get_customer_finance')}
                            </span>
                        </Col>
                    </Row>

                </Col>

            </Row>
        );
    }
}

CustomerInfo.defaultProps = {
    customerData: {
        customer: {},
        customer_finance: {},
    }
};

CustomerInfo.propTypes = {
    customerData: PropTypes.object,

};

export default withTranslation()(CustomerInfo);

