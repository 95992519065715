import React from "react";
import lodash from "lodash";
import FormSearch from "./FormSearch";
import ListPackage from "./ListPackage";


class ListPackageStatusDelete extends React.Component {
    render() {
        return (
            <div>
                <FormSearch
                    input={this.props.filter}
                    inputVersion={lodash.toInteger(this.props.filter.i)}
                    loading={this.props.loading}
                    onSearch={this.props.onSearch}
                />
                <ListPackage {...this.props}/>
            </div>
        );
    }

}

export default ListPackageStatusDelete
