import {connect} from 'react-redux';
import ChangePackageTransportStatus from './components';
import * as actions from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    packageData: props.packageData,
    isModalProcessing: lodash.get(state, 'package.changePackageTransportStatus.isProcessing', false),
    loading: lodash.get(state, 'package.changePackageTransportStatus.loading', false),
});

const mapDispatchToProps = (dispatch) => ({
    beginChangePackageTransportStatus: () => {
        dispatch(actions.beginChangePackageTransportStatus());
    },
    endChangePackageTransportStatus: () => {
        dispatch(actions.endChangePackageTransportStatus());
    },
    onChangePackageTransportStatus: (code) => {
        dispatch(actions.changePackageTransportStatus(code));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePackageTransportStatus);
