import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {
    Input,
    Row,
    Col,
    Form,
} from 'antd';
import lodash from 'lodash';

import { isCreateAlonePackageAndLinkOrder } from './../../../helper';
import { getVar } from './../../../../../system/support/helpers';

import LocationPackageFormContainer from './../../../../Location/LocationPackageForm/LocationPackageFormContainer';
import SelectCustomerContainer from './../../../../Customer/SelectCustomer/SelectCustomerContainer';

const { TextArea } = Input;

class CustomerInformation extends React.PureComponent {
    state = {
        identity: undefined,
        isExtend: true,
        id_package: 0,
        customer_receive: '',
        customer_phone_destination: '',
        customer_address_destination: '',
        id_customer: 0,
        customer_note: '',
        id_warehouse_destination: '',
    };

    static getDerivedStateFromProps(props, state) {
        const { packageData } = props;
        let nextState = {...state};

        // Trường hợp tạo kiện độc lập mà muốn khớp đơn
        if (isCreateAlonePackageAndLinkOrder(packageData) && lodash.isNil(state.order_code)) {
            nextState = {
                ...nextState,
                ...getVar(props, 'packageData')
            };
        }

        return nextState;
    }

    changeTextValue = event => {
        const { submitWhenChangeTextValue, onSubmit } = this.props;
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });

        if (submitWhenChangeTextValue) {
            onSubmit({
                ...this.state,
                [name]: value
            });
        }

        this.onResetError(name);
    };

    changeSelectValue(name, value) {
        const { updateInfoWhenCustomerChange, onSubmit } = this.props;

        let newState = {};

        if (name === 'id_customer') {
            if (updateInfoWhenCustomerChange && value) {
                newState = {
                    id_customer: getVar(value, 'customer.id'),
                    customer_receive: getVar(value, 'customer.full_name'),
                    customer_address_destination: getVar(value, 'customer.address'),
                    customer_phone_destination: getVar(value, 'customer.phone')
                };
            } else {
                newState.id_customer = value;
            }
        } else {
            newState[name] = value;
        }

        this.setState(newState, () => {
            onSubmit(this.state);
            this.onResetError(name);    
        });
    }

    onResetError(name) {
        let {errors} = this.props;
        if (errors && errors.hasOwnProperty(name)) {
            this.props.resetError(name);
        }
    }

    onChangeChildState(state) {
        const { onSubmit } = this.props;

        this.setState(state, () => {
            onSubmit(this.state);
        });
    }

    onChangeExtend() {
        this.setState({isExtend: !this.state.isExtend});
    }

    handlePressEnter = () => {
        const { submitWhenPressEnter, onSubmit } = this.props;

        if (submitWhenPressEnter) {
            onSubmit({
                ...this.state
            });
        }
    };

    getWarningParams = param => {
        const { id_customer } = this.state;
        const { errors, packageData, t } = this.props;
        const warningContents = getVar(packageData, 'warning_contents', []);

        switch (param) {
            case 'id_customer': {
                if (errors.id_customer || (!id_customer && lodash.includes(getVar(packageData, 'required_contents'), 'id_customer'))) {
                    return {
                        status: 'error',
                        message: errors.id_customer || t('validation:required', {
                            attribute: t('customer')
                        })
                    };
                }

                if (warningContents.includes('id_customer') && !id_customer) {
                    return {
                        status: 'warning',
                        message: t('package:message.id_customer_warning')
                    };
                }

                return {
                    status: null
                };
            }
            default: {
                return {
                    status: null
                };
            }
        }
    };

    handleChangePackageData = packageData => {
        this.setState({
            identity: getVar(packageData, 'identity'),
            id_package: getVar(packageData, 'id'),
            customer_receive: getVar(packageData, 'customer_receive'),
            customer_phone_destination: getVar(packageData, 'customer_phone_destination'),
            customer_address_destination: getVar(packageData, 'customer_address_destination'),
            id_warehouse_destination: getVar(packageData, 'id_warehouse_destination'),
            id_customer: getVar(packageData, 'id_customer'),
            customer_note: getVar(packageData, 'customer_note')
        });
    };

    componentDidUpdate(prevProps) {
		const { packageData } = this.props;

		if (getVar(packageData, 'id') !== getVar(prevProps.packageData, 'id') || getVar(packageData, 'identity') !== getVar(prevProps.packageData, 'identity')) {
			this.handleChangePackageData(packageData);
		}
	}

    componentDidMount() {
        const { packageData } = this.props;

        this.handleChangePackageData(packageData);
    }

    render() {
        const { errors, loading, packageData, submitWhenDidMount, t, updateInfoWhenCustomerChange } = this.props;
        const { isExtend } = this.state;
        const isAlonePackage = packageData.type === "ALONE" && packageData.barcode === '';
        const required = lodash.isNil(this.props.required) ? !(packageData.id === 0 || lodash.isNil(packageData.id) || isAlonePackage) : this.props.required;
        const disableAllInput = isCreateAlonePackageAndLinkOrder(packageData);
        const requiredIdCustomer = required || lodash.includes(getVar(packageData, 'required_contents'), 'id_customer');

        return (
            <Row className="a-block-info infocustomer pb-0" gutter={{lg: 24, xl: 48}} type="flex">
                <Col lg={{span: 24}} className="info--title iscollapse amt-16">
                    <div className="title ">
                        <h4>{t("package:label.customer_information")}</h4>
                    </div>
                </Col>
                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 4}} className="info--group">
                    <Form.Item
                        className="a-form__item"
                        disabled={loading}
                        help={this.getWarningParams('id_customer')['message']}
                        label={t('customer')}
                        labelCol={{span: 24}}
                        required={requiredIdCustomer}
                        validateStatus={this.getWarningParams('id_customer')['status']}
                    >
                        <SelectCustomerContainer
                            allowClear={!requiredIdCustomer}
                            className="a-select--w-100 _customer_send package-customer_send a-text--fz-18"
                            disabled={disableAllInput}
                            dispatchObject={updateInfoWhenCustomerChange}
                            emptyOption={false}
                            name="id_customer"
                            showSearch
                            value={this.state.id_customer}
                            onChange={this.changeSelectValue.bind(this, 'id_customer')}
                            selectProps={{tabIndex: 6}}
                        />
                    </Form.Item>
                </Col>
                <Col lg={{span: 8, offset: 0}} xl={{span: 6}} xxl={{span: 4, offset: 1}}
                        className="info--group">
                    <Form.Item className="a-form__item " label={t("package:label.customer_receive")}
                                labelCol={{span: 24}}
                                help={errors.customer_receive}
                                validateStatus={errors.customer_receive ? 'error' : null}
                    >
                        <Input
                            className="_customer_receive"
                            disabled={disableAllInput || loading}
                            placeholder={t("package:placeholder.customer_receive")}
                            value={this.state.customer_receive}
                            name={"customer_receive"}
                            onChange={this.changeTextValue.bind(this)}
                            onPressEnter={this.handlePressEnter}
                        />
                    </Form.Item>
                </Col>
                <Col lg={{span: 8, offset: 0}} xl={{span: 6}} xxl={{span: 4, offset: 1}}
                        className="info--group">
                    <Form.Item
                        required={required}
                        className="a-form__item  package-customer_phone_destination"
                        label={t("package:label.customer_phone_destination")}
                        labelCol={{span: 24}}
                        help={errors.customer_phone_destination}
                        validateStatus={errors.customer_phone_destination ? 'error' : null}
                    >
                        <Input
                            className="_customer_phone_destination"
                            disabled={disableAllInput || loading}
                            placeholder={t("package:placeholder.customer_phone_destination")}
                            value={this.state.customer_phone_destination}
                            name={"customer_phone_destination"}
                            onChange={this.changeTextValue.bind(this)}
                            onPressEnter={this.handlePressEnter}
                        />
                    </Form.Item>
                </Col>
                {isExtend ? (
                    <>
                        <LocationPackageFormContainer
                            errors={errors}
                            packageData={packageData}
                            required={required}
                            resetError={this.onResetError.bind(this)}
                            submitWhenDidMount={submitWhenDidMount}
                            onSubmit={this.onChangeChildState.bind(this)}
                            disabled={disableAllInput}
                        />
                        <Col className="info--group" lg={{span: 12, offset: 0}} xl={{span: 12}} xxl={{span: 9, offset: 1}}>
                            <Form.Item
                                className="a-form__item"
                                help={errors.customer_address_destination}
                                label={t("package:label.customer_address_destination")}
                                labelCol={{span: 24}}
                                required={required}
                                validateStatus={errors.customer_address_destination ? 'error' : null}
                            >
                                    <TextArea
                                        disabled={disableAllInput || loading}
                                        className="a-input--textarea _customer_address_destination"
                                        autosize={{minRows: 1, maxRows: 3}}
                                        allowClear={true}
                                        spellCheck={false}
                                        name="customer_address_destination"
                                        placeholder={t("package:label.customer_address_destination")}
                                        value={this.state.customer_address_destination}
                                        onChange={this.changeTextValue.bind(this)}
                                        onPressEnter={this.handlePressEnter}
                                    />
                            </Form.Item>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xxl={{span: 14}}>
                            <Form.Item
                                className="a-form__item package-customer_note"
                                label={t('package:label.customer_note')}
                                labelCol={{span: 24}}
                                help={errors.customer_note}
                                validateStatus={errors.customer_note ? 'error' : null}
                            >
                                <TextArea
                                    disabled={disableAllInput || loading}
                                    className="a-input--textarea _customer_note"
                                    autosize={{minRows: 1, maxRows: 3}}
                                    allowClear={true}
                                    spellCheck={false}
                                    placeholder={t('package:label.customer_note')}
                                    value={this.state.customer_note}
                                    name="customer_note"
                                    onChange={this.changeTextValue.bind(this)}
                                    onPressEnter={this.handlePressEnter}
                                />
                            </Form.Item>
                        </Col>
                    </>
                ) : null}
            </Row>
        );
    }
}

CustomerInformation.defaultProps = {
    packageData: {},
    loading: false,
    errors: {},
    submitWhenDidMount: true,
    submitWhenChangeTextValue: true,
    updateInfoWhenCustomerChange: false
};

CustomerInformation.propTypes = {
    packageData: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    submitWhenDidMount: PropTypes.bool,
    submitWhenChangeTextValue: PropTypes.bool,
    updateInfoWhenCustomerChange: PropTypes.bool
};

export default Form.create({})(withTranslation()(CustomerInformation));
