import React, { Component } from 'react';
import { Button, Form, Icon, Input, Modal, Popover, Table } from 'antd';
import clsx from 'clsx';
import { filter, find, get, map } from 'lodash';

import { INVENTORY_STATUS_PROCESSING } from './../../../constants';
import { dateFormatter } from './../../../../Common/services/format';
import { t } from '../../../../../system/i18n';

import ProcessedReasonModal from './ProcessedReasonModal';
import Link from '../../../../../system/routing/Link';

const { TextArea } = Input;

class Remainder extends Component {
    state = {
        selectedRowKeys: [],
        inputWarehouseItems: [],
        listPackageEdit: [],
        processedReasonModalVisible: false
    };

    changePackageStatus = data => {
        const { inventory, updateInventoryPackageStatus } = this.props;
        let isProcessing = false;
        let inputWarehouse = false;

        if (data.loading || (data.current_is_resolved && !data.current_reason) || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return false;
        }

        if (data.action === 'processed') {
            isProcessing = true;
        }
        if (data.action === 'input_warehouse') {
            inputWarehouse = true;
        }

        if (inputWarehouse || isProcessing) {
            const formattedData = {
                id: data.item.id,
                is_resolved: true,
                reason: data.reason
            };

            if (data.action === 'input_warehouse') {
                delete formattedData.reason;
            }

            updateInventoryPackageStatus(formattedData);
        }
    };


    onEdit = (id, status) => {
        const { errors, resetError } = this.props;

        if (status) {
            return;
        }

        const listPackageEdit = [].concat(this.state.listPackageEdit);
        listPackageEdit.push({
            id, reason: ''
        });

        this.setState({
            listPackageEdit
        });

        if (errors.reason) {
            resetError('reason');
        }
    };

    onCancelEdit = id => {
        const { errors, resetError } = this.props;
        const listPackageEdit = filter(this.state.listPackageEdit, item => item.id !== id);

        this.setState({
            listPackageEdit
        });

        if (errors.reason) {
            resetError('reason');
        }
    };

    onChangeProcessedReason = (id, e) => {
        if (id) {
            const listPackageEdit = map(this.state.listPackageEdit, item => ({
                ...item,
                reason: item.id === id ? e.target.value : item.reason
            }));

            this.setState({
                listPackageEdit
            });
        } else {
            this.setState({
                processedReason: e.target.value
            });
        }
    };

    renderReason = reason => (
        <div>
            <p className="_reason">{reason}</p>
        </div>
    );

    handleChangeSelectedRowKeys = keys => {
        this.setState({
            selectedRowKeys: keys
        });
    };

    handleResolveInventoryPackagesButtonClick = type => {
        const { selectedRowKeys } = this.state;
        const { inventory, resolvingRemainderInventoryPackages, warehouse, resolveRemainderInventoryPackages } = this.props;

        if (resolvingRemainderInventoryPackages || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return;
        }

        if (type === 'processed') {
            this.setState({
                processedReasonModalVisible: true
            });
        } else {
            const modal = Modal.confirm({
                cancelText: t('btn.cancel'),
                okText: t('btn.ok'),
                title: t('inventory:input_warehouse_confirm', {
                    type: t('inventory:types.package'),
                    warehouse: warehouse.code
                }),
                onOk: () => {
                    modal.destroy();
                    resolveRemainderInventoryPackages({
                        ids: selectedRowKeys,
                        is_resolved: true
                    });
                }
            });
        }
    };

    handleCancelProcessedReasonModal = () => {
        this.setState({
            processedReasonModalVisible: false
        });
    };

    handleSubmitProcessedReason = reason => {
        const { selectedRowKeys } = this.state;
        const { resolvingRemainderInventoryPackages, resolveRemainderInventoryPackages } = this.props;

        this.setState({
            processedReasonModalVisible: false
        });

        if (resolvingRemainderInventoryPackages) {
            return;
        }

        resolveRemainderInventoryPackages({
            ids: selectedRowKeys,
            is_resolved: true,
            reason
        });
    };

    render() {
        const { processedReasonModalVisible, selectedRowKeys } = this.state;
        const { errors, inventory, loading, remainders, resolvingRemainderInventoryPackages } = this.props;
        const columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            key: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:package_code'),
            dataIndex: 'inventory_package.code_package',
            key: 'code_package',
            render: text => (
                <Link
                    className="a-text--nowrap"
                    isNewTab
                    params={{id: text}}
                    to="packages.detail"
                >
                    {text}
                </Link>
            )
        }, {
            title: t('warehouse_status'),
            dataIndex: 'package.status_warehouse',
            key: 'warehouse_status',
            render: text => text ? t(`warehouse_statuses.${text}`) : '--'
        }, {
            title: t('current_warehouse'),
            dataIndex: 'current_warehouse.code',
            key: 'current_warehouse'
        }, {
            title: t('inventory:transporting_at'),
            dataIndex: 'package.transporting_at',
            key: 'transporting_at',
            render: text => text ? dateFormatter.full(text) : '--'
        }, {
            title: (
                <Button
                    className="_btn-processes a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryPackagesButtonClick.bind(this, 'processed')}
                >
                    <Icon type={resolvingRemainderInventoryPackages ? 'loading' : 'check'} />
                    <span className="ml-2">{t('inventory:processed')}</span>
                </Button>
            ),
            key: 'processed',
            render: (text, record) => {
                const { inventory } = this.props;
                const isResolved = get(record, 'inventory_package.is_resolved');
                const id = get(record, 'inventory_package.id');
                const onEdit = find(this.state.listPackageEdit, item => item.id === id) || {};
                const processed = get(record, 'inventory_package.reason', '') || '';
                const isOpenEdit = Object.keys(onEdit).length > 0 && processed === '' && !isResolved;
                const canUpdate = inventory.status === INVENTORY_STATUS_PROCESSING;

                const data = {
                    item: record.inventory_package,
                    loading: record.loading,
                    current_is_resolved: isResolved,
                    reason: onEdit.reason || '',
                    current_reason: get(record, 'inventory_package.reason') || '',
                };

                return !isOpenEdit ? (
                    <div className="group-action-cross-check">
                        {processed ? (
                            <Popover content={this.renderReason(processed)} title={t('inventory:reason')}>
                                <Icon
                                    className={clsx('action', {
                                        active: isResolved && processed,
                                        'disable-active': !canUpdate && isResolved && processed,
                                        'disable-unactive': !canUpdate && (!isResolved || !processed)
                                    })}
                                    type={record.loading ? 'loading' : 'check'}
                                    onClick={this.onEdit.bind(this, id, isResolved)}
                                />
                            </Popover>
                        ) : (
                            <Icon
                                className={clsx('action', {
                                    active: isResolved && processed,
                                    'disable-active': !canUpdate && isResolved && processed,
                                    'disable-unactive': !canUpdate && (!isResolved || !processed)
                                })}
                                type={record.loading ? 'loading' : 'check'}
                                onClick={this.onEdit.bind(this, id, isResolved)}
                            />
                        )}
                    </div>
                ) : (
                    <div className="a-inline-edit">
                        <label className="a-inline-edit--label">
                            {t('inventory:reason')}
                            <>
                                <Icon
                                    className="icon-inline"
                                    type="close"
                                    onClick={this.onCancelEdit.bind(this, id)}
                                />
                                <Icon
                                    className="icon-inline"
                                    type={record.loading ? 'loading' : 'check'}
                                    onClick={this.changePackageStatus.bind(this, {
                                        ...data,
                                        reason: onEdit.reason,
                                        action: 'processed'
                                    })}
                                />
                            </>
                        </label>
                        <div className="a-inline-edit--input">
                            <Form.Item
                                className="a-form__item"
                                help={get(errors, 'reason.id') === id ? get(errors, 'reason.text') : ''}
                                labelCol={{span: 24}}
                                required
                                validateStatus={get(errors, 'reason.id') === id ? 'error' : null}
                            >
                                <TextArea
                                    className="a-inline-edit--input"
                                    value={onEdit.reason}
                                    onChange={this.onChangeProcessedReason.bind(this, id)}
                                />
                            </Form.Item>
                        </div>
                    </div>
                );
            }
        }, {
            title: (
                <Button
                    className="_btn-processes a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryPackagesButtonClick.bind(this, 'input_warehouse')}
                >
                    <Icon type={resolvingRemainderInventoryPackages ? 'loading' : 'select'} />
                    <span className="ml-2">{t('inventory:input_warehouse')}</span>
                </Button>
            ),
            key: 'input_warehouse',
            render: (text, record) => {
                const { inventory } = this.props;
                const isResolved = get(record, 'inventory_package.is_resolved');
                const id = get(record, 'inventory_package.id');
                const onEdit = find(this.state.listPackageEdit, item => item.id === id) || {};
                const processed = get(record, 'inventory_package.reason', '') || '';
                const canUpdate = inventory.status === INVENTORY_STATUS_PROCESSING;

                const data = {
                    item: record.inventory_package,
                    loading: record.loading,
                    current_is_resolved: isResolved,
                    reason: onEdit.reason || '',
                    current_reason: get(record, 'inventory_package.reason') || '',
                };

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action action', {
                                active: isResolved && !processed,
                                'disable-active': !canUpdate && isResolved && !processed,
                                'disable-unactive': !canUpdate && (!isResolved || processed)
                            })}
                            type={record.loading ? 'loading' : 'select'}
                            onClick={this.changePackageStatus.bind(this, {
                                ...data,
                                action: 'input_warehouse'
                            })}
                        />
                    </div>
                );
            }
        }];

        return (
            <>
                <Table
                    className="a-table--antd a-table--antd-scroll-x"
                    columns={columns}
                    loading={loading}
                    dataSource={remainders}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: this.handleChangeSelectedRowKeys
                    }}
                    scroll={{ x: 'fit-content' }}
                    rowKey={row => get(row, 'inventory_package.id')}
                />

                <ProcessedReasonModal
                    visible={processedReasonModalVisible}
                    onCancel={this.handleCancelProcessedReasonModal}
                    onOk={this.handleSubmitProcessedReason}
                />
            </>
        );
    }
}

export default Remainder;
