import React, { createRef, PureComponent } from 'react';
import { Avatar, Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import UpdateReceivedQuantity from './../UpdateReceivedQuantityContainer';
import { currentFormatter } from './../../../Common/services/format';
import { getVar } from './../../../../system/support/helpers';

import Logs from './Logs';

class ProductInfo extends PureComponent {
    state = {
        updateReceivedQuantityInputRef: createRef()
    };

    focusReceivedQuantityInput = () => {
        const { updateReceivedQuantityInputRef } = this.state;

        updateReceivedQuantityInputRef.current && updateReceivedQuantityInputRef.current.focus();
    };

    componentDidUpdate(prevProps) {
        const { focus } = this.props;

        if (focus && !prevProps.focus) {
            this.focusReceivedQuantityInput();
        }
    }

    componentDidMount() {
        const { focus } = this.props;

        if (focus) {
            this.focusReceivedQuantityInput();
        }
    }

    render() {
        const { updateReceivedQuantityInputRef } = this.state;
        const { t, product = {}, successUpdateReceivedQuantitiesCount, updatingReceivedQuantity, onReceiveAll } = this.props;

        return (
            <Row gutter={32} type={"flex"}
                 className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                <div className="a-item-package__top">
                    <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 8}} className="pl-0">
                        <div className="gflex">
                            <div className="a-item-package-checking__avatar">
                            <Popover 
                                title={product.original_name}
                                trigger="hover"
                                placement="right"
                                content={
                                    <img src={product.product_image} alt='Product image'/>
                                } 
                            >
                                <Avatar size={140} shape="square" icon="user" src={product.product_image} />
                            </Popover>
                                
                                <span>{product.code_item}</span>
                            </div>
                            <div className="a-item-package__info">
                                <a
                                    className="a-item-package--link"
                                    href={product.url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {product.original_name}
                                </a>
                                <span>{lodash.map(product.variant_properties || [], 'originalValue').join(' / ') || '--'}</span>
                            </div>
                        </div>

                    </Col>
                    <Col lg={{span: 6}} xl={{span: 5}} xxl={{span: 4}}
                         className="info--group amb-14">
                        <label className="info--label a-text--nowrap">{t('package:checking.unit_price')} (VND)</label>
                        <p className="a-text--fz-18 a-text--bold mb-0 mt-2 item-package__info-unit_price">{currentFormatter.toLocaleStringCurrency(getVar(product, 'unit_price', 0))}</p>
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 4}}
                         className="info--group amb-14">
                        <label className="info--label a-text--nowrap">{t('package:checking.purchased_quantity')}</label>
                        <p className="a-text--fz-18 a-text--bold mb-0 mt-2 item-package__info-purchased_quantity">{getVar(product, 'purchase_quantity', '--')}</p>
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                         className="info--group amb-14 pr-0">
                        <label className="info--label">{t('package:checking.order_over_package_received_quantity')}</label>
                        <p className="a-text--fz-18 a-text--bold mb-0 mt-2 item-package__info-package_received_quantity">
                            <span className={clsx('item-package__info-order_received_quantity',{
                                'a-text--red': getVar(product, 'order_received_quantity', 0) > getVar(product, 'purchase_quantity', 0)
                            })}>{getVar(product, 'order_received_quantity', '--')}</span>/{getVar(product, 'package_received_quantity', '--')}
                        </p>
                    </Col>
                    <Col lg={{span: 8}} xl={{span: 5}} xxl={{span: 4}}
                         className="info--group mb-0 pr-0">
                        <label className="info--label">{t('package:checking.package_quantity')}</label>
                        <UpdateReceivedQuantity
                            packageId={this.props.packageId}
                            product={this.props.product}
                            reupdateQuantity={successUpdateReceivedQuantitiesCount}
                            inputRef={updateReceivedQuantityInputRef}
                            loading={updatingReceivedQuantity}
                            onSubmit={onReceiveAll}
                        />
                    </Col>
                </div>
                <div className="a-item-package__bottom gflex">
                    <Col className="info--group pl-0 mb-0 border-right" lg={{span: 10}}>
                        <Logs logs={getVar(product, 'logs', [])} />
                    </Col>
                    <Col className="info--group pr-0 mb-0" lg={{span: 14}}>
                        <Row>
                            <Col className="info--group">
                                <label className="info--label">{t('package:checking.customer_note')}:</label>
                                <span className="info--value--small item-package__info-customer_note">{getVar(product, 'customer_note', '--')}</span>
                            </Col>
                            <Col className="info--group mb-0">
                                <label className="info--label">{t('package:checking.staff_note')}:</label>
                                <span
                                    className="info--value--small item-package__info-staff_note">{getVar(product, 'staff_note', '--')}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Row>
        );
    }
}

ProductInfo.defaultProps = {
    packageId: null,
    product: {},
};

ProductInfo.propTypes = {
    packageId: PropTypes.number,
    product: PropTypes.object,
};

export default withTranslation()(ProductInfo);
