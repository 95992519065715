import { Form, Icon, Layout, Switch } from 'antd'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from '../../../system/routing'
import { setMenuActive } from '../../Common/actions'
import CreateForm from './CreateForm'

const mapStateToProps = (state, props) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

const Content = Layout.Content

const TemplateLastmileCreate = ({ t }) => {

    const [active, setActive] = useState(false)


    return (
        <Content className={'aml-16-lg aml-24 amr-24 amr-16-lg a-content-config '}>
            <div className="a-breadcrumb">
                <Link
                    to="config.template-bags-lastmile.list"
                    className={'a-text--gray _btn-back'}>
                    <Icon
                        type="arrow-left"
                        className={'mr-2 '}
                    />{' '}
                    {t('template_bag:back_to_list')}
                </Link>
            </div>
            <div className="a-block-config amt-16">
                <div className="a-block-config__title gflex gjustify-space-between">
                    <h3 className="title">{t('template_bag:info')}</h3>
                    <div>
                        <label className={'a-text--fz-12 a-text--gray mr-2'}>{t('template_bag:status')}</label>
                        <Switch
                            checked={active}
                            size="small"
                            className={'_switch-active'}
                            onChange={(checked)=>setActive(checked)}
                        />
                    </div>
                </div>
                <div className="a-block-config__body">
                    <CreateForm
                        active={active}
                    />
                </div>
            </div>
        </Content>
    )
}
export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(TemplateLastmileCreate)
)
