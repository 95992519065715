import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchLocation = createAction(ACTION.FETCH_LIST_LOCATION.REQUEST, (params) => ({...params}));
export const fetchCountry = createAction(ACTION.FETCH_COUNTRY.REQUEST, (params) => ({...params}));
export const fetchProvince = createAction(ACTION.FETCH_PROVINCE.REQUEST, (params) => ({...params}));
export const fetchDistrict= createAction(ACTION.FETCH_DISTRICT.REQUEST, (params) => ({...params}));
export const fetchWard = createAction(ACTION.FETCH_WARD.REQUEST, (params) => ({...params}));
export const setCurrentCountry = createAction(ACTION.SET_CURRENT_COUNTRY, (idCountry) => ({idCountry: idCountry}));
export const setCurrentWard = createAction(ACTION.SET_CURRENT_WARD, (idWard) => ({idWard: idWard}));
export const setCurrentCity = createAction(ACTION.SET_CURRENT_CITY, (idCity) => ({idCity: idCity}));
export const setCurrentDistrict = createAction(ACTION.SET_CURRENT_DISTRICT, (idDistrict) => ({idDistrict: idDistrict}));

export const setCountries = createAction(ACTION.SET_COUNTRIES);
export const setProvinces = createAction(ACTION.SET_PROVINCES);
export const setDistricts = createAction(ACTION.SET_DISTRICTS);
export const setWards = createAction(ACTION.SET_WARDS);
