import React, { useState } from 'react'
import { Icon, Modal, notification, Upload } from 'antd'
import { get, last } from 'lodash'
import { withTranslation } from 'react-i18next'

import apiService from '../../apiService'

const PictureWall = ({ orderId, t, isTerminalComment }) => {
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState()
    const [previewVisible, setPreviewVisible] = useState(false)
    const maximumImages = 5

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    )

    const handleCancel = () => {
        setPreviewVisible(false)
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    }

    const updateFileList = fileList => {
        setFileList(fileList)
    }

    const handleChange = data => {
        let newFileList = data.fileList.slice()
        if (data.file.status === 'uploading' && data.fileList.length - 1 >= maximumImages) {
            const index = newFileList.findIndex(file => file.uid === data.file.uid)

            if (index !== -1) {
                newFileList[index].status = 'error'
            }
        }

        if (data.file.status === 'done') {
            const index = newFileList.findIndex(file => file.uid === data.file.uid)

            if (index !== -1) {
                newFileList[index].url = get(data, 'file.response.url')
            }
        }

        if (data.file.status !== 'removed') {
            updateFileList(newFileList)
        }
    }

    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    return (
        <div className="clearfix">
            <Upload
                accept="image/*"
                className="width-100-pc"
                fileList={fileList}
                customRequest={async ({ onSuccess, onError, file }) => {
                    try {
                        const formData = new FormData()
                        formData.append('files[]', file)

                        if (fileList.length >= maximumImages) {
                            onError()
                        } else {
                            const { data } = await isTerminalComment
                                ? apiService.uploadInternalCommentImages(orderId, formData)
                                : apiService.uploadCommentImages(orderId, formData)
                            onSuccess({
                                ...data,
                                url: last(get(data, 'package_detail.damaged_images')),
                            })
                        }
                    } catch (error) {
                        if (get(error, 'response.status') === 401) {
                            notification.error({
                                message: t('auth:message.token_expired'),
                            })
                        } else {
                            onError(error)
                        }
                    }
                }}
                showUploadList={{ showPreviewIcon: true, showDownloadIcon: false, showRemoveIcon: false }}
                listType="picture-card"
                multiple
                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={false}>
                {fileList.length < maximumImages && uploadButton}
            </Upload>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                />
            </Modal>
        </div>
    )
}

export default withTranslation()(PictureWall)
