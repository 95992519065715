import lodash from 'lodash';
import {createSelector} from 'reselect';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'service' + (param ? '.'+param : ''), defaultValue);

/**
 * Lấy danh sách consign service đang active và có thể show
 */
export const getActiveConsignServiceGroups = createSelector(
    state => getState(state, 'consignServiceGroups', []),
    serviceGroups => serviceGroups
        .filter(serviceGroup => serviceGroup.active)
        .map(serviceGroup => ({
            ...serviceGroup,
            services: serviceGroup.services.filter(service => service.active && service.show),
        }))
);

export const getActiveConsignServicesByGroupCode = code => createSelector(
    getActiveConsignServiceGroups,
    serviceGroups => {
        const serviceGroup = serviceGroups.find(group => group.code === code);

        if (serviceGroup) {
            return serviceGroup.services;
        }

        return [];
    }
);

/**
 * Lấy danh sách service đang active và có thể show
 */
export const getActiveServiceGroups = createSelector(
    state => getState(state, 'serviceGroups', []),
    serviceGroups => serviceGroups
        .filter(serviceGroup => serviceGroup.active)
        .map(serviceGroup => ({
            ...serviceGroup,
            services: serviceGroup.services.filter(service => service.active && service.show),
        }))
);

export const getActiveServicesByGroupCode = code => createSelector(
    getActiveServiceGroups,
    serviceGroups => {
        const serviceGroup = serviceGroups.find(group => group.code === code);

        if (serviceGroup) {
            return serviceGroup.services;
        }

        return [];
    }
);
