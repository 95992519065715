import React from 'react';
import { Col, Row, Skeleton } from 'antd';

const InfoLoading = () => (
    <div className="a-block-info abg-white amb-16 apt-24 apx-8 pb-0">
        <Row gutter={{lg: 24, xl: 32}} className="mx-0">
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 16}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
            <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                <Skeleton active paragraph={{rows: 1}} className="askeleton-list" />
            </Col>
        </Row>
    </div>
);

export default InfoLoading;
