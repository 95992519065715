import React from 'react';
import lodash from 'lodash';

/**
 * Class xử lý translate hỗ trợ param dạng component
 */
export default class Translator {
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Xử lý translate
     *
     * @param {String} lang
     * @param {Object} replace
     * @returns {String|Array}
     */
    translate(lang, replace = {}) {
        if (this.shouldUseI18n(replace)) {
            return this.i18n.t(lang, replace);
        }

        let result = [];
        let index = 0;
        let match;
        let regex = new RegExp('{([a-z0-9_-]+)}', 'gi');

        lang = this.i18n.t(lang, lodash.mapValues(replace, (val, key) => `{${key}}`));

        while ((match = regex.exec(lang)) !== null) {
            result.push(lang.slice(index, match.index), replace[match[1]]);
            index = regex.lastIndex;
        }

        result.push(lang.slice(index));

        return result.map((lang, index) => <React.Fragment key={index}>{lang}</React.Fragment>);
    }

    /**
     * Xác định xem có nên sử dụng func translate mặc định của i18n hay không
     *
     * @param {Object} replace
     * @returns {boolean}
     */
    shouldUseI18n(replace) {
        return !lodash.findKey(replace, value => typeof value === 'object');
    }
}