import React from 'react';
import {connect} from 'react-redux';
import ListWarehouseStaff from "./components"
import {setMenuActive} from "../../Common/actions";
import {getListWarehouseStaff, getState} from "./selectors";
import * as actions from "./actions"
import SearchableComponent from "../../Common/components/SearchableComponent";

const mapStateToProps = (state, props) => ({
	listWarehouseStaff: getListWarehouseStaff(state, props.route.name),
	loading: getState(state, 'loading', false)
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	fetchListWarehouseStaff: (filter) => dispatch(actions.getListWarehouseStaff(filter)),
});

class ListWarehouseStaffContainer extends SearchableComponent {

	onSearch(filter) {
		this.pushFilter({
			...filter,
			i: parseInt(filter.i || 0, 0) + 1,
		});
	}

	onChangeFilter(filter) {
		filter.sort_by = 'username';
		filter.sort_direction = 'asc';
		this.props.fetchListWarehouseStaff(filter);
	}

	getCurrentFilter() {
		return {
			page: 1,
			sort_by: 'username',
			sort_direction: 'asc',
			...this.getFilterFromLocation(this.props.location),
		};
		;
	}

	render() {
		return <ListWarehouseStaff
			{...this.props}
			filter={this.getCurrentFilter()}
			onSearch={this.onSearch.bind(this)}/>
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListWarehouseStaffContainer);
