import {message} from 'antd';
import lodash from 'lodash';
import {dispatch, getState} from '../../../system/store';
import {HTTP_STATUS} from '../../../system/api/constants';
import {t} from '../../../system/i18n';
import apiService from '../apiService';
import authService from '../authService';
import {GET_USER} from '../constants';
import i18n from '../../../system/i18n/i18n';

let loadedUser = false;

export default (payload, next) => {
    if (!authService.isLogged() || lodash.get(getState(), 'auth.user')) {
        next(payload);
        return;
    }
    // if (!loadedUser && lodash.get(getState(), 'auth.user')) {
    //     next(payload);
    //     return;
    // }
    // Chỉ hiển thị loading khi bắt đầu vào page
    let hideLoading = !loadedUser ? message.loading(t('label.loading'), 0) : () => {
    };
    loadedUser = true;

    let getUser = apiService.getUser();

    getUser.then(res => {
        dispatch({type: GET_USER.SUCCESS, payload: res.data});
        if (i18n.language !== res.data.user.language) {
            localStorage.setItem('i18nextLng', res.data.user.language || 'vi');
            i18n.changeLanguage(res.data.user.language || 'vi');
        }
        hideLoading();
        loadedUser = false;
        next(payload);
    });

    getUser.catch(({response}) => {
        dispatch({type: GET_USER.FAILED, payload: response && response.data});
        hideLoading();
        loadedUser = false;
        if (lodash.get(response, 'status') === HTTP_STATUS.UNAUTHENTICATED) {
            message.error(t('auth:message.token_expired'), 4);
            authService.login();
        }
    });
};
