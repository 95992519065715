import { withTranslation } from 'react-i18next'
import React from 'react'
import { Empty, Table } from 'antd'
import Pagination from '../../../Common/components/Pagination'
import { BAG_REQUEST_LIST_COLUMN } from '../constants'
import lodash from 'lodash'

function BagList(props) {
    const { t, bags, pagination, activeColumns, loading, filter, onSearch } = props

    const getColumns = () => {
        let arrSort = [BAG_REQUEST_LIST_COLUMN.CREATED_AT]
        return lodash
            .values(BAG_REQUEST_LIST_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    className: column === BAG_REQUEST_LIST_COLUMN.BAGGING_REQUEST_CODE ? 'a-text--nowrap _tracking_no_column' : 'a-text--nowrap',
                    key: column,
                    dataIndex: column,
                    title: t('bag:column.' + column),
                }

                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === filter.sort_by ? (filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                    }
                }
                return res
            })
    }

    const onChangePage = (page, pageSize) => {
        if (!loading) {
            onSearch({
                ...filter,
                page,
                per_page: pageSize,
            })
        }
    }

    return (
        <>
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed ">
                <div className="a-table--responsive">
                    <Table
                        loading={loading}
                        columns={getColumns()}
                        rowKey={record => record.id}
                        dataSource={bags}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('common:label.no_data')}</span>}></Empty>
                            ),
                        }}
                    />
                </div>
                <Pagination
                    page={lodash.toInteger(filter.page) || 1}
                    pageSize={lodash.toInteger(filter.per_page)}
                    total={pagination ? pagination.total : 0}
                    loading={loading}
                    onChange={onChangePage}
                />
            </div>
        </>
    )
}
export default withTranslation()(BagList)
