import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as lodash from "lodash";

let list = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GET_LIST_WAREHOUSE.SUCCESS:
            return payload.warehouses;

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.GET_LIST_WAREHOUSE.REQUEST:
        case ACTION.FETCH_WAREHOUSE_SUGGEST.REQUEST:
            return true;
        case ACTION.GET_LIST_WAREHOUSE.SUCCESS:
        case ACTION.GET_LIST_WAREHOUSE.FAILED:
        case ACTION.FETCH_WAREHOUSE_SUGGEST.SUCCESS:
        case ACTION.FETCH_WAREHOUSE_SUGGEST.FAILED:
            return false;

        default:
            return state;
    }
};

let suggest = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_WAREHOUSE_SUGGEST.SUCCESS:
            return lodash.map(payload.warehouses, warehouse => warehouse.warehouse);

        default:
            return state;
    }
};
let listSuggestPermission = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_WAREHOUSE_SUGGEST_IS_PERMISSION.SUCCESS:
            return lodash.map(payload.warehouses, warehouse => warehouse.warehouse);

        default:
            return state;
    }
};

export default combineReducers({
    list,
    loading,
    suggest,
    listSuggestPermission
});
