import React from 'react';
import { Select } from 'antd';
import { find, first, get } from 'lodash';
import PropTypes from 'prop-types';

const Option = Select.Option;

class SelectShippingUser extends React.PureComponent {
    constructor (props) {
        super(props);
        this.autoSelected = false;
    }

    componentDidMount () {
        this.autoSelect();
    }

    componentDidUpdate () {
        this.autoSelect();
    }

    autoSelect = () => {
        const { autoSelect, users, value } = this.props;

        if (autoSelect && !this.autoSelected && !value && users.length) {
            this.autoSelected = true;
            this.changeInput({
                value: get(first(users), 'shipping_user.id')
            });
        }
    };

    changeInput = (input) => {
        const { isFieldDecorator, users, onChange } = this.props;

        input = {
            ...input,
            ...find(users, sp => get(sp, 'shipping_user.id') === input.value)
        };

        if (isFieldDecorator) {
            onChange(input.value);
        } else {
            onChange(input);
        }
    };

    render() {
        const {
            allowClear,
            className,
            disabled,
            dropdownClassName,
            loading,
            placeholder,
            selectProps,
            users,
            value,
            onChange
        } = this.props;

        const formattedSelectProps = {
            allowClear,
            className,
            defaultValue: this.autoSelected ? get(first(users), 'shipping_user.name') : (placeholder ? undefined : ''),
            disabled: disabled || loading,
            dropdownClassName,
            loading,
            optionFilterProp: 'children',
            placeholder,
            showSearch: true,
            value: !value || loading ? undefined : Number(value),
            onChange,
            ...selectProps
        };

        return (
            <Select {...formattedSelectProps}>
                {users.map(user => (
                    <Option
                        key={user.shipping_user.id}
                        value={user.shipping_user.id}
                        className={`_person__item _id_shipping_user_${user.shipping_user.id}`}
                    >
                        {`${get(user, 'shipping_user.full_name')}${get(user, 'shipping_user.username') ? ` (${get(user, 'shipping_user.username')}) ` : ''}`}
                    </Option>
                ))}
            </Select>
        );
    }
}

SelectShippingUser.defaultProps = {
    users: [],
    error: null,
    disabled: false,
    showName: false,
    className: '',
    placeholder: '',
    selectProps: {},
    onChange: () => {
    },
};

SelectShippingUser.propTypes = {
    users: PropTypes.array,
    value: PropTypes.any,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    showName: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectShippingUser;
