import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { resetError } from './../../../../../actions';
import { getBarcodesOfSuggest, getState } from './../../../../../selectors';
import { getErrors } from './../../../../../../selectors'
import { removeBarcode } from './../../../../../../DeliveryNoteBarcode/actions';
import { successCancelDeliveryNote } from './../../../../../../CancelDeliveryNote/actions';
import { successExportDeliveryNote } from './../../../../../../DeliveryNoteExport/actions';
import { successPlaceDeliveryNote } from './../../../../../../PlaceDeliveryNote/actions';


import DeliveryNoteItem from './components';
import { getLastmileCarrierSuggest } from '../../../../../../../ShippingPartner/ListShippingPartner/actions';

const DeliveryNoteItemContainer = props => {
    const handleRemoveReadyForDelivery = code => {
        props.removeBarcode({
            code,
            identity: props.identity
        });
    };

    const handleSuccessExportDeliveryNote = data => {
        props.successExportDeliveryNote({
            ...data,
            identity: props.identity
        })
    };

    const handleSuccessPlaceDeliveryNote = data => {
        props.successPlaceDeliveryNote({
            ...data,
            identity: props.identity
        });
    };

    const handleSuccessCancelDeliveryNote = data => {
        props.successCancelDeliveryNote({
            ...data,
            identity: props.identity
        });
    };

    return (
        <DeliveryNoteItem
            {...props}
            onRemoveReadyForDelivery={handleRemoveReadyForDelivery}
            onSuccessCancelDeliveryNote={handleSuccessCancelDeliveryNote}
            onSuccessExportDeliveryNote={handleSuccessExportDeliveryNote}
            onSuccessPlaceDeliveryNote={handleSuccessPlaceDeliveryNote}
        />
    );
}

const mapStateToProps = (state, props) => {
    return {
        barcodes: getBarcodesOfSuggest(state, props),
        errors: getErrors(getState(state, `errors[${props.identity}]`, {}))
    };
};

const mapDispatchToProps = {
    removeBarcode,
    resetError,
    successCancelDeliveryNote,
    successExportDeliveryNote,
    successPlaceDeliveryNote,
    getLastmileCarrierSuggest
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryNoteItemContainer));
