import {combineReducers} from 'redux';
import * as ACTION from './constants';
import lodash from "lodash";

let list = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GET_LIST_CUSTOMER.SUCCESS:
            return lodash.map(payload.customers, item => item.customer);

        default:
            return state;
    }
};

let detail = (state = {customer: {}, customer_finance: {}}, action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_CUSTOMER.SUCCESS:
            return {...payload};
        case ACTION.FETCH_DETAIL_CUSTOMER.FAILED:
        case ACTION.CLEAR_DETAIL_CUSTOMER_STATE:
            return {customer: {}, customer_finance: {}};
        default:
            return state;
    }
};

let loading = (state = {detail: false, list: false}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.FETCH_DETAIL_CUSTOMER.REQUEST:
            return {...state, detail: true};
        case ACTION.FETCH_DETAIL_CUSTOMER.FAILED:
        case ACTION.FETCH_DETAIL_CUSTOMER.SUCCESS:
            return {...state, detail: false};
        case ACTION.GET_LIST_CUSTOMER.REQUEST:
            return {...state, list: true};
        case ACTION.GET_LIST_CUSTOMER.FAILED:
        case ACTION.GET_LIST_CUSTOMER.SUCCESS:
            return {...state, list: false};
        default:
            return state;
    }
};

let errorDetail = (state = {}, action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_CUSTOMER.FAILED:
            if (lodash.isUndefined(payload)) {
                payload = {code: "ERR_INTERNET_DISCONNECTED"}
            }
            return {...payload};
        case ACTION.FETCH_DETAIL_CUSTOMER.REQUEST:
        case ACTION.FETCH_DETAIL_CUSTOMER.SUCCESS:
        case ACTION.CLEAR_DETAIL_CUSTOMER_STATE:
        case ACTION.CLEAR_ERROR_DETAIL_CUSTOMER_STATE:
            return {};

        default:
            return state;
    }
};

const suggests = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_SUGGESTS.SUCCESS: {
            return payload.customers;
        }
        case ACTION.GET_SUGGESTS.FAILED:
        case ACTION.CLEAR_SUGGESTS: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const loadingSuggests = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.GET_SUGGESTS.REQUEST: {
            return true;
        }
        case ACTION.GET_SUGGESTS.SUCCESS:
        case ACTION.GET_SUGGESTS.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const initCustomersOfSelects = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case ACTION.GET_INIT_CUSTOMER_OF_SELECT.SUCCESS: {
            return {
                ...state,
                [request]: [{
                    customer: lodash.get(payload, 'customer')
                }]
            };
        }
        case ACTION.GET_INIT_CUSTOMER_OF_SELECT.FAILED: {
            return {
                ...state,
                [request]: []
            };
        }
        default: {
            return state;
        }
    }
};

const loadingInitCustomersOfSelects = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case ACTION.GET_INIT_CUSTOMER_OF_SELECT.REQUEST: {
            return {
                ...state,
                [payload]: true
            };
        }
        case ACTION.GET_INIT_CUSTOMER_OF_SELECT.SUCCESS:
        case ACTION.GET_INIT_CUSTOMER_OF_SELECT.FAILED: {
            return {
                ...state,
                [request]: false
            };
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    detail,
    loading,
    list,
    errorDetail,
    suggests,
    loadingSuggests,
    initCustomersOfSelects,
    loadingInitCustomersOfSelects
});
