import lodash from 'lodash';
import authService from "../Auth/authService";

class PackageService {
    makeFormInput(packageData) {
        return lodash.mapValues(packageData, (value, param) => {
            // m to cm
            if (['length', 'width', 'height'].includes(param)) {
                return lodash.round(value * 100, 1);
            }

            // m to 100g
            if (param === 'weight_net') {
                let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g');
                return configPackageUnitWeight === "kg" ? value : lodash.round(value * 10, 2);
            }

            // m3 to cm3
            if (param === 'volume') {
                return lodash.round(value * 1000000, 3);
            }

            return value;
        })
    }
}

export default new PackageService();
