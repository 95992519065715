import React, { useState } from 'react'
import { get, isFunction, map } from 'lodash'
import { connect } from 'react-redux'

import { clearState, create, removeBarcode, resetError } from './actions'
import { getBagIds, getBarcodes, getPackageIds, getState } from './selectors'
import apiService from './../apiService'
import { getErrors } from './../selectors'
import { BARCODE_TYPE } from './../DeliveryNoteBarcode/constants'
import customerApiService from './../../Customer/apiService'
import { getSettingItemByCode } from './../../Setting/selectors'

import FormCustomer from './components'
import { notification } from 'antd'
import { t } from '../../../system/i18n'
import { getState as getStateSystem } from '../../../system/store'
import { createWithTrackingNo } from '../Create/actions'

const usingShippingDomesticPartner = getSettingItemByCode('CONFIG:USING_SHIPPING_DOMESTIC_PARTNER', false)
const defaultShippingDomesticPartner = getSettingItemByCode('CONFIG:DEFAULT_SHIPPING_DOMESTIC_PARTNER')

const FormCustomerContainer = props => {
    const [customerBagPackages, setCustomerBagPackages] = useState({
        bags: [],
        packages: [],
    })
    const [loadingCustomerBagPackages, setLoadingCustomerBagPackages] = useState(false)
    const [customerDetailInfo, setCustomerDetailInfo] = useState({
        customer: {},
        customer_finance: {},
    })
    const [loadingCustomerDetailInfo, setLoadingCustomerDetailInfo] = useState(false)

    const getCustomerBagPackages = async params => {
        setLoadingCustomerBagPackages(true)

        try {
            const { data } = await apiService.getCustomerBagPackages(params.id_customer, params)

            setCustomerBagPackages({
                ...data,
                bags: map(data.bags, bag => ({
                    ...bag,
                    code: get(bag, 'bag.code'),
                    type: BARCODE_TYPE.BAG,
                })),
                packages: map(data.packages, pkg => ({
                    ...pkg,
                    code: get(pkg, 'package.code'),
                    type: BARCODE_TYPE.PACKAGE,
                })),
            })
        } catch (error) {}

        setLoadingCustomerBagPackages(false)
    }

    const getCustomerDetailInfo = async customerId => {
        setLoadingCustomerDetailInfo(true)

        await customerApiService
            .fetchDetailCustomer(customerId)
            .then(res => {
                setCustomerDetailInfo(get(res, 'data', {}))
                const id_agency = get(res, 'data.agency.id')
                if (isFunction(props.getLastmileCarrierSuggest)) {
                    props.getLastmileCarrierSuggest({ id_agency, id_warehouse:  get(props, 'input.id_warehouse', 0)})
                }
            })
            .catch(err => {
                const code = get(err, 'response.status')
                if (code === 404) {
                    notification.error({
                        message: <span className={'_create_package_error'}>{t('delivery_note:message.customer_exception')}</span>,
                    })
                } else {
                    notification.error({
                        message: <span className={'_create_package_error'}>{t('message.server_error')}</span>,
                    })
                }
            })
            .finally(() => {
                setLoadingCustomerDetailInfo(false)
            })
    }

    const handleSubmit = (data, auto = false) => {
        if (auto) {
            props.createWithTrackingNo(data)
        } else {
            props.create(data)
        }
    }

    return (
        <FormCustomer
            {...props}
            customerBagPackages={customerBagPackages}
            customerDetailInfo={customerDetailInfo}
            loadingCustomerBagPackages={loadingCustomerBagPackages}
            loadingCustomerDetailInfo={loadingCustomerDetailInfo}
            getCustomerBagPackages={getCustomerBagPackages}
            getCustomerDetailInfo={getCustomerDetailInfo}
            onSubmit={handleSubmit}
        />
    )
}

const mapStateToProps = state => {
    return {
        bags: getBagIds(state),
        barcodes: getBarcodes(state),
        creating: getState(state, 'creating'),
        deliveryNote: getState(state, 'deliveryNote'),
        errors: getErrors(getState(state, 'errors', {})),
        packages: getPackageIds(state),
        usingShippingDomesticPartner: usingShippingDomesticPartner(state),
        defaultShippingDomesticPartner: defaultShippingDomesticPartner(state),
        agencySetting: getStateSystem('setting.agencySetting'),
        globalSetting: getStateSystem('setting.setting'),
        creatingWithTrackingNo: get(state, 'deliveryNote.create.creatingWithTrackingNo', false)
    }
}

const mapDispatchToProps = {
    clearState,
    create,
    removeBarcode,
    resetError,
    createWithTrackingNo
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCustomerContainer)
