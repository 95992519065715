import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchProperty = createAction(ACTION.FETCH_LIST_PROPERTY.REQUEST, (params) => params);
export const fetchDetailProperty = createAction(ACTION.FETCH_DETAIL_PROPERTY.REQUEST, (id) => ({id}));
export const fetchPropertySuggest = createAction(ACTION.FETCH_LIST_PROPERTY_SUGGEST.REQUEST, (params) => params);
export const createProperty = createAction(ACTION.CREATE_PROPERTY.REQUEST, (params) => params);
export const updateProperty = createAction(ACTION.UPDATE_PROPERTY.REQUEST, (params) => params);
export const clearState = createAction(ACTION.CLEAR_STATE, (params) => params);
export const resetError = createAction(ACTION.RESET_ERROR, (params) => ({params}));
