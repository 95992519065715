import { combineReducers } from 'redux';

import { FETCH_SUGGEST_AGENCIES, GET_AGENCIES } from './constants';
import { map } from 'lodash';

const agencies = (state = [], action) => {
    switch (action.type) {
        case GET_AGENCIES.SUCCESS: {
            return action.payload.agencies;
        }
        case GET_AGENCIES.FAILED: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case GET_AGENCIES.SUCCESS: {
            return action.payload.pagination;
        }
        case GET_AGENCIES.FAILED: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_AGENCIES.REQUEST: 
        case FETCH_SUGGEST_AGENCIES.REQUEST: {
            return true;
        }
        case GET_AGENCIES.SUCCESS:
        case GET_AGENCIES.FAILED: 
        case FETCH_SUGGEST_AGENCIES.SUCCESS:
        case FETCH_SUGGEST_AGENCIES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

let suggest = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case FETCH_SUGGEST_AGENCIES.SUCCESS:
            return map(payload.agencies, agency => agency.agency);

        default:
            return state;
    }
};

export default combineReducers({
    agencies,
    pagination,
    suggest,
    loading
});
