import { get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import PackageGroup from '../SplitPackage/PackageList/PackageGroup'

import PackageItem from './PackageList/PackageItem'

const PackageList = ({
    boxSizes,
    loadingBoxSizes,
    orderItems,
    originPackage,
    packages,
    onUpdate,
    onRemovePackage,
    handleKeyPress,
    stateProgress,
    groupByProduct,
    detachSuggest,
    packageCreatedIds,
    packageSplitting
}) => {
    const splittingPackages = packages.filter(pkg => !pkg.id)
    const [packageData, setPackageData] = useState([])

    useEffect(() => {
        setPackageData(packages)
    }, [packages])

    // const handleKeyPress = currentIndex => {
    //     setCurrentIndex(prevIndex => {
    //         let nextIndex = currentIndex + 1 > packageData.length ? currentIndex : currentIndex + 1

    //         const nextInput = get(packageData, `${nextIndex}.ref.current`)

    //         if (nextInput) {
    //             nextInput.focus()
    //             const el = document.querySelector(`.package-item._package-item-${nextIndex}`)
    //             if (el) {
    //                 el.scrollIntoView()
    //             }
    //         }

    //         return nextIndex
    //     })
    // }

    useEffect(() => {
        // focus the firs iput initially\
        if(stateProgress === 1){
         
            const eleFirst = document.querySelector(`.package-item._package-item-1`)
            if (eleFirst) {
                eleFirst.classList.add('package-focus')
                eleFirst.scrollIntoView({ behavior: 'smooth', block: 'start' })
               
                const inputCurr = eleFirst.querySelector('#weight_net_1')
                inputCurr.focus()
            }
        }
      
    }, [packageData, stateProgress, groupByProduct])

    const packageItem = get(detachSuggest, 'package_items', [])
    const orderItem = map(get(detachSuggest, 'order_items', []), item=>({...item, name_zh: item.original_name,received_quantity: item.purchase_quantity}))

    return groupByProduct ? (
        <PackageGroup
            boxSizes={boxSizes}
            loadingBoxSizes={loadingBoxSizes}
            orderItems={orderItems}
            originPackage={originPackage}
            packages={packages}
            onUpdate={onUpdate}
            onRemovePackage={onRemovePackage}
            autoPrint
            groupByProduct={groupByProduct}
            detachSuggestItems={!isEmpty(packageItem) ? packageItem : orderItem}
            stateProgress={stateProgress}
            packageCreatedIds={packageCreatedIds}
            packageSplitting={packageSplitting}
        />
    ) : (
        <ul className="package-list">
            {packageData.map((pkg, index) => (
                <PackageItem
                    boxSizes={boxSizes}
                    haveSplittingPackages={!!splittingPackages.length}
                    index={index}
                    key={pkg.id || index}
                    loadingBoxSizes={loadingBoxSizes}
                    orderItems={orderItems}
                    originPackage={originPackage}
                    products={pkg.items}
                    pkg={pkg}
                    onUpdate={onUpdate.bind(undefined, index)}
                    onRemovePackage={onRemovePackage.bind(undefined, index)}
                    refPackage={pkg.ref}
                    handleKeyPress={handleKeyPress}
                    stateProgress={stateProgress}
                    packageData={packageData}
                    groupByProduct={groupByProduct}
                    packageCreatedIds={packageCreatedIds}
                    packageSplitting={packageSplitting}
                />
            ))}
        </ul>
    )
}

export default PackageList
