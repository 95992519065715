import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { fetchInventoryDetail, resolveDisappearInventoryPackages, resolveRemainderInventoryPackages, updateInventoryStatus, resolveInventoryTrackingBills } from './../actions';
import * as selectors from './../selectors';
import { setMenuActive } from './../../Common/actions';

import InventoryProcess from './components';

const mapStateToProps = (state) => {
    return {
        loading: selectors.getState(state, 'loading'),
        inventory: selectors.getState(state, 'detail.inventory', {}),
        remainders: selectors.remainders(state),
        disappears: selectors.disappears(state),
        normals: selectors.normals(state),
        creator: selectors.getState(state, 'detail.creator', {}),
        warehouse: selectors.getState(state, 'detail.warehouse', {}),
        loadingUpdateStatus: selectors.getState(state, 'loadingUpdateStatus', false),
        resolvingDisappearInventoryPackages: selectors.getState(state, 'resolvingDisappearInventoryPackages', false),
        resolvingRemainderInventoryPackages: selectors.getState(state, 'resolvingRemainderInventoryPackages', false),
        resolvingInventoryTrackingBills: selectors.getState(state, 'resolvingInventoryTrackingBills', false)
    };
};

const mapDispatchToProps = {
    fetchInventoryDetail,
    setMenuActive,
    updateInventoryStatus,
    resolveDisappearInventoryPackages,
    resolveRemainderInventoryPackages,
    resolveInventoryTrackingBills
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryProcess));
