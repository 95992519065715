import React from 'react';
import {Layout} from "antd";
import MenuLeft from "../modules/Common/MenuLeft/MenuLeftContainer"
import Header from '../modules/Common/components/Header';
import PrintStampContainer from "../modules/Common/PrintStamp/PrintStampContainer";

export default ({children}) => (
    <div>
        <div id="page-content">
            <Layout>
                <MenuLeft/>
                <Layout className="a-main _a-main">
                    <Header/>
                    {children}
                </Layout>
            </Layout>
        </div>
        <div id="print-content" className="print-container">
            <PrintStampContainer/>
        </div>
        <div style={{display: "none"}}>
            <img style={{display: "none"}} src="https://assets-vns.mygobiz.net/m6/images/kititu.JPG" />
        </div>
    </div>

);