export const LIST_COLUMN = {
    CODE: 'code',
    STATUS: 'status',
    CREATE_AT: 'created_at',
    EXPORTED_AT: 'exported_at',
    ID_SHIPPING_PARTNER: 'id_shipping_partner',
    PACKET_NUMBER: 'total_number',
    ID_WAREHOUSE: 'id_warehouse',
    ID_CREATOR: 'id_creator',
    ID_EXPORTER: 'id_exporter',
    PARTNER_TRACKING_NUMBER: 'partner_tracking_number',
}
