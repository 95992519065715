import {connect} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

const mapStateToProps = (state) => ({
    i18n,
    language : state.language,
});

export default connect(mapStateToProps)(I18nextProvider);

