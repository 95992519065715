import React, {Component} from 'react';
import {connect} from 'react-redux';
import lodash from 'lodash';
import {notification} from "antd";
import PrintBagStamp from "./PrintBagStamp";
import apiService from '../../../Bag/apiService';
import {printBagStamp} from "../actions";
import {t} from '../../../../system/i18n';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    printBagStamp
};

class PrintBagStampTest extends Component {
    state = {
        data: {}
    }

    componentDidMount() {
        const { match: { params: { code } } } = this.props;

        document.getElementsByTagName('body')[0].classList.add('A5');

        apiService.printBarcode(code)
            .then(response => {
                const data = lodash.get(response, 'data');

                this.setState({
                    data
                });
            })
            .catch(() => {
                notification.error({
                    message: t('message.server_error')
                })
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })
    }


    render() {
        const { data } = this.state;

        if (Object.keys(data).length) {
            return (
                <>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"/>
                    <section className="sheet">
                        <PrintBagStamp
                            {...data}
                        />
                    </section>
                </>
            );
        }

        return (
            <div>123</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintBagStampTest);