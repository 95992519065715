import lodash from 'lodash';
import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* getListCustomer(action) {
    yield processApiRequest(ACTION.GET_LIST_CUSTOMER, () => apiService.getListCustomer(action.payload), action.payload);
}

export function* getSuggests(action) {
    yield processApiRequest(ACTION.GET_SUGGESTS, () => apiService.getSuggests(lodash.omit({...action.payload,ignore_finance: 1, visible: 1 }, 'callback')), action.payload);
}

export function* fetchDetailCustomer(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_CUSTOMER, () => apiService.fetchDetailCustomer(action.payload.customerId), action.payload);
}

export function* getInitCustomersOfSelect(action) {
    yield processApiRequest(ACTION.GET_INIT_CUSTOMER_OF_SELECT, () => apiService.fetchDetailCustomer(action.payload, { ignore_finance: 1 }), action.payload);
}

export function* fetchDetailCustomerSuccess(action) {
    const cb = lodash.get(action, 'request.cb');
    if (typeof cb === 'function') {
        yield cb(action);
    }
}

