import { Button, Col, Form, Input, InputNumber, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import pageService from '../../Common/Page/pageService'
import SelectCustomerContainer from '../../Customer/SelectCustomer/SelectCustomerContainer'
import FormPropertiesContainer from '../../Property/FormProperties/FormPropertiesContainer'
import { setMenuActive } from '../../Common/actions'
import { get } from 'lodash'
import SelectAgency from '../../ExpeditedProcessing/components/Agency/SelectAgency'
import SelectShippingLastmile from '../../BagLastmile/components/SelectShippingLastmile'
import apiServices from '../apiServices'
import { router } from '../../../system/routing'

const mapStateToProps = state => {
    return {}
}

const CreateForm = ({ form, t, setMenuActive, active }) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const { getFieldDecorator } = form

    useEffect(() => {
        pageService.setTitle(t('template_bag:page_title'))
        setMenuActive('config/template-bags-lastmile')
    }, [])

    const onChangeSelectValue = (name, value) => {
        if (name === 'id_customer') {
            form.setFieldsValue({
                [name]: get(value, 'customer.id'),
            })
            setData({ ...data, [name]: get(value, 'customer.id') })
        } else {
            form.setFieldsValue({
                [name]: value,
            })
            setData({ ...data, [name]: value })
        }
    }

    const onSave = () => {
        form.validateFields((error, values) => {
            if (error) {
                return
            }

            const data = {
                ...values,
                active,
                width_max: get(values, 'width_max') ? get(values, 'width_max') / 100 : undefined,
                height_max: get(values, 'height_max') ? get(values, 'height_max') / 100 : undefined,
                length_max: get(values, 'length_max') ? get(values, 'length_max') / 100 : undefined,
                weight_max: get(values, 'weight_max') ? get(values, 'weight_max') / 100 : undefined,
            }

            setLoading(true)
            apiServices
                .createTemplateBagLastmile({ ...data })
                .then(res => {
                    router.redirect('/config/template-bags-lastmile')
                    notification.success({ message: t('template_bag:message.create_bag_template_success') })
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data', {})

                    if (code === 'INPUT_INVALID') {
                        Object.entries(data).forEach(([key, value]) => {
                            notification.error({
                                message: t(`package:expedited_package.message.${Object.keys(value)[0]}`, {
                                    attribute: t(`bag:label.${key}`),
                                }),
                            })
                        })
                    } else {
                        notification.error({ message: t('template_bag:message.create_bag_template_fail') })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <Form
            className="a-block"
            form={form}>
            <div className="body">
                <Row
                    gutter={{ lg: 24, xl: 36 }}
                    type="flex">
                    <Col>
                        <div className="a-block-info pb-0 block-create-bag">
                            <Row
                                gutter={{ lg: 24, xl: 32 }}
                                className="a-form mx-0 " type='flex'>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        className="a-form__item"
                                        label={t('bag:label.label')}
                                        labelCol={{ span: 24 }}>
                                        {getFieldDecorator('label', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: t('validation:required', {
                                                        attribute: t('bag:label.label'),
                                                    }),
                                                },
                                            ],
                                        })(<Input placeholder={t('template_bag:input_name')} />)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        className="a-form__item"
                                        label={t('bag:label.customer')}
                                        labelCol={{ span: 24 }}>
                                        {getFieldDecorator('id_customer', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('bag:label.customer'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(<Input hidden />)}
                                        <SelectCustomerContainer
                                            value={data.id_customer}
                                            allowClear
                                            autoSelect
                                            className="a-select a-select--search-content _id_customer"
                                            dropdownClassName="_dropdown_id_customer"
                                            emptyOption={false}
                                            is_shipper
                                            placeholder={t('bag:label.customer_placeholder')}
                                            showName
                                            dispatchObject={true}
                                            onChange={value => onChangeSelectValue('id_customer', value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={t('bag:label.id_lastmile_shipping_partner')}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('id_lastmile_shipping_partner', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('bag:label.id_lastmile_shipping_partner'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(<SelectShippingLastmile placeholder={t('bag:label.id_lastmile_shipping_partner')} />)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={'Agency'}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('id_agency', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: 'Agency',
                                            //         }),
                                            //     },
                                            // ],
                                        })(<SelectAgency placeholder="Agency" />)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={t('template_bag:label.height_max')}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('height_max', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('template_bag:label.height_max'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(
                                            <InputNumber
                                                min={0}
                                                className="gw-100"
                                                placeholder={t('template_bag:placeholder.height_max')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={t('template_bag:label.width_max')}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('width_max', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('template_bag:label.width_max'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(
                                            <InputNumber
                                                min={0}
                                                className="gw-100"
                                                placeholder={t('template_bag:placeholder.width_max')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={t('template_bag:label.length_max')}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('length_max', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('template_bag:label.length_max'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(
                                            <InputNumber
                                                min={0}
                                                className="gw-100"
                                                placeholder={t('template_bag:placeholder.length_max')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col
                                    className="a-form--group"
                                    lg={{ span: 8 }}
                                    xxl={{ span: 6 }}>
                                    <Form.Item
                                        label={t('template_bag:label.weight_max')}
                                        className="a-form__item"
                                    >
                                        {getFieldDecorator('weight_max', {
                                            // rules: [
                                            //     {
                                            //         required: true,
                                            //         message: t('validation:required', {
                                            //             attribute: t('template_bag:label.weight_max'),
                                            //         }),
                                            //     },
                                            // ],
                                        })(
                                            <InputNumber
                                                min={0}
                                                className="gw-100"
                                                placeholder={t('template_bag:placeholder.weight_max')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row
                                gutter={{ lg: 24, xl: 32 }}
                                className="a-form mx-0">
                                <Col
                                className="a-form--group"
                                >
                                    <Form.Item
                                        className="a-form__item"
                                        label={t('bag:label.properties')}
                                        labelCol={{ span: 24 }}>
                                        {getFieldDecorator('id_properties')(
                                            <FormPropertiesContainer
                                                classUl="property--package _properties"
                                                values={form.getFieldValue('id_properties')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                gutter={{ lg: 24, xl: 32 }}
                                className="a-form mx-0">
                                <Col
                                //  className="a-form--group"
                                >
                                    <Form.Item
                                        className="a-form__item"
                                        label={t('label.note')}
                                        labelCol={{ span: 24 }}>
                                        {getFieldDecorator('note')(
                                            <Input.TextArea
                                                placeholder={t('placeholder.note')}
                                                rows={4}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col className="a-package--action only-action create-bag p-5">
                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="a-btn a-btn--primary a-btn--save-package _btn-create"
                                onClick={onSave}
                                loading={loading}>
                                {t('template_bag:btn_create')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Form>
    )
}

export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(Form.create()(CreateForm))
)
