import {message} from 'antd';
import {t} from '../../../system/i18n';

class Loading {
    constructor() {
        this.hide = null;
    }

    open(content) {
        this.hide = message.loading(content || t('label.loading'), 0);
    }

    close() {
        if (this.hide) {
            this.hide();
            this.hide = null;
        }
    }
}

let loading = new Loading();

export {Loading};

export default loading;
