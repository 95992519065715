export const GET_RE_EXECUTIONS = {
    REQUEST: 'RE_EXECUTION.GET_LIST.REQUEST',
    SUCCESS: 'RE_EXECUTION.GET_LIST.SUCCESS',
    FAILED: 'RE_EXECUTION.GET_LIST.FAILED'
};

export const ADD_RE_EXECUTION = {
    REQUEST: 'RE_EXECUTION.ADD.REQUEST',
    SUCCESS: 'RE_EXECUTION.ADD.SUCCESS',
    FAILED: 'RE_EXECUTION.ADD.FAILED'
};

export const TABLE_COLUMNS = {
    SHIPPING_PARTNER: 'shipping_partner',
    AGENCY: 'agency',
    STARTED_AT: 'started_at',
    FINISHED_AT: 'finished_at',
    CREATOR: 'creator',
    CREATED_AT: 'created_at',
    STATE: 'state'
};
