/* eslint-disable */
import React from 'react';
import CreateBag from './components'
class CreateBagContainer extends React.PureComponent{
    render() {
        return(
            <CreateBag/>
        )
    }
}
export default CreateBagContainer;