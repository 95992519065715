import {connect} from 'react-redux';
import PrintPackageStamp from './components/';
import {printPackageStamp} from './../../Common/PrintStamp/actions';
import {getInputFocus} from '../CreatePackageViaScan/selectors';
import { get } from 'lodash';

const mapStateToProps = (state, props) => {
   return ({
        ...getInputFocus(state),
        packageData: props.packageData,
        permissions: get(props, 'packageData.permissions', {})
    })
};

const mapDispatchToProps = (dispatch) => ({
    printPackageStamp: (datas) => {
        dispatch(printPackageStamp(datas));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintPackageStamp);
