import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchInventoryDetail = createAction(ACTION.FETCH_INVENTORY_DETAIL.REQUEST, (id) => ({id}));
export const updateInventoryStatus = createAction(ACTION.UPDATE_INVENTORY_STATUS.REQUEST, (id, status) => ({id, status}));
export const updateInventoryPackageStatus = createAction(ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.REQUEST);
export const resolveDisappearInventoryPackages = createAction(ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.REQUEST);
export const resolveRemainderInventoryPackages = createAction(ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.REQUEST);
export const resolveInventoryTrackingBill = createAction(ACTION.RESOLVE_INVENTORY_TRACKING_BILL.REQUEST);
export const resolveInventoryTrackingBills = createAction(ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.REQUEST);
export const fetchInventories = createAction(ACTION.FETCH_INVENTORIES.REQUEST, (filter) => ({filter}));
export const resetError = createAction(ACTION.RESET_ERROR, (params) => ({params}));
