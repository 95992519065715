/* eslint-disable */
import React from 'react';
import {Form, Select, Button, Row, Col, Icon, Input, DatePicker} from 'antd';
const Option = Select.Option;
const Search = Input.Search;
const {RangePicker} = DatePicker;
class FormSearch extends React.PureComponent{
    render() {
        return(
            <div className="a-content--search-header list-invoice search-default">
                <Row gutter={24}>
                    <Form className="a-form">
                        <Col lg={18} xl={17} xxl={18} className="search--left">
                            <Row gutter={{lg: 12, xl: 12, xxl: 24}}>
                                <Col lg={6} xl={5} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Trạng thái phiếu xuất "
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content _status_partner"
                                                defaultValue="Tất cả ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={9} xl={7} xxl={7}>
                                    <Form.Item
                                        className="a-form__item search-content _time_create_partner"
                                        label="Thời gian tạo "
                                        labelCol={{span: 24}}>
                                        <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={9} xl={7} xxl={7}>
                                    <Form.Item
                                        className="a-form__item search-content _time_export_partner"
                                        label="Thời gian xuất"
                                        labelCol={{span: 24}}>
                                        <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={5} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Nhân viên vận chuyển"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content _person_transport_partner"
                                                defaultValue="Tất cả ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={5} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Kho xuất "
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content _warehouse_export_partner"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={5} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Nguời tạo"
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content _person_create_partner"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} xl={5} xxl={5}>
                                    <Form.Item
                                        className="a-form__item search-content"
                                        label="Người xuất "
                                        labelCol={{span: 24}}>
                                        <Select className="a-select a-select--search-content _person_export_partner"
                                                defaultValue="CNGZ">
                                            <Option value="CNGZ">CNGZ</Option>
                                            <Option value="VNSG">VNSG</Option>
                                            <Option value="VNHN">VNHN</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={7} xxl={6} className="search--right">
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label="Mã xuất"
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search _input_search_partner"
                                />
                            </Form.Item>
                            <div className="search--action">
                                <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                                <Button type="primary" htmlType="submit"
                                        className="a-btn a-btn--primary a-btn--search-list _search_partner">Tìm
                                    kiếm</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>
        )
    }
}
export default FormSearch;