import React from 'react'
import { notification, Layout } from 'antd'
import moment from 'moment'
import lodash, { get, isEmpty, map } from 'lodash'
import { withTranslation } from 'react-i18next'
import apiService from './apiService'
import shippingPartnerApi from '../ShippingPartner/ListShippingPartner/apiService'
import SearchableComponent from '../Common/components/SearchableComponent'
import { convertBlobDataToExcel, getVar } from '../../system/support/helpers'
import { setMenuActive } from '../Common/actions'
import LastmileOrderList from './components/List'
import FormSearch from './components/List/FormSearch'
import pageService from '../Common/Page/pageService'
import { Link } from '../../system/routing'
import { dateFormatter } from '../Common/services/format'
import DetailDeliveryRequestContainer from '../DeliveryRequest/DetailDeliveryRequest/DetailDeliveryRequestContainer'
import { dispatch } from '../../system/store'
import { showDetailDeliveryRequest } from '../DeliveryRequest/DetailDeliveryRequest/actions'
import find from 'lodash/find'
import { LASTMILE_TRACKING_NO_STATUS } from './constants'

const monthAllow = 3
const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
const threeMonthsAgo = moment(currentDate).subtract(monthAllow, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss')

const { Content } = Layout

class LastmileOrderListContainer extends SearchableComponent {
    constructor(props) {
        super(props)
        this.state = {
            exporting: false,
            loading: false,
            data: [],
            pagination: {},
            isOpenListSetting: false,
            loadingShippingPartner: false,
            shippingPartners: [],
        }
    }

    componentDidMount() {
        const { t } = this.props
        pageService.setTitle(t('lastmile_order:title.list'))
        setMenuActive('lastmile-order.list')
        const filter = this.getCurrentFilter()
        this.onChangeFilter(filter)
        this.fetchShippingPartner()
    }

    fetchShippingPartner = () => {
        this.setState({ loadingShippingPartner: true })
        shippingPartnerApi
            .getListLastmileCarrier()
            .then(res => {
                this.setState({ shippingPartners: getVar(res, 'data.data', []) })
            })
            .catch(() => {
                this.setState({ shippingPartners: [] })
            })
            .finally(() => this.setState({ loadingShippingPartner: false }))
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    onChangeFilter(filter) {
        if (!lodash.get(filter, 'created_at[from]')) {
            filter['created_at[from]'] = threeMonthsAgo
        }
        this.fetchBillOfLading(filter)
    }

    getCurrentFilter = () => {
        const filter = {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            sort_direction: this.props.sort_direction || 'desc',
            ...this.getFilterFromLocation(this.props.location),
        }

        return filter
    }

    fetchBillOfLading = filter => {
        const { t } = this.props
        this.setState({ loading: true })
        apiService
            .getListBillOfLading(filter)
            .then(res => {
                const data = get(res, 'data.data', [])
                const pagination = get(res, 'data.meta.pagination', {})
                this.setState({ data: this.refactorData(data), pagination })
            })
            .catch(() => {
                notification.error({
                    message: t("message.server_error"),
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    refactorData = data => {
        const { t } = this.props
        const newData = []
        data.map((item, index) => {
            const packageCodes = map(getVar(item, 'packages.data', []), 'code')
            const deliveryRequestCode = getVar(item, 'delivery_request.code')
            const deliveryNote = getVar(item, 'delivery_note', {})
            const agency = getVar(item, 'agency', {})
            const lastmileShipmentHistories = getVar(item, 'last_mile_shipment_histories.data', [])
            const deliveredAt = getVar(find(lastmileShipmentHistories, ['status', LASTMILE_TRACKING_NO_STATUS.DELIVERED]), 'time')
            const returnedAt = getVar(find(lastmileShipmentHistories, ['status', LASTMILE_TRACKING_NO_STATUS.RETURNED]), 'time')
            const exportedAt = getVar(find(lastmileShipmentHistories, ['status', LASTMILE_TRACKING_NO_STATUS.DELIVERING]), 'time')
            return newData.push({
                tracking_no: getVar(item, 'tracking_no', '---'),
                package_code: packageCodes.map(packageCode => (
                    <div>
                        <Link
                            isNewTab
                            to="packages.detail"
                            params={{ id: packageCode }}>
                            {packageCode}
                        </Link>
                    </div>
                )),
                delivery_request_code: deliveryRequestCode ? (
                    <span
                        className="a-text--link cursor-pointer"
                        onClick={() =>
                            dispatch(
                                showDetailDeliveryRequest({
                                    code: deliveryRequestCode,
                                    agency: getVar(agency, 'code'),
                                })
                            )
                        }>
                        {deliveryRequestCode}
                    </span>
                ) : (
                    '---'
                ),
                delivery_note_code: !isEmpty(deliveryNote) ? (
                    <Link
                        isNewTab
                        to="delivery-notes.customer.detail"
                        params={{ id: deliveryNote.id }}>
                        {deliveryNote.code}
                    </Link>
                ) : (
                    '---'
                ),
                customer_username: `${getVar(item, 'customer.username', '---')} (${getVar(item, 'customer.code', '---')})`,
                id_last_mile_carrier: getVar(item, 'last_mile_carrier.name', '---'),
                created_at: dateFormatter.dateTime(getVar(item, 'created_at')),
                status: t(`delivery_note:LAST_MILE_SHIPMENT_STATUS.${getVar(item, 'status', '---')}`),
                id_agency: `${getVar(item, 'agency.name', '---')} (${getVar(item, 'agency.code', '---')})`,
                id_warehouse: getVar(item, 'warehouse.code', '---'),
                id_creator: `${getVar(item, 'creator.name', '---')} (${getVar(item, 'creator.username', '---')})`,
                agency,
                key: index,
                delivered_at: deliveredAt ? dateFormatter.dateTime(deliveredAt) : '---',
                returned_at: returnedAt ? dateFormatter.dateTime(returnedAt) : '---',
                exported_at: exportedAt ? dateFormatter.dateTime(exportedAt) : '---',
            })
        })
        return newData
    }

    handleExport = async params => {
        const { t } = this.props
        const createdAtFrom = lodash.get(params, 'created_at[from]')
        const createdAtTo = lodash.get(params, 'created_at[to]', moment().format('YYYY-MM-DD 23:59:59'))

        if (!createdAtFrom) {
            notification.error({
                message: t("lastmile_order:message.choose_created_at_to_export_file"),
            })
            return
        }
        const fromDate = moment(createdAtFrom, 'YYYY-MM-DD')
        const toDate = moment(createdAtTo, 'YYYY-MM-DD')

        const differenceInMonths = toDate.diff(fromDate, 'months', true)

        if (differenceInMonths > monthAllow) {
            notification.error({
                message: t("lastmile_order:message.created_at_max_value", {value: monthAllow}),
            })
            return
        }

        this.setState({
            exporting: true,
        })

        try {
            const response = await apiService.exportListBillOfLading(params)
            const reader = new FileReader()
            let message = t("lastmile_order:message.export_file.success")

            reader.addEventListener('loadend', event => {
                const now = moment()
                let fileName = `lastmile_order_${now.format('DD_MM_YYYY')}`
                try {
                    convertBlobDataToExcel(response.data, fileName)
                } catch (e) {
                    convertBlobDataToExcel(response.data, fileName)
                }
                notification.success({
                    message,
                })
            })

            reader.readAsText(response.data)
        } catch (error) {
            notification.error({
                message: t("lastmile_order:message.export_file.failed"),
            })
        }

        this.setState({
            exporting: false,
        })
    }

    render() {
        const { route } = this.props
        const { exporting, loading, data, pagination, isOpenListSetting, loadingShippingPartner, shippingPartners } = this.state
        const filter = this.getCurrentFilter()
        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <FormSearch
                        input={filter}
                        inputVersion={lodash.toInteger(filter.i)}
                        loading={loading}
                        onSearch={this.onSearch}
                        loadingShippingPartner={loadingShippingPartner}
                        shippingPartners={shippingPartners}
                    />
                    <LastmileOrderList
                        filter={filter}
                        data={data}
                        pagination={pagination}
                        loading={loading}
                        onSearch={this.onSearch}
                        exporting={exporting}
                        handleExport={this.handleExport}
                        isOpenListSetting={isOpenListSetting}
                        onOpenListSetting={isOpenListSetting => this.setState({ isOpenListSetting })}
                    />
                    <DetailDeliveryRequestContainer
                        route={route}
                        onSuccessDeliveringDeliveryRequest={() => {}}
                    />
                </Content>
            </Layout>
        )
    }
}

export default withTranslation()(LastmileOrderListContainer)
