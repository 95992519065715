export const FETCH_BAG_SHIPPING_PARTNER = {
    REQUEST: 'FETCH_BAG_SHIPPING_PARTNER.REQUEST',
    SUCCESS: 'FETCH_BAG_SHIPPING_PARTNER.SUCCESS',
    FAILED: 'FETCH_BAG_SHIPPING_PARTNER.FAILED',
};

export const FETCH_DETAIL_SHIPPING_PARTNER = {
    REQUEST: 'FETCH_DETAIL_SHIPPING_PARTNER.REQUEST',
    SUCCESS: 'FETCH_DETAIL_SHIPPING_PARTNER.SUCCESS',
    FAILED: 'FETCH_DETAIL_SHIPPING_PARTNER.FAILED',
};

export  const  CLEAR_DETAIL_SHIPPING_PARTNER_STATE = "CLEAR_DETAIL_SHIPPING_PARTNER_STATE";

export const FETCH_SHIPPING_PARTNER_SUGGEST = {
    REQUEST: 'FETCH_SHIPPING_PARTNER_SUGGEST.REQUEST',
    SUCCESS: 'FETCH_SHIPPING_PARTNER_SUGGEST.SUCCESS',
    FAILED: 'FETCH_SHIPPING_PARTNER_SUGGEST.FAILED',
};

export const GET_INTERNATIONALS = {
    REQUEST: 'SHIPPING_PARTNER.GET_INTERNATIONALS.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.GET_INTERNATIONALS.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.GET_INTERNATIONALS.FAILED',
};

export const GET_DOMESTICS = {
    REQUEST: 'SHIPPING_PARTNER.GET_DOMESTICS.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.GET_DOMESTICS.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.GET_DOMESTICS.FAILED',
};

export const GET_DOMESTIC_SUGGESTS = {
    REQUEST: 'SHIPPING_PARTNER.GET_DOMESTIC_SUGGESTS.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.GET_DOMESTIC_SUGGESTS.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.GET_DOMESTIC_SUGGESTS.FAILED',
};

export const UPDATE_DOMESTIC = {
    REQUEST: 'SHIPPING_PARTNER.UPDATE_DOMESTIC.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.UPDATE_DOMESTIC.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.UPDATE_DOMESTIC.FAILED',
};

export const GET_LASTMILE_CARRIER_SUGGEST = {
    REQUEST: 'SHIPPING_PARTNER.GET_LASTMILE_CARRIER_SUGGEST.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.GET_LASTMILE_CARRIER_SUGGEST.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.GET_LASTMILE_CARRIER_SUGGEST.FAILED',
};

export const PROVIDER_LAST_MILE_CARRIER = {
    UNKNOWN: 'UnKnown', // Dành cho những dvvc không xác định, không có kết nối API
    SNAPPY_EXPRESS: 'SnappyExpress'
}