import React from 'react'
import { Col, Form, InputNumber, Row } from 'antd'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import { withTranslation } from 'react-i18next'

import { currentFormatter } from '../../../Common/services/format'
import { getVar } from '../../../../system/support/helpers'

const ProductItemGroup = ({ canEdit, form, index, isOriginPackage, originPackage, packageIndex, product, t, onChange }) => {
    const { getFieldDecorator } = form
    const originPackageItems = getVar(originPackage, 'items', [])
    const originItem = originPackageItems.find(item => item.code_item === product.code_item)

    const handleChange = value => {
        onChange(value)
    }

    return (
        <li className={`product-item _product-item-${index}`}>
            <Row gutter={8}>
                <Col lg={{ span: 2 }}>
                    <img
                        alt=""
                        src={product.product_image}
                    />
                    <div>{product.code_item}</div>
                </Col>
                <Col lg={{ span: 6 }}>
                    <a
                        href={product.url}
                        className="_product-name"
                        rel="noopener noreferrer"
                        target="_blank">
                        {product.original_name}
                    </a>
                    <div>{map(product.variant_properties, 'value').join(', ')}</div>
                </Col>
                <Col
                    className="product-quantity"
                    lg={{ span: 6 }}>
                    <p>{t(`package:label.number_product_in_${isOriginPackage ? 'origin' : 'splitted'}_package`)}</p>
                    {canEdit ? (
                        <Form.Item>
                            {getFieldDecorator(`received_quantity_${packageIndex}_${index}`, {
                                initialValue: product.received_quantity,
                                rules: [
                                    {
                                        type: 'number',
                                        message: t('validation:numeric', {
                                            attribute: t(`package:label.number_product_in_${isOriginPackage ? 'origin' : 'splitted'}_package`),
                                        }),
                                    },
                                    {
                                        validator: async (rule, value, callback) => {
                                            if (isNumber(value) && value <= 0) {
                                                throw new Error(
                                                    t('validation:gt', {
                                                        attribute: t('package:label.number_product_in_splitted_package'),
                                                        value: 0,
                                                    })
                                                )
                                            }

                                            callback()
                                        },
                                    },
                                ],
                            })(<InputNumber onChange={value => handleChange(value)} />)}
                            {!isOriginPackage && (
                                <>
                                    <span className="ml-4 mr-2">/</span>
                                    {getVar(originItem, 'received_quantity', 0)}
                                </>
                            )}
                        </Form.Item>
                    ) : (
                        <>
                            {product.received_quantity || 0}
                            {!isOriginPackage && (
                                <>
                                    <span className="mx-2">/</span>
                                    {getVar(originItem, 'received_quantity', 0)}
                                </>
                            )}
                        </>
                    )}
                </Col>
                <Col
                    className="product-price"
                    lg={{ span: 5 }}>
                    <p>{t('package:label.product_price')}</p>
                    <b>{currentFormatter.toLocaleStringCurrency(product.unit_price)} VNĐ</b>
                </Col>
                <Col
                    className="total-amount"
                    lg={{ span: 5 }}>
                    <p>{t('package:label.price')}</p>
                    <b>{currentFormatter.toLocaleStringCurrency(product.unit_price * product.received_quantity)} VNĐ</b>
                </Col>
            </Row>
        </li>
    )
}

export default withTranslation()(ProductItemGroup)
