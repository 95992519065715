import React, {Component} from 'react';
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';

const { Search } = Input;

class Filter extends Component {
    state = {
        filter: this.props.filter
    };

    onChangeInput = (field, e) => {
        this.setState({
            filter: {
                [field]: e.target.value
            }
        });

        if (e.type === 'click') {
            this.props.onSearch({});
        }
    };

    onFilter = () => {
        this.props.onSearch(this.state.filter)
    };

    render() {
        const { filter } = this.state;
        const { t } = this.props;

        return (
            <Search
                allowClear={true}
                className="a-input--search-inline-title _input-search-bag-area"
                placeholder={t('bag_area:find_area')}
                value={filter.name}
                onChange={this.onChangeInput.bind(this, 'name')}
                onPressEnter={this.onFilter}
            />
        );
    }
}

export default withTranslation()(Filter);
