import React from 'react';
import { Table } from 'antd';
import { get, isArray, isString, map, uniqueId } from 'lodash';
import { withTranslation } from 'react-i18next';

const UploadResultTable = ({ t, uploadResult }) => {
    const getTranslatedReasonText = text => {
        if (isString(text)) {
            return t(`package:upload_file.fail_reason.create.${text}`);
        }
    
        const key = Object.keys(text)[0];
        return t(`package:upload_file.fail_reason.create.${key === "error" ?  text[key] : key}`, {
            value: Array.isArray(text[key]) ? text[key].join(', ') : text[key]
        });
    };

    return (
        <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
            <div className="mb-4">
                {`${t('package:upload_file.success_total')}: ${get(uploadResult, 'total_success', 0)}`}
            </div>
            <div className="a-text--orange mb-4">
                {`${t('package:upload_file.warning_total')}: ${get(uploadResult, 'total_warning', 0)}`}
            </div>
            <div className="a-text--red mb-4">
                {`${t('package:upload_file.failed_total')}: ${get(uploadResult, 'total_fail', 0)}`}
            </div>
            <div className="a-table--responsive">
                <Table
                    className="a-table--custom-table"
                    columns={[{
                        title: t('line'),
                        key: 'line',
                        dataIndex: 'line'
                    }, {
                        title: t('package'),
                        key: 'code',
                        dataIndex: 'code',
                        render: text => ({
                            children: text,
                            props: {
                                className: 'a-text--nowrap'
                            }
                        })
                    }, {
                        title: t('error'),
                        key: 'reason',
                        dataIndex: 'reason',
                        render: (text, record) => (
                            <span>
                                {getTranslatedReasonText(text ? text : get(record, "error", ""))}
                            </span>
                        )
                    }]}
                    dataSource={isArray(uploadResult) ? map(uploadResult, item => ({...item, type: 'fail'})) : [
                        ...map(get(uploadResult, 'fails', []), fail => ({
                            ...fail,
                            type: 'fail'
                        })),
                        ...map(get(uploadResult, 'warnings', []), warning => ({
                            ...warning,
                            type: 'warning'
                        }))
                    ]}
                    pagination={false}
                    rowClassName={record => {
                        switch (record.type) {
                            case 'error': {
                                return 'has-error';
                            }
                            case 'warning': {
                                return 'has-warning';
                            }
                            default: {
                                return '';
                            }
                        }
                    }}
                    rowKey={() => uniqueId()}
                />
            </div>
        </div>
    );
};

export default withTranslation()(UploadResultTable);
