import { get } from 'lodash'
import { combineReducers } from 'redux'

import { PACKING_PACKAGE_LASTMILE, PACKING_PACKAGE_LASTMILE_DETAIL } from './constants'
import { SOUND_CONSTANT } from './../../Sound/constants'

const failedPackedPackageLastmileCount = (state = 0, action) => {
    switch (action.type) {
        case PACKING_PACKAGE_LASTMILE.SUCCESS: {
            let newState = state
            const soundNotices = get(action.payload, 'notices.sound', [])

            for (const notice of soundNotices) {
                if (notice.includes(SOUND_CONSTANT.PACKAGED.toLowerCase())) {
                    newState += 1
                }
            }
            return newState
        }
        case PACKING_PACKAGE_LASTMILE.FAILED: {
            return state + 1
        }
        default: {
            return state
        }
    }
}

const packedPackageLastmile = (state = 0, action) => {
    switch (action.type) {
        case PACKING_PACKAGE_LASTMILE.SUCCESS: {
            return get(action, 'payload.response.data') || {}
        }

        default: {
            return get(action, 'payload.response.data') || {}
        }
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case PACKING_PACKAGE_LASTMILE.REQUEST:
            return true
        case PACKING_PACKAGE_LASTMILE.SUCCESS:
        case PACKING_PACKAGE_LASTMILE.FAILED:
            return false
        default:
            return false
    }
}

const detailBagLastmile = (state={}, action) => {
    switch (action.type) {
        case PACKING_PACKAGE_LASTMILE_DETAIL.SUCCESS: {
            return get(action, 'payload') 
        }

        default: {
            return  state
        }
    }
}

const loadingDetail = (state = false, action) => {
    switch (action.type) {
        case PACKING_PACKAGE_LASTMILE_DETAIL.REQUEST:
            return true
        case PACKING_PACKAGE_LASTMILE_DETAIL.SUCCESS:
        case PACKING_PACKAGE_LASTMILE_DETAIL.FAILED:
            return false
        default:
            return false
    }
}

export default combineReducers({
    failedPackedPackageLastmileCount,
    packedPackageLastmile,
    loading,
    detailBagLastmile,
    loadingDetail,
})
