import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.EDIT_SHIPPING_PARTNER.REQUEST, saga.editShippingPartner);
    yield takeLatest(ACTION.EDIT_SHIPPING_PARTNER.SUCCESS, saga.editShippingPartnerSuccess);
    yield takeLatest(ACTION.EDIT_SHIPPING_DOMESTIC_PARTNER.REQUEST, saga.editShippingDomesticPartner);
    yield takeLatest(ACTION.EDIT_SHIPPING_DOMESTIC_PARTNER.SUCCESS, saga.editShippingDomesticPartnerSuccess);
}
