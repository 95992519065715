/* eslint-disable */
import React from 'react';
import {Form, Select, Button, Row, Col, Icon, Input, DatePicker} from 'antd';

const Search = Input.Search;
const {RangePicker} = DatePicker;

const Option = Select.Option;

class FormSearch extends React.PureComponent {
    render() {
        return (
            <div className="a-content--search-header search-default" id="list-search">
                <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                    <Form className="a-form">
                        <Col lg={6} xl={6} xxl={4} >
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label= "Mã kiện"
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search _packages_search"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={3} xxl={2}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Kho kiểm kê"
                                labelCol={{span: 24}}>
                                <Select className="a-select a-select--search-content"
                                        defaultValue="Tất cả">
                                    <Option value="Tất cả">Tất cả</Option>
                                    <Option value="CNGZ">CNGZ</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Thời gian kiểm kê"
                                labelCol={{span: 24}}>
                                <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Thời gian đối soát"
                                labelCol={{span: 24}}>
                                <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Người kiểm kê"
                                labelCol={{span: 24}}>
                                <Select className="a-select a-select--search-content"
                                        defaultValue="Lê Hoàng Huyền Trang">
                                    <Option value="Lê Hoàng Huyền Trang">Lê Hoàng Huyền Trang</Option>
                                    <Option value="Lê Đặng Hoàng Vũ">Lê Đặng Hoàng Vũ</Option>
                                    <Option value="Lê Ngọc Ánh">Lê Ngọc Ánh</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Form>
                </Row>
                <div className="search--action">
                    <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                    <Button type="primary" htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list ">Tìm
                        kiếm</Button>
                </div>
            </div>
        )
    }
}

export default FormSearch;