import React, { Fragment, PureComponent } from 'react';
import { Alert, Button, Col, Layout, notification, Row, Typography } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { resetError, updateInventoryPackageStatus } from './../../../actions';
import apiService from './../../../apiService';
import { INVENTORY_TYPES } from './../../../constants';
import * as selectors from './../../../selectors';
import pageService from './../../../../Common/Page/pageService';
import { trans } from './../../../../../system/i18n';
import { convertBlobDataToExcel } from './../../../../../system/support/helpers';

import Disappear from './Disappear';
import FormSearch from './FormSearch';
import Normal from './Normal';
import Remainder from './Remainder';
import Tabs from './../../../../Common/components/Tabs/Tabs';
import TabPane from './../../../../Common/components/Tabs/TabPane';

const { Paragraph } = Typography;

const mapStateToProps = (state) => {
    return {
        inventoryPackages: selectors.getState(state, 'detail.inventory_packages', []),
        countInventoryPackages: selectors.getState(state, 'detail.count_inventory_packages', {}),
        loadingUpdatePackageStatus: selectors.getState(state, 'loadingUpdatePackageStatus', false),
        errors: selectors.getErrors(selectors.getState(state, 'errors', {})),
    };
};

const mapDispatchToProps = {
    resetError,
    updateInventoryPackageStatus
};

class Packages extends PureComponent {
    state = {
        downloadingDisappearPackages: false,
        downloadingScanningPackages: false
    };

    downloadDisappearPackages = async () => {
        const { inventory, warehouse } = this.props;

        this.setState({
            downloadingDisappearPackages: true
        });

        try {
            const { data } = await apiService.downloadDisappearPackages(get(inventory, 'id'));

            convertBlobDataToExcel(data, `disappear_packages_${get(warehouse, 'code')}`);

            notification.success({
                message: trans('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: trans('message.export_excel_failed')
            });
        }

        this.setState({
            downloadingDisappearPackages: false
        });
    };

    downloadScanningPackages = async () => {
        const { inventory, warehouse } = this.props;

        this.setState({
            downloadingScanningPackages: true
        });

        try {
            const { data } = await apiService.downloadScanningPackages(get(inventory, 'id'));

            convertBlobDataToExcel(data, `scanning_packages_${get(warehouse, 'code')}`);

            notification.success({
                message: trans('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: trans('message.export_excel_failed')
            });
        }

        this.setState({
            downloadingScanningPackages: false
        });
    };

    componentDidMount() {
        pageService.setTitle(trans('inventory:comparing.package'));
    }

    render() {
        const { downloadingDisappearPackages, downloadingScanningPackages } = this.state;
        const { inventory, loading, normals, remainders } = this.props;

        return (
            <Layout className="inventory-process gflex gflex-direction-column height-not-header">
                <FormSearch {...this.props} inventoryType={INVENTORY_TYPES.PACKAGE.key} />
                {!!(get(inventory, 'ignore_package_in_bag') || get(inventory, 'ignore_services.length')) && (
                    <Alert
                        className="a-alert--warning amr-24 amb-16"
                        type="warning"
                        message={(
                            <>
                                {!!get(inventory, 'ignore_package_in_bag') && (
                                    <Paragraph className="mb-0">
                                        {trans('inventory:ignore_package_in_bag')}
                                    </Paragraph>
                                )}
                                {!!get(inventory, 'ignore_services.length') && (
                                    <Paragraph className="mb-0">
                                        {trans('inventory:ignore_services_detail', {
                                            services: inventory.ignore_services.map((service, index) => (
                                                <Fragment key={service.id}>
                                                    <span className={clsx({
                                                        'a-text--green': service.type === 'consign'
                                                    })}>
                                                        {service.name}
                                                    </span>
                                                    {index !== inventory.ignore_services.length - 1 && (
                                                        <span>
                                                            {', '}
                                                        </span>
                                                    )}
                                                </Fragment>
                                            ))
                                        })}
                                    </Paragraph>
                                )}
                            </>
                        )}
                    />
                )}
                <Row gutter={{lg: 24, xl: 24, xxl: 24}} className="aflex-1 gflex min-h-0">
                    <Col lg={12} xl={12} xxl={12} className="aheight-100 gflex gflex-direction-column">
                        <Disappear
                            {...this.props}
                            downloadingDisappearPackages={downloadingDisappearPackages}
                            onDownloadDisappearPackagesButtonClick={this.downloadDisappearPackages}
                        />
                    </Col>
                    <Col lg={12} xl={12} xxl={12} className="aheight-100 gflex gflex-direction-column">
                        <Tabs
                            className="a-tabs a-tabs--container"
                            defaultActiveKey="remainder"
                            rightTopBarContent={(
                                <Button loading={downloadingScanningPackages} onClick={this.downloadScanningPackages}>
                                    {trans('btn.export_excel')}
                                </Button>
                            )}
                        >
                            <TabPane
                                key="remainder"
                                tab={`${trans('inventory:remainder_list.package')} (${remainders.length})`}
                            >
                                <Remainder {...this.props} />
                            </TabPane>
                            <TabPane
                                key="normal"
                                tab={`${trans('inventory:normal_list.package')} (${normals.length})`}
                            >
                                <Normal
                                    loading={loading}
                                    normals={normals}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Layout>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Packages);
