export const GET_SCAN_BARCODE_HISTORY = {
    REQUEST: 'PACKAGE.GET_SCAN_BARCODE_HISTORY.REQUEST',
    SUCCESS: 'PACKAGE.GET_SCAN_BARCODE_HISTORY.SUCCESS',
    FAILED: 'PACKAGE.GET_SCAN_BARCODE_HISTORY.FAILED',
};

export const CLEAR_SCAN_BARCODE_HISTORY = {
    REQUEST: 'PACKAGE.CLEAR_SCAN_BARCODE_HISTORY.REQUEST',
    SUCCESS: 'PACKAGE.CLEAR_SCAN_BARCODE_HISTORY.SUCCESS',
    FAILED: 'PACKAGE.CLEAR_SCAN_BARCODE_HISTORY.FAILED',
};

export const ERROR_SCAN_BAG_NOT_VALID = 'ERROR_SCAN_BAG_NOT_VALID'; //Mã Bao không tồn tại
export const ERROR_SCAN_BAG = 'ERROR_SCAN_BAG'; //Lỗi quét bao
export const ERROR_SCAN_BAG_STATUS_OUT = 'ERROR_SCAN_BAG_STATUS_OUT'; //Lỗi quét bao ở trạng thái đã xuất
export const ERROR_WAREHOUSE_NOT_EXIST = 'WAREHOUSE_NOT_EXIST'; // Kho ko ton tai
export const ERROR_BARCODE_NOT_EXIST = 'BARCODE_NOT_EXIST'; // ma quet ko ton tai
export const ERROR_SCAN_PACKAGE_NOT_VALID = 'ERROR_SCAN_PACKAGE_NOT_VALID'; // ma quet ko hợp lệ
export const ERROR_SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE = 'SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE'; // Kiện không nằm trong kho
export const ERROR_SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE = 'SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE'; // Kiện đang nằm trong kho khác, kiểm tra lại hành động nhập
export const ERROR_SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE = 'SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE'; // Kiện đang nằm trong kho khác, kiểm tra lại hành động xuất
export const ERROR_SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE = 'SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE'; // Kiện đã nằm trong kho
export const ERROR_SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE = 'SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE'; // Kiện đã xuất khỏi kho
export const ERROR_SCAN_BAG_MULTIPLE_COMPLETED = 'ERROR_SCAN_BAG_MULTIPLE_COMPLETED'; // Bao đã ở trạng thái "Tới đích" thì quét ko thành công và hiển thị thông báo lỗi "Mã bao không hợp lệ do bao đã tới đích"
export const ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED = 'ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED'; // loi khi quet nhap bao nguyen da toi dich
export const ERROR_SCAN_BAG_PACKING = 'ERROR_SCAN_BAG_PACKING'; // loi khi quet bao chua hoan tat dong bao
export const ERROR_STATUS_ACTION = {
    VALUE_INVALID: 'STATUS_ACTION.VALUE_INVALID'
};
export const ERROR_REQUIRED_WAREHOUSE_DESTINATION = 'ERROR_REQUIRED_WAREHOUSE_DESTINATION';
export const ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE = 'ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE';
export const ERROR_NOT_SHIPMENT_PACKAGE = 'ERROR_NOT_SHIPMENT_PACKAGE';
export const ERROR_PACKAGE_NOT_WEIGHT = 'ERROR_PACKAGE_NOT_WEIGHT';
export const ERROR_SCAN_COMPLETED_BAG = 'ERROR_SCAN_COMPLETED_BAG';
export const ERROR_SCAN_UNCOMPLETED_PACKING_BAG = 'ERROR_SCAN_UNCOMPLETED_PACKING_BAG';
export const ERROR_MESSAGE = {
    MORE_ONE_PACKAGE: "MORE_ONE_PACKAGE"
}