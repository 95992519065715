import { combineReducers } from 'redux';
import lodash from 'lodash';

import * as ACTION from './constants';
import { UPDATE_TRANSPORT_STATUS } from './../ChangePackageTransportStatus/constants';
import { SET_PACKAGE_PAGE } from './../CreatePackageViaScan/constants';
import { DELETE_PACKAGE } from './../DeletePackage/constants';
import * as ConstantLink from './../LinkOrderPackage/constants';
import * as ConstantsUnlink from './../UnlinkOrder/constants';
import { UPDATE_ALONE_PACKAGE } from './../UpdateAlonePackage/constants';
import { UPDATE_PACKAGE as UPDATE_RELATED_PACKAGE } from './../UpdateRelatedPackage/constants';
import { UPDATE_NOTE } from './../../Notes/constants';
import { getVar } from './../../../system/support/helpers';

const packageReducer = (state = null, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.FETCH_PACKAGE_DETAIL.SUCCESS:
        case ConstantsUnlink.UNLINK_ORDER.SUCCESS:
        case DELETE_PACKAGE.SUCCESS: {
            return {
                ...payload.package,
                ...lodash.omit(payload, 'package')
            };
        }
        case ACTION.SET_PACKAGE: {
            return payload;
        }
        case ACTION.CLEAR_STATE: {
            return null;
        }
        case SET_PACKAGE_PAGE: {
            return (state && state.id === payload.packageId) ? {
                ...state,
                current_page: payload.page
            } : {
                ...state
            };
        }
        case UPDATE_RELATED_PACKAGE.SUCCESS:
        case UPDATE_ALONE_PACKAGE.SUCCESS:
        case ConstantLink.LINK_ORDER_PACKAGE.SUCCESS:
        case UPDATE_TRANSPORT_STATUS.SUCCESS: {
            return {
                ...state,
                ...payload,
                ...payload.package
            };
        }
        case UPDATE_NOTE.SUCCESS: {
            const notes = getVar(state, 'notes', []);
            const index = notes.findIndex(note => note.id === getVar(payload, 'note.id'));

            if (index === -1) {
                return state;
            }

            const newNotes = [
                ...notes.slice(0, index),
                {
                    ...notes[index],
                    ...getVar(payload, 'note')
                },
                ...notes.slice(index + 1)
            ];

            return {
                ...state,
                notes: newNotes
            };
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGE_DETAIL.REQUEST: {
            return true;
        }
        case ACTION.FETCH_PACKAGE_DETAIL.SUCCESS:
        case ACTION.FETCH_PACKAGE_DETAIL.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    package: packageReducer,
    loading,
});
