import React from 'react';
import {Tabs} from "antd/lib/index";
import ListContentDeliveryRequest from './ListContentDeliveryRequest.js';
import ListContentDeliveryCustomerRequest from './ListDeliveryCustomerRequest';
import {withTranslation} from "react-i18next";
import {DELIVERY_REQUEST_STATUS} from "../constants";
import lodash from "lodash";
import PropTypes from 'prop-types';
import { showAliasAgency } from '../../../../system/support/helpers.js';
import { getState } from '../../../../system/store/index.js';
import permissions from '../../../Auth/permissions.js';

const TabPane = Tabs.TabPane;

class ListDeliveryNoteTab extends React.PureComponent {
    state = {
        status: DELIVERY_REQUEST_STATUS.STATUS_NEW
    };

    onChange(status) {
        let filter = {
            sort_direction: status === DELIVERY_REQUEST_STATUS.STATUS_NEW ? "asc" : "desc",
            sort_by: 'created_at',
            status,
            from: 'delivery_request',
            id_warehouse: localStorage.getItem("selectedDeliveryRequestWarehouse_" + this.props.user_id)
        };
        this.props.onSearch(filter);
        this.setState({status})
    }

    renderTab() {
        let {loading, deliveryRequests, deliveryCustomerRequests, t, onChangeFilter} = this.props;

        const showAlias = showAliasAgency(getState('setting.agencySetting'),  getState('setting.setting'), permissions.CONFIG_SHOW_AGENCY_ALIAS)

        return lodash.map(lodash.values(DELIVERY_REQUEST_STATUS), tab => tab === DELIVERY_REQUEST_STATUS.STATUS_NEW ?
            <TabPane tab={<span className={'_tab-' + tab}>{t('delivery_request:tab.' + tab)}</span>} key={tab}>
                <ListContentDeliveryCustomerRequest
                    loading={loading}
                    key={tab}
                    deliveryRequests={deliveryRequests}
                    pagination={this.props.pagination}
                    onSearch={this.props.onSearch}
                    filter={this.props.filter}
                    permissions={this.props.permissions}
                    showDetailDeliveryRequest={this.props.showDetailDeliveryRequest}
                    fetchListPackageExport={this.props.fetchListPackageExport}
                    listDeliveryCustomerRequest={this.props.listDeliveryCustomerRequest}
                    customers={this.props.customers}
                    fetchDeliveryRequests={this.props.fetchDeliveryRequests}
                    fetchDeliveryRequestsByCustomer={this.props.fetchDeliveryRequestsByCustomer}
                    onChangeFilter={onChangeFilter}
                    onSuccessUpdateDeliveryRequest={this.props.onSuccessUpdateDeliveryRequest}
                    showAlias={showAlias}
                />

            </TabPane> :
            <TabPane tab={<span className={'_tab-' + tab}>{t('delivery_request:tab.' + tab)}</span>} key={tab}>
                <ListContentDeliveryRequest loading={loading}
                                            deliveryRequests={deliveryRequests}
                                            key={tab}
                                            deliveryCustomerRequests={deliveryCustomerRequests}
                                            permissions={this.props.permissions}
                                            onSearch={this.props.onSearch}
                                            pagination={this.props.pagination}
                                            filter={this.props.filter}
                                            onChangeFilter={onChangeFilter}
                                            showDetailDeliveryRequest={this.props.showDetailDeliveryRequest}
                                            fetchListPackageExport={this.props.fetchListPackageExport}
                                            fetchDeliveryRequests={this.props.fetchDeliveryRequests}/>

            </TabPane>
        )
    }

    render() {

        return (
            <React.Fragment>
                <Tabs defaultActiveKey={this.props.filter.status}
                      className="a-tabs a-tabs--delivery-request"
                      onChange={this.onChange.bind(this)}
                      animated={false}>
                    {this.renderTab()}
                </Tabs>
            </React.Fragment>
        );
    }
}

ListDeliveryNoteTab.defaultProps = {
    deliveryRequests: [],
    deliveryCustomerRequests: [],
    loading: {},
    onSearch: () => {
    },
    onResetDeliveryRequest: () => {
    },
    onResetDeliveryCustomerRequest: () => {
    },
    user_id: '',
    pagination: {},
    filter: {},
    fetchDeliveryRequests: () => {
    },
    showDetailDeliveryRequest: () => {
    }
};

ListDeliveryNoteTab.propTypes = {
    deliveryRequests: PropTypes.array,
    deliveryCustomerRequests: PropTypes.array,
    loading: PropTypes.object,
    onSearch: PropTypes.func,
    onResetDeliveryRequest: PropTypes.func,
    onResetDeliveryCustomerRequest: PropTypes.func,
    user_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    pagination: PropTypes.object,
    filter: PropTypes.object,
    fetchDeliveryRequests: PropTypes.func,
    showDetailDeliveryRequest: PropTypes.func
};
export default withTranslation()(ListDeliveryNoteTab);
