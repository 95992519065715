import React from 'react';
import { find, get, isObject, replace } from 'lodash';
import moment from 'moment';

import { CODE_PARTNER } from './../../../../Auth/constants';

import Info from './Info';
import List from './List';

const Print = ({ deliveryNoteTransportWarehouse, t, agencySetting, partner }) => {
    const deliveryNoteDetail = get(deliveryNoteTransportWarehouse, 'delivery_note_transport_warehouse', {});
    const creator = get(deliveryNoteTransportWarehouse, 'creator', {});
    const day = moment().format('D');
    const month = moment().format('M');
    const year = moment().format('YYYY');
    let createdAtFormat = t('delivery_note:print.created_at_locale_format');
    createdAtFormat = replace(createdAtFormat, '%day%', day);
    createdAtFormat = replace(createdAtFormat, '%month%', month);
    createdAtFormat = replace(createdAtFormat, '%year%', year);

    let companyName = get(find(partner, partnerData => partnerData.code === CODE_PARTNER.ORGANIZATION_NAME), 'value', '');
    let companyAddr = get(find(partner, partnerData => partnerData.code === CODE_PARTNER.ORGANIZATION_ADDRESS), 'value', '');

    if (isObject(deliveryNoteTransportWarehouse.customer)) {
        companyName = get(find(agencySetting, item => item.id_agency === get(deliveryNoteTransportWarehouse.customer, 'id_agency') && item.code === 'META:ORGANIZATION_NAME'), 'value', '');
        companyAddr = get(find(agencySetting, item => item.id_agency === get(deliveryNoteTransportWarehouse.customer, 'id_agency') && item.code === 'META:ORGANIZATION_ADDRESS'), 'value', '');
    }

    return (
        <div className="print-delivery-note abg-white">
            <div className="print-delivery-note--header gflex gjustify-space-between">
                <div className="header-left">
                    <h2 className="a-text--uppercase print-header-title">{companyName}</h2>
                    <p className="print-header-description">{companyAddr}</p>
                </div>
                <div className="header-right atext-right">
                    <h2 className="a-text--uppercase print-header-title">{deliveryNoteDetail.code}</h2>
                    <p className="print-header-description">{createdAtFormat}</p>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <td>
                            <div className="page-header-space"></div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <Info
                            deliveryNoteTransportWarehouse={deliveryNoteTransportWarehouse}
                        />
                        <List
                            deliveryNoteTransportWarehouse={deliveryNoteTransportWarehouse}
                        />
                    </td>
                </tr>
                </tbody>

                <tfoot>
                <tr>
                    <td>
                        <div className="page-footer-space"></div>
                    </td>
                </tr>
                </tfoot>
            </table>
            <div className="print-delivery-note--footer">
                <div className="delivery-note--person">
                    <div className="person-action">
                        <p>{t('delivery_note:print.invoice_creator')}</p>
                        <span>{get(creator, 'name', '--')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Print;
