import { combineReducers } from 'redux';
import * as _ from 'lodash';

import * as ACTION from './constants';
import { BARCODE_TYPE, SCAN_BARCODE } from './../DeliveryNoteBarcode/constants';
import { addBarcode, removeBarcode } from './../DeliveryNoteBarcode/reducer';
import { makeModelStateFromApiRes } from './../../Common/services/helps';
import { STAGES, UPDATE_NOTE } from './../../Notes/constants';

const deliveryNote = (state = null, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_DELIVERY_NOTE.SUCCESS: {
            const { delivery_note_bags, delivery_note_packages } = action.payload;

            const barcodes = delivery_note_packages ? delivery_note_packages.map(packageRes => {
                let packageData = makeModelStateFromApiRes(packageRes, 'package');
                return packageData.id;
            }) : [];

            _.forEach(delivery_note_bags, (item) => {
                let bag = makeModelStateFromApiRes(item, 'bag');
                barcodes.push(bag.id);
            });

            return {
                ...action.payload,
                barcodes
            };
        }
        case ACTION.CLEAR_STATE: {
            return null;
        }
        default: {
            return state;
        }
    }
};

const loading = (state = {deliveryNote : false, onUpdate : false}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_NOTE.REQUEST: {
            return {
                ...state,
                deliveryNote : true
            };
        }
        case ACTION.FETCH_DELIVERY_NOTE.SUCCESS:
        case ACTION.FETCH_DELIVERY_NOTE.FAILED: {
            return {
                ...state,
                deliveryNote : false
            };
        }
        case ACTION.UPDATE_DELIVERY_NOTE.REQUEST: {
            return {
                ...state,
                onUpdate : true
            };
        }
        case ACTION.UPDATE_DELIVERY_NOTE.SUCCESS:
        case ACTION.UPDATE_DELIVERY_NOTE.FAILED: {
            return {
                ...state,
                onUpdate : false
            };
        }
        default: {
            return state;
        }
    }
};

const customerDetailInfo = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_CUSTOMER_DETAIL_INFO.SUCCESS: {
            return payload;
        }
        case ACTION.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loadingCustomerDetailInfo = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.GET_CUSTOMER_DETAIL_INFO.REQUEST: {
            return true;
        }
        case ACTION.GET_CUSTOMER_DETAIL_INFO.SUCCESS:
        case ACTION.GET_CUSTOMER_DETAIL_INFO.FAILED:
        case ACTION.CLEAR_STATE: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const barcodes = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.ADD_BARCODE: {
            return addBarcode(state, payload.code, payload);
        }
        case ACTION.REMOVE_BARCODE: {
            return removeBarcode(state, payload);
        }
        case SCAN_BARCODE.SUCCESS: {
            const { customer } = payload;
            const customerId = _.get(customer, 'id');
            let newState = state;

            const warning = payload.package_warning;
            const code = action.request.code;

            const data = {
                code,
                type: BARCODE_TYPE.PACKAGE,
                warning,
                ...payload
            };

            // If scan bag code
            if (_.isObject(payload.bag) && payload.bag.code === code) {
                data.type = BARCODE_TYPE.BAG;
                data.warning = payload.bag_warning;
            }

            if (!customerId) {
                return newState;
            }

            return addBarcode(state, code, data);
        }
        case ACTION.SET_BARCODES: {
            return payload;
        }
        case ACTION.CLEAR_STATE: {
            return [];
        }
        case UPDATE_NOTE.SUCCESS: {
            if (_.get(payload, 'stage') !== STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE) {
                return state;
            }

            const newState = state.map(barcode => {
                const notes = barcode.notes || [];
                const index = notes.findIndex(note => note.id === _.get(payload, 'note.id'));

                if (index === -1) {
                    return barcode;
                }

                const isRemoveNote = !_.includes(_.get(payload, 'note.stages'), STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE);
                const newNotes = isRemoveNote ? [
                    ...notes.slice(0, index),
                    ...notes.slice(index + 1)
                ] : [
                    ...notes.slice(0, index),
                    {
                        ...notes[index],
                        ..._.get(payload, 'note', {})
                    },
                    ...notes.slice(index + 1)
                ];

                return {
                    ...barcode,
                    notes: newNotes
                };
            });

            return newState;
        }
        default: {
            return state;
        }
    }
};

const customerBagPackages = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_CUSTOMER_BAG_PACKAGES.SUCCESS: {
            if (!_.isObject(payload)) {
                return state;
            }

            return {
                ...payload,
                bags: _.map(payload.bags, bag => ({
                    ...bag,
                    code: _.get(bag, 'bag.code'),
                    type: BARCODE_TYPE.BAG
                })),
                packages: _.map(payload.packages, pkg => ({
                    ...pkg,
                    code: _.get(pkg, 'package.code'),
                    type: BARCODE_TYPE.PACKAGE
                }))
            };
        }
        case ACTION.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loadingCustomerBagPackages = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.GET_CUSTOMER_BAG_PACKAGES.REQUEST: {
            return true;
        }
        case ACTION.GET_CUSTOMER_BAG_PACKAGES.SUCCESS:
        case ACTION.GET_CUSTOMER_BAG_PACKAGES.FAILED:
        case ACTION.CLEAR_STATE: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const errors = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.UPDATE_DELIVERY_NOTE.REQUEST: {
            return {};
        }
        case ACTION.UPDATE_DELIVERY_NOTE.FAILED: {
            return payload;
        }
        case ACTION.RESET_ERROR: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: _.omit(state.data, payload.field)
            };
        }
        case ACTION.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    deliveryNote,
    loading,
    customerDetailInfo,
    loadingCustomerDetailInfo,
    barcodes,
    customerBagPackages,
    loadingCustomerBagPackages,
    errors
});
