import { withTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import apiService from '../../apiService'
import { every, get } from 'lodash'
import { DELIVERY_REQUEST_STATUS } from '../../../DeliveryRequest/ListDeliveryRequest/constants'

function ConfirmDeliveryNoteSuccess({ t, deliveryNote, fetchDeliveryNote }) {
    const [isAllRequestComplete, setIsAllRequestComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const deliveryRequests = get(deliveryNote, 'delivery_requests', [])
        setIsAllRequestComplete(every(deliveryRequests, ['status', DELIVERY_REQUEST_STATUS.STATUS_COMPLETED]))
    }, [deliveryNote])
    const handleOk = () => {
        const deliveryNoteId = get(deliveryNote, 'id')
        setLoading(true)
        apiService
            .confirmDeliveryNote(deliveryNoteId)
            .then(res => {
                fetchDeliveryNote(deliveryNoteId)
                notification.success({ message: t('delivery_note:message.confirm_success') })
            })
            .catch(error => {
                const code = get(error, 'response.data.code')
                if (code === 'INPUT_INVALID') {
                    const data = get(error, 'response.data.data')
                    Object.entries(data).forEach(([key, value]) => {
                        return notification.error({
                            message: t(`delivery_note:message.${key}.${Object.keys(value)[0]}`),
                        })
                    })
                } else {
                    notification.error({ message: t('delivery_note:message.confirm_failed') })
                }
            })
            .finally(() => {
                setLoading(true)
            })
    }
    const handleClick = () => {
        Modal.confirm({
            title: t('delivery_note:message.warning_confirm'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => {
                handleOk()
            },
        })
    }
    return (
        <div>
            <Button
                // className="a-btn a-btn--transparent-white "
                type="primary"
                // ghost
                loading={loading}
                disabled={isAllRequestComplete}
                onClick={handleClick}>
                {t('delivery_note:btn.confirm_success')}
            </Button>
        </div>
    )
}
export default withTranslation()(ConfirmDeliveryNoteSuccess)
