import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as PACKING_ACTION  from "./../BagPacking/constants";

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_WEIGHT_REAL.REQUEST:
            return true;
        case ACTION.UPDATE_WEIGHT_REAL.SUCCESS:
        case ACTION.UPDATE_WEIGHT_REAL.FAILED:
        case PACKING_ACTION.CLEAR_PACKING_BAG_STATE:
            return false;
        default:
            return state;
    }
};

let errors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_WEIGHT_REAL.REQUEST:
        case ACTION.UPDATE_WEIGHT_REAL.SUCCESS:
        case PACKING_ACTION.CLEAR_PACKING_BAG_STATE:
        case ACTION.RESET_ERROR_UPDATE_WEIGHT_REAL_BAG:
            return {};
        case ACTION.UPDATE_WEIGHT_REAL.FAILED:
            return {...action.payload};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    errors
});
