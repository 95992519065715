import {combineReducers} from "redux";
import * as ACTION from '../constants';

let detail = (state = {}, action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.SUCCESS:
            let warehouseStaff = payload;
            return warehouseStaff;
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.REQUEST:
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.FAILED:
        case ACTION.CLEAR_STATE:
            return {};
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.REQUEST:
            return true;
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.SUCCESS:
        case ACTION.FETCH_DETAIL_WAREHOUSE_STAFF.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state
    }
};

export default combineReducers({
    loading, detail
})