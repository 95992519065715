import React from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Bag from './Bag';
import PackageInfo from './PackageInfo';
import { BARCODE_TYPE } from '../../../DeliveryNoteBarcode/constants';

class DeliveryNoteBagList extends React.Component {
    state = {
        scrolltable: false
    };

    componentWillUnmount () {
        this.props.clearState();
    }


    onScrollTable = (e) => {
        let element = e.target;
        if (element.scrollWidth - 1 - element.scrollLeft === element.clientWidth) {
            this.setState({scrolltable: false});
        } else {
            this.setState({scrolltable: true});
        }
    };

    render () {
        const { t, packages, bags } = this.props;
        return (
            <div className={"amr-24 amr-16-lg a-bgwhite aheight-100"}>
                <div className="a-content__top a-content__top--page-list mt-0 mr-0">
                    <div className="a-content--page-list__title">
                        <h3>{t("delivery_note:label.list_package_export")} ({packages.length + bags.length})</h3>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list apt-16 mr-0">
                    {this.renderBarcodes()}
                </div>
            </div>
        );
    }

    renderBarcodes () {
        const { scrolltable } = this.state;
        const { bags, packages, t, removeBarcode} = this.props;
        if (!bags.length && !packages.length) {
            return (
                <div className="content-list--empty">
                    <img src={require('./../../../../../resources/images/boxempty.png')} alt="" />
                    <span className="info a-text--uppercase">CHƯA CÓ MÃ QUÉT NÀO </span>
                </div>
            );
        }

        return (
            <div className="a-table--responsive" onScroll={this.onScrollTable}>
                <table className="a-table a-table--isdata--warning-error">
                    <thead>
                    <tr>
                        <th>{t("scan_code")}</th>
                        <th>{t("delivery_note:label.id_export")}</th>
                        <th></th>
                        <th>{t('weight')}</th>
                        <th>{t('package:label.volume')}</th>
                        <th className={clsx('sticky-right', {
                            'box-shadow': scrolltable
                        })} />
                    </tr>
                    </thead>
                    {bags.map((bag, index) => (
                        <Bag
                            {...this.props}
                            key={index}
                            bag={bag}
                            onRemove={() => removeBarcode(index, BARCODE_TYPE.BAG)}
                            scrollTable={this.state.scrolltable}
                        />
                    ))}
                    {packages.map((item, index) => (
                        <PackageInfo
                            {...this.props}
                            key={index}
                            packageInfo={item}
                            onRemove={() => removeBarcode(index, BARCODE_TYPE.PACKAGE)}
                            scrollTable={this.state.scrolltable}
                        />
                    ))}
                </table>
            </div>
        );
    }
}

DeliveryNoteBagList.defaultProps = {
    bags: [],
    removeBarcode: () => {
    },
    clearState: () => {
    },
};

DeliveryNoteBagList.propTypes = {
    bags: PropTypes.array,
    removeBarcode: PropTypes.func,
    clearState: PropTypes.func,
};

export default withTranslation()(DeliveryNoteBagList);
