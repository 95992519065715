import * as ACTION from './constants';
import * as saga from './saga';
import {all, fork, takeLatest} from 'redux-saga/effects';
import commonLocationWatcher from './CommonLocation/sagaWatcher';

export default function*() {
    yield takeLatest(ACTION.FETCH_LIST_LOCATION.REQUEST, saga.fetchLocation);
    yield takeLatest(ACTION.FETCH_LIST_LOCATION.FAILED, saga.fetchLocationFailed);
    yield takeLatest(ACTION.FETCH_COUNTRY.REQUEST, saga.fetchCountry);
    yield takeLatest(ACTION.FETCH_COUNTRY.FAILED, saga.fetchCountryFailed);
    yield takeLatest(ACTION.FETCH_PROVINCE.REQUEST, saga.fetchProvince);
    yield takeLatest(ACTION.FETCH_PROVINCE.FAILED, saga.fetchProvinceFailed);
    yield takeLatest(ACTION.FETCH_DISTRICT.REQUEST, saga.fetchDistrict);
    yield takeLatest(ACTION.FETCH_DISTRICT.FAILED, saga.fetchDistrictFailed);
    yield takeLatest(ACTION.FETCH_WARD.REQUEST, saga.fetchWard);
    yield takeLatest(ACTION.FETCH_WARD.FAILED, saga.fetchWardFailed);
    yield all([
        fork(commonLocationWatcher),
    ]);
}
