import React, {Component} from 'react';
import {
    Row,
    Col,
} from 'antd/lib/index';
import { Skeleton } from 'antd';

class FormCustomerLoading extends Component {
    render() {
        return (
            <Row gutter={{lg: 24, xl: 32}} className="info-customer-view ml-0 mr-0">
                <Col lg={{span: 10}} xl={{span: 7}} xxl={{span: 7}}>
                    <div className="info">
                        <Skeleton avatar active paragraph={{ rows: 1 }} className="askeleton-avatar" />

                    </div>
                </Col>
                <Col lg={{span: 14}} xl={{span: 17}} xxl={{span: 17}}>
                    <Row gutter={{lg: 24, xl: 32}}>
                        <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 8}} className="info--group mb-0">
                            <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                        </Col>
                        {/*<Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}} className="info--group mb-0">*/}
                        {/*    <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>*/}
                        {/*</Col>*/}
                        {/*<Col lg={{span: 12}} xl={{span: 9}} xxl={{span: 8}} className="info--group mb-0 mt-lg-5">*/}
                        {/*    <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>*/}
                        {/*</Col>*/}
                    </Row>

                </Col>

            </Row>
        );
    }
}

export default FormCustomerLoading;
