import React from 'react';
import PropTypes from 'prop-types';
import {Collapse, Radio, Checkbox, Spin} from 'antd';
import lodash from 'lodash';

import Icon from './../../../../components/icons/Icon';

const { Panel } = Collapse;

class FormServices extends React.PureComponent {
    state = {
        activeKey: []
    };

    /**
     * Event thay đổi service
     *
     * @param {Object} serviceGroup
     * @param {Object} service
     * @param event
     */
    onChange(serviceGroup, service, event) {
        let values = this.calculateValues(this.props.values, service, event.target.checked, serviceGroup);
        this.props.onChange(values);
    }

    /**
     * Tính toán values khi chọn/bỏ chọn service
     *
     * @param {Array} values
     * @param {Object} service
     * @param {Boolean} selected
     * @param {Object} serviceGroup
     * @return {Array}
     */
    calculateValues(values, service, selected, serviceGroup) {
        const { consignServiceGroups, serviceGroups, singleType } = this.props;
        let newValues = [];

        // Bỏ chọn service
        if (!selected) {
            newValues = values.filter(value => value !== service.id);
        } else if (serviceGroup.multi_select) { // Chọn service mà group cho phép multi select
            newValues = [...values, service.id];
        } else {
            // Bỏ chọn radio service
            if (values.includes(service.id)) {
                newValues = values.filter(value => value !== service.id)
            } else {
                // Chọn service mà group không cho phép multi select
                newValues = this.props.values.filter(value => !this.inServices(value, serviceGroup.services));

                newValues = [
                    ...newValues,
                    service.id
                ];
            }
        }

        if (singleType) {
            const { type } = service;

            let selectedServiceGroups = serviceGroups;

            if (type === 'consign') {
                selectedServiceGroups = consignServiceGroups;
            }

            const services = lodash.flatMap(lodash.map(selectedServiceGroups, 'services'));
            const filteredServices = services.filter(service => newValues.includes(service.id));

            newValues = lodash.map(filteredServices, 'id');
        }

        return newValues;
    }

    /**
     * Kiểm tra serviceId có nằm trong danh sách services hay không
     *
     * @param {Number} serviceId
     * @param {Array} services
     * @return {boolean}
     */
    inServices(serviceId, services) {
        return lodash.map(services, 'id').includes(serviceId);
    }

    handleChangePanel = key => {
        this.setState({
            activeKey: key
        });
    };

    // componentDidMount() {
    //     const { types } = this.props;

    //     this.setState(prevState => {
    //         const { activeKey } = prevState;
    //         let newActiveKey = activeKey;

    //         if (!Array.isArray(types) || types.includes('normal')) {
    //             newActiveKey = ['normal'];
    //         } else if (!Array.isArray(types) || types.includes('consign')) {
    //             newActiveKey = ['consign'];
    //         }

    //         return {
    //             activeKey: newActiveKey
    //         };
    //     });
    // }

    render() {
        const { activeKey } =  this.state;
        const { consignServiceGroups, loading, serviceGroups, t, types } = this.props;
        return (
            <Spin spinning={loading}>
                <Collapse accordion activeKey={activeKey} className="a-service" onChange={this.handleChangePanel}>
                    {!Array.isArray(types) || types.includes('normal') ? (
                        <Panel className="_normal-services" header={t('service:label.select_normal_services')} key="normal">
                            <div className="service--body">
                                {serviceGroups.map(serviceGroup => (
                                    <div key={serviceGroup.code} className={`service--block _normal-service-group _normal-service-group-${serviceGroup.id}`}>
                                        <h4 className="title--block">
                                            {t(`package:service_groups.${serviceGroup.code}`)}
                                            <div className="service--block--body">
                                                <ul>
                                                    {serviceGroup.services.map(service => (
                                                        <li key={service.id} className={`_service_${service.id}`}>{this.renderService(serviceGroup, service)}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </h4>
                                    </div>
                                ))}
                            </div>
                        </Panel>
                    ) : null}

                    {!Array.isArray(types) || types.includes('consign') ? (
                        <Panel className="_consign-services" header={t('service:label.select_consign_services')} key="consign">
                            <div className="service--body">
                                {consignServiceGroups.map(serviceGroup => (
                                    <div key={serviceGroup.code} className={`service--block _consign-service-group _consign-service-group-${serviceGroup.id}`}>
                                        <h4 className="title--block">
                                            {t(`package:service_groups.${serviceGroup.code}`)}
                                            <div className="service--block--body">
                                                <ul>
                                                    {serviceGroup.services.map(service => (
                                                        <li key={service.id} className={`_service_${service.id}`}>{this.renderService(serviceGroup, service)}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </h4>
                                    </div>
                                ))}
                            </div>
                        </Panel>
                    ) : null}
                </Collapse>
            </Spin>
        );
    }

    renderService(serviceGroup, service) {
        const Service = serviceGroup.multi_select ? Checkbox : Radio;

        return (
            <Service
                className="label--service"
                checked={this.props.values.includes(service.id)}
                disabled={this.props.disabled}
                onClick={this.onChange.bind(this, serviceGroup, service)}
            >
                <Icon className="mr-2" type={service.icon} />
                {service.name}
            </Service>
        );
    }
}

FormServices.defaultProps = {
    loading: false,
    serviceGroups: [],
    values: [],
    onChange: () => {
    },
    disabled: false
};

FormServices.propTypes = {
    loading: PropTypes.bool,
    serviceGroups: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default FormServices;