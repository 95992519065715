import React from 'react';

const Box6 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0.002)">
        <path className="c" d="M9.665,7.774a.333.333,0,0,0-.491-.293l-4.1,2.21a.167.167,0,0,1-.154,0L1.881,8.176a.167.167,0,0,1,0-.3L5.315,6.029a.667.667,0,1,0-.632-1.174L.351,7.188a.667.667,0,0,0-.351.6V12.94a.333.333,0,0,0,.176.294l4.666,2.5A.333.333,0,0,0,5,15.773a.337.337,0,0,0,.167-.044L9.5,13.23a.333.333,0,0,0,.167-.289Z" />
        <path className="c" d="M15.465,7.454a.166.166,0,0,1-.133-.163V2.108A1.333,1.333,0,0,0,14,.775h-.333a.165.165,0,0,1-.129-.061L13.494.665a1.333,1.333,0,0,0-1.887-.1L7.9,3.919a.667.667,0,0,0-.047.943l.019.021a.167.167,0,0,1-.013.237L5.989,6.77a.5.5,0,1,0,.667.749l1.9-1.677a.14.14,0,0,1,.2.011.667.667,0,0,0,.943.049L13.4,2.55a1.339,1.339,0,0,0,.279-.354.167.167,0,0,1,.147-.088h.009A.167.167,0,0,1,14,2.275V7.441a1.333,1.333,0,0,0,1.333,1.333.667.667,0,0,0,.133-1.32Z" />
      </g>
    </g>
  </svg>
);

export default Box6;
