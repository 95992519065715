import React, { memo } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const Notice = ({ className, notice, t }) => {
    return (
        <Paragraph className={clsx(className, 'notice-item')}>{t(`notice:${notice}`)}</Paragraph>
    );
};

export default memo(withTranslation()(Notice));
