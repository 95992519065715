export const SCAN_BARCODE_FORM = {
    REQUEST: 'SCAN_BARCODE_FORM.REQUEST',
    SUCCESS: 'SCAN_BARCODE_FORM.SUCCESS',
    FAILED: 'SCAN_BARCODE_FORM.FAILED'
};
export const SCAN_RECEIVED = {
    REQUEST: 'SCAN_BARCODE.SCAN_RECEIVED.REQUEST',
    SUCCESS: 'SCAN_BARCODE.SCAN_RECEIVED.SUCCESS',
    FAILED: 'SCAN_BARCODE.SCAN_RECEIVED.FAILED'
};

export const SCAN_RECEIVED_BAG = {
    REQUEST: 'SCAN_BARCODE.SCAN_RECEIVED_BAG.REQUEST',
    SUCCESS: 'SCAN_BARCODE.SCAN_RECEIVED_BAG.SUCCESS',
    FAILED: 'SCAN_BARCODE.SCAN_RECEIVED_BAG.FAILED'
};

export const TRANSPORT_WAREHOUSE = {
    SUCCESS: 'TRANSPORT_WAREHOUSE.SUCCESS',
    FAILED: 'TRANSPORT_WAREHOUSE.FAILED'
}
export const MAX_ITEM_PER_PAGE = 300;
