import lodash from 'lodash';
import Model from '../../Common/base/Model';
import {BAG_SHIPPING_TYPE} from '../BagShippingType/constants';

class Bag extends Model {
    get volumeAsCm() {
        return lodash.round(this.volume * 1000000, 3);
    }

    get shippingType() {
        if (this.id_shipping_user) {
            return BAG_SHIPPING_TYPE.USER;
        }

        if (this.id_shipping_partner) {
            return BAG_SHIPPING_TYPE.PARTNER;
        }

        return null;
    }

    get shipper() {
        switch (this.shippingType) {
            case BAG_SHIPPING_TYPE.USER:
                return this.shipping_user;

            case BAG_SHIPPING_TYPE.PARTNER:
                return this.shipping_partner;

            default:
                return null;
        }
    }
}

export default Bag;