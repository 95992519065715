import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Form} from 'antd';
import lodash from 'lodash';
import UpdateNotMapOrderPackageContainer from "../../UpdateAlonePackage/UpdateNotMapOrderPackageContainer";
import UpdatePackageParam from "../../UpdatePackageParams/UpdatePackageParamContainer";

class FormNotMapOrderPackage extends React.PureComponent {
    state = {
        isEdit: false
    };

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        if (!lodash.isEmpty(props.packageData) && state.isEdit === false && props.packageData.type === "ALONE") {
            nextState.isEdit = true;
        }

        return nextState;
    }

    /**
     * handle action show update package's info form
     */
    onUpdate() {
        this.setState({isEdit: true});
    }

    render() {
        const {form, t, packageData} = this.props;
        const {isEdit} = this.state;

        if (isEdit) {
            return (
                <UpdateNotMapOrderPackageContainer packageData={packageData} />
            );
        }

        if (!lodash.get(packageData, 'permissions.can_update')) {
            return null;
        }

        return (
            <>
                <UpdatePackageParam className="amt-16" form={form} package={packageData} />
                <div className="a-collapse--package__body is_empty apt-8">
                    <Button
                        className="a-btn a-btn--primary a-btn--create-bill-code _create_package_not_map"
                        htmlType="button"
                        type="primary"
                        onClick={this.onUpdate.bind(this)}
                    >
                        {t('package:label.insert_info')}
                    </Button>
                </div>
            </>
        );
    }
}

FormNotMapOrderPackage.defaultProps = {
    packageData: {},
};

FormNotMapOrderPackage.propTypes = {
    packageData: PropTypes.object,

};

export default Form.create({})(withTranslation()(FormNotMapOrderPackage));
