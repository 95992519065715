export const FETCH_PACKAGES = {
    REQUEST: 'PACKAGE.LIST_PACKAGE.FETCH_PACKAGES.REQUEST',
    SUCCESS: 'PACKAGE.LIST_PACKAGE.FETCH_PACKAGES.SUCCESS',
    FAILED: 'PACKAGE.LIST_PACKAGE.FETCH_PACKAGES.FAILED',
};
export const EXPORT_PACKAGE_LIST = {
    REQUEST: 'PACKAGE.LIST_PACKAGE.EXPORT_PACKAGE_LIST.REQUEST',
    SUCCESS: 'PACKAGE.LIST_PACKAGE.EXPORT_PACKAGE_LIST.SUCCESS',
    FAILED: 'PACKAGE.LIST_PACKAGE.EXPORT_PACKAGE_LIST.FAILED',
};

export const PACKAGE_COLUMN = {
    CODE: 'code',
    REPORTER: 'reporter',
    DAMAGED_AT: 'damaged_at',
    STATUS_DAMAGED: 'status_damaged',
    DAMAGED_REASONS: 'damaged_reasons',
    COMPLAINT_SELLER: 'complaint_seller',
    BARCODE: 'barcode',
    ORDER: 'order',
    BAG_CODE: 'bag_code',
    WEIGHT: 'weight_net',
    VOLUME: 'volume',
    NOTE:"note",
    CREATE_TIME: 'created_at',
    ORDER_LINKED_AT: 'order_linked_at',
    TRANSPORTING_AT: 'transporting_at',
    READY_FOR_DELIVERY_AT: 'ready_for_delivery_at',
    STATE: 'status_warehouse',
    STATUS_TRANSPORT: 'status_transport',
    AGENCY: 'agency',
    USERNAME: 'username',
    PROPERTIES: 'properties',
    PRINTED_OFFICIAL_STAMP: 'printed_official_stamp',
    BOX_SERVICES: 'box_services',
    SHIPPING_SERVICES: 'shipping_services',
    EXTEND_SERVICES: 'extend_services',
    CURRENT_WAREHOUSE: 'current_warehouse',
    DESTINATION_WAREHOUSE: 'destination_warehouse',
    SHIPPING_PARTNER: 'shipping_partner',
    DIMENSION: 'dimension',
    INPUT_WAREHOUSE: 'input_warehouse',
    IS_CHECKED: 'is_checked',
    DELIVERY_NOTE_TRANSPORT_WAREHOUSE_CODE: 'delivery_note_transport_warehouse_code'
};


export const LIST_PACKAGE_OBJECT = {
    ALL: 'ALL',
    DAMAGED: 'DAMAGED',
    DELETE: 'DELETE',
    SHIPMENT: 'SHIPMENT',
    NOT_IN_BAG: 'NOT_IN_BAG'
};
