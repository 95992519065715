import React from 'react';
import { Row } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';

import Log from './../../../../Common/Log/LogContainer';
import pageService from './../../../../Common/Page/pageService';
import { DELIVERY_OBJECT } from './../../../constants';

import Info from './Info';
import LoadingSkeleton from './LoadingSkeleton';
import SearchableComponent from './../../../../Common/components/SearchableComponent';
import DeliveryNoteBarcodes from './../../../DeliveryNoteBarcode/ListDeliveryNoteBarcodeContainer';

class Detail extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();

        const { t } = this.props;

        pageService.setTitle(t('delivery_note_return_detail'));
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { deliveryNoteReturn, loading } = this.props;

        if (
            get(deliveryNoteReturn, 'delivery_note_return.id') !== get(prevProps.deliveryNoteReturn, 'delivery_note_return.id') ||
            loading !== prevProps.loading
        ) {
            this.updatePageTitle();
        }
    }

    componentWillUnmount() {
        const { clearState } = this.props;

        clearState();
    }

    onChangeFilter = filter => {
        const { getDetail } = this.props;

        getDetail(filter.id);
    };

    updatePageTitle = () => {
        const { deliveryNoteReturn, t } = this.props;
        const title = `${t('delivery_note_return_detail')}${
            get(deliveryNoteReturn, 'delivery_note_return.code') ? ` - ${deliveryNoteReturn.delivery_note_return.code}` : ''
        }`;

        pageService.setTitle(title);
    };

    handleSuccessExportDeliveryNote = data => {
        const { setDetail } = this.props;

        setDetail(data);
    };

    handlePrint = event => {
        const { printDeliveryNoteReturn } = this.props;

        event.stopPropagation();
        printDeliveryNoteReturn();
    };

    render() {
        const { deliveryNoteReturn, loading, t } = this.props;
        const havePermissions = !!(
            get(deliveryNoteReturn, 'permissions.can_update') ||
            get(deliveryNoteReturn, 'permissions.can_export') ||
            get(deliveryNoteReturn, 'permissions.can_cancel') ||
            (get(deliveryNoteReturn, 'delivery_note_return.status') !== 'EXPORTED' &&
                get(deliveryNoteReturn, 'delivery_note_return.status') !== 'CANCELED')
        );

        return (
            <Row type="flex">
                <div
                    className={clsx('a-content--scroll-content pt-0 gflex gflex-direction-column', {
                        'not-navigation-fixed': !havePermissions,
                    })}
                >
                    {loading ? <LoadingSkeleton /> : <Info deliveryNoteReturn={deliveryNoteReturn} />}

                    <DeliveryNoteBarcodes
                        deliveryNoteReturn={deliveryNoteReturn}
                        isDetailPage
                        object={DELIVERY_OBJECT.RETURN}
                        onPrint={this.handlePrint}
                        onSuccessExportDeliveryNote={this.handleSuccessExportDeliveryNote}
                    />
                </div>

                <div
                    className={clsx('aml-24 alog', {
                        'not-navigation-fixed': !havePermissions,
                    })}
                >
                    <div className="title">
                        <span>{t('label.log')}</span>
                    </div>
                    <Log
                        delayTime={0}
                        object="delivery-note-returns"
                        objectId={get(deliveryNoteReturn, 'delivery_note_return.id')}
                    />
                </div>
            </Row>
        );
    }
}

export default Detail;
