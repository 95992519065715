import React from 'react';
import PropTypes from 'prop-types';
import authService from '../../Auth/authService';
import settingService from './settingService';
import ListPackageSetting from './components/ListPackageSetting';
import {LIST_PACKAGE_OBJECT} from "./constants";

class ListPackageSettingContainer extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            setting: [],
            page: props.page,
            object: props.object,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextState.object !== nextProps.object) {
            nextState.object = nextProps.object;
            settingService.getOption(nextProps.object);
            let setting = settingService.getPageSetting(authService.user('id'), nextProps.object);
            nextProps.onChange(setting);
        }
        return nextState
    }

    componentDidMount() {
        let setting = settingService.getUserSetting(this.getUserId(), this.getPage());
        this.setState({setting});
        this.props.onChange(setting);
    }

    onChangeSetting = setting => {
        settingService.updateUserSetting(this.getUserId(), setting, this.getPage());
        this.setState({setting});
        this.props.onChange(setting);
    };

    getUserId() {
        return authService.user('id');
    }

    getPage() {
        return this.props.object
    }

    render() {
        const { setting } = this.state;
        const { isCurrentPage, isOpenListSetting, object, onOpenListSetting } = this.props;

        return (
            <ListPackageSetting
                options={settingService.options}
                values={setting}
                isOpen={isOpenListSetting}
                onChange={this.onChangeSetting}
                onOpenSetting={onOpenListSetting}
                isCurrentPage={isCurrentPage}
                object={object}
            />
        );
    }
}

ListPackageSettingContainer.defaultProps = {
    object: LIST_PACKAGE_OBJECT.ALL,
    isCurrentPage: LIST_PACKAGE_OBJECT.ALL,
    onChange: () => {
    },
    isOpenListSetting: false
};

ListPackageSettingContainer.propTypes = {
    object: PropTypes.string,
    onChange: PropTypes.func,
    onOpenListSetting: PropTypes.func,
    isOpenListSetting: PropTypes.bool,
    isCurrentPage: PropTypes.string
};

export default ListPackageSettingContainer;

