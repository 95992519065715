import React from 'react';
import {Modal, Table} from "antd";
import bagIcon from '../../../../resources/images/bag-white.svg';
import {formatVolume, formatWeight} from "../../../../system/support/helpers";

import Link from './../../../../system/routing/Link';

class PopupListPackageByBag extends React.Component {

    render() {
        const {visible, t, packages, bag} = this.props;

        const columns = [
            {
                className: 'a-text--nowrap',
                title: t('delivery_note:print.package_columns.stt'),
                dataIndex: 'stt',
                key: 'stt',
                width: 47,
                render: (text, record, index) => index + 1
            },
            {
                className: 'a-text--nowrap',
                title: t('delivery_note:label.code_package'),
                dataIndex: 'package.code',
                key: 'code',
                width: 190,
                render: text => (
                    <Link
                        isNewTab
                        params={{id: text}}
                        to="packages.detail"
                    >
                        {text}
                    </Link>
                )
            },
            {
                className: 'a-text--nowrap',
                title: t('delivery_note:label.order_code'),
                dataIndex: 'order.code',
                key: 'order.code',
                width: 155,
                render: (text, row) => text ? (
                    <Link
                        href={row.order.m1_url}
                        isNewTab
                    >
                        {text}
                    </Link>
                ) : '--'
            },
            {
                className: 'a-text--nowrap',
                title: t('delivery_note:label.weight'),
                dataIndex: 'package.weight_net',
                key: 'weight',
                width: 105,
                render: text => formatWeight(text, 2)
            },
            {
                className: 'a-text--nowrap',
                title: t('delivery_note:label.size'),
                dataIndex: 'package.volume',
                key: 'volume',
                width: 80,
                render: text => text ? formatVolume(text, 2) : '--'
            }
        ];

        const modalTitle = (
            <div className="title-modal">
                <img
                    src={bagIcon}
                    alt={bag.code}
                />
                <span>{bag.code}</span>
            </div>
        );
        return (
            <Modal
                title={modalTitle}
                visible={visible}
                onCancel={this.props.onCancel}
                className="a-modal a-modal--package-in-bag"
                width={644}
                footer={null}
            >
                <Table
                    className={"a-table--antd"}
                    columns={columns}
                    dataSource={packages}
                    scroll={{ y: 400 }}
                    pagination={false}
                    rowKey={row => row.package.id}
                />
            </Modal>
        )
    }
}

PopupListPackageByBag.defaultProps = {
    bag: {},
    visible: false,
    packages: [],
    onCancel: () => {}
};

export {PopupListPackageByBag};
