export const FETCH_BAGS = {
    REQUEST: 'BAGS.LIST_BAG.FETCH_BAGS.REQUEST',
    SUCCESS: 'BAGS.LIST_BAG.FETCH_BAGS.SUCCESS',
    FAILED: 'BAGS.LIST_BAG.FETCH_BAGS.FAILED',
};
export const EXPORT_BAGS_CSV = {
    REQUEST: 'BAGS.LIST_BAG.EXPORT_BAGS_CSV.REQUEST',
    SUCCESS: 'BAGS.LIST_BAG.EXPORT_BAGS_CSV.SUCCESS',
    FAILED: 'BAGS.LIST_BAG.EXPORT_BAGS_CSV.FAILED',
};

export const RESET_LIST_BAG = 'RESET_LIST_BAG';
export const SWITCH_TAB = 'SWITCH_TAB';


export const BAG_COLUMN = {
    CODE: 'code',
    RECEIVER: 'receiver_name',
    BAG_TYPE: 'bag_type',
    CUSTOMER: 'customer',
    AGENCIES: 'agencies',
    CREATE_TIME: 'created_at',
    ID_WAREHOUSE_INPUT: 'id_warehouse_input',
    ID_WAREHOUSE_DESTINATION: 'id_warehouse_destination',
    CODE_WAREHOUSE_DESTINATION: 'code_warehouse_destination',
    ID_WAREHOUSE_CURRENT: 'id_warehouse_current',
    STATUS_WAREHOUSE: 'status_warehouse',
    TRANSPORT_STATUS: 'transport_status',
    SCAN_TIME: 'time_scan',
    SHIP_NAME: 'ship_name',
    PROPERTIES: 'properties',
    SERVICES: 'services',
    TOTAL_PACKAGES: 'num_package',
    TOTAL_PACKAGES_WEIGHT: 'weight_package',
    BAG_WEIGHT: 'weight_real',
    BAG_SIZE: 'bag_size',
    DIMENSION: 'volume',
    EXPORT: 'export',
    UNCHECKED: 'is_unchecked'
};
