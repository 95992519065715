import lodash from 'lodash';
import {createSelector} from 'reselect';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'property' + (param ? '.'+param : ''), defaultValue);

/**
 * Lấy danh sách property đang active
 */
export const getActiveProperties = createSelector(
	state => getState(state, 'suggest', []),
	property => property.filter(serviceGroup => serviceGroup.active)
);

