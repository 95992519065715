import React from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { DELIVERY_NOTE_STATUS } from './../../../constants';
import { converValue2Int } from './../../../../Common/services/helps';

import FormDeliveryTypeContainer from './../../../FormDeliveryType/FormDeliveryTypeContainer';
import FormProcessing from './../../../../Common/components/FormProcessing';
import SelectUserContainer from './../../../../User/SelectUser/SelectUserContainer';
import SelectWarehouse from './../../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';

class SearchForm extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            status: undefined,
            id_warehouse: undefined,
            'created_at[from]': undefined,
            'created_at[to]': undefined,
            'exported_at[from]': undefined,
            'exported_at[to]': undefined,
            id_creator: undefined,
            id_exporter: undefined,
            id_shipping_partner: undefined,
            id_shipping_user: undefined,
            code_package: undefined
        };

        this.state = {
            input: {
                ...this.defaultInput
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit = event => {
        const { loading, onSearch } = this.props;

        event.preventDefault();

        if (loading) {
            return;
        }

        const { input } = this.state;
        const formattedInput = {
            ...input,
            page: 1
        };

        onSearch(formattedInput);
    };

    onReset = event => {
        const { loading, onSearch } = this.props;
        event.preventDefault();

        if (loading) {
            return;
        }

        const filter = this.defaultInput;
        this.changeInput(filter);
        onSearch(filter);
    };

    /**
     *
     * @param name
     * @param date
     */
    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onChangeMultiSelect = (name, value) => {
        this.changeInput({
            [name]: value
        });
    };

    render() {
        const { loading, t } = this.props;
        const input = converValue2Int(this.state.input);

        return (
            <div className="a-content--search-header search-default search-form">
                <Form onSubmit={this.onSubmit.bind(this)} className="a-form">
                    <Row gutter={{lg: 12, xl: 24}}>
                        <Col lg={6} xl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t('delivery_note:label.search_code')}
                                labelCol={{span: 24}}
                            >
                                <Input
                                    className="_code-package"
                                    value={input.code_package}
                                    onChange={this.onChangeInput.bind(this, 'code_package')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t('delivery_note_return_code')}
                                labelCol={{span: 24}}
                            >
                                <Input
                                    className="_code"
                                    value={input.code}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={5}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note_return_status')}
                                labelCol={{span: 24}}
                            >
                                <FormDeliveryTypeContainer
                                    allowClear
                                    className="a-select a-select--search-content _status"
                                    dropdownClassName="_dropdown-status"
                                    excludes={[DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING, DELIVERY_NOTE_STATUS.CANCELED]}
                                    value={input.status}
                                    disabled={loading}
                                    placeholder={t('delivery_note_return_status')}
                                    onChange={status => this.changeInput({
                                        status
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.created_at')}
                                labelCol={{span: 24}}
                            >
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.exported_at')}
                                labelCol={{span: 24}}
                            >
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[from]'] ? moment(input['exported_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[to]'] ? moment(input['exported_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.id_warehouse')}
                                labelCol={{span: 24}}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content _id-warehouse"
                                    dropdownClassName="_dropdown-warehouse"
                                    placeholder={t('delivery_note:label.id_warehouse')}
                                    value={input.id_warehouse}
                                    onChange={id_warehouse => this.changeInput({
                                        id_warehouse
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.id_creator')}
                                labelCol={{span: 24}}
                            >
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-creator"
                                    dropdownClassName="_dropdown-creator"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_creator')}
                                    value={input.id_creator}
                                    onChange={id_creator => this.changeInput({
                                        id_creator
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('delivery_note:label.id_exporter')}
                                labelCol={{span: 24}}
                            >
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-exporter"
                                    dropdownClassName="_dropdown-exporter"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_exporter')}
                                    value={input.id_exporter}
                                    onChange={id_exporter => this.changeInput({
                                        id_exporter
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a
                            className="link-reload _btn-reset"
                            href="/"
                            onClick={this.onReset}
                        >
                            <Icon type="sync" />
                            {t('label.refresh_filter')}
                        </a>
                        <Button
                            className="a-btn a-btn--primary a-btn--search-list _btn-submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {t('btn.search')}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

SearchForm.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

SearchForm.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(SearchForm);
