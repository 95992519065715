import {all, fork,takeLatest} from 'redux-saga/effects';
import listShippingUserWatcher from './List/sagaWatcher';
import createShippingUserWatcher from './Create/sagaWatcher';
import editShippingUserWatcher from './Edit/sagaWatcher';
import * as saga from './saga';
import * as ACTION from './constants';
export default function*() {
    yield all([
        fork(listShippingUserWatcher),
        fork(createShippingUserWatcher),
        fork(editShippingUserWatcher)
    ]);
	yield takeLatest(ACTION.FETCH_SHIPPING_USER_SUGGEST.REQUEST, saga.fetchShippingUsersSuggest)
}
