import {api} from '../../../system/api';

export default {
    getDeliveryCustomerRequests: (params) => api.get(`delivery-requests/customers`, {
        params: {
            sort_direction: 'asc',
            ...params
        }
    }),
    getDeliveryRequests: (params) => api.get(`delivery-requests`, {params}),
    getDeliveryRequestsByCustomer: (params) => api.get(`customers/${params.id_customer}/delivery-requests`, {params}),
    getPackageExport: (params = {}) => api.get('delivery-requests/packages', {params}),
    update: data => api.put(`delivery-requests/${data.code}`, data)
};
