export const FETCH_DELIVERY_NOTES = {
    REQUEST: 'DELIVERY_NOTES.LIST_DELIVERY_NOTE.FETCH_DELIVERY_NOTES.REQUEST',
    SUCCESS: 'DELIVERY_NOTES.LIST_DELIVERY_NOTE.FETCH_DELIVERY_NOTES.SUCCESS',
    FAILED: 'DELIVERY_NOTES.LIST_DELIVERY_NOTE.FETCH_DELIVERY_NOTES.FAILED',
};

export const RESET_LIST_DELIVERY_NOTE = 'RESET_LIST_DELIVERY_NOTE';

export const DELIVERY_NOTE_COLUMN = {
    CODE: 'code',
    STATUS: 'status',
    CREATE_AT: 'created_at',
    EXPORTED_AT: 'exported_at',
    NOTE:"note",
    ID_SHIPPING_PARTNER: 'id_shipping_partner',
    ID_AGENCY: 'id_agency',
    ID_CUSTOMER: 'id_customer',
    ID_SHIPPING_USER: 'id_shipping_user',
    PARTNER_TRACKING_NUMBER: 'partner_tracking_number',
    AMOUNT_COLLECT: 'amount_collect',
    PACKAGE_WEIGHT: 'total_weight',
    PACKAGE_NUMBER: 'total_number',
    ID_WAREHOUSE: 'id_warehouse',
    ID_CREATOR: 'id_creator',
    ID_EXPORTER: 'id_exporter',
};
