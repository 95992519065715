import React from "react"
import FormSearch from "./FormSearch";
import lodash from "lodash";
import List from "./List";
import {Layout} from "antd";

const Content = Layout.Content;

function ListProperty({listProperty, loading, onSearch, filter}) {
	return <Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
		<FormSearch input={filter}
		            listProperty={listProperty}
		            inputVersion={lodash.toInteger(filter.i)}
		            loading={loading}
		            onSearch={onSearch}/>
		<List listProperty={listProperty} loading={loading}/>
	</Content>
}

export default ListProperty
