import {put} from 'redux-saga/effects';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './../apiService';
import alonePackageApiService from './../UpdateAlonePackage/apiService';
import {setPackage, setPackagePage} from '../CreatePackageViaScan/actions';
import lodash from "lodash";
import React from "react";
import authService from "../../Auth/authService";
import { TYPE_PACKAGE } from './../constants';

export function* updatePackage(action) {
    const data = {...action.payload.data};
    const configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g');

    if (configPackageUnitWeight === 'kg') {
        data.weight_net = parseFloat(data.weight_net) * 10;
    }

    let cb = () => alonePackageApiService.updateAlonePackage({
        ...data,
        code: action.payload.id
    });

    if (data && data.type === TYPE_PACKAGE.RELATED) {
        cb = () => apiService.updateRelatedPackage(action.payload.id, data);
    }

    yield processApiRequest(
        ACTION.UPDATE_PACKAGE_PARAM,
        cb,
        action.payload
    );
}

export function* onUpdatePackageSuccess(action) {
    yield notification.success({
        message: t('message.update_success')
    });
    yield put(setPackagePage(action.request.id));
    yield put(setPackage(action.payload));

    if (lodash.isFunction(action.request.cb)) {
        yield action.request.cb(action);
    }
}

export function* onUpdatePackageFailed(action) {
    let error = lodash.get(action, 'payload.data');
    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}
