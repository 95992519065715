import { get } from 'lodash'
import React from 'react'
import PackageItemGroup from './PackageItemGroup'

const PackageGroup = ({
    boxSizes,
    loadingBoxSizes,
    orderItems,
    originPackage,
    packages,
    onUpdate,
    onRemovePackage,
    autoPrint,
    detachSuggestItems,
    stateProgress,
    groupByProduct,
    packageCreatedIds,
    packageSplitting,
}) => {
    const splittingPackages = packages.filter(pkg => !pkg.id)

    return (
        <ul className="package-list">
            {packages.map((pkg, index) => (
                <PackageItemGroup
                    boxSizes={boxSizes}
                    haveSplittingPackages={!!splittingPackages.length}
                    index={index}
                    key={pkg.id || index}
                    loadingBoxSizes={loadingBoxSizes}
                    orderItems={orderItems}
                    originPackage={originPackage}
                    products={pkg.items}
                    originProduct={get(packages, '0.items', [])}
                    pkg={pkg}
                    onUpdate={onUpdate.bind(undefined, index)}
                    onRemovePackage={onRemovePackage.bind(undefined, index)}
                    autoPrint={autoPrint}
                    detachSuggestItems={detachSuggestItems}
                    packageData={packages}
                    stateProgress={stateProgress}
                    groupByProduct={groupByProduct}
                    packageCreatedIds={packageCreatedIds}
                    packageSplitting={packageSplitting}
                />
            ))}
        </ul>
    )
}

export default PackageGroup
