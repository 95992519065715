import { connect } from 'react-redux';

import * as selectors from './../selectors';
import * as actions from './../actions';

import ListCustomer from './components';

const mapStateToProps = state => {
    return {
        suggestCustomer: selectors.getState(state, 'listCustomer.suggestCustomer'),
        list: selectors.getState(state, 'list'),
        pagination: selectors.getState(state, 'pagination', {}),
        loading: selectors.getState(state, 'loading', false)
    };
};

const mapDispatchToProps = {
    getNotes: actions.getNotes,
    clearSuggestCustomer: actions.clearSuggestCustomer,
    setSuggestCustomerId: actions.setSuggestCustomerId
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListCustomer);
