import React from 'react';
import {Button, notification} from 'antd';
import {isEqual, isNil} from 'lodash';
import PropTypes from 'prop-types';

import apiService from './../../apiService';

import ProductInfo from './ProductInfo';
import ProductInfoLoading from './ProductInfoLoading';
import FinishCheckingPackageButton from './../../components/FinishCheckingPackageButton';

class CheckingPackageProducts extends React.Component {
    state = {
        focuses: [],
        updatingReceivedQuantities: [],
        receivingAllAllProducts: false
    };

    handleReceiveAll = async (index, payload) => {
        const { focuses } = this.state;
        const { t, onReceiveAll, onReceiveAllLastProduct, onSuccessUpdateReceivedQuantities } = this.props;

        try {
            this.setState(prevState => ({
                updatingReceivedQuantities: [
                    ...prevState.updatingReceivedQuantities.slice(0, index),
                    true,
                    ...prevState.updatingReceivedQuantities.slice(index + 1)
                ]
            }));

            const { data } = await apiService.updatePackageProducts(payload.packageId, {
                items: [{
                    code: payload.codeItem,
                    received_quantity: Number(payload.quantity)
                }]
            });

            notification.success({
                message: t('message.update_success')
            });
            onSuccessUpdateReceivedQuantities(data);
            onReceiveAll();

            const length = focuses.length;

            if (index < length - 1) {
                // focus next item
                const newFocuses = focuses.map((focus, i) => i === index + 1);

                this.setState({
                    focuses: newFocuses
                });
            } else {
                if (index === length - 1) {
                    onReceiveAllLastProduct();
                }

                const newFocuses = focuses.map(() => false);

                this.setState({
                    focuses: newFocuses
                });
            }
        } catch (e) {
            notification.error({
                message: t('message.update_failed')
            });
        }

        this.setState(prevState => ({
            updatingReceivedQuantities: [
                ...prevState.updatingReceivedQuantities.slice(0, index),
                false,
                ...prevState.updatingReceivedQuantities.slice(index + 1)
            ]
        }));
    };

    handleReceiveAllAllProducts = async () => {
        const { packageId, products, t, onReceiveAll, onSuccessUpdateReceivedQuantities } = this.props;

        this.setState(prevState => ({
            updatingReceivedQuantities: prevState.updatingReceivedQuantities.map(() => true),
            receivingAllAllProducts: true
        }));

        try {
            const response = await apiService.updatePackageProducts(packageId, {
                items: products.map(product => ({
                    code: product.code_item,
                    received_quantity: product.purchase_quantity
                }))
            });

            onSuccessUpdateReceivedQuantities(response.data);
            onReceiveAll();

            notification.success({
                message: t('message.update_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.update_failed')
            });
        }

        this.setState(prevState => ({
            updatingReceivedQuantities: prevState.updatingReceivedQuantities.map(() => false),
            receivingAllAllProducts: false
        }));
    };

    updateDependencies(products) {
        const focuses = products.map((product, index) => index === 0);
        const updatingReceivedQuantities = products.map(() => false);

        this.setState({
            focuses,
            updatingReceivedQuantities
        });
    }

    componentDidUpdate(prevProps) {
        const { products } = this.props;

        if (!isEqual(products, prevProps.products)) {
            this.updateDependencies(products || []);
        }
    }

    componentDidMount() {
        const { products } = this.props;

        if (products && products.length) {
            this.updateDependencies(products);
        }
    }

    render() {
        const { focuses, receivingAllAllProducts, updatingReceivedQuantities } = this.state;
        const { loading, products, onSuccessFinishCheckingPackage, successUpdateReceivedQuantitiesCount, sumOrderReceivedQuantity, sumPackageReceivedQuantity, sumPurchasedQuantity, t } = this.props;

        return (
            <div className=" block--primary check-package">
                <div className="title create-bag gflex gjustify-space-between">
                    <span className="a-text--bold a-text--fz-16">{t('package:checking.order_products')}</span>
                    <span className="a-text--bold a-text--fz-16">{t('package:checking.order_quantity')}: <strong className='check-package-order_quantity'>{isNil(sumOrderReceivedQuantity) ? '--' : sumOrderReceivedQuantity}/{isNil(sumPurchasedQuantity) ? '--' : sumPurchasedQuantity}</strong></span>
                    <span className="a-text--bold a-text--fz-16">{t('package:checking.package_received_quantity')}: <strong className='check-package-package_received_quantity'>{isNil(sumPackageReceivedQuantity) ? '--' : sumPackageReceivedQuantity}</strong></span>
                    <Button
                        className="a-btn a-btn--transparent-white _btn-receving-all-all-products"
                        ghost
                        loading={receivingAllAllProducts}
                        onClick={this.handleReceiveAllAllProducts}
                    >
                        {t('package:checking.receive_all_all_products')}
                    </Button>

                    <FinishCheckingPackageButton
                        className="a-btn a-btn--transparent-white _btn-finish-checking-package-products"
                        ghost
                        package={this.props.package}
                        onSuccessFinishCheckingPackage={onSuccessFinishCheckingPackage}
                    />
                </div>
                {loading ? (
                    <ProductInfoLoading />
                ) : (
                    <div className="body not-boxshadow">
                        {products.map((product, index) =>
                            <ProductInfo
                                focus={focuses[index]}
                                key={product.code_item}
                                packageId={this.props.packageId}
                                product={product}
                                successUpdateReceivedQuantitiesCount={successUpdateReceivedQuantitiesCount}
                                updatingReceivedQuantity={updatingReceivedQuantities[index]}
                                onReceiveAll={this.handleReceiveAll.bind(undefined, index)}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

CheckingPackageProducts.defaultProps = {
    products: [],
    loading: false,
    packageId: null,
};

CheckingPackageProducts.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool,
    packageId: PropTypes.number,
};

export default CheckingPackageProducts;
