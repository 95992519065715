import React from 'react';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';

import Log from './../../../Common/Log/LogContainer';

const LogsModal = ({ t, storageFeeId, visible, onCancel }) => (
    <Modal
        bodyStyle={{
            height: '60vh',
            overflow: 'auto'
        }}
        centered
        title={t('label.log')}
        visible={visible}
        footer={false}
        onCancel={onCancel}
    >
        <Log object="storage-fees" objectId={storageFeeId} delayTime={0} />
    </Modal>
);

export default withTranslation()(LogsModal);
