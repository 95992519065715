export const FETCH_DETAIL_DELIVERY_REQUEST = {
    REQUEST: 'DELIVERY_REQUESTS.DETAIL_DELIVERY_CUSTOMER_REQUEST.FETCH_DETAIL_DELIVERY_REQUESTS.REQUEST',
    SUCCESS: 'DELIVERY_REQUESTS.DETAIL_DELIVERY_CUSTOMER_REQUEST.FETCH_DETAIL_DELIVERY_REQUESTS.SUCCESS',
    FAILED: 'DELIVERY_REQUESTS.DETAIL_DELIVERY_CUSTOMER_REQUEST.FETCH_DETAIL_DELIVERY_REQUESTS.FAILED',
};
export const RESET_DETAIL_DELIVERY_REQUEST = 'RESET_DETAIL_DELIVERY_REQUEST';
export const SHOW_DETAIL_DELIVERY_REQUEST = "SHOW_DETAIL_DELIVERY_REQUEST";
export const HIDE_DETAIL_DELIVERY_REQUEST = "HIDE_DETAIL_DELIVERY_REQUEST";

export const ORDER_REQUEST_COLUMN = {
    PACKAGE_CODE: 'package_code',
    ODER_CODE: 'order_code',
    BAG_CODE: 'bag_code',
    WEIGHT: 'weight',
    WAREHOUSE_AREA: 'warehouse_area'
};
