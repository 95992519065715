import React from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {login} from './actions';

class Login extends React.PureComponent {
    componentDidMount() {
        const { location } = this.props;

        const pathname = get(location, 'state.pathname');
        const search = get(location, 'state.search');
        const redirectUrl = `${pathname}${search}`;

        this.props.login(redirectUrl);
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (redirectUrl) => {
            dispatch(login({
                domain: window.location.hostname,
                next_page: redirectUrl
            }));
        },
    };
};

export default connect(
    undefined,
    mapDispatchToProps
)(Login);
