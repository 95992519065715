import React, { useEffect, useState } from 'react'
import { trans } from '../../../../system/i18n'
import { currentFormatter } from '../../../Common/services/format'
import apiService from '../../apiService'
import { get, isEmpty, join, map, sumBy, uniq } from 'lodash'
import { Spin } from 'antd'

const LoanByOrders = ({ readyForDeliveries, customer }) => {
    const [loading, setLoading] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [readyData, setReadyData] = useState([])


    useEffect(() => {
        setReadyData(prev=>{
           return prev.length === readyForDeliveries.length ? prev : readyForDeliveries
        })
    
    }, [readyForDeliveries])
    

    useEffect(() => {
        const getTotalBifin = async () => {
            setLoading(true)
            try {
                const data = {
                    order_codes: join(uniq(map(readyData, 'order.code')), ','),
                    id_partner: get(customer, 'agency.id_partner'),
                }

                const customerId = get(customer, 'id')

                const res = await apiService.getLoanByOrders(customerId, data)

                setTotalAmount(sumBy(get(res, 'data'), 'totalAmountPay') || 0)

            } catch (error) {
            } finally {
                setLoading(false)
            }
        }

        if (!isEmpty(readyData)) {
            getTotalBifin()
        }
    }, [readyData, customer])

    return (
        <Spin spinning={loading}>
            <span> {trans('delivery_note:label.still_pay_for_bifin', { amount: <b>{currentFormatter.toLocaleStringCurrency(totalAmount)}đ</b> })}</span>
        </Spin>
    )
}

export default LoanByOrders
