import { connect } from 'react-redux';

import { scanPackage, scanTrackingBill } from './actions';
import * as selectorsInventoryScan from './../InventoryScan/selectors';
import * as selectorsWarehouse from './../../Warehouse/selectors';

import InventoryBarcode from './components';

const mapStateToProps = (state) => {
	return {
		inventory: selectorsInventoryScan.getState(state, 'detail.inventory'),
		loading: selectorsInventoryScan.getState(state, 'loading') || selectorsWarehouse.getState(state, 'loading')
	};
};

const mapDispatchToProps = {
	scanPackage,
	scanTrackingBill
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryBarcode);
