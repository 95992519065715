import React, { memo } from 'react';
import { Button, Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { t } from './../../../../system/i18n';

const { Option } = Select;

const ChooseWarehouse = ({
    allowClear,
    buttonClassName,
    className,
    disabled,
    dropdownClassName,
    isSelect,
    loading,
    maxWarehouse,
    placeholder,
    selectClassName,
    value,
    warehouses,
    onChange
}) => {
    const formattedValue = value && parseInt(value, 10);

    if (!isSelect && warehouses.length <= maxWarehouse) {
        return (
            warehouses.map(warehouse => (
                <Button
                className={clsx(buttonClassName, className, `a-btn a-btn--warehouse _btn-warehouse _btn-warehouse-${warehouse.id}`, {
                    'a-btn--primary': formattedValue === warehouse.id,
                    'a-btn--default': formattedValue !== warehouse.id
                })}
                    disabled={disabled}
                    key={warehouse.id}
                    type="primary"
                    onClick={onChange.bind(undefined, formattedValue && allowClear && formattedValue === warehouse.id ? undefined : warehouse.id)}
                >
                    {warehouse.code}
                </Button>
            ))
        );
    }

    const formattedSelectProps = {
        allowClear,
        className: clsx(`${(className || 'a-select a-select--w-100 ')} _select_warehouse`, selectClassName),
        placeholder: placeholder ? placeholder : t('placeholder.choose_warehouse'),
        value: formattedValue && !loading ? (warehouses.some(item => item.id === formattedValue) ? parseInt(formattedValue, 0) : undefined) : undefined,
        disabled,
        showSearch: true,
        loading,
        optionFilterProp: "children",
        filterOption: (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        onChange,
        dropdownClassName
    };

    return (
        <Select {...formattedSelectProps}>
            {warehouses.map(warehouse => (
                <Option
                    className="_select_warehouse__item"
                    key={warehouse.id}
                    value={warehouse.id}
                >
                    {warehouse.code}
                </Option>
            ))}
        </Select>
    );
};

ChooseWarehouse.defaultProps = {
    dropdownClassName: '',
    warehouses: [],
    maxWarehouse: 10,
    disabled: false,
    onChange: () => {
    },
    isSelect: false
};

ChooseWarehouse.propTypes = {
    warehouses: PropTypes.array,
    maxWarehouse: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    isSelect: PropTypes.bool,
    onChange: PropTypes.func,
};

export default memo(withTranslation()(ChooseWarehouse));
