import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

const deepCompareEquals = (a, b) => isEqual(a, b);

const useDeepCompareMemoize = value => {
    const ref = useRef() 
  
    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }
  
    return ref.current;
};

export const useDeepCompareEffect = (callback, dependencies) => {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
