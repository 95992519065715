export const GET_BAG_PACKING_DETAIL = {
    REQUEST: 'BAGS.GET_BAG_PACKING_DETAIL.REQUEST',
    SUCCESS: 'BAGS.GET_BAG_PACKING_DETAIL.SUCCESS',
    FAILED: 'BAGS.GET_BAG_PACKING_DETAIL.FAILED',
};

export const UPDATE_BAG_PARAM = {
    REQUEST: 'BAG.BAG_PACKING.UPDATE_BAG_PARAM.REQUEST',
    SUCCESS: 'BAG.BAG_PACKING.UPDATE_BAG_PARAM.SUCCESS',
    FAILED: 'BAG.IBAG_PACKING.UPDATE_BAG_PARAM.FAILED',
};
export const RESET_ERROR = 'BAG.BAG_PACKING.INLINE_EDIT_BAG_PARAM.RESET_ERROR';
export const CLEAR_STATE = 'BAG.BAG_PACKING.INLINE_EDIT_BAG_PARAM.CLEAR_STATE';
export const CLEAR_PACKING_BAG_STATE = "CLEAR_PACKING_BAG_STATE";
