import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function* () {
    yield takeLatest(ACTION.FETCH_PACKAGES.REQUEST, saga.fetchPackages);
    yield takeLatest(ACTION.EXPORT_PACKAGE_LIST.REQUEST, saga.exportPackageList);
    yield takeLatest(ACTION.EXPORT_PACKAGE_LIST.SUCCESS, saga.exportPackageListSuccess);
    yield takeLatest(ACTION.EXPORT_PACKAGE_LIST.FAILED, saga.exportPackageListFailed);
}
