/* eslint-disable */
import React from 'react';
import {Button, Empty, Icon, Modal, notification, Popover, Skeleton, Table} from "antd";
import {withTranslation} from "react-i18next";
import lodash from "lodash";
import {DELIVERY_REQUEST_CHILD_COLUMN, DELIVERY_REQUEST_CUSTOMER_COLUMN, DELIVERY_REQUEST_STATUS} from "../constants";
import {dateFormatter} from "../../../Common/services/format";
import Pagination from '../../../Common/components/Pagination';
import PropTypes from 'prop-types';
import CancelDeliveryRequest from "../../CancelDeliveryRequest/CancelDeliveryRequest";
import PERMISSIONS from "../../../Auth/permissions";
import {t} from "../../../../system/i18n";
import authService from "../../../Auth/authService";
import {Link} from "../../../../system/routing";
import apiService from './../apiService';

import mainApiService from './../../apiService';
import { handleDeliveringDeliveryRequestError } from './../../helper';
import {getVar} from './../../../../system/support/helpers';

import InlineEditInput from './../../../Common/InlineEdit/InlineEditInput';

function CustomExpandIcon(props) {
	let text;
	if (props.expanded) {
		text = "up"
	} else {
		text = "down"
	}
	return (
		<Icon type={text} className="a-text--gray-70" onClick={e => props.onExpand(props.record, e)}/>

	);
}


class ListDeliveryCustomerRequest extends React.PureComponent {
	state = {
		expandedRowKeys: [],
		deliveringDeliveryRequestId: undefined
	};

	static getDerivedStateFromProps(props, state) {
		let nextState = {...state};
		if (props.filter.status !== DELIVERY_REQUEST_STATUS.STATUS_NEW || props.loading.loadingDeliveryCustomerRequest) {
			nextState.expandedRowKeys = []
		}
		return nextState
	}

	getTitleColumn(column, type) {
		switch (column) {
            case DELIVERY_REQUEST_CUSTOMER_COLUMN.ACTION:
				return;
			default :
				return <span
					className={`${type === "child" && 'a-text--bold'}`}>{this.props.t('delivery_request:column.' + column)}</span>
		}
	}

	getColumns(type) {
		let arrSort = [];
        return lodash.values(type === "child" ? DELIVERY_REQUEST_CHILD_COLUMN : DELIVERY_REQUEST_CUSTOMER_COLUMN)
			.map(column => {
				let className = `a-text--nowrap _column-${column}`;
				let width;

				if (column === DELIVERY_REQUEST_CHILD_COLUMN.ACTION) {
					width = '120px';
				}

				if (column === DELIVERY_REQUEST_CHILD_COLUMN.ADDRESS) {
					className = undefined;
					width = '300px';
				}

				let res = {
					className,
					dataIndex: column,
					key: column,
					width,
					title: this.getTitleColumn(column, type),
				};

				if (arrSort.indexOf(column) > -1) {
					res = {
						...res,
						sorter: true,
						sortOrder: column === this.props.filter.sort_by
							? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
							: null,
					}
				}
				return res;
			});
	}

	showDeliveryRequest(code, agency) {
        this.props.showDetailDeliveryRequest(code, agency)
	}

	noteValueRender = (deliveryRequest, agency, value) => {
		return (
			value ? (
				<Popover content={value}>
					<a
						className="a-btn--button-link mr-auto a-text--blue _note"
						onClick={this.showDeliveryRequest.bind(this, lodash.get(deliveryRequest, 'code', ''), agency)}
					>
						{value ? lodash.truncate(value, {
							length: 15
						}) : '--'}
					</a>
				</Popover>
			) : (
				<a
					className="a-btn--button-link mr-auto a-text--blue _note"
					onClick={this.showDeliveryRequest.bind(this, lodash.get(deliveryRequest, 'code', ''), agency)}
				>
					{value ? lodash.truncate(value, {
							length: 15
						}) : '--'}
				</a>
			)
		);
	};

	getChildDeliveryRequest (deliveryRequests, can_handle, agency) {
		const {permissions} = this.props;

        return this.props.loading.loadingDeliveryCustomerRequest ?
			[{
				key: 0,
				[DELIVERY_REQUEST_CHILD_COLUMN.ID_DELIVERY_REQUEST]: <Skeleton active paragraph={{rows: 0}}/>,
				[DELIVERY_REQUEST_CHILD_COLUMN.WAREHOUSE]: <Skeleton active paragraph={{rows: 0}}/>,
				[DELIVERY_REQUEST_CHILD_COLUMN.CREATE_AT]: <Skeleton active paragraph={{rows: 0}}/>,
				[DELIVERY_REQUEST_CHILD_COLUMN.NUM_PACKAGE]: <Skeleton active paragraph={{rows: 0}}/>,
				[DELIVERY_REQUEST_CHILD_COLUMN.TOTAL_WEIGHT]: <Skeleton active paragraph={{rows: 0}}/>,
				[DELIVERY_REQUEST_CHILD_COLUMN.ADDRESS]: <Skeleton active paragraph={{rows: 0}}/>,
			}] : deliveryRequests.map(deliveryRequest => {
				deliveryRequest = lodash.get(deliveryRequest, 'delivery_request');
                let totalWeight = lodash.get(deliveryRequest, 'total_weight', '--');
				totalWeight = lodash.isNil(totalWeight) || totalWeight === "null" ? "--" : totalWeight;
				return {
					key: deliveryRequest.id,
                    [DELIVERY_REQUEST_CHILD_COLUMN.ID_DELIVERY_REQUEST]: <a
                        onClick={this.showDeliveryRequest.bind(this, lodash.get(deliveryRequest, 'code', ''), agency)}
                        className="a-btn--button-link mr-auto a-text--nowrap a-text--blue _id-delivery-request"> {lodash.get(deliveryRequest, 'code', '--')}</a>,
                    [DELIVERY_REQUEST_CHILD_COLUMN.WAREHOUSE]: <span
                        className={'_warehouse'}>{lodash.get(deliveryRequest, 'warehouse.name', '--')}</span>,
					[DELIVERY_REQUEST_CHILD_COLUMN.CREATE_AT]:
                        deliveryRequest['created_at'] ? <span
                            className={'_created-at'}>{dateFormatter.dateTime(deliveryRequest['created_at'])}</span> : '--',
                    [DELIVERY_REQUEST_CHILD_COLUMN.NUM_PACKAGE]: <span
                        className={'_num-package'}>{lodash.get(deliveryRequest, 'total_package', '--')}</span>,
                    [DELIVERY_REQUEST_CHILD_COLUMN.TOTAL_WEIGHT]: <span
                        className={'_total-weight'}>{totalWeight + '(kg)'}</span>,
                    [DELIVERY_REQUEST_CHILD_COLUMN.ADDRESS]: <span
                        className={'_address'}>{lodash.get(deliveryRequest, 'address', '--') +
                    (lodash.get(deliveryRequest, 'location.detail') ? ", " : " ") +
					lodash.get(deliveryRequest, 'location.detail', '--')}</span>,
					[DELIVERY_REQUEST_CHILD_COLUMN.NOTE]: (
						<InlineEditInput
							inputStyle={{
								width: '27rem'
							}}
							inputType="textarea"
							value={lodash.get(deliveryRequest, 'note')}
							onSubmit={this.handleSubmit.bind(undefined, deliveryRequest.code, agency, 'note')}
							valueRender={this.noteValueRender.bind(undefined, deliveryRequest, agency)}
						/>
					),
                    [DELIVERY_REQUEST_CHILD_COLUMN.ACTION]: (
						<>
							{this.renderDeliveringDeliveryRequestButton(deliveryRequest)}
							{lodash.includes(permissions, PERMISSIONS.DELIVERY_REQUEST_CANCEL) && (
								<CancelDeliveryRequest detailDeliveryRequest={{...deliveryRequest, agency}} />
							)}
						</>
					),
				};
			})
	}

	renderDeliveringDeliveryRequestButton = deliveryRequest => {
		const { deliveringDeliveryRequestId } = this.state;

		return (
			<Button
				className="a-btn--link a-text--link _btn-delivering"
				loading={deliveringDeliveryRequestId === deliveryRequest.id}
				type="link"
				onClick={this.deliveringDeliveryRequest.bind(this, deliveryRequest)}
			>
				{t('processed')}
			</Button>
		);
	};

	expandedRowRender(record) {
		return (
			<Table
				className="a-table--antd"
				columns={this.getColumns('child')}
				dataSource={this.getChildDeliveryRequest(record.deliveryRequests, record.can_handle, record.agencyCode)}
				pagination={false}
				rowClassName="row-show-item-hover"
			/>
		);
	};

	getDeliveryCustomerRequests() {
        const {listDeliveryCustomerRequest, showAlias} = this.props;
   

		return listDeliveryCustomerRequest.map(deliveryCustomerRequest => {
			const existDeliveryRequestExceed24h = deliveryCustomerRequest.existDeliveryRequestExceed24h;
			const detailCustomer = deliveryCustomerRequest.customer || {};
			const deliveryRequests = deliveryCustomerRequest.delivery_requests || [];
			const can_handle = deliveryCustomerRequest.can_handle || false;
			const warehouseId = localStorage.getItem(`selectedDeliveryRequestWarehouse_${authService.user('id')}`);
			const customerId = lodash.get(detailCustomer, 'id');
			return {
				key: lodash.get(detailCustomer, 'code'),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.ID_CUSTOMER]: (
					<a
						className={"a-text--nowrap a-text--blue _id-customer " + (existDeliveryRequestExceed24h ? "a-text--red" : "")}
                    	href="#top"
					>
						{getVar(detailCustomer, 'username', '--')} ({getVar(detailCustomer, 'code', '--')})
					</a>
				),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.AGENCY]: (
					<span className={"_agency " +  (existDeliveryRequestExceed24h ? "a-text--red" : "")}>
							 {lodash.get(deliveryCustomerRequest, 'agency.name', '--')} {lodash.get(deliveryCustomerRequest, 'agency.identify_name') && showAlias
                                            ? `(${lodash.get(deliveryCustomerRequest, 'agency.identify_name')})` : null}
					</span>
				),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.TOTAL_REQUEST]: (
					<span className={"_total-request " +  (existDeliveryRequestExceed24h ? "a-text--red" : "")}>
						{lodash.get(deliveryCustomerRequest, 'total_delivery_request', 0)}
					</span>
				),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.TOTAL_PACKAGE]: (
					<span className={"_total-package " +  (existDeliveryRequestExceed24h ? "a-text--red" : "")}>
						{lodash.get(deliveryCustomerRequest, 'total_package', 0)}
					</span>
				),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.TOTAL_WEIGHT]: (
					<span className={"_total-weight " +  (existDeliveryRequestExceed24h ? "a-text--red" : "")}>
						{lodash.get(deliveryCustomerRequest, 'total_weight', 0) ?
                    lodash.get(deliveryCustomerRequest, 'total_weight', 0) + '(kg)' : '0' + '(kg)'}
					</span>
				),
                [DELIVERY_REQUEST_CUSTOMER_COLUMN.ACTION]: !!can_handle && (
					<Link
						className={'_create-delivery-note ' +  (existDeliveryRequestExceed24h ? "a-text--red" : "")}
						href={`/delivery-notes/customer/create?id_warehouse=${warehouseId}&id_customer=${customerId}`}
					>
						{t("delivery_request:label.create_delivery_note")}
					</Link>
				),
				deliveryRequests: deliveryRequests,
				agencyCode: lodash.get(deliveryCustomerRequest, 'agency.code'),
				can_handle: deliveryCustomerRequest.can_handle
			}
		})
	}

	onChangePage(page, pageSize) {
		if (!this.props.loading) {
			this.props.onSearch({
				...this.props.filter,
				page,
				per_page: pageSize,
			});
		}
	}

	onExpandCustomer(expanded, record) {
		this.setState({expandedRowKeys: expanded ? [record.key] : []})
	}

	handleSubmit = async (deliveryRequestCode, agencyCode, field, value) => {
		const { t, onSuccessUpdateDeliveryRequest } = this.props;

		try {
			const response = await apiService.update({
				code: deliveryRequestCode,
				agency: agencyCode,
				[field]: value
			});

			onSuccessUpdateDeliveryRequest(response.data.delivery_request);

			notification.success({
                message: t('delivery_request:update.success')
            });
		} catch (error) {
			notification.error({
                message: t('delivery_request:update.failed')
			});
			
			throw error;
		}
	};

	deliveringDeliveryRequest = (deliveryRequest, event) => {
		event.stopPropagation();

		Modal.confirm({
			cancelButtonProps: {
				className: '_btn-cancel-delivering-delivery-request'
			},
			cancelText: t('btn.cancel'),
			className: 'a-modal a-modal--warning',
            content: t('delivery_request:delivering.confirm'),
            okButtonProps: {
				className: '_btn-confirm-delivering-delivery_request'
			},
			okText: t('btn.ok'),
            onOk: this.confirmDeliveringDeliveryRequest.bind(undefined, deliveryRequest.id)
        });
	};

	confirmDeliveringDeliveryRequest = async deliveryRequestId => {
		const { onChangeFilter, filter } = this.props;

		this.setState({
			deliveringDeliveryRequestId: deliveryRequestId
		});

		try {
			await mainApiService.delivering(deliveryRequestId);

			onChangeFilter(filter);

			notification.success({
				message: t('delivery_request:delivering.success')
			});
		} catch (error) {
			handleDeliveringDeliveryRequestError(error);
		}

		this.setState({
			deliveringDeliveryRequestId: undefined
		});
	};

	render() {
		let {t, loading, filter, pagination} = this.props;
		return (
			<div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed mt-0">
				<div className="a-table--responsive">
					<Table className={"a-table--antd a-table--delivery-request"}
                           columns={this.getColumns()}
                           dataSource={this.getDeliveryCustomerRequests()}
                           pagination={false}
                           loading={loading.loadingDeliveryCustomerRequest}
                           expandedRowRender={this.expandedRowRender.bind(this)}
                           expandIcon={CustomExpandIcon}
                           expandedRowKeys={this.state.expandedRowKeys}
                           expandRowByClick
                           onExpand={this.onExpandCustomer.bind(this)}
                           expandIconAsCell
                           locale={{
						       emptyText: (
							       <Empty
								       image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
								       description={<span>{t("common:label.no_data")}</span>}
							       >
							       </Empty>)
					       }}

					/>
				</div>
				<Pagination
					page={lodash.toInteger(filter.page) || 1}
					pageSize={lodash.toInteger(filter.per_page)}
					total={pagination ? pagination.total : 0}
					loading={this.props.loading.loadingDeliveryCustomerRequest}
					onChange={this.onChangePage.bind(this)}/>
			</div>
		)
	}
}


ListDeliveryCustomerRequest.defaultProps = {
	loading: {},
	deliveryRequests: [],
	deliveryCustomerRequests: [],
	pagination: {},
	onSearch: () => {
	},
	filter: {},
	onChangeFilter: () => {
	},
};

ListDeliveryCustomerRequest.propTypes = {
	loading: PropTypes.object,
	deliveryRequests: PropTypes.array,
	deliveryCustomerRequests: PropTypes.array,
	pagination: PropTypes.object,
	onSearch: PropTypes.func,
	filter: PropTypes.object,
	onChangeFilter: PropTypes.func,
};

export default withTranslation()(ListDeliveryCustomerRequest);
