import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';

import { NOTE_TYPES } from './../../constants';
import pageService from './../../../Common/Page/pageService';

import FormSearch from './../../components/FormSearch';
import List from './../../components/List';

const { Content } = Layout;

const ListCustomer = props => {
	useEffect(() => {
		pageService.setTitle(props.t('note:label.list_note_order'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Content className="a-content a-content--page-list">
			<FormSearch
				{...props}
				object={NOTE_TYPES.CUSTOMER}
			/>
			<List
				{...props}
				object={NOTE_TYPES.CUSTOMER}
				suggestObject={props.suggestCustomer}
				clearSuggestObject={props.clearSuggestCustomer}
				setSuggestObjectId={props.setSuggestCustomerId}
			/>
		</Content>
	);
};

export default withTranslation()(ListCustomer);
