import lodash from 'lodash';
import {LIST_PACKAGE_OBJECT, PACKAGE_COLUMN} from './constants';
import {PACKAGE_COLUMN as LIST_PACKAGE_STATUS_DELETE_COLUMN} from '../ListPackageStatusDelete/constants';

const SETTING_OPTIONS = [
    {
        key: PACKAGE_COLUMN.CODE,
        selected: true,
        disabled: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG,
        ]
    },
    {
        key: PACKAGE_COLUMN.REPORTER,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.DAMAGED
        ]
    },
    {
        key: PACKAGE_COLUMN.DAMAGED_AT,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.DAMAGED
        ]
    },
    {
        key: PACKAGE_COLUMN.STATUS_DAMAGED,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.DAMAGED
        ]
    },
    {
        key: PACKAGE_COLUMN.COMPLAINT_SELLER,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.DAMAGED
        ]
    },
    {
        key: PACKAGE_COLUMN.DAMAGED_REASONS,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG,
        ]
    },
    {
        key: PACKAGE_COLUMN.BARCODE,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.BAG_CODE,
        selected: true,
        disabled: false,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.CREATE_TIME,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.ORDER_LINKED_AT,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.TRANSPORTING_AT,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.READY_FOR_DELIVERY_AT,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: LIST_PACKAGE_STATUS_DELETE_COLUMN.DELETED_TIME,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.DELETE]
    },
    {
        key: PACKAGE_COLUMN.STATE,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL, 
            LIST_PACKAGE_OBJECT.DAMAGED, 
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.STATUS_TRANSPORT,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL, 
            LIST_PACKAGE_OBJECT.DAMAGED, 
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: LIST_PACKAGE_STATUS_DELETE_COLUMN.CUSTOMER_USERNAME,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.DELETE]
    },
    {
        key: PACKAGE_COLUMN.ORDER,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    }, {
        key: PACKAGE_COLUMN.PROPERTIES,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL, 
            LIST_PACKAGE_OBJECT.DAMAGED, 
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    }, {
        key: PACKAGE_COLUMN.PRINTED_OFFICIAL_STAMP,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    }, {
        key: PACKAGE_COLUMN.BOX_SERVICES,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.DAMAGED, LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    }, {
        key: PACKAGE_COLUMN.SHIPPING_SERVICES,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.DAMAGED, LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    }, {
        key: PACKAGE_COLUMN.EXTEND_SERVICES,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.DAMAGED, LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    }, {
        key: PACKAGE_COLUMN.AGENCY,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    }, {
        key: PACKAGE_COLUMN.USERNAME,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    },
    {
        key: PACKAGE_COLUMN.INPUT_WAREHOUSE,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.CURRENT_WAREHOUSE,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.DAMAGED, LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    },
    {
        key: PACKAGE_COLUMN.DESTINATION_WAREHOUSE,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.SHIPPING_PARTNER,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.WEIGHT,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: PACKAGE_COLUMN.VOLUME,
        selected: true,
        page: [
            LIST_PACKAGE_OBJECT.ALL,
            LIST_PACKAGE_OBJECT.DAMAGED,
            LIST_PACKAGE_OBJECT.DELETE,
            LIST_PACKAGE_OBJECT.SHIPMENT,
            LIST_PACKAGE_OBJECT.NOT_IN_BAG
        ]
    },
    {
        key: LIST_PACKAGE_STATUS_DELETE_COLUMN.DELETE_REASON,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.DELETE]
    },
    {
        key: LIST_PACKAGE_STATUS_DELETE_COLUMN.DELETED_USERNAME,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.DELETE]
    },
    {
        key: PACKAGE_COLUMN.IS_CHECKED,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    },
    {
        key: PACKAGE_COLUMN.NOTE,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.ALL, LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    },
    {
        key: PACKAGE_COLUMN.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_CODE,
        selected: true,
        page: [LIST_PACKAGE_OBJECT.NOT_IN_BAG]
    },
];

class SettingService {
    constructor(options) {
        this.options = options;
    }

    getSetting(page) {
        let setting = localStorage.getItem('package_setting_' + page);
        return setting ? JSON.parse(setting) : {};
    }

    updateSetting(setting, page) {
        localStorage.setItem('package_setting' + (page !== "" ? "_" + page : ""), JSON.stringify(setting));
    }

    getOption(page) {
        let options = [];
        lodash.each(SETTING_OPTIONS, option => {
            if (option.page.includes(page)) {
                options.push(option);
            }
        });
        this.options = options;

        return options;
    }

    getPageSetting(userId, page) {
        let defaultSetting = lodash.get(this.getSetting(page), userId, this.getDefaultSetting(page));
        let settings = [];
        lodash.each(SETTING_OPTIONS, setting => {
            if (setting.page.includes(page) && defaultSetting.includes(setting.key)) {
                settings.push(setting.key);
            }
        });

        return settings;
    }

    getDefaultSetting(page) {
        const options = this.getOption(page);

        return options.filter(option => option.selected).map(option => option.key);
    }

    getUserSetting(userId, page) {
        this.getOption(page);
        return this.getPageSetting(userId, page);
    }

    updateUserSetting(userId, setting, page) {
        this.getOption(page);
        this.updateSetting({...this.getSetting(page), [userId]: setting}, page);
    }
}

let settingService = new SettingService(SETTING_OPTIONS);

export default settingService;
