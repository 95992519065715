import React, {Component} from 'react';
import {Icon} from 'antd';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Link} from './../../../../system/routing';

import { BARCODE_TYPE } from './../../../Barcode/constants';

import Notices from './../../../Common/components/notices/Notices';
import { getVar } from '../../../../system/support/helpers';

class ItemTableFailed extends Component {
    renderErrors() {
        let {listDataItem} = this.props;
        let message = get(listDataItem, "message_warning", "");
        return message === "" ? null :
            <span className="a-text--black-45">
             <Icon type='close-circle' theme="filled" className="a-text--red mr-2"/>
                {message}
          </span>
    }

    render() {
        const {index, listDataItem, t} = this.props;
        const packageRoutings = getVar(listDataItem, "packageRoutings", {})
        const packageRoutingsContent = [];
        Object.keys(packageRoutings).map(key => {
            if (packageRoutings[key].action === "yes") {
                packageRoutingsContent.push(t(`package:package_routing.${key}`));
            }
        })
        return (
            <>
                <tbody className="error">
                <tr className="data">
                    <td>{index + 1}</td>
                    <td className={'_id-scan'}>
                        {listDataItem.barcode_type === BARCODE_TYPE.BAG ?
                            <Link isNewTab
                                  className="a-text--nowrap" to="bags.detail"
                                  params={{id: listDataItem.barcode}}><img
                                  className="logo-shop"
                                  src={require('../../../../resources/images/bag.svg')}
                                  alt=""/>{listDataItem.barcode}</Link>
                            :
                            listDataItem.barcode}
                    </td>
                    <td className="_bag-code">
                        {get(listDataItem, 'bag.code') ? (
                            <Link
                                isNewTab
                                params={{id: listDataItem.bag.code}}
                                to="bags.detail"
                            >
                                {listDataItem.bag.code}
                            </Link>
                        ) : '--'}
                    </td>
                    <td className={'_created_at'}>{listDataItem.created_at}</td>
                    <td className={'_action-scan'}><span
                        className={listDataItem.status_warehouse === "import" ? "a-text--green" : "a-text--blue"}>
                                        {t("scan:label." + listDataItem.status_warehouse)}</span>
                    </td>
                    <td className={'_code_warehouse_scan'}>{listDataItem.code_warehouse_scan}&nbsp;&nbsp;
                    </td>
                    <td className={'_code_warehouse_scan'}>
                        {get(listDataItem, 'customer.username', '---')}
                    </td>
                    <td className={'_handling_indicate'}>
                        {packageRoutingsContent.join(", ")}
                    </td>
                </tr>
                <tr className="message">
                    <td colSpan="8" style={{paddingTop: '0px'}} className={'_error'}>
                        {this.renderErrors()}
                    </td>
                </tr>
                {get(listDataItem, 'notices.text', []).length ? (
                    <tr>
                        <td colSpan="8" className="p-0">
                            <Notices
                                className="aradius--0 border--none"
                                displayIcon
                                notices={get(listDataItem, 'notices.text', [])}
                            />
                        </td>
                    </tr>
                ) : null}
                </tbody>
            </>
        );
    }
}

ItemTableFailed.defaultProps = {
    listDataItem: {},
};

ItemTableFailed.propTypes = {
    listDataItem: PropTypes.object,
};

export default withTranslation()(ItemTableFailed);
