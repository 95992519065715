import React, { Component } from 'react';
import { Form, Select } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class CommonLocation extends Component{
    state = {
        locations: [],
        currentCountry: 0
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = prevState;

        if (nextProps.currentCountry !== prevState.currentCountry) {
            nextState = {
                ...prevState,
                currentCountry: nextProps.currentCountry
            }
        }

        return nextState;
    }

    componentDidMount() {
        const { autoload, listCountry, listProvince, type, value, fetchCountries } = this.props;

        if (type === 'country') {
            if (!get(listCountry, 'length')) {
                fetchCountries(0, 'country');
            }

            if (value) {
                this.onChangeCountry(value);
            }
        }

        if (type === 'province') {
            if (autoload && !get(listProvince, 'length')) {
                fetchCountries(0, 'country', autoload);
            }

            if (value) {
                this.onChangeProvince(value);
            }
        }
    }

    onChangeCountry = value => {
        const { autoloadChildren, changeCurrentCountry, fetchProvinces, onChange } = this.props;

        changeCurrentCountry(value);
        onChange(value);

        if (autoloadChildren) {
            fetchProvinces(value, 'province');
        }
    };

    onChangeProvince = value => {
        const { autoloadChildren, fetchDistricts, onChange } = this.props;

        onChange(value);

        if (autoloadChildren) {
            fetchDistricts(value, 'district');
        }
    };

    onChangeDistrict = value => {
        const { onChange } = this.props;

        onChange(value);
    };

    onChangeWard = value => {
        const { onChange } = this.props;

        onChange(value);
    };

    render() {
        const {
            filter,
            loadingCountry,
            loadingDistrict,
            loadingProvince,
            listCountry,
            listProvince,
            listDistrict,
            listWard,
            required,
            selectProps,
            t,
            type,
            value
        } = this.props;
        let list, label, placeholder, className, onChange, loading, locations;

        if (type === 'country') {
            placeholder = t('location:placeholder.country');
            className = 'a-select a-select--w-100 _country';
            onChange = this.onChangeCountry;
            loading = loadingCountry;
            locations = listCountry;
        }
        if (type === 'province') {
            placeholder = t('location:placeholder.city');
            className = 'a-select a-select--w-100 _province';
            onChange = this.onChangeProvince;
            loading = loadingCountry || loadingProvince;
            locations = listProvince;

        }
        if (type === 'district') {
            placeholder = t('location:placeholder.district');
            className = 'a-select a-select--w-100 _district';
            onChange = this.onChangeDistrict;
            loading = loadingDistrict;
            locations = listDistrict;
        }
        if (type === 'ward') {
            placeholder = t('location:placeholder.ward');
            className = 'a-select a-select--w-100 _ward';
            onChange = this.onChangeWard;
            locations = listWard;
        }

        if (filter) {
            locations = locations.filter(filter);
        }

        list = (
            <Form.Item
                className="a-form__item"
                label={label}
                labelCol={{span: 24}}
                required={required}
            >
                <Select
                    {...selectProps}
                    className={className}
                    disabled={loading}
                    loading={loading}
                    optionFilterProp="children"
                    placeholder={this.props.placeholder || placeholder}
                    showSearch={true}
                    value={loading ? undefined : value && Number(value)}
                    onChange={onChange}
                >
                    {locations.map(item => (
                        <Option
                            className={`_${type}-item`}
                            key={item.location.id}
                            value={item.location.id}
                        >
                            {item.location.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );

        return list;
    };
}

export default withTranslation()(CommonLocation);
