import {combineReducers} from 'redux';
import * as ACTION from './constants';
import inventoryScan from "./InventoryScan/reducer"
import inventoryBarcode from "./InventoryBarcode/reducer"
import historyList from "./HistoryList/reducer";
import checkingInventory from "./CheckingInventory/reducer"
import updateStatusInventory from "./UpdateStatusInventory/reducer"
import * as lodash from "lodash";

import createReducer from './CreateInventory/reducer';

let loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.UPDATE_INVENTORY_STATUS.REQUEST:
        case ACTION.FETCH_INVENTORIES.REQUEST:
        case ACTION.FETCH_INVENTORY_DETAIL.REQUEST:
            return true;
        case ACTION.FETCH_INVENTORY_DETAIL.SUCCESS:
        case ACTION.FETCH_INVENTORY_DETAIL.FAILED:
        case ACTION.UPDATE_INVENTORY_STATUS.SUCCESS:
        case ACTION.UPDATE_INVENTORY_STATUS.FAILED:
        case ACTION.FETCH_INVENTORIES.SUCCESS:
        case ACTION.FETCH_INVENTORIES.FAILED:
            return false;
        default:
            return state;
    }
};

const detail = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case ACTION.FETCH_INVENTORY_DETAIL.SUCCESS: {
            return {
                ...payload,
                inventory_packages: lodash.map(payload.inventory_packages, inventoryPackage => ({
                    ...inventoryPackage,
                    loading: false
                })),
                inventory_tracking_bills: lodash.map(payload.inventory_tracking_bills, inventoryTrackingBill => ({
                    ...inventoryTrackingBill,
                    loading: false
                }))
            };
        }
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.REQUEST: {
            const inventoryPackages = state.inventory_packages;
            const index = inventoryPackages.findIndex(inventoryPackage => inventoryPackage.inventory_package.id === payload.id);

            if (index === -1) {
                return state;
            }

            const newInventoryPackages = [
                ...inventoryPackages.slice(0, index),
                {
                    ...inventoryPackages[index],
                    loading: true
                },
                ...inventoryPackages.slice(index + 1)
            ];
 
            return {
                ...state,
                inventory_packages: newInventoryPackages
            };
        }
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.SUCCESS: {
            const inventoryPackages = state.inventory_packages;
            const index = inventoryPackages.findIndex(inventoryPackage => inventoryPackage.inventory_package.id === request.id);

            if (index === -1) {
                return state;
            }

            const newInventoryPackages = [
                ...inventoryPackages.slice(0, index),
                {
                    ...inventoryPackages[index],
                    ...lodash.pick(payload, 'inventory_package'),
                    loading: false
                },
                ...inventoryPackages.slice(index + 1)
            ];

            return {
                ...state,
                count_inventory_packages: lodash.get(payload, 'count_inventory_packages', {}),
                inventory: lodash.get(payload, 'inventory', {}),
                inventory_packages: newInventoryPackages
            };
        }
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.FAILED: {
            const inventoryPackages = state.inventory_packages;
            const index = inventoryPackages.findIndex(inventoryPackage => inventoryPackage.inventory_package.id === request.id);

            if (index === -1) {
                return state;
            }

            const newInventoryPackages = [
                ...inventoryPackages.slice(0, index),
                {
                    ...inventoryPackages[index],
                    loading: false
                },
                ...inventoryPackages.slice(index + 1)
            ];
 
            return {
                ...state,
                inventory_packages: newInventoryPackages
            };
        }
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.REQUEST:
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.REQUEST: {
            const inventoryPackages = state.inventory_packages;
            const newInventoryPackages = [];

            for (const inventoryPackage of inventoryPackages) {
                const newInventoryPackage = {
                    ...inventoryPackage
                };

                if (payload.ids.includes(inventoryPackage.inventory_package.id)) {
                    newInventoryPackage.loading = true;
                } else {
                    newInventoryPackage.loading = false;
                }

                newInventoryPackages.push(newInventoryPackage);
            }
 
            return {
                ...state,
                inventory_packages: newInventoryPackages
            };
        }
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.SUCCESS:
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.SUCCESS: {
            const inventoryPackages = state.inventory_packages;
            const newInventoryPackages = [];

            for (const inventoryPackage of inventoryPackages) {
                let newInventoryPackage = {
                    ...inventoryPackage
                };
                const index = payload.inventory_packages.findIndex(item => item.inventory_package.id === inventoryPackage.inventory_package.id);

                if (index !== -1) {
                    newInventoryPackage = {
                        ...newInventoryPackage,
                        ...payload.inventory_packages[index],
                        loading: false
                    };
                }

                newInventoryPackages.push(newInventoryPackage);
            }
 
            return {
                ...state,
                inventory_packages: newInventoryPackages,
                count_inventory_packages: lodash.get(payload, 'count_inventory_packages', {})
            };
        }
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.FAILED:
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.FAILED: {
            const inventoryPackages = state.inventory_packages;
            const newInventoryPackages = [];

            for (const inventoryPackage of inventoryPackages) {
                const newInventoryPackage = {
                    ...inventoryPackage
                };

                if (request.ids.includes(inventoryPackage.inventory_package.id)) {
                    newInventoryPackage.loading = false;
                }

                newInventoryPackages.push(newInventoryPackage);
            }
 
            return {
                ...state,
                inventory_packages: newInventoryPackages
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.REQUEST: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const index = inventoryTrackingBills.findIndex(inventoryTrackingBill => inventoryTrackingBill.inventory_tracking_bill.id === payload.id);

            if (index === -1) {
                return state;
            }

            const newInventoryTrackingBills = [
                ...inventoryTrackingBills.slice(0, index),
                {
                    ...inventoryTrackingBills[index],
                    loading: true
                },
                ...inventoryTrackingBills.slice(index + 1)
            ];
 
            return {
                ...state,
                inventory_tracking_bills: newInventoryTrackingBills
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.SUCCESS: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const index = inventoryTrackingBills.findIndex(inventoryTrackingBill => inventoryTrackingBill.inventory_tracking_bill.id === request.id);

            if (index === -1) {
                return state;
            }

            const newInventoryTrackingBills = [
                ...inventoryTrackingBills.slice(0, index),
                {
                    ...inventoryTrackingBills[index],
                    ...lodash.pick(payload, 'inventory_tracking_bill'),
                    loading: false
                },
                ...inventoryTrackingBills.slice(index + 1)
            ];
 
            return {
                ...state,
                count_inventory_tracking_bills: lodash.get(payload, 'count_inventory_tracking_bills', {}),
                inventory: lodash.get(payload, 'inventory', {}),
                inventory_tracking_bills: newInventoryTrackingBills
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.FAILED: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const index = inventoryTrackingBills.findIndex(inventoryTrackingBill => inventoryTrackingBill.inventory_tracking_bill.id === request.id);

            if (index === -1) {
                return state;
            }

            const newInventoryTrackingBills = [
                ...inventoryTrackingBills.slice(0, index),
                {
                    ...inventoryTrackingBills[index],
                    loading: false
                },
                ...inventoryTrackingBills.slice(index + 1)
            ];
 
            return {
                ...state,
                inventory_tracking_bills: newInventoryTrackingBills
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.REQUEST: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const newInventoryTrackingBills = [];

            for (const inventoryTrackingBill of inventoryTrackingBills) {
                const newInventoryPackage = {
                    ...inventoryTrackingBill
                };

                if (payload.ids.includes(inventoryTrackingBill.inventory_tracking_bill.id)) {
                    newInventoryPackage.loading = true;
                } else {
                    newInventoryPackage.loading = false;
                }

                newInventoryTrackingBills.push(newInventoryPackage);
            }
 
            return {
                ...state,
                inventory_tracking_bills: newInventoryTrackingBills
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.SUCCESS: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const newInventoryTrackingBills = [];

            for (const inventoryTrackingBill of inventoryTrackingBills) {
                let newInventoryTrackingBill = {
                    ...inventoryTrackingBill
                };
                const index = payload.inventory_tracking_bills.findIndex(item => item.inventory_tracking_bill.id === inventoryTrackingBill.inventory_tracking_bill.id);

                if (index !== -1) {
                    newInventoryTrackingBill = {
                        ...newInventoryTrackingBill,
                        ...payload.inventory_tracking_bills[index],
                        loading: false
                    };
                }

                newInventoryTrackingBills.push(newInventoryTrackingBill);
            }
 
            return {
                ...state,
                inventory_tracking_bills: newInventoryTrackingBills,
                count_inventory_tracking_bills: lodash.get(payload, 'count_inventory_tracking_bills', {})
            };
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.FAILED: {
            const inventoryTrackingBills = state.inventory_tracking_bills;
            const newInventoryTrackingBills = [];

            for (const inventoryTrackingBill of inventoryTrackingBills) {
                const newInventoryTrackingBill = {
                    ...inventoryTrackingBill
                };

                if (request.ids.includes(inventoryTrackingBill.inventory_tracking_bill.id)) {
                    newInventoryTrackingBill.loading = false;
                }

                newInventoryTrackingBills.push(newInventoryTrackingBill);
            }
 
            return {
                ...state,
                inventory_tracking_bills: newInventoryTrackingBills
            };
        }
        default: {
            return state;
        }
    }
};

const loadingUpdateStatus = (_default = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.UPDATE_INVENTORY_STATUS.REQUEST:
            return true;
        case ACTION.UPDATE_INVENTORY_STATUS.SUCCESS:
        case ACTION.UPDATE_INVENTORY_STATUS.FAILED:
            return false;
        default:
            return _default;
    }
};

const loadingUpdatePackageStatus = (_default = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.REQUEST:
            return true;
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.SUCCESS:
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.FAILED:
            return false;
        default:
            return _default;
    }
};

const list = (state = [], action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_INVENTORIES.SUCCESS: {
            return {
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};


let errors = (state = {}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.FAILED:
            return {...payload, id: lodash.get(action, 'request.data.id')};

        case ACTION.RESET_ERROR:
            let error = {...state};
            return {...error, data: lodash.omit(error.data, payload.params)};
        default:
            return state;
    }
};

const resolvingInventoryTrackingBill = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.REQUEST: {
            return true;
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.SUCCESS:
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILL.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const resolvingDisappearInventoryPackages = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.REQUEST: {
            return true;
        }
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.SUCCESS:
        case ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const resolvingRemainderInventoryPackages = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.REQUEST: {
            return true;
        }
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.SUCCESS:
        case ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const resolvingInventoryTrackingBills = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.REQUEST: {
            return true;
        }
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.SUCCESS:
        case ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    loading,
    detail,
    list,
    loadingUpdateStatus,
	inventoryScan,
	inventoryBarcode,
    historyList,
    checkingInventory,
    updateStatusInventory,
    loadingUpdatePackageStatus,
    resolvingInventoryTrackingBill,
    resolvingInventoryTrackingBills,
    errors,
    create: createReducer,
    resolvingDisappearInventoryPackages,
    resolvingRemainderInventoryPackages
});
