import React from 'react';
import { Modal } from 'antd';

import BaseLog from './../../../Common/Log/LogContainer';
import { t } from './../../../../system/i18n';

const Logs = ({ objectId, visible, onCloseLog }) => (
    <div>
        <Modal
            bodyStyle={{
                height: '60vh',
                overflow: 'auto'
            }}
            className="modal-logs-by-attention"
            footer={null}
            title={t('note:logs.title_popup_log')}
            visible={visible}
            onCancel={onCloseLog}
        >
            <BaseLog
                delayTime={0}
                object="notes"
                objectId={objectId}
            />
        </Modal>
    </div>
);

export default Logs;
