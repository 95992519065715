/* eslint-disable */
import React from 'react';
import {Col, Drawer, Icon, Pagination, Row, Select, Table} from "antd";

const Option = Select.Option;
const data = [];
for (let i = 0; i < 20; i++) {
    data.push({
        codeinventory: `030219956${i}`,
        timeinventory: `00.00 03/02/2019`,
        warehouseinventory: `CNXZ`,
        timecrosscheck: `00.00 03/03/2019`,
        lost: `${i + 2}`,
        warehousing: `${i + 3}`,
        treated: `${i + 3}`,
        waitingtreat: `${i + 3}`,
        personinventory: `Lê Ngọc Yến Nhi`,
    });
}
const data2 = [];
for (let i = 0; i < 20; i++) {
    data2.push({
        stt: `${i}`,
        codepackage: `01231239131`,
        timecrosscheck: `00.00 03/03/2019`,
        timeprocess: `00.00 03/03/2019`,
        action: `Nhập kho`,
    });
}

class ListNotProcess extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {visible: false, halfwindow: 0, iconClose: false};
    }

    showDrawer = () => {
        this.setState({
            visible: true,
            iconClose: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            iconClose: false
        });
    };

    componentDidMount() {
        let halfwindow = window.innerWidth / 2;
        this.setState({halfwindow: halfwindow});
    }

    render() {
        const columns = [
            {
                title: 'Mã kiểm kê',
                dataIndex: 'codeinventory',
                key: 'codeinventory',
                render: text => <a className="a-text--nowrap a-text--blue" onClick={this.showDrawer}>{text}</a>,
            }, {
                title: 'Thời gian kiểm kê',
                dataIndex: 'timeinventory',
                key: 'timeinventory',
                className: "a-text--nowrap",
                sorter: true,
            }, {
                title: 'Thời gian đối soát',
                dataIndex: 'timecrosscheck',
                key: 'timecrosscheck',
                render: text => <span>{text}</span>,
            }, {
                title: 'Số kiện thừa',
                dataIndex: 'warehousing',
                key: 'warehousing',
                render: text => <span>{text}</span>,
            }, {
                title: 'Số kiện thiếu',
                dataIndex: 'lost',
                key: 'lost',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: 'Chờ xử lý',
                dataIndex: 'waitingtreat',
                key: 'waitingtreat',
                className: "a-text--nowrap",
            }, {
                title: 'Kho kiểm kê',
                dataIndex: 'warehouseinventory',
                key: 'warehouseinventory',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: 'Người kiểm kê',
                dataIndex: 'personinventory',
                key: 'personinventory',
                render: text => <span className="a-text--nowrap">{text}</span>,

            }];
        const column2 = [
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                render: text => <span>{text}</span>,
            }, {
                title: 'Mã kiện',
                dataIndex: 'codepackage',
                key: 'codepackage',
                className: "a-text--nowrap",
                render: text => <a className="a-text--nowrap a-text--blue">{text} <span
                    className="a-text--green ml-1">Thừa</span></a>,
                sorter: true,
            }, {
                title: 'Thời gian kiểm kê',
                dataIndex: 'timecrosscheck',
                key: 'timecrosscheck',
                render: text => <span>{text}</span>,
            }, {
                title: 'Thời gian xử lý',
                dataIndex: 'timeprocess',
                key: 'timeprocess',
                render: text => <span>{text}</span>,
            }, {
                title: 'Hành động',
                dataIndex: 'action',
                key: 'action',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }];
        return (
            <div className="a-content__body bg-white a-content__body--page-list inventory-history is-pagination-fixed">
                <Table className={"a-table--antd"} columns={columns} dataSource={data}
                       pagination={false}
                />
                <div className="a-pagination a-pagination--fixed">
                    <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                    <div className="a-pagination--select-number-item">
                        <span className="text-show">Hiển thị</span>
                        <Select defaultValue="10" className="a-select--select-number-show">
                            <Option value="10">10</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListNotProcess;