import React, { useState } from 'react'
import { Button, notification } from 'antd'
import { withTranslation } from 'react-i18next'
import apiService from '../../apiService'
function SyncAgency({ t, packageData }) {
    const { code } = packageData
    const [loading, setLoading] = useState(false);
    function handleOnClick() {
        setLoading(true);
        apiService
            .syncToAgency({ codes: [code] })
            .then(res => {
                notification.success({ message: t('package:message.sync_agency_success') })
            })
            .catch(err => {
                notification.error({ message: t('package:message.sync_agency_fail') })
            }).finally(() => {
                setLoading(false)
            })
    }
    return (
        <Button
            className='mr-4 a-btn a-btn--history-in-package _btn-sync-agency'
            onClick={handleOnClick}
            loading={loading}
            >
            {t('package:btn.sync_agency')}
        </Button>
    )
}

export default withTranslation()(SyncAgency)
