import {all, fork} from 'redux-saga/effects';
import createWarehouse from "./CreateWarehouse/sagaWatcher";
import createWarehouseStaff from "./CreateWarehouseStaff/sagaWatcher";
import listWarehouse from "./ListWarehouse/sagaWatcher";
import detailWarehouse from "./DetailWarehouse/sagaWatcher";
import updateWarehouse from "./UpdateWarehouse/sagaWatcher";
import detailWarehouseStaff from "./DetailWarehouseStaff/sagaWatcher";
import listWarehouseStaff from "./ListWarehouseStaff/sagaWatcher";
import updateWarehouseStaff from "./EditWarehouseStaff/sagaWatcher";
import createStaffInWarehouse from "./AddStaffInWarehouse/sagaWatcher";
import removeStaffInWarehouse from "./RemoveStaffInWarehouse/sagaWatcher";

export default function* () {
	yield all([
		fork(createWarehouse),
		fork(listWarehouse),
		fork(detailWarehouse),
		fork(updateWarehouse),
		fork(listWarehouseStaff),
		fork(updateWarehouseStaff),
		fork(detailWarehouseStaff),
		fork(createWarehouseStaff),
		fork(createStaffInWarehouse),
		fork(removeStaffInWarehouse),
	]);
}
