import { combineReducers } from 'redux';
import { get, includes, isObject, omit, round } from 'lodash';

import * as ACTION from './constants';
import { BARCODE_TYPE, SCAN_BARCODE } from './../DeliveryNoteBarcode/constants';
import { addBarcode, removeBarcode } from './../DeliveryNoteBarcode/reducer';
import { STAGES, UPDATE_NOTE } from './../../Notes/constants';

const barcodes = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.ADD_BARCODE: {
            return addBarcode(state, payload.code, payload);
        }
        case ACTION.REMOVE_BARCODE: {
            return removeBarcode(state, payload);
        }
        case SCAN_BARCODE.SUCCESS: {
            const { customer } = payload;
            const customerId = get(customer, 'id');
            let newState = state;

            const warning = payload.package_warning;
            const code = action.request.code;

            const data = {
                code,
                type: BARCODE_TYPE.PACKAGE,
                warning,
                ...payload
            };

            // If scan bag code
            if (isObject(payload.bag) && payload.bag.code === code) {
                data.type = BARCODE_TYPE.BAG;
                data.warning = payload.bag_warning;
            }

            if (!customerId) {
                return newState;
            }

            return addBarcode(state, code, data);
        }
        case ACTION.SET_BARCODES: {
            return payload;
        }
        case ACTION.CLEAR_STATE: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const deliveryNote = (state = {}, action) => {
    const { payload, request, type } = action;

    switch (type) {
        case SCAN_BARCODE.SUCCESS: {
            const object = get(request, 'params.object');
            const newBarcodes = barcodes(state.barcodes, action);
            const newDeliveryNote = {};
            const barcodeType = request.params.barcode_type.toLowerCase();

            if (!object) {
                return state;
            }

            if (newBarcodes.length === 1) {
                newDeliveryNote.delivery_note = {
                    identity: get(state, 'delivery_note.identity') ? state.delivery_note.identity + 1 : 1,
                    length: get(payload, `${barcodeType}.length`) ? round(get(payload, `${barcodeType}.length`) * 100, 2) : undefined,
                    width: get(payload, `${barcodeType}.width`) ? round(get(payload, `${barcodeType}.width`) * 100, 2) : undefined,
                    height: get(payload, `${barcodeType}.height`) ? round(get(payload, `${barcodeType}.height`) * 100, 2) : undefined
                };
            } else {
                newDeliveryNote.delivery_note = {
                    identity: get(state, 'delivery_note.identity') ? state.delivery_note.identity + 1 : 1,
                    length: undefined,
                    width: undefined,
                    height: undefined
                };
            }

            return {
                ...newDeliveryNote,
                barcodes: newBarcodes
            };
        }
        case ACTION.REMOVE_BARCODE: {
            const newBarcodes = barcodes(state.barcodes, action);
            const newState = {
                ...state,
                barcodes: newBarcodes
            };

            if (get(newState, 'delivery_note.identity')) {
                newState.delivery_note.identity += 1;
            }

            return newState;
        }
        case ACTION.SET_BARCODES: {
            const newBarcodes = barcodes(state.barcodes, action);
            return {
                ...state,
                barcodes: newBarcodes
            };
        }
        case ACTION.CLEAR_STATE: {
            return {};
        }
        case UPDATE_NOTE.SUCCESS: {
            if (get(payload, 'stage') !== STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE) {
                return state;
            }

            const barcodes = state.barcodes || [];

            const newBarcodes = barcodes.map(barcode => {
                const notes = barcode.notes || [];
                const index = notes.findIndex(note => note.id === get(payload, 'note.id'));

                if (index === -1) {
                    return barcode;
                }

                const isRemoveNote = !includes(get(payload, 'note.stages'), STAGES.ATTACH_PACKAGE_TO_DELIVERY_NOTE);
                const newNotes = isRemoveNote ? [
                    ...notes.slice(0, index),
                    ...notes.slice(index + 1)
                ] : [
                    ...notes.slice(0, index),
                    {
                        ...notes[index],
                        ...get(payload, 'note', {})
                    },
                    ...notes.slice(index + 1)
                ];

                return {
                    ...barcode,
                    notes: newNotes
                };
            });

            return {
                ...state,
                barcodes: newBarcodes
            };
        }
        default: {
            const newBarcodes = barcodes(state.barcodes, action);

            return {
                ...state,
                barcodes: newBarcodes
            };
        }
    }
};

const creating = (state = false, action) => {
    switch (action.type) {
        case ACTION.CREATE.REQUEST: {
            return true;
        }
        case ACTION.CREATE.SUCCESS:
        case ACTION.CREATE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const errors = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.CREATE.REQUEST: {
            return {};
        }
        case ACTION.CREATE.FAILED: {
            return payload;
        }
        case ACTION.RESET_ERROR: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: omit(state.data, payload.field)
            };
        }
        case ACTION.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    creating,
    deliveryNote,
    errors
});
