import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FormDeliveryType from './components';
import {withTranslation} from 'react-i18next';
import {LIST_DELIVERY_NOTE_STATUS} from "./constants";

const mapStateToProps = (state, props) => ({
    ...props
});

class FormBagShippingTypeContainer extends React.Component {
    render() {
        const { excludes } = this.props;
        const deliveryNoteStatuses = [...LIST_DELIVERY_NOTE_STATUS].filter(status => {
            if (!excludes || !excludes.length) {
                return true;
            }

            return !excludes.includes(status.id);
        });
        return (
            <FormDeliveryType {...this.props} deliveryNoteStatuses={deliveryNoteStatuses} />
        );
    }
}

let Container = connect(
    mapStateToProps
)(FormBagShippingTypeContainer);

Container.defaultProps = {
    deliveryNoteStatuses: [],
    errors: {},
    disabled: false,
    onChange: () => {
    },
};

Container.propTypes = {
    deliveryNoteStatuses: PropTypes.array,
    value: PropTypes.string,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withTranslation()(Container);
