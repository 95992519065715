/* eslint-disable */
import React, {Component} from 'react';
import { Row, Col, Button, Layout, Input} from 'antd';

const {Sider} = Layout;

class SiderBarcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            warehouse: "CNGZ"
        }
    };

    onChangeWarehouse = (warehouse) => {
        this.setState({warehouse});
    }

    render() {
        const {warehouse} = this.state;
        return (
            <Sider className={"a-sider-sub a-sider-sub--radius"}>
                <div className="a-sider-sub__block mt-5">
                    <h4 className="a-sider-sub__block__title">Hành động</h4>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--w-100">Xuất</Button>
                        </Col>
                        <Col span={8}>
                            <Button className="a-btn a-btn--default a-btn--w-100">Nhập</Button>
                        </Col>
                        <Col span={8}>
                            <Button className="a-btn a-btn--default a-btn--w-100">Kiểm</Button>
                        </Col>
                    </Row>
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">Khâu vận hành</h4>
                    <Row gutter={8} className={"a-form--group-button--warehouse"}>
                        <Button type="primary"
                                onClick={e => this.onChangeWarehouse("CNGZ")}
                                className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNGZ" ? 'active' : ''} `}>CNGZ</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNPX" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNPX")}>CNPX</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "VNHNX" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("VNHNX")}>VNHNX</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "VNSG" ? 'active' : ''}`}
                            onClick={e => this.onChangeWarehouse("VNSG")}>VNSG</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''}`}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                    </Row>
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">Quét mã</h4>
                    <Input className="a-input--scan-code a-input--disable"/>
                </div>
            </Sider>
        )
    }
}

export default SiderBarcode