/* eslint-disable */
import React from 'react';
import {Button, Empty, Modal, notification, Table} from 'antd';
import clsx from 'clsx';
import {withTranslation} from "react-i18next";
import lodash from "lodash";
import {DELIVERY_REQUEST_COLUMN, DELIVERY_REQUEST_CUSTOMER_COLUMN, DELIVERY_REQUEST_STATUS} from "../constants";
import {dateFormatter} from "../../../Common/services/format";
import Pagination from '../../../Common/components/Pagination';
import PropTypes from 'prop-types';
import CancelDeliveryRequest from "../../CancelDeliveryRequest/CancelDeliveryRequest";
import PERMISSIONS from "../../../Auth/permissions"
import {Link} from "../../../../system/routing";

import apiService from './../../apiService';
import { handleDeliveringDeliveryRequestError } from './../../helper';
import {getVar} from './../../../../system/support/helpers';

class ListContentDeliveryRequest extends React.PureComponent {
    state = {
        deliveringDeliveryRequestId: undefined,
        expandedRowKeys: []
    };

    getTitleColumn(column) {
        switch (column) {
            case DELIVERY_REQUEST_CUSTOMER_COLUMN.ACTION:
                return;
            default :
                return this.props.t('delivery_request:column.' + column)
        }
    }

    getColumns() {
        let arrSort = [];
        return lodash.values(DELIVERY_REQUEST_COLUMN)
            .map(column => {
                let res = {
                    className: clsx(`_${column}`, {
                        'a-text--nowrap': column !== DELIVERY_REQUEST_COLUMN.DELIVERY_NOTES
                    }),
                    key: column,
                    dataIndex: column,
                    width: column === "action" && "120px",
                    title: this.getTitleColumn(column),
                };

                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by
                            ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : null,
                    }
                }
                return res;
            });
    }

    getDeliveryRequest() {
        let {deliveryRequests, permissions, filter} = this.props;
        return deliveryRequests.map(deliveryRequest => {
            deliveryRequest = getVar(deliveryRequest, 'delivery_request');
            let detailDeliveryRequest = {
                ...deliveryRequest,
                agency: getVar(deliveryRequest, 'agency.name')
            };
            return {
                key: deliveryRequest.id,
                [DELIVERY_REQUEST_COLUMN.ID_CUSTOMER]: (
                    <a
						className="a-text--nowrap a-text--blue _id-customer"
                    	href="#top"
					>
						{getVar(deliveryRequest, 'customer.full_name')} ({getVar(deliveryRequest, 'customer.username', '--')})
					</a>
                ),
                [DELIVERY_REQUEST_COLUMN.ID_DELIVERY_REQUEST]: getVar(deliveryRequest, 'deliveryNote', '') &&
                filter.status !== DELIVERY_REQUEST_STATUS.STATUS_CANCELED ? (
                    <Link
                        className="a-text--nowrap a-text--blue"
                        params={{id: getVar(deliveryRequest, 'deliveryNote', '--')}}
                        to="delivery-notes.customer.detail"
                    >
                        {getVar(deliveryRequest, 'code', '--')}
                    </Link>
                ) : (
                    <Link
                        className="a-btn--button-link a-text--nowrap a-text--blue"
                        onClick={this.showDeliveryRequest.bind(this, lodash.get(deliveryRequest, 'code', ''), getVar(deliveryRequest, 'agency.name'))}
                    >
                        {getVar(deliveryRequest, 'code', '--')}
                    </Link>
                ),
                [DELIVERY_REQUEST_COLUMN.DELIVERY_NOTES]: (
                    <div className="gflex flex-wrap">
                        {lodash.map(deliveryRequest.delivery_notes, (deliveryNote, index) => (
                            <span className="a-text--nowrap" key={deliveryNote.id}>
                                <Link
                                    isNewTab
                                    params={{id: deliveryNote.code}}
                                    to="delivery-notes.customer.detail"
                                >
                                    {deliveryNote.code}
                                </Link>
                                {index !== deliveryRequest.delivery_notes.length - 1 && (
                                    <span className="mr-2">,</span>
                                )}
                            </span>
                        ))}
                    </div>
                ),
                [DELIVERY_REQUEST_COLUMN.AGENCY]: <span>
                {lodash.get(deliveryRequest, 'agency.name', '--')} {lodash.get(deliveryRequest, 'agency.identify_name') && showAlias
                ? `(${lodash.get(deliveryRequest, 'agency.identify_name')})` : null}</span>,
                [DELIVERY_REQUEST_COLUMN.WAREHOUSE]: getVar(deliveryRequest, 'warehouse.name', '--'),
                [DELIVERY_REQUEST_COLUMN.CREATE_AT]:
                    deliveryRequest['created_at'] ? dateFormatter.dateTime(deliveryRequest['created_at']) : '--',
                [DELIVERY_REQUEST_COLUMN.NUM_PACKAGE]: getVar(deliveryRequest, 'total_package', '--'),
                [DELIVERY_REQUEST_COLUMN.TOTAL_WEIGHT]: getVar(deliveryRequest, 'total_weight', '--') + '(kg)',
                [DELIVERY_REQUEST_COLUMN.ADDRESS]: getVar(deliveryRequest, 'address', '--') + (getVar(deliveryRequest, 'location.detail') ? ", " : " ") +
                getVar(deliveryRequest, 'location.detail', '--'),
                [DELIVERY_REQUEST_COLUMN.ACTION]: lodash.includes(permissions, PERMISSIONS.DELIVERY_REQUEST_CANCEL) && filter.status === DELIVERY_REQUEST_STATUS.STATUS_PROCESSING && (
                    <>
                        {this.renderDeliveringDeliveryRequestButton(detailDeliveryRequest)}
                        <CancelDeliveryRequest detailDeliveryRequest={detailDeliveryRequest}/>
                    </>
                )
            };
        })
    }

    showDeliveryRequest = (code, agency) => {
        this.props.showDetailDeliveryRequest(code, agency);
	};

    renderDeliveringDeliveryRequestButton = deliveryRequest => {
        const { deliveringDeliveryRequestId } = this.state;
        const { t } = this.props;

		return (
			<Button
				className="a-btn--link a-text--link _btn-delivering"
				loading={deliveringDeliveryRequestId === deliveryRequest.id}
				type="link"
				onClick={this.deliveringDeliveryRequest.bind(this, deliveryRequest)}
			>
				{t('processed')}
			</Button>
		);
    };

    deliveringDeliveryRequest = (deliveryRequest, event) => {
        const { t } = this.props;

		event.stopPropagation();

		Modal.confirm({
			cancelButtonProps: {
				className: '_btn-cancel-delivering-delivery-request'
			},
			cancelText: t('btn.cancel'),
			className: 'a-modal a-modal--warning',
            content: t('delivery_request:delivering.confirm'),
            okButtonProps: {
				className: '_btn-confirm-delivering-delivery_request'
			},
			okText: t('btn.ok'),
            onOk: this.confirmDeliveringDeliveryRequest.bind(undefined, deliveryRequest.id)
        });
	};

	confirmDeliveringDeliveryRequest = async deliveryRequestId => {
		const { filter, t, onChangeFilter } = this.props;

		this.setState({
			deliveringDeliveryRequestId: deliveryRequestId
		});

		try {
			await apiService.delivering(deliveryRequestId);

			onChangeFilter(filter);

			notification.success({
				message: t('delivery_request:delivering.success')
			});
		} catch (error) {
			handleDeliveringDeliveryRequestError(error);
		}

		this.setState({
			deliveringDeliveryRequestId: undefined
		});
	};

    onChangePage(page, pageSize) {
        if (!this.props.loading.loadingDeliveryRequest) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    render() {
        let {t, loading, filter, pagination,} = this.props;
        return (
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed mt-0">
                <div className="a-table--responsive">
                    <Table className={"a-table--antd"}
                           columns={this.getColumns()}
                           dataSource={this.getDeliveryRequest()}
                           pagination={false}
                           loading={loading.loadingDeliveryRequest}
                           rowClassName="row-show-item-hover"
                           locale={{
                               emptyText: (
                                   <Empty
                                       image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                       description={<span>{t("common:label.no_data")}</span>}
                                   >
                                   </Empty>)
                           }}

                    />
                </div>
                <Pagination
                    page={lodash.toInteger(filter.page) || 1}
                    pageSize={lodash.toInteger(filter.per_page)}
                    total={pagination.deliveryRequests ? pagination.deliveryRequests.total : 0}
                    loading={this.props.loading.loadingDeliveryRequest}
                    onChange={this.onChangePage.bind(this)}/>
            </div>
        )
    }
}

ListContentDeliveryRequest.defaultProps = {
    loading: {},
    deliveryRequests: [],
    pagination: {},
    onSearch: () => {
    },
    filter: {},
};

ListContentDeliveryRequest.propTypes = {
    loading: PropTypes.object,
    deliveryRequests: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    filter: PropTypes.object,
};


export default withTranslation()(ListContentDeliveryRequest);
