import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Select} from 'antd';
import lodash from 'lodash';

const {Option} = Select;

function ChooseOrderChangeReadyForDeliveries(props) {
    const {canDeliveries, setBarcodes, readyForDeliveries} = props;
    const [orderList, setOrderList] = useState([]);

    useEffect(() => {
        if (canDeliveries) {
            const orderList = [];
            canDeliveries.map(canDelivery => {
                const orderCode = lodash.get(canDelivery, "order.code", "");
                if (orderCode && !orderList.includes(orderCode)) {
                    orderList.push(orderCode);
                }
            });
            setOrderList(orderList);
        } else {
            setOrderList([]);
        }
    }, [canDeliveries]);
    const handleChange = (value) => {
        if (value) {
            const barcodes = [];
            canDeliveries.map(item => {
                const orderCode = lodash.get(item, "order.code", "");
                if (value === "all" || orderCode === value) {
                    barcodes.push({
                        ...item
                    });
                }
            });
            setBarcodes([...barcodes, ...readyForDeliveries]);
        }
    };
    return (
        <Select
            showSearch
            allowClear={true}
            style={{width: "200px"}}
            onChange={handleChange}
            placeholder="Giao kiện theo đơn"
        >
            {
                orderList.length > 0 && <Option key={'all'} value={'all'}>Tất cả</Option>
            }
            {
                orderList.map(order => {
                    return <Option key={order} value={order}>{order}</Option>
                })
            }
        </Select>
    )
}

export default withTranslation()(ChooseOrderChangeReadyForDeliveries)