import React, { Component } from 'react';
import { Row } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import pageService from './../../../../Common/Page/pageService';

import ListDeliveryNote from './ListDeliveryNoteExport';
import InfoShippingPartnerDeliveryNote from './ShippingPartner/InfoShippingPartnerDeliveryNote';
import InfoShippingPartnerDeliveryNoteLoading from './ShippingPartner/InfoShippingPartnerDeliveryNoteLoading';
import Log from './../../../../Common/Log/LogContainer';

class DeliveryNoteDetail extends Component {
    componentDidMount() {
        this.updatePageTitle();
        this.props.setMenuActive('delivery-notes/global-shipping-partner/detail');
    }

    componentDidUpdate(prevProps) {
        if (
            get(this.props.deliveryNote, 'delivery_note.id') !== get(prevProps.deliveryNote, 'delivery_note.id')
            || this.props.loading !== prevProps.loading
        ) {
            this.updatePageTitle();
        }
    }

    updatePageTitle() {
        const { t, deliveryNote } = this.props;

        pageService.setTitle(`${t('transport_warehouse_delivery_note')} - ${(get(deliveryNote, 'delivery_note_transport_warehouse.code') ? deliveryNote.delivery_note_transport_warehouse.code : '')}`);
    }

    render() {
        const { cancelling, deliveryNote, exporting, loading, t, onCancel, onExport, onPrint } = this.props;

        if (!get(deliveryNote, 'delivery_note_transport_warehouse.id')) {
            return null;
        }

        const checkPermission = !!get(deliveryNote, 'permissions.can_update') ||
            !!get(deliveryNote, 'permissions.can_export') ||
            !!get(deliveryNote, 'permissions.can_cancel') ||
            (get(deliveryNote, 'delivery_note_transport_warehouse.status') !== 'EXPORTED' && get(deliveryNote, 'delivery_note_transport_warehouse.status') !== 'CANCELED');

        return (
            <Row type="flex">
                <div
                    className={clsx('a-content--scroll-content pt-0 gflex gflex-direction-column', {
                        'not-navigation-fixed': !checkPermission
                    })}
                >
                    {loading ? (
                        <InfoShippingPartnerDeliveryNoteLoading />
                    ) : (
                        <InfoShippingPartnerDeliveryNote
                            {...this.props}
                            permissions={deliveryNote.permissions}
                        />
                    )}

                    <ListDeliveryNote
                        {...this.props}
                        cancelling={cancelling}
                        deliveryNoteTransportWarehouse={deliveryNote.delivery_note_transport_warehouse}
                        exporting={exporting}
                        loading={loading}
                        permissions={deliveryNote.permissions}
                        onCancel={onCancel}
                        onExport={onExport}
                        togglePopupListPackageByBag={this.togglePopupListPackageByBag}
                        onPrint={onPrint}
                    />
                </div>
                <div
                    className={clsx('aml-24 alog', {
                        'not-navigation-fixed': !checkPermission
                    })}
                >
                    <div className="title">
                        <span>{t('delivery_note:transport_warehouse.log_title')}</span>
                    </div>

                    <Log delayTime={0} object="delivery-note-transport-warehouses" objectId={deliveryNote.delivery_note_transport_warehouse.id} />
                </div>
            </Row>
        );
    }
}

export default withTranslation()(DeliveryNoteDetail);
