import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t, trans} from './../../../system/i18n';
import {setPackagePage} from '../CreatePackageViaScan/actions';
import {put} from 'redux-saga/effects';
import {router} from '../../../system/routing';
import lodash from 'lodash';
import React from "react";

export function* updateAlonePackage(action) {
    yield processApiRequest(ACTION.UPDATE_ALONE_PACKAGE, () => apiService.updateAlonePackage(action.payload), action.payload);
}

export function* createAlonePackage(action) {
    yield processApiRequest(ACTION.CREATE_ALONE_PACKAGE, () => apiService.createAlonePackage(action.payload), action.payload);
}

export function* updateAlonePackageSuccess(action) {
    if (action.payload) {
        yield put(setPackagePage(action.request.id));
        yield notification['success']({
            message: t('message.update_success'),
        });
    }
}

export function* createAlonePackageSuccess(action) {
    if (action.payload) {
        router.redirect("/packages/detail/" + lodash.get(action, 'payload.package.code', 0));
        yield notification['success']({
            message: t('message.create_alone_package_success'),
        });
    }
}

export function* createAlonePackageFailed(action) {
    const error = lodash.get(action, 'payload.data');

    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className="_scan_package_failed">{t('message.warehouse_not_is_staff')}</span>,
        });
    }

    if (lodash.get(error, 'order_code.order_is_cancel')) {
        return yield notification['error']({
            message: (
                <span className="_scan_package_failed">
                    {trans('package:message.scan_failed_code', {
                        code: (
                            <b>{lodash.get(error, 'order_code.order_is_cancel.code')}</b>
                        ),
                        status: (
                            <b>
                                {t(`package:status_transport.${lodash.get(error, 'order_code.order_is_cancel.status')}`)}
                            </b>
                        )
                    })}
                </span>
            ),
        });
    }
}

export function* updateAlonePackageFailed(action) {
    const error = lodash.get(action, 'payload.data');

    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className="_scan_package_failed">{t('message.warehouse_not_is_staff')}</span>,
        });
    }

    if (lodash.get(error, 'order_code.order_is_cancel')) {
        return yield notification['error']({
            message: (
                <span className="_scan_package_failed">
                    {trans('package:message.scan_failed_code', {
                        code: (
                            <b>{lodash.get(error, 'order_code.order_is_cancel.code')}</b>
                        ),
                        status: (
                            <b>
                                {t(`package:status_transport.${lodash.get(error, 'order_code.order_is_cancel.status')}`)}
                            </b>
                        )
                    })}
                </span>
            ),
        });
    }
}



