import React, {Component} from 'react';
import {Select} from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import api from './../../../Customer/apiService';
import { getVar } from './../../../../system/support/helpers';

const {Option} = Select;

class SelectCustomerDeliveryNote extends Component {
    state = {
        customers: [],
        loading: false,
        username: ''
    };

    componentWillUnmount() {
        this.props.clearErrorDetailCustomer();
    }

    handleChangeUserName = username => {
        const { clearDetailCustomer, clearErrorDetailCustomer } = this.props;

        this.setState({
            username
        });

        clearErrorDetailCustomer();
        clearDetailCustomer();

        this.doSearch(username);
    };

    handleSelectUser = userId => {
        const { fetchDetailCustomer } = this.props;

        fetchDetailCustomer(userId);
    };

    onSearch = async username => {
        this.setState({
            loading: true
        });

        try {
            const response = await api.getSuggests({
                username
            });

            const data = lodash.get(response, 'data.customers', []) || [];

            this.setState({
                customers: data
            });
        } catch (error) {
            this.setState({
                customers: []
            });
        }

        this.setState({
            loading: false
        });
    };

    doSearch = lodash.debounce(this.onSearch, 250);

    handleCancel = () => {
        this.setState({
            loading: false,
            customers: []
        });
    };

    render() {
        const {t} = this.props;
        const {customers, loading} = this.state;

        return (
            <>
                <Select
                    className="a-select a-select--export-scan _customer"
                    disable={this.props.loading}
                    filterOption={(input, option) => {
                        return (option.props.label ? option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) || (option.props.code ? option.props.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                    }}
                    loading={loading || this.props.loading}
                    name="username"
                    optionFilterProp="children"
                    optionLabelProp="label"
                    placeholder={t('delivery_note:placeholder.choose_customer')}
                    showSearch
                    onSearch={this.handleChangeUserName}
                    onSelect={this.handleSelectUser}
                >
                    {customers.map(customer => (
                        <Option code={getVar(customer, 'customer.code', '--')} key={lodash.get(customer, 'customer.id')} label={getVar(customer, 'customer.username', '--')} value={lodash.get(customer, 'customer.id')}>
                            <span className="a-text--black">{getVar(customer, 'customer.username', '--')}</span>
                            <br />
                            <span className="a-text--fz-12 a-text--gray-70">{getVar(customer, 'customer.code', '--')} ({getVar(customer, 'agency.code') || getVar(customer, 'customer.agency.code') || '--'})</span>
                        </Option>
                    ))}
                </Select>
            </>
        );
    }
}

SelectCustomerDeliveryNote.defaultProps = {
    loading: false
};

SelectCustomerDeliveryNote.propTypes = {
    fetchDetailCustomer: PropTypes.func,
    loading: PropTypes.bool
};

export default withTranslation()(SelectCustomerDeliveryNote);
