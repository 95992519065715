import React, { Component } from 'react';
import { Form, Icon, Input } from 'antd';

const { TextArea } = Input;

class QuickEditUpdateDelivery extends Component {
    state = {
        isShowInputEdit: false
    };

    onClickEditInline = (status) => {
        this.setState({
            isShowInputEdit: status
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { form, id, updateDelivery } = this.props;

        form.validateFields((err, values) => {
            if (!err) {
                updateDelivery(id, values);

                this.onClickEditInline(false);
            }
        });
    };

    render() {
        const { isShowInputEdit } = this.state;
        const { form, permissions, t, updating, value } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form>
                <div className="a-inline-edit">
                    <label className="a-inline-edit--label">
                        <label className="info--label d-inline">
                            {t('delivery_note:label.note')}
                        </label>
                        {isShowInputEdit ? null : (
                            <>
                                {!!permissions && (
                                    <>
                                        {updating ? (
                                            <Icon type="loading-3-quarters" className="icon-loading icon-inline" />
                                        ) : null}

                                        {updating ? null : (
                                            <Icon
                                                className="icon-inline"
                                                type="edit"
                                                onClick={this.onClickEditInline.bind(this, true)}
                                            />
                                        )}
                                    </>
                                )}
                                <div className="info--value">{value ? value : '--'}</div>
                            </>
                        )}

                        {isShowInputEdit ? (
                            <>
                                <Icon
                                    className="icon-inline"
                                    type="close"
                                    onClick={this.onClickEditInline.bind(this, false)}
                                />
                                <Icon type="check" onClick={this.handleSubmit} className="icon-inline ml-0" />
                                <div className="a-inline-edit--input">
                                    <Form.Item className="note _note">
                                        {getFieldDecorator('note', {
                                            rules: [{ max: 255, message: t('delivery_note:delivery.length_required_maximum_255') }],
                                            initialValue: value
                                        })(
                                            <TextArea
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.handleSubmit(e);
                                                    }
                                                }}
                                                autoFocus
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            </>
                        ) : null}
                    </label>
                </div>
            </Form>

        );
    }
}

QuickEditUpdateDelivery = Form.create()(QuickEditUpdateDelivery);

export default QuickEditUpdateDelivery;
