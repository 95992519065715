import React, { PureComponent } from 'react';
import { get } from 'lodash';

import { INVENTORY_TYPES } from './../../constants';
import pageService from './../../../Common/Page/pageService';

import Packages from './Packages';
import ReceivePackages from './ReceivePackages';

class InventoryProcess extends PureComponent {
    componentDidMount() {
        const { match: { params: { id } }, t, fetchInventoryDetail, setMenuActive } = this.props;

        fetchInventoryDetail(id);
        pageService.setTitle(t('inventory:comparing.main'));
        setMenuActive('inventory/create');
    }

    render() {
        const { inventory } = this.props;
        const inventoryType = get(inventory, 'type');

        if (inventoryType === INVENTORY_TYPES.PACKAGE.key) {
            return (
                <Packages {...this.props} />
            );
        }

        if (inventoryType === INVENTORY_TYPES.RECEIVED.key) {
            return (
                <ReceivePackages {...this.props} />
            );
        }

        return null;
    }
}


export default InventoryProcess;
