/* eslint-disable */
import React from 'react';
import {Button, Icon, Select, Table, Pagination, Modal} from 'antd';
const Option = Select.Option;
const columns = [
    {
        title: 'Mã kiện',
        dataIndex: 'codepackage',
        key: 'codepackage',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    }, {
        title: 'Mã đơn',
        dataIndex: 'code',
        key: 'code',
        render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
    }, {
        title: 'Cân nặng',
        dataIndex: 'weight',
        key: 'weight',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kích thước',
        dataIndex: 'size',
        key: 'size',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: '',
        dataIndex: 'remove',
        key: 'remove',
        render: text => <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">{text}</a>,
    }];

const data = [];
for (let i = 0; i < 10; i++) {
    data.push({
        codepackage: `H1234-75011909${i}`,
        code: `12312319883429342`,
        size: `17${i} (cm3)`,
        weight: `17${i} (kg)`,
        remove: 'Xóa'
    });
}

function confirm() {
    Modal.confirm({
        title: 'Xóa kiện',
        content: 'Xóa kiện khỏi bao HHA999-9999999 ?',
        okText: 'Đồng ý',
        cancelText: 'Hủy',
        zIndex: 1050,
        icon: <Icon type="close-circle" className={"a-text--error"} theme="filled"/>,
        centered: true
    });
}

class  ListPackageInBag extends React.PureComponent{
    render(){
        return(
            <div className="list-package-in-bag">
                <div className="a-content__top a-content__top--page-list  mr-0">
                    <div className="a-content--page-list__title" id="title-search">
                        <h3>Danh sách kiện trong bao (302)</h3>
                    </div>
                    <div className="a-content--page-list__top-action" id="content-list">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                        >Quét kiện vào bao
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white content-list-package-in-bag  ">
                    <div className="">
                        <div className="a-table--responsive a-table--antd">
                            <table className="a-table a-table--barcode-scan">
                                <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã kiện</th>
                                    <th>Thời Gian Kiểm kê </th>
                                    <th>Kho kiểm kê</th>
                                    <th>Kho đích</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>

                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>

                                </tr>
                                </tbody>
                                <tbody className="error">
                                <tr className="data">
                                    <td>4</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>

                                </tr>
                                <tr className="message">
                                    <td colSpan="6">
                                                <span className="a-text--black-45">
                                                <Icon type='close-circle' theme="filled" className="a-text--red mr-2"/>
                                                Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>

                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td>6</td>
                                    <td><span className="a-text--nowrap">987293847928927</span></td>
                                    <td>00.00 03/02/1995</td>
                                    <td>CNPX</td>
                                    <td>CNPX</td>
                                    <td> <a href="javascript:void(0)" onClick={confirm} className="a-text--nowrap a-action-hover-show">Xoá</a></td>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="content-list-package-in-bag--empty">
                        <img src={require('../../../../../resources/images/boxempty.png')}/>
                        <span className="info">CHƯA CÓ KIỆN NÀO TRONG BAO. “<a
                            href="#">ĐÓNG BAO</a>” NGAY</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default ListPackageInBag;