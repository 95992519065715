import React, {Component} from 'react';
import {connect} from "react-redux";
import {createWarehouseStaff} from "./actions";
import CreateWarehouseStaff from "../CreateWarehouseStaff/components";
import {clearState} from "../DetailWarehouseStaff/actions";
import {fetchUsers} from "../../User/actions";
import {fetchSuggestWarehouses} from "../../Warehouse/actions";
import {setMenuActive} from "../../Common/actions";
import { getState } from './selectors';

const mapStateToProps = state => {
    return {
        adding: getState(state, 'adding')
    };
};

const mapDispatchToProps = (dispatch) => ({
    createWarehouseStaff: (params) => dispatch(createWarehouseStaff(params)),
    clearState: () => dispatch(clearState()),
    fetchUsers: (params) => dispatch(fetchUsers(params)),
    fetchListWarehouse: () => dispatch(fetchSuggestWarehouses()),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class CreateWarehouseStaffContainer extends Component {
    componentDidMount() {
        this.props.fetchUsers({active_warehouse_staff: false});
        this.props.fetchListWarehouse();
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        return <CreateWarehouseStaff {...this.props}/>
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateWarehouseStaffContainer)