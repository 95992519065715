import { api } from './../../system/api'

export default {
    importCreatePackage: data => {
        return api.post('/packages/expedited-processing/check-for-create-packages', data)
    },
    checkDistributes: data => {
        return api.post('/packages/expedited-processing/check-for-distributions', data)
    },
    createDistributes: data => {
        return api.post('/packages/expedited-processing/distributions', data)
    },
    rePayment: data => {
        return api.post('/packages/expedited-processing/payments', data)
    },
    wasPaid: data => {
        return api.post('/packages/expedited-processing/was-paid', data)
    },
}
