/* eslint-disable */
import React, { Component } from 'react'
import { Table, Button, Icon } from 'antd'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { LIST_WAREHOUSE_STAFF_COLUMN } from './../../constants'

class List extends Component {
    state = {
        expand: true,
    }

    handleExpand = code => {
        const oldExpand = this.state.expand
        this.setState({
            expand: oldExpand === code ? true : code,
        })
    }
    getColumns = () => {
        return lodash.values(LIST_WAREHOUSE_STAFF_COLUMN).map(column => {
            if ([LIST_WAREHOUSE_STAFF_COLUMN.WAREHOUSE, LIST_WAREHOUSE_STAFF_COLUMN.TRANSPORT_STATUS].includes(column)) {
                return {
                    key: column,
                    dataIndex: column,
                    title: this.props.t('config:column.' + column),
                    className: "column-hover",
                    render: (text, row) => {
                        return {
                            children: (
                                <div
                                    onClick={() => this.handleExpand(row.code_warehouse_staff)}
                                    className={this.state.expand === row.code_warehouse_staff ? 'mb-0' : 'mb-0 ellipsis-3'}>
                                    {text}
                                </div>
                            ),
                        }
                    },
                }
            }
            return {
                key: column,
                dataIndex: column,
                title: column !== LIST_WAREHOUSE_STAFF_COLUMN.ACTION ? this.props.t('config:column.' + column) : '',
                fixed: column === LIST_WAREHOUSE_STAFF_COLUMN.ACTION && 'right',
                render: (text, row) => {
                    const active = row.active ? 'a-notification-dot a-notification-dot--table active' : 'a-notification-dot a-notification-dot--table unactive'
                    const listClass = {
                        code_warehouse_staff: active,
                        action: 'a-text--right',
                    }
                    return {
                        children: text,
                        props: {
                            className: clsx(listClass[column], `_row-${column}`, {
                                'a-text--nowrap': ![LIST_WAREHOUSE_STAFF_COLUMN.WAREHOUSE, LIST_WAREHOUSE_STAFF_COLUMN.TRANSPORT_STATUS].includes(column),
                            }),
                        },
                    }
                },
            }
        })
    }

    render() {
        const { listWarehouseStaff, loading } = this.props

        return (
            <div className="a-content__body bg-white a-content__body--page-list height-100">
                <Table
                    className="a-table--antd a-table--antd-scroll-x _a-table-warehouse-staff"
                    columns={this.getColumns()}
                    bordered={true}
                    dataSource={listWarehouseStaff}
                    loading={loading}
                    pagination={false}
                    rowKey={record => {
                        return record.key
                    }}
                    scroll={{ x: 'fit-content' }}
                />
            </div>
        )
    }
}

List.defaultProps = {
    listWarehouseStaff: [],
    loading: false,
}

List.propTypes = {
    listWarehouseStaff: PropTypes.array,
    loading: PropTypes.bool,
}
export default withTranslation()(List)
