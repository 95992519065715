import {api} from '../../system/api';

export default {
    scanBarcode: (params) => api.post('barcode-scannings/scan', {...params}, {singleRequest: false}),
    scanReceive: (params) => api.post('barcode-scannings/scan-receive', {...params}, {singleRequest: false}),
    scanReceiveBag: (params) => api.post('barcode-scannings/scan-receive-bag', {...params}, {singleRequest: false}),
    export: params => api.get('barcode-scannings/export', {
        params,
        responseType: 'blob'
    }),
    updateWeightVolume: (packageCode, data) => api.put(`packages/${packageCode}/update-weight-volume`, data)
};
