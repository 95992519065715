import {combineReducers} from 'redux';
import create from './Create/reducer';
import updateDeliveryNote from './UpdateDeliveryNote/reducer';
import deliveryNoteBarcode from './DeliveryNoteBarcode/reducer';
import detailDeliveryNote from './DetailDeliveryNote/reducer';
import listDeliveryNote from './ListDeliveryNote/reducer';
import deliveryNoteCancel from './CancelDeliveryNote/reducer';
import deliveryNotePrint from './DeliveryNotePrint/reducer';
import formCustomer from './FormCustomer/reducer';
import returnReducer from './Return/reducer';

export default combineReducers({
    listDeliveryNote,
    create,
    updateDeliveryNote,
    deliveryNoteBarcode,
    detailDeliveryNote,
    deliveryNoteCancel,
    deliveryNotePrint,
    formCustomer,
    return: returnReducer
});
