import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import get from 'lodash/get';
import omit from 'lodash/omit';

import pageService from './../../../Common/Page/pageService';
import { useDeepCompareEffect } from './../../../../hooks';

import BottomContent from './BottomContent';
import RemainingPackagesModal from './RemainingPackagesModal';
import TopContent from './TopContent';

const { Content } = Layout;

const CreateDomesticShippingOrder = ({
    addingOrder,
    customer,
    deliveryNote,
    domesticShippingInfo,
    loading,
    match,
    orders,
    printing,
    remainingPackages,
    selectedOrder,
    selectedOrderIndex,
    t,
    getCustomer,
    getDeliveryNote,
    getDomesticShippingDetail,
    onCreateOrder,
    onDeleteOrder,
    onCreateSuggestOrder,
    onPrint,
    onSelectOrder,
    onSuccessExportDeliveryNote
}) => {
    const [remainingPackagesModalVisible, setRemainingPackagesModalVisible] = useState(false);

    const handleCreateOrderButtonClick = () => {
        setRemainingPackagesModalVisible(true);
    };

    const handleCancelRemainingPackagesModal = () => {
        setRemainingPackagesModalVisible(false);
    };

    const handleCreateSuggestOrder = (packages, packageIds) => {
        setRemainingPackagesModalVisible(false);
        onCreateSuggestOrder(packages, packageIds);
    };

    const handleCreateOrder = data => {
        const formattedData = omit(data, ['province', 'district', 'ward']);

        if (data.ward) {
            formattedData.id_location = data.ward;
        } else if (data.district) {
            formattedData.id_location = data.district;
        } else if (data.province) {
            formattedData.id_location = data.province;
        }

        if (selectedOrder.bag) {
            formattedData.bags = [selectedOrder.bag.id];
        } else {
            formattedData.packages = selectedOrder.packages.map(pkg => pkg.id);
        }

        onCreateOrder(formattedData);
    };

    useDeepCompareEffect(() => {
        if (get(domesticShippingInfo, 'customer.id')) {
            getCustomer(domesticShippingInfo.customer.id);
        }
    }, [domesticShippingInfo]);

    useEffect(() => {
        getDeliveryNote(match.params.id);
        getDomesticShippingDetail(match.params.id);
        pageService.setTitle(t('shipping_order_list'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Spin />
        );
    }

    if (!get(domesticShippingInfo, 'id')) {
        return null;
    }

    return (
        <Content className="amr-16-lg amr-24 delivery-note__domestic-shipping-orders">
            <TopContent
                className="amb-16"
                customer={customer}
                deliveryNote={deliveryNote}
                domesticShippingInfo={domesticShippingInfo}
                remainingPackages={remainingPackages}
                onCreate={handleCreateOrderButtonClick}
                onSuccessExportDeliveryNote={onSuccessExportDeliveryNote}
            />

            <BottomContent
                addingOrder={addingOrder}
                domesticShippingInfo={domesticShippingInfo}
                orders={orders}
                printing={printing}
                selectedOrder={selectedOrder}
                selectedOrderIndex={selectedOrderIndex}
                onCreateOrder={handleCreateOrder}
                onDeleteOrder={onDeleteOrder}
                onPrint={onPrint}
                onSelectOrder={onSelectOrder}
                deliveryNote={deliveryNote}
            />

            <RemainingPackagesModal
                packages={remainingPackages}
                visible={remainingPackagesModalVisible}
                onCancel={handleCancelRemainingPackagesModal}
                onSubmit={handleCreateSuggestOrder}
            />
        </Content>
    );
};

export default CreateDomesticShippingOrder;
