import {dispatch} from '../../../system/store';
import * as actions from './actions';

class PageService {
    constructor() {
        this.title = null;
        this.description = null;
    }

    /**
     * Set the page's title
     *
     * @param {string} title
     * @param {string|ReactNode} description
     * @param {string} browserTitle
     */
    setTitle(title, description = null, browserTitle = null) {
        this.title = title;
        this.description = description;
        document.title = browserTitle || title;
        dispatch(actions.updatePageTitle());
    }
}

export {PageService}

export default new PageService();