import lodash from 'lodash';
import {createSelector} from "reselect";

const locations = (state) => lodash.get(state, 'location.list', []);
const idCountry = (state) => lodash.get(state, 'location.currentCountry', 11935);
const idCity = (state) => lodash.get(state, 'location.currentCity', 0);
const idDistrict = (state) => lodash.get(state, 'location.currentDistrict', 0);

export const getCityLocations = createSelector(
    locations,
    idCountry,
    (locations, idCountry) => filterCityByCountry(idCountry, locations)
);

export const getDistrictLocations = createSelector(
    locations,
    idCity,
    (locations, idCity) => filterDistrictByCity(locations, idCity)
);

export const getWardLocations = createSelector(
    locations,
    idDistrict,
    (locations, idDistrict) => filterWardByDistrict(locations, idDistrict)
);

export const getListCountry = createSelector(
    locations,
    locations => filterCountry(locations)
);

// export const getLocation = createSelector(
//     locations,
//     idLocation,
//     (locations, idLocation) => filterLocation(locations)
// );

// export const filterLocation = (locations, idLocation) => lodash.filter(locations, (location) => {
//     let result;
//     if(idLocation === location.id){
//         result = location.label
//     }
//     return result
// });

export const filterCountry = (locations) => lodash.filter(locations, (location) => {
    return (location.location.type === 'COUNTRY');
});

export const filterCityByCountry = (idCountry, locationsInput) => {
    let counties = lodash.filter(locationsInput,location => {
        return location.location.type === "COUNTRY" && idCountry === location.location.id;
    });
    let codeCountry = counties.length > 0 ? counties[0].location.code : '';
    return lodash.filter(locationsInput, (location) => {
        return (location.location.type === 'PROVINCE' && location.location.code_parent === codeCountry);
    });
};

export const filterDistrictByCity = (locationsInput, idCityInput) => {
    let cities = lodash.filter(locationsInput,location => {
        return location.location.type === "PROVINCE" && idCityInput === location.location.id;
    });
    let codeCity = cities.length > 0 ? cities[0].location.code : '';
        return lodash.filter(locationsInput, (location) => {
        return (location.location.type === 'DISTRICT' && location.location.code_parent === codeCity);
    });
};

export const filterWardByDistrict = (locationsInput, idDistrictInput) => {
    let districts = lodash.filter(locationsInput,location => {
        return location.location.type === "DISTRICT" && idDistrictInput === location.location.id;
    });
    let codeWard = districts.length > 0 ? districts[0].location.code : '';
        return lodash.filter(locationsInput, (location) => {
        return (location.location.type === 'WARD' && location.location.code_parent === codeWard);
    });
};

export const getDefaultCountry = createSelector(
    locations,
    (locations) => findDefaultCountry(locations)
);

export const findDefaultCountry = (locations) => {
    let arrLocation = lodash.filter(locations, (location) => {
        return (location.location.type === 'COUNTRY' && location.location.code === "vietnam");
    });
    if (arrLocation.length > 0) {
        return arrLocation[0].location;
    } else {
        return {id: 11935};
    }
};
