export const FETCH_CONSIGN_SERVICE_GROUPS = {
    REQUEST: 'SERVICE.FETCH_CONSIGN_SERVICE_GROUPS.REQUEST',
    SUCCESS: 'SERVICE.FETCH_CONSIGN_SERVICE_GROUPS.SUCCESS',
    FAILED: 'SERVICE.FETCH_CONSIGN_SERVICE_GROUPS.FAILED'
};

export const FETCH_SERVICE_GROUPS = {
    REQUEST: 'SERVICE.FETCH_SERVICE_GROUPS.REQUEST',
    SUCCESS: 'SERVICE.FETCH_SERVICE_GROUPS.SUCCESS',
    FAILED: 'SERVICE.FETCH_SERVICE_GROUPS.FAILED',
};

export const FETCH_SERVICES = {
    REQUEST: 'SERVICE.FETCH_SERVICES.REQUEST',
    SUCCESS: 'SERVICE.FETCH_SERVICES.SUCCESS',
    FAILED: 'SERVICE.FETCH_SERVICES.FAILED'
};

export const FETCH_CONSIGN_SERVICES = {
    REQUEST: 'SERVICE.FETCH_CONSIGN_SERVICES.REQUEST',
    SUCCESS: 'SERVICE.FETCH_CONSIGN_SERVICES.SUCCESS',
    FAILED: 'SERVICE.FETCH_CONSIGN_SERVICES.FAILED'
};
