import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function* () {
    yield takeLatest(ACTION.FETCH_BAGS.REQUEST, saga.fetchBags);
    yield takeLatest(ACTION.EXPORT_BAGS_CSV.REQUEST, saga.exportBags);
    yield takeLatest(ACTION.EXPORT_BAGS_CSV.SUCCESS, saga.exportBagsSuccess);
    yield takeLatest(ACTION.EXPORT_BAGS_CSV.FAILED, saga.exportBagsFailed);
}
