import React from 'react';
import lodash from 'lodash';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import {t} from '../../../../system/i18n';
import api from '../../apiService';
import {ChatItem} from "./ChatItem";
import {ChatInput} from "./ChatInput";

class BagChat extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            comments: [],
            loadingAddInternalCommentByCode: false,
            pagination: {
                total: 0
            }
        }
    }

    componentDidMount() {
        const bagId = lodash.get(this.props.bag, 'id');
        if (bagId > 0) {
            this.fetchComments().then(response => {
                this.setStateFromCommentsResponse(response);
            })
        }
    }

    fetchComments = (params) => {
        const bagId = lodash.get(this.props.bag, 'id');
        this.setState({loading: true});
        return new Promise((resolve, reject) => {
            api.getInternalCommentsByCode(bagId, params).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            }).finally(() => {
                this.setState({loading: false});
            })
        });
    };

    setStateFromCommentsResponse = (response) => {
        this.setState({
            comments: lodash.get(response.data, 'items', []) || [],
            pagination: lodash.get(response.data, 'pagination')
        })
    };

    onSubmitComment = (data) => {
        if (data.comment || data.attachments.length) {

            const bagId = lodash.get(this.props.bag, 'id');
            const attachments = lodash.get(data, 'attachments', []) || [];
            let formData = new FormData();
            formData.append('comment', data.comment);
            lodash.each(attachments, (file) => {
                formData.append(`images[]`, file);
            });
            let comments = [].concat(this.state.comments);
            comments.splice(0, 0, {loading: true, id: 'loading'});
            this.setState({loadingAddInternalCommentByCode: true, comments});
            api.addInternalCommentByCode(bagId, formData)
                .then((res) => {
                    comments.splice(0, 1, res.data);
                    this.setState({comments})
                })
                .catch(err => {
                    if (err.message !== "Network Error") {
                        notification.error({
                            message: t('bag:message.bag_chat_error')
                        });
                    }
                    comments.shift();
                    this.setState({comments})
                }).finally(fn => {
                this.setState({loadingAddInternalCommentByCode: false});
            })
        } else {
            notification.error({
                message: t('bag:message.bag_chat_required')
            });
        }
    };
    onShowImage = (previewImage) => {
        this.setState({previewImage, previewVisible: true})
    };
    handleCancel = () => this.setState({previewVisible: false});

    render() {
        const {comments, loading, loadingAddInternalCommentByCode, previewVisible, previewImage} = this.state;
        return (
            <React.Fragment>
                <Spin spinning={loading} wrapperClassName="list-chat-spinner">
                    <ChatInput
                        onSubmit={this.onSubmitComment}
                        loading={loadingAddInternalCommentByCode}
                    />
                    <div className="list-chat-checking-bag pb-5">
                        {comments.map(item => (
                            <ChatItem
                                onShowImage={this.onShowImage}
                                key={lodash.uniqueId('chat_item')}
                                item={item}
                            />
                        ))}
                        <div className={'empty-row'}/>
                    </div>
                </Spin>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} width={880}>
                    <img alt="example" style={{width: '100%'}} className={'mt-5'} src={previewImage}/>
                    <Row type={'flex'} justify={'center'}>
                        <Col>
                            <Button className={'btn btn-primary mt-5 _show-image'}>
                                <a href={previewImage || "#top"} target={'_blank'} rel="noopener noreferrer">
                                    {t('bag:btn.show_image')}
                                </a>
                            </Button>
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        )
    }
}

export default BagChat;
