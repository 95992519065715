import {message} from 'antd';
import {auth} from '../../system/api';
import {router} from '../../system/routing';
import {t} from '../../system/i18n';
import authService from './authService';

export function* onAuthSuccess(action) {
    auth.setToken(action.payload.token);
    router.redirect(action.payload.callbackUrl || '/');
    yield message.success(t('auth:message.auth_success'));
}

export function* onLogout() {
    yield authService.logout();
}
