import { Alert, Icon, Skeleton } from 'antd'
import clsx from 'clsx'
import React from 'react'
import { t } from '../../../../system/i18n'
import { getVar } from '../../../../system/support/helpers'
import { renderNotices, useNotes } from '../../../Common/components/Attentions'
import { STAGES } from '../../../Notes/constants'
import WarehouseStatusCard from './WarehouseStatusCard'
import { linkHTC } from '../../../Package/helper'

const Package = ({ bag, canDelete, packageData, page }) => {
    const agencyCode = getVar(bag, 'agency.code', '')
    const linkHtc = linkHTC(agencyCode)
    const notes = getVar(packageData, 'notes', []);
    const noteElements = useNotes(notes, STAGES.ATTACH_PACKAGE_TO_BAG);
    const notices = getVar(packageData, 'notices.text', []) || [];
    const haveNotes = !!(Array.isArray(notes) && notes.length);
    const haveNotices = !!(Array.isArray(notices) && notices.length);
    let tbodyClass = '';

    if (haveNotes || haveNotices) {
        tbodyClass = 'warning'
    };
    if (packageData.class_error) {
        tbodyClass = packageData.class_error
    };

    return (
        <tbody className={tbodyClass}>
            {packageData.loading ? (
                <tr className="data">
                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                    {page === 'packing' ? (
                        <td>
                            <Skeleton active paragraph={{rows: 0}} />
                        </td>
                    ) : null}
                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                    <td></td>
                </tr>
            ) : (
                <tr className="data">
                    <td className="_package_code">{packageData.code_package_text}</td>
                    {page === 'packing' ? (
                        <td className="_package_time_scan_display">
                            {packageData.time_scan_display}
                        </td>
                    ) : null}
                    <td className={"_package_order_code"}>{getVar(packageData, 'code_order') ?
                        <a href={ linkHtc ? linkHtc + "/order/detail/default/" + getVar(packageData, 'code_order') : getVar(packageData, 'order_url') || '#top'}
                        rel="noopener noreferrer"
                        target={getVar(packageData, 'order_url') ? '_blank' : '_self'}>{getVar(packageData, 'code_order')}</a> : '--'}</td>
    
                    <td>{getVar(packageData, 'customer_username', '--')} ({getVar(packageData, 'customer', '--')})</td>
                    <td className={"_package_weight"}>{packageData.weight_package_text}</td>
                    <td className={"_package_volume"}>{packageData.volume_package_text}</td>
                    {page === 'detail' && (
                        <>
                            <td className={"_package_current_warehouse_code"}>{getVar(packageData, 'current_warehouse.code', '--')}</td>
                            <td className={"_package_destination_warehouse_code"}>{getVar(packageData, 'destination_warehouse.code', '--')}</td>
                            <td className={"_package_status_warehouse"}>
                                <WarehouseStatusCard status={getVar(packageData, 'status_warehouse')} packageData={packageData} />
                            </td>
                        </>
                    )}

                </tr>
            )}

            {!!(haveNotes || haveNotices || packageData.message_error) && (
                <tr className="message">
                    <td colSpan={page === 'packing' ? 6 : 9} className="_box_message_error">
                        {packageData.message_error && (
                            <p className="mb-2 a-text--black-45 a-flex _package_message_error">
                                {packageData.class_error === 'warning' ?
                                    <Icon type='info-circle' theme="filled"
                                        className="a-text--orange mr-2"/>
                                    : <Icon type='close-circle' theme="filled"
                                            className="a-text--red mr-2"/>
                                }
                                {packageData.message_error}
                            </p>
                        )}
                        {(haveNotes || haveNotices) && (
                            <>
                                <div
                                    className={clsx({
                                        'mb-2': notices && notices.length
                                    })}
                                >
                                    {noteElements}
                                </div>
                                {renderNotices(notices)}
                            </>
                        )}
                    </td>
                </tr>
            )}
        </tbody>
    );
};



const TableChildPackage = ({ bag, canDelete, packageData, page }) => {
  
    const notes = getVar(packageData, 'notes', [])
    const noteElements = useNotes(notes, STAGES.ATTACH_PACKAGE_TO_BAG)
    const notices = getVar(packageData, 'notices.text', []) || []
    const haveNotes = !!(Array.isArray(notes) && notes.length)
    const haveNotices = !!(Array.isArray(notices) && notices.length)

    let tbodyClass = ''

    if (haveNotes || haveNotices) {
        tbodyClass = 'warning'
    }
    if (packageData.class_error) {
        tbodyClass = packageData.class_error
    }

    return (
        <>
            <div
                className={
                    'a-content__body bg-white a-barcode-scan__body aheight-100 p-0 m-0 ml-5 mt-5' +
                    (page === 'detail' ? 'content-list-package-in-bag' : ' list-is-sidebar a-content__body--page-list')
                }>
                {/* {bag.message_warnings && bag.message_warnings !== '' && page === 'packing' ? (
                    <Alert
                        className="mb-4"
                        message={bag.message_warnings}
                        showIcon
                        type="warning"
                    />
                ) : null} */}
                <div className="a-table--responsive a-table--antd">
                    <table className="a-table a-table--barcode-scan _table_list_package">
                        <thead>
                            <tr>
                                <th>{t('bag:packing.code_package')}</th>
                                {page === 'packing' ? (
                                    <>
                                        <th>
                                            {t('bag:packing.time_scan')}
                                            <div className="sort">
                                                <Icon
                                                    type="caret-up"
                                                    // className={sort === 'up' ? 'active' : ''}
                                                />
                                                <Icon
                                                    type="caret-down"
                                                    // className={sort === 'down' ? 'active' : ''}
                                                />
                                            </div>
                                        </th>
                                    </>
                                ) : null}
                                <th>{t('bag:packing.code_order')}</th>
                                {page === 'packing' ? null : <th>{t('customer')}</th>}
                                <th>{t('bag:packing.weight')}</th>
                                <th>{t('bag:packing.volume')}</th>
                                {page === 'detail' && (
                                    <>
                                        <th>{t('bag:packing.warehouse')}</th>
                                        <th>{t('bag:packing.destination_warehouse')}</th>
                                        <th className="a-text--center">{t('bag:packing.status_warehouse')}</th>
                                    </>
                                )}
                            </tr>
                        </thead>

                        {packageData.length
                            ? packageData.map(data => (
                                  <Package
                                      bag={bag}
                                      canDelete={canDelete}
                                      key={data.id}
                                      packageData={data}
                                      page={page}
                                  />
                              ))
                            : null}
                    </table>
                </div>
            </div>
        </>
    )
}

export default TableChildPackage
