import React from 'react';
import {Select} from 'antd';
import clsx from 'clsx';
import { first, get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class SelectBagSize extends React.Component {
    constructor(props) {
        super(props);
        this.autoSelected = false;
    }

    componentDidMount() {
        this.autoSelect();
    }

    componentDidUpdate() {
        this.autoSelect();
    }

    /**
     * Xử lý tự động select reason đầu tiên khi không truyền vào reason hiện tại
     */
    autoSelect() {
        let {value, bagSizes} = this.props;
        if (this.props.autoSelect && !this.autoSelected && !value && bagSizes.length) {
            this.autoSelected = true;
            this.changeInput({value: get(first(bagSizes), 'id')});
        }
    }

    changeInput(input) {
        const { isFieldDecorator, onChange } = this.props;

        if (isFieldDecorator) {
            onChange(input.value);
        } else {
            onChange(input);
        }
    }

    render() {
        const { allowClear, bagSizes, disabled, dropdownClassName, loading, placeholder, value } = this.props;
        const formattedSelectProps = {
            allowClear,
            className: 'a-select a-select--w-100 _bag-size',
            defaultValue: this.autoSelected ? get(first(bagSizes), 'name') : (placeholder ? undefined : ''),
            disabled,
            dropdownClassName,
            loading,
            optionFilterProp: 'children',
            placeholder,
            showSearch: true,
            value: bagSizes.length ? (bagSizes.some(item => item.id === value) ? value : undefined) : undefined,
            onChange: value => this.changeInput({
                value
            })
        };

        return (
                <Select {...formattedSelectProps}>
                    {bagSizes.map((bagSize, index) => (
                        <Option
                            key={get(bagSize, 'id', `bag-size-${index}`)}
                            value={bagSize.id}
                            className={clsx('_bag-size__item', `_bag-size-item-${bagSize.id}`)}
                        >
                            {bagSize.name}
                        </Option>
                    ))}
                </Select>
        );
    }
}

SelectBagSize.defaultProps = {
    bagSizes: [],
    errors: {},
    value: 0,
    autoSelect: false,
    disabled: false,
    onChange: () => {
    },
    placeholder : '',
    loading: false
};

SelectBagSize.propTypes = {
    loading: PropTypes.bool,
    bagSizes: PropTypes.array,
    value: PropTypes.number,
    errors: PropTypes.object,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

export default withTranslation()(SelectBagSize);
