import * as _ from 'lodash';
import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import {INVENTORY_STATUS_CHECKING, INVENTORY_STATUS_DONE} from './constants';
import apiService from './apiService';
import {Modal, notification} from "antd";
import {t, trans} from "../../system/i18n";
import {router} from "../../system/routing";
import React from "react";

export function* fetchInventoryDetail(action) {
    yield processApiRequest(ACTION.FETCH_INVENTORY_DETAIL, () => apiService.fetchInventoryDetail(action.payload.id), action.payload);
}

export function handleOnOk(inventory) {
    router.redirect(`/inventory/scan/` + inventory.id);
}

export function* fetchInventorySuccess(action) {
    if (_.get(action, 'payload.inventory.status', '') === INVENTORY_STATUS_CHECKING) {
        yield  Modal.confirm({
            title: t("label.warning"),
            content:
                <p>{trans("inventory:message.inventory_is_status_checking", {code: t('inventory:status_inventory.' + action.payload.inventory.status).toLowerCase()})}</p>,
            okText: t("btn.ok"),
            cancelText: t("btn.cancel"),
            onOk: () => handleOnOk(action.payload.inventory),
        });
    }
}

export function* fetchInventoryFailed(action) {
    if (_.get(action, 'payload.data.id_inventory', '') !== '') {
        yield router.redirect("/404");
    }
}

export function* updateInventoryUpdate(action) {
    yield processApiRequest(ACTION.UPDATE_INVENTORY_STATUS, () => apiService.updateInventoryStatus(action.payload.id, action.payload.status), action.payload);
}

export function* updateInventoryUpdateSuccess(action) {
    let message = t("inventory:update_success");
    if(_.get(action.payload, 'inventory.status') === INVENTORY_STATUS_DONE) {
        message = t('inventory:update_status_done_success');
    }
    yield notification.success({
        message: message
    });

    yield fetchInventoryDetail({
        payload: {
            id: _.get(action.payload, 'inventory.id')
        }
    });
}

export function* updateInventoryUpdateFailed(action) {
    let error = _.get(action, 'payload.data');
    if (_.get(error, 'warehouse')) {
        return yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
    let messageKey = _.get(action.payload, 'data.id_inventory');
    let message = t('inventory:update_failed');
    if (messageKey.hasOwnProperty('inventory_is_not_complete_resolved')) {
        message = t('inventory:inventory_is_not_complete_resolved');
    }
    return yield notification.error({
        message: message
    })
}

export function* resolveInventoryTrackingBill(action) {
    yield processApiRequest(ACTION.RESOLVE_INVENTORY_TRACKING_BILL, () => apiService.resolveInventoryTrackingBill(action.payload), action.payload);
}

export function* resolveInventoryTrackingBillSuccess() {
    yield notification.success({
        message: t('inventory:update_tracking_bill_status.success')
    });
}

export function* resolveInventoryTrackingBillFailed(action) {
    const error = _.get(action, 'payload.data');

    if (_.get(error, 'warehouse')) {
        return yield notification.error({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (_.get(error, '403.package') === 'not_permission_change_transport_status') {
        return yield notification.error({
            message: t('inventory:update_tracking_bill_status.errors.403.package.not_permission_change_transport_status')
        });
    }

    if (action.status_code !== 403) {
        return yield notification.error({
            message: t('inventory:update_tracking_bill_status.failed')
        });
    }
}

export function* updateInventoryPackageStatus(action) {
    yield processApiRequest(ACTION.UPDATE_INVENTORY_PACKAGE_STATUS, () => apiService.updateInventoryPackageStatus(action.payload), action.payload);
}

export function* updateInventoryPackageStatusSuccess() {
    yield notification.success({
        message: t('inventory:update_package_status_success')
    });
}

export function* updateInventoryPackageStatusFailed(action) {
    const error = _.get(action, 'payload.data');

    if (_.get(error, 'warehouse')) {
        return yield notification['error']({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (_.get(error, '403.package') === 'not_permission_change_transport_status') {
        return yield notification.error({
            message: t('inventory:update_package_status.errors.403.package.not_permission_change_transport_status')
        });
    }

    if (action.status_code !== 403) {
        return yield notification.error({
            message: t('inventory:update_package_status_failed')
        });
    }
}

export function* resolveDisappearInventoryPackages(action) {
    yield processApiRequest(ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES, () => apiService.resolveDisappearInventoryPackages(action.payload), action.payload);
}

export function* resolveDisappearInventoryPackagesSuccess() {
    yield notification.success({
        message: t('inventory:update_package_status_success')
    });
}

export function* resolveDisappearInventoryPackagesFailed(action) {
    const error = _.get(action, 'payload.data');

    if (_.get(error, 'warehouse')) {
        return yield notification['error']({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (_.get(error, '403.package') === 'not_permission_change_transport_status') {
        return yield notification.error({
            message: t('inventory:update_package_status.errors.403.package.not_permission_change_transport_status')
        });
    }

    if (_.get(error, 'ids.required')) {
        return yield notification.error({
            message: t('inventory:resolve_inventory_packages.errors.ids.required')
        });
    }

    if (action.status_code !== 403) {
        return yield notification.error({
            message: t('inventory:update_package_status_failed')
        });
    }
}

export function* resolveRemainderInventoryPackages(action) {
    yield processApiRequest(ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES, () => apiService.resolveRemainderInventoryPackages(action.payload), action.payload);
}

export function* resolveRemainderInventoryPackagesSuccess() {
    yield notification.success({
        message: t('inventory:update_package_status_success')
    });
}

export function* resolveRemainderInventoryPackagesFailed(action) {
    const error = _.get(action, 'payload.data');

    if (_.get(error, 'warehouse')) {
        return yield notification['error']({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (_.get(error, '403.package') === 'not_permission_change_transport_status') {
        return yield notification.error({
            message: t('inventory:update_package_status.errors.403.package.not_permission_change_transport_status')
        });
    }

    if (_.get(error, 'ids.required')) {
        return yield notification.error({
            message: t('inventory:resolve_inventory_packages.errors.ids.required')
        });
    }

    if (_.get(error, 'ids.remainder_package_has_been_in_stock')) {
        return yield notification.error({
            message: t('inventory:resolve_inventory_packages.errors.ids.remainder_package_has_been_in_stock')
        });
    }

    if (action.status_code !== 403) {
        return yield notification.error({
            message: t('inventory:update_package_status_failed')
        });
    }
}

export function* resolveInventoryTrackingBills(action) {
    yield processApiRequest(ACTION.RESOLVE_INVENTORY_TRACKING_BILLS, () => apiService.resolveInventoryTrackingBills(action.payload), action.payload);
}

export function* resolveInventoryTrackingBillsSuccess() {
    yield notification.success({
        message: t('inventory:update_tracking_bill_status.success')
    });
}

export function* resolveInventoryTrackingBillsFailed(action) {
    const error = _.get(action, 'payload.data');

    if (_.get(error, 'warehouse')) {
        return yield notification.error({
            message: t('message.warehouse_not_is_staff')
        });
    }

    if (_.get(error, '403.package') === 'not_permission_change_transport_status') {
        return yield notification.error({
            message: t('inventory:update_tracking_bill_status.errors.403.package.not_permission_change_transport_status')
        });
    }

    if (_.get(error, 'ids.required')) {
        return yield notification.error({
            message: t('inventory:update_tracking_bill_status.errors.ids.required')
        });
    }

    if (action.status_code !== 403) {
        return yield notification.error({
            message: t('inventory:update_tracking_bill_status.failed')
        });
    }
}

export function* fetchInventories(action) {
    yield processApiRequest(ACTION.FETCH_INVENTORIES, () => apiService.fetchInventories({...action.payload.filter}), action.payload);
}

