import * as ACTION from './../ScanBarcodeForm/constants';
import * as saga from './saga';
import {takeLatest, takeEvery} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.SCAN_BARCODE_FORM.REQUEST, saga.scanBarcode);
    yield takeEvery(ACTION.SCAN_BARCODE_FORM.FAILED, saga.scanBarcodeFailed);
    yield takeEvery(ACTION.SCAN_BARCODE_FORM.SUCCESS, saga.scanBarcodeSuccess);
    yield takeLatest(ACTION.SCAN_RECEIVED.REQUEST, saga.scanReceived);
    yield takeEvery(ACTION.SCAN_RECEIVED.SUCCESS, saga.scanReceivedSuccess);
    yield takeEvery(ACTION.SCAN_RECEIVED.FAILED, saga.scanReceivedFailed);
    yield takeLatest(ACTION.SCAN_RECEIVED_BAG.REQUEST, saga.scanReceivedBag);
    yield takeEvery(ACTION.SCAN_RECEIVED_BAG.SUCCESS, saga.scanReceivedBagSuccess);
    yield takeEvery(ACTION.SCAN_RECEIVED_BAG.FAILED, saga.scanReceivedBagFailed);
}
