import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t, trans} from '../../../system/i18n';
import lodash from 'lodash';
import React from 'react';
import {fetchPackageDetail} from "../PackageDetail/actions"
import {put} from 'redux-saga/effects';
import {STATUS_TRANSPORT_PACKAGE} from "./constants";
export function* changePackageTransportStatus(action) {
    yield processApiRequest(ACTION.CHANGE_PACKAGE_TRANSPORT_STATUS, () => apiService.changePackageTransportStatus(action.payload), action.payload);
}

export function* changePackageTransportStatusFailed(action) {
    if (action.payload) {
        if (Array.isArray(lodash.get(action, 'payload.data.code.status_transport_invalid', null))) {
            yield  notification['error']({
                message: t('package:message.change_package_transport_status_invalid'),
            });
        } else if (lodash.get(action, 'payload.data.code.package_in_open_delivery_note')) {
            yield  notification['error']({
                message: t('package:message.package_in_open_delivery_note'),
            });
        } else {
            yield  notification['error']({
                message: t('package:message.change_package_transport_status_failed'),
            });
        }

    }
}

export function* changePackageTransportStatusSuccess(action) {
    switch (action.request.status_transport) {
        case STATUS_TRANSPORT_PACKAGE.MIA:
        default :
            yield put(fetchPackageDetail(action.request.code));

    }
    yield  notification['success']({
        message: trans('package:message.change_package_transport_status_success', {
            status: <b>{t('package:status_transport.' + action.request.status_transport.toUpperCase()).toLowerCase()}</b>
        }),
    })
    ;
}

export function* updateTransportStatus(action) {
    yield processApiRequest(ACTION.UPDATE_TRANSPORT_STATUS, () => apiService.updateTransportStatus(action.payload), action.payload);
}

export function* failedUpdateTransportStatus(action) {
    if (action.payload) {
        if (Array.isArray(lodash.get(action, 'payload.data.code.status_transport_invalid', null))) {
            yield  notification.error({
                message: t('package:message.change_package_transport_status_invalid'),
            });
        } else if (lodash.get(action, 'payload.data.code.package_in_open_delivery_note')) {
            yield  notification.error({
                message: t('package:message.package_in_open_delivery_note'),
            });
        } else {
            yield  notification.error({
                message: t('package:message.change_package_transport_status_failed'),
            });
        }
    }
}

export function* successUpdateTransportStatus(action) {
    yield  notification.success({
        message: trans('package:message.change_package_transport_status_success', {
            status: t(`package:status_transport.${action.request.status_transport ? action.request.status_transport.toUpperCase() : STATUS_TRANSPORT_PACKAGE.CIRCULATE}`).toLowerCase()
        }),
    });
}
