import React, { Component, Fragment } from 'react';
import { Form, Icon, Layout, Select, Tabs } from 'antd';
import * as lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import apiService from '../../apiService';
import { BAG_SHIPPING_TYPE } from './../../BagShippingType/constants';
import { DEFAULT_STATE_CREATE_BAG } from './../../CreateBag/constants';
import { BAG_COLUMN } from './../../ListBag/constants';

import { ListBagCreatedToday } from './ListBagCreatedToday';
import UpdateVolumeBag from './UpdateVolumeBag';
import BagPackingActionContainer from './../../BagPackingAction/BagPackingActionContainer';
import FormBagShippingTypeContainer from './../../BagShippingType/FormBagShippingType/FormBagShippingTypeContainer';
import SelectBagSizeContainer from './../../../BagSize/SelectBagSize/SelectBagSizeContainer';
import SelectCustomerContainer from './../../../Customer/SelectCustomer/SelectCustomerContainer';
import SelectCreatePartnerContainer from './../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer';
import SelectCreateShippingUserContainer from './../../../ShippingUser/Select/SelectCreate';
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/components/SelectWarehouse';

const { Sider } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

class SiderBagPack extends Component {
    state = {
        editing: false,
        bag: {},
        loadingBagsCreatedToday: false,
        bagsCreatedToday: [],
        volume: 0,
        input: {
            width: 0,
            height: 0,
            length: 0,
        },
        ...DEFAULT_STATE_CREATE_BAG
    };

    onChangeSelectValue = (name, input) => {
        const { bag, errors, resetError } = this.props;
        const value = input && input.value ? input.value : input;

        if (name === 'id_customer') {
            if (this.state.type === 'MULTIPLE') {
                this.setState({[name]: null});
            } else {
                this.setState({[name]: value});
            }
        } else if (name === 'type') {
            if (value === 'MULTIPLE') {
                this.setState({
                    id_customer: null,
                    [name]: value
                });
            } else {
                this.setState({
                    [name]: value,
                    id_customer: bag.id_customer
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }

        if (errors && errors[name]) {
            resetError(name);
        }
    };

    onReset = () => {
        this.props.onReset();
    };

    onCancel= () => {
        const { bag } = this.props;

        this.setState({
            editing: false,
            type: lodash.get(bag, 'type', ''),
            id_customer: lodash.get(bag, 'id_customer', 0),
            id_bag_size: lodash.get(bag, 'id_bag_size', 0),
            subject_shipping: lodash.get(bag, 'subject_shipping'),
            id_shipping_partner: lodash.get(bag, 'id_shipping_partner', 0),
            id_shipping_user: lodash.get(bag, 'id_shipping_user', 0),
            id_warehouse_destination: lodash.get(bag, 'id_warehouse_destination', 0),
            input: {
                width: 0,
                height: 0,
                length: 0
            }
        })
    };

    onEdit = () => {
        this.setState({
            editing: true,
            input: {
                width: lodash.get(this.props.bag, 'width', 0) * 100,
                height: lodash.get(this.props.bag, 'height', 0) * 100,
                length: lodash.get(this.props.bag, 'length', 0) * 100,
            }
        })
    };

    onUpdateParam = () => {
        const { id_bag_size, id_customer, id_shipping_partner, id_shipping_user, id_warehouse_destination, subject_shipping, type } = this.state;
        const { bag, updateBagParam } = this.props;
        let data = {};

        if (type === 'SINGLE') {
            let value = {
                type,
                id_customer: id_customer === 0 ? null : id_customer,
            };
            if (id_bag_size !== 0) {
                value.id_bag_size = id_bag_size;
            }
            value = lodash.pickBy(value, lodash.identity);
            data.value = value;
        } else {
            data.value = {
                type,
            };
            if (id_bag_size !== 0) {
                data.value.id_bag_size = id_bag_size;
            }
        }

        data.value.subject_shipping = subject_shipping;
        data.value.id_warehouse_destination = id_warehouse_destination;


        if (subject_shipping === BAG_SHIPPING_TYPE.USER) {
            data.value.id_shipping_user = id_shipping_user;
        } else if (subject_shipping === BAG_SHIPPING_TYPE.PARTNER) {
            data.value.id_shipping_partner = id_shipping_partner;
        }
        data.value = {...data.value, ...this.state.input};
        data = {
            ...data,
            idBag: lodash.get(bag, 'id', 0),
            param: 'id_customer',
        };

        updateBagParam(data);
    };

    fetchBagsCreatedToday = () => {
        this.setState({loadingBagsCreatedToday: true});
        apiService.getBags({tab_on_day: 1})
            .then(response => {
                this.setState({
                    bagsCreatedToday: lodash.get(response.data, 'bags')
                })
            })
            .finally(() => {
                this.setState({loadingBagsCreatedToday: false});
            })
    };

    onChangeTab = (key) => {
        if (key === 'ListBagCreatedToday') {
            this.fetchBagsCreatedToday();
        }
    };

    onChangeValue = (param, value) => {
        this.setState({
            input: {
                ...this.state.input,
                [param]: value
            }
        })
    };

    componentDidUpdate(prevProps) {
        const { bag } = this.props;

        if (!lodash.isEqual(bag, prevProps.bag)) {
            this.setState({
                editing: false,
                type: lodash.get(bag, 'type', ''),
                id_customer: lodash.get(bag, 'id_customer', ''),
                id_bag_size: lodash.get(bag, 'id_bag_size', ''),
                volume: lodash.get(bag, 'volume', 0),
                subject_shipping: lodash.get(bag, 'subject_shipping'),
                id_shipping_partner: lodash.get(bag, 'id_shipping_partner'),
                id_shipping_user: lodash.get(bag, 'id_shipping_user'),
                id_warehouse_destination: lodash.get(bag, 'id_warehouse_destination'),
            });
        }
    }

    render() {
        const {t, scroll, bag, packingData, canUpdate, errors, bagSize, loading, warehouses} = this.props;
        const bagType = [
            {key: 1, label: t('bag:label.multi_bag'), value: 'MULTIPLE'},
            {key: 2, label: t('bag:label.single_bag'), value: 'SINGLE'}
        ];
        return (
            <Sider className={"a-sider-sub a-sider-sub--radius bag-pack _slider_bag_packing " + (scroll ? 'scroll' : '')}>
                <Tabs className="height-100" defaultActiveKey="FormPackingBag" onChange={this.onChangeTab}>
                    <TabPane tab={t('bag:packing.form_create_packing')} key="FormPackingBag">
                        <div className="bag-pack-detail ">
                            <div className="bag-pack--block" ref="name_bag">
                                <span className="title">{t("bag:label.bag")}</span>
                                <p className="name-bag _bag_code">{bag.code}</p>
                            </div>
                            <BagPackingActionContainer
                                bag={bag}
                                canUpdate={canUpdate}
                                packingData={packingData}
                                typeScan={this.props.typeScan}
                                handleChangeType={this.props.handleChangeType}
                            />
                            <div className="info-bag scroll">
                                <div className="title" ref="info_bag__title">
                                    <span>{t("bag:label.bag_info")}</span>
                                    {
                                        canUpdate ? !loading && this.state.editing ?
                                            <Fragment> <Icon type={"close"}
                                                             onClick={this.onCancel.bind(this)}
                                                             className={`icon-inline _icon_close a-text--blue ml-4 `}/>
                                                <Icon type={"check"}
                                                      onClick={this.onUpdateParam.bind(this)}
                                                      className={`icon-inline _icon_on_update_param a-text--blue ml-2 `}/>
                                            </Fragment> :
                                            < Icon type={loading ? "loading" : "edit"} onClick={this.onEdit.bind(this)}
                                                   className={`icon-inline _icon_loading a-text--blue ml-4 `}/>
                                            : null
                                    }
                                </div>
                                <ul>
                                    <Form>
                                        <li>
                                            <label
                                                className="info-bag--label"
                                            >
                                                {t('bag:label.bag_type')}
                                            </label>
                                            {!this.state.editing || lodash.get(bag, 'packing_complete') ? (
                                                <span
                                                    className="_static_bag_type info-bag--value"
                                                >
                                                    {bag.type ? t('bag:label.' + bag.type.toLowerCase() + '_bag') : '--'}
                                                </span>
                                            ) : (
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.type}
                                                    validateStatus={errors.type ? 'error' : null}
                                                    label={t('bag:label.bag_type')}
                                                    labelCol={{span: 24}}
                                                >
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        onChange={this.onChangeSelectValue.bind(this, 'type')}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        value={this.state.type}
                                                        className="_type-bag"
                                                        dropdownClassName={'_dropdown_type-bag'}
                                                    >
                                                        {bagType.map(bag => (
                                                            <Option
                                                                key={bag.key}
                                                                value={bag.value}
                                                                className={"_type-bag__item"}
                                                            >{bag.label}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </li>
                                        <li>
                                            {lodash.get(bag, "id_customer") !== 0 && (<label
                                                className={`info-bag--label ` + (this.state.editing ? ' a-hidden' : '')}>{t("bag:label.id_customer")}</label>)}

                                            {!this.state.editing ? (lodash.get(bag, "id_customer") !== 0 && <span
                                                    className={`_static_bag_customer info-bag--value `}>{lodash.get(bag.customer, 'username', '--')}</span>) :
                                                <Form.Item className="a-form__item "
                                                           label={t('bag:label.customer')}
                                                           labelCol={{span: 24}}
                                                           help={errors.id_customer}
                                                           validateStatus={errors.id_customer ? 'error' : null}
                                                           required={this.state.type === 'SINGLE'}
                                                >
                                                    {this.state.type === 'SINGLE' && (
                                                        <SelectCustomerContainer
                                                            autoSelect={true}
                                                            emptyOption={false}
                                                            is_shipper={true}
                                                            showName={true}
                                                            className="a-select a-select--search-content _id_customer"
                                                            value={this.state.id_customer || ''}
                                                            onChange={this.onChangeSelectValue.bind(this, "id_customer")}
                                                            dropdownClassName={'_dropdown_id_customer'}
                                                        />
                                                    )}

                                                    {(this.state.type === 'MULTIPLE' || this.state.type === '') && (
                                                        <Select
                                                            disabled={true}
                                                            placeholder={t('bag:label.customer_placeholder')}
                                                        >
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            }
                                        </li>
                                        <li>
                                            <label
                                                className={`info-bag--label ` + (this.state.editing ? ' a-hidden' : '')}>{t("bag:label.bag_size")}</label>
                                            {!this.state.editing ? <span
                                                    className={`info-bag--value `}>{lodash.get(bagSize, 'bag_size.name', '--')}</span> :
                                                <Form.Item className="a-form__item "
                                                           label={t('bag:label.bag_size')}
                                                           labelCol={{span: 24}}
                                                >
                                                    <SelectBagSizeContainer
                                                        value={this.state.id_bag_size}
                                                        onChange={this.onChangeSelectValue.bind(this, "id_bag_size")}
                                                    />
                                                </Form.Item>
                                            }
                                        </li>
                                        <li>
                                            {
                                                !this.state.editing ?
                                                    <>
                                                        <label
                                                            className="info-bag--label">{t("bag:label.volume")}</label>
                                                        <span
                                                            className="info-bag--value _static_bag_volume">{bag.volume}</span>
                                                    </> :
                                                    <>
                                                        <Form.Item
                                                            label={t('bag:label.dimension') + ' (cm)'}
                                                            labelCol={{span: 24}}>
                                                            <UpdateVolumeBag input={this.state.input}
                                                                             loading={this.props.loading}
                                                                             onChangeValue={this.onChangeValue}/>
                                                        </Form.Item>
                                                    </>
                                            }
                                        </li>
                                        <li>
                                            <label className="info-bag--label">{t("bag:label.weight_package")}</label>
                                            <span
                                                className="info-bag--value _static_bag_total_package_weight">{bag[BAG_COLUMN.TOTAL_PACKAGES_WEIGHT]}</span>
                                        </li>
                                        <li>
                                            <label className="info-bag--label">{t("bag:label.properties")}</label>
                                            <span className="info-bag--value _static_bag_properties">{bag.properties}</span>
                                        </li>
                                        <li>
                                            <label className="info-bag--label">{t("bag:label.services")}</label>
                                            <span className="info-bag--value _static_bag_services">{bag.services}</span>
                                        </li>
                                        <li>
                                            <label
                                                className="info-bag--label">{t("bag:label.id_warehouse_input")}</label>
                                            <span className="info-bag--value _static_bag_id_warehouse_input">{bag.id_warehouse_input}</span>
                                        </li>
                                        <li>
                                            {this.state.editing ? (
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.id_warehouse_destination}
                                                    label={t('bag:label.id_warehouse_destination')}
                                                    labelCol={{span: 24}}
                                                    validateStatus={errors.id_warehouse_destination ? 'error' : undefined}
                                                >
                                                    <SelectWarehouse
                                                        allowClear
                                                        className="_destination-warehouse"
                                                        dropdownClassName="_destination-warehouse"
                                                        value={this.state.id_warehouse_destination}
                                                        warehouses={warehouses}
                                                        onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_destination')}
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    <label className="info-bag--label">{t("bag:label.id_warehouse_destination")}</label>
                                                    <span className="info-bag--value _static_bag_id_warehouse_destination">{bag.code_warehouse_destination}</span>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            {this.state.editing ? (
                                                <Form.Item
                                                    className="a-form__item"
                                                    help={errors.subject_shipping}
                                                    label={t('bag:label.shipping_type')}
                                                    labelCol={{span: 24}}
                                                    validateStatus={errors.subject_shipping ? 'error' : undefined}
                                                >
                                                    <FormBagShippingTypeContainer
                                                        allowClear
                                                        disabled={false}
                                                        dropdownClassName="_dropdown_subject_shipping"
                                                        noForm
                                                        value={this.state.subject_shipping}
                                                        onChange={this.onChangeSelectValue.bind(this, 'subject_shipping')}
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    <label className="info-bag--label">
                                                        {t('bag:label.shipping_type')}
                                                    </label>
                                                    <span className="info-bag--value _static_bag_ship_name">
                                                        {bag.subject_shipping ? t(`bag:shipping_type.${bag.subject_shipping.toLowerCase()}`) : '--'}
                                                    </span>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            {this.state.editing ? [
                                                this.state.subject_shipping === BAG_SHIPPING_TYPE.USER ? (
                                                    <Form.Item
                                                        className="a-form__item"
                                                        help={errors.id_shipping_user}
                                                        label={t('bag:label.shippingPartner')}
                                                        labelCol={{span: 24}}
                                                        validateStatus={errors.id_shipping_user ? 'error' : undefined}
                                                    >
                                                        <SelectCreateShippingUserContainer
                                                            allowClear
                                                            autoSelect
                                                            className="a-select a-select--search-content _id_shipping_user"
                                                            dropdownClassName="_dropdown__id_shipping_user"
                                                            emptyOption={false}
                                                            isFieldDecorator
                                                            placeholder={t('bag:label.id_shipping_user_placeholder')}
                                                            selectProps={{
                                                                dropdownClassName: '_dropdown__id_shipping_user'
                                                            }}
                                                            value={this.state.id_shipping_user}
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_user')}
                                                        />
                                                    </Form.Item>
                                                ) : null,
                                                this.state.subject_shipping === BAG_SHIPPING_TYPE.PARTNER ? (
                                                    <Form.Item
                                                        className="a-form__item"
                                                        help={errors.id_shipping_partner}
                                                        key="shipping_partner"
                                                        label={t('bag:label.shippingPartner')}
                                                        labelCol={{span: 24}}
                                                        validateStatus={errors.id_shipping_partner ? 'error' : undefined}
                                                    >
                                                        <SelectCreatePartnerContainer
                                                            allowClear
                                                            autoSelect
                                                            className="a-select a-select--search-content _id_shipping_partner"
                                                            dropdownClassName="_dropdown__id_shipping_partner"
                                                            emptyOption={false}
                                                            isFieldDecorator
                                                            placeholder={t('bag:label.id_shipping_partner_placeholder')}
                                                            selectProps={{
                                                                dropdownClassName: '_dropdown__id_shipping_partner'
                                                            }}
                                                            value={this.state.id_shipping_partner}
                                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_partner')}
                                                        />
                                                    </Form.Item>
                                                ) : null,
                                                this.state.subject_shipping !== BAG_SHIPPING_TYPE.USER && this.state.subject_shipping !== BAG_SHIPPING_TYPE.PARTNER ? (
                                                    <Form.Item
                                                        className="a-form__item"
                                                        key="empty_shipping_partner"
                                                        label={t('bag:label.shippingPartner')}
                                                        labelCol={{span: 24}}
                                                    >
                                                        <Select disabled />
                                                    </Form.Item>
                                                ) : null
                                            ] : (
                                                <>
                                                    <label className="info-bag--label">
                                                        {t('bag:label.shipping_name')}
                                                    </label>
                                                    <span className="info-bag--value _static_bag_ship_name">
                                                        {bag.ship_name}
                                                    </span>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label className="info-bag--label">{t("bag:label.receiver_name")}</label>
                                            <span className="info-bag--value _static_bag_receiver_name">{bag.receiver_name}</span>
                                        </li>
                                        <li>
                                            <label className="info-bag--label">{t("bag:label.receiver_phone")}</label>
                                            <span className="info-bag--value _static_bag_receiver_phone">{bag.receiver_phone}</span>
                                        </li>
                                    </Form>

                                </ul>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t('bag:packing.bags_created_today')} key="ListBagCreatedToday">
                        <ListBagCreatedToday
                            loading={this.state.loadingBagsCreatedToday}
                            bags={this.state.bagsCreatedToday}
                        />
                    </TabPane>
                </Tabs>

            </Sider>
        )
    }
}

SiderBagPack.defaultProps = {
    bag: {},
    loading: false,
    packingData: [],
    updateBagParam: () => {

    },
};

SiderBagPack.propTypes = {
    bag: PropTypes.object,
    loading: PropTypes.bool,
    packingData: PropTypes.array,
    updateBagParam: PropTypes.func,
};

export default withTranslation()(SiderBagPack);
