import React from 'react';
import { connect } from 'react-redux';

import { printBagStamp } from './../../../Common/PrintStamp/actions';

import PrintBagStampButton from './PrintBagStampButton';

const PrintBagStampButtonContainer = props => (
    <PrintBagStampButton {...props} />
);

export default connect(
    undefined,
    {
        printBagStamp
    }
)(PrintBagStampButtonContainer);
