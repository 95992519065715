/**
 *
 * @param input
 * @returns {*}
 * @private
 */
export function converValue2Int (input) {
    Object.keys(input).forEach(function (property) {
            if (Array.isArray(input[property])) {
                input[property] = input[property].map(value => {
                    return parseInt(value, 10);
                })
            }
            return property;
        }
    );
    return input;
}
