import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SearchableComponent from '../../Common/components/SearchableComponent';
import ListBagSize from './components/ListBagSize';
import * as commonAction from "../../Common/actions";

import * as bagSizeActions from '../actions';
import * as bagSizeSelectors from '../selectors';
import {currentFormatter} from "../../Common/services/format";

const mapStateToProps = (state) => {
    let bagSizes = bagSizeSelectors.getBagSizes(state);
    bagSizes = bagSizes.map(item => item.bag_size);
    bagSizes = bagSizes.map(bagSize => {
        bagSize.volume_convert = currentFormatter.toLocaleStringCurrency(bagSize.length * 100 * bagSize.width * 100 * bagSize.height * 100, 2);
        bagSize.length_convert = currentFormatter.toLocaleStringCurrency(bagSize.length * 100);
        bagSize.width_convert = currentFormatter.toLocaleStringCurrency(bagSize.width * 100);
        bagSize.height_convert = currentFormatter.toLocaleStringCurrency(bagSize.height * 100);
        return bagSize;
    });
    return {
        list: bagSizes,
        loading: bagSizeSelectors.getLoading(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    fetchBagSizes: (filter) => dispatch(bagSizeActions.fetchBagSizes(filter))
});

class ListBagSizeContainer extends SearchableComponent {

    componentDidMount() {
        super.componentDidMount();
        this.props.setMenuActive("config/bag-sizes");
        this.props.fetchBagSizes(this.getCurrentFilter());
    }

    onSearch = (filter) => {
        filter = {
            ...filter,
            i: parseInt(filter.i || 0, 0),
        };
        this.pushFilter(filter);
    };

    onChangeFilter(filter) {
        this.props.fetchBagSizes(filter);
    }

    getCurrentFilter() {
        return {
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        return <ListBagSize {...{
            ...this.props,
            filter: this.getCurrentFilter(),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

ListBagSizeContainer.defaultProps = {
    list: []
};

ListBagSizeContainer.propTypes = {
    list: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListBagSizeContainer)
