import React, {Component} from 'react';
import {Layout, Tabs} from 'antd';
import lodash from 'lodash';
import moment from 'moment';

import FormSearch from './FormSearch';
import ListPackage from './ListPackage';
import PropTypes from 'prop-types';
import pageService from '../../../Common/Page/pageService';
import {withTranslation} from 'react-i18next';
import ListPackageStatusDelete from "../../ListPackageStatusDelete";
import DamagedPackages from './../../DamagedPackages/DamagedPackagesContainer';
import settingService from "../settingService";
import authService from './../../../Auth/authService';
import {LIST_PACKAGE_OBJECT} from "../constants";

const {Content} = Layout;

class ListPackageIndex extends Component {
    constructor(props) {
        super();

        const { filter } = props;

        this.state = {
            activeKey: 'ALL',
            isOpenListSetting: false,
            isCurrentPage: LIST_PACKAGE_OBJECT.ALL,
            formattedFilter: {
                ...lodash.omit(filter, 'length_of_transport_status'),
                'length_of_transport_status[]': this.getFormattedLengthOfTransportStatus(filter),
                is_deleted: this.getFormattedBoolean(filter, 'is_deleted'),
                is_shipment: this.getFormattedBoolean(filter, 'is_shipment'),
                is_damage: this.getFormattedBoolean(filter, 'is_damage'),
                is_not_in_bag: this.getFormattedBoolean(filter, 'is_not_in_bag')
            }
        };
    }

    getFormattedLengthOfTransportStatus = filter => {
        let lengthOfTransportStatus = filter.length_of_transport_status;

        if (filter.length_of_transport_status && filter.length_of_transport_status.length) {
            try {
                lengthOfTransportStatus = JSON.parse(filter.length_of_transport_status[0]);
            } catch (error) {
                lengthOfTransportStatus = undefined;
            }
        }

        return lengthOfTransportStatus;
    };

    getFormattedBoolean = (filter, field) => {
        if (filter[field] === 'false') {
            return false;
        }

        if (filter[field] === 'true') {
            return true;
        }

        return filter[field];
    };

    getFormattedTime = time => {
        if (time) {
            return moment(time);
        }

        return time;
    };

    componentDidUpdate(prevProps) {
        const { filter } = this.props;

        if (!lodash.isEqual(filter, prevProps.filter)) {
            this.setState({
                activeKey: this.getActiveKey(),
                formattedFilter: {
                    ...lodash.omit(filter, 'length_of_transport_status'),
                    'length_of_transport_status[]': this.getFormattedLengthOfTransportStatus(filter),
                    is_deleted: this.getFormattedBoolean(filter, 'is_deleted'),
                    is_shipment: this.getFormattedBoolean(filter, 'is_shipment'),
                    is_damage: this.getFormattedBoolean(filter, 'is_damage'),
                    is_not_in_bag: this.getFormattedBoolean(filter, 'is_not_in_bag')
                }
            });
        }
    }

    componentDidMount() {
        const { t, getPropertySuggests, setMenuActive } = this.props;

        pageService.setTitle(t('package:label.list'));
        setMenuActive('packages');
        getPropertySuggests();
        this.setState({
            activeKey: this.getActiveKey()
        });
    }

    onTabChange = (tab) => {
        const { defaultFilter, onSearch } = this.props;
        let setting = settingService.getPageSetting(authService.user('id'), tab);
        settingService.updateUserSetting(authService.user('id'), setting, tab);

        const filter = {
            ...defaultFilter,
            sort_by: 'created_at'
        };

        if (tab === 'DELETE') {
            filter.is_deleted = true;
        }

        if (tab === 'SHIPMENT') {
            filter.is_shipment = true;
        }

        if (tab === 'DAMAGED') {
            filter.is_damage = true;
        }

        if (tab === LIST_PACKAGE_OBJECT.NOT_IN_BAG) {
            filter.is_not_in_bag = true;
        }

        onSearch(filter);

        this.setState({
            activeKey: tab
        });

        if (this.state.isOpenListSetting) {
            this.setState({isOpenListSetting: false})
        }
    };

    onOpenListSetting = (isOpenListSetting, isCurrentPage) => {
        this.setState({isOpenListSetting, isCurrentPage})
    };

    handleDownloadTransportStatuses = selectedPackages => {
        const { filter, onDownloadTransportStatuses } = this.props;

        onDownloadTransportStatuses({
            ...filter,
            id_packages: selectedPackages
        });
    };

    getActiveKey = () => {
        const { filter } = this.props;

        if (filter.is_deleted) {
            return LIST_PACKAGE_OBJECT.DELETE;
        }

        if (filter.is_shipment) {
            return LIST_PACKAGE_OBJECT.SHIPMENT;
        }

        if (filter.is_damage) {
            return LIST_PACKAGE_OBJECT.DAMAGED;
        }

        if (filter.is_not_in_bag) {
            return LIST_PACKAGE_OBJECT.NOT_IN_BAG;
        }

        return LIST_PACKAGE_OBJECT.ALL;
    };

    render() {
        const { activeKey, formattedFilter, isCurrentPage, isOpenListSetting } = this.state;
        const { consignServices, downloadingTransportStatus, exportPackageList, loading, orderCreateShipment, packages, pagination, services, t, onSearch } = this.props;

        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <Tabs animated={false} className="a-tabs a-tabs--container"
                          activeKey={activeKey}
                          onChange={this.onTabChange}>
                        <Tabs.TabPane key={LIST_PACKAGE_OBJECT.ALL} tab={t('package:label.tab_all')}>
                            <FormSearch
                                consignServices={consignServices}
                                input={formattedFilter}
                                inputVersion={lodash.toInteger(formattedFilter.i)}
                                loading={loading}
                                services={services}
                                onSearch={onSearch}
                            />
                            <ListPackage
                                downloadingTransportStatus={downloadingTransportStatus}
                                packages={packages}
                                pagination={pagination}
                                filter={formattedFilter}
                                loading={loading}
                                onSearch={onSearch}
                                object="ALL"
                                exportPackageList={exportPackageList}
                                isOpenListSetting={isOpenListSetting}
                                onOpenListSetting={this.onOpenListSetting}
                                isCurrentPage={isCurrentPage}
                                onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="DELETE"
                            tab={t('package:label.tab_delete')}
                        >
                            <ListPackageStatusDelete
                                {...this.props}
                                isCurrentPage={isCurrentPage}
                                isOpenListSetting={isOpenListSetting}
                                object="DELETE"
                                onOpenListSetting={this.onOpenListSetting}
                            />
                        </Tabs.TabPane>
                        {!!orderCreateShipment && (
                            <Tabs.TabPane
                                key="SHIPMENT"
                                tab={t('package:label.tab_shipment')}
                            >
                                <FormSearch
                                    consignServices={consignServices}
                                    input={formattedFilter}
                                    inputVersion={lodash.toInteger(formattedFilter.i)}
                                    loading={loading}
                                    services={services}
                                    onSearch={onSearch}
                                />
                                <ListPackage
                                    downloadingTransportStatus={downloadingTransportStatus}
                                    packages={packages}
                                    pagination={pagination}
                                    filter={formattedFilter}
                                    loading={loading}
                                    onSearch={onSearch}
                                    object="ALL"
                                    exportPackageList={exportPackageList}
                                    isOpenListSetting={isOpenListSetting}
                                    onOpenListSetting={this.onOpenListSetting}
                                    isCurrentPage={isCurrentPage}
                                    onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
                                />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane
                            key="DAMAGED"
                            tab={t('damaged_package')}
                        >
                            <DamagedPackages
                                consignServices={consignServices}
                                downloadingTransportStatus={downloadingTransportStatus}
                                packages={packages}
                                pagination={pagination}
                                filter={formattedFilter}
                                loading={loading}
                                services={services}
                                onSearch={onSearch}
                                object="DAMAGED"
                                exportPackageList={exportPackageList}
                                isOpenListSetting={isOpenListSetting}
                                onOpenListSetting={this.onOpenListSetting}
                                isCurrentPage={isCurrentPage}
                                onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key="NOT_IN_BAG"
                            tab={t('package_transport_not_in_bag')}
                        >
                            <FormSearch
                                    consignServices={consignServices}
                                    input={formattedFilter}
                                    inputVersion={lodash.toInteger(formattedFilter.i)}
                                    loading={loading}
                                    services={services}
                                    onSearch={onSearch}
                                />
                                <ListPackage
                                    downloadingTransportStatus={downloadingTransportStatus}
                                    packages={packages}
                                    pagination={pagination}
                                    filter={formattedFilter}
                                    loading={loading}
                                    onSearch={onSearch}
                                    object="NOT_IN_BAG"
                                    exportPackageList={exportPackageList}
                                    isOpenListSetting={isOpenListSetting}
                                    onOpenListSetting={this.onOpenListSetting}
                                    isCurrentPage={isCurrentPage}
                                    onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
                                />
                        </Tabs.TabPane>
                    </Tabs>
                </Content>
            </Layout>
        )
    }
}

ListPackageIndex.defaultProps = {
    packages: [],
    pagination: {},
    filter: {},
    loading: false,
    onSearch: () => {
    },
};

ListPackageIndex.propTypes = {
    packages: PropTypes.array,
    pagination: PropTypes.object,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(ListPackageIndex);
