import React from "react"
import {Col, Form, Input, Row, Select} from "antd";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {DEFAULT_STATE_FORM_INPUT_PROPERTY} from "../../constants";
import lodash from "lodash";

import icons from './../../../../config/icons';

import FormPropertyLoading from './FormPropertyLoading';
import Icon from './../../../../components/icons/Icon';

const { TextArea } = Input;
const { Option } = Select;

class FormInputProperty extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			...DEFAULT_STATE_FORM_INPUT_PROPERTY,
			id_property: 0
		}
	}

	static getDerivedStateFromProps(props, state) {
		let nextState = {...state};
		let id_property = !lodash.isNil(props.propertyData) ? lodash.get(props, 'propertyData.id', 0) : 0;
		if (nextState.id_property !== id_property && id_property !== 0) {
			nextState = lodash.isNil(props.propertyData) ? {...DEFAULT_STATE_FORM_INPUT_PROPERTY} : {
				name: lodash.get(props, 'propertyData.name', ""),
				alias: lodash.get(props, 'propertyData.alias', ""),
				note: lodash.get(props, 'propertyData.note', ""),
				icon: lodash.get(props, 'propertyData.icon'),
			};
			nextState.id_property = id_property;
		}

		return nextState;
	}

	onChangeInput = (event, valueInput = 0) => {
		let value = event.target ? event.target.value : valueInput;
		let name = event.target ? event.target.name : event;
		this.setState({[name]: value});
		this.props.onSubmit({...this.state, [name]: value});
		if (this.props.errors[name]) {
			this.props.resetError(name);
		}
	};

	onSelect = (name, value) => {
		const { onSubmit } = this.props;

		this.setState({
			[name]: value
		});

		onSubmit({
			...this.state,
			[name]: value
		});
	};

	render() {
		const {alias, icon, name} = this.state;
		const {t, errors, loading} = this.props;

		if (loading) {
			return <FormPropertyLoading />;
		}

		return (
			<Form className="a-form ">
				<Row gutter={{lg: 24, xl: 32}} className="a-block-info">
					<Col lg={{span: 6}} className="a-form--group">
						<Form.Item className="a-form__item "
						           required
						           label={t('config:label.name_property')}
						           validateStatus={errors.name ? 'error' : null}
						           help={errors.name}
						           labelCol={{span: 24}}>
                            <Input className={"a-input _name-property"}
                                   name={'name'}
                                   disabled={loading}
                                   placeholder={t('config:placeholder.name_property')}
                                   value={name === '' ? null : name}
                                   onChange={this.onChangeInput}/>
						</Form.Item>
					</Col>
					<Col lg={{span: 6}} className="a-form--group">
						<Form.Item className="a-form__item "
						           label={t('config:label.alias')}
						           validateStatus={errors.alias ? 'error' : null}
						           help={errors.alias}
						           labelCol={{span: 24}}>
                            <Input className={"a-input _alias"}
                                   name={'alias'}
                                   disabled={loading}
                                   placeholder={t('config:placeholder.alias')}
                                   value={alias === '' ? null : alias}
                                   onChange={this.onChangeInput}/>
						</Form.Item>
					</Col>
					<Col lg={{span: 3}} className="a-form--group">
						<Form.Item className="a-form__item" label="Icon">
							<Select allowClear name="icon" value={icon || undefined} onChange={this.onSelect.bind(undefined, 'icon')}>
								{Object.keys(icons).map(key => (
									<Option key={key}>
										<Icon type={key} />
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={{lg: 24, xl: 32}} className="a-block-info">
					<Col lg={{span: 24}} className="a-form--group">
						<Form.Item className="a-form__item" label={t('config:label.note')}
						           labelCol={{span: 24}}>
                            <TextArea className="a-input--textarea _note"
                                      autosize={{minRows: 1, maxRows: 3}}
                                      allowClear={true}
                                      name={'note'}
                                      disabled={loading}
                                      spellCheck={false}
                                      value={this.state.note}
                                      onChange={this.onChangeInput}
                                      placeholder={t('config:placeholder.note')}/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}
}

FormInputProperty.defaultProps = {
	propertyData: {},
	loading: false
};

FormInputProperty.propTypes = {
	propertyData: PropTypes.object,
	loading: PropTypes.bool,
};
export default withTranslation()(FormInputProperty)
