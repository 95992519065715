import lodash from 'lodash'
import { DELIVERY_NOTE_COLUMN } from './constants'
import { DELIVERY_OBJECT } from './../constants'

const SETTING_OPTIONS = [
    {
        key: DELIVERY_NOTE_COLUMN.CODE,
        selected: true,
        disabled: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.STATUS,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.CREATE_AT,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.EXPORTED_AT,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.NOTE,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_SHIPPING_PARTNER,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_CUSTOMER,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.PACKAGE_NUMBER,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.PACKAGE_WEIGHT,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_WAREHOUSE,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_CREATOR,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_EXPORTER,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
    {
        key: DELIVERY_NOTE_COLUMN.ID_AGENCY,
        selected: true,
        page: [DELIVERY_OBJECT.CUSTOMER],
    },
]

class SettingService {
    constructor(options) {
        this.options = options
    }

    getSetting(page) {
        let setting = localStorage.getItem('delivery_note_setting_' + page)
        return setting ? JSON.parse(setting) : {}
    }

    updateSetting(setting, page) {
        localStorage.setItem('delivery_note_setting' + (page !== '' ? '_' + page : ''), JSON.stringify(setting))
    }

    getOption(page) {
        let options = []
        lodash.each(SETTING_OPTIONS, option => {
            if (option.page.includes(page)) {
                options.push(option)
            }
        })
        this.options = options
    }

    getPageSetting(userId, page) {
        let defaultSetting = lodash.get(this.getSetting(page), userId, this.getDefaultSetting())
        let settings = []
        lodash.each(SETTING_OPTIONS, setting => {
            if (setting.page.includes(page) && defaultSetting.includes(setting.key)) {
                settings.push(setting.key)
            }
        })
        return settings
    }

    getDefaultSetting() {
        return this.options.filter(option => option.selected).map(option => option.key)
    }

    getUserSetting(userId, page) {
        this.getOption(page)
        return this.getPageSetting(userId, page)
    }

    updateUserSetting(userId, setting, page) {
        this.getOption(page)
        this.updateSetting({ ...this.getSetting(page), [userId]: setting }, page)
    }
}

let settingService = new SettingService(SETTING_OPTIONS)

export default settingService
