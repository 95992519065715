import {combineReducers} from 'redux';
import * as ACTION from './constants';

let packages = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_ORDER_PACKAGES.SUCCESS:
            return payload.packages;

        case ACTION.CLEAR_STATE:
            return [];

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_ORDER_PACKAGES.REQUEST:
            return true;

        case ACTION.FETCH_ORDER_PACKAGES.SUCCESS:
        case ACTION.FETCH_ORDER_PACKAGES.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    packages,
    loading,
});
