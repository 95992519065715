import React from 'react';

const Truck1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0)">
        <path className="c" d="M7.667,2.833A.167.167,0,0,0,7.5,2.667H4A1.667,1.667,0,0,0,2.333,4.333v.333a.651.651,0,0,0,.333.568.165.165,0,0,1,.067.212l-.9,2.038a.169.169,0,0,1-.083.084L.563,8.117A.951.951,0,0,0,0,8.97v2.081A.977.977,0,0,0,1,12H2.173a.167.167,0,0,0,.166-.154,2,2,0,0,1,3.988,0A.167.167,0,0,0,6.493,12H7.5a.167.167,0,0,0,.167-.167Zm-1.5,2.5a.167.167,0,0,1,.167.167V7.167a.167.167,0,0,1-.167.167H3.616A.167.167,0,0,1,3.463,7.1L4.2,5.432a.168.168,0,0,1,.153-.1Z" />
        <path className="c" d="M16,3.667a1,1,0,0,0-1-1H8.5a.167.167,0,0,0-.167.167v9A.167.167,0,0,0,8.5,12h2.34a.167.167,0,0,0,.166-.154,2,2,0,0,1,3.988,0A.167.167,0,0,0,15.16,12h.507A.333.333,0,0,0,16,11.667v-8Z" />
        <circle className="c" cx="1.333" cy="1.333" r="1.333" transform="translate(3 10.667)" />
        <circle className="c" cx="1.333" cy="1.333" r="1.333" transform="translate(11.667 10.667)" />
      </g>
    </g>
  </svg>
);

export default Truck1;
