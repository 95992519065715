import React from 'react';
import { Col, Row } from 'antd';
import { get, round } from 'lodash';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';

const Info = ({ deliveryNoteReturn, t }) => {
    const deliveryNoteReturnDetail = get(deliveryNoteReturn, 'delivery_note_return', {});
    const creator = get(deliveryNoteReturn, 'creator', {}) || {};
    const exporter = get(deliveryNoteReturn, 'exporter', {});
    const partner = get(deliveryNoteReturn, 'shipping_return_partner', {}) || {};
    const warehouse = get(deliveryNoteReturn, 'warehouse', {}) || {};
    const exporterName = exporter && Object.keys(exporter).length ? `${exporter.name} (${exporter.username})`: '--';

    const totalPackageExport = get(deliveryNoteReturn, 'delivery_note_return.package_number');
    const totalPackageWeight = `${round(get(deliveryNoteReturn, 'delivery_note_return.package_weight'), 4)} (kg)`;

    return (
        <div className="print-delivery-note--info a-block-info">
            <div className="delivery-note-info--title gflex galign-center gflex-direction-column">
                <h1>{t('delivery_note:print.invoice_title')}</h1>
            </div>
            <Row className="mx-0" gutter={48}>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('return_partner')}</label>
                    <span className="info--value a-text--bold ">{partner.name} ({partner.code})</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.creator_name')}</label>
                    <span className="info--value a-text--bold">{Object.keys(creator).length ? `${creator.name} (${creator.username})`: "--"}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.created_at')}</label>
                    <span className="info--value a-text--bold">{dateFormatter.dateTime(deliveryNoteReturnDetail.created_at)}</span>
                </Col>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('delivery_note:print.exporter_name')}</label>
                    <span className="info--value a-text--bold">{exporterName}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.exported_at')}</label>
                    <span className="info--value a-text--bold">{deliveryNoteReturnDetail.exported_at ? dateFormatter.dateTime(deliveryNoteReturnDetail.exported_at): '--'}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.warehouse_export')}</label>
                    <span className="info--value a-text--bold">{warehouse.code}</span>
                </Col>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('delivery_note:print.total_weight')}</label>
                    <span className="info--value a-text--bold">{totalPackageWeight}</span>
                </Col>
                <Col className="info--group pr-0" span={7}>
                    <label className="info--label">{t('delivery_note:print.total_quantity')}</label>
                    <span className="info--value a-text--bold">{totalPackageExport}</span>
                </Col>
                <Col className="info--group pl-0" span={24}>
                    <label className="info--label">{t('delivery_note:print.note')}</label>
                    <span className="info--value a-text--bold">{get(deliveryNoteReturnDetail, 'note', '--') || '--'}</span>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(Info);
