import {combineReducers} from 'redux';
import formBagSize from './FormBagSize/reducer';
import * as ACTION from './constants';

const detail = (state = {}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_DETAIL_BAG_SIZE.REQUEST:
        case ACTION.FETCH_DETAIL_BAG_SIZE.FAILED:
        case ACTION.CLEAR_STATE_DETAIL_BAG_SIZE:
            return {};
        case ACTION.FETCH_DETAIL_BAG_SIZE.SUCCESS:
            return {...payload};
        default:
            return state;
    }
};

const bagSizes = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case ACTION.FETCH_BAG_SIZE.REQUEST:
        case ACTION.FETCH_BAG_SIZE.FAILED:
            return {};
        case ACTION.FETCH_BAG_SIZE.SUCCESS:
            return {...action.payload};
        default:
            return state;
    }
};
const suggestBagSizes = (state = {}, action) => {
    const {type} = action;
    switch (type) {
        case ACTION.FETCH_BAG_SIZE_SUGGEST.REQUEST:
        case ACTION.FETCH_BAG_SIZE_SUGGEST.FAILED:
            return {};
        case ACTION.FETCH_BAG_SIZE_SUGGEST.SUCCESS:
            return {...action.payload};
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    const type = action.type;
    switch (type) {
        case ACTION.FETCH_BAG_SIZE.REQUEST:
        case ACTION.FETCH_BAG_SIZE.FAILED:
            return true;
        case ACTION.FETCH_BAG_SIZE.SUCCESS:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    formBagSize,
    detail,
    bagSizes,
    loading,
    suggestBagSizes
});
