import { Button, Empty, Modal, Table, notification } from 'antd'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import apiService from '../../../Warehouse/apiService'
import { map } from 'lodash'
import { convertBlobDataToExcel, formatVolume, getVar } from '../../../../system/support/helpers'
import { Link } from '../../../../system/routing'
import { useDeepCompareEffect } from '../../../../hooks'

function WarehouseAreaList(props) {
    const { warehouseId, customerInfos, selectedPackageIds } = props
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [total, setTotal] = useState(0)

    useDeepCompareEffect(() => {
        if (visible) {
            if (customerInfos.length > 0) {
                fetchListWarehouseArea()
            } else {
                setData([])
                setTotal(0)
            }
        }
    }, [visible, customerInfos])

    const fetchListWarehouseArea = () => {
        const customerIds = map(customerInfos, 'customer.id')
        setLoading(true)
        apiService
            .getListPackageInWarehouseArea({
                id_warehouse: warehouseId,
                id_customers: customerIds,
                id_package_ignores: selectedPackageIds.length > 0 ? selectedPackageIds : undefined,
            })
            .then(res => {
                setData(getVar(res, 'data.packages', []))
                setTotal(getVar(res, 'data.totalPackages', 0))
            })
            .catch(() => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const exportListWarehouseArea = () => {
        const customerIds = map(customerInfos, 'customer.id')
        setExportLoading(true)
        apiService
            .exportListPackageInWarehouseArea({
                id_warehouse: warehouseId,
                id_customers: customerIds,
                id_package_ignores: selectedPackageIds.length > 0 ? selectedPackageIds : undefined,
            })
            .then(res => {
                const data = getVar(res, 'data')
                convertBlobDataToExcel(data, 'export_package_in_warehouse_shelf')

                notification.success({
                    message: t('message.export_excel_success'),
                })
            })
            .catch(e => {
                notification.error({
                    message: t('message.export_excel_failed'),
                })
            })
            .finally(() => setExportLoading(false))
    }

    const columns = [
        {
            title: t('package:label.code'),
            dataIndex: ['package', 'code'],
            key: 'code',
            render: text => {
                return (
                    <Link
                        isNewTab
                        params={{ id: text }}
                        to="packages.detail">
                        {text}
                    </Link>
                )
            },
        },
        {
            title: t('package:label.weight'),
            dataIndex: ['package', 'weight_net'],
            key: 'weight_net',
            render: text => (text ? `${text} kg` : '--'),
        },
        {
            title: t('package:label.dimension'),
            dataIndex: ['package', 'volume'],
            key: 'volume',
            render: text => (text ? formatVolume(text) : '--'),
        },
        {
            title: t('customer'),
            dataIndex: 'customer',
            key: 'customer',
            render: text => `${getVar(text, 'username', '--')} (${getVar(text, 'code', '--')})`,
        },
        {
            title: t('warehouse_area'),
            dataIndex: ['warehouse_shelf', 'code'],
            key: 'warehouse_area',
        },
    ]

    function handleCancel() {
        setVisible(false)
        setData([])
        setTotal(0)
    }

    return (
        <>
            <Button
                type="primary"
                onClick={() => setVisible(true)}>
                {t('delivery_note:title.warehouse_area_list')}
            </Button>
            {visible && (
                <Modal
                    width="60%"
                    title={t('delivery_note:title.warehouse_area_list')}
                    visible={visible}
                    closable={true}
                    onOk={exportListWarehouseArea}
                    okButtonProps={{ loading: exportLoading, icon: 'download', disabled: data.length <= 0 }}
                    onCancel={handleCancel}
                    cancelText={t('btn.cancel')}
                    okText={t('delivery_note:btn.download_list')}>
                    <div className="mb-3">
                        <h3>
                            {t('total_package')} ({total})
                        </h3>
                    </div>
                    <Table
                        loading={loading}
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('common:label.no_data')}</span>}></Empty>
                            ),
                        }}
                    />
                </Modal>
            )}
        </>
    )
}
export default WarehouseAreaList
