import {processApiRequest} from "../../Common/saga";
import * as ACTION from '../constants';
import apiService from '../apiService';
import {notification} from "antd";
import {router} from "../../../system/routing";
import {t} from '../../../system/i18n';

export function* editWarehouseStaff(action) {
    yield processApiRequest(ACTION.UPDATE_WAREHOUSE_STAFF, () => apiService.updateWarehouseStaff(action.payload), action.payload);
}

export function* editWarehouseStaffSuccess() {
    yield notification['success']({
        message: t('common:message.edit_warehouse_staff_success')
    });
    yield router.redirect('/config/warehouse-staff');
}