import React, {useEffect, useState} from 'react';
import {Icon, Popover} from 'antd';
import Content from "./components/content"
import {FLAG, LANGUAGE} from "./constants";
import {useDispatch, useSelector} from 'react-redux';
import i18n from "../../../system/i18n/i18n";

function Language() {
	const dispatch = useDispatch();
	const props = useSelector((state) => {
		return {user: state.auth.user}
	});
	const [flag, setFlag] = useState(FLAG['vi']);
	useEffect(() => {
		setFlag(FLAG[i18n.language || 'vi']);
	}, [flag]);
	const [language, setLanguage] = useState(LANGUAGE['vi']);
	useEffect(() => {
		setLanguage(LANGUAGE[i18n.language || 'vi']);
	}, [language]);

	return (
		<Popover content={<Content setFlag={setFlag} setLanguage={setLanguage}
		                           dispatch={dispatch} user={props.user}/>}
		         className="a-dropdown__choose-language"
		         overlayClassName={"a-dropdown__choose-language--overlay"}
		         trigger="click">
			<div className="ant-dropdown-link a-dropdown__choose-language__title">
				<img className="img__flag-language" src={flag}
				     alt=""/>
				<Icon type="caret-down" className="icon-dropdown__language"/>
			</div>
		</Popover>

	);

}

export default React.memo(Language)
