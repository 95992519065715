import React, { Component } from 'react';
import { Button, Empty, Input, notification, Table, Typography, Modal } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LIST_STAFF_COLUMN } from './../constants';

import AddUserToStaff from './../AddUserToStaff';
import apiService from '../../Agencies/apiService';
import { t } from '../../../system/i18n';

const { Search } = Input;
const { Paragraph } = Typography;

const LIST_ACTION = {
    ADD_ALL_STAFF: 'add-all',
    REMOVE_ALL_STAFF: 'remove-all',
    REMOVE_STAFF_ALL_AGENCY: 'remove-staff-all-agency',
};

class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: undefined,
            visible: false,
            loading: false,
            userId: undefined,
        };
    }

    handleRemoveFromAllAgency = (userId) => {
        this.setState({
            visible: true,
            action: LIST_ACTION.REMOVE_STAFF_ALL_AGENCY,
            userId,
        });
    };

    getColumns = () => {
        const { columns, t } = this.props;
        const { loading, action } = this.state;
        const formattedColumns = !!this.props.columns ? columns : LIST_STAFF_COLUMN;

        return lodash.values(formattedColumns).map(column => {
            return {
                key: column,
                dataIndex: column,
                title: column !== LIST_STAFF_COLUMN.ACTION ? t(`config:column.${column}`) : '',
                render: (text, row) => {
                    const active = clsx('a-notification-dot a-notification-dot--table', {
                        active: row.active,
                        unactive: !row.active,
                    });
                    const classList = {
                        code_warehouse: active,
                        action: 'a-text--right',
                    };

                    return {
                        children: (column === LIST_STAFF_COLUMN.ACTION ? <>
                                    {text}
                                    <Button
                                        key={row.id}
                                        className={'ml-2 pl-2 a-btn--border-left a-btn--button-link mr-auto a-text--blue a-btn--bg-transparent _btn-remove-all-agency'}
                                        loading={loading && action === LIST_ACTION.REMOVE_STAFF_ALL_AGENCY}
                                        onClick={() => this.handleRemoveFromAllAgency(row.id)}
                                    >
                                        {t('config:btn.remove_staff_all_agency')}
                                    </Button>
                                </> :
                                text
                        ),
                        props: {
                            className: clsx(`_row-${column}`, {
                                [classList[column]]: !!classList[column],
                            }),
                        },
                    };
                },
            };
        });
    };

    handleSearch = event => {
        const value = event.target.value;

        this.doEmitOnChange({
            page: 1,
            username: value,
        });
    };

    handleAddAllStaff = () => {
        this.setState({
            visible: true,
            action: LIST_ACTION.ADD_ALL_STAFF,
        });
    };

    handleRemoveAllStaff = () => {
        this.setState({
            visible: true,
            action: LIST_ACTION.REMOVE_ALL_STAFF,
        });
    };

    handleConfirmModal = () => {
        const { agencyId, getStaffs } = this.props;
        const { action, userId } = this.state;
        this.setState({
            loading: true,
        });
        switch (action) {
            case LIST_ACTION.ADD_ALL_STAFF: {
                apiService.addUserToStaff({ id_users: ['*'], id_agency: agencyId }).then(res => {
                    getStaffs(agencyId);
                    notification.success({
                        message: t('agency:message.add_all_staff.success'),
                    });
                }).catch(() => {
                    notification.error({
                        message: t('agency:message.add_all_staff.failed'),
                    });
                }).finally(() => {
                    this.handleCancelModal();
                });
                return;
            }
            case LIST_ACTION.REMOVE_ALL_STAFF: {
                apiService.removeStaffByUsers({ id_users: ['*'], id_agency: agencyId }).then(res => {
                    getStaffs(agencyId);
                    notification.success({
                        message: t('agency:message.remove_all_staff.success'),
                    });
                }).catch(() => {
                    notification.error({
                        message: t('agency:message.remove_all_staff.failed'),
                    });
                }).finally(() => {
                    this.handleCancelModal();
                });
                return;
            }
            case LIST_ACTION.REMOVE_STAFF_ALL_AGENCY: {
                apiService.removeAllStaffByUserId(userId).then(res => {
                    getStaffs(agencyId);
                    notification.success({
                        message: t('agency:message.remove_staff_all_agency.success'),
                    });
                }).catch(() => {
                    notification.error({
                        message: t('agency:message.remove_staff_all_agency.failed'),
                    });
                }).finally(() => {
                    this.handleCancelModal();
                });
                return;
            }
        }
    };

    handleCancelModal = () => {
        this.setState({
            visible: false,
            action: undefined,
            loading: false,
            userId: undefined,
        });
    };

    doEmitOnChange = lodash.debounce(this.props.onChange, 350);

    renderTitleModal() {
        const {action} = this.state;
        let titleModal = '';
        switch (action) {
            case LIST_ACTION.ADD_ALL_STAFF: {
                titleModal = t('config:title.add_all_staff');
                break;
            }
            case LIST_ACTION.REMOVE_ALL_STAFF: {
                titleModal = t('config:title.remove_all_staff');
                break;
            }
            case LIST_ACTION.REMOVE_STAFF_ALL_AGENCY: {
                titleModal = t('config:title.remove_staff_all_agency');
                break;
            }
            default :
                titleModal = "";
        }
        return titleModal;
    }

    render() {
        const { active, agencyId, data, filter, loading, pagination, t, onChange, agencies } = this.props;
        const { loading: isLoading, visible, action } = this.state;
        // let titleModal = '';
        return (
            <div>
                <div className="gflex galign-center gjustify-space-between">
                    <div className="gflex galign-center">
                        <Paragraph className="mb-0">
                            {t('staff_number')}: {lodash.get(pagination, 'total') || 0}
                        </Paragraph>
                        <div className="a-form__search-inline-title">
                            <Search
                                allowClear
                                placeholder={t('search_staff')}
                                className="a-input--search-inline-title _input-search-staff"
                                onChange={this.handleSearch}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action gflex ">
                        <Button
                            icon="plus-circle"
                            loading={isLoading && action === LIST_ACTION.ADD_ALL_STAFF}
                            onClick={this.handleAddAllStaff}
                            className="mr-2 a-btn a-btn--h-25 a-btn--font-small a-text--regular _a-btn-add-all-staff"
                        >
                            {t('config:btn.add_all_staff')}
                        </Button>
                        <Button
                            icon="delete"
                            loading={isLoading && action === LIST_ACTION.REMOVE_ALL_STAFF}
                            onClick={this.handleRemoveAllStaff}
                            type="danger"
                            className="mr-2 a-btn a-btn--h-25 a-btn--font-small a-text--regular _a-btn-remove-all-staff"
                        >
                            {t('config:btn.remove_all_staff')}
                        </Button>
                        {active ? (
                            <AddUserToStaff
                                agencies={agencies}
                                agencyId={agencyId}
                                loading={loading}
                                staffs={data}
                                title={t('config:label.add_user_to_agency')}
                                onAddStaff={this.props.onAddStaff}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="amt-16">
                    <Table
                        rowKey={record => record.id}
                        className={'a-table--antd __a-table-staff-in-warehouse'}
                        columns={this.getColumns()}
                        dataSource={data}
                        loading={loading}
                        locale={{
                            emptyText: (
                                <Empty
                                    description={(
                                        <span>{t('label.no_data')}</span>
                                    )}
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                />
                            ),
                        }}
                        pagination={{
                            current: filter.page,
                            pageSize: filter.per_page,
                            total: pagination.total,
                        }}
                        scroll={{ x: 'fit-content' }}
                        onChange={onChange}
                    />
                </div>
                {
                    visible && action &&
                    <Modal
                        closable={true}
                        visible={visible}
                        title={this.renderTitleModal()}
                        footer={[
                            <Button id="cancel" onClick={this.handleCancelModal}>{t('btn.cancel')}</Button>,
                            <Button loading={isLoading} type="primary" id="confirm"
                                    onClick={this.handleConfirmModal}>{t('btn.ok')}</Button>,
                        ]}
                        onCancel={this.handleCancelModal}
                    >
                        {
                            action === 'add-all' ? t('config:message.add_all_staff') : (action === 'remove-all') ? t('config:message.remove_all_staff') : t('config:message.remove_staff_all_agency')
                        }
                    </Modal>
                }
            </div>
        );
    }
}

ListUser.defaultProps = {
    data: [],
    columns: {},
    loading: false,
    active: false,
    agencies: [],
    onAddStaff: () => {
    },
};

ListUser.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    active: PropTypes.bool,
    columns: PropTypes.object,
    onAddStaff: PropTypes.func,
    agencies: PropTypes.array,

};

export default withTranslation()(ListUser);
