/* eslint-disable */
import React from 'react';
import Background from '../../../resources/images/404.png';
import {t} from "../../../system/i18n";
import Link from "../../../system/routing/Link";

export default () => (
    <div className="a-page--404" style={{backgroundImage: `url(${Background})`,backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',}}>
        <div className="a-page--404__detail">
            <div className="title">
                <h1><span>4</span><span>0</span><span>4</span></h1>
            </div>
            <h2>{t('message.404')}</h2>
            <button className="button-back">
                <Link to="home" className={'a-text--white'}>{t('btn.back_home')}</Link>
            </button>
        </div>
    </div>
);
