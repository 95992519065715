import React, { Component } from 'react';
import { Button, Col, Form, Icon, Row } from 'antd';
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';

import SelectCreatePartnerContainer from '../../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer';
import SelectAgency from './../../../../Setting/Agency/SelectAgency';

class SearchForm extends Component {
    state = {
        filter: this.props.filter,
        shippingPartner: undefined
    };

    handleChange = (field, value) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [field]: value
            }
        }));
    };

    handleSearch = event => {
        const { filter } = this.state;
        const { onSearch } = this.props;

        event.preventDefault();

        onSearch(filter);
    };

    handleRefreshFilter = event => {
        const { onRefresh } = this.props;

        event.preventDefault();

        onRefresh();
    };

    componentDidUpdate(prevProps) {
        const { filter } = this.props;

        if (!isEqual(filter, prevProps.filter)) {
            this.setState({
                filter
            });
        }
    }

    render() {
        const { filter } = this.state;
        const { t } = this.props;

        return (
            <Form className="a-content--search-header search-default pt-0 px-0 mb-0 mr-0" onSubmit={this.handleSearch}>
                <Row gutter={{lg: 12, xl: 24}}>
                    <Col lg={6} xl={8}>
                        <Form.Item
                            label={t('shipping_partner')}
                        >
                            <SelectCreatePartnerContainer
                                allowClear
                                className="_search-id-shipping-partner"
                                dropdownClassName="_search-dropdown-id-shipping-partner"
                                isFieldDecorator
                                value={filter.id_shipping_partner ? Number(filter.id_shipping_partner) : filter.id_shipping_partner}
                                onChange={this.handleChange.bind(undefined, 'id_shipping_partner')}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6} xl={8}>
                        <Form.Item
                            label="Agency"
                        >
                            <SelectAgency
                                allowClear
                                className="_search-id-agency"
                                dropdownClassName="_search-dropdown-id-agency"
                                placeholder=""
                                value={filter.id_agency}
                                onChange={this.handleChange.bind(undefined, 'id_agency')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="search--action">
                    <a href="/" className="_btn-refresh-filter link-reload" onClick={this.handleRefreshFilter}>
                        <Icon type="sync" />{t('label.refresh_filter')}
                    </a>
                    <Button
                        className="a-btn a-btn--primary a-btn--search-list _btn-search"
                        htmlType="submit"
                        icon="search"
                        type="primary"
                    >
                        {t('btn.search')}
                    </Button>
                </div>
            </Form>
        );
    }
}

export default withTranslation()(SearchForm);
