import React from 'react';
import {Affix, Button, Col, Form, Icon, Input, Layout, Row, Table} from "antd";

const Content = Layout.Content;
const Search = Input.Search;

const columns = [
    {
        title: 'Họ tên / Mã nhân viên ',
        dataIndex: 'name',
        key: 'name',
        className: 'a-text--nowrap',
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'phonenumber',
        key: 'phonenumber',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kho phụ trách',
        dataIndex: 'warehouseuse',
        key: 'warehouseuse',
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Ngày thêm',
        dataIndex: 'createdate',
        key: 'createdate',
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Người thêm ',
        dataIndex: 'personcreate',
        key: 'personcreate',
        render: text => <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: "a-text--nowrap atext-right",
        fixed: 'right',
        render: text => <a href={"#top"} className="a-text--nowrap ">{text}</a>,
        width: 40,
    }
];
const data = [];
for (let i = 0; i < 50; i++) {
    data.push({
        name: `Lê Hoàng Thuỳ Trang(lehoangthuytrang)`,
        phonenumber: `0868.678.888 `,
        warehouseuse: 'CNGZ-HX',
        createdate: '12:00 22/05/2019',
        personcreate: 'Vũ Nguỵ Như(vunguyenhu)',
        action: 'Xoá'
    });
}

class DetailPartner extends React.Component {
    render() {
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <a href={"#top"} className={"a-text--gray"}><Icon type="arrow-left" className={"mr-2 "}/>Quay
                        lại danh sách đối tác</a>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">Thông tin đối tác </h3>
                        <div className={"a-notification-dot active pl-5"}>
                            <span>Ngừng hoạt động </span>
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <Form onSubmit={this.handleSubmit} className="a-form ">
                            <Row gutter={{lg: 24, xl: 32}} className="a-block-info info--detail">
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Tên đối tác</label>
                                    <span className="info--value ">Công ty Shippo</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Mã đối tác</label>
                                    <span className="info--value ">Shippo</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Số điện thoại</label>
                                    <span className="info--value ">0985.888.888</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">E-mail</label>
                                    <span className="info--value ">contact@gobiz.vn</span>
                                </Col>
                            </Row>
                            <Row gutter={{lg: 24, xl: 32}} className="a-block-info info--detail">
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Quốc gia</label>
                                    <span className="info--value ">Việt Nam</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Tỉnh / Thành phố</label>
                                    <span className="info--value ">Hà Nội</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Quận / Huyện</label>
                                    <span className="info--value ">Thanh Xuân</span>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Phường / Xã</label>
                                    <span className="info--value ">Thanh Xuân</span>
                                </Col>
                            </Row>
                            <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                                <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                     className="info--group">
                                    <label className="info--label">Địa chỉ</label>
                                    <span className="info--value ">Số 05, đường Nguyễn Trãi, Thanh Xuân, Hà Nội</span>
                                </Col>
                                <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                     className="info--group">
                                    <label className="info--label">Ghi chú</label>
                                    <span className="info--value ">Đây là ghi chú của đối tác</span>
                                </Col>
                            </Row>

                            <Row gutter={48} className={"ml-0 mr-0"}>
                                <Col lg={{span: 24}} xl={{span: 24}}
                                     className="a-package--action only-action pl-0 pr-0 gflex gjustify-space-between">
                                    <a className="a-btn a-btn--back-package " href={"#top"}>Huỷ </a>
                                    <Button type="primary" htmlType="submit"
                                            className="a-btn a-btn--primary a-btn--save-package _btn-save-package">Chỉnh
                                        sửa</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className={"amt-16"}>
                    <Affix>
                        <div className="a-content__top a-content__top--page-list mr-0 mt-0">
                            <div className="a-content--page-list__title gflex galign-center">
                                <h3>Danh sách nhân viên (78)</h3>
                                <div className={"a-form__search-inline-title"}>
                                    <Search
                                        placeholder="Tìm kiếm bao mẫu"
                                        className={"a-input--search-inline-title"}
                                    />
                                </div>
                            </div>
                            <div className="a-content--page-list__top-action gflex ">

                                <Button type="primary"
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2"
                                        ghost>Xuất CSV
                                </Button>
                                <Button type="primary"
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ">Thêm
                                    nhân viên
                                </Button>
                            </div>
                        </div>
                    </Affix>
                    <div className="a-content__body bg-white a-content__body--page-list  height-100 mr-0">
                        <Table className={"a-table--antd"} columns={columns} dataSource={data}
                               pagination={false}
                               scroll={{x: 'fit-content'}}
                        />
                    </div>
                </div>
            </Content>
        )
    }
}

export default DetailPartner;
