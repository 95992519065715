import React, { Fragment, PureComponent } from 'react';
import { Button, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import lodash from 'lodash';

import authService from './../../../Auth/authService';
import { DEFAULT_STATE_ALONE_PACKAGE, PARAMETER_PACKAGE_ARR } from './../../constants';
import { isAlonePackage, isShipment } from './../../helper';
import packageService from './../../packageService';

import Notices from './../../../Common/components/notices/Notices';
import CustomerInformationContainer from './../../FormCommon/CustomerInformation/CustomerInformationContainer';
import PackageInfomationContainer from './../../FormCommon/PackageInfomation/PackageInfomationContainer';
import UpdatePackageParams from './../../UpdatePackageParams/UpdatePackageParamContainer'
import FormServices from './../../../Service/FormServices/FormServicesContainer';

class UpdateNotMapOrderPackage extends PureComponent {
    state = {
        ...DEFAULT_STATE_ALONE_PACKAGE
    };

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        if (lodash.get(props, 'packageData.id', 0) !== state.idPackage) {
            let {packageData} = props;
            let newPackageData = {...packageData, ...packageService.makeFormInput(packageData)};
            for (let propertyName in newPackageData) {
                let isEditedPackage = (packageData.current_page !== "UpdateAlonePackage" && PARAMETER_PACKAGE_ARR.includes(propertyName));
                if (newPackageData.hasOwnProperty(propertyName) && !isEditedPackage) {
                    nextState[propertyName] = newPackageData[propertyName];
                }
            }

            nextState.idPackage = packageData.id;
            nextState.services = lodash.map(packageData.services, 'id');
            nextState.properties = lodash.map(packageData.properties, 'id');
            nextState.weight_box = lodash.get(packageData, 'weight_box', 0);
        }

        if (lodash.get(props, "packageData.type", "") === "ALONE" && lodash.isNil(state.id_warehouse_input)) {
            nextState.id_warehouse_input = lodash.get(props, "packageData.id_warehouse_input", 0);
        }

        // Tạo dữ liệu mặc định khi tạo kiện độc lập
        if (lodash.isNil(state.permissions) && lodash.get(props, "packageData.type", "") === "ALONE" && lodash.get(props, 'packageData.id') === 0) {
            nextState = {
                ...props.packageData
            };
        }
        return nextState;
    }

    componentDidMount() {
        const { services } = this.state;
        const { clearSuggestBoxSizes, fetchSuggestBoxSizes } = this.props;

        if (!this.canDisplayUpdatePackageParamsForm()) {
            if (services && services.length) {
                fetchSuggestBoxSizes({
                    id_services: services
                });
            } else {
                clearSuggestBoxSizes();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { services } = this.state;
        const { packageData, fetchSuggestBoxSizes } = this.props;

        if (!lodash.isEqual(packageData, prevProps.packageData)) {
            const newState = {};

            let newPackageData = {...packageData, ...packageService.makeFormInput(packageData)};
            for (let propertyName in newPackageData) {
                let isEditedPackage = (packageData.current_page !== "UpdateAlonePackage" && PARAMETER_PACKAGE_ARR.includes(propertyName));
                if (newPackageData.hasOwnProperty(propertyName) && !isEditedPackage) {
                    newState[propertyName] = newPackageData[propertyName];
                }
            }

            newState.idPackage = packageData.id;
            newState.services = lodash.map(packageData.services, 'id');
            newState.properties = lodash.map(packageData.properties, 'id');
            newState.weight_box = lodash.get(packageData, 'weight_box', 0);

            this.setState(newState);
        }

        if (!this.canDisplayUpdatePackageParamsForm() && !lodash.isEqual(services, prevState.services)) {
            fetchSuggestBoxSizes(prevState.services);
        }
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    _customParamsRequest(packageData, state, defaultCountry) {
        let objParams = {
            ...state,
            id: packageData.id,
            code: packageData.code,
            customer_id_country_destination: state.customer_id_country_destination ? state.customer_id_country_destination : defaultCountry.id,
            customer_id_city_destination: state.customer_id_city_destination ? state.customer_id_city_destination : null,
            customer_id_district_destination: state.customer_id_district_destination ? state.customer_id_district_destination : null,
            id_customer: state.id_customer ? state.id_customer : null,
            id_box_size: state.id_box_size ? state.id_box_size : null,
            weight_box: !state.weight_box ? 0 : state.weight_box,
        };
        let isAlonePackage = packageData.type === 'ALONE' && packageData.barcode === '';
        if (packageData.current_page !== "UpdateAlonePackage" && !isAlonePackage) {
            PARAMETER_PACKAGE_ARR.map(params => {
                delete objParams[params];
                return params;
            });
        } else {
            delete objParams["volume"];
            PARAMETER_PACKAGE_ARR.map(params => {
                return objParams[params] = lodash.isNil(objParams[params]) ? 0 : objParams[params];
            });
        }

        return objParams;
    }

    /**
     * Handle change state of children components
     * @param state
     */
    onChangeChildState(state) {
        this.setState(state);

        if (lodash.get(state, "isNeedSubmit", false)) {
            let {defaultCountry, packageData} = this.props;
            let objParams = this._customParamsRequest(packageData, {...this.state, ...state}, defaultCountry);
            if (packageData.type === "ALONE" && packageData.id === 0) {
                this.props.onCreateAlonePackage(objParams);
            } else {
                this.props.onSubmit(objParams);
            }
        }
    }

    changeTextValue(event) {
        let {name, value} = event.target;
        this.setState({[name]: value});
        this.onResetError(name);
    }

    changeSelectValue(name, value) {
        this.setState({[name]: value});
        this.onResetError(name);
    }

    onResetError = name => {
        let {errors} = this.props;
        if (errors && errors.hasOwnProperty(name)) {
            this.props.resetError(name);
        }
    };

    back2DetailView = event => {
        event.preventDefault();
        this.props.onBack();
    };

    onSelectOrder = (orderItem) => {
        const properties = lodash.get(orderItem, 'properties', []) || [];
        const services = lodash.get(orderItem, 'services', []) || [];
        let data = {...this.state};
        data.order_code = lodash.get(orderItem, 'order.code');
        data.id_agency = lodash.get(orderItem, 'order.id_agency');
        data.properties = properties.length ? lodash.map(properties, 'id_property') : [];
        data.services = services.length ? lodash.map(services, 'id_service') : [];
        data.customer_sent = lodash.get(orderItem, 'order.customer_name');
        data.customer_receive = lodash.get(orderItem, 'order.customer_destination_name');
        data.customer_phone_destination = lodash.get(orderItem, 'order.customer_destination_phone');
        data.customer_note = lodash.get(orderItem, 'order.customer_note');
        data.customer_address_destination = lodash.get(orderItem, 'order.customer_destination_address');
        data.id_customer = lodash.get(orderItem, 'order.id_customer');
        data.id_warehouse_destination = lodash.get(orderItem, 'order.id_warehouse_destination');
        data.customer_id_country_destination = lodash.get(orderItem, 'order.customer_destination_country');
        data.customer_id_city_destination = lodash.get(orderItem, 'order.customer_destination_city');
        data.customer_id_district_destination = lodash.get(orderItem, 'order.customer_destination_district');
        data.permissions = lodash.get(this.props, 'packageData.permissions', {}) || {};

        this.setState({...data});
    };

    onChangeOrder = (value) => {
        if (lodash.isNil(value)) {
            let data = {...this.state};
            data.order_code = null;
            this.setState({...data});
        }
    };

    handleSubmit = (event) => {
        const { boxSizes, defaultCountry, form, onCreateAlonePackage, onSubmit } = this.props;

        event.preventDefault();

        form.validateFieldsAndScroll((err) => {
            if (!err) {
                const packageData = {...this.props.packageData};
                const configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g');
                const newPackageData = {...packageData, ...packageService.makeFormInput(packageData)};
                let objParams = this._customParamsRequest(newPackageData, {...this.state}, defaultCountry);

                if (configPackageUnitWeight === 'kg' && !lodash.isNil(objParams.weight_net)) {
                    objParams.weight_net = objParams.weight_net * 10;
                }

                if (!boxSizes || !boxSizes.length) {
                    objParams.id_box_size = null;
                    objParams.weight_box = 0;
                }

                if (isAlonePackage(packageData) && packageData.id === 0 && packageData.barcode === '') {
                    onCreateAlonePackage(objParams);
                } else {
                    onSubmit(objParams);
                }
            }
        });
    };

    handleChangeServices = services => {
        this.setState({
            services
        });
    };

    canDisplayUpdatePackageParamsForm = () => {
        let { packageData } = this.props;
        const isAlonePackage = (packageData.id === 0 || lodash.isNil(packageData.id)) && packageData.barcode === '';
        if (isAlonePackage && packageData.type === 'ALONE') {
            packageData = {
                ...this.state
            };
        }

        return !!(packageData.current_page === 'UpdateAlonePackage' || isAlonePackage);
    };

    render() {
        const { order_code, services } = this.state;
        let { errors, form, isLoadingProperty, loading, packageData, t } = this.props;
        const isAlonePackage = (packageData.id === 0 || lodash.isNil(packageData.id)) && packageData.barcode === '';
        if (isAlonePackage && packageData.type === 'ALONE') {
            packageData = {...this.state};
        }

        return lodash.get(packageData, 'permissions.can_update') ? (
            <Fragment>
                <Form className="a-form form--package">
                    <Notices
                        className="amb-16"
                        notices={lodash.get(packageData, 'notices.text', [])}
                    />
                    <Row gutter={{lg: 32, xl: 48}}>
                        <Col lg={{span: 16}} xl={{span: 19}} xxl={{span: 18}}>
                            <PackageInfomationContainer
                                form={form}
                                packageData={packageData}
                                loading={loading}
                                errors={errors}
                                resetError={this.onResetError}
                                onSubmit={this.onChangeChildState.bind(this)}
                                onSelectOrder={this.onSelectOrder}
                                onChangeOrder={this.onChangeOrder}
                            />
                            {this.canDisplayUpdatePackageParamsForm() && (
                                <UpdatePackageParams
                                    className="amt-16"
                                    errors={lodash.pick(errors, ['weight_net', 'length', 'width', 'height', 'id_box_size', 'size_drc'])}
                                    form={form}
                                    isWapper={true}
                                    isNeedSubmit={false}
                                    package={packageData}
                                    services={services}
                                    validate={true}
                                    onSubmit={this.onChangeChildState.bind(this)}
                                    resetError={this.onResetError}
                                />
                            )}
                        </Col>
                        <Col lg={{span: 8}} xl={{span: 5}} xxl={{span: 6}} className="pl-0">
                            <div className="a-inline-edit service-edit">
                                <div className="a-inline-edit--input">
                                    <FormServices
                                        singleType
                                        types={isShipment(packageData) ? ['consign'] : undefined}
                                        values={services}
                                        onChange={this.handleChangeServices}
                                        disabled={isAlonePackage && packageData.type === 'ALONE' && !lodash.isNil(order_code)}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <CustomerInformationContainer
                        errors={errors}
                        loading={loading}
                        packageData={(isAlonePackage && packageData.type === 'ALONE') ? {...this.state} : packageData}
                        required={false}
                        resetError={this.onResetError}
                        onSubmit={this.onChangeChildState.bind(this)}
                    />
                    <Row gutter={48}>
                        <Col className="a-package--action only-action" lg={{span: 24}}>
                            {packageData.current_page === 'UpdateAlonePackage' ? (
                                <a
                                    className="a-btn a-btn--back-package"
                                    href="/"
                                    onClick={this.back2DetailView}
                                >
                                    {t('btn.back')}
                                </a>
                            ) : null}
                            <Button
                                className="a-btn a-btn--primary a-btn--save-package _btn-save-package"
                                htmlType="submit"
                                loading={loading || isLoadingProperty}
                                type="primary"
                                onClick={this.handleSubmit}
                            >
                                {t('btn.save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        ) : null;
    }
}

UpdateNotMapOrderPackage.defaultProps = {
    packageData: {},
    loading: false,
    isLoadingProperty: false,
    defaultCountry: {id: 0},
    errors: {},
};

UpdateNotMapOrderPackage.propTypes = {
    packageData: PropTypes.object,
    loading: PropTypes.bool,
    isLoadingProperty: PropTypes.bool,
    canBack: PropTypes.bool,
    defaultCountry: PropTypes.object,
    errors: PropTypes.object,
    onSubmit: PropTypes.func,
    onCreateAlonePackage: PropTypes.func,
    onReset: PropTypes.func,
    onBack: PropTypes.func,
};

export default Form.create({})(withTranslation()(UpdateNotMapOrderPackage));
