import React from 'react';
import {connect} from 'react-redux';
import CreateBag from './components/'
import {setMenuActive} from "../../Common/actions";
import {clearState, createBag, fetchTemplateBags, resetError} from "./actions";
import {fetchShippingUserSuggest} from '../../ShippingUser/List/actions';
import lodash from "lodash";
import * as selector from "./selectors";
import {LIST_BAG_SHIPPING_TYPE} from "../BagShippingType/constants";
import authService from "../../Auth/authService";
import {PARTNER_BAG_CONFIG} from "../../Auth/constants";
import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups';
import withBagAreas from './../../../hocs/withBagAreas';

const mapStateToProps = (state) => {
    const configBagSizeRequired = authService.getPartnerConfig('bag', PARTNER_BAG_CONFIG.BAG_SIZE_REQUIRED_WHEN_CREATING);
    const bagSizeRequired = !!lodash.get(configBagSizeRequired, 'value');
    return {
        bagSizeRequired: bagSizeRequired,
        isLoadingProperty: lodash.get(state, "property.loading", false),
        loading: !!lodash.get(state, "bag.CreateBag.loading", false),
        errors: selector.getErrors(lodash.get(state, "bag.CreateBag.errors", {})),
        newBag: lodash.get(state, "bag.CreateBag.newBag", {}),
        bagSizes: lodash.get(state, "bag.BagSize.list", []),
        bagTypes: [...LIST_BAG_SHIPPING_TYPE],
        listShippingUser: lodash.get(state, "shippingUser.list.usersSuggest"),
        dataTemplateBags: selector.getDataTemplateBags(state)
    }
};

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    onSubmit: (params) => dispatch(createBag(params)),
    onReset: () => dispatch(clearState()),
    resetError: (params) => {
        dispatch(resetError(params));
    },
    fetchTemplateBags: (params) => {dispatch(fetchTemplateBags(params))},
    fetchShippingUserSuggest: () => dispatch(fetchShippingUserSuggest())
});

class CreateBagContainer extends React.PureComponent {
    componentDidMount() {
        const { listShippingUser, fetchShippingUserSuggest, getActiveBagAreaSuggests } = this.props;

        if (!listShippingUser.length) {
            fetchShippingUserSuggest();
        }

        getActiveBagAreaSuggests();
    }

    render() {
        return (
            <CreateBag {...this.props} />
        );
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withBagAreas(withConsignServiceGroups(CreateBagContainer)));

export default Container;
