import { Button, Modal, notification } from 'antd'
import clsx from 'clsx'
import { get } from 'lodash'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import apiService from '../apiService'

const CompletePackingBag = ({ loading, disabled, idBag, fetchDetailBag, bag }) => {
    const [loadingBtn, setLoadingBtn] = useState(false)

    const onCompleteBag = () => {
        if (!get(bag, 'weight_real')) {
            return notification.warning({ message: t('bag:lastmile.message.weight_real_require') })
        }

        Modal.confirm({
            title: t('bag:lastmile.message.confirm_complete_packing'),
            content: '',
            onOk() {
                return apiService
                    .packingBagLastmileComplete(idBag)
                    .then(result => {
                        fetchDetailBag(idBag)
                        notification.success({ message: t('bag:lastmile.message.packing_end_success') })
                    })
                    .catch(err => {
                        notification.error({ message: t('bag:lastmile.message.packing_end_fail') })
                    })
                    .finally(() => {
                        setLoadingBtn(false)
                    })
            },
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
        })
    }

    return (
        <>
            <Button
                className={clsx('a-btn a-btn--primary _btn_end_packing a-btn--action-bag-pack', {
                    'disabled-bag-pack': loading || disabled,
                })}
                disabled={loading || disabled || loadingBtn}
                loading={loading || loadingBtn}
                type="primary"
                onClick={onCompleteBag}>
                {t('bag:btn.end_packing')}
            </Button>
        </>
    )
}

export default CompletePackingBag
