import React, {PureComponent} from 'react';
import { Button, Col, Layout, notification, Row } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { resolveInventoryTrackingBill } from './../../../actions';
import apiService from './../../../apiService';
import { INVENTORY_TYPES } from './../../../constants';
import * as selectors from './../../../selectors';
import pageService from './../../../../Common/Page/pageService';
import { convertBlobDataToExcel } from './../../../../../system/support/helpers';

import Disappear from './Disappear';
import FormSearch from './FormSearch';
import Normal from './Normal';
import Remainder from './Remainder';
import Tabs from './../../../../Common/components/Tabs/Tabs';
import TabPane from './../../../../Common/components/Tabs/TabPane';

const mapStateToProps = (state) => {
    return {
        resolving: selectors.getState(state, 'resolvingInventoryTrackingBill'),
        countInventoryTrackingBills: selectors.getState(state, 'detail.count_inventory_tracking_bills', {})
    };
};

const mapDispatchToProps = {
    resolveInventoryTrackingBill
};

class ReceivePackages extends PureComponent {
    state = {
        downloadingDisappearTrackingBills: false,
        downloadingScanningTrackingBills: false
    };

    downloadDisappearTrackingBills = async () => {
        const { inventory, t, warehouse } = this.props;

        this.setState({
            downloadingDisappearTrackingBills: true
        });

        try {
            const { data } = await apiService.downloadDisappearTrackingBills(get(inventory, 'id'));

            convertBlobDataToExcel(data, `disappear_tracking_bills_${get(warehouse, 'code')}`);

            notification.success({
                message: t('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_failed')
            });
        }

        this.setState({
            downloadingDisappearTrackingBills: false
        });
    };

    downloadScanningTrackingBills = async () => {
        const { inventory, t, warehouse } = this.props;

        this.setState({
            downloadingScanningTrackingBills: true
        });

        try {
            const { data } = await apiService.downloadScanningTrackingBills(get(inventory, 'id'));

            convertBlobDataToExcel(data, `scanning_tracking_bills_${get(warehouse, 'code')}`);

            notification.success({
                message: t('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_failed')
            });
        }

        this.setState({
            downloadingScanningTrackingBills: false
        });
    };

    componentDidMount() {
        const { t } = this.props;

        pageService.setTitle(t('inventory:comparing.receive_package'));
    }

    render() {
        const { downloadingDisappearTrackingBills, downloadingScanningTrackingBills } = this.state;
        const { loading, normals, remainders, t } = this.props;

        return (
            <Layout className="inventory-process gflex gflex-direction-column height-not-header">
                <FormSearch {...this.props} inventoryType={INVENTORY_TYPES.RECEIVED.key} />
                <Row gutter={{lg: 24, xl: 24, xxl: 24}} className="aflex-1 gflex min-h-0">
                    <Col lg={12} xl={12} xxl={12} className="aheight-100 gflex gflex-direction-column">
                        <Disappear
                            {...this.props}
                            downloadingDisappearTrackingBills={downloadingDisappearTrackingBills}
                            onDownloadDisappearTrackingBillsButtonClick={this.downloadDisappearTrackingBills}
                        />
                    </Col>
                    <Col lg={12} xl={12} xxl={12} className="aheight-100 gflex gflex-direction-column">
                        <Tabs
                            className="a-tabs a-tabs--container"
                            defaultActiveKey="remainder"
                            rightTopBarContent={(
                                <Button loading={downloadingScanningTrackingBills} onClick={this.downloadScanningTrackingBills}>
                                    {t('btn.export_excel')}
                                </Button>
                            )}
                        >
                            <TabPane
                                key="remainder"
                                tab={`${t('inventory:remainder_list.receive_package')} (${remainders.length})`}
                            >
                                <Remainder {...this.props} />
                            </TabPane>
                            <TabPane
                                key="normal"
                                tab={`${t('inventory:normal_list.receive_package')} (${normals.length})`}
                            >
                                <Normal
                                    loading={loading}
                                    normals={normals}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Layout>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceivePackages);
