import React, { Component } from 'react';
import { notification } from 'antd';
import lodash from 'lodash';
import { connect } from 'react-redux';

import { printPackageUnOfficialStamp } from './../actions';
import apiService from '../../../Package/apiService';
import { t } from '../../../../system/i18n';

import PrintPackageUnOfficialStamp from './PrintPackageUnOfficialStamp';

const mapDispatchToProps = {
    printPackageUnOfficialStamp
};

class PrintPackageUnOfficialStampTest extends Component {
    state = {
        data: {}
    };

    componentDidMount() {
        const { match: { params: { code }} } = this.props;

        document.getElementsByTagName('body')[0].classList.add('A5');

        apiService.getUnOfficialStampData(code)
            .then(response => {
                const data = lodash.get(response, 'data');

                this.setState({
                    data
                });
            })
            .catch(() => {
                notification.error({
                    message: t('common:message.server_error')
                });
            });
    }


    render() {
        const { data } = this.state;

        if (!Object.keys(data).length) {
            return null;
        }

        return (
            <>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"/>
                <section className="sheet">
                    <PrintPackageUnOfficialStamp
                        {...data}
                    />
                </section>
            </>
        );
    }
}

export default connect(
    undefined,
    mapDispatchToProps
)(PrintPackageUnOfficialStampTest);
