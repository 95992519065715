import {takeLatest, all, fork} from 'redux-saga/effects';
import * as saga from './saga';
import {API} from '../../system/api/constants';
import logWatcher from './Log/sagaWatcher';

export default function*() {
    yield all([
        fork(logWatcher),
    ]);

    yield takeLatest(API.ERROR, saga.onApiRequestError);
}
