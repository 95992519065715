export const API = {
    REQUEST: 'API.REQUEST',
    SUCCESS: 'API.SUCCESS',
    ERROR: 'API.ERROR',
};

export const HTTP_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHENTICATED: 401,
    UNAUTHORIZED: 403,
    ENTITY_TOO_LARGE: 413,
    NOT_FOUND: 404
};

