import React from 'react';

const Deposit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(-0.084 -0.084)">
        <path className="c" d="M15.456,5.837l-2.521-1.05A.168.168,0,0,1,13,4.463h.977a1.684,1.684,0,1,0,0-3.368H11.284a.337.337,0,0,1-.337-.337A.674.674,0,0,0,9.6.758a1.684,1.684,0,0,0,1.684,1.684h2.695a.337.337,0,0,1,0,.674H11.958a1.347,1.347,0,0,0-1.329,1.165.168.168,0,0,1-.1.135L7.113,5.837a.337.337,0,0,0-.207.311v4.379a.337.337,0,0,0,.207.311l4.042,1.684a.331.331,0,0,0,.259,0l4.042-1.684a.337.337,0,0,0,.207-.311V6.147a.337.337,0,0,0-.207-.311ZM11.22,5.192a.166.166,0,0,1,.129,0l1.923.8a.168.168,0,0,1,0,.311l-1.92.8a.166.166,0,0,1-.129,0L9.3,6.3a.168.168,0,0,1,0-.311Z" />
        <path className="c" d="M9.263,13.053H4.211a.337.337,0,1,1,0-.674H6.232a.337.337,0,0,0,.337-.337,1.347,1.347,0,0,0-1.347-1.347H1.684a.168.168,0,0,1-.168-.168.505.505,0,1,0-1.011,0v5.053a.505.505,0,1,0,1.011,0,.168.168,0,0,1,.168-.168h9.263a.337.337,0,0,0,.337-.337A2.021,2.021,0,0,0,9.263,13.053Z" />
      </g>
    </g>
  </svg>
);

export default Deposit;
