export const TYPE_PRINT = {
    PACKAGE: 'package',
    MULTI_PACKAGE: 'multi_package',
    BAG: 'bag',
    ISSUE: 'issue',
    DELIVERY_NOTE_RETURN: 'delivery-note-return',
    DELIVERY_NOTE_TRANSPORT_WAREHOUSE: 'delivery-note-transport-warehouse',
    FBU_STAMP: 'fbu_stamp'
};

export const PRINT_PACKAGE_STAMP = 'PRINT_PACKAGE_STAMP';
export const CLEAR_PRINT_STATE = 'CLEAR_PRINT_STATE';
export const PRINT_ISSUE = 'PRINT_ISSUE';
export const PRINT_DELIVERY_NOTE_RETURN = 'PRINT_DELIVERY_NOTE_RETURN';
export const PRINT_DELIVERY_NOTE_TRANSPORT_WAREHOUSE = 'PRINT_DELIVERY_NOTE_TRANSPORT_WAREHOUSE';
export const CLEAR_PRINT_ISSUE = 'CLEAR_PRINT_ISSUE';
export const PRINT_OFFICIAL_PACKAGE_STAMP = 'PRINT_OFFICIAL_PACKAGE_STAMP';
export const PRINT_PACKAGE_UNOFFICIAL_STAMP = 'PRINT_PACKAGE_UNOFFICIAL_STAMP';
export const PRINT_BAG_STAMP = 'PRINT_BAG_STAMP';
export const LOAD_PRINT_TEMPLATE = 'LOAD_PRINT_TEMPLATE';
export const FINISH_LOAD_PRINT_TEMPLATE = 'FINISH_LOAD_PRINT_TEMPLATE';
export const PRINT_IFRAME = 'PRINT_IFRAME';
export const PRINT_BATCH_STAMP = 'PRINT_BATCH_STAMP';
export const PRINT_MULTI_PACKAGE_STAMP = 'PRINT_MULTI_PACKAGE_STAMP'
export const PRINT_FBU_STAMP = 'PRINT_FBU_STAMP'
export const PRINT_WAREHOUSE_AREA_STAMP = 'PRINT_WAREHOUSE_AREA_STAMP'
export const PRINT_9710_STAMP = 'PRINT_9710_STAMP'

export const TEMPLATE_STAMP = {
    "DCT": "dct"
}