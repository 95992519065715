import lodash from 'lodash';
import {t} from '../../../system/i18n';

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let attribute = t('bag_size:label.' + param);

        let messages = lodash.mapValues(rules, (data, rule) => {
            let messageKey = 'validation:' + rule;
            let message = t(messageKey, {...data, attribute});

            if (param === 'weight_max' && rule === 'gte') {
                message = t('bag_size:validation.weight_max_gte_weight_min');
            }

            if (param === 'weight_min' && rule === 'lte') {
                message = t('bag_size:validation.weight_min_lte_weight_max');
            }

            if (param === 'height' || param === 'length' || param === 'width') {
                if (rule === 'max.numeric') {
                    let d = {...data};
                    d.max = data.max + 'cm';
                    message = t(messageKey, {...d, attribute});
                }
            }

            return message;
        });

        // let messages = translateValidationErrors(attribute, rules);
        return lodash.first(lodash.values(messages));
    });
};
