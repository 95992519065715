import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchPropertySuggest } from './../actions';
import { getActiveProperties, getState } from './../selectors';

import FormProperties from './components/FormProperties';

const mapStateToProps = (state, props) => ({
    ...props,
    properties: getActiveProperties(state),
    loading: getState(state, 'loading'),
});

const mapDispatchToProps = {
    fetchPropertySuggest
};

class FormPropertiesContainer extends Component {
    componentDidMount() {
        const { properties, fetchPropertySuggest } = this.props;

        if (!properties.length) {
            fetchPropertySuggest();
        }
    }

    render() {
        const { disabled, loading, properties, values, onChange } = this.props;

        return (
            <FormProperties
                disabled={disabled}
                loading={loading}
                properties={properties}
                values={values}
                onChange={onChange}
            />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(FormPropertiesContainer);

Container.defaultProps = {
    values: [],
    onChange: () => {},
    disabled: false
};

Container.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default Container;
