import {combineReducers} from 'redux';
import listDeliveryRequest from './ListDeliveryRequest/reducer';
import cancelDeliveryRequest from './CancelDeliveryRequest/reducer';
import detailDeliveryRequest from './DetailDeliveryRequest/reducer';

export default combineReducers({
    listDeliveryRequest,
    cancelDeliveryRequest,
    detailDeliveryRequest
});
