import React, {Component} from 'react';
import {Layout, Tabs} from 'antd';
import FormSearch from './FormSearch';
import ListBag from './ListBag';
import lodash from 'lodash';
import {t} from "../../../../system/i18n";
import pageService from '../../../Common/Page/pageService';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {TYPES} from "../../constants";

const {Content} = Layout;
const TabPane = Tabs.TabPane;

class ListBagIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenListSetting: false,
            isCurrentPage: TYPES.ALL,
            page: props.page,
            tab_on_day: 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        let page = props.page;
        if (nextState.page !== page) {
            let path = (page === 'list_bag_pack') ? "bags/list-pack" : "bags";
            pageService.setTitle(t("common:menu." + page));
            nextState.isCurrentPage = TYPES.ALL;
            nextState.page = page;
            props.setMenuActive(path);

        }
        return nextState;
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    componentDidMount() {
        const { page, setMenuActive, getPropertySuggests } = this.props;
        const path = (page === 'list_bag_pack') ? 'bags/list-pack' : 'bags';

        pageService.setTitle(t(`common:menu.${page}`));
        setMenuActive(path);
        getPropertySuggests();
    }

    onChangeTabs(tab) {
        let filter = {};
        filter.tab_on_day = tab === "IN_A_DAY" ? 1 : 0;
        filter.i = lodash.toInteger(this.props.filter.i);
        filter.packing_complete = this.props.page === 'list_bag_pack' ? 0 : null;
        this.props.onSwitchTab();
        this.props.onSearch(filter);
        this.setState({
            isOpenListSetting: this.state.isOpenListSetting ? false : this.state.isOpenListSetting,
            isCurrentPage: tab
        })
    }

    onOpenListSetting(isOpenListSetting, isCurrentPage) {
        this.setState({isOpenListSetting, isCurrentPage})
    }

    render() {
        const { bagTemplates, consignServices, filter, services } = this.props;

        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <Tabs defaultActiveKey={filter.tab_on_day === "1" ? 'IN_A_DAY' : 'ALL'}
                          className="a-tabs a-tabs--container"
                          onChange={this.onChangeTabs.bind(this)}
                          activeKey={filter.tab_on_day === "1" ? 'IN_A_DAY' : 'ALL'}
                          animated={false}>
                        {lodash.map(TYPES, (tab) =>
                            <TabPane tab={t("bag:label." + tab.toLocaleLowerCase() + '_bag')} key={tab}>
                                <FormSearch
                                    bagTemplates={bagTemplates}
                                    consignServices={consignServices}
                                    filter={filter}
                                    input={filter}
                                    inputVersion={lodash.toInteger(this.props.filter.i)}
                                    loading={this.props.loading}
                                    page={this.props.page}
                                    services={services}
                                    onSearch={this.props.onSearch}
                                />
                                <ListBag
                                    bags={this.props.bags}
                                    pagination={this.props.pagination}
                                    filter={filter}
                                    page={this.props.page}
                                    loading={this.props.loading}
                                    onSearch={this.props.onSearch}
                                    isOpenListSetting={this.state.isOpenListSetting}
                                    onOpenListSetting={this.onOpenListSetting.bind(this)}
                                    isCurrentPage={this.state.isCurrentPage}
                                    object={tab}
                                    exportBag={this.props.exportBag}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </Content>
            </Layout>
        )
    }
}

ListBagIndex.defaultProps = {
    bags: [],
    title: '',
};

ListBagIndex.propTypes = {
    bags: PropTypes.array,
    onReset: PropTypes.func,
    title: PropTypes.string,
};

export default withTranslation()(ListBagIndex);
