import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Icon from './../../../../components/icons/Icon';

class FormServices extends React.PureComponent {
    onSelect(propertyId, selected, event) {
        event.preventDefault();
        if (!this.props.disabled) {
            this.changeProperty(propertyId, selected);
        }
    }

    changeProperty(propertyId, selected) {
        let values = selected
            ? [...this.props.values, propertyId]
            : this.props.values.filter(value => value !== propertyId);
        this.props.onChange(values);
    }

    render() {
        return (
            <ul className={this.props.classUl === '' ? 'property--package' : this.props.classUl}>
                {this.props.properties.map(property => {
                    let active = this.props.values.includes(property.id);
                    return (
                        <li
                            key={property.id}
                            className={clsx('list', '_property', `_property${property.id}`, {
                                active
                            })}
                        >
                            <a
                                href="#top"
                                className={clsx('link', {
                                    'not-allowed': this.props.disabled
                                })}
                                onClick={this.onSelect.bind(this, property.id, !active)}
                            >
                                <Icon className="mr-2" type={property.icon} />
                                {property.name}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

FormServices.defaultProps = {
    classUl: '',
    loading: false,
    properties: [],
    values: [],
    onChange: () => {
    },
    disabled: false
};

FormServices.propTypes = {
    loading: PropTypes.bool,
    properties: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    classUl: PropTypes.string,
};

export default FormServices;
