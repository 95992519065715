import React, { Component } from 'react';
import { Button, notification } from 'antd';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { handleExportManifestsError, isExportManifestToEmail } from './../../helper';
import { convertBlobDataToExcel } from './../../../../system/support/helpers';

import ExportManifestsModal from './../../ListBag/components/ExportManifestsModal';

class ExportManifestButton extends Component {
    state = {
        visible: false,
        exporting: false
    };

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    handleExportButtonClick = () => {
        this.showModal();
    };

    exportManifests = async (officialForm, language) => {
        const { bag, filter, t } = this.props;
        const bagIds = [bag.id];

        this.setState({
            exportingManifests: true
        });

        try {
            const response = await apiService.exportManifests({
                ...filter,
                id_bags: bagIds,
                language,
                official_form: officialForm
            });
            const reader = new FileReader();
            let message = t('message.export_manifest.success');

            reader.addEventListener('loadend', event => {
                const text = event.target.result;

                try {
                    const data = JSON.parse(text);

                    if (isExportManifestToEmail(data)) {
                        message = t('message.export_manifest.success_to_mail');
                    } else {
                        convertBlobDataToExcel(response.data, `export_manifests_${language}`);
                    }

                    notification.success({
                        message
                    });
                } catch (e) {
                    convertBlobDataToExcel(response.data, `export_manifests_${language}`);

                    notification.success({
                        message
                    });
                }
            });

            reader.readAsText(response.data);
        } catch (error) {
            handleExportManifestsError(error);
        }

        this.setState({
            exportingManifests: false
        });
    };

    render() {
        const { exportingManifests, visible } = this.state;
        const { t } = this.props;

        return (
            <>
                <Button
                    type="button"
                    className="a-btn a-btn--transparent-white mr-3 _btn_export_manifest"
                    ghost
                    onClick={this.handleExportButtonClick}
                >
                    {t('bag:btn.export_manifest_file')}
                </Button>

                <ExportManifestsModal
                    exporting={exportingManifests}
                    visible={visible}
                    onCancel={this.handleCancel}
                    onExport={this.exportManifests}
                />
            </>
        );
    }
}

export default withTranslation()(ExportManifestButton);
