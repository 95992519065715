import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getState} from '../selectors';
import FormMultiServices from './components/FormMultiServices';

const mapStateToProps = state => ({
    loading: getState(state, 'loadingServices'),
    services: getState(state, 'services')
});

const FormMultiServicesContainer = props => (
    <FormMultiServices {...props} />
);

FormMultiServicesContainer.defaultProps = {
    values: [],
    onChange: () => {},
};

FormMultiServicesContainer.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default connect(
    mapStateToProps
)(FormMultiServicesContainer);
