/* eslint-disable */
import React, {Component} from 'react';
import {Layout, Button, Alert, Icon, Pagination, Select} from 'antd';
import SiderBarcode from '../Sidebar/modules/SiderBarcode';

const {Content} = Layout;
const Option = Select.Option;
const Sort = <div className="sort"><Icon type="caret-up" className="active"/><Icon type="caret-down"/></div>;

class BarcodeScan extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout>
                <SiderBarcode/>
                <Content className="a-content a-content--is-sidebar a-barcode-scan ">
                    <div className="a-content__top">
                        <div className="a-barcode-scan__title">
                            <h3>Lịch Sử quét <span> - Đã quét <span>32216</span> mã</span></h3>
                        </div>
                        <div className="a-barcode-scan__top-action">
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                    ghost>Xuất CSV
                            </Button>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular a-btn--disable "
                                    icon={"sync"}>Làm mới lịch
                                sử quét</Button>
                        </div>
                    </div>
                    <div
                        className="a-content__body bg-white a-barcode-scan__body a-content__body--page-list list-is-sidebar ">
                        <Alert className="mb-4" message="Warning" type="warning" showIcon/>
                        <div className="a-table--responsive">
                            <table className="a-table a-table--barcode-scan">
                                <thead>
                                <tr>
                                    <th>Mã Quét</th>
                                    <th>Thời Gian Quét {Sort}</th>
                                    <th>Hành Động</th>
                                    <th>Kho quét</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody className="error">
                                <tr className="data">
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="4">
                                                <span className="a-text--black-45">
                                                <Icon type='close-circle' theme="filled" className="a-text--red mr-2"/>
                                                Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody className="warning">
                                <tr className="data">
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                <tr className="message">
                                    <td colSpan="4">
                                                <span className="a-text--black-45">
                                                    <Icon type='info-circle' theme="filled"
                                                          className="a-text--orange mr-2"/>
                                                    Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                        className="logo-shop"
                                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                        alt=""/>987293847928927</a></td>
                                    <td>00.00 03/02/1995</td>
                                    <td><span className="a-text--green">Nhập</span></td>
                                    <td>CNPX</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </Content>
            </Layout>
        );
    }
}

export default BarcodeScan;
