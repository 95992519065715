import { auth } from '../../../system/api';
import { router } from '../../../system/routing';

export default (payload, next) => {
    const locationQuery = router.getLocationQuery();
    const storageAuthToken = auth.getToken();
    const authToken = locationQuery.auth_token || storageAuthToken;

    if (!authToken || !locationQuery.code) {
        return router.replace('/403');
    }

    auth.setToken(authToken);

    return next(payload);
};
