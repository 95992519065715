import { Icon, Modal, notification } from 'antd'
import { get } from 'lodash'
import React, { useState } from 'react'
import { t, trans } from '../../../../system/i18n'
import apiService from '../apiService'

const DeletePackage = ({ idBag, idPackage, bag, packageCode,fetchDetailBag }) => {
    const [loading, setLoading] = useState(false)

    const onDeletePackage = () => {
        Modal.confirm({
            title: trans('bag:lastmile.message.delete_package_from_bag', { bag_code: <b>{get(bag, 'code', '---')}</b>, package_code: <b>{packageCode}</b> }),
            content: '',
            onOk() {
                return apiService
                    .packingBagLastmileDelete(idBag, { id_package: idPackage })
                    .then(result => {
                        fetchDetailBag(idBag)
                        notification.success({ message: t('bag:lastmile.message.delete_package_from_bag_success') })
                    })
                    .catch(err => {
                        notification.error({ message: t('bag:lastmile.message.delete_package_from_bag_fail') })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            },
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
        })
    }

    return (
        <Icon
            className="_icon_close"
            type={'close'}
            onClick={onDeletePackage}
        />
    )
}

export default DeletePackage
