/* eslint-disable */
import React, {Component} from 'react';
import {Row, Col, Button, Layout, Input, Form, Select, Icon, AutoComplete} from 'antd';

const {Sider} = Layout;

class SiderBagPack extends Component {
    constructor(props) {
        super(props)
        this.state = {
            warehouse: "CNGZ",
            infobag: 0
        }
    };

    onChangeWarehouse = (warehouse) => {
        this.setState({warehouse});
    }

    componentDidMount() {
        let name_bag, action_bag, info_bag__title, infobag, bag_pack;
        bag_pack = window.innerHeight;
        name_bag = this.refs.name_bag.clientHeight;
        action_bag = this.refs.action_bag.clientHeight;
        info_bag__title = this.refs.info_bag__title.clientHeight;
        infobag = bag_pack - 105 - name_bag - action_bag - info_bag__title;
        this.setState({infobag: infobag})
    }

    render() {
        let {scroll} = this.props;
        return (
            <Sider className={"a-sider-sub a-sider-sub--radius bag-pack " + (scroll ? 'scroll' : '')}>
                <div className="bag-pack--block" ref="name_bag">
                    <span className="title">Bao hàng</span>
                    <p className="name-bag">HH01A2 - 998112345678</p>
                </div>
                <div className="bag-pack--block" ref="action_bag">
                    <Form onSubmit={this.handleSubmit} className="a-form a-form--sider-in-bag-pack ">
                        <Form.Item className="a-form__item item--bag-pack label-special " label="Quét mã kiện"
                                   labelCol={{span: 24}}
                        >

                            <AutoComplete
                                className="a-input a-select--auto-complete--bag-pack "
                            />
                        </Form.Item>
                        <Form.Item className="a-form__item item--bag-pack " label="Cân nặng bao(kg)"
                                   labelCol={{span: 24}}
                                   required={true}
                        >
                            <Input className="a-input a-input--w-100" value={0}/>
                        </Form.Item>
                        <Form.Item className="a-form__item item--bag-pack " labelCol={{span: 24}}
                        >
                            <div className="item--bag-pack--action">
                                <Button type="primary" className="a-btn a-btn--primary a-btn--action-bag-pack">Kết thúc
                                    đóng bao</Button>
                                <Select
                                    className="a-select a-select--action-bag-pack"
                                    suffixIcon={
                                        <span className="icon--more"></span>
                                    }
                                    dropdownClassName={"a-select--action-bag-pack--dropdown"}
                                >
                                    <Option value='Kết thúc đóng bao'>Kết thúc đóng bao</Option>
                                    <Option value='Kết thúc và tạo bao mới'>Kết thúc và tạo bao mới</Option>
                                    <Option value='Kết thúc và chọn bao có sẵn'>Kết thúc và chọn bao có sẵn</Option>
                                </Select>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div className="info-bag">
                    <div className="title" ref="info_bag__title">
                        <span>Thông tin bao hàng</span>
                    </div>
                    <ul style={{maxHeight: (scroll ? this.state.infobag + 100 + 'px' : this.state.infobag + 'px')}}>
                        <li>
                            <label className="info-bag--label">Tổng cân nặng kiện</label>
                            <span className="info-bag--value">2320 (kg)</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Đặc tính</label>
                            <span className="info-bag--value">Điện tử / Dễ vỡ / Công nghệ</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Dịch vụ</label>
                            <span className="info-bag--value">Đóng gỗ; CNP</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Kho tạo</label>
                            <span className="info-bag--value">USNY</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Kho đích</label>
                            <span className="info-bag--value">VNHN</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Vận chuyển</label>
                            <span className="info-bag--value">Đơn vị - Autocomplete</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Người nhận</label>
                            <span className="info-bag--value">Lê Hoàng Trọng</span>
                        </li>
                        <li>
                            <label className="info-bag--label">Số điện thoại</label>
                            <span className="info-bag--value">09869849584</span>
                        </li>

                    </ul>
                </div>
            </Sider>
        )
    }
}

export default SiderBagPack