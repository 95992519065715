import { connect } from 'react-redux';

import { updateTransportStatus } from './../../ChangePackageTransportStatus/actions';

import PackageInfomation from './components';

const mapDispatchToProps = {
    updateTransportStatus
};

export default connect(
    undefined,
    mapDispatchToProps
)(PackageInfomation);
