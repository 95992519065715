import React from 'react';
import authService from '../authService';
import Page403 from '../../Common/components/403';

const authorize = (payload, next, permissions, authorizer) => {
    if (!authorizer(permissions)) {
        payload.response.render(<Page403/>);
        return;
    }

    next(payload);
};

const can = (permissions) => (payload, next) => authorize(payload, next, permissions, authService.can.bind(authService));
const canAny = (permissions) => (payload, next) => authorize(payload, next, permissions, authService.canAny.bind(authService));

export {
    can,
    canAny,
};

export default authorize;