import React, {Component} from 'react';
import {Layout} from 'antd';
import lodash, { filter, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import Background from './../../../../resources/images/bar-code.png';
import Link from "../../../../system/routing/Link";
import TablePackage from "./TablePackage";
import TablePackageLastmile from './TablePackageLastmile';


const {Content} = Layout;

class ListBagPackage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: 'down'
        }
    }

    sortBy(type) {
        this.setState({sort: type});
    }

    /**
     * get bag packing data
     * @param packingData
     */
    getBagPackings(packingData) {
        if (packingData.length) {
            if (this.state.sort === 'up') {
                packingData = lodash.orderBy(packingData, ['time_scan'], ['asc']);
            } else {
                packingData = lodash.orderBy(packingData, ['time_scan'], ['desc']);
            }
        } else {
            return [];
        }

        return packingData;
    }

    render() {
        let {t, scroll, packingData, loading, bag, canDelete, page, typeScan} = this.props;

        const packingDataStandard = filter(packingData, item=>isEmpty(item.bag_last_mile) && item.packing_barcode_type !== "bag_last_mile_freight_bill")

        let bag_packings = this.getBagPackings(packingDataStandard);
        let canPacking = !!lodash.get(bag, 'permissions.can_packing');

        const packingLastmile = filter(packingData, item=>!isEmpty(item.bag_last_mile))
      
        return (
            <React.Fragment>
                {packingData.length === 0 && !loading ?
                    <Content className="a-content a-content--package a-package__scan-empty">
                        <img src={Background} alt=""/>
                        <span>Chưa có dữ liệu <br/>quét mã</span>
                    </Content>
                    :
                    <Content className={"a-content a-barcode-scan aflex-1 gflex gflex-direction-column " + (scroll ? 'scroll-is-sider' : '')}>
                        <div className="a-content__top">
                            <div className="a-content__top--page-list--title">
                                <h3>{t('bag:packing.package_list')} 
                                
                                ({ typeScan ==="package_code" ? 
                                     packingDataStandard.length : packingLastmile.length
                                })
                                </h3>
                            </div>
                            <div className="a-content--page-list__top-action" id="content-list">
                                {canPacking && page !== 'packing' ? (
                                    <Link to={"bags.packing"}
                                          params={{id: bag.id}}
                                          className="a-btn a-btn--link-primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                                    >{t("bag:label.scan_package_in_bag")}
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                        {!isEmpty(packingDataStandard) && <TablePackage
                            page={page}
                            bag={this.props.bag}
                            scroll={scroll}
                            canDelete={canDelete}
                            loading={this.props.loading}
                            packingData={packingDataStandard}
                        />}
                        {!isEmpty(packingLastmile) && <TablePackageLastmile
                                page={page}
                                bag={this.props.bag}
                                scroll={scroll}
                                loading={this.props.loading}
                                packingLastmile={packingLastmile}
                                getBagPackingDetail={this.props.getBagPackingDetail}
                            />}
                    </Content>
                }
            </React.Fragment>
        );
    }
}

ListBagPackage.defaultProps = {
    bag: {},
    packingData: [],
    loading: false,
    scroll: false,
    canDelete: true,
    page: "",
};

ListBagPackage.propTypes = {
    page: PropTypes.string,
    bag: PropTypes.object,
    packingData: PropTypes.array,
    loading: PropTypes.bool,
    scroll: PropTypes.bool,
    canDelete: PropTypes.bool,
};

export default withTranslation()(ListBagPackage);
