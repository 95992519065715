import { Spin, Tabs } from 'antd'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { t } from '../../../system/i18n'
import EmptyPage from './components/EmptyPage'
import WaitingDeliveryContent from './components/WaitingDeliveryContent'
import { DELIVERY_STATUS } from './constanst'

const { TabPane } = Tabs

const TabContainer = ({ filters, onSearch, pagination, loading, data }) => {
    const onChangeTabs = tab => {
        onSearch({
            ...filters,
            status: tab,
            per_page: undefined,
            page:undefined
        })
    }
    return (
        <Spin spinning={loading}>
            <Tabs
                activeKey={get(filters, 'status', undefined)}
                className="a-tabs a-tabs--delivery-request"
                animated={false}
                onChange={onChangeTabs}>
                {Object.keys(DELIVERY_STATUS).map(tab => {
                    return (
                        <TabPane
                            tab={t(`package:waiting_delivery.tab.${tab}`)}
                            key={tab}>
                            {isEmpty(data) ? (
                                <EmptyPage />
                            ) : (
                                <WaitingDeliveryContent
                                    filters={filters}
                                    loading={loading}
                                    data={data}
                                    pagination={pagination}
                                    onSearch={onSearch}
                                />
                            )}
                        </TabPane>
                    )
                })}
            </Tabs>
        </Spin>
    )
}

export default TabContainer
