import React, { Component } from 'react'
import { Button, Icon, Modal, notification } from 'antd'
import lodash, { omit, isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'

import apiService from './../../../apiService'
import authService from './../../../../Auth/authService'
import permissions from './../../../../Auth/permissions'

import images from './../../../../../resources/images'
import { t } from '../../../../../system/i18n'

class PrintBagStampButton extends Component {
    state = {
        loading: false,
        visible: false,
        dataPrint: {},
    }

    printStamp = async () => {
        const { bagId, printBagStamp, numberStamp } = this.props

        this.setState({
            loading: true,
        })

        try {
            const response = await apiService.printBarcode(bagId)
            const { data } = response
            if (!isEmpty(data)) {
                const countPrintBarcode = lodash.get(data, 'bag.count_print_barcode')
                const allowPrintBarcode = lodash.get(data, 'bag.allow_print_barcode', false)
                const allowPrintBarcodeCode = lodash.get(data, 'bag.allow_print_barcode_code', "")
                if (allowPrintBarcode) {
                    if (countPrintBarcode) {
                        this.setState({ visible: true, dataPrint: {...data, numberStamp} })
                    } else {
                        printBagStamp({...data, numberStamp})
                    }
                } else {
                    if (allowPrintBarcodeCode) {
                        notification.error({
                            message: t(`bag:message.${allowPrintBarcodeCode}`),
                        })
                    }else {
                        notification.error({
                            message: t(`bag:message.cannot_print`),
                        })
                    }
                }
            } else {
                notification.error({
                    message: t('package:message.no_data_print'),
                })
            }
        } catch (e) {}

        this.setState({
            loading: false,
        })
    }

    render() {
        const { loading, visible, dataPrint } = this.state
        const { t, printBagStamp, ...props } = this.props

        return authService.can(permissions.BAG_PRINT_BARCODE) ? (
            <>
                <img
                    alt=""
                    id={'logo-shippo'}
                    className="mt-8 a-hidden"
                    height="61"
                    src={images.shippo.src}
                    width="160"
                />
                <Button
                    {...omit(props, ['bag'])}
                    loading={loading}
                    onClick={this.printStamp}>
                    {t('bag:label.print_stamp_fuc')}
                </Button>
                {visible && (
                    <Modal
                        title={
                            <div className="galign-center">
                                <Icon
                                    type="question-circle"
                                    theme="twoTone"
                                    twoToneColor="#faad14"
                                    width="1.5em"
                                    height="1.5em"
                                />{' '}
                                {t('bag:title.print_confirm')}
                            </div>
                        }
                        visible={visible}
                        closable={false}
                        onCancel={() => {
                            this.setState({ visible: false, dataPrint: {} })
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    this.setState({ visible: false, dataPrint: {} })
                                }}>
                                {t('btn.cancel')}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                onClick={() => {
                                    printBagStamp(dataPrint)
                                    this.setState({
                                        visible: false,
                                        dataPrint: {},
                                    })
                                }}>
                                {t('btn.ok')}
                            </Button>,
                        ]}>
                        {t('bag:label.printed_confirm')}
                    </Modal>
                )}
            </>
        ) : null
    }
}

export default withTranslation()(PrintBagStampButton)
