import { combineReducers } from 'redux';

import list from './List/reducer';
import create from './Create/reducer';
import detail from './Detail/reducer';
import edit from './Edit/reducer';

export default combineReducers({
    list,
    create,
    detail,
    edit
});
