import {combineReducers} from 'redux';
import * as lodash from 'lodash';

import * as ACTION from './constants';
import listCustomer from './ListCustomer/reducer';
import listOrder from './ListOrder/reducer';
import listPackage from './ListPackage/reducer';

const list = (state = [], action) => {
    switch (action.type) {
        case ACTION.GET_NOTES.SUCCESS: {
            return lodash.get(action, 'payload.notes', []);
        }
        case ACTION.GET_NOTES.FAILED: {
            return [];
        }
        case ACTION.ADD_NOTE.SUCCESS: {
            const { payload } = action;

            return [
                payload,
                ...state
            ];
        }
        case ACTION.UPDATE_NOTE.SUCCESS: {
            const { payload } = action;

            const index = state.findIndex(note => lodash.get(note, 'note.id') === lodash.get(payload, 'note.id'));

            if (index === -1) {
                return state;
            }

            return [
                ...state.slice(0, index),
                payload,
                ...state.slice(index + 1)
            ];
        }
        case ACTION.RESET_NOTES: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.GET_NOTES.SUCCESS: {
            return action.payload.pagination;
        }
        case ACTION.GET_NOTES.FAILED: {
            return {
                total: 0
            };
        }
        case ACTION.ADD_NOTE.SUCCESS: {
            return {
                ...state,
                total: state.total + 1
            };
        }
        case ACTION.RESET_NOTES: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.GET_NOTES.REQUEST:
        case ACTION.FETCH_PACKAGE_DETAIL.REQUEST:
            return true;
        case ACTION.RESET_NOTES:
        case ACTION.GET_NOTES.SUCCESS:
        case ACTION.GET_NOTES.FAILED:
        case ACTION.FETCH_PACKAGE_DETAIL.SUCCESS:
        case ACTION.FETCH_PACKAGE_DETAIL.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    list,
    pagination,
    loading,
    listCustomer,
    listPackage,
    listOrder
});
