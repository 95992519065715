import { Col, Row } from 'antd'
import { get, map, range } from 'lodash'
import numeral from 'numeral'
import React from 'react'
import Barcode from 'react-barcode'
import { getVar } from '../../../../system/support/helpers'

const TemplateStamp = ({ packageData, classPrint, numberStamp, warehouseCode, showAlias }) => {
    const { properties = [], services = [], warehouse_services = [] } = packageData
    const propertyAliases = map(
        properties.filter(property => property.alias),
        'alias'
    )
    const serviceAliases = map(
        services.filter(service => service.alias),
        'alias'
    )
    const warehouseServiceAliases = map(
        warehouse_services.filter(warehouse_service => warehouse_service.alias),
        'alias'
    )
    const volume = get(packageData, 'package.volume', 0)
    const satisfiedEcommerce = propertyAliases.includes('X')
    const canPrintOfficialStamp = get(packageData, 'permissions.can_print_official_stamp')
    const aliases = (satisfiedEcommerce && (canPrintOfficialStamp || operatePackage) ? propertyAliases.filter(alias => alias !== 'X') : propertyAliases)
        .concat(serviceAliases)
        .concat(warehouseServiceAliases)
    const productGroupCode = get(packageData, 'product_group.code')
    const originPackage = get(packageData, 'origin_package', {})
    const operatePackage = get(packageData, 'operate_package', {})

    const package_stt = getVar(packageData, 'package_stt', 0)
    const estimate_package_total = getVar(packageData, 'estimate_package_total', 0)
    return range(numberStamp).map((item, index) => (
        <div key={index}>
            {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
            <div
                className={`a-stamp ${classPrint}`}
                style={{ width: 320 }}>
                <div id="a-stamp--print">
                    <Row className="a-stamp--print--top">
                        <Col
                            span={12}
                            className="mr-4">
                            <Row className="a-stamp--warehouse a-flex gjustify-space-between">
                                <Col span={8}>
                                    <span className="a-text--nowrap">{get(originPackage, 'destination_warehouse.code', '')}</span>
                                </Col>
                                <Col span={16}>
                                    <span className="a-text--bold a-text--nowrap">{get(packageData, 'order.code', '')}</span>
                                </Col>
                            </Row>
                            <div className="a-flex">
                                {/* <Col span={12}> */}
                                <span className="a-text--bold a-text--fz-12 a-text--nowrap mr-4">
                                    {showAlias ? get(originPackage, 'agency.identify_name', '') : get(originPackage, 'agency.code', '')}
                                </span>
                                {/* </Col> */}
                                {/* <Col span={12}> */}
                                <span className="a-text--bold a-text--fz-12 a-text--nowrap">{get(originPackage, 'package.customer_username', '')}</span>
                                {/* </Col> */}
                            </div>
                        </Col>
                        <Col span={12}>
                            <Row className="a-stamp--warehouse a-flex gjustify-space-between">
                                <Col span={8}>
                                    <span className="a-text--nowrap">{get(operatePackage, 'destination_warehouse.code', '')}</span>
                                </Col>
                                <Col span={16}>
                                    <span className="a-text--bold a-text--nowrap">{get(operatePackage, 'order.code', '')}</span>
                                </Col>
                            </Row>
                            <Row className="a-flex gjustify-space-between">
                                <Col span={8}>
                                    <span className="a-text--bold a-text--fz-12 a-text--nowrap">{get(operatePackage, 'agency.code', '')}</span>
                                </Col>
                                <Col span={16}>
                                    <span className="a-text--bold a-text--fz-12 a-text--nowrap">{get(operatePackage, 'package.customer_username', '')}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="a-stamp--barcode">
                        {get(packageData, 'package.code') ? (
                            <Barcode
                                width={2.4}
                                height={50}
                                displayValue={false}
                                value={get(packageData, 'package.code')}
                                copyStyles={true}
                            />
                        ) : null}
                    </div>
                    <div className="a-stamp--print--bottom">
                        <div className="a-stamp--print--block">
                            <div className="a-text--bold a-text--fz-12 a-text--nowrap">
                                <span>{get(packageData, 'package.code')} </span>
                            </div>
                            <div className="weight">
                                <span>
                                    {get(packageData, 'package.weight_net') ? packageData.package.weight_net + 'Kg' : ''}
                                    {get(packageData, 'package.weight_converted') ? ' - QĐ: ' + packageData.package.weight_converted + 'Kg' : ''}
                                </span>
                            </div>
                        </div>

                        <div className="a-stamp--print--block">
                            {/* <div className="bill-code">{packageData.order ? packageData.order.code : ''}</div> */}
                            <div className="mt-1">
                                <span>{aliases.join('-')}</span>
                                {satisfiedEcommerce && !!canPrintOfficialStamp && <span className="ml-2">ONT</span>}
                                {productGroupCode ? <span className="ml-2">{productGroupCode}</span> : null}
                            </div>
                            {volume && volume !== 0 ? (
                                <div className="volumn">
                                    {numeral(volume).format('0.0000') || '--'}
                                    <span className="ml-1">
                                        m<sup>3</sup>
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div className="a-stamp--print--block">
                            <div className="a-stamp--agency"></div>
                            {!!(estimate_package_total && package_stt && estimate_package_total > 0) && (
                                <div className="stt a-text--fz-12">{`${package_stt}/${estimate_package_total}`}</div>
                            )}
                        </div>
                        {/* <p className="a-stamp--agency">{get(packageData, 'agency.name', '--') || '--'}</p> */}
                    </div>
                </div>
            </div>
        </div>
    ))
}

export default TemplateStamp
