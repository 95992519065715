import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { withTranslation } from 'react-i18next'

import apiService from './../../../../../Agencies/apiService'
import authService from './../../../../../Auth/authService'
import permissions from './../../../../../Auth/permissions'

import EditForm from './EditForm'
import Staffs from './Staffs'

const { TabPane } = Tabs

const Agencies = ({ agencies, loading, t, getAgencies }) => {
    const [agencyClicked, setAgencyClicked] = useState()
    useEffect(() => {
        if (authService.can(permissions.CONFIG_AGENCY)) {
            getAgencies()
        }
    }, [getAgencies])

    const handleChange = async (agencyId, field, value) => {
        try {
            await apiService.update(agencyId, {
                [field]: value,
            })
        } catch (error) {}
    }

    if (loading || !authService.can(permissions.CONFIG_AGENCY)) {
        return null
    }

    return (
        <>
            <div className="a-block-config__title--block-child">
                <span className="title">{t('agency:label.list')}</span>
            </div>

            <div className="a-block-info">
                <Tabs onChange={key => setAgencyClicked(key)}>
                    <TabPane
                        className={`_free_agency-tab`}
                        key={0}
                        tab={t('agency:label.free_agency')}>
                        <Staffs
                            agencyClicked={agencyClicked}
                            agencyId={0}
                            agencies={agencies}
                        />
                    </TabPane>
                    {agencies.length > 0 &&
                        agencies.map(agency => (
                            <TabPane
                                className={`_${agency.agency.code}-tab`}
                                key={agency.agency.id}
                                tab={agency.agency.code}>
                                <EditForm
                                    agency={agency.agency}
                                    onChange={handleChange.bind(this, agency.agency.id)}
                                />
                                <Staffs
                                    agencyClicked={agencyClicked}
                                    agencyId={agency.agency.id}
                                    agencies={agencies}
                                />
                            </TabPane>
                        ))}
                </Tabs>
            </div>
        </>
    )
}

export default withTranslation()(Agencies)
