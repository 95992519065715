/* eslint-disable */

import React from 'react';
import {Button, Col, Form, Modal, Row} from 'antd';

class FormSearch extends React.PureComponent {
    state = {visible: false};

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className="a-content--search-header search-crosscheck search-default " id="list-search">
                <Row gutter={24}>
                    <div className="search-crosscheck--title">
                        <span className="title">Thông tin kiểm kê </span>
                        <div className={"a-notification-dot blue animation pl-5"}>
                            <span>Đang đối soát </span>
                        </div>
                    </div>
                    <Form className="a-form">
                        <Col lg={18} xl={21} xxl={21} className="search--left">
                            <Row gutter={{lg: 12, xl: 24, xxl: 24}} className="a-block-info">
                                <Col lg={6} xl={3} xxl={3}
                                     className="info--group">
                                    <label className="info--label">Mã kiểm </label>
                                    <span className="info--value ">KZ9817-6789</span>
                                </Col>
                                <Col lg={6} xl={4} xxl={3}
                                     className="info--group">
                                    <label className="info--label">Người quét </label>
                                    <p className="info--value ">Lê Hoàng Thuỳ Trang </p>
                                    <p className="info--value ">Lê Danh Phương </p>
                                    <p className="info--value ">Lê Hoàng Dương </p>
                                </Col>
                                <Col lg={6} xl={3} xxl={3}
                                     className="info--group">
                                    <label className="info--label">Kho kiểm kê </label>
                                    <span className="info--value ">USNY</span>
                                </Col>
                                <Col lg={6} xl={3} xxl={4}
                                     className="info--group">
                                    <label className="info--label">Thời gian kiểm kê </label>
                                    <span className="info--value ">18:23 29/03/2019</span>
                                </Col>
                                <Col lg={6} xl={3} xxl={4}
                                     className="info--group">
                                    <label className="info--label">Thời gian đối soát </label>
                                    <span className="info--value ">18:23 29/03/2019</span>
                                </Col>
                                <Col lg={6} xl={2} xxl={2}
                                     className="info--group">
                                    <label className="info--label">Số kiện thiếu</label>
                                    <span className="info--value ">200</span>
                                </Col>
                                <Col lg={6} xl={2} xxl={2}
                                     className="info--group">
                                    <label className="info--label">Số kiện thừa </label>
                                    <span className="info--value ">300</span>
                                </Col>
                                <Col lg={6} xl={3} xxl={3}
                                     className="info--group">
                                    <label className="info--label">Số kiện chưa xử lý </label>
                                    <span className="info--value ">300</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={3} xxl={3}>
                            <Button type="primary" htmlType="submit"
                                    className="a-btn a-btn--primary gw-100 a-text--upppercase primary-special"
                                    onClick={this.showModal}>Hoàn thành ĐS</Button>
                        </Col>
                    </Form>
                </Row>
                <Modal
                    footer={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    centered
                    closable={false}
                    bodyStyle={{height: '300px'}}
                >
                    <div className="a-text--center">
                        <img src={require("../../../../../resources/images/LoadingCheckingPackage.png")} alt="Loading"/>
                        <p className="a-text--fz-24  a-text--uppercase a-text--black mt-5">Đối soát</p>
                        <div className="mt-5">
                            <p className="mb-2 a-text--black">Đang tiến hành đối soát <span
                                className="loading-check-package"></span></p>
                            <p className="a-text--black">Quy trình này có thể sẽ mất nhiều thời gian. Đừng tắt trình
                                duyệt của bạn!</p>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default FormSearch;
