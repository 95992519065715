import React from 'react';
import { Form } from 'antd';
import round from 'lodash/round';

import Actions from './RightBottomContent/Actions';
import OrderDetail from './RightBottomContent/OrderDetail';

const RightBottomContent = ({ addingOrder, customer, form, order, printing, selectedOrderIndex, onDelete, onPrint, onSubmit, deliveryNote }) => {
    const handleSubmit = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((errors, values) => {
            if (errors) {
                return;
            }

            const formattedValues = {
                ...values,
                length: round(values.length / 100, 8),
                width: round(values.width / 100, 8),
                height: round(values.height / 100, 8)
            };

            onSubmit(formattedValues);
        });
    };

    return (
        <Form className="height-100 gflex gflex-direction-column" onSubmit={handleSubmit}>
            <OrderDetail
                deliveryNote={deliveryNote}
                customer={customer}
                form={form}
                order={order}
            />
            <Actions
                addingOrder={addingOrder}
                order={order}
                printing={printing}
                selectedOrderIndex={selectedOrderIndex}
                onDelete={onDelete}
                onPrint={onPrint}
            />
        </Form>
    );
};

RightBottomContent.defaultProps = {
    order: {}
};

export default Form.create()(RightBottomContent);
