/* eslint-disable */
import React from 'react';
import {Layout, Row, Col} from "antd";
import FormSeach from "./components/FormSeach";
import Excess from "./components/Excess";
import Deficient from "./components/Deficient";

class CrossCheckContainer extends React.PureComponent {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <Layout className={"gflex gflex-direction-column height-not-header"}>
                <FormSeach/>
                <Row gutter={{lg: 24, xl: 24, xxl: 24}} className={"aheight-100 gflex"}>
                    <Col lg={2} xl={12} xxl={12} className={"aheight-100 gflex gflex-direction-column"}>
                        <Deficient/>
                    </Col>
                    <Col lg={12} xl={12} xxl={12} className={"aheight-100 gflex gflex-direction-column"} >
                        <Excess/>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

export default CrossCheckContainer;