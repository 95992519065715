export const GET_SHIPPING_PARTNER_ID = 'GET_SHIPPING_PARTNER_ID';

export const EDIT_SHIPPING_PARTNER = {
    REQUEST: 'SHIPPING_PARTNER.EDIT.REQUEST',
    SUCCESS: 'SHIPPING_PARTNER.EDIT.SUCCESS',
    FAILED: 'SHIPPING_PARTNER.EDIT.FAILED'
};

export const EDIT_SHIPPING_DOMESTIC_PARTNER = {
    REQUEST: 'SHIPPING_DOMESTIC_PARTNER.EDIT.REQUEST',
    SUCCESS: 'SHIPPING_DOMESTIC_PARTNER.EDIT.SUCCESS',
    FAILED: 'SHIPPING_DOMESTIC_PARTNER.EDIT.FAILED'
};
