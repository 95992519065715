import { notification } from 'antd';

import { ADD_RE_EXECUTION, GET_RE_EXECUTIONS } from './constants';
import apiService from './../apiService';
import { processApiRequest } from './../../Common/saga';

import { t } from './../../../system/i18n';

export function* getReExecutions(action) {
    yield processApiRequest(GET_RE_EXECUTIONS, () => apiService.reExecutions(action.payload), action.payload);
}

export function* addReExecution(action) {
    yield processApiRequest(ADD_RE_EXECUTION, () => apiService.addReExecution(action.payload), action.payload);
}

export function* successAddReExecution() {
    yield notification.success({
        message: t('shipping_fee:add_re_execution.success')
    });
}

export function* failedAddReExecution() {
    yield notification.error({
        message: t('shipping_fee:add_re_execution.failed')
    });
}
