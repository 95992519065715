import { connect } from 'react-redux';

import * as actions from './actions';
import * as selectors from './selectors';

import DeliveryNoteBarcodes from './components/ListDeliveryNoteBarcode';

const mapStateToProps = state => {
    return {
        barcodes: selectors.getBarcodes(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeBarcode: (code) => dispatch(actions.removeBarcode(code)),
        clearState: () => dispatch(actions.clearState()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryNoteBarcodes);
