import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';

import { DELIVERY_NOTE_STATUS } from './../../constants';

const PlaceDeliveryNote = ({ className, deliveryNote, placing, t, onPlace }) => {
    const status = get(deliveryNote, 'delivery_note.status');

    return (
        <Button
            className={clsx(className, '_btn-place')}
            loading={placing}
            onClick={onPlace}
        >
            {[DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(status) ? t(`delivery_note:status.${status}`) : t('delivery_note:place.title')}
        </Button>
    );
};

export default PlaceDeliveryNote;
