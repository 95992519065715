import { createAction } from 'redux-actions';

import * as ACTION from './constants';

export const fetchBagDetail = createAction(ACTION.FETCH_BAG_DETAIL.REQUEST, (id) => ({id}));
export const detachPackage = createAction(ACTION.DETACH_PACKAGE.REQUEST, (bagId, packageId) => ({bagId, packageId}));
export const clearState = createAction(ACTION.CLEAR_STATE);
export const createSimilarBag = createAction(ACTION.CREATE_SIMILAR_BAG.REQUEST, (id) => ({id}));
export const togglePopupCreateTemplateBag = createAction(ACTION.TOGGLE_POPUP_CREATE_TEMPLATE_BAG, (id) => ({id}));
export const createTemplateBag = createAction(ACTION.CREATE_TEMPLATE_BAG.REQUEST, (payload) => ({...payload}));
export const updateBag = createAction(ACTION.UPDATE_BAG.REQUEST, (id, data, onCallback) => ({id, data, onCallback}));
export const exportBag = createAction(ACTION.EXPORT_BAG.REQUEST, (params) => params);
export const setBagDetail = createAction(ACTION.SET_BAG_DETAIL);
