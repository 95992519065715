import React, { Component } from 'react';
import { notification } from 'antd';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import apiService from '../../apiService';
import { downloadBlobData } from '../../../../system/support/helpers';

import UploadForm from './NewPackagesTab/UploadForm';
import UploadResultTable from './NewPackagesTab/UploadResultTable';

class NewPackagesTab extends Component {
    state = {
        downloadingCreatePackageTemplate: false,
        uploadingCreatePackage: false,
        uploadResult: undefined,
        cacheKey: undefined,
        hasError: false,
        loading: undefined
    };

    downloadCreatePackageTemplate = async () => {
        const { t } = this.props;

        this.setState({
            downloadingCreatePackageTemplate: true
        });

        try {
            const response = await apiService.getTemplateUploadCreatePackage();

            downloadBlobData(response.data, 'create-package-template', 'xlsx');
        } catch (error) {
            notification.error({
                message: t('package:download_sample_file.failed')
            });
        }

        this.setState({
            downloadingCreatePackageTemplate: false
        });
    };

    beforeUpload = (form, event) => {
        event.preventDefault();
        const { t } = this.props;
        this.handleCancel();
        this.setState({
            uploadingCreatePackage: true,
        });
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            const data = new FormData()
            data.append("file", values.file.file)
            data.append("id_warehouse_input", get(values, "id_warehouse_input"))

            try {
                const response = await apiService.validateUploadCreatePackage(data);
                
                const errors = get(response, "data.errors", []);
                const cacheKey = get(response, "data.cache_key", "")
                if (isEmpty(errors)) {
                    this.setState({
                        cacheKey
                    }, () => {
                        this.uploadCreatePackage(form, true, event)
                    })
                    
                } else {
                    this.setState({
                        uploadResult: errors
                    });
                    this.setState({
                        cacheKey,
                        hasError: true
                    })
                }
                
            } catch (error) {
                notification.error({message: t('message.server_error') })
            }
        })
        this.setState({
            uploadingCreatePackage: false,
        });
    } 

    uploadCreatePackage = (form, create_with_errors = false, event) => {
        event.preventDefault();

        form.validateFields((error, values) => {
            if (error) {
                return;
            }
            const {cacheKey} = this.state;

            this.doUploadCreatePackage({
                id_warehouse_input: get(values, "id_warehouse_input") ,
                create_with_errors,
                cache_key: cacheKey
            });
        });
    };

    doUploadCreatePackage = async data => {
        const { t } = this.props;

        this.setState({
            uploadingCreatePackage: true,
            uploadResult: undefined
        });

        try {
            const response = await apiService.uploadCreatePackage(data);

            this.setState({
                uploadResult: response.data
            });
        } catch (error) {
            if (get(error, 'response.data.data.warehouse') === 'not_is_staff') {
                notification.error({
                    message: t('message.warehouse_not_is_staff')
                });
            } else {
                notification.error({
                    message: t('package:upload_file.failed')
                });
            }
        }
        this.handleCancel()
       
    };

    handleCancel = () => {
        this.setState({
            uploadingCreatePackage: false,
            loading: undefined,
            hasError: false,
            cacheKey: undefined
        });
    }

    render() {
        const { t } = this.props;
        const { downloadingCreatePackageTemplate, uploadingCreatePackage, uploadResult, hasError, loading} = this.state;

        return (
            <>
                <UploadForm
                    downloadingCreatePackageTemplate={downloadingCreatePackageTemplate}
                    uploadingCreatePackage={uploadingCreatePackage}
                    onDownloadCreatePackageTemplate={this.downloadCreatePackageTemplate}
                    onUploadCreatePackage={this.uploadCreatePackage}
                    beforeUpload={this.beforeUpload}
                    hasError={hasError}
                    loading={loading}
                    handleUpdateState={this.handleCancel}
                />

                {uploadResult ? (
                    <UploadResultTable
                        uploadResult={uploadResult}
                    />
                ) : null}
            </>
        );
    }
}

export default withTranslation()(NewPackagesTab);
