import { Layout, Tabs } from 'antd'
import { get, join, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../../system/i18n'
import BagPackingAction from './BagPackingAction'

const { Sider } = Layout
const { TabPane } = Tabs

const SiderBagPack = ({ bag, packages, loading, idBag, setBag, fetchDetailBag }) => {
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (window.pageYOffset > 104) {
            if (!scroll) {
                setScroll(true)
            }
        } else {
            if (scroll) {
                setScroll(false)
            }
        }
    }

    return (
        <Sider className={'a-sider-sub a-sider-sub--radius bag-pack _slider_bag_packing ' + (scroll ? 'scroll' : '')}>
            <Tabs
                className="height-100"
                defaultActiveKey="FormPackingBag">
                <TabPane
                    tab={t('bag:packing.form_create_packing')}
                    key="FormPackingBag">
                    <div className="bag-pack-detail scroll-small">
                        <div className="bag-pack--block">
                            <span className="title">{t('bag:label.bag')}</span>
                            <p className="name-bag _bag_code a-text--bold">{bag.code}</p>
                        </div>
                        <BagPackingAction
                            bag={bag}
                            loading={loading}
                            packages={packages}
                            idBag={idBag}
                            setBag={setBag}
                            fetchDetailBag={fetchDetailBag}
                        />
                        <div className="info-bag" >
                            <div className="title">
                                <span>{t('bag:label.bag_info')}</span>
                            </div>
                            <ul>
                                <li>
                                    <label className={`info-bag--label `}>{t('bag:label.id_customer')}</label>

                                    <span className={`_static_bag_customer info-bag--value `}>{get(bag, 'customer.username', '--')}</span>
                                </li>
                                <li>
                                    <label className="info-bag--label">{t('bag:label.dvvc_bag_lastmile')}</label>
                                    <span className="info-bag--value _static_bag_dvvc_bag_lastmile">{get(bag, 'lastmile_shipping_partner.code', '--')}</span>
                                </li>
                                <li>
                                    <label className="info-bag--label">{t('Agency')}</label>
                                    <span className="info-bag--value _static_bag_agency">{get(bag, 'agency.name', '--')}</span>
                                </li>
                                <li>
                                    <label className="info-bag--label">{t('bag:label.weight_package')}</label>
                                    <span className="info-bag--value _static_bag_total_package_weight">{get(bag, 'weight_package', '--')} (Kg)</span>
                                </li>
                                <li>
                                    <label className="info-bag--label">{t('bag:label.properties')}</label>
                                    <span className="info-bag--value _static_bag_properties">{join(map(get(bag, 'properties', []), 'name'), ', ')}</span>
                                </li>

                                <li>
                                    <label className="info-bag--label">{t('delivery_request:label.warehouse')}</label>
                                    <span className="info-bag--value _static_bag_id_warehouse_input">{get(bag, 'warehouse.name', '--')}</span>
                                </li>

                                <li>
                                    <label className="info-bag--label">{t('delivery_note:label.address')}</label>
                                    <span className="info-bag--value _static_bag_receiver_name">{get(bag, 'receiver_address', '--')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </Sider>
    )
}

export default SiderBagPack
