import React from 'react';
import { Button, Collapse, Icon } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import Link from './../../../../../../../system/routing/Link';

import DeliveryNoteItem from './DeliveryNoteItem/DeliveryNoteItemContainer';

const { Panel } = Collapse;

const DeliveryNoteList = ({
    activeSuggestKeys,
    creatings,
    customerDetailInfos,
    deliveryNotes,
    deliveryNoteForms,
    selectedBagIds,
    selectedPackageIds,
    t,
    warehouseId,
    onChangeCustomerDetailInfo,
    onCreateDeliveryNote,
    onDelete,
    onOpenPackageListByBagPopup,
    onPrint,
    setActiveSuggestKeys,
    creatingWithTrackingNo
}) => {
    if (!deliveryNotes || !deliveryNotes.length) {
        return null;
    }
    return (
        <Collapse activeKey={activeSuggestKeys} className="delivery-note-list" onChange={setActiveSuggestKeys}>
            {deliveryNotes.map(deliveryNote => {
                const canPrint = !!get(deliveryNote, 'permissions.can_print') && get(deliveryNote, 'delivery_note.status') === 'EXPORTED';

                return (
                    <Panel
                        className="bg-primary"
                        header={(
                            <div className="gflex galign-center gjustify-space-between">
                                <div>
                                    {!!get(deliveryNote, 'delivery_note.id') && (
                                        <>
                                            <Link
                                                className="a-text--white"
                                                isNewTab
                                                params={{id: deliveryNote.delivery_note.id}}
                                                to="delivery-notes.customer.detail"
                                            >
                                                {deliveryNote.delivery_note.code}
                                            </Link>
                                            {' - '}
                                        </>
                                    )}
                                    {`${get(deliveryNote, 'customer.username')} - ${get(deliveryNote, 'customer.full_name')}${get(customerDetailInfos[deliveryNote.identity], 'customer.agency.name') ? ` - ${customerDetailInfos[deliveryNote.identity].customer.agency.name}` : ''}`}
                                </div>

                                <div className="gflex galign-center">
                                    {canPrint && (
                                        <Button
                                            className="a-btn a-btn--transparent-white amr-16 _btn-print"
                                            ghost
                                            onClick={onPrint}
                                        >
                                            {t('delivery_note:label.print')}
                                        </Button>
                                    )}

                                    <Button
                                        className="a-text--white p-0 _btn-delete"
                                        type="link"
                                        onClick={onDelete.bind(undefined, deliveryNote.identity)}
                                    >
                                        <Icon type="close" />
                                    </Button>
                                </div>
                            </div>
                        )}
                        key={deliveryNote.identity}
                    >
                        <DeliveryNoteItem
                            bagIds={selectedBagIds[deliveryNote.identity]}
                            creating={creatings[deliveryNote.identity]}
                            deliveryNote={{
                                ...deliveryNote,
                                ...deliveryNoteForms[deliveryNote.identity]
                            }}
                            identity={deliveryNote.identity}
                            packageIds={selectedPackageIds[deliveryNote.identity]}
                            warehouseId={warehouseId}
                            onChangeCustomerDetailInfo={onChangeCustomerDetailInfo}
                            onCreateDeliveryNote={onCreateDeliveryNote}
                            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                            onPrint={onPrint}
                            creatingWithTrackingNo={creatingWithTrackingNo}
                        />
                    </Panel>
                );
            })}
        </Collapse>
    );
};

export default withTranslation()(DeliveryNoteList);
