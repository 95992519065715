import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE.REQUEST, saga.packingBagLastmile);
    yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE.FAILED, saga.packingBagLastmileFailed);
    yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE.SUCCESS, saga.packingBagLastmileSuccess);
    yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE.SUCCESS, saga.packingBagLastmileDetailPackingSuccess);

    yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE_DETAIL.REQUEST, saga.packingBagLastmileDetail);
    // yield takeLatest(ACTION.PACKING_PACKAGE_LASTMILE.SUCCESS, saga.packingBagLastmileDetail);
}
