import React, { Component } from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import moment from 'moment';

import { BARCODE_TYPE } from '../../../../../Barcode/constants';
import { getVar } from './../../../../../../system/support/helpers';

import ReadyForDeliveryItem from './ReadyForDeliveryItem';

class readyForDeliveries extends Component {
    state = {
        scrolltable: false,
        sortedReadyForDeliveries: []
    };

    countSuccessBarcode = () => {
        const { readyForDeliveries } = this.props;

        return (readyForDeliveries && readyForDeliveries.length) || 0;
    };

    onScrollTable = (e) => {
        let element = e.target;
        if (element.scrollWidth - 1 - element.scrollLeft === element.clientWidth) {
            this.setState({scrolltable: false});
        } else {
            this.setState({scrolltable: true});
        }
    };

    sortReadyForDeliveries = readyForDeliveries => {
        const sortedReadyForDeliveries = readyForDeliveries ? readyForDeliveries.slice() : [];

        sortedReadyForDeliveries.sort((a, b) => {
            if (a.type === BARCODE_TYPE.PACKAGE && b.type === BARCODE_TYPE.BAG) {
                return -1;
            }

            if (b.type === BARCODE_TYPE.PACKAGE && a.type === BARCODE_TYPE.BAG) {
                return 1;
            }

            let compareValue1, compareValue2;

            if (a.type === BARCODE_TYPE.PACKAGE) {
                compareValue1 = moment(getVar(a, 'package.ready_for_delivery_at'));
                compareValue2 = moment(getVar(b, 'package.ready_for_delivery_at'));
            }

            if (a.type === BARCODE_TYPE.BAG) {
                compareValue1 = moment(getVar(a, 'bag.completed_at'));
                compareValue2 = moment(getVar(b, 'bag.completed_at'));
            }

            if (compareValue1.isAfter(compareValue2)) {
                return -1;
            }

            if (compareValue1.isBefore(compareValue2)) {
                return 1;
            }

            return 0;
        });

        this.setState({
            sortedReadyForDeliveries
        });
    };

    componentDidMount() {
        const { readyForDeliveries } = this.props;

        this.sortReadyForDeliveries(readyForDeliveries);
    }

    componentDidUpdate(prevProps) {
        const { readyForDeliveries } = this.props;

        if (!isEqual(readyForDeliveries, prevProps.readyForDeliveries)) {
            this.sortReadyForDeliveries(readyForDeliveries);
        }
    }

    renderTable = () => {
        const { scrolltable, sortedReadyForDeliveries } = this.state;
        const { canUpdate, t, onOpenPackageListByBagPopup, onRemove, showDeliveryRequestDetailModal, agency} = this.props;

        if (!sortedReadyForDeliveries || !sortedReadyForDeliveries.length) {
            return (
                <div className="content-list--empty">
                    <img src={require('./../../../../../../resources/images/boxempty.png')} alt="" />
                    <span className="info a-text--uppercase">CHƯA CÓ MÃ QUÉT NÀO </span>
                </div>
            );
        }

        return (
            <div className="a-table--responsive" onScroll={this.onScrollTable}>
                <table className="a-table a-table--isdata--warning-error">
                    <thead>
                        <tr>
                            <th>{t('delivery_note:label.id_export')}</th>
                            <th>{t('delivery_note:label.code_delivery_request')}</th>
                            <th>{t('delivery_note:label.id_order')}</th>
                            <th>
                                {t('delivery_note:label.weight')} <span>/ {t('delivery_note:label.size')} </span>
                            </th>
                            <th className={clsx('sticky-right', {
                                'box-shadow': scrolltable
                            })} />
                        </tr>
                    </thead>
                    {sortedReadyForDeliveries.map(readyForDelivery => {
                        const { type } = readyForDelivery;
                        let key;
                        let code;

                        if (type === BARCODE_TYPE.BAG) {
                            code = getVar(readyForDelivery, 'bag.code');
                            key = `bag_${code}`;
                        } else if (type === BARCODE_TYPE.PACKAGE) {
                            code = getVar(readyForDelivery, 'package.code');
                            key = `package_${code}`;
                        }

                        return (
                            <ReadyForDeliveryItem
                                canUpdate={canUpdate}
                                key={key}
                                readyForDelivery={readyForDelivery}
                                scrollTable={scrolltable}
                                onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                                onRemove={onRemove}
                                showDeliveryRequestDetailModal={showDeliveryRequestDetailModal}
                                agency={agency}
                            />
                        );
                    })}
                </table>
            </div>
        );
    };

    render() {
        const { t } = this.props;

        return (
            <div className="a-bgwhite aheight-100 ready-for-deliveries">
                <div className="apy-16 border-bottom">
                    <div className="a-content--page-list__title">
                        <h3>{t('delivery_note:ready_for_delivery_list')} ({this.countSuccessBarcode()})</h3>
                    </div>
                </div>
                <div className="apy-16">
                    {this.renderTable()}
                </div>
            </div>
        );
    }
}

export default readyForDeliveries;
