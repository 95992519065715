import { Button, Layout, Spin } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setMenuActive } from '../../Common/actions'
import pageService from '../../Common/Page/pageService'
import Sound from '../../Sound'
import apiService from './apiService'
import ListBagPacking from './components/ListBagPacking'
import SiderBagPack from './components/SiderBagPack'
import * as actions from './actions'

const mapStateToProps = state => {
    return {
        detailBagLastmile: get(state, 'bag.bagPackingLastmileAction.detailBagLastmile', {}),
        loadingDetail: get(state, 'bag.bagPackingLastmileAction.loadingDetail', false),
    }
}

const mapDispatchToProps = dispatch => ({
    packingBagLastmile: id => dispatch(actions.packingPackageLasmileDetail({ id })),
    setMenuActive,
})

const BagPackingLastmileContainer = ({ t, setMenuActive, match, packingBagLastmile, detailBagLastmile, loadingDetail }) => {
    const [bag, setBag] = useState({})
    const [packages, setPackages] = useState([])
    const { id } = match.params
    

    useEffect(() => {
        pageService.setTitle(t('bag:label.packing_bag_lastmile'), <Sound />)
    }, [])

    useEffect(() => {
        packingBagLastmile(id)
    }, [])

    useEffect(() => {
        setBag(get(detailBagLastmile, 'bag_lastmile', []))
        setPackages(get(detailBagLastmile, 'packages', []))
    }, [detailBagLastmile])

    // const fetchDetailBag = () => {
    //     // setLoading(true)

    //     // apiService
    //     //     .getBagLastmileDetail(id)
    //     //     .then(res => {
    //     //         setBag(get(res, 'data.bag_lastmile', []))
    //     //         setPackages(get(res, 'data.packages', []))
    //     //     })
    //     //     .catch(() => {})
    //     //     .finally(() => {
    //     //         setLoading(false)
    //     //     })
    // }


    return (
        <Spin spinning={loadingDetail}>
            <Layout className={'_screen_bag_packing'}>
                <SiderBagPack
                    bag={bag}
                    loading={loadingDetail}
                    packages={packages}
                    idBag={id}
                    setBag={setBag}
                    fetchDetailBag={packingBagLastmile}
                />
                <ListBagPacking
                    packages={packages}
                    loading={loadingDetail}
                    bag={bag}
                    idBag={id}
                    fetchDetailBag={packingBagLastmile}
                />
            </Layout>
        </Spin>
    )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BagPackingLastmileContainer))
