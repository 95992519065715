import { Button, Col, Form, Input, InputNumber, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import pageService from '../../Common/Page/pageService'
import SelectCustomerContainer from '../../Customer/SelectCustomer/SelectCustomerContainer'
import FormPropertiesContainer from '../../Property/FormProperties/FormPropertiesContainer'
import { setMenuActive } from '../../Common/actions'
import { get } from 'lodash'
import SelectShippingLastmile from '../components/SelectShippingLastmile'
import ChooseWarehouse from '../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'
import PopupSelectTemplateBag from '../components/PopupSelectTemplateBag'
import SelectAgency from '../components/SelectAgency'
import apiService from '../apiService'
import { router } from '../../../system/routing'

const mapStateToProps = state => {
    return {}
}

const CreateBagLastmile = ({ form, t, setMenuActive }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [templateBag, setTemplateBag] = useState({})

    const { getFieldDecorator } = form

    useEffect(() => {
        pageService.setTitle(t('bag:lastmile.title.create'))
        setMenuActive('bags-lastmile/create')
    }, [])

    const onChangeSelectValue = (name, value) => {
        if (name === 'id_customer') {
            form.setFieldsValue({
                [name]: get(value, 'customer.id'),
            })
            setTemplateBag({ ...templateBag, [name]: get(value, 'customer.id') })
        } else {
            form.setFieldsValue({
                [name]: value,
            })
            setTemplateBag({ ...templateBag, [name]: value })
        }
    }

    const onSave = () => {
        form.validateFields((error, values) => {
            if (error) {
                return
            }
            const data = {
                ...values,
                width_max: get(values, 'width_max') ? get(values, 'width_max') / 100 : undefined,
                height_max: get(values, 'height_max') ? get(values, 'height_max') / 100 : undefined,
                length_max: get(values, 'length_max') ? get(values, 'length_max') / 100 : undefined,
                weight_max: get(values, 'weight_max') ? get(values, 'weight_max') / 100 : undefined,
            }

            apiService
                .createLastmileBag({ ...data })
                .then(res => {
                    const id = get(res, 'data.bag_lastmile.id')
                    notification.success({ message: t('bag:lastmile.message.create_bag_success') })
                    router.redirect(`/bags/packing-lastmile/${id}`)
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data', {})

                    if (code === 'INPUT_INVALID') {
                        Object.entries(data).forEach(([key, value]) => {
                            notification.error({
                                message: t(`package:expedited_package.message.${Object.keys(value)[0]}`, {
                                    attribute: t(`bag:label.${key}`),
                                }),
                            })
                        })
                    } else {
                        notification.error({ message: t('bag:lastmile.message.create_bag_fail') })
                    }
                })
                .finally(() => {})
        })
    }

    const onVisibleModal = () => {
        setIsVisible(true)
    }

    const onHideModal = () => {
        setIsVisible(false)
    }

    const onSelectTemplateBag = template => {
        form.setFieldsValue({
            id_customer: get(template, 'id_customer'),
            id_properties: get(template, 'id_properties'),
            // id_warehouse: get(template, 'id_warehouse'),
            note: get(template, 'note'),
            id_lastmile_shipping_partner: get(template, 'lastmile_shipping_partner.id'),
            id_agency: get(template, 'agency.id'),
        })
        const data = {
            ...template,
            width_max: get(template, 'width_max') ? get(template, 'width_max') * 100 : undefined,
            height_max: get(template, 'height_max') ? get(template, 'height_max') * 100 : undefined,
            length_max: get(template, 'length_max') ? get(template, 'length_max') * 100 : undefined,
            weight_max: get(template, 'weight_max') ? get(template, 'weight_max') * 100 : undefined,
            id_lastmile_shipping_partner: get(template, 'lastmile_shipping_partner.id'),
            id_agency: get(template, 'agency.id'),
        }
        setTemplateBag({ ...data })
        onHideModal()
    }

    return (
        <div>
            <Form
                className="a-block"
                form={form}>
                <div className="block--primary a-content--bag bag-create">
                    <div className="title create-bag">
                        <div className="left">
                            <span className="a-text--bold">{t('bag:new')}</span>
                        </div>

                        <div className="right">
                            <Button
                                className="a-btn a-btn--transparent-white _btn-create-temple-bag"
                                ghost
                                type="button"
                                onClick={onVisibleModal}>
                                {t('bag:label.choose_template_bag')}
                            </Button>
                        </div>
                    </div>
                    <div className="body ">
                        <Row
                            gutter={{ lg: 24, xl: 48 }}
                            className="px-3"
                            type="flex">
                            <Col>
                                <div className="a-block-info pb-0 block-create-bag">
                                    <div className="block-create-bag-title">
                                        <h3>{t('bag:create_bag.info_bag')}</h3>
                                    </div>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="a-form apt-16 mx-0"
                                        type="flex">
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                className="a-form__item"
                                                label={t('bag:label.customer')}
                                                labelCol={{ span: 24 }}>
                                                {getFieldDecorator('id_customer', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('bag:label.customer'),
                                                            }),
                                                        },
                                                    ],
                                                })(<Input hidden />)}
                                                <SelectCustomerContainer
                                                    value={templateBag.id_customer}
                                                    allowClear
                                                    autoSelect
                                                    className="a-select a-select--search-content _id_customer"
                                                    dropdownClassName="_dropdown_id_customer"
                                                    emptyOption={false}
                                                    is_shipper
                                                    placeholder={t('bag:label.customer_placeholder')}
                                                    showName
                                                    dispatchObject={true}
                                                    onChange={value => onChangeSelectValue('id_customer', value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('bag:label.id_lastmile_shipping_partner')}
                                                className="a-form__item">
                                                {getFieldDecorator('id_lastmile_shipping_partner', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('bag:label.id_lastmile_shipping_partner'),
                                                            }),
                                                        },
                                                    ],
                                                })(<SelectShippingLastmile placeholder={t('bag:label.id_lastmile_shipping_partner')} />)}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={'Agency'}
                                                className="a-form__item">
                                                {getFieldDecorator('id_agency', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: 'Agency',
                                                            }),
                                                        },
                                                    ],
                                                })(<SelectAgency placeholder="Agency" />)}
                                            </Form.Item>
                                        </Col>

                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('delivery_request:label.warehouse')}
                                                className="a-form__item">
                                                {getFieldDecorator('id_warehouse', {
                                                    rules: [
                                                        {
                                                            message: t('validation:required', {
                                                                attribute: t('delivery_request:label.warehouse'),
                                                            }),
                                                            required: true,
                                                        },
                                                    ],
                                                })(
                                                    <ChooseWarehouse
                                                        active
                                                        allowClear
                                                        autoSelected={false}
                                                        className="a-select a-select--search-content _id_warehouse"
                                                        dropdownClassName="_dropdown__id_warehouse"
                                                        emptyOption={false}
                                                        isPermission
                                                        isSelect
                                                        onChange={value => onChangeSelectValue('id_warehouse', value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('template_bag:label.height_max')}
                                                className="a-form__item">
                                                {getFieldDecorator('height_max', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('template_bag:label.height_max'),
                                                            }),
                                                        },
                                                    ],
                                                    initialValue: get(templateBag, 'height_max'),
                                                })(
                                                    <InputNumber
                                                        min={1}
                                                        className="gw-100"
                                                        placeholder={t('template_bag:placeholder.height_max')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('template_bag:label.width_max')}
                                                className="a-form__item">
                                                {getFieldDecorator('width_max', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('template_bag:label.width_max'),
                                                            }),
                                                        },
                                                    ],
                                                    initialValue: get(templateBag, 'width_max'),
                                                })(
                                                    <InputNumber
                                                        min={1}
                                                        className="gw-100"
                                                        placeholder={t('template_bag:placeholder.width_max')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('template_bag:label.length_max')}
                                                className="a-form__item">
                                                {getFieldDecorator('length_max', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('template_bag:label.length_max'),
                                                            }),
                                                        },
                                                    ],
                                                    initialValue: get(templateBag, 'length_max'),
                                                })(
                                                    <InputNumber
                                                        min={1}
                                                        className="gw-100"
                                                        placeholder={t('template_bag:placeholder.length_max')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            className="a-form--group"
                                            lg={{ span: 8 }}
                                            xxl={{ span: 6 }}>
                                            <Form.Item
                                                label={t('template_bag:label.weight_max')}
                                                className="a-form__item">
                                                {getFieldDecorator('weight_max', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('validation:required', {
                                                                attribute: t('template_bag:label.weight_max'),
                                                            }),
                                                        },
                                                    ],
                                                    initialValue: get(templateBag, 'weight_max'),
                                                })(
                                                    <InputNumber
                                                        min={1}
                                                        className="gw-100"
                                                        placeholder={t('template_bag:placeholder.weight_max')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="a-form mx-0">
                                        <Col className="a-form--group">
                                            <Form.Item
                                                className="a-form__item"
                                                label={t('bag:label.properties')}
                                                labelCol={{ span: 24 }}>
                                                {getFieldDecorator('id_properties')(
                                                    <FormPropertiesContainer
                                                        classUl="property--package _properties"
                                                        values={form.getFieldValue('id_properties')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={{ lg: 24, xl: 32 }}
                                        className="a-form mx-0">
                                        <Col className="a-form--group">
                                            <Form.Item
                                                className="a-form__item"
                                                label={t('label.note')}
                                                labelCol={{ span: 24 }}>
                                                {getFieldDecorator('note')(
                                                    <Input.TextArea
                                                        placeholder={t('placeholder.note')}
                                                        rows={4}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col
                                className="a-package--action only-action create-bag amt-24 pb-0"
                                lg={{ span: 24 }}>
                                <Button
                                    className="a-btn a-btn--primary _save_bag_packing_btn aml-16"
                                    size="large"
                                    type="primary"
                                    onClick={onSave}>
                                    {t('bag:btn.save_and_close')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                {isVisible && (
                    <PopupSelectTemplateBag
                        onCancel={onHideModal}
                        onSelectTemplateBag={onSelectTemplateBag}
                    />
                )}
            </Form>
        </div>
    )
}

export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(Form.create()(CreateBagLastmile))
)
