import React from 'react';

import ProductItem from './ProductList/ProductItem';

const ProductList = ({ boxSizes, canEdit, form, isOriginPackage, loadingBoxSizes, originPackage, packageIndex, products, onChange }) => (
    <ul className="product-list">
        {products.map((product, index) => (
            <ProductItem
                boxSizes={boxSizes}
                canEdit={canEdit}
                form={form}
                index={index}
                isOriginPackage={isOriginPackage}
                key={product.id}
                loadingBoxSizes={loadingBoxSizes}
                originPackage={originPackage}
                packageIndex={packageIndex}
                product={product}
                onChange={onChange.bind(undefined, index)}
            />
        ))}
    </ul>
);

export default ProductList;
