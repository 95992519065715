/* eslint-disable */
import React from 'react';
import {Col, Row, Icon, Table, Modal, Button} from "antd";

const data = [];
for (let i = 0; i < 15; i++) {
    data.push({
        order: `XYZABC`,
        totalorder: `${i + 2} `,
        totalweight: `${i + 10} (kg) `,
    });
}

function CustomExpandIcon(props) {
    let text;
    if (props.expanded) {
        text = "up"
    } else {
        text = "down"
    }
    return (
        <Icon type={text} className="a-text--gray-70" onClick={e => props.onExpand(props.record, e)}/>

    );
}


const expandedRowRender = () => {
    return (
        <div className="list-package-modal">
            <div className="list-package-modal__title">
                <h2>Mã kiện</h2>
            </div>
            <div className="list-package-modal__item">
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
            </div>
            <div className="list-package-modal__item">
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
            </div>
            <div className="list-package-modal__item">
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
            </div>
            <div className="list-package-modal__item">
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
                <div className="list-package-modal--link">
                    <a href="#">6759804950694</a>
                </div>
            </div>
        </div>
    )
};

const columns = [
    {
        title: 'Đơn hàng',
        dataIndex: 'order',
        key: 'order',
        render: text => <a className="a-text--nowrap a-text--blue">{text}</a>,
    }, {
        title: 'Tổng số kiện',
        dataIndex: 'totalorder',
        key: 'totalorder',
        className: "a-text--nowrap",
    }, {
        title: 'Tổng cân nặng',
        dataIndex: 'totalweight',
        key: 'totalweight',
        render: text => <span>{text}</span>,
    }
];

class InfoDeliveryNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="a-block-info abg-white  amb-16 apt-24 apl-8 apr-8 pb-0">
                    <div className="delivery-note__info-money gflex">
                        <span className="money-title">Số tiền tất toán khi tạo phiếu </span>
                        <a href="#" className="ml-auto more-info" onClick={this.showModal}><Icon type="info"/> Xem thông
                            tin chi tiết</a>
                    </div>
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Tổng</label>
                            <span className="a-text--fz-24 a-text--bold a-text--red">1.500.000</span>
                        </Col>
                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Trừ vào TK</label>
                            <span className="a-text--fz-24 a-text--bold a-text--orange">500.000</span>
                        </Col>
                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Cần thu thêm </label>
                            <span className="a-text--fz-24 a-text--bold a-text--red">1.000.000</span>
                        </Col>
                    </Row>
                </div>
                <div className="a-block-info abg-white  amb-16 apt-24 apl-8 apr-8 pb-0">
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 10}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Tên khách hàng</label>
                            <span className="info--value ">Lê Hoàng Thuỳ Trang (lehoangthuytrang999)</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Số điện thoại </label>
                            <span className="info--value ">033.333.3333</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Người nhận </label>
                            <span className="info--value ">Lê Hoàng Thuỳ Dung</span>
                        </Col>
                    </Row>
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 10}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Người tạo</label>
                            <span className="info--value ">Nguyễn Hoàng Anh(giannan123)</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Thời gian tạo</label>
                            <span className="info--value ">01:10 14/04/2019</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Trạng thái phiếu xuất</label>
                            <span className="info--value ">Đang vận chuyển </span>
                        </Col>
                    </Row>
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 10}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Người xuất</label>
                            <span className="info--value ">Nguyễn Hoàng Anh2(giannan123)</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Thời gian xuất</label>
                            <span className="info--value ">01:10 14/04/2019</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Kho xuât</label>
                            <span className="info--value ">CNGZ</span>
                        </Col>
                    </Row>
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 10}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Thu hộ(VNĐ)</label>
                            <span className="info--value ">1.000.000.000</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Số lượng xuất </label>
                            <span className="info--value ">999</span>
                        </Col>
                        <Col lg={{span: 7}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Tổng cân nặng</label>
                            <span className="info--value ">9981 (kg)</span>
                        </Col>
                    </Row>
                    <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                        <Col lg={{span: 10}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">Số tiền thực thu</label>
                            <span className="info--value ">1.000.000.000</span>
                        </Col>
                        <Col lg={{span: 14}} xl={{span: 16}} xxl={{span: 16}}
                             className="info--group">
                            <label className="info--label">Ghi chú</label>
                            <span className="info--value ">Loremm isump</span>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title={<div className="title-modal "><span
                        className="a-text--uppercase">Thông tin yêu cầu giao</span></div>}
                    visible={this.state.visible}
                    className="a-modal a-modal--package-in-bag request-deliverynote"
                    width={644}
                    footer={null}

                >
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           scroll={{y: 400}}
                           pagination={false}
                           expandedRowRender={expandedRowRender}
                           expandIcon={CustomExpandIcon}
                           expandIconAsCell

                    />
                    <div className="action-modal apl-16 gflex gjustify-space-between">
                        <Button className="a-btn a-btn--button-link a-text--blue">Xoá yêu cầu giao</Button>
                        <Button type="primary" className="a-btn--primary " size={"large"}>
                            Tạo phiếu xuất
                        </Button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default InfoDeliveryNote;