import React from 'react'
import { createSelector } from 'reselect';
import map from 'lodash/map';

import { LIST_STAFF_IN_WAREHOUSE_COLUMN } from '../constants';
import { dateFormatter } from './../../Common/services/format';
import { getVar } from './../../../system/support/helpers';

import RemoveStaffInWarehouse from './../RemoveStaffInWarehouse/RemoveStaffInWarehouseContainer';

export const getState = (state, param = null, defaultValue = null) => getVar(state, `configWarehouse.detailWarehouse${(param ? `.${param}` : '')}`, defaultValue);
export const getListWarehouseStaff = createSelector(
	(state) => getState(state, 'staff', []),
	(warehouses_staffs) => map(warehouses_staffs, warehouse_staff => {
		const { user: staff, creator } = warehouse_staff;
		const created_at = getVar(warehouse_staff, 'warehouse_staff.created_at');

		return {
			key: getVar(staff, 'id'),
			[LIST_STAFF_IN_WAREHOUSE_COLUMN.USERNAME]: (
				<span className="_username">
					{getVar(staff, 'username', '--')}
				</span>
			),
            [LIST_STAFF_IN_WAREHOUSE_COLUMN.NAME]: (
				<span className="_name">
					<span className="_staff-name">{(getVar(staff, 'name', '--'))}</span>
				</span>
			),
			[LIST_STAFF_IN_WAREHOUSE_COLUMN.EMAIL]: getVar(staff, 'email', '--'),
			[LIST_STAFF_IN_WAREHOUSE_COLUMN.PHONE]: getVar(staff, 'phone', '--'),
			[LIST_STAFF_IN_WAREHOUSE_COLUMN.CREATE_AT]: created_at ? dateFormatter.dateTime(created_at) : '--' || '--',
            [LIST_STAFF_IN_WAREHOUSE_COLUMN.CREATOR]: creator ? (
				<span className="_creator">
					<span className="_creator-name">{getVar(creator, 'name', '--')}</span>
					<span className="_creator-username"> {getVar(creator, 'username') ?
						`(${creator.username})` : null}
					</span>
				</span>
			) : '--',
			[LIST_STAFF_IN_WAREHOUSE_COLUMN.ACTION]: (
				<RemoveStaffInWarehouse staff={staff} />
			)
		};
	})
);
