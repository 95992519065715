import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {Empty, Icon, Table} from 'antd';
import {Link} from './../../../system/routing';
import Pagination from './../../Common/components/Pagination';
import {dateFormatter} from '../../Common/services/format';
import {PACKAGE_COLUMN} from './constants';
import ListPackageSetting from "../ListPackage/ListPackageSettingContainer";

class ListPackage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeColumns: [],
            followPackage: false
        };
    }

    getClassNameIdentifier(column) {
        switch (column) {
            case PACKAGE_COLUMN.CODE:
                return 'a-text--nowrap _package_column_code';
            case PACKAGE_COLUMN.ORDER:
                return 'a-text--nowrap _package_column_order';
            default :
                return '_row' + column
        }
    }

    followPackage = () => {
        this.setState({followPackage: !this.state.followPackage});
    };


    getColumns() {
        let arr = [PACKAGE_COLUMN.DELETED_TIME, PACKAGE_COLUMN.CREATE_TIME, PACKAGE_COLUMN.WEIGHT, PACKAGE_COLUMN.VOLUME];
        let activeColumns = this.state.activeColumns;
        return lodash.values(PACKAGE_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    key: column,
                    dataIndex: column,
                    title: column === PACKAGE_COLUMN.FOLLOW ? '' : this.props.t('package:column.' + column),
                    className: this.getClassNameIdentifier(column)
                };
                if (arr.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by
                            ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : null,
                    }
                }


                return res;
            });
    }

    getDataSource() {
        let {t} = this.props;
        return this.props.packages.map(packageData => {
            let delete_reason = lodash.get(packageData, 'delete_reason');
            if (delete_reason === "OTHER") {
                delete_reason = lodash.get(packageData, 'delete_reason_detail')
            } else {
                delete_reason = delete_reason ? t('package:delete_reason.' + lodash.get(packageData, 'delete_reason')) : "--"
            }

            return ({
                key: packageData.id,
                [PACKAGE_COLUMN.CODE]: <span className="a-text--nowrap _code"><Link to="packages.detail"
                                                                                    params={{id: packageData.code}}>{packageData.code}</Link></span>,
                [PACKAGE_COLUMN.BARCODE]: <span
                    className="a-text--nowrap _barcode">{lodash.get(packageData, 'barcode') || '--'}</span>,
                [PACKAGE_COLUMN.BAG_CODE]: (
                    <span className="a-text--nowrap _code-bag">{lodash.get(packageData, 'code_bag') || '--'}</span>
                ),
                [PACKAGE_COLUMN.DELETED_TIME]: <span
                    className="a-text--nowrap _deleted_at">{packageData.deleted_at ? dateFormatter.dateTime(packageData.deleted_at) : '--'}</span>,
                [PACKAGE_COLUMN.CREATE_TIME]: <span
                    className="a-text--nowrap _created_at">{dateFormatter.dateTime(packageData.created_at)}</span>,
                [PACKAGE_COLUMN.ORDER_LINKED_AT]: (
                    <span className="a-text--nowrap _order-linked-at">
                        {lodash.get(packageData, 'order_linked_at') ? dateFormatter.dateTime(packageData.order_linked_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.TRANSPORTING_AT]: (
                    <span className="a-text--nowrap _transporting-at">
                        {lodash.get(packageData, 'transporting_at') ? dateFormatter.dateTime(packageData.transporting_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.READY_FOR_DELIVERY_AT]: (
                    <span className="a-text--nowrap _ready-for-delivery-at">
                        {lodash.get(packageData, 'ready_for_delivery_at') ? dateFormatter.dateTime(packageData.ready_for_delivery_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.DELETE_REASON]: <span className="a-text--nowrap _delete_reason">{delete_reason}</span>,
                [PACKAGE_COLUMN.ORDER]: (
                    <span className="a-text--nowrap _order">
                        {lodash.get(packageData, 'code_order') ? (
                            <a
                                className="info--value a-text--blue"
                                href={lodash.get(packageData, 'm2_url_order') || '#top'}
                                rel="noopener noreferrer"
                                target={lodash.get(packageData, 'm2_url_order') ? '_blank' : '_self'}
                            >
                                {lodash.get(packageData, 'code_order', '--')}
                            </a>
                        ) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.CUSTOMER_USERNAME]: <span
                    className="a-text--nowrap _customer_username">{lodash.get(packageData, 'username_customer', '--') || '--'}</span>,
                [PACKAGE_COLUMN.PRINTED_OFFICIAL_STAMP]: (
                    <span className="a-text--nowrap _printed-official-stamp">
                        {t(`package:printed_official_stamp.${!!lodash.get(packageData, 'is_printed_official_stamp')}`)}
                    </span>
                ),
                [PACKAGE_COLUMN.INPUT_WAREHOUSE]: <span
                    className="a-text--nowrap _warehouse_input">{lodash.get(packageData, 'code_warehouse_input', '--')}</span>,
                [PACKAGE_COLUMN.DESTINATION_WAREHOUSE]:
                    <span
                        className="a-text--nowrap _destination_warehouse">{lodash.get(packageData, 'code_warehouse_destination', '--')}</span>,
                [PACKAGE_COLUMN.WEIGHT]: <span
                    className="a-text--nowrap _weight_net">{packageData.weight_net ? (packageData.weight_net + ' kg') : '--'}</span>,
                [PACKAGE_COLUMN.VOLUME]: <span
                    className="a-text--nowrap _volume">{packageData.volume ? (packageData.volume + ' m3') : '--'}</span>,
                [PACKAGE_COLUMN.DELETED_USERNAME]: <span
                    className="a-text--nowrap _deleted_username">{lodash.get(packageData, 'username_deletor', '--') || '--'}</span>,

            })
        });
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }


    onChange(pagination, filters, sorter) {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            });
        }
    }

    render() {
        const { filter, loading, object, pagination, t } = this.props;

        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title">
                        <h3>{t("package:label.list_package_delete")} ({pagination ? pagination.total : 0})</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <ListPackageSetting
                            onChange={activeColumns => this.setState({activeColumns})}
                            object={object}
                            loading={loading}
                            isOpenListSetting={this.props.isOpenListSetting}
                            onOpenListSetting={this.props.onOpenListSetting}
                            isCurrentPage={this.props.isCurrentPage}
                        />
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <div className="a-table--responsive">
                        <Table
                            className={"a-table--page-list _a-table--page-list"}
                            columns={this.getColumns()}
                            dataSource={this.getDataSource()}
                            pagination={false}
                            loading={loading}
                            rowClassName={(record, index) => index.toString()}
                            onChange={this.onChange.bind(this)} locale={{
                            emptyText: (
                                loading ? <Icon type="loading-3-quarters" className="icon-loading a-text--black "/> :
                                    <Empty
                                        className={"_empty"}
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        description={<span>Không có dữ liệu </span>}
                                    >
                                    </Empty>)
                        }}
                        /></div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        pageSizeOptions={[20, 50, 100, 200]}
                        total={pagination.total}
                        loading={this.props.loading}
                        onChange={this.onChangePage.bind(this)}/>
                </div>
            </React.Fragment>
        )
    }
}

ListPackage.defaultProps = {
    filter: {},
    loading: false,
    packages: [],
    pagination: {},
    onSearch: (filter) => {
    },
};

ListPackage.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    packages: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
};

export default withTranslation()(ListPackage);
