import React from "react"
import {connect} from 'react-redux';
import {setMenuActive} from "../../Common/actions";
import UpdateProperty from "./components";
import {clearState, fetchDetailProperty, updateProperty} from "../actions"
import SearchableComponent from "../../Common/components/SearchableComponent";
import * as selectors from "../selectors"

const mapStateToProps = (state) => ({
	propertyData: selectors.getState(state, 'detail', {}),
	loading: selectors.getState(state, 'loading', false)
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	updateProperty: (params) => dispatch(updateProperty(params)),
	fetchDetailProperty: (params) => dispatch(fetchDetailProperty(params)),
	clearState: () => dispatch(clearState())
});

class UpdatePropertyContainer extends SearchableComponent {
	componentWillUnmount() {
		this.props.clearState();
	}

	componentDidMount() {
		super.componentDidMount();
		this.props.setMenuActive('config/property')
	}

	onChangeFilter(filter) {
		this.props.fetchDetailProperty(filter.id);
	}

	render() {
		return <UpdateProperty {...this.props} />
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdatePropertyContainer);
