import React from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { get } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { formatCurrency } from './../../../../../Common/services/helps';
import { useDeepCompareEffect } from './../../../../../../hooks';

import SelectAgency from './../../../../Agency/SelectAgency';

const { Option } = Select;

const EditModal = ({ form, loading, storageFee, t, visible, onCancel, onOk }) => {
    const { getFieldDecorator } = form;

    const handleCancel = () => {
        onCancel();
    };

    useDeepCompareEffect(() => {
        form.setFieldsValue({
            id: get(storageFee, 'storage_fee.id'),
            id_agency: get(storageFee, 'agency.id'),
            unit_price: get(storageFee, 'storage_fee.unit_price'),
            weight_by: get(storageFee, 'storage_fee.weight_by'),
            applied_at: get(storageFee, 'storage_fee.applied_at') ? moment(storageFee.storage_fee.applied_at) : undefined,
            day_free: get(storageFee, 'storage_fee.day_free')
        });
    }, [storageFee]);

    const handleOk = () => {
        form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }

            const formattedValues = {
                ...values,
                applied_at: values.applied_at.format('YYYY-MM-DD HH:mm:ss')
            };

            onOk(formattedValues);
        });
    };

    return (
        <Modal
            centered
            className="_edit-package-storage-fee-modal"
            title={form.getFieldValue('id') ? t('storage_fee:update.title') : t('storage_fee:add.title')}
            visible={visible}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {t('btn.cancel')}
                </Button>,
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={handleOk}>
                    {t('btn.ok')}
                </Button>
            ]}
            onCancel={handleCancel}
        >
            <Form>
                <Form.Item
                    className="a-hidden"
                    label={t('id')}
                >
                    {getFieldDecorator('id', {
                        defaultValue: get(storageFee, 'storage_fee.id')
                    })(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item
                    label={t('agency')}
                >
                    {getFieldDecorator('id_agency', {
                        defaultValue: get(storageFee, 'agency.id')
                    })(
                        <SelectAgency
                            allowClear
                            className="_id-agency"
                            dropdownClassName="_dropdown-id-agency"
                            placeholder=""
                        />
                    )}
                </Form.Item>

                <Form.Item
                    label={`${t('storage_fee:unit_price_per_kg')} (VNĐ)`}
                >
                    {getFieldDecorator('unit_price', {
                        defaultValue: get(storageFee, 'storageFee.unit_price'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('storage_fee:unit_price_per_kg')
                            })
                        }, {
                            validator: async (rule, value, callback) => {
                                if (value && !Number.isInteger(value)) {
                                    callback(t('validation:integer', {
                                        attribute: t('storage_fee:unit_price_per_kg')
                                    }));
                                } else if (Number.isInteger(value) && value <= 0) {
                                    callback(t('validation:gt', {
                                        attribute: t('storage_fee:unit_price_per_kg'),
                                        value: 0
                                    }));
                                } else {
                                    callback();
                                }
                            }
                        }]
                    })(
                        <InputNumber
                            className="_unit-price width-100-pc"
                            formatter={value => formatCurrency(value)}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    label={t('weight_by')}
                >
                    {getFieldDecorator('weight_by', {
                        defaultValue: get(storageFee, 'storage_fee.weight_by'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('weight_by')
                            })
                        }]
                    })(
                        <Select
                            className="_weight-by"
                            dropdownClassName="_dropdown-weight-by"
                        >
                            {['NET', 'CONVERSION', 'MAX'].map(item => (
                                <Option className="_weight-by-item" key={item} value={item}>
                                    {t(`weight_by_options.${item}`)}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label={t('application_time')}
                >
                    {getFieldDecorator('applied_at', {
                        defaultValue: get(storageFee, 'storage_fee.applied_at'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('application_time')
                            })
                        }]
                    })(
                        <DatePicker
                            className="_applied-at width-100-pc"
                            format="HH:mm:ss DD/MM/YYYY"
                            locale={locale}
                            showTime
                        />
                    )}
                </Form.Item>

                <Form.Item
                    label={t('storage_fee:day_free')}
                >
                    {getFieldDecorator('day_free', {
                        defaultValue: get(storageFee, 'storage_fee.day_free'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('storage_fee:day_free')
                            })
                        }, {
                            validator: async (rule, value, callback) => {
                                if (value && !Number.isInteger(value)) {
                                    callback(t('validation:integer', {
                                        attribute: t('storage_fee:day_free')
                                    }));
                                } else if (Number.isInteger(value) && value < 0) {
                                    callback(t('validation:gte', {
                                        attribute: t('storage_fee:day_free'),
                                        value: 0
                                    }));
                                } else {
                                    callback();
                                }
                            }
                        }]
                    })(
                        <InputNumber className="_day-free width-100-pc" />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default withTranslation()(EditModal);
