export const SCAN_PACKAGE = {
	REQUEST: 'SCAN_PACKAGE.REQUEST',
	SUCCESS: 'SCAN_PACKAGE.SUCCESS',
	FAILED: 'SCAN_PACKAGE.FAILED'
};

export const SCAN_TRACKING_BILL = {
	REQUEST: 'SCAN_TRACKING_BILL.REQUEST',
	SUCCESS: 'SCAN_TRACKING_BILL.SUCCESS',
	FAILED: 'SCAN_TRACKING_BILL.FAILED'
};
