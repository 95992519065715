
export const UPDATE_WEIGHT = {
    REQUEST: 'UPDATE_WEIGHT_PACKAGE.REQUEST',
    SUCCESS: 'UPDATE_WEIGHT_PACKAGE.SUCCESS',
    FAILED: 'UPDATE_WEIGHT_PACKAGE.FAILED',
};


export const BEGIN_UPDATE_WEIGHT = "BEGIN_UPDATE_WEIGHT";
export const END_UPDATE_WEIGHT = "END_UPDATE_WEIGHT";

export const CLEAR_STATE = 'PACKAGE.FORM_PACKAGE_UPDATE_WEIGHT.CLEAR_STATE';
