import React, {Component} from 'react';
import {Button, Form, Input, Modal} from "antd";

class PopupCreateTemplateBag extends Component {

    handleOk = () => {
        const {form, bag, loading} = this.props;
        if (!loading) {
            form.validateFields((error, values) => {
                if (!error) {
                    const payload = {
                        name: values.name,
                        id_bag: bag.id
                    };
                    this.props.createTemplateBag(payload);
                }
            })
        }
    };

    handleCancel = () => {
        this.props.togglePopupCreateTemplateBag()
    };

    render() {
        const {t, loading} = this.props;

        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                visible={true}
                width={400}
                closable={false}
                className="a-modal a-modal--create-template-bag _popup_create_template_bag"
                footer={null}
                centered={true}
            >
                <div className="create-template-bag--title">
                    <span className="title">{t('bag:template_bag.heading')}</span>
                </div>
                <div className="body">
                    <Form>
                        <Form.Item label={t('bag:template_bag.name')}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {required: true, message: t('validation:required', {attribute: 'Tên'})}
                                ]
                            })(
                                <Input
                                    className="a-input _input_name"
                                    placeholder={t('bag:label.template_bag_placeholder')}
                                    onPressEnter={this.handleOk}
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            <Button className="a-btn a-btn--default _btn_cancel mr-3"
                                    onClick={this.handleCancel}>{t('bag:template_bag.btn_cancel')}</Button>
                            <Button
                                type="primary" className="a-btn a-btn--primary mr-3 _btn_ok"
                                disabled={loading}
                                loading={loading}
                                onClick={this.handleOk}
                            >{t('bag:template_bag.btn_ok')}</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        )
    }
}

PopupCreateTemplateBag = Form.create({})(PopupCreateTemplateBag);

export {PopupCreateTemplateBag};