import React from 'react';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import * as actions from './actions';
import * as userActions from './../../User/actions';
import * as customerActions from './../../Customer/actions';
import * as warehouseActions from './../../Warehouse/actions';
import * as shippingPartnerActions from './../../ShippingPartner/ListShippingPartner/actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import {setMenuActive} from './../../Common/actions';
import DetailDeliveryNote from './components/';
import * as customerSelector from './../../Customer/selectors';
import * as userSelector from './../../User/selectors';
import * as warehouseSelector from './../../Warehouse/selectors';
import * as shippingPartnerSelector from './../../ShippingPartner/ListShippingPartner/selectors';
import {printIssue, printFBUStamp} from "../../Common/PrintStamp/actions";
import * as deliveryNotePrintAction from '../DeliveryNotePrint/actions';
import { getState } from '../../../system/store';

const mapStateToProps = (state) => {
    return {
        deliveryNote: selectors.getDeliveryNoteDetail(state),
        loading: selectors.getState(state, 'loading'),
        customers: customerSelector.getState(state, 'list', []),
        customer: customerSelector.getState(state, 'detail', {}),
        users: userSelector.getState(state, 'list', []),
        warehouses: warehouseSelector.getState(state, 'list', []),
        shippingPartners: shippingPartnerSelector.getState(state, 'listShippingPartner.list', []),
        loadingQuickUpdate: selectors.getState(state, 'loadingQuickUpdate'),
        exportErrors: _.get(state, 'deliveryNote.deliveryNoteExport.errors', {}),
        updateDeliveryResult: selectors.getState(state, 'updateDelivery'),
        agencySetting: getState('setting.agencySetting'),
        globalSetting: getState('setting.setting'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeliveryNote: (deliveryNoteId) => dispatch(actions.fetchDeliveryNoteDetail(deliveryNoteId)),
        clearState: () => dispatch(actions.clearState()),
        setMenuActive: (menu) => dispatch(setMenuActive(menu)),
        fetchUsers: () => dispatch(userActions.fetchUsers()),
        getListCustomer: () => dispatch(customerActions.getListCustomer()),
        getListWarehouse: () => dispatch(warehouseActions.getListWarehouse()),
        fetchShippingPartner: () => dispatch(shippingPartnerActions.fetchBagShippingPartner()),
        printIssue: (data) => dispatch(printIssue(data)),
        printFBU: (data) => dispatch(printFBUStamp(data)),
        updateDelivery: (id,payload, cb) => dispatch(actions.updateDelivery(id,payload, cb)),
        removePackageFromDeliveryNote: (deliveryNoteId, packageIds) => dispatch(actions.removePackageFromDeliveryNote(deliveryNoteId, packageIds)),
        removeBagFromDeliveryNote: (deliveryNoteId, bagIds) => dispatch(actions.removeBagFromDeliveryNote(deliveryNoteId, bagIds)),
        fetchDeliveryNotePrintSetting: () => dispatch(deliveryNotePrintAction.fetchSetting()),
        fetchDetailCustomer: customerId => dispatch(customerActions.fetchDetailCustomer(customerId))
    };
};

class DetailDeliveryNoteContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
        let {customers, users, warehouses, shippingPartners} = this.props;
        if (customers.length === 0) {
            this.props.getListCustomer();
        }

        if (users.length === 0) {
            this.props.fetchUsers();
        }

        if (warehouses.length === 0) {
            this.props.getListWarehouse();
        }

        if (shippingPartners.length === 0) {
            this.props.fetchShippingPartner();
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onChangeFilter = filter => {
        this.props.fetchDeliveryNote(filter.id);
        this.props.fetchDeliveryNotePrintSetting();
    };

    render() {
        return (
            <DetailDeliveryNote
                {...this.props}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailDeliveryNoteContainer);
