import React from 'react';
import get from 'lodash/get';

import authService from './../../Auth/authService';
import permissions from './../../Auth/permissions';

import Page403 from './../../Common/components/403';

export default (payload, next) => {
    const type = get(payload, 'request.match.params.type');

    if (type === 'domestic' && !authService.can(permissions.DOMESTIC_DELIVERY_PARTNER_MANAGE)) {
        return payload.response.render(<Page403 /> );
    }

    if (type === 'international' && !authService.can(permissions.DELIVERY_PARTNER_MANAGE)) {
        return payload.response.render(<Page403 /> );
    }

    return next(payload);
}
