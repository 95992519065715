import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as _ from 'lodash';

let user = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.SET_USER:
            return payload.user;

        case ACTION.GET_USER.SUCCESS:
            return payload.user;

        case ACTION.GET_USER.FAILED:
            return null;

        default:
            return state;
    }
};
let partner = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GET_USER.SUCCESS:
            return _.get(payload, 'partner', null);

        case ACTION.GET_USER.FAILED:
            return null;

        default:
            return state;
    }
};
let lou_stamp_url = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GET_USER.SUCCESS:
            return _.get(payload, 'lou_stamp_url', '');

        case ACTION.GET_USER.FAILED:
            return null;

        default:
            return state;
    }
};

let lou_stamp_url_auto = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GET_USER.SUCCESS:
            return _.get(payload, 'lou_stamp_url_auto', '');

        case ACTION.GET_USER.FAILED:
            return null;

        default:
            return state;
    }
};

export default combineReducers({
    user,
    partner,
    lou_stamp_url,
    lou_stamp_url_auto
});
