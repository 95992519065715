import React, { Component } from 'react';
import { Layout, notification } from 'antd';
import clsx from 'clsx';
import { find, uniqueId, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { connect } from 'react-redux';

import apiService from './../../apiService';
import {setMenuActive} from './../../../Common/actions';
import pageService from './../../../Common/Page/pageService';
import { successScanBarcode, failedTransportWarehouse } from './../../../ScanBarcode/ScanBarcodeForm/actions';
import Sound from './../../../Sound';
import { notifyErrorFromServer } from './../../../../system/support/helpers';

import FormDeliveryNote from './components/FormDeliveryNote';
import FormTransportWarehouse from './components/FormTransportWarehouseContainer';
import { BARCODE_TYPE } from '../../DeliveryNoteBarcode/constants';
import { dispatch } from '../../../../system/store';
import { normal as normalSound} from '../../../Sound/actions';
import { SOUND_CONSTANT } from '../../../Sound/constants';

class CreateContainer extends Component {
    state = {
        scroll: false,
        input: {},
        bags: [],
        packages: [],
        loading: false
    };

    handleScroll = () => {
        const { scroll } = this.state;

        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({
                    scroll: true
                });
            }
        } else {
            if (scroll) {
                this.setState({
                    scroll: false
                });
            }
        }
    };

    onChangeInput = (name, value) => {
        this.setState({
            input: {
                ...this.state.input,
                [name]: value
            }
        });
    };

    onScanBarcode = async () => {
        const { input } = this.state;
        const { successScanBarcode } = this.props;
        const newInput = { ...input, ...this.getInput() };
        const { code } = newInput;
        const identity = uniqueId();

        this.setState(prevState => {
            const { bags, packages } = prevState;            
            if (newInput.barcode_type === BARCODE_TYPE.BAG) {
                return {
                    bags: [{
                        ...(find(bags, {code}) || {code}),
                        scanning: true,
                        identity,
                    }, ...bags]
                };
            } else { 
                return {
                    packages: [{
                        ...(find(packages, {code}) || {code}),
                        scanning: true,
                        identity,
                    }, ...packages]
                };
            }
        });

        try {
            const response = await this.scanBarcode(newInput);
            successScanBarcode(response);
            const data = this.addBarcode(newInput, code, response, identity)
            if (newInput.barcode_type === BARCODE_TYPE.BAG) {
                this.setState({
                    bags: data
                });
            } else {
                this.setState({
                    packages: data
                });
            }
        } catch (error) {
            this.removeBarcode(identity, newInput)
        }
    };

    addBarcode = (input, code, data, identity) => {
        const isScanBag = get(input, "barcode_type", '') === BARCODE_TYPE.BAG
        const { bags, packages} = this.state;
        const objectData = isScanBag ? bags : packages;
        const barcode = find(objectData, item => (item.code === code || (!isScanBag  && (item.package.code === code || data.package.code === item.code)))   && !item.scanning) ;
        const index = objectData.findIndex(item => item.identity === identity);

        let newBarcode = {
            ...data,
            code,
            bag: isScanBag ? data.bag : undefined,
            package: isScanBag ? undefined : data.package
        };

        if (barcode && (barcode.bag || barcode.package)) {
            const codeError = 'scan_packet_scanned';
            dispatch(normalSound(SOUND_CONSTANT.BARCODE_SCANNING_DUPLICATE))
            
            newBarcode = {
                ...newBarcode,
                warning: newBarcode.warning ? [
                    ...newBarcode.warning,
                    { code: codeError }
                ] : [
                    { code: codeError }
                ]
            };
        }

        if (index === -1) {
            return objectData;
        }

        return [
            ...objectData.slice(0, index),
            newBarcode,
            ...objectData.slice(index + 1)
        ];
    };

    removeBarcode = (identity, input) => {
        const { bags, packages} = this.state;
        if (input.barcode_type === BARCODE_TYPE.BAG) {
            this.setState({
                bags: bags.filter(bag => bag.identity !== identity)
            })
        }else {
            this.setState({
                packages: packages.filter(item => item.identity !== identity)
            })
        }
    };

    scanBarcode = async input => {
        const { t, failedTransportWarehouse} = this.props;

        try {
            const response = await apiService.scanBarcode('export-transport-warehouse', input.code, {
                id_shipping_partner: input.id_shipping_partner,
                id_warehouse_scan: input.id_warehouse,
                barcode: input.code,
                barcode_type: input.barcode_type
            });

            return response.data;
        } catch (error) {
            failedTransportWarehouse()
            const data = get(error, 'response.data.data');
            const message = get(data, "barcode.message");
            if (message) {
                const packages = get(data, "barcode.packages", [])
                notification.error({
                    message: t(`scan:error.${message}`, {packages: packages.join(", "), count: packages.length})
                });
            } else {
                notifyErrorFromServer(error, t('delivery_note:scan_transport_warehouse.failed'), 'delivery_note:scan_transport_warehouse.errors');
            }
            throw error.response;
        }
    };

    getInput = () => {
        const { history: { location } } = this.props;

        return queryString.parse(location.search, {
            arrayFormat: 'bracket'
        });
    };

    removeBarcodeByIndex = (index, type) => {
        const bags = [...this.state.bags];
        const packages = [...this.state.packages]
        if (type === BARCODE_TYPE.BAG) {
            bags.splice(index, 1);
        } else {
            packages.splice(index, 1);
        }
        this.setState({
            bags,
            packages
        });
    };

    clearBarcodes = () => {
        this.setState({
            bags: [],
            packages: []
        });
    };

    componentDidMount () {
        const { t, setMenuActive } = this.props;

        pageService.setTitle(t('create_transport_warehouse_delivery_note'), <Sound/>);
        setMenuActive('delivery-notes/global-shipping-partner/create');
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render () {
        const { bags, packages, input, loading, scroll  } = this.state;
        return (
            <Layout className={clsx({
                'a-scroll--is-sider': scroll
            })}>
                <FormDeliveryNote
                    input={{...this.getInput(), ...input}}
                    onChange={this.onChangeInput}
                    scanBarcode={this.onScanBarcode}
                    loading={loading}
                />
                <FormTransportWarehouse
                    input={{...this.getInput(), ...input}}
                    loading={loading}
                    bags={bags}
                    packages={packages}
                    removeBarcode={this.removeBarcodeByIndex}
                    clearBarcodes={this.clearBarcodes}
                />
            </Layout>
        )
    }
}

const mapDispatchToProps = {
    setMenuActive,
    successScanBarcode,
    failedTransportWarehouse
};

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(CreateContainer));
