import React from 'react';
import { Col, Row } from 'antd';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import { getVar } from './../../../../../system/support/helpers';

const Info = ({ deliveryNoteTransportWarehouse, t }) => {
    const deliveryNoteTransportWarehouseDetail = getVar(deliveryNoteTransportWarehouse, 'delivery_note_transport_warehouse', {});
    const creator = getVar(deliveryNoteTransportWarehouse, 'creator', {}) || {};
    const exporter = getVar(deliveryNoteTransportWarehouse, 'exporter', {});
    const partner = getVar(deliveryNoteTransportWarehouse, 'shipping_partner', {}) || {};
    const warehouse = getVar(deliveryNoteTransportWarehouse, 'warehouse', {}) || {};
    const exporterName = exporter && Object.keys(exporter).length ? `${exporter.name} (${exporter.username})` : '--';
    const isCustomWarehouse = getVar(warehouse, 'is_custom_warehouse', false);

    const totalExport = isCustomWarehouse ? getVar(deliveryNoteTransportWarehouse, 'outside_bags.length') : getVar(deliveryNoteTransportWarehouse, 'bags.length') + getVar(deliveryNoteTransportWarehouse, 'packages.length');

    return (
        <div className="print-delivery-note--info a-block-info">˝
            <div className="delivery-note-info--title gflex galign-center gflex-direction-column">
                <h1>{t('transport_warehouse_delivery_note')}</h1>
            </div>
            <Row className="mx-0" gutter={48}>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('partner')}</label>
                    <span className="info--value a-text--bold ">{partner.name} ({partner.code})</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.creator_name')}</label>
                    <span className="info--value a-text--bold">{Object.keys(creator).length ? `${creator.name} (${creator.username})` : "--"}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.created_at')}</label>
                    <span className="info--value a-text--bold">{dateFormatter.dateTime(deliveryNoteTransportWarehouseDetail.created_at)}</span>
                </Col>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('delivery_note:print.exporter_name')}</label>
                    <span className="info--value a-text--bold">{exporterName}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.exported_at')}</label>
                    <span className="info--value a-text--bold">{deliveryNoteTransportWarehouseDetail.exported_at ? dateFormatter.dateTime(deliveryNoteTransportWarehouseDetail.exported_at) : '--'}</span>
                </Col>
                <Col className="info--group" span={7}>
                    <label className="info--label">{t('delivery_note:print.warehouse_export')}</label>
                    <span className="info--value a-text--bold">{warehouse.code}</span>
                </Col>
                <Col className="info--group pl-0" span={10}>
                    <label className="info--label">{t('vehicle_number')}</label>
                    <span className="info--value a-text--bold">{getVar(deliveryNoteTransportWarehouseDetail, 'car_number', '--')}</span>
                </Col>
                <Col className="info--group pr-0" span={7}>
                    <label className="info--label">{t('delivery_note:print.total_quantity')}</label>
                    <span className="info--value a-text--bold">{totalExport}</span>
                </Col>
                <Col className="info--group pl-0" span={24}>
                    <label className="info--label">{t('delivery_note:print.note')}</label>
                    <span className="info--value a-text--bold">{getVar(deliveryNoteTransportWarehouseDetail, 'note', '--') || '--'}</span>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(Info);
