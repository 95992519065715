import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {CLEAR_STATE} from '../FormShippingPartner/constants';

export const shippingPartnerId = (state = '', action) => {
    let { type, payload } = action;

    switch (type) {
        case ACTION.GET_SHIPPING_PARTNER_ID:
            return payload;
        case CLEAR_STATE:
            return '';
        default:
            return state;
    }
};

export default combineReducers({
    shippingPartnerId
})
