import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import {withTranslation} from 'react-i18next';

import Icon from './../../../../components/icons/Icon';

const Option = Select.Option;

class FormMultiServices extends React.PureComponent {
    onSelect(serviceId, selected, event) {
        event.preventDefault();
        this.changeService(serviceId, selected);
    }

    changeService(serviceId, selected) {
        let values = selected
            ? [...this.props.values, serviceId]
            : this.props.values.filter(value => value !== serviceId);

        this.props.onChange(values);
    }

    render() {
        let {t, emptyOption, loading, services, values} = this.props;
        return (
            <Select
                value={values && !loading ? values : []}
                showSearch={true}
                optionFilterProp="children"
                mode="multiple"
                loading={loading}
                disabled={this.props.disabled || loading}
                className={this.props.className}
                placeholder={this.props.placeholder}
                validateStatus={this.props.error ? 'error' : null}
                help={this.props.error}
                onChange={this.props.onChange}
                dropdownClassName={this.props.dropdownClassName}
            >
                {emptyOption ? (
                    <Option key="" value="" className={"_service_muliti__item"}>{emptyOption === true ? t('label.all') : emptyOption}</Option>
                ) : null}
                {services.map(service => (
                    <Option
                        key={service.id}
                        value={service.id}
                        className="_service_muliti__item"
                    >
                        <Icon className="mr-2" type={service.icon} />
                        {service.name}
                    </Option>
                ))}
            </Select>
        );
    }
}

FormMultiServices.defaultProps = {
    loading: false,
    services: [],
    values: [],
    onChange: () => {
    },
};

FormMultiServices.propTypes = {
    loading: PropTypes.bool,
    services: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default withTranslation()(FormMultiServices);