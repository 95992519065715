import {processApiRequest} from "../../Common/saga";
import * as ACTION from "../constants";
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {router} from "../../../system/routing";

export function* updateProperty(action) {
	yield processApiRequest(ACTION.UPDATE_PROPERTY, () => apiService.updateProperty(action.payload), action.payload);
}

export function* updatePropertySuccess() {
	yield notification.success({message: t('config:message.update_property_success')});
	router.redirect("/config/property");
}
