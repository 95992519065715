import React from 'react';
import { Button, Checkbox, Layout, Row } from 'antd';
import clsx from 'clsx';
import { get, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Sticky from 'react-sticky-el';

import { DELIVERY_OBJECT } from './../../constants';
import deliveryService from './../../deliveryService';
import { BARCODE_TYPE } from './../../../Barcode/constants';

import DeliveryCustomerRequests from './DeliveryCustomerRequests';
import ScanBarcode from './../../DeliveryNoteBarcode/ScanBarcodeContainer';
import ChooseWarehouse from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';

const { Sider } = Layout;

class FormDeliveryNote extends React.Component {
    state = {
        barcodeType: this.props.type === DELIVERY_OBJECT.RETURN ? BARCODE_TYPE.PACKAGE : undefined,
        fixedSticky: false
    };

    canChangeInput = () => {
        const { canChangeInput, deliveryNote } = this.props;

        if (get(deliveryNote, 'delivery_note.id')) {
            return false;
        }

        return canChangeInput;
    };

    onChangeBarcodeType = barcodeType => {
        if (!this.canChangeBarcodeType()) {
            return;
        }

        this.setState(prevState => ({
            barcodeType: prevState.barcodeType === barcodeType ? undefined : barcodeType
        }));
    };

    handleOnChange = (field, value) => {
        const { input, onChange } = this.props;

        if (!this.canChangeInput()) {
            return;
        }

        if (field === 'object' && value === input[field]) {
            value = undefined;
        }

        onChange({
            [field]: value
        });
    };

    getObjects = () => {
        return Object.values(DELIVERY_OBJECT).filter(object => deliveryService.canCrete(object));
    };

    handleFixedToggle = value => {
        this.setState({
            fixedSticky: value
        });
    };

    canScanBarcode = () => {
        const { barcodeType } = this.state;
        const { canScanBarcode, input, type } = this.props;

        if (!canScanBarcode) {
            return false;
        }

        if (type === DELIVERY_OBJECT.RETURN) {
            if (!input.id_shipping_return_partner || barcodeType !== BARCODE_TYPE.PACKAGE) {
                return false;
            }

            return true;
        }

        return [BARCODE_TYPE.BAG, BARCODE_TYPE.PACKAGE].includes(barcodeType) && Boolean((!input.object || (input.object && input.id_customer)));
    };

    canChangeBarcodeType = () => {
        const { input, type } = this.props;
        const isWarehouseSelected = !isNil(input.id_warehouse);

        if (type === DELIVERY_OBJECT.RETURN) {
            return false;
        }

        return isWarehouseSelected;
    };

    disableSelectBarcodeType = () => {
        const { type } = this.props;

        if (type === DELIVERY_OBJECT.RETURN) {
            return false;
        }

        return !this.canChangeBarcodeType();
    };

    render() {
        const {
            deliveryCustomerRequests,
            input,
            loadingDeliveryCustomerRequests,
            multiple,
            route,
            scanBarcodeByCustomer,
            showDeliveryCustomerRequests,
            showDeliveryRequestDetailModal,
            t,
            type,
            onSuccessDeliveringDeliveryRequest
        } = this.props;
        const { barcodeType, fixedSticky } = this.state;
        const canChangeInput = this.canChangeInput();

        return (
            <Sider className="a-sub-sider a-sub-sider--radius">
                <Sticky className={clsx('sticky-container', {
                    'is-fixed': fixedSticky
                })} onFixedToggle={this.handleFixedToggle}>
                    <PerfectScrollbar
                        options={{
                            wheelPropagation: false
                        }}
                    >
                        <div className="a-sider-sub__block mt-5">
                            <h4 className="a-sider-sub__block__title">{t('delivery_note:label.warehouse')}</h4>
                            <Row gutter={8} className={"a-form--group-button--warehouse"}>
                                <ChooseWarehouse
                                    isPermission={true}
                                    value={isNaN(input.id_warehouse) ? 0 : input.id_warehouse}
                                    disabled={!canChangeInput}
                                    active={true}
                                    onChange={this.handleOnChange.bind(this, 'id_warehouse')}
                                />
                            </Row>
                        </div>
                        {type !== DELIVERY_OBJECT.RETURN && (
                            <div className="a-sider-sub__block">
                                <h4 className="a-sider-sub__block__title">{t('delivery_note:label.object')}</h4>
                                {this.getObjects().map(object => (
                                    <Button
                                        className={clsx(`a-btn a-btn--w100 _btn-object-${object}`, {
                                            'a-btn--primary primay-special': object === input.object,
                                            'a-btn--default': object !== input.object
                                        })}
                                        disabled={!canChangeInput}
                                        key={object}
                                        value={input.object}
                                        onClick={this.handleOnChange.bind(this, 'object', object)}
                                    >
                                        {t(`delivery_note:object.${object}`)}
                                    </Button>
                                ))}
                            </div>
                        )}
                        <div className="a-sider-sub__block">
                            <h4 className="a-sider-sub__block__title">{t('delivery_note:label.scan_barcode')}</h4>
                            <div className="a-sider-sub__block pl-2">
                                {type !== DELIVERY_OBJECT.RETURN && (
                                    <Checkbox
                                        checked={barcodeType === BARCODE_TYPE.BAG}
                                        className="_checkbox-input-bag"
                                        disabled={this.disableSelectBarcodeType()}
                                        onChange={this.onChangeBarcodeType.bind(this, BARCODE_TYPE.BAG)}
                                    >
                                        {t('bag')}
                                    </Checkbox>
                                )}
                                <Checkbox
                                    checked={barcodeType === BARCODE_TYPE.PACKAGE}
                                    className="_checkbox-input-package"
                                    disabled={this.disableSelectBarcodeType()}
                                    onChange={this.onChangeBarcodeType.bind(this, BARCODE_TYPE.PACKAGE)}
                                >
                                    {t('package')}
                                </Checkbox>
                            </div>
                            <ScanBarcode
                                canScan={this.canScanBarcode()}
                                deliveryNote={this.props.deliveryNote}
                                multiple={multiple}
                                params={{
                                    ...input,
                                    barcode_type: barcodeType,
                                    type
                                }}
                                scanBarcodeByCustomer={scanBarcodeByCustomer}
                            />
                        </div>

                        {showDeliveryCustomerRequests && (
                            <DeliveryCustomerRequests
                                deliveryCustomerRequests={deliveryCustomerRequests}
                                customerId={input.id_customer}
                                loading={loadingDeliveryCustomerRequests}
                                route={route}
                                warehouseId={input.id_warehouse}
                                showDeliveryRequestDetailModal={showDeliveryRequestDetailModal}
                                onSuccessDeliveringDeliveryRequest={onSuccessDeliveringDeliveryRequest}
                            />
                        )}
                    </PerfectScrollbar>
                </Sticky>
            </Sider>
        );
    }
}

FormDeliveryNote.defaultProps = {
    canChangeInput: true,
    canScanBarcode: true,
    input: {},
    deliveryNote: null,
    scanBarcodeByCustomer: true,
    showDeliveryCustomerRequests: false,
    onChange: () => {
    },
};

FormDeliveryNote.propTypes = {
    canChangeInput: PropTypes.bool,
    input: PropTypes.object,
    deliveryNote: PropTypes.any,
    scanBarcodeByCustomer: PropTypes.bool,
    showDeliveryCustomerRequests: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withTranslation()(FormDeliveryNote);
