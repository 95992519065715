import React from 'react';
import { connect } from 'react-redux';

import * as actions from './../actions';
import { NOTE_TYPES } from './../constants';
import authSerice from './../../Auth/authService';
import permissions from './../../Auth/permissions';
import { setMenuActive } from './../../Common/actions';
import { fetchUsers } from './../../User/actions';
import * as userSelectors from './../../User/selectors';

import NotesPage from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';

const mapStateToProps = state => {
    return {
        listUser: userSelectors.getState(state, 'users', []),
        loadingUsers: userSelectors.getState(state, 'loading', false)
    };
};

const mapDispatchToProps = dispatch => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    getNotes: (params) => dispatch(actions.getNotes(params)),
    getListUser: () => dispatch(fetchUsers())
});

class NotesPageContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
        this.props.setMenuActive('notes');
        this.props.getListUser();
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    getFilterObject = filter => {
        let object = filter.object;

        if (object) {
            if ([NOTE_TYPES.ORDER, NOTE_TYPES.PACKAGE, NOTE_TYPES.TRACKING].includes(object) && !authSerice.can(permissions.ATTENTION_MANAGE)) {
                object = NOTE_TYPES.CUSTOMER;
            } else if (object === NOTE_TYPES.CUSTOMER && !authSerice.can(permissions.ATTENTION_CUSTOMER_MANAGE)) {
                object = NOTE_TYPES.ORDER;
            }
        } else {
            object = authSerice.can(permissions.ATTENTION_MANAGE) ? NOTE_TYPES.ORDER : NOTE_TYPES.CUSTOMER;
        }

        return object;
    };

    onChangeFilter = filter => {
        const { sort_by, sort_direction, getNotes } = this.props;

        filter.object = this.getFilterObject(filter);

        if (!filter.object) {
            filter.page = 1;
            filter.sort_by = sort_by || 'created_at';
            filter.sort_direction = sort_direction || 'desc';
        }

        getNotes(filter);
    };

    getCurrentFilter = () => {
        const { location, sort_by, sort_direction } = this.props;

        const filter = {
            page: 1,
            sort_by: sort_by || 'created_at',
            sort_direction: sort_direction || 'desc',
            ...this.getFilterFromLocation(location)
        };

        filter.object = this.getFilterObject(filter);

        return filter;
    };

    render() {
        return (
            <NotesPage
                {...this.props}
                filter={this.getCurrentFilter()}
                onChangeFilter={this.onChangeFilter}
                onSearch={this.onSearch}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotesPageContainer);
