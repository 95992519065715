import React from 'react';
import {Layout, Spin} from "antd";
import SiderBagPack from "./SiderBagPack";
import PropTypes from 'prop-types';
import pageService from '../../../Common/Page/pageService';
import {withTranslation} from 'react-i18next';
import ListBagPackageContainer from "../../ListBagPackage/ListBagPackageContainer";
import Sound from "../../../Sound";

class BagPacking extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
            typeScan: 'package_code',
        };
    }

    componentDidMount() {
        pageService.setTitle(this.props.t('bag:label.packing_bag'), <Sound/>);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false});
            }
        }
    };
    
    handleChangeType = (value) => {
        this.setState({
            typeScan: value
        })
    }

    render() {
        const { scroll, typeScan } = this.state;
        return (
            <Spin spinning={this.props.loading}>
                <Layout className={"_screen_bag_packing"}>
                    <SiderBagPack
                        bag={this.props.bag} loading={this.props.loading} scroll={scroll}
                        bagSize={this.props.bagSize}
                        resetError={this.props.resetError}
                        canUpdate={this.props.canUpdate}
                        errors={this.props.errors}
                        version={this.props.version}
                        updateBagParam={this.props.updateBagParam}
                        packingData={this.props.packingData}
                        warehouses={this.props.warehouses}
                        handleChangeType={this.handleChangeType}
                        typeScan={typeScan}
                    />
                    <ListBagPackageContainer
                        page={'packing'}
                        bag={this.props.bag}
                        scroll={scroll}
                        loading={this.props.loading}
                        packingData={this.props.packingData}
                        typeScan={typeScan}
                        getBagPackingDetail={this.props.getBagPackingDetail}
                    />
                </Layout>
            </Spin>
          
        )
    }
}


BagPacking.defaultProps = {
    bag: {},
    packingData: [],
    loading: false,
    canUpdate: false,
    errors: {},
    updateBagParam: () => {

    }
};

BagPacking.propTypes = {
    bag: PropTypes.object,
    packingData: PropTypes.array,
    loading: PropTypes.bool,
    canUpdate: PropTypes.bool,
    errors: PropTypes.object,
    updateBagParam: PropTypes.func,

};

export default withTranslation()(BagPacking);
