import React from 'react';
import { Skeleton } from 'antd';
import { Col, Row } from 'antd/lib/index';

const FormInformationLoading = () => (
	<Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
		<Col className="a-form--group" lg={{span: 24}}>
			<Skeleton active className="askeleton-list" paragraph={{rows: 1}} />
		</Col>
		<Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
			<Skeleton active className="askeleton-list" paragraph={{rows: 1}} />
		</Col>
		<Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
			<Skeleton active className="askeleton-list" paragraph={{rows: 1}} />
		</Col>
		<Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
			<Skeleton active className="askeleton-list" paragraph={{rows: 1}} />
		</Col>
	</Row>
);

export default FormInformationLoading;
