import { combineReducers } from 'redux';

import * as ACTION from './constants';

const detail = (state = {}, action) => {
	let {type, payload} = action;
	switch (type) {
		case ACTION.FETCH_INVENTORY.SUCCESS:
			return payload;
		case ACTION.FETCH_INVENTORY.FAILED:
		default :
			return state
	}
};

const loading = (state = false, action) => {
	let {type} = action;
	switch (type) {
		case ACTION.FETCH_INVENTORY.REQUEST:
			return true;
		case ACTION.FETCH_INVENTORY.SUCCESS:
			return false;
		case ACTION.FETCH_INVENTORY.FAILED:
			return false;
		default :
			return state
	}
};

export default combineReducers({
	detail,
	loading
});
