
const MIN_SIZE = 1000000

class ScanAutoService {
    getDataLog() {
        const listDataLocalStorage = localStorage.getItem('log_time_auto_scan')

        return JSON.parse(listDataLocalStorage) || []
    }

    updateData(data) {
        localStorage.setItem('log_time_auto_scan', JSON.stringify(data))
    }

    addEventDataPackage(packageCode, data) {
        const listDataLocalStorage = [...this.getDataLog()]

        const lastData = listDataLocalStorage[listDataLocalStorage.length - 1];

        if(listDataLocalStorage && lastData.packageCode === packageCode){

            listDataLocalStorage.pop()

            lastData.event.push(data)


            listDataLocalStorage.push(lastData)


            this.updateData(listDataLocalStorage)
            

        }
        else{
            this.addItemsToData(data)
        }

        // localStorage.setItem('log_time_auto_scan', JSON.stringify(data))
    }

    addItemsToData(items) {
        let listDataLocalStorage = this.getDataLog()

        if (!Array.isArray(items)) {
            items = [items]
        }

        const storageSize = 1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length

        if (storageSize > MIN_SIZE) {

            listDataLocalStorage = [...listDataLocalStorage, ...items]
            this.updateData(listDataLocalStorage)
        } else {
            localStorage.removeItem('log_time_auto_scan')
        }
    }
}

const scanAutoService = new ScanAutoService()

export default scanAutoService
