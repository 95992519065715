import React, { Component, Fragment } from 'react'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Row, Col, Icon, Tag } from 'antd'
import lodash from 'lodash'

import CustomIcon from './../../../../components/icons/Icon'

class ViewBag extends Component {
    render() {
        let { t, newBag } = this.props
        const agency = lodash.get(newBag, 'agency')
        const bagAreaName = lodash.get(newBag, 'bag_area.name')
        const bagAreaCode = lodash.get(newBag, 'bag_area.code')

        return (
            <Fragment>
                <Row
                    gutter={{ lg: 24, xl: 48 }}
                    className="a-block-info">
                    <Col
                        lg={{ span: 16 }}
                        xl={{ span: 19 }}
                        xxl={{ span: 19 }}>
                        <Row
                            gutter={{ lg: 24, xl: 32 }}
                            className="a-block-info bg-gray info--detail pb-0 apt-16">
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _bag_type">
                                <label className="info--label">{t('bag:label.bag_type')}</label>
                                <span className="info--value ">{newBag.bag.type ? t('bag:label.' + newBag.bag.type.toLowerCase() + '_bag') : '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _customer">
                                <label className="info--label">{t('bag:label.customer')}</label>
                                <span className="info--value ">{`${lodash.get(newBag.customer, 'username', '--')} (${lodash.get(
                                    newBag.customer,
                                    'code',
                                    '--'
                                )})`}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _size">
                                <label className="info--label">{t('bag:label.size')}</label>
                                <span className="info--value ">{lodash.get(newBag, 'bag_size.name', '--')}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _properties">
                                <label className="info--label">{t('bag:label.properties')}</label>
                                <span className="info--value">
                                    {Array.isArray(newBag.properties) && newBag.properties.length
                                        ? lodash.map(newBag.properties, (property, index) => (
                                              <Fragment key={property.id}>
                                                  <span className={`_property-${property.id}`}>
                                                      <CustomIcon
                                                          className="mr-2"
                                                          type={property.icon}
                                                      />
                                                      {property.name}
                                                  </span>
                                                  {index === newBag.properties.length - 1 ? null : <span className="mx-2">/</span>}
                                              </Fragment>
                                          ))
                                        : '--'}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _shipping_type">
                                <label className="info--label">{t('bag:label.shipping_type')}</label>
                                <span className="info--value ">
                                    {newBag.shipping_partner ? t('bag:shipping_type.partner') : newBag.shipping_user ? t('bag:shipping_type.user') : '--'}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _shipping_partner">
                                <label className="info--label">{t('bag:label.shippingPartner')}</label>
                                <span className="info--value ">
                                    {newBag.shipping_partner
                                        ? newBag.shipping_partner.name
                                        : newBag.shipping_user
                                        ? newBag.shipping_user.full_name + (newBag.shipping_user.username ? ' (' + newBag.shipping_user.username + ')' : '')
                                        : '--'}
                                </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _receiver_name">
                                <label className="info--label">{t('bag:label.receiver_name')}</label>
                                <span className="info--value ">{newBag.bag.receiver_name || '--'} </span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _receiver_phone">
                                <label className="info--label">{t('bag:label.receiver_phone')}</label>
                                <span className="info--value ">{newBag.bag.receiver_phone || '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _warehouse_input">
                                <label className="info--label">{t('bag:label.warehouse_input')}</label>
                                <span className="info--value ">{newBag.warehouse_input.name || '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _warehouse_destination">
                                <label className="info--label">{t('bag:label.warehouse_destination')}</label>
                                <span className="info--value ">{newBag.destination_warehouse.name}</span>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _note">
                                <label className="info--label">{t('bag:label.note')}</label>
                                <span className="info--value ">{newBag.bag.note || '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _is_unchecked">
                                <label className="info--label">{t('bag:label.unchecked')}</label>
                                <span className="info--value ">{newBag.bag.is_unchecked ? t('bag:label.unchecked') : '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                                className="info--group _agency">
                                <label className="info--label">{t('bag:label.agency')}</label>
                                <span className="info--value ">{!lodash.isEmpty(agency) ? <Tag key={agency.id}>{lodash.get(agency, 'name')}</Tag> : '--'}</span>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 8 }}
                                className="info--group _bag_area">
                                <label className="info--label">{t('bag:label.bag_area')}</label>
                                <span className="info--value">{bagAreaName ? `${bagAreaName} (${bagAreaCode})` : '--'}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 5 }}
                        className="_services a-package--service only-view">
                        <h2 className="title">{t('service:label.select_services')}</h2>
                        <div className="service--body">
                            <div className="service--block">
                                <div className="service--block--body">
                                    <ul className="_service_list">
                                        {newBag.services.length === 0 ? (
                                            <span className="service-empty mt-4">
                                                <Icon
                                                    type="exclamation-circle"
                                                    theme="filled"
                                                    className="a-text--orange mr-2"
                                                />
                                                {t('common:message.no_service')}
                                            </span>
                                        ) : (
                                            newBag.services.map((service, index) => (
                                                <li
                                                    className={clsx('mb-0', {
                                                        '_normal-service': service.type === 'normal',
                                                        '_consign-service': service.type === 'consign',
                                                    })}
                                                    key={index}>
                                                    <span
                                                        className={clsx({
                                                            'a-text--green': service.type === 'consign',
                                                        })}>
                                                        {service.icon ? (
                                                            <CustomIcon type={service.icon} />
                                                        ) : (
                                                            <img
                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA"
                                                                alt={service.name}
                                                            />
                                                        )}
                                                        {service.name}
                                                    </span>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

ViewBag.defaultProps = {
    newBag: {},
    loading: false,
}

ViewBag.propTypes = {
    newBag: PropTypes.object,
}

export default withTranslation()(ViewBag)
