import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Icon, Input, InputNumber, Row, Select, Typography, Checkbox} from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { currentFormatter } from './../../../../../../Common/services/format';
import withLocations from './../../../../../../../hocs/withLocations';
import { useDeepCompareEffect } from './../../../../../../../hooks';
import { getVar, isVietnamesePhoneNumber } from './../../../../../../../system/support/helpers';
import { sumBy, isNumber } from 'lodash';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const OrderInfo = ({
    className,
    customer,
    districts,
    form,
    loadingDistricts,
    loadingProvinces,
    loadingWards,
    order,
    t,
    vietNamProvinces,
    wards,
    getDistricts,
    getVietNamProvinces,
    getWards,
    deliveryNote
}) => {
    const [ward, setWard] = useState();
    const [district, setDistrict] = useState();
    const [province, setProvince] = useState();
    const [showWarning, setShowWarning] = useState(false);

    const handleChangeLocation = (name, value) => {
        if (name === 'province') {
            getDistricts(value);

            form.setFieldsValue({
                district: undefined,
                ward: undefined
            });

            setDistrict(undefined);
            setWard(undefined);
        }

        if (name === 'district') {
            getWards(value);

            form.setFieldsValue({
                ward: undefined
            });

            setWard(undefined);
        }

        let fn;

        if (name === 'ward') {
            fn = setWard;
        }

        if (name === 'district') {
            fn = setDistrict;
        }

        if (name === 'province') {
            fn = setProvince;
        }

        fn(value);
    };

    const handleResetButtonClick = () => {
        form.setFieldsValue({
            customer_receiver: undefined,
            customer_phone: undefined,
            note: undefined,
            province: undefined,
            district: undefined,
            ward: undefined,
            customer_address: undefined,
            cod: undefined,
            weight: undefined,
            length: undefined,
            width: undefined,
            height: undefined,
            is_receiver_pay: false
        });
    };

    useDeepCompareEffect(() => {
        const mapLocation = (object, location) => {
            if (!location) {
                return object;
            }

            if (location.type === 'WARD') {
                object.ward = location.id;
            }

            if (location.type === 'DISTRICT') {
                object.district = location.id;
            }

            if (location.type === 'PROVINCE') {
                object.province = location.id;
            }

            return mapLocation(object, location.parent);
        };
        const location = getVar(order, 'location', {});
        const { ward, district, province } = mapLocation({}, location);
        const orderId = getVar(order, 'id');
        const domesticShippingFee = getVar(deliveryNote, "delivery_note.domestic_shipping_fee", 0)
        const isReceiverPay = getVar(order, "is_receiver_pay", getVar(deliveryNote, "delivery_requests.[0].is_receiver_pay"));
        form.resetFields();
        form.setFieldsValue({
            customer_receiver: orderId ? order.receiver_name : getVar(order, 'customer_receiver'),
            customer_phone: orderId ? order.receiver_phone : getVar(order, 'customer_phone'),
            customer_address: orderId ? getVar(order, 'receiver_address') : getVar(order, 'customer_address'),
            cod: getVar(order, 'cod'),
            length: getVar(order, 'length') ? Number.parseInt(order.length * 100) : getVar(order, 'length'),
            width: getVar(order, 'width') ? Number.parseInt(order.width * 100) : getVar(order, 'width'),
            height: getVar(order, 'height') ? Number.parseInt(order.height * 100) : getVar(order, 'height'),
            weight: orderId ? getVar(order, 'weight') : sumBy(getVar(order, "packages", []), "weight_net"),
            note: getVar(order, 'note'),
            province,
            district,
            ward,
            is_receiver_pay: isReceiverPay
        });

        setWard(ward);
        setDistrict(district);
        setProvince(province);

        if (province) {
            getDistricts(province);
        }

        if (district) {
            getWards(district);
        }

        if (domesticShippingFee && !!isReceiverPay && !orderId) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [order]);

    useEffect(() => {
        if (loadingProvinces) {
            form.setFieldsValue({
                province: undefined
            });
        } else {
            form.setFieldsValue({
                province
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProvinces, province]);

    useEffect(() => {
        if (loadingDistricts) {
            form.setFieldsValue({
                district: undefined
            });
        } else {
            form.setFieldsValue({
                district
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDistricts, district]);

    useEffect(() => {
        if (loadingWards) {
            form.setFieldsValue({
                ward: undefined
            });
        } else {
            form.setFieldsValue({
                ward
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingWards, ward]);

    useEffect(() => {
        getVietNamProvinces();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getFieldDecorator } = form;
    const orderId = getVar(order, 'id');

    function handleCheckReceiverPay (e) {
        const domesticShippingFee = getVar(deliveryNote, "delivery_note.domestic_shipping_fee", 0)
        const checkedValue = e.target.checked
        if (domesticShippingFee && checkedValue) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
        form.setFieldsValue({is_receiver_pay: checkedValue ? 1 : 0})
    }

    return (
        <div className={clsx('a-tile', className)}>
            <div className="a-tile__header">
                <Text strong>{t('order_info')}</Text>
            </div>
            <div className="a-tile__body">
                <Row gutter={16} type="flex">
                    <Col span={5}>
                        <Item label={t('package:label.customer_receive')}>
                            {getFieldDecorator('customer_receiver', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('package:label.customer_receive')
                                    })
                                }]
                            })(
                                <Input className="_receiver" disabled={!!orderId} />
                            )}
                        </Item>
                    </Col>
                    <Col span={5}>
                        <Item label={t('phone_number')}>
                            {getFieldDecorator('customer_phone', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('phone_number')
                                    })
                                }, {
                                    validator: async (rule, value, callback) => {
                                        if (value && !isVietnamesePhoneNumber(value)) {
                                            callback(t('validation:regex', {
                                                attribute: t('phone_number'),
                                                value: 0
                                            }));
                                        } else {
                                            callback();
                                        }
                                    }
                                }]
                            })(
                                <Input className="_phone" disabled={!!orderId} />
                            )}
                        </Item>
                    </Col>
                    <Col span={14}>
                        <Item label={t('package:label.customer_note')}>
                            {getFieldDecorator('note')(
                                <TextArea autosize={{minRows: 1, maxRows: 2}} className="_note" disabled={!!orderId} />
                            )}
                        </Item>
                    </Col>

                    <Col span={5}>
                        <Item label={t('location:label.city')}>
                            {getFieldDecorator('province', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('location:label.city')
                                    })
                                }]
                            })(
                                <Select
                                    className="a-select a-select--w-100 _province"
                                    disabled={!!(loadingProvinces || orderId)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={Boolean(loadingProvinces)}
                                    optionFilterProp="children"
                                    showSearch
                                    onChange={handleChangeLocation.bind(undefined, 'province')}
                                >
                                    {vietNamProvinces.map(province => (
                                        <Option
                                            className="_province_id"
                                            key={getVar(province, 'location.id')}
                                            value={getVar(province, 'location.id')}
                                        >
                                            {getVar(province, 'location.label')}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Item>
                    </Col>
                    <Col span={5}>
                        <Item label={t('location:label.district')}>
                            {getFieldDecorator('district', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('location:label.district')
                                    })
                                }]
                            })(
                                <Select
                                    className="a-select a-select--w-100 _district"
                                    disabled={!!(loadingDistricts || orderId)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={Boolean(loadingDistricts)}
                                    optionFilterProp="children"
                                    showSearch
                                    onChange={handleChangeLocation.bind(undefined, 'district')}
                                >
                                    {districts && districts[form.getFieldValue('province')] ? districts[form.getFieldValue('province')].map(district => (
                                        <Option
                                            className="_district_item"
                                            key={getVar(district, 'location.id')}
                                            value={getVar(district, 'location.id')}
                                        >
                                            {getVar(district, 'location.label')}
                                        </Option>
                                    )) : null}
                                </Select>
                            )}
                        </Item>
                    </Col>
                    <Col span={5}>
                        <Item label={t('location:label.ward')}>
                            {getFieldDecorator('ward', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('location:label.ward')
                                    })
                                }]
                            })(
                                <Select
                                    className="a-select a-select--w-100 _ward"
                                    disabled={!!(loadingWards || orderId)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={Boolean(loadingWards)}
                                    optionFilterProp="children"
                                    showSearch
                                    onChange={handleChangeLocation.bind(undefined, 'ward')}
                                >
                                    {wards && wards[form.getFieldValue('district')] ? wards[form.getFieldValue('district')].map(ward => (
                                        <Option
                                            className="_ward_item"
                                            key={getVar(ward, 'location.id')}
                                            value={getVar(ward, 'location.id')}
                                        >
                                            {getVar(ward, 'location.label')}
                                        </Option>
                                    )) : null}
                                </Select>
                            )}
                        </Item>
                    </Col>
                    <Col span={9}>
                        <Item label={t('address')}>
                            {getFieldDecorator('customer_address', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('address')
                                    })
                                }]
                            })(
                                <TextArea autosize={{minRows: 1, maxRows: 2}} className="_address" disabled={!!orderId} />
                            )}
                        </Item>
                    </Col>

                    <Col span={5}>
                        <Item label={t('freight_bill')}>
                            {getVar(order, 'tracking_no', '--')}
                        </Item>
                    </Col>
                    <Col span={5}>
                        <Item label="COD (VNĐ)">
                            {getFieldDecorator('cod', {
                                rules: [{
                                    validator: async (rule, value, callback) => {
                                        if (value && value <= 0) {
                                            callback(t('validation:gt', {
                                                attribute: 'COD',
                                                value: 0
                                            }));
                                        } else if (value && !Number.isInteger(value)) {
                                            callback(t('validation:integer', {
                                                attribute: 'COD'
                                            }));
                                        } else {
                                            callback();
                                        }
                                    }
                                }]
                            })(
                                <InputNumber
                                    className="_cod width-100-pc"
                                    disabled={!!orderId}
                                    formatter={value => currentFormatter.toLocaleStringCurrency(value)}
                                />
                            )}
                        </Item>
                    </Col>
                    <Col span={5}>
                        <Item label={`${t('weight')} (kg)`}>
                            {getFieldDecorator('weight', {
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('weight')
                                    })
                                }, {
                                    type: 'number',
                                    message: t('validation:numeric', {
                                        attribute: t('weight')
                                    })
                                }, {
                                    validator: async (rule, value, callback) => {
                                        if (isNumber(value) && value <= 0) {
                                            callback(t('validation:gt', {
                                                attribute: t('weight'),
                                                value: 0
                                            }));
                                        } else {
                                            callback();
                                        }
                                    }
                                }]
                            })(
                                <InputNumber className="_weight width-100-pc" disabled={!!orderId} />
                            )}
                        </Item>
                    </Col>
                    <Col span={9}>
                        <Item
                            label={`${t('package:label.dimension')} (cm)`}
                        >
                            <Row gutter={12}>
                                <Col span={8}>
                                    <Item className="mb-0">
                                        {getFieldDecorator('length', {
                                            rules: [{
                                                type: 'number',
                                                message: t('validation:numeric', {
                                                    attribute: t('package:label.length')
                                                })
                                            }, {
                                                validator: async (rule, value, callback) => {
                                                    if (isNumber(value) && value <= 0) {
                                                        callback(t('validation:gt', {
                                                            attribute: t('package:label.length'),
                                                            value: 0
                                                        }));
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }]
                                        })(
                                            <InputNumber
                                                className="_length width-100-pc"
                                                disabled={!!orderId}
                                                placeholder={t('length')}
                                            />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={8}>
                                    <Item className="mb-0">
                                        {getFieldDecorator('width', {
                                            rules: [{
                                                type: 'number',
                                                message: t('validation:numeric', {
                                                    attribute: t('package:label.width')
                                                })
                                            }, {
                                                validator: async (rule, value, callback) => {
                                                    if (isNumber(value) && value <= 0) {
                                                        callback(t('validation:gt', {
                                                            attribute: t('package:label.width'),
                                                            value: 0
                                                        }));
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }]
                                        })(
                                            <InputNumber
                                                className="_width width-100-pc"
                                                disabled={!!orderId}
                                                placeholder={t('width')}
                                            />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={8}>
                                    <Item className="mb-0">
                                        {getFieldDecorator('height', {
                                            rules: [{
                                                type: 'number',
                                                message: t('validation:numeric', {
                                                    attribute: t('package:label.height')
                                                })
                                            }, {
                                                validator: async (rule, value, callback) => {
                                                    if (isNumber(value) && value <= 0) {
                                                        callback(t('validation:gt', {
                                                            attribute: t('package:label.height'),
                                                            value: 0
                                                        }));
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }]
                                        })(
                                            <InputNumber
                                                className="_height width-100-pc"
                                                disabled={!!orderId}
                                                placeholder={t('height')}
                                            />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                        </Item>
                    </Col>

                    <Col span={5}>
                        <Item label={t('delivery_note:label.domestic_shipping_fee')}>
                            {getVar(order, 'fee') ? currentFormatter.toLocaleStringCurrency(order.fee) : '--'}
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item className="mb-0">
                            {getFieldDecorator('is_receiver_pay')(
                                <Checkbox 
                                    disabled={!!orderId} 
                                    checked={!!form.getFieldValue("is_receiver_pay")} 
                                    onChange={(e) => handleCheckReceiverPay(e) }
                                    >
                                    {t('label.is_receiver_pay')}
                                </Checkbox>
                            )}
                        </Item>
                        {
                            !orderId && showWarning && <p className='a-text--warning'>{t('placeholder.warning_receiver_pay')}</p>
                        }
                    </Col>
                    <Col span={5}>
                        {orderId ? null : (
                            <Button
                                className="reset-button _reset-button mt-2"
                                type="link"
                                onClick={handleResetButtonClick}
                            >
                                <Icon type="sync" />
                                {t('btn.reset')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default withTranslation()(withLocations(OrderInfo));
