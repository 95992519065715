import {combineReducers} from 'redux';
import * as ACTION from './constants';
import lodash from 'lodash';
import commonList from './CommonLocation/reducer';

let list = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_LOCATION.REQUEST:
        case ACTION.FETCH_LIST_LOCATION.FAILED:
            return [];
        case ACTION.FETCH_LIST_LOCATION.SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

// DEPRECATED - use countries instead
let listCountry = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST:
        case ACTION.FETCH_COUNTRY.FAILED:
            return [];
        case ACTION.FETCH_COUNTRY.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};

// DEPRECATED - use provinces instead
let listProvince = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST:
        case ACTION.FETCH_PROVINCE.REQUEST:
        case ACTION.FETCH_PROVINCE.FAILED:
            return [];
        case ACTION.FETCH_PROVINCE.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};

// DEPRECATED - use districts instead
let listDistrict = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST:
        case ACTION.FETCH_PROVINCE.REQUEST:
        case ACTION.FETCH_DISTRICT.REQUEST:
        case ACTION.FETCH_DISTRICT.FAILED:
            return [];
        case ACTION.FETCH_DISTRICT.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
let listWard = (state = [], action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST:
        case ACTION.FETCH_PROVINCE.REQUEST:
        case ACTION.FETCH_DISTRICT.REQUEST:
        case ACTION.FETCH_WARD.REQUEST:
        case ACTION.FETCH_WARD.FAILED:
            return [];
        case ACTION.FETCH_WARD.SUCCESS:
            return [...action.payload.locations];
        default:
            return state;
    }
};
let currentCountry = (state = 0, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_LOCATION.REQUEST:
        case ACTION.FETCH_LIST_LOCATION.FAILED:
            return 0;
        case ACTION.SET_CURRENT_COUNTRY:
            return lodash.get(action, "payload.idCountry", 11935);
        default:
            return state;
    }
};

let currentCity =  (state = 0, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_LOCATION.REQUEST:
        case ACTION.FETCH_LIST_LOCATION.FAILED:
            return 0;
        case ACTION.SET_CURRENT_CITY:
            return lodash.get(action, "payload.idCity", 0);
        default:
            return state;
    }
};

let currentDistrict =  (state = 0, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_LIST_LOCATION.REQUEST:
        case ACTION.FETCH_LIST_LOCATION.FAILED:
            return 0;
        case ACTION.SET_CURRENT_DISTRICT:
            return lodash.get(action, "payload.idDistrict", 0);
        default:
            return state;
    }
};

// DEPRECATED - use loading of specific type
let loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST:
            return true;
        case ACTION.FETCH_COUNTRY.SUCCESS:
        case ACTION.FETCH_COUNTRY.FAILED:
            return false;
        case ACTION.FETCH_PROVINCE.REQUEST:
            return true;
        case ACTION.FETCH_PROVINCE.SUCCESS:
        case ACTION.FETCH_PROVINCE.FAILED:
            return false;
        case ACTION.FETCH_DISTRICT.REQUEST:
            return true;
        case ACTION.FETCH_DISTRICT.SUCCESS:
        case ACTION.FETCH_DISTRICT.FAILED:
            return false;
        case ACTION.FETCH_WARD.REQUEST:
            return true;
        case ACTION.FETCH_WARD.SUCCESS:
        case ACTION.FETCH_WARD.FAILED:
            return false;
        default:
            return state;
    }
};

const loadingCountries = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_COUNTRY.REQUEST: {
            return true;
        }
        case ACTION.FETCH_COUNTRY.SUCCESS:
        case ACTION.FETCH_COUNTRY.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const loadingProvinces = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_PROVINCE.REQUEST: {
            return true;
        }
        case ACTION.FETCH_PROVINCE.SUCCESS:
        case ACTION.FETCH_PROVINCE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const loadingDistricts = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_DISTRICT.REQUEST: {
            return true;
        }
        case ACTION.FETCH_DISTRICT.SUCCESS:
        case ACTION.FETCH_DISTRICT.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const countries = (state = [], action) => {
    const { type } = action;

    switch (type) {
        case ACTION.SET_COUNTRIES: {
            return [
                ...action.payload
            ];
        }
        default: {
            return state;
        }
    }
};

const provinces = (state = {}, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_PROVINCE.SUCCESS: {
            return {
                ...state,
                [lodash.get(action, 'request.id')]: lodash.get(action, 'payload.locations')
            };
        }
        case ACTION.SET_PROVINCES: {
            return {
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

const districts = (state = {}, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_DISTRICT.SUCCESS: {
            return {
                ...state,
                [lodash.get(action, 'request.id')]: lodash.get(action, 'payload.locations')
            };
        }
        case ACTION.SET_DISTRICTS: {
            return {
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

const wards = (state = {}, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.FETCH_WARD.SUCCESS: {
            return {
                ...state,
                [lodash.get(action, 'request.id')]: lodash.get(action, 'payload.locations')
            };
        }
        case ACTION.SET_WARDS: {
            return {
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    loading,
    loadingCountries,
    loadingProvinces,
    loadingDistricts,
    list,
    currentCountry,
    currentCity,
    currentDistrict,
    listCountry,
    listProvince,
    listDistrict,
    listWard,
    commonList,
    countries,
    provinces,
    districts,
    wards
});
