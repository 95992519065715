export const GET_SHIPPING_FEES = {
    REQUEST: 'SHIPPING_FEE.GET_LIST.REQUEST',
    SUCCESS: 'SHIPPING_FEE.GET_LIST.SUCCESS',
    FAILED: 'SHIPPING_FEE.GET_LIST.FAILED'
};

export const ADD_SHIPPING_FEE = {
    REQUEST: 'SHIPPING_FEE.ADD.REQUEST',
    SUCCESS: 'SHIPPING_FEE.ADD.SUCCESS',
    FAILED: 'SHIPPING_FEE.ADD.FAILED'
};

export const UPDATE_SHIPPING_FEE = {
    REQUEST: 'SHIPPING_FEE.UPDATE.REQUEST',
    SUCCESS: 'SHIPPING_FEE.UPDATE.SUCCESS',
    FAILED: 'SHIPPING_FEE.UPDATE.FAILED'
};

export const CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS';

export const GET_ROUTES = {
    REQUEST: 'SHIPPING_FEE.GET_ROUTES.REQUEST',
    SUCCESS: 'SHIPPING_FEE.GET_ROUTES.SUCCESS',
    FAILED: 'SHIPPING_FEE.GET_ROUTES.FAILED'
};

export const TABLE_COLUMNS = {
    SHIPPING_PARTNER: 'shipping_partner',
    CREATOR: 'creator',
    ROUTE: 'route',
    SERVICE: 'service',
    APPLIED_AT: 'applied_at',
    UNIT_PRICE: 'unit_price',
    DIVIDED_CONVERSION: 'divided_conversion',
    WEIGHT_BY: 'weight_by'
};
