import { Button, Form, Icon, Input, Modal, notification, Upload } from 'antd'
import clsx from 'clsx'
import { get, isEmpty, isNil, replace } from 'lodash'
import React, { useState } from 'react'
import { t } from '../../../system/i18n'
import { isExcelFile } from '../../../system/support/helpers'
import SelectWarehouse from '../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import apiService from '../apiService'
import SelectAgency from '../components/Agency/SelectAgency'
import SuggestBagList from '../components/SuggestBagList'

const { Dragger } = Upload

const DistributionWarehouse = ({ form }) => {
    const { getFieldDecorator } = form
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)

    const [uploadingCreatePackage, setUploadingCreatePackage] = useState(false)
    const [errors, setErrors] = useState([])
    const [hash, setHash] = useState('')

    const uploadCreatePackage = () => {
        form.validateFields((error, values) => {
            if (error) {
                return
            }

            doUploadCreatePackage({
                ...values,
                create_shipment_order: get(values, 'create_shipment_order', false),
                file: values.file.file,
            })
        })
    }

    const doUploadCreatePackage = async data => {
        setUploadingCreatePackage(true)
        setErrors([])
        try {
            const formData = new FormData()

            if (data) {
                Object.keys(data).forEach(key => formData.append(key, data[key]))
            }

            const response = await apiService.checkDistributes(formData)
            setErrors(get(response, 'data.errors', []))
            setHash(get(response, 'data.hash', ''))
        } catch (error) {
            notificationErrors(error)
        } finally {
            setUploadingCreatePackage(false)
        }
    }

    const notificationErrors = error => {
        const code = get(error, 'response.data.code')
        const data = get(error, 'response.data.data')

        if (code === 'INPUT_INVALID') {
            Object.entries(data).forEach(([key, value]) => {
                switch (key) {
                    case 'id_bag_template':
                        notification.error({
                            message: t(`package:expedited_package.message.${key}.${value.message}`, {
                                attribute: t(`package:expedited_package.label.${value.code}`),
                            }),
                        })

                        break
                    case 'id_agency':
                        notification.error({
                            message: t(`package:expedited_package.message.${key}.${Object.keys(value)[0]}`),
                        })

                        break
                    default:
                        notification.error({
                            message: t(`package:expedited_package.message.${Object.keys(value)[0]}`, {
                                attribute: t(`package:expedited_package.label.${key}`),
                            }),
                        })
                        break
                }
            })
        } else {
            notification.error({ message: t('message.server_error') })
        }
    }

    const renderErrors = line => {
        const error = get(line, 'items', []).map(item => {
            return t(`package:expedited_package.message.${item.message}`, { attribute: t(`package:label.${item.code}`) })
        })

        return t(`package:expedited_package.message.line`, {
            line: line.line,
            content: error.join(', '),
        })
    }

    const handleChangeFile = event => {
        const { file, fileList } = event
        form.setFieldsValue({
            id_bag: replace(file.name, /\.[^/.]+$/, ''),
        })
        setFileList(fileList.length ? [file] : [])
    }

    const beforeUpload = () => false

    const onConfirmCreatePackage = () => {
        if (!isEmpty(errors)) {
            Modal.confirm({
                title: t('btn.confirm'),
                content: t('package:expedited_package.message.confirm_create_expedited_package'),
                onOk: onCreatePackage,
                onCancel() {},
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
            })
        } else {
            onCreatePackage()
        }
    }

    const onCreatePackage = () => {
        const data = {
            ...form.getFieldsValue(),
            hash,
        }

        return apiService
            .createDistributes(data)
            .then(result => {
                reset()
                notification.success({ message: t('package:expedited_package.message.create_processing') })
            })
            .catch(err => {
                notificationErrors(err)
            })
    }

    const reset = () => {
        setHash('')
        form.resetFields()
        setFileList([])
        setErrors([])
    }


    return (
        <div className="a-content--search-header">
            <div className="gflex gjustify-space-between">
                <div></div>

                <Button
                    className="mb-4"
                    onClick={reset}>
                    <Icon type="reload" />
                    {t('btn.reset')}
                </Button>
            </div>
            <Form form={form}>
                <div className="gflex">
                    <Form.Item
                        className="mb-4 width-25-pc mr-4 mb-0"
                        label={'Agency'}>
                        {getFieldDecorator('id_agency', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: 'Agency',
                                    }),
                                },
                            ],
                        })(<SelectAgency placeholder="Agency" disabled={hash}/>)}
                    </Form.Item>
                    <Form.Item
                        className="mb-4 width-25-pc mr-4 mb-0"
                        label={t('package:expedited_package.label.id_warehouse')}>
                        {getFieldDecorator('id_warehouse', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('package:expedited_package.label.id_warehouse'),
                                    }),
                                },
                            ],
                        })(
                            <SelectWarehouse
                                className="_a-select-warehouse-input-package"
                                dropdownClassName="_dropdown_id_warehouse_input"
                                placeholder={t('package:expedited_package.label.id_warehouse')}
                                disabled={hash}
                            />
                        )}
                    </Form.Item>

                    {/* <Form.Item
                        className="width-25-pc mr-4 mb-0"
                        label={t('bag:label.code')}>
                        {getFieldDecorator('id_bag', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('bag:label.code'),
                                    }),
                                },
                            ],
                        })(
                            <SuggestBagList
                                form={form}
                                disabled={hash}
                                valueId
                            />
                        )}
                    </Form.Item> */}
                </div>

                <Form.Item style={{ marginBottom: 12 }}>
                    {getFieldDecorator('file', {
                        rules: [
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: 'File',
                                }),
                            },
                            {
                                validator: async (rule, value, callback) => {
                                    if (value) {
                                        const { file, fileList } = value
                                        const { name } = file

                                        if (fileList.length && !isExcelFile(name)) {
                                            throw new Error(
                                                t('validation:mimes', {
                                                    attribute: 'File',
                                                    values: 'xlsx, xls',
                                                })
                                            )
                                        }
                                    }

                                    callback()
                                },
                            },
                        ],
                    })(
                        <Dragger
                            accept=".xlsx, .xls"
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeFile}>
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">{t('dragger_form.hint1')}</p>
                            <p className="ant-upload-hint">{t('dragger_form.hint2')}</p>
                        </Dragger>
                    )}
                </Form.Item>

                {!isEmpty(errors) && (
                    <div className="pt-3">
                        {errors.map((item, index) => {
                            return (
                                <p
                                    key={index}
                                    className="a-text--red">
                                    - {renderErrors(item)}
                                </p>
                            )
                        })}
                    </div>
                )}
                <div className="gflex gjustify-center mt-4">
                    {hash ? (
                        <Button
                            onClick={onConfirmCreatePackage}
                            loading={loading}
                            type="primary"
                            style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', padding: '0 48px', height: '42px' }}>
                            {t('package:expedited_package.label.distribution')}
                        </Button>
                    ) : (
                        <Button
                            loading={uploadingCreatePackage}
                            onClick={uploadCreatePackage}
                            type="primary">
                            {t('upload')}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )
}

export default Form.create()(DistributionWarehouse)
