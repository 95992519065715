import React from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { PACKAGE_ACTION } from './../../../Bag/constants';
import { BARCODE_TYPE, SCAN_ACTION } from './../../../Barcode/constants';
import { STAGES } from './../../../Notes/constants';
import { Link } from './../../../../system/routing';

import { renderNotices, useNotes } from './../../../Common/components/Attentions';
import { getVar } from '../../../../system/support/helpers';

const { Paragraph } = Typography;

const ItemTableWarning = ({ index, listDataItem, t }) => {
    const notes = get(listDataItem, 'notes', []);
    const notices = get(listDataItem, 'notices.text', []);
    const noteElements = useNotes(notes, STAGES.SCANNING_PACKAGE);
    const packageRoutings = getVar(listDataItem, "packageRoutings", {})
    const packageRoutingsContent = [];
    Object.keys(packageRoutings).map(key => {
        if (packageRoutings[key].action === "yes") {
            packageRoutingsContent.push(t(`package:package_routing.${key}`));
        }
    })
    return (
        <>
            <tbody className="warning">
                <tr className="data">
                    <td>{index + 1}</td>
                    <td className="_id-scan">
                        {[BARCODE_TYPE.BAG, BARCODE_TYPE.PACKAGE].includes(listDataItem.barcode_type) ? (
                            <Link
                                className="a-text--nowrap"
                                isNewTab
                                params={{id: listDataItem.barcode}}
                                to={listDataItem.barcode_type === BARCODE_TYPE.BAG ? 'bags.detail' : 'packages.detail'}
                            >
                                <img
                                    alt=""
                                    className="logo-shop"
                                    src={listDataItem.barcode_type === BARCODE_TYPE.BAG ? require('./../../../../resources/images/bag.svg') : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'}
                                />
                                {listDataItem.barcode}
                            </Link>
                        ) : listDataItem.barcode}
                    </td>
                    <td className="_bag-code">
                        {get(listDataItem, 'bag.code') ? (
                            <>
                                {listDataItem.status_warehouse === SCAN_ACTION.IN && listDataItem.barcode_type === BARCODE_TYPE.PACKAGE && (listDataItem.bag.id_warehouse_current !== listDataItem.id_warehouse_scan || listDataItem.bag.status_warehouse !== PACKAGE_ACTION.IN) && (
                                    <Paragraph className="a-text--warning mb-0">
                                        {t('scan:error.bag_not_in_warehouse', {
                                            warehouse: listDataItem.code_warehouse_scan
                                        })}
                                    </Paragraph>
                                )}
                                <Link
                                    isNewTab
                                    params={{id: listDataItem.bag.code}}
                                    to="bags.detail"
                                >
                                    {listDataItem.bag.code}
                                </Link>
                                <span className="ml-2">
                                    ({t('package_number', {
                                        count: listDataItem.bag.num_package
                                    })})
                                </span>
                            </>
                        ) : '--'}
                    </td>
                    <td className="_created_at">{listDataItem.created_at}</td>
                    <td className="_action-scan">
                        <span
                            className={clsx({
                                'a-text--green': listDataItem.status_warehouse === 'import',
                                'a-text--blue': listDataItem.status_warehouse !== 'import'
                            })}
                        >
                            {t(`scan:label.${listDataItem.status_warehouse}`)}
                        </span>
                    </td>
                    <td className={'_code_warehouse_scan'}>
                        {listDataItem.code_warehouse_scan}&nbsp;&nbsp;
                    </td>
                    <td className={'_code_warehouse_scan'}>
                        {get(listDataItem, 'customer.username', '---')}
                    </td>
                    <td className={'_handling_indicate'}>
                        {packageRoutingsContent.join(", ")}
                    </td>
                </tr>

                {notes.length || notices.length ? (
                    <tr className="message">
                        <td colSpan="8">
                            <div
                                className={clsx({
                                    'mb-2': notices && notices.length
                                })}
                            >
                                {noteElements}
                            </div>
                            {renderNotices(notices)}
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </>
    );
};

ItemTableWarning.defaultProps = {
    listDataItem: {}

};

ItemTableWarning.propTypes = {
    listDataItem: PropTypes.object,
};

export default withTranslation()(ItemTableWarning);
