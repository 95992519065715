import React from 'react';
import {connect} from 'react-redux';

import * as actions from './actions';
import * as selectorsInventoryScan from './selectors';
import * as historyListSelectors from './../HistoryList/selectors';
import {setMenuActive} from './../../Common/actions';
import {getListWarehouse} from './../../Warehouse/actions';

import InventoryScan from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';

const mapStateToProps = (state) => {
	return {
		loading: selectorsInventoryScan.getState(state, 'loading'),
		inventory: selectorsInventoryScan.getState(state, 'detail'),
		scanHistories: historyListSelectors.getState(state, 'list', []),
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchInventory: (id) => dispatch(actions.fetchInventory(id)),
		fetchWarehouse: () => dispatch(getListWarehouse()),
		setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	};
};

class InventoryScanContainer extends SearchableComponent {
	componentDidMount() {
		super.componentDidMount();
		this.props.fetchWarehouse()
	}

	onChangeFilter(filter) {
		this.props.fetchInventory(filter.id)
	}

	render() {
		return <InventoryScan {...this.props}/>
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryScanContainer);
