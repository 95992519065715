import * as ACTION from './constants';
import apiService from './apiService';
import {processApiRequest} from '../../Common/saga';
import {makeModelStateFromApiRes} from "../../Common/services/helps";
import {BARCODE_TYPE} from "../../DeliveryNote/DeliveryNoteBarcode/constants";
import {setBarcodes} from "../../DeliveryNote/DeliveryNoteBarcode/actions";
import {put} from 'redux-saga/effects';
import lodash from "lodash"

export function* fetchDeliveryRequests(action) {
	yield processApiRequest(ACTION.FETCH_DELIVERY_REQUESTS, () => apiService.getDeliveryRequests(action.payload), action.payload);
}

export function* fetchDeliveryRequestsByCustomer(action) {
	yield processApiRequest(ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER, () => apiService.getDeliveryRequestsByCustomer(action.payload), action.payload);
}

export function* fetchDeliveryRequestsSuccess(action) {
	if (action.request.from === 'delivery_note') {
        let code_delivery_request = action.payload.delivery_requests.map(deliveryRequest => lodash.get(deliveryRequest, 'delivery_request.code'));
		yield processApiRequest(ACTION.FETCH_LIST_PACKAGE_EXPORT, () => apiService.getPackageExport({
			code_delivery_request,
			agency: action.request.agency
		}));
	}
}

export function* fetchDeliveryCustomerRequests(action) {
	yield processApiRequest(ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS, () => apiService.getDeliveryCustomerRequests(action.payload), action.payload);
}

export function* onFetchPackageExportRequest(action) {
	yield processApiRequest(ACTION.FETCH_LIST_PACKAGE_EXPORT, () => apiService.getPackageExport(action.payload), action.payload);
}

export function* onFetchPackageExportSuccess(action) {
	let barcodes = action.payload.packages.map(packageRes => {
		let packageData = makeModelStateFromApiRes(packageRes, 'package');
		return {
			code: packageData.code,
			type: BARCODE_TYPE.PACKAGE,
			object: packageData,
		};
	});
	yield put(setBarcodes(barcodes));
}
