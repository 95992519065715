import { Col, Form, InputNumber, Row } from 'antd'
import map from 'lodash/map'
import React, { useEffect, useState } from 'react'

import { get, groupBy, sumBy } from 'lodash'
import { t } from '../../../../system/i18n'
import { getVar } from '../../../../system/support/helpers'
import { currentFormatter } from '../../../Common/services/format'
import InputNumberIv from '../components/InputNumberIv'

const PackageItemOnlyGroup = ({ canEdit, isOriginPackage, originPackage, products, onChange, keyZh, groupProductDetachSuggestItems, onEnter }) => {
    const originPackageItems = getVar(originPackage, 'items', [])
    const originItem = {
        received_quantity: sumBy(originPackageItems, 'received_quantity'),
    }
    const [product, setProduct] = useState({})

    useEffect(() => {
        let totalQuantity = 0
        let totalValue = 0

        //Tính tổng số lượng và tổng giá trị tiền hàng của cả hai kiện
        for (let i = 0; i < products.length; i++) {
            totalQuantity += products[i].received_quantity
            totalValue += products[i].received_quantity * products[i].unit_price
           
        }
        //Tính đơn giá của cả hai kiện gộp lại
        let unitPrice = totalValue / totalQuantity

        const data = {
            code_item: get(products, '0.code_item'),
            product_image: get(products, '0.product_image'),
            original_name: get(products, '0.original_name'),
            variant_properties: get(products, '0.variant_properties'),
            received_quantity: sumBy(products, 'received_quantity'),
            unit_price: unitPrice,
            total_amount: totalValue,
        }

        setProduct({ ...data })
    }, [products])

    const handleChange = value => {
        onChange(value)
    }

    return (
        <li
            className={`product-item _product-item-0`}
            key={keyZh}>
            <Row gutter={8}>
                <Col lg={{ span: 2 }}>
                    <img
                        alt=""
                        src={product.product_image}
                    />
                    {/* <div>{product.code_item}</div> */}
                </Col>
                <Col lg={{ span: 6 }}>
                    <a
                        href={product.url}
                        className="_product-name"
                        rel="noopener noreferrer"
                        target="_blank">
                        {product.original_name}
                    </a>
                    <div>{map(product.variant_properties, 'value').join(', ')}</div>
                </Col>
                <Col
                    className="product-quantity"
                    lg={{ span: 6 }}>
                    <p>{t(`package:label.number_product_in_${isOriginPackage ? 'origin' : 'splitted'}_package`)}</p>
                    {canEdit ? (
                        <div>
                            {isOriginPackage ? (
                                <InputNumberIv
                                    // onPressEnter={onEnter}
                                    value={product.received_quantity}
                                    onChange={handleChange}
                                    style={{ width: 80 }}
                                />
                            ) : (
                                <InputNumber
                                    onChange={handleChange}
                                    min={0}
                                    value={product.received_quantity}
                                />
                            )}

                            {!isOriginPackage && (
                                <>
                                    <span className="ml-4 mr-2">/</span>
                                    {sumBy(get(groupProductDetachSuggestItems, keyZh, []), 'received_quantity')}
                                </>
                            )}
                        </div>
                    ) : (
                        <>{product.received_quantity || 0}</>
                    )}
                </Col>
                <Col
                    className="product-price"
                    lg={{ span: 5 }}>
                    <p>{t('package:label.product_price')}</p>
                    <b>{currentFormatter.toLocaleStringCurrency(product.unit_price)} VNĐ</b>
                </Col>
                <Col
                    className="total-amount"
                    lg={{ span: 5 }}>
                    <p>{t('package:label.price')}</p>
                    <b>{currentFormatter.toLocaleStringCurrency(product.total_amount)} VNĐ</b>
                </Col>
            </Row>
        </li>
    )
}

export default PackageItemOnlyGroup
