import React from 'react';
import {connect} from 'react-redux';
import {message} from 'antd';
import {url} from '../../../system/routing';
import {t} from '../../../system/i18n';
import authService from '../authService';

class LoginCallback extends React.PureComponent {
    componentDidMount() {
        this.props.auth();
    }

    render() {
        return null;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () =>({
    auth: () => {
        const token = url.getQuery('token');

        if (!token) {
            message.error(t('auth:message.login_token_invalid'), 0);
            return;
        }

        authService.loginSuccess(token);
        message.success(t('auth:message.auth_success'));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginCallback);
