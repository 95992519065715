import React from 'react';
import {Layout} from 'antd';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import FormSearch from './FormSearch';
import ListDeliveryNoteTab from './ListDeliveryNoteTab';
import pageService from "../../../Common/Page/pageService";
import Empty from './Empty';
import lodash from "lodash";
import DetailDeliveryRequest from "../../DetailDeliveryRequest/DetailDeliveryRequestContainer"

const {Content} = Layout;


class ListDeliveryRequest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenListSetting: false,

        }
    }

    componentDidMount() {
        pageService.setTitle(this.props.t('delivery_request:label.list_delivery_request'));
        this.props.setMenuActive("delivery-requests");
    }

    componentWillUnmount() {
        this.props.onResetDeliveryRequest();
        this.props.onResetDeliveryCustomerRequest();
    }

    renderContent() {
        let select_warehouse = localStorage.getItem("selectedDeliveryRequestWarehouse_" + this.props.user_id);
        return (lodash.isNil(select_warehouse) || lodash.isUndefined(select_warehouse) || select_warehouse === '') ?
            <Empty/>
            :
            <ListDeliveryNoteTab
                deliveryRequests={this.props.deliveryRequests}
                loading={this.props.loading}
                onChangeFilter={this.props.onChangeFilter}
                onSearch={this.props.onSearch}
                onResetDeliveryRequest={this.props.onResetDeliveryRequest}
                onResetDeliveryCustomerRequest={this.props.onResetDeliveryCustomerRequest}
                user_id={this.props.user_id}
                pagination={this.props.pagination}
                filter={this.props.filter}
                fetchDeliveryRequests={this.props.fetchDeliveryRequests}
                fetchDeliveryRequestsByCustomer={this.props.fetchDeliveryRequestsByCustomer}
                showDetailDeliveryRequest={this.props.showDetailDeliveryRequest}
                fetchListPackageExport={this.props.fetchListPackageExport}
                listDeliveryCustomerRequest={this.props.listDeliveryCustomerRequest}
                customers={this.props.customers}
                permissions={this.props.permissions}
                onSuccessUpdateDeliveryRequest={this.props.onSuccessUpdateDeliveryRequest}
            />
    }

    handleSuccessDeliveringDeliveryRequest = () => {
        const { filter, onChangeFilter } = this.props;

        onChangeFilter(filter);
    };

    render() {
        let select_warehouse = localStorage.getItem("selectedDeliveryRequestWarehouse_" + this.props.user_id);
        let isNil = (lodash.isNil(select_warehouse) || lodash.isUndefined(select_warehouse) || select_warehouse === '');
        return (
            <Layout>
                <Content
                    className={`a-content a-content--page-list ${isNil && "a-content--height-not-header"} gflex gflex-direction-column `}>
                    <FormSearch
                        input={this.props.filter}
                        inputVersion={lodash.toInteger(this.props.filter.i)}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                        object={this.props.object}
                        user_id={this.props.user_id}
                    />
                    {this.renderContent()}
                    <DetailDeliveryRequest
                        customers={this.props.customers}
                        route={this.props.route}
                        onSuccessDeliveringDeliveryRequest={this.handleSuccessDeliveringDeliveryRequest}
                    />
                </Content>
            </Layout>

        )
    }
}


ListDeliveryRequest.defaultProps = {
    deliveryRequests: [],
    loading: false,
    filter: {},
};

ListDeliveryRequest.propTypes = {
    deliveryRequests: PropTypes.array,
    filter: PropTypes.object,
    onReset: PropTypes.func,
    setMenuActive: PropTypes.func,
    onSearch: PropTypes.func,
    loading: PropTypes.object,
    showDetailDeliveryRequest: PropTypes.func,
};

export default withTranslation()(ListDeliveryRequest);
