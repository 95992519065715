import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from "lodash";
import * as actions from './actions';
import * as selectors from './selectors';
import FormBagSize from './components';
import * as commonAction from "../../Common/actions";

const mapStateToProps = (state) => ({
    errors: selectors.getErrors(lodash.get(state, "bagSize.formBagSize.errors", {})),
    loading: !!lodash.get(state, "bagSize.formBagSize.loading", false)
});

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    clearState: () => dispatch(actions.clearState()),
    resetError: (params) => dispatch(actions.resetError(params))
});

class FormBagSizeContainer extends React.Component {

	componentDidMount() {
        this.props.setMenuActive("config/bag-sizes");
    }

    render() {
        return <FormBagSize {...this.props}/>
    }
}

FormBagSizeContainer.defaultProps = {
    errors: {},
    loading: false
};

FormBagSizeContainer.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormBagSizeContainer);
