import React, { memo } from 'react';

import { dateFormatter } from './../../../../Common/services/format';
import Link from './../../../../../system/routing/Link';
import { getVar } from './../../../../../system/support/helpers';

const SuccessItem = ({ index, item }) => (
	<tbody key={index}>
		<tr>
			<td>{index + 1}</td>
			<td>
				<Link
					params={{id: item.inventory_package.code_package}}
					to="packages.detail"
				>
					{item.inventory_package.code_package}
				</Link>
			</td>
			<td>{getVar(item, 'inventory_package.created_at', '--') === '--' ? '--' : dateFormatter.dateTime(item.inventory_package.created_at)} </td>
			<td>{getVar(item, 'inventory_package.code_warehouse', '--')}</td>
			<td>
				{getVar(item, 'destination_warehouse.name', '--')}
			</td>
		</tr>
	</tbody>
);

export default memo(SuccessItem);
