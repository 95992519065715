import React, { Component } from 'react'
import { Col, Row, Typography } from 'antd'
import { get, isNil, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'

// import ProductDetail from './PrintBagStam p/ProductDetail';
// import ShipmentDetail from './PrintBagStamp/ShipmentDetail';

const { Paragraph, Text, Title } = Typography

class TemplateDefault extends Component {
    componentDidMount() {
        this.props.onDidMount()
    }

    render() {
        const { bag, warehouse_input, product, batchStamp, numberStamp } = this.props

        return range(numberStamp || 1).map((item, index) => (
            <div key={index}>
                {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
                <div className="print-bag-stamp">
                    <div className="a-flex mb-5">
                        <div className="width-50-pc mx-4">
                            <div className="a-flex">
                                <div className="width-35-pc">
                                    <Paragraph className="mb-2">Created date:</Paragraph>
                                </div>
                                <div className="width-65-pc">
                                    <Paragraph className="mb-2">{moment(get(bag, 'created_at')).format('DD - MM - YYYY')}</Paragraph>
                                </div>
                            </div>
                            <div className="a-flex">
                                <div className="width-35-pc">
                                    <Paragraph className="mb-0">Export date:</Paragraph>
                                </div>
                                <div className="width-65-pc">
                                    <Paragraph className="mb-0">
                                        {!isNil(get(bag, 'shipping_at')) ? moment(get(bag, 'shipping_at')).format('DD - MM - YYYY') : ''}
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                        <div className="width-50-pc mx-4">
                            <Barcode
                                width={244 / (46 + 11 * (get(batchStamp, 'code', '').length - 1))}
                                height={80}
                                margin={0}
                                displayValue={false}
                                value={get(batchStamp, 'code')}
                                copyStyles={true}
                            />
                        </div>
                    </div>

                    <div className="a-flex border-b">
                        <div className="width-35-pc mx-4">
                            <Paragraph className="mb-2">Bag no:</Paragraph>
                        </div>
                        <div className="width-100-pc mx-4">
                            <Title
                                className="mb-2"
                                level={1}>
                                {get(batchStamp, 'code')}
                            </Title>
                        </div>
                    </div>

                    <div className="a-flex border-b">
                        <div
                            className="a-flex galign-center gjustify-center tile width-25-pc"
                            style={{ minWidth: 132 }}>
                            <Paragraph className="mb-0 a-text a-text--uppercase">From</Paragraph>
                        </div>
                        <div className="p-2 tile-bl w-75-pc">
                            <Title
                                className="mb-2"
                                level={4}>
                                {get(batchStamp, 'from', '')}
                            </Title>
                        </div>
                    </div>
                    <div className="a-flex border-b">
                        <div
                            className="a-flex galign-center gjustify-center tile width-25-pc"
                            style={{ minWidth: 132 }}>
                            <Paragraph className="mb-0 a-text a-text--uppercase">To</Paragraph>
                        </div>
                        <div className="p-2 tile-bl w-75-pc">
                            <Title
                                className="mb-2"
                                level={3}>
                                {get(batchStamp, 'to')}
                            </Title>
                        </div>
                    </div>

                    <div className="row border-b pl-5">
                        <div
                            className="a-flex galign-center gjustify-center tile width-25-pc"
                            style={{ minWidth: 132 }}></div>
                        <div>
                            <Title
                                className="mb-2"
                                level={3}>
                                {get(batchStamp, 'agency')}
                            </Title>{' '}
                        </div>
                    </div>
                    {/* <ShipmentDetail bag={bag} />

                <ProductDetail product={product} /> */}

                    <Row className="a-flex gjustify-space-between px-2 py-2">
                        <Col span={8}>
                            <div className="a-flex galign-center gjustify-center">Total number of packages</div>
                            <div className="a-flex galign-center gjustify-center">
                                <span className="a-text--bold a-text--fz-16"> {get(bag, 'num_package')}</span>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="a-flex galign-center gjustify-center">Actual Weight</div>
                            <div className="a-flex galign-center gjustify-center">{get(bag, 'weight_real')} kg</div>
                        </Col>

                        <Col span={4}>
                            <div className="a-flex galign-center gjustify-center">Volume</div>
                            <div className="a-flex galign-center gjustify-center">{get(bag, 'volume')} m3</div>
                        </Col>

                        <Col span={6}>
                            <div className="a-flex galign-center gjustify-center">Chargeable weight</div>
                            <div className="a-flex galign-center gjustify-center"> {get(batchStamp, 'chargeable_weight')} Kg</div>
                        </Col>
                    </Row>
                </div>
            </div>
        ))
    }
}

TemplateDefault.defaultProps = {
    onDidMount: () => {},
}

TemplateDefault.propTypes = {
    onDidMount: PropTypes.func,
}

export default TemplateDefault
