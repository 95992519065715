import { combineReducers } from 'redux';

import * as ACTION from './constants';

const suggest = (state = {}, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.FETCH_SUGGEST_BOX_SIZES.SUCCESS: {
            return {
                ...payload
            };
        }
        case ACTION.FETCH_SUGGEST_BOX_SIZES.FAILED:
        case ACTION.CLEAR_SUGGEST_BOX_SIZES: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SUGGEST_BOX_SIZES.REQUEST: {
            return true;
        }
        case ACTION.FETCH_SUGGEST_BOX_SIZES.SUCCESS:
        case ACTION.FETCH_SUGGEST_BOX_SIZES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const suggestsByVolume = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_SUGGESTS_BY_VOLUME.SUCCESS: {
            return payload;
        }
        case ACTION.GET_SUGGESTS_BY_VOLUME.FAILED:
        case ACTION.CLEAR_SUGGEST_BOX_SIZES:
        case ACTION.CLEAR_SUGGEST_BY_VOLUME_BOX_SIZES: {
            return [];
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    suggest,
    loading,
    suggestsByVolume
});
