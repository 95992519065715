import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button} from 'antd';

class CreatePackageSameBarcode extends React.PureComponent {
    componentWillUnmount() {
        this.props.onReset();
    }

    render() {
        let {t} = this.props;
        return (
            <Button
                type="primary"
                className="a-btn a-btn--primary a-btn--create-package-same-bill-code _create_package_same_barcode"
                loading={this.props.loading}
                disabled={!this.props.canCreate}
                onClick={this.props.onCreate}
            >{t('package:create_same_barcode.btn_create')}</Button>
        );
    }
}

CreatePackageSameBarcode.defaultProps = {
    canCreate: false,
    loading: false,
    onCreate: () => {},
};

CreatePackageSameBarcode.propTypes = {
    canCreate: PropTypes.bool,
    loading: PropTypes.bool,
    onCreate: PropTypes.func,
};

export default withTranslation()(CreatePackageSameBarcode);
