import { endsWith, isNaN, isString } from 'lodash';
import numeral from 'numeral';

export default class CurrentFormatter {
    converseFormat(input) {
        return (input).toString().replace(".", ",");
    }

    formatMoney(n, c, d, t) {
        c = isNaN(c = Math.abs(c)) ? 0 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        let s = n < 0 ? "-" : "",
            i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c), 10)),
            j = i.length;
        j = (i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    /**
     * Hàm làm đẹp các con số tổng theo dạng K, M, B (ie: 100k, 1M)
     */
    prettyNumber(num, digits) {
        var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
            decimal;

        for (var i = units.length - 1; i >= 0; i--) {
            decimal = Math.pow(1000, i + 1);

            if (num <= -decimal || Math.abs(num) >= decimal) {
                return +(num / decimal).toFixed(digits) + units[i];
            }
        }

        return num;
    }

    //Nên dùng hàm này chuẩn nhé
    toLocaleStringCurrency(numberInput = 0, numberUnit = 2) {
        if (numberInput === '') {
            return numberInput;
        }

        const endWithDot = isString(numberInput) && endsWith(numberInput, '.');

        return `${numeral(numberInput).format(`0,0.[${'0'.padEnd(numberUnit, '0')}]`)}${endWithDot ? '.' : ''}`;
    }

    //Hàm chuẩn đơn vị VND
    toLocaleStringCurrencyVND(numberInput = 0, numberUnit = 2) {
        let currentcy = (numberInput ? numberInput.toLocaleString('en-US', { maximumFractionDigits: numberUnit }).toString() : 0);
        return (numberInput >= 0 ? "+ " : " ") + currentcy + " đ";
    }
}
