import React from 'react';
import { Alert, Icon } from 'antd';
import clsx from 'clsx';

import NoticeItem from './Notice';

const Notices = ({ className, displayIcon, notices }) => {
    if (!notices || !notices.length) {
        return null;
    }

    return (
        <Alert
            className={clsx(className, 'notice-list')}
            message={notices.map((notice, index) => (
                <div className="a-flex galign-center" key={notice}>
                    {!!displayIcon && (
                        <Icon className="a-text--orange mr-2" theme="filled" type="info-circle" />
                    )}
                    <NoticeItem className={clsx({
                        'mb-0': index === notices.length - 1
                    })} key={index} notice={notice} />
                </div>
            ))}
            type="notice"
        />
    );
};

export default Notices;
