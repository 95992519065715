import * as selectorsInventoryScan from "../InventoryScan/selectors";
import * as selectorsInventoryBarcode from "../InventoryBarcode/selectors";
import * as selectorsWarehouse from "../../Warehouse/selectors";
import * as selectorsUpdateStatusInventory from "./selectors";
import {connect} from "react-redux";
import UpdateStatusInventory from "./components";
import * as actions from "./actions";
import * as lodash from "lodash";

const mapStateToProps = (state) => {
	return {
		loading: selectorsInventoryScan.getState(state, 'loading') ||
			selectorsWarehouse.getState(state, 'loading') ||
			selectorsInventoryBarcode.getState(state, 'loading') ||
			selectorsUpdateStatusInventory.getState(state, 'loading'),
		inventoryChecking: selectorsInventoryScan.getState(state, 'detail.inventory'),
		inventoryProcessing: lodash.get(state, 'inventory.detail.inventory', {})
	}
};
const mapDispatchToProps = (dispatch) => {
	return {updateStatusInventory: (data) => dispatch(actions.updateStatusInventory(data))};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateStatusInventory);
