import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { get, isNil, uniqueId } from 'lodash';

import { getVar } from './../../../../../system/support/helpers';
import { trans } from './../../../../../system/i18n';
import { dateFormatter } from './../../../../Common/services/format';

const { Paragraph, Text } = Typography;

const NOTE_ACTIONS = {
    CREATE: 'NOTE:CREATE',
    UPDATE: 'NOTE:UPDATE',
    DELETE: 'NOTE:DELETE'
};

const NoteLogMessage = ({ log }) => {
    const action = get(log, 'action', '');
    const changeFields = get(log, 'payload.change_fields', {});

    const updatedFields = Object.keys(changeFields).map(field => {
        let oldValue, newValue, add, remove;

        switch (field) {
            case 'expired_at': {
                oldValue = dateFormatter.date(changeFields[field].old);
                newValue = dateFormatter.date(changeFields[field].new);

                break;
            }
            case 'stages': {
                add = getVar(changeFields[field], 'add');
                remove = getVar(changeFields[field], 'remove');

                if (add && add.length) {
                    add = add.map((item, index) => (
                        <Fragment key={item}>
                            <b>{trans(`note:stages.${item}`)}</b>
                            {index !== add.length - 1 && ', '}
                        </Fragment>
                    ))
                }

                if (remove && remove.length) {
                    remove = remove.map((item, index) => (
                        <Fragment key={item}>
                            <b>{trans(`note:stages.${item}`)}</b>
                            {index !== remove.length - 1 && ', '}
                        </Fragment>
                    ))
                }

                break;
            }
            default: {
                oldValue = getVar(changeFields[field], 'old');
                newValue = getVar(changeFields[field], 'new');
                add = getVar(changeFields[field], 'add.length') ? changeFields[field].add : undefined;
                remove = getVar(changeFields[field], 'remove.length') ? changeFields[field].remove : undefined;

                break;
            }
        }

        return {
            translatedField: trans(`log:note.fields.${field}`),
            oldValue,
            newValue,
            add,
            remove,
            isOldNew: !!(oldValue || newValue),
            isAddRemove: !!(add || remove)
        };
    });

    const renderOldNew = updatedField => {
        if (!updatedField.isOldNew) {
            return null;
        }

        return (
            <Paragraph className="mb-2" key={uniqueId()}>
                {trans('log:note.actions.update')}
                <Text className="mx-2" strong>{updatedField.translatedField}</Text>
                {isNil(updatedField.oldValue) ? null : (
                    <>
                        {trans('log:from')}
                        <Text className="mx-2" strong>{updatedField.oldValue}</Text>
                    </>
                )}
                {trans('log:to')}
                <Text className="ml-2" strong>{updatedField.newValue}</Text>
            </Paragraph>
        );
    };

    const renderAddRemove = updatedField => {
        if (!updatedField.isAddRemove) {
            return null;
        }

        return (
            <>
                {updatedField.add ? renderAdd(updatedField) : null}
                {updatedField.remove ? renderRemove(updatedField) : null}
            </>
        );
    };

    const renderAdd = updatedField => {
        return (
            <Paragraph className="mb-2" key={uniqueId()}>
                {trans('log:add', {
                    field: updatedField.translatedField,
                    new: updatedField.add
                })}
            </Paragraph>
        );
    };

    const renderRemove = updatedField => {
        return (
            <Paragraph className="mb-2" key={uniqueId()}>
                {trans('log:remove', {
                    field: updatedField.translatedField,
                    old: updatedField.remove
                })}
            </Paragraph>
        );
    };

    switch (action) {
        case NOTE_ACTIONS.CREATE: {
            return trans('log:note.actions.create');
        }
        case NOTE_ACTIONS.UPDATE: {
            const htmlArray = updatedFields.map((updatedField, index) => (
                <Fragment key={index}>
                    {renderOldNew(updatedField)}
                    {renderAddRemove(updatedField)}
                </Fragment>
            ));

            return htmlArray;
        }
        case NOTE_ACTIONS.DELETE: {
            return trans('log:note.actions.delete');
        }
        default: {
            return null;
        }
    }
};

export default NoteLogMessage;
