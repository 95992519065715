import { Button, Checkbox, Form, Icon, Modal } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { isShipment, isUnlinkOrder } from './../../../helper';
import withConsignServiceGroups from './../../../../../hocs/withConsignServiceGroups';

import AddProducts from './UpdateProductsModal/AddProducts';
import SelectServices from './UpdateProductsModal/SelectServices';
import UnlinkOrder from './../../../UnlinkOrder/UnlinkOrderContainer';

const mapStateToProps = state => ({
    unlinkingOrder: get(state, 'package.unlinkOrder.loading', false),
});

const UpdateProductsModal = ({
    consignServiceGroups,
    form,
    packageItems,
    pkg,
    t,
    units,
    updating,
    visible,
    onCancel,
    onOk,
    creating,
    loading,
    unlinkingOrder,
    updatePackageService,
}) => {
    const [expand, setExpand] = useState(true);
    const order = get(pkg, 'order', {});
    const { getFieldDecorator } = form

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const handleChangeServices = services => {
        updatePackageService(services);
    };

    const handleOk = (create_order = false) => {
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            const items = [];

            if (Array.isArray(values.sku)) {
                values.sku.forEach((sku, index) => {
                    items.push({
                        code_item: values.code_item[index],
                        name_zh: values.name_zh[index],
                        name_vi: values.name_vi[index],
                        url: values.url[index],
                        hscode: values.hscode[index],
                        sku: values.sku[index],
                        order_quantity: values.order_quantity[index],
                        unit_price_origin: values.unit_price_origin[index],
                        chinese_unit: values.chinese_unit[index],
                        vietnamese_unit: values.vietnamese_unit[index],
                    });
                });
            }

            try {
                await onOk(
                    {
                        items,
                        sync_all: values['sync_all']
                    },
                    create_order
                );

                handleCancel();
            } catch (error) {}
        });
    };

    const handleCancel = () => {
        form.resetFields();

        onCancel();
    };

    return (
        <Modal
        className='_checking-package-modal-update-product'
            footer={[
                <Button className="_btn-cancel" key="back" onClick={handleCancel}>
                    {t('btn.cancel')}
                </Button>,
                <Button
                    className="_btn-ok"
                    key="submit"
                    loading={updating || loading || unlinkingOrder}
                    type="primary"
                    onClick={() => handleOk(false)}
                >
                    {t('btn.update')}
                </Button>,
                isUnlinkOrder(pkg) ? (
                    <Button
                        className="_btn-ok"
                        key="submitCreateOrder"
                        loading={creating || loading || unlinkingOrder}
                        type="primary"
                        onClick={() => handleOk(true)}
                    >
                        {t('btn.update_and_create_order')}
                    </Button>
                ) : (
                    ''
                ),
            ]}
            title={t('update_products_for_package')}
            visible={visible}
            width={800}
            onCancel={handleCancel}
        >
            <div className="_package_bottom_action a-package--action border-top-none pt-0">
                <ul className="list-action list-action--left">
                    <li>
                        <label className="info--label _label mr-5">{t('package:label.order')}</label>
                        <span className={'_value'}>
                            {get(order, 'code') ? (
                                <a
                                    href={get(order, 'm1_url') || '#top'}
                                    rel="noopener noreferrer"
                                    target={get(order, 'm1_url') ? '_blank' : '_self'}
                                    className="_checking-package-modal-update-order-code"
                                >
                                    {get(order, 'code')}
                                </a>
                            ) : (
                                '--'
                            )}
                        </span>
                    </li>
                    {!isUnlinkOrder(pkg) && (
                        <li>
                            <UnlinkOrder packageData={pkg} />
                        </li>
                    )}
                </ul>
            </div>

            {expand && visible && (
                <SelectServices
                    consignServiceGroups={isShipment(pkg) ? consignServiceGroups : []}
                    form={form}
                    pkg={pkg}
                    onChange={handleChangeServices}
                />
            )}

            <>
                <div className="gflex gjustify-center">
                    <Button type="link" onClick={toggleExpand} className={`_checking-package-modal-update-collapse`}>
                        <Icon type={expand ? 'shrink' : 'arrows-alt'} />
                        {t(`btn.${expand ? 'collapse' : 'expand'}`)}
                    </Button>
                </div>

                <hr className="border-bottom border-top-none" />
            </>

            {visible && <AddProducts className="amt-16" form={form} packageItems={packageItems} units={units} />}

            {
                !isEmpty(order) && <Form.Item className="mt-4">
                        {getFieldDecorator(`sync_all`)(
                            <p><Checkbox /> {t(`update_all_package_same_freight_bill`)}</p>
                        )}
                    </Form.Item>
            }
            
        
        </Modal>
    );
};

export default Form.create()(withTranslation()(withConsignServiceGroups(connect(mapStateToProps)(UpdateProductsModal))));
