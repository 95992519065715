import React, {Component} from 'react';
import {Layout} from 'antd';
import ScanHistoryContainer from "../../ScanHistory/ScanHistoryContainer";
import ScanBarcodeFormContainer from "../../ScanBarcodeForm/ScanBarcodeFormContainer";
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import pageService from '../../../Common/Page/pageService';
import Sound from "../../../Sound";

class ScanBarcodePage extends Component {
    constructor(props) {
        super(props);
        this.state = {scroll: false};
    }

    componentDidMount() {
        pageService.setTitle(this.props.t('scan:label.title'), <Sound/>);
        this.props.setMenuActive('barcode/scan');
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false});
            }
        }
    };

    render() {
        let {scroll} = this.state;
        return (
            <Layout>
                <ScanBarcodeFormContainer scroll={scroll}/>
                <ScanHistoryContainer scroll={scroll}/>
            </Layout>
        );
    }
}

ScanBarcodePage.defaultProps = {
    user_id: null,
};

ScanBarcodePage.propTypes = {
    user_id: PropTypes.number,
};

export default withTranslation()(ScanBarcodePage);
