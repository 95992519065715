import React from 'react';
import { Layout } from 'antd';
import { get, isEqual, isFunction } from 'lodash';
import { withTranslation } from 'react-i18next';

import { DELIVERY_NOTE_STATUS } from './../../constants';
import { getCandeliveries } from './../../helper';

import DetailInfoForm from './../../DetailInfoForm/DetailInfoFormContainer';
import CreateDeliveryNote from './../../Create/components';
import FormDeliveryNote from './../../Create/components/FormDeliveryNote';
import PopupListPackageByBag from './../../Create/components/PopupListPackageByBag';

const { Content } = Layout;

class UpdateDeliveryNote extends CreateDeliveryNote {
    state = {
        canDeliveries: [],
        isShowPackageListByBagPopup: false,
        selectedBag: {}
    };

    handleOpenPackageListByBagPopup = bag => {
        this.setState({
            isShowPackageListByBagPopup: true,
            selectedBag: bag
        });
    };

    handleCancelPackageListByBagPopup = () => {
        this.setState({
            isShowPackageListByBagPopup: false,
            selectedBag: {}
        });
    };

    getInput = () => {
        const deliveryNote = this.props.deliveryNote || {};

        const input = {
            id_customer: get(deliveryNote, 'delivery_note.id_customer'),
            id_warehouse: get(deliveryNote, 'delivery_note.id_warehouse')
        };

        return input;
    };

    onChangeInput = input => {
        this.pushFilter(input);
    };

    togglePopupListPackageByBag = bag => {
        this.setState({
            bag: bag,
            isShowPopupListPackageByBag: !this.state.isShowPopupListPackageByBag
        }, () => {
            if (this.state.isShowPopupListPackageByBag) {
                this.props.fetchPackagesByBag(bag)
            }
        });
    };

    componentDidUpdate(prevProps) {
        const { bagIds, customerBagPackages, packageIds, deliveryNote, getLastmileCarrierSuggest} = this.props;

        if (!isEqual(bagIds, prevProps.bagIds) || !isEqual(customerBagPackages, prevProps.customerBagPackages) || !isEqual(packageIds, prevProps.packageIds)) {
            this.setState({
                canDeliveries: getCandeliveries(customerBagPackages, bagIds, packageIds)
            });
        }

        if (isFunction(getLastmileCarrierSuggest) && !isEqual(deliveryNote, get(prevProps, "deliveryNote", {}))) {
            const id_agency = get(deliveryNote, "agency.id")
            const id_warehouse = get(deliveryNote, "warehouse.id")
            getLastmileCarrierSuggest( {id_agency, id_warehouse })
            
        }

    }

    componentDidMount() {
        super.componentDidMount();

        const { bagIds, customerBagPackages, packageIds } = this.props;
        this.setState({
            canDeliveries: getCandeliveries(customerBagPackages, bagIds, packageIds)
        });
    }

    render() {
        const { canDeliveries, isShowPackageListByBagPopup, selectedBag } = this.state;
        const {
            bagIds,
            barcodes,
            customerDetailInfo,
            deliveryNote,
            errors,
            loading,
            loadingCustomerBagPackages,
            loadingCustomerDetailInfo,
            packageIds,
            updating,
            onRemoveReadyForDelivery,
            onSubmit,
            onReloadCustomerBagPackages,
            resetError
        } = this.props;
        const input = this.getInput();

        return (
            <Layout className="update-delivery-note has-sub-sider">
                {loading && (
                    <div className="a-loading-page__icon" />
                )}
                {deliveryNote ? (
                    <>
                        <FormDeliveryNote
                            canScanBarcode={![DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(get(deliveryNote, 'delivery_note.status'))}
                            deliveryNote={get(deliveryNote, 'delivery_note')}
                            input={input}
                        />
                        <Content className="a-sub-main bg-white gflex gflex-direction-column">
                            <DetailInfoForm
                                actionsClassName="a-content--action-fixed-bottom"
                                bags={bagIds}
                                canDeliveries={canDeliveries}
                                canUpdateReadyForDeliveries={![DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(get(deliveryNote, 'delivery_note.status'))}
                                customerDetailInfo={customerDetailInfo}
                                customerId={get(deliveryNote, 'customer.id')}
                                deliveryNote={deliveryNote}
                                errors={errors}
                                identity={get(deliveryNote, 'delivery_note.id')}
                                loadingCanDeliveries={loadingCustomerBagPackages}
                                loadingCustomerDetailInfo={loadingCustomerDetailInfo}
                                packages={packageIds}
                                readyForDeliveries={barcodes}
                                showPrint
                                showUpdate
                                updating={updating}
                                warehouseId={get(deliveryNote, 'delivery_note.id_warehouse')}
                                onOpenPackageListByBagPopup={this.handleOpenPackageListByBagPopup}
                                onReloadCanDeliveries={onReloadCustomerBagPackages}
                                onRemoveReadyForDelivery={onRemoveReadyForDelivery}
                                onSubmit={onSubmit}
                                resetError={resetError}
                            />
                        </Content>
                    </>
                ) : null}

                <PopupListPackageByBag
                    bag={selectedBag}
                    visible={isShowPackageListByBagPopup}
                    onCancel={this.handleCancelPackageListByBagPopup}
                />
            </Layout>
        );
    }
}

export default withTranslation()(UpdateDeliveryNote);
