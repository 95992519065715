import { combineReducers } from 'redux';
import { find, get, isEmpty, isObject } from 'lodash';

import { ADD_BARCODE, BARCODE_TYPE, CLEAR_STATE, REMOVE_BARCODE, SCAN_BARCODE, SET_BARCODES, UPDATE_BARCODE } from './constants';
import { getBarcodeError } from './selectors';
import { updateCollectionItem } from './../../Common/services/helps';

/**
 * Thêm mới barcode (xóa barcode cũ nếu tồn tại và thêm vào đầu danh sách)
 *
 * @param {Array} barcodes
 * @param {String} code
 * @param {Object} data
 * @return {Array}
 */
export const addBarcode = (barcodes, code, data) => {
    const barcode = find(barcodes, {
        code
    });

    let newBarcode = {...data, code};

    // Nếu barcode đã được quét thành công trước đó thì cảnh báo barcode đã được quét
    if (barcode && barcode.object && isEmpty(newBarcode.warning)) {
        let code = 'scan_package_scanned';

        if (barcode.type === BARCODE_TYPE.BAG) {
            code = 'scan_bag_scanned';
        }
        newBarcode = {
            ...newBarcode,
            warning: [{
                code
            }]
        };
    }

    if (!Array.isArray(barcodes)) {
        return [
            newBarcode
        ];
    }

    return [
        newBarcode,
        ...removeBarcode(barcodes, code)
    ];
};

export const removeBarcode = (barcodes, code) => barcodes.filter(barcode => barcode.code !== code);

const barcodes = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case SCAN_BARCODE.REQUEST: {
            const { code } = payload;

            return [{
                ...(find(state, {
                    code
                }) || {
                    code
                }),
                scanning: true,
            }, ...removeBarcode(state, payload.code)];
        }
        case SCAN_BARCODE.SUCCESS: {
            let _type = BARCODE_TYPE.PACKAGE;
            let object = payload.package;
            let warning = payload.package_warning;
            const _code = action.request.code;

            const data = {
                type: _type,
                object: object,
                order: payload.order,
                scanning: false,
                warning: warning,
                notices: get(payload, 'notices')
            };

            // If scan bag code
            if (isObject(payload.bag) && payload.bag.code === _code) {
                data.type = BARCODE_TYPE.BAG;
                data.object = {...payload.bag, bag_size: payload.bag_size || {}};
                data.warning = payload.bag_warning;
                data.packages = payload.packages;
            }

            return addBarcode(state, _code, data);
        }
        case SCAN_BARCODE.FAILED: {
            const listPackage = [
                ...state
            ];

            if (get(payload, 'data.warehouse')) {
                listPackage.shift();
            }

            return updateCollectionItem(listPackage, action.request.code, {
                scanning: false,
                error: getBarcodeError(payload),
            }, 'code');
        }
        case ADD_BARCODE: {
            return addBarcode(state, payload.code, payload);
        }
        case REMOVE_BARCODE: {
            return removeBarcode(state, payload.code);
        }
        case SET_BARCODES: {
            return payload.barcodes;
        }
        case UPDATE_BARCODE: {
            const index = state.findIndex(barcode => barcode.type === payload.type && barcode.code === payload.code);

            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        ...payload
                    },
                    ...state.slice(index + 1)
                ]
            }

            return state;
        }
        case CLEAR_STATE: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const scanning = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case SCAN_BARCODE.REQUEST: {
            return true;
        }
        case SCAN_BARCODE.SUCCESS:
        case SCAN_BARCODE.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    barcodes,
    scanning
});
