import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Icon, Modal} from 'antd';
import lodash from 'lodash';

class UnlinkOrder extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenUnlinkConfirm: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.isOpenUnlinkConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenUnlinkConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmUnlink() {
        let {t} = this.props;
        Modal.confirm({
            title: t("package:label.warning"),
            content: <p>{t("package:message.warning_confirm_unlink")}</p>,
            okText: t("package:label.ok"),
            cancelText: t("package:label.cancel"),
            onOk: this.handleOnOk.bind(this),
            onClose:this.handleOnClose.bind(this),
            icon: <Icon type="question-circle" theme="filled"/>
        });
        this.props.beginUnlinkOrder();
    }

    /**
     * handle action click ok to unlink order
     */
    handleOnOk() {
        let {packageData} = this.props;
        this.setState({isOpenUnlinkConfirm: true});
        this.props.onUnlinkOrder(packageData.code);
    }

    /**
     * handle action close modal
     */
    handleOnClose() {
        this.props.endUnlinkOrder();
    }

    render() {
        let {t, loading, packageData} = this.props;
        return lodash.get(packageData, 'permissions.can_unlink_order') ? (
            <span>

                <Button
                    loading={loading}
                    onClick={this.confirmUnlink.bind(this)}
                    className="a-btn--button-link a-text--blue _unlink_package">
                                                {t('package:label.unlink_order')}
                                            </Button>

            </span>
        ) : null;
    }
}

UnlinkOrder.defaultProps = {
    packageData: {},
    isModalProcessing: false,
    loading: false
};

UnlinkOrder.propTypes = {
    packageData: PropTypes.object,
    onUnlinkOrder: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginUnlinkOrder: PropTypes.func,
    endUnlinkOrder: PropTypes.func,

};

export default withTranslation()(UnlinkOrder);
