import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, notification, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { get } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import apiService from '../../../Package/apiService';
import { currentFormatter, dateFormatter } from './../../../Common/services/format';
import { useDeepCompareEffect } from './../../../../hooks';

import ComplaintSellerReasonsSelect from '../Selects/ComplaintSellerReasonsSelect';

const { TextArea } = Input;
const { Option } = Select;

const CreateComplaintSeller = ({ agencyId, form, packages, t, visible, onCancel, onOk }) => {
    const [complaintSellerReasons, setComplaintSellerReasons] = useState([]);
    const [adding, setAdding] = useState(false);
    const { getFieldDecorator } = form;

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleOk = () => {
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            setAdding(true);

            try {
                const formattedValues = {
                    ...values,
                    expire_time: dateFormatter.shortStandard(values.expire_time)
                };

                await apiService.addComplaintSeller(formattedValues);

                notification.success({
                    message: t('create_complaint_seller.success')
                });

                form.resetFields();
                onOk();
            } catch (error) {
                let message = t('create_complaint_seller.failed');

                if (get(error, 'response.data.data.packages')) {
                    const key = Object.keys(error.response.data.data.packages)[0];

                    message = t(`create_complaint_seller.errors.packages.${key}`, {
                        payload: error.response.data.data.packages[key]
                    });
                }

                if (get(error, 'response.data.data.complaint_seller_reason_id')) {
                    const key = Object.keys(error.response.data.data.complaint_seller_reason_id)[0];

                    message = t(`create_complaint_seller.errors.complaint_seller_reason_id.${key}`, {
                        payload: error.response.data.data.complaint_seller_reason_id[key]
                    });
                }

                notification.error({
                    message
                });
            }

            setAdding(false);
        });
    };

    const getComplaintSellerReasons = async agencyId => {
        try {
            const { data } = await apiService.getComplaintSellerReasons(agencyId);

            setComplaintSellerReasons(data.complaint_seller_reasons);
        } catch (error) {
            setComplaintSellerReasons([]);
        }
    };

    const disabledDate = current => current && current < moment().startOf('day');

    useEffect(() => {
        if (agencyId) {
            getComplaintSellerReasons(agencyId);
        }
    }, [agencyId]);

    useDeepCompareEffect(() => {
        if (visible) {
            form.setFieldsValue({
                packages: packages.map(pkg => pkg.id)
            });
        }
    }, [packages, visible]);

    return (
        <Modal
            centered
            className="_create-seller-complaint-modal"
            footer={[
                <Button className="_btn-cancel" key="cancel" onClick={handleCancel}>
                    {t('btn.cancel')}
                </Button>,
                <Button
                    className="_btn-save"
                    htmlType="submit"
                    key="submit"
                    loading={adding}
                    type="primary"
                    onClick={handleOk}
                >
                    {t('btn.ok')}
                </Button>
            ]}
            title={t('create_complaint_seller.title')}
            visible={visible}
            onCancel={handleCancel}
        >
            <Form>
                <Form.Item
                    className="_form-item-complaint-seller-reason-id"
                    label={t('cashback_reason')}
                >
                    {getFieldDecorator('complaint_seller_reason_id', {
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('complaint_seller')
                            })
                        }]
                    })(
                        <ComplaintSellerReasonsSelect
                            className="_complaint-seller-reason-id"
                            complaintSellerReasons={complaintSellerReasons}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    className="_form-item-amount"
                    label={t('amount')}
                >
                    {getFieldDecorator('amount')(
                        <InputNumber
                            className="_amount width-100-pc"
                            formatter={value => currentFormatter.toLocaleStringCurrency(value)}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    className="_form-item-packages"
                    label={t('package_list')}
                >
                    {getFieldDecorator('packages', {
                        defaultValue: packages,
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('package_list')
                            })
                        }]
                    })(
                        <Select
                            className="_packages"
                            disabled
                            mode="multiple"
                        >
                            {packages.map(pkg => (
                                <Option key={pkg.id} value={pkg.id}>
                                    {pkg.code}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    className="_form-item-expire-time"
                    label={t('processing_term')}
                >
                    {getFieldDecorator('expire_time', {
                        initialValue: moment().add(1, 'days'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('processing_term')
                            })
                        }]
                    })(
                        <DatePicker
                            className="_expire-time width-100-pc"
                            disabledDate={disabledDate}
                            format="DD/MM/YYYY"
                            locale={locale}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    className="_form-item-note"
                    label={t('note')}
                >
                    {getFieldDecorator('note')(
                        <TextArea
                            autosize={{
                                minRows: 1,
                                maxRows: 3
                            }}
                            className="_note"
                        />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default withTranslation(undefined, {
    withRef: true
})(Form.create()(CreateComplaintSeller));
