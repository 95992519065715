import React from 'react'
import { Button, Col, Collapse, Divider, Row } from 'antd'
import { t } from '../../../../system/i18n'
import { get, map } from 'lodash'
import { router } from '../../../../system/routing'

const { Panel } = Collapse

const WaitingDeliveryItem = ({ item }) => {

    return (
        <Collapse
            defaultActiveKey={['1']}
            className="mb-5">
            <Panel
                header={
                    <div className="gflex galign-center gjustify-space-between">
                        <span>
                            {item.code} - {get(item, 'midmile_shipping_partner.data.name')} - {get(item, 'customer.data.username')}
                        </span>
                        <Button
                            className="a-btn a-btn--primary"
                            type="primary"
                            onClick={()=> router.redirect('/bags-lastmile/create')}
                            
                            >
                            {t('bag:create.title')}
                        </Button>
                    </div>
                }
                key="1">
                {map(get(item, 'packages.data', []), (_, index) => {
                    return (
                        <Row
                            gutter={12}
                            className="mb-3"
                            key={index}>
                            <Col span={4}>
                                <div className="a-text--bold">{t('package:label.code')}</div>
                                <div>{get(_, 'code', '---')}</div>
                            </Col>
                            <Col span={4}>
                                <div className="a-text--bold">{t('package:label.weight')}</div> <div>{get(_, 'weight', '---')}</div>
                            </Col>
                            <Col span={4}>
                                <div className="a-text--bold">{t('package:label.dimension')}</div> <div>{get(_, 'volume', '---')} m3</div>
                            </Col>
                            <Col span={4}>
                                <div className="a-text--bold">{t('delivery_request:label.code_delivery_request')}</div> <div>{get(item, 'code', '---')}</div>
                            </Col>
                            <Col
                                span={4}
                                className="mb-3">
                                <div className="a-text--bold">{t('package:label.create_at')}</div> <div>{get(_, 'created_at', '---')}</div>
                            </Col>
                            <Col
                                span={4}
                                className="mb-3">
                                <div className="a-text--bold">{t('package:label.status')}</div> <div>{get(_, 'status_text', '---')}</div>
                            </Col>
                            <Divider />
                        </Row>
                    )
                })}
            </Panel>
        </Collapse>
    )
}

export default WaitingDeliveryItem
