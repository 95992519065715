import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Form, Button, Row, Col, Select, Icon, DatePicker, Input } from 'antd'
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { DOCUMENT_STATUS } from '../../constants'
import SelectUserContainer from '../../../User/SelectUser/SelectUserContainer'

const { Option } = Select

function Search(props) {
    const { t, filter, onSearch, defaultFilter, loading } = props
    const [input, setInput] = useState(filter)

    function onChangeInput(name, value) {
        setInput({
            ...input,
            [name]: value,
        })
    }

    function onChangeTime(name, value) {
        onChangeInput(name, value ? value.format('YYYY-MM-DD HH:mm:ss') : '')
    }

    function onReset(event) {
        event.preventDefault()
        if (!loading) {
            setInput(defaultFilter)
            onSearch(defaultFilter)
        }
    }

    function handleSubmit() {
        onSearch(input)
    }


    return (
        <div className="a-content--search-header search-default">
            <Form className="a-form">
                <Row gutter={{ lg: 12, xl: 24, xxl: 24 }}>
                    <Col
                        lg={6}
                        xl={4}
                        xxl={4}>
                        <Form.Item
                            className="a-form__item search-content item--special"
                            label={t(`return_goods:label.search_multi_code`)}
                            labelCol={{ span: 24 }}>
                            <Input.Search
                                onPressEnter={() => onSearch(input)}
                                value={input.code || ''}
                                onChange={e => onChangeInput('code', e.target.value)}
                                className="a-inputonChangeInput--search _delivery_note_search"
                                placeholder={t(`delivery_note:placeholder.code`)}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('return_goods:label.status')}
                            labelCol={{ span: 24 }}>
                            <Select
                                placeholder={t('delivery_note:label.status')}
                                className="a-select a-select--w-100 _subject_shipping"
                                showSearch={true}
                                optionFilterProp="children"
                                value={input.status || undefined}
                                onChange={value => onChangeInput('status', value)}>
                                {Object.keys(DOCUMENT_STATUS).map(item => (
                                    <Option
                                        className="_status_customer__item"
                                        key={item}
                                        value={item}>
                                        {t(`return_goods:status.${item}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col
                        lg={11}
                        xl={9}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('return_goods:label.created_at')}
                            labelCol={{ span: 24 }}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                        }}
                                        value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                        onChange={value => onChangeTime('created_at[from]', value)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                        }}
                                        value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                        onChange={value => onChangeTime('created_at[to]', value)}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col
                        lg={4}
                        xl={3}
                        xxl={3}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('return_goods:label.id_warehouse')}
                            labelCol={{ span: 24 }}>
                            <SelectWarehouse
                                allowClear
                                className="a-select a-select--search-content"
                                dropdownClassName="_dropdown_id_warehouse"
                                placeholder={t('return_goods:label.id_warehouse')}
                                value={input.id_warehouse}
                                onChange={id_warehouse => onChangeInput('id_warehouse', id_warehouse)}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={7}
                        xl={4}
                        xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('return_goods:label.id_creator')}
                            labelCol={{ span: 24 }}>
                            <SelectUserContainer
                                allowClear
                                className="a-select a-select--search-content _person_create_customer"
                                dropdownClassName="_dropdown_person_create_customer'"
                                emptyOption={false}
                                placeholder={t('return_goods:label.id_creator')}
                                value={input.id_creator}
                                onChange={id_creator => onChangeInput('id_creator', id_creator)}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        lg={7}
                        xl={4}
                        xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('return_goods:label.id_confirmation_agent')}
                            labelCol={{ span: 24 }}>
                            <SelectUserContainer
                                allowClear
                                className="a-select a-select--search-content _person_create_customer"
                                dropdownClassName="_dropdown_person_create_customer'"
                                emptyOption={false}
                                placeholder={t('return_goods:label.id_confirmation_agent')}
                                value={input.id_confirmation_agent}
                                onChange={id_confirmation_agent => onChangeInput('id_confirmation_agent', id_confirmation_agent)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="search--action">
                    <a
                        href="/"
                        className="link-reload "
                        onClick={onReset}>
                        <Icon type="sync" />
                        {t('common:label.refresh_filter')}
                    </a>
                    <Button
                        onClick={handleSubmit}
                        className="a-btn a-btn--primary a-btn--search-list"
                        loading={loading}>
                        {t('btn.search')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}
export default withTranslation()(Search)
