export const CREATE_BAG = {
    REQUEST: 'BAG.CREATE.REQUEST',
    SUCCESS: 'BAG.CREATE.SUCCESS',
    FAILED: 'BAG.CREATE.FAILED',
};
export const CLEAR_STATE = 'BAG.CREATE.CLEAR_STATE';
export const RESET_ERROR = "BAG.CREATE.RESET_ERROR";
export const DEFAULT_STATE_CREATE_BAG = {
    code: '',
    id_bag_size: undefined,
    id_shipping_user: undefined,
    id_shipping_partner: undefined,
    id_warehouse_input: undefined,
    id_warehouse_destination: undefined,
    receiver_name: undefined,
    receiver_phone: undefined,
    note: undefined,
    services: [],
    properties: [],
    id_customer: undefined,
    type: undefined,
    is_unchecked: false,
    subject_shipping: undefined,
    id_bag_template: undefined
};


export const GET_TEMPLATE_BAGS = {
    REQUEST: 'BAG.GET_TEMPLATE_BAGS.REQUEST',
    SUCCESS: 'BAG.GET_TEMPLATE_BAGS.SUCCESS',
    FAILED: 'BAG.GET_TEMPLATE_BAGS.FAILED',
};
