import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';
import {CREATE_SIMILAR_BAG, CREATE_TEMPLATE_BAG, UPDATE_BAG} from './constants';
import {REMOVE_PACKAGE} from "../DeletePackage/constants";

export default function* () {
    yield takeLatest(ACTION.FETCH_BAG_DETAIL.REQUEST, saga.fetchBagDetail);
    yield takeLatest(ACTION.FETCH_BAG_DETAIL.FAILED, saga.onFetchBagDetailFailed);
    yield takeLatest(ACTION.DETACH_PACKAGE.REQUEST, saga.detachPackage);
    yield takeLatest(ACTION.DETACH_PACKAGE.SUCCESS, saga.onDetachPackageSuccess);
    yield takeLatest(ACTION.DETACH_PACKAGE.FAILED, saga.onDetachPackageFailed);

    yield takeLatest(REMOVE_PACKAGE.SUCCESS, saga.removePackageSuccess);
    yield takeLatest(CREATE_SIMILAR_BAG.REQUEST, saga.createSimilarBagRequest);
    yield takeEvery(CREATE_SIMILAR_BAG.SUCCESS, saga.createSimilarBagSuccess);
    yield takeEvery(CREATE_SIMILAR_BAG.FAILED, saga.createSimilarBagFailed);

    yield takeLatest(CREATE_TEMPLATE_BAG.REQUEST, saga.createTemplateBagRequest);
    yield takeEvery(CREATE_TEMPLATE_BAG.SUCCESS, saga.createTemplateBagSuccess);
    yield takeEvery(CREATE_TEMPLATE_BAG.FAILED, saga.createTemplateBagFailed);

    yield takeLatest(UPDATE_BAG.REQUEST, saga.updateBag);
    yield takeLatest(UPDATE_BAG.SUCCESS, saga.updateBagSuccess);
    yield takeLatest(UPDATE_BAG.FAILED, saga.updateBagFailed);

    yield takeLatest(ACTION.EXPORT_BAG.REQUEST, saga.exportBag);
    yield takeLatest(ACTION.EXPORT_BAG.FAILED, saga.exportBagFailed);
    yield takeEvery(ACTION.EXPORT_BAG.SUCCESS, saga.exportBagSuccess);
}
