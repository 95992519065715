export const FETCH_DELIVERY_NOTE = {
    REQUEST: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.FETCH_DELIVERY_NOTE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.FETCH_DELIVERY_NOTE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.FETCH_DELIVERY_NOTE.FAILED',
};
export const UPDATE_DELIVERY_NOTE = {
    REQUEST: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.FAILED',
};
export const GET_CUSTOMER_BAG_PACKAGES = {
    REQUEST: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_BAG_PACKAGES.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_BAG_PACKAGES.SUCCESS',
    FAILED: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_BAG_PACKAGES.FAILED',
};
export const GET_CUSTOMER_DETAIL_INFO = {
    REQUEST: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_DETAIL_INFO.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_DETAIL_INFO.SUCCESS',
    FAILED: 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.GET_CUSTOMER_DETAIL_INFO.FAILED',
};
export const RESET_ERROR = 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.RESET_ERROR';

export const CLEAR_STATE = 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.CLEAR_STATE';

export const ADD_BARCODE = 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.ADD_BARCODE';
export const REMOVE_BARCODE = 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.REMOVE_BARCODE';
export const SET_BARCODES = 'DELIVERY_NOTE.UPDATE_DELIVERY_NOTE.SET_BARCODES';
