import React, {Component} from 'react';
import {Form, Input, Switch} from 'antd';
import {get} from 'lodash';
import {withTranslation} from 'react-i18next';

class BagAreaForm extends Component {
    render() {
        const {bagArea, form, formErrors, t, onChange} = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form>
                <Form.Item
                    className="a-hidden"
                    label={t('common:id')}
                >
                    {getFieldDecorator('id', {
                        initialValue: get(bagArea, 'bag_area.id')
                    })(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label={t('bag_area:area_name')}>
                    {getFieldDecorator('name', {
                        initialValue: get(bagArea, 'bag_area.name'),
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('bag_area:area_name')
                            })
                        }]
                    })(<Input autoFocus className="_name" />)}
                </Form.Item>

                <Form.Item
                    help={formErrors.code}
                    label={t('bag_area:area_code')}
                    validateStatus={formErrors.code ? 'error' : undefined}
                >
                    {getFieldDecorator('code', {
                        initialValue: get(bagArea, 'bag_area.code'),
                        normalize: value => value ? value.toUpperCase() : '',
                        rules: [{
                            required: true,
                            message: t('validation:required', {
                                attribute: t('bag_area:area_code')
                            })
                        }, {
                            len: 1,
                            message: t('validation:max.string', {
                                attribute: t('bag_area:area_code'),
                                max: 1
                            })
                        }, {
                            validator: async (rule, value, callback) => {
                                if (value && value.length && !/^[a-zA-Z]+$/.test(value)) {
                                    throw new Error(t('validation:form.rules.alpha', {
                                        attribute: t('bag_area:area_code')
                                    }));
                                }

                                callback();
                            }
                        }]
                    })(<Input className="_code" onChange={onChange} />)}
                </Form.Item>

                <Form.Item label={t('status')}>
                    {getFieldDecorator('active', {
                        initialValue: get(bagArea, 'bag_area.active', true),
                        valuePropName: 'checked'
                    })(<Switch className="_active" />)}
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(BagAreaForm);
