import {connect} from 'react-redux';
import page from './pageService';
import PageTitle from './components/PageTitle';

const mapStateToProps = (state) => ({
    title: page.title,
    description: page.description,
    titleVersion: state.common.page.titleVersion,
});

export default connect(
    mapStateToProps
)(PageTitle);
