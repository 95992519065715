/* eslint-disable */
import React, {Component} from 'react';
import {Layout, Button, Collapse, Input, Row, Col, Form, DatePicker, Mention, Icon} from 'antd';
const {Content} = Layout;

import SiderPackage from '../Sidebar/modules/SiderPackage';

/*End*/
class ScanEmpty extends Component {

    render() {

        return (
            <Layout>
                <SiderPackage/>
                <Content className="a-content a-content--package a-package__scan-empty">
                    <img src="https://cdn.dribbble.com/users/634336/screenshots/2246883/_____.png" alt=""/>
                    <span>Chưa có dữ liệu <br/> quét mã</span>
                </Content>
            </Layout>
        );
    }
}


export default ScanEmpty;
