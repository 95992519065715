import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Col, Form, Row} from 'antd';
import lodash from 'lodash';
import FormProcessing from '../../../Common/components/FormProcessing';
import PackageGeneralInfo from './PackageGeneralInfo';
import UpdatePackageParams from '../../UpdatePackageParams/UpdatePackageParamContainer'
import PackageServices from '../../FormPackageDetail/components/PackageServices';
import PackageCustomer from '../../FormPackageDetail/components/PackageCustomer';

import Notices from './../../../Common/components/notices/Notices';

import {convertUndefinedPropertyTo} from './../../../../system/support/helpers';

class UpdateRelatedPackage extends FormProcessing {
    state = {
        input: {
            status_transport: '',
            type: '',
            note: '',
            code: '',
            weight_net: '',
            length: '',
            width: '',
            height: '',
            note_status: undefined,
            return_address: undefined
        },
        inputVersion: 0,
        changedParams: [],
    };

    componentWillUnmount() {
        this.props.onReset();
    }

    changeInput(input) {
        const { errors, loading, onUpdate, resetError } = this.props;
        const result = super.changeInput(input);
        const params = lodash.keys(input);

        if (input.status_transport && errors.code) {
            resetError(['code']);
        } else if (!lodash.isEmpty(lodash.pick(errors, params))) {
            resetError(params);
        }

        if (lodash.get(input, 'isNeedSubmit', false) && !loading) {
            onUpdate({
                ...this.state.input,
                ...input
            });
        }

        return result;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            const { input } = this.state;
            const { boxSizes, form, onUpdate } = this.props;

            form.validateFields(error => {
                if (error) {
                    return;
                }

                const formattedInput = convertUndefinedPropertyTo(input, 0);

                if (!boxSizes || !boxSizes.length) {
                    formattedInput.id_box_size = null;
                    formattedInput.weight_box = 0;   
                }

                onUpdate(formattedInput);
            });
        }
    }

    render() {
        let {form, t, loading, errors, resetError} = this.props;
        let {input} = this.state;
        let packageData = this.props.package;

        return (
            <Form onSubmit={this.onSubmit.bind(this)} className="a-form form--package">
                <Notices
                    className="amb-16"
                    notices={lodash.get(packageData, 'notices.text', [])}
                />
                <Row gutter={{lg: 32, xl: 48}}>
                    <Col lg={{span: 18}} xl={{span: 19}} xxl={{span: 18}}>
                        <PackageGeneralInfo
                            input={input}
                            errors={errors}
                            package={packageData}
                            onChange={this.changeInput.bind(this)}
                        />
                        <UpdatePackageParams
                            className="amt-16"
                            form={form}
                            onSubmit={this.changeInput.bind(this)}
                            errors={lodash.pick(errors, ['weight_net', 'length', 'width', 'height', 'size_drc'])}
                            package={packageData}
                            services={lodash.map(packageData.services, 'id')}
                            isWapper={true}
                            validate={true}
                            resetError={resetError}
                        />
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 5}} xxl={{span: 6}}
                         className="a-package--service only-view">
                        <PackageServices services={packageData.services}/>
                    </Col>
                </Row>

                <PackageCustomer package={packageData}/>

                <Row gutter={48}>
                    <Col lg={{span: 24}} xl={{span: 24}}
                         className="a-package--action only-action">
                        <a href="/"
                           className="a-btn a-btn--back-package _back_package"
                           onClick={event => {
                               event.preventDefault();
                               this.props.onExit();
                           }}
                        >{t('btn.exit')}</a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--save-package _btn-save-package"
                            loading={loading}
                        >{t('btn.save')}</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

UpdateRelatedPackage.defaultProps = {
    package: {},
    input: {},
    errors: {},
    loading: null,
    onUpdate: () => {
    },
    onExit: () => {
    },
    onReset: () => {
    },
    resetError: () => {
    },
};

UpdateRelatedPackage.propTypes = {
    package: PropTypes.object,
    input: PropTypes.object,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    onUpdate: PropTypes.func,
    onExit: PropTypes.func,
    onReset: PropTypes.func,
    resetError: PropTypes.func,
};

export default withTranslation()(Form.create()(UpdateRelatedPackage));
