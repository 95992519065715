import { get, isEqual } from 'lodash'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { t } from '../../../system/i18n'

import { setMenuActive } from '../../Common/actions'
import SearchableComponent from '../../Common/components/SearchableComponent'
import pageService from '../../Common/Page/pageService'
import apiService from '../apiService'

import { DELIVERY_STATUS } from './constanst'
import Filter from './Filter'
import TabContainer from './TabContainer'

const mapStateToProps = state => {
    return {}
}

class ExpeditedProcessing extends SearchableComponent {
    state = {
        loading: false,
        pagination: {},
        data: [],
        defaultTab: DELIVERY_STATUS.NO_PROCESS,
        tabs: ['NO_PROCESS', 'PROCESSING', 'PROCESSED'],
    }

    componentDidMount() {
        pageService.setTitle(t('package:label.waiting_delivery_list'));
        setMenuActive('packages/waiting-delivery');
        this.fetchPackage(this.getCurrentFilter())
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchPackage(this.getCurrentFilter())
        }
    }

    onSearch = filter => {
        this.pushFilter(filter)
    }

    getDefaultFilter() {
        return {
            status: DELIVERY_STATUS.NO_PROCESS,
        }
    }

    getCurrentFilter = () => {
        const { defaultTab, tabs } = this.state
        const { location } = this.props
        const filter = {
            ...this.getDefaultFilter() ,
            ...this.getFilterFromLocation(location),
        }

        const tab = tabs.find(tab => tab.key === filter.tab)

        if (!tab) {
            filter.tab = defaultTab
        }

        return filter
    }

    fetchPackage = filters => {
        this.setState({
            loading: true,
        })
        apiService
            .getDeliveryRequest({ ...filters, include: 'packages,midmile_shipping_partner,customer' })
            .then(res => {
                this.setState({
                    data: get(res, 'data.data', []),
                    pagination: get(res, 'data.meta.pagination', {}),
                })
            })
            .catch(() => {})
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const { loading, pagination, data } = this.state

        return (
            <>
                <Filter
                    loading={loading}
                    filters={this.getCurrentFilter()}
                    onSearch={this.onSearch}
                />
                <TabContainer
                    filters={this.getCurrentFilter()}
                    onSearch={this.onSearch}
                    loading={loading}
                    pagination={pagination}
                    data={data}
                />
            </>
        )
    }
}

export default withTranslation()(
    connect(mapStateToProps, {
        setMenuActive,
    })(ExpeditedProcessing)
)
