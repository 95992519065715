import React, {Component} from 'react';
import {Table, Empty} from 'antd';
import {DELIVERY_NOTE_COLUMN} from '../../constants';
import lodash from 'lodash';
import {withTranslation} from 'react-i18next';
import ListDeliveryNoteSetting from '../../ListDeliveryNoteSettingContainer';
import Pagination from '../../../../Common/components/Pagination';
import PropTypes from 'prop-types';
import DownloadDeliveryNode from './DownloadDeliveryNode';
import authService from '../../../../Auth/authService';
import permissions from '../../../../Auth/permissions';

class ListDeliveryNote extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeColumns: [],
		};
	}

	getColumns() {
		let arrSort = [DELIVERY_NOTE_COLUMN.CREATE_AT, DELIVERY_NOTE_COLUMN.EXPORTED_AT, DELIVERY_NOTE_COLUMN.PACKAGE_WEIGHT, DELIVERY_NOTE_COLUMN.PACKAGE_NUMBER];
		return lodash.values(DELIVERY_NOTE_COLUMN)
			.filter(column => this.state.activeColumns.includes(column))
			.map(column => {

				let res = {
					className: column === DELIVERY_NOTE_COLUMN.CODE ? "a-text--nowrap _delivery_note_column" : "a-text--nowrap",
					key: column,
					dataIndex: column,
					title: this.props.t('delivery_note:column.' + column),
				};

				if (arrSort.indexOf(column) > -1) {
					res = {
						...res,
						sorter: true,
						sortOrder: column === this.props.filter.sort_by
							? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
							: null,
					}
				}
				return res;
			});
	}

	onChange(pagination, filters, sorter) {
		this.search({
			sort_by: sorter.field || 'created_at',
			sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
		});
	}

	search(input) {
		if (!this.props.loading) {
			this.props.onSearch({
				...this.props.filter,
				...input,
			});
		}
	}

	onChangePage(page, pageSize) {
		if (!this.props.loading) {
			this.props.onSearch({
				...this.props.filter,
				page,
				per_page: pageSize,
			});
		}
	}

	render() {
		const { filter, loading, pagination, t } = this.props;
        const canDownloadList = authService.can(permissions.DELIVERY_NOTE_DOWNLOAD_LISTING);
		return (
			<>
				<div className="a-content__top a-content__top--page-list mt-0">
					<div className="a-content--page-list__title">
						<h3>{t('delivery_note:label.list_delivery_note_CUSTOMER')} ({lodash.get(pagination, 'total', 0)})</h3>
					</div>
					<div className="a-content--page-list__top-action">
                        {canDownloadList && <DownloadDeliveryNode filter={filter}/>}
						<ListDeliveryNoteSetting
							onChange={activeColumns => this.setState({activeColumns})}
							loading={loading}
							isOpenListSetting={this.props.isOpenListSetting}
							onOpenListSetting={this.props.onOpenListSetting}
						/>
					</div>
				</div>
				<div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
					<div className="a-table--responsive">
						<Table
							loading={loading}
							className={"a-table--page-list"}
							columns={this.getColumns()}
							dataSource={this.props.deliveryNotes}
							pagination={false}
							rowKey={record => record.id}
							locale={{
								emptyText: (
									<Empty
										image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
										description={<span>{t("common:label.no_data")}</span>}
									>
									</Empty>)
							}}
							onChange={this.onChange.bind(this)}
						/>
					</div>
					<Pagination
						page={lodash.toInteger(filter.page) || 1}
						pageSize={lodash.toInteger(filter.per_page)}
						total={pagination ? pagination.total : 0}
						loading={this.props.loading}
						onChange={this.onChangePage.bind(this)}/>
				</div>
			</>
		)
	}
}

ListDeliveryNote.defaultProps = {
	isOpenListSetting: false,
};

ListDeliveryNote.propTypes = {
	options: PropTypes.array,
	values: PropTypes.array,
	onChange: PropTypes.func,
	isOpenListSetting: PropTypes.bool,
	onOpenListSetting: PropTypes.func
};


export default withTranslation()(ListDeliveryNote);
