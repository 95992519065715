import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Form} from 'antd';
import InputNumber from '../../../Common/components/InputNumber';
import lodash from 'lodash';

class UpdateWeightRealBag extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            weight_real: 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        if (!lodash.isEmpty(props.bag) && props.bag.id !== state.id) {
            nextState.id = props.bag.id;
            nextState.weight_real = props.bag.weight_real;
        }

        return nextState;
    }

    onChangeInputWeightReal(value) {
        value = value || null;
        let currentValue = this.state.weight_real || null;
        if (currentValue !== value) {
            this.setState({weight_real: value});
        }

        if (this.props.errors['weight_real']) {
            this.props.resetError();
        }
    }

    onUpdateWeightReal() {
        if (this.state.weight_real !== "") {
            this.props.onUpdateWeightReal({
                weight_real: this.state.weight_real,
                id: this.props.bag.id
            });
        }
    }

    render() {
        const {canUpdate, t, loading, errors} = this.props;

        return (
            <React.Fragment>
                <Form.Item className="a-form__item item--bag-pack " label={t("bag:label.weight_bag")}
                           labelCol={{span: 24}}
                           required={true}
                           help={errors.weight_real}
                           validateStatus={errors.weight_real ? 'error' : null}
                >
                    <InputNumber
                        placeholder={t("bag:placeholder.weight_real")}
                        loading={!!loading}
                        disabled={!canUpdate || !!loading}
                        precision={4}
                        min={0}
                        max={99999.9999}
                        onChange={this.onChangeInputWeightReal.bind(this)}
                        onPressEnter={this.onUpdateWeightReal.bind(this)}
                        name={"weight_real"}
                        className="a-input a-input--w-100 _input_bag_weight"
                        value={this.state.weight_real === 0 ? null : this.state.weight_real}/>
                </Form.Item>
            </React.Fragment>);
    }
}

UpdateWeightRealBag.defaultProps = {
    bag: {},
    loading: false,
    errors: {},
};

UpdateWeightRealBag.propTypes = {
    loading: PropTypes.bool,
    bag: PropTypes.object,
    errors: PropTypes.object,
};

export default (withTranslation()(UpdateWeightRealBag));
