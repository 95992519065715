import { takeLatest } from 'redux-saga/effects';

import { CREATE } from './constants';
import { create, failedCreate, successCreate } from './saga';

export default function*() {
    yield takeLatest(CREATE.REQUEST, create);
    yield takeLatest(CREATE.FAILED, failedCreate);
    yield takeLatest(CREATE.SUCCESS, successCreate);
}
