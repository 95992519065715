import React from 'react';
import { first, forEach, get, has, isObject, map } from 'lodash';
import { createSelector } from 'reselect';

import { BARCODE_TYPE } from './constants';
import { t, trans } from './../../../system/i18n';
import { Link } from './../../../system/routing';

export const getBarcodeError = error => {
    if (has(error, 'data.barcode.package_not_in_delivery_request')) {
        return t('scan:error.package_not_in_delivery_request');
    }
    
    if (has(error, 'data.barcode.package_not_of_customer')) {
        return t('scan:error.package_not_of_customer');
    }
    if (has(error, 'data.barcode.package_in_open_delivery_note')) {
        let deliveryNotes = error.data.barcode.package_in_open_delivery_note.delivery_note;
        return trans('scan:error.package_in_open_delivery_note', {
            code: map(deliveryNotes, (deliveryNote, i) => {
                return getListDeliveryNote(deliveryNote, i)
            })
        });
    }
    if (has(error, 'data.barcode.bag_in_open_delivery_note')) {
        let deliveryNotes = error.data.barcode.bag_in_open_delivery_note.delivery_note;
        return trans('scan:error.bag_in_open_delivery_note', {
            code: map(deliveryNotes, (deliveryNote, i) => {
                return getListDeliveryNote(deliveryNote, i)
            })
        });
    }
    if (has(error, 'data.barcode.package_in_single_bag')) {
        return trans('scan:error.package_in_single_bag', {
            code: get(error, 'data.barcode.package_in_single_bag.bag.code', '')
        });
    }
    if (has(error, 'data.barcode.package_in_multiple_bag_not_completed')) {
        return trans('scan:error.package_in_multiple_bag_not_completed', {
            code: get(error, 'data.barcode.package_in_multiple_bag_not_completed.bag.code', '')
        });
    }
    if (has(error, 'data.barcode.package_not_valid')) {
        return t('scan:error.package_not_valid');
    }
    if (has(error, 'data.barcode.bag_not_valid')) {
        return t('scan:error.bag_not_valid');
    }
    if (has(error, 'data.barcode.bag_multiple_is_completed')) {
        return t('scan:error.bag_multiple_is_completed');
    }
    if (has(error, 'data.barcode.package_in_bag_not_completed')) {
        return trans('scan:error.package_in_bag_not_completed', {
            code: get(error, 'data.barcode.package_in_bag_not_completed.bag.code', '')
        });
    }
    if (has(error, 'data.barcode.package_in_single_bag_not_completed')) {
        return trans('scan:error.package_in_single_bag_not_completed', {
            code: get(error, 'data.barcode.package_in_single_bag_not_completed.bag.code', '')
        });
    }
    if (has(error, 'data.barcode.package_in_cancelled_order')) {
        return trans('scan:error.package_in_cancelled_order');
    }

    if (has(error, 'data.barcode.bag_is_packing')) {
        return t('scan:error.bag_is_packing');
    }
    if (has(error, 'data.type.bag_type_not_valid')) {
        return t('scan:error.bag_type_not_valid');
    }
    if (has(error, 'data.barcode.status_invalid')) {
        return t(`scan:error.status_invalid.${error.data.barcode.status_invalid}`);
    }
    if (has(error, 'data.barcode.package_in_invalid_order')) {
        return t('scan:error.package_in_invalid_order');
    }
    if (has(error, 'data.status_warehouse.package_not_in_warehouse')) {
        return t('scan:error.package_not_in_warehouse');
    }
    if (has(error, 'data.WAIT_FOR_RETURN.status_package_different_wait_for_return')) {
        return t('scan:error.status_package_different_wait_for_return');
    }
    if (has(error, 'data.id_warehouse_scan.package_warehouse_current_different_warehouse_scan')) {
        return t('scan:error.package_warehouse_current_different_warehouse_scan');
    }
    if (has(error, 'data.id_warehouse_scan.required')) {
        return t('scan:error.id_warehouse_scan_required');
    }

    if (has(error, 'data.barcode.package_unlink_order')) {
        return t('scan:error.package_unlink_order');
    }

    if (has(error, 'data.barcode.package_delivery_without_destination')) {
        return t('scan:error.package_delivery_without_destination');
    }

    if (has(error, 'data.barcode.message')) {
        const message = get(error, "data.barcode.message");
        const packages = get(error, "data.barcode.packages", [])
        return t(`scan:error.${message}`, {packages: packages.join(", "), count: packages.length});
    }

    return t('scan:error.barcode_invalid');
};

const getListDeliveryNote = (deliveryNote, i) => (
    <Link
        isNewTab
        key={i}
        params={{
            id: deliveryNote
        }}
        to="delivery-notes.customer.detail"
    >
        {i > 0 && ', '}
        {deliveryNote}
    </Link>
);

export const getBarcodeWarning = (warning) => {
    const bag = first(map(warning, 'bag'));
    const pkg = first(map(warning, 'package'));
    const errorMessages = [];

    forEach(warning, (item) => {
        let code = item.code;
        // Báo lỗi khi quét 1 bao ở 1 kho khác
        if (code === 'ERROR_SCAN_BAG'
            && isObject(bag)
            && (bag.status_warehouse === "IN" || bag.status_warehouse === "OUT")) {
            errorMessages.push(trans(`scan:warning.scan_bag_from_other_warehouse_${bag.status_warehouse}`, {
                warehouse_code: bag.warehouse_current
            }));
        } else if (code === 'ERROR_SCAN_PACKAGE'
            && isObject(pkg)
            && (pkg.status_warehouse === "IN" || pkg.status_warehouse === "OUT")) {
            errorMessages.push(trans(`scan:warning.scan_package_from_other_warehouse_${pkg.status_warehouse}`, {
                warehouse_code: pkg.warehouse_current
            }));
        } else if (code === 'ERROR_SCAN_PACKAGE_IN_SINGLE_BAG_COMPLETED') {
            errorMessages.push(t(`scan:warning.error_scan_package_in_single_bag_complete`));
        } else {
            errorMessages.push(t('scan:warning.' + code.toLowerCase()));
        }
    });

    return errorMessages ? errorMessages.join(', ') : null;
};

export const getState = (state, param = null, defaultValue = null) => get(state, 'deliveryNote.deliveryNoteBarcode' + (param ? '.' + param : ''), defaultValue);

export const getBarcodes = createSelector(
    state => getState(state, 'barcodes', []),
    barcodes => barcodes.map(barcode => ({
        ...barcode,
        warning: getBarcodeWarning(barcode.warning)
    }))
);

export const getBags = createSelector(
    getBarcodes,
    barcodes => barcodes.filter(barcode => barcode.type === BARCODE_TYPE.BAG)
);

export const getPackages = createSelector(
    getBarcodes,
    barcodes => barcodes.filter(barcode => barcode.type === BARCODE_TYPE.PACKAGE)
);

export const getBagIds = createSelector(
    getBags,
    bags => bags.map(bag => get(bag, 'object.id'))
);

export const getPackageIds = createSelector(
    getPackages,
    packages => packages.map(pkg => get(pkg, 'object.id'))
);
