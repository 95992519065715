import { api } from '../../system/api'

export default {
    validPackage: params => api.get(`/return-notes/valid-package`, { params, singleRequest: true }),
    create: params => api.post('/return-notes', { ...params }),
    confirmCreate: params => api.post(`return-notes/valid-beafore-create`, { ...params }),
    detail: id => api.get(`return-notes/${id}`, { singleRequest: true }),
    confirm: id => api.post(`return-notes/${id}/confirm`),
    cancel: id => api.post(`return-notes/${id}/cancel`),
    update: (id, data) => api.put(`/return-notes/${id}`, data),
    getDeliveryNoteInfo: params => api.get(`return-notes/scan-delivery-note`, { params, singleRequest: true }),
    getReturnGoodsList: params => api.get('/return-notes', { params, singleRequest: true }),
    exportReturnGoodsList: params => api.get('/return-notes/download', { params, responseType: 'blob' }),
    addPackageToDocument: (id, data) => api.post(`/return-notes/${id}/add-package`, data),
    removePackageFromDocument: (id, packageId) => api.delete(`/return-notes/${id}/remove-package/${packageId}`),
}
