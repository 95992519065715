import React, { Component } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import { connect } from 'react-redux';

import { currentFormatter } from '../../services/format';
import { finishLoadPrintTemplate, loadPrintTemplate } from '../actions';

import ProductDetail from './PrintOfficialPackageStamp/ProductDetail';
import ShipmentDetail from './PrintOfficialPackageStamp/ShipmentDetail';

const { Paragraph, Text } = Typography;

class PrintPackageUnOfficialStamp extends Component {
    constructor(props) {
        super();

        props.loadPrintTemplate();
    }

    handleDidMount = () => {
        const { finishLoadPrintTemplate, onDidMount } = this.props;

        finishLoadPrintTemplate();
        onDidMount();
    };

    componentDidMount() {
        const { logo } = this.props;

        if (!logo) {
            this.handleDidMount();
        }
    }

    render() {
        const { custom_delegation, logo, max_value, official_profile, products, product_quantity, total_value, order, services, warehouse_services} = this.props;
        const packageData = this.props.package;
        let totalAmount = total_value > 0 && total_value < (max_value || 1000000) ? total_value : null;
        let fromName = '';
        let fromMobile = '';
        let fromAddress = '';
        let toName = '';
        let toMobile = '';
        let toAddress = '';

        if (get(custom_delegation, 'name')) {
            fromName = get(custom_delegation, 'name');
        }

        if (get(custom_delegation, 'tel')) {
            fromMobile = get(custom_delegation, 'tel');
        }

        if (get(custom_delegation, 'address')) {
            fromAddress = get(custom_delegation, 'address');
        }

        if (get(official_profile, 'full_name')) {
            toName = get(official_profile, 'full_name');
        } else if (get(packageData, 'customer_name')) {
            toName = get(packageData, 'customer_name');
        }

        if (get(official_profile, 'phone')) {
            toMobile = get(official_profile, 'phone');
        } else if (get(packageData, 'customer_phone_destination')) {
            toMobile = get(packageData, 'customer_phone_destination');
        }

        if (get(official_profile, 'address')) {
            toAddress = get(official_profile, 'address');
        } else if (get(packageData, 'customer_address_destination')) {
            toAddress = get(packageData, 'customer_address_destination');
        }

        return (
            <div className="print-package-unofficial-stamp">
                <div className="row border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            From
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        {!!fromName && (
                            <Paragraph className="mb-0">
                                Name: {fromName}
                            </Paragraph>
                        )}
                        {!!fromMobile && (
                            <Paragraph className="mb-0">
                                Mobile: {fromMobile}
                            </Paragraph>
                        )}
                        {!!fromAddress && (
                            <Paragraph className="mb-0">
                                Add: {fromAddress}
                            </Paragraph>
                        )}
                    </div>
                </div>

                <div className="a-flex border-bottom">
                    <div className="a-flex flex-shrink-0 galign-center gjustify-center tile width-15-pc">
                        <Paragraph className="mb-0 a-text a-text--uppercase">
                            To
                        </Paragraph>
                    </div>
                    <div className="p-2 tile">
                        <Paragraph className="mb-0">
                            Name: {toName}
                        </Paragraph>
                        <Paragraph className="mb-0">
                            Mobile: {toMobile}
                        </Paragraph>
                        <Paragraph className="mb-0">
                            Add: {toAddress}
                        </Paragraph>
                    </div>
                </div>

                <div className="a-flex a-text--nowrap">
                    <div className={clsx('a-flex mx-4', {
                        'width-45-pc': logo,
                        'width-25-pc galign-center gjustify-center': !logo
                    })}>
                        {logo ? (
                            <img
                                alt=""
                                className="partner-logo"
                                src={logo}
                                onLoad={this.handleDidMount}
                                onError={this.handleDidMount}
                            />
                        ) : (
                            <div className="circle circle--black opacity-5" />
                        )}
                    </div>
                    <div className={clsx('mx-4 a-text--pre-wrap', {
                        'width-55-pc': logo,
                        'width-75-pc': !logo
                    })}>
                        <div className="a-flex">
                            <div className="width-30-pc">
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    {get(order, 'destination_warehouse')}
                                </Paragraph>
                            </div>
                            <div className="width-70-pc">
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    Order no: <Text className="a-text--fz-18">{get(order, 'code')}</Text>
                                </Paragraph>
                            </div>
                        </div>
                        <div className="a-flex">
                            <div className="width-30-pc">
                                <Paragraph className="mb-2 mt-1 a-text--fz-16" strong>
                                    {get(order, 'id')}
                                </Paragraph>
                            </div>
                            <div className="width-70-pc">
                                <Paragraph className="mb-2 a-text--fz-16" strong>
                                    User: <Text
                                    className="a-text--fz-18 a-text--uppercase">{get(order, 'customer_username') || get(packageData, 'customer_username')}</Text>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="a-flex g-justify-center border-bottom pb-2 ">
                    <Barcode
                        copyStyles={true}
                        height={80}
                        margin={0}
                        value={get(packageData, 'code')}
                        width={3}
                        fontSize={16}
                        fontOptions={"bold"}
                        font={'Roboto, sans-serif'}
                    />
                </div>

                <ShipmentDetail
                    packageData={packageData}
                    productQuantity={product_quantity}
                    services={services}
                    warehouse_services={warehouse_services}
                />

                <ProductDetail products={products} />

                <div className="row">
                    <div className="a-flex galign-center gjustify-center gflex-direction-column a-text--center tile width-25-pc">
                        <Text className="a-text--fz-14">Payment COD:</Text>
                        <Text className="a-text--fz-16" strong>
                            {totalAmount ? currentFormatter.toLocaleStringCurrency(totalAmount)+' VNĐ' : ''}
                        </Text>
                    </div>
                    <div className="a-flex gjustify-center gflex-direction-column tile width-75-pc">
                        <Text>Receiver's signature</Text>
                        <Text>(Confirm intact goods)</Text>
                    </div>
                </div>
            </div>
        );
    }
}

PrintPackageUnOfficialStamp.defaultProps = {
    onDidMount: () => {}
};

PrintPackageUnOfficialStamp.propTypes = {
    onDidMount: PropTypes.func
};

export default connect(
    undefined,
    {
        finishLoadPrintTemplate,
        loadPrintTemplate
    },
)(PrintPackageUnOfficialStamp);
