import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, notification, Button, Spin } from 'antd'
import { setMenuActive } from '../../Common/actions'
import { connect } from 'react-redux'
import pageService from '../../Common/Page/pageService'
import { getVar } from '../../../system/support/helpers'
import apiService from '../apiService'
import DocumentInfo from './components/DocumentInfo'
import PackageList from './components/PackageList'
import SearchableComponent from '../../Common/components/SearchableComponent'
import DetailDeliveryRequestContainer from '../../DeliveryRequest/DetailDeliveryRequest/DetailDeliveryRequestContainer'
import { router } from '../../../system/routing'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

class DetailDocumentReturnGoods extends SearchableComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loadingConfirm: false,
            loadingCancel: false,
            loadingUpdate: false,
            data: {},
        }
    }

    componentDidMount() {
        super.componentDidMount()
        pageService.setTitle(this.props.t('return_goods:title.detail_document'))
        this.props.setMenuActive('return-goods/create')
    }

    onChangeFilter = filter => {
        this.fetchDetailDocument(filter.id)
    }

    fetchDetailDocument = id => {
        const { t } = this.props
        this.setState({ loading: true })
        apiService
            .detail(id)
            .then(res => {
                this.setState({ data: getVar(res, 'data', {}) })
            })
            .catch(error => {
                const {
                    response: { status },
                } = error
                if (status === 403) {
                    router.redirect('/403');
                } else {
                    notification.error({ message: t('message.server_error') })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    confirmDocument = id => {
        const { t } = this.props
        const { data } = this.state
        this.setState({ loadingConfirm: true })
        apiService
            .confirm(id)
            .then(res => {
                this.setState({ data: { ...data, status: getVar(res, 'data.status') } })
                notification.success({ message: t('return_goods:message.confirm_success') })
            })
            .catch(error => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                this.setState({ loadingConfirm: false })
            })
    }

    cancelDocument = id => {
        const { t } = this.props
        const { data } = this.state
        this.setState({ loadingCancel: true })
        apiService
            .cancel(id)
            .then(res => {
                this.setState({ data: { ...data, status: getVar(res, 'data.status') } })
                notification.success({ message: t('return_goods:message.cancel_success') })
            })
            .catch(error => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                this.setState({ loadingCancel: false })
            })
    }

    updateDocument = (id, dataUpdate) => {
        const { t } = this.props
        const { data } = this.state
        this.setState({ loadingUpdate: true })
        apiService
            .update(id, dataUpdate)
            .then(res => {
                this.setState({ data: { ...data, note: getVar(res, 'data.note') } })
                notification.success({ message: t('return_goods:message.update_success') })
            })
            .catch(error => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                this.setState({ loadingUpdate: false })
            })
    }

    render() {
        const { t, route } = this.props
        const { data, loading, loadingConfirm, loadingCancel, loadingUpdate } = this.state
        const status = getVar(data, 'status')
        return (
            <Row type={'flex'}>
                <div className="a-content--scroll-content pt-0 gflex gflex-direction-column not-navigation-fixed">
                    <Spin spinning={loading}>
                        <DocumentInfo
                            data={data}
                            confirmDocument={this.confirmDocument}
                            cancelDocument={this.cancelDocument}
                            loadingConfirm={loadingConfirm}
                            loadingCancel={loadingCancel}
                            updateDocument={this.updateDocument}
                            loadingUpdate={loadingUpdate}
                        />
                        <PackageList
                            data={data}
                            loading={loading}
                        />
                    </Spin>
                </div>
                <div className="aml-24 alog not-navigation-fixed">
                    <div className="title">
                        <span>{t('return_goods:title.log_action')}</span>
                    </div>
                </div>
                <DetailDeliveryRequestContainer
                    route={route}
                    onSuccessDeliveringDeliveryRequest={() => {}}
                />
            </Row>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DetailDocumentReturnGoods))
