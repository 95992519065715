import {combineReducers} from 'redux';
import * as ACTION from './constants';

let users = (state = [], action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_SHIPPING_USER.SUCCESS:
            return payload.shipping_users;
        default:
            return state;
    }
};

let usersSuggest = (state = [], action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.SUCCESS:
            return payload.shipping_users;
        default:
            return state;
    }
};

const loadingUserSuggestList = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.REQUEST:
            return true;
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.SUCCESS:
        case ACTION.FETCH_SHIPPING_USER_SUGGEST.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    users,
    usersSuggest,
    loadingUserSuggestList
});
