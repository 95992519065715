import React from 'react'
import { Tabs, Button } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Chat from './CheckingPackageChat'
import Log from './../../../Common/Log/LogContainer'
import PackageChat from './../../PackageDetail/components/PackageChat'
import ScanUploadCommentImagesLinkModal from './ScanUploadCommentImagesLinkModal'

const TabPane = Tabs.TabPane

class CheckingPackageLogs extends React.PureComponent {
    state = {
        visible: false,
        isTerminalComment: 0,
    }

    handleOpenUploadImage = isTerminalComment => {
        this.setState({
            visible: true,
            isTerminalComment,
        })
    }

    handleCancelUploadImage = () => {
        this.setState({
            visible: false,
            isTerminalComment: 0,
        })
    }

    render() {
        const { orderId, packageId, t, linkHtc, orderCode } = this.props
        const { visible, isTerminalComment } = this.state
        return (
            <div className="a-chatbox checkpackage">
                <div className="a-chatbox--body">
                    <Tabs className="a-tabs a-tabs--chatbox-tab">
                        <TabPane
                            className="a-tabs--chatbox--panel-chat _tab-chat"
                            tab={t('package:label.chat')}
                            key="1">
                            {linkHtc && (
                                <div className="mt-3 mb-3 pl-3">
                                    <span>{t('package:message.link_to_chat_htc')}</span>
                                    <a
                                        href={linkHtc + '/order/detail/default/' + orderCode}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {t('package:message.access_please')}
                                    </a>
                                </div>
                            )}
                            <Chat  {...this.props} handleOpenUploadImage={this.handleOpenUploadImage.bind(this)}/>
                            <PackageChat orderId={orderId} isCheckingPackage={true} handleOpenUploadImage={this.handleOpenUploadImage.bind(this)}/>
                        </TabPane>

                        <TabPane
                            className="a-tabs--chatbox--panel _tab-system-information"
                            tab={t('label.log')}
                            key="2">
                            <Log
                                object="package-tracking"
                                objectId={packageId}
                            />
                        </TabPane>
                    </Tabs>

                    <ScanUploadCommentImagesLinkModal
                        orderId={orderId}
                        visible={visible}
                        isTerminalComment={isTerminalComment}
                        onCancel={this.handleCancelUploadImage.bind(this)}
                    />
                </div>
            </div>
        )
    }
}

CheckingPackageLogs.defaultProps = {
    packageId: null,
}

CheckingPackageLogs.propTypes = {
    packageId: PropTypes.number,
}

export default withTranslation()(CheckingPackageLogs)
