import { Icon } from 'antd'
import { get, groupBy, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../../../system/i18n'
import BagItem from './BagItem'

const TablePackageLastmile = ({ packingLastmile, page, bag, getBagPackingDetail}) => {
    const [bagData, setBagData] = useState([])

    useEffect(() => {
        setBagData(processData(packingLastmile))
    }, [packingLastmile])

    const processData = data => {
        const newData = Object.entries(groupBy(data, 'id_bag_lastmile')).map(([key, val]) => {
            return {
                bag_lastmile: get(val, '0.bag_last_mile'),
                packing_package: val,
            }
        })

        return newData
    }

    return (
        <div
            className={
                'a-content__body bg-white a-barcode-scan__body aheight-100 ' +
                (page === 'detail' ? 'content-list-package-in-bag' : ' list-is-sidebar a-content__body--page-list')
            }>
            <div className="a-table--responsive a-table--antd">
                <table className="a-table a-table--barcode-scan _table_list_package">
                    <thead>
                        <tr>
                            <th>{t('bag:label.code')}</th>
                            <th>
                                {t('bag:packing.time_scan')}
                                <div className="sort">
                                    {/* <Icon
                                        type="caret-up"
                                        // className={this.state.sort === 'up' ? 'active' : ''}
                                    />
                                    <Icon
                                        type="caret-down"
                                        // className={this.state.sort === 'down' ? 'active' : ''}
                                    /> */}
                                </div>
                            </th>
                            <th>{t('freight_bill')}</th>
                            <th>{t('bag:packing.weight')}</th>

                            <th className="action-remove">
                             
                            </th>
                        </tr>
                    </thead>
                    <tbody className='tbody_not_hover_body'>
                        {bagData.map(item => (
                            <BagItem
                                item={item}
                                key={uniqueId()}
                                bag={bag}
                                getBagPackingDetail={getBagPackingDetail}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TablePackageLastmile
