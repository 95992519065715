import React from "react"
import {withTranslation} from "react-i18next";
import lodash from "lodash";
import {Button, Checkbox, Input, Modal, Skeleton, Tag} from "antd";
import {DEFAULT_STATE_ADD_STAFF_IN_WAREHOUSE} from "../../constants";
import * as MD5 from 'md5.js';

const Search = Input.Search;

class AddStaffInWarehouse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...DEFAULT_STATE_ADD_STAFF_IN_WAREHOUSE
		}
	}

	componentWillUnmount() {
		this.props.clearState()
	}

	onAddStaffInWarehouse = () => {
		this.props.fetchUsers();
		this.setState({id_users: [], visible: true})
	};
	onCancel = () => {
		this.setState({id_users: [], visible: false})
	};
	onSubmit = () => {
		let {warehouseData} = this.props;
		let {id_users} = this.state;
		let data = {};
		data.id_users = id_users;
		data.id_warehouse = warehouseData.id;
		this.props.createStaffInWarehouse({data, onCallback: this.onCallback.bind(this)})
	};

	onCallback(status) {
		if (status) {
			this.setState({id_users: [], visible: false})
		}
	}

	onSearch(e) {
		let {listUser} = this.props;
		let {users} = this.state;
		let value = e.target.value;
		if (value.lenght > 0) {
			users = []
		} else {
			users = lodash.filter(listUser, user => {
				return user.username === value || lodash.includes(user.name.toLowerCase(), value.toLowerCase())
			})
		}
		this.setState({users, code: value})
	}
	onChecker(id) {
		let {id_users} = this.state;
		if (lodash.includes(id_users, id)) {
			lodash.remove(id_users, user => user === id);
		} else {
			id_users.push(id);
		}
		this.setState({id_users})
	}


	render() {
		const {t, listUser, loading} = this.props;
		const {users, code} = this.state;
		let list = code.length > 0 ? users : listUser;
		return <React.Fragment>
			<Button type="primary"
			        onClick={this.onAddStaffInWarehouse}
			        loading={loading}
			        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _a-btn-create-warehouse-staff">
                {t('config:btn.create_warehouse_staff')}
			</Button>
			<Modal
				visible={this.state.visible}
				onCancel={this.onCancel}
				className={"a-modal--add-employee"}
				closable={false}
				footer={false}
			>
				<div className={"a-form--select-double-column"}>
					<div className="a-form--select-double-column__body">
						<div className="block-left">
							<div className="title">
								<h2>{t('config:label.add_staff_in_warehouse')}</h2>
							</div>
							<div className="body-left">
								<div className="apr-16">
									<Search
										placeholder={t('config:placeholder.search_staff_in_warehouse')}
										value={this.state.code}
                                        className={"_input-search"}
										onChange={this.onSearch.bind(this)}
									/>
								</div>
								<ul className="list-user">
									{loading ?
										<li>
											<Checkbox disabled/>
											<div className="info ">
												<Skeleton avatar active paragraph={{rows: 1}}
												          className="askeleton-avatar ml-2"/>

											</div>
										</li>
										:
										lodash.map(list, user => {
											const md5Avatar = new MD5().update(user.email).digest('hex');
										return (
											<li key={user.id} className={"_list-user"}>
												<Checkbox checked={lodash.includes(this.state.id_users, user.id)}
                                                          className={"_checkbox-active-user"}
												          onChange={this.onChecker.bind(this, user.id)}/>
												<img src={`https://www.gravatar.com/avatar/${md5Avatar}`}
												     alt=""/>
												<div className="info">
													<p className="info-name _info-name">{(user.name || '--') + '(' + (user.username || '--') + ')'} </p>
													<span
														className="info-phone _info-phone">{lodash.get(user, 'phone', '--') || '--'}</span>
												</div>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						<div className="block-right">
							<div className="title">
								<h2>{t('config:label.selected_staff_warehouse')}</h2>
							</div>
							<div className="body-right">
								{this.state.id_users.map(id =>
									<Tag key={id} closable
									     onClose={this.onChecker.bind(this, id)}
									     className={"a-tag--user _tag-user _tag-" + id}>{lodash.get(lodash.find(listUser, user => user.id === id), 'name')}</Tag>
								)}
							</div>
						</div>
					</div>
                    <div className="a-form--select-double-column__footer bg-color-white">
						<Button type="primary"
						        onClick={this.onCancel}
						        className="a-btn a-btn--default  a-text--regular mr-2 _btn-cancel">{t('btn.cancel')}
						</Button>
						<Button type="primary"
						        onClick={this.onSubmit}
						        disabled={this.state.id_users.length === 0}
						        className="a-btn a-btn--primary  a-text--regular apr-24 apl-24 _btn-save">{t('btn.save')}
						</Button>
					</div>
				</div>
			</Modal>
		</React.Fragment>
	}

}

export default withTranslation()(AddStaffInWarehouse)
