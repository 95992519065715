import {get, isNil} from 'lodash';

import {api} from './../../../system/api';

export default {
    updateAlonePackage: (params) => api.put('packages/' + params.code + '/alone-packages/update', {
        ...params,
        weight_net: isNil(get(params, 'weight_net')) ? 1 : get(params, 'weight_net')
    }, {
        singleRequest: true
    }),
    createAlonePackage: (params) => api.post('packages/alone-packages/create', {...params}, {singleRequest: true}),
};
