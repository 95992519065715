import React from 'react';
import { reduce, find, get, map, uniqueId, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import apiService from './../apiService';
import deliveryNoteApiService from './../../apiService';
import { setMenuActive } from './../../../Common/actions';
import pageService from './../../../Common/Page/pageService';
import { normal, success } from './../../../Sound/actions';
import { notifyErrorFromServer } from './../../../../system/support/helpers';

import Update from './components';
import SearchableComponent from './../../../Common/components/SearchableComponent';
import Sound from './../../../Sound';
import { BARCODE_TYPE } from '../../DeliveryNoteBarcode/constants';
import { notification } from 'antd';
import { dispatch } from '../../../../system/store';
import { SOUND_CONSTANT } from '../../../Sound/constants';

class UpdateContainer extends SearchableComponent {
    state = {
        scroll: false,
        input: {},
        bags: [],
        packages: [],
        loading: false,
        deliveryNote: {}
    };

    componentDidMount () {
        super.componentDidMount();
        const { t, setMenuActive } = this.props;

        pageService.setTitle(t('update_transport_warehouse_delivery_note'), <Sound />);
        setMenuActive('delivery-notes/global_shipping_partner/update');
        window.addEventListener('scroll', this.handleScroll);
    }

    onChangeFilter = filter => {
        this.getDeliveryNoteDetail(filter.id);
    };

    handleScroll = () => {
        const { scroll } = this.state;

        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({
                    scroll: true
                });
            }
        } else {
            if (scroll) {
                this.setState({
                    scroll: false
                });
            }
        }
    };

    onChangeInput = (name, value) => {
        this.setState({
            input: {
                ...this.state.input,
                [name]: value
            }
        });
    };

    onScanBarcode = async () => {
        const {t} = this.props;
        const { input, deliveryNote } = this.state;
        const newInput = { ...input, ...this.getInput() };
        const { code } = newInput;
        const identity = uniqueId();

        this.setState(prevState => {
            const { bags, packages } = prevState;            
            if (newInput.barcode_type === BARCODE_TYPE.BAG) {
                return {
                    bags: [{
                        ...(find(bags, {code}) || {code}),
                        scanning: true,
                        identity,
                    }, ...bags]
                };
            } else {
                return {
                    packages: [{
                        ...(find(packages, {code}) || {code}),
                        scanning: true,
                        identity,
                    }, ...packages]
                };
            }
        });

        try {
            const response = await this.scanBarcode(newInput);
            let id_bag = get(response, "bag.id", 0);
            let id_package = get(response, "package.id", 0);
            const isUpdate = !isEmpty(deliveryNote);
            const data = this.addBarcode(newInput, code, response, identity)
            if (isUpdate) {
                const deliveryNoteId = get(deliveryNote, "delivery_note_transport_warehouse.id")
                apiService.addPack(deliveryNoteId, {id_bag, id_package}).then(() => {
                    if (newInput.barcode_type === BARCODE_TYPE.BAG) {
                        this.setState({
                            bags: data
                        });
                    } else {
                        this.setState({
                            packages: data
                        });
                    }
                }).catch(() => {
                    notification.error({message: t('message.update_failed')})
                })
                
            }else {
                if (newInput.barcode_type === BARCODE_TYPE.BAG) {
                    this.setState({
                        bags: data
                    });
                } else {
                    this.setState({
                        packages: data
                    });
                }
            }
            
        } catch (error) {
            this.removeBarcode(identity, newInput)
        }
    };

    addBarcode = (input, code, data, identity) => {
        const isScanBag = get(input, "barcode_type", '') === BARCODE_TYPE.BAG
        const { bags, packages} = this.state;
        const { playSoundSuccess } = this.props;
        const objectData = isScanBag ? bags : packages;
        const barcode = find(objectData, item =>  (item.code === code || (!isScanBag  && (item.package.code === code || data.package.code === item.code))) && !item.scanning);
        const index = objectData.findIndex(item => item.identity === identity);

        let newBarcode = {
            ...data,
            code,
            bag: isScanBag ? data.bag : undefined,
            package: isScanBag ? undefined : data.package
        };

        if (barcode && (barcode.bag || barcode.package)) {
            const codeError = 'scan_packet_scanned';
            dispatch(normal(SOUND_CONSTANT.BARCODE_SCANNING_DUPLICATE))
            newBarcode = {
                ...newBarcode,
                warning: newBarcode.warning ? [
                    ...newBarcode.warning,
                    { code: codeError }
                ] : [
                    { code: codeError }
                ]
            };
        }

        playSoundSuccess();

        if (index === -1) {
            return objectData;
        }

        return [
            ...objectData.slice(0, index),
            newBarcode,
            ...objectData.slice(index + 1)
        ];
    };

    removeBarcode = (identity, input) => {
        const { bags, packages} = this.state;
        if (input.barcode_type === BARCODE_TYPE.BAG) {
            this.setState({
                bags: bags.filter(bag => bag.identity !== identity)
            })
        }else {
            this.setState({
                packages: packages.filter(item => item.identity !== identity)
            })
        }
    };

    scanBarcode = async input => {
        const { t } = this.props;

        try {
            const response = await deliveryNoteApiService.scanBarcode('export-transport-warehouse', input.code, {
                id_shipping_partner: input.id_shipping_partner,
                id_warehouse_scan: input.id_warehouse,
                barcode: input.code,
                barcode_type: input.barcode_type
            });

            return response.data;
        } catch (error) {
            const data = get(error, 'response.data.data');
            const message = get(data, "barcode.message");
            if (message) {
                const packages = get(data, "barcode.packages", [])
                notification.error({
                    message: t(`scan:error.${message}`, {packages: packages.join(", "), count: packages.length})
                });
            } else {
                notifyErrorFromServer(error, t('delivery_note:scan_transport_warehouse.failed'), 'delivery_note:scan_transport_warehouse.errors');
            }
            throw error.response;
        }
    };

    getInput = () => {
        const { deliveryNote } = this.state;
        const input = {
            id_warehouse: get(deliveryNote, 'delivery_note_transport_warehouse.id_warehouse'),
            id_shipping_partner: get(deliveryNote, 'delivery_note_transport_warehouse.id_shipping_partner')
        };

        return input;
    };

    removeBarcodeByIndex = (index, type) => {
        const {deliveryNote} = this.state;
        const {t} = this.props;
        const isUpdate = !isEmpty(deliveryNote);
        const bags = [...this.state.bags];
        const packages = [...this.state.packages]
        let id_bag = 0;
        let id_package = 0;
        if (type === BARCODE_TYPE.BAG) {
            id_bag = get(bags[index], "bag.id")
            bags.splice(index, 1);
        } else {
            id_package = get(packages[index], "package.id")
            packages.splice(index, 1);
        }

        if (isUpdate) {
            const deliveryNoteId = get(deliveryNote, "delivery_note_transport_warehouse.id")
            apiService.removePack(deliveryNoteId, {id_bag, id_package}).then(res => {
                this.setState({
                    bags,
                    packages
                });
            }).catch(() => {
                notification.error({message: t('message.update_failed')})
            })
            
        }else {
        
        this.setState({
            bags,
            packages
        });
        }
    };

    clearBarcodes = () => {
        this.setState({
            bags: [],
            packages: []
        });
    };

    getDeliveryNoteDetail = async id => {
        this.setState({
            loading: true
        });

        try {
            const response = await apiService.detail(id);
            const bagsResponse = get(response, 'data.bags');
            const packagesResponse = get(response, 'data.packages');
            const packageCodes = map(packagesResponse, 'package.code')
            const packageTrackingNos = map(packagesResponse, "package.tracking_no")
            const bags = get(response, 'data.warehouse.is_custom_warehouse') ? reduce(get(response, 'data.outside_bags'), (result, item) => {
                    const code = get(item, "outside_bag.code")
                    const bagInfo = find(bagsResponse, ['bag.code', code])
                    if (!packageCodes.includes(code) && !packageTrackingNos.includes(code)) {
                        result.push({
                            ...item,
                            bag: isEmpty(bagInfo) ? item.outside_bag : get(bagInfo, "bag", {}),
                            
                        }) 
                    } 
                return result
            }, []) : bagsResponse;


            this.setState({
                deliveryNote: get(response, 'data', {}),
                bags: map(bags, bag => ({
                    ...bag,
                    code: get(bag, 'bag.code'),
                    barcode_scanning: get(bag, 'bag.code')
                })),
                packages: map(packagesResponse, packageInfo => ({
                    ...packageInfo,
                    code: get(packageInfo, 'package.code'),
                    barcode_scanning: get(packageInfo, 'package.code')
                }))
            });
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };


    render () {
        const { bags, packages, deliveryNote, input, loading, scroll  } = this.state;

        return (
            <Update
                {...this.props}
                bags={bags}
                packages={packages}
                deliveryNote={deliveryNote}
                input={{
                    ...this.getInput(),
                    ...input
                }}
                loading={loading}
                scroll={scroll}
                clearBarcodes={this.clearBarcodes}
                getDeliveryNoteDetail={this.getDeliveryNoteDetail}
                getInput={this.getInput}
                onChangeInput={this.onChangeInput}
                onScanBarcode={this.onScanBarcode}
                removeBarcodeByIndex={this.removeBarcodeByIndex}
            />
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    playSoundSuccess: () => dispatch(success())
});

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(UpdateContainer));
