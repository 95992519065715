import React from 'react'
import settingService from './settingService'
import { Button, Checkbox, Dropdown, Icon, Menu } from 'antd'
import authService from '../../../Auth/authService'
import { t } from '../../../../system/i18n'
class ColumnSetting extends React.PureComponent {
    state = {
        setting: [],
    }

    componentDidMount() {
        const setting = settingService.getUserSetting(this.getUserId())

        this.setState({
            setting,
        })
        this.props.onChange(setting)
    }

    onChangeSetting = setting => {
        settingService.updateUserSetting(this.getUserId(), setting)
        this.setState({ setting })
        this.props.onChange(setting)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = { ...prevState }
        if (nextState.object !== nextProps.object) {
            nextState.object = nextProps.object
            settingService.getOption(nextProps.object)
            let setting = settingService.getPageSetting(authService.user('id'), nextProps.object)
            nextProps.onChange(setting)
        }
        return nextState
    }

    getUserId() {
        return authService.user('id')
    }

    onChangeOption = (optionKey, event) => {
        const values = this.state.setting

        const formattedValues = event.target.checked ? [...values, optionKey] : values.filter(value => value !== optionKey)

        this.onChangeSetting(formattedValues)
    }

    render() {
        const { isOpenListSetting, onOpenListSetting } = this.props

        return (
            <Dropdown
                visible={isOpenListSetting}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field">
                <Button
                    onClick={onOpenListSetting.bind(undefined, true)}
                    type="primary"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost>
                    {t('setting_info_column')}
                </Button>
            </Dropdown>
        )
    }

    renderMenu = () => {
        const { options, onOpenListSetting } = this.props
        const { setting } = this.state

        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon
                        onClick={onOpenListSetting.bind(undefined, false)}
                        type="close"
                    />
                </div>
                {options.map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item">
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={setting.includes(option.key)}
                            disabled={option.disabled}
                            onChange={this.onChangeOption.bind(this, option.key)}>
                            {t(`bag:column.${option.key}`)}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
}

export default ColumnSetting
