import {processApiRequest} from "../../Common/saga";
import * as ACTION from "../constants";
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {fetchDetailWarehouse} from "../DetailWarehouse/actions";
import * as lodash from "lodash";
import {dispatch} from "../../../system/store";

export function* createStaffInWarehouse(action) {
	yield processApiRequest(ACTION.CREATE_STAFF_IN_WAREHOUSE, () => apiService.createStaffInWarehouse(action.payload.data), action.payload);
}

export function* createStaffInWarehouseSuccess(action) {
	yield action.request.onCallback(true);
	yield notification.success({message: t('config:message.create_staff_in_warehouse_success')});
	yield dispatch(fetchDetailWarehouse(action.request.data.id_warehouse))
}

export function* createStaffInWarehouseFailed(action) {
	if (action.payload) {
		if (lodash.get(action, 'payload.data.active')) {
		yield notification.error({message: t('config:message.warehouse_status_invalid')});
		yield  window.location.reload();
	} else {
		yield notification.error({message: t('config:message.create_staff_in_warehouse_failed')});
	}
	}
}
