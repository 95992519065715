import React from 'react';
import {connect} from 'react-redux';
import BagPacking from './components/'
import {setMenuActive} from "../../Common/actions";
import {clearState, getBagPackingDetail} from "./actions";
import * as propertySelectors from "./../../Property/selectors";
import SearchableComponent from '../../Common/components/SearchableComponent';
import * as selector from "./selectors";
import {fetchPropertySuggest} from './../../Property/actions';
import * as warehouseSelectors from './../../Warehouse/selectors';
import * as bagShippingPartnersSelectors from "../../ShippingPartner/ListShippingPartner/selectors";
import {fetchSuggestWarehouses} from "../../Warehouse/actions";
import {fetchShippingPartnersSuggest} from "../../ShippingPartner/ListShippingPartner/actions";
import {fetchShippingUserSuggest} from '../../ShippingUser/List/actions';
import {resetError, updateBagParam} from "../UpdateBagParam/actions";
import {fetchSuggestBagSize} from '../../BagSize/actions';
import lodash from "lodash";
import * as selectors from "../UpdateBagParam/selectors";
import Bag from "../models/Bag";

const mapStateToProps = (state) => {
    let bag = selector.getBagData(state);
    bag = bag ? new Bag(bag) : null;
    return {
        loading: selector.getState(state, "loading", false),
        loadingUpdateParams: selector.getState(state, "loading", false),
        bag,
        packingData: selector.getPackageData(state),
        warehouses: warehouseSelectors.getState(state, 'suggest', []),
        shippingPartners: bagShippingPartnersSelectors.getListShippingPartner(state),
        shippers: state.shippingUser.list.usersSuggest,
        bagSizes: lodash.get(state, "bagSize.bagSizes.bag_sizes", []),
        bagSize: selector.getBagData(state).bag_size,
        properties: propertySelectors.getState(state, 'list'),
        canUpdate: !!selector.getState(state, 'bagPackingData.permissions.can_update', false),
        errors: selector.getErrors(lodash.get(state, "bag.updateBagParam.error", {})),
        version: bag.id + '.' + selectors.getState(state, 'version.id_customer'),
    }
};

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    getBagPackingDetail: (idBag) => dispatch(getBagPackingDetail(idBag)),
    onReset: () => dispatch(clearState()),
    getListWarehouse: () => dispatch(fetchSuggestWarehouses()),
    fetchBagShippingPartner: () => dispatch(fetchShippingPartnersSuggest()),
    fetchShippingUserSuggest: () => dispatch(fetchShippingUserSuggest()),
    fetchBagSizes: () => dispatch(fetchSuggestBagSize()),
    getProperties: () => dispatch(fetchPropertySuggest()),
    updateBagParam: ({param, value, idBag}) => dispatch(updateBagParam(idBag, param, value)),
    resetError: (params) => {
        dispatch(resetError(params));
    }
});

class BagPackingContainer extends SearchableComponent {
    componentWillUnmount() {
        this.props.onReset();
    }

    onChangeFilter(filter) {
        this.props.getBagPackingDetail(filter.id);
        if (Array.isArray(this.props.warehouses) && this.props.warehouses.length === 0) {
            this.props.getListWarehouse();
        }

        if (Array.isArray(this.props.shippingPartners) && this.props.shippingPartners.length === 0) {
            this.props.fetchBagShippingPartner();
        }

        if (Array.isArray(this.props.shippers) && this.props.shippers.length === 0) {
            this.props.fetchShippingUserSuggest();
        }
        if (Array.isArray(this.props.properties) && this.props.properties.length === 0) {
            this.props.getProperties();
        }
        if (Array.isArray(this.props.bagSizes) && this.props.bagSizes.length === 0) {
            this.props.fetchBagSizes();
        }
    }

    render() {
        return <BagPacking {...this.props}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BagPackingContainer);

