import { combineReducers } from 'redux';
import { omit } from 'lodash';

import * as ACTION from './constants';

const deliveryNotesReturn = (state = [], action) => {
    switch (action.type) {
        case ACTION.GET_RETURNS.SUCCESS: {
            return action.payload.delivery_notes_return.map(deliveryNoteReturn => ({
                ...deliveryNoteReturn.delivery_note_return,
                ...omit(deliveryNoteReturn, 'delivery_note_return')
            }));
        }
        case ACTION.GET_RETURNS.FAILED:
        case ACTION.CLEAR_STATE: {
            return [];
        }
        default: {
            return state;
        }
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case ACTION.GET_RETURNS.SUCCESS: {
            return action.payload.pagination;
        }
        case ACTION.GET_RETURNS.FAILED:
        case ACTION.CLEAR_STATE: {
            return {};
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.GET_RETURNS.REQUEST: {
            return true;
        }
        case ACTION.CLEAR_STATE:
        case ACTION.GET_RETURNS.SUCCESS:
        case ACTION.GET_RETURNS.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    deliveryNotesReturn,
    pagination,
    loading,
});
