import lodash from "lodash";
import {t} from "../../../system/i18n";
import {translateValidationErrors} from "../../Common/services/helps";

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let attribute = t('bag:label.' + (param === 'note_status' ? 'inventory_reason' : param));
        let messages = translateValidationErrors(attribute, rules);

        return lodash.first(lodash.values(messages));
    });
};
