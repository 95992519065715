import React from 'react';
import { Typography } from 'antd';
import { get, isNil, uniqueId } from 'lodash';
import { withTranslation } from 'react-i18next';

import { getVar } from './../../../../../system/support/helpers';
import { dateFormatter } from './../../../../Common/services/format';
import { formatCurrency } from './../../../../Common/services/helps';

const { Paragraph, Text } = Typography;

const SHIPPING_FEE_ACTIONS = {
    CREATE: 'SHIPPING_FEE.CREATE',
    UPDATE: 'SHIPPING_FEE.UPDATE'
};

const ShippingFeeLogMessage = ({ t, log }) => {
    const action = get(log, 'action', '');
    const changeFields = get(log, 'payload.change_fields', {});

    const updatedFields = Object.keys(changeFields).map(field => {
        let oldValue, newValue;

        switch (field) {
            case 'applied_at': {
                oldValue = dateFormatter.full(changeFields[field].old);
                newValue = dateFormatter.full(changeFields[field].new);

                break;
            }
            case 'weight_by': {
                oldValue = getVar(changeFields[field], 'old');
                newValue = getVar(changeFields[field], 'new');

                if (!isNil(oldValue)) {
                    oldValue = t(`weight_by_options.${oldValue}`)
                }

                if (!isNil(newValue)) {
                    newValue = t(`weight_by_options.${newValue}`)
                }

                break;
            }
            case 'unit_prices': {
                const unitPrices = getVar(changeFields[field], 'update');
                newValue = unitPrices.map(unitPrice => `${t('shipping_fee:weight_from')}: ${unitPrice.weight_from} (kg) - ${t('log:shipping_fee.fields.unit_price')}: ${formatCurrency(unitPrice.unit_price)} VNĐ`);
                newValue = newValue.join(', ');

                break;
            }
            default: {
                oldValue = getVar(changeFields[field], 'old');
                newValue = getVar(changeFields[field], 'new');

                break;
            }
        }

        return {
            translatedField: t(`log:shipping_fee.fields.${field}`),
            oldValue,
            newValue,
        };
    });

    switch (action) {
        case SHIPPING_FEE_ACTIONS.CREATE: {
            const shippingFeeDetails = get(log, 'payload.shipping_fee_details', []);
            let shippingFeeDetailsLogMessage = shippingFeeDetails.map(shippingFeeDetail => `${t('shipping_fee:weight_from')}: ${shippingFeeDetail.weight_from} (kg) - ${t('log:shipping_fee.fields.unit_price')}: ${formatCurrency(shippingFeeDetail.unit_price)} VNĐ`);
            shippingFeeDetailsLogMessage = shippingFeeDetailsLogMessage.join(', ');

            return (
                <>
                    <Paragraph className="mb-2">
                        {t('log:shipping_fee.actions.create')}
                    </Paragraph>
                    <Paragraph>
                        <Text strong>
                            {shippingFeeDetailsLogMessage}
                        </Text>
                    </Paragraph>
                </>
            );
        }
        case SHIPPING_FEE_ACTIONS.UPDATE: {
            return updatedFields.map(updatedField => (
                <Paragraph className="mb-2" key={uniqueId()}>
                    {t('log:shipping_fee.actions.update')}
                    <Text className="mx-2" strong>{updatedField.translatedField}</Text>
                    {isNil(updatedField.oldValue) ? null : (
                        <>
                            {t('log:from')}
                            <Text className="mx-2" strong>{updatedField.oldValue}</Text>
                        </>
                    )}
                    {t('log:to')}
                    <Text className="ml-2" strong>{updatedField.newValue}</Text>
                </Paragraph>
            ));
        }
        default: {
            return null;
        }
    }
};

export default withTranslation()(ShippingFeeLogMessage);
