export const DELETE_PACKAGE = {
    REQUEST: 'PACKAGE.DELETE_PACKAGE.REQUEST',
    SUCCESS: 'PACKAGE.DELETE_PACKAGE.SUCCESS',
    FAILED: 'PACKAGE.DELETE_PACKAGE.FAILED',
};

export const BEGIN_DELETE_PACKAGE = "BEGIN_DELETE_PACKAGE";
export const END_DELETE_PACKAGE = "END_DELETE_PACKAGE";

export const DELETE_REASONS = {
    VIRTUAL_PACKAGE: {key: 'VIRTUAL_PACKAGE', label: 'Kiện ảo'},
    CANCEL_RECEIVE: {key: 'CANCEL_RECEIVE', label: 'Từ chối nhận'},
    WRONG_ADDRESS: {key: 'WRONG_ADDRESS', label: 'Sai địa chỉ'},
    OTHER : {key: 'OTHER', label: 'Lý do khác'},
};

export const DEFAULT_DELETE_REASON = "VIRTUAL_PACKAGE";
