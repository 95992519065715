/* eslint-disable */
import React, { Component } from 'react';
import { Button, Form, Layout, Row } from 'antd/lib/index';
import clsx from 'clsx';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { DEFAULT_STATE_FORM_RETURN, DELIVERY_OBJECT } from './../../constants';
import { url } from './../../../../system/routing';

import ShippingReturnPartnerFormInput from './ShippingReturnPartnerFormInput';
import PromptLeavePage, { checkLeavePage } from './../../Create/components/PromptLeavePage';
import DeliveryNoteBarcodes from './../../DeliveryNoteBarcode/ListDeliveryNoteBarcodeContainer';

const paramConfirmInputs = ['partner_tracking_number', 'note', 'packages'];

const { Content } = Layout;

class FormShippingReturnPartner extends Component {
    state = {
        ...DEFAULT_STATE_FORM_RETURN,
        submit:false
    };

    componentDidMount() {
        const { getShippingReturnPartner, input } = this.props;

        getShippingReturnPartner(get(input, 'id_shipping_return_partner', 0));

        window.onbeforeunload = this.checkOnbeforeunload;
    }

    componentDidUpdate(prevProps) {
        const { deliveryNoteReturn, form } = this.props;

        if (!isEqual(deliveryNoteReturn, prevProps.deliveryNoteReturn)) {
            form.setFieldsValue({
                note: get(deliveryNoteReturn, 'delivery_note_return.note'),
                partner_tracking_number: get(deliveryNoteReturn, 'delivery_note_return.partner_tracking_number'),
                return_fee: get(deliveryNoteReturn, 'delivery_note_return.return_fee')
            });
        }
    }

    componentWillUnmount() {
        const { clearDetailShippingPartner, onReset } = this.props;

        clearDetailShippingPartner();
        onReset();
        window.onbeforeunload = null;
    }

    checkOnbeforeunload = () => {
        const { submit } = this.state;
        const { deliveryNoteReturn, form, packages, t } = this.props;

        const values = {
            ...form.getFieldsValue(),
            packages
        };

        if (get(deliveryNoteReturn, 'delivery_note_return.id') || !checkLeavePage(deliveryNoteReturn, paramConfirmInputs, submit, values)) {
            return null;
        }

        return t('message.confirm_when_leave');
    };

    redirectRenew = () => {
        const { input } = this.props;

        const idWarehouse = get(input, 'id_warehouse', 0);

        url.redirectTo('delivery-notes.return.create', null, {
            id_warehouse : idWarehouse,
        });
    };

    onSubmit = () => {
        const { deliveryNoteReturn, form, input, packages, add, update } = this.props;

        form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            this.setState({
                ...this.state,
                submit: true
            });

            const formattedValues = {
                ...values,
                packages,
                id_shipping_return_partner: input.id_shipping_return_partner,
                id_warehouse: input.id_warehouse
            };
    
            if (!get(deliveryNoteReturn, 'delivery_note_return.id')) {
                add(formattedValues);
            } else {
                update({
                    ...formattedValues,
                    id: deliveryNoteReturn.delivery_note_return.id
                });
            }
        });
    };

    componentWillUnmount() {
        this.props.onReset();
    }

    onUpdate = params => {
        if (params.packages) {
            delete params.packages;
            delete params.id_shipping_partner;
            delete params.id_warehouse;
        }

        this.setState({
            ...this.state,
            ...params,
            submit: false
        });
    };

    render() {
        const { submit } = this.state;
        const { adding, deliveryNoteReturn, form, packages, scroll, shippingReturnPartner, t, updating } = this.props;
        const isShowBtnRenew = !get(deliveryNoteReturn, 'delivery_note_return.id');
        const isShowBtnSave = !get(deliveryNoteReturn, 'delivery_note_return.id') || (get(deliveryNoteReturn, 'delivery_note_return.id') && get(deliveryNoteReturn, 'permissions.can_update', 0) === 1);
        const data = {
            ...form.getFieldsValue(),
            packages
        };

        return (
            <Content
                className={clsx('a-content is-action-fixed-bottom a-barcode-scan gflex gflex-direction-column', {
                    'scroll-is-sider': scroll
                })}
            >
                <PromptLeavePage
                    when={get(deliveryNoteReturn, 'delivery_note_return.id') ? false : checkLeavePage(deliveryNoteReturn, paramConfirmInputs, submit, data)}
                />
                <Form onSubmit={this.handleSubmit} className="a-block amb-16">
                    <div className="block--primary a-content--bag">
                        <div className="title create-bag">
                            <div className="left">
                                <span className="a-text--medium a-text--fz-16">
                                    {t('delivery_note:label.title_create_page')}
                                </span>
                            </div>
                            <div className="right">
                                <p className="mb-0 a-text--fz-16 a-text--nowrap">{t('partner')} - (
                                    <span className="a-text--medium ml-1 _employee_code">
                                        {shippingReturnPartner.name || '--'}
                                    </span>
                                )
                                </p>
                            </div>
                        </div>
                        <div className="body not-boxshadow">
                            <Row gutter={{lg: 24}}>
                                <div className="a-block-info pb-0">
                                    <ShippingReturnPartnerFormInput
                                        deliveryNoteReturn={deliveryNoteReturn}
                                        form={form}
                                        onUpdate={this.onUpdate}
                                    />
                                </div>
                            </Row>
                        </div>
                    </div>
                </Form>
                <DeliveryNoteBarcodes
                    {...this.props}
                    canRemove
                    className="amr-24 amr-16-lg"
                    object={DELIVERY_OBJECT.RETURN}
                />
                {!!(isShowBtnRenew || isShowBtnSave) && (
                    <div className="a-content--action-fixed-bottom">
                        {!!isShowBtnRenew && (
                            <Button
                                className="a-btn--button-link mr-auto a-text--blue"
                                onClick={this.redirectRenew}
                            >
                                {t('btn.create_new')}
                            </Button>
                        )}
                        {!!isShowBtnSave && (
                            <Button
                                className="a-btn a-btn--primary a-btn--save-export _btn-save"
                                loading={adding || updating}
                                size="large"
                                onClick={this.onSubmit}
                            >
                                {t('btn.save')}
                            </Button>
                        )}
                    </div>
                )}
            </Content>
        );
    }
}

FormShippingReturnPartner.defaultProps = {
    loading: false,
    input: {},
    permissions: [],
    deliveryNote: {},
    shippingReturnPartner: {}
};

FormShippingReturnPartner.propTypes = {
    loading: PropTypes.bool,
    input: PropTypes.object,
    permissions: PropTypes.array,
    deliveryNote: PropTypes.object,
    shippingReturnPartner: PropTypes.object,
    clearDetailShippingPartner: PropTypes.func,
};

export default withTranslation()(Form.create()(FormShippingReturnPartner));
