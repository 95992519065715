import { api } from '../../system/api'

export default {
    createWarehouse: params => api.post('warehouses', { ...params }),
    createWarehouseStaff: params => api.post('warehouse-staffs/warehouses', { ...params }),
    updateWarehouse: params => api.put('warehouses/' + params.id_warehouse, { ...params }),
    updateWarehouseStaff: data => api.put(`warehouse-staffs/${data.id_user}/warehouses`, data),
    getListWarehouse: params => api.get('warehouses', { params }),
    getListWarehouseStaff: params => api.get('warehouse-staffs', { params }),
    detailWarehouse: id => api.get('warehouses/' + id),
    detailWarehouseStaff: id => api.get('warehouse-staffs/' + id),
    createStaffInWarehouse: params => api.post('warehouses/' + params.id_warehouse + '/staffs', { ...params }),
    deleteStaffInWarehouse: params => api.put('warehouses/' + params.id_warehouse + '/staffs/' + params.id_staff + '/remove', { ...params }),
    uploadWarehouseArea: (warehouseId, data) => api.post(`warehouses/${warehouseId}/shelves/upload`, data),
    getListWarehouseArea: (warehouseId, params) => api.get(`/warehouses/${warehouseId}/shelves`, { params }),
    updateWarehouseArea: (warehouseId, warehouseAreaId, data) => api.put(`/warehouses/${warehouseId}/shelves/${warehouseAreaId}`, data),
    deleteWarehouseArea: (warehouseId, warehouseAreaId) => api.delete(`/warehouses/${warehouseId}/shelves/${warehouseAreaId}`),
}
