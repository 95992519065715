import {combineReducers} from "redux";
import * as ACTION from '../constants';


let list = (state = false, action) => {
	let {type, payload} = action;
	switch (type) {
		case ACTION.FETCH_LIST_WAREHOUSE.SUCCESS:
			return payload.warehouses;
		case ACTION.RESET_LIST_WAREHOUSE:
			return [];
		case  ACTION.FETCH_LIST_WAREHOUSE.REQUEST:
		case ACTION.FETCH_LIST_WAREHOUSE.FAILED:
		default:
			return state

	}
};
let loading = (state = false, action) => {
	let {type} = action;
	switch (type) {
		case  ACTION.FETCH_LIST_WAREHOUSE.REQUEST:
			return true;
		case ACTION.FETCH_LIST_WAREHOUSE.FAILED:
		case ACTION.FETCH_LIST_WAREHOUSE.SUCCESS:
			return false;
		default:
			return state

	}
};
let pagination = (state = {}, action) => {
	switch (action.type) {
		case ACTION.FETCH_LIST_WAREHOUSE.SUCCESS:
			return action.payload.pagination;
		case ACTION.RESET_LIST_WAREHOUSE:
			return {};
		default:
			return state;
	}
};
export default combineReducers({loading, list, pagination})
