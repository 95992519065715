import {combineReducers} from 'redux';
import lodash from "lodash";
import * as ACTION from './constants';
import {CREATE_SHIPPING_USER} from '../Create/constants';
import {EDIT_SHIPPING_USER} from '../Edit/constants';

export const loading = (state = false, action) => {
    switch (action.type) {
        case CREATE_SHIPPING_USER.REQUEST:
            return true;
        case CREATE_SHIPPING_USER.SUCCESS:
        case CREATE_SHIPPING_USER.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

export const errors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case CREATE_SHIPPING_USER.REQUEST:
        case CREATE_SHIPPING_USER.SUCCESS:
        case EDIT_SHIPPING_USER.REQUEST:
        case EDIT_SHIPPING_USER.SUCCESS:
        case ACTION.CLEAR_STATE:
            return {};
        case CREATE_SHIPPING_USER.FAILED:
        case EDIT_SHIPPING_USER.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    errors
});
