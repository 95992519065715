import React from 'react';
import lodash from 'lodash';
import { connect } from 'react-redux';

import * as actions from './actions';
import * as selectors from './selectors';
import { exportBag } from './../BagDetail/actions';
import { setMenuActive } from './../../Common/actions';

import ListBag from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';

import withBagTemplates from './../../../hocs/withBagTemplates';
import withConsignServices from './../../../hocs/withConsignServices';
import withProperties from './../../../hocs/withProperties';
import withServices from './../../../hocs/withServices';

const mapStateToProps = (state, props) => {
    return {
        ...selectors.getState(state),
        list: state,
        bags: selectors.getListBag(state, props.route.name),
        loading: selectors.getState(state, 'loading'),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchBags: (filter) => dispatch(actions.fetchBags(filter)),
    onReset: () => dispatch(actions.onReset()),
    onSwitchTab: () => dispatch(actions.onSwitchTab()),
    fetchWarehouse: (filter) => dispatch(actions.fetchBags(filter)),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    exportBag: (payload) => dispatch(exportBag(payload)),
});

class ListBagContainer extends SearchableComponent {
    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter = filter => {
        const { route, fetchBags } = this.props;

        filter = lodash.pickBy(filter, lodash.identity);

        if (route.name === 'bags.list_bag_pack') {
            filter.packing_complete = 0;
        }

        filter.excludes = [
            'customer',
            'services',
            'properties',
            'agencies',
            'shipping_user',
            'shipping_partner',
            'warehouse_input', 
            'warehouse_current',
            'warehouse_destination'
        ];

        fetchBags(filter);
    };

    getCurrentFilter = () => {
        const { location, route, sort_by, sort_direction } = this.props;

        const filter = {
            page: 1,
            sort_by: sort_by || 'created_at',
            sort_direction: sort_direction || 'desc',
            ...this.getFilterFromLocation(location),
        };

        if (route.name === 'bags.list_bag_pack') {
            filter.packing_complete = 0;
        }

        return filter;
    };

    /**
     * get Page Name
     * @returns {string}
     */
    getPageName = () => {
        return this.props.route.name === 'bags.list_bag_pack' ? 'list_bag_pack' : 'list_bag';
    };

    render() {
        return (
            <ListBag
                {...this.props}
                filter={this.getCurrentFilter()}
                page={this.getPageName()}
                onSearch={this.onSearch}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withBagTemplates(
    withProperties(
        withConsignServices(
            withServices(ListBagContainer)
        )
    ), {
        getDataWhenMounted: true
    }
));
