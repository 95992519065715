import { notification } from 'antd';

import apiService from './apiService';
import { ADD_SHIPPING_FEE, GET_ROUTES, GET_SHIPPING_FEES, UPDATE_SHIPPING_FEE } from './constants';
import { processApiRequest } from './../Common/saga';

import { t } from './../../system/i18n';

export function* getShippingFees(action) {
    yield processApiRequest(GET_SHIPPING_FEES, () => apiService.list(action.payload), action.payload);
}

export function* addShippingFee(action) {
    yield processApiRequest(ADD_SHIPPING_FEE, () => apiService.add(action.payload), action.payload);
}

export function* successAddShippingFee() {
    yield notification.success({
        message: t('shipping_fee:add.success')
    });
}

export function* updateShippingFee(action) {
    yield processApiRequest(UPDATE_SHIPPING_FEE, () => apiService.update(action.payload), action.payload);
}

export function* successUpdateShippingFee() {
    yield notification.success({
        message: t('shipping_fee:update.success')
    });
}

export function* getRoutes() {
    yield processApiRequest(GET_ROUTES, () => apiService.routes());
}
