import React from 'react';
import { connect } from 'react-redux';

import * as actions from './actions';
import * as selectors from './selectors';
import * as customerActions from './../../Customer/actions';
import * as customerSelector from './../../Customer/selectors';
import * as usersActions from './../../User/actions';
import * as usersSelector from './../../User/selectors';

import ListDeliveryNote from './components';
import { setMenuActive } from './../../Common/actions';

import SearchableComponent from './../../Common/components/SearchableComponent';

const mapStateToProps = (state, props) => {
    return {
        ...selectors.getState(state),
        customers: customerSelector.getState(state, 'list', []),
        users: usersSelector.getState(state, 'users', []),
        deliveryNotes: selectors.getListDeliveryNote(state, props.route.name),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchDeliveryNotes: (filter) => dispatch(actions.fetchDeliveryNotes(filter)),
    onReset: () => dispatch(actions.onReset()),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
    getListCustomer: () => dispatch(customerActions.getListCustomer()),
    getListUser: () => dispatch(usersActions.fetchUsers()),
});

class ListDeliveryNoteContainer extends SearchableComponent {
    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter = filter => {
        this.props.fetchDeliveryNotes(filter);
    };

    getCurrentFilter = () => {
        const filter = {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            sort_direction: this.props.sort_direction || 'desc',
            ...this.getFilterFromLocation(this.props.location),
        };

        return filter;
    };

    componentDidMount() {
        super.componentDidMount();

        const { customers, users, getListCustomer, getListUser } = this.props;

        if (customers.length === 0) {
            getListCustomer();
        }

        if (users.length === 0) {
            getListUser();
        }
    }

    render() {
        return (
            <ListDeliveryNote
                {...this.props}
                filter={this.getCurrentFilter()}
                onSearch={this.onSearch}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListDeliveryNoteContainer)
