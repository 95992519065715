import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearState } from './actions';
import { getState } from './../../DeliveryNoteBarcode/selectors';
import { setMenuActive } from './../../../Common/actions';

import Create from './components';
import SearchableComponent from './../../../Common/components/SearchableComponent';

class CreateContainer extends SearchableComponent {
    getInput = () => {
        const input = this.getFilterFromLocation(this.props.location);

        return input;
    };

    onChangeInput = input => {
        this.pushFilter(input);
    };

    render() {
        return (
            <Create
                {...this.props}
                input={this.getInput()}
                onChangeInput={this.onChangeInput}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        barcodes: getState(state, 'barcodes')
    };
};

const mapDispatchToProps = {
    clearState,
    setMenuActive
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateContainer));
