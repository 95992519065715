import lodash from 'lodash'
import { PACKAGE_IN_BAG_REQUEST_COLUMN } from '../constants'

const SETTING_OPTIONS = [
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.PACKAGE_CODE,
        selected: true,
        disabled: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.ORDER_CODE,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.AGENCY,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.WAREHOUSE_AREA,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.WEIGHT,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.VOLUME,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.PROPERTIES,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.SERVICES,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.CUSTOMER_NAME,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.TRANSPORT_STATUS,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.BAG,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.MIDMILE_SHIPPING_PARTNER,
        selected: true,
    },
    {
        key: PACKAGE_IN_BAG_REQUEST_COLUMN.NOTE,
        selected: true,
    },
]

class SettingService {
    constructor(options) {
        this.options = options
    }

    getSetting() {
        let setting = localStorage.getItem('package_in_packing_request_setting')
        return setting ? JSON.parse(setting) : {}
    }

    updateSetting(setting) {
        localStorage.setItem('package_in_packing_request_setting', JSON.stringify(setting))
    }

    getOption() {
        let options = []
        lodash.each(SETTING_OPTIONS, option => {
            options.push(option)
        })
        this.options = options
    }

    getPageSetting(userId) {
        let defaultSetting = lodash.get(this.getSetting(), userId, this.getDefaultSetting())
        let settings = []
        lodash.each(SETTING_OPTIONS, setting => {
            if (defaultSetting.includes(setting.key)) {
                settings.push(setting.key)
            }
        })
        return settings
    }

    getDefaultSetting() {
        return this.options.filter(option => option.selected).map(option => option.key)
    }

    getUserSetting(userId) {
        this.getOption()
        return this.getPageSetting(userId)
    }

    updateUserSetting(userId, setting) {
        this.getOption()
        this.updateSetting({ ...this.getSetting(), [userId]: setting })
    }
}

let settingService = new SettingService(SETTING_OPTIONS)

export default settingService
