import React, { PureComponent } from 'react';
import {Col, Form, Input, Row, Select} from 'antd';
import clsx from 'clsx';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TRANSPORT_STATUS } from '../../constants';
import { updateTransportStatus } from '../../ChangePackageTransportStatus/actions';
import { getVar } from '../../../../system/support/helpers';

import SelectAgency from './../../../Setting/Agency/SelectAgency';
import apiService from "../../apiService";

const { Option } = Select;

class PackageGeneralInfo extends PureComponent {
    state = {
        input: this.props.input,
        configUpdateStatusNote: {},
        canUpdateStatusNote: false
    };

    componentDidMount() {
        this.handleGetConfigUpdateStatusNote();
    }

    handleGetConfigUpdateStatusNote() {
        //call api lấy config state nào
        const {input: {status_transport}} = this.props;
        apiService.getPackageStatusConfig().then(res => {
            const configUpdateStatusNote = getVar(res, "data.package_status", [])
            this.setState({
                configUpdateStatusNote,
                canUpdateStatusNote: getVar(find(configUpdateStatusNote, {status: status_transport}), "apply_status_note", false)
            })
        }).catch(e => {

        })

    }

    handleChange = (field, event) => {
        const { value } = event.target;

        this.setState(prevState => produce(prevState, draft => {
            draft.input = produce(draft.input, input => {
                input[field] = value;
            });
        }));

        this.doEmitchange({
            [field]: value
        });
    };

    doEmitchange = debounce(this.props.onChange, 350);

    handleChangeStatusTransport = value => {
        const { onChange, updateTransportStatus, packageData } = this.props;
        const {configUpdateStatusNote} = this.state;
        const canUpdateStatusNote = getVar(find(configUpdateStatusNote, {status: value}), "apply_status_note", false);
        if (!canUpdateStatusNote) {
            onChange({
                status_transport: value,
                note_status: getVar(packageData, 'package_detail.note_status')
            })
        } else {
            onChange({
                status_transport: value
            });
        }

        if (!value) {
            updateTransportStatus({
                code: getVar(this.props.package, 'code'),
                status_transport: value
            });
        }


        this.setState({
            canUpdateStatusNote
        })
    };

    componentDidUpdate(prevProps) {
        const { input } = this.props;

        if (!isEqual(input, prevProps.input)) {
            this.setState({
                input
            });
        }
    }

    render() {
        const { errors, input, package: packageData, t, onChange } = this.props;
        const {canUpdateStatusNote} = this.state;
        const id_agency = getVar(packageData, 'id_agency');
        const canUpdateTransportStatus = !!getVar(packageData, 'permissions.can_update_transport_status', false);
        return (
            <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray info--detail" type="flex">
                <Col lg={{span: 24}} className="info--title">
                    <h4>{t('package:detail.package_info')}</h4>
                </Col>
                {!!id_agency && (
                    <Col className="a-form--group" lg={{span: 12}}>
                        <Row>
                            <Col lg={{span: 14}}>
                                <Form.Item label={t("package:label.choose_agency")} className="a-form__item ">
                                    <SelectAgency
                                        disabled
                                        placeholder={t('delivery_request:placeholder.select_agency')}
                                        value={id_agency}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col className="a-form--group" lg={{span: 12}}>
                    <Row>
                        <Col lg={{span: 14}}>
                            <Form.Item
                                className="a-form__item "
                                label={t("package:label.status_transport")}
                                help={errors.status_transport || errors.code}
                                validateStatus={errors.status_transport || errors.code ? 'error' : null}
                                labelCol={{span: 24}}>
                                <Select
                                    className="a-select a-select--w-100 _status-transport"
                                    dropdownClassName="_dropdown-status-transport"
                                    name="status_transport"
                                    optionFilterProp="children"
                                    placeholder={t("package:placeholder.status_transport")}
                                    showSearch
                                    value={input.status_transport}
                                    onChange={this.handleChangeStatusTransport}
                                >
                                    {canUpdateTransportStatus && (
                                        <Option className="_status-transport-item a-text--green" value="">
                                            {t(`package:status_transport.${TRANSPORT_STATUS.CIRCULATE}`)}
                                        </Option>
                                    )}
                                    <Option
                                        className="_status-transport-item"
                                        value={packageData.status_transport}
                                    >
                                        {t(`package:status_transport.${packageData.status_transport}`)}
                                    </Option>
                                    {packageData.enable_transport_status.map(state => (
                                        <Option
                                            className={clsx('_status-transport-item', {
                                                'a-text--red': !state.apply_shipping_fee
                                            })}
                                            key={state.status}
                                            value={state.status}
                                        >
                                            {t(`package:status_transport.${state.status}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col className="a-form--group" lg={{span: 12}}>
                    {
                        canUpdateStatusNote ?
                            <Form.Item
                                className="a-form__item _status-note"
                                label={t('status_note')}
                                help={errors.note_status}
                                validateStatus={errors.note_status ? 'error' : null}
                                labelCol={{span: 24}}>
                                <Input
                                    className="_input-status-note"
                                    placeholder={t("input_status_note")}
                                    value={input.note_status || getVar(packageData, 'package_detail.note_status')}
                                    onChange={(e) => onChange({note_status: e.target.value})}
                                />
                            </Form.Item> :
                            <div className="info--group _note-status">
                                <label className="info--label _label a-text--black">{t('status_note')}</label>
                                <span className="info--value _value">{getVar(packageData, 'package_detail.note_status', '--')}</span>
                            </div>
                    }

                </Col>

                <Col className="a-form--group" lg={{span: 12}}>
                    <div className="info--group _return-address">
                        <label className="info--label a-text--black">{t('return_address')}</label>
                        <span className="info--value">{getVar(packageData, 'package_detail.return_address', '--')}</span>
                    </div>
                </Col>

                <Col className="info--group" lg={{span: 24}}>
                    <label className="info--label">{t('package:label.attribute')}</label>
                    <span className="info--value ">{map(packageData.properties || [], 'name').join(' / ') || '--'}</span>
                </Col>
            </Row>
        );
    }
}

PackageGeneralInfo.defaultProps = {
    input: {},
    errors: {},
    onChange: () => {
    },
};

PackageGeneralInfo.propTypes = {
    input: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

const mapDispatchToProps = {
    updateTransportStatus
};

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(PackageGeneralInfo));
