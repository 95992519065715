import React from "react"
import {Col, Form, Input, Row} from "antd";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import LocationWarehouseFormContainer from "../../../Location/LocationWarehouseForm/LocationWarehouseFormContainer";
import {DEFAULT_STATE_FORM_INPUT_WAREHOUSE} from "../../constants";
import lodash from "lodash";

const TextArea = Input.TextArea;

class FormWarehouse extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_STATE_FORM_INPUT_WAREHOUSE
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        let id_warehouse = !lodash.isNil(props.warehouseData) ? lodash.get(props, 'warehouseData.id', 0) : 0;
        if (state.id_warehouse !== id_warehouse && id_warehouse !== 0) {
            nextState = lodash.isNil(props.warehouseData) ? {...DEFAULT_STATE_FORM_INPUT_WAREHOUSE} : {
                name: lodash.get(props, 'warehouseData.name', ""),
                code: lodash.get(props, 'warehouseData.code', ""),
                phone: lodash.get(props, 'warehouseData.phone', ""),
                address: lodash.get(props, 'warehouseData.address', ""),
                // note: lodash.get(props, 'warehouseData.note', ""),
                id_location: lodash.get(props, 'warehouseData.id_location')
            };
            nextState.id_warehouse = id_warehouse;
        }

        return nextState;
    }

    onChangeInput(event, valueInput = 0) {
        let value = event.target ? event.target.value : valueInput;
        let name = event.target ? event.target.name : event;
        this.setState({[name]: value});
        this.props.onSubmit({...this.state, [name]: value});
        if (this.props.errors[name]) {
            this.props.resetError(name);
        }
    }

    onSubmit = (values) => {
        this.setState({...values});
        this.props.onSubmit(values)
    };

    render() {
        const {t, errors, warehouseData, loading} = this.props;
        return <React.Fragment>
            <Form onSubmit={this.handleSubmit} className="a-form ">
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group">
                        <Form.Item className="a-form__item "
                                   required
                                   label={t('config:label.name_warehouse')}
                                   validateStatus={errors.name ? 'error' : null}
                                   help={errors.name}
                                   labelCol={{span: 24}}>
                            <Input className={"a-input _a-input-name"}
                                   name={'name'}
                                   disabled={loading}
                                   placeholder={t('config:placeholder.name_warehouse')}
                                   value={this.state.name === '' ? null : this.state.name}
                                   onChange={this.onChangeInput.bind(this)}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group">
                        <Form.Item className="a-form__item "
                                   label={t('config:label.code_warehouse')}
                                   required
                                   validateStatus={errors.code ? 'error' : null}
                                   help={errors.code}
                                   labelCol={{span: 24}}>
                            <Input className={"a-input _a-input-code-warehouse"}
                                   name={'code'}
                                   disabled={lodash.keys(warehouseData).length > 0 || loading}
                                   placeholder={t('config:placeholder.code_warehouse')}
                                   value={this.state.code === '' ? null : this.state.code}
                                   onChange={this.onChangeInput.bind(this)}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group">
                        <Form.Item className="a-form__item "
                                   label={t('config:label.phone_warehouse')}
                                   labelCol={{span: 24}}>
                            <Input className={"a-input _a-input-phone"}
                                   name={'phone'}
                                   placeholder={t('config:placeholder.phone_warehouse')}
                                   value={this.state.phone === '' ? null : this.state.phone}
                                   onChange={this.onChangeInput.bind(this)}/>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <LocationWarehouseFormContainer
                        errors={errors}
                        warehouseData={warehouseData}
                        resetError={this.props.resetError}
                        onSubmit={this.onSubmit}
                    />
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                    <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                         className="a-form--group">
                        <Form.Item className="a-form__item  " label={t('config:label.address_warehouse')}
                                   required
                                   validateStatus={errors.address ? 'error' : null}
                                   help={errors.address}
                                   labelCol={{span: 24}}>
                            < TextArea className="a-input--textarea _a-input-note"
                                       autosize={{minRows: 1, maxRows: 3}}
                                       allowClear={true}
                                       name={'address'}
                                       spellCheck={false}
                                       disabled={loading}
                                       value={this.state.address}
                                       onChange={this.onChangeInput.bind(this)}
                                       placeholder={t('config:placeholder.address_warehouse')}/>
                        </Form.Item>
                    </Col>
                </Row>


            </Form>
        </React.Fragment>
    }
}

FormWarehouse.defaultProps = {
    warehouseData: {},
    loading: false
};

FormWarehouse.propTypes = {
    warehouseData: PropTypes.object,
    loading: PropTypes.bool,
};
export default withTranslation()(FormWarehouse)
