import {processApiRequest} from "../../Common/saga";
import * as ACTION from "../constants";
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {router} from "../../../system/routing";

export function* createWarehouse(action) {
	yield processApiRequest(ACTION.CREATE_WAREHOUSE, () => apiService.createWarehouse(action.payload), action.payload);
}

export function* createWarehouseSuccess() {
	yield notification.success({message: t('config:message.create_warehouse_success')});
	router.redirect("/config/warehouse/");
}
