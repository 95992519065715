import React, { Component } from 'react';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import SelectAgency from './../../../../Setting/Agency/SelectAgency';
import SelectCreatePartnerContainer from './../../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer';

const { RangePicker } = DatePicker;

class EditReExecutionModal extends Component {
    handleCancel = () => {
        const { form, onCancel } = this.props;

        form.resetFields();
        onCancel();
    };

    handleOk = () => {
        const { form, onOk } = this.props;

        form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }

            const formattedValues = {
                ...values,
                transporting_at: {
                    from: values.transporting_at[0].format('YYYY-MM-DD HH:mm:ss'),
                    to: values.transporting_at[1].format('YYYY-MM-DD HH:mm:ss')
                }
            };

            onOk(formattedValues);
        });
    }

    componentDidUpdate(prevProps) {
        const { form, reExecution, visible } = this.props;

        if (!isEqual(reExecution, prevProps.reExecution)) {
            form.setFieldsValue({
                id: get(reExecution.re_execution, 'id'),
                id_agency: get(reExecution.re_execution, 'id_agency'),
                id_shipping_partner: get(reExecution.shipping_partner, 'id'),
                transporting_at: get(reExecution.re_execution, 'transporting_at') ? moment(get(reExecution.re_execution, 'transporting_at')) : undefined
            });
        }

        if (visible !== prevProps.visible && !visible) {
            form.resetFields();
        }
    }

    render() {
        const { form, formErrors, loading, t, reExecution, visible } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                centered
                className="_edit-shipping-fee-modal"
                title={form.getFieldValue('id') ? t('shipping_fee:re_execution.update.title') : t('shipping_fee:re_execution.add.title')}
                visible={visible}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        {t('btn.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                        {t('btn.ok')}
                    </Button>
                ]}
                onCancel={this.handleCancel}
            >
                <Form>
                    <Form.Item
                        className="a-hidden"
                        label={t('id')}
                    >
                        {getFieldDecorator('id', {
                            defaultValue: get(reExecution.re_execution, 'id')
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        help={get(formErrors, 'id_shipping_partner')}
                        label={t('shipping_partner')}
                        validateStatus={get(formErrors, 'id_shipping_partner') ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_shipping_partner', {
                            defaultValue: get(reExecution.shipping_partner, 'id'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('shipping_partner')
                                })
                            }]
                        })(
                            <SelectCreatePartnerContainer
                                className="_id-shipping-partner"
                                disabled={!!form.getFieldValue('id')}
                                dropdownClassName="_dropdown-id-shipping-partner"
                                isFieldDecorator
                                onChange={this.handleOnChange}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        help={get(formErrors, 'transporting_at')}
                        label={t('application_time')}
                        validateStatus={get(formErrors, 'transporting_at') ? 'error' : undefined}
                    >
                        {getFieldDecorator('transporting_at', {
                            defaultValue: get(reExecution.re_execution, 'transporting_at'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('application_time')
                                })
                            }]
                        })(
                            <RangePicker
                                className="_transporting-at width-100-pc"
                                format="HH:mm:ss DD/MM/YYYY"
                                locale={locale}
                                placeholder={[t('label.time_start'), t('label.time_end')]}
                                showTime={{
                                    defaultValue: [
                                        moment('00:00:00', 'HH:mm:ss'),
                                        moment('23:59:59', 'HH:mm:ss')
                                    ]
                                }}
                                onChange={this.handleOnChange}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        help={get(formErrors, 'id_agency')}
                        label="Agency"
                        validateStatus={get(formErrors, 'id_agency') ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_agency', {
                            defaultValue: get(reExecution.re_execution, 'id_agency'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: 'Agency'
                                })
                            }]
                        })(
                            <SelectAgency
                                placeholder=""
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default withTranslation()(Form.create()(EditReExecutionModal));
