import {connect} from 'react-redux';
import AddStaffInWarehouse from "./components"
import {setMenuActive} from "../../Common/actions";
import * as detailWarehouseSelectors from "../DetailWarehouse/selectors";
import * as userWarehouseSelectors from "../../User/selectors";
import * as selectors from "./selectors";
import {getListUser} from "./selectors";
import {clearState, fetchUsers} from "../../User/actions"
import {createStaffInWarehouse} from "./actions"
import lodash from "lodash";

const mapStateToProps = (state, props) => ({
	warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
	listUser: getListUser(state),
	loading: detailWarehouseSelectors.getState(state, 'loading', false) ||
		selectors.getState(state, 'loading', false) ||
		userWarehouseSelectors.getState(state, 'loading', false) ||
		lodash.get(state, "location.loading", false),
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	clearState: () => dispatch(clearState()),
	fetchUsers: () => dispatch(fetchUsers()),
	createStaffInWarehouse: (params) => dispatch(createStaffInWarehouse(params)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddStaffInWarehouse);
