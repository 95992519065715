import React, { useState } from 'react';
import { Checkbox, Radio, Typography } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import map from 'lodash/map';
import { withTranslation } from 'react-i18next';

import { useDeepCompareEffect } from './../../../../../../hooks';
import {isUnlinkOrder} from "../../../../helper";

const { Title } = Typography;

const SelectServices = ({ consignServiceGroups, pkg, serviceGroups, t, onChange }) => {
    const [selectedServices, setSelectedServices] = useState([]);

    const handleChangeServices = (serviceGroup, service, event) => {
        const values = calculateValues(selectedServices, service, event.target.checked, serviceGroup);

        setSelectedServices(values);
        onChange(values);
    };

    const calculateValues = (values, service, selected, serviceGroup) => {
        let newValues = [];

        // Bỏ chọn service
        if (!selected) {
            newValues = values.filter(value => value !== service.id);
        } else if (serviceGroup.multi_select) { // Chọn service mà group cho phép multi select
            newValues = [...values, service.id];
        } else {
            // Bỏ chọn radio service
            if (values.includes(service.id)) {
                newValues = values.filter(value => value !== service.id)
            } else {
                // Chọn service mà group không cho phép multi select
                newValues = selectedServices.filter(value => !inServices(value, serviceGroup.services));
                newValues = [
                    ...newValues,
                    service.id
                ];
            }
        }

        return newValues;
    };

    const inServices = (serviceId, services) => {
        return map(services, 'id').includes(serviceId);
    };

    const renderConsignServiceGroups = () => {
        return consignServiceGroups.map(consignServiceGroup => (
            <div className="mb-4" key={consignServiceGroup.id}>
                <Title className="a-text--fz-14" level={4}>
                    {t(`package:service_groups.${consignServiceGroup.code}`)}
                </Title>

                <div>
                    {consignServiceGroup.services.map(service => renderService(service, consignServiceGroup))}
                </div>
            </div>
        ));
    };

    const renderServiceGroups = () => {
        return serviceGroups.map(serviceGroup => (
            <div className="mb-4" key={serviceGroup.id}>
                <Title className="a-text--fz-14" level={4}>
                    {t(`package:service_groups.${serviceGroup.code}`)}
                </Title>

                <div>
                    {serviceGroup.services.map(service => renderService(service, serviceGroup))}
                </div>
            </div>
        ));
    };

    const renderService = (service, consignServiceGroup) => {
        const Component = consignServiceGroup.multi_select ? Checkbox : Radio;

        return (
            <Component
                checked={selectedServices.includes(service.id)}
                className={clsx(`ml-0 mr-4 mb-4 dpl-inline-flex a-package--checking ${service.type}-${service.code}`, {
                    'a-text--green _consign-service': service.type === 'consign',
                    '_normal-service': service.type === 'normal'
                })}
                key={service.id}
                value={service.id}
                onChange={handleChangeServices.bind(undefined, consignServiceGroup, service)}
                disabled={!isUnlinkOrder(pkg)}
            >
                {service.name}
            </Component>
        );
    };

    useDeepCompareEffect(() => {
        const serviceIds = map(get(pkg, 'services'), 'id');

        setSelectedServices(serviceIds);
    }, [pkg]);

    return (
        <>
            <Title className="a-text--fz-16" level={3}>
                {t('select_service')}
            </Title>

            {renderConsignServiceGroups()}
            {renderServiceGroups()}
        </>
    );
};

SelectServices.defaultProps = {
    consignServiceGroups: [],
    serviceGroups: []
};

export default withTranslation()(SelectServices);
