import React, { Component } from 'react';
import { Button } from 'antd/lib/index';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { url } from './../../../../system/routing';

import SelectCreatePartnerContainer from './../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer';

class SelectShippingPartnerDeliveryNote extends Component {
    state = {
        id_shipping_partner: 0,
    };

    changeSelectValue = (name, input) => {
        this.setState({
            [name]: input.value
        });
    };

    onSearch = () => {
        const { id_shipping_partner } = this.state;
        const { input } = this.props;
        const routeName = this.props.routeName || 'delivery-notes.create';

        url.redirectTo(routeName, null, {
                id_shipping_partner: id_shipping_partner,
                id_warehouse : input.id_warehouse,
            }
        );
    };

    render() {
        const { defaultOptionLabel, hasDefaultOption, loading, shippingPartnerType, t } = this.props;

        return (
            <div className="gflex gflex-direction-column">
                <div className="a-select a-select--export-scan">
                    <SelectCreatePartnerContainer
                        active
                        autoSelect={false}
                        defaultOptionLabel={defaultOptionLabel}
                        disabled={false}
                        hasDefaultOption={hasDefaultOption}
                        loading={loading}
                        placeholder={t('select_partner')}
                        shippingPartnerType={shippingPartnerType}
                        value={this.state.id_shipping_partner}
                        onChange={this.changeSelectValue.bind(this, 'id_shipping_partner')}
                    />
                </div>
                <Button
                    className="a-btn a-btn--scan-export a-btn--primary mt-5 _btn-submit"
                    loading={loading}
                    onClick={this.onSearch}
                >
                    {t('btn.choose')}
                </Button>
            </div>
        );
    }
}


SelectShippingPartnerDeliveryNote.defaultProps = {
    loading: false,
    routeName: ''
};

SelectShippingPartnerDeliveryNote.propTypes = {
    loading: PropTypes.bool,
    routeName: PropTypes.string
};


export default withTranslation()(SelectShippingPartnerDeliveryNote);
