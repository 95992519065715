import { api } from './../../../system/api';

export default {
    create: (params = {}) => api.post('delivery-notes/customer', {
        ...params
    }, {
        singleRequest: true
    }),
    createWithTrackingNo: (data) => api.post ('/delivery-notes/create-with-tracking-no', data, {singleRequest: true}),
    createTrackingNoWithCodIsZero: (deliveryNoteId, data) => api.post(`/delivery-notes/${deliveryNoteId}/create-tracking-no-with-cod-is-zero`, data, {singleRequest: true}),
    createTaskCheckPaymentStatus: (data) => api.post(`/tasks/check-payment-status`, data),
    handleProcessTaskId: (taskId) => api.get(`/tasks/${taskId}`),
};
