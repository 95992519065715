export const FETCH_DELIVERY_REQUESTS = {
    REQUEST: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST.FETCH_DELIVERY_REQUESTS.REQUEST',
    SUCCESS: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST.FETCH_DELIVERY_REQUESTS.SUCCESS',
    FAILED: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST.FETCH_DELIVERY_REQUESTS.FAILED',
}
export const FETCH_DELIVERY_REQUESTS_BY_CUSTOMER = {
    REQUEST: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST_BY_CUSTOMER.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.REQUEST',
    SUCCESS: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST_BY_CUSTOMER.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.SUCCESS',
    FAILED: 'DELIVERY_REQUESTS.LIST_DELIVERY_REQUEST_BY_CUSTOMER.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.FAILED',
}
export const FETCH_DELIVERY_CUSTOMER_REQUESTS = {
    REQUEST: 'DELIVERY_REQUESTS.LIST_DELIVERY_CUSTOMER_REQUEST.FETCH_DELIVERY_CUSTOMER_REQUESTS.REQUEST',
    SUCCESS: 'DELIVERY_REQUESTS.LIST_DELIVERY_CUSTOMER_REQUEST.FETCH_DELIVERY_CUSTOMER_REQUESTS.SUCCESS',
    FAILED: 'DELIVERY_REQUESTS.LIST_DELIVERY_CUSTOMER_REQUEST.FETCH_DELIVERY_CUSTOMER_REQUESTS.FAILED',
}
export const FETCH_LIST_PACKAGE_EXPORT = {
    REQUEST: 'DELIVERY_REQUESTS.FETCH_LIST_PACKAGE_EXPORT.REQUEST',
    SUCCESS: 'DELIVERY_REQUESTS.FETCH_LIST_PACKAGE_EXPORT.SUCCESS',
    FAILED: 'DELIVERY_REQUESTS.FETCH_LIST_PACKAGE_EXPORT.FAILED',
}
export const UPDATE = {
    REQUEST: 'DELIVERY_REQUEST.UPDATE.REQUEST',
    SUCCESS: 'DELIVERY_REQUEST.UPDATE.SUCCESS',
    FAILED: 'DELIVERY_REQUEST.UPDATE.FAILED',
}

export const RESET_LIST_DELIVERY_REQUEST = 'RESET_LIST_DELIVERY_REQUEST'
export const RESET_LIST_DELIVERY_CUSTOMER_REQUEST = 'RESET_LIST_DELIVERY_CUSTOMER_REQUEST'

export const DELIVERY_REQUEST_CUSTOMER_COLUMN = {
    ID_CUSTOMER: 'id_customer',
    AGENCY: 'agency',
    TOTAL_REQUEST: 'total_requests',
    TOTAL_PACKAGE: 'total_package',
    TOTAL_WEIGHT: 'total_weight',
    ACTION: 'action',
}
export const DELIVERY_REQUEST_CHILD_COLUMN = {
    ID_DELIVERY_REQUEST: 'id_delivery_request',
    WAREHOUSE: 'warehouse',
    CREATE_AT: 'createdAt',
    NUM_PACKAGE: 'num_package',
    TOTAL_WEIGHT: 'total_weight',
    ADDRESS: 'address',
    NOTE: 'note',
    ACTION: 'action',
}
export const DELIVERY_REQUEST_COLUMN = {
    ID_CUSTOMER: 'id_customer',
    ID_DELIVERY_REQUEST: 'id_delivery_request',
    DELIVERY_NOTES: 'delivery_notes',
    AGENCY: 'agency',
    WAREHOUSE: 'warehouse',
    CREATE_AT: 'createdAt',
    NUM_PACKAGE: 'num_package',
    TOTAL_WEIGHT: 'total_weight',
    ADDRESS: 'address',
    ACTION: 'action',
}
export const DELIVERY_REQUEST_STATUS = {
    STATUS_NEW: 'NEW',
    STATUS_PROCESSING: 'PENDING',
    STATUS_DELIVERING: 'DELIVERING',
    STATUS_TRANSFERRED_TO_LASTMILE: 'TRANSFERRED_TO_LASTMILE',
    STATUS_COMPLETED: 'COMPLETED',
    STATUS_CANCELED: 'CANCELLED',
    STATUS_FAILED: 'FAILED',
}
