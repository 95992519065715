import { api } from '../../system/api'

export default {
    getListBillOfLading: params => api.get('/last-mile-shipments', { params }),
    exportListBillOfLading: params =>
        api.get(`/last-mile-shipments/download`, {
            params,
            responseType: 'blob',
        }),
}
