import React from 'react';
import { Button, Form, Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ACTION_COMPLETE_PACKING } from './../constants';
import { router } from './../../../../system/routing';

const { Option } = Select;

class UpdateWeightRealBag extends React.PureComponent {
    onCompletePackingBag = value => {
        this.props.completePackingBag({id: this.props.bag.id, action: value});
    };

    submitFrom = action => {
        this.props.completePackingBag({id: this.props.bag.id, action: action});
    };

    redirectListUnPackingBag = () => {
        router.redirect('/bags/list-pack?packing_complete=0');
    };

    render() {
        const { bag, disabled, loading, t } = this.props;

        return (
            <Form.Item className="a-form__item item--bag-pack" labelCol={{span: 24}}>
                <div className="item--bag-pack--action">
                    {bag.packing_complete ? (bag.confirmed_packing_complete ? (
                        <Button
                            className="a-btn a-btn--primary a-btn--action-bag-pack _unpacking-bag-list"
                            type="primary"
                            onClick={this.redirectListUnPackingBag}
                        >
                            {t('menu.list_bag_pack')}
                        </Button>
                    ) : (
                        <Button
                            className="a-btn a-btn--primary a-btn--action-bag-pack _confirm-finish-packing-bag"
                            loading={loading}
                            type="primary"
                            onClick={this.submitFrom.bind(this, ACTION_COMPLETE_PACKING.END_PACKING)}
                        >
                            {t('confirm_finish_packing_bag')}
                        </Button>
                    )) : (
                        <>
                            <Button
                                className={clsx('a-btn a-btn--primary _btn_end_packing a-btn--action-bag-pack', {
                                    'disabled-bag-pack': loading || disabled
                                })}
                                disabled={loading || disabled}
                                loading={loading}
                                type="primary"
                                onClick={this.submitFrom.bind(this, ACTION_COMPLETE_PACKING.END_PACKING)}
                            >
                                {t('bag:btn.end_packing')}
                            </Button>
                            <Select
                                className={clsx('a-select _select_action_complete_packing a-select--action-bag-pack', {
                                    'disabled-bag-pack': loading || disabled
                                })}
                                disabled={loading || disabled}
                                dropdownClassName="a-select--action-bag-pack--dropdown"
                                dropdownStyle={{left: 0}}
                                loading={loading}
                                suffixIcon={(
                                    <span className="icon--more" />
                                )}
                                onChange={this.onCompletePackingBag}
                            >
                                <Option
                                    className="_option_end_packing_create_new_bag"
                                    value={ACTION_COMPLETE_PACKING.END_PACKING_CREATE_NEW_BAG}
                                >
                                    {t('bag:btn.end_packing_create_new_bag')}
                                </Option>
                                <Option
                                    className="_option_end_packing_go_detail"
                                    value={ACTION_COMPLETE_PACKING.END_PACKING_GO_DETAIL}
                                >
                                    {t('bag:btn.end_packing_go_detail')}
                                </Option>
                            </Select>
                        </>
                    )}
                </div>
            </Form.Item>
        );
    }
}

UpdateWeightRealBag.defaultProps = {
    bag: {},
    loading: false,
    disabled: false,
};

UpdateWeightRealBag.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    bag: PropTypes.object,
    completePackingBag: PropTypes.func,
};

export default (withTranslation()(UpdateWeightRealBag));
