import { notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ListPackage from './ListPackage'
import Log from './Log'
import BagRequestInfo from './BagRequestInfo'
import pageService from '../../../Common/Page/pageService'
import { setMenuActive } from '../../../Common/actions'
import apiService from '../../apiService'
import { getVar } from '../../../../system/support/helpers'
import { Link, url } from '../../../../system/routing'
import { linkHTC } from '../../../Package/helper'
import { filter, isEmpty, map, round } from 'lodash'
import { PACKAGE_IN_BAG_REQUEST_COLUMN } from '../constants'

function BagRequestDetail(props) {
    const {
        t,
        match: { params },
    } = props

    const { id } = params

    const [data, setData] = useState({})
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [packages, setPackages] = useState([])
    const [loadingPackage, setLoadingPackage] = useState(false)
    const [pagination, setPagination] = useState(false)

    useEffect(() => {
        pageService.setTitle(t('bag:title.bag_request_detail'))
        setMenuActive('bag/request-list')
    }, [])

    useEffect(() => {
        fetchDetail()
        fetchPackageList()
    }, [id])

    const fetchDetail = () => {
        setLoadingDetail(true)
        apiService
            .getBagRequestDetail(id)
            .then(res => {
                setData(getVar(res, 'data', {}))
            })
            .catch(err => {
                const { response } = err
                const code = getVar(response, 'data.code', '')
                if (code === 403) {
                    url.redirect('/403')
                } else {
                    notification.error({ message: t('message.server_error') })
                }
            })
            .finally(() => {
                setLoadingDetail(false)
            })
    }

    const fetchPackageList = (params = {}) => {
        setLoadingPackage(true)
        apiService
            .getPackagesInBagRequest(id, params)
            .then(res => {
                setPackages(refactorDataPackages(getVar(res, 'data.data', [])))
                setPagination(getVar(res, 'data.meta.pagination', {}))
            })
            .catch(err => {
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                setLoadingPackage(false)
            })
    }

    const refactorDataPackages = data => {
        return data.map(item => {
            const order = getVar(item, 'order', {})
            const orderCode = getVar(order, 'code')
            const linkHtc = linkHTC(getVar(item, 'agency.code'))
            const agency = getVar(item, 'agency', {})
            const warehouseShelf = getVar(item, 'package_detail.warehouse_shelf')
            const weightNet = getVar(item, 'weight_net')
            const volume = getVar(item, 'volume')
            const bag = getVar(item, 'bag', {})
            const shippingPartner = getVar(item, 'shipping_partner', {})
            const properties = filter(map(getVar(item, 'properties.data'), 'alias'), item => item !== '')
            const services = filter(map(getVar(item, 'services.data'), 'alias'), item => item !== '')
            return {
                id: item.id,
                [PACKAGE_IN_BAG_REQUEST_COLUMN.PACKAGE_CODE]: (
                    <Link
                        isNewTab
                        to="packages.detail"
                        params={{ id: item.code }}>
                        {item.code}
                    </Link>
                ),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.ORDER_CODE]: orderCode ? (
                    <a
                        href={linkHtc ? linkHtc + '/order/detail/default/' + orderCode : getVar(order, 'm1_url') || '#top'}
                        rel="noopener noreferrer"
                        target={getVar(order, 'm1_url') ? '_blank' : '_self'}>
                        {orderCode}
                    </a>
                ) : (
                    '---'
                ),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.AGENCY]: !isEmpty(agency) ? `${getVar(agency, 'name')} (${getVar(agency, 'code')})` : '---',
                [PACKAGE_IN_BAG_REQUEST_COLUMN.WAREHOUSE_AREA]: !isEmpty(warehouseShelf) ? getVar(warehouseShelf, 'code') : '---',
                [PACKAGE_IN_BAG_REQUEST_COLUMN.WEIGHT]: weightNet ? weightNet + ' (kg)' : '--',
                [PACKAGE_IN_BAG_REQUEST_COLUMN.VOLUME]: volume ? round(volume * 1000000, 3) + ' (cm3)' : '--',
                [PACKAGE_IN_BAG_REQUEST_COLUMN.PROPERTIES]: properties.join(', '),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.SERVICES]: services.join(', '),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.CUSTOMER_NAME]: getVar(item, 'customer_username', '--'),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.TRANSPORT_STATUS]: t(`package:status_transport.${getVar(item, 'status_transport')}`),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.BAG]: !isEmpty(bag) ? (
                    <Link
                        isNewTab
                        to="bags.detail"
                        params={{ id: getVar(bag, 'id') }}>
                        {getVar(bag, 'code')}
                    </Link>
                ) : (
                    '---'
                ),
                [PACKAGE_IN_BAG_REQUEST_COLUMN.MIDMILE_SHIPPING_PARTNER]: !isEmpty(shippingPartner)
                    ? `${getVar(shippingPartner, 'name')} (${getVar(shippingPartner, 'code')})`
                    : '---',
                [PACKAGE_IN_BAG_REQUEST_COLUMN.NOTE]: getVar(item, 'note', '---'),
            }
        })
    }

    return (
        <Row type="flex">
            <div className="a-content--ischatbox a-package--detail">
                <BagRequestInfo
                    data={data}
                    loading={loadingDetail}
                />
                <ListPackage
                    packages={packages}
                    loading={loadingPackage}
                    pagination={pagination}
                    refetch={fetchPackageList}
                    data={data}
                />
            </div>
            <Log objectId={id} />
        </Row>
    )
}
export default withTranslation()(BagRequestDetail)
