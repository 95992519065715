/* eslint-disable */
import React, {Component} from 'react';
import {  Row, Col,} from 'antd';

class ViewBag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="block--primary a-content--bag  ">
                    <div className="title">
                        <div className="left">
                            <span className="a-text--bold">HHA01 - 12385948459</span>
                        </div>
                    </div>
                    <div className="body ">
                        <Row gutter={{lg: 24, xl: 48}}>
                            <Col lg={{span: 17}} xl={{span: 19}} xxl={{span: 19}}>
                                <div className="a-block-info bg-gray">
                                    <Row gutter={{lg: 24, xl: 32}} className="a-form mt-5 mr-0 ml-0">
                                        <Col lg={{span: 12}} xl={{span: 6}} xxl={{span: 4}}
                                             className="info--group">
                                            <label className="info--label">Kích thước</label>
                                            <span className="info--value ">Siêu Lớn</span>
                                        </Col>
                                        <Col lg={{span: 12, offset: 0}} xl={{span: 18, offset: 0}}
                                             xxl={{span: 18, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Đặc tính</label>
                                            <span className="info--value ">Hàng mỏng / Nhẹ / Dễ vỡ / Dễ cháy</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-form  mr-0 ml-0">
                                        <Col lg={{span: 12}} xl={{span: 6}} xxl={{span: 4}}
                                             className="info--group">
                                            <label className="info--label">Loại vận chuyển</label>
                                            <span className="info--value ">Đơn vị</span>
                                        </Col>
                                        <Col lg={{span: 12, offset: 0}} xl={{span: 6, offset: 0}}
                                             xxl={{span: 4, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Vận chuyển</label>
                                            <span className="info--value ">Đơn vị vận chuyển 3</span>
                                        </Col>
                                        <Col lg={{span: 12, offset: 0}} xl={{span: 6, offset: 0}}
                                             xxl={{span: 4, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Người nhận</label>
                                            <span className="info--value "> Jason Statham</span>
                                        </Col>
                                        <Col lg={{span: 12, offset: 0}} xl={{span: 6, offset: 0}}
                                             xxl={{span: 4, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Số điện thoại nhận</label>
                                            <span className="info--value "> +91.0909.09090</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-form  mr-0 ml-0">
                                        <Col lg={{span: 12}} xl={{span: 6}} xxl={{span: 4}}
                                             className="info--group">
                                            <label className="info--label">Kho tạo</label>
                                            <span className="info--value "> USNY-A</span>
                                        </Col>
                                        <Col lg={{span: 12, offset: 0}} xl={{span: 6, offset: 0}}
                                             xxl={{span: 4, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Kho nhận</label>
                                            <span className="info--value "> VNHN-A</span>
                                        </Col>
                                        <Col lg={{span: 24, offset: 0}} xl={{span: 12, offset: 0}}
                                             xxl={{span: 12, offset: 2}}
                                             className="info--group">
                                            <label className="info--label">Ghi chú</label>
                                            <span className="info--value ">Warning! Jason Statham </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={{span: 7}} xl={{span: 5}} xxl={{span: 5}}
                                 className="a-package--service only-view is-border-left">
                                <h2 className="title">Dịch vụ sử dụng</h2>
                                <div className="service--body ">
                                    <div className="service--block">
                                        <div className="service--block--body">
                                            <ul>
                                                <li>
                                                    <span>Chuyển thẳng</span>
                                                    <span>Đóng gỗ riêng </span>
                                                    <span>Kiểm hàng </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ViewBag;