import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';

import { NOTE_TYPES } from './../../constants';
import pageService from './../../../Common/Page/pageService';

import FormSearch from './../../components/FormSearch';
import List from './../../components/List';

const { Content } = Layout;

const ListOrder = props => {
	useEffect(() => {
		pageService.setTitle(props.t('note:label.list_note_order'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Content className="a-content a-content--page-list">
			<FormSearch
				{...props}
				object={NOTE_TYPES.ORDER}
			/>
			<List
				{...props}
				loadingSuggestObject={props.loadingSuggestOrder}
				object={NOTE_TYPES.ORDER}
				suggestObject={props.suggestOrder}
				clearSuggestObject={props.clearSuggestOrder}
				getSuggestObject={props.getSuggestOrders}
				setSuggestObjectId={props.setSuggestOrderId}
			/>
		</Content>
	);
};

export default withTranslation()(ListOrder);
