import { map } from 'lodash';
import { createSelector } from 'reselect';

import { TABLE_COLUMNS } from './constants';
import { dateFormatter } from './../../Common/services/format';
import { t } from './../../../system/i18n';
import { getVar } from './../../../system/support/helpers';

export const getState = (state, param = null, defaultValue = null) => getVar(state, `shippingFee.reExecution${(param ? `.${param}` : '')}`, defaultValue);

export const getTableDisplayReExecutions = createSelector(
    state => getState(state, 'reExecutions', []),
    reExecutions => map(reExecutions, reExecution => ({
        id : getVar(reExecution, 'process_shipping_fee.id'),
        [TABLE_COLUMNS.SHIPPING_PARTNER]: getVar(reExecution, 'shipping_partner.name', '--'),
        [TABLE_COLUMNS.AGENCY]: getVar(reExecution, 'agency.name', '--'),
        [TABLE_COLUMNS.STARTED_AT]: getVar(reExecution, 'process_shipping_fee.started_at') ? dateFormatter.full(reExecution.process_shipping_fee.started_at) : '--',
        [TABLE_COLUMNS.FINISHED_AT]: getVar(reExecution, 'process_shipping_fee.finished_at') ? dateFormatter.full(reExecution.process_shipping_fee.finished_at) : '--',
        [TABLE_COLUMNS.CREATOR]: getVar(reExecution, 'creator.username', '--'),
        [TABLE_COLUMNS.CREATED_AT]: getVar(reExecution, 'process_shipping_fee.created_at') ? dateFormatter.full(reExecution.process_shipping_fee.created_at) : '--',
        [TABLE_COLUMNS.STATE]: getVar(reExecution, 'process_shipping_fee.state') ? t(`shipping_fee:re_execution_states.${reExecution.process_shipping_fee.state}`) : '--',
    }))
);
