import {notification} from 'antd';
import * as _ from 'lodash';
import apiService from "./apiService";
import {processApiRequest} from "../Common/saga";
import * as ACTION from "./constants";
import {t} from "../../system/i18n";
import {router} from "../../system/routing";

export function* fetchTemplateBags(action) {
    yield processApiRequest(ACTION.FETCH_TEMPLATE_BAGS, () => apiService.fetchTemplateBags(action.payload.params));
}

export function* createTemplateBag(action) {
    yield processApiRequest(ACTION.CREATE_TEMPLATE_BAG, () => apiService.createTemplateBag(action.payload.data));
}

export function* createTemplateSuccess(action) {
    yield notification.success({
        message: t('template_bag:create_success')
    });
    yield router.redirect('/config/template-bags');
}

export function* createTemplateFailed(action) {
    const code = _.get(action.payload, 'code');
    if(code !== "INPUT_INVALID") {
        yield notification.error({
            message: t('template_bag:create_failed')
        });
    }
}

export function* updateTemplateBag(action) {
    yield processApiRequest(ACTION.UPDATE_TEMPLATE_BAG, () => apiService.updateTemplateBag(action.payload.id, action.payload.data));
}

export function* updateTemplateBagSuccess(action) {
    yield notification.success({
        message: t('template_bag:update_success')
    });
    yield router.redirect('/config/template-bags');
}

export function* updateTemplateBagFailed(action) {
    const code = _.get(action.payload, 'code');
    if(code !== "INPUT_INVALID") {
        yield notification.error({
            message: t('template_bag:update_failed')
        });
    }
}

export function* fetchTemplateBagDetail(action) {
    const id = _.get(action.payload, 'id');
    const params = _.get(action.payload, 'params');
    yield processApiRequest(ACTION.FETCH_TEMPLATE_BAG_DETAIL, () => apiService.fetchTemplateBagDetail(id, params), action.payload);
}