import React, { Component } from 'react';
import { Icon, Input } from 'antd';
import clsx from 'clsx';

const { TextArea } = Input;

class InlineEditInput extends Component {
    state = {
        iconedit: true,
        iconremove: false,
        iconsave: false,
        input: false,
        labelvalue: true,
        loading: false,
        value: this.props.value
    }

    onClickEditInline = () => {
        this.setState({
            iconedit: !this.state.iconedit,
            iconremove: !this.state.iconremove,
            iconsave: !this.state.iconsave,
            labelvalue: !this.state.labelvalue,
            input: !this.state.input,
        })
    };

    handleClose = () => {
        const { value } = this.props;

        this.setState({
            iconedit: true,
            iconremove: false,
            iconsave: false,
            input: false,
            labelvalue: true,
            value
        });
    };

    handleChange = event => {
        this.setState({
            value: event.target.value
        });
    };

    handleSubmit = async () => {
        const { value } = this.state;
        const { onSubmit } = this.props;

        this.setState({
            loading: true
        });

        try {
            await onSubmit(value);

            this.onClickEditInline();
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };

    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (value !== prevProps.value) {
            this.setState({
                value
            });
        }
    }

    render() {
        const { iconedit, iconremove, iconsave, input, labelvalue, loading, value } = this.state;
        const { inputClass, inputStyle, inputType, label, labelClass, type, valueRender } = this.props;

        return (
            <div className={clsx('a-inline-edit', {
                'flex-nowrap': labelvalue,
                'gflex-direction-column': type === 'block'
            })}>
                {type !== 'block' && (
                    <div
                        className={clsx('a-inline-edit--value mt-0', {
                            'a-hidden': !labelvalue
                        })}
                    >
                        {valueRender ? valueRender(value) : value}
                    </div>
                )}
                <label className={clsx('a-inline-edit--label', labelClass)}>
                    {label}
                    <Icon
                        className={clsx('icon-inline', {
                            'a-hidden': !iconedit
                        })}
                        type="edit"
                        onClick={this.onClickEditInline}
                    />
                    <Icon
                        className={clsx('icon-inline', {
                            'a-hidden': !iconremove
                        })}
                        type="close"
                        onClick={this.handleClose}
                    />
                    <Icon
                        className={clsx('icon-inline', {
                            'a-hidden': loading || !iconsave
                        })}
                        type="check"
                        onClick={this.handleSubmit}
                    />
                    {loading && (
                        <Icon
                            className="icon-loading icon-inline"
                            type="loading-3-quarters"
                        />
                    )}
                </label>
                {type === 'block' && (
                    <div
                        className={clsx('a-inline-edit--value mt-0', {
                            'a-hidden': !labelvalue
                        })}
                    >
                        {valueRender ? valueRender(value) : value}
                    </div>
                )}
                <div
                    className={clsx('a-inline-edit--input', {
                        'a-hidden': !input
                    })}
                >
                    {inputType === 'textarea' ? (
                        <TextArea
                            className={inputClass}
                            autosize={{minRows: 1, maxRows: 3}}
                            placeholder={this.props.placeholder}
                            style={inputStyle}
                            value={value}
                            onChange={this.handleChange}
                        />
                    ) : (
                        <Input
                            className={inputClass}
                            placeholder={this.props.placeholder}
                            style={inputStyle}
                            value={value}
                            onChange={this.handleChange}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default InlineEditInput;