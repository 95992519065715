import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, InputNumber, Modal, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import clsx from 'clsx';
import { get, isEqual, isNil, isNumber, map } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { formatCurrency } from './../../../../Common/services/helps';

import ServicesSelectContainer from './../../../../Common/components/ServicesSelect/ServicesSelectContainer';
import RoutesSelectContainer from './../../../../Common/components/ShippingFees/RoutesSelect/RoutesSelectContainer';
import SelectCreatePartnerContainer from './../../../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer';

const { Option } = Select;

class EditShippingFeeModal extends Component {
    state = {
        formErrors: this.props.formErrors,
        nextShippingFeeDetailId: 2,
        openEnterDividedConversion: false
    };

    getDefaultShippingFeeDetails = () => [{
        id: 1,
        weight_from: 0
    }];

    handleOnChange = () => {
        this.setState({
            formErrors: null
        });
    };

    handleCancel = () => {
        const { form, onCancel } = this.props;

        this.setState({
            nextShippingFeeDetailId: 2
        });
        form.resetFields();
        onCancel();
    };

    handleOk = () => {
        const { form, onOk } = this.props;

        form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }

            const formattedValues = {
                ...values,
                applied_at: values.applied_at.format('YYYY-MM-DD HH:mm:ss'),
                unit_prices: values.unit_prices.map((unitPrice, index) => ({
                    weight_from: Number(values.weight_froms[index]),
                    unit_price: Number(values.unit_prices[index])
                }))
            };

            onOk(formattedValues);
        });
    }

    addUnitPrice = () => {
        const { form } = this.props;
        const shippingFeeDetails = form.getFieldValue('shipping_fee_details');

        this.setState(prevState => {
            const newNextShippingFeeDetailId = prevState.nextShippingFeeDetailId + 1;

            const newShippingFeeDetails = shippingFeeDetails.concat({
                id: prevState.nextShippingFeeDetailId
            });

            form.setFieldsValue({
                shipping_fee_details: newShippingFeeDetails
            });

            return {
                nextShippingFeeDetailId: newNextShippingFeeDetailId
            };
        });;
    };

    removeUnitPrice = id => {
        const { form } = this.props;
        const shippingFeeDetails = form.getFieldValue('shipping_fee_details');
        const weightFroms = form.getFieldValue('weight_froms');
        const unitPrices = form.getFieldValue('unit_prices');

        const index = shippingFeeDetails.findIndex(shippingFeeDetail => shippingFeeDetail.id === id);

        if (index === -1) {
            return;
        }

        form.setFieldsValue({
            shipping_fee_details: [
                ...shippingFeeDetails.slice(0, index),
                ...shippingFeeDetails.slice(index + 1)
            ],
            weight_froms: [
                ...weightFroms.slice(0, index),
                ...weightFroms.slice(index + 1)
            ],
            unit_prices: [
                ...unitPrices.slice(0, index),
                ...unitPrices.slice(index + 1)
            ]
        });
    };

    validateDuplicateWeightFrom = (value, index) => {
        if (!isNumber(value)) {
            return true;
        }

        const { form } = this.props;
        const weightFroms = form.getFieldValue('weight_froms');

        weightFroms.splice(index, 1);

        const formattedWeightFroms = map(weightFroms, Number);

        const notDuplicateWeightFrom = formattedWeightFroms.findIndex(weightFrom => weightFrom === value) === -1;

        return notDuplicateWeightFrom;
    };

    componentDidUpdate(prevProps) {
        const { form, formErrors, shippingFee, visible } = this.props;

        if (!isEqual(shippingFee, prevProps.shippingFee)) {
            const shippingFeeDetails = get(shippingFee, 'shipping_fee_details', []);
            const newShippingFeeDetails = shippingFeeDetails.length ? shippingFeeDetails : this.getDefaultShippingFeeDetails();
            const greatestId = Math.max(...map(newShippingFeeDetails, 'id'));

            form.setFieldsValue({
                id: get(shippingFee.shipping_fee, 'id'),
                id_shipping_partner: get(shippingFee, 'shipping_partner.id'),
                id_services: get(shippingFee, 'service.id') ? [shippingFee.service.id] : [],
                id_locations: get(shippingFee, 'shipping_fee.id_location') ? [shippingFee.shipping_fee.id_location] : [],
                applied_at: get(shippingFee, 'shipping_fee.applied_at') ? moment(shippingFee.shipping_fee.applied_at) : undefined,
                divided_conversion: get(shippingFee, 'shipping_fee.divided_conversion'),
                weight_by: get(shippingFee, 'shipping_fee.weight_by'),
                shipping_fee_details: newShippingFeeDetails
            });

            this.setState({
                nextShippingFeeDetailId: greatestId + 1
            });
        }

        if (!isEqual(formErrors, prevProps.formErrors)) {
            this.setState({
                formErrors
            });
        }

        if (visible !== prevProps.visible && !visible) {
            form.resetFields();
        }
    }

    render() {
        const { consignServices, form, formErrors, loading, routes, t, services, shippingFee, visible } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const {openEnterDividedConversion} = this.state;
        getFieldDecorator('shipping_fee_details', {
            initialValue: this.getDefaultShippingFeeDetails()
        });

        const shippingFeeDetails = getFieldValue('shipping_fee_details');
        const unitPriceFormItems = shippingFeeDetails.map((shippingFeeDetail, index) => (
            <Row gutter={8} key={shippingFeeDetail.id} type="flex">
                <Col span={10}>
                    <Form.Item
                        key={shippingFeeDetail.id}
                        label={index === 0 ? t('weight_by') : undefined}
                    >
                        <div className="gflex">
                            <span className="w-20 mr-3">
                                {t('from')}
                            </span>
                            {getFieldDecorator(`weight_froms[${index}]`, {
                                initialValue: shippingFeeDetail.weight_from,
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('weight_by')
                                    })
                                }, {
                                    validator: async (rule, value, callback) => {
                                        if (value && Number.isNaN(Number(value))) {
                                            callback(t('validation:numeric', {
                                                attribute: t('weight_by')
                                            }));
                                        } else if (value && Number(value) <= 0 && index > 0) {
                                            callback(t('validation:gt', {
                                                attribute: t('weight_by'),
                                                value: 0
                                            }));
                                        } else if (!this.validateDuplicateWeightFrom(value, index)) {
                                            callback(t('validation:distinct', {
                                                attribute: t('weight_by')
                                            }));
                                        } else {
                                            callback();
                                        }
                                    }
                                }]
                            })(
                                index === 0 ? (
                                    <InputNumber
                                        className="width-100-pc"
                                        disabled
                                        formatter={value => {
                                            if (isNil(value) || value === '') {
                                                return value;
                                            }

                                            return `${value} kg`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        }}
                                        parser={value => value.replace(/k|g|\s|(,*)/g, '')}
                                        placeholder={`${t('shipping_fee:weight_from')} (kg)`}
                                    />
                                ) : (
                                    <InputNumber
                                        className="width-100-pc"
                                        formatter={value => {
                                            if (isNil(value) || value === '') {
                                                return value;
                                            }

                                            return `${value} kg`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        }}
                                        parser={value => value.replace(/k|g|\s|(,*)/g, '')}
                                        placeholder={`${t('shipping_fee:weight_from')} (kg)`}
                                    />
                                )
                            )}
                        </div>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        label={index === 0 ? t('weight_by') : undefined}
                        labelCol={{
                            className: clsx({
                                invisible: index === 0
                            })
                        }}
                    >
                        <div className="gflex">
                            {getFieldDecorator(`unit_prices[${index}]`, {
                                initialValue: shippingFeeDetail.unit_price,
                                rules: [{
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('fee')
                                    })
                                }, {
                                    validator: async (rule, value, callback) => {
                                        if (value && !Number.isInteger(Number(value))) {
                                            callback(t('validation:integer', {
                                                attribute: t('fee')
                                            }));
                                        } else if (value && Number(value) <= 0 && index > 0) {
                                            callback(t('validation:gt', {
                                                attribute: t('fee'),
                                                value: 0
                                            }));
                                        } else {
                                            callback();
                                        }
                                    }
                                }]
                            })(
                                <InputNumber
                                    className="width-100-pc"
                                    formatter={value => {
                                        if (isNil(value) || value === '') {
                                            return value;
                                        }

                                        return `${formatCurrency(value)} VNĐ`;
                                    }}
                                    parser={value => value.replace(/V|N|Đ|\s|(,*)/g, '')}
                                    placeholder={t('fee')}
                                />
                            )}
                            <span className="w-20 ml-3">
                            </span>
                        </div>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label={index === 0 ? t('weight_by') : undefined}
                        labelCol={{
                            className: clsx({
                                invisible: index === 0
                            })
                        }}
                        wrapperCol={{
                            className: 'a-text--right'
                        }}
                    >
                        {index === shippingFeeDetails.length - 1 && (
                            <Icon
                                className="_btn-add-unit-price a-text--blue"
                                type="plus-circle-o"
                                onClick={this.addUnitPrice}
                            />
                        )}
                        {index !== 0 && (
                            <Icon
                                className={clsx('_btn-remove-unit-price a-text--red', {
                                    'ml-3': index === shippingFeeDetails.length - 1
                                })}
                                type="minus-circle-o"
                                onClick={this.removeUnitPrice.bind(this, shippingFeeDetail.id)}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        ));

        const renderActionDividedConversion = openEnterDividedConversion ?
            <div className="d-flex"><Icon type="caret-down" onClick={() => this.setState({
                openEnterDividedConversion: false
            })} />{t('shipping_fee:divided_conversion')}</div>  :
            <div className="d-flex"><Icon type="caret-right" onClick={() => this.setState({
                openEnterDividedConversion: true
            })}/>{t('shipping_fee:divided_conversion')}</div>

        return (
            <Modal
                centered
                className="_edit-shipping-fee-modal"
                title={form.getFieldValue('id') ? t('shipping_fee:update.title') : t('shipping_fee:add.title')}
                visible={visible}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        {t('btn.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                        {t('btn.ok')}
                    </Button>
                ]}
                onCancel={this.handleCancel}
            >
                <Form>
                    <Form.Item
                        className="a-hidden"
                        label={t('id')}
                    >
                        {getFieldDecorator('id', {
                            defaultValue: get(shippingFee.shipping_fee, 'id')
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        help={get(formErrors, 'id_shipping_partner')}
                        label={t('shipping_partner')}
                        validateStatus={get(formErrors, 'id_shipping_partner') ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_shipping_partner', {
                            defaultValue: get(shippingFee.shipping_partner, 'id'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('shipping_partner')
                                })
                            }]
                        })(
                            <SelectCreatePartnerContainer
                                className="_id-shipping-partner"
                                disabled={!!form.getFieldValue('id')}
                                dropdownClassName="_dropdown-id-shipping-partner"
                                isFieldDecorator
                                onChange={this.handleOnChange}
                            />
                        )}
                    </Form.Item>
                    {unitPriceFormItems}
                    <Form.Item
                        help={get(formErrors, 'applied_at')}
                        label={t('application_time')}
                        validateStatus={get(formErrors, 'applied_at') ? 'error' : undefined}
                    >
                        {getFieldDecorator('applied_at', {
                            defaultValue: get(shippingFee.shipping_fee, 'applied_at'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('application_time')
                                })
                            }]
                        })(
                            <DatePicker
                                className="_applied-at width-100-pc"
                                format="HH:mm:ss DD/MM/YYYY"
                                locale={locale}
                                showTime
                                onChange={this.handleOnChange}
                            />
                        )}
                    </Form.Item>
                    <>
                        <Form.Item
                            className="divided_conversion"
                            label={renderActionDividedConversion}
                        >
                            {
                                openEnterDividedConversion &&
                                <div className="a-flex a-text--nowrap galign-center">
                                    {t('shipping_fee:length')} x {t('shipping_fee:width')} x {t('shipping_fee:height')}
                                    <span className="mx-2">/</span>
                                    {getFieldDecorator('divided_conversion', {
                                        defaultValue: get(shippingFee.shipping_fee, 'divided_conversion'),
                                        rules: [
                                            // {
                                            //     required: true,
                                            //     message: t('validation:required', {
                                            //         attribute: t('shipping_fee:divided_conversion')
                                            //     })
                                            // },
                                            {
                                                validator: async (rule, value, callback) => {
                                                    if (value && !Number.isInteger(Number(value))) {
                                                        callback(t('validation:integer', {
                                                            attribute: t('shipping_fee:divided_conversion')
                                                        }));
                                                    } else if (isNumber(value) && Number(value) <= 0) {
                                                        callback(t('validation:gt', {
                                                            attribute: t('shipping_fee:divided_conversion'),
                                                            value: 0
                                                        }));
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }]
                                    })(
                                        <InputNumber className="_divided-conversion" type="integer"/>
                                    )}
                                </div>
                            }
                        </Form.Item>
                    </>

                    <Form.Item
                        label={t('weight_by')}
                    >
                        {getFieldDecorator('weight_by', {
                            defaultValue: get(shippingFee.shipping_fee, 'weight_by'),
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('weight_by')
                                })
                            }]
                        })(
                            <Select
                                className="_weight-by"
                                dropdownClassName="_dropdown-weight-by"
                            >
                                {['NET', 'CONVERSION', 'MAX'].map(item => (
                                    <Option className="_weight-by-item" key={item} value={item}>
                                        {t(`weight_by_options.${item}`)}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('route')}
                    >
                        {getFieldDecorator('id_locations', {
                            defaultValue: get(shippingFee.shipping_fee, 'id_location') ? [get(shippingFee.shipping_fee, 'id_location')] : [],
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('route')
                                })
                            }]
                        })(
                            <RoutesSelectContainer
                                className="_id-locations"
                                disabled={!!form.getFieldValue('id')}
                                dropdownClassName="_dropdown-id-locations"
                                mode="multiple"
                                optionFilterProp="children"
                                routes={routes}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('service')}
                    >
                        {getFieldDecorator('id_services', {
                            defaultValue: get(shippingFee.service, 'id') ? [get(shippingFee.service, 'id')] : [],
                            rules: [{
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('service')
                                })
                            }]
                        })(
                            <ServicesSelectContainer
                                allowClear
                                className="_id-services"
                                consignServices={consignServices}
                                disabled={!!form.getFieldValue('id')}
                                dropdownClassName="_dropdown-id-services"
                                mode="multiple"
                                optionFilterProp="children"
                                services={services}
                                showSearch
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default withTranslation()(Form.create()(EditShippingFeeModal));
