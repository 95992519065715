import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from './actions';
import SearchableComponent from '../../Common/components/SearchableComponent';
import ListShippingUser from './components';
import * as commonAction from "../../Common/actions";
import * as selectors from "../selectors"

const mapStateToProps = (state) => ({
    // list: state.shippingUser.list.users
    list: selectors.getState(state, 'list.users')
});

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    fetchShippingUser: () => dispatch(actions.fetchShippingUser())
});

class ListShippingUserContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
        this.props.setMenuActive("config/shipping-users");
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0),
        });
    }

    onChangeFilter(filter) {
        this.props.fetchShippingUser(filter);
    }

    getCurrentFilter() {
        return {
            sort_by: 'full_name',
            sort_direction: 'asc',
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        return <ListShippingUser {...{
            ...this.props,
            filter: this.getCurrentFilter(),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

ListShippingUserContainer.defaultProps = {
    list: []
};

ListShippingUserContainer.propTypes = {
    list: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListShippingUserContainer)
