import React, { Component } from 'react';
import { Select } from 'antd';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getVar } from './../../../../system/support/helpers';

const { Option } = Select;

class SelectCustomer extends Component {
    componentDidMount() {
        const { value, getInitialCustomers, } = this.props;

        if (value) {
            getInitialCustomers(value);
        }
    }

    componentWillUnmount() {
        const { clearSuggests } = this.props;

        clearSuggests();
    }

    render() {
        const {
            allowClear,
            className,
            customers,
            disabled,
            dropdownClassName,
            emptyOption,
            error,
            initCustomersOfSelects,
            loading,
            loadingInitCustomersOfSelects,
            placeholder,
            selectProps,
            t,
            value,
            onChange,
            onSearch,
            onSelect
        } = this.props;

        const formattedLoading = loading || (loadingInitCustomersOfSelects && loadingInitCustomersOfSelects[value]);
        const formattedCustomers = customers && customers.length ? customers : (value ? (initCustomersOfSelects && initCustomersOfSelects[value]) : []);

        const formattedSelectProps = {
            allowClear,
            className: className,
            disabled,
            dropdownClassName: dropdownClassName,
            help: error,
            loading: formattedLoading,
            optionFilterProp: 'children',
            placeholder: placeholder ? placeholder : t('placeholder.select_customer'),
            showSearch: true,
            validateStatus: error ? 'error' : null,
            value: value && !formattedLoading ? parseInt(value, 0) : undefined,
            filterOption: (input, option) => {
                return (option.props.label ? option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) || (option.props.code ? option.props.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
            },
            onChange,
            onSearch,
            onSelect,
            ...selectProps
        };

        return (
            <Select {...formattedSelectProps} optionLabelProp="label">
                {emptyOption ? (
                    <Option
                        className="_customer__item"
                        value=""
                    >
                        {emptyOption === true ? t('label.all') : emptyOption}
                    </Option>
                ) : null}

                {map(formattedCustomers, customer => (
                    <Option
                        className="_customer__item"
                        code={`${getVar(customer, 'customer.code', '--')} ${getVar(customer, 'customer.system_code', '--')}`}
                        key={getVar(customer, 'customer.id')}
                        label={getVar(customer, 'customer.username', '--')}
                        value={getVar(customer, 'customer.id')}
                    >
                        <span className="a-text--black">{getVar(customer, 'customer.username', '--')}</span>
                        <br />
                        <span className="a-text--fz-12 a-text--gray-70">{getVar(customer, 'customer.code', '--')} ({getVar(customer, 'agency.code') || getVar(customer, 'customer.agency.code') || '--'})</span>
                    </Option>
                ))}
            </Select>
        );
    }
}

SelectCustomer.defaultProps = {
    loading: false,
    customers: [],
    error: null,
    emptyOption: true,
    className: '',
    selectProps: {},
    placeholder: '',
    onChange: () => {
    },
};

SelectCustomer.propTypes = {
    loading: PropTypes.bool,
    customers: PropTypes.array,
    value: PropTypes.any,
    error: PropTypes.string,
    emptyOption: PropTypes.any,
    className: PropTypes.string,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default withTranslation()(SelectCustomer);
