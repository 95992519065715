import { Button, Col, DatePicker, Divider, Form, Icon, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { t } from '../../../system/i18n'
import locale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'
import { get, isEmpty, omit } from 'lodash'
import SelectWarehouseContainer from '../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import SelectCreatePartnerContainer from '../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer'

const initialFilters = {
    'created_at_from': undefined,
    'created_at_to': undefined,
    package_code: undefined,
    code: undefined,
    id_shipping_partner: undefined,
}

const { Search } = Input
const { RangePicker } = DatePicker

const Filter = props => {
    const { form, filters, onSearch, loading } = props

    const [input, setInput] = useState(initialFilters)
    const package_code = get(input, 'package_code')
    const code = get(input, 'code')
    const id_shipping_partner = get(input, 'id_shipping_partner')
    const id_warehouse = get(input, 'id_warehouse')
    
    useEffect(() => {
        setInput(filters)
    }, [filters])

    function onSubmit() {
        onSearch({ ...input, page: undefined, per_page: undefined })
    }

    function onReset() {
        onSearch({
            package_code: undefined,
            code: undefined,
            id_shipping_partner: undefined,
            'created_at_from': undefined,
            'created_at_to': undefined,
            status: get(filters, 'status'),
        })
    }

    function onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        setInput({ ...input, [name]: value })
    }

    function onChangeSelect(name, value) {
        setInput({ ...input, [name]: value })
    }

    const onChangeTime = (field, value) => {
        setInput({ ...input, [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : '' })
    }

    // function onChangeDate(name, dates) {
    //     const name_from = `${name}[from]`
    //     const name_to = `${name}[to]`

    //     setInput({
    //         ...input,
    //         [name_from]: !isEmpty(dates) ? dates[0].format('YYYY-MM-DD') : undefined,
    //         [name_to]: !isEmpty(dates) ? dates[1].format('YYYY-MM-DD') : undefined,
    //     })
    // }

    return (
        <div className="a-content--search-header search-default">
            <div>
                <h2>{t('btn.search')}</h2>
                <Divider />
            </div>
            <Form
                className="a-form"
                form={form}>
                <Row
                    gutter={{ lg: 12, xl: 24, xxl: 24 }}
                    type="flex">
                    <Col
                        lg={4}
                        xl={5}
                        xxl={5}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('delivery_request:label.warehouse')}
                            labelCol={{ span: 24 }}>
                            <SelectWarehouseContainer
                                allowClear
                                className="a-select a-select--search-content _a-select-warehouse-input-package"
                                dropdownClassName="_dropdown_id_warehouse_input"
                                placeholder={t('package:label.warehouse_input')}
                                value={id_warehouse}
                                onChange={value => onChangeSelect('id_warehouse', value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={4}
                        xl={5}
                        xxl={5}>
                        <Form.Item
                            className="a-form__item search-content item--special"
                            label={t('delivery_request:label.code_search_package')}
                            labelCol={{ span: 24 }}>
                            <Search
                                name="package_code"
                                onChange={onChangeInput}
                                value={package_code}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        lg={4}
                        xl={5}
                        xxl={5}>
                        <Form.Item
                            className="a-form__item search-content item--special"
                            label={t('delivery_request:label.code_delivery_request')}
                            labelCol={{ span: 24 }}>
                            <Search
                                name="code"
                                onChange={onChangeInput}
                                value={code}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={12}
                        xl={9}
                        xxl={9}>
                        <Form.Item
                            className="a-form__item"
                            label={t('bag:label.created_at')}>
                            {/* <RangePicker
                                className="width-100-pc _created-at-from"
                                locale={locale}
                                placeholder={[t('label.time_start'), t('label.time_end')]}
                                format="HH:mm:ss DD/MM/YYYY"
                                showTime={{
                                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                }}
                                value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                onChange={date => onChangeDate('created_at', date)}
                            /> */}
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                        }}
                                        value={get(input, 'created_at_from') ? moment(input['created_at_from']) : null}
                                        onChange={value => onChangeTime('created_at_from', value)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                        }}
                                        value={get(input, 'created_at_to') ? moment(input['created_at_to']) : null}
                                        onChange={value => onChangeTime('created_at_to', value)}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col
                        lg={6}
                        xl={5}>
                        <Form.Item
                            className={'a-form__item'}
                            label={t('package:label.dvvc_global')}>
                            <SelectCreatePartnerContainer
                                allowClear
                                dropdownClassName="_dropdown_id_shipping_partner"
                                value={id_shipping_partner}
                                placeholder={t('bag:label.id_shipping_partner_placeholder')}
                                onChange={value => onChangeSelect('id_shipping_partner', get(value, 'value'))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="search--action gjustify-space-end">
                    <div>
                        <span
                            className="link-reload _btn-reset a-text--cursor-pointer"
                            onClick={onReset}>
                            <Icon type="sync" />
                            {t('label.refresh_filter')}
                        </span>
                        <Button
                            className="a-btn a-btn--primary a-btn--search-list _btn-search"
                            type="primary"
                            onClick={onSubmit}
                            loading={loading}>
                            {t('btn.search')}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default Form.create()(Filter)
