import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { setPackagePage } from './../CreatePackageViaScan/actions';
import { getErrors } from './../UpdateAlonePackage/selectors';
import { updatePackage } from './../UpdatePackageParams/actions';
import { getPackageState } from './../UpdatePackageParams/selectors';
import { createPackageSameCode } from './../../Package/CreatePackageSameCode/actions';
import { getLoadings } from './../../Package/CreatePackageSameCode/selectors';

import FormPackageDetail from './components/FormPackageDetail';

const mapStateToProps = (state, props) => {
    const createCode = get(state, 'package.createPackageSameCode', []);
    const packageState = getPackageState(state, props.package.id) || {};

    return {
        errors: {
            ...getErrors(packageState.error || {}),
            ...(props.errors ? props.errors : {})
        },
        loading: getLoadings(createCode)
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    setPackagePage: (page) => dispatch(setPackagePage(props.package.id, page)),
    createPackageCode: (data) => {
        dispatch(createPackageSameCode(data));
    },
    updatePackage: (id, data, cb) => dispatch(updatePackage(id, data, cb))
});

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormPackageDetail);

Container.defaultProps = {
    package: {},
};

Container.propTypes = {
    package: PropTypes.object,
};

export default Container;
