import { combineReducers } from 'redux';

import { GET_ROUTES } from './constants';

const loading = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case GET_ROUTES.REQUEST: {
            return true;
        }
        case GET_ROUTES.SUCCESS:
        case GET_ROUTES.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const routes = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case GET_ROUTES.SUCCESS: {
            return payload;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    loading,
    routes
});
