export const BAG_REQUEST_STATUS = {
    NEW: 'new',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
}

export const BAG_REQUEST_LIST_COLUMN = {
    BAGGING_REQUEST_CODE: 'bagging_request_code',
    MIDMILE_SHIPPING_PARTNER: 'midmile_shipping_partner',
    WAREHOUSE_INPUT: 'warehouse_input',
    CREATED_AT: 'created_at',
    FINISHED_AT: 'finished_at',
    STATUS: 'status',
    NUM_PACKAGE: 'num_package',
}

export const PACKAGE_IN_BAG_REQUEST_COLUMN = {
    PACKAGE_CODE: 'code',
    ORDER_CODE: 'order_code',
    AGENCY: 'agency',
    WAREHOUSE_AREA: 'warehouse_area',
    WEIGHT: 'weight_net',
    VOLUME: 'volume',
    PROPERTIES: 'properties',
    SERVICES: 'services',
    CUSTOMER_NAME: 'customer_username',
    TRANSPORT_STATUS: 'status_transport',
    BAG: 'bag_code',
    MIDMILE_SHIPPING_PARTNER: 'midmile_shipping_partner',
    NOTE: 'note',
}
