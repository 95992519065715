import { connect } from 'react-redux';
import { get } from 'lodash';

import * as actions from './actions';
import { getState } from './selectors';
import * as userSelectors from './../../User/selectors';

import BagPackingAction from './components/';

const mapStateToProps = (state, props) => ({
    failedPackedPackageCount: getState(state, 'failedPackedPackageCount'),
    loading: get(props, 'loading', false) || userSelectors.getState(state, 'loading', false),
});

const mapDispatchToProps = (dispatch) => ({
    packingPackage: (datas) => dispatch(actions.packingPackage(datas)),
});

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(BagPackingAction);

export default Container;
