import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button, Menu, Dropdown, Icon, Checkbox} from 'antd';

class ListDeliveryNoteSetting extends React.PureComponent {
    onChangeOption = (optionKey, event) => {
        const { values, onChange } = this.props;

        const formattedValues = event.target.checked ? [
            ...values,
            optionKey
        ] : values.filter(value => value !== optionKey);

        onChange(formattedValues);
    };

    render() {
        const { isOpen, onOpenSetting } = this.props;

        return (
            <Dropdown
                visible={isOpen}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field"
            >
                <Button
                    onClick={onOpenSetting.bind(undefined, true)}
                    type="primary"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost
                >Thiết lập cột thông tin</Button>
            </Dropdown>
        );
    }

    renderMenu = () => {
        const { options, t, values, onOpenSetting } = this.props;

        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={onOpenSetting.bind(undefined, false)} type="close" />
                </div>
                {options.map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={values.includes(option.key)}
                            disabled={option.disabled}
                            onChange={this.onChangeOption.bind(this, option.key)}
                        >{t(`delivery_note:column.${option.key}`)}</Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };
}

ListDeliveryNoteSetting.defaultProps = {
    options: [],
    values: [],
    onChange: () => {
    },
    isOpen: false
};

ListDeliveryNoteSetting.propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    onOpenSetting: PropTypes.func
};


export default withTranslation()(ListDeliveryNoteSetting);
