import lodash from 'lodash';

export const getLoadings = createCode => {
    return lodash.mapValues({
        createCode: false,
    }, (value, param) => createCode['loading']);
};


export const getRedirect = state => {
    return lodash.get(state, 'package.createPackageSameCode.redirect', false);
};

