import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import {createSimilarBag, createTemplateBag, togglePopupCreateTemplateBag} from './actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import {setMenuActive} from './../../Common/actions';
import BagDetail from './components/BagDetail';
import Bag from '../models/Bag';
import authService from '../../Auth/authService';
import PERMISSION from '../../Auth/permissions';
import lodash from 'lodash';
import {getSettingItemByCode} from './../../Setting/selectors';
import withAgencies from './../../../hocs/withAgencies';
import withBagAreas from './../../../hocs/withBagAreas';
import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups';

const requiredAgencyWhenCreateBagGenerator = getSettingItemByCode('BAG:REQUIRED_AGENCY_WHEN_CREATE_BAG', false);

const mapStateToProps = (state) => {
    let bag = selectors.getState(state, 'bag');
    bag = bag ? new Bag(bag) : null;
    return {
        bag: bag,
        packages: selectors.getPackageData(state),
        loading: selectors.getState(state, 'loading'),
        canDelete: authService.canAny([PERMISSION.BAG_PACKING, PERMISSION.BAG_PACKING_ALL]),
        settings: lodash.get(state, 'setting.setting', []),
        isShowPopupCreateTemplateBag: lodash.get(state, 'bag.bagDetail.isShowPopupCreateTemplateBag'),
        requiredAgencyWhenCreateBag: requiredAgencyWhenCreateBagGenerator(state),
        exporting: selectors.getState(state, 'exporting')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBag: (bagId) => dispatch(actions.fetchBagDetail(bagId)),
        detachPackage: (bagId, packageId) => dispatch(actions.detachPackage(bagId, packageId)),
        clearState: () => dispatch(actions.clearState()),
        setMenuActive: (menu) => dispatch(setMenuActive(menu)),
        createSimilarBag: (id) => dispatch(createSimilarBag(id)),
        togglePopupCreateTemplateBag: (id) => dispatch(togglePopupCreateTemplateBag(id)),
        createTemplateBag: (payload) => dispatch(createTemplateBag(payload)),
        exportBag: (payload) => dispatch(actions.exportBag(payload)),
        updateBag: (id, data, onCallback) => dispatch(actions.updateBag(id, data, onCallback))
    };
};

class BagDetailContainer extends SearchableComponent {
    componentDidMount() {
        const { getActiveBagAreaSuggests, getAgencies } = this.props;

        super.componentDidMount();

        getActiveBagAreaSuggests();
        getAgencies();
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onChangeFilter(filter) {
        this.props.fetchBag(filter.id);
    }

    render() {
        return <BagDetail {...this.props}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAgencies(withBagAreas(withConsignServiceGroups(BagDetailContainer))));
