import PropTypes from 'prop-types';
import { isNil, omit } from 'lodash';
import { connect } from 'react-redux';

import * as actions from './actions';
import { getState } from './selectors';

import ScanBarcode from './components/ScanBarcode';

const mapStateToProps = (state, props) => {
    return {
        deliveryNote: props.deliveryNote || {},
        canScan: !!props.canScan,
        scanning: getState(state, 'scanning')
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const { deliveryNote, params, scanBarcodeByCustomer } = props;

    let formattedParams = isNil(deliveryNote) ? {
        ...params
    } : {
        ...params,
        id_delivery_note: deliveryNote.id
    };

    if (!scanBarcodeByCustomer) {
        formattedParams = omit(formattedParams, 'id_customer');
    }

    formattedParams.multiple = props.multiple;

    return {
        scanBarcode: (code, stage) => dispatch(actions.scanBarcode(code, formattedParams, stage)),
    };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanBarcode);

Container.defaultProps = {
    canScan: true,
    object: null,
    params: {},
};

Container.propTypes = {
    canScan: PropTypes.bool,
    object: PropTypes.string,
    params: PropTypes.object,
};

export default Container;
