import { api } from './../../system/api';

export default {
    creteAuthOnlyToken: () => api.post('/auth/auth-only-token'),
    checkAuthOnlyToken: token => api.get('/me', {
        params: {
            auth_only: token
        }
    })
}
