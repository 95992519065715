import React, { Component } from 'react';
import { notification } from 'antd';

import apiService from './../modules/Package/apiService';
import { t } from './../system/i18n';

const withPackageDamagedReasons = (WrappedComponent, config = {}) => {
    return class extends Component {
        state = {
            packageDamagedReasons: [],
            loadingPackageDamagedReasons: false
        };

        getPackageDamageReasons = async () => {
            this.setState({
                loadingPackageDamagedReasons: true
            });

            try {
                const response = await apiService.getDamagedReasons();
    
                this.setState({
                    packageDamagedReasons: response.data.package_status_reasons
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }
    
            this.setState({
                loadingPackageDamagedReasons: false
            });
        };

        componentDidMount() {
            if (config && config.getDataWhenMounted) {
                this.getPackageDamageReasons();
            }
        }

        render() {
            const { packageDamagedReasons, loadingPackageDamagedReasons } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    packageDamagedReasons={packageDamagedReasons}
                    loadingPackageDamagedReasons={loadingPackageDamagedReasons}
                    getPackageDamageReasons={this.getPackageDamageReasons}
                />
            );
        }
    };
};

export default withPackageDamagedReasons;
