import React from 'react';
import {Button, Col, Form, Icon, Layout, Row, Switch} from "antd";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {DEFAULT_STATE_FORM_INPUT_PROPERTY} from "../../constants";
import FormInputProperty from "../../FormInputProperty/FormInputPropertyContainer"
import {router} from "../../../../system/routing";
import * as lodash from "lodash";

const Content = Layout.Content;

class CreateWarehouse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {...DEFAULT_STATE_FORM_INPUT_PROPERTY, active: true}
	}

	componentWillUnmount() {
		this.props.clearState();
	}

	componentDidMount() {
		this.props.setMenuActive('config/property')
	}

	onCreateProperty = () => {
		let data = lodash.pickBy({...this.state}, lodash.identity);
		data.active = this.state.active ? 1 : 0;
		this.props.createProperty(data)
	};
	onSubmit = (value) => {
		this.setState({
			...this.state, ...value
		})
	};
	onChecker = (e) => {
		this.setState({active: e})
	};
	onBackConfigListProperty = () => {
		router.redirect('/config/property')
	};

	render() {
		const {t, loading} = this.props;
		return (
			<Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
				<div className="a-breadcrumb">
                    <a href="#top" className={"a-text--gray _btn-back"} onClick={this.onBackConfigListProperty}><Icon
						type="arrow-left" className={"mr-2 "}/>{t('config:btn.back_config_list_property')}</a>
				</div>
				<div className="a-block-config amt-16">
					<div className="a-block-config__title gflex gjustify-space-between">
						<h3 className="title">{t('config:label.information_property')}</h3>
						<div>
							<label className={"a-text--fz-12 a-text--gray mr-2"}>{t('config:label.status')}</label>
                            <Switch defaultChecked size="small" className={"_switch-active"} onChange={this.onChecker}
                                    disabled={loading}
                                    loading={loading}
                                    checker={this.state.active}/>
						</div>
					</div>
					<div className="a-block-config__body">
						<Form className="a-form ">
							<FormInputProperty onSubmit={this.onSubmit}
							                   errors={this.props.errors}/>
							<Row gutter={48}>
								<Col lg={{span: 24}} xl={{span: 24}}
								     className="a-package--action only-action">
                                    <a className="a-btn a-btn--back-package _btn-cancel"
                                       href={'#top'}
                                       onClick={this.onBackConfigListProperty}>{t('btn.cancel')} </a>
									<Button type="primary" onClick={this.onCreateProperty}
                                            disabled={loading}
                                            loading={loading}
                                            className="a-btn a-btn--primary a-btn--save-package primary-special _btn-create-new">{t('btn.create_new')} </Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</Content>
		)
	}
}

CreateWarehouse.defaultProps = {
	loading: false
};

CreateWarehouse.propTypes = {
	loading: PropTypes.bool,
};
export default withTranslation()(CreateWarehouse);
