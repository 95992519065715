import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getState } from './../selectors';

import FormMultiConsignServices from './components/FormMultiConsignServices';

const mapStateToProps = state => ({
    loading: getState(state, 'loadingConsignServices'),
    consignServices: getState(state, 'consignServices')
});

const FormMultiConsignServicesContainer = props => (
    <FormMultiConsignServices {...props} />
);

FormMultiConsignServicesContainer.defaultProps = {
    values: [],
    onChange: () => {},
};

FormMultiConsignServicesContainer.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default connect(
    mapStateToProps
)(FormMultiConsignServicesContainer);
