import React from 'react';
import clsx from 'clsx';
import { map } from 'lodash';
import { createSelector } from 'reselect';

import { TABLE_COLUMNS } from './constants';
import { dateFormatter } from './../Common/services/format';
import { formatCurrency } from './../Common/services/helps';
import { t } from './../../system/i18n';
import { getVar } from './../../system/support/helpers';

export const getState = (state, param = null, defaultValue = null) => getVar(state, `shippingFee${(param ? `.${param}` : '')}`, defaultValue);

export const getTableDisplayShippingFees = createSelector(
    state => getState(state, 'shippingFees', []),
    state => getState(state, 'routes.routes', []),
    (shippingFees, routes) => map(shippingFees, shippingFee => {
        const route = routes.find(route => route.id === getVar(shippingFee.shipping_fee, 'id_location'));

        return {
            id : getVar(shippingFee, 'shipping_fee.id'),
            [TABLE_COLUMNS.SHIPPING_PARTNER]: getVar(shippingFee, 'shipping_partner.name', '--'),
            [TABLE_COLUMNS.CREATOR]: getVar(shippingFee, 'creator.username', '--'),
            [TABLE_COLUMNS.ROUTE]: getVar(route, 'label', '--'),
            [TABLE_COLUMNS.SERVICE]: (
                <span className={clsx({
                    '_consign-service a-text--green': getVar(shippingFee, 'service.type') === 'consign',
                    '_normal-service': getVar(shippingFee, 'service.type') === 'normal'
                })}>
                    {getVar(shippingFee, 'service.name', '--')}
                </span>
            ),
            [TABLE_COLUMNS.APPLIED_AT]: getVar(shippingFee, 'shipping_fee.applied_at') ? dateFormatter.full(getVar(shippingFee, 'shipping_fee.applied_at')) : '--',
            [TABLE_COLUMNS.UNIT_PRICE]: getVar(shippingFee, 'shipping_fee.unit_price') ? formatCurrency(getVar(shippingFee, 'shipping_fee.unit_price')) : '--',
            [TABLE_COLUMNS.DIVIDED_CONVERSION]: getVar(shippingFee, 'shipping_fee.divided_conversion', '--'),
            [TABLE_COLUMNS.WEIGHT_BY]: getVar(shippingFee, 'weight_by') ? t(`weight_by_options.${getVar(shippingFee, 'shipping_fee.weight_by')}`) : '--'
        };
    })
);
