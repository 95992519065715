/* eslint-disable */
import React from 'react';
import {Icon} from "antd";

class LogSystem extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onClickIconCollapse = (iconCollapse) => {
       this.props.onClickIconCollapse(iconCollapse)
    }

    render() {
        const {iconCollapse} = this.props;
        return (
            <div className={`block log-system ${iconCollapse ? 'collapse' : ''}`}>
                <div className="title">
                            <span>Log hành động của bao
                                <Icon type="arrows-alt" onClick={e => this.onClickIconCollapse(true)}
                                      className={`icon-collapse ${iconCollapse ? 'a-hidden' : ''}`}/>
                                <Icon type="shrink" onClick={e => this.onClickIconCollapse(false)}
                                      className={`icon-collapse ${!iconCollapse ? 'a-hidden' : ''}`}/></span>
                </div>
                <ul className="list-log">
                    <li>
                        <span className="time">15:54 - 29/02/2019</span>
                        <span
                            className="detail"><strong>Nguyễn Ngọc Định</strong> đã thêm <strong>1238124</strong> kiện vào bao <strong>H12313-12837</strong></span>
                    </li>
                    <li>
                        <span className="time">15:54 - 29/02/2019</span>
                        <span
                            className="detail"><strong>Nguyễn Ngọc Định</strong> đã thêm <strong>1238124</strong> kiện vào bao <strong>H12313-12837</strong></span>
                    </li>
                </ul>
            </div>
        )
    }
}

export default LogSystem;