import React, { PureComponent } from 'react';
import { Button, Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ChangePackageTransportStatus extends PureComponent {
    state = {
        isOpenChangePackageTransportStatusConfirm: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            ...prevState
        };

        if (prevState.isOpenChangePackageTransportStatusConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenChangePackageTransportStatusConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmChangePackageTransportStatus = () => {
        const { t, beginChangePackageTransportStatus } = this.props;

        Modal.confirm({
            className: '_confirm-change-package-transport-status-modal',
            cancelText: t('btn.cancel'),
            content: t('package:message.warning_confirm_change_package_transport_status'),
            icon: (
                <Icon type="question-circle" theme="filled" />
            ),
            okText: t('btn.ok'),
            title: t('package:label.warning'),
            onClose: this.handleOnClose,
            onOk: this.handleOnOk
        });

        beginChangePackageTransportStatus();
    };

    /**
     * handle action click ok to unlink order
     */
    handleOnOk = () => {
        const { packageData, status_transport, onChangePackageTransportStatus } = this.props;

        this.setState({
            isOpenChangePackageTransportStatusConfirm: true
        });

        const data = {
            code: packageData.code,
            status_transport,
        };

        onChangePackageTransportStatus(data);
    };

    /**
     * handle action close modal
     */
    handleOnClose = () => {
        this.props.endChangePackageTransportStatus();
    };

    render() {
        const { loading, t } = this.props;

        return (
            <span>
                <Button
                    className="a-btn--button-link a-text--blue _btn_change_package_transport_status"
                    loading={loading}
                    onClick={this.confirmChangePackageTransportStatus}
                >
                    {t('package:label.change_package_transport_status')}
                </Button>
            </span>
        );
    }
}

ChangePackageTransportStatus.defaultProps = {
    packageData: {},
    isModalProcessing: false,
    loading: false
};

ChangePackageTransportStatus.propTypes = {
    packageData: PropTypes.object,
    onChangePackageTransportStatus: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginChangePackageTransportStatus: PropTypes.func,
    endChangePackageTransportStatus: PropTypes.func
};

export default withTranslation()(ChangePackageTransportStatus);
