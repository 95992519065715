import {combineReducers} from 'redux';
import * as UNLINK_ACTION from './constants';

let isProcessing = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case UNLINK_ACTION.BEGIN_UNLINK_ORDER:
            return true;
        case UNLINK_ACTION.UNLINK_ORDER.SUCCESS:
        case UNLINK_ACTION.UNLINK_ORDER.FAILED:
        case UNLINK_ACTION.END_UNLINK_ORDER:
            return false;
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case UNLINK_ACTION.UNLINK_ORDER.REQUEST:
            return true;
        case UNLINK_ACTION.UNLINK_ORDER.SUCCESS:
        case UNLINK_ACTION.UNLINK_ORDER.FAILED:
            return false;
        default:
            return state;
    }
};


let isUnlinkOrderSuccess = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case UNLINK_ACTION.UNLINK_ORDER.REQUEST:
        case UNLINK_ACTION.UNLINK_ORDER.FAILED:
            return false;
        case UNLINK_ACTION.UNLINK_ORDER.SUCCESS:
            return true;
        default:
            return state;
    }
};


export default combineReducers({
    isProcessing,
    loading,
    isUnlinkOrderSuccess
});
