export const UNAUTHENTICATED = 401;
export const UNAUTHORIZED = 403;

export const GET_USER = {
    REQUEST: 'AUTH.GET_USER.REQUEST',
    SUCCESS: 'AUTH.GET_USER.SUCCESS',
    FAILED: 'AUTH.GET_USER.FAILED',
};
export const GET_PARTNER = {
    REQUEST: 'AUTH.GET_PARTNER.REQUEST',
    SUCCESS: 'AUTH.GET_PARTNER.SUCCESS',
    FAILED: 'AUTH.GET_PARTNER.FAILED',
};
export const CODE_PARTNER = {
    IDENTIFY_NAME: 'META:IDENTIFY_NAME',
    ORGANIZATION_NAME: 'META:ORGANIZATION_NAME',
    ORGANIZATION_ADDRESS: 'META:ORGANIZATION_ADDRESS',
    ORGANIZATION_PHONE: 'META:ORGANIZATION_PHONE',
    LOGO: 'META:LOGO',
    AVATAR: 'META:AVATAR'
};

export const PARTNER_BAG_CONFIG = {
    'BAG_SIZE_REQUIRED_WHEN_CREATING': 'BAG:SIZE_REQUIRED_WHEN_CREATING'
};

export const SET_USER = 'AUTH.SET_USER';

export const AUTH_SUCCESS = 'AUTH.AUTH_SUCCESS';

export const LOGOUT = 'AUTH.LOGOUT';
