import lodash from 'lodash';
import clsx from 'clsx';
import {createSelector} from "reselect";
import {BAG_COLUMN} from "./constants";
import {Link} from './../../../system/routing';
import {dateFormatter} from '../../Common/services/format';
import {t} from './../../../system/i18n';
import React from 'react';

import { getVar } from './../../../system/support/helpers';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'bag.listBag' + (param ? '.' + param : ''), defaultValue);

export const getListBag = createSelector(
    (state) => getState(state, "bags", []),
    (state, page) => page,
    (bags, page) => bags.map((bagData) => {
        let shipper;

        if (!lodash.isNil(bagData.shipping_partner)) {
            shipper = `${getVar(bagData, 'shipping_partner.name', '--')} (${getVar(bagData, 'shipping_partner.code', '--')})`;
        }

        if (!lodash.isNil(bagData.shipping_user)) {
            shipper = `${getVar(bagData, 'shipping_user.username', '--')} (${getVar(bagData, 'shipping_user.code', '--')})`;
        }

        const customer_info = bagData.customer ? bagData.customer.username + ' (' + (bagData.customer.code ? bagData.customer.code : '--') + ')' : '';
        const agencies = lodash.get(bagData, 'agency', '');

        return {
            key: bagData.id,
            id_warehouse_scan: bagData.id_warehouse_current,
            packing_complete: bagData.packing_complete,
            [BAG_COLUMN.CODE]: <Link isNewTab
                                     to={page === "bags.list_bag_pack" ? "bags.packing" : "bags.detail"}
                                     params={{id: page === "bags.list_bag_pack" ? bagData.id : bagData.code}}>{bagData.code}</Link>,
            [BAG_COLUMN.RECEIVER]: lodash.get(bagData, 'receiver_name', '--') + '/' + lodash.get(bagData, 'receiver_phone', '--'),
            [BAG_COLUMN.BAG_TYPE]: (bagData.type !== "") ? (bagData.type === "SINGLE" ? "Bao nguyên" : "Bao ghép") : "--",
            [BAG_COLUMN.CREATE_TIME]: dateFormatter.dateTime(bagData.created_at),
            [BAG_COLUMN.ID_WAREHOUSE_INPUT]: getVar(bagData, 'warehouse_input.code', '--'),
            [BAG_COLUMN.ID_WAREHOUSE_DESTINATION]: getVar(bagData, 'destination_warehouse.code', '--'),
            [BAG_COLUMN.ID_WAREHOUSE_CURRENT]: getVar(bagData, 'current_warehouse.code', '--'),
            [BAG_COLUMN.STATUS_WAREHOUSE]: bagData.status_warehouse ? t('package:action.' + bagData.status_warehouse) : "--",
            [BAG_COLUMN.TRANSPORT_STATUS]: bagData.status ? t(`bag:statuses.${bagData.status}`) :'--',
            [BAG_COLUMN.SHIP_NAME]: shipper,
            [BAG_COLUMN.CUSTOMER]: customer_info,
            [BAG_COLUMN.AGENCIES]: agencies,
            [BAG_COLUMN.SCAN_TIME]: dateFormatter.dateTime(bagData.time_scan),
            [BAG_COLUMN.PROPERTIES]: bagData.properties.length ? bagData.properties.map(e => e.name).join(', ') : '--',
            [BAG_COLUMN.SERVICES]: bagData.services.length ? bagData.services.map((service, index) => (
                <>
                    <span className={clsx({
                        'a-text--green': service.type === 'consign'
                    })}>
                        {service.name}
                    </span>
                    {index !== bagData.services.length -1 && ', '}
                </>
            )) : '--',
            [BAG_COLUMN.TOTAL_PACKAGES]: lodash.get(bagData, 'num_package', '--'),
            [BAG_COLUMN.TOTAL_PACKAGES_WEIGHT]: lodash.get(bagData, 'weight_package', '0') + " (kg)",
            [BAG_COLUMN.BAG_WEIGHT]: lodash.get(bagData, 'weight_real', '0') + " (kg)",
            [BAG_COLUMN.DIMENSION]: lodash.get(bagData.bag_size, 'name', '--'),
            [BAG_COLUMN.UNCHECKED]: lodash.get(bagData, 'is_unchecked') ? t('bag:label.unchecked') : '--'
        }
    })
);
