import ApiAuth from './ApiAuth';
import ApiUrl from './ApiUrl';
import ApiFactory from './ApiFactory';
import config from '../../config/app';

let auth = new ApiAuth();
let url = new ApiUrl(config.apiUrl, auth);
let api = (new ApiFactory(config.apiUrl, auth)).make();

export {
    auth,
    url,
    api,
};