import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchLocation = createAction(ACTION.FETCH_LIST_LOCATIONS.REQUEST, (id, type) => ({id: id, type: type}));
export const fetchCountries = createAction(ACTION.FETCH_LIST_COUNTRY.REQUEST, (id, type, autoloadChildren) => ({
    id,
    type,
    autoloadChildren
}));
export const fetchProvinces = createAction(ACTION.FETCH_LIST_PROVINCE.REQUEST, (id, type) => ({id: id, type: type}));
export const fetchDistricts = createAction(ACTION.FETCH_LIST_DISTRICT.REQUEST, (id, type) => ({id: id, type: type}));
export const fetchWards = createAction(ACTION.FETCH_LIST_WARD.REQUEST, (id, type) => ({id: id, type: type}));
export const changeCurrentCountry = createAction(ACTION.CHANGE_CURRENT_COUNTRY, (id) => id);
