import {processApiRequest} from "../../Common/saga";
import * as ACTION from "../constants";
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {fetchDetailWarehouse} from "../DetailWarehouse/actions";
import {dispatch} from "../../../system/store";

export function* removeStaffInWarehouse(action) {
	yield processApiRequest(ACTION.REMOVE_STAFF_IN_WAREHOUSE, () => apiService.deleteStaffInWarehouse(action.payload), action.payload);
}

export function* removeStaffInWarehouseSuccess(action) {
	yield notification.success({message: t('config:message.remove_staff_in_warehouse_success')});
	yield dispatch(fetchDetailWarehouse(action.request.id_warehouse))
}

export function* removeStaffInWarehouseFailed(action) {
	if (action.payload) {
		yield notification.error({message: t('config:message.remove_staff_in_warehouse_failed')});
	}
}
