import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as actions from './actions';
import * as checkingInventorySelectors from './selectors';
import * as inventoryBarcodeSelectors from './../InventoryBarcode/selectors';
import * as inventoryScanSelectors from './../InventoryScan/selectors';
import * as warehouseSelectors from './../../Warehouse/selectors';

import CheckingInventory from './components';

const mapStateToProps = (state) => {
	return {
		comparing: checkingInventorySelectors.getState(state, 'comparing') || checkingInventorySelectors.getState(state, 'comparingTrackingBill'),
		loading: (
			inventoryScanSelectors.getState(state, 'loading') ||
			warehouseSelectors.getState(state, 'loading') ||
			inventoryBarcodeSelectors.getState(state, 'loading')
		),
		inventory: inventoryScanSelectors.getState(state, 'detail.inventory')
	};
};

const mapDispatchToProps = {
	checkingInventory: actions.checkingInventory,
	comparingTrackingBill: actions.comparingTrackingBill
};

export default withTranslation()(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckingInventory));
