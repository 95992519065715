import lodash from 'lodash';
import {createSelector} from "reselect";

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'warehouse' + (param ? '.' + param : ''), defaultValue);
export const getListWarehouseActive = createSelector(
    state => getState(state, 'suggest', []),
    (warehouses) => warehouses.filter(warehouse => warehouse.active)
);
export const getListWarehousePermissionActive = createSelector(
    state => getState(state, 'listSuggestPermission', []),
    (warehouses) => warehouses.filter(warehouse => warehouse.active)
);
