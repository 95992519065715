import {connect} from 'react-redux';
import DeletePackage from './components';
import * as actions from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    page: props.page,
    packageData: props.packageData,
    isModalProcessing: lodash.get(state, 'bag.deletePackage.isProcessing', false),
    loading: lodash.get(state, 'bag.deletePackage.loading', false),
    type: props.type
});

const mapDispatchToProps = (dispatch) => ({
    beginDeletePackage: () => {
        dispatch(actions.beginDeletePackage());
    },
    endDeletePackage: () => {
        dispatch(actions.endDeletePackage());
    },
    onDeletePackage: (bagId, packingId) => {
        dispatch(actions.deletePackage(bagId, packingId));
    },
    onRemovePackage: (bagId, packageId) => {
        dispatch(actions.removePackage(bagId, packageId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeletePackage);
