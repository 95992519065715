import {connect} from 'react-redux';
import AddStaffInWarehouse from "./components"
import {setMenuActive} from "../../Common/actions";
import * as detailWarehouseSelectors from "../DetailWarehouse/selectors";
import * as selectors from "./selectors";
import {clearState} from "../../User/actions"
import {removeStaffInWarehouse} from "./actions"
import lodash from "lodash";

const mapStateToProps = (state, props) => ({
	warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
	loading: detailWarehouseSelectors.getState(state, 'loading', false) ||
		selectors.getState(state, 'loading', false) ||
		lodash.get(state, "location.loading", false),
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	clearState: () => dispatch(clearState()),
	removeStaffInWarehouse: (params) => dispatch(removeStaffInWarehouse(params)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddStaffInWarehouse);
