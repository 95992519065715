import {takeLatest} from 'redux-saga/effects';
import * as saga from './saga';
import * as sagaDetail from '../BagPacking/saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.PACKING_PACKAGE.REQUEST, saga.packingPackage);
    yield takeLatest(ACTION.PACKING_PACKAGE.FAILED, saga.packingPackageFailed);
    // yield takeLatest(ACTION.PACKING_PACKAGE.FAILED, sagaDetail.getBagDetailAfterPacking);
    yield takeLatest(ACTION.PACKING_PACKAGE.SUCCESS, saga.packingPackageSuccess);
    // yield takeLatest(ACTION.PACKING_PACKAGE.FAILED, sagaDetail.getBagDetailAfterPacking);
    yield takeLatest(ACTION.PACKING_PACKAGE.SUCCESS, sagaDetail.getBagDetailAfterPacking);
}
