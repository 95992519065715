import { connect } from 'react-redux';

import * as selectors from './../selectors';
import * as actions from './../actions';

import ListOrder from './components';

const mapStateToProps = state => {
    return {
        suggestPackage: selectors.getState(state, 'listPackage.detailPackage'),
        list: selectors.getState(state, 'list'),
        pagination: selectors.getState(state, 'pagination', {}),
        loading: selectors.getState(state, 'loading', false),
        loadingSuggestPackage: selectors.getState(state, 'listPackage.loading')
    };
};

const mapDispatchToProps = (dispatch) => ({
    getNotes: () => dispatch(actions.getNotes()),
    getSuggestPackage: code => dispatch(actions.fetchPackageDetail(code))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOrder);
