import {connect} from 'react-redux';
import UpdateWeightRealBag from './components';
import {resetError, updateWeightReal} from './actions';
import lodash from 'lodash';
import * as selector from './selectors';

const mapStateToProps = (state, props) => ({
    bag: props.bag || {},
	loading: props.loading || lodash.get(state, "bag.updateWeightReal.loading", false),
    errors: selector.getErrors(lodash.get(state, "bag.updateWeightReal.errors", {})),
});

const mapDispatchToProps = (dispatch, props) => ({
    onUpdateWeightReal: (param) => {
        dispatch(updateWeightReal(param))
    },
    resetError: () => dispatch(resetError()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateWeightRealBag);
