import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Modal} from 'antd';

class DetachPackage extends React.PureComponent {
    confirm(event) {
        event.preventDefault();

        let {t} = this.props;

        Modal.confirm({
            title: t('bag:message.warning_confirm_delete'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => {
                this.props.onDetach();
            },
        });
    }

    render() {
        return <a
            href="/"
            className="a-text--nowrap a-action-hover-show"
            onClick={this.confirm.bind(this)}
        >{this.props.t("bag:label.delete")}</a>;
    }
}

DetachPackage.defaultProps = {
    package: {},
    onDetach: () => {}
};

DetachPackage.propTypes = {
    package: PropTypes.object,
    onDetach: PropTypes.func,
};

export default withTranslation()(DetachPackage);
