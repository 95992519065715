import {combineReducers} from 'redux';
import * as ACTION from './constants';

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.CREATE_PACKAGE_SAME_CODE.REQUEST:
            return true;

        case ACTION.CREATE_PACKAGE_SAME_CODE.SUCCESS:
            return false;
        case ACTION.CREATE_PACKAGE_SAME_CODE.FAILED:
            return false;
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};


export default combineReducers({
    loading,
});
