import {connect} from 'react-redux';
import {setMenuActive} from "../../Common/actions";
import CreateProperty from "./components";
import {clearState, createProperty} from "../actions"
import * as selectors from "../selectors";

const mapStateToProps = (state) => ({
	loading: selectors.getState(state, 'loading', false)
});
const mapDispatchToProps = (dispatch) => ({
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	createProperty: (params) => dispatch(createProperty(params)),
	clearState: () => dispatch(clearState())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateProperty);
