import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useImmer } from 'use-immer';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE } from './../../../constants';
import * as selectors from './../../../../Warehouse/selectors';

const { TextArea } = Input;

const ShippingPartnerFormInput = ({ deliveryNote, errors, loading, shippingPartnerDetail, t, warehouseId, warehouses, onUpdate, resetError }) => {
    const [form, updateForm] = useImmer({
        ...DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE,
        id: undefined
    });
    const [displayCarNumberInput, setDisplayCarNumberInput] = useState(false);

    const onChangeInput = (event, valueInput = 0) => {
        const value = event.target ? event.target.value : valueInput;
        const name = event.target ? event.target.name : event;

        updateForm(form => {
            form[name] = value;
        });

        onUpdate({
            ...form,
            [name]: value
        });

        if (errors[name]) {
            resetError(name);
        }
    };

    useEffect(() => {
        updateForm(form => {
            const idShippingPartner = get(shippingPartnerDetail, 'id');

            if (form.id_shipping_partner !== idShippingPartner) {
                form.id_shipping_partner = idShippingPartner;
            }
        });
        
    }, [shippingPartnerDetail, updateForm]);

    useEffect(() => {
        updateForm(form => {
            const id = get(deliveryNote, 'id');

            if (form.id !== id) {
                form = !id ? { ...DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE } : {
                    id,
                    note: get(deliveryNote, 'note'),
                    partner_tracking_number: get(deliveryNote, 'partner_tracking_number'),
                    car_number: get(deliveryNote, 'car_number')
                };

                return form;
            }
        });
    }, [deliveryNote, updateForm]);

    useEffect(() => {
        const warehouse = warehouses.find(warehouse => warehouse.id === warehouseId);

        if (warehouse && warehouse.is_custom_warehouse) {
            setDisplayCarNumberInput(true);
        } else {
            setDisplayCarNumberInput(false);
        }
    }, [warehouses, warehouseId])

    return (
        <>
            <Row gutter={{lg: 24}} className="a-form mr-0 ml-0">
                <Col lg={{span: 8}} className="a-form--group">
                    <Form.Item
                        className="a-form__item"
                        help={errors.partner_tracking_number}
                        label={t('delivery_note:label.tracking_number')}
                        labelCol={{span: 24}}
                        validateStatus={errors.partner_tracking_number ? 'error' : null}
                    >
                        <Input
                            className="a-input _tracking-number"
                            disabled={loading}
                            name="partner_tracking_number"
                            placeholder={t('delivery_note:placeholder.tracking_number')}
                            value={form.partner_tracking_number}
                            onChange={onChangeInput}
                        />
                    </Form.Item>
                </Col>
                {displayCarNumberInput && (
                    <Col lg={{span: 8}} className="a-form--group">
                        <Form.Item
                            className="a-form__item"
                            help={errors.car_number}
                            label={t('vehicle_number')}
                            labelCol={{span: 24}}
                            validateStatus={errors.car_number ? 'error' : null}
                        >
                            <Input
                                className="a-input _car-number"
                                disabled={loading}
                                name="car_number"
                                placeholder={t('vehicle_number')}
                                value={form.car_number}
                                onChange={onChangeInput}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={{lg: 24}} className="a-form mr-0 ml-0">
                <Col lg={{span: 24}} className="a-form--group mb-0">
                    <Form.Item
                        className="a-form__item"
                        validateStatus={errors.note ? 'error' : null}
                        help={errors.note}
                        label={t('common:label.note')}
                        labelCol={{span: 24}}
                    >
                        <TextArea
                            autosize={{
                                minRows: 1,
                                maxRows: 3
                            }}
                            className="a-input--textarea _note"
                            name="note"
                            placeholder={t('placeholder.note')}
                            spellCheck={false}
                            value={form.note}
                            onChange={onChangeInput}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

ShippingPartnerFormInput.defaultProps = {
    errors: {},
    loading: false,
    customer: {}
};

ShippingPartnerFormInput.propTypes = {
    loading: PropTypes.bool,
    resetError: PropTypes.func,
    onUpdate: PropTypes.func,
    errors: PropTypes.object,
};

const mapStateToProps = state => ({
    warehouses: selectors.getListWarehouseActive(state)
});

export default withTranslation()(connect(
    mapStateToProps
)(ShippingPartnerFormInput));
