import React, { useState } from 'react';
import { Button, Empty, Icon, Table } from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { useComplaintSellersDisplay } from './../../hooks';
import { PACKAGE_COLUMN } from './../../ListPackage/constants';
import authService from './../../../Auth/authService';
import PERMISSION from './../../../Auth/permissions';
import { dateFormatter } from './../../../Common/services/format';
import { useDeepCompareEffect } from './../../../../hooks';
import { Link } from './../../../../system/routing';
import { getVar } from './../../../../system/support/helpers';

import CreateComplaintSellerButton from './../../components/CreateComplaintSellerButton';
import ListPackageSetting from './../../ListPackage/ListPackageSettingContainer';
import CreateComplaintSellerModal from './../../../Common/components/Modals/CreateComplaintSeller';
import Pagination from './../../../Common/components/Pagination';

const ComplaintSellers = props => {
    const elements = useComplaintSellersDisplay(props);

    return elements;
};

const ShipmentPackageList = ({
    downloadingTransportStatus,
    exportPackageList,
    filter,
    isCurrentPage,
    isOpenListSetting,
    loading,
    object,
    packages,
    pagination,
    t,
    onDownloadTransportStatuses,
    onOpenListSetting,
    onSearch
}) => {
    const [activeColumns, setActiveColumns] = useState([]);
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [modalVisibles, setModalVisibles] = useState({
        createComplaintSeller: false
    });
    const [agencyId, setAgencyId] = useState();

    const canExportPackageList = authService.can(PERMISSION.EXPORT_PACKAGES_LIST);
    const canDownloadTransportStatuses = authService.can(PERMISSION.PACKAGE_CHANGE_TRANSPORT_STATUS);

    const getClassNameIdentifier = column => {
        switch (column) {
            case PACKAGE_COLUMN.CODE: {
                return 'a-text--nowrap _package_column_code';
            }
            case PACKAGE_COLUMN.ORDER: {
                return 'a-text--nowrap _package_column_order';
            }
            default: {
                return `_row${column}`;
            }
        }
    };


    const getColumns = () => {
        const arr = [PACKAGE_COLUMN.CREATE_TIME, PACKAGE_COLUMN.WEIGHT, PACKAGE_COLUMN.VOLUME];
        const formattedActiveColumns = activeColumns;

        formattedActiveColumns.push(PACKAGE_COLUMN.FOLLOW);

        return lodash.values(PACKAGE_COLUMN)
            .filter(column => formattedActiveColumns.includes(column))
            .map(column => {
                let res = {
                    key: column,
                    dataIndex: column,
                    title: column === PACKAGE_COLUMN.FOLLOW ? '' : t(`package:column.${column}`),
                    className: getClassNameIdentifier(column)
                };
                if (arr.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === filter.sort_by
                            ? (filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : null,
                    }
                }


                return res;
            });
    };

    const getDataSource = () => {
        return packages.map(packageData => {
            const complaintSellers = (
                <ComplaintSellers
                    className="mb-0"
                    complaintSellers={lodash.get(packageData, 'package_detail.id_m5_complaint_sellers')}
                    displayLabel={false}
                    packageId={lodash.get(packageData, 'id')}
                />
            );

            return ({
                key: packageData.id,
                origin: packageData,
                [PACKAGE_COLUMN.CODE]: (
                    <span className="a-text--nowrap _code">
                        <Link
                            isNewTab
                            params={{id: packageData.code}}
                            to="packages.detail"
                        >
                            {packageData.code}
                        </Link>
                    </span>
                ),
                [PACKAGE_COLUMN.REPORTER]: (
                    <span className="a-text--nowrap _reporter">
                        {getVar(packageData, 'username_reporter', '--')}
                    </span>
                ),
                [PACKAGE_COLUMN.DAMAGED_AT]: (
                    <span className="a-text--nowrap _damaged-at">
                        {getVar(packageData, 'damaged_at') ? dateFormatter.dateTime(packageData.damaged_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.STATUS_DAMAGED]: (
                    <span className="a-text--nowrap _damaged-status">
                        {getVar(packageData, 'status_damaged') ? t(`package:damaged_statuses.${packageData.status_damaged}`) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.DAMAGED_REASONS]: (
                    <span className="a-text--nowrap _damaged-reasons">
                        {getVar(packageData, 'damaged_reasons.length') ? packageData.damaged_reasons.join(', ') : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.COMPLAINT_SELLER]: complaintSellers,
                [PACKAGE_COLUMN.BARCODE]: (
                    <span className="a-text--nowrap _barcode">{lodash.get(packageData, 'barcode') || '--'}</span>
                ),
                [PACKAGE_COLUMN.BAG_CODE]: (
                    <span className="a-text--nowrap _code-bag">{lodash.get(packageData, 'code_bag') || '--'}</span>
                ),
                [PACKAGE_COLUMN.CREATE_TIME]: (
                    <span className="a-text--nowrap _created_at">
                        {dateFormatter.dateTime(packageData.created_at)}
                    </span>
                ),
                [PACKAGE_COLUMN.ORDER_LINKED_AT]: (
                    <span className="a-text--nowrap _order-linked-at">
                        {lodash.get(packageData, 'order_linked_at') ? dateFormatter.dateTime(packageData.order_linked_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.TRANSPORTING_AT]: (
                    <span className="a-text--nowrap _transporting-at">
                        {lodash.get(packageData, 'transporting_at') ? dateFormatter.dateTime(packageData.transporting_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.READY_FOR_DELIVERY_AT]: (
                    <span className="a-text--nowrap _ready-for-delivery-at">
                        {lodash.get(packageData, 'ready_for_delivery_at') ? dateFormatter.dateTime(packageData.ready_for_delivery_at) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.STATE]: (
                    <span className="a-text--nowrap _status_warehouse">
                        {packageData.status_warehouse ? t(`package:action.${packageData.status_warehouse}`) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.STATUS_TRANSPORT]: (
                    <span className="a-text--nowrap _status_transport">
                        {packageData.status_transport ? t(`package:status_transport.${packageData.status_transport}`) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.ORDER]: (
                    <span className="a-text--nowrap _order">
                        {lodash.get(packageData, 'code_order') ? (
                            <a
                                className="info--value a-text--blue"
                                href={lodash.get(packageData, 'm2_url_order') || '#top'}
                                rel="noopener noreferrer"
                                target={lodash.get(packageData, 'm2_url_order') ? '_blank' : '_self'}
                            >
                                {lodash.get(packageData, 'code_order', '--')}
                            </a>
                        ) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.PROPERTIES]: (
                    <span className="a-text--nowrap _properties">
                        {lodash.get(packageData, 'properties', []).join(', ') || '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.PRINTED_OFFICIAL_STAMP]: (
                    <span className="a-text--nowrap _printed-official-stamp">
                        {t(`package:printed_official_stamp.${!!lodash.get(packageData, 'is_printed_official_stamp')}`)}
                    </span>
                ),
                [PACKAGE_COLUMN.BOX_SERVICES]: (
                    <span className="a-text--nowrap _box-services">
                        {lodash.get(packageData, 'box_services', []).join(', ') || '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.SHIPPING_SERVICES]: (
                    <span className="a-text--nowrap _shipping-services">
                        {lodash.get(packageData, 'shipping_services', []).join(', ') || '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.EXTEND_SERVICES]: (
                    <span className="a-text--nowrap _extend-services">
                        {lodash.get(packageData, 'extend_services', []).join(', ') || '--'}
                    </span>
                ),        
                [PACKAGE_COLUMN.INPUT_WAREHOUSE]: (
                    <span className="a-text--nowrap _warehouse_input">
                        {lodash.get(packageData, 'code_warehouse_input', '--')}
                    </span>
                ),
                [PACKAGE_COLUMN.CURRENT_WAREHOUSE]: (
                    <span className="a-text--nowrap _current_warehouse">
                        {lodash.get(packageData, 'code_warehouse_current', '--')}
                    </span>
                ),
                [PACKAGE_COLUMN.DESTINATION_WAREHOUSE]: (
                    <span className="a-text--nowrap _destination_warehouse">
                        {lodash.get(packageData, 'code_warehouse_destination', '--')}
                    </span>
                ),
                [PACKAGE_COLUMN.SHIPPING_PARTNER]: (
                    <span className="a-text--nowrap _shipping-partner">
                        {lodash.get(packageData, 'shipping_partner', '--')}
                    </span>
                ),
                [PACKAGE_COLUMN.WEIGHT]: (
                    <span className="a-text--nowrap _weight_net">
                        {packageData.weight_net ? (`${packageData.weight_net} kg`) : '--'}
                    </span>
                ),
                [PACKAGE_COLUMN.VOLUME]: (
                    <span className="a-text--nowrap _volume">
                        {packageData.volume ? (`${packageData.volume} m3`) : '--'}
                    </span>
                )
            });
        });
    };

    const onChangePage = (page, pageSize) => {
        if (!loading) {
            onSearch({
                ...filter,
                page,
                per_page: pageSize,
            });
        }
    };

    const onChange = (pagination, filters, sorter) => {
        search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    };

    const search = input => {
        if (!loading) {
            onSearch({
                ...filter,
                ...input,
            });
        }
    };

    const handleExportPackageListButtonClick = () => {
        exportPackageList({
            ...filter,
            id_packages: selectedPackageIds
        });
    };

    const downloadTransportStatuses = () => {
        onDownloadTransportStatuses(selectedPackageIds);
    };

    const selectRow = (selectedRowKeys, selectedRows) => {
        setAgencyId(getVar(selectedRows, '[0].origin.id_agency'));
        setSelectedPackageIds(selectedRowKeys);
        setSelectedPackages(lodash.map(selectedRows, 'origin'));
    };

    const handleOpenModal = modal => {
        const newModalVisibles = {
            ...modalVisibles,
            [modal]: true
        };

        setModalVisibles(newModalVisibles);
    };

    const handleCancelModal = modal => {
        const newModalVisibles = {
            ...modalVisibles,
            [modal]: false
        };

        setModalVisibles(newModalVisibles);
    };

    useDeepCompareEffect(() => {
        setSelectedPackageIds([]);
    }, [lodash.omit(filter, [
        'i',
        'page',
        'per_page',
        'sort_by',
        'sort_direction'
    ])]);

    return (
        <>
            <CreateComplaintSellerModal
                agencyId={agencyId}
                packages={selectedPackages}
                visible={modalVisibles.createComplaintSeller}
                onCancel={handleCancelModal.bind(this, 'createComplaintSeller')}
                onOk={handleCancelModal.bind(this, 'createComplaintSeller')}
            />
            <div className="a-content__top a-content__top--page-list mt-0">
                <div className="a-content--page-list__title">
                    <h3>{t("package:label.list")} ({lodash.get(pagination, 'total', 0)})</h3>
                </div>
                <div className="a-content--page-list__top-action">
                    <CreateComplaintSellerButton
                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                        ghost
                        isDamage={true}
                        onClick={handleOpenModal.bind(this, 'createComplaintSeller')}
                    />

                    {canDownloadTransportStatuses ? (
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                            ghost
                            loading={downloadingTransportStatus}
                            type="primary"
                            onClick={downloadTransportStatuses}
                        >
                            {t('btn.export_excel_package_status')}
                        </Button>
                    ) : null}

                    {canExportPackageList && (
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                            ghost
                            loading={loading}
                            type="primary"
                            onClick={handleExportPackageListButtonClick}
                        >
                            {t('btn.export_excel')}
                        </Button>
                    )}

                    <ListPackageSetting
                        onChange={activeColumns => setActiveColumns(activeColumns)}
                        object={object}
                        loading={loading}
                        isOpenListSetting={isOpenListSetting}
                        onOpenListSetting={onOpenListSetting}
                        isCurrentPage={isCurrentPage}
                    />
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                <div className="a-table--responsive">
                    <Table
                        className="a-table--page-list _a-table--page-list"
                        columns={getColumns()}
                        dataSource={getDataSource()}
                        loading={loading}
                        locale={{
                            emptyText: loading ? (
                                <Icon type="loading-3-quarters" className="icon-loading a-text--black " />
                            ) : (
                                <Empty
                                    className="_empty"
                                    description={(
                                        <span>Không có dữ liệu </span>
                                    )}
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                >
                                </Empty>
                            )
                        }}
                        pagination={false}
                        rowClassName={(record, index) => index.toString()}
                        rowKey={record => record.key}
                        rowSelection={{
                            selectedRowKeys: selectedPackageIds,
                            onChange: selectRow
                        }}
                        onChange={onChange}
                    />
                </div>
                <Pagination
                    loading={loading}
                    page={lodash.toInteger(filter.page) || 1}
                    pageSize={lodash.toInteger(filter.per_page)}
                    pageSizeOptions={[20, 50, 100, 200]}
                    total={pagination.total}
                    onChange={onChangePage}
                />
            </div>
        </>
    );
};

ShipmentPackageList.defaultProps = {
    filter: {},
    loading: false,
    packages: [],
    pagination: {},
    onSearch: () => {
    },
};

ShipmentPackageList.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    packages: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
};

export default withTranslation()(ShipmentPackageList);
