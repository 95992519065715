/* eslint-disable */
import React, {Component} from 'react';
import {Select, Table, Pagination, Button, Menu, Dropdown, Icon, Checkbox, Modal} from 'antd';
import {ReactComponent as IconViewAll} from '../../../../resources/images/totalpackage.svg';

const Option = Select.Option;
const columnspackage = [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        render: text =>  <span className="a-text--nowrap">{text}</span>,
    },
    {
        title: 'Mã kiện',
        dataIndex: 'codepackage',
        key: 'codepackage',
        render: text =>  <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Mã đơn hàng',
        dataIndex: 'cododer',
        key: 'cododer',
        render: text =>  <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Cân nặng ',
        dataIndex: 'weight',
        key: 'weight',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }, {
        title: 'Kích thước',
        dataIndex: 'size',
        key: 'size',
        render: text => <span className="a-text--nowrap">{text}</span>,
    }];

const datapackage = [];
for (let i = 0; i < 50; i++) {
    datapackage.push({
        stt:`${i + 1}`,
        codepackage: `0912308710231${i}`,
        cododer: `1239123812${i}`,
        weight: `12${i}0kg`,
        size: `1${i + 1} `,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

class DeliveryNoteExportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const columns = [
            {
                title: 'Mã bao',
                dataIndex: 'codeexport',
                key: 'codeexport',
                render: text => <a href="javascript:void(0)" className="a-text--nowrap">
                    <img className="logo-shop" src={require("../../../../resources/images/bag.svg")}
                         alt=""/>{text}</a>,
            }, {
                title: 'Mã đơn hàng',
                dataIndex: 'cododer',
                key: 'cododer',
                sorter: true,
                render: text => <a href="javascript:void(0)" className="a-text--nowrap">
                    <img className="logo-shop" src={''}
                         alt=""/>{text}</a>,
            }, {
                title: 'Cân nặng ',
                dataIndex: 'weight',
                key: 'weight',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: 'Kích thước',
                dataIndex: 'size',
                key: 'size',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: '',
                dataIndex: 'view',
                key: 'view',
                className:'a-text--right',
                render: text => <a href="#" className="a-text--nowrap " onClick={this.showModal}><IconViewAll/></a>,
            }];

        const data = [];
        for (let i = 0; i < 50; i++) {
            data.push({
                cododer: `0912308710231${i}`,
                codeexport: `1239123812${i}`,
                weight: `${i}0kg`,
                size: `${i + 1} `,
                view: `Xem chi tiết`
            });
        }
        return (
            <React.Fragment>
                <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0">
                    <div className="a-content--page-list__title">
                        <h3>Danh Sách Bao - <span>32216</span></h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                ghost>Xuất CSV
                        </Button>
                        <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>Thiết lập cột thông tin
                            </Button>
                        </Dropdown>
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3 "
                        >In phiếu xuất
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list pb-0 mr-0">
                    <Table className={"a-table--antd"} columns={columns} dataSource={data}
                           pagination={false}
                    />
                    <div className="a-pagination a-pagination--fixed gflex gjustify-space-between p-5">
                        <Button className="a-btn a-btn--button-link a-text--blue ">Chỉnh sửa
                        </Button>
                        <Button type="primary"
                                className="ant-btn a-btn a-btn--primary a-btn--save-export _save-export-shipping_customer"
                                size="large">Xuất Kho
                        </Button>
                    </div>
                </div>
                <Modal
                    title={<div className="title-modal"><img  src={require("../../../../resources/images/bag-white.svg")}
                         alt=""/><span>HH0234-2342342</span></div>}
                    visible={this.state.visible}
                    className="a-modal a-modal--package-in-bag"
                    width={644}
                    footer={null}

                >
                    <Table className={"a-table--antd"} columns={columnspackage} dataSource={datapackage} scroll={{ y: 400 }}
                           pagination={false}
                    />
                </Modal>

            </React.Fragment>
        )
    }
}

export default DeliveryNoteExportList;