/* eslint-disable */

import React from 'react';
import InfoCustomerRequest from './components/InfoDeliveryNote'
import ListDeliveryNote from './components/ListDeliveryNoteExport';
import {Row} from "antd";
import Log from "./Log";
class RequestDeliveryNoteDetailContainer extends React.PureComponent{
    render() {
        return(
            <Row type="flex">
                <div className="a-content--scroll-content pt-0">
                <InfoCustomerRequest/>
                <ListDeliveryNote/>
                </div>
                <Log/>

            </Row>
        )
    }
}
export default RequestDeliveryNoteDetailContainer;