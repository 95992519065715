import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon} from 'antd';

class CancelDeliveryNote extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenCancelConfirm: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.isOpenCancelConfirm === true && nextProps.isModalProcessing === false) {
            nextState.isOpenCancelConfirm = false;
        }

        return nextState;
    }

    /**
     * handle action show modal confirm
     */
    confirmCancel = event => {
        event.preventDefault();

        let {t} = this.props;
        Modal.confirm({
            title: t("delivery_note:label.warning"),
            content: <p>{t("delivery_note:label.warning_confirm_cancel")}</p>,
            okText: t("delivery_note:label.ok"),
            cancelText: t("delivery_note:label.cancel"),
            onOk: this.handleOnOk.bind(this),
            onCancel: this.handleOnClose.bind(this),
            centered: true,
            icon: <Icon type="question-circle" theme="filled" />
        });
        this.props.beginCancelDeliveryNote();
    };

    /**
     * handle action click ok to unlink order
     */
    handleOnOk() {
        let {deliveryNoteData} = this.props;
        this.setState({isOpenCancelConfirm: true});
        this.props.cancelDeliveryNote(deliveryNoteData.id);
    }

    /**
     * handle action close modal
     */
    handleOnClose() {
        this.props.endCancelDeliveryNote();
    }

    render() {
        const { loading, t } = this.props;

        return (
            <Button
                className="a-btn a-btn--save-cancel a-btn--button-link a-text--blue amr-24  _save-cancel-shipping_customer"
                loading={loading}
                size="large"
                onClick={this.confirmCancel}
            >
                {t('delivery_note:label.cancel')}
            </Button>
        );
    }
}

CancelDeliveryNote.defaultProps = {
    deliveryNoteData: {},
    isModalProcessing: false,
    loading: false
};

CancelDeliveryNote.propTypes = {
    deliveryNoteData: PropTypes.object,
    cancelDeliveryNote: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginCancelDeliveryNote: PropTypes.func,
    endCancelDeliveryNote: PropTypes.func
};

export default CancelDeliveryNote;
