import React from 'react';
import lodash from 'lodash';
import {withTranslation} from 'react-i18next';
import {Tabs} from 'antd';
import Log from '../../../Common/Log/LogContainer';
import BagHistory from "./BagHistory";
import PackageChat from "./PackageChat";
import { HTC_ORDER_DETAIL_URL_NH } from '../../constants';
import { linkHTC } from '../../helper';

const TabPane = Tabs.TabPane;

class PackageLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {scroll: false};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scroll = window.pageYOffset > 104;

        if (this.state.scroll !== scroll) {
            this.setState({scroll});
        }
    };

    render() {
        let {t} = this.props;
        const order= lodash.get(this.props, 'package.order');
        const orderCode= lodash.get(this.props, 'package.order.code');
        const defaultActiveKey = '3';

        const linkHtc = linkHTC(lodash.get(this.props, 'package.agency.code'))

        return (
            <div className={"a-chatbox checkpackage " + (this.state.scroll ? 'scroll' : '')}>
                <div className="a-chatbox--body">
                    <Tabs defaultActiveKey={defaultActiveKey} className="a-tabs a-tabs--chatbox-tab _a-tabs-chatbox-tab">
                        <TabPane className="a-tabs--chatbox--panel _a-tabs-chatbox--panel-log" tab={t('package:label.log_title')} key="3">
                            <Log object="packages" objectId={this.props.package.code} delayTime={0} />
                        </TabPane>
                        {!lodash.isNil(order) && (
                            <TabPane className="a-tabs--chatbox--panel-chat _a-tabs-chatbox--panel-chat" tab={t('package:label.chat')} key="4">
                                {linkHtc && (
                                <div className="mt-3 mb-3 pl-3">
                                    <span>{t('package:message.link_to_chat_htc')}</span>
                                    <a
                                        href={linkHTC + "/order/detail/default/" + orderCode}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                      {t('package:message.access_please')}  
                                    </a>
                                </div>
                            )}
                                <PackageChat
                                    orderId={lodash.get(order, 'id')}
                                />
                            </TabPane>
                        )}
                        <TabPane className="a-tabs--chatbox--panel _a-tabs-chatbox--panel-bag-history"
                                 tab={t('package:label.history_title')} key="1">
                            <BagHistory package={this.props.package} loading={this.props.loading}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PackageLog);
