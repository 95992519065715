import { omit } from 'lodash';

import apiService from './apiService';
import * as ACTION from './constants';
import { processApiRequest } from './../Common/saga';

export function* fetchSuggestBoxSizes(action) {
    yield processApiRequest(
        ACTION.FETCH_SUGGEST_BOX_SIZES,
        () => apiService.fetchSuggestBoxSizes(omit(action.payload, ['successHandler', 'errorHandler'])),
        action.payload
    );
}

export function* getSuggestsByVolume(action) {
    yield processApiRequest(
        ACTION.GET_SUGGESTS_BY_VOLUME,
        () => apiService.getSuggestsByVolume(action.payload),
        action.payload
    );
}
