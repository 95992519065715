import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import FormShippingUserContainer from '../Form';

const mapDispatchToProps = (dispatch) => ({
    createShippingUser: (data) => dispatch(actions.createShippingUser(data)),
});

class CreateShippingUserContainer extends React.Component {
    render() {
        return <FormShippingUserContainer {...this.props}/>
    }
}

export default connect(
    null,
    mapDispatchToProps
)(CreateShippingUserContainer);
