import React from 'react'
import Background from '../../../../resources/images/bar-code.png'

const EmptyPage = () => {
    return (
        <div
            className="a-content--empty scanpackage a-bgwhite height-100 mt-2"
            style={{ minHeight: 480 }}>
            <img
                src={Background}
                alt=""
            />
            <span>Không có dữ liệu</span>
        </div>
    )
}

export default EmptyPage
