import lodash from 'lodash';
import moment from 'moment';
import {trans} from '../../../system/i18n';
import * as ACTION from './constants';
import {createSelector} from 'reselect';
import {dateFormatter} from './../../Common/services/format/index';
import * as selectorWarehouse from './../../Warehouse/selectors';

let listData = state => state.scanHistory.listData || [];
let dataWarehouses = state => selectorWarehouse.getState(state, 'list') || [];

export const listDataScanHistory = createSelector(
    listData,
    dataWarehouses,
    (listData, dataWarehouses) => listData.map((listDataItem) => {
        let code_warehouse_scan = '';
        let code_error = null;
        let message_warning = "";
        let created_at = moment();
        if (!listDataItem.code_warehouse_scan) {
            let warehouseCurrent = dataWarehouses.filter(dataWarehouseItem => dataWarehouseItem.id === listDataItem.id_warehouse_scan);
            warehouseCurrent = lodash.head(warehouseCurrent);
            if (!lodash.isEmpty(warehouseCurrent)) {
                code_warehouse_scan = warehouseCurrent.code;
            }
        }
        code_warehouse_scan = listDataItem.code_warehouse_scan ? listDataItem.code_warehouse_scan : code_warehouse_scan;
        created_at = listDataItem.created_at ? listDataItem.created_at : created_at;
        code_error = listDataItem.code_error || null;
        if (Array.isArray(code_error)) {
            code_error.map(error => {
                message_warning += message_warning !== '' ? '. ' : '';
                switch (error.code) {
                    case ACTION.ERROR_SCAN_BAG_NOT_VALID:
                        message_warning += trans('scan:error.ERROR_SCAN_BAG_NOT_VALID');
                        break;
                    case ACTION.ERROR_WAREHOUSE_NOT_EXIST:
                        message_warning += trans('scan:warning.' + ACTION.ERROR_WAREHOUSE_NOT_EXIST.toLowerCase());
                        break;
                    case ACTION.ERROR_BARCODE_NOT_EXIST:
                        message_warning += trans('scan:warning.' + ACTION.ERROR_BARCODE_NOT_EXIST.toLowerCase());
                        break;
                    case ACTION.ERROR_STATUS_ACTION.VALUE_INVALID:
                        message_warning += trans(`scan:error.${ACTION.ERROR_STATUS_ACTION.VALUE_INVALID.toLowerCase()}`);
                        break;
                    case ACTION.ERROR_REQUIRED_WAREHOUSE_DESTINATION: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_REQUIRED_WAREHOUSE_DESTINATION.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_MORE_THAN_ONE_PROCESS_SHIPMENT_PACKAGE.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_NOT_SHIPMENT_PACKAGE: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_NOT_SHIPMENT_PACKAGE.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_PACKAGE_NOT_WEIGHT: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_PACKAGE_NOT_WEIGHT.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_SCAN_COMPLETED_BAG: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_SCAN_COMPLETED_BAG.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_SCAN_UNCOMPLETED_PACKING_BAG: {
                        message_warning += trans(`scan:error.${ACTION.ERROR_SCAN_UNCOMPLETED_PACKING_BAG.toLowerCase()}`);
                        break;
                    }
                    case ACTION.ERROR_MESSAGE.MORE_ONE_PACKAGE: {
                        const packages = lodash.get(error, "packages", []);
                        message_warning += trans(`scan:error.${ACTION.ERROR_MESSAGE.MORE_ONE_PACKAGE.toLowerCase()}`, {packages: packages.join(", "), count: packages.length});
                        break;
                    }
                    default:
                        message_warning += trans('scan:error.barcode_invalid');
                        break;
                }

                return error;
            });
        }
        listDataItem = {
            ...listDataItem, ...{
                code_warehouse_scan: code_warehouse_scan,
                created_at: dateFormatter.dateTime(created_at),
                message_warning: message_warning,
            }
        };

        return listDataItem;
    })
);
