import {notification} from 'antd';
import {t, trans} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import lodash from "lodash";
import React from "react";

export function* updateBagParam(action) {
    let data = {};
    if (action.payload.param === 'id_customer' || action.payload.param === 'type') {

        data = {...action.payload.value}
    } else {
        data = {[action.payload.param]: action.payload.value}
    }
    yield processApiRequest(
        ACTION.UPDATE_BAG_PARAM,
        () => apiService.updateBag(action.payload.bagId, data),
        action.payload
    );
}

export function* onUpdateBagParamSuccess() {
    yield notification.success({message: trans('message.update_success')});
}

export function* onUpdateBagParamFailed(action) {
    let error = lodash.get(action, 'payload.data');
    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}
