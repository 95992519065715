import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as barcodeSelectors from './../DeliveryNoteBarcode/selectors';
import * as createActions from './../Return/Create/actions';
import * as editActions from './../Return/Edit/actions';
import * as createSelectors from './../Return/Create/selectors';
import * as editSelectors from './../Return/Edit/selectors';
import * as detailSelectors from './../Return/Detail/selectors';
import apiService from './../../ShippingReturnPartners/apiService';

import FormShippingReturnPartner from './components';

const FormShippingReturnPartnerContainer = props => {
    const [loading, setLoading] = useState(false);
    const [shippingReturnPartner, setShippingReturnPartner] = useState({});

    const getShippingReturnPartner = async id => {
        setLoading(true);

        try {
            const { data } = await apiService.detail(id);

            setShippingReturnPartner(data.shipping_return_partner);
        } catch (error) {
        };

        setLoading(false);
    };

    const handleReset = () => {
        props.clearCreateState();
    };

    return (
        <FormShippingReturnPartner
            {...props}
            loading={loading}
            shippingReturnPartner={shippingReturnPartner}
            getShippingReturnPartner={getShippingReturnPartner}
            onReset={handleReset}
        />
    );
};

const mapStateToProps = state => {
    return {
        adding: createSelectors.getState(state, 'adding'),
        updating: editSelectors.getState(state, 'updating'),
        deliveryNoteReturn: detailSelectors.getState(state, 'deliveryNoteReturn'),
        packages: barcodeSelectors.getPackageIds(state)
    };
};

const mapDispatchToProps = {
    add: createActions.add,
    update: editActions.update,
    clearCreateState: createActions.clearState
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormShippingReturnPartnerContainer);
