import React, {Component} from 'react';
import {t} from "../../../system/i18n";
import {Input} from "antd";

class FormFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.filter
        }

    };

    onChangeInput = (field, e) => {
        this.setState({
            filter: {
                [field]: e.target.value
            }
        });

        if(e.type === 'click') {
            this.props.onSearch({});
        }
    };

    onFilter = () => {
        this.props.onSearch(this.state.filter)
    };

    render() {
        const {filter} = this.state;
        return (
            <Input.Search
                placeholder={t('template_bag:search_template_bag')}
                className={"a-input--search-inline-title _input-search-template-bag"}
                onChange={this.onChangeInput.bind(this, 'label')}
                onPressEnter={this.onFilter}
                value={filter.label}
                allowClear={true}
            />
        )
    }
}

FormFilter.defaultProps = {
    filter: {},
    onSearch: (filter) => {}
};

export {FormFilter};
