import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {setCollectionItem} from '../../Common/services/helps';

let packages = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.LINK_ORDER_PACKAGE.REQUEST:
            return setCollectionItem(state, payload.packageCode, {loading: true}, 'code');

        case ACTION.LINK_ORDER_PACKAGE.SUCCESS:
            return setCollectionItem(state, action.request.packageCode, {loading: false}, 'code');

        case ACTION.LINK_ORDER_PACKAGE.FAILED:
            return setCollectionItem(state, action.request.packageCode, {loading: false}, 'code');

        case ACTION.CLEAR_STATE:
            return state.filter(packageData => packageData.code !== payload.packageCode);

        default:
            return state;
    }
};

export default combineReducers({
    packages,
});
