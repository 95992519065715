import * as ACTION from './constants';
import * as saga from './saga';
import {takeLatest} from 'redux-saga/effects';

export default function*() {
    yield takeLatest(ACTION.DELETE_PACKAGE.REQUEST, saga.deletePackage);
    yield takeLatest(ACTION.DELETE_PACKAGE.SUCCESS, saga.deletePackageSuccess);
    yield takeLatest(ACTION.DELETE_PACKAGE.FAILED, saga.deletePackageFailed);

    yield takeLatest(ACTION.REMOVE_PACKAGE.REQUEST, saga.removePackage);
    yield takeLatest(ACTION.REMOVE_PACKAGE.SUCCESS, saga.removePackageSuccess);
    yield takeLatest(ACTION.REMOVE_PACKAGE.FAILED, saga.removePackageFailed);
}
