import {connect} from 'react-redux';
import DeletePackage from './components';
import * as actions from './actions';
import lodash from 'lodash';

const mapStateToProps = (state, props) => ({
    packageData: props.packageData,
    isModalProcessing: lodash.get(state, 'package.deletePackage.isProcessing', false),
    loading: lodash.get(state, 'package.deletePackage.loading', false),
});

const mapDispatchToProps = (dispatch) => ({
    beginDeletePackage: () => {
        dispatch(actions.beginDeletePackage());
    },
    endDeletePackage: () => {
        dispatch(actions.endDeletePackage());
    },
    onDeletePackage: (code, params) => {
        dispatch(actions.deletePackage(code, params));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeletePackage);
