import lodash from 'lodash';
import { createSelector } from "reselect";
import * as userSelector from './../../User/selectors';
import * as warehouseSelector from './../../Warehouse/selectors';
import { currentFormatter, dateFormatter } from '../../Common/services/format';
import { Link } from './../../../system/routing';
import React from 'react';
import { t, trans } from '../../../system/i18n';
import { translateValidationErrors } from "../../Common/services/helps";

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'deliveryNote.detailDeliveryNote' + (param ? '.' + param : ''), defaultValue);

export const getDeliveryNoteDetail = createSelector(
        state => getState(state, 'deliveryNote', { delivery_note: {}, delivery_note_packages: [], delivery_note_bags: [] }),
        state => userSelector.getState(state, 'users', []),
        state => warehouseSelector.getState(state, 'list', []),
        (deliveryNote, users, warehouses) => {
            deliveryNote = lodash.isNil(deliveryNote) ? {
                delivery_note: {},
                delivery_note_packages: [],
                delivery_note_bags: [],
                errors: {}
            } : deliveryNote;
            let { delivery_note, delivery_note_packages, delivery_note_bags, errors, customer_info, delivery_requests, customer } = deliveryNote;
            let exporter = lodash.find(users, (user => {
                return user.id === delivery_note.id_exporter
            }));
            let creator = lodash.find(users, (user => {
                return user.id === delivery_note.id_creator;
            }));
            let shippingUser = lodash.find(users, user => {
                return user.id === delivery_note.id_shipping_user;
            });
            let warehouse = lodash.find(warehouses, warehouses => {
                return warehouses.warehouse.id === delivery_note.id_warehouse;
            });

            delivery_note.creator = lodash.isNil(creator) ? '--' : creator.name + " (" + creator.username + ")";
            delivery_note.exporter = lodash.isNil(exporter) ? '--' : exporter.name + " (" + exporter.username + ")";
            delivery_note.shippingDomesticPartner = lodash.get(deliveryNote, "last_mile_carrier.name",  "--") ;
        delivery_note.warehouse = lodash.isNil(warehouse) ? '--' : warehouse.warehouse.name;
        delivery_note.shippingUser = lodash.isNil(shippingUser) ? '--' : shippingUser.name + " (" + shippingUser.username + ")";
        delivery_note.shippingUserPhone = lodash.isNil(shippingUser) ? '--' : shippingUser.phone;
        delivery_note.createdAtFormat = dateFormatter.dateTime(delivery_note.created_at);
        delivery_note.exportedAtFormat = lodash.isNil(delivery_note.exported_at) ? "--" : dateFormatter.dateTime(delivery_note.exported_at);
        delivery_note.packageWeightFomat = delivery_note.package_weight + " (kg)";
        delivery_note.amountCollectFomat = currentFormatter.toLocaleStringCurrency(delivery_note.amount_collect);
        delivery_note.delivery_requests = delivery_requests;
        delivery_note.customer_id_agency = lodash.get(customer, 'id_agency');
        
        lodash.map(delivery_note_packages || [], deliveryNotePackage => {
            let packageCode = lodash.get(deliveryNotePackage, 'package.code');
            let message = [];
            if(errors) {
                lodash.forEach(errors, function (values, key) {
                    if(values){
                        lodash.forEach(values, function (scanCodes, keyErr) {
                            switch (keyErr) {
                                case 'package_in_bag_not_completed' :
                                    lodash.each(scanCodes, (scan) => {
                                        if (lodash.includes(scan.package, packageCode)) {
                                            message.push(trans('delivery_note:message.' + keyErr, {
                                                code: <Link to="bags.detail"
                                                            params={{id: scan.bag}}>{scan.bag}</Link>
                                            }));
                                        }
                                    });
                                    break;
                                case 'package_not_include_weight':
                                    if (scanCodes.includes(packageCode)) {
                                        lodash.each(scanCodes, (code) => {
                                            message.push(trans('delivery_note:message.export_error_package_not_include_weight'));
                                        });
                                    }

                                    break;

                                default :
                                    if (lodash.includes(scanCodes, packageCode)) {
                                        message.push(t('delivery_note:message.' + keyErr));
                                    }
                            }

                        });
                        message = lodash.uniqBy(message, function (e) {
                            return e;
                        });
                    }
                })
            }
            deliveryNotePackage.netWeightFormat = lodash.get(deliveryNotePackage, 'package.weight_net', '0') + " (kg)";
            deliveryNotePackage.volumneFormat = lodash.round(lodash.get(deliveryNotePackage, 'package.volume', '0') * 1000000, 3) + " (cm3)";
            deliveryNotePackage.orderCode = lodash.get(deliveryNotePackage, 'order.code', '--');
            deliveryNotePackage.packageCode = <Link to="packages.detail"
                                                    params={{id: packageCode}}>{packageCode}</Link>;
            deliveryNotePackage.message = message;
            return deliveryNotePackage;
        });

        lodash.map(delivery_note_bags || [], item => {
            let code = item.bag.code;
            let message = [];
            if(errors) {
                lodash.forEach(errors, function (values, key) {
                    lodash.forEach(values, function (scanCodes, keyErr) {
                        switch (keyErr) {
                            case 'package_in_bag_not_completed' :
                                lodash.each(scanCodes, (scan) => {
                                    if (lodash.includes(scan.package, code)) {
                                        message.push(trans('delivery_note:message.' + keyErr, {
                                            code: <Link to="bags.detail"
                                                        params={{id: scan.bag}}>{scan.bag}</Link>
                                        }));
                                    }
                                });
                                break;
                            default :
                                if (lodash.includes(scanCodes, code)) {
                                    message.push(t('delivery_note:message.' + keyErr));
                            }
                    }

                    });
                });
            }

            lodash.forEach(item.packages, pkg => {
                pkg.netWeightFormat = lodash.get(pkg, 'package.weight_net', '0') + " (kg)";
                pkg.volumneFormat = lodash.round(lodash.get(pkg, 'package.volume', '0') * 1000000, 3) + " (cm3)";
            });

            item.message = message;
            return item;
        });

        deliveryNote.delivery_note = delivery_note;
        deliveryNote.delivery_note_packages = delivery_note_packages;
        deliveryNote.delivery_note_bags = delivery_note_bags;
        deliveryNote.errors = errors;
        deliveryNote.customer_info = customer_info || {};

        const totalPackage = lodash.get(deliveryNote, 'delivery_note.total_number', 0);
        const totalPackageWeight = lodash.get(deliveryNote, 'delivery_note.total_weight', 0);

        deliveryNote.totalPackage = totalPackage;
        deliveryNote.totalPackageWeight = {
            value: totalPackageWeight,
            format: totalPackageWeight + " (kg)"
        };

        return deliveryNote;
    }
);

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let messages = translateValidationErrors(t('delivery_note:label.' + param), rules);

        return lodash.first(lodash.values(messages));
    });
};
