import React from 'react'
import { Col, Divider, Row, Typography } from 'antd'
import { get, isEmpty } from 'lodash'

import { currentFormatter } from './../../../../Common/services/format'
import { getVar } from './../../../../../system/support/helpers'

const { Paragraph, Text, Title } = Typography

const InfoDeliveryNotePrint = ({ t, deliveryNote }) => {
    const deliveryNoteDetail = get(deliveryNote, 'delivery_note', {})
    const customer = get(deliveryNote, 'customer', {}) || {}
    const creator = get(deliveryNote, 'creator', {})
    const totalPackageExport = get(deliveryNote, 'totalPackage')
    const totalPackageWeight = get(deliveryNote, 'totalPackageWeight.format')
    const agency = get(deliveryNote, 'agency', {})
    const deliveryRequests = get(deliveryNote, 'delivery_requests', [])

    return (
        <div className="print-delivery-note-for-customer--info a-block-info">
            <Title
                className="title"
                level={2}>
                {t('delivery_note:print.receiving_information')}
            </Title>

            <Row>
                <Col
                    className="gflex info--group"
                    span={24}>
                    <label className="info--label">{t('delivery_note:print.customer_name')}:</label>
                    <span className="info--value a-text--bold ">
                        {customer.full_name} ({customer.username})
                    </span>
                </Col>
                <Col
                    className="gflex info--group"
                    span={24}>
                    <Row className="gw-100">
                        <Col
                            className="gflex"
                            span={16}>
                            <label className="info--label">{t('delivery_note:print.receiver')}:</label>
                            <span className="info--value a-text--bold">{getVar(deliveryNoteDetail, 'customer_receiver', '--')}</span>
                        </Col>
                        <Col
                            className="gflex"
                            span={8}>
                            <label className="info--label">{t('delivery_note:print.mobile')}:</label>
                            <span className="info--value a-text--bold">{getVar(deliveryNoteDetail, 'customer_phone', '--')}</span>
                        </Col>
                    </Row>
                </Col>
                <Col
                    className="gflex info--group"
                    span={24}>
                    <label className="info--label">{t('package:label.customer_address_destination')}:</label>
                    <span className="info--value a-text--bold">{getVar(deliveryNoteDetail, 'customer_address', '--')}</span>
                </Col>
                <Col
                    className="gflex info--group"
                    span={24}>
                    <Row className="gw-100">
                        <Col
                            className="gflex"
                            span={12}>
                            <label className="info--label">{t('delivery_note:print.total_quantity')}:</label>
                            <span className="info--value a-text--bold">
                                {t('delivery_note:print.exported_package_amount', {
                                    amount: totalPackageExport,
                                })}
                            </span>
                        </Col>
                        <Col
                            className="gflex"
                            span={12}>
                            <label className="info--label">{t('delivery_note:print.total_weight')}:</label>
                            <span className="info--value a-text--bold">{totalPackageWeight}</span>
                        </Col>
                    </Row>
                </Col>
                <Col
                    className="gflex info--group"
                    span={24}>
                    <Row className="gw-100">
                        {(!!deliveryNoteDetail.length || !!deliveryNoteDetail.width || !!deliveryNoteDetail.height) && (
                            <Col
                                className="gflex"
                                span={12}>
                                <label className="info--label">{t('lwh_dimension')}:</label>
                                <span className="info--value a-text--bold">
                                    {deliveryNoteDetail.length || 0}x{deliveryNoteDetail.width || 0}x{deliveryNoteDetail.height || 0}
                                </span>
                            </Col>
                        )}
                        {!!deliveryNoteDetail.domestic_shipping_fee && (
                            <Col
                                className="gflex"
                                span={12}>
                                <label className="info--label">{t('shipping_fee')} (VNĐ):</label>
                                <span className="info--value a-text--bold">
                                    {currentFormatter.toLocaleStringCurrency(deliveryNoteDetail.domestic_shipping_fee)}
                                </span>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col
                    className="gflex info--group"
                    span={24}>
                    <Row className="gw-100">
                        <Col
                            className="gflex"
                            span={12}>
                            <label className="info--label">{t('negative_balance')} (VNĐ):</label>
                            <span className="info--value a-text--bold">
                                {!!deliveryNoteDetail.negative_balance ? (
                                    <>
                                        {deliveryNoteDetail.type === 'CUSTOMER' ? '-' : ''}
                                        {currentFormatter.toLocaleStringCurrency(deliveryNoteDetail.negative_balance)}
                                    </>
                                ) : (
                                    currentFormatter.toLocaleStringCurrency(0)
                                )}
                            </span>
                        </Col>

                        {!!deliveryNoteDetail.cod && (
                            <Col
                                className="gflex"
                                span={12}>
                                <label className="info--label">COD (VNĐ):</label>
                                <span className="info--value a-text--bold">{currentFormatter.toLocaleStringCurrency(deliveryNoteDetail.cod)}</span>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col
                    className="gflex info--group"
                    span={24}>
                    <Row className="gw-100">
                        <Col
                            className="gflex"
                            span={12}>
                            <label className="info--label">{t('agency')} :</label>
                            <span className="info--value a-text--bold">{!isEmpty(agency) ? get(agency, 'name', get(agency, 'code', '')) : ''}</span>
                        </Col>

                        <Col
                            className="gflex"
                            span={12}>
                            <label className="info--label">{t('menu.delivery_requests')} :</label>
                            <span className="info--value a-text--bold">
                                {deliveryRequests ? deliveryRequests.map((deliveryRequest, key) => <span> {get(deliveryRequest, 'code')} </span>) : ''}
                            </span>
                        </Col>
                    </Row>
                </Col>

                <Col
                    className="gflex"
                    span={24}>
                    <label className="info--label">{t('delivery_note:print.note')}:</label>
                    <span className="info--value a-text--bold">{getVar(deliveryNoteDetail, 'note', '--')}</span>
                </Col>
            </Row>

            <Divider />

            <Paragraph>{t('delivery_note:print.hint1')}</Paragraph>
            <Paragraph>{t('delivery_note:print.hint2')}</Paragraph>
            <Paragraph>{t('delivery_note:print.hint3')}</Paragraph>
            <Paragraph>{t('delivery_note:print.hint4')}</Paragraph>
            <Paragraph>{t('delivery_note:print.hint5')}</Paragraph>

            <div className="delivery-note--person">
                <div className="person-action">
                    <Paragraph>{t('delivery_note:print.invoice_creator')}</Paragraph>
                    <Text strong>{get(creator, 'name', '--')}</Text>
                </div>
                <div className="person-action atext-right">
                    <Paragraph>{t('delivery_note:print.receiver_name')}</Paragraph>
                    <Text strong>{deliveryNoteDetail.customer_receiver}</Text>
                </div>
            </div>
        </div>
    )
}

export default InfoDeliveryNotePrint
