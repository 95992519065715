import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { addShippingFee, clearFormErrors, getRoutes, getShippingFees, updateShippingFee } from './../../../actions';
import { getState, getTableDisplayShippingFees } from './../../../selectors';
import { getActiveConsignServicesByGroupCode, getActiveServicesByGroupCode } from './../../../../Service/selectors';
import { fetchConsignServiceGroups, fetchServiceGroups } from './../../../../Service/actions';
import { getState as getServiceState } from './../../../../Service/selectors';

import EditShippingFeeModal from './EditShippingFeeModal';
import ShippingFeeLogsModal from './ShippingFeeLogsModal';
import SearchForm from './SearchForm';
import ShippingFeeTable from './ShippingFeeTable';
import SearchableComponent from './../../../../Common/components/SearchableComponent';

const consignServicesSelector = getActiveConsignServicesByGroupCode('CONSIGN_SHIPPING');
const servicesSelector = getActiveServicesByGroupCode('SHIPPING');

class Main extends SearchableComponent {
    state = {
        editModalVisible: false,
        filter: {},
        selectedShippingFee: {},
        shippingFeeLogsModalVisible: false
    };

    handleChangePage = pagination => {
        const { filter } = this.state;

        this.pushFilter({
            ...filter,
            page: pagination.current,
            per_page: pagination.pageSize
        });
    };

    onChangeFilter = filter => {
        const { getShippingFees, location } = this.props;

        getShippingFees(filter);

        this.setState({
            filter: this.getFilterFromLocation(location)
        });
    };

    handleSearch = filter => {
        const { pagination } = this.props;

        this.pushFilter({
            ...filter,
            page: pagination.page ? 1 : undefined,
            per_page: pagination.per_page
        });

        this.setState({
            filter
        });
    };

    handleRefreshFilter = () => {
        this.setState({
            filter: {}
        });

        this.pushFilter();
    };

    handleCreateButtonClick = () => {
        this.setState({
            editModalVisible: true
        });
    };

    handleCancelEditShippingFee = () => {
        const { clearFormErrors } = this.props;

        this.setState({
            editModalVisible: false,
            selectedShippingFee: {}
        });

        clearFormErrors();
    };

    handleSubmit = async values => {
        const { adding, updating, addShippingFee, updateShippingFee } = this.props;

        if (adding || updating) {
            return;
        }

        if (values && values.id) {
            updateShippingFee(values);
        } else {
            addShippingFee(values);
        }
    };

    handleEditButtonClick = index => {
        const { shippingFees } = this.props;

        this.setState({
            editModalVisible: true,
            selectedShippingFee: shippingFees[index]
        });
    };

    handleViewLogButtonClick = index => {
        const { shippingFees } = this.props;

        this.setState({
            selectedShippingFee: shippingFees[index],
            shippingFeeLogsModalVisible: true
        });
    };

    handleCloseShippingFeeLogsModal = () => {
        this.setState({
            selectedShippingFee: {},
            shippingFeeLogsModalVisible: false
        });
    };

    componentDidMount() {
        const { fetchConsignServiceGroups, fetchServiceGroups, getRoutes, location } = this.props;
        const filter = this.getFilterFromLocation(location);

        super.componentDidMount();

        fetchServiceGroups();
        fetchConsignServiceGroups();
        getRoutes();

        this.setState({
            filter
        });
    }

    componentDidUpdate(prevProps) {
        const { filter } = this.state;
        const { adding, editModalVisible, formErrors, pagination, updating, getShippingFees } = this.props;

        super.componentDidUpdate(prevProps);

        if (((adding !== prevProps.adding && !adding) || (updating !== prevProps.updating && !updating)) && !formErrors) {
            this.handleCancelEditShippingFee();
            getShippingFees({
                ...filter,
                ...pagination
            });
        }

        if (editModalVisible !== prevProps.editModalVisible) {
            this.handleCreateButtonClick();
        }
    }

    render() {
        const { editModalVisible, filter, selectedShippingFee, shippingFeeLogsModalVisible } = this.state;
        const {
            adding,
            consignServices,
            formErrors,
            loading,
            loadingConsignServiceGroups,
            loadingServiceGroups,
            pagination,
            routes,
            services,
            tableDisplayShippingFees,
            updating
        } = this.props;

        return (
            <div className="a-content__body bg-white a-content__body--page-list mt-0 height-100">
                <SearchForm
                    consignServices={consignServices}
                    filter={filter}
                    loadingConsignServiceGroups={loadingConsignServiceGroups}
                    loadingServiceGroups={loadingServiceGroups}
                    routes={routes}
                    services={services}
                    onRefresh={this.handleRefreshFilter}
                    onSearch={this.handleSearch}
                />

                <ShippingFeeTable
                    loading={loading}
                    pagination={pagination}
                    shippingFees={tableDisplayShippingFees}
                    onChangePage={this.handleChangePage}
                    onEditButtonClick={this.handleEditButtonClick}
                    onViewLogButtonClick={this.handleViewLogButtonClick}
                />

                <EditShippingFeeModal
                    consignServices={consignServices}
                    formErrors={formErrors}
                    loading={adding || updating}
                    routes={routes}
                    services={services}
                    shippingFee={selectedShippingFee}
                    visible={editModalVisible}
                    onCancel={this.handleCancelEditShippingFee}
                    onOk={this.handleSubmit}
                />

                <ShippingFeeLogsModal
                    shippingFeeId={get(selectedShippingFee.shipping_fee, 'id')}
                    visible={shippingFeeLogsModalVisible}
                    onCancel={this.handleCloseShippingFeeLogsModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        adding: getState(state, 'adding'),
        consignServices: consignServicesSelector(state),
        formErrors: getState(state, 'formErrors'),
        loading: getState(state, 'loading'),
        loadingConsignServiceGroups: getServiceState(state, 'loadingConsignServiceGroups'),
        loadingServiceGroups: getServiceState(state, 'loadingServiceGroups'),
        pagination: getState(state, 'pagination'),
        routes: getState(state, 'routes.routes'),
        services: servicesSelector(state),
        shippingFees: getState(state, 'shippingFees'),
        tableDisplayShippingFees: getTableDisplayShippingFees(state),
        updating: getState(state, 'updating')
    };
};

const mapDispatchToProps = {
    fetchConsignServiceGroups,
    fetchServiceGroups,
    getRoutes,
    getShippingFees,
    addShippingFee,
    updateShippingFee,
    clearFormErrors
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main);
