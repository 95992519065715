import { Icon, Radio } from 'antd'
import { filter, get, map } from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../Service/actions'
import * as selectors from '../../../Service/selectors'

const mapStateToProps = (state, props) => ({
    ...props,
    consignServiceGroups: selectors.getActiveConsignServiceGroups(state),
    loading: selectors.getState(state, 'loadingConsignServiceGroups'),
})

const mapDispatchToProps = dispatch => ({
    fetchConsignServiceGroups: () => dispatch(actions.fetchConsignServiceGroups()),
})

const SelectService = props => {
    const { consignServiceGroups, loading, t, types, fetchConsignServiceGroups } = props

    const serviceGroup = get(filter(consignServiceGroups, { code: 'CONSIGN_SHIPPING' }), '0.services', [])

    useEffect(() => {
        if (!consignServiceGroups.length) {
            fetchConsignServiceGroups()
        }
    }, [])

    return (
        <Radio.Group
            // onChange={this.onChange}
            // value={this.state.value}
            {...props}
            className='gflex'
            >
            {map(serviceGroup, service => (
                <Radio
                    value={service.id}
                    key={service.id}
                    className={`_service_${service.id}`}>
                    {' '}
                    {service.name}
                </Radio>
            ))}
        </Radio.Group>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectService)
