import React from 'react';
import {Affix, Button, Layout, Skeleton} from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import {t} from './../../../../system/i18n';

import ItemTableFailed from './ItemTableFailed';
import ItemTableSuccess from './ItemTableSuccess';
import ItemTableWarning from './ItemTableWarning';

import Background from './../../../../resources/images/bar-code.png';

const {Content} = Layout;

class ScanHistory extends React.Component {
    componentDidMount() {
        this.props.onFetchScanHistory({user_id: this.props.user_id});
    }

    onClearHistory = () => {
        this.props.onClearScanHistory({user_id: this.props.user_id});
    };

    render() {
        const {dataList, scroll, onExportCsv, onExportWarehouseStorageCsv} = this.props;
        let key = 0;
        return (
            <Content
                className={clsx('a-content a-barcode-scan aflex-1 gflex gflex-direction-column', {
                    'a-package__scan-empty': !dataList.length,
                    'scroll-is-sider': scroll
                })}
            >
                {dataList.length ? (
                    <>
                        <Affix>
                            <div className="a-content__top">
                                <div className="a-barcode-scan__title">
                                    <h3>
                                        {t('scanning_history')} ({dataList.length})
                                    </h3>
                                </div>
                                <div className="a-barcode-scan__top-action">
                                    <Button
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 _btn-export-csv"
                                        type="primary"
                                        onClick={onExportWarehouseStorageCsv}
                                    >
                                        {t('common:btn.export_warehouse_storage')}
                                    </Button>
                                    <Button
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 _btn-export-csv"
                                        type="primary"
                                        onClick={onExportCsv}
                                    >
                                        {t('common:btn.export_csv')}
                                    </Button>
                                    <Button
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-clear-scan-history"
                                        icon="sync"
                                        type="primary"
                                        onClick={this.onClearHistory}
                                    >
                                        {t('delete_scan_history')}
                                    </Button>
                                </div>
                            </div>
                        </Affix>
                        <div
                            className="a-content__body bg-white a-barcode-scan__body a-content__body--page-list list-is-sidebar aheight-100">
                            <div className="a-table--responsive">
                                <table className="a-table a-table--barcode-scan">
                                    <thead>
                                    <tr>
                                        <th>{t('table.#')}</th>
                                        <th>{t('scan_code')}</th>
                                        <th>{t('bag_code')}</th>
                                        <th>{t('time_scan')}</th>
                                        <th>{t('action')}</th>
                                        <th>{t('scan_warehouse')}</th>
                                        <th>{t('config:column.username')}</th>
                                        <th>{t('barcode:scanning_history.column.handling_indicate')}</th>
                                    </tr>
                                    </thead>
                                    {dataList.map((listDataItem) => {
                                        const barcodeType = lodash.get(listDataItem, "barcode_type");
                                        const bag_codes   = barcodeType === 'PACKAGE' ?  lodash.get(listDataItem, "package_codes", []) : lodash.get(listDataItem, "bag_codes", []);
                                        const is_loading  = lodash.get(listDataItem, "is_loading");

                                        let noticeAsync = 'process_bags_async';
                                        if (barcodeType == 'PACKAGE') {
                                            noticeAsync = 'process_packages_async'
                                        }
                                        
                                        if (bag_codes.length > 0) {
                                            return bag_codes.map(item => {
                                                if (item) {
                                                    const dataRender = listDataItem.success ?
                                                        (is_loading ?
                                                            <tbody>
                                                                <tr>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                    <td><Skeleton active paragraph={{rows: 0}}/></td>
                                                                </tr>
                                                            </tbody>
                                                            :
                                                            <ItemTableWarning
                                                                index={key}
                                                                listDataItem={{
                                                                    ...listDataItem,
                                                                    barcode: item,
                                                                    notices: {text: [`${noticeAsync}`]}
                                                                }}
                                                            />
                                                        ) :
                                                        <ItemTableFailed
                                                            index={key}
                                                            listDataItem={{...listDataItem, barcode: item}}
                                                        />;
                                                    key++;
                                                    return dataRender;
                                                }
                                            });

                                        } else {
                                            const dataRender = listDataItem.success ? (
                                                lodash.get(listDataItem, 'notes.length') || lodash.get(listDataItem, 'notices.text.length') ? (
                                                    <ItemTableWarning
                                                        index={key}
                                                        key={listDataItem.request_id}
                                                        listDataItem={listDataItem}
                                                    />
                                                ) : (
                                                    <ItemTableSuccess
                                                        index={key}
                                                        key={listDataItem.request_id}
                                                        listDataItem={listDataItem}
                                                    />
                                                )
                                            ) : (
                                                <ItemTableFailed
                                                    index={key}
                                                    key={listDataItem.request_id}
                                                    listDataItem={listDataItem}
                                                />
                                            );
                                            key++;
                                            return dataRender;
                                        }
                                    })}
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="a-barcode-scan--empty">
                        <img src={Background} alt=""/>
                        <span>Chưa có dữ liệu <br/>quét mã</span>
                    </div>
                )}
            </Content>
        );
    }
}

ScanHistory.defaultProps = {
    dataList: []

};

ScanHistory.propTypes = {
    dataList: PropTypes.array,
};

export default withTranslation()(ScanHistory);
