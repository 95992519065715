import React from 'react';
import General from "./components";
import {connect} from 'react-redux';
import {setMenuActive} from "../../Common/actions";
import * as selectors from "../selectors";
import {fetchDetailSetting, setDetail, updateSetting} from "../actions";

const mapStateToProps = (state, props) => ({
	errors: selectors.getErrors(selectors.getState(state, "errors", {})),
	settings: selectors.getState(state, 'detail', {}),
	loading: selectors.getState(state, 'loading', {}),
});
const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
	fetchDetailSetting: () => dispatch(fetchDetailSetting()),
    updateSetting: (params) => dispatch(updateSetting(params)),
    setDetail: data => dispatch(setDetail(data))
});
class SettingGeneralContainer extends React.PureComponent{
    componentDidMount() {
	    this.props.setMenuActive('config/general');
	    this.props.fetchDetailSetting();
    }

    render() {
        return (
            <General {...this.props}/>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingGeneralContainer);
