import moment from 'moment';
import { createAction } from 'redux-actions';

import * as ACTION from './constants';

export const addBarcode = createAction(ACTION.ADD_BARCODE);
export const removeBarcode = createAction(ACTION.REMOVE_BARCODE);
export const scanBarcode = createAction(ACTION.SCAN_BARCODE.REQUEST, (code, params, stage) => ({
    code,
    params,
    stage,
    scanTime: moment().valueOf()
}));
export const setBarcodes = createAction(ACTION.SET_BARCODES, (barcodes) => ({barcodes}));
export const clearState = createAction(ACTION.CLEAR_STATE);
export const updateBarcode = createAction(ACTION.UPDATE_BARCODE);
