import React from 'react';

import withPackageDamagedReasons from './../../../hocs/withPackageDamagedReasons';

import DamagedPackages from './components';

const DamagedPackagesContainer = props => {
    return (
        <DamagedPackages
            {...props}
        />
    );
};

export default withPackageDamagedReasons(DamagedPackagesContainer, {
    getDataWhenMounted: true
});
