import {connect} from 'react-redux';
import CreateWarehouse from "./components"
import {clearState, createWarehouse} from "./actions";
import * as selectors from "./selectors"
import {setMenuActive} from "../../Common/actions";

const mapStateToProps = (state, props) => ({
	loading: selectors.getState(state, 'loading', false)
});
const mapDispatchToProps = (dispatch) => ({
	createWarehouse: (params) => dispatch(createWarehouse(params)),
	clearState: () => dispatch(clearState()),
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateWarehouse);
