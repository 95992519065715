import { notification } from 'antd'
import * as _ from 'lodash'
import { t, trans } from '../../../system/i18n'
import { processApiRequest } from '../../Common/saga'
import * as ACTION from './constants'
import apiService from '../apiService'
import { router, url } from '../../../system/routing'
import scanBarcodeApiService from '../../ScanBarcode/apiService'
import * as SCAN_BARCODE_ACTION from '../../ScanBarcode/ScanHistory/constants'
import React from 'react'
import { dispatch } from '../../../system/store'
import { fetchBags } from '../ListBag/actions'
import queryString from 'query-string'

export function* fetchBagDetail(action) {
    yield processApiRequest(ACTION.FETCH_BAG_DETAIL, () => apiService.getBag(action.payload.id), action.payload)
}

export function* onFetchBagDetailFailed(action) {
    const code = _.get(action, 'payload.code', '');
    if (code === 'not_is_staff') {
        setTimeout(() => {
            router.redirect('/403')
        }, 500)
        return yield notification.error({ message: t('bag:message.not_is_staff') })
    } else {
        if (action.payload) {
            let error = _.get(action, 'payload.data')
            if (_.get(error, 'warehouse')) {
                return yield notification['error']({
                    message: <span className={'_scan_package_failed'}>{t('message.warehouse_not_is_staff')}</span>,
                })
            }
            yield notification.error({ message: t('bag:detail.message_cant_load_bag') })
        }
    }
}

export function* detachPackage(action) {
    yield processApiRequest(ACTION.DETACH_PACKAGE, () => apiService.detachPackage(action.payload.bagId, action.payload.packageId), action.payload)
}

export function* onDetachPackageSuccess(action) {
    if (action.payload) {
        yield notification.success({ message: t('bag:message.delete_success') })
    }
}

export function* onDetachPackageFailed(action) {
    if (action.payload) {
        let error = _.get(action, 'payload.data')
        if (_.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={'_scan_package_failed'}>{t('message.warehouse_not_is_staff')}</span>,
            })
        }
        yield notification.error({ message: t('bag:message.delete_failed') })
    }
}

export function* removePackageSuccess(action) {
    yield processApiRequest(ACTION.FETCH_BAG_DETAIL, () => apiService.getBag(action.request.bagId), {})
}

export function* createSimilarBagRequest(action) {
    yield processApiRequest(ACTION.CREATE_SIMILAR_BAG, () => apiService.createSimilarBag(action.payload.id), {})
}

export function* createSimilarBagSuccess(action) {
    if (action.payload) {
        yield notification.success({ message: t('bag:message.create_similar_bag_success') })
        yield router.redirect('/bags/detail/' + action.payload.bag.code)
    }
}

export function* createSimilarBagFailed(action) {
    if (action.payload) {
        let error = _.get(action, 'payload.data')
        if (_.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={'_scan_package_failed'}>{t('message.warehouse_not_is_staff')}</span>,
            })
        }
        yield notification.error({ message: t('bag:message.create_similar_bag_failed') })
    }
}

export function* createTemplateBagRequest(action) {
    yield processApiRequest(ACTION.CREATE_TEMPLATE_BAG, () => apiService.createTemplateBag(action.payload), { ...action.payload })
}

export function* createTemplateBagSuccess(action) {
    if (action.payload) {
        yield notification.success({ message: t('bag:message.create_template_bag_success') })
    }
}

export function* createTemplateBagFailed(action) {
    if (action.payload) {
        let error = _.get(action, 'payload.data')
        if (_.get(error, 'warehouse')) {
            return yield notification['error']({
                message: <span className={'_scan_package_failed'}>{t('message.warehouse_not_is_staff')}</span>,
            })
        }
        if (_.has(error, 'id_bag.not_is_staff')) {
            return yield notification['error']({
                message: <span className={'_create_template_bag_failed'}>{t('message.user_not_is_staff')}</span>,
            })
        }
        yield notification.error({ message: t('bag:message.create_template_bag_failed') })
    }
}

export function* updateBag(action) {
    yield processApiRequest(ACTION.UPDATE_BAG, () => apiService.updateBag(action.payload.id, action.payload.data), action.payload)
}

export function* updateBagSuccess(action) {
    if (action.request.onCallback) {
        yield action.request.onCallback(true)
    }

    yield notification.success({
        message: trans('message.update_success'),
    })
}

export function* updateBagFailed(action) {
    const error = _.get(action, 'payload.data')

    if (_.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className="_update-bag-failed">{t('message.warehouse_not_is_staff')}</span>,
        })
    }

    if (action.request.onCallback) {
        yield action.request.onCallback(false)
    }
}

export function* exportBag(action) {
    yield processApiRequest(ACTION.EXPORT_BAG, () => scanBarcodeApiService.scanBarcode(action.payload), action.payload)
}

export function* exportBagFailed(action) {
    const error = _.get(action, 'payload.data')

    if (_.get(error, 'warehouse')) {
        return yield notification.error({
            message: t('message.warehouse_not_is_staff'),
        })
    }

    let message = t('bag:message.export_bag_failed')
    let errorWarehouse = _.get(action, 'payload.data.id_warehouse_scan', {})

    if (_.has(errorWarehouse, 'exists')) {
        message = t('scan:warning.warehouse_not_exist')
    }

    if (_.has(error, 'barcode.scan_uncompleted_packing_bag')) {
        message = t('bag:export_bag.errors.barcode.scan_uncompleted_packing_bag')
    }

    yield notification.error({
        message: message,
    })
}

export function* exportBagSuccess(action) {
    const barcodeScanning = _.get(action, 'payload.barcode_scanning', {}) || {}
    const code_error = barcodeScanning.code_error || null
    let message_warning
    if (Array.isArray(code_error) && code_error.length > 0) {
        const error = _.first(code_error)
        const code_warehouse_scan = barcodeScanning.code_warehouse_scan
        switch (error.code) {
            case SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_NOT_VALID:
                message_warning = trans('scan:error.ERROR_SCAN_BAG_NOT_VALID')
                break
            case SCAN_BARCODE_ACTION.ERROR_WAREHOUSE_NOT_EXIST:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_WAREHOUSE_NOT_EXIST.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_BARCODE_NOT_EXIST:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_BARCODE_NOT_EXIST.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE.toLowerCase(), {
                    warehouse: code_warehouse_scan,
                })
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_MULTIPLE_COMPLETED:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_MULTIPLE_COMPLETED.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_NOT_VALID:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_PACKAGE_NOT_VALID.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_PACKING:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_PACKING.toLowerCase())
                break
            case SCAN_BARCODE_ACTION.ERROR_SCAN_BAG:
                message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_BAG.toLowerCase())

                if (_.get(error, 'bag.status_warehouse') === 'OUT') {
                    message_warning = trans('scan:warning.' + SCAN_BARCODE_ACTION.ERROR_SCAN_BAG_STATUS_OUT.toLowerCase(), {
                        warehouse: <b>{_.get(error, 'bag.warehouse_current')}</b>,
                    })
                }
                break
            default:
                message_warning = trans('scan:error.barcode_invalid')
                break
        }

        yield notification.error({
            message: message_warning,
        })
    } else {
        yield notification.success({
            message: t('bag:message.export_bag_success'),
        })
        if (_.get(url, 'router.currentRoute.name') === 'bags.list_bag_pack' || _.get(url, 'router.currentRoute.name') === 'bags.list') {
            let parse = queryString.parse(url.location().search) || {}
            return yield dispatch(fetchBags(parse))
        }

        yield router.redirect('/bags/list-pack?packing_complete=0')
    }
}
