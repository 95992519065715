import React from 'react';
import { get, has } from 'lodash';

import bagApiService from './../apiService';
import { setBagDetail } from './../BagDetail/actions';
import { dispatch } from './../../../system/store';

import Page404 from './../../Common/components/404';
import Page403 from './../../Common/components/403';
import {notification} from "antd";
import {t} from "../../../system/i18n";

export default (payload, next) => {
    const id = get(payload, 'request.match.params.id');

    bagApiService.getBag(id)
        .then(response => {
            dispatch(setBagDetail(response.data));

            return next(payload);
        })
        .catch(error => {
            const code = get(error, "response.data.code");
            if (has(error, 'response.data.data.code.exists')) {
                return payload.response.render(<Page404 />);
            }
            if (code === "not_is_staff") {
                notification.error({ message: t('bag:message.not_is_staff') })
                return payload.response.render(<Page403 />);
            }
            return next(payload);
        });
}
