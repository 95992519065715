import React, { Component } from 'react';
import Tabs from 'antd/es/tabs';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { NOTE_TYPES } from './../../constants';
import authSerice from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import pageService from './../../../Common/Page/pageService';

import ListCustomer from './../../ListCustomer/ListCustomerContainer';
import ListOrder from './../../ListOrder/ListOrderContainer';
import ListPackage from './../../ListPackage/ListPackageContainer';
import ListTracking from './../../ListTracking/ListTrackingContainer';
import authService from './../../../Auth/authService';

const { TabPane } = Tabs;

class NotesPage extends Component {
    onChangeTab = tab => {
        const filter = {};
        filter.object = tab;
        this.props.onSearch(filter);

        pageService.setTitle(this.props.t(`note:label.list_note_${tab.toLowerCase()}`));
    };

    render() {
        const { filter, t } = this.props;

        return (
            <Tabs
                className="a-tabs a-tabs--container"
                defaultActiveKey={filter.object}
                onChange={this.onChangeTab}
            >
                {authSerice.can(permissions.ATTENTION_MANAGE) && (
                    <TabPane tab={t('note:label.order')} key={NOTE_TYPES.ORDER}>
                        <ListOrder {...this.props} object={NOTE_TYPES.ORDER} />
                    </TabPane>
                )}
                {authSerice.can(permissions.ATTENTION_MANAGE) && (
                    <TabPane tab={t('note:label.tracking')} key={NOTE_TYPES.TRACKING}>
                        <ListTracking  {...this.props} object={NOTE_TYPES.TRACKING} />
                    </TabPane>
                )}
                {authSerice.can(permissions.ATTENTION_MANAGE) && (
                    <TabPane tab={t('note:label.package')} key={NOTE_TYPES.PACKAGE}>
                        <ListPackage {...this.props} object={NOTE_TYPES.PACKAGE} />
                    </TabPane>
                )}
                {authService.can(permissions.ATTENTION_CUSTOMER_MANAGE) && (
                    <TabPane tab={t('customer')} key={NOTE_TYPES.CUSTOMER}>
                        <ListCustomer {...this.props} object={NOTE_TYPES.CUSTOMER} />
                    </TabPane>
                )}
            </Tabs>
        );
    }
}

NotesPage.defaultProps = {
    loading: false,
    filter: {},
};

NotesPage.propTypes = {
    filter: PropTypes.object,
    onReset: PropTypes.func,
    setMenuActive: PropTypes.func,
    onSearch: PropTypes.func,
};
export default withTranslation()(NotesPage)
