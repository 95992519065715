export const ELEMENT = {
    BARCODE: 'barcode',
    PACKAGE_ORDER_CODE: 'order_code',
    PACKAGE_NET_WEIGHT: 'net_weight',
    PACKAGE_LENGTH: 'length',
    PACKAGE_WIDTH: 'width',
    PACKAGE_HEIGHT: 'height',
    PACKAGE_BTN_PRINT_BARCODE: 'btn_print_barcode',
    PACKAGE_WEIGHT_BOX: 'weight_box',
    PACKAGE_NOTE: 'note'
};
