import lodash from 'lodash';
import PERMISSION from '../Auth/permissions';
import authService from '../Auth/authService';
import {DELIVERY_OBJECT} from './constants';

class DeliveryService {
    /**
     * Kiểm tra user hiện tại có quyền tạo phiếu xuất theo các đối tượng hay không
     *
     * @param {String|Array} objects
     * @return {boolean}
     */
    canCrete(objects) {
        return this.checkObjectPermissions(objects, {
            [DELIVERY_OBJECT.CUSTOMER]: PERMISSION.DELIVERY_NOTE_CREATE_FOR_CUSTOMER
        });
    }

    /**
     * Kiểm tra user hiện tại có quyền tạo phiếu xuất theo các đối tượng hay không
     *
     * @param {String|Array} objects
     * @return {boolean}
     */
    canUpdate(objects) {
        return this.checkObjectPermissions(objects, {
            [DELIVERY_OBJECT.CUSTOMER]: PERMISSION.DELIVERY_NOTE_UPDATE_FOR_CUSTOMER
        });
    }

    /**
     * Kiểm tra permission theo objects
     *
     * @param {String|Array} objects
     * @param {Array} objectPermissions
     * @return {boolean}
     */
    checkObjectPermissions(objects, objectPermissions) {
        objects = lodash.isArray(objects) ? objects : [objects];

        return !!objects.filter(object => objectPermissions[object] && authService.can(objectPermissions[object])).length;
    }
}

let deliveryService = new DeliveryService();

export default deliveryService;