import React, { Component } from 'react';
import { Button, Checkbox, Dropdown, Icon, Menu } from 'antd';
import { withTranslation } from 'react-i18next';

class ConfigTableColumn extends Component {
    state = {
        isOpen: false
    };

    onChangeOption = (optionKey, event) => {
        const { values, onChange } = this.props;
        const filteredValues = event.target.checked ? [
            ...values,
            optionKey
        ] : values.filter(value => value !== optionKey);

        onChange(filteredValues);
    };

    onToggleSetting = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    renderMenu = () => {
        const { options, values } = this.props;

        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={this.onToggleSetting} type="close"/>
                </div>
                {options.filter(option => option.key !== 'action').map(option => (
                    <Menu.Item
                        key={option.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            className="a-checkbox--set-field"
                            checked={values.includes(option.key)}
                            disabled={option.disabled}
                            onChange={this.onChangeOption.bind(this, option.key)}
                        >{option.title}</Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    render() {
        const { t } = this.props;

        return (
            <Dropdown
                visible={this.state.isOpen}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field"
            >
                <Button
                    onClick={this.onToggleSetting}
                    type="primary"
                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                    ghost
                >{t('note:setting_table_column')}</Button>
            </Dropdown>
        );
    }
}

ConfigTableColumn.defaultProps = {
    options: [],
    values: [],
    onChange: () => {
    },
    isOpen: false
};


export default withTranslation()(ConfigTableColumn);
