import React from 'react';
import { Col, Form, Layout, Row } from 'antd/lib/index';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import EmptyPage from './EmptyPage';
import FormTransportWarehouse from './FormTransportWarehouse';
import FormSelectShippingPartner from './../../../FormSelectShippingPartner/FormSelectShippingPartnerContainer';

const { Content } = Layout;

const FormTransportWarehouseContainer = ({ bags, packages, deliveryNote, input, loading, t, clearBarcodes, removeBarcode }) => {
    if (input.id_warehouse) {
        if (isNil(input.id_shipping_partner)) {
            return <Content className={"a-content a-barcode-scan"}>
                <Form className="a-block">
                    <div className="block--primary a-content--bag">
                        <div className="title create-bag">
                            <div className="left">
                                <span className="a-text--bold">{t('delivery_note:label.title_create_page')}</span>
                            </div>
                        </div>
                        <div className="body not-boxshadow ">
                            <Row gutter={{lg: 24, xl: 48}}>
                                <Col lg={{span: 24}}>
                                    <div className="a-block-info block--export-scan">
                                        <FormSelectShippingPartner
                                            defaultOptionLabel={t('delivery_note:transport_warehouse.default_shipping_partner_option')}
                                            hasDefaultOption
                                            input={input}
                                            loading={loading}
                                            routeName="delivery-notes.transport-warehouse.create"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
            </Content>
        }

        return (
            <FormTransportWarehouse
                deliveryNote={deliveryNote}
                input={input}
                loading={loading}
                bags={bags}
                packages={packages}
                clearBarcodes={clearBarcodes}
                removeBarcode={removeBarcode}
            />
        );
    }

    return (
        <EmptyPage />
    );
};

FormTransportWarehouseContainer.defaultProps = {
    input: {},
    deliveryNote: null,
};

FormTransportWarehouseContainer.propTypes = {
    input: PropTypes.object,
    deliveryNote: PropTypes.any,
};

export default withTranslation()(FormTransportWarehouseContainer);
