import lodash from 'lodash';
import { ELEMENT } from './constants';
import { isUnlinkOrder } from './../../../helper';

export default class InputFocusDetector {
    /**
     * @param {InputFocusParser} parser
     */
    constructor(parser) {
        this.parser = parser;
    }

    /**
     * Xác định input sẽ focus từ danh sách kiện
     *
     * @param {array} packages
     * @param {array} orderSuggests
     * @return {string}
     */
    detect(packages, orderSuggests = []) {
        if (lodash.isEmpty(packages)) {
            return ELEMENT.BARCODE;
        }

        let activePackage = lodash.first(packages);

        return this.parser.make(activePackage.id, this.detectForPackage(activePackage, orderSuggests));
    }

    /**
     * Xác định input focus từ thông tin kiện
     *
     * @param {object} packageData
     * @param {array} orderSuggests
     * @return {string}
     */
    detectForPackage(packageData, orderSuggests = []) {
        if (isUnlinkOrder(packageData) && orderSuggests && orderSuggests.length) {
            return ELEMENT.PACKAGE_ORDER_CODE;
        }

        if (!packageData.weight_net || !packageData.status || !orderSuggests.length) {
            return ELEMENT.PACKAGE_NET_WEIGHT;
        }

        return ELEMENT.PACKAGE_BTN_PRINT_BARCODE;
    }
}
