import React from 'react'
import { get } from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Search from './components/Search'
import List from './components/List'
import SearchableComponent from '../../Common/components/SearchableComponent'
import * as commonAction from '../../Common/actions'
import moment from 'moment'
import apiService from '../apiService'
import { notification } from 'antd'

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
})

const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
const sixMonthsAgo = moment(currentDate).subtract(2, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss')

class ReturnGoodsList extends SearchableComponent {
    state = {
        loading: false,
        data: [],
        pagination: {},
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    getDefaultFilter() {
        return {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            'created_at[from]': sixMonthsAgo,
        }
    }

    getCurrentFilter() {
        const { location } = this.props
        const filter = {
            ...this.getDefaultFilter(),
            ...this.getFilterFromLocation(location),
        }
        return filter
    }

    onChangeFilter(filter) {
        if (!get(filter, 'created_at[from]')) {
            filter['created_at[from]'] = sixMonthsAgo
        }

        this.fetchReturnGoodsList(filter)
    }

    fetchReturnGoodsList(filter) {
        const { t } = this.props
        this.setState({
            loading: true,
        })
        apiService
            .getReturnGoodsList(filter)
            .then(res => {
                const data = get(res, 'data.data', [])
                const pagination = get(res, 'data.meta.pagination', {})
                this.setState({
                    data,
                    pagination,
                })
            })
            .catch(e => {
                this.setState({
                    data: [],
                    pagination: {},
                })
                notification.error({ message: t('message.server_error') })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const defaultFilter = this.getDefaultFilter()
        const currentFilter = this.getCurrentFilter()
        return (
            <div>
                <Search
                    loading={this.state.loading}
                    defaultFilter={defaultFilter}
                    filter={currentFilter}
                    onSearch={params => this.onSearch(params)}
                />
                <List
                    {...this.props}
                    loading={this.state.loading}
                    data={this.state.data}
                    pagination={this.state.pagination}
                    currentFilter={currentFilter}
                    onSearch={params => this.onSearch(params)}
                />
            </div>
        )
    }
}
export default withTranslation()(connect(undefined, mapDispatchToProps)(ReturnGoodsList))
