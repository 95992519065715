import { currentFormatter, dateFormatter } from './../../Common/services/format';
import { t } from './../../../system/i18n';
import { getVar } from './../../../system/support/helpers';

export const renderColumn = (column, data) => {
    switch (column) {
        case 'agency': {
            return getVar(data, 'agency.name', '--');
        }
        case 'unit_price': {
            return getVar(data, 'storage_fee.unit_price') ? `${currentFormatter.toLocaleStringCurrency(data.storage_fee.unit_price)} VNĐ` : '--';
        }
        case 'weight_by': {
            return getVar(data, 'storage_fee.weight_by') ? t(`weight_by_options.${data.storage_fee.weight_by}`) : '--';
        }
        case 'applied_at': {
            return getVar(data, 'storage_fee.applied_at') ? dateFormatter.full(data.storage_fee.applied_at) : '--'
        }
        case 'day_free': {
            return getVar(data, 'storage_fee.day_free', '--')
        }
        default: {
            return '--';
        }
    }
};
