import lodash from 'lodash'
import { LASTMILE_ORDER_COLUMN } from '../../constants'

const SETTING_OPTIONS = [
    {
        key: LASTMILE_ORDER_COLUMN.TRACKING_NO,
        selected: true,
        disabled: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.PACKAGE_CODE,
        selected: true,
        disabled: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.DELIVERY_REQUEST_CODE,
        selected: true,
        disabled: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.DELIVERY_NOTE_CODE,
        selected: true,
        disabled: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.CUSTOMER_USERNAME,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.ID_LAST_MILE_CARRIER,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.CREATE_AT,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.EXPORTED_AT,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.DELIVERED_AT,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.RETURNED_AT,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.STATUS,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.ID_AGENCY,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.ID_WAREHOUSE,
        selected: true,
    },
    {
        key: LASTMILE_ORDER_COLUMN.ID_CREATOR,
        selected: true,
    },
]

class SettingService {
    constructor(options) {
        this.options = options
    }

    getSetting() {
        let setting = localStorage.getItem('lastmile_order_list_setting')
        return setting ? JSON.parse(setting) : {}
    }

    updateSetting(setting) {
        localStorage.setItem('lastmile_order_list_setting', JSON.stringify(setting))
    }

    getOption() {
        let options = []
        lodash.each(SETTING_OPTIONS, option => {
            options.push(option)
        })
        this.options = options
    }

    getPageSetting(userId) {
        let defaultSetting = lodash.get(this.getSetting(), userId, this.getDefaultSetting())
        let settings = []
        lodash.each(SETTING_OPTIONS, setting => {
            if (defaultSetting.includes(setting.key)) {
                settings.push(setting.key)
            }
        })
        return settings
    }

    getDefaultSetting() {
        return this.options.filter(option => option.selected).map(option => option.key)
    }

    getUserSetting(userId) {
        this.getOption()
        return this.getPageSetting(userId)
    }

    updateUserSetting(userId, setting) {
        this.getOption()
        this.updateSetting({ ...this.getSetting(), [userId]: setting })
    }
}

let settingService = new SettingService(SETTING_OPTIONS)

export default settingService
