import {takeLatest} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';

export default function* () {
	yield takeLatest(ACTION.CHECKING_INVENTORY.REQUEST, saga.checkingInventoryRequest);
	yield takeLatest(ACTION.CHECKING_INVENTORY.SUCCESS, saga.checkingInventorySuccess);
	yield takeLatest(ACTION.CHECKING_INVENTORY.FAILED, saga.checkingInventoryFailed);
	yield takeLatest(ACTION.COMPARING_TRACKING_BILL.REQUEST, saga.comparingTrackingBill);
	yield takeLatest(ACTION.COMPARING_TRACKING_BILL.SUCCESS, saga.comparingTrackingBillSuccess);
	yield takeLatest(ACTION.COMPARING_TRACKING_BILL.FAILED, saga.comparingTrackingBillFailed);
}
