import { api } from '../../system/api'

export default {
    getListWarehouse: params => api.get('warehouses/suggest', { params }),
    fetchSuggestWarehouses: params => api.get('warehouses/suggest', { params }),
    scanToStowing: (params) => api.get(`/warehouses/shelves/scan-to-stowing`, { params }),
    confirmStowing: (warehouseId, warehouseAreaId, data) => api.post(`warehouses/${warehouseId}/shelves/${warehouseAreaId}/stowing`, data),
    getListPackageInWarehouseArea: (params) => api.get(`customers/packages-in-warehouse-shelf`, {params}),
    exportListPackageInWarehouseArea: (params) => api.get(`customers/export-packages-in-warehouse-shelf`, { params, responseType: 'blob', })
}
