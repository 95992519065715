import React, { useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useDeepCompareEffect } from './../../../../hooks';

const { Option } = Select;

const ShippingReturnPartnersSelect = ({ className, dropdownClassName, shippingReturnPartners, value, ...other }) => {
    const [formattedValue, setFormattedValue] = useState();

    useDeepCompareEffect(() => {
        let formattedValue = value && Number(value);
        const index = shippingReturnPartners.findIndex(shippingReturnPartner => shippingReturnPartner.shipping_return_partner.id === formattedValue);
    
        if (index === -1) {
            formattedValue = undefined;
        }

        setFormattedValue(formattedValue);
    }, [shippingReturnPartners, value]);

    return (
        <Select
            {...other}
            className={clsx(className, '_shipping-return-partner-select')}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-shipping-return-partner')}
            value={formattedValue}
        >
            {shippingReturnPartners.map(shippingReturnPartner => (
                <Option
                    className="_shipping-return-partner-item"
                    key={shippingReturnPartner.shipping_return_partner.id}
                    value={shippingReturnPartner.shipping_return_partner.id}
                >
                    {shippingReturnPartner.shipping_return_partner.name}
                </Option>
            ))}
        </Select>
    );
}

ShippingReturnPartnersSelect.propTypes = {
    shippingReturnPartners: PropTypes.array
};

ShippingReturnPartnersSelect.defaultProps = {
    shippingReturnPartners: []
};

export default ShippingReturnPartnersSelect;
