import React from 'react'
import { Button, Col, Form, Icon, Layout, Row, Switch, Tabs, Spin} from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import FormWarehouse from '../../FormWarehouse/FormWarehouseContainer'
import { DEFAULT_STATE_FORM_INPUT_WAREHOUSE } from '../../constants'
import { router } from '../../../../system/routing'
import * as lodash from 'lodash'
import ListStaffInWarehouse from '../../ListStaffInWarehouse/ListStaffInWarehouseContainer'
import ListWarehouseArea from '../../WarehouseArea/List'

const Content = Layout.Content
const { TabPane } = Tabs

class UpdateWarehouse extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...DEFAULT_STATE_FORM_INPUT_WAREHOUSE, active: true, id_warehouse: 0 }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = { ...state }
        let id_warehouse = !lodash.isNil(props.warehouseData) ? lodash.get(props, 'warehouseData.id', 0) : 0
        if (state.id_warehouse !== id_warehouse && id_warehouse !== 0) {
            nextState = lodash.isNil(props.warehouseData)
                ? { ...DEFAULT_STATE_FORM_INPUT_WAREHOUSE }
                : {
                      name: lodash.get(props, 'warehouseData.name', ''),
                      code: lodash.get(props, 'warehouseData.code', ''),
                      phone: lodash.get(props, 'warehouseData.phone', ''),
                      address: lodash.get(props, 'warehouseData.address', ''),
                      note: lodash.get(props, 'warehouseData.note', ''),
                      active: lodash.get(props, 'warehouseData.active', true),
                      id_location: lodash.get(props, 'warehouseData.id_location'),
                  }
            nextState.id_warehouse = id_warehouse
        }

        return nextState
    }

    onUpdateWarehouse = () => {
        let data = this.state
        data.active = this.state.active ? 1 : 0
        this.props.updateWarehouse(data)
    }
    onSubmit = value => {
        this.setState({
            ...this.state,
            ...value,
        })
    }
    onChecker = e => {
        this.setState({ active: e })
    }
    onBackConfigListWarehouse = () => {
        router.redirect('/config/warehouse')
    }

    render() {
        const { t, loading } = this.props
        return (
            <Content className={'aml-16-lg aml-24 amr-24 amr-16-lg a-content-config '}>
                <Spin spinning={loading}>
                    <div className="a-breadcrumb">
                        <a
                            href="#top"
                            className={'a-text--gray _btn-back'}
                            onClick={this.onBackConfigListWarehouse}>
                            <Icon
                                type="arrow-left"
                                className={'mr-2 '}
                            />
                            {t('config:btn.back_config_list_warehouse')}
                        </a>
                    </div>
                    <div className="a-block-config amt-16">
                        <div className="a-block-config__title gflex gjustify-space-between">
                            <h3 className="title">{t('config:label.information_warehouse')}</h3>
                            {!loading && (
                                <div>
                                    <label className={'a-text--fz-12 a-text--gray mr-2'}>{t('config:label.status')}</label>

                                    <Switch
                                        defaultChecked={this.state.active}
                                        size="small"
                                        onChange={this.onChecker}
                                        disabled={loading}
                                        loading={loading}
                                        className={'_switch-active'}
                                        checker={this.state.active}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="a-block-config__body">
                            <Form className="a-form ">
                                <FormWarehouse
                                    onSubmit={this.onSubmit}
                                    errors={this.props.errors}
                                />
                                <Row gutter={48}>
                                    <Col
                                        lg={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        className="a-package--action only-action">
                                        <a
                                            className="a-btn a-btn--back-package _btn-cancel"
                                            href={'#top'}
                                            onClick={this.onBackConfigListWarehouse}>
                                            {t('btn.cancel')}{' '}
                                        </a>
                                        <Button
                                            type="primary"
                                            onClick={this.onUpdateWarehouse}
                                            disabled={loading}
                                            loading={loading}
                                            className="a-btn a-btn--primary a-btn--save-package primary-special _btn-save-update-warehouse">
                                            {t('btn.save')}{' '}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>

                    <Tabs>
                        <TabPane
                            tab="Danh sách nhân viên"
                            key="1">
                            <ListStaffInWarehouse />
                        </TabPane>
                        <TabPane
                            tab="Danh sách vị trí kho"
                            key="2">
                            <ListWarehouseArea />
                        </TabPane>
                    </Tabs>
                </Spin>
            </Content>
        )
    }
}

UpdateWarehouse.defaultProps = {
    loading: false,
    warehouseData: {},
}

UpdateWarehouse.propTypes = {
    warehouseData: PropTypes.object,
    loading: PropTypes.bool,
}
export default withTranslation()(UpdateWarehouse)
