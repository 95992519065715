import PERMISSION from './../../Auth/permissions';

export const MENU_CONFIG = [
    {permission: PERMISSION.WAREHOUSES_MANAGE, path: '/config/warehouse'},
    {permission: PERMISSION.WAREHOUSES_MANAGE_STAFF, path: '/config/warehouse-staff'},
    {permission: [PERMISSION.DELIVERY_PARTNER_MANAGE, PERMISSION.DOMESTIC_DELIVERY_PARTNER_MANAGE], path: '/config/shipping-partners'},
    {permission: PERMISSION.DELIVERY_AGENT_MANAGE, path: '/config/shipping-users'},
    {permission: PERMISSION.SHIPPING_FEE_MANAGE, path: '/config/shipping-fees'},
    {permission: PERMISSION.STORAGE_FEE_MANAGE, path: '/config/storage-fees'},
    {permission: PERMISSION.CONFIG_SAMPLE_BAG, path: '/config/template-bags'},
    {permission: PERMISSION.CONFIG_BAG_SIZE, path: '/config/bag-sizes'},
    {permission: PERMISSION.BAG_AREA_MANAGE, path: '/config/bag-areas'},
    {permission: PERMISSION.PROPERTIES_MANAGE, path: '/config/property'},
    {permission: PERMISSION.NOTICE_STAGE_MANAGE, path: '/config/notice-stages'},
    {permission: PERMISSION.CONFIG_SERVICE, path: '/config/service'},
    {permission: PERMISSION.CONFIG_GENERAL, path: '/config/general'}
];