import React, { PureComponent } from 'react';
import { Button, notification } from 'antd';
import lodash from 'lodash';

import api from './../../apiService';
import { t } from './../../../../system/i18n';
import { convertBlobDataToExcel } from './../../../../system/support/helpers';

class ExportExcel extends PureComponent {
    state = {
        loading: false
    };

    onExportExcel = async () => {
        const code = lodash.get(this.props, 'bag.code', '');

        this.setState({
            loading: true
        });

        try {
            const { data } = await api.exportPackages(code);

            convertBlobDataToExcel(data, `export_packages_${code}`);
        } catch (e) {
            notification.error({
                message: t('bag:message.error_export_csv_package_in_bag')
            });
        }

        this.setState({
            loading: false
        });
    };

    render() {
        return (
            <Button
                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-2 _btn-export-excel"
                disabled={this.state.loading}
                ghost
                loading={this.state.loading}
                type="primary"
                onClick={this.onExportExcel}
            >
                {t('btn.export_excel')}
            </Button>
        );
    }
}

export default ExportExcel;
