import React, {Component} from 'react';
import CommonLocation from "./components";
import * as actions from "./actions";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
    listCountry: [...state.location.commonList.listCountry],
    listProvince: [...state.location.commonList.listProvince],
    listDistrict: [...state.location.commonList.listDistrict],
    listWard: [...state.location.commonList.listWard],
    currentCountry: state.location.commonList.currentCountry,
    loadingCountry: state.location.commonList.loadingCountry,
    loadingProvince: state.location.commonList.loadingProvince,
    loadingDistrict: state.location.commonList.loadingDistrict
});

const mapDispatchToProps = (dispatch) => ({
    fetchLocation: (id, type) => dispatch(actions.fetchLocation(id, type)),
    fetchCountries: (id, type, autoloadChildren) => dispatch(actions.fetchCountries(id, type, autoloadChildren)),
    fetchProvinces: (id, type) => dispatch(actions.fetchProvinces(id, type)),
    fetchDistricts: (id, type) => dispatch(actions.fetchDistricts(id, type)),
    fetchWards: (id, type) => dispatch(actions.fetchWards(id, type)),
    changeCurrentCountry: (id) => dispatch(actions.changeCurrentCountry(id)),
});

class CommonLocationContainer extends Component {
    render() {
        return(
            <CommonLocation {...this.props}/>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonLocationContainer)
