import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import lodash from "lodash";
import React from "react";

export function* updateWeightPackage(action) {
    yield processApiRequest(
        ACTION.UPDATE_WEIGHT,
        () => apiService.updateWeightPackage(action.payload.params),
        action.payload
    );
}


export function* updateWeightPackageSuccess() {
    yield  notification['success']({
        message: t('package:message.update_weight_success'),
    });
}

export function* updateWeightPackageFailed(action) {
    let error = lodash.get(action, 'payload.data');
    if (lodash.get(error, 'warehouse')) {
        yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}
