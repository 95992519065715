import React, { memo } from 'react';
import { Icon } from 'antd/lib/index';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import Link from './../../../../../system/routing/Link';
import { getVar } from './../../../../../system/support/helpers';

const WarningItem = ({ index, item, t }) => (
	<tbody key={index} className="warning">
		<tr className="data">
			<td>{index + 1}</td>
			<td>
				<Link
					params={{id: item.inventory_package.code_package}}
					to="packages.detail"
				>
					{item.inventory_package.code_package}
				</Link>
			</td>
			<td>{getVar(item, 'inventory_package.created_at', '--') === '--' ? '--' : dateFormatter.dateTime(item.inventory_package.created_at)} </td>
			<td>{getVar(item, 'inventory_package.code_warehouse', '--')}</td>
			<td>
				{getVar(item, 'destination_warehouse.name', '--')}
			</td>
		</tr>
		<tr className="message">
			<td colSpan="5">
				<div className="a-text--black-45 gflex">
					<Icon type='info-circle' theme="filled" className="a-text--orange mr-2" />
					<div>
						{getVar(item, 'inventory_package.situation') === 'remainder' && (
							<p className="mb-0">
								{t(`inventory:warning.${getVar(item, 'inventory_package.situation')}`, {
									type: t('inventory:types.package')
								})}
							</p>
						)}
						{getVar(item, 'notices.text.length') ? (
							<>
								{item.notices.text.map(notice => (
									<p className="mb-0" key={notice}>
										{t(`notice:${notice}`)}
									</p>
								))}
								{item.notices.text.map(notice => notice === 'storage_too_long' && (
									<p className="mb-0" key={notice}>
										{t('inventory:storage_days', {
											days: getVar(item, 'storage_days', 0)
										})}
									</p>
								))}
							</>
						) : null}
					</div>
				</div>
			</td>
		</tr>
	</tbody>
);

export default memo(withTranslation()(WarningItem));
