import React from 'react';
import {Layout} from "antd";

import Menu from './menus';
import Warehouse from './components/partner/DetailPartner';
const Content = Layout.Content;
class Config extends React.Component {
    render() {
        return (
            <Content className={'a-flex aflex-direction-row a-flex-1 min-height-not-header'}>
                <Menu/>
                <Warehouse/>
            </Content>
        )
    }
}

export default Config;