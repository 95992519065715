import React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { currentFormatter } from './../../../Common/services/format';

const { TextArea } = Input;

const ShippingReturnPartnerFormInput = ({ deliveryNoteReturn, form, t }) => {
    const { getFieldDecorator } = form;

    return (
        <>
            <Row className="a-form mx-0" gutter={{lg: 24}}>
                <Col className="a-form--group" lg={{span: 8}}>
                    <Form.Item
                        className="a-form__item"
                        label={t('delivery_note:label.tracking_number')}
                        labelCol={{span: 24}}
                    >
                        {getFieldDecorator('partner_tracking_number', {
                            initialValue: get(deliveryNoteReturn, 'delivery_note_return.partner_tracking_number')
                        })(
                            <Input
                                className="a-input _partner-tracking-number"
                                name="partner_tracking_number"
                                placeholder={t('delivery_note:placeholder.tracking_number')}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col className="a-form--group" lg={{span: 8}}>
                    <Form.Item
                        className="a-form__item"
                        label={t('delivery_note:return.fee')}
                        labelCol={{span: 24}}
                    >
                        {getFieldDecorator('return_fee', {
                            initialValue: get(deliveryNoteReturn, 'delivery_note_return.return_fee')
                        })(
                            <InputNumber
                                className="a-input width-100-pc _return-fee"
                                formatter={value => currentFormatter.toLocaleStringCurrency(value)}
                                name="return_fee"
                                placeholder={t('delivery_note:return.input_fee')}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col className="a-form--group mb-0" span={24}>
                    <Form.Item
                        className="a-form__item"
                        label={t('label.note')}
                        labelCol={{span: 24}}
                    >
                        {getFieldDecorator('note', {
                            initialValue: get(deliveryNoteReturn, 'delivery_note_return.note')
                        })(
                            <TextArea
                                autosize={{
                                    minRows: 1,
                                    maxRows: 3
                                }}
                                className="a-input--textarea _note"
                                name="note"
                                placeholder={t('placeholder.note')}
                                spellCheck={false}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default withTranslation()(ShippingReturnPartnerFormInput);
