import React from 'react';
import { Icon } from 'antd';
import clsx from 'clsx';
import { get, map, isEmpty, round } from 'lodash';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import { Link } from './../../../../../system/routing';

const ItemDeliveryNoteExport = (props) => {
    const {data, t, deliveryNote} = props;
    const isPackage = !isEmpty(get(data, 'package', {}))
    const messages = data.error || data.warning || data.warnings;
    const dataDetail =  isPackage ?  get(data, 'package'): get(data, 'bag') ;
    const linkTo = isPackage ? "packages.detail" : "bags.detail";
    const status = isPackage ? get(dataDetail, 'status_transport') : get(dataDetail, 'status') 
    const bagType =  get(data, "bag.type")
    const barcodeTypeLabel = isPackage ?  t('package') : (bagType ? t(`bag:label.${bagType}`) : "")
    const weight = isPackage ? get(data, 'package.weight_net') : get(data, 'bag.weight_real')
    const volume = isPackage ? get(data, 'package.volume') : get(data, 'bag.volume')
    const createdAt = get(deliveryNote, 'delivery_note_transport_warehouse.created_at')
    const dataTask = get(props, "dataTask", {});
    const errorIds = isPackage ? get(dataTask, "package_error_ids", []) : get(dataTask, "bag_error_ids", [])
    const processedIds = isPackage ? get(dataTask, "package_processed_ids", []) : get(dataTask, "bag_processed_ids", []) ;
    return (
        <tbody className={clsx({
            'error': data.error,
            'warning': !data.error && (data.warning || data.warnings)
        })}>
            <tr className="data">
                <td className="gflex galign-center">
                    <Link
                        isNewTab
                        params={{
                            id: get(dataDetail, 'code')
                        }}
                        to={linkTo}
                    >
                        {get(dataDetail, 'code')}
                    </Link>
                    {
                        !isEmpty(dataTask) && 
                        <div className="ml-2">
                            {processedIds.includes( get(dataDetail, 'id')) ? <Icon type="check-circle" style={{color: "#52c41a"}} /> : (errorIds.includes( get(dataDetail, 'id')) ? <Icon type="close-circle" /> : <Icon type="loading" style={{color: "red"}} /> ) }
                        </div>
                    }
                    
                </td>
                <td>{barcodeTypeLabel}</td>
                <td>
                    {createdAt ? dateFormatter.full(createdAt) : "---"}
                </td>
                <td>
                    {status ? (isPackage ?  t(`package:status_transport.${status}`) : t(`bag:statuses.${status}`)) : '--'}
                </td>
                <td>{weight ? weight + "(kg)": '---'}</td>
                <td>{volume ? round(volume * 1000000, 3) + " (cm3)" : "---"}</td>
            </tr>

            {messages ? (
                <tr className="message">
                    <td colSpan="6">
                        {map(messages, (message, index) => (
                            <p className="mb-2 a-text--black-45" key={index}>
                                <Icon
                                    className={clsx('mr-2', {
                                        'a-text--red': data.error,
                                        'a-text--orange': !data.error && (data.warning || data.warnings)
                                    })}
                                    theme="filled"
                                    type="info-circle"
                                />
                                {t(`scan:warning.${message.code || message}`)}
                            </p>
                        ))}
                    </td>
                </tr>
            ) : null}
        </tbody>
    );
};

export default withTranslation()(ItemDeliveryNoteExport);
