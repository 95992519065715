import {combineReducers} from "redux";
import * as ACTION from '../constants';

export const loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.UPDATE_WAREHOUSE_STAFF.REQUEST:
            return true;
        case ACTION.UPDATE_WAREHOUSE_STAFF.SUCCESS:
        case ACTION.UPDATE_WAREHOUSE_STAFF.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
        loading
    }
)
