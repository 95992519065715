import lodash from 'lodash';
import {BAG_COLUMN} from './constants';
import {BAG_TYPES, TYPES} from "../constants";

const SETTING_OPTIONS = [
    {
        key: BAG_COLUMN.CODE,
        selected: true,
        disabled: true,
        page: [
            "list_bag_pack_" + TYPES.ALL,
            "list_bag_pack_" + TYPES.IN_A_DAY,
            "list_bag_pack_" + TYPES.IN_A_DAY,
            "list_bag_" + TYPES.ALL,
            "list_bag_" + TYPES.MULTIPLE,
            "list_bag_" + TYPES.SINGLE,
            "list_bag_" + TYPES.IN_A_DAY
        ]

    },
    {
        key: BAG_COLUMN.RECEIVER,
        selected: true,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.BAG_TYPE,
        selected: true,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.CUSTOMER,
        selected: true,
        page: ["list_bag_" + TYPES.ALL, "list_bag_pack_" + TYPES.ALL, "list_bag_" + TYPES.SINGLE, "list_bag_pack_" + TYPES.SINGLE, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.AGENCIES,
        selected: true,
        page: [`list_bag_${TYPES.ALL}`, `list_bag_pack_${TYPES.ALL}`, `list_bag_${TYPES.SINGLE}`, `list_bag_pack_${TYPES.SINGLE}`, `list_bag_pack_${TYPES.IN_A_DAY}`, `list_bag_${TYPES.IN_A_DAY}`]
    },
    {
        key: BAG_COLUMN.CREATE_TIME,
        selected: true,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.ID_WAREHOUSE_INPUT,
        selected: true,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },

    {
        key: BAG_COLUMN.ID_WAREHOUSE_DESTINATION,
        selected: true,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.ID_WAREHOUSE_CURRENT,
        selected: true,
        page: ["list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.STATUS_WAREHOUSE,
        selected: true,
        page: ["list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.TRANSPORT_STATUS,
        selected: true,
        page: [`list_bag_${TYPES.ALL}`, `list_bag_${BAG_TYPES.MULTIPLE}`, `list_bag_${BAG_TYPES.SINGLE}`, `list_bag_${TYPES.IN_A_DAY}`]
    },
    {
        key: BAG_COLUMN.SCAN_TIME,
        selected: true,
        page: ["list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.SHIP_NAME,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.PROPERTIES,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.SERVICES,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.TOTAL_PACKAGES,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.TOTAL_PACKAGES_WEIGHT,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.BAG_WEIGHT,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.DIMENSION,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    },
    {
        key: BAG_COLUMN.UNCHECKED,
        selected: false,
        page: ["list_bag_pack_" + TYPES.ALL, "list_bag_pack_" + TYPES.IN_A_DAY, "list_bag_" + TYPES.ALL, "list_bag_" + TYPES.MULTIPLE, "list_bag_" + TYPES.SINGLE, "list_bag_" + TYPES.IN_A_DAY]
    }
];

class SettingSingleService {
    constructor(options) {
        this.options = options;
    }

    getSetting(page) {
        let setting = localStorage.getItem(page + '_setting');
        return setting ? JSON.parse(setting) : {};
    }

    updateSetting(setting, page) {
        localStorage.setItem(page + '_setting', JSON.stringify(setting));
    }

    getOption(page, type) {
        let options = [];
        lodash.each(SETTING_OPTIONS, option => {
            if (option.page.includes(page + '_' + type)) {
                options.push(option);
            }
        });
        this.options = options;
    }

    getPageSetting(userId, page, type) {
        let defaultSetting = lodash.get(this.getSetting(page), userId, this.getDefaultSetting());
        let settings = [];
        lodash.each(SETTING_OPTIONS, setting => {
            if (setting.page.includes(page + '_' + type) && defaultSetting.includes(setting.key)) {
                settings.push(setting.key);
            }
        });
        return settings;
    }

    getDefaultSetting() {
        return this.options.filter(option => option.selected).map(option => option.key);
    }

    getUserSetting(userId, page, type) {
        this.getOption(page, type);
        return this.getPageSetting(userId, page, type);
    }

    updateUserSetting(userId, setting, page, type) {
        this.getOption(page, type);
        this.updateSetting({...this.getSetting(page), [userId]: setting}, page);
    }
}

let settingSingleService = new SettingSingleService(SETTING_OPTIONS);

export default settingSingleService;
