import {processApiRequest} from '../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';

export function* fetchConsignServices(action) {
    yield processApiRequest(ACTION.FETCH_CONSIGN_SERVICES, () => apiService.getConsignServices(), action.payload);
}

export function* fetchServices(action) {
    yield processApiRequest(ACTION.FETCH_SERVICES, () => apiService.getServices(), action.payload);
}

export function* fetchConsignServiceGroups(action) {
    yield processApiRequest(ACTION.FETCH_CONSIGN_SERVICE_GROUPS, () => apiService.getConsignServiceGroups(action.payload), action.payload);
}

export function* fetchServiceGroups(action) {
    yield processApiRequest(ACTION.FETCH_SERVICE_GROUPS, () => apiService.getServiceGroups(), action.payload);
}
