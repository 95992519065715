import {connect} from 'react-redux';
import authService from '../authService';
import {logout} from '../actions';
import UserPanel from './components/UserPanel';

const mapStateToProps = (state) => ({
    user: authService.user(),
    louStampUrl: authService.lou_stamp_url(),
    louStampUrlAuto: authService.lou_stamp_url_auto(),
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPanel);
