/* eslint-disable */
import React, {Component} from 'react';
import lodash from 'lodash';
import {Icon, Input, Layout, Modal} from 'antd/lib/index';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next/src/index";
import List from "./List"
import FormSearch from "./FormSearch"

const Search = Input.Search;

const Content = Layout.Content;

function confirm() {
	Modal.confirm({
		title: 'Xoá kho',
		content: <div>Bạn có chắc chắn muốn xoá kho "<strong>G-HN</strong>" không ?</div>,
		okText: 'Đồng ý',
		cancelText: 'Hủy',
		zIndex: 1050,
		icon: <Icon type="close-circle" theme="filled" className={"a-text--red"}/>
	});
}

class ListWarehouse extends Component {
	componentDidMount() {
		this.props.setMenuActive('config/warehouse-staff');
	}

	state = {visible: false};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = e => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};


	render() {
		const {listWarehouseStaff, loading, onSearch, filter} = this.props;
		return (
			<Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
				<FormSearch input={filter}
				            listWarehouseStaff={listWarehouseStaff}
				            inputVersion={lodash.toInteger(filter.i)}
				            loading={loading}
				            onSearch={onSearch}/>
				<List listWarehouseStaff={listWarehouseStaff} loading={loading}/>
			</Content>

		)
	}
}

ListWarehouse.defaultProps = {
	listWarehouseStaff: [],
	filter: {},
	loading: false,
	fetchListWarehouse: () => {
	},
	onSearch: () => {
	}
};

ListWarehouse.propTypes = {
	listWarehouseStaff: PropTypes.array,
	fetchListWarehouse: PropTypes.func,
	onSearch: PropTypes.func,
	filter: PropTypes.object,
	loading: PropTypes.bool,

};
export default withTranslation()(ListWarehouse);
