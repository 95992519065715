import React, { useState } from 'react';
import { Alert, Button, Icon } from 'antd';
import clsx from 'clsx';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { successUpdateNote } from './../../Notes/actions';
import { t } from './../../../system/i18n';

import EditNoteModal from './../../Notes/components/EditNoteModal';
import Logs from './../../Notes/Logs/LogsContainer';

import { ReactComponent as NoteIcon } from './../../../resources/images/24px_notes.svg';

const now = moment();

export const renderNotices = notices => {
    if (!notices || !notices.length) {
        return null;
    }

    return (
        <div className="_notices gflex flex-wrap">
            <Icon type="info-circle" theme="filled" className="a-text--orange mr-2" />
            {notices.map((notice, index) => (
                <div className="a-text--black-45 _notice" key={notice}>
                    <span>
                        {t(`notice:${notice}`)}
                    </span>
                    {index !== notices.length - 1 && (
                        <span className="mr-2">,</span>
                    )}
                </div>   
            ))}
        </div>
    );
};

export const useNotes = (notes, stage, onSuccessUpdateNote) => {
    const [selectedNoteId, setSelectedNoteId] = useState();
    const [selectedNote, setSelectedNote] = useState();
    const [logsModalVisible, setLogsModalVisible] = useState(false);
    const [editNoteModalVisible, setEditNoteModalVisible] = useState(false);
    const dispatch = useDispatch();

    const handleShowLog = id => {
        setSelectedNoteId(id);
        setLogsModalVisible(true);
    };

    const handleCloseLog = () => {
        setSelectedNoteId(undefined);
        setLogsModalVisible(false);
    };

    const handleShowEditNoteModal = note => {
        setEditNoteModalVisible(true);
        setSelectedNote(note);
    };

    const handleCancelEditNoteModal = () => {
        setEditNoteModalVisible(false);
        setSelectedNote(undefined);
    };

    const handleSuccessUpdateNote = note => {
        if (isFunction(onSuccessUpdateNote)) {
            onSuccessUpdateNote(note);
        } else {
            dispatch(successUpdateNote({
                ...note,
                stage
            }));
        }

        handleCancelEditNoteModal();
    };

    if (!notes || !notes.length) {
        return null;
    }

    return (
        <>
            {notes.map((note, index) => (
                <div
                    className={clsx('a-text--black-45', {
                        'mb-2': index !== notes.length - 1
                    })}
                    key={note.id}
                >
                    <div className="dpl-inline-block mr-2">
                        <NoteIcon className={clsx('note-icon', {
                            'note-icon--deleted': !!note.is_deleted,
                            'note-icon--warning': !now.is_deleted && now.isAfter(note.expired_at)
                        })} />
                    </div>

                    <span
                        className="a-text--blue a-text--cursor-pointer a-text--nowrap mr-2"
                        onClick={handleShowLog.bind(this, note.id)}
                    >
                        {t('note:btn.history_log_follow')}
                    </span>

                    {t('attention')}: {note.content}
                    {has(note, 'manage') && note.manage ? (
                        <Button
                            className="a-btn--button-link a-text--blue a-btn--bg-transparent ml-2"
                            onClick={handleShowEditNoteModal.bind(this, note)}
                        >
                            <Icon type="edit" />
                        </Button>
                    ) : null}
                </div>
            ))}

            <Logs
                objectId={selectedNoteId}
                visible={logsModalVisible}
                onCloseLog={handleCloseLog}
            />

            <EditNoteModal
                note={selectedNote}
                visible={editNoteModalVisible}
                onCancel={handleCancelEditNoteModal}
                onOk={handleSuccessUpdateNote}
            />
        </>
    );
};

const Attentions = ({ className, notes, notices, stage, onSuccessUpdateNote }) => {
    const noteElements = useNotes(notes, stage, onSuccessUpdateNote);

    if ((!notes || !notes.length) && (!notices || !notices.length)) {
        return null;
    }

    return (
        <Alert
            className={className}
            message={(
                <>
                    <div
                        className={clsx({
                            'mb-2': notices && notices.length
                        })}
                    >
                        {noteElements}
                    </div>
                    {renderNotices(notices)}
                </>
            )}
            type="warning"
        />
    );
};

Attentions.defaultProps = {
    notes: [],
    notices: []
};

Attentions.propTypes = {
    notes: PropTypes.array,
    notices: PropTypes.array
};

export default Attentions;
