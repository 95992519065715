import React from 'react';
import { Icon, Table } from 'antd';
import clsx from 'clsx';

import { currentFormatter } from './../../../../Common/services/format';
import { trans } from './../../../../../system/i18n';
import { formatWeight, getVar } from './../../../../../system/support/helpers';

import Link from './../../../../../system/routing/Link';

const List = ({ loading, shippingPartners }) => {
    const columns = [{
        dataIndex: 'shipping_partner.code',
        key: 'code',
        title: trans('shipping_partner:label.code'),
        width: 150,
        render: (text, record) => {
            return {
                props: {
                    className: clsx('a-notification-dot a-notification-dot--table _status-active', {
                        active: getVar(record, 'shipping_partner.active', true),
                        unactive: !getVar(record, 'shipping_partner.active', true),
                    })
                },
                children: (
                    <Link
                        className="_code"
                        to="config.shipping-partners.edit"
                        params={{
                            id: getVar(record, 'shipping_partner.id', 0),
                            type: 'international'
                        }}
                    >
                    {text || '--'}
                </Link>
                )
            };
        }
    }, {
        className: '_name',
        dataIndex: 'shipping_partner.name',
        key: 'name',
        title: trans('shipping_partner:label.name'),
        width: 180,
        render: text => text || '--'
    }, {
        className: '_phone',
        dataIndex: 'shipping_partner.phone',
        key: 'phone',
        title: trans('shipping_partner:label.phone'),
        width: 150,
        render: text => text || '--'
    }, {
        className: '_email',
        dataIndex: 'shipping_partner.email',
        key: 'email',
        title: trans('shipping_partner:label.email'),
        width: 300,
        render: text => text || '--'
    }, {
        className: '_address',
        dataIndex: 'shipping_partner.address',
        key: 'address',
        title: trans('shipping_partner:label.address'),
        width: 300,
        render: (text, record) => {
            const detail = getVar(record, 'shipping_partner.location.detail');

            return text ? `${text}${detail ? (`, ${detail}`) : ''}` : '--'
        }
    }, {
        className: '_max-value-package',
        dataIndex: 'shipping_partner.max_value_package',
        key: 'shipping_partner.max_value_package',
        title: trans('shipping_partner:label.max_value_package'),
        width: 300,
        render: text => `${currentFormatter.toLocaleStringCurrency(text)} VNĐ`
    }, {
        className: '_max-weight-package',
        dataIndex: 'shipping_partner.max_weight_package',
        key: 'shipping_partner.max_weight_package',
        title: trans('shipping_partner:label.max_weight_package'),
        render: text => formatWeight(text),
    }, {
        className: 'a-text--nowrap atext-right _action',
        key: 'action',
        render: (_, record) => (
            <Link
                className="_btn-edit"
                params={{
                    id: getVar(record, 'shipping_partner.id', 0),
                    type: 'international'
                }}
                to="config.shipping-partners.edit"
            >
                <Icon className="ahover-pointer a-text--blue" type="edit" />
            </Link>
        )
    }];

    return (
        <Table
            className="a-table--antd a-table--antd-scroll-x a-text--nowrap _a-table-international-shipping-partner"
            columns={columns}
            dataSource={shippingPartners}
            loading={loading}
            pagination={false}
            rowKey={(record => getVar(record, 'shipping_partner.id'))}
            scroll={{
                x: 'fit-content'
            }}
        />
    );
};

export default List;
