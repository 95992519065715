export const PACKAGE_COLUMN = {
    CODE: 'code',
    BARCODE: 'barcode',
    BAG_CODE: 'bag_code',
    ORDER: 'order',
    CUSTOMER_USERNAME: 'customer_username',
    PRINTED_OFFICIAL_STAMP: 'printed_official_stamp',
    DELETED_TIME: "deleted_at",
    CREATE_TIME: 'created_at',
    ORDER_LINKED_AT: 'order_linked_at',
    TRANSPORTING_AT: 'transporting_at',
    READY_FOR_DELIVERY_AT: 'ready_for_delivery_at',
    DELETE_REASON: 'delete_reason',
    INPUT_WAREHOUSE: 'input_warehouse',
    DESTINATION_WAREHOUSE: 'destination_warehouse',
    WEIGHT: 'weight_net',
    VOLUME: 'volume',
    DELETED_USERNAME: 'deleted_username'
};
