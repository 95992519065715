import { api } from './../../system/api';

const BASE_URL = '/shipping-fees';

export default {
    list: params => api.get(BASE_URL, {
        params
    }),
    add: payload => api.post(BASE_URL, payload),
    update: payload => api.put(`${BASE_URL}/${payload.id}`, payload),
    routes: () => api.get(`${BASE_URL}/routes`),
    reExecutions: params => api.get(`${BASE_URL}/re-executions`, {
        params
    }),
    addReExecution: payload => api.post(`${BASE_URL}/re-executions`, payload)
};
