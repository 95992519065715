export const CREATE = {
    REQUEST: 'DELIVERY_NOTE.FORM_CUSTOMER.CREATE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.FORM_CUSTOMER.CREATE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.FORM_CUSTOMER.CREATE.FAILED',
};

export const CLEAR_STATE = 'DELIVERY_NOTE.FORM_CUSTOMER.CLEAR_STATE';

export const RESET_ERROR = 'DELIVERY_NOTE.FORM_CUSTOMER.RESET_ERROR';

export const ADD_BARCODE = 'DELIVERY_NOTE.CUSTOMER_FORM.ADD_BARCODE';
export const REMOVE_BARCODE = 'DELIVERY_NOTE.CUSTOMER_FORM.REMOVE_BARCODE';
export const SET_BARCODES = 'DELIVERY_NOTE.CUSTOMER_FORM.SET_BARCODES';
