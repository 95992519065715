/* eslint-disable */
import React from 'react';
import {Button, Input} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {router} from "../../../../system/routing";
import * as services from "../../../DeliveryNote/ListDeliveryNote/services";

const Search = Input.Search;

class FormSearch extends React.PureComponent {
	constructor(props) {
		super(props);

		this.defaultInput = {};

		this.state = {
			input: {...this.defaultInput},
			inputVersion: 0,
			changedParams: [],
		};
	}

	onCreateWarehouse = () => {
		router.redirect('/config/warehouse/create')
	};

	onSubmit(event) {
		event.preventDefault();
		if (!this.props.loading) {
			let {input} = this.state;
			input.page = 1;
			this.props.onSearch(input);
		}
	}

	onClearSearch() {
		let input = {};
		input.page = 1;
		input.code = "";
		this.setState({input});
		this.props.onSearch(input);
	}

	onChangeSearch(e) {
		this.setState({
			input: {
				code: e.target.value
			}
		});
		if (e.type === 'click' && !this.props.loading) {
			let {input} = this.state;
			input.page = 1;
			input.code = "";
			this.props.onSearch(input);
		}
	}

	render() {
		const {t, listWarehouse, loading} = this.props;
		let input = services.converValue2Int({...this.state.input});
		return (
			<div className="a-content__top a-content__top--page-list mt-0">
				<div className="a-content--page-list__title gflex galign-center">
					<h3>{t('config:label.list_warehouse_current')} ({listWarehouse.length})</h3>
					<div className={"a-form__search-inline-title"}>
						<Search
							placeholder={t('config:placeholder.search_warehouse')}
							onChange={this.onChangeSearch.bind(this)}
							value={input.code}
							onPressEnter={this.onSubmit.bind(this)}
							allowClear
							className={"a-input--search-inline-title _input-search-warehouse"}
						/>
					</div>
				</div>
				<div className="a-content--page-list__top-action">
					<Button type="primary"
					        loading={loading}
					        disabled={loading}
					        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-warehouse"
					        onClick={this.onCreateWarehouse}>{t('config:btn.create_warehouse')}
					</Button>
				</div>
			</div>

		)
	}
}

FormSearch.defaultProps = {
	listWarehouse: [],
	fetchListWarehouse: () => {
	}
};

FormSearch.propTypes = {
	listWarehouse: PropTypes.array,
	fetchListWarehouse: PropTypes.func,
};
export default withTranslation()(FormSearch);
