import { all, takeLatest } from 'redux-saga/effects';

import { ADD_RE_EXECUTION, GET_RE_EXECUTIONS } from './constants';
import { addReExecution, failedAddReExecution, getReExecutions, successAddReExecution } from './saga';

export default function*() {
    yield all([
        yield takeLatest(GET_RE_EXECUTIONS.REQUEST, getReExecutions),
        yield takeLatest(ADD_RE_EXECUTION.REQUEST, addReExecution),
        yield takeLatest(ADD_RE_EXECUTION.SUCCESS, successAddReExecution),
        yield takeLatest(ADD_RE_EXECUTION.FAILED, failedAddReExecution)
    ]);
}
