import React from 'react';
import { connect } from 'react-redux';

import { createInventory } from './actions';
import { getState } from './selectors';
import { setMenuActive } from './../../Common/actions';

import CreateInventory from './components';
import SearchableComponent from './../../Common/components/SearchableComponent';
import withConsignServices from './../../../hocs/withConsignServices';
import withServices from './../../../hocs/withServices';

const mapStateToProps = state => {
	return {
		adding: getState(state, 'adding')
	};
};

const mapDispatchToProps = {
	setMenuActive,
	createInventory
};

class CreateInventoryContainer extends SearchableComponent {
	getInput = () => this.getFilterFromLocation(this.props.location);

	onChangeInput = input => {
		this.pushFilter(input);
	};

	render() {
		return (
			<CreateInventory
				{...{
					...this.props,
					input: this.getInput(),
					onChangeInput: this.onChangeInput,
				}}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withConsignServices(withServices(CreateInventoryContainer)));
