export const FETCH_BAG_DETAIL = {
    REQUEST: 'BAG.BAG_DETAIL.FETCH_BAG_DETAIL.REQUEST',
    SUCCESS: 'BAG.BAG_DETAIL.FETCH_BAG_DETAIL.SUCCESS',
    FAILED: 'BAG.BAG_DETAIL.FETCH_BAG_DETAIL.FAILED',
};

export const DETACH_PACKAGE = {
    REQUEST: 'BAG.BAG_DETAIL.DETACH_PACKAGE.REQUEST',
    SUCCESS: 'BAG.BAG_DETAIL.DETACH_PACKAGE.SUCCESS',
    FAILED: 'BAG.BAG_DETAIL.DETACH_PACKAGE.FAILED',
};

export const CLEAR_STATE = 'BAG.BAG_DETAIL.CLEAR_STATE';

export const PACKAGE_COLUMN = {
    INDEX: 'stt',
    CODE_PACKAGE: 'code_package',
    CODE_ORDER: 'code_order',
    WEIGHT: 'weight',
    VOLUME: 'volume',
    DELETE: 'delete'
};

export const CREATE_SIMILAR_BAG = {
    REQUEST: 'BAG.CREATE_SIMILAR_BAG.REQUEST',
    SUCCESS: 'BAG.CREATE_SIMILAR_BAG.SUCCESS',
    FAILED: 'BAG.CREATE_SIMILAR_BAG.FAILED',
};

export const TOGGLE_POPUP_CREATE_TEMPLATE_BAG = "BAG.TOGGLE_POPUP_CREATE_TEMPLATE_BAG";

export const UPDATE_BAG = {
    REQUEST: 'BAG.BAG_DETAIL.UPDATE_BAG.REQUEST',
    SUCCESS: 'BAG.BAG_DETAIL.UPDATE_BAG.SUCCESS',
    FAILED: 'BAG.BAG_DETAIL.UPDATE_BAG.FAILED',
};

export const CREATE_TEMPLATE_BAG = {
    REQUEST: 'BAG.CREATE_TEMPLATE_BAG.REQUEST',
    SUCCESS: 'BAG.CREATE_TEMPLATE_BAG.SUCCESS',
    FAILED: 'BAG.CREATE_TEMPLATE_BAG.FAILED',
};

export const EXPORT_BAG = {
    REQUEST: 'BAG.EXPORT_BAG.REQUEST',
    SUCCESS: 'BAG.EXPORT_BAG.SUCCESS',
    FAILED: 'BAG.EXPORT_BAG.FAILED',
};

export const BAG_STATUS = {
    SHIPPING: "SHIPPING"
};

export const SET_BAG_DETAIL = 'SET_BAG_DETAIL';
