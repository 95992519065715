import { createAction } from 'redux-actions';

import { ADD_SHIPPING_FEE, CLEAR_FORM_ERRORS, GET_ROUTES, GET_SHIPPING_FEES, UPDATE_SHIPPING_FEE } from './constants';

export const getShippingFees = createAction(GET_SHIPPING_FEES.REQUEST);

export const addShippingFee = createAction(ADD_SHIPPING_FEE.REQUEST);

export const updateShippingFee = createAction(UPDATE_SHIPPING_FEE.REQUEST);

export const clearFormErrors = createAction(CLEAR_FORM_ERRORS);

export const getRoutes = createAction(GET_ROUTES.REQUEST);
