import React from 'react';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {clearState} from './actions';
import apiService from './../../Package/apiService';

import OrderPackages from './components/OrderPackages';

const mapDispatchToProps = {
    clearState
};

class OrderPackagesContainer extends React.PureComponent {
    state = {
        loading: false,
        orderPackages: [],
        filter: {
            id_order: get(this.props.order, 'id'),
            page: 1,
            per_page: 20
        }
    };

    getPackages = async (filter = {}) => {
        this.setState({
            loading: true
        });

        try {
            const { data } = await apiService.getPackages(filter);

            this.setState({
                filter: {
                    ...filter,
                    ...data.pagination
                },
                orderPackages: data.packages
            });
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };

    handleSearchOrderPackages = (name, value) => {
        this.setState(prevState => {
            const newFilter = {
                ...prevState.filter,
                [name]: value
            };

            this.getPackages(newFilter);

            return {
                filter: newFilter
            };
        });
    };

    componentDidMount() {
        const { filter } = this.state;
        const { order } = this.props;

        this.getPackages({
            ...filter,
            id_order: get(order, 'id')
        });
    }

    componentDidUpdate(prevProps) {
        const { filter } = this.state;
        const { order } = this.props;

        if (order.id !== prevProps.order.id) {
            this.getPackages({
                ...filter,
                id_order: get(order, 'id')
            });
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        const { loading, orderPackages, filter } = this.state;
        const { currentPackageId, order } = this.props;

        return (
            <OrderPackages
                currentPackageId={currentPackageId}
                loading={loading}
                order={order}
                orderPackages={orderPackages}
                filter={filter}
                onSearch={this.handleSearchOrderPackages}
            />
        );
    }
}

const Container = connect(
    undefined,
    mapDispatchToProps
)(OrderPackagesContainer);

Container.defaultProps = {
    order: {},
    currentPackageId: null,
};

Container.propTypes = {
    order: PropTypes.object,
    currentPackageId: PropTypes.number,
};

export default Container;

