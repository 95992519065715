import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {translateValidationErrors} from '../../Common/services/helps';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'package.updateRelatedPackage' + (param ? '.' + param : ''), defaultValue);

export const getPackageState = (state, id) => lodash.find(getState(state, 'packages', []), {id});

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let messages = translateValidationErrors(t('package:label.' + param), rules);

        return lodash.first(lodash.values(messages));
    });
};
