import React, { PureComponent } from 'react';
import { Icon, Skeleton } from 'antd';
import clsx from 'clsx';
import { map,round } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { renderNotices } from './../../../../Common/components/Attentions';
import { Link } from './../../../../../system/routing';
import { getVar } from './../../../../../system/support/helpers';

import { ReactComponent as IconExport } from './../../../../../resources/images/Iconremove.svg';

class Bag extends PureComponent {
    render() {
        const { bag } = this.props;

        return bag.scanning ? this.renderLoading() : this.renderBag(bag);
    }

    renderLoading = () => {
        return (
            <tbody>
                <tr>
                    <td colSpan={4}>
                        <Skeleton active paragraph={{ rows: 0 }} className="askeleton-item-table" />
                    </td>
                </tr>
            </tbody>
        );
    };

    renderBag = bag => {
        const { scrollTable, t, onRemove } = this.props;
        const messages = bag.error || bag.warning || bag.warnings;
        const notices = getVar(bag, 'notices.text', []);
        const bagType = getVar(bag, "bag.type")
        const weight = getVar(bag, 'bag.weight_real')
        const volume = getVar(bag, 'bag.volume')
        return (
            <tbody className={clsx({
                'error': bag.error,
                'warning': !bag.error && (bag.warning || bag.warnings || notices.length)
            })}>
                <tr className="data">
                    <td>{getVar(bag, 'code')}</td>
                    <td>
                        <Link
                            isNewTab
                            params={{
                                id: getVar(bag, 'code')
                            }}
                            to="bags.detail"
                        >
                            {getVar(bag, 'bag.code')}
                        </Link>
                    </td>
                    <td>
                        {bagType ? t(`bag:label.${bagType}`) : ""}
                    </td>
                    <td>{weight ? weight + "(kg)": '---'}</td>
                    <td>{volume ? round(volume * 1000000, 3) + " (cm3)" : "---"}</td>
                    <td className={clsx('sticky-right', {
                        'box-shadow': scrollTable
                    })}>
                        <div className="gflex gjustify-end">
                            <span className="action-remove">
                                {bag.error ? (
                                    <Icon type="close" onClick={onRemove} />
                                ) : (
                                    <IconExport onClick={onRemove} />
                                )}
                            </span>
                        </div>
                    </td>
                </tr>

                {messages || notices.length ? (
                    <tr className="message">
                        <td colSpan="6">
                            {map(messages, (message, index) => (
                                <p className="mb-2 a-text--black-45" key={index}>
                                    <Icon type='info-circle'
                                        theme="filled"
                                        className={clsx('mr-2', {
                                            'a-text--red': bag.error,
                                            'a-text--orange': !bag.error && (bag.warning || bag.warnings)
                                        })}
                                    />
                                    {t(`scan:warning.${message.code || message}`)}
                                </p>
                            ))}

                            {renderNotices(notices)}
                        </td>
                    </tr>
                ) : null}
            </tbody>
        );
    };
}

Bag.defaultProps = {
    onRemove: () => {
    }
};

Bag.propTypes = {
    onRemove: PropTypes.func
};

export default withTranslation()(Bag);
