import { get } from 'lodash'
import React from 'react'
import PackageGroup from './PackageList/PackageGroup'

import PackageItem from './PackageList/PackageItem'

const PackageList = ({
    boxSizes,
    loadingBoxSizes,
    orderItems,
    originPackage,
    packages,
    onUpdate,
    onRemovePackage,
    groupByProduct,
    getDetachSuggest,
    detachSuggest,
}) => {
    const splittingPackages = packages.filter(pkg => !pkg.id)
    // const [groupByProduct, setGroupByProduct] = useState(false)

    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search)
    //     setGroupByProduct(urlParams.get('group_by_product') === 'true')
    // }, [])

    return (
        <>
            {groupByProduct ? (
                <PackageGroup
                    boxSizes={boxSizes}
                    loadingBoxSizes={loadingBoxSizes}
                    orderItems={orderItems}
                    originPackage={originPackage}
                    packages={packages}
                    onUpdate={onUpdate}
                    onRemovePackage={onRemovePackage}
                    detachSuggest={detachSuggest}
                    groupByProduct={groupByProduct}
                    detachSuggestItems={get(detachSuggest, 'package_items', [])}
                />
            ) : (
                <ul className="package-list">
                    {packages.map((pkg, index) => (
                        <PackageItem
                            boxSizes={boxSizes}
                            haveSplittingPackages={!!splittingPackages.length}
                            index={index}
                            key={pkg.id || index}
                            loadingBoxSizes={loadingBoxSizes}
                            orderItems={orderItems}
                            originPackage={originPackage}
                            products={pkg.items}
                            pkg={pkg}
                            packageData={packages}
                            onUpdate={onUpdate.bind(undefined, index)}
                            onRemovePackage={onRemovePackage.bind(undefined, index)}
                            getDetachSuggest={getDetachSuggest}
                        />
                    ))}
                </ul>
            )}
        </>
    )
}

export default PackageList
