import { withTranslation } from 'react-i18next'
import React from 'react'
import { Skeleton, Button } from 'antd'
import { isNil, map } from 'lodash'
import { getVar } from '../../../../system/support/helpers'
import { dateFormatter } from '../../../Common/services/format'
import { Link, url } from '../../../../system/routing'
import { showDetailDeliveryRequest } from '../../../DeliveryRequest/DetailDeliveryRequest/actions'
import { dispatch } from '../../../../system/store'
import { renderNotices } from '../../../Common/components/Attentions'
import { DOCUMENT_STATUS } from '../../constants'
import clsx from 'clsx'

function PackageList({ t, data, loading }) {
    const packages = getVar(data, 'packages.data', [])
    const status = getVar(data, 'status')
    const packageIdsInDeliveryNote = map(getVar(data, 'delivery_note.packages.data', []), 'id')
    const deliveryRequestId = getVar(data, 'id_delivery_request')
    function onEdit() {
        url.redirectTo('return-goods.edit', {
            id: getVar(data, 'id', 0),
        })
    }

    return (
        <React.Fragment>
            <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0 mt-0">
                <div className="a-content--page-list__title">
                    <h3>
                        {t('return_goods:title.total_package')} ({packages.length})
                    </h3>
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list  mr-0 aflex-1 pb-0">
                <div className="a-table--responsive">
                    <table className="a-table a-table--isdata--warning-error">
                        <thead>
                            <tr>
                                <th>{t('package:label.code')}</th>
                                <th>{t('delivery_request:label.code_delivery_request')}</th>
                                <th>{t('return_goods:label.scaned_at')}</th>
                                <th>{t('delivery_note:label.exported_at')}</th>
                                <th>{t('weight')}</th>
                            </tr>
                        </thead>
                        {!!loading && (
                            <tr className="data">
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                            </tr>
                        )}

                        {packages.length > 0 &&
                            packages.map(packageInfo => {
                                const packageId = getVar(packageInfo, 'id')
                                const deliveryRequestidOfPackage = getVar(packageInfo, 'delivery_request.id')
                                const warning = [];
                                if (packageIdsInDeliveryNote.length > 0 && !packageIdsInDeliveryNote.includes(packageId))
                                    warning.push("package_not_in_delivery_note")
                                if  (deliveryRequestId && deliveryRequestId !== deliveryRequestidOfPackage) {
                                    warning.push('package_not_in_delivery_request')
                                }        
                                const code = getVar(packageInfo, 'code')
                                const delivering_at = getVar(packageInfo, 'delivering_at')
                                const agency = getVar(packageInfo, 'delivery_request.agency', {})
                                const deliveryRequestCode = getVar(packageInfo, 'delivery_request.code')
                                const weightNet = getVar(packageInfo, 'weight_net', 0)
                                const scanImportedAt = getVar(packageInfo, 'pivot.scanned_at')
                                return (
                                    <tbody
                                        className={warning.length > 0 ? 'warning' : ''}
                                        key={code}>
                                        <tr className="data">
                                            <td>
                                                {
                                                    <Link
                                                        isNewTab
                                                        to="packages.detail"
                                                        params={{ id: code }}>
                                                        {code}
                                                    </Link>
                                                }
                                            </td>
                                            <td>
                                                {deliveryRequestCode ? (
                                                    <span
                                                        className="a-text--link cursor-pointer"
                                                        onClick={() =>
                                                            dispatch(
                                                                showDetailDeliveryRequest({
                                                                    code: deliveryRequestCode,
                                                                    agency: getVar(agency, 'code'),
                                                                })
                                                            )
                                                        }>
                                                        {deliveryRequestCode}
                                                    </span>
                                                ) : (
                                                    '---'
                                                )}
                                            </td>
                                            <td>{isNil(scanImportedAt) ? '--' : dateFormatter.dateTime(scanImportedAt)}</td>
                                            <td>{isNil(delivering_at) ? '--' : dateFormatter.dateTime(delivering_at)}</td>
                                            <td>{(weightNet ? weightNet : 0) + ' (kg)'}</td>
                                        </tr>

                                        {warning.length > 0 && (
                                            <tr className="message">
                                                <td
                                                    colSpan={5}
                                                    className="_box_message_error">
                                                    {renderNotices(warning)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )
                            })}
                    </table>
                </div>
                {status === DOCUMENT_STATUS.PENDING && (
                    <div className="a-pagination a-pagination--fixed a-pagination--height-action gflex p-5 gjustify-space-between">
                        <ul className="bottom-action-list">
                            {status === DOCUMENT_STATUS.PENDING && (
                                <li>
                                    <Button
                                        onClick={onEdit}
                                        className="a-btn a-btn--button-link a-text--blue ">
                                        {t('delivery_note:label.edit')}
                                    </Button>
                                </li>
                            )}
                        </ul>
                        <div></div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}
export default withTranslation()(PackageList)
