import React from "react";
import {Col, Icon, Row} from "antd";
import InputNumber from "../../../Common/components/InputNumber";
import {t} from "../../../../system/i18n";
import * as lodash from "lodash";

class UpdateVolumeBag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                width: 0,
                height: 0,
                length: 0
            },
            volume: 0
        }
    }

    getWarningParams(param) {
        let warning = {};
        switch (param) {
            case 'length':
            case 'width':
            case 'height':
            default:
                warning['status'] = '';
                warning['message'] = '';
                warning['value'] = '';
        }

        return warning;
    }


    getInputStatusClass(hasError, hasWarning) {
        if (hasError) {
            return 'a-input--error';
        }

        if (hasWarning) {
            return 'a-input--warning';
        }

        return '';
    }

    onChangeInput(param, value) {
        if (lodash.isObject(value) && value.hasOwnProperty('target')) {
            value = value.target.value;
        }
        let currentValue = this.props.input[param];
        if (currentValue !== value) {
            this.props.onChangeValue(param, value)
        }
    }

    render() {
        let {input, loading} = this.props;
        const volume = input.height * input.width * input.length;
        return <div className="a-form form--bag form-package-lg">
            <Row gutter={{lg: 24, xl: 32}}>
                <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}} className="pr-0 ">
                    <div className="a-packing-bag__body--group-size">
                        <div className="group--input-bill-code-small">
                            {['length', 'width', 'height'].map(param => (
                                <div key={param}
                                     className="a-form--loading loading-input mr-2 dpl-inline-block width-30-pc">
                                    <InputNumber
                                        name={param}
                                        placeholder={t('package:placeholder.' + param)}
                                        title={t('package:label.' + param)}
                                        className={'a-input width-100-pc a-input--package-size _package_size ' +
                                        this.getInputStatusClass(this.getWarningParams(param)['status'] === 'error',
                                            this.getWarningParams(param)['status'] === 'warning') + ` _package_size_${param}`}
                                        value={input[param] === 0 ? '' : (input[param] || '') || ''}
                                        precision={1}
                                        min={0}
                                        onChange={this.onChangeInput.bind(this, param)}
                                        // onPressEnter={this.onPressEnter.bind(this)}
                                    />
                                    {loading ? <Icon type="loading-3-quarters"
                                                     className="icon-loading"/> : null}
                                </div>
                            ))}
                            {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'error' ? (
                                <p key={param}
                                   className="a-text--error mb-0 mt-1">{this.getWarningParams(param)['message']}</p>
                            ) : null)}

                            {['length', 'width', 'height'].map(param => this.getWarningParams(param)['status'] === 'warning' ? (
                                <p key={param}
                                   className="a-text--warning mb-1 mt-1">{this.getWarningParams(param)['message']}</p>
                            ) : null)}
                        </div>
                    </div>
                </Col>
            </Row>
            {!!volume ?
                <span
                    className="bill-code-sub _volume">= {volume} (cm<sup>3</sup>)</span> : null}
        </div>
    }
}

export default UpdateVolumeBag
