import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import ScanningHistory from './components/ScanningHistory';
import * as commonAction from "../../Common/actions";

const mapStateToProps = (state) => ({
    ...selectors.getState(state),
});

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    fetchHistories: (filter) => dispatch(actions.fetchHistories(filter)),
});

class ScanningHistoryContainer extends SearchableComponent {
    onSearch(filter) {
        this.pushFilter({
            ...filter,
            sort_by: filter.sort_by || 'created_at',
            sort_direction: filter.sort_direction || 'desc',
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter(filter) {
        this.props.fetchHistories(filter);
    }

    getCurrentFilter() {
        return {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            sort_direction: this.props.sort_direction || 'desc',
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        return <ScanningHistory {...{
            ...this.props,
            filter: this.getCurrentFilter(),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanningHistoryContainer)