import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';

import Icon from './../../../../components/icons/Icon';

const {Option} = Select;

class FormServices extends React.PureComponent {
    onSelect(propertyId, selected, event) {
        event.preventDefault();
        this.changeProperty(propertyId, selected);
    }


    changeProperty(propertyId, selected) {
        let values = selected
            ? [...this.props.values, propertyId]
            : this.props.values.filter(value => value !== propertyId);

        this.props.onChange(values);
    }


    render() {
        const {className, loading} = this.props;
        return (
            <Select
                value={this.props.values && !loading ? this.props.values : []}
                showSearch={true}
                optionFilterProp="children"
                mode="multiple"
                loading={loading}
                disabled={this.props.disabled || loading}
                className={clsx(className, '_select-property')}
                placeholder={this.props.placeholder}
                validateStatus={this.props.error ? 'error' : null}
                help={this.props.error}
                onChange={this.props.onChange}
                dropdownClassName={this.props.dropdownClassName + '_select-dropdown-property'}
            >
                {this.props.properties.map(property => (
                    <Option
                        key={property.id}
                        value={property.id}
                        className={clsx('option-property', `_property-${property.id}`)}
                    >
                        <Icon className="mr-2" type={property.icon} />
                        {property.name}
                    </Option>
                ))}
            </Select>
        );
    }
}

FormServices.defaultProps = {
    classUl: "",
    loading: false,
    properties: [],
    values: [],
    onChange: values => {
    },
};

FormServices.propTypes = {
    loading: PropTypes.bool,
    properties: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    classUl: PropTypes.string,

};

export default withTranslation()(FormServices);
