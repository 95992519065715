/* eslint-disable */

import React from 'react';
import {Button, Col, Collapse, Form, Icon, Input, Layout, Row} from "antd";

import Log from "../../DeliveryNoteDetail/components/Log";

const Content = Layout.Content;

class DetailPackageChecked extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapseInfo: false,
        }
    }

    onClickCollapse = () => {
        this.setState({
            isCollapseInfo: !this.state.isCollapseInfo
        })
    }

    render() {
        const {isCollapseInfo} = this.state;
        return (
            <Content className="a-content pl-0 gflex gflex-direction-column height-not-header">
                <div className="a-content__top amb-16">
                    <div className="a-content__top__title">
                        <h3 className="a-text--uppercase">Quét mã kiểm kiện </h3>
                    </div>
                    <Input className="a-input w-260" placeholder="Mã quét"/>
                </div>
                <Row type="flex" className="aposition-reletive height-100">
                    <div className="a-content--scroll-is-box-chat ">
                        <div className="amb-14 pt-0 block--primary info-package-checkpackage ">
                            <div className="title create-bag">
                                <div className="left">
                                    <span className="a-text--bold">Mã kiện - 12043583953 </span>
                                </div>
                            </div>
                            <div className={`body not-boxshadow `}>
                                <Row gutter={48} className={"a-block-info pb-0"}>
                                    <Col lg={{span: 24}} xl={{span: 24}}>
                                        <div className="info--title mt-0 border--bottom a-text--medium">
                                            <h4>Thông tin kiện hàng</h4>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                         className="info--group amb-14">
                                        <label className="info--label">Mã vận đơn</label>
                                        <a href="#" className="info--value--small a-text--blue">399053453030453</a>
                                    </Col>
                                    <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                         className="info--group amb-14">
                                        <label className="info--label">Đơn hàng</label>
                                        <a href="#" className="info--value--small a-text--blue">399053453030453</a>
                                    </Col>
                                    <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                         className="info--group amb-14">
                                        <label className="info--label">Thời gian tạo</label>
                                        <span className="info--value--small ">12:15 28/02/2019</span>
                                    </Col>
                                </Row>
                                <div className={isCollapseInfo ? 'a-hidden' : ''}>
                                    <Row gutter={48} className={"a-block-info pb-0"}>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group amb-14">
                                            <label className="info--label">Nhóm ngành hàng</label>
                                            <span className="info--value--small ">Công nghệ</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group amb-14">
                                            <label className="info--label">Đặc tính</label>
                                            <span className="info--value--small ">Điện tử / Dễ vỡ / Dễ hỏng</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={48} className={"a-block-info pb-0"}>
                                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                                             className="info--group amb-14">
                                            <label className="info--label">Ghi chú</label>
                                            <span className="info--value--small ">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor </span>
                                        </Col>
                                    </Row>
                                    <Row gutter={48} className={"a-block-info pb-0"}>
                                        <Col lg={{span: 24}} xl={{span: 24}}>
                                            <div className="info--title border--bottom a-text--medium">
                                                <h4>Thông số kiện</h4>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group amb-14">
                                            <label className="info--label">Mã vận đơn</label>
                                            <a href="#"
                                               className="info--value--small a-text--blue">399053453030453</a>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group amb-14">
                                            <label className="info--label">Đơn hàng</label>
                                            <a href="#"
                                               className="info--value--small a-text--blue">399053453030453</a>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group amb-14">
                                            <label className="info--label">Thời gian tạo</label>
                                            <span className="info--value--small ">12:15 28/02/2019</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={48} className={"a-block-info pb-0"}>
                                        <Col lg={{span: 24}} xl={{span: 24}}>
                                            <div className="info--title border--bottom a-text--medium">
                                                <h4>Dịch vụ</h4>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                                             className="info--group amb-14">
                                            <span className="info--value--small ">Kiểm hàng | Chuyển phát nhanh | Đóng gỗ riêng | Chuyển thẳng </span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className={`collapse--checkpackage`} onClick={this.onClickCollapse}>
                                {isCollapseInfo ?
                                    <a href="#">{t('view_full_info')} <Icon type="arrows-alt" className="ml-1"/></a> :
                                    <a href="#">Thu gọn thông tin <Icon type={`shrink`} className="ml-1"/></a>}
                            </div>
                        </div>
                        <div className=" block--primary check-package">
                            <div className="title create-bag gflex gjustify-space-between">
                                    <span className="a-text--bold">Sản phẩm</span>
                                    <span>Số lượng mua : <strong>555</strong></span>
                                    <span>Số lượng nhận : <strong>755</strong></span>
                                    <Button type="button" className="a-btn a-btn--transparent-white" ghost>Nhận đủ </Button>
                            </div>
                            <div className="body not-boxshadow ">
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                                <Row gutter={32} type={"flex"}
                                     className={"a-block-info a-item-package m-0 amb-16 gflex-direction-column"}>
                                    <div className="a-item-package__top">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}} className="pl-0">
                                            <div className="gflex">
                                                <div className="a-item-package__avatar">
                                                    <img src={require("../../../../resources/images/Image 49.png")}
                                                         alt="Avatar"/>
                                                    <span>#4514870</span>
                                                </div>
                                                <div className="a-item-package__info">
                                                    <a href="#"
                                                       className="a-item-package--link">无线蓝牙音响收音机新款老人户外小型广场舞u盘外放便携式家用低音炮插卡小音箱</a>
                                                    <span>Phân loại màu: Đen / Vàng / BDKD, kích thước: 40</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14">
                                            <label className="info--label">Số lượng mua</label>
                                            <span className="a-text--fz-18 a-text--bold">50</span>
                                        </Col>
                                        <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng đã nhận</label>
                                            <span className="a-text--fz-18 a-text--bold">100</span>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 4}} xxl={{span: 4}}
                                             className="info--group amb-14 pr-0">
                                            <label className="info--label">Số lượng trong kiện</label>
                                            <div className="a-item-package__recived-package mt-2">
                                                <Input className="a-input aradius--2 aflex-1"/>
                                                <Button type="primary"
                                                        className="a-btn a-btn--primary ml-2 aradius--2 a-btn--w-70 phz-0 a-text--regular">Nhận
                                                    đủ</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="a-item-package__bottom">
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pl-0 mb-0 note-customer">
                                            <label className="info--label">Ghi chú của khách hàng:</label>
                                            <span className="info--value--small">Giao hàng từ 9h30 - 4h30</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                             className="info--group pr-0 mb-0">
                                            <label className="info--label">Ghi chú của nhân viên:</label>
                                            <span
                                                className="info--value--small">Chị @NguyetPham check giúp em hàng <span
                                                className="a-text--black-25 a-text--fz-12">(NV.Lan Anh, 14:00 29/03/2018)</span></span>
                                        </Col>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Log/>
                </Row>
            </Content>
        )
    }
}

export default DetailPackageChecked;