import React from 'react';
import { connect } from 'react-redux';

import { printBagStamp } from '../../../Common/PrintStamp/actions';

import PrintBagStampFucButton from './PrintBagStampButton/PrintBagStampFucButton';

const PrintBagStampFucButtonContainer = props => (
    <PrintBagStampFucButton {...props} />
);

export default connect(
    undefined,
    {
        printBagStamp
    }
)(PrintBagStampFucButtonContainer);
