import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';

const Option = Select.Option;

class SelectWarehouse extends React.PureComponent {
    render() {
        const {allowClear, className, disabled, dropdownClassName, error, isEmpty, loading, placeholder, selectProps, t, value, warehouses, onChange} = this.props;
        let _value = undefined;
        if (loading) {
            _value = undefined;
        } else {
            if (!Array.isArray(value) && value !== undefined && value !== null && value !== "") {
                _value = Number(value);
            } else {
                _value = value;
            }
        }

        return (
            <Select
                allowClear={allowClear}
                value={_value}
                showSearch={true}
                loading={loading}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabled || loading}
                dropdownClassName={dropdownClassName}
                className={className}
                placeholder={placeholder}
                validateStatus={error ? 'error' : null}
                help={error}
                onChange={onChange}
                {...selectProps}
            >
                {isEmpty ? (
                    <Option
                        className="_warehouse__item"
                        key={0}
                        value={0}
                    >
                        {t('warehouse:select_warehouse.empty_option')}
                    </Option>
                ) : null}
                {warehouses.map(warehouse => (
                    <Option
                        className="_warehouse__item"
                        key={warehouse.id}
                        value={warehouse.id}
                    >{warehouse.code}</Option>
                ))}
            </Select>
        );
    }
}

SelectWarehouse.defaultProps = {
    warehouses: [],
    loading: false,
    error: null,
    disabled: false,
    className: '',
    placeholder: '',
    selectProps: {},
    onChange: () => {
    },
};

SelectWarehouse.propTypes = {
    warehouses: PropTypes.array,
    value: PropTypes.any,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectWarehouse;
