import React, { useState } from 'react';
import { Button, notification } from 'antd';
import clsx from 'clsx';
import { get, omit } from 'lodash';
import { withTranslation } from 'react-i18next';

import { CHECKING_STATUSES } from './../constants';
import apiService from './../apiService';

const FinishCheckingPackageButton = props => {
    const [loading, setLoading] = useState(false);

    // if (get(props.package, 'package_detail.status_checking') !== CHECKING_STATUSES.CHECKING) {
    //     return null;
    // }

    const finishCheckingPackage = async () => {
        setLoading(true);

        try {
            const { data } = await apiService.finishChecking(get(props.package, 'id'));

            notification.success({
                message: props.t('package:finish_checking.success')
            });

            props.onSuccessFinishCheckingPackage(data);
        } catch (error) {
            let message = props.t('package:finish_checking.failed');

            if (get(error, 'response.data.data.id_package')) {
                const key = Object.keys(error.response.data.data.id_package)[0];

                message = props.t(`package:finish_checking.errors.id_package.${key}`);
            }

            notification.error({
                message
            });
        }

        setLoading(false);
    };

    const btnProps = omit(props, [
        'className',
        'package',
        't',
        'onSuccessFinishCheckingPackage'
    ]);

    return (
        <Button
            disabled={get(props, 'package.package_detail.status_checking') !== CHECKING_STATUSES.CHECKING}
            className={clsx('_btn-finish-checking-package', props.className)}
            loading={loading}
            {...btnProps}
            onClick={finishCheckingPackage}
        >
            {props.t('finish_checking_package')}
        </Button>
    );
};

export default withTranslation()(FinishCheckingPackageButton);
