import {processApiRequest} from './../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {url} from '../../../system/routing';
import {hideDetailDeliveryRequest} from "../DetailDeliveryRequest/actions";
import {put} from 'redux-saga/effects';
import {get} from 'lodash';
import {getDeliveryCustomerRequests} from './../../DeliveryNote/Create/actions';
import queryString from 'query-string';

export function* cancelDeliveryRequest(action) {
    yield processApiRequest(ACTION.ON_CANCEL_DELIVERY_REQUEST, () => apiService.cancelDeliveryNote(action.payload.data), action.payload);
}

export function* cancelDeliveryRequestFailed(action) {
    if (action.payload) {
        yield  notification['error']({
            message: t('delivery_request:message.cancel_failed'),
        });
    }
}

export function* cancelDeliveryRequestSuccess(action) {
    const { request } = action;
    const routeName = get(request, 'data.routeName');

    yield  notification['success']({
        message: t('delivery_request:message.cancel_success'),
    });
    yield put(hideDetailDeliveryRequest());

    if (routeName === 'delivery-notes.customer.create') {
        const { location } = url.router;
        const { id_warehouse } = queryString.parse(get(location, 'search'), {
            arrayFormat: 'bracket'
        });

        yield put(getDeliveryCustomerRequests({
            id_warehouse
        }));
    }
}


