export const FETCH_LIST_LOCATIONS = {
    REQUEST: 'FETCH_LIST_LOCATIONS.REQUEST',
    SUCCESS: 'FETCH_LIST_LOCATIONS.SUCCESS',
    FAILED: 'FETCH_LIST_LOCATIONS.FAILED',
};
export const FETCH_LIST_COUNTRY = {
    REQUEST: 'FETCH_LIST_COUNTRY.REQUEST',
    SUCCESS: 'FETCH_LIST_COUNTRY.SUCCESS',
    FAILED: 'FETCH_LIST_COUNTRY.FAILED',
};
export const FETCH_LIST_PROVINCE = {
    REQUEST: 'FETCH_LIST_PROVINCE.REQUEST',
    SUCCESS: 'FETCH_LIST_PROVINCE.SUCCESS',
    FAILED: 'FETCH_LIST_PROVINCE.FAILED',
};
export const FETCH_LIST_DISTRICT = {
    REQUEST: 'FETCH_LIST_DISTRICT.REQUEST',
    SUCCESS: 'FETCH_LIST_DISTRICT.SUCCESS',
    FAILED: 'FETCH_LIST_DISTRICT.FAILED',
};
export const FETCH_LIST_WARD = {
    REQUEST: 'FETCH_LIST_WARD.REQUEST',
    SUCCESS: 'FETCH_LIST_WARD.SUCCESS',
    FAILED: 'FETCH_LIST_WARD.FAILED',
};
export const CHANGE_CURRENT_COUNTRY = 'CHANGE_CURRENT_COUNTRY';
