import React, {Component, Fragment} from 'react';
import {Col, Row,} from 'antd/lib/index';
import {Skeleton} from 'antd';

class FormPropertyLoading extends Component {
	render() {
		return (
			<Fragment>
				<Row gutter={{lg: 24, xl: 32}} className="a-block-info">
					<Col lg={{span: 6}} className="a-form--group">
						<Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
					</Col>
					<Col lg={{span: 6}} className="a-form--group">
						<Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
					</Col>
					<Col lg={{span: 3}} className="a-form--group">
						<Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
					</Col>
				</Row>
				<Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
					<Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
					     className="a-form--group">
						<Skeleton active paragraph={{rows: 1}} className="askeleton-avatar"/>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default FormPropertyLoading;
