import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { clearState as clearDetailState, getDetail } from './../Detail/actions';
import { getState as getDetailState } from './../Detail/selectors';

import Edit from './components';

const EditContainer = props => {
    const clearState = () => {
        props.clearDetailState();
    };

    return (
        <Edit {...props} clearState={clearState} />
    );
};

const mapStateToProps = state => {
    return {
        deliveryNoteReturn: getDetailState(state, 'deliveryNoteReturn'),
        loading: getDetailState(state, 'loading')
    };
};

const mapDispatchToProps = {
    clearDetailState,
    getDetail
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditContainer));
