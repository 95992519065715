import React from 'react'
import lodash, { get } from 'lodash'
import Barcode from 'react-barcode'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { getState } from '../../../../system/store'
import { connect } from 'react-redux'
import { showAliasAgency } from '../../../../system/support/helpers'
import permissions from '../../../Auth/permissions'
import { Col, Row } from 'antd'

const mapStateToProps = state => {
    return {
        agencySetting: getState('setting.agencySetting'),
        globalSetting: getState('setting.setting'),
    }
}

const mapDispatchToProps = {}

class PrintStampMultiPackage extends React.Component {
    componentDidMount() {
        this.props.onDidMount()
    }

    componentDidUpdate() {
        this.scrollTo('a-stamp--print')
    }

    scrollTo(id, callback) {
        let timer = 500
        let settings = {
            duration: 1000,
            easing: {
                outQuint: function (x, t, b, c, d) {
                    return c * ((t = t / d - 1) * t * t * t * t + 1) + b
                },
            },
        }
        let percentage
        let startTime
        let node = document.getElementById(id)
        let nodeTop = node.offsetTop
        let nodeHeight = node.offsetHeight
        let body = document.body
        let html = document.documentElement
        let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        let windowHeight = window.innerHeight
        let offset = window.pageYOffset
        let delta = nodeTop - offset
        let bottomScrollableY = height - windowHeight
        let targetY = bottomScrollableY < delta ? bottomScrollableY - (height - nodeTop - nodeHeight + offset) : delta

        startTime = Date.now()
        percentage = 0

        if (timer) {
            clearInterval(timer)
        }

        function step() {
            let yScroll
            let elapsed = Date.now() - startTime

            if (elapsed > settings.duration) {
                clearTimeout(timer)
            }

            percentage = elapsed / settings.duration

            if (percentage > 1) {
                clearTimeout(timer)

                if (callback) {
                    callback()
                }
            } else {
                yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration)
                window.scrollTo(0, yScroll)
                timer = setTimeout(step, 10)
            }
        }

        timer = setTimeout(step, 10)
    }

    renderPrintOperationSupport() {
        const { packageData, classPrint, agencySetting, globalSetting, items } = this.props
        const showAlias = showAliasAgency(agencySetting, globalSetting, permissions.CONFIG_SHOW_AGENCY_ALIAS)

        const { properties = [], services = [], warehouse_services } = packageData
        const propertyAliases = lodash.map(
            properties.filter(property => property.alias),
            'alias'
        )
        const serviceAliases = lodash.map(
            services.filter(service => service.alias),
            'alias'
        )

        const warehouseServiceAliases = lodash.map(
            warehouse_services.filter(warehouse_service => warehouse_service.alias),
            'alias'
        )
        const volume = lodash.get(packageData, 'package.volume', 0)
        const satisfiedEcommerce = propertyAliases.includes('X')
        const canPrintOfficialStamp = lodash.get(packageData, 'permissions.can_print_official_stamp')
        const operatePackage = lodash.get(packageData, 'operatePackage', false)
        const originPackage = get(packageData, 'origin_package', {})
        const aliases = (satisfiedEcommerce && (canPrintOfficialStamp || operatePackage) ? propertyAliases.filter(alias => alias !== 'X') : propertyAliases)
            .concat(serviceAliases)
            .concat(warehouseServiceAliases)
        let productGroupCode = lodash.get(packageData, 'product_group.code')
        return items.map((code, index) => {
            return (
                <div key={index}>
                    {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
                    <div
                        className={`a-stamp ${classPrint}`}
                        style={{ width: 320 }}>
                        <div id="a-stamp--print">
                            <Row className="a-stamp--print--top">
                                <Col
                                    span={12}
                                    className="mr-4">
                                    <Row className="a-stamp--warehouse a-flex gjustify-space-between">
                                        <Col span={8}>
                                            <span className="a-text--nowrap">{get(originPackage, 'destination_warehouse.code', '')}</span>
                                        </Col>
                                        <Col span={16}>
                                            <span className="a-text--bold a-text--nowrap">{get(packageData, 'order.code', '')}</span>
                                        </Col>
                                    </Row>
                                    <div className="a-flex">
                                        {/* <Col span={12}> */}
                                        <span className="a-text--bold a-text--fz-12 a-text--nowrap mr-4">
                                            {showAlias ? get(originPackage, 'agency.identify_name', '') : get(originPackage, 'agency.code', '')}
                                        </span>
                                        {/* </Col> */}
                                        {/* <Col span={12}> */}
                                        <span className="a-text--bold a-text--fz-12 a-text--nowrap">{get(originPackage, 'package.customer_username', '')}</span>
                                        {/* </Col> */}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Row className="a-stamp--warehouse a-flex gjustify-space-between">
                                        <Col span={8}>
                                            <span className="a-text--nowrap">{get(operatePackage, 'destination_warehouse.code', '')}</span>
                                        </Col>
                                        <Col span={16}>
                                            <span className="a-text--bold a-text--nowrap">{get(operatePackage, 'order.code', '')}</span>
                                        </Col>
                                    </Row>
                                    <Row className="a-flex gjustify-space-between">
                                        <Col span={8}>
                                            <span className="a-text--bold a-text--fz-12 a-text--nowrap">{get(operatePackage, 'agency.code', '')}</span>
                                        </Col>
                                        <Col span={16}>
                                            <span className="a-text--bold a-text--fz-12 a-text--nowrap">
                                                {get(operatePackage, 'package.customer_username', '')}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="a-stamp--barcode">
                                <Barcode
                                    width={2.4}
                                    height={50}
                                    displayValue={false}
                                    value={code}
                                    copyStyles={true}
                                />
                            </div>
                            <div className="a-stamp--print--bottom">
                                <div className="a-stamp--print--block">
                                    <div className="a-text--bold a-text--fz-12 a-text--nowrap">
                                        <span>{code} </span>
                                    </div>
                                    <div className="weight">
                                        <span>
                                            {get(packageData, 'package.weight_net') ? packageData.package.weight_net + 'Kg' : ''}
                                            {get(packageData, 'package.weight_converted') ? ' - QĐ: ' + packageData.package.weight_converted + 'Kg' : ''}
                                        </span>
                                    </div>
                                </div>

                                <div className="a-stamp--print--block">
                                    {/* <div className="bill-code">{packageData.order ? packageData.order.code : ''}</div> */}
                                    <div className="mt-1">
                                        <span>{aliases.join('-')}</span>
                                        {satisfiedEcommerce && !!canPrintOfficialStamp && <span className="ml-2">ONT</span>}
                                        {productGroupCode ? <span className="ml-2">{productGroupCode}</span> : null}
                                    </div>
                                    {volume && volume !== 0 ? (
                                        <div className="volumn">
                                            {numeral(volume).format('0.0000') || '--'}
                                            <span className="ml-1">
                                                m<sup>3</sup>
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderPrintNotOperationSupport() {
        const { packageData, classPrint, warehouseCode, agencySetting, globalSetting, items } = this.props
        const showAlias = showAliasAgency(agencySetting, globalSetting, permissions.CONFIG_SHOW_AGENCY_ALIAS)

        const { properties = [], services = [], warehouse_services } = packageData
        const propertyAliases = lodash.map(
            properties.filter(property => property.alias),
            'alias'
        )
        const serviceAliases = lodash.map(
            services.filter(service => service.alias),
            'alias'
        )

        const warehouseServiceAliases = lodash.map(
            warehouse_services.filter(warehouse_service => warehouse_service.alias),
            'alias'
        )
        const volume = lodash.get(packageData, 'package.volume', 0)
        const satisfiedEcommerce = propertyAliases.includes('X')
        const canPrintOfficialStamp = lodash.get(packageData, 'permissions.can_print_official_stamp')
        const operatePackage = lodash.get(packageData, 'operatePackage', false)
        const aliases = (satisfiedEcommerce && (canPrintOfficialStamp || operatePackage) ? propertyAliases.filter(alias => alias !== 'X') : propertyAliases)
            .concat(serviceAliases)
            .concat(warehouseServiceAliases)
        let productGroupCode = lodash.get(packageData, 'product_group.code')
        return items.map((code, index) => (
            <div key={index}>
                {index ? <div className="a-stamp--print-page-break">{''}</div> : ''}
                <div className={`a-stamp ${classPrint}`}>
                    <div id="a-stamp--print">
                        <div className="a-stamp--print--top">
                            <div className="a-stamp--warehouse">
                                <span>{warehouseCode}&nbsp;</span>
                                <span className="ml-2">{aliases.join('-')}</span>
                                {satisfiedEcommerce && !!canPrintOfficialStamp && <span className="ml-2">ONT</span>}
                                {productGroupCode ? <span className="ml-2">{productGroupCode}</span> : null}
                            </div>
                            <div className="a-stamp--code">{code}</div>
                        </div>
                        <div className="a-stamp--barcode">
                            <Barcode
                                width={2.4}
                                height={50}
                                displayValue={false}
                                value={code}
                                copyStyles={true}
                            />
                        </div>
                        <div className="a-stamp--print--bottom">
                            <div className="a-stamp--print--block gjustify-center a-text--bold a-text--uppercase">
                                {lodash.get(packageData, 'order.id_order_m2', '')}
                            </div>
                            <div className="a-stamp--print--block">
                                <div className="username">
                                    {lodash.get(packageData, 'order.id')
                                        ? lodash.get(packageData, 'order.customer_username', '')
                                        : lodash.get(packageData, 'customer_username', '')}
                                </div>
                                <div className="weight">
                                    <span>
                                        {lodash.get(packageData, 'package.weight_net') ? packageData.package.weight_net + 'Kg' : ''}
                                        {lodash.get(packageData, 'package.weight_converted') ? ' - QĐ: ' + packageData.package.weight_converted + 'Kg' : ''}
                                    </span>
                                </div>
                            </div>

                            <div className="a-stamp--print--block">
                                <div className="bill-code">{packageData.order ? packageData.order.code : ''}</div>
                                {volume && volume !== 0 ? (
                                    <div className="volumn">
                                        {numeral(volume).format('0.0000') || '--'}
                                        <span className="ml-1">
                                            m<sup>3</sup>
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                            <p className="a-stamp--agency">
                                {lodash.get(packageData, 'agency.identify_name') && showAlias
                                    ? lodash.get(packageData, 'agency.identify_name')
                                    : lodash.get(packageData, 'agency.name', '--') || '--'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    render() {
        const { packageData } = this.props
        const canPrintOperationSupport = lodash.get(packageData, 'permissions.can_use_label_from_operation_support', false)
        return canPrintOperationSupport ? this.renderPrintOperationSupport() : this.renderPrintNotOperationSupport()
    }
}

PrintStampMultiPackage.defaultProps = {
    title: '',
    warehouseCode: '',
    packageData: {},
    classPrint: '',
    onDidMount: () => {},
    items: [],
}

PrintStampMultiPackage.propTypes = {
    title: PropTypes.string,
    warehouseCode: PropTypes.string,
    packageData: PropTypes.object,
    items: PropTypes.array,
    classPrint: PropTypes.string,
    onDidMount: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintStampMultiPackage)
