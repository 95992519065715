import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const getNotes = createAction(ACTION.GET_NOTES.REQUEST);
export const successUpdateNote = createAction(ACTION.UPDATE_NOTE.SUCCESS);
export const successDeleteNote = createAction(ACTION.DELETE_NOTE.SUCCESS);
export const fetchPackageDetail = createAction(ACTION.FETCH_PACKAGE_DETAIL.REQUEST);
export const onReset = createAction(ACTION.RESET_NOTES);

export const clearSuggestOrder = createAction(ACTION.CLEAR_SUGGEST_ORDER);
export const clearSuggestCustomer = createAction(ACTION.CLEAR_SUGGEST_CUSTOMER);
export const setSuggestCustomerId = createAction((ACTION.SET_SUGGEST_CUSTOMER_ID));
export const setSuggestOrderId = createAction(ACTION.SET_SUGGEST_ORDER_ID)
