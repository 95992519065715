import React, { Component } from 'react'
import { Button, Checkbox, Col, Form, Icon, Input, Row, Select } from 'antd'
import * as _ from 'lodash'
import { t } from '../../../system/i18n'
import SelectCustomerContainer from '../../Customer/SelectCustomer/SelectCustomerContainer'
import FormPropertiesContainer from '../../Property/FormProperties/FormPropertiesContainer'
import { BAG_SHIPPING_TYPE, LIST_BAG_SHIPPING_TYPE } from '../../Bag/BagShippingType/constants'
import FormServicesContainer from '../../Service/FormServices/FormServicesContainer'
import SelectBagSizeContainer from '../../BagSize/SelectBagSize/SelectBagSizeContainer'
import SelectWarehouseContainer from '../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'
import SelectCreateShippingUserContainer from '../../ShippingUser/Select/SelectCreate'
import SelectCreatePartnerContainer from '../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer'
import Link from '../../../system/routing/Link'

import SelectAgency from './../../Setting/Agency/SelectAgency'
import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups'
import lodash, { get, isObject } from 'lodash'
import { router } from '../../../system/routing'

const Option = Select.Option
const TextArea = Input.TextArea

class EditForm extends Component {
    state = {
        form: {},
    }

    static getDerivedStateFromProps(props, state) {
        const dataTemplateBag = _.get(props, 'templateBag.bag_template')
        if (_.isEmpty(state.form) && _.isObject(dataTemplateBag)) {
            let newState = {}
            newState.form = { ...dataTemplateBag }
            newState.form.services = dataTemplateBag.id_services
            newState.form.properties = dataTemplateBag.id_properties
            if (newState.form.id_shipping_user > 0) {
                newState.form.subject_shipping = BAG_SHIPPING_TYPE.USER
            } else if (newState.form.id_shipping_partner > 0) {
                newState.form.subject_shipping = BAG_SHIPPING_TYPE.PARTNER
            }
            return { ...newState }
        }

        return { ...state }
    }

    onChangeSelectValue = (field, input) => {
        let value = _.has(input, 'value') ? input.value : input
        if (field === 'is_unchecked') {
            value = input.target.checked
        }
        let { form } = this.state
        form[field] = value
        if (field === 'id_customer') {
            let agencyId = undefined
            if (isObject(input)) {
                value = get(input, 'customer.id')
                agencyId = get(input, 'agency.id')
            }

            form['id_customer'] = value
            form['id_agency'] = agencyId ? agencyId : undefined
        }
        this.props.resetError(field)
        this.setState({ form })
    }

    onChangeServices = services => {
        let { form } = this.state
        form.services = services
        this.setState({ form })
    }

    onChangeInput = (field, e) => {
        let { form } = this.state
        form[field] = e.target.value
        this.props.resetError(field)
        this.setState({ form })
    }

    handleSubmit = e => {
        e.preventDefault()
        const { form } = this.state
        let data = _.pick(form, [
            'name',
            'type',
            'id_bag_size',
            'id_warehouse_input',
            'id_warehouse_destination',
            'subject_shipping',
            'receiver_name',
            'receiver_phone',
            'note',
            'services',
            'properties',
            'is_unchecked',
            'id_agency',
        ])

        if (data.subject_shipping === BAG_SHIPPING_TYPE.USER) {
            data.id_shipping_user = form.id_shipping_user
        } else if (data.subject_shipping === BAG_SHIPPING_TYPE.PARTNER) {
            data.id_shipping_partner = form.id_shipping_partner
        }

        if (data.type === 'SINGLE') {
            data.id_customer = form.id_customer
        }
        data.active = this.props.active
        this.props.updateTemplateBag(this.props.id, data)
    }

    getShippingUserName() {
        const { id_shipping_user } = this.state.form
        const { shippingUsers } = this.props
        let shippingUser = shippingUsers.filter(shippingUser => _.get(shippingUser, 'shipping_user.id', 0) === id_shipping_user)[0]
        return (
            _.get(shippingUser, 'shipping_user.full_name', '--') +
            ' (' +
            (!_.isNil(_.get(shippingUser, 'shipping_user.username', '--')) ? _.get(shippingUser, 'shipping_user.username', '--') : '--') +
            ')'
        )
    }

    getShippingPartnerName() {
        const { id_shipping_partner } = this.state.form
        const { shippingPartners } = this.props
        let shippingPartner = shippingPartners.filter(shippingPartner => _.get(shippingPartner, 'shipping_partner.id', 0) === id_shipping_partner)[0]
        return _.get(shippingPartner, 'shipping_partner.name', '')
    }

    getWarehouseName(params) {
        const { form } = this.state
        const { warehouses } = this.props
        return _.get(
            _.find(warehouses, warehouse => warehouse.id === form[params]),
            'name',
            '--'
        )
    }
    getBagSizeName() {
        const { id_bag_size } = this.state.form
        const { bagSizes } = this.props
        let bagSize = bagSizes.filter(bagSize => _.get(bagSize, 'bag_size.id', 0) === id_bag_size)[0]
        return _.get(bagSize, 'bag_size.name', '')
    }

    componentDidUpdate(prevProps) {
        const {
            templateBag: { agency },
        } = this.props

        if (!_.isEqual(agency, prevProps.templateBag.agency)) {
            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    id_agency: _.get(agency, 'id'),
                },
            }))
        }
    }

    render() {
        const bagTypes = [
            { key: 1, label: t('bag:label.multi_bag'), value: 'MULTIPLE' },
            { key: 2, label: t('bag:label.single_bag'), value: 'SINGLE' },
        ]
        const { form } = this.state
        const { errors, loadingShippingUser, updating, inModal } = this.props
        const shippingTypes = [...LIST_BAG_SHIPPING_TYPE]
        return (
            <Form
                onSubmit={this.handleSubmit}
                className="a-form form--package">
                <Row
                    gutter={{ lg: 24, xl: 32 }}
                    className="a-block-info mb-5">
                    <Col
                        lg={{ span: 16 }}
                        xl={{ span: 19 }}
                        xxl={{ span: 19 }}>
                        <Row gutter={{ lg: 24, xl: 32 }}>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.name')}
                                    labelCol={{ span: 24 }}
                                    required={true}
                                    help={errors.name}
                                    validateStatus={errors.name ? 'error' : null}>
                                    <Input
                                        className={'a-input _a-input-name '}
                                        placeholder={t('template_bag:input_name')}
                                        value={form.name}
                                        onChange={this.onChangeInput.bind(this, 'name')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:type')}
                                    labelCol={{ span: 24 }}>
                                    <Select
                                        className="a-select _a-select-bag-type"
                                        showSearch={true}
                                        value={form.type}
                                        onChange={this.onChangeSelectValue.bind(this, 'type')}
                                        disabled={false}
                                        dropdownClassName="_dropdown_bag_type"
                                        suffixIcon={
                                            <Icon
                                                type="down"
                                                className="icon__dropdown__select"
                                                onChange={this.handleSelectChange}
                                            />
                                        }>
                                        {bagTypes.map(item => (
                                            <Option
                                                key={item.value}
                                                value={item.value}
                                                className={'_type-bag__item _type _type' + item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:customer')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_customer}
                                    validateStatus={errors.id_customer ? 'error' : null}>
                                    {form.type === 'SINGLE' && (
                                        <SelectCustomerContainer
                                            autoSelect={true}
                                            emptyOption={false}
                                            is_shipper={true}
                                            showName={true}
                                            className="a-select a-select--search-content _id_customer"
                                            value={form.id_customer || ''}
                                            dispatchObject={true}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_customer')}
                                            dropdownClassName={'_dropdown_id_customer'}
                                        />
                                    )}

                                    {form.type !== 'SINGLE' && (
                                        <Select
                                            disabled={true}
                                            className={'_MULTIPLE'}
                                            placeholder={t('bag:label.customer_placeholder')}></Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ lg: 24, xl: 32 }}>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.size')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_bag_size}
                                    validateStatus={errors.id_bag_size ? 'error' : null}>
                                    <SelectBagSizeContainer
                                        placeholder={this.getBagSizeName()}
                                        value={form.id_bag_size}
                                        errors={_.pick(errors, ['id_bag_size'])}
                                        autoSelect={false}
                                        disabled={false}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_bag_size')}
                                        dropdownClassName={'_dropdown_id_bag_size'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 18 }}
                                xxl={{ span: 18 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item  "
                                    label={t('template_bag:property')}
                                    labelCol={{ span: 24 }}>
                                    <FormPropertiesContainer
                                        onChange={this.onChangeSelectValue.bind(this, 'properties')}
                                        errors={_.pick(errors, ['properties'])}
                                        values={form.properties}
                                        classUl="property--package _properties"
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:warehouse_create')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_warehouse_input}
                                    validateStatus={errors.id_warehouse_input ? 'error' : null}>
                                    <SelectWarehouseContainer
                                        allowClear
                                        emptyOption={false}
                                        placeholder={this.getWarehouseName('id_warehouse_input')}
                                        className="a-select a-select--search-content _id_warehouse_input"
                                        value={form.id_warehouse_input}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_input')}
                                        dropdownClassName={'_dropdown__id_warehouse_input'}
                                        autoSelected={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:label.id_warehouse_destination')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_warehouse_destination}
                                    validateStatus={errors.id_warehouse_destination ? 'error' : null}>
                                    <SelectWarehouseContainer
                                        allowClear
                                        placeholder={this.getWarehouseName('id_warehouse_destination')}
                                        emptyOption={false}
                                        className="a-select a-select--search-content _id_warehouse_destination"
                                        isPermission={false}
                                        value={form.id_warehouse_destination}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_destination')}
                                        dropdownClassName={'_dropdown__id_warehouse_destination'}
                                        autoSelected={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.receiver')}
                                    labelCol={{ span: 24 }}
                                    help={errors.receiver_name}
                                    validateStatus={errors.receiver_name ? 'error' : null}>
                                    <Input
                                        className={'a-input _a-input-receiver'}
                                        value={form.receiver_name}
                                        onChange={this.onChangeInput.bind(this, 'receiver_name')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.phone')}
                                    labelCol={{ span: 24 }}
                                    help={errors.receiver_phone}
                                    validateStatus={errors.receiver_phone ? 'error' : null}>
                                    <Input
                                        className={'a-input _a-input-phone'}
                                        value={form.receiver_phone}
                                        onChange={this.onChangeInput.bind(this, 'receiver_phone')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:shipping_type')}
                                    labelCol={{ span: 24 }}>
                                    <Select
                                        className="a-select  _a-select-shipping-type"
                                        showSearch={true}
                                        defaultValue={shippingTypes[0].id}
                                        value={form.subject_shipping}
                                        onChange={this.onChangeSelectValue.bind(this, 'subject_shipping')}
                                        disabled={false}
                                        suffixIcon={
                                            <Icon
                                                type="down"
                                                className="icon__dropdown__select"
                                            />
                                        }>
                                        {shippingTypes.map(item => (
                                            <Option
                                                key={item.id}
                                                value={item.id}
                                                className={'_subject_shipping__item'}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:shipping_name')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_shipping_user || errors.id_shipping_partner}
                                    validateStatus={errors.id_shipping_user || errors.id_shipping_partner ? 'error' : null}>
                                    {form.subject_shipping === BAG_SHIPPING_TYPE.PARTNER && (
                                        <SelectCreatePartnerContainer
                                            autoSelect
                                            disabled={false}
                                            getPartnersByWarehouse
                                            placeholder={this.getShippingPartnerName()}
                                            selectProps={{ style: { width: '100%' } }}
                                            value={form.id_shipping_partner}
                                            warehouseId={form.id_warehouse_input}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_partner')}
                                        />
                                    )}
                                    {form.subject_shipping === BAG_SHIPPING_TYPE.USER && (
                                        <SelectCreateShippingUserContainer
                                            placeholder={this.getShippingUserName()}
                                            autoSelect={true}
                                            emptyOption={false}
                                            disabled={this.props.loading}
                                            loading={loadingShippingUser}
                                            className="a-select a-select--search-content _id_shipping_user"
                                            value={form.id_shipping_user > 0 ? form.id_shipping_user : null}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_user')}
                                            selectProps={{ style: { width: '100%' } }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('bag:label.agency')}
                                    labelCol={{ span: 24 }}>
                                    <SelectAgency
                                        allowClear
                                        className="_id-agencies"
                                        dropdownClassName="_dropdown-id-agencies"
                                        // mode="multiple"
                                        optionFilterProp="children"
                                        value={form.id_agency}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_agency')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('template_bag:note')}
                                    labelCol={{ span: 24 }}
                                    validateStatus={errors.note ? 'error' : null}
                                    help={errors.note}>
                                    <TextArea
                                        className="a-input--textarea _note"
                                        autosize={{ minRows: 1, maxRows: 3 }}
                                        spellCheck={false}
                                        value={form.note}
                                        onChange={this.onChangeInput.bind(this, 'note')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('bag:label.unchecked')}
                                    labelCol={{ span: 24 }}>
                                    <Checkbox
                                        className={'_is_unchecked'}
                                        checked={form.is_unchecked}
                                        onChange={this.onChangeSelectValue.bind(this, 'is_unchecked')}>
                                        {t('bag:label:unchecked')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 5 }}
                        xxl={{ span: 5 }}
                        className="pl-0">
                        <div className="a-inline-edit service-edit">
                            <div className="a-inline-edit--input">
                                <FormServicesContainer
                                    values={form.services}
                                    onChange={this.onChangeServices}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        className="a-package--action only-action">
                      {!inModal &&  <Button className="a-btn a-btn--back-package a-btn--default _btn-cancel">
                            <Link to="config.template-bags.list">{t('template_bag:cancel')}</Link>
                        </Button>}
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--save-package _btn-update"
                            loading={updating}>
                            {t('template_bag:btn_update')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

EditForm = withConsignServiceGroups(EditForm)

export { EditForm }
