import React from 'react';

const Truck2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0)">
        <path className="c" d="M8.363,11.5H6.3a.667.667,0,0,0-.652.747v.015a.649.649,0,0,0,.652.571H8.359a.649.649,0,0,0,.651-.572v-.015a.663.663,0,0,0-.647-.746Z" />
        <path className="c" d="M16,7.5a2.667,2.667,0,0,0-2.667-2.667H11.167A.167.167,0,0,1,11,4.667v-1.5A1.333,1.333,0,0,0,9.667,1.833H1.333A1.333,1.333,0,0,0,0,3.167v9a.667.667,0,0,0,.667.667H.841a.167.167,0,0,0,.123-.054.169.169,0,0,0,.043-.127,2.237,2.237,0,0,1,.3-1.295.172.172,0,0,0,.023-.085V3.833A.667.667,0,0,1,2,3.167H9a.667.667,0,0,1,.667.667V10.5a.167.167,0,0,0,.267.133A2.333,2.333,0,0,1,13.667,12.5a1.359,1.359,0,0,1-.009.149.167.167,0,0,0,.166.185h.843A1.333,1.333,0,0,0,16,11.5v-4Zm-1.333,0v.488a.167.167,0,0,1-.169.167l-1.667-.025a.167.167,0,0,1-.164-.167V6.333a.167.167,0,0,1,.167-.167h.5A1.333,1.333,0,0,1,14.667,7.5Z" />
        <circle className="c" cx="1.667" cy="1.667" r="1.667" transform="translate(9.667 10.833)" />
        <circle className="c" cx="1.667" cy="1.667" r="1.667" transform="translate(1.667 10.833)" />
        <rect className="c" width="2.667" height="2.667" rx="0.333" transform="translate(4 4.167)" />
        <path className="c" d="M5,10.167v-2a.333.333,0,0,0-.333-.333h-2a.333.333,0,0,0-.333.333v2a.349.349,0,0,0,.333.333h2A.333.333,0,0,0,5,10.167Z" />
        <rect className="c" width="2.667" height="2.667" rx="0.333" transform="translate(6 7.833)" />
      </g>
    </g>
  </svg>
);

export default Truck2;
