import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let agencySetting = (state = [], action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_SETTING.SUCCESS:
            return lodash.get(payload, 'settings.agencySetting', []) || [];
        case ACTION.CLEAR_STATE:
            return [];
        default:
            return state;
    }
};

let setting = (state = [], action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_SETTING.SUCCESS:
            return lodash.get(payload, 'settings.globalSetting', []) || [];
        case ACTION.CLEAR_STATE:
            return [];
        default:
            return state;
    }
};


export default combineReducers({
    agencySetting,
    setting
});
