import React from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SearchableComponent from '../../Common/components/SearchableComponent';
import {setMenuActive} from './../../Common/actions';
import UpdateDeliveryNote from './components/UpdateDeliveryNote';
import lodash from "lodash";
import {getErrors} from './../selectors';
import {getLastmileCarrierSuggest} from './../../ShippingPartner/ListShippingPartner/actions';

const mapStateToProps = (state) => {
    const deliveryNote = selectors.getState(state, 'deliveryNote');

    return {
        bagIds: selectors.getBagIds(state),
        barcodes: selectors.getBarcodes(state),
        customerDetailInfo: selectors.getState(state, 'customerDetailInfo'),
        loadingCustomerDetailInfo: selectors.getState(state, 'loadingCustomerDetailInfo'),
        deliveryNote: deliveryNote,
        loading: selectors.getState(state, 'loading.deliveryNote'),
        listPackageByBag: lodash.get(state, 'deliveryNote.createDeliveryNote.listPackageByBag'),
        packageIds: selectors.getPackageIds(state),
        updating: selectors.getState(state, 'loading.onUpdate'),
        errors: getErrors(selectors.getState(state, 'errors', {})),
        customerBagPackages: selectors.getState(state, 'customerBagPackages'),
        loadingCustomerBagPackages: selectors.getState(state, 'loadingCustomerBagPackages')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeliveryNote: (deliveryNoteId) => dispatch(actions.fetchDeliveryNote(deliveryNoteId)),
        updateDeliveryNote: data => dispatch(actions.updateDeliveryNote(data)),
        clearState: () => dispatch(actions.clearState()),
        getCustomerBagPackages: payload => dispatch(actions.getCustomerBagPackages(payload)),
        setMenuActive: (menu) => dispatch(setMenuActive(menu)),
        removeBarcode: code => dispatch(actions.removeBarcode(code)),
        resetError: data => dispatch(actions.resetError(data)),
        getLastmileCarrierSuggest: (params) => dispatch(getLastmileCarrierSuggest(params))
    };
};

class UpdateDeliveryNoteContainer extends SearchableComponent {
    componentWillUnmount() {
        this.props.clearState();
    }

    onChangeFilter(filter) {
        this.props.fetchDeliveryNote(filter.id);
    }

    handleRemoveReadyForDelivery = code => {
        const { removeBarcode } = this.props;

        removeBarcode(code);
    };

    handleSubmit = data => {
        const { updateDeliveryNote } = this.props;

        updateDeliveryNote(data);
    };

    handleReloadCustomerBagPackages = event => {
        const { deliveryNote, getCustomerBagPackages } = this.props;

        event.preventDefault();

        getCustomerBagPackages({
            id_customer: lodash.get(deliveryNote, 'delivery_note.id_customer'),
            id_delivery_note: lodash.get(deliveryNote, 'delivery_note.id'),
            id_warehouse: lodash.get(deliveryNote, 'delivery_note.id_warehouse'),
        });
    };

    render() {
        return (
            <UpdateDeliveryNote
                {...this.props}
                onRemoveReadyForDelivery={this.handleRemoveReadyForDelivery}
                onReloadCustomerBagPackages={this.handleReloadCustomerBagPackages}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateDeliveryNoteContainer);
