import {notification} from 'antd';
import {t} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';

export function* createPackageSameBarcode(action) {
    yield processApiRequest(
        ACTION.CREATE_PACKAGE_SAME_BARCODE,
        () => apiService.createPackageSameBarcode(action.payload),
        action.payload
    );
}

export function* onCreatePackageSameBarcodeSuccess() {
    yield notification.success({message: t('package:create_same_barcode.message_create_package_success')});
}

export function* onCreatePackageSameBarcodeFailed() {
    yield notification.error({message: t('package:create_same_barcode.message_create_package_failed'), duration: 0});
}
