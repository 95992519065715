import React from 'react';
import { Icon, Table } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

const BagAreasTable = ({ bagAreas, loading, t, onRowClick }) => {
    const columns = [{
        key: 'code',
        dataIndex: 'bag_area.code',
        title: t('bag_area:area_code'),
        render: (text, record) => ({
            props: {
                className: clsx('a-notification-dot a-notification-dot--table _area_code', {
                    'active': get(record, 'bag_area.active'),
                    'unactive': !get(record, 'bag_area.active')
                })
            },
            children: text
        })
    }, {
        key: 'name',
        dataIndex: 'bag_area.name',
        title: t('bag_area:area_name'),
        render: text => ({
            props: {
                className: '_area_name'
            },
            children: text
        })
    }, {
        align: 'right',
        key: 'actions',
        render: (text, record) => (
            <Icon
                className="ahover-pointer a-text--blue _icon-btn-edit"
                type="edit"
                onClick={onRowClick.bind(undefined, record)}
            />
        )
    }];

    return (
        <Table
            className="a-table--antd a-table--antd-scroll-x _a-table-bag-area"
            columns={columns}
            dataSource={bagAreas}
            loading={loading}
            pagination={false}
            rowKey={row => get(row, 'bag_area.id')}
        />
    );
};

export default withTranslation()(BagAreasTable);

