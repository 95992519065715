/* eslint-disable */
import React, {Component} from 'react';
import {
    Layout,
    Button,
    Icon,
    Form,
    Row,
    Col,
    Input,
} from 'antd/lib/index';
import {ReactComponent as IconImport} from '../../../../../resources/images/Import.svg';
import {ReactComponent as IconExport} from '../../../../../resources/images/Export.svg'
const TextArea = Input.TextArea;
const {Content} = Layout;


class Employee extends Component {
    render() {
        let {scroll} = this.props;
        return (
            <Content className={"a-content is-action-fixed-bottom a-barcode-scan " + (scroll ? 'scroll-is-sider' : '')}>
                <Form onSubmit={this.handleSubmit} className="a-block">
                    <div className="block--primary a-content--bag">
                        <div className="title create-bag">
                            <div className="left">
                                <span className="a-text--bold">Thông tin phiếu xuất </span>
                            </div>
                            <div className="right">
                                <p class="mb-0 a-text--fz-16 a-text--nowrap ">Khách hàng - <span
                                    className="a-text--medium _employee_code">KH0021-322</span></p>
                            </div>
                        </div>
                        <div className="body not-boxshadow ">
                            <Row gutter={{lg: 24, xl: 48}}>
                                <div className="a-block-info ">
                                    <Row gutter={{lg: 24, xl: 32}} className="info-customer-view ml-0 mr-0">
                                        <Col lg={{span: 10}} xl={{span: 7}} xxl={{span: 7}}>
                                            <div className="info">
                                                <div className="avatar">
                                                    <img src={require('../../../../../resources/images/ngoc-trinh-be-9.jpg')}/>
                                                </div>
                                                <div className="info-detail">
                                                    <p className="name">Lê Hoàng Thuỳ Trang</p>
                                                    <span className="phonenumber">0868.678.678</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 14}} xl={{span: 17}} xxl={{span: 17}}>
                                            <Row gutter={{lg: 24, xl: 32}}>
                                                <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 8}} className="info--group mb-0">
                                                    <label className="info--label a-text--regular">Số dư tài khoản</label>
                                                    <span className="info--value a-text--blue a-text--bold">+ 999.500.000 đ</span>
                                                </Col>
                                                <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}} className="info--group mb-0">
                                                    <label className="info--label a-text--regular">Số tiền thanh toán hiện tại</label>
                                                    <span className="info--value a-text--orange a-text--bold">+ 999.500.000 đ</span>
                                                </Col>
                                                <Col lg={{span: 12}} xl={{span: 9}} xxl={{span: 8}} className="info--group mb-0 mt-lg-5">
                                                    <label className="info--label a-text--regular">Số tiền chưa thanh toán hiện tại</label>
                                                    <span className="info--value a-text--red a-text--bold">+ 999.500.000 đ</span>
                                                </Col>
                                            </Row>

                                        </Col>

                                    </Row>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-form pt-5 mr-0 ml-0">
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="a-form--group">
                                            <Form.Item className="a-form__item  "
                                                       label="Tên người nhận"
                                                       labelCol={{span: 24}}>
                                                <Input placeholder={"Nhập tên người nhận"}
                                                       className="a-input _person_receive_customer"/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                                             className="a-form--group">
                                            <Form.Item className="a-form__item  "
                                                       label="Số tiền thu hộ (VNĐ)"
                                                       labelCol={{span: 24}}>
                                                <Input placeholder={"Nhập số tiền thu hộ"}
                                                       className="a-input _money_collect_on_behalf"/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                                             className="a-form--group mb-0">
                                            <Form.Item className="a-form__item  " label="Ghi chú"
                                                       labelCol={{span: 24}}>
                                                < TextArea className="a-input--textarea _note_export_employee"
                                                           autosize={{minRows: 1, maxRows: 3}}
                                                           allowClear={true}
                                                           spellCheck={false}
                                                           placeholder={"Ghi chú"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </div>
                    </div>
                </Form>
                <Row gutter={16} >
                    <Col lg={{span: 24}} xl={{span: 12}}>
                        <div className="a-content__top a-content__top--page-list pl-5 pr-5 mr-0">
                            <div className="a-content--page-list__title customer_export-order gw-100 gflex gjustify-space-between galign-center ">
                                <h3>Danh Sách Trong Kho(20)</h3>
                                <a href="#"><Icon type="sync" className="mr-1" />Làm mới </a>
                            </div>
                        </div>
                        <div className="a-content__body bg-white a-content__body--page-list p-5 mr-0">
                            <div className="a-table--responsive">
                                <table className="a-table a-table--isdata--warning-error">
                                    <thead>
                                    <tr>
                                        <th>Mã Xuất</th>
                                        <th>Đơn hàng</th>
                                        <th>Cân nặng (kg)</th>
                                        <th>Kích thước (cm<sup>3</sup>)</th>
                                        <th className="sticky-right"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td>90kg</td>
                                        <td>100 cm <sup>3</sup></td>
                                        <td className="sticky-right"> <IconImport/></td>
                                    </tr>
                                    </tbody>
                                    <tbody>
                                    <tr>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td>90kg</td>
                                        <td>100 cm <sup>3</sup></td>
                                        <td className="sticky-right"> <IconImport/></td>
                                    </tr>
                                    </tbody>

                                    <tbody className="warning">
                                    <tr className="data">
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td>90kg</td>
                                        <td>100 cm <sup>3</sup></td>
                                        <td className="sticky-right data-warning"> <IconImport/></td>
                                    </tr>
                                    <tr className="message">
                                        <td colSpan="5">
                                                <span className="a-text--black-45">
                                                    <Icon type='info-circle' theme="filled"
                                                          className="a-text--orange mr-2"/>
                                                    Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="content-list--empty a-hidden">
                                <img src={require('../../../../../resources/images/boxempty.png')}/>
                                <span className="info a-text--uppercase">CHƯA CÓ MÃ QUÉT NÀO </span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 24}} xl={{span: 12}} className="mt-lg-5">
                        <div className="a-content__top a-content__top--page-list pl-5 ">
                            <div className="a-content--page-list__title">
                                <h3>Danh Sách Xuất(20)</h3>
                            </div>
                        </div>
                        <div className="a-content__body bg-white a-content__body--page-list p-5">
                            <div className="a-table--responsive">
                                <table className="a-table a-table--isdata--warning-error">
                                    <thead>
                                    <tr>
                                        <th className="sticky-left"></th>
                                        <th>Mã Xuất</th>
                                        <th>Cân nặng (kg)</th>
                                        <th>Kích thước (cm<sup>3</sup>)</th>
                                        <th>Kích thước</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="sticky-left"><IconExport/></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td>90kg</td>
                                        <td>100 cm <sup>3</sup></td>
                                    </tr>
                                    </tbody>
                                    <tbody className="warning">
                                    <tr className="data">
                                        <td className="sticky-left data-warning"><IconExport/></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td><a href="javascript:void(0)" className="a-text--nowrap"><img
                                            className="logo-shop"
                                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                            alt=""/>987293847928927</a></td>
                                        <td>90kg</td>
                                        <td>100 cm <sup>3</sup></td>
                                    </tr>
                                    <tr className="message">
                                        <td colSpan="5">
                                                <span className="a-text--black-45">
                                                    <Icon type='info-circle' theme="filled"
                                                          className="a-text--orange mr-2"/>
                                                    Lỗi tại thông tin Hành động, không có dữ liệu Nhập</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="content-list--empty a-hidden">
                                <img src={require('../../../../../resources/images/boxempty.png')}/>
                                <span className="info a-text--uppercase">CHƯA CÓ MÃ QUÉT NÀO </span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="a-content--action-fixed-bottom">
                    <a href="#" className="mr-auto">Tạo phiếu mới</a>
                    <Button
                        className={`a-btn a-btn--primary a-btn--save-export _save-export-employee`} size="large">Lưu</Button>
                </div>
            </Content>
        );
    }
}

export default Employee;
