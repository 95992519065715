import React from 'react';
import {connect} from 'react-redux';
import UpdateWarehouse from "./components"
import SearchableComponent from "../../Common/components/SearchableComponent";
import * as actions from "../DetailWarehouse/actions";
import {setMenuActive} from "../../Common/actions";
import {updateWarehouse} from "./actions";
import * as detailWarehouseSelectors from "../DetailWarehouse/selectors";
import * as updateWarehouseSelectors from "./selectors";
import lodash from "lodash";

const mapStateToProps = (state, props) => ({
	warehouseData: detailWarehouseSelectors.getState(state, 'detail', {}),
	loading: detailWarehouseSelectors.getState(state, 'loading', false) ||
		updateWarehouseSelectors.getState(state, 'loading', false) ||
		lodash.get(state, "location.loading", false),
});
const mapDispatchToProps = (dispatch) => ({
	fetchDetailWarehouse: (warehouseId) => dispatch(actions.fetchDetailWarehouse(warehouseId)),
	updateWarehouse: (warehouseId) => dispatch(updateWarehouse(warehouseId)),
	clearState: () => dispatch(actions.clearState()),
	setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class UpdateWarehouseContainer extends SearchableComponent {
	componentWillUnmount() {
		this.props.clearState();
	}

	componentDidMount() {
		super.componentDidMount();
		this.props.setMenuActive('config/warehouse')
	}

	onChangeFilter(filter) {
		this.props.fetchDetailWarehouse(filter.id);
	}

	render() {
		return <UpdateWarehouse {...this.props}/>
	}
}


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateWarehouseContainer);
