import React from 'react';
import {Col, Row} from "antd";
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { Skeleton } from 'antd';

class  InfoCustomerDeliveryNoteLoading extends React.Component {
    render() {
        return (
            <div className="a-block-info abg-white  amb-16 apt-24 apl-8 apr-8 pb-0">
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="ml-0 mr-0">
                    <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                         className="info--group">
                        <Skeleton active paragraph={{ rows: 1 }} className="askeleton-list"/>
                    </Col>
                </Row>
            </div>
        )
    }
}

 InfoCustomerDeliveryNoteLoading.defaultProps = {
    delivery_note: {},
};

 InfoCustomerDeliveryNoteLoading.propTypes = {
    deliveryNote: PropTypes.object,
};

export default withTranslation()( InfoCustomerDeliveryNoteLoading);