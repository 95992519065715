import React from 'react';
import lodash from "lodash";
import systemAvatar from "../../../../resources/images/log-sys.svg";
import {dateFormatter} from "../../../Common/services/format";
import {Skeleton} from "antd";

class ChatItem extends React.Component {
    renderAttachmentItem = (item) => {
        return (
            <a key={lodash.uniqueId()} target="_self" rel="noopener noreferrer" href={"#top"}
               onClick={() => this.props.onShowImage(item)}
               className="attachment-item _attachment_item_image">
                <img alt="" src={item} className="img" />
            </a>
        );
    };

    render() {
        const {item} = this.props;
        const chatName = lodash.get(item, 'creator.name') || lodash.get(item, 'creator.username');
        const chatTime = dateFormatter.full(item.time);
        const chatAvatar = lodash.get(item, 'creator.avatar', systemAvatar) || systemAvatar;
        const images = lodash.get(item, 'payload.images', []) || [];
        const content = lodash.get(item, 'payload.comment', "") || "";
        if (item.loading) {
            return <div className="a-chatbox--log user _chat_item" key={item.id}>
                <Skeleton avatar active paragraph={{rows: 1}} className="askeleton-avatar"/>
                <div className="a-chatbox--log--detail">
                    <Skeleton active paragraph={{rows: 1}} size={'small'} className="askeleton-list"/>
                </div>
            </div>
        }
        return (
            <div className="a-chatbox--log user _chat_item" key={item.id}>
                <img className="img-ads _chat_avatar" src={chatAvatar}
                     alt="Log system"/>
                <div className="a-chatbox--log--detail">
                    <p className="title">{chatName}, {chatTime}</p>
                    <span className="detail">{content}</span>
                    {images.length > 0 && (
                        <div className="attachments">
                            {images.map(a => this.renderAttachmentItem(a))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

ChatItem.defaultProps = {
    item: {}
};

export {ChatItem}
