import React from 'react';
import {Icon, Layout, Switch} from "antd";
import {connect} from "react-redux";
import * as _ from 'lodash';
import {CreateForm} from "./CreateForm";
import Link from "../../../system/routing/Link";
import {t} from "../../../system/i18n";
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as shippingPartnerSelectors from '../../ShippingPartner/ListShippingPartner/selectors';
import * as shippingUserSelectors from '../../ShippingUser/Select/selectors';
import * as shippingUserActions from '../../ShippingUser/actions';
import pageService from "../../Common/Page/pageService";
import {setMenuActive} from "../../Common/actions";

import withConsignServiceGroups from './../../../hocs/withConsignServiceGroups';

const Content = Layout.Content;


const mapStateToProps = (state) => {
    return {
        creating: state.templateBag.creating,
        errors: selectors.getErrors(_.get(state, 'templateBag.createErrors', {})),
        shippingPartners: shippingPartnerSelectors.getListShippingPartnerSuggest(state),
        shippingUsers: shippingUserSelectors.shippingUsersSuggestActive(state),
        loadingShippingUser: shippingUserSelectors.getState(state, 'shippingUser.loading', false)
    }
};

const mapDispatchToProps = (dispatch) => ({
    createTemplateBag: (data) => dispatch(actions.createTemplateBag(data)),
    resetError: (field) => dispatch(actions.resetError(field)),
    fetchShippingUsers: () => dispatch(shippingUserActions.fetchShippingUsersSuggest()),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class TemplateBagCreateContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true
        }
    }


    componentDidMount() {
        this.props.fetchShippingUsers();
        pageService.setTitle(t("template_bag:page_title"));
        this.props.setMenuActive("config/template-bags");
    }

    componentWillUnmount() {
        this.props.resetError('all');
    }

    onChangeStatus = (checked) => {
        this.setState({active: checked});
    };

    render() {
        const {active} = this.state;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <Link to="config.template-bags.list" className={"a-text--gray _btn-back"}>
                        <Icon type="arrow-left" className={"mr-2 "}/> {t('template_bag:back_to_list')}
                    </Link>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t('template_bag:info')}</h3>
                        <div>
                            <label className={"a-text--fz-12 a-text--gray mr-2"}>{t('template_bag:status')}</label>
                            <Switch
                                defaultChecked
                                size="small"
                                className={"_switch-active"}
                                onChange={this.onChangeStatus}
                            />
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <CreateForm
                            {...this.props}
                            active={active}
                        />
                    </div>
                </div>
            </Content>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withConsignServiceGroups(TemplateBagCreateContainer));
