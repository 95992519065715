import React from 'react';
import { isFunction, omit } from 'lodash';
import PropTypes from 'prop-types';

import { router, url } from './';

/**
 * Tạo element <a> xử lý redirect theo router
 * Có thể truyền các thuộc tính mặc định như className, id, ...
 *
 * Example:
 * <Link href="/path/to/page">Text</Link>
 * <Link to="route_name" params={{key: value}}>Text</Link>
 */
class Link extends React.Component {
    onClick = (url, event) => {
        event.preventDefault();
        event.stopPropagation();

        const { isNewTab, state, onClick } = this.props;

        if (isFunction(onClick)) {
            onClick();
        } else {
            if (isNewTab === true || (event.ctrlKey && url)) {
                window.open(url, "_blank");
                return true;
            }
    
            if (url) {
                router.redirect(url, state);
            }
        }
    };

    render() {
        const { children, queries = {}, params = {}, to } = this.props;
        const props = omit(this.props, ['to', 'params', 'children', 'isNewTab']);

        if (to) {
            props.href = url.to(to, params, queries);
        }

        return (
            <a {...props} onClick={this.onClick.bind(this, props.href)}>{children}</a>
        );
    }
}

Link.defaultProps = {
    to: '',
    params: {},
    href: '',
    isNewTab: false,
};

Link.propTypes = {
    to: PropTypes.string,
    params: PropTypes.object,
    href: PropTypes.string,
    isNewTab: PropTypes.bool
};

export default Link;
