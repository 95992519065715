import React from 'react';
import FormSearch from "./components/FormSearch";
import List from "./components";
import {Layout, notification} from "antd";
import pageService from "../../../Common/Page/pageService";
import {setMenuActive} from "../../../Common/actions";
import {connect} from "react-redux";
import SearchableComponent from "../../../Common/components/SearchableComponent";
import {t} from "../../../../system/i18n"
import authService from "../../../Auth/authService";
import apiService from "../apiService";
import lodash from "lodash"
import {LIST_COLUMN} from "./constants";
import {Link} from "../../../../system/routing";
import {dateFormatter} from "../../../Common/services/format";

const {Content} = Layout;

class ListContainer extends SearchableComponent {
    state = {
        delivery_notes: [],
        loading: false,
        pagination: {}
    };

    componentDidMount () {
        super.componentDidMount();
        pageService.setTitle(t('transport_warehouse_delivery_note_list'));
        this.props.setMenuActive('delivery-notes/transfer-warehouse');
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter (filter) {
        this.getDeliveryNotes(filter);
    }

    getDeliveryNotes = async (filter) => {
        this.setState({
            loading: true
        })
        try {
            const data = await apiService.list(filter);
            const delivery_notes = lodash.get(data, 'data.delivery_notes_transport_warehouse').map(item => {
                const warehouse = lodash.get(item, 'warehouse', {});
                const creator = lodash.get(item, 'creator', {});
                const exporter = lodash.get(item, 'exporter', {});
                const deliveryNote = lodash.get(item, 'delivery_note_transport_warehouse', {});
                const shippingPartner = lodash.get(item, 'shipping_partner', {});
                const bagsNumber =  lodash.get(item, 'outside_bags.length', 0);

                return {
                    key: deliveryNote.id,
                    [LIST_COLUMN.CODE]: (
                        <Link
                            isNewTab
                            params={{
                                id: deliveryNote.id
                            }}
                            to="delivery-notes.transport-warehouse.detail"
                        >
                            {deliveryNote.code}
                        </Link>
                    ),
                    [LIST_COLUMN.STATUS]: t("delivery_note:status." + deliveryNote.status),
                    [LIST_COLUMN.CREATE_AT]: deliveryNote[LIST_COLUMN.CREATE_AT] ? dateFormatter.dateTime(deliveryNote[LIST_COLUMN.CREATE_AT]) : '--',
                    [LIST_COLUMN.EXPORTED_AT]: deliveryNote[LIST_COLUMN.EXPORTED_AT] ? dateFormatter.dateTime(deliveryNote[LIST_COLUMN.EXPORTED_AT]) : '--',
                    [LIST_COLUMN.ID_SHIPPING_PARTNER]: !lodash.isNil(shippingPartner) ? shippingPartner.name + " (" + shippingPartner.code + ")" : "--",
                    [LIST_COLUMN.PARTNER_TRACKING_NUMBER]: lodash.get(deliveryNote, 'partner_tracking_number') ? deliveryNote.partner_tracking_number : '--',
                    [LIST_COLUMN.PACKET_NUMBER]: bagsNumber,
                    [LIST_COLUMN.ID_WAREHOUSE]: warehouse ? warehouse.code : "--",
                    [LIST_COLUMN.ID_CREATOR]: creator ? creator.name + " (" + creator.username + ")" : "--",
                    [LIST_COLUMN.ID_EXPORTER]: exporter ? exporter.name + " (" + exporter.username + ")" : "--",
                }
            });
            this.setState({
                delivery_notes,
                pagination: lodash.get(data, 'data.pagination', {})
            })
        } catch (e) {
            notification.error({message: t("delivery_note:message.fetch_delivery_notes_errors")})
        }
        this.setState({
            loading: false
        })
    }

    getCurrentFilter () {
        return {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            sort_direction: this.props.sort_direction || 'desc',
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render () {
        return (
            <Content className="a-content a-content--page-list">
                <FormSearch
                    {...this.props}
                    filter={this.getCurrentFilter()}
                    input={this.getCurrentFilter()}
                    loading={this.state.loading}
                    onSearch={this.onSearch}
                />
                <List
                    {...this.props}
                    delivery_notes={this.state.delivery_notes}
                    filter={this.getCurrentFilter()}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onSearch={this.onSearch}
                />
            </Content>
        )
    }
}

const mapStateToProps = () => {
    return {
        user: authService.user(),
    }
};
const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListContainer)
