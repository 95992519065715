function CurrencyFormat() {
    let currency = {
        code: 'CNY',
        precision: 2,
        format: '¥{amount}',
        thousands_separator: ',',
        decimal_separator: '.',
    }

    function setCurrency(newCurrency) {
        currency = newCurrency ? newCurrency : currency
    }

    function getCurrency() {
        return currency
    }

    return {
        currency: getCurrency,
        setCurrency: setCurrency,
    }
}

export default CurrencyFormat()
