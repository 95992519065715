import i18n from './i18n';
import Translator from './Translator';

let translator = new Translator(i18n);

const t = i18n.t.bind(i18n);

const trans = translator.translate.bind(translator);

export {
    t,
    trans,
};

export default i18n;