import React from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as services from './../../services';
import { DELIVERY_OBJECT } from './../../../constants';
import { getLastmileCarrierSuggest } from './../../../../ShippingPartner/ListShippingPartner/actions';
import * as shippingPartnerSelectors from './../../../../ShippingPartner/ListShippingPartner/selectors';

import FormDeliveryTypeContainer from './../../../FormDeliveryType/FormDeliveryTypeContainer';
import FormProcessing from './../../../../Common/components/FormProcessing';
import ShippingDomesticPartnersSelect from './../../../../Common/components/Selects/ShippingDomesticPartnersSelect';
import SelectUserContainer from './../../../../User/SelectUser/SelectUserContainer';
import SelectWarehouse from './../../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';
import ShippingReturnPartnersSelect from '../../../../Common/components/Selects/ShippingReturnPartnersSelect';
import withShippingReturnPartners from '../../../../../hocs/withShippingReturnPartners';
import FormSelectCustomerContainer from '../../../../DeliveryNote/FormSelectCustomer/FormSelectCustomerContainer';
import SelectSuggestCustomer from '../SelectSuggestCustomer';
import { split } from 'lodash';
import SelectAgency from '../../../../Setting/Agency/SelectAgency';

const { Search } = Input;

class FormSearch extends FormProcessing {

    constructor(props) {
        super(props);

        this.defaultInput = {
            status: undefined,
            id_warehouse: undefined,
            'created_at[from]': '',
            'created_at[to]': '',
            'exported_at[from]': '',
            'exported_at[to]': '',
            id_creator: undefined,
            id_exporter: undefined,
            id_last_mile_carrier: undefined,
            id_shipping_user: undefined,
            username_customer: undefined,
            id_customer: undefined,
            id_agency: undefined,
            delivery_request_code: undefined,
        };

        this.state = {
            input: { ...this.defaultInput },
            inputVersion: 0,
            changedParams: [],
        };
    }


    /**
     * Get filter
     * @param filter
     * @returns {*}
     */
    getFilter(filter) {
        let page = this.props.page;
        if (page === 'list_delivery_note_pack') {
            filter.packing_complete = 0;
        }
        return filter;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            let { input } = this.state;
            input.page = 1;
            input.type = DELIVERY_OBJECT.CUSTOMER;
            this.props.onSearch(input);
        }
    }

    onReset(event) {
        event.preventDefault();
        if (!this.props.loading) {
            let filter = this.getFilter(this.defaultInput);
            filter.type = DELIVERY_OBJECT.CUSTOMER;
            this.changeInput(filter);
            this.props.onSearch(filter);
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onChangeSelect(name, value) {
        if (!this.props.loading) {
            let { input } = this.state;
            input.page = 1;
            input.type = DELIVERY_OBJECT.CUSTOMER;

            if (name === 'id_customer') {
                this.props.onSearch({
                    ...input, [name]: split(value, ";")[0], username_customer: split(value, ";")[1]
                });
            } else {
                this.props.onSearch({
                    ...input, [name]: value
                });
            }




        }
    }

    onChangeMultiSelect(name, value) {
        this.changeInput({ [name]: value });
    }

    componentDidMount() {
        const { getLastmileCarrierSuggest } = this.props;

        getLastmileCarrierSuggest();
    }

    render() {
        const { loading, loadingShippingDomesticPartnerSuggests, shippingDomesticPartnerSuggests, t, } = this.props;
        const input = services.converValue2Int({
            ...this.state.input
        });

        return (
            <div className="a-content--search-header search-default">
                <Form onSubmit={this.onSubmit.bind(this)} className="a-form">
                    <Row gutter={{ lg: 12, xl: 24, xxl: 24 }}>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t(`delivery_note:column.code`)}
                                labelCol={{ span: 24 }}>
                                <Search
                                    value={input.code || ''}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                    className="a-inputonChangeInput--search _delivery_note_search"
                                    placeholder={t(`delivery_note:placeholder.code`)}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.status")}
                                labelCol={{ span: 24 }}
                            >
                                <FormDeliveryTypeContainer
                                    allowClear
                                    className="a-select a-select--search-content _status_customer"
                                    dropdownClassName="_dropdown_status_customer"
                                    value={input.status}
                                    disabled={loading}
                                    placeholder={t("delivery_note:label.status")}
                                    onChange={status => this.changeInput({ status })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('shipping_partner')}
                                labelCol={{ span: 24 }}
                            >
                                <ShippingDomesticPartnersSelect
                                    allowClear
                                    loading={loadingShippingDomesticPartnerSuggests}
                                    shippingDomesticPartners={shippingDomesticPartnerSuggests}
                                    value={input.id_last_mile_carrier}
                                    onChange={idPartnerShippingDomestic => this.changeInput({
                                        id_last_mile_carrier: idPartnerShippingDomestic
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.created_at")}
                                labelCol={{ span: 24 }}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.exported_at")}
                                labelCol={{ span: 24 }}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[from]'] ? moment(input['exported_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _exported-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={input['exported_at[to]'] ? moment(input['exported_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'exported_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={3} xxl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.id_warehouse")}
                                labelCol={{ span: 24 }}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content"
                                    dropdownClassName="_dropdown_id_warehouse"
                                    placeholder={t('delivery_note:label.id_warehouse')}
                                    value={input.id_warehouse}
                                    onChange={id_warehouse => this.changeInput({ id_warehouse })}>
                                </SelectWarehouse>
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.id_creator")}
                                labelCol={{ span: 24 }}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _person_create_customer"
                                    dropdownClassName="_dropdown_person_create_customer'"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_creator')}
                                    value={input.id_creator}
                                    onChange={id_creator => this.changeInput({ id_creator })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("delivery_note:label.id_exporter")}
                                labelCol={{ span: 24 }}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _person_export_customer"
                                    dropdownClassName="_dropdown_person_export_customer"
                                    emptyOption={false}
                                    placeholder={t('delivery_note:label.id_exporter')}
                                    value={input.id_exporter}
                                    onChange={id_exporter => this.changeInput({ id_exporter })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("bag:label.customer_code")}
                                labelCol={{ span: 24 }}
                            >

                                <SelectSuggestCustomer 
                                    value={input.username_customer || ''} 
                                    onChange={this.onChangeSelect.bind(this, 'id_customer')} 
                                />

                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Agency"
                                labelCol={{ span: 24 }}>
                                <SelectAgency
                                    showSearch={true}
                                    allowClear
                                    value={input.id_agency}
                                    optionFilterProp="children"
                                    onChange={agencyId =>
                                        this.changeInput({
                                            id_agency: agencyId,
                                        })
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t("delivery_request:label.code_delivery_request")}
                                labelCol={{span: 24}}>
                                <Search
                                    placeholder={t("delivery_request:label.code_delivery_request")}
                                    value={input.delivery_request_code || ''}
                                    onChange={this.onChangeInput.bind(this, 'delivery_request_code')}
                                    className="a-input--search _code-delivery-request"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a href="/" className="link-reload " onClick={this.onReset.bind(this)}><Icon
                            type="sync" />{t("common:label.refresh_filter")}</a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list"
                            loading={this.props.loading}
                        >{t('btn.search')}</Button>
                    </div>
                </Form>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        shippingDomesticPartnerSuggests: shippingPartnerSelectors.getState(state, 'lastmileCarriers.suggests'),
        loadingShippingDomesticPartnerSuggests: shippingPartnerSelectors.getState(state, 'lastmileCarriers.loadingSuggests')
    };
};

const mapDispatchToProps = {
    getLastmileCarrierSuggest
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(FormSearch))
