import React, { Component } from 'react'
import { Button, Checkbox, Col, Form, Icon, Input, Row, Select } from 'antd'
import * as _ from 'lodash'
import lodash, { get, isObject } from 'lodash'
import { t } from '../../../system/i18n'
import SelectCustomerContainer from '../../Customer/SelectCustomer/SelectCustomerContainer'
import FormPropertiesContainer from '../../Property/FormProperties/FormPropertiesContainer'
import { BAG_SHIPPING_TYPE, LIST_BAG_SHIPPING_TYPE } from '../../Bag/BagShippingType/constants'
import FormServicesContainer from '../../Service/FormServices/FormServicesContainer'
import SelectBagSizeContainer from '../../BagSize/SelectBagSize/SelectBagSizeContainer'
import ChooseWarehouseContainer from '../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer'
import Link from '../../../system/routing/Link'
import SelectCreatePartner from './../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer'

import SelectAgency from './../../Setting/Agency/SelectAgency'

const Option = Select.Option
const TextArea = Input.TextArea

class CreateForm extends Component {
    state = {
        form: {
            name: undefined,
            type: 'MULTIPLE',
            id_customer: undefined,
            id_bag_size: undefined,
            id_shipping_partner: undefined,
            id_shipping_user: undefined,
            subject_shipping: BAG_SHIPPING_TYPE.USER,
            id_warehouse_input: undefined,
            id_warehouse_destination: undefined,
            receiver_name: undefined,
            receiver_phone: undefined,
            note: undefined,
            services: [],
            properties: [],
            id_agency: undefined,
        },
    }

    static getDerivedStateFromProps(props, state) {
        let newState = { ...state }
        if (state.form.subject_shipping === BAG_SHIPPING_TYPE.USER) {
            if (!state.form.id_shipping_user) {
                newState.form.id_shipping_user = _.get(props.shippingUsers, '0.shipping_user.id')
                return { ...newState }
            }
        } else if (state.form.subject_shipping === BAG_SHIPPING_TYPE.PARTNER) {
            if (!state.form.id_shipping_partner) {
                newState.form.id_shipping_partner = _.get(props.shippingPartners, '0.shipping_partner.id')
                return { ...newState }
            }
        }
        return null
    }

    onChangeSelectValue = (field, input) => {
        const { resetError } = this.props
        let value = _.has(input, 'value') ? input.value : input

        if (field === 'is_unchecked') {
            value = input.target.checked
        }

        this.setState(prevState => {
            const newForm = {
                ...prevState.form,
            }

            newForm[field] = value

            if (field === 'type' && value !== 'SINGLE') {
                newForm.id_customer = undefined
                resetError('id_customer')
            }

            if (field === 'id_customer') {
                let agencyId = undefined
                if (isObject(input)) {
                    value = get(input, 'customer.id')
                    agencyId = get(input, 'agency.id')
                }

                newForm.id_customer = value
                newForm.id_agency = agencyId ? agencyId : undefined
            }
            resetError(field)
            return {
                form: newForm,
            }
        })
    }

    onChangeServices = services => {
        this.setState(prevState => {
            const { form } = prevState

            return {
                form: {
                    ...form,
                    services,
                },
            }
        })
    }

    onChangeInput = (field, e) => {
        let { form } = this.state
        form[field] = e.target.value
        this.props.resetError(field)
        this.setState({ form })
    }

    handleSubmit = e => {
        e.preventDefault()
        const { form } = this.state
        let data = lodash.pick(form, [
            'name',
            'type',
            'id_customer',
            'id_bag_size',
            'id_warehouse_input',
            'id_warehouse_destination',
            'subject_shipping',
            'receiver_name',
            'receiver_phone',
            'note',
            'services',
            'properties',
            'is_unchecked',
            'id_agency',
        ])

        if (data.subject_shipping === BAG_SHIPPING_TYPE.USER) {
            data.id_shipping_user = form.id_shipping_user
        } else if (data.subject_shipping === BAG_SHIPPING_TYPE.PARTNER) {
            data.id_shipping_partner = form.id_shipping_partner
        }

        data.active = this.props.active

        this.props.createTemplateBag(data)
    }

    render() {
        const bagTypes = [
            { key: 1, label: t('bag:label.multi_bag'), value: 'MULTIPLE' },
            { key: 2, label: t('bag:label.single_bag'), value: 'SINGLE' },
        ]
        const { form } = this.state
        const { creating, errors, shippingUsers, loadingShippingUser } = this.props
        const shippingTypes = [...LIST_BAG_SHIPPING_TYPE]

        return (
            <Form
                onSubmit={this.handleSubmit}
                className="a-form form--package">
                <Row
                    gutter={{ lg: 24, xl: 32 }}
                    className="a-block-info mb-5">
                    <Col
                        lg={{ span: 16 }}
                        xl={{ span: 19 }}
                        xxl={{ span: 19 }}>
                        <Row gutter={{ lg: 24, xl: 32 }}>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.name')}
                                    labelCol={{ span: 24 }}
                                    required={true}
                                    help={errors.name}
                                    validateStatus={errors.name ? 'error' : null}>
                                    <Input
                                        className={'a-input _input-name'}
                                        placeholder={t('template_bag:input_name')}
                                        value={form.name}
                                        onChange={this.onChangeInput.bind(this, 'name')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:type')}
                                    labelCol={{ span: 24 }}>
                                    <Select
                                        className="a-select  _a-select-type"
                                        showSearch
                                        defaultValue={bagTypes[0].value}
                                        onChange={this.onChangeSelectValue.bind(this, 'type')}
                                        suffixIcon={
                                            <Icon
                                                type="down"
                                                className="icon__dropdown__select"
                                                onChange={this.handleSelectChange}
                                            />
                                        }>
                                        {bagTypes.map(item => (
                                            <Option
                                                key={item.value}
                                                value={item.value}
                                                className={'_type-bag__item _type _type-' + item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:customer')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_customer}
                                    validateStatus={errors.id_customer ? 'error' : null}>
                                    {form.type === 'SINGLE' && (
                                        <SelectCustomerContainer
                                            autoSelect={true}
                                            emptyOption={false}
                                            is_shipper={true}
                                            showName={true}
                                            className="a-select a-select--search-content _id_customer"
                                            value={form.id_customer}
                                            dispatchObject={true}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_customer')}
                                            dropdownClassName={'_dropdown_id_customer'}
                                        />
                                    )}

                                    {form.type !== 'SINGLE' && (
                                        <Select
                                            disabled={true}
                                            placeholder={t('bag:label.customer_placeholder')}></Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ lg: 24, xl: 32 }}>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:label.size')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_bag_size}
                                    validateStatus={errors.id_bag_size ? 'error' : null}>
                                    <SelectBagSizeContainer
                                        value={form.id_bag_size}
                                        errors={lodash.pick(errors, ['id_bag_size'])}
                                        autoSelect={true}
                                        disabled={false}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_bag_size')}
                                        dropdownClassName={'_dropdown_id_bag_size'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 18 }}
                                xxl={{ span: 18 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item  "
                                    label={t('template_bag:property')}
                                    labelCol={{ span: 24 }}>
                                    <FormPropertiesContainer
                                        onChange={this.onChangeSelectValue.bind(this, 'properties')}
                                        errors={lodash.pick(errors, ['properties'])}
                                        values={form.properties}
                                        classUl="property--package _properties"
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:warehouse_create')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_warehouse_input}
                                    validateStatus={errors.id_warehouse_input ? 'error' : null}>
                                    <ChooseWarehouseContainer
                                        emptyOption={false}
                                        isSelect
                                        active={true}
                                        isPermission={true}
                                        className="a-select a-select--search-content _id_warehouse_input"
                                        value={form.id_warehouse_input}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_input')}
                                        dropdownClassName={'_dropdown__id_warehouse_input'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:label.warehouse_destination')}
                                    labelCol={{ span: 24 }}
                                    help={errors.id_warehouse_destination}
                                    validateStatus={errors.id_warehouse_destination ? 'error' : null}>
                                    <ChooseWarehouseContainer
                                        emptyOption={false}
                                        isSelect
                                        isPermission={false}
                                        active={true}
                                        className="a-select a-select--search-content _id_warehouse_destination"
                                        value={form.id_warehouse_destination}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_warehouse_destination')}
                                        dropdownClassName={'_dropdown__id_warehouse_destination'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.receiver')}
                                    labelCol={{ span: 24 }}
                                    help={errors.receiver_name}
                                    validateStatus={errors.receiver_name ? 'error' : null}>
                                    <Input
                                        className={'a-input _a-input-receiver'}
                                        value={form.receiver_name}
                                        onChange={this.onChangeInput.bind(this, 'receiver_name')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:columns.phone')}
                                    labelCol={{ span: 24 }}
                                    help={errors.receiver_phone}
                                    validateStatus={errors.receiver_phone ? 'error' : null}>
                                    <Input
                                        className={'a-input _a-input-phone'}
                                        value={form.receiver_phone}
                                        onChange={this.onChangeInput.bind(this, 'receiver_phone')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:shipping_type')}
                                    labelCol={{ span: 24 }}>
                                    <Select
                                        className="a-select  _a-select-shipping-type"
                                        showSearch={true}
                                        defaultValue={shippingTypes[0].id}
                                        value={form.subject_shipping}
                                        onChange={this.onChangeSelectValue.bind(this, 'subject_shipping')}
                                        suffixIcon={
                                            <Icon
                                                type="down"
                                                className="icon__dropdown__select"
                                            />
                                        }>
                                        {shippingTypes.map(item => (
                                            <Option
                                                key={item.id}
                                                value={item.id}
                                                className={'_subject_shipping__item'}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t('template_bag:shipping_name')}
                                    labelCol={{ span: 24 }}>
                                    {form.subject_shipping === BAG_SHIPPING_TYPE.PARTNER && (
                                        <SelectCreatePartner
                                            dropdownClassName="_dropdown_id_shipping_partner"
                                            getPartnersByWarehouse
                                            optionFilterProp="children"
                                            showSearch
                                            value={form.id_shipping_partner}
                                            warehouseId={form.id_warehouse_input}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_partner')}
                                        />
                                    )}
                                    {form.subject_shipping === BAG_SHIPPING_TYPE.USER && (
                                        <Select
                                            loading={loadingShippingUser}
                                            className="a-select a-select--w-100 _id_shipping_user"
                                            showSearch={true}
                                            optionFilterProp="children"
                                            value={form.id_shipping_user}
                                            onChange={this.onChangeSelectValue.bind(this, 'id_shipping_user')}
                                            dropdownClassName={'_dropdown_id_shipping_user'}>
                                            {shippingUsers.map(item => (
                                                <Option
                                                    key={_.get(item, 'shipping_user.id')}
                                                    value={_.get(item, 'shipping_user.id')}
                                                    className={'_id_shipping_user__item'}>
                                                    {`${_.get(item, 'shipping_user.full_name', '--')}(${
                                                        _.isNil(_.get(item, 'shipping_user.username')) ? '--' : _.get(item, 'shipping_user.username', '--')
                                                    })`}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('bag:label.agency')}
                                    labelCol={{ span: 24 }}>
                                    <SelectAgency
                                        allowClear
                                        className="_id-agencies"
                                        dropdownClassName="_dropdown-id-agencies"
                                        // mode="multiple"
                                        optionFilterProp="children"
                                        value={form.id_agency}
                                        onChange={this.onChangeSelectValue.bind(this, 'id_agency')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                xl={{ span: 6 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('template_bag:note')}
                                    labelCol={{ span: 24 }}
                                    validateStatus={errors.note ? 'error' : null}
                                    help={errors.note}>
                                    <TextArea
                                        className="a-input--textarea _note"
                                        autosize={{ minRows: 1, maxRows: 3 }}
                                        spellCheck={false}
                                        value={form.note}
                                        onChange={this.onChangeInput.bind(this, 'note')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 24 }}
                                className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={t('bag:label.unchecked')}
                                    labelCol={{ span: 24 }}>
                                    <Checkbox
                                        className={'_is_unchecked'}
                                        checked={form.is_unchecked}
                                        onChange={this.onChangeSelectValue.bind(this, 'is_unchecked')}>
                                        {t('bag:label:unchecked')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        lg={{ span: 8 }}
                        xl={{ span: 5 }}
                        xxl={{ span: 5 }}
                        className="pl-0">
                        <div className="a-inline-edit service-edit ">
                            <div className="a-inline-edit--input">
                                <FormServicesContainer
                                    values={form.services}
                                    onChange={this.onChangeServices}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        className="a-package--action only-action">
                        <Button className="a-btn a-btn--back-package a-btn--default _btn-cancel">
                            <Link to="config.template-bags.list">{t('template_bag:cancel')}</Link>
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--save-package _btn-create"
                            loading={creating}>
                            {t('template_bag:btn_create')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export { CreateForm }
