import React from 'react';
import {Icon, Layout} from 'antd';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import * as actions from './actions';

import FormShippingPartnerContainer from '../FormShippingPartner/FormShippingPartnerContainer';

const mapDispatchToProps = (dispatch) => ({
    createShippingPartner: (data) => dispatch(actions.createShippingPartner(data)),
});

const {Content} = Layout;

class CreateShippingPartnerContainer extends React.Component {
    render() {
        const {t} = this.props;

        return (
            <Content className="aml-16-lg aml-24 amr-24 amr-16-lg a-content-config">
                <div className="a-breadcrumb">
                    <Link to="/config/shipping-partners" className="a-text--gray _btn-back">
                        <Icon type="arrow-left" className="mr-2" />
                        {t("shipping_partner:label.back_shipping_partner")}
                    </Link>
                </div>
                <FormShippingPartnerContainer {...this.props} />
            </Content>
        );
    }
}

export default withTranslation()(connect(
    null,
    mapDispatchToProps
)(CreateShippingPartnerContainer));
