import React from 'react'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'
import { getVar } from '../../../../system/support/helpers'

const WarehouseStatusCard = ({ t, status, packageData }) => {
    const currentWarehouse = getVar(packageData, 'current_warehouse.code', '--')
    const destinationWarehouse = getVar(packageData, 'destination_warehouse.code', '--')
    return (
        <div
            className={clsx('warehouse-status-card', {
                'warehouse-status-card--success': status === 'OUT',
                'warehouse-status-card--warning': status === 'IN' && currentWarehouse !== destinationWarehouse,
                'warehouse-status-card--error': status === 'IN' && currentWarehouse === destinationWarehouse,
            })}>
            {t(`package:action.${status}`)}
        </div>
    )
}

export default withTranslation()(WarehouseStatusCard)
