import React, { memo, useState } from 'react';
import { get, isEqual, isFunction } from 'lodash';

import apiService from './../../../../../../../apiService';
import { getCandeliveries } from './../../../../../../../helper';
import customerApiService from './../../../../../../../../Customer/apiService';
import { useDeepCompareEffect } from './../../../../../../../../../hooks';

import DetailInfoForm from './../../../../../../../DetailInfoForm/DetailInfoFormContainer';

const DeliveryNoteItem = ({
    bagIds,
    barcodes,
    creating,
    deliveryNote,
    errors,
    packageIds,
    warehouseId,
    onChangeCustomerBagPackages,
    onChangeCustomerDetailInfo,
    onCreateDeliveryNote,
    onOpenPackageListByBagPopup,
    onPrint,
    onRemoveReadyForDelivery,
    onSuccessCancelDeliveryNote,
    onSuccessExportDeliveryNote,
    onSuccessPlaceDeliveryNote,
    resetError,
    getLastmileCarrierSuggest,
    creatingWithTrackingNo
}) => {
    const [canDeliveries, setCanDeliveries] = useState();
    const [customerDetailInfo, setCustomerDetailInfo] = useState();
    const [loadingCustomerDetailInfo, setLoadingCustomerDetailInfo] = useState(false);
    const [customerBagPackages, setCustomerBagPackages] = useState();
    const [loadingCustomerBagPackages, setLoadingCustomerBagPackages] = useState({});

    const getCustomerDetailInfo = async (suggest) => {
        setLoadingCustomerDetailInfo(true);

        if (!customerDetailInfo || get(suggest, 'delivery_note.id')) {
            try {
                const { data } = await customerApiService.fetchDetailCustomer(get(suggest, 'customer.id'));

                setCustomerDetailInfo(data);
                const id_agency = get(data, 'agency.id')
                if (isFunction(getLastmileCarrierSuggest)) {
                    getLastmileCarrierSuggest({ id_agency, id_warehouse: warehouseId })
                }

                onChangeCustomerDetailInfo(get(suggest, 'identity'), data);
            } catch (error) {
            }
        }

        setLoadingCustomerDetailInfo(false);
    };

    const getCustomerBagPackages = async (customerId, identity, forceReload = false) => {
        setLoadingCustomerBagPackages(true);

        if (!customerBagPackages || forceReload) {
            try {
                const { data } = await apiService.getCustomerBagPackages(customerId, {
                    id_warehouse: warehouseId
                });

                setCustomerBagPackages(data);
                onChangeCustomerBagPackages(identity, data);
            } catch (error) {
            }
        }

        setLoadingCustomerBagPackages(false);
    };

    const handleReloadCustomerBagPackages = event => {
        event.preventDefault();

        getCustomerBagPackages(get(deliveryNote, 'customer.id'), get(deliveryNote, 'identity'), true);
    };

    const handleCreateDeliveryNote = (data, auto = false) => {
        onCreateDeliveryNote({
            ...data,
            customerBagPackages
        }, auto);
    };

    useDeepCompareEffect(() => {
        setCanDeliveries(getCandeliveries(customerBagPackages, bagIds, packageIds));
    }, [bagIds, customerBagPackages, packageIds]);

    useDeepCompareEffect(() => {
        getCustomerDetailInfo(deliveryNote);
        getCustomerBagPackages(get(deliveryNote, 'customer.id'), deliveryNote.identity);
    }, [deliveryNote]);

    return (
        <DetailInfoForm
            creatingWithTrackingNo={creatingWithTrackingNo}
            bags={bagIds}
            canDeliveries={canDeliveries}
            canUpdateReadyForDeliveries={!get(deliveryNote, 'delivery_note.id')}
            creating={creating}
            customerDetailInfo={customerDetailInfo}
            customerId={get(deliveryNote, 'customer.id')}
            deliveryNote={deliveryNote}
            errors={errors}
            identity={deliveryNote.identity}
            loadingCanDeliveries={loadingCustomerBagPackages}
            loadingCustomerDetailInfo={loadingCustomerDetailInfo}
            packages={packageIds}
            readyForDeliveries={barcodes}
            showCancel
            showExport
            showPlace
            warehouseId={warehouseId}
            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
            onPrint={onPrint}
            onReloadCanDeliveries={handleReloadCustomerBagPackages}
            onRemoveReadyForDelivery={onRemoveReadyForDelivery}
            onSubmit={handleCreateDeliveryNote}
            onSuccessCancelDeliveryNote={onSuccessCancelDeliveryNote}
            onSuccessExportDeliveryNote={onSuccessExportDeliveryNote}
            onSuccessPlaceDeliveryNote={onSuccessPlaceDeliveryNote}
            resetError={resetError}
        />
    );
};

export default memo(DeliveryNoteItem, isEqual);
