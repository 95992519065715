import React, {Component} from 'react';
import {Col, Form, Row, Skeleton} from 'antd';
import {withTranslation} from 'react-i18next';

class BagAreaFormLoading extends Component {
    render () {
        const {t} = this.props;
        return (
            <Form>
                <Form.Item
                    className="a-hidden"
                    label={t('common:id')}
                >
                    <Row>
                        <Col span={24}>
                            <Skeleton active/>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label={t('bag_area:area_name')}>
                    <Row>
                        <Col span={24}>
                            <Skeleton active paragraph={{rows: 1}} title={false}
                                      className={"ant-skeleton-paragraph-m0"}/>

                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    label={t('bag_area:area_code')}
                >
                    <Row>
                        <Col span={24}>
                            <Skeleton active paragraph={{rows: 1}} title={false}
                                      className={"ant-skeleton-paragraph-m0"}/>

                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label={t('status')}>
                    <Row>
                        <Col span={24}>
                            <Skeleton active paragraph={{rows: 0}}
                                      className={"ant-skeleton-paragraph-m0"}/>

                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(BagAreaFormLoading);
