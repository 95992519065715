import permissions from './../Auth/permissions';

export const FETCH_INVENTORY_DETAIL = {
    REQUEST: 'FETCH_INVENTORY_DETAIL.REQUEST',
    SUCCESS: 'FETCH_INVENTORY_DETAIL.SUCCESS',
    FAILED: 'FETCH_INVENTORY_DETAIL.FAILED',
};

export const UPDATE_INVENTORY_STATUS = {
    REQUEST: 'UPDATE_INVENTORY_STATUS.REQUEST',
    SUCCESS: 'UPDATE_INVENTORY_STATUS.SUCCESS',
    FAILED: 'UPDATE_INVENTORY_STATUS.FAILED',
};

export const INVENTORY_STATUS_CHECKING   = 'checking'; // dang kiem tra/doi soat
export const INVENTORY_STATUS_PROCESSING = 'processing'; // dang xu ly
export const INVENTORY_STATUS_DONE       = 'done'; // ket thuc kiem ke
export const INVENTORY_STATUS_CANCELLED  = 'cancelled';

export const UPDATE_INVENTORY_PACKAGE_STATUS = {
    REQUEST: 'UPDATE_INVENTORY_PACKAGE_STATUS.REQUEST',
    SUCCESS: 'UPDATE_INVENTORY_PACKAGE_STATUS.SUCCESS',
    FAILED: 'UPDATE_INVENTORY_PACKAGE_STATUS.FAILED',
};

export const RESOLVE_DISAPPEAR_INVENTORY_PACKAGES = {
    REQUEST: 'RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.REQUEST',
    SUCCESS: 'RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.SUCCESS',
    FAILED: 'RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.FAILED',
};

export const RESOLVE_REMAINDER_INVENTORY_PACKAGES = {
    REQUEST: 'RESOLVE_REMAINDER_INVENTORY_PACKAGES.REQUEST',
    SUCCESS: 'RESOLVE_REMAINDER_INVENTORY_PACKAGES.SUCCESS',
    FAILED: 'RESOLVE_REMAINDER_INVENTORY_PACKAGES.FAILED',
};

export const RESOLVE_INVENTORY_TRACKING_BILL = {
    REQUEST: 'RESOLVE_INVENTORY_TRACKING_BILL.REQUEST',
    SUCCESS: 'RESOLVE_INVENTORY_TRACKING_BILL.SUCCESS',
    FAILED: 'RESOLVE_INVENTORY_TRACKING_BILL.FAILED'
};

export const RESOLVE_INVENTORY_TRACKING_BILLS = {
    REQUEST: 'RESOLVE_INVENTORY_TRACKING_BILLS.REQUEST',
    SUCCESS: 'RESOLVE_INVENTORY_TRACKING_BILLS.SUCCESS',
    FAILED: 'RESOLVE_INVENTORY_TRACKING_BILLS.FAILED'
};

export const FETCH_INVENTORIES = {
    REQUEST: 'FETCH_INVENTORIES.REQUEST',
    SUCCESS: 'FETCH_INVENTORIES.SUCCESS',
    FAILED: 'FETCH_INVENTORIES.FAILED',
};

export const RESET_ERROR = 'RESET_ERROR';

export const INVENTORY_TYPES = {
	PACKAGE: {
		key: 'package',
		permission: permissions.INVENTORY_ALL
	},
	RECEIVED: {
		key: 'receive_package',
		permission: permissions.INVENTORY_RECEIVE_ALL
	}
};
