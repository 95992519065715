import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {notification} from 'antd';
import {has, map} from 'lodash';
import {withTranslation} from 'react-i18next';

import {LIST_STAFF_COLUMN} from './../../../../Agency/constants';
import apiService from './../../../../../Agencies/apiService';
import {dateFormatter} from './../../../../../Common/services/format';
import {getVar} from './../../../../../../system/support/helpers';

import ListUserStaff from './../../../../../User/ListUserStaff';
import RemoveUserStaff from './../../../../../User/RemoveUserStaff';

const Staffs = ({agencies, agencyId, t, agencyClicked}) => {
    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState();
    const [filter, setFilter] = useState({
        page: 1,
        per_page: 100
    });
    const [pagination, setPagination] = useState({
        total: 0
    });

    const getStaffs = useCallback(async (agencyId, params) => {
        setLoading(true);

        try {
            const {data} = await apiService.detail(agencyId, {
                ...filter,
                ...params
            });

            setStaffs(data.agency_staffs);
            setPagination(data.pagination)
        } catch (error) {
        }

        setLoading(false);
    }, [agencyId, filter]);

    const handleRemoveStaff = useCallback(async data => {
        setLoading(true);

        try {
            await apiService.removeUserToStaff(data);

            setFilter({
                ...filter,
                page: 1
            })
            notification.success({
                message: t('agency:message.remove_staff_success')
            });
        } catch (error) {
            notification.error({
                message: t('agency:message.remove_staff_failed')
            });
        }

        setLoading(false);
    }, [filter, t, setFilter]);

    const handleAddStaff = useCallback(async (users, id_agencies) => {
        const data = {
            id_agency: agencyId,
            id_users: users,
            id_agencies
        };

        setLoading(true);

        try {
            await apiService.addUserToStaff(data);

            getStaffs(agencyId);
            notification.success({
                message: t('agency:message.add_user_to_staff_success')
            });
        } catch (error) {
            notification.error({
                message: t('agency:message.add_user_to_staff_failed')
            });
        }

        setLoading(false);
    }, [agencyId, getStaffs, t]);

    const handleChange = useCallback(params => {
        if (loading) {
            return;
        }

        const newFilter = {
            ...filter,
            page: has(params, 'current') ? params.current : filter.page,
            per_page: has(params, 'pageSize') ? params.pageSize : filter.per_page,
            username: has(params, 'username') ? params.username : filter.username
        };

        setFilter(newFilter);
    }, [filter, loading]);

    const formattedStaffData = useMemo(() => map(staffs, staff => {
        const createdAt = getVar(staff, 'agency_staff.created_at');
        const creator = getVar(staff, 'creator');

        return {
            key: getVar(staff, 'agency_staff.id'),
            id: getVar(staff, 'user.id'),
            [LIST_STAFF_COLUMN.NAME]: (
                <span className="_name">
                    <span
                        className="_staff-name"
                    >
                        {getVar(staff, 'user.name', '--')}
                    </span>
                </span>
            ),
            [LIST_STAFF_COLUMN.USERNAME]: getVar(staff, 'user.username', '--'),
            [LIST_STAFF_COLUMN.EMAIL]: getVar(staff, 'user.email', '--'),
            [LIST_STAFF_COLUMN.PHONE]: getVar(staff, 'user.phone', '--'),
            [LIST_STAFF_COLUMN.CREATE_AT]: createdAt ? dateFormatter.dateTime(createdAt) : '--',
            [LIST_STAFF_COLUMN.CREATOR]: creator ? (
                <span className="_creator">
                    <span className="_creator-name">{getVar(creator, 'name', '--')}</span>
                    <span className="_creator-username ml-2">
                        {getVar(creator, 'username') ? `(${creator.username})` : null}
                    </span>
                </span>
            ) : '--',
            [LIST_STAFF_COLUMN.ACTION]: (
                <RemoveUserStaff
                    user={getVar(staff, 'user')}
                    onRemoveUserStaff={handleRemoveStaff.bind(this, {
                        id_agency: agencyId,
                        id_staff: getVar(staff, 'user.id')
                    })}
                />
            )
        }
    }), [agencyId, staffs, handleRemoveStaff]);

    useEffect(() => {
        if(!agencyClicked || Number(agencyClicked) === Number(agencyId))
        {
            getStaffs(agencyId);
        }
    }, [agencyClicked, filter]);

    return (
        <ListUserStaff
            active
            agencies={agencies}
            agencyId={agencyId}
            columns={LIST_STAFF_COLUMN}
            data={formattedStaffData}
            filter={filter}
            loading={loading}
            pagination={pagination}
            onAddStaff={handleAddStaff}
            onChange={handleChange}
            getStaffs={getStaffs}
        />
    );
};

export default withTranslation()(Staffs);
