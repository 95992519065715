/* eslint-disable */
import React, { Component } from 'react'
import { Menu } from 'antd'
import PropTypes from 'prop-types'

import PERMISSION from './../../../Auth/permissions'
import { t } from './../../../../system/i18n'
import { Link, router } from './../../../../system/routing'

const MenuConfigs = [
    {
        title: t('menu.configs.warehouse'),
        key: 'config/warehouse',
        permission: [PERMISSION.WAREHOUSES_MANAGE],
        className: '_menu_warehouse',
    },
    {
        title: t('menu.configs.operation_permission_for_staff'),
        key: 'config/warehouse-staff',
        permission: [PERMISSION.WAREHOUSES_MANAGE_STAFF],
        className: '_menu_warehouse_staff',
    },
    {
        title: t('menu.configs.shipping_partner'),
        key: 'config/shipping-partners',
        permission: [PERMISSION.DELIVERY_PARTNER_MANAGE, PERMISSION.DOMESTIC_DELIVERY_PARTNER_MANAGE],
        className: '_menu_shipping_partner',
    },
    {
        title: t('menu.configs.shipping_user'),
        key: 'config/shipping-users',
        permission: [PERMISSION.DELIVERY_AGENT_MANAGE],
        className: '_menu_shipping_user',
    },
    {
        title: t('menu.configs.shipping_fee'),
        key: 'config/shipping-fees',
        permission: [PERMISSION.SHIPPING_FEE_MANAGE],
        className: '_menu_shipping_fee',
    },
    {
        title: t('storage_fee'),
        key: 'config/storage-fees',
        permission: [PERMISSION.STORAGE_FEE_MANAGE],
        className: '_menu_storage_fees',
    },
    {
        title: t('menu.configs.sample_bag_midmile'),
        key: 'config/template-bags',
        permission: [PERMISSION.CONFIG_SAMPLE_BAG],
        className: '_menu_sample_bag',
    },
    {
        title: t('menu.configs.sample_bag_lastmile'),
        key: 'config/template-bags-lastmile',
        permission: [PERMISSION.LASTMILE_BAG_TEMPLATE_MANAGE],
        className: '_menu_sample_bag_lastmile',
    },
    {
        title: t('menu.configs.bag_size'),
        key: 'config/bag-sizes',
        permission: [PERMISSION.CONFIG_BAG_SIZE],
        className: '_menu_bag_size',
    },
    {
        title: t('menu.configs.receiving_area'),
        key: 'config/bag-areas',
        permission: [PERMISSION.BAG_AREA_MANAGE],
        className: '_menu_bag_area',
    },
    {
        title: t('menu.configs.property'),
        key: 'config/property',
        permission: [PERMISSION.PROPERTIES_MANAGE],
        className: '_menu_property',
    },
    {
        title: t('notice_stage'),
        key: 'config/notice-stages',
        permission: [PERMISSION.NOTICE_STAGE_MANAGE],
        className: '_menu_notice_stages',
    },
    {
        title: t('menu.configs.service'),
        key: 'config/service',
        permission: [PERMISSION.CONFIG_SERVICE],
        className: '_menu_service',
    },
    {
        title: t('menu.configs.general'),
        key: 'config/general',
        permission: [PERMISSION.CONFIG_GENERAL],
        className: '_menu_config_general',
    },
]

class SiderX extends Component {
    handleClick = () => {
        this.props.clearPrintState()
    }

    render() {
        const { menuActive, permissions } = this.props
        const hasAnyPermission = MenuConfigs.some(
            menu => !Array.isArray(menu.permission) || !menu.permission.length || permissions.some(permission => menu.permission.indexOf(permission) >= 0)
        )

        if (!hasAnyPermission) {
            router.redirect('/403')
        }

        return (
            <div>
                <Menu
                    defaultSelectedKeys={[menuActive]}
                    selectedKeys={[menuActive]}
                    className="a-menu--configs height-100"
                    onClick={this.handleClick}>
                    {MenuConfigs.map(menu => {
                        if (
                            !Array.isArray(menu.permission) ||
                            !menu.permission.length ||
                            permissions.some(permission => menu.permission.indexOf(permission) >= 0)
                        ) {
                            return (
                                <Menu.Item
                                    key={menu.key}
                                    className={menu.className}>
                                    <Link href={`/${menu.key}`}>{menu.title}</Link>
                                </Menu.Item>
                            )
                        }
                    })}
                </Menu>
            </div>
        )
    }
}

SiderX.defaultProps = {
    permissions: [],
    menuActive: '',
}

SiderX.propTypes = {
    permissions: PropTypes.array,
    menuActive: PropTypes.string,
}

export default SiderX
