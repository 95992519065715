import React from 'react';
import {withTranslation} from 'react-i18next';
import {Icon, Menu, Popover} from 'antd';
import {ReactComponent as IconUser} from '../../../../resources/images/user.svg';
import {ReactComponent as IconLogout} from '../../../../resources/images/logout-line.svg';
import lodash from "lodash";

class UserPanel extends React.PureComponent {
    onLogout(event) {
        event.preventDefault();
        this.props.onLogout();
    }

    render() {
        let {user, t, louStampUrl, louStampUrlAuto} = this.props;
        if (!user) {
            return null;
        }
        const content = (
            <ul className={"a-dropdown__info-user__list-action"}>
                <li className="action-item">
                    <a href={lodash.get(user, 'm10_url_profile') || '#top'} className={"action-item__link"}
                       rel="noopener noreferrer"
                       target={lodash.get(user, 'm10_url_profile') ? '_blank' : '_self'}
                    >
                        <IconUser className={"action-item__icon"}/> {t("layout:header.user_information.user_information")}
                    </a>
                </li>
                <li className="action-item">
                    {
                        louStampUrlAuto &&
                        <a href={louStampUrlAuto} className={"action-item__link"}
                           rel="noopener noreferrer"
                           target='_blank'
                        >
                            <Icon type="printer" className={"action-item__icon"} /> {t("layout:header.user_information.stamp_LOU_auto")}
                        </a>
                    }

                </li>
                <li className="action-item">
                    {
                        louStampUrl && 
                        <a href={louStampUrl} className={"action-item__link"}
                            rel="noopener noreferrer"
                            target='_blank'
                        >
                            <Icon type="printer" className={"action-item__icon"} /> {t("layout:header.user_information.stamp_LOU")}
                        </a>
                    } 
                    
                </li>
                <li className="action-item">
                    <a href="/" className={"action-item__link"} onClick={this.onLogout.bind(this)}><IconLogout className={"action-item__icon"} /> {t("layout:header.user_information.logout")}</a>
                </li>

            </ul>
        );
        return (

                <Popover placement="bottomRight"  content={content}  trigger="click"    className="a-dropdown__info-user _a-dropdown__info-user" overlayClassName={"list-action-user"}>
                    <img className="img__avatar _img__avatar"
                         src={user.avatar ? user.avatar : require("../../../../resources/images/avatar-default.png")}
                         alt="ImageAvatar"/>
                    <span className="name__user _name__user">{user.name}</span>
                    <Icon type="caret-down" className="icon-dropdown"/>
                </Popover>
        );
    }

    renderMenu() {
        let {t} = this.props;

        return (

            <Menu>
                <Menu.Item key="1">
                    <a href="/" onClick={this.onLogout.bind(this)}>{t("layout:header.user_information.logout")}</a>
                </Menu.Item>
            </Menu>
        );
    }
}

export default withTranslation()(UserPanel);
