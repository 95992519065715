import { withTranslation } from 'react-i18next'
import React from 'react'
import { Layout, Button, Skeleton, Icon, Tooltip } from 'antd'
import clsx from 'clsx'
import { isNil } from 'lodash'
import { dateFormatter } from '../../../Common/services/format'
import { getVar } from '../../../../system/support/helpers'
import { Link } from '../../../../system/routing'
import { renderNotices } from '../../../Common/components/Attentions'

const { Content } = Layout

function PackageList({ action = false, t, title, scroll, handleCreate, data, handleUpdateData }) {
    const packages = action ? getVar(data, 'packages', []) : getVar(data, 'deliveryNote.packages.data', [])
    const createLoading = getVar(data, 'createLoading', false)
    const loading = action ? getVar(data, 'scanLoading', false) : getVar(data, 'loadingDeliveryNote', false)
    const handleRemovePackage = packageId => {
        const newPackages = packages.filter(packageInfo => packageInfo.id !== packageId)
        handleUpdateData({ packages: newPackages })
    }
    return (
        <Content
            className={clsx('a-content a-barcode-scan aflex-1 gflex gflex-direction-column', {
                'scroll-is-sider': scroll,
            })}>
            <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0 mt-0">
                <div className="a-content--page-list__title">
                    <h3>
                        {title} ({packages.length})
                    </h3>
                </div>

                {packages.length > 0 && !!action && (
                    <div className="a-barcode-scan__top-action">
                        <Button
                            type="primary"
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                            loading={createLoading}
                            onClick={handleCreate}>
                            {t('return_goods:btn.create')}
                        </Button>
                    </div>
                )}
            </div>
            <div className="a-content__body bg-white a-content__body--page-list  mr-0 aflex-1 pb-0">
                <div className="a-table--responsive a-table--antd">
                    <table className="a-table a-table--barcode-scan">
                        <thead>
                            <tr>
                                <th>{t('package:label.code')}</th>
                                <th>{!!action ? t('return_goods:label.exported_at') : t('return_goods:label.created_at_delivery_request')}</th>
                                <th>{!!action ? t('return_goods:label.scaned_at') : t('return_goods:label.address_receive')}</th>
                                <th>{t('weight')}</th>
                                {!!action && <th></th>}
                            </tr>
                        </thead>
                        {!!loading && (
                            <tr className="data">
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                <td>
                                    <Skeleton
                                        active
                                        paragraph={{ rows: 0 }}
                                    />
                                </td>
                                {!!action && (
                                    <td>
                                        <Skeleton
                                            active
                                            paragraph={{ rows: 0 }}
                                        />
                                    </td>
                                )}
                            </tr>
                        )}

                        {packages.length > 0 &&
                            packages.map(packageInfo => {
                                const warning = getVar(packageInfo, 'warning', [])
                                const code = getVar(packageInfo, 'code')
                                const deliveryNoteExportedAt = getVar(packageInfo, 'delivery_note.exported_at')
                                const deliveryRequestCreatedAt = getVar(packageInfo, 'delivery_request.created_at')
                                const createdAt = !!action ? deliveryNoteExportedAt : deliveryRequestCreatedAt
                                const scanImportedAt = getVar(packageInfo, 'imported_at')
                                const deliveryAddress = getVar(packageInfo, 'delivery_request.address')
                                const weightNet = getVar(packageInfo, 'weight_net', 0)
                                return (
                                    <tbody className={warning.length > 0 ? 'warning' : ''}>
                                        <tr className="data">
                                            <td>
                                                {
                                                    <Link
                                                        isNewTab
                                                        to="packages.detail"
                                                        params={{ id: code }}>
                                                        {code}
                                                    </Link>
                                                }
                                            </td>
                                            <td>{isNil(createdAt) ? '--' : dateFormatter.dateTime(createdAt)}</td>
                                            <td>{!!action ? (isNil(scanImportedAt) ? '--' : dateFormatter.dateTime(scanImportedAt)) : deliveryAddress}</td>
                                            <td className="_package_code">{(weightNet ? weightNet : 0) + ' (kg)'}</td>
                                            {!!action && (
                                                <td>
                                                    <Tooltip title={t('return_goods:title.remove_package')}>
                                                        <Icon
                                                            theme="filled"
                                                            style={{ color: 'red' }}
                                                            type="delete"
                                                            onClick={() => handleRemovePackage(getVar(packageInfo, 'id'))}
                                                        />
                                                    </Tooltip>
                                                </td>
                                            )}
                                        </tr>

                                        {warning.length > 0 && (
                                            <tr className="message">
                                                <td
                                                    colSpan={!!action ? 5 : 4}
                                                    className="_box_message_error">
                                                    {renderNotices(warning)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )
                            })}
                    </table>
                </div>
            </div>
        </Content>
    )
}
export default withTranslation()(PackageList)
