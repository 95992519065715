import { notification } from 'antd';
import { get } from 'lodash';

import * as ACTION from './constants';
import apiService from './../apiService';
import { processApiRequest } from './../../../Common/saga';
import { t } from './../../../../system/i18n';
import { router } from './../../../../system/routing';

export function* update(action) {
    yield processApiRequest(
        ACTION.UPDATE,
        () => apiService.update(action.payload),
        action.payload
    );
}

export function* successUpdate(action) {
    yield notification.success({
        message: t('delivery_note:return.update.success')
    });

    router.redirect(`/delivery-notes/return/${get(action, 'payload.delivery_note_return.id', 0)}`);
}

export function* failedUpdate(action) {
    const error = get(action, 'payload.data');

    if (get(error, 'warehouse')) {
        return yield notification.error({
            message: t('message.warehouse_not_is_staff')
        });
    }
}
