import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

class FormProcessing extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        let nextInput = FormProcessing.getNextInputFromProps(nextProps, prevState);

        if (JSON.stringify(prevState.input) !== JSON.stringify(nextInput)) {
            state.input = nextInput;
        }

        if (lodash.has(prevState, 'inputVersion') && prevState.inputVersion !== nextProps.inputVersion) {
            state.inputVersion = nextProps.inputVersion;
        }

        return lodash.isEmpty(state) ? null : state;
    }

    static getNextInputFromProps(nextProps, prevState) {
        let propInput = nextProps.input || {};

        let shouldOverride = (
            lodash.has(prevState, 'inputVersion')
            && prevState.inputVersion !== nextProps.inputVersion
        );

        return shouldOverride
            ? {...propInput}
            : FormProcessing.compileInput(prevState.input, propInput, prevState.changedParams);
    }

    static compileInput(prevInput, nextInput, changedParams) {
        let formParams = lodash.keys(prevInput);
        let allowedParams = lodash.difference(formParams, changedParams);

        return {...prevInput, ...lodash.pick(nextInput, allowedParams)};
    }

    changeInput(input, cb) {
        this.setChangedParam(...lodash.keys(input));
        return this.updateInput(input, cb);
    }

    setChangedParam(...params) {
        this.setState({changedParams: lodash.uniq([...this.state.changedParams, ...params])});
    }

    removeChangedParam(...params) {
        this.setState({changedParams: this.state.changedParams.filter(changedParam => params.includes(changedParam))});
    }

    updateInput(data, cb) {
        let input = {...this.state.input, ...data};
        this.setState({input}, cb);

        return input;
    }

    onChangeInput(param, event) {
        let target = event.target;
        this.changeInput({[param]: ['checkbox', 'radio'].includes(target.type) ? target.checked : target.value});
    }

    onPasteInput(event) {
        const _self = this;
        const clipboardData = event.clipboardData;
        const allowedAttachFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
        const allowedAttachFileSize = 5120000; // bytes => 5MB

        // Kiểm tra xem clipboard có phải là dạng Files ko đã, không phải thì ko xử lý gì
        if (lodash.includes(clipboardData.types, 'Files')) {
            let files = clipboardData.items;

            if (files.length > 0) {
                lodash.forEach(files, function (file) {
                    if (!lodash.includes(allowedAttachFileTypes, file.type)) {
                        // @TODO Thông báo định dạng file không cho phép

                        return true;
                    }

                    if (file.size > allowedAttachFileSize) {
                        // @TODO Thông báo kích thước file không vượt quá 5Mb

                        return;
                    }

                    let attachFile = file.getAsFile();
                    let reader = new FileReader();
                    // Bắt đầu đọc file
                    reader.readAsDataURL(attachFile);

                    // Chờ file load xong tạm trong DOM mới lấy đươc base64 data
                    reader.onload = function (evt) {
                        _self.setState({inputAttachFile: evt.target.result});
                    };

                });
            }
        }
    }

    onRemoveAttachFile() {
        this.setState({inputAttachFile: ''});
    }

    /**
     * Dành riêng cho việc change input có type = number
     * @param param
     * @param event
     */
    onChangeInputNumber(param, event) {
        let target = event.target;
        let value = "" + target.value;
        value = value.replace(/[^0-9.]/g, '').replace("..", '.');
        let index = value.indexOf(".");
        if (index !== -1) {
            let lengthSave = value.length;
            value = value.split(".").join("");
            value = value.slice(0, index) + "." + value.slice(index, lengthSave);
        }
        this.changeInput({[param]: ['checkbox', 'radio'].includes(target.type) ? target.checked : value});
    }

    /**
     * Dành riêng cho việc change input có nhập số nguyên
     * @param param
     * @param event
     */
    onChangeInputInteger(param, event) {
        let target = event.target;
        let value = "" + target.value;
        value = value.replace(/[^0-9]/g, '');
        this.changeInput({[param]: ['checkbox', 'radio'].includes(target.type) ? target.checked : value});
    }

    /**
     * Dành riêng cho việc change input có nhập số nguyên > 0
     * @param param
     * @param event
     */
    onChangeInputIntegerNotZero(param, event) {
        let target = event.target;
        let value = "" + target.value;
        value = value.replace(/[^1-9]/g, '');
        this.changeInput({[param]: ['checkbox', 'radio'].includes(target.type) ? target.checked : value});
    }

}

FormProcessing.defaultProps = {
    input: {},
    inputVersion: 1,
};

FormProcessing.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
};

export default FormProcessing;
