import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let users = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_USERS.SUCCESS:
            return payload.users.map(item => ({...item.user, ...lodash.omit(item, 'user')}));
        default:
            return state;
    }
};
let detail = (state = {}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_DETAIL_USERS.SUCCESS:
            return payload.shipping_user;
        case ACTION.CLEAR_DETAIL_USER_STATE:
            return {};
        default:
            return state;
    }
};


let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_USERS.REQUEST:
            return true;
        case ACTION.FETCH_USERS.SUCCESS:
        case ACTION.FETCH_USERS.FAILED:
            return false;

        default:
            return state;
    }
};
let loadingDetail = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_DETAIL_USERS.REQUEST:
            return true;
        case ACTION.FETCH_DETAIL_USERS.SUCCESS:
        case ACTION.FETCH_DETAIL_USERS.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    users,
    loading,
    detail,
    loadingDetail
});
