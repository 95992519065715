import { takeLatest } from 'redux-saga/effects'

import { CREATE, GET_DELIVERY_CUSTOMER_REQUESTS, CREATE_WITH_TRACKING_NO } from './constants'
import {
    create,
    failedCreate,
    getDeliveryCustomerRequests,
    successCreate,
    succcessScanBarcode,
    createWithTrackingNo,
    failedCreateWithTrackingNo,
    successCreateWithTrackingNo,
} from './saga'
import { SCAN_BARCODE } from './../DeliveryNoteBarcode/constants'

export default function* () {
    yield takeLatest(CREATE.REQUEST, create)
    yield takeLatest(CREATE.FAILED, failedCreate)
    yield takeLatest(CREATE.SUCCESS, successCreate)
    yield takeLatest(SCAN_BARCODE.SUCCESS, succcessScanBarcode)
    yield takeLatest(GET_DELIVERY_CUSTOMER_REQUESTS.REQUEST, getDeliveryCustomerRequests)
    yield takeLatest(CREATE_WITH_TRACKING_NO.REQUEST, createWithTrackingNo)
    yield takeLatest(CREATE_WITH_TRACKING_NO.FAILED, failedCreate)
    yield takeLatest(CREATE_WITH_TRACKING_NO.SUCCESS, successCreateWithTrackingNo)
}
