import { processApiRequest } from './../../Common/saga'
import * as ACTION from './constants'
import apiService from './apiService'
import lodash, { get, isArray, isEmpty, join, map } from 'lodash'
import { notification } from 'antd'
import { t, trans } from '../../../system/i18n'
import React from 'react'

export function* packingPackage(action) {
    yield processApiRequest(ACTION.PACKING_PACKAGE, () => apiService.packingPackage(action.payload), action.payload)
}

export function* packingPackageFailed(action) {
    const error = lodash.get(action, 'payload.data')
    if (isArray(error)) {
        error.forEach(item => {
            return notification.error({
                message: join(
                    map(get(item, 'error.packing_not_completed', []), ele =>
                        trans(`bag:lastmile.message.packing_not_completed.${ele}`, { bag_code: item.package })
                    ),
                    ' ,'
                ),
            })
        })
    } else {
        Object.entries(error).forEach(([key, value]) => {
            if (Object.keys(value).length > 1) {
                const code = get(value, "code", get(value, "message"))
                const bag_lastmile = get(value, "bag_lastmile")
                const packages = get(value, "packages", [])
                return notification.error({
                    message: trans(`bag:lastmile.message.${key}.${code}`, {
                        bag_code: <b>{bag_lastmile}</b>,
                        packages: packages.join(", "),
                        count: packages.length
                    }),
                })
            } else {
                return notification.error({
                    message: t(`bag:lastmile.message.${key}.${Object.keys(value)[0]}`),
                })
            }
        })
    }

    if (lodash.get(error, 'warehouse')) {
        return yield notification.error({
            message: <span className="_scan_package_failed">{t('message.warehouse_not_is_staff')}</span>,
        })
    }
    if (lodash.get(error, 'packing_not_completed.unauthorized')) {
        return yield notification.error({
            message: <span className="_scan_package_failed">{t('message.unauthorized')}</span>,
        })
    }
}

export function* packingPackageSuccess(action) {
    const packageExisted = lodash.get(action, 'payload.packageExisted', [])

    if (!isEmpty(packageExisted)) {
        packageExisted.forEach(item => {
            notification.error({
                message: trans(`bag:lastmile.message.package_exist`, {
                    package_code: <b>{item.code}</b>,
                }),
            })
        })
    }

    return yield ''
}
