import React, { Component } from 'react';
import { Button, Icon, Table } from 'antd';
import clsx from 'clsx';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import { INVENTORY_STATUS_PROCESSING } from './../../../constants';

import Link from '../../../../../system/routing/Link';

class Disappear extends Component {
    state = {
        selectedRowKeys: []
    };

    changePackageStatus = data => {
        const { inventory, updateInventoryPackageStatus } = this.props;

        if (data.loading || data.current_is_resolved === data.is_resolved || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return;
        }

        updateInventoryPackageStatus({
            id: data.item.id,
            is_resolved: data.is_resolved,
        });
    };

    handleChangeSelectedRowKeys = keys => {
        this.setState({
            selectedRowKeys: keys
        });
    };

    handleResolveInventoryPackagesButtonClick = data => {
        const { inventory, resolvingDisappearInventoryPackages, resolveDisappearInventoryPackages } = this.props;

        if (resolvingDisappearInventoryPackages || inventory.status !== INVENTORY_STATUS_PROCESSING) {
            return;
        }

        resolveDisappearInventoryPackages(data);
    };

    render() {
        const { selectedRowKeys } = this.state;
        const {
            disappears,
            downloadingDisappearPackages,
            inventory,
            loading,
            resolvingDisappearInventoryPackages,
            t,
            onDownloadDisappearPackagesButtonClick
        } = this.props;

        const columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            key: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:package_code'),
            dataIndex: 'inventory_package.code_package',
            key: 'code_package',
            render: text => (
                <Link
                    className="a-text--nowrap"
                    isNewTab
                    params={{id: text}}
                    to="packages.detail"
                >
                    {text}
                </Link>
            )
        }, {
            title: t('warehouse_status'),
            dataIndex: 'package.status_warehouse',
            key: 'warehouse_status',
            render: text => text ? t(`warehouse_statuses.${text}`) : '--'
        }, {
            title: t('current_warehouse'),
            dataIndex: 'current_warehouse.code',
            key: 'current_warehouse'
        }, {
            title: t('inventory:transporting_at'),
            dataIndex: 'package.transporting_at',
            key: 'transporting_at',
            render: text => text ? dateFormatter.full(text) : '--'
        }, {
            title: (
                <Button
                    className="_btn-processes a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryPackagesButtonClick.bind(this, {
                        ids: selectedRowKeys,
                        is_resolved: true
                    })}
                >
                    <Icon type={resolvingDisappearInventoryPackages ? 'loading' : 'check'} />
                    <span className="ml-2">{t('inventory:processed')}</span>
                </Button>
            ),
            key: 'processed',
            render: (text, record) => {
                const isResolved = record.inventory_package.is_resolved;
                const inventoryStatus = this.props.inventory.status;
                const canUpdate = inventoryStatus === INVENTORY_STATUS_PROCESSING;
                const data = {
                    item: record.inventory_package,
                    is_resolved: true,
                    loading: record.loading,
                    current_is_resolved: record.inventory_package.is_resolved
                };

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action', {
                                active: isResolved,
                                'disable-active': !canUpdate && isResolved,
                                'disable-unactive': !canUpdate && !isResolved
                            })}
                            type={(record.loading || loading) ? 'loading' : 'check'}
                            onClick={this.changePackageStatus.bind(this, data)}
                        />
                    </div>
                );
            }
        }, {
            title: (
                <Button
                    className="_btn-disappears a-btn a-btn--primary"
                    disabled={inventory.status !== INVENTORY_STATUS_PROCESSING}
                    type="primary"
                    onClick={this.handleResolveInventoryPackagesButtonClick.bind(this, {
                        ids: selectedRowKeys,
                        is_resolved: false
                    })}
                >
                    <Icon type={resolvingDisappearInventoryPackages ? 'loading' : 'question'} />
                    <span className="ml-2">{t('inventory:lost')}</span>
                </Button>
            ),
            key: 'disappear',
            render: (text, record) => {
                const isResolved = record.inventory_package.is_resolved;
                const inventoryStatus = this.props.inventory.status;
                const canUpdate = inventoryStatus === INVENTORY_STATUS_PROCESSING;
                const data = {
                    item: record.inventory_package,
                    is_resolved: false,
                    loading: record.loading,
                    current_is_resolved: record.inventory_package.is_resolved
                };

                return (
                    <div className="group-action-cross-check">
                        <Icon
                            className={clsx('action', {
                                active: isResolved === false,
                                'disable-active': !canUpdate && isResolved === false,
                                'disable-unactive': !canUpdate && isResolved !== false
                            })}
                            type={record.loading ? 'loading' : 'question'}
                            onClick={this.changePackageStatus.bind(this, data)}
                        />
                    </div>
                );
            }
        }];

        return (
            <>
                <div className="a-content__top a-content__top--page-list mt-0 mr-0">
                    <div className="width-100-pc a-content--page-list__title gflex galign-center gjustify-space-between">
                        <h3 className="a-text--medium a-text--fz-16">
                            {t('inventory:disappear_list.package')} ({disappears.length})
                        </h3>
                        <Button
                            loading={downloadingDisappearPackages}
                            onClick={onDownloadDisappearPackagesButtonClick}
                        >
                            {t('btn.export_excel')}
                        </Button>
                    </div>
                </div>
                <div className="aflex-1 a-content__body bg-white a-content__body--page-list mr-0 aoverflow-y pb-0">
                    <Table
                        className="_table-disappear a-table--antd a-table--antd-scroll-x a-table--error-fixed"
                        columns={columns}
                        dataSource={disappears}
                        loading={loading}
                        pagination={false}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: this.handleChangeSelectedRowKeys
                        }}
                        scroll={{x: 'fit-content'}}
                        rowKey={row => get(row, 'inventory_package.id')}
                    />
                </div>
            </>
        )
    }
}

export default withTranslation()(Disappear);
