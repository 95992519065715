import lodash from 'lodash';

export default class InputFocusParser {
    /**
     * Tạo input focus code cho package
     *
     * @param {int} packageId
     * @param {string} element
     * @return {string}
     */
    make(packageId, element) {
        return `package.${packageId}.${element}`;
    }

    /**
     * Lấy element sẽ được focus từ package input focus
     *
     * @param {string} inputFocus
     * @param {int} packageId
     * @return {string|null}
     */
    parse(inputFocus, packageId) {
        let path = `package.${packageId}.`;

        return lodash.startsWith(inputFocus, path) ? lodash.last(inputFocus.split(path)) : null;
    }
}
