export const CHANGE_PACKAGE_TRANSPORT_STATUS = {
    REQUEST: 'PACKAGE.CHANGE_PACKAGE_TRANSPORT_STATUS.REQUEST',
    SUCCESS: 'PACKAGE.CHANGE_PACKAGE_TRANSPORT_STATUS.SUCCESS',
    FAILED: 'PACKAGE.CHANGE_PACKAGE_TRANSPORT_STATUS.FAILED',
};

export const BEGIN_CHANGE_PACKAGE_TRANSPORT_STATUS = "BEGIN_CHANGE_PACKAGE_TRANSPORT_STATUS";
export const END_CHANGE_PACKAGE_TRANSPORT_STATUS = "END_CHANGE_PACKAGE_TRANSPORT_STATUS";
export const STATUS_TRANSPORT_PACKAGE = {
    INIT: 'INIT',
    MIA: 'MIA',
    PUTAWAY: 'PUTAWAY',
    WAIT_FOR_TRANSPORTING: "WAIT_FOR_TRANSPORTING",
    TRANSPORTING: 'TRANSPORTING',
    READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
    REQUEST_FOR_DELIVERY: 'REQUEST_FOR_DELIVERY',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    WAIT_FOR_RETURN: 'WAIT_FOR_RETURN',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    INACTIVE: 'INACTIVE',
    CIRCULATE: 'CIRCULATE',
    REFUSED: 'REFUSED',
    WAIT_FOR_LIQUIDATION: 'WAIT_FOR_LIQUIDATION',
    LIQUIDATED: 'LIQUIDATED'
};

export const UPDATE_TRANSPORT_STATUS = {
    REQUEST: 'PACKAGE.UPDATE_TRANSPORT_STATUS.REQUEST',
    SUCCESS: 'PACKAGE.UPDATE_TRANSPORT_STATUS.SUCCESS',
    FAILED: 'PACKAGE.UPDATE_TRANSPORT_STATUS.FAILED'
}
