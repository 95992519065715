import React from 'react'
import { Button, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import FormProcessing from '../../../Common/components/FormProcessing'
import { split, omit } from 'lodash'
import { converValue2Int } from '../../../Common/services/helps'
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import SelectUserContainer from '../../../User/SelectUser/SelectUserContainer'
import SelectSuggestCustomer from '../../../DeliveryNote/ListDeliveryNote/components/SelectSuggestCustomer'
import SelectAgency from '../../../Setting/Agency/SelectAgency'
import { LASTMILE_TRACKING_NO_STATUS } from '../../constants'
import ShippingDomesticPartnersSelect from '../../../Common/components/Selects/ShippingDomesticPartnersSelect'

const { Search } = Input

const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
const threeMonthsAgo = moment(currentDate).subtract(3, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss')

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props)

        this.defaultInput = {
            'created_at[from]': threeMonthsAgo,
            id_agency: undefined,
            id_warehouse: undefined,
            id_creator: undefined,
            tracking_no: undefined,
            package_code: undefined,
            delivery_request_code: undefined,
            delivery_note_code: undefined,
            id_last_mile_carrier: undefined,
            customer_username: undefined,
            status: undefined,
            'history[status]': undefined,
            'history[from]': undefined,
            'history[to]': undefined,
        }

        this.state = {
            input: { ...this.defaultInput },
            inputVersion: 0,
            changedParams: [],
            validateStatuses: {
                history: undefined,
            },
            helps: {
                history: undefined,
            },
        }
    }

    componentDidUpdate() {
        const { input, validateStatuses } = this.state
        const { t } = this.props

        if (input['history[status]'] && !input['history[from]'] && !input['history[to]']) {
            if (validateStatuses['history'] !== 'error') {
                this.setState({
                    validateStatuses: {
                        history: 'error',
                    },
                    helps: {
                        history: t('validation:value_invalid', {
                            attribute: t('lastmile_order:label.history_change_status'),
                        }),
                    },
                })
            }
        } else {
            if (validateStatuses['history'] === 'error') {
                this.setState({
                    validateStatuses: {
                        history: undefined,
                    },
                    helps: {
                        history: undefined,
                    },
                })
            }
        }
    }

    /**
     * Get filter
     * @param filter
     * @returns {*}
     */
    getFilter(filter) {
        let page = this.props.page
        if (page === 'list_delivery_note_pack') {
            filter.packing_complete = 0
        }
        return filter
    }

    onSubmit(event) {
        event.preventDefault()

        if (!this.props.loading) {
            let { input } = this.state
            input.page = 1
            this.props.onSearch(input)
        }
    }

    onReset(event) {
        event.preventDefault()
        if (!this.props.loading) {
            let filter = this.getFilter(this.defaultInput)
            this.changeInput(filter)
            this.props.onSearch(filter)
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : '',
        })
    }

    onChangeSelect(name, value) {
        if (!this.props.loading) {
            let { input } = this.state
            input.page = 1

            if (name === 'id_customer') {
                this.props.onSearch({
                    ...input,
                    [name]: split(value, ';')[0],
                    customer_username: split(value, ';')[1],
                })
            } else {
                this.props.onSearch({
                    ...input,
                    [name]: value,
                })
            }
        }
    }

    onChangeMultiSelect(name, value) {
        this.changeInput({ [name]: value })
    }

    render() {
        const { loading, t, loadingShippingPartner, shippingPartners } = this.props
        const input = converValue2Int({
            ...this.state.input,
        })
        const { helps, validateStatuses } = this.state

        return (
            <div className="a-content--search-header search-default">
                <Form
                    onSubmit={this.onSubmit.bind(this)}
                    className="a-form">
                    <Row gutter={{ lg: 12, xl: 24, xxl: 24 }}>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t(`lastmile_order:label.tracking_no`)}
                                labelCol={{ span: 24 }}>
                                <Search
                                    value={input.tracking_no || ''}
                                    onChange={this.onChangeInput.bind(this, 'tracking_no')}
                                    className="a-inputonChangeInput--search _tracking_no_search"
                                    placeholder={t(`lastmile_order:placeholder.tracking_no`)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t(`lastmile_order:label.package_code`)}
                                labelCol={{ span: 24 }}>
                                <Search
                                    value={input.package_code || ''}
                                    onChange={this.onChangeInput.bind(this, 'package_code')}
                                    className="a-inputonChangeInput--search _package_code_search"
                                    placeholder={t(`lastmile_order:placeholder.package_code`)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t(`lastmile_order:label.delivery_request_code`)}
                                labelCol={{ span: 24 }}>
                                <Search
                                    value={input.delivery_request_code || ''}
                                    onChange={this.onChangeInput.bind(this, 'delivery_request_code')}
                                    className="a-inputonChangeInput--search _delivery_request_code_search"
                                    placeholder={t(`lastmile_order:placeholder.delivery_request_code`)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t(`lastmile_order:label.delivery_note_code`)}
                                labelCol={{ span: 24 }}>
                                <Search
                                    value={input.delivery_note_code || ''}
                                    onChange={this.onChangeInput.bind(this, 'delivery_note_code')}
                                    className="a-inputonChangeInput--search _delivery_note_code_search"
                                    placeholder={t(`lastmile_order:placeholder.delivery_note_code`)}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={7}
                            xl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('bag:label.customer_code')}
                                labelCol={{ span: 24 }}>
                                <SelectSuggestCustomer
                                    value={input.customer_username || ''}
                                    onChange={this.onChangeSelect.bind(this, 'id_customer')}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('lastmile_order:label.id_last_mile_carrier')}
                                labelCol={{ span: 24 }}>
                                <ShippingDomesticPartnersSelect
                                    allowClear
                                    loading={loadingShippingPartner}
                                    shippingDomesticPartners={shippingPartners}
                                    value={input.id_last_mile_carrier}
                                    onChange={idPartnerShippingDomestic =>
                                        this.changeInput({
                                            id_last_mile_carrier: idPartnerShippingDomestic,
                                        })
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={11}
                            xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('lastmile_order:label.created_at')}
                                labelCol={{ span: 24 }}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('lastmile_order:label.status')}
                                labelCol={{ span: 24 }}>
                                <Select
                                    allowClear
                                    className="a-select a-select--search-content"
                                    dropdownClassName="_dropdown_status"
                                    placeholder={t('lastmile_order:label.status')}
                                    value={input.status}
                                    onChange={status => this.changeInput({ status })}>
                                    {Object.values(LASTMILE_TRACKING_NO_STATUS).map(item => (
                                        <Select.Option
                                            key={item}
                                            value={item}>
                                            {t(`delivery_note:LAST_MILE_SHIPMENT_STATUS.${item}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label="Agency"
                                labelCol={{ span: 24 }}>
                                <SelectAgency
                                    showSearch={true}
                                    allowClear
                                    value={input.id_agency}
                                    optionFilterProp="children"
                                    onChange={agencyId =>
                                        this.changeInput({
                                            id_agency: agencyId,
                                        })
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={4}
                            xl={3}
                            xxl={3}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('lastmile_order:label.id_warehouse')}
                                labelCol={{ span: 24 }}>
                                <SelectWarehouse
                                    allowClear
                                    className="a-select a-select--search-content"
                                    dropdownClassName="_dropdown_id_warehouse"
                                    placeholder={t('lastmile_order:label.id_warehouse')}
                                    value={input.id_warehouse}
                                    onChange={id_warehouse => this.changeInput({ id_warehouse })}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={7}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('lastmile_order:label.id_creator')}
                                labelCol={{ span: 24 }}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content _person_create_customer"
                                    dropdownClassName="_dropdown_person_create_customer'"
                                    emptyOption={false}
                                    placeholder={t('lastmile_order:label.id_creator')}
                                    value={input.id_creator}
                                    onChange={id_creator => this.changeInput({ id_creator })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="a-form__item search-content"
                                help={helps['history']}
                                label={t('lastmile_order:label.history_change_status')}
                                validateStatus={validateStatuses['history']}
                                labelCol={{ span: 24 }}>
                                <Col lg={8}>
                                    <Select
                                        allowClear
                                        className="a-select a-select--search-content _length-of-transport-status-state"
                                        optionFilterProp="children"
                                        placeholder={t('lastmile_order:label.status')}
                                        showSearch={true}
                                        value={input['history[status]']}
                                        onChange={value => {
                                            return this.changeInput({ 'history[status]': value })
                                        }}>
                                        {Object.values(omit(LASTMILE_TRACKING_NO_STATUS, LASTMILE_TRACKING_NO_STATUS.NEW)).map(item => (
                                            <Select.Option
                                                key={item}
                                                value={item}>
                                                {t(`delivery_note:LAST_MILE_SHIPMENT_STATUS.${item}`)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                        }}
                                        value={input['history[from]'] ? moment(input['history[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'history[from]')}
                                    />
                                </Col>
                                <Col span={8}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                        }}
                                        value={input['history[to]'] ? moment(input['history[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'history[to]')}
                                    />
                                </Col>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action">
                        <a
                            href="/"
                            className="link-reload "
                            onClick={this.onReset.bind(this)}>
                            <Icon type="sync" />
                            {t('common:label.refresh_filter')}
                        </a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list"
                            loading={loading}>
                            {t('btn.search')}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default withTranslation()(FormSearch)
