export const ADD_BARCODE = 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.ADD_BARCODE';

export const REMOVE_BARCODE = 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.REMOVE_BARCODE';

export const SCAN_BARCODE = {
    REQUEST: 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.SCAN_BARCODE.REQUEST',
    SUCCESS: 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.SCAN_BARCODE.SUCCESS',
    FAILED: 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.SCAN_BARCODE.FAILED',
};

export const SET_BARCODES = 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.SET_BARCODES';

export const CLEAR_STATE = 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.CLEAR_STATE';

export const BARCODE_TYPE = {
    PACKAGE: 'PACKAGE',
    BAG: 'BAG',
    PACKAGE_ERROR: 'PACKAGE_ERROR'
};

export const UPDATE_BARCODE = 'DELIVERY_NOTE.DELIVERY_NOTE_BARCODES.UPDATE_BARCODE';
