import React, {Component} from 'react';
import { Table } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import Link from './../../../../../system/routing/Link';

class Normal extends Component {
    render() {
        const { normals, loading, t } = this.props;
        let columns = [];

        columns = [{
            title: t('table.#'),
            dataIndex: 'stt',
            render: (text, record, index) => index + 1
        }, {
            title: t('inventory:column.code.package'),
            dataIndex: 'inventory_package.code_package',
            key: 'code_package',
            render: text => (
                <Link
                    className="a-text--nowrap"
                    isNewTab
                    params={{id: text}}
                    to="packages.detail"
                >
                    {text}
                </Link>
            )
        }, {
            title: t('warehouse_status'),
            dataIndex: 'package.status_warehouse',
            key: 'warehouse_status',
            render: text => t(`warehouse_statuses.${text}`)
        }, {
            title: t('current_warehouse'),
            dataIndex: 'current_warehouse.code',
            key: 'current_warehouse'
        }, {
            title: t('inventory:transporting_at'),
            dataIndex: 'package.transporting_at',
            key: 'transporting_at',
            render: text => text ? dateFormatter.full(text) : '--'
        }];

        return (
            <Table
                className="a-table--antd a-table--antd-scroll-x"
                columns={columns}
                loading={loading}
                dataSource={normals}
                pagination={false}
                scroll={{ x: 'fit-content' }}
                rowKey={row => get(row, 'inventory_package.id')}
            />
        );
    }
}

export default withTranslation()(Normal);
