/* eslint-disable */
import React, {Component} from 'react';
import {   Button, Input,   Row,  Col, Icon,   Pagination, Form, Radio, Checkbox, Select, Modal, Table} from 'antd';

const TextArea = Input.TextArea;
const Search = Input.Search;
const columns = [{
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    render: text => <span className="a-text--nowrap">{text}</span>,
}, {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    className: "a-text--nowrap",
}, {
    title: 'Kích thước',
    dataIndex: 'size',
    key: 'size',
    render: i => <span>{i % 2 == 0 ? "Trong kho" : "Xuất kho"}</span>,
},{
    title: 'Đặc tính',
    dataIndex: 'attribute',
    key: 'attribute',
    className: "a-text--nowrap",
}];

const data = [];
for (let i = 0; i < 20; i++) {
    data.push({
        stt:i+1,
        name: `Bao của Trọng pro ${i +1}`,
        size: `Lớn`,
        attribute: `Điện tử / Hoá Chất / Dễ Vỡ`,
    });
}
class CreateBag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:false
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Form onSubmit={this.handleSubmit} className="a-block">
                    <div className="block--primary a-content--bag">
                        <div className="title create-bag">
                            <div className="left">
                                <span className="a-text--bold">BAO MỚI</span>
                            </div>
                            <div className="right">
                                <Button type="button" className="a-btn a-btn--transparent-white" ghost onClick={this.showModal}>Chọn bao mẫu</Button>
                            </div>
                        </div>
                        <div className="body ">
                            <Row gutter={{lg: 24, xl: 48}}>
                                <Col lg={{span: 18}} xl={{span: 19}} xxl={{span: 19}}>
                                    <div className="a-block-info bg-gray">
                                        <Row gutter={{lg: 24, xl: 32}} className="a-form pt-5 mr-0 ml-0">
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 4}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item " label="Kích thước"
                                                           labelCol={{span: 24}}
                                                           required={true}
                                                >
                                                    <Select
                                                        className="a-select a-select--w-100"
                                                        showSearch={true}
                                                        defaultValue="Siêu siêu lớn"
                                                        suffixIcon={
                                                            <Icon
                                                                type="down"
                                                                className="icon__dropdown__select"
                                                                onChange={this.handleSelectChange}
                                                            />}
                                                    >
                                                        <Option value='Siêu siêu lớn'>Siêu siêu lớn</Option>
                                                        <Option value='Siêu siêu bé'>Siêu siêu bé</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 24, offset: 0}} xl={{span: 17, offset: 0}}
                                                 xxl={{span: 18, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item  "
                                                           label="Đặc tính"
                                                           labelCol={{span: 24}}
                                                           required={true}>
                                                    <ul className="property--package">
                                                        <li className="list active">
                                                            <a href="#" className="link">Điện tử</a>
                                                        </li>
                                                        <li className="list">
                                                            <a href="#" className="link">Dễ vỡ</a>
                                                        </li>
                                                        <li className="list">
                                                            <a href="#" className="link">Hóa chất</a>
                                                        </li>
                                                        <li className="list active">
                                                            <a href="#" className="link">Giá trị cực cực cao</a>
                                                        </li>
                                                    </ul>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={{lg: 24, xl: 32}} className="a-form mr-0 ml-0">
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 4}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item " label="Loại vận chuyển"
                                                           labelCol={{span: 24}} required={true}>
                                                    <Select
                                                        className="a-select a-select--w-100"
                                                        showSearch={true}
                                                        defaultValue="Người vận chuyển 1"
                                                        suffixIcon={
                                                            <Icon
                                                                type="down"
                                                                className="icon__dropdown__select"
                                                                onChange={this.handleSelectChange}
                                                            />}
                                                    >
                                                        <Option value='Người vận chuyển 1'>Nhân viên</Option>
                                                        <Option value='Người vận chuyển 2'>Đơn vị</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 8, offset: 2}} xl={{span: 6, offset: 0}}
                                                 xxl={{span: 4, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item " label="Vận chuyển"
                                                           labelCol={{span: 24}}  required={true}>
                                                    <Select
                                                        className="a-select a-select--w-100"
                                                        showSearch={true}
                                                        defaultValue="Người vận chuyển 1"
                                                        suffixIcon={
                                                            <Icon
                                                                type="down"
                                                                className="icon__dropdown__select"
                                                                onChange={this.handleSelectChange}
                                                            />}
                                                    >
                                                        <Option value='Người vận chuyển 1'>Người vận chuyển 1</Option>
                                                        <Option value='Người vận chuyển 2'>Người vận chuyển 2</Option>
                                                        <Option value='Người vận chuyển 3'>Người vận chuyển 3</Option>
                                                        <Option value='Người vận chuyển 4'>Người vận chuyển 4</Option>
                                                        <Option value='Người vận chuyển 5'>Người vận chuyển 5</Option>
                                                        <Option value='Người vận chuyển 6'>Người vận chuyển 6</Option>
                                                        <Option value='Người vận chuyển 7'>Người vận chuyển 7</Option>
                                                        <Option value='Người vận chuyển 8'>Người vận chuyển 8</Option>
                                                        <Option value='Người vận chuyển 9'>Người vận chuyển 9</Option>
                                                        <Option value='Người vận chuyển 10'>Người vận chuyển 10</Option>
                                                        <Option value='Người vận chuyển 11'>Người vận chuyển 11</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 8, offset: 0}} xl={{span: 6, offset: 0}}
                                                 xxl={{span: 4, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item  "
                                                           label="Người nhận"
                                                           labelCol={{span: 24}}  required={true}>
                                                    <Input placeholder={"Nhập người nhận"}
                                                           className="a-input"/>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 8, offset: 2}} xl={{span: 6, offset: 0}}
                                                 xxl={{span: 4, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item  "
                                                           label="Số điện thoại nhận"
                                                           labelCol={{span: 24}}  required={true}>
                                                    <Input placeholder={"Nhập số điện thoại"}
                                                           className="a-input"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={{lg: 24, xl: 32}} className="a-form  mr-0 ml-0">
                                            <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 4}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item " label="Kho tạo"
                                                           labelCol={{span: 24}}  required={true}>
                                                    <Select
                                                        className="a-select a-select--w-100"
                                                        showSearch={true}
                                                        defaultValue=" Kho tạo"
                                                        suffixIcon={
                                                            <Icon
                                                                type="down"
                                                                className="icon__dropdown__select"
                                                                onChange={this.handleSelectChange}
                                                            />}
                                                    >
                                                        <Option value='Kho tạo'>Kho tạo 1</Option>
                                                        <Option value='Kho tạo'>Kho tạo 2</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 8, offset: 2}} xl={{span: 6, offset: 0}}
                                                 xxl={{span: 4, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item " label="Kho nhận"
                                                           labelCol={{span: 24}}  required={true}>
                                                    <Select
                                                        className="a-select a-select--w-100"
                                                        showSearch={true}
                                                        defaultValue=" Kho nhận"
                                                        suffixIcon={
                                                            <Icon
                                                                type="down"
                                                                className="icon__dropdown__select"
                                                                onChange={this.handleSelectChange}
                                                            />}
                                                    >
                                                        <Option value='Kho nhận'>Kho nhận 1</Option>
                                                        <Option value='Kho nhận'>Kho nhận 2</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={{span: 24, offset: 0}} xl={{span: 12, offset: 0}}
                                                 xxl={{span: 9, offset: 1}}
                                                 className="a-form--group">
                                                <Form.Item className="a-form__item  " label="Ghi chú bao"
                                                           labelCol={{span: 24}} >
                                                            < TextArea className="a-input--textarea"
                                                                       autosize={{minRows: 1, maxRows: 3}}
                                                                       allowClear={true}
                                                                       spellCheck={false}
                                                                       placeholder={"Ghi chú"}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={{span: 6}} xl={{span: 5}} xxl={{span: 5}} className="pl-0">
                                    <div className="a-package--service">
                                        <h2 className="title">Lựa Chọn Dịch Vụ</h2>
                                        <div className="service--body">
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <ul>
                                                            <li>
                                                                <Radio className="label--service" checked>Chuyển
                                                                    thẳng</Radio>
                                                            </li>
                                                            <li>
                                                                <Radio className="label--service">Chuyển
                                                                    phát nhanh</Radio>
                                                            </li>
                                                            <li>
                                                                <Radio className="label--service">Chuyển
                                                                    phát tiết kiệm</Radio>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </h4>
                                            </div>
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <div className="pb-5">
                                                            <ul className="mt-5">
                                                                <li>
                                                                    <Checkbox className="label--service"
                                                                              checked>Ký
                                                                        gửi</Checkbox>
                                                                </li>
                                                                <li>
                                                                    <Checkbox className="label--service">Kiểm
                                                                        hàng</Checkbox>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </h4>
                                            </div>
                                            <div className="service--block">
                                                <h4 className="title--block">
                                                    Dịch vụ vận chuyển
                                                    <div className="service--block--body">
                                                        <div className="pb-5">
                                                            <ul className="mt-5">
                                                                <li>
                                                                    <Radio className="label--service"
                                                                           checked>Đóng
                                                                        gỗ
                                                                        chung</Radio>
                                                                </li>
                                                                <li>
                                                                    <Radio className="label--service">Đóng
                                                                        gỗ
                                                                        riêng</Radio>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="action-width-fixed pb-5">
                        <Button type="button" className="a-btn a-btn--primary a-btn--width-fixed">Lưu</Button>
                    </div>
                </Form>
                <Modal
                    visible={this.state.visible}
                    width={644}
                    closable={false}
                    className="a-modal a-modal--choose-template-bag"
                    footer={null}
                    centered={true}
                >
                    <div className="choose-template-bag--title">
                        <span className="title">Chọn mẫu bao</span>
                        <Search
                            placeholder="Nhập mẫu bao"
                            className='a-input--search-template-bag'
                        />
                    </div>
                    <div className="body">
                        <Table className={"a-table--antd"} columns={columns} dataSource={data}
                               pagination={false}
                        />
                    </div>
                    <div className="a-pagination a-pagination--modal-template-bag">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show">
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CreateBag;