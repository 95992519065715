import React, { Component } from 'react'
import * as _ from 'lodash'
import lodash from 'lodash'
import { ReactComponent as BagIcon } from '../../../../resources/images/bag.svg'
import { ReactComponent as BagIconList } from '../../../../resources/images/totalpackage.svg'
import { formatWeight } from '../../../../system/support/helpers'
import { Link } from '../../../../system/routing'
import { Icon, Modal } from 'antd'

class ItemBagDeliveryNoteExport extends Component {
    onClickIconList = (bag, e) => {
        this.props.onClickIconList(bag, e)
    }

    onRemove = item => {
        const { t } = this.props
        Modal.confirm({
            title: t('bag:label.warning'),
            content: <p>{t('bag:message.warning_confirm_delete_bag')}</p>,
            okText: t('bag:label.ok'),
            cancelText: t('bag:label.cancel'),
            onOk: this.confirmRemove.bind(this, item),
            icon: (
                <Icon
                    type="question-circle"
                    theme="filled"
                />
            ),
        })
    }

    confirmRemove = item => {
        const { delivery_note } = this.props
        this.props.removeBagFromDeliveryNote(delivery_note.id, [item.id])
    }

    render() {
        let { object, t, size, isShowAddress } = this.props
        const bag = _.get(object, 'bag', {})
        const bagSize = _.get(size, 'code')
        let { message } = object
        let tbodyClassName = []
        if (message.length > 0) {
            tbodyClassName.push('error')
        }
        return (
            <tbody className={tbodyClassName.join(' ')}>
                <tr className="data">
                    <td>
                        <BagIcon />{' '}
                        <Link
                            to="bags.detail"
                            params={{ id: bag.code }}>
                            {bag.code}
                        </Link>
                    </td>
                    <td>--</td>
                    <td>--</td>
                    <td>{isShowAddress ? lodash.get(object, 'order.customer_destination_address', '--') : '--'}</td>
                    <td>{formatWeight(bag.weight_real, 2)}</td>
                    <td>
                        <span>{t(`bag:column.size_${bagSize}`)}</span>
                    </td>

                    <td>
                        <div className="gflex gjustify-end">
                            <BagIconList
                                className="pointer"
                                onClick={this.onClickIconList.bind(this, bag)}
                            />

                            {message.length > 0 && (
                                <span className={'action-remove ml-5'}>
                                    <Icon
                                        type="close"
                                        onClick={this.onRemove.bind(this, bag)}
                                    />
                                </span>
                            )}
                        </div>
                    </td>
                </tr>

                {message.length > 0 ? (
                    <tr className="message">
                        <td colSpan="5">
                            <span className="a-text--black-45 gflex">
                                <Icon
                                    type="info-circle"
                                    theme="filled"
                                    className={'a-text--red galign-baseline mr-2 mt-1'}
                                />
                                <div className="a-flex-1">
                                    {message.map((mes, index) => {
                                        return (
                                            <span key={index}>
                                                {mes} <br />
                                            </span>
                                        )
                                    })}
                                </div>
                            </span>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        )
    }
}

export default ItemBagDeliveryNoteExport
