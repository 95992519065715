import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as lodash from 'lodash';
import {getShippingUserId, editShippingUser} from './actions';
import * as selectors from './selectors';
import FormShippingUserContainer from '../Form/index';
import {fetchShippingUser} from '../List/actions';

const mapStateToProps = (state) => ({
    shippingUsers: lodash.get(state, "shippingUser.list.users"),
    shippingUserDetail: selectors.getDetailShippingUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchShippingUser: () => dispatch(fetchShippingUser()),
    getShippingUserId: id => dispatch(getShippingUserId(id)),
    editShippingUser: data => dispatch(editShippingUser(data))
});

class EditShipingUserContainer extends React.Component {

    componentDidMount(){
        let {shippingUsers} = this.props;
        if (shippingUsers.length === 0){
            this.props.fetchShippingUser();
        }
    }

    render() {
        return <FormShippingUserContainer {...this.props}/>
    }
}

EditShipingUserContainer.defaultProps = {
    shippingUsers: [],
    shippingUserDetail: {}
};

EditShipingUserContainer.propTypes = {
    shippingUsers: PropTypes.array,
    shippingUserDetail: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditShipingUserContainer);
