import React from 'react';
import {get, has, includes} from 'lodash';
import {notification} from 'antd';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {failedCheckingPackage, successCheckingPackage, successUpdateReceiveQuantity} from './actions';
import api from './../apiService';
import {getSuggestsByVolume} from './../../BoxSize/actions';
import {getState} from './../../BoxSize/selectors';
import {setMenuActive} from './../../Common/actions';
import pageService from './../../Common/Page/pageService';
import {makeModelStateFromApiRes, updateCollectionItem} from './../../Common/services/helps';
import {STAGES} from './../../Notes/constants';
import {t} from './../../../system/i18n';
import {getVar} from './../../../system/support/helpers';

import SearchableComponent from './../../Common/components/SearchableComponent';
import CheckingPackage from './components/CheckingPackage';
import lodash from 'lodash';

const mapStateToProps = state => {
    return {
        boxSizesSuggestByVolume: getState(state, 'suggestsByVolume'),
        isUnLinkOrderSuccess: lodash.get(state, "package.unlinkOrder.isUnlinkOrderSuccess", false)
    }
};

const mapDispatchToProps = {
    failedCheckingPackage,
    getSuggestsByVolume,
    setMenuActive,
    successCheckingPackage,
    successUpdateReceiveQuantity
};

class CheckingPackageContainer extends SearchableComponent {
    state = {
        loading: false,
        loadingProducts: false,
        notes: [],
        notices: {},
        package: {},
        products: [],
        successUpdateReceivedQuantitiesCount: 0,
        totalAmount: 0,
        warnings: {},
        updatingProducts: true,
        packageItems: []
    };

    componentDidMount() {
        const {setMenuActive} = this.props;

        super.componentDidMount();
        setMenuActive('packages/checking');
        pageService.setTitle(t('package:checking.title'));
    }

    calculateTotalAmount = (products) => {
        const totalValue = products.reduce((amount, product) => amount + parseFloat(product.total_amount), 0);

        return totalValue;
    };

    fetchCheckingItems = async packageId => {
        const {t, failedCheckingPackage, successCheckingPackage} = this.props;

        if (!packageId) {
            return;
        }

        this.setState({
            loadingProducts: true,
            products: [],
            totalAmount: 0
        });

        try {
            const response = await api.getPackageProducts(packageId);

            if (has(response, 'data.code.order_not_found')) {
                return notification.error({
                    message: t('package:checking.message_order_not_found')
                });
            }

            let products = [];

            if (getVar(response, 'data.order_items')) {
                products = response.data.order_items.map(orderItem => {
                    const packageItem = response.data.package_items.find(packageItem => getVar(packageItem, 'package_item.code_item') === orderItem.code_item);

                    if (packageItem) {
                        return {
                            ...orderItem,
                            order_received_quantity: orderItem.received_quantity,
                            package_received_quantity: getVar(packageItem, 'package_item.received_quantity'),
                            package_order_quantity: getVar(packageItem, 'package_item.order_quantity'),
                            original_name: getVar(packageItem, 'package_item.name_zh'),
                            name_zh: getVar(packageItem, 'package_item.name_zh'),
                            name_vi: getVar(packageItem, 'package_item.name_vi'),
                            hscode: getVar(packageItem, 'package_item.hscode'),
                            logs: getVar(packageItem, 'item_logs', []),
                            chinese_unit: getVar(packageItem, 'package_item.chinese_unit'),
                            vietnamese_unit: getVar(packageItem, 'package_item.vietnamese_unit')
                        }
                    }

                    return {
                        ...orderItem,
                        order_received_quantity: orderItem.received_quantity,
                        package_received_quantity: '',
                        logs: [],
                        not_package_item: true
                    };
                });
            } else {
                products = getVar(response, 'data.package_items', []).map(packageItem => ({
                    ...packageItem.package_item,
                    order_received_quantity: 0,
                    original_name: getVar(packageItem, 'package_item.name_zh'),
                    name_zh: getVar(packageItem, 'package_item.name_zh'),
                    name_vi: getVar(packageItem, 'package_item.name_vi'),
                    hscode: getVar(packageItem, 'package_item.hscode'),
                    package_received_quantity: getVar(packageItem, 'package_item.received_quantity'),
                    package_order_quantity: getVar(packageItem, 'package_item.order_quantity'),
                    logs: getVar(packageItem, 'item_logs', []),
                    chinese_unit: getVar(packageItem, 'package_item.chinese_unit'),
                    vietnamese_unit: getVar(packageItem, 'package_item.vietnamese_unit'),
                }));
            }

            successCheckingPackage(getVar(response, 'data'));

            this.setState({
                products,
                totalAmount: this.calculateTotalAmount(products),
                notices: getVar(response, 'data.notices', {}),
                notes: getVar(response, 'data.notes', []),
                packageItems: getVar(response, 'data.package_items', []).map(packageItem => getVar(packageItem, 'package_item', {}))
            });
        } catch (e) {

            if (get(e, "response") !== undefined) {
                notification.error({
                    message: t('package:checking.message_cant_load_products')
                });
            }
            failedCheckingPackage();
        }

        this.setState({
            loadingProducts: false
        });
    };

    fetchPackage = async (code, clearState) => {
        const {t, failedCheckingPackage} = this.props;

        this.setState({
            loading: true
        });

        try {
            const response = await api.getPackage(code);

            this.setState({
                package: makeModelStateFromApiRes(response.data, 'package'),
                warnings: getVar(response.data, 'warnings')
            });

            if (clearState) {
                this.fetchCheckingItems(getVar(response, 'data.package.id'));
            }
        } catch (e) {
            if (get(e, "response") !== undefined) {
                const data = getVar(e, "response.data.data", {})
                const message = getVar(data, "barcode.message");
                if (message === "more_one_package") {
                    const packages = getVar(data, "barcode.packages", [])
                    notification.error({
                        message: t('scan:error.more_one_package', {packages: packages.join(", "), count: packages.length})
                    });
                } else {
                    notification.error({
                        message: t('package:checking.message_package_not_found')
                    });
                }
                
            }
            failedCheckingPackage();
        }

        this.setState({
            loading: false
        });
    };

    clearState() {
        this.setState({
            loading: false,
            package: {}
        });
    };

    onChangeFilter(filter, clearState = true) {
        if (filter.code) {
            if (clearState) {
                this.clearState();
            }
            this.fetchPackage(filter.code, clearState);
        }
    }

    onScan = filter => {
        this.pushFilter(filter);
    };

    getFilter() {
        return this.getFilterFromLocation(this.props.location);
    }

    handleReceiveAll = () => {
        this.refreshPackageData();
    };

    refreshPackageData = (clearState = false) => {
        this.onChangeFilter(this.getFilter(), clearState);
    };

    handleSuccessUpdateReceivedQuantities = payload => {
        const { successUpdateReceiveQuantity } = this.props;

        successUpdateReceiveQuantity(payload);

        this.setState(prevState => {
            const { products } = prevState;
            let newProducts = products;

            for (const item of payload.package_items) {
                newProducts = updateCollectionItem(newProducts, item.code_item, {
                    order_received_quantity: item.received_quantity_order,
                    package_received_quantity: item.received_quantity
                }, 'code_item');
            }

            return {
                products: newProducts,
                successUpdateReceivedQuantitiesCount: prevState.successUpdateReceivedQuantitiesCount + 1,
                packageItems: getVar(payload, 'package_items', [])
            };
        });
    };

    handleSuccessFinishCheckingPackage = data => {
        const {t} = this.props;

        this.refreshPackageData();

        if (getVar(data, 'warnings') && data.warnings.length) {
            notification.warning({
                message: t(`package:finish_checking.warnings.${data.warnings[0]}`)
            });
        }
    };

    handleSuccessUpdateDamagedProperty = data => {
        this.setState(prevState => ({
            package: {
                ...prevState.package,
                ...data
            }
        }));
    };

    handleSuccessUpdateProducts = () => {
        this.refreshPackageData(true);
    };

    handleSuccessUpdateNote = data => {
        this.setState(prevState => {
            const index = prevState.notes.findIndex(note => note.id === get(data, 'note.id'));

            if (index === -1) {
                return null;
            }

            const isRemoveNote = !includes(get(data, 'note.stages'), STAGES.CHECKING_PACKAGE);
            const newNotes = isRemoveNote ? [
                ...prevState.notes.slice(0, index),
                ...prevState.notes.slice(index + 1)
            ] : [
                ...prevState.notes.slice(0, index),
                {
                    ...prevState.notes[index],
                    ...get(data, 'note', {})
                },
                ...prevState.notes.slice(index + 1)
            ];

            return {
                notes: newNotes
            };
        });
    };

    render() {
        const {
            loading,
            loadingProducts,
            notes,
            notices,
            packageItems,
            products,
            successUpdateReceivedQuantitiesCount,
            totalAmount,
            warnings
        } = this.state;

        return (
            <CheckingPackage
                {...this.props}
                filter={this.getFilter()}
                loading={loading}
                notes={notes}
                notices={notices}
                packageItems={packageItems}
                package={this.state.package}
                loadingProducts={loadingProducts}
                products={products}
                successUpdateReceivedQuantitiesCount={successUpdateReceivedQuantitiesCount}
                totalAmount={totalAmount}
                fetchPackage={this.fetchPackage}
                warnings={warnings}
                onScan={this.onScan}
                onReceiveAll={this.handleReceiveAll}
                onSuccessFinishCheckingPackage={this.handleSuccessFinishCheckingPackage}
                onSuccessUpdateDamagedProperty={this.handleSuccessUpdateDamagedProperty}
                onSuccessUpdateNote={this.handleSuccessUpdateNote}
                onSuccessUpdateProducts={this.handleSuccessUpdateProducts}
                onSuccessUpdateReceivedQuantities={this.handleSuccessUpdateReceivedQuantities}
            />
        );
    }
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckingPackageContainer));
