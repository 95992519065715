import { get } from 'lodash';
import { createSelector } from 'reselect/lib/index';

export const getState = (state, param = null, defaultValue = null) => get(state, `shippingPartner.listShippingPartner${param ? `.${param}` : ''}`, defaultValue);

/**
 * Lấy danh sách bagShippingPartners đang active
 */
export const getListShippingPartnerActive = createSelector(
    (state, query) => getState(state, 'listSuggest', [])
        .filter(bagShippingPartner => query ? get(bagShippingPartner, 'shipping_partner.' + query) : bagShippingPartner),
    bagShippingPartners => bagShippingPartners.filter(bagShippingPartner => get(bagShippingPartner, 'shipping_partner.active', false))
);

/**
 * Lấy toàn bộ danh sách bagShippingPartners
 */
export const getListShippingPartner = createSelector(
    state => getState(state, 'list', []),
    shippingPartners => shippingPartners
);

export const getListShippingPartnerSuggest = createSelector(
    state => getState(state, 'listSuggest', []),
    shippingPartners => shippingPartners
);
