import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import authService from '../../Auth/authService';
import {MAX_WAREHOUSE} from '../constants';
import * as actions from '../actions';
import * as selectors from '../selectors';
import ChooseWarehouse from './components/ChooseWarehouse';

const mapStateToProps = (state, props) => ({
    warehouses: props.isPermission ? selectors.getListWarehouseActive(state) : selectors.getListWarehousePermissionActive(state),
    loading: !!selectors.getState(state, 'loading'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchWarehouses: (params) => dispatch(actions.fetchSuggestWarehouses(params)),
    fetchWarehouseIsPermissions: (params) => dispatch(actions.fetchSuggestWarehouseIsPermissions(params)),
});

class ChooseWarehouseContainer extends React.Component {
    constructor() {
        super();
        this.autoSelected = false;
    }

    componentDidMount() {
        const { active, isCustomWarehouse, isPermission, fetchWarehouseIsPermissions, fetchWarehouses } = this.props;

        const data = {
            active
        };

        if (isCustomWarehouse) {
            data.is_custom_warehouse = true;
        }

        if (isPermission) {
            fetchWarehouses({...data, warehouse_permission: true });
        } else {
            fetchWarehouseIsPermissions(data);
        }
    }

    componentDidUpdate() {
        const { autoSelect } = this.props;

        if (autoSelect) {
            this.processAutoSelect();
        }
    }

    /**
     * Xử lý tự động chọn kho lưu trong storage khi chưa chọn kho
     */
    processAutoSelect() {
        if (this.props.value || this.autoSelected) {
            return;
        }

        let warehouseId = this.getWarehouseIdInStorage();
        let warehouse = warehouseId ? lodash.find(this.props.warehouses, {id: warehouseId}) : null;

        if (warehouse) {
            this.autoSelected = true;
            if (this.props.autoSelected) {
                this.props.onChange(warehouse.id);
            }
        }
    }

    getWarehouseIdInStorage() {
        return parseInt(localStorage.getItem(this.getKeyLocalStorage()));
    }

    getKeyLocalStorage() {
        return this.props.keyLocalStorage || 'warehouse_' + authService.user('id');
    }

    onChange = value => {
        const { autoSelect, onChange } = this.props;

        if (autoSelect) {
            localStorage.setItem(this.getKeyLocalStorage(), value);
            this.autoSelected = false;
        }

        onChange(value);
    };

    render() {
        return (
            <ChooseWarehouse
                {...this.props}
                onChange={this.onChange}
            />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(ChooseWarehouseContainer);

Container.defaultProps = {
    autoSelect: true,
    keyLocalStorage: null,
    maxWarehouse: MAX_WAREHOUSE,
    disabled: false,
    onChange: () => {
    },
    autoSelected: true,
    isPermission: true,
    isCustomWarehouse: false
};

Container.propTypes = {
    keyLocalStorage: PropTypes.string,
    maxWarehouse: PropTypes.number,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    isPermission: PropTypes.bool,
    onChange: PropTypes.func,
    isCustomWarehouse: PropTypes.bool
};

export default Container;

