import React, {Component} from 'react';
import {Modal, Form, Select, Input} from "antd";
import lodash from 'lodash';
import {DEFAULT_DELETE_REASON, DELETE_REASONS} from "../constants";
import {t} from '../../../../system/i18n';

class PopupDeletePackage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            reason: DEFAULT_DELETE_REASON,
            reasonDetail: ""
        }
    }

    handleChangeSelect = (field, value) => {
        this.setState({[field]: value})
    };

    handleChangeInput = (field, e) => {
        this.setState({[field]: e.target.value});
    };

    handleOk = () => {
        const data = {
            delete_reason: this.state.reason,
            delete_reason_detail: this.state.reasonDetail
        };
        this.props.onOk(data);
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    render() {
        const {reason, reasonDetail} = this.state;
        const {loading} = this.props;
        const isShowInputReasonDetail = reason === DELETE_REASONS.OTHER.key;
        let disableSubmit = false;
        if (reason === DELETE_REASONS.OTHER.key && !reasonDetail) {
            disableSubmit = true;
        }
        return (
            <Modal
                title={t('package:label.delete_package')}
                visible={this.state.visible}
                className="a-modal a-modal--default _modal_delete_package"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText={t('package:label.delete_package')}
                cancelText={t('package:label.cancel')}
                okButtonProps={{
                    disabled: disableSubmit,
                    loading: loading,
                    className: "_btn_ok"
                }}
                cancelButtonProps={{
                    loading: loading,
                    className: "_btn_cancel"
                }}
            >
                <Form>
                    <Form.Item
                        label={t('package:label.select_reason_delete_package')}
                    >
                        <Select
                            className={"_select_reason"}
                            value={reason}
                            onChange={this.handleChangeSelect.bind(this, 'reason')}
                        >
                            {lodash.map(DELETE_REASONS, (item => (
                                <Select.Option
                                    className={`_option_reason _option_reason_${item.key}`}
                                    value={item.key}
                                >{item.label}</Select.Option>
                            )))}
                        </Select>
                    </Form.Item>

                    {isShowInputReasonDetail && (
                        <Form.Item
                            label={t('package:label.input_reason_delete_package')}
                            required={true}
                        >
                            <Input.TextArea
                                className={"_input_reason_detail"}
                                rows={3}
                                value={reasonDetail}
                                onChange={this.handleChangeInput.bind(this, 'reasonDetail')}
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        );
    }
}

export default PopupDeletePackage;