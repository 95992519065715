import {takeLatest} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import * as saga from './saga';
import * as ACTION from './constants';

export default function*() {
    yield takeLatest(ACTION.FETCH_BAG_SHIPPING_PARTNER.REQUEST, saga.getBagShippingPartners);
    yield takeLatest(ACTION.FETCH_DETAIL_SHIPPING_PARTNER.REQUEST, saga.getDetailShippingPartners);
    yield takeLatest(ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.REQUEST, saga.getShippingPartnersSuggest);
    yield takeLatest(ACTION.GET_INTERNATIONALS.REQUEST, saga.getInternationals);
    yield takeLatest(ACTION.GET_DOMESTICS.REQUEST, saga.getDomestics);
    yield takeLatest(ACTION.GET_DOMESTIC_SUGGESTS.REQUEST, saga.getDomesticSuggests);
    yield takeLatest(ACTION.UPDATE_DOMESTIC.REQUEST, saga.updateDomestic);
    yield takeLatest(ACTION.GET_LASTMILE_CARRIER_SUGGEST.REQUEST, saga.getLastmileCarrierSuggest);
}
