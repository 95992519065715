import React from 'react';
import Background from "../../../../resources/images/Notrequest.png";
class EmptyListDeliveryRequest extends React.Component{
    render() {
        return (
            <div className="a-content--empty scanpackage a-bgwhite height-100 mt-2 request-delivery-note">
                <img src={Background} alt="" />
                <span >hãy chọn kho để xem <a href="#top" className="a-text--black-45">Danh sách yêu cầu giao</a></span>
            </div>
        );
    }
}
export default EmptyListDeliveryRequest;