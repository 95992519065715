import { groupBy, map } from 'lodash'
import React from 'react'
import PackageItemOnlyGroup from './PackageList/PackageItemOnlyGroup'

import ProductItemGroup from './ProductList/ProductItemGroup'

const ProductListGroup = ({
    boxSizes,
    canEdit,
    form,
    isOriginPackage,
    loadingBoxSizes,
    originPackage,
    packageIndex,
    products,
    originProduct,
    onChange,
    groupByProduct,
    detachSuggestItems,
    handleUpdateProductGroup,onEnter
}) => {
    const groupProduct = groupBy(products, 'original_name')
    const groupProductDetachSuggestItems = groupBy(detachSuggestItems, 'name_zh')

    return (
        <ul className="product-list">
            {groupByProduct ? (
                <>
                    {map(Object.entries(groupProduct), ([key, value], index) => {

                        return (
                            <PackageItemOnlyGroup
                                products={value}
                                boxSizes={boxSizes}
                                canEdit={canEdit}
                                form={form}
                                isOriginPackage={isOriginPackage}
                                loadingBoxSizes={loadingBoxSizes}
                                originPackage={originPackage}
                                packageIndex={packageIndex}
                                onChange={handleUpdateProductGroup.bind(undefined,  key)}
                                originProduct={originProduct}
                                detachSuggestItems={detachSuggestItems}
                                key={key}
                                keyZh={key}
                                index={index}
                                groupProductDetachSuggestItems={groupProductDetachSuggestItems}
                                onEnter={onEnter}
                            />
                        )
                    })}
                </>
            ) : (
                <>
                    {products.map((product, index) => (
                        <ProductItemGroup
                            boxSizes={boxSizes}
                            canEdit={canEdit}
                            form={form}
                            index={index}
                            isOriginPackage={isOriginPackage}
                            key={product.id}
                            loadingBoxSizes={loadingBoxSizes}
                            originPackage={originPackage}
                            packageIndex={packageIndex}
                            product={product}
                            onChange={onChange.bind(undefined, index)}
                        />
                    ))}
                </>
            )}
        </ul>
    )
}

export default ProductListGroup
