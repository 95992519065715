import { Form, Input } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import CompletePackingBag from './CompletePackingBag'
import * as actions from '../actions'
import { connect } from 'react-redux'
import UpdateValue from './UpdateValue'

const mapStateToProps = (state, props) => {
    return {
        loadingScan: get(state, 'bag.bagPackingLastmileAction.loading', false),
    }
}

const mapDispatchToProps = dispatch => ({
    packingBagLastmile: data => dispatch(actions.packingPackageLasmile(data)),
})

const BagPackingAction = ({ form, loading, bag, packages, idBag, packingBagLastmile, loadingScan, setBag, fetchDetailBag }) => {
    const [inputVal, setInputVal] = useState('')

    const onScanPackage = value => {
        setInputVal('')
        packingBagLastmile({ code_package: value, idBag })
    }

    return (
        <div className="bag-pack--block">
            <Form className="a-form a-form--sider-in-bag-pack">
                <Form.Item
                    className="a-form__item item--bag-pack label-special "
                    label={t('bag:label.scan_package')}
                    labelCol={{ span: 24 }}>
                    <Input
                        name={'code_package'}
                        className="a-input a-select--auto-complete--bag-pack _input_package_code"
                        disabled={loading || loadingScan }
                        loading={loadingScan}
                        value={inputVal}
                        onPressEnter={e => onScanPackage(e.target.value)}
                        onChange={e => setInputVal(e.target.value)}
                    />
                </Form.Item>

                <UpdateValue
                    loading={loading}
                    disabled={get(bag, 'packing_complete', false)}
                    form={form}
                    bag={bag}
                    setBag={setBag}
                    keyName="length"
                    keyMax="length_max"
                />
                <UpdateValue
                    loading={loading}
                    disabled={get(bag, 'packing_complete', false)}
                    form={form}
                    bag={bag}
                    setBag={setBag}
                    keyName="width"
                    keyMax="width_max"
                />
                <UpdateValue
                    loading={loading}
                    disabled={get(bag, 'packing_complete', false)}
                    form={form}
                    bag={bag}
                    setBag={setBag}
                    keyName="height"
                    keyMax="height_max"
                />
                <UpdateValue
                    loading={loading}
                    disabled={get(bag, 'packing_complete', false)}
                    form={form}
                    bag={bag}
                    setBag={setBag}
                    keyName="weight_real"
                    keyMax="weight_max"
                    notCore
                />

                <CompletePackingBag
                    loading={loading}
                    disabled={isEmpty(packages) || get(bag, 'packing_complete', false)}
                    idBag={idBag}
                    form={form}
                    fetchDetailBag={fetchDetailBag}
                    bag={bag}
                />
            </Form>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(BagPackingAction))
