import React from 'react';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { connect } from 'react-redux';

import * as actions from './actions';
import * as selectors from './selectors';
import { getInputFocus } from './../CreatePackageViaScan/selectors';
import { inputFocusHandler } from './../CreatePackageViaScan/services';
import packageService from './../packageService';
import * as updatePackageActions from './../UpdateAlonePackage/actions';
import * as boxSizeActions from './../../BoxSize/actions';
import * as boxSizeSelectors from './../../BoxSize/selectors';
import * as serviceSelectors from './../../Service/selectors';
import { getSetting } from './../../Setting/selectors';

import UpdatePackageParams from './components';

const makeFormInput = packageData => {
    const input = pick(packageData, [
        'weight_net',
        'length',
        'width',
        'height',
        'volume',
        'id_box_size',
        'weight_box',
        'volume_box',
        'note'
    ]);

    if (input.id_box_size === 0) {
        delete input.id_box_size
    }

    if (input.width === 0 && input.height === 0 && input.length === 0) {
        input.volume = null
    }

    return packageService.makeFormInput(input);
};

const mapStateToProps = (state, props) => {
    const packageState = selectors.getPackageState(state, props.package.id) || {};
    const setting = getSetting(state);
    const input = makeFormInput(props.package);
    const boxSizes = boxSizeSelectors.getSuggestBoxSizes(state, 'box_sizes', []);
    const boxSizesSuggestByVolume = boxSizeSelectors.getState(state, 'suggestsByVolume', []);

    return {
        ...getInputFocus(state),
        onSubmit: props.onSubmit,
        isWapper: get(props, 'isWapper', false),
        package: props.package,
        input,
        inputVersion: props.package.id,
        errors: {
            ...selectors.getErrors(packageState.error || {}),
            ...(props.errors ? props.errors : {})
        },
        loading: !!packageState.loading || get(props, "loading", false),
        setting: selectors.getDimensionSetting(setting),
        boxSizes,
        boxSizesSuggestByVolume,
        loadingBoxSizes: boxSizeSelectors.getLoading(state),
        serviceGroups: serviceSelectors.getActiveServiceGroups(state)
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const packageId = props.package.id;

    return {
        onPressEnter: (param) => inputFocusHandler.onEnterPackageInput(props.package, param),
        onUpdatePackageParams: (data, cb) => dispatch(actions.updatePackage(packageId, data, cb)),
        onReset: () => dispatch(actions.clearState(packageId)),
        resetError: (params) => {
            dispatch(actions.resetError(packageId, params));

            if (props.resetError) {
                props.resetError(params);
            }
        },
        fetchSuggestBoxSizes: params => dispatch(boxSizeActions.fetchSuggestBoxSizes({
            ...params,
            only_active: true
        })),
        getSuggestsByVolume: params => dispatch(boxSizeActions.getSuggestsByVolume(params)),
        clearSuggestBoxSizes: () => dispatch(boxSizeActions.clearSuggestBoxSizes()),
        clearSuggestByVolumeBoxSizes: () => dispatch(boxSizeActions.clearSuggestByVolumeBoxSizes()),
        resetErrorUpdatePackage: (params) => dispatch(updatePackageActions.resetError(packageId, params))
    };
};

class UpdatePackageParamContainer extends React.Component {
    render() {
        return (
            <UpdatePackageParams {...this.props} />
        );
    }
}

UpdatePackageParamContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePackageParamContainer);

UpdatePackageParamContainer.defaultProps = {
    package: {},
    isWapper: false,
    isNeedSubmit: false,
    services: []
};

UpdatePackageParamContainer.propTypes = {
    package: PropTypes.object,
    isWapper: PropTypes.bool,
    isNeedSubmit: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default UpdatePackageParamContainer;

