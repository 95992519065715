import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import FormProcessing from './../../../Common/components/FormProcessing';
import InputNumber from './../../../Common/components/InputNumber';

class UpdateReceivedQuantity extends FormProcessing {
    state = {
        input: {
            quantity: '',
        },
        inputVersion: 0,
        changedParams: [],
    };

    onSubmit = (event) => {
        event.preventDefault();

        if (!this.props.loading && this.state.input.quantity) {
            this.props.onSubmit(this.state.input);
        }
    };

    receiveAll = () => {
        const { input, loading, onSubmit } = this.props;
        const { purchasedQuantity } = input;

        if (!loading && purchasedQuantity) {
            const input = this.changeInput({
                quantity: purchasedQuantity
            });

            onSubmit(input);
        }
    };

    componentDidUpdate(prevProps) {
        const { product, reupdateQuantity } = this.props;

        if (Number(product.package_received_quantity) !== Number(prevProps.product.package_received_quantity) || reupdateQuantity !== prevProps.reupdateQuantity) {
            this.changeInput({
                quantity: product.package_received_quantity
            });
        }
    }

    render() {
        const {t, loading, inputRef} = this.props;

        return (
            <form onSubmit={this.onSubmit} className="a-item-package__recived-package mt-2">
                <InputNumber
                    value={this.state.input.quantity}
                    disabled={!!loading}
                    precision={0}
                    min={0}
                    onChange={quantity => this.changeInput({quantity})}
                    className="a-input aradius--2 aflex-1 item-package__recived-package-quantity-input"
                    ref={inputRef}
                />
                <Button
                    disabled={!!loading}
                    loading={!!loading}
                    onClick={this.receiveAll}
                    type="link"
                    className=" ml-2 aradius--2 a-btn--w-70 phz-0 a-text--link item-package__recived-package-get_all-btn"
                >{loading ? null : t('package:btn.get_all')}</Button>
            </form>
        );
    }
}

UpdateReceivedQuantity.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSubmit: () => {},
};

UpdateReceivedQuantity.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default UpdateReceivedQuantity;
