import React, { useEffect, useRef, useState } from 'react'
import { setMenuActive } from '../../Common/actions'
import { connect } from 'react-redux'
import pageService from '../../Common/Page/pageService'
import { Input, Layout, Button, Table, notification, Modal, Empty, Row, Col } from 'antd'
import { t } from '../../../system/i18n'
import Sound from '../../Sound'
import apiService from '../apiService'
import { dispatch } from '../../../system/store'
import { success, error as errorSound } from '../../Sound/actions'
import { find, isEmpty, map, sumBy } from 'lodash'
import { getVar } from '../../../system/support/helpers'
import { LOCAL_STORAGE_TO_STOWING_KEY, WARNING_TO_STOWING_KEY } from '../constants'
import authService from '../../Auth/authService'

const { Content } = Layout

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(setMenuActive(menu)),
})

const { confirm } = Modal
const ScanToStowing = props => {
    const keyLocalStorage = LOCAL_STORAGE_TO_STOWING_KEY + '_' + authService.user('id')
    const localStowingData = JSON.parse(localStorage.getItem(keyLocalStorage))
    const { setMenuActive } = props
    const inputScanRef = useRef()

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState()
    const [warehouseAreaInfo, setWarehouseAreaInfo] = useState({})

    const pagination = {
        pageSizeOptions: [20, 50, 100, 200],
        locale: { items_per_page: '' },
        defaultPageSize: 20,
        defaultCurrent: 1,
        showSizeChanger: true,
    }

    useEffect(() => {
        pageService.setTitle(t('menu.scan-to-stowing'), <Sound />)
        setMenuActive('warehouse/scan-to-stowing')
        inputScanRef.current.focus()
        setWarehouseAreaInfo(getVar(localStowingData, 'warehouseAreaInfo', {}))
        setPackages(getVar(localStowingData, 'packages', []))
    }, [])

    const scanToStowing = value => {
        value = value.trim()
        const warehouseAreaCode = getVar(warehouseAreaInfo, 'code')
        if (warehouseAreaCode && packages.length > 0 && warehouseAreaCode === value) {
            return handleConfirmToStowing()
        } else {
            setLoading(true)
            apiService
                .scanToStowing({ mixed_code: value, id_warehouse: getVar(warehouseAreaInfo, 'id_warehouse') })
                .then(res => {
                    const data = getVar(res, 'data', {})
                    refactorData(data)
                })
                .catch(err => {
                    const { response } = err
                    const code = getVar(response, 'data.code')
                    if (code === 'INPUT_INVALID') {
                        notification.error({ message: t('package:message.scan_to_stowing.input_error') })
                    }
                    dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
                })
                .finally(() => {
                    setLoading(false)
                    setValue()
                })
        }
    }

    const refactorData = data => {
        const warehouseArea = getVar(data, 'warehouse_shelf', {})
        const packageInfo = getVar(data, 'package', {})
        if (!isEmpty(warehouseArea)) {
            if (packages.length > 0 && warehouseAreaInfo) {
                notification.error({ message: t('package:message.scan_to_stowing.not_exist') })
                dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
            } else {
                dispatch(success())
                setWarehouseAreaInfo(warehouseArea)
                localStorage.setItem(keyLocalStorage, JSON.stringify({ warehouseAreaInfo: warehouseArea }))
            }
        } else {
            const warnings = []
            const existsPackage = find(packages, ['id', getVar(packageInfo, 'id')])
            if (isEmpty(existsPackage)) {
                const newPackages = [...packages]
                dispatch(success())
                const maxWeight = getVar(warehouseAreaInfo, 'remain_weight', '')
                const maxCapacity = getVar(warehouseAreaInfo, 'remain_capacity', '')
                const totalWeightOfPackages = sumBy([...packages, packageInfo], 'weight_net')
                if (maxWeight !== '' && totalWeightOfPackages > maxWeight) {
                    warnings.push(t(`package:warning_to_stowing.${WARNING_TO_STOWING_KEY.OVER_MAX_WEIGHT}`))
                }
                if (maxCapacity !== '' && [...packages, packageInfo].length > maxCapacity) {
                    warnings.push(t(`package:warning_to_stowing.${WARNING_TO_STOWING_KEY.OVER_MAX_CAPACITY}`))
                }
                const refactorPackage = { ...packageInfo, warnings }
                newPackages.unshift(refactorPackage)
                setPackages(newPackages)
                localStorage.setItem(keyLocalStorage, JSON.stringify({ warehouseAreaInfo, packages: newPackages }))
            } else {
                notification.error({ message: t('package:message.scan_to_stowing.already_exist') })
                dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
            }
        }
    }

    const handleConfirmToStowing = () => {
        const warehouseId = getVar(warehouseAreaInfo, 'id_warehouse')
        const warehouseAreaId = getVar(warehouseAreaInfo, 'id')
        const packageIds = map(packages, 'id')
        apiService
            .confirmStowing(warehouseId, warehouseAreaId, { package_ids: packageIds })
            .then(() => {
                inputScanRef.current.focus()
                dispatch(success())
                resetInitValue()
                notification.success({ message: t('package:message.scan_to_stowing.confirm_success') })
            })
            .catch(() => {
                notification.error({ message: t('package:message.scan_to_stowing.confirm_failed') })
                dispatch(errorSound({ type: 'SCAN_BARCODE_FORM.FAILED', message: '' }))
            })
    }

    const handleProcessScan = e => {
        const { value } = e.target
        setValue(value)
        scanToStowing(value)
    }

    const handleByPass = () => {
        confirm({
            title: t('package:message.scan_to_stowing.by_pass'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => resetInitValue(),
        })
    }

    const resetInitValue = () => {
        setPackages([])
        setValue()
        setWarehouseAreaInfo({})
        localStorage.removeItem(keyLocalStorage)
    }

    const columns = [
        {
            title: t('package:label.code'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('package:label.weight'),
            dataIndex: 'weight_net',
            key: 'weight_net',
        },
        {
            title: t('customer'),
            dataIndex: 'customer',
            key: 'customer',
            render: (text) => {
                return `${getVar(text, 'username', '--')} (${getVar(text, 'code', '--')})`
            }
        },
        {
            title: t('package:label.previous_warehouse_area'),
            dataIndex: ['warehouse_shelf', 'code'] ,
            key: 'previous_warehouse_area',
        },
        {
            title: t('label.note'),
            dataIndex: 'warnings',
            key: 'warnings',
            render: text => {
                return text.join(', ')
            },
        },
    ]

    return (
        <Layout>
            <Content className="a-content a-content--page-list">
                <div
                    className="a-content__body bg-white a-content__body--page-list"
                    style={{ marginBottom: '1.4rem' }}>
                    <div className="gflex gflex-direction-column galign-center">
                        <div className={`gflex ${packages.length > 0 ? 'gjustify-space-between' : 'gjustify-center'} width-100-pc mb-4 p-4`}>
                            {packages.length > 0 && <Button onClick={handleByPass}>{t('btn.by_pass')}</Button>}
                            <h2>{t('package:title.to_stowing')}</h2>
                            {packages.length > 0 && (
                                <Button
                                    type="primary"
                                    onClick={handleConfirmToStowing}>
                                    {t('btn.confirm')}
                                </Button>
                            )}
                        </div>
                        <div className="mt-4 width-50-pc">
                            <Input
                                value={value}
                                placeholder={t('package:placeholder.code_scan')}
                                onChange={e => setValue(e.target.value)}
                                ref={inputScanRef}
                                onPressEnter={handleProcessScan}
                            />
                        </div>
                    </div>
                    <div className={'amt-24'}>
                        {!isEmpty(warehouseAreaInfo) && (
                            <div className="amt-16 warehouse-area-info">
                                <h3>{t('package:title.warehouse_area_info')}: </h3>
                                <div className="aml-16">
                                    <label className="info--label">{t('warehouse:label.shelf_code')}: </label>
                                    <span className="info--value ">{getVar(warehouseAreaInfo, 'code')}</span>
                                </div>
                                <div className="aml-16">
                                    <label className="info--label">{t('warehouse:label.max_weight')}: </label>
                                    <span className="info--value ">{getVar(warehouseAreaInfo, 'max_weight')}</span>
                                </div>
                                <div className="aml-16">
                                    <label className="info--label">{t('warehouse:label.max_capacity')}: </label>
                                    <span className="info--value ">{getVar(warehouseAreaInfo, 'max_capacity')}</span>
                                </div>
                                <div className="aml-16">
                                    <label className="info--label">{t('warehouse:label.remain_weight')}: </label>
                                    <span className="info--value ">{getVar(warehouseAreaInfo, 'remain_weight')}</span>
                                </div>
                                <div className="aml-16">
                                    <label className="info--label">{t('warehouse:label.remain_capacity')}: </label>
                                    <span className="info--value ">{getVar(warehouseAreaInfo, 'remain_capacity')}</span>
                                </div>
                            </div>
                        )}

                        <div className="amt-16">
                            <h3 className="a-text--upppercase ">{t('package:title.package_list_scan')}</h3>
                            <div className="a-table--responsive amt-16">
                                <Table
                                    rowKey={record => record.id}
                                    className={'a-table--antd a-table--antd-scroll-x _a-table-temple-bag'}
                                    columns={columns}
                                    dataSource={packages}
                                    loading={loading}
                                    scroll={{ x: 'fit-content' }}
                                    pagination={pagination}
                                    locale={{
                                        emptyText: (
                                            <Empty
                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                description={<span>{t('common:label.no_data')}</span>}></Empty>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanToStowing)
