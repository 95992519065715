import React from 'react';
import { Tag } from 'antd';
import { withTranslation } from 'react-i18next';

const StageCard = ({ className, stage, t }) => {
    return (
        <Tag className={className}>
            {t(`note:stages.${stage}`)}    
        </Tag>
    );
};

export default withTranslation()(StageCard);
