/* eslint-disable */
import React, { Component } from 'react'
import { Icon, Input } from 'antd'
import { Form as AntForm } from 'antd'
import { withTranslation } from 'react-i18next'

const { TextArea } = Input

class EditNote extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowInputEdit: false,
        }
    }

    onClickEditInline = status => {
        this.setState({
            isShowInputEdit: status,
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateDocument(this.props.id, values)
                this.onClickEditInline(false)
            }
        })
    }

    render() {
        const { value, permissions, t, loadingUpdate } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <AntForm>
                <div className="a-inline-edit">
                    <label className={`a-inline-edit--label info--label`}>
                        {t('label.note')}
                        {!this.state.isShowInputEdit && (
                            <>
                                <>
                                    {loadingUpdate && (
                                        <Icon
                                            type="loading-3-quarters"
                                            className="icon-loading icon-inline"
                                        />
                                    )}

                                    {!loadingUpdate && (
                                        <Icon
                                            type="edit"
                                            onClick={() => {
                                                this.onClickEditInline(true)
                                            }}
                                            className={`icon-inline`}
                                        />
                                    )}
                                </>
                                <div className={`a-inline-edit--value`}>{value ? value : '--'}</div>
                            </>
                        )}

                        {this.state.isShowInputEdit && (
                            <>
                                <Icon
                                    type="close"
                                    onClick={() => {
                                        this.onClickEditInline(false)
                                    }}
                                    className="icon-inline"
                                />
                                <Icon
                                    type="check"
                                    onClick={this.handleSubmit}
                                    className="icon-inline ml-0"
                                />
                                <div className={`a-inline-edit--input`}>
                                    <AntForm.Item className="note _note">
                                        {getFieldDecorator('note', {
                                            rules: [{ max: 255, message: t('delivery_note:delivery.length_required_maximum_255') }],
                                            initialValue: value,
                                        })(
                                            <TextArea
                                                onKeyDown={e => {
                                                    if (e.keyCode === 13) {
                                                        this.handleSubmit(e)
                                                    }
                                                }}
                                                autoFocus
                                            />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </>
                        )}
                    </label>
                </div>
            </AntForm>
        )
    }
}

EditNote = AntForm.create()(EditNote)
export default withTranslation()(EditNote)
