import React, { PureComponent } from 'react';
import { Button, Icon, Modal, notification } from 'antd';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';

import EditNoteModal from './../../components/EditNoteModal';
class ActionAttention extends PureComponent {
    state = {
        visible: false
    };

    showEditNoteModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    handleOk = data => {
        const { successUpdateNote } = this.props;

        this.setState({
            visible: false
        });

        successUpdateNote(data);
    };

    handleDeleteButtonClick = () => {
        const { t } = this.props;

        Modal.confirm({
            cancelText: t('btn.cancel'),
            okText: t('btn.ok'),
            title: t('note:delete_note.confirm'),
            onOk: this.deleteNote
        });
    };

    deleteNote = async () => {
        const { note, t, successDelete } = this.props;

        try {
            await apiService.delete(note.id);

            notification.success({
                message: t('note:delete_note.success')
            });

            if (isFunction(successDelete)) {
                successDelete();
            }
        } catch (error) {
            notification.error({
                message: t('note:delete_note.failed')
            });
        }
    };

    render() {
        const { className, creator, id, note, object } = this.props;
        const { visible } = this.state;

        return (
            <div className={clsx(className, 'dpl-inline-block')}>
                <Button
                    onClick={this.showEditNoteModal}
                    className="a-btn--button-link a-text--blue a-btn--bg-transparent"
                >
                    <Icon type="edit" />
                </Button>

                <span className="a-text--gray mx-2">|</span>

                <Button
                    onClick={this.handleDeleteButtonClick}
                    className="a-btn--button-link a-text--blue a-btn--bg-transparent"
                >
                    <Icon type="delete" />
                </Button>

                <EditNoteModal
                    creator={creator}
                    id={id}
                    note={note}
                    object={object}
                    visible={visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                />
            </div> 
        );
    }
}

export default withTranslation()(ActionAttention)
