import { pick } from 'lodash';

import { api } from './../../../system/api';

export default {
    getBagShippingPartners: params => api.get('/shipping-partners', {
        params
    }),
    getDetailShippingPartner: partnerId => api.get(`/shipping-partners/${partnerId}`),
    getShippingPartnersSuggest: params => api.get('/shipping-partners/suggest', {
        params
    }),
    getInternationals: params => api.get('/shipping-partners', {
        params
    }),
    getDomestics: params => api.get('/shipping-domestic-partners', {
        params
    }),
    getDomesticSuggests: params => api.get('/shipping-domestic-partners/suggest', {
        params
    }),
    updateDomestic: data => api.put(`shipping-domestic-partners/${data.id}`, pick(data, [
        'access_token',
        'active'
    ])),
    getLastmileCarrierSuggest: params => api.get(`last-mile-carriers/suggest`, {params}),
    getListLastmileCarrier: params => api.get(`/last-mile-carriers`, {params}),
    detailLastmileCarrier: (id) => api.get(`last-mile-carriers/${id}`),
    updateLastmileCarrier: (id, data) => api.put(`last-mile-carriers/${id}`, data),
    addLastmileCarrier: (data) => api.post(`last-mile-carriers`, data)
};
