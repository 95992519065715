import * as lodash from 'lodash';
import {all, put} from 'redux-saga/effects';

import * as actions from './actions';
import * as ACTION from './constants';
import * as ACTION_BAG_PACKING from './../Bag/BagPackingAction/constants';
import * as ACTION_BAG_PACKING_LASTMILE from './../Bag/BagPackingLastmile/constants';
import { DELIVERY_OBJECT } from './../DeliveryNote/constants';
import * as ACTION_DELIVERY_NOTE from './../DeliveryNote/DeliveryNoteBarcode/constants';
import * as ACTION_INVENTORY_BY_SCAN from './../Inventory/InventoryBarcode/constants';
import * as ACTION_PACKAGE_BY_SCAN from './../Package/constants';
import {CHECKING_PACKAGE} from './../Package/CheckingPackage/constants';
import * as SCAN_ACTION from './../ScanBarcode/ScanBarcodeForm/constants';
import {dispatch, getState} from './../../system/store';
import i18n from './../../system/i18n/i18n';
import { flatten, get, map } from 'lodash';

export function* success_sound() {
    let soundStatus = getState("sound.status");
    if (soundStatus) {
        yield put({
            type: ACTION.PLAY_SOUND,
            meta: {
                sound: {
                    play: ACTION.SOUND_CONSTANT.SUCCESS
                }
            }
        })
    }
}

export function* error_sound(action) {
    let soundStatus = getState("sound.status");
    if (soundStatus && ACTION.LIST_ERROR_SOUND.includes(action.payload.type)) {
        yield put({
            type: ACTION.PLAY_SOUND,
            meta: {
                sound: {
                    play: action.payload.message.toUpperCase() === ACTION.SOUND_CONSTANT.NETWORK_ERROR ? ACTION.SOUND_CONSTANT.NETWORK_ERROR : ACTION.SOUND_CONSTANT.ERROR
                }
            }
        })
    }
}

export function* warning_sound(action) {
    let soundStatus = getState("sound.status");
    if (soundStatus) {
        yield put({
            type: ACTION.PLAY_SOUND,
            meta: {
                sound: {
                    play: action.payload
                }
            }
        })
    }

}

export function* normalSound(action) {
    const soundStatus = getState('sound.status');
    const language = i18n.language;

    if (soundStatus) {
        yield put({
            type: ACTION.PLAY_SOUND,
            meta: {
                sound: {
                    play: `${action.payload}_${language.toUpperCase()}`
                }
            }
        });
    }
}


export function* play_sound(action) {
    let code_error = [];
    let attention = {};
    let notices = [];
    let time = 0;
    let timeStep = 1500;

    switch (action.type) {
        // nhận âm thanh khi quét vào phiếu xuất
        case ACTION_DELIVERY_NOTE.SCAN_BARCODE.SUCCESS :
            lodash.each(getState('deliveryNote.deliveryNoteBarcode.barcodes'), (barcode => {
                if (action.request.code === barcode.code && barcode.warning && barcode.warning.length > 0) {
                    code_error = barcode.warning
                }
            }));
            if (!lodash.isNil(lodash.get(action, 'payload.attentions')) && lodash.get(action, 'payload.attentions', []).length > 0) {
                attention = {code: ACTION.SOUND_CONSTANT.WARNING}
            }

            if (lodash.get(action.request, 'params.type') !== DELIVERY_OBJECT.RETURN && !lodash.get(action.payload, 'customer.id')) {
                code_error = [{
                    code: ACTION_DELIVERY_NOTE.SCAN_BARCODE.FAILED
                }];
            }
            break;
        case SCAN_ACTION.SCAN_BARCODE_FORM.FAILED:
        case ACTION_DELIVERY_NOTE.SCAN_BARCODE.FAILED:
        case SCAN_ACTION.TRANSPORT_WAREHOUSE.FAILED:
        case ACTION_PACKAGE_BY_SCAN.CREATE_PACKAGE_BY_SCAN.FAILED:
        case ACTION_INVENTORY_BY_SCAN.SCAN_PACKAGE.FAILED:
        case ACTION_INVENTORY_BY_SCAN.SCAN_TRACKING_BILL.FAILED:
        case ACTION_BAG_PACKING.PACKING_PACKAGE.FAILED:
        case ACTION_BAG_PACKING_LASTMILE.PACKING_PACKAGE_LASTMILE.FAILED:
        case CHECKING_PACKAGE.FAILED:
            code_error = [{code: action.type, message: action.message}];
            break;
        default :
            code_error = [];
            break;
    }
    let soundStatus = getState("sound.status");
    let stages = lodash.map(lodash.get(action, 'payload.attention_stages', []), stage => lodash.get(stage, 'attention_stage.stage'));
    let current_stage = lodash.get(action, 'request.stage');
    if (!stages.includes(current_stage)) {
        attention = {}
    }

    if (soundStatus) {
        if (code_error.length > 0) {
            yield all(lodash.map(code_error, err => {
                if (lodash.isObject(err) && ACTION.LIST_ERROR_SOUND.includes(err.code.toUpperCase())) {
                    setTimeout(() => dispatch(actions.error({
                        type: err.code.toUpperCase(),
                        message: lodash.get(err, 'message', '')
                    })), time);

                    time += timeStep;
                }
            }));
        } else {
            yield success_sound();

            time += timeStep / 3;

            if (Object.keys(attention).length > 0) {
                yield setTimeout(() => dispatch(actions.warning(attention.code.toUpperCase())), timeStep);

                time += timeStep;
            }
        }
    }

    // handle notices
    switch (action.type) {
        case ACTION_PACKAGE_BY_SCAN.CREATE_PACKAGE_BY_SCAN.SUCCESS: {
            notices = lodash.get(action.payload, 'packages[0].notices.sound', []);

            break;
        }
        default: {
            notices = lodash.get(action.payload, 'notices.sound', []);

            break;
        }
    }

    if(get(action, 'request.packing_barcode_type') === 'bag_last_mile_freight_bill'){
        notices = flatten(map(get(action,"payload.bagPackings", []), 'notices.sound', []))
        
    }

    const filteredNotices = lodash.compact(notices);

    yield all(lodash.map(filteredNotices, notice => {
        setTimeout(() => dispatch(actions.normal(notice.replace('.mp3', '').toUpperCase())), time);

        time += timeStep;
    }));
}
