import { get, map, toInteger } from 'lodash'
import React from 'react'
import Pagination from '../../../Common/components/Pagination'
import WaitingDeliveryItem from './WaitingDeliveryItem'

const WaitingDeliveryContent = ({ filters, pagination, loading, data, onSearch }) => {
    const onChangePage = (page, pageSize) => {
        if (!loading) {
            onSearch({
                ...filters,
                page,
                per_page: pageSize,
            })
        }
    }

    return (
        <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed mt-0">
            {map(data, (item, index) => (
                <WaitingDeliveryItem
                    item={item}
                    key={index}
                />
            ))}

            <Pagination
                page={toInteger(filters.page) || 1}
                pageSize={toInteger(filters.per_page)}
                total={get(pagination, 'total', 0)}
                onChange={onChangePage}
            />
        </div>
    )
}

export default WaitingDeliveryContent
