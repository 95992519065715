import React, { Fragment, useCallback, useState } from 'react'
import { AutoComplete, Button, Col, Form, Icon, Input, InputNumber, Row, Select } from 'antd'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'
import { withTranslation } from 'react-i18next'

import apiService from './../../../../apiService'
import { useDeepCompareEffect } from './../../../../../../hooks'
import { getVar } from './../../../../../../system/support/helpers'
import { formatCurrency } from '../../../../../Common/services/helps'

const { Option } = AutoComplete

const AddProducts = ({ className, form, packageItems, t, units }) => {
    const [nextProductId, setNextProductId] = useState(2)
    const [suggestProducts, setSuggestProducts] = useState({})
    const getDefaultProducts = () => [
        {
            id: 1,
            code_item: undefined,
            name_zh: undefined,
            name_vi: undefined,
            hscode: undefined,
            url: undefined,
            sku: undefined,
            order_quantity: undefined,
            unit_price_origin: undefined,
            chinese_unit: undefined,
            vietnamese_unit: undefined,
        },
    ]

    const addProduct = () => {
        const products = getFieldValue('products')
        const newProducts = products.concat({
            id: nextProductId,
        })

        form.setFieldsValue({
            products: newProducts,
        })

        setNextProductId(nextProductId + 1)
    }

    const removeProduct = index => {
        const products = getFieldValue('products')
        const newProducts = [...products.slice(0, index), ...products.slice(index + 1)]

        form.setFieldsValue({
            products: newProducts,
        })
    }

    const renderSuggestProductOption = item => <Option key={item.sku}>{item.ChineseName}</Option>

    const handleSearch = (index, value) => {
        doGetSuggestProducts(index, value)
    }

    const handleSelectSuggestProduct = (index, sku) => {
        const product = ((suggestProducts && suggestProducts[index]) || []).find(suggestProduct => suggestProduct.sku === sku)

        if (!product) {
            return
        }

        form.setFieldsValue({
            name_zh: [...form.getFieldValue('name_zh').slice(0, index), product.ChineseName, ...form.getFieldValue('name_zh').slice(index + 1)],
            name_vi: [...form.getFieldValue('name_vi').slice(0, index), product.VietnameseName, ...form.getFieldValue('name_vi').slice(index + 1)],
            url: [...form.getFieldValue('url').slice(0, index), product.link, ...form.getFieldValue('url').slice(index + 1)],
            unit_price_origin: [
                ...form.getFieldValue('unit_price_origin').slice(0, index),
                Number(product.price_origin) || 0,
                ...form.getFieldValue('unit_price_origin').slice(index + 1),
            ],
            sku: [...form.getFieldValue('sku').slice(0, index), product.sku, ...form.getFieldValue('sku').slice(index + 1)],
            chinese_unit: [...form.getFieldValue('chinese_unit').slice(0, index), product.Chinese_Unit, ...form.getFieldValue('chinese_unit').slice(index + 1)],
            vietnamese_unit: [
                ...form.getFieldValue('vietnamese_unit').slice(0, index),
                product.ItemUnit,
                ...form.getFieldValue('vietnamese_unit').slice(index + 1),
            ],
        })

        setSuggestProducts([])
    }

    const getSuggestProducts = async (index, keyword) => {
        try {
            const { data } = await apiService.getSuggestProducts(keyword)

            setSuggestProducts({
                ...suggestProducts,
                [index]: data,
            })
        } catch (error) {
            setSuggestProducts([])
        }
    }

    useDeepCompareEffect(() => {
        let formProducts = getDefaultProducts()
        let newNextProductId = nextProductId

        if (packageItems.length > 0) {
            formProducts = []
            forEach(packageItems, packageItem => {
                if (getVar(packageItem, 'id') > newNextProductId) {
                    newNextProductId = packageItem.id + 1
                }

                formProducts.push({
                    id: getVar(packageItem, 'id'),
                    code_item: getVar(packageItem, 'code_item'),
                    name_zh: getVar(packageItem, 'name_zh'),
                    name_vi: getVar(packageItem, 'name_vi'),
                    hscode: getVar(packageItem, 'hscode'),
                    url: getVar(packageItem, 'url'),
                    sku: getVar(packageItem, 'sku'),
                    order_quantity: getVar(packageItem, 'order_quantity'),
                    unit_price_origin: getVar(packageItem, 'unit_price_origin'),
                    vietnamese_unit: getVar(packageItem, 'vietnamese_unit'),
                    chinese_unit: getVar(packageItem, 'chinese_unit'),
                })
            })
        }

        form.setFieldsValue({
            products: formProducts,
        })

        setNextProductId(newNextProductId)
    }, [packageItems])

    const doGetSuggestProducts = useCallback(debounce(getSuggestProducts, 350), [])

    const { getFieldDecorator, getFieldValue } = form

    getFieldDecorator('products', {
        initialValue: getDefaultProducts(),
    })

    const productFormItems = getFieldValue('products').map((product, index) => (
        <Fragment key={product.id}>
            <Form.Item className="a-hidden">
                {getFieldDecorator(`code_item[${index}]`, {
                    initialValue: product.code_item,
                })(<Input className="input-code_item" />)}
            </Form.Item>

            <Form.Item className="a-hidden">
                {getFieldDecorator(`chinese_unit[${index}]`, {
                    initialValue: product.chinese_unit,
                })(
                    <Select
                        className="width-100-pc input-chinese_unit"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp="children"
                        placeholder={t('calculation_unit')}
                        showSearch>
                        {units.map(unit => (
                            <Option
                                key={unit.ItemUnit}
                                value={unit.Chinese_Unit}>
                                {unit.Chinese_Unit}
                            </Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Row
                gutter={8}
                type="flex">
                <Col span={14}>
                    <Form.Item>
                        {getFieldDecorator(`name_zh[${index}]`, {
                            initialValue: product.name_zh,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('product_zh_name'),
                                    }),
                                },
                            ],
                        })(
                            <AutoComplete
                                className="width-100-pc input-product_zh_name"
                                dataSource={((suggestProducts && suggestProducts[index]) || []).map(renderSuggestProductOption)}
                                placeholder={t('product_zh_name')}
                                onSearch={handleSearch.bind(undefined, index)}
                                onSelect={handleSelectSuggestProduct.bind(undefined, index)}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={10}>
                    <Form.Item>
                        {getFieldDecorator(`hscode[${index}]`, {
                            initialValue: product.hscode,
                        })(
                            <Input
                                placeholder="HSCODE"
                                className="input-hs-code"
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={14}>
                    <Form.Item>
                        {getFieldDecorator(`name_vi[${index}]`, {
                            initialValue: product.name_vi,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('product_vi_name'),
                                    }),
                                },
                            ],
                        })(
                            <Input
                                placeholder={t('product_vi_name')}
                                className="input-product_vi_name"
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={10}>
                    <Form.Item>
                        {getFieldDecorator(`sku[${index}]`, {
                            initialValue: product.sku,
                            rules: [],
                        })(
                            <Input
                                placeholder="SKU"
                                className="input-product_sku"
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={14}>
                    <Form.Item>
                        {getFieldDecorator(`url[${index}]`, {
                            initialValue: product.url,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('product_link'),
                                    }),
                                },
                            ],
                        })(
                            <Input
                                placeholder={t('product_link')}
                                className="input-product_link"
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item>
                        {getFieldDecorator(`order_quantity[${index}]`, {
                            initialValue: product.order_quantity,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('quantity'),
                                    }),
                                },
                                {
                                    validator: async (rule, value, callback) => {
                                        if (Number.isInteger(value) && value <= 0) {
                                            callback(
                                                t('validation:gt', {
                                                    attribute: t('quantity'),
                                                    value: 0,
                                                })
                                            )
                                        } else if (value && !Number.isInteger(value)) {
                                            callback(
                                                t('validation:integer', {
                                                    attribute: t('quantity'),
                                                })
                                            )
                                        } else {
                                            callback()
                                        }
                                    },
                                },
                            ],
                        })(
                            <InputNumber
                                className="width-100-pc input-product_quantity"
                                placeholder={t('quantity')}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item>
                        {getFieldDecorator(`unit_price_origin[${index}]`, {
                            initialValue: product.unit_price_origin,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('unit_price'),
                                    }),
                                },
                                {
                                    validator: async (rule, value, callback) => {
                                        if (Number.isInteger(value) && value <= 0) {
                                            callback(
                                                t('validation:gt', {
                                                    attribute: t('unit_price'),
                                                    value: 0,
                                                })
                                            )
                                        } else {
                                            callback()
                                        }
                                    },
                                },
                            ],
                        })(
                            <InputNumber
                                className="width-100-pc  input-unit_price"
                                placeholder={`${t('unit_price')} (CNY)`}
                                formatter={value => formatCurrency(value)}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item>
                        {getFieldDecorator(`vietnamese_unit[${index}]`, {
                            initialValue: product.vietnamese_unit,
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('calculation_unit'),
                                    }),
                                },
                            ],
                        })(
                            <Select
                                className="width-100-pc input-calculation_unit"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                optionFilterProp="children"
                                placeholder={t('calculation_unit')}
                                showSearch>
                                {units.map(unit => (
                                    <Option
                                        key={unit.ItemUnit}
                                        value={unit.ItemUnit}
                                        className="input-calculation_unit-item">
                                        {unit.Chinese_Unit}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col className="amb-24 gflex galign-center">
                    <Icon
                        className="dynamic-delete-button a-text--fz-18"
                        type="minus-circle-o"
                        onClick={removeProduct.bind(undefined, index)}
                    />
                </Col>
            </Row>

            {getFieldValue('products') && getFieldValue('products').length
                ? index < getFieldValue('products').length - 1 && <hr className="border-bottom border-top-none amb-24" />
                : null}
        </Fragment>
    ))

    return (
        <div className={clsx('add-products', className)}>
            {productFormItems}

            <Button
                type="dashed"
                onClick={addProduct}
                className="input-add_product-btn">
                <Icon type="plus" />
                {t('add_product')}
            </Button>
        </div>
    )
}

export default withTranslation()(AddProducts)
