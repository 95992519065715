/* eslint-disable */
import React, {Component} from 'react';
import {Button, Empty, Icon, Input, Layout, Table} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {trans} from '../../../../system/i18n';
import Link from '../../../../system/routing/Link';
import * as lodash from "lodash";

const Search = Input.Search;
const Content = Layout.Content;

const listAction = (id) => {
    return (
        <React.Fragment>
            <Link href={`/config/shipping-users/edit/${id}`} className={'_btn-edit'}>
                <Icon type="edit" className={"mr-3 ahover-pointer a-text--blue"} />
            </Link>
        </React.Fragment>
    )
};
const columns = [
    {
        title: trans("shipping_user:label.code"),
        dataIndex: 'code',
        key: 'code',
        render: (_, record) => {
            return {
                props: {
                    className: `a-notification-dot a-notification-dot--table active ${record.shipping_user.active ? 'active': 'unactive'}`
                },
                children: <Link href={`/config/shipping-users/edit/${lodash.get(record, 'shipping_user.id', 0)}`}
                                className="a-text--nowrap _code">{lodash.get(record, 'shipping_user.code', '--')}</Link>,
            };
        },
    },{
        title: trans("shipping_user:label.username"),
        dataIndex: 'username',
        key: 'username',
        render: (_, record) => <span className={'_username'}>{lodash.get(record, 'shipping_user.username', '--')}</span>
    },{
        title: trans("shipping_user:label.full_name"),
        dataIndex: 'full_name',
        key: 'full_name',
        render: (_, record) => <span className={'_full_name'}>{lodash.get(record, 'shipping_user.full_name', '--')}</span>
    },{
        title: trans("shipping_user:label.phone"),
        dataIndex: 'phone',
        key: 'phone',
        render: (_, record) => <span className={'_phone'}>{lodash.get(record, 'shipping_user.phone', '--')}</span>
    },{
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: "a-text--right",
        fixed: 'right',
        width: 24,
        render: (_, record) => listAction(lodash.get(record, 'shipping_user.id', 0)),
    }
];

class ListShippingUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            i: 0,
            textSearch: '',
            arrShippingUser: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextProps.list !== prevState.arrShippingUser) {
            nextState.arrShippingUser = [...nextProps.list];
        }
        if (nextProps.filter.i){
            nextState.i = parseInt(nextProps.filter.i, 10)
        }
        if(nextProps.filter.textSearch !== prevState.textSearch){
            nextState.textSearch = nextProps.filter.textSearch;
        }
        return nextState;
    }

    handleSearch = (value) => {
        this.setState({
            textSearch: value,
        })
        this.props.onSearch({textSearch: value, i: this.state.i + 1});
    }

    handleSearchChange(event){
        if(event.target.value === "" && this.state.i !== 0){
            this.props.onSearch({textSearch: "", i: this.state.i});
        }
    }

    render() {
        let {t} = this.props;
        let { arrShippingUser, textSearch } = this.state;
        if(textSearch){
            arrShippingUser = arrShippingUser.filter(item => {
                if(item.shipping_user.username){
                    return (item.shipping_user.full_name.toLowerCase().indexOf(textSearch.toLowerCase().trim()) !== -1) || (item.shipping_user.username.toLowerCase().indexOf(textSearch.toLowerCase().trim()) !== -1)
                } else {
                    return item.shipping_user.full_name.toLowerCase().indexOf(textSearch.toLowerCase().trim()) !== -1
                }
            })
        }
        return (
            <Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>{t("shipping_user:label.list")} ({arrShippingUser.length})</h3>
                        <div className={"a-form__search-inline-title"}>
                            <Search
                                allowClear={true}
                                defaultValue={textSearch}
                                placeholder={t("shipping_user:placeholder.search")}
                                className={"a-input--search-inline-title _input-search"}
                                onSearch={this.handleSearch.bind(this)}
                                onChange={this.handleSearchChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary" className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-shipping-user">
                            <Link href="/config/create-shipping-user">{t("shipping_user:btn.create_user")}</Link>
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  height-100">
                    <Table className={"a-table--antd a-table--antd-scroll-x _a-table-shipping-user"} columns={columns} dataSource={arrShippingUser}
                        pagination={false}
                        // scroll={{ x: 'fit-content'}}
                        rowKey={record => record.shipping_user.id}
                        locale={{
                            emptyText: (
                            <Empty
                                className={"_empty"}
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                description={<span>{t("common:label.no_data")}</span>}
                            >
                            </Empty>)
                        }}
                    />
                </div>
            </Content>

        )
    }
}

ListShippingUser.defaultProps = {
    list: [],
    filter: {}
};

ListShippingUser.propTypes = {
    list: PropTypes.array,
    filter: PropTypes.object
};

export default withTranslation()(ListShippingUser);
