import { combineReducers } from 'redux';

import * as ACTION from './constants';

const list = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case ACTION.FETCH_BAG_SHIPPING_PARTNER.SUCCESS: {
            return payload.shipping_partners;
        }
        default: {
            return state;
        }
    }
};

const listSuggest = (state = [], action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.SUCCESS:
            return payload.shipping_partners;
        default:
            return state;
    }
};

const detail = (state = {}, action) => {
    let {type, payload} = action;
    switch (type) {
        case ACTION.FETCH_DETAIL_SHIPPING_PARTNER.SUCCESS:
            return {...payload.shipping_partner};
        case ACTION.FETCH_DETAIL_SHIPPING_PARTNER.FAILED:
        case ACTION.CLEAR_DETAIL_SHIPPING_PARTNER_STATE:
            return {};
        default:
            return state;
    }
};

const loadingDetail = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_DETAIL_SHIPPING_PARTNER.REQUEST:
            return true;
        case ACTION.FETCH_DETAIL_SHIPPING_PARTNER.FAILED:
        case ACTION.FETCH_DETAIL_SHIPPING_PARTNER.SUCCESS:
            return false;
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_BAG_SHIPPING_PARTNER.REQUEST:
            return true;
        case ACTION.FETCH_BAG_SHIPPING_PARTNER.FAILED:
        case ACTION.FETCH_BAG_SHIPPING_PARTNER.SUCCESS:
            return false;
        default:
            return state;
    }
};

const loadingSuggestList = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.REQUEST:
            return true;
        case ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.SUCCESS:
        case ACTION.FETCH_SHIPPING_PARTNER_SUGGEST.FAILED:
            return false;
        default:
            return state;
    }
};

const internationals = combineReducers({
    internationals: (state = [], action) => {
        const { payload, type } = action;

        switch (type) {
            case ACTION.GET_INTERNATIONALS.SUCCESS: {
                return payload.shipping_partners;
            }
            case ACTION.GET_INTERNATIONALS.FAILED: {
                return [];
            }
            default: {
                return state;
            }
        }
    },
    loading: (state = false, action) => {
        switch (action.type) {
            case ACTION.GET_INTERNATIONALS.REQUEST: {
                return true;
            }
            case ACTION.GET_INTERNATIONALS.FAILED:
            case ACTION.GET_INTERNATIONALS.SUCCESS: {
                return false;
            }
            default: {
                return state;
            }
        }
    }
});

const domestics = combineReducers({
    domestics: (state = {}, action) => {
        const { payload, type } = action;

        switch (type) {
            case ACTION.GET_DOMESTICS.SUCCESS: {
                return payload.shipping_domestic_partners;
            }
            case ACTION.GET_DOMESTICS.FAILED: {
                return {};
            }
            default: {
                return state;
            }
        }
    },
    suggests: (state = {}, action) => {
        const { payload, type } = action;

        switch (type) {
            case ACTION.GET_DOMESTIC_SUGGESTS.SUCCESS: {
                return payload.shipping_domestic_partners;
            }
            case ACTION.GET_DOMESTIC_SUGGESTS.FAILED: {
                return {};
            }
            default: {
                return state;
            }
        }
    },
    loading: (state = false, action) => {
        switch (action.type) {
            case ACTION.GET_DOMESTICS.REQUEST: {
                return true;
            }
            case ACTION.GET_DOMESTICS.FAILED:
            case ACTION.GET_DOMESTICS.SUCCESS: {
                return false;
            }
            default: {
                return state;
            }
        }
    },
    loadingSuggests: (state = false, action) => {
        switch (action.type) {
            case ACTION.GET_DOMESTIC_SUGGESTS.REQUEST: {
                return true;
            }
            case ACTION.GET_DOMESTIC_SUGGESTS.FAILED:
            case ACTION.GET_DOMESTIC_SUGGESTS.SUCCESS: {
                return false;
            }
            default: {
                return state;
            }
        }
    }
});

const lastmileCarriers = combineReducers({
    suggests: (state = [], action) => {
        const { payload, type } = action;

        switch (type) {
            case ACTION.GET_LASTMILE_CARRIER_SUGGEST.SUCCESS: {
                return payload.data;
            }
            case ACTION.GET_LASTMILE_CARRIER_SUGGEST.FAILED: {
                return []
            }
            default: {
                return state;
            }
        }
    },
    loadingSuggests: (state = false, action) => {
        switch (action.type) {
            case ACTION.GET_LASTMILE_CARRIER_SUGGEST.REQUEST: {
                return true;
            }
            case ACTION.GET_LASTMILE_CARRIER_SUGGEST.FAILED:
            case ACTION.GET_LASTMILE_CARRIER_SUGGEST.SUCCESS: {
                return false;
            }
            default: {
                return state;
            }
        }
    }
});

export default combineReducers({
    list,
    loading,
    detail,
    loadingDetail,
    listSuggest,
    loadingSuggestList,
    internationals,
    domestics,
    lastmileCarriers,
});
