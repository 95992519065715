import React from 'react';
import { connect } from 'react-redux';

import { setMenuActive } from './../Common/actions';

import Statistics from './components';

const StatisticsContainer = props => {
    return (
        <Statistics {...props} />
    );
};

const mapDispatchToProps = {
    setMenuActive
};

export default connect(
    undefined,
    mapDispatchToProps
)(StatisticsContainer);
