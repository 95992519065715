import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as ACTION_PACKING from './../BagPackingAction/constants';
import {DELETE_PACKAGE} from './../DeletePackage/constants';
import * as ACTION_UPDATE_WEIGHT from './../UpdateWeightRealBag/constants';
import * as moment from 'moment';
import lodash, { filter, get, isEmpty } from 'lodash';
import {UPDATE_BAG_PARAM} from "../UpdateBagParam/constants";
import {makeModelStateFromApiRes} from "../../Common/services/helps";
import {STAGES, UPDATE_NOTE} from './../../Notes/constants';

const makeBagState = (apiRes) => lodash.omit(makeModelStateFromApiRes(apiRes, 'bag'), 'package');
let bagPackingData = (state = {}, action) => {
    switch (action.type) {
        case ACTION.GET_BAG_PACKING_DETAIL.SUCCESS:
            return {...action.payload};
        case UPDATE_BAG_PARAM.SUCCESS:
            let newState = makeBagState(action.payload);
            return {...state, ...newState};
        case ACTION.CLEAR_PACKING_BAG_STATE:
            return {};
        default:
            return state;
    }
};

let bagData = (state = {}, action) => {
    switch (action.type) {
        case ACTION.GET_BAG_PACKING_DETAIL.SUCCESS:
            return {...action.payload.bag};
        case ACTION.CLEAR_PACKING_BAG_STATE:
            return {};
        case UPDATE_BAG_PARAM.SUCCESS:
            let newState = makeBagState(action.payload);
            return {...state, ...newState};
        case ACTION_UPDATE_WEIGHT.UPDATE_WEIGHT_REAL.SUCCESS:
            return {
                ...state,
                weight_real: lodash.get(action, "payload.bag.weight_real", 0),
                message_warnings: lodash.get(action, "payload.bag.message_warnings", [])
            };
        case ACTION_PACKING.PACKING_PACKAGE.SUCCESS:
        case DELETE_PACKAGE.SUCCESS: {
            const newState = {
                ...state,
                ...lodash.get(action, 'payload.bag', {}),
                message_warnings: lodash.get(action, 'payload.bag.message_warnings', []),
                weight_package: lodash.get(action, 'payload.bag.weight_package', 0)
            };

            return newState;
        }
        default: {
            return state;
        }
    }
};

let listBagPacking = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.GET_BAG_PACKING_DETAIL.SUCCESS:
            return [...action.payload.bag_packings];
        case ACTION.CLEAR_PACKING_BAG_STATE:
            return [];
        case ACTION_PACKING.PACKING_PACKAGE.REQUEST:
            if (isEmpty(filter(state, item=>!isEmpty(item.bag_last_mile)))) {
                let bagPackingWhileAddNew = [...state];
                const requestId = lodash.get(action, 'payload.request_id', null);
                const codePackage = lodash.get(action, 'payload.code_package');
                const index = bagPackingWhileAddNew.findIndex(bagPacking => lodash.get(bagPacking, 'code_package') === codePackage);
                const bagPacking = {
                    id: index === -1 ? -1 : lodash.get(bagPackingWhileAddNew[index], 'id'),
                    time_scan: moment().format("YYYY-MM-DD HH:mm:ss"),
                    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                    code_errors: "",
                    code_order: "",
                    code_package: action.payload.code_package,
                    id_bag: action.payload.id,
                    volume_package: 0,
                    weight_package: 0,
                    loading: true,
                    request_id: requestId,
                    ...action.payload
                };

                if (index === -1) {
                    bagPackingWhileAddNew.unshift(bagPacking);
                } else {
                    bagPackingWhileAddNew = [
                        ...bagPackingWhileAddNew.slice(0, index),
                        bagPacking,
                        ...bagPackingWhileAddNew.slice(index + 1)
                    ];
                }

                return bagPackingWhileAddNew;
            }

        return state
          
        case ACTION_PACKING.PACKING_PACKAGE.SUCCESS:
            let bag_packing = lodash.get(action.payload, 'bag_packing', {});
            let requestIdSuccess = lodash.get(action, 'request.request_id', null);

            return [...state].map((bagPacking) => {
                if (bagPacking.request_id === requestIdSuccess) {
                    bagPacking = {
                        ...bagPacking, ...bag_packing,
                        loading: false,
                        notes: lodash.get(action, 'payload.notes', []),
                        notices: lodash.get(action, 'payload.notices', []),
                        agency: lodash.get(action, 'payload.agency', []),
                        alias_services: lodash.get(action, 'payload.alias_services', []),
                        alias_properties: lodash.get(action, 'payload.alias_properties', [])
                    }
                }

                return bagPacking.code_package === bag_packing.code_package && bag_packing.code_errors && !bag_packing.code_errors.includes('PACKAGE_NOT_FOUND') && bagPacking.request_id !== requestIdSuccess ? null : {
                    ...bagPacking,
                    code_errors: bagPacking.code_package === lodash.get(action.request, 'code_package') ? [] : bagPacking.code_errors // not display code_errors of scanned package
                };
            }).filter(function (el) {
                return el !== null;
            });
        case ACTION_PACKING.PACKING_PACKAGE.FAILED: {

            const packing_barcode_type = get(action, 'request.packing_barcode_type')

            if (packing_barcode_type !== 'bag_last_mile_freight_bill') {

                return state.map(bagPacking => {
                    const package_not_checked = lodash.get(action.payload, 'data.code.package_not_checked');
                    const message = lodash.get(action.payload, 'data.code_package.message')
                    const packages = lodash.get(action.payload, 'data.code_package.packages')
                    const errors = lodash.get(action.payload, 'data.packing_not_completed', {});
                    const requestId = lodash.get(action, 'request.request_id', null);
                    const code_errors = Object.keys(errors).map(error => ({
                        code: error
                    }));

                    if (message) {
                        code_errors.push({
                            code: message,
                            packages
                        });
                    }
                    if (package_not_checked) {
                        code_errors.push({
                            code: "package_not_checked",
                        });
                    }
                    if (bagPacking.request_id !== requestId) {
                        return {
                            ...bagPacking
                            ,isLocal: true,
                        };
                    }
    
                    return {
                        ...bagPacking,
                        loading: false,
                        success: false,
                        isLocal: true,
                        code_errors
                    };
                });

            }
            return state

        }
        case DELETE_PACKAGE.REQUEST:
            let packingId = lodash.get(action, 'payload.packingId', null);
            return [...state].map((bagPacking) => {
                if (bagPacking.id === packingId) {
                    bagPacking = {...bagPacking, loading: true}
                }
                return bagPacking;
            });
        case DELETE_PACKAGE.SUCCESS:
            packingId = lodash.get(action, 'request.packingId', null);
            let bagPackingNew = [];
            [...state].map((bagPacking) => {
                if (bagPacking.id !== packingId) {
                    bagPackingNew.push(bagPacking);
                }

                return bagPacking;
            });
            return bagPackingNew;
        case UPDATE_NOTE.SUCCESS: {
            if (lodash.get(payload, 'stage') !== STAGES.ATTACH_PACKAGE_TO_BAG) {
                return state;
            }

            const newState = state.map(item => {
                const notes = item.notes || [];

                const index = notes.findIndex(note => note.id === lodash.get(payload, 'note.id'));

                if (index === -1) {
                    return item;
                }

                const isRemoveNote = !lodash.includes(lodash.get(payload, 'note.stages'), STAGES.ATTACH_PACKAGE_TO_BAG);
                const newNotes = isRemoveNote ? [
                    ...notes.slice(0, index),
                    ...notes.slice(index + 1)
                ] : [
                    ...notes.slice(0, index),
                    {
                        ...notes[index],
                        ...lodash.get(payload, 'note', {})
                    },
                    ...notes.slice(index + 1)
                ];

                return {
                    ...item,
                    notes: newNotes
                };
            });

            return newState;
        }
        default:
            return state;
    }
};

let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.GET_BAG_PACKING_DETAIL.REQUEST:
        case UPDATE_BAG_PARAM.REQUEST:
            return true;
        case ACTION.GET_BAG_PACKING_DETAIL.SUCCESS:
        case ACTION.GET_BAG_PACKING_DETAIL.FAILED:
        case UPDATE_BAG_PARAM.SUCCESS:
        case UPDATE_BAG_PARAM.FAILED:
        case ACTION.CLEAR_PACKING_BAG_STATE:

            return false;

        default:
            return state;
    }
};


export default combineReducers({
    bagData,
    listBagPacking,
    bagPackingData,
    loading
});
