import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import SelectWarehouse from './components/SelectWarehouse';
import lodash from "lodash";

const mapStateToProps = (state, props) => {
    return ({
        ...props,
        warehouses: props.warehouses || selectors.getState(state, 'suggest'),
        loading: props.loading || selectors.getState(state, 'loading', false),
    })
};

const mapDispatchToProps = (dispatch) => ({
    fetchWarehouses: (params) => dispatch(actions.fetchSuggestWarehouses(params)),
});

class SelectWarehouseContainer extends Component {
    state = {
        active: this.props.active
    };

    componentDidMount() {
        const { active } = this.state;
        const { isCustomWarehouse, fetchWarehouses, getDataWhenMounted } = this.props;
        const params = {
            active
        };

        if (isCustomWarehouse) {
            params.is_custom_warehouse = true;
        }

        if (getDataWhenMounted) {
            fetchWarehouses(params);
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {};
        // Nếu props active thay đổi thì update lại active warehouse trong state
        if (props.active !== state.active) {
            nextState = {
                ...nextState,
                active: props.active,
            };

            props.fetchWarehouses({
                active: props.active
            });
        }

        return lodash.isEmpty(lodash) ? null : nextState;
    }

    render() {
        return (
            <SelectWarehouse {...this.props} />
        );
    }
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {
        forwardRef: true
    }
)(SelectWarehouseContainer);

Container.defaultProps = {
    getDataWhenMounted: true,
    onChange: () => {}
};

Container.propTypes = {
    getDataWhenMounted: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default withTranslation()(Container);
