import { createAction } from 'redux-actions';

import * as ACTION from './constants';

export const addBarcode = createAction(ACTION.ADD_BARCODE);
export const clearState = createAction(ACTION.CLEAR_STATE);
export const create = createAction(ACTION.CREATE.REQUEST);
export const removeBarcode = createAction(ACTION.REMOVE_BARCODE);
export const resetError = createAction(ACTION.RESET_ERROR);
export const setBarcodes = createAction(ACTION.SET_BARCODES);
