import React from 'react';
import { Icon } from 'antd/lib/index';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import Link from 'react-router-dom/es/Link';
import { createSelector } from 'reselect';

import { LIST_WAREHOUSE_COLUMN, LIST_WAREHOUSE_STAFF_COLUMN } from './../constants';
import { getVar } from './../../../system/support/helpers';
import { t } from '../../../system/i18n';


export const getState = (state, param = null, defaultValue = null) => getVar(state, `configWarehouse.listWarehouseStaff${(param ? `.${param}` : '')}`, defaultValue);
export const getListWarehouseStaff = createSelector(
    state => getState(state, 'list', []),
    (warehouses_staffs) => map(warehouses_staffs, warehouse_staff => {
        const { warehouses, package_status} = warehouse_staff;
        const staff = warehouse_staff.user;
        const warehouse_charge = warehouses.map(values => ' ' + values.name).toString();
        const transport_status = !isEmpty(package_status) ? package_status.map(status => t(`package:status_transport.${status}`)).join(", ") : '';
        return {
            key: warehouse_staff.user.id,
            active: getVar(staff, 'active_warehouse_staff', true),
            [LIST_WAREHOUSE_STAFF_COLUMN.CODE]: (
                <span className="a-text--blue">
					{getVar(staff, 'username', '--')}
				</span>
            ),
            [LIST_WAREHOUSE_STAFF_COLUMN.NAME]: getVar(staff, 'name', '--'),
            [LIST_WAREHOUSE_STAFF_COLUMN.EMAIL]: getVar(staff, 'email', '--'),
            [LIST_WAREHOUSE_STAFF_COLUMN.PHONE]: getVar(staff, 'phone', '--'),
            [LIST_WAREHOUSE_STAFF_COLUMN.WAREHOUSE]: warehouse_charge,
            [LIST_WAREHOUSE_STAFF_COLUMN.TRANSPORT_STATUS]: transport_status,
            [LIST_WAREHOUSE_COLUMN.ACTION]: (
                <Link
                    to={`/config/warehouse-staff/edit/${getVar(staff, 'id', 0)}`}
                >
                    <Icon
                        className="mr-3 ahover-pointer a-text--blue a-text--right"
                        type="edit"
                    />
                </Link>
            )
        }
    })
);
