import React from 'react';

import { isPackageInventory, isReceivePackageInventory } from '../../helper';

import Packages from './Packages';
import ReceivePackages from './ReceivePackages';

const HistoryList = props => {
	const { inventory: { inventory} } = props;

	if (isPackageInventory(inventory)) {
		return <Packages {...props} />
	}

	if (isReceivePackageInventory(inventory)) {
		return <ReceivePackages {...props} />
	}

	return null;
};

export default HistoryList;
