import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as lodash from "lodash";

const setting = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_SETTING.SUCCESS:
            return lodash.get(payload, 'settings.globalSetting', []) || [];

        default:
            return state;
    }
};

const agencySetting = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_SETTING.SUCCESS:
            return lodash.get(payload, 'settings.agencySetting', []) || [];

        default:
            return state;
    }
};
const detail = (state = {}, action) => {
    const {type, payload} = action;
    let data = {};
    switch (type) {
        case ACTION.FETCH_DETAIL_SETTING.SUCCESS:
            data = {
                id: state.id ? state.id + 1 : 1,
                id_general: lodash.get(payload, 'setting_config_general.partner_organization_name.id', 0),
                information: {
                    partner_identify_name: lodash.get(payload, 'setting_config_general.partner_identify_name.value', ''),
                    partner_organization_address: lodash.get(payload, 'setting_config_general.partner_organization_address.value', ''),
                    partner_organization_name: lodash.get(payload, 'setting_config_general.partner_organization_name.value', ''),
                    partner_organization_phone: lodash.get(payload, 'setting_config_general.partner_organization_phone.value', ''),
                    partner_avatar: lodash.get(payload, 'setting_config_general.partner_avatar.value', ''),
                    partner_logo: lodash.get(payload, 'setting_config_general.partner_logo.value', ''),
                },
                system_config: {
                    partner_unit_weight: lodash.get(payload, 'setting_config_general.partner_unit_weight.value', ''),
                    partner_first_number_code: lodash.get(payload, 'setting_config_general.partner_first_number_code.value', ''),
                    partner_default_shipping_partner: lodash.get(payload, 'setting_config_general.partner_default_shipping_partner.value', ''),
                    partner_split_package: lodash.get(payload, 'setting_config_general.partner_split_package.value', false),
                    partner_bag_code_depend_agency: lodash.get(payload, 'setting_config_general.partner_bag_code_depend_agency.value', false),
                    partner_require_agency_when_create_bag: lodash.get(payload, 'setting_config_general.partner_require_agency_when_create_bag.value', false)
                }
            };
            return data;
        case ACTION.SET_DETAIL: {
            return {
                ...state,
                information: payload,
                id: state.id ? state.id + 1 : 1
            };
        }
        default:
            return state;
    }
};
const loading = (state = true, action) => {
    const {type} = action;

    switch (type) {
        case ACTION.FETCH_DETAIL_SETTING.REQUEST:
        case ACTION.FETCH_SETTING.REQUEST:
        case ACTION.UPDATE_SETTING.REQUEST:
            return true;
        case ACTION.FETCH_DETAIL_SETTING.SUCCESS:
        case ACTION.FETCH_DETAIL_SETTING.FAILED:
        case ACTION.UPDATE_SETTING.SUCCESS:
        case ACTION.UPDATE_SETTING.FAILED:
        case ACTION.FETCH_SETTING.SUCCESS:
        case ACTION.FETCH_SETTING.FAILED:
            return false;


        default:
            return state;
    }
};


export default combineReducers({
    setting,
    loading,
    detail,
    agencySetting
});
