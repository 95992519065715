import React from "react";
import {Button, Col, Form, Icon, Layout, message, notification, Radio, Row, Upload} from "antd";
import SelectCreatePartner from "../../ShippingPartner/SelectCreatePartner/SelectCreatePartnerContainer";
import lodash from "lodash"
import {checkFileExtension} from "../../../system/support/helpers";
import {t, trans} from "../../../system/i18n"
import api from "../apiService";
import {HTTP_STATUS} from "../../../system/api/constants";
import {DEFAULT_STATE_FORM_IMPORT_MANIFEST} from "./constans";
import {getErrors} from "./selectors"
import {connect} from "react-redux";
import {setMenuActive} from "../../Common/actions";
import pageService from "../../Common/Page/pageService";

const Content = Layout.Content;
const accept = ".xlsx, .xls";

class ImportManifest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_STATE_FORM_IMPORT_MANIFEST,
            errors: {},
            fileList: [],
            languages: [],
            loading: false
        }
    }

    componentDidMount() {
        pageService.setTitle(t("menu.import_manifest"));
        this.props.setMenuActive('bags/manifest')
    }

    onChangeLanguage = (e) => {
        this.setState({
            language: e.target.value,
            errors: this.state.errors["language"] ? lodash.omit(this.state.errors, 'language') : {...this.state.errors}
        });
    };
    onChangePartner = ({value, shipping_partner}) => {
        this.setState({
            id_shipping_partner: value,
            errors: this.state.errors["id_shipping_partner"] ? lodash.omit(this.state.errors, 'id_shipping_partner') : {...this.state.errors},
            languages: lodash.get(shipping_partner, 'official_config.manifest'),
            language: lodash.get(shipping_partner, 'official_config.manifest', []).length === 1 ?
                lodash.get(shipping_partner, 'official_config.manifest[0]') : null
        })
    };
    onPreview = () => {
        return false;
    };
    beforeUpload = (file) => {
        if (checkFileExtension(file.name, accept)) {
            let errors = {...this.state.errors};
            this.setState({
                manifest: file,
                fileList: [file],
                errors: (errors["manifest"] || errors['manifestFile']) ? lodash.omit(errors, ['manifest', 'manifestFile']) : {...errors},
            });
        } else {
            message.error(t('bag:message.error_accept'))
        }
        return false;
    };
    onRemoveManifestFile = () => {
        let errors = {...this.state.errors};
        this.setState({
            manifest: "",
            fileList: [],
            errors: (errors["manifest"] || errors['manifestFile']) ? lodash.omit(errors, ['manifest', 'manifestFile']) : {...errors},
        })
    };

    handleSubmit = () => {
        let formData = new FormData();
        lodash.keys(DEFAULT_STATE_FORM_IMPORT_MANIFEST).forEach((key) => {
            if (lodash.has(this.state, key) && !lodash.isNil(this.state[key])) {
                formData.append(key, this.state[key])
            }
        });
        this.setState({loading: true, errors: {}});
        api.importManifest(formData).then(res => {
            if (lodash.get(res, 'data.fail', []).length > 0) {
                this.setState({errors: {...this.state.errors, manifestFile: lodash.get(res, 'data.fail')}})
            } else {
                notification.success({
                    message: <span
                        className={"_import-manifest-success"}>{t('bag:message.import_manifest_success')}</span>
                });
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }

            this.setState({loading: false});
        }).catch(error => {
            this.setState({loading: false});
            let response = error.response;
            if (lodash.get(response, 'data.code') === 'exception') {
                notification.error({
                    message: <span className={"_import-manifest-error"}>{t('message.server_error')}</span>
                });
            } else if (lodash.get(response, 'data.code') === "INPUT_INVALID") {
                this.setState({errors: getErrors(lodash.get(response, 'data', {}))})
            } else if (lodash.get(response, 'status') !== HTTP_STATUS.UNAUTHORIZED) {
                notification.error({
                    message: <span className={"_notification_error_no_service"}>{t('common:message.no_service')}</span>
                });
            }
        })


    };
    render() {
        const {languages, fileList, errors, loading} = this.state;
        const checkedLanguages = languages.filter(language => !language.toLowerCase().includes('nocheck'));
        const noCheckLanguages = languages.filter(language => language.toLowerCase().includes('nocheck'));

        return (
            <Content className={" aml-16-lg aml-24 amr-24 amr-16-lg"}>
                    <div className="a-block-config amt-16">
                        <div className="a-block-config__body">
                            <Form className="a-form ">
                                <div className="a-block-config__title--block-child amb-16">
                                    <span className="title">{t('shipping_partner:label.choose_shipping_partner')}</span>
                                </div>
                                <Row gutter={{lg: 24, xl: 32}} className="a-block-info">
                                    <Col lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}>
                                        <Form.Item className="a-form__item "
                                                   label={t("shipping_partner:label.shipping_partner")}
                                                   required={true}
                                                   validateStatus={errors.id_shipping_partner ? 'error' : null}
                                                   help={errors.id_shipping_partner}
                                                   labelCol={{span: 24}}
                                        >
                                            <SelectCreatePartner
                                                autoSelect={false}
                                                emptyOption={false}
                                                placeholder={t('shipping_partner:placeholder.id_shipping_partner')}
                                                query="official_form"
                                                onChange={this.onChangePartner}
                                                className="a-select a-select--inventory-scan-warehouse _id_shipping_partner"
                                                value={this.state.id_shipping_partner}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {checkedLanguages && checkedLanguages.length && checkedLanguages.length > 0 ? (
                                    <>
                                        <div className="a-block-config__title--block-child amb-16">
                                            <span className="title">{t('bag:label.checking')}</span>
                                        </div>
                                        <Form.Item
                                            className={"a-form__item a-form--group amt-16"}
                                            help={errors.language}
                                            label={t("bag:label.language")}
                                            required={true}
                                            validateStatus={errors.language ? 'error' : null}
                                        >
                                            <Row gutter={{lg: 24, xl: 32}}>
                                                {checkedLanguages.map(language => (
                                                    <Col key={language} xs={6} lg={{span: 4}} xxl={{span: 3}}>
                                                        <Radio
                                                            className={`_manifest-language-${language}`}
                                                            checked={this.state.language === language}
                                                            value={language}
                                                            onChange={this.onChangeLanguage}
                                                        >
                                                            <span className={`a-text--black _${language}`}>
                                                                {t(`language:locales.${language}`)}
                                                            </span>
                                                        </Radio>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Form.Item>
                                    </>
                                ) : null}
                                {noCheckLanguages && noCheckLanguages.length && noCheckLanguages.length > 0 ? (
                                    <>
                                        <div className="a-block-config__title--block-child amb-16">
                                            <span className="title">{t('bag:label.no_checking')}</span>
                                        </div>
                                        <Form.Item
                                            className={"a-form__item a-form--group amt-16"}
                                            help={errors.language}
                                            label={t("bag:label.language")}
                                            required={true}
                                            validateStatus={errors.language ? 'error' : null}
                                        >
                                            <Row gutter={{lg: 24, xl: 32}}>
                                                {noCheckLanguages.map(language => (
                                                    <Col key={language} xs={6} lg={{span: 4}} xxl={{span: 3}}>
                                                        <Radio
                                                            className={`_manifest-language-${language}`}
                                                            checked={this.state.language === language}
                                                            value={language}
                                                            onChange={this.onChangeLanguage}
                                                        >
                                                            <span className={`a-text--black _${language}`}>
                                                                {t(`language:locales.${language.replace('_nocheck', '')}`)}
                                                            </span>
                                                        </Radio>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Form.Item>
                                    </>
                                ) : null}
                                <div>
                                    <div className="a-block-config__title--block-child amb-16">
                                        <span className="title">{t("bag:label.upload_title")}</span>
                                    </div>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-block-info ">
                                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                                            <Form.Item className="a-form__item "
                                                       required={true}
                                                       label={t("bag:label.upload_file_manifest")}
                                                       validateStatus={errors.manifest ? 'error' : null}
                                                       help={errors.manifest}>
                                                <Upload.Dragger
                                                    accept={accept}
                                                    onPreview={this.onPreview}
                                                    fileList={fileList}
                                                    className={"_upload-file"}
                                                    disabled={!this.state.language}
                                                    beforeUpload={this.beforeUpload}
                                                    onRemove={this.onRemoveManifestFile}>

                                                    <p className="ant-upload-drag-icon">
                                                        <Icon type="inbox"/>
                                                    </p>
                                                    <p className="ant-upload-text">{t('dragger_form.hint1')}</p>
                                                    <p className="ant-upload-hint">{t('dragger_form.hint2')}</p>
                                                </Upload.Dragger>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {lodash.get(errors, 'manifestFile', []).length > 0 && <Row>
                                        <Col>
                                            <p className={"a-text--error mb-0 mt-1"}>{trans('bag:message.file_manifest_row_error', {
                                                line_from_excel:
                                                    lodash.get(errors, 'manifestFile', []).map((row, index) =>
                                                        <span key={index}><b key={index}>{row.line_from_excel}</b>
                                                            {index < lodash.get(errors, 'manifestFile', []).length - 1 ? ", " : ""}</span>
                                                    ),
                                            })}</p>
                                        </Col>
                                    </Row>}
                                </div>
                                <Row gutter={48}>
                                    <Col lg={{span: 24}} xl={{span: 24}}
                                         className="a-package--action only-action mt-26">
                                        <Button
                                            loading={loading}
                                            onClick={this.handleSubmit}
                                            className={"a-btn a-btn--primary mr-3 phz-24 _btn-ok"}>
                                            {t("btn.ok")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Content>
            // </Layout>
        );
    }

}

const mapDispatchToProps = (dispatch) => ({
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});
const mapStateToProps = (state) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportManifest)
