import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';


let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_TEMPLATE_BAGS.REQUEST:
            return true;
        case ACTION.FETCH_TEMPLATE_BAGS.FAILED:
        case ACTION.FETCH_TEMPLATE_BAGS.SUCCESS:
            return false;
        default:
            return state;
    }
};

const list = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_TEMPLATE_BAGS.SUCCESS:
            return {...action.payload};
        default:
            return state;
    }
};

const creating = (state = false, action) => {
    switch (action.type) {
        case ACTION.CREATE_TEMPLATE_BAG.REQUEST:
            return true;
        case ACTION.CREATE_TEMPLATE_BAG.FAILED:
        case ACTION.CREATE_TEMPLATE_BAG.SUCCESS:
            return false;
        default:
            return state;
    }
};

let createErrors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.CREATE_TEMPLATE_BAG.REQUEST:
        case ACTION.CREATE_TEMPLATE_BAG.SUCCESS:
            return {};
        case ACTION.CREATE_TEMPLATE_BAG.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            if(action.payload.field === 'all') {
                return {};
            }
            return {...state, data: lodash.omit(state.data, action.payload.field)};
        default:
            return state;
    }
};

const updating = (state = false, action) => {
    switch (action.type) {
        case ACTION.UPDATE_TEMPLATE_BAG.REQUEST:
            return true;
        case ACTION.UPDATE_TEMPLATE_BAG.FAILED:
        case ACTION.UPDATE_TEMPLATE_BAG.SUCCESS:
            return false;
        default:
            return state;
    }
};

let updateErrors = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_TEMPLATE_BAG.REQUEST:
        case ACTION.UPDATE_TEMPLATE_BAG.SUCCESS:
            return {};
        case ACTION.UPDATE_TEMPLATE_BAG.FAILED:
            return {...action.payload};
        case ACTION.RESET_ERROR:
            if(action.payload.field === 'all') {
                return {};
            }
            return {...state, data: lodash.omit(state.data, action.payload.field)};
        default:
            return state;
    }
};

let detail = (state = {}, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.FETCH_TEMPLATE_BAG_DETAIL.SUCCESS:
            return {...action.payload};
        case ACTION.CLEAR_STATE_TEMPLATE_BAG:
            return {};
        default:
            return state;
    }
};


export default combineReducers({
    creating,
    loading,
    list,
    detail,
    createErrors,
    updating,
    updateErrors
});