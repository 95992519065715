import { combineReducers } from 'redux';
import { map, omit } from 'lodash';

import * as ACTION from './constants';
import { makeModelStateFromApiRes, updateCollectionItem } from './../../Common/services/helps';
import { UPDATE_BAG_PARAM } from './../UpdateBagParam/constants';

const makeBagState = apiRes => omit(makeModelStateFromApiRes(apiRes, 'bag'), 'package');

const bag = (state = null, action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.FETCH_BAG_DETAIL.SUCCESS: {
            return makeBagState(payload);
        }
        case UPDATE_BAG_PARAM.SUCCESS:
        case ACTION.UPDATE_BAG.SUCCESS: {
            const newState = makeBagState(payload);
            return {...state, ...newState};
        }
        case ACTION.SET_BAG_DETAIL: {
            return makeBagState(payload);
        }
        case ACTION.CLEAR_STATE: {
            return null;
        }
        default: {
            return state;
        }
    }
};

const packages = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.FETCH_BAG_DETAIL.SUCCESS: {
            return map(payload.packages, packageRes => makeModelStateFromApiRes(packageRes, 'package'));
        }
        case ACTION.DETACH_PACKAGE.REQUEST: {
            return updateCollectionItem(state, payload.packageId, {loading: true});
        }
        case ACTION.DETACH_PACKAGE.SUCCESS: {
            return state.filter(packageData => packageData.id !== action.request.packageId);
        }
        case ACTION.DETACH_PACKAGE.FAILED: {
            return updateCollectionItem(state, action.request.packageId, {loading: false});
        }
        default: {
            return state;
        }
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_BAG_DETAIL.REQUEST:
        case ACTION.UPDATE_BAG.REQUEST:
        case ACTION.CREATE_TEMPLATE_BAG.REQUEST: {
            return true;
        }
        case ACTION.FETCH_BAG_DETAIL.SUCCESS:
        case ACTION.FETCH_BAG_DETAIL.FAILED:
        case ACTION.UPDATE_BAG.SUCCESS:
        case ACTION.UPDATE_BAG.FAILED:
        case ACTION.CREATE_TEMPLATE_BAG.SUCCESS:
        case ACTION.CREATE_TEMPLATE_BAG.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const similarBag = (state = {}, action) => {
    switch (action.type) {
        case ACTION.CREATE_SIMILAR_BAG.REQUEST: {
            return state;
        }
        case ACTION.CREATE_SIMILAR_BAG.SUCCESS: {
            return {...action.payload};
        }
        case ACTION.CREATE_SIMILAR_BAG.FAILED: {
            return state;
        }
        default: {
            return state;
        }
    }
};

const isShowPopupCreateTemplateBag = (state = false, action) => {
    switch (action.type) {
        case ACTION.TOGGLE_POPUP_CREATE_TEMPLATE_BAG: {
            return !state;
        }
        case ACTION.CREATE_TEMPLATE_BAG.SUCCESS: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const exporting = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case ACTION.EXPORT_BAG.REQUEST: {
            return true;
        }
        case ACTION.EXPORT_BAG.SUCCESS:
        case ACTION.EXPORT_BAG.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    bag,
    loading,
    packages,
    similarBag,
    isShowPopupCreateTemplateBag,
    exporting
});
