import React from 'react';
import { Icon, Layout } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { editShippingPartner, editShippingDomesticPartner } from './actions';

import FormShippingPartnerContainer from './../FormShippingPartner/FormShippingPartnerContainer';

const { Content } = Layout;

const mapDispatchToProps = (dispatch, props) => ({
    editShippingPartner: data => {
        const { match: { params: { type } } } = props;

        if (type === 'domestic') {
            return dispatch(editShippingDomesticPartner(data));
        }

        return dispatch(editShippingPartner(data));
    }
});

const EditShipingPartnerContainer = props => (
    <Content className="aml-16-lg aml-24 amr-24 amr-16-lg a-content-config">
        <div className="a-breadcrumb">
            <Link to={`/config/shipping-partners/${props.match.params.type}`} className="a-text--gray _btn-back">
                <Icon type="arrow-left" className="mr-2" />
                {props.t('shipping_partner:label.back_shipping_partner')}
            </Link>
        </div>
        <FormShippingPartnerContainer {...props} />
    </Content>
);

export default withTranslation()(connect(
    undefined,
    mapDispatchToProps
)(EditShipingPartnerContainer));
