import React, { Component } from 'react'
import { Button, Layout, Modal, notification, Row, Spin, Switch } from 'antd'
import { filter, find, get, isEmpty, map, omit, round, some, toLength } from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import apiService from './../apiService'
import { fetchSuggestBoxSizes } from './../../BoxSize/actions'
import { getLoading, getSuggestBoxSizes } from './../../BoxSize/selectors'
import pageService from './../../Common/Page/pageService'
import { getErrors } from './../../../system/support/helpers'

import PackageList from './PackageList'
import CheckingPackageLogs from './../CheckingPackage/components/CheckingPackageLogs'
import AdjustSuggest from './AdjustSuggest'
import apiServices from '../../ShippingPartner/DetailShippingPartner/apiServices'
import { t, trans } from '../../../system/i18n'

const { Content } = Layout

class SplitPackage extends Component {
    state = {
        detachSuggest: {},
        loading: false,
        packages: [],
        originPackage: {},
        groupByProduct: false,
        adjustNum: 0,
        loadingDetachAuto: false,
        countPackaged: 0,
    }
    intervalRef = React.createRef()
    updatePageTitle = () => {
        const { t } = this.props

        pageService.setTitle(t('package:detail.split_package'), this.state.loading ? <Spin /> : null)
    }

    getDetachSuggest = async (updateOriginPackageOnly = false, notChangeAdjust, params = {}, notCall) => {
                const {
            match: {
                params: { id },
            },
            fetchSuggestBoxSizes,
        } = this.props

        const { detachSuggest: detachSuggestData } = this.state

        this.setState({
            loading: true,
        })

        const urlParams = new URLSearchParams(window.location.search)
        const myParam = { without_origin: urlParams.get('without_origin') === 'true', number: urlParams.get('number')}

        try {
            const response = await apiService.getDetachSuggest(id, { ...myParam, ...params })

            const detachSuggest = response.data
            const formattedPackageItems = map(get(detachSuggest, 'package_items', []), packageItem => omit(packageItem, 'unit_price'))
            const services = get(detachSuggest, 'services', [])
            const orderItems = map(map(get(detachSuggest, 'order_items', []), packageItem => omit(packageItem, 'unit_price')), item=>({...item, received_quantity: item.purchase_quantity}))
            if (!isEmpty(services)) {
                fetchSuggestBoxSizes({ id_services: map(services, 'id'), only_active: true })
            }

            this.setState(prevState => {
                const { packages } = prevState
                const originPackage = {
                   
                    ...detachSuggest.package,
                    items: !isEmpty(formattedPackageItems) ? formattedPackageItems: orderItems,
                    length: round(get(detachSuggest, 'package.length', 0) * 100, 6),
                    width: round(get(detachSuggest, 'package.width', 0) * 100, 6),
                    height: round(get(detachSuggest, 'package.height', 0) * 100, 6),
                    type: 'origin',
                    weight: get(detachSuggest, 'package.weight_net'),
                    permissions: get(detachSuggest, 'permissions'),
                    required_contents: get(detachSuggest, 'required_contents', []),
                }

                let newPackages = packages

                if (updateOriginPackageOnly) {
                    newPackages = [originPackage, ...prevState.packages.slice(1)]
                } else {
                    newPackages = [originPackage, ...detachSuggest.suggested_packages]

                    if (!notCall) {
                        const firstPackage = get(newPackages, '0')
                        const packageItems = get(detachSuggest, 'package_items', [])

                        apiServices
                            .fetchDetailShippingPartner(get(firstPackage, 'id_shipping_partner'))
                            .then(result => {
                                const maxValueShipping = get(result, 'data.shipping_partner.max_value_package') || 0

                                const underValueItem = find(packageItems, item => item.unit_price > maxValueShipping)
                                if(!isEmpty(underValueItem)){
                                    Modal.warning({
                                        title:"",
                                        content:  trans('package:message.warning_under_unit_price', {
                                            code_item: <b>{get(underValueItem, 'code_item', '')}</b>,
                                        }),
                                        okText: t('btn.close'),
                                    })
                                }
                               
                            })
                            .catch(err => {})
                    }
                }

                if (get(detachSuggestData, 'suggested_packages', []).length !== get(detachSuggest, 'suggested_packages').length) {
                    this.handleChangeLoadingDetachAuto(false)
                    this.handleChangeCountPackage(get(detachSuggest, 'suggested_packages').length)
                    clearInterval(this.intervalRef.current)
                }

                if (notChangeAdjust) {
                    return {
                        detachSuggest,
                        originPackage,
                        packages: newPackages,
                    }
                } else {
                    return {
                        detachSuggest,
                        originPackage,
                        packages: newPackages,
                        adjustNum: get(detachSuggest, 'suggested_packages').length,
                        countPackaged: filter(newPackages, item => !item.id).length + 1,
                    }
                }
            })
        } catch (error) {
            const code = get(error, 'response.data.code')
            const data = get(error, 'response.data.data')
            if (code === 'INPUT_INVALID') {
                Object.entries(data).forEach(([key, value]) => {
                    notification.error({
                        message: t(`package:create_shipment_order.errors.${key}.${Object.keys(value)[0]}`),
                    })
                })
            }
            this.setState({
                detachSuggest: {},
            })
        }

        this.setState({
            loading: false,
        })
    }

    addPackage = async (index, data) => {
        const {
            match: {
                params: { id },
            },
            t,
        } = this.props
        const  { groupByProduct } = this.state

        try {
            const response = await apiService.detach(id, {...data, group_product_detach: +groupByProduct})

            notification.success({
                message: t('package:message.split_package_success'),
            })

            this.setState(prevState => ({
                packages: [
                    ...prevState.packages.slice(0, index),
                    {
                        ...prevState.packages[index],
                        ...data,
                        ...get(response, 'data.detach_package.package'),
                        id: get(response, 'data.detach_package.package.id'),
                        code: get(response, 'data.detach_package.package.code'),
                        weight_net: get(response, 'data.detach_package.package.weight_net'),
                        weight: get(response, 'data.detach_package.package.weight_net'),
                        permissions: get(response, 'data.detach_package.permissions'),
                        length: round(get(response, 'data.detach_package.package.length', 0) * 100, 6),
                        width: round(get(response, 'data.detach_package.package.width', 0) * 100, 6),
                        height: round(get(response, 'data.detach_package.package.height', 0) * 100, 6),
                        box_size: get(response, 'data.detach_package.box_size'),
                        weight_converted: get(response, 'data.detach_package.package.weight_converted'),
                        weight_box: get(response, 'data.detach_package.package.weight_box'),
                    },
                    ...prevState.packages.slice(index + 1),
                ],
            }))
            this.getDetachSuggest(true)
        } catch (error) {
            const errors = get(error, 'response.data', {})

            if (Object.keys(errors.data).includes('weight_net')) {
                throw error
            }

            const translatedErrors = getErrors(errors)

            const message = `${t('package:message.split_package_error')}: ${translatedErrors[Object.keys(translatedErrors)[0]]}`

            notification.error({
                message,
            })
        }
    }

    updateOriginPackage = async data => {
        const { t } = this.props

        try {
            const response = await apiService.updateWeightVolume(data.id, data)

            notification.success({
                message: t('package:message.update_weight_success'),
            })

            return response
        } catch (error) {
            throw error
        }
    }

    handleUpdatePackage = async (index, data) => {
        const { packages } = this.state

        if (!packages[index]) {
            return
        }

        try {
            if (data.id) {
                await this.updateOriginPackage(data)
            } else {
                await this.addPackage(index, data)
                this.handleDecreaseCountPackage()
            }
        } catch (error) {
            throw error
        }
    }

    handleRemovePackage = index => {
        this.setState(prevState => ({
            packages: [...prevState.packages.slice(0, index), ...prevState.packages.slice(index + 1)],
        }))
        this.handleDecreaseCountPackage()
    }

    handleAddDetachPackageButtonClick = () => {
        const pkg = this.getNewPackage()

        this.setState(prevState => ({
            packages: [...prevState.packages, pkg],
        }))
        this.handleIncreaseCountPackage()
    }

    getNewPackage = () => {
        const {
            detachSuggest: { order_items, package_items },
        } = this.state
        const items = []

        if (Array.isArray(order_items)) {
            for (const orderItem of order_items) {
                const packageItem = find(package_items, packageItem => packageItem.code_item === orderItem.code_item)

                if (packageItem && packageItem.received_quantity) {
                    items.push({
                        ...orderItem,
                        received_quantity: undefined,
                    })
                }
            }
        }

        return {
            weight: undefined,
            items,
        }
    }

    handleChangeGroupByProduct = checked => {
        this.setState({
            groupByProduct: checked,
        })
    }

    handleChangeCountPackage = (value) => {
        this.setState({
            countPackaged: value,
        })
    }

    handleDecreaseCountPackage = () => {
        const { countPackaged } = this.state
        this.setState({
            countPackaged: countPackaged - 1,
        })
    }

    handleIncreaseCountPackage = () => {
        const { countPackaged } = this.state
        this.setState({
            countPackaged: countPackaged + 1,
        })
    }

    handleChangeLoadingDetachAuto = value => {
        this.setState({
            loadingDetachAuto: value,
        })
    }

    componentDidMount() {
        this.updatePageTitle()
        this.getDetachSuggest()
        const urlParams = new URLSearchParams(window.location.search)
        this.setState({
            groupByProduct: urlParams.get('group_by_product') === 'true',
        })
    }

    render() {
        const { detachSuggest, loading, originPackage, packages, groupByProduct, adjustNum, loadingDetachAuto, countPackaged } = this.state
        const { boxSizes, loadingBoxSizes, t, history, location } = this.props

        return (
            <Spin spinning={loading || loadingDetachAuto}>
                <Content className="screen-split-package _screen_split_package a-content pl-0 gflex gflex-direction-column min-height-not-header a-content--height-not-header">
                    <Row
                        type="flex"
                        className="aposition-reletive height-100 a-flex-1">
                        <div className="a-content--scroll-is-box-chat">
                            <div className="gflex galign-center gjustify-space-between mb-3">
                                <div>
                                    <Switch
                                        checked={groupByProduct}
                                        onChange={this.handleChangeGroupByProduct}
                                    />
                                    <span className="ml-2">{t('package:label.group_by_product')}</span>
                                </div>
                                <div className="gflex galign-center gjustify-space-between">
                                    <AdjustSuggest
                                        adjustNum={adjustNum}
                                        packages={packages}
                                        getDetachSuggest={this.getDetachSuggest}
                                        intervalRef={this.intervalRef}
                                        handleChangeLoadingDetachAuto={this.handleChangeLoadingDetachAuto}
                                        loadingDetachAuto={loadingDetachAuto}
                                        countPackaged={countPackaged}
                                        history={history}
                                        location={location}
                                    />
                                    {/* <h3 className="a-text--fz-18 ap-16 a-text--right">
                                        <span className="mr-1"> {t('package:label.number_separated_package')} : </span>
                                        {get(detachSuggest, 'suggested_packages', []).length}
                                    </h3> */}
                                </div>
                            </div>
                            {get(detachSuggest, 'package.id') ? (
                                <PackageList
                                    boxSizes={boxSizes}
                                    loadingBoxSizes={loadingBoxSizes}
                                    orderItems={get(detachSuggest, 'order_items', [])}
                                    originPackage={originPackage}
                                    packages={packages}
                                    pkg={get(detachSuggest, 'package', {})}
                                    suggestedPackages={get(detachSuggest, 'suggested_packages', [])}
                                    onUpdate={this.handleUpdatePackage}
                                    onRemovePackage={this.handleRemovePackage}
                                    groupByProduct={groupByProduct}
                                    getDetachSuggest={this.getDetachSuggest}
                                    detachSuggest={detachSuggest}
                                />
                            ) : null}

                            <div className="a-text--center mt-8 mb-8">
                                <Button
                                    className="a-btn a-btn--primary _btn-add-detach-package"
                                    type="primary"
                                    onClick={this.handleAddDetachPackageButtonClick}>
                                    {t('package:btn.add_detach_package')}
                                </Button>
                            </div>
                        </div>

                        {get(detachSuggest, 'package.id') ? (
                            <CheckingPackageLogs
                                packageId={get(detachSuggest, 'package.id')}
                                orderId={get(detachSuggest, 'package.id_order')}
                            />
                        ) : null}
                    </Row>
                </Content>
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    const boxSizes = getSuggestBoxSizes(state, 'box_sizes', [])

    return {
        boxSizes,
        loadingBoxSizes: getLoading(state),
    }
}

const mapDispatchToProps = {
    fetchSuggestBoxSizes,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SplitPackage))
