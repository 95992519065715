import React from 'react';
import lodash from 'lodash';
import {Spin} from "antd";
import Link from "../../../../system/routing/Link";
import {t} from "../../../../system/i18n";
import {EmptyData} from "../../../Common/components/EmptyData";

class ListBagCreatedToday extends React.Component {

    render() {
        const {bags, loading} = this.props;
        if (loading === false && bags.length === 0) {
            return <EmptyData />
        }
        return (
            <Spin spinning={loading}>
                <div className="bags-created-today _bags_created_today">
                    {bags.map(item => (
                        <div className="bag-item" key={lodash.get(item, 'bag.id')}>
                            <div className="name _bag_code">
                                <Link to={"bags.packing"} params={{id: lodash.get(item, 'bag.id')}} isNewTab={true}>{lodash.get(item, 'bag.code')}</Link>
                            </div>
                            <div className="info">
                                <div className="dpl-inline-block weight">
                                    <span className="lbel _bag_weight">{t('bag:packing.weight')}:</span> <span className="num">{lodash.get(item, 'bag.weight_real')}(kg)</span>
                                </div>
                                <div className="dpl-inline-block">
                                    <span className="lbel _bag_count_package">{t('bag:packing.count_package')}:</span> <span className="num">{lodash.get(item, 'bag.num_package')}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Spin>
        )
    }
}

export {ListBagCreatedToday}