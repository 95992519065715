import React from 'react';

const Box5 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(-0.348 -0.348)">
        <path className="c" d="M3.741,6.871a.522.522,0,1,0-.417.956l.527.231A.522.522,0,0,0,4.268,7.1Z" />
        <path className="c" d="M12.174,7.334a.7.7,0,0,0-.7.7v4.619a.174.174,0,0,1-.1.159L7.131,14.665v-.751a.522.522,0,0,0-1.044,0v.751L1.844,12.808a.174.174,0,0,1-.1-.159V6.829a.175.175,0,0,1,.1-.159l4.7-2.054a.173.173,0,0,1,.139,0l1.67.73A.7.7,0,0,0,8.9,4.071L6.887,3.189a.688.688,0,0,0-.557,0L.775,5.62h0a.729.729,0,0,0-.424.649v6.949a.7.7,0,0,0,.417.637L6.331,16.29a.7.7,0,0,0,.557,0l5.565-2.435a.7.7,0,0,0,.417-.637V8.03a.7.7,0,0,0-.7-.7Z" />
        <path className="c" d="M6.088,11.479v.7a.522.522,0,1,0,1.044,0v-.7a.522.522,0,1,0-1.044,0Z" />
        <circle className="c" cx="1.391" cy="1.391" r="1.391" transform="translate(5.218 7.305)" />
        <path className="c" d="M15.955.737a1.329,1.329,0,0,0-1.878,0l-3.54,3.539a.348.348,0,0,0-.095.177l-.348,1.739a.347.347,0,0,0,.408.41l1.739-.348a.348.348,0,0,0,.178-.1L15.96,2.62a1.331,1.331,0,0,0,0-1.883Z" />
      </g>
    </g>
  </svg>
);

export default Box5;
