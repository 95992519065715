import {createSelector} from "reselect";
import lodash from "lodash";
import * as selectorDetailWarehouse from "../DetailWarehouse/selectors"
import * as selectorUser from "../../User/selectors"

export const getState = (state, param = null, defaultValue = null) =>
	lodash.get(state, 'configWarehouse.addStaffInWarehouse' + (param ? '.' + param : ''), defaultValue);

export const getListUser = createSelector(
	(state, page) => selectorUser.getState(state, 'users', []),
	(state, page) => selectorDetailWarehouse.getState(state, 'staff', []),
	(users, staffs) => lodash.filter(users, user => !lodash.find(staffs, staff => lodash.get(staff, 'user.id', 0) === user.id)
	)
);
