/* eslint-disable */
import React from 'react';
import {Icon, Pagination, Select, Table} from "antd";

const Option = Select.Option;

const data = [];
for (let i = 0; i < 15; i++) {
    data.push({
        namecode: `Lê Hoàng Thuỳ Trang (lhtt123)`,
        requrestnumber: `${i + 2} `,
        numberorder: `${i + 5} `,
        numberpackage: `${i + 10} `,
        totalweight: `${i + 10} (kg) `,
        action: `Tạo phiếu xuất`
    });
}
const data2 = [];
for (let i = 0; i < 3; i++) {
    data2.push({
        warehouse: `CNGZ`,
        requestdeliverynote: `YGISY${i + 1}`,
        numberorder: `28`,
        numberpackage: `6`,
        totalweight: `12 (kg)`,
        address: `Toà Nhà JSC34, Ngõ 164, Nhân Chính, Thanh Xuân, Hà `,
        action: `Tạo phiếu xuất`
    });
}


function CustomExpandIcon(props) {
    let text;
    if (props.expanded) {
        text = "up"
    } else {
        text = "down"
    }
    return (
        <Icon type={text} className="a-text--gray-70" onClick={e => props.onExpand(props.record, e)}/>

    );
}


const expandedRowRender = () => {
    const column2 = [
        {
            title: 'Yêu cầu giao',
            dataIndex: 'requestdeliverynote',
            key: 'requestdeliverynote',
            render: text => <a className="a-text--nowrap a-text--blue">{text} </a>,
        },
        {
            title: 'Kho',
            dataIndex: 'warehouse',
            key: 'warehouse',
            className: "a-text--nowrap",
            render: text => <span>{text}</span>,
        }, {
            title: 'Số đơn hàng',
            dataIndex: 'numberorder',
            key: 'numberorder',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Số lượng kiện',
            dataIndex: 'numberpackage',
            key: 'numberpackage',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Tổng cân nặng',
            dataIndex: 'totalweight',
            key: 'totalweight',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Địa chỉ giao',
            dataIndex: 'address',
            key: 'address',
            render: text => <span>{text}</span>,
        }, {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: text => <a className="a-text--nowrap a-text--blue action-row-show-hover">{text} <Icon type="delete"
                                                                                     className="ml-5"/></a>,
        }];


    return <Table className={"a-table--antd"} columns={column2} dataSource={data2}
                  pagination={false}
                  rowClassName="row-show-item-hover"
    />;
};

class ListRequestDeliveryNote extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const columns = [
            {
                title: 'Họ và tên / Mã KH',
                dataIndex: 'namecode',
                key: 'namecode',
                render: text => <a className="a-text--nowrap a-text--blue">{text}</a>,
            }, {
                title: 'Số lượng yêu cầu giao',
                dataIndex: 'requrestnumber',
                key: 'requrestnumber',
                className: "a-text--nowrap",
            }, {
                title: 'Số lượng đơn',
                dataIndex: 'numberorder',
                key: 'numberorder',
                render: text => <span>{text}</span>,
            }, {
                title: 'Số lượng kiện',
                dataIndex: 'numberpackage',
                key: 'numberpackage',
                render: text => <span>{text}</span>,
            },
            {
                title: 'Tổng cân nặng',
                dataIndex: 'totalweight',
                key: 'totalweight',
                render: text => <span>{text}</span>,

            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: 'a-text--right',
                render: text => <a className="a-text--nowrap a-text--blue ">{text}</a>,
            }
        ];
        return (
            <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed mt-0">
                <Table className={"a-table--antd"} columns={columns} dataSource={data}
                       pagination={false}
                       expandedRowRender={expandedRowRender}
                       expandIcon={CustomExpandIcon}
                       expandIconAsCell

                />
                <div className="a-pagination a-pagination--fixed">
                    <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                    <div className="a-pagination--select-number-item">
                        <span className="text-show">Hiển thị</span>
                        <Select defaultValue="10" className="a-select--select-number-show">
                            <Option value="10">10</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListRequestDeliveryNote;