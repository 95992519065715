export const GET_RETURNS = {
    REQUEST: 'DELIVERY_NOTES.RETURN.GET_RETURNS.REQUEST',
    SUCCESS: 'DELIVERY_NOTES.RETURN.GET_RETURNS.SUCCESS',
    FAILED: 'DELIVERY_NOTES.RETURN.GET_RETURNS.FAILED',
};

export const CLEAR_STATE = 'DELIVERY_NOTES.RETURN.LIST.CLEAR_STATE';

export const COLUMNS = {
    CODE: 'code',
    RETURN_FEE: 'return_fee',
    STATUS: 'status',
    CREATE_AT: 'created_at',
    EXPORTED_AT: 'exported_at',
    ID_WAREHOUSE: 'id_warehouse',
    ID_CREATOR: 'id_creator',
    ID_EXPORTER: 'id_exporter'
};
