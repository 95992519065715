import React, { Fragment } from 'react';
import lodash from 'lodash';
import { createSelector } from 'reselect';

import * as bagDetailSelectors from './../BagDetail/selectors';
import { BAG_SHIPPING_TYPE } from './../BagShippingType/constants';
import { BAG_COLUMN } from './../ListBag/constants';
import { dateFormatter } from './../../Common/services/format';
import { translateValidationErrors } from './../../Common/services/helps';
import * as warehouseSelectors from './../../Warehouse/selectors';
import { Link } from './../../../system/routing';
import { t, trans } from './../../../system/i18n';
import get from 'lodash/get';

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'bag.bagPacking' + (param ? '.' + param : ''), defaultValue);

export const getBagData = createSelector(
    state => getState(state, "bagData", {}),
    state => getState(state, "bagPackingData.properties", []),
    state => getState(state, "bagPackingData.services", []),
    state => warehouseSelectors.getState(state, 'suggest'),
    state => getState(state, "bagPackingData.customer", []),
    state => lodash.get(state, "bagSize.suggestBagSizes.bag_sizes", []),
    state => bagDetailSelectors.getState(state, 'bag.shipping_partner'),
    state => bagDetailSelectors.getState(state, 'bag.shipping_user'),
    state => bagDetailSelectors.getState(state, 'bag.agency'),
    (bagData, properties, services, warehouses, customer, bagSizes, shippingPartner, shippingUser, agency) => {
        const warehouseInput = lodash.find(warehouses, {id: lodash.get(bagData, 'id_warehouse_input', 0)});
        const warehouseDestination = lodash.find(warehouses, {id: lodash.get(bagData, 'id_warehouse_destination', 0)});
        const warehouseCurrent = lodash.find(warehouses, {id: lodash.get(bagData, 'id_warehouse_current', 0)});
        const idShippingUser = lodash.get(bagData, 'id_shipping_user', 0);
        const idShippingPartner = lodash.get(bagData, 'id_shipping_partner', 0);
        let shipper;
        let bagWarnings = null;
        if (bagData.message_warnings && bagData.message_warnings.length) {
            bagWarnings = bagData.message_warnings.map(messageWarning => {
                let warning = null;

                if (messageWarning.weight_diff) {
                    warning = trans('bag:message.weight_diff', {
                        weight_diff: messageWarning.weight_diff
                    })
                } else if (messageWarning.attach_package_to_bag_than_amount_order) {
                    warning = trans('bag:message.attach_package_to_bag_than_amount_order');
                } else {
                    warning = trans(`bag:message.${lodash.isObject(messageWarning) ? Object.keys(messageWarning) : messageWarning}`);
                }

                return (
                    <div key={lodash.isObject(messageWarning) ? Object.keys(messageWarning) : messageWarning}>
                        {warning}
                    </div>
                );
            });
        }
        const bagSizesActive = bagSizes.filter(item => item.bag_size.active);
        const bagSize = lodash.find(bagSizesActive, bagSize => lodash.get(bagSize, "bag_size.id", 0) === bagData.id_bag_size);
        let subjectShipping;

        if (idShippingUser) {
            subjectShipping = BAG_SHIPPING_TYPE.USER;
            shipper = lodash.get(shippingUser, 'full_name') || lodash.get(shippingUser, 'name');
        } else if (idShippingPartner) {
            subjectShipping = BAG_SHIPPING_TYPE.PARTNER;
            shipper = lodash.get(shippingPartner, 'full_name') || lodash.get(shippingPartner, 'name');
        }

        return {
            ...bagData,
            code: <Link to="bags.detail" params={{id: bagData.code}}>{bagData.code}</Link>,
            subject_shipping: subjectShipping,
            agency:get(bagData, "agency") ? get(bagData, "agency") : agency,
            id_customer: lodash.get(customer, 'id'),
            [BAG_COLUMN.BAG_WEIGHT + "_display"]: lodash.get(bagData, 'weight_real', '0') + " (kg)",
            [BAG_COLUMN.PROPERTIES]: properties && properties.length !== 0 ? properties.map(e => e.name).join(' , ') : "--",
            [BAG_COLUMN.SERVICES]: services && services.length !== 0 ? services.map(e => e.name).join(' , ') : "--",
            [BAG_COLUMN.CREATE_TIME]: dateFormatter.dateTime(bagData.created_at),
            [BAG_COLUMN.ID_WAREHOUSE_INPUT]: warehouseInput ? warehouseInput.code : "--",
            [BAG_COLUMN.ID_WAREHOUSE_DESTINATION]: warehouseDestination ? warehouseDestination.id : 0,
            [BAG_COLUMN.CODE_WAREHOUSE_DESTINATION]: warehouseDestination ? warehouseDestination.code : "--",
            [BAG_COLUMN.ID_WAREHOUSE_CURRENT]: warehouseCurrent ? warehouseCurrent.code : "--",
            [BAG_COLUMN.SHIP_NAME]: shipper,
            [BAG_COLUMN.BAG_SIZE]: bagSize,
            [BAG_COLUMN.SCAN_TIME]: dateFormatter.dateTime(bagData.time_scan),
            [BAG_COLUMN.TOTAL_PACKAGES]: lodash.get(bagData, 'num_package', '--'),
            [BAG_COLUMN.TOTAL_PACKAGES_WEIGHT]: lodash.get(bagData, 'weight_package', '0') + " (kg)",
            [BAG_COLUMN.DIMENSION]: lodash.round(lodash.get(bagData, 'volume', '0') * 1000000, 3) + " (cm3)",
            message_warnings: bagWarnings,
            customer
        }
    }
);

export const getPackageData = createSelector(
    state => getState(state, "listBagPacking", []),
    bagPackingDatas => transformListPackageBag(bagPackingDatas)
);

/**
 *
 * @param bagPackingDatas
 * @returns {Array}
 */
export function transformListPackageBag(bagPackingDatas) {
    return bagPackingDatas ? bagPackingDatas.map((packageData) => {
        if (!Array.isArray(packageData.code_errors)) {
            packageData.code_errors = packageData.bag_package_diff ? packageData.bag_package_diff : [];
        }
        packageData.code_order = packageData.code_order ? packageData.code_order : lodash.get(packageData, 'order.code');
        packageData.order_url = packageData.order_url ? packageData.order_url : lodash.get(packageData, 'order.m1_url');

        packageData.time_scan_display = dateFormatter.dateTime(packageData.time_scan);
        packageData.code_package_text =
            packageData.code_errors.includes('PACKAGE_NOT_FOUND') ? packageData.code_package :
                <Link isNewTab
                      to="packages.detail"
                      params={{id: packageData.code_package ? packageData.code_package : packageData.code}}>{packageData.code_package ? packageData.code_package : packageData.code}</Link>;

        packageData.volume_package_text = (packageData.volume_package ? lodash.round(lodash.get(packageData, 'volume_package', '0') * 1000000, 3) : lodash.round(lodash.get(packageData, 'volume', '0') * 1000000, 3)) + " (cm3)";
        packageData.weight_package_text = (packageData.weight_package ? lodash.get(packageData, 'weight_package', '0') : lodash.get(packageData, 'weight_net', '0')) + " (kg)";
        packageData.message_error = null;

        const filteredCodeErrors = packageData.code_errors.filter(codeError => codeError);

        packageData.class_error = lodash.get(packageData, 'notes.length') || lodash.get(packageData, 'notices.text.length') || filteredCodeErrors.length ? (!lodash.isNil(packageData.success) && packageData.success === false ? 'error' : 'warning') : '';
        packageData.notes = lodash.get(packageData, 'notes');

        if (filteredCodeErrors.length) {
            packageData.message_error = (
                <span>
                    {packageData.code_errors.map((codeError, index) => {
                        let message_error = '';
                        switch (codeError.code) {
                            case 'ALREADY_OTHER_BAG': {
                                const bagCodes = lodash.get(codeError, 'bags', []).map((bag, index) => {
                                    return (
                                        <Fragment key={bag}>
                                            <Link
                                                isNewTab
                                                params={{id: bag}}
                                                to="bags.packing"
                                            >
                                                {bag}
                                                {index !== codeError.bags.length - 1 && (
                                                    <span>, </span>
                                                )}
                                            </Link>
                                        </Fragment>
                                    )
                                });
                                message_error = trans('bag:packing.ALREADY_OTHER_BAG', {
                                    bag_code: bagCodes
                                });
                                break;
                            }
                            case 'PACKAGE_ALREADY_OTHER_BAG': {
                                message_error = trans('bag:packing.ALREADY_OTHER_BAG', {
                                    bag_code: (
                                        <Link
                                            isNewTab
                                            params={{id: codeError.bag}}
                                            to="bags.packing"
                                        >
                                            {codeError.bag}
                                        </Link>
                                    )
                                });
                                break;
                            }
                            case 'SERVICE_NOT_CONSISTENT':
                            case 'PROPERTY_NOT_CONSISTENT': {
                                let key = (codeError.code === 'SERVICE_NOT_CONSISTENT') ? 'service' : 'property';
                                let bag_diff = lodash.get(codeError, 'bag_diff', []);
                                let package_diff = lodash.get(codeError, 'package_diff', []);
                                message_error = trans(`bag:packing.${codeError.code}`);
                                if (bag_diff.length) {
                                    message_error = message_error + ': ' + trans(`bag:packing.${key}_bag_diff`, {
                                        bag_diff: bag_diff.join(', ')
                                    });
                                }
                                if (package_diff.length) {
                                    message_error = message_error + (bag_diff.length ? ' - ' : ': ') + trans(`bag:packing.${key}_package_diff`, {
                                        package_diff: package_diff.join(', ')
                                    });
                                }
                                break;
                            }
                            case 'more_one_package': {
                                const packages = lodash.get(codeError, "packages", [])
                                message_error = t('scan:error.more_one_package', {packages: packages.join(", "), count: packages.length})
                                break;
                            }
                            default:
                                message_error = codeError.code ? t(`bag:packing.${codeError.code}`) : t(`notice:${codeError}`);
                                break;
                        }

                        return (
                            <Fragment key={index}>
                                {message_error}
                                {index !== packageData.code_errors.length - 1 && ', '}
                            </Fragment>
                        );
                    })}
                </span>
            );
        }

        return packageData;
    }) : [];
}

export const getErrors = (error) => {
    return lodash.mapValues(lodash.get(error, 'id_customer.data', {}), (rules, param) => {
        let attribute = t('bag:label.' + param);
        let messages = translateValidationErrors(attribute, rules);
        return lodash.first(lodash.values(messages));
    });
};
