import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import Icon from './../../../../components/icons/Icon';

class PackageServices extends React.PureComponent {
    render() {
        let {t} = this.props;
        return (
            <React.Fragment>
                <h2 className="title">{t('package:detail.service_use')}</h2>
                <div className="service--body ">
                    <div className="service--block">
                        <div className="service--block--body">
                            <ul>
                                {this.props.services.length > 0 ?
                                    <li className="_service_list">
                                        {this.props.services.map(service => (
                                            <span className={clsx('mb-0', {
                                                '_consign-service a-text--green': service.type === 'consign',
                                                '_normal-service': service.type === 'normal'
                                            })} key={service.id}>
                                                <Icon className="mr-2" type={service.icon} />
                                                {service.name}
                                            </span>
                                        ))}
                                    </li>
                                    :
                                    <li className="_service_list">
                                        <span className="_service-empty">
                                            {t("package:message.no_service")}
                                        </span>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PackageServices.defaultProps = {
    services: [],
};

PackageServices.propTypes = {
    services: PropTypes.array,
};

export default withTranslation()(PackageServices);
