import React, { useEffect } from 'react';

const PrintIframe = ({ src, onDidMount }) => {
    useEffect(() => {
        onDidMount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{height: '27.7cm'}}>
            <iframe allowFullScreen className="width-100-pc height-100" src={src} title="print-iframe" />
        </div>
    );
};

export default PrintIframe;
