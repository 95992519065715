import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../ListShippingPartner/actions';
import {fetchShippingUserSuggest} from '../../ShippingUser/List/actions';
import * as selectors from '../ListShippingPartner/selectors';
import SelectSortPartnerUser from './components';
import lodash from "lodash";

const mapStateToProps = (state, props) => ({
    ...props,
    bagShippingPartners: selectors.getListShippingPartnerSuggest(state),
    users: state.shippingUser.list.usersSuggest,
    loading: lodash.get(props, 'loading', false) || selectors.getState(state, 'listShippingPartner.loadingSuggestList', false) || state.shippingUser.list.loadingUserSuggestList
});

const mapDispatchToProps = (dispatch) => ({
    fetchBagShippingPartner: () => dispatch(actions.fetchShippingPartnersSuggest()),
    fetchShippingUserSuggest: () => dispatch(fetchShippingUserSuggest())
});

class SelectSortPartnerUserContainer extends React.Component {
    componentDidMount() {
        this.props.fetchBagShippingPartner();

        if (!this.props.users.length) {
            this.props.fetchShippingUserSuggest();
        }
    }

    render() {
        return <SelectSortPartnerUser {...this.props}/>
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectSortPartnerUserContainer);

Container.defaultProps = {
    id_shipping_user: 0,
    id_shipping_partner: 0,
    disabled: false,
    onChange: () => {
    },
};

Container.propTypes = {
    id_shipping_user: PropTypes.number,
    id_shipping_partner: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Container;
