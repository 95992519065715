/* eslint-disable */
import React, {Component} from 'react';
import {Row, Button, Layout, Input,Icon,Select} from 'antd';

const {Sider} = Layout;
const Option = Select.Option;

class SiderScanExport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            warehouse: "CNGZ"
        }
    };

    onChangeWarehouse = (warehouse) => {
        this.setState({warehouse});
    }

    render() {
        const {warehouse} = this.state;
        const {scroll} = this.props;
        return (
            <Sider className={`a-sider-sub a-sider-sub--radius ${scroll ? 'scroll' : ''}`}>
                <div className="a-sider-sub__block mt-5">
                    <h4 className="a-sider-sub__block__title">Kho</h4>
                    <Row gutter={8} className={"a-form--group-button--warehouse"}>
                        <Button type="primary"
                                onClick={e => this.onChangeWarehouse("CNGZ")}
                                className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNGZ" ? 'active' : ''} `}>CNGZ</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNPX" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNPX")}>CNPX</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "VNHNX" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("VNHNX")}>VNHNX</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "VNSG" ? 'active' : ''}`}
                            onClick={e => this.onChangeWarehouse("VNSG")}>VNSG</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''}`}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                        <Button
                            className={`a-btn a-btn--default a-btn--warehouse ${warehouse === "CNBK" ? 'active' : ''} `}
                            onClick={e => this.onChangeWarehouse("CNBK")}>CNBK</Button>
                        <Select defaultValue="CNPX" className='a-select a-select--warehouse'>
                            <Option value="CNPX">CNPX</Option>
                            <Option value="VNHNX">VNHNX</Option>
                            <Option value="CNBK">CNBK</Option>
                            <Option value="VNSG">VNSG</Option>
                        </Select>
                    </Row>
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">Đối tượng </h4>
                    <Button
                        className={`a-btn a-btn--w-100 a-btn--default`}>Khách hàng</Button>
                    <Button
                        className={`a-btn a-btn--w-100 a-btn--primary mt-5`}>Nhân viên vận chuyển </Button>
                    <Button
                        className={`a-btn a-btn--w-100 a-btn--default mt-5`}>Đối tác vận chuyển</Button>
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">Quét mã</h4>
                    <div className="a-form--loading loading-input">
                        <Input className="a-input--scan-code a-btn--disable"/>
                        <Icon type="loading-3-quarters" className="icon-loading" />
                    </div>
                </div>
            </Sider>
        )
    }
}

export default SiderScanExport