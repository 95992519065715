import {connect} from 'react-redux';
import PrintPackageStamp from './components/';
import {fetchPrintPackage} from './actions';
import lodash from 'lodash';
import * as actions from '../PackageDetail/actions';

const mapStateToProps = (state) => {
    return {
        packageData: {
            ...lodash.get(state, 'package.LinkStampPackage.packagePrint', {}),
            permissions: lodash.get(state, 'package.packageDetail.package.permissions', {})
        },
    }
};

const mapDispatchToProps = (dispatch) => ({
    getDataPrintPackage: (filter) => {
        dispatch(fetchPrintPackage(filter));
        
    },
    fetchPackage: (packageId) => dispatch(actions.fetchPackageDetail(packageId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintPackageStamp);
