import lodash from 'lodash';
import { combineReducers } from 'redux';

import * as ACTION from './constants';
import { CREATE_ALONE_PACKAGE } from './../UpdateAlonePackage/constants';
import { setCollectionItem } from './../../Common/services/helps';

const packages = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case ACTION.UPDATE_PACKAGE_PARAM.REQUEST: {
            return setCollectionItem(state, payload.id, {
                loading: true,
                error: null
            });
        }
        case ACTION.UPDATE_PACKAGE_PARAM.SUCCESS: {
            return setCollectionItem(state, action.request.id, {
                loading: false
            });
        }
        case CREATE_ALONE_PACKAGE.FAILED:
        case ACTION.UPDATE_PACKAGE_PARAM.FAILED: {
            return setCollectionItem(state, action.request.id, {
                loading: false,
                error: payload
            });
        }
        case ACTION.RESET_ERROR: {
            return setCollectionItem(state, payload.id, packageData => {
                let error = packageData.error;

                if (!error) {
                    return packageData;
                }

                return {
                    ...packageData,
                    error: {
                        ...error,
                        data: lodash.omit(error.data, payload.params)
                    }
                };
            });
        }
        case ACTION.CLEAR_STATE: {
            return state.filter(packageData => packageData.id !== payload.id);
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    packages
});
