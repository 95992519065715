/* eslint-disable */
import React from 'react';
import {Button, Row} from "antd";
import LogSystem from './LogSystem';

class BagBlockRight extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
            iconCollapse: true,
            lbag_today: 0,
            hheader: 104,
            halfwindowinfo: 0
        };
    }

    componentWillUnmount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let {scroll} = this.state;
        if (window.pageYOffset > 104) {
            if (!scroll) {
                this.setState({scroll: true, lbag_today: this.state.lbag_today + this.state.hheader});
            }
        } else {
            if (scroll) {
                this.setState({scroll: false, lbag_today: this.state.lbag_today - this.state.hheader});
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        let lbag_today, title_list_bag, bag_today;
        title_list_bag = this.refs.title_list_bag.clientHeight;
        bag_today = this.refs.bag_today.clientHeight;
        lbag_today = bag_today  - title_list_bag;
        this.setState({
            lbag_today: lbag_today,
        })
    }

    onClickIconCollapse = (iconCollapse) => {
        let warehousescroll;
        warehousescroll = window.innerHeight / 2 - 130;
        if (iconCollapse) {
            this.setState({iconCollapse, lbag_today: this.state.lbag_today + warehousescroll});
        } else {
            this.setState({iconCollapse, lbag_today: this.state.lbag_today - warehousescroll});
        }
    }

    render() {
        let {scroll, iconCollapse} = this.state;
        return (
            <div className={"info-right-detail-bag " + (scroll ? 'scroll' : '')}>
                <div className={`block bag-today ${iconCollapse ? 'log-system-collapse' : ''}`}
                     ref="bag_today">
                    <div className="list-bag-today">
                        <span className="title" ref="title_list_bag">Danh sách bao cùng ngày</span>
                        <ul className="list-bag-today--list">
                            <li className="list-bag-today--item active">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item ">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648</span>
                            </li>
                            <li className="list-bag-today--item">
                                <span className="list-bag-today--link">H12312 - 12931823172648hgj</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <LogSystem
                    onClickIconCollapse={this.onClickIconCollapse}
                    iconCollapse={this.state.iconCollapse}
                />
            </div>
        )
    }
}

export default BagBlockRight;