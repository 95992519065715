import {processApiRequest} from "../../Common/saga";
import * as ACTION from '../constants';
import apiService from "../apiService";
import {notification} from "antd";
import {t} from "../../../system/i18n";

export function* getDetailWarehouseStaff(action) {
    yield processApiRequest(ACTION.FETCH_DETAIL_WAREHOUSE_STAFF, () => apiService.detailWarehouseStaff(action.payload.id), action.payload);
}

export function* getDetailWarehouseStaffFailed() {
    yield notification['error']({
        message: t('common:message.not_exist_warehouse_staff')
    });
}