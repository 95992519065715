export const FETCH_SETTING = {
    REQUEST: 'SETTING.FETCH_SETTING.REQUEST',
    SUCCESS: 'SETTING.FETCH_SETTING.SUCCESS',
    FAILED: 'SETTING.FETCH_SETTING.FAILED',
};
export const FETCH_DETAIL_SETTING = {
    REQUEST: 'SETTING.FETCH_DETAIL_SETTING.REQUEST',
    SUCCESS: 'SETTING.FETCH_DETAIL_SETTING.SUCCESS',
    FAILED: 'SETTING.FETCH_DETAIL_SETTING.FAILED',
};
export const UPDATE_SETTING = {
    REQUEST: 'SETTING.UPDATE_SETTING.REQUEST',
    SUCCESS: 'SETTING.UPDATE_SETTING.SUCCESS',
    FAILED: 'SETTING.UPDATE_SETTING.FAILED',
};
export const DEFAULT_STATE_FORM_GENERAL = {
    partner_organization_address: undefined,
    partner_organization_name: undefined,
    partner_organization_phone: undefined,
    partner_logo: undefined
};
export const SET_DETAIL = 'SETTING.SET_DETAIL';
