import { processApiRequest } from './../../Common/saga'
import * as ACTION from './constants'
import apiService from './apiService'
import lodash, { get, uniqBy } from 'lodash'
import { notification } from 'antd'
import { t, trans } from '../../../system/i18n'
import React from 'react'

export function* packingBagLastmile(action) {
    yield processApiRequest(ACTION.PACKING_PACKAGE_LASTMILE, () => apiService.packingBagLastmile(action.payload.idBag, action.payload), action.payload)
}

export function* packingBagLastmileDetail(action) {

    yield processApiRequest(ACTION.PACKING_PACKAGE_LASTMILE_DETAIL, () => apiService.getBagLastmileDetail(action.payload.id), action.payload)
}

export function* packingBagLastmileDetailPackingSuccess(action) {

    yield processApiRequest(ACTION.PACKING_PACKAGE_LASTMILE_DETAIL, () => apiService.getBagLastmileDetail(action.request.idBag), action.payload)
}


export function* packingBagLastmileFailed(action) {
    const error = lodash.get(action, 'payload.data')
    const code = lodash.get(action, 'payload.code')

    if (code === 'INPUT_INVALID') {
        Object.entries(error).forEach(([key, value]) => {
            if (Object.keys(value).length > 1) {
                return notification.error({
                    message: trans(`bag:lastmile.message.${key}.${value.code}`, {
                        bag_code: <b>{value.bag_lastmile}</b>,
                    }),
                })
            } else {
                return notification.error({
                    message: t(`bag:lastmile.message.${key}.${Object.keys(value)[0]}`),
                })
            }
        })
    } else {
        return yield notification.error({ message: t('bag:lastmile.message.packing_scan_fail') })
    }
}

export function* packingBagLastmileSuccess(action) {
    const data = lodash.get(action, 'payload')
    
    return yield  processDataSucess(data)
}

function processDataSucess(data) {

    const packageData = {
        id: get(data, 'package.id'),
        warning_err: get(data, 'notices.text'),
    }

    const listPackage = JSON.parse(localStorage.getItem('packing_lasmile_packages')) || []

    listPackage.push(packageData)

    listPackage.forEach(item => {
        if(item.id === get(data, 'package.id')){
            item.warning_err = get(data, 'notices.text')
        }
    })

    localStorage.setItem('packing_lasmile_packages',JSON.stringify(uniqBy(listPackage, 'id')))

    return uniqBy(listPackage, 'id')
}
