/* eslint-disable */
import React, {Component} from 'react';
import {Layout, Button, Collapse, Input, Form, AutoComplete} from 'antd';
import SiderPackage from '../Sidebar/modules/SiderPackage';

const {Content} = Layout;
const Panel = Collapse.Panel;

/*Khai báo component heading*/
const heading = (props) => (
    <h4 className="a-collapse--package__title">BT-CNGZ <span>/ 012837372845</span></h4>
);

/*End*/

class CratePackage extends Component {
    render() {
        return (
            <Layout>
                <SiderPackage/>
                <Content className="a-content a-package">
                    <div className="a-content__top">
                        <div className="a-content__top__title">
                            <h3>Mã Vận Đơn - <a href="#" className="">216321995</a></h3>
                        </div>
                        <Button type="primary" htmlType="submit"
                                className="a-btn a-btn--primary a-btn--create-package-same-bill-code a-btn--disable">Tạo
                            kiện cùng vận đơn</Button>
                    </div>
                    <div className="a-content__body a-content__block-scroll a-package__body a-package__body a-package__body--scroll">
                        <Collapse accordion className="a-collapse--package" bordered={false} defaultActiveKey={['1']}>
                            <div className="a-collapse--package__block-absolute-heading">
                                <AutoComplete
                                    placeholder={'Mã khớp đơn'}
                                    className="a-input a-input--matching-code"
                                />
                                <p className="info-matching_code a-hidden">CNGZ <span>/ 213219950302</span></p>
                            </div>
                            <Panel header={heading()} key="1" className="a-collapse--package__item">
                                <div className="a-collapse--package__body is_empty">
                                    <Button type="primary" htmlType="button"
                                            className="a-btn a-btn--primary a-btn--create-bill-code">Nhập thông tin</Button>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Content>
            </Layout>
        );
    }
}

CratePackage = Form.create({})(CratePackage);

export default CratePackage;
