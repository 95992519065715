import React, { useState }  from 'react';
import { Icon, Modal, notification, Upload } from 'antd';
import { get, last, uniqueId } from 'lodash';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { useDeepCompareEffect } from './../.././../../hooks';

const PictureWall = ({ damagedImages, packageId, sorted, t }) => {
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    const maximumImages = 5;

    useDeepCompareEffect(() => {
        const fileList = [];
        let sortedDamagedImages = damagedImages.slice();

        if (sorted) {
            sortedDamagedImages.reverse();
        }

        for (const damagedImage of sortedDamagedImages) {
            fileList.push({
                name: damagedImage,
                status: 'done',
                uid: uniqueId(),
                url: damagedImage
            });
        }

        setFileList(fileList);
    }, [damagedImages]);

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const updateFileList = fileList => {
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new

        if (sorted) {
            fileList = fileList.slice().sort((file1, file2) => {
                if (file1.status === 'uploading' && file2.status !== 'uploading') {
                    return -1;
                }
    
                if (file1.status !== 'uploading' && file2.status === 'uploading') {
                    return 1;
                }
    
                return 0;
            });
        }

        setFileList(fileList);
    };

    const removeFile = async removedFile => {
        const index = fileList.findIndex(file => file.uid === removedFile.uid);

        if (index === -1) {
            return;
        }

        try {
            if (removedFile.url) {
                const formData = new FormData();
                formData.append('removed_files[]', removedFile.url);

                await apiService.uploadDamagedImages(packageId, formData);

                notification.success({
                    message: t('delete_image.success')
                });
            } else {
            }

            updateFileList([
                ...fileList.slice(0, index),
                ...fileList.slice(index + 1)
            ]);
        } catch (error) {
            let message;

            if (get(error, 'response.status') === 401) {
                message = t('auth:message.token_expired');
            } else {
                message = t('delete_image.failed');
            }

            notification.error({
                message
            });
        }
    };

    const handleChange = data => {
        let newFileList = data.fileList.slice();
        if (data.file.status === 'uploading' && data.fileList.length - 1 >= maximumImages) {
            const index = newFileList.findIndex(file => file.uid === data.file.uid);

            if (index !== -1) {
                newFileList[index].status = 'error';
            }
        }

        if (data.file.status === 'done') {
            const index = newFileList.findIndex(file => file.uid === data.file.uid);

            if (index !== -1) {
                newFileList[index].url = get(data, 'file.response.url');
            }
        }

        if (data.file.status !== 'removed') {
            updateFileList(newFileList);
        }
    };

    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleRemove = file => {
        if (!file.url) {
            removeFile(file);
        } else {
            Modal.confirm({
                cancelText: t('btn.cancel'),
                content: t('delete_image.confirm'),
                icon: null,
                okText: t('btn.ok'),
                onOk: removeFile.bind(this, file)
            });
        }
    };

    return (
        <div className="clearfix">
            <Upload
                accept="image/*"
                className="width-100-pc"
                fileList={fileList}
                customRequest={async ({ onSuccess, onError, file }) => {
                    try {
                        const formData = new FormData();
                        formData.append('files[]', file);

                        if (fileList.length >= maximumImages) {
                            onError();
                        } else {
                            const { data } = await apiService.uploadDamagedImages(packageId, formData);

                            onSuccess({
                                ...data,
                                url: last(get(data, 'package_detail.damaged_images'))
                            });
                        }
                    } catch (error) {
                        if (get(error, 'response.status') === 401) {
                            notification.error({
                                message: t('auth:message.token_expired')
                            });
                        } else {
                            onError(error);
                        }
                    }
                }}
                listType="picture-card"
                multiple
                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={handleRemove}
            >
            {fileList.length < maximumImages && uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default withTranslation()(PictureWall);
