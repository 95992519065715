import React, {Fragment} from 'react'
import {withTranslation} from "react-i18next";
import {Button, Modal,} from 'antd'
import PropTypes from 'prop-types';
import {trans} from "../../../system/i18n";

const {confirm} = Modal;


class RemoveUserStaff extends React.Component {
    onOk (id) {
        this.props.onRemoveUserStaff(id)
    }

    onRemoveStaff () {
        const {user, t} = this.props;
        const {id} = user;
        confirm({
            title: t('label.warning'),
            content: trans('config:message.remove_staff', {
                staff: <b>{user.name}</b>
            }),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            okButtonProps: {
                loading: this.props.loading,
                className: "_btn-ok-delete"
            },
            cancelButtonProps: {
                loading: this.props.loading,
                className: "_btn-cancel-delete"
            },
            onOk: this.onOk.bind(this, id),
            onCancel () {
            },
        });
    }

    render () {
        const {t} = this.props;
        return <Fragment>
            <Button onClick={this.onRemoveStaff.bind(this)}
                    className={"a-btn--button-link mr-auto a-text--blue a-btn--bg-transparent _btn-delete"}>{t("common:btn.delete")}</Button>
        </Fragment>
    }
}

RemoveUserStaff.defaultProps = {
    user: {},
    loading: false,
    onRemoveUserStaff: () => {
    },

};

RemoveUserStaff.propTypes = {
    loading: PropTypes.bool,
    user: PropTypes.object,
    onRemoveUserStaff: PropTypes.func

};

export default withTranslation()(RemoveUserStaff)
