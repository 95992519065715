/* eslint-disable */
import React, { Component } from 'react';
import { Button, Form, Layout, notification, Row } from 'antd';
import clsx from 'clsx';
import { get, isNil, map, omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE } from './../../../constants';
import { getErrors } from './../../../selectors';
import apiServiceShippingPartner from './../../../../ShippingPartner/DetailShippingPartner/apiServices';
import { url } from './../../../../../system/routing';

import DeliveryNoteBagList from './DeliveryNoteBagList';
import ShippingPartnerFormInput from './ShippingPartnerFormInput';
import PromptLeavePage, { checkLeavePage } from './PromptLeavePage';

const paramConfirmInputs = ['partner_tracking_number', 'car_number', 'note', 'bags', 'id_packages'];
const { Content } = Layout;

class FormTransportWarehouse extends Component {
    state = {
        ...DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE,
        shippingPartnerDetail: {},
        errors: {},
        submit: false,
        bags: [],
        packages: [],
        id_packages: [],
        id: undefined
    };

    static getDerivedStateFromProps(props, state) {
        const { deliveryNote, input } = props;
        let nextState = {
            ...state
        };
        const shippingPartnerId = get(input, 'id_shipping_partner');
        const id = get(deliveryNote, 'delivery_note_transport_warehouse.id');

        if (state.id_shipping_partner !== shippingPartnerId) {
            nextState.id_shipping_partner = shippingPartnerId;
            nextState.id_warehouse = get(input, 'id_warehouse', 0);
        }

        if (state.id !== id) {
            nextState = !id ? { ...DEFAULT_STATE_FORM_TRANSPORT_WAREHOUSE } : {
                id,
                note: get(deliveryNote, 'delivery_note_transport_warehouse.note'),
                partner_tracking_number: get(deliveryNote, 'delivery_note_transport_warehouse.partner_tracking_number'),
                car_number: get(deliveryNote, 'car_number')
            };
        }

        nextState.bags = props.bags;
        nextState.packages = props.packages;

        return nextState;
    }

    getDetailShippingPartner = async shippingPartnerId => {
        const { t } = this.props;

        this.setState({
            loading: true
        });

        try {
            const response = await apiServiceShippingPartner.fetchDetailShippingPartner(shippingPartnerId);
            const shippingPartnerDetail = get(response, 'data.shipping_partner', {});

            this.setState({
                shippingPartnerDetail
            });
        } catch (error) {
            notification.error({
                message: t('shipping_partner:message.error_get_detail_shipping_partner')
            });
        }

        this.setState({
            loading: false
        });
    };

    checkOnbeforeunload = () => {
        const { submit } = this.state;
        const { deliveryNote, t } = this.props;

        if (checkLeavePage(deliveryNote, paramConfirmInputs, submit, this.state)) {
            return t('message.confirm_when_leave');
        } else {
            return null;
        }
    };

    redirectRenew = () => {
        const { input } = this.props;

        url.redirectTo('delivery-notes.transport-warehouse.create', undefined, {
            id_warehouse: get(input, 'id_warehouse', 0)
        });
    };

    handleCreateNew = () => {
        const { clearBarcodes } = this.props;

        clearBarcodes();

        this.redirectRenew();
    };

    handleSubmit = () => {
        const { deliveryNote } = this.props;

        this.setState({
            ...this.state,
            submit: true
        });

        const data = {
            ...this.state
        };

        data.outside_bags = [...map(data.bags,'barcode_scanning'), ...map(data.packages, 'package.code')];
        data.bags = map(data.bags, 'bag.id');
        data.id_packages = map(data.packages, 'package.id');
        data.id = get(deliveryNote, 'delivery_note_transport_warehouse.id');

        // if (get(deliveryNote, 'warehouse.is_custom_warehouse')) {
        //     data.bags = [];
        //     data.id_packages = [];
        // }

        this.updateOrCreateDeliveryNote(pick(data, [
            'id',
            'id_shipping_partner',
            'id_warehouse',
            'partner_tracking_number',
            'car_number',
            'note',
            'bags',
            'id_packages',
            'outside_bags'
        ]));
    };

    updateOrCreateDeliveryNote = async data => {
        const { t } = this.props;

        this.setState({
            loading: true
        });

        try {
            let response;

            if (data.id) {
                response = await apiService.update(data.id, data);

                notification.success({
                    message: t('delivery_note:transport_warehouse.update.success')
                });
            } else {
                response = await apiService.add(data);

                notification.success({
                    message: t('delivery_note:transport_warehouse.create.success')
                });
            }

            url.redirectTo('delivery-notes.transport-warehouse.detail', {
                id: get(response, 'data.delivery_note_transport_warehouse.id', 0)
            });
        } catch (error) {
            if (data.id) {
                notification.error({
                    message: t('delivery_note:transport_warehouse.update.failed')
                });
            } else {
                notification.error({
                    message: t('delivery_note:transport_warehouse.create.failed')
                });
            }

            this.setState({
                errors: getErrors(error.response.data)
            });
        }

        this.setState({
            loading: false
        });
    };

    resetError = (params) => {
        this.setState({
            errors: omit(this.state.errors, params)
        });
    };

    onUpdate = (params) => {
        if (params.packages) {
            delete params.packages;
            delete params.id_shipping_partner;
            delete params.id_warehouse;
        }

        this.setState({
            ...this.state,
            ...params,
            submit: false
        });
    };

    componentDidMount() {
        const shippingPartnerId = Number(get(this.props, 'input.id_shipping_partner', 0));

        if (shippingPartnerId) {
            this.getDetailShippingPartner(shippingPartnerId);
        }

        window.onbeforeunload = this.checkOnbeforeunload;
    }

    componentWillUnmount() {
        const { clearBarcodes } = this.props;

        window.onbeforeunload = null;
        clearBarcodes();
    }

    render() {
        const { errors, shippingPartnerDetail } = this.state;
        const { deliveryNote, input, scroll, t } = this.props;
        const loading = this.props.loading || this.state.loading;
        const shippingPartnerId = Number(get(input, 'id_shipping_partner', 0));
        const warehouseId = Number(get(input, 'id_warehouse', 0));
        const isShowBtnRenew = isNil(deliveryNote);
        const isShowBtnSave = isNil(deliveryNote) || (!isNil(deliveryNote) && get(deliveryNote, 'permissions.can_update', 0) === 1);

        return (
            <Content
                className={clsx('a-content is-action-fixed-bottom a-barcode-scan gflex gflex-direction-column', {
                    'scroll-is-sider': scroll
                })}
            >
                <PromptLeavePage
                    stateData={this.state}
                    deliveryNote={deliveryNote}
                    params={paramConfirmInputs}
                    submit={this.state.submit}
                />
                <Form onSubmit={this.handleSubmit} className="a-block amb-16">
                    <div className="block--primary a-content--bag">
                        <div className="title create-bag">
                            <div className="left">
                                <span className="a-text--medium a-text--fz-16">
                                    {t('transport_warehouse_delivery_note_info')}
                                </span>
                            </div>
                            {shippingPartnerId ? (
                                <div className="right">
                                    <p className="mb-0 a-text--fz-16 a-text--nowrap ">{t('partner')} -
                                        <span className="a-text--medium ml-1 _employee_code">
                                            {shippingPartnerDetail.name || '--'}
                                        </span>
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        <div className="body not-boxshadow ">
                            <Row gutter={{ lg: 24 }}>
                                <div className="a-block-info pb-0">
                                    <ShippingPartnerFormInput
                                        errors={errors}
                                        loading={loading}
                                        onUpdate={this.onUpdate}
                                        resetError={this.resetError}
                                        deliveryNote={get(deliveryNote, 'delivery_note_transport_warehouse')}
                                        shippingPartnerDetail={shippingPartnerDetail}
                                        warehouseId={warehouseId}
                                    />
                                </div>
                            </Row>
                        </div>
                    </div>
                </Form>
                <DeliveryNoteBagList
                    {...this.props}
                />
                {isShowBtnRenew || isShowBtnSave ?
                    <div className="a-content--action-fixed-bottom">
                        {isShowBtnRenew ? (
                            <Button className="a-btn--button-link mr-auto a-text--blue" onClick={this.handleCreateNew}>
                                {t('btn.create_new')}
                            </Button>
                        ) : null}
                        {isShowBtnSave ?
                            <Button
                                className="a-btn a-btn--primary a-btn--save-export _btn-submit"
                                htmlType="submit"
                                loading={loading}
                                size="large"
                                onClick={this.handleSubmit}
                            >
                                {t('btn.save')}
                            </Button>
                            : null}
                    </div>
                    : null}
            </Content>
        );
    }
}

FormTransportWarehouse.defaultProps = {
    loading: false,
    input: {},
    deliveryNote: {},
    fetchDetailShippingPartner: () => {
    }
};

FormTransportWarehouse.propTypes = {
    loading: PropTypes.bool,
    input: PropTypes.object
};

export default withTranslation()(FormTransportWarehouse);
