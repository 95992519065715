import React from 'react'
import { Typography } from 'antd'
import { get, slice } from 'lodash'

const { Paragraph, Text } = Typography

const ProductDetail = ({ products }) => {
    if (!products || !products.length) {
        return null
    }

    let productNameDisplay = ''

    if (products && products.length) {
        productNameDisplay = products.slice(0, 2).map(product => (
            <Paragraph
                className="mb-0"
                key={product.id}>
                {product.manifest_translated_name || product.translated_name || product.original_name}
            </Paragraph>
        ))

        if (products.length > 2) {
            productNameDisplay.push(
                <Paragraph
                    className="mb-0"
                    key="...">
                    ...
                </Paragraph>
            )
        }
    }

    const variantProterties = slice(get(products, '[0].variant_properties') || [], 0, 3)

    return (
        <div className="product-detail">
            <div className="row tile">
                <Text
                    strong
                    className="a-text--fz-13 a-text--bold">
                    Product Detail
                </Text>
            </div>

            <div className="row">
                <div className="tile">{productNameDisplay}</div>
            </div>
        </div>
    )
}

export default ProductDetail
