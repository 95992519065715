import { Button, notification } from 'antd'
import React, { useState } from 'react'
import { t } from '../../../../../system/i18n'
import { api } from '../../../../../system/api'
import apiService from '../../../apiService'
import { convertBlobDataToExcel } from '../../../../../system/support/helpers'

const DownloadDeliveryNode = ({ filter }) => {
    const [loading, setLoading] = useState(false)

    const handleDownload = () => {
        setLoading(true)
        apiService
            .exportDeliveryNote(filter, { type: 'CUSTOMER' })
            .then(response => {
                notification.success({
                    message: 'Tải xuống danh sách thành công'
                });
                convertBlobDataToExcel(response.data, 'DANH SÁCH PHIẾU XUẤT KH')
            })
            .catch(() => {
                notification.success({
                    message: 'Tải xuống danh sách thất bại'
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Button
            type="primary"
            loading={loading}
            onClick={handleDownload}
            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular __download-list mr-2">
            {t('delivery_note:btn.download_list')}
        </Button>
    )
}

export default DownloadDeliveryNode
