import { Button, Icon, Layout, Skeleton } from 'antd'
import { forEach, get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { t } from '../../../../system/i18n'
import { renderNotices } from '../../../Common/components/Attentions'
import Background from './../../../../resources/images/bar-code.png'
import DeletePackage from './DeletePackage'
import PrintStamp from './PrintStamp'

const mapStateToProps = (state, props) => {
    return {
        packageDataLocal: get(state, 'bag.bagPackingLastmileAction.packingBagLastmileLocal', []),
    }
}

const mapDispatchToProps = dispatch => ({})

const { Content } = Layout

const ListBagPacking = ({ loading, packages, scroll, bag, idBag, fetchDetailBag }) => {
    const localDataPacking = JSON.parse(localStorage.getItem('packing_lasmile_packages'))
    const [packageLocal, setPackageLocal] = useState([])

    useEffect(() => {
        setPackageLocal(processData(packages, localDataPacking))
    }, [packages])

    const processData = (packages, localData) => {
        const newData = map(packages, item => {
            forEach(localData, element => {
                if (item.package.id === element.id) {
                    item = {
                        ...item,
                        warning_err: element.warning_err,
                    }
                }
            })

            return item
        })
        return newData
    }

    const packing_complete = get(bag, 'packing_complete')
    return (
        <>
            {loading || isEmpty(packageLocal) ? (
                <Content className="a-content a-content--package a-package__scan-empty">
                    <img
                        src={Background}
                        alt=""
                    />
                    <span>
                        Chưa có dữ liệu <br />
                        quét mã
                    </span>
                </Content>
            ) : (
                <Content className={'a-content a-barcode-scan ' + (scroll ? 'scroll-is-sider' : '')}>
                    <div className="a-content__top">
                        <div className="a-content__top--page-list--title">
                            <h3>
                                {t('bag:packing.package_list')} <span> - ({packageLocal.length})</span>
                            </h3>
                        </div>
                        {packing_complete && <PrintStamp idBag={idBag} />}
                    </div>

                    <>
                        <div className="a-content__body bg-white a-barcode-scan__body aheight-100  list-is-sidebar a-content__body--page-list">
                            <div className="a-table--responsive a-table--antd">
                                <table className="a-table a-table--barcode-scan">
                                    <thead>
                                        <tr>
                                            <th>{t('bag:packing.code_package')}</th>
                                            <th>
                                                {t('bag:packing.time_scan')}
                                                <div className="sort">
                                                    <Icon
                                                        type="caret-up"
                                                        // className={this.state.sort === 'up' ? 'active' : ''}
                                                    />
                                                    <Icon
                                                        type="caret-down"
                                                        // className={this.state.sort === 'down' ? 'active' : ''}
                                                    />
                                                </div>
                                            </th>
                                            <th>{t('bag:packing.code_order')}</th>
                                            <th>{t('bag:packing.weight')}</th>
                                            <th>{t('bag:packing.volume')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    {!isEmpty(packageLocal)
                                        ? packageLocal.map((packageData, index) => (
                                              <tbody
                                                  key={index}
                                                  className={!isEmpty(packageData.warning_err) && "warning"}>
                                                  {packageData.loading ? (
                                                      <tr className="data">
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                          <td>
                                                              <Skeleton
                                                                  active
                                                                  paragraph={{ rows: 0 }}
                                                              />
                                                          </td>
                                                      </tr>
                                                  ) : (
                                                      <tr
                                                          className="data"
                                                          key={packageData.package.id}>
                                                          <td>{get(packageData, 'package.code', '---')}</td>
                                                          <td>{get(packageData, 'package_detail.push_lastmiled_at', '---')}</td>
                                                          <td>{get(packageData, 'order.code', '---')}</td>
                                                          <td>{get(packageData, 'package.weight_net', '---')}</td>
                                                          <td>{get(packageData, 'package.volume', '---')} (m3)</td>
                                                          <td className="action-remove _action_remove">
                                                              <DeletePackage
                                                                  idBag={idBag}
                                                                  idPackage={packageData.package.id}
                                                                  bag={bag}
                                                                  packageCode={get(packageData, 'package.code', '---')}
                                                                  fetchDetailBag={fetchDetailBag}
                                                              />
                                                          </td>
                                                      </tr>
                                                  )}
                                                  {!isEmpty(packageData.warning_err) && (
                                                      <tr className="message">
                                                          <td
                                                              colSpan={9}
                                                              className="_box_message_error">
                                                              {renderNotices(packageData.warning_err)}
                                                          </td>
                                                      </tr>
                                                  )}
                                              </tbody>
                                          ))
                                        : null}
                                </table>
                            </div>
                        </div>
                    </>
                </Content>
            )}
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBagPacking)
