import { Button, Col, DatePicker, Form, Icon, Input, Row } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import lodash from 'lodash'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import withWarehouses from '../../../../hocs/withWarehouses'
import FormProcessing from '../../../Common/components/FormProcessing'
import SelectSortPartnerUserContainer from '../../../ShippingPartner/SelectSortPartnerUser/SelectSortPartnerContainer'
import SelectWarehouseContainer from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import { BAG_SHIPPING_TYPE } from '../../BagShippingType/constants'

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props)

        this.defaultInput = {
            code: undefined,
            code_search: undefined,
            id_shipping_partner: undefined,
            'created_at[from]': '',
            'created_at[to]': '',
            'completed_time[from]': '',
            'completed_time[to]': '',
            id_warehouse: undefined,
            status: undefined,
        }

        this.state = {
            input: { ...this.defaultInput },
            inputVersion: 0,
            changedParams: [],
        }
    }

    changeShipping = input => {
        if (lodash.startsWith(input.value, 'id_shipping_user_')) {
            this.changeInput({
                id_shipping_user: lodash.split(input.value, 'id_shipping_user_')[1],
                id_shipping_partner: lodash.isNil(input.value) ? undefined : 0,
                subject_shipping: lodash.isNil(input.value) ? undefined : BAG_SHIPPING_TYPE.USER,
            })
        } else {
            this.changeInput({
                id_shipping_partner: lodash.split(input.value, 'id_shipping_partner_')[1],
                id_shipping_user: lodash.isNil(input.value) ? undefined : 0,
                subject_shipping: lodash.isNil(input.value) ? undefined : BAG_SHIPPING_TYPE.PARTNER,
            })
        }
    }

    onChangeSelect = (field, value) => {
        this.changeInput({
            [field]: value,
        })
    }

    onSubmit = event => {
        event.preventDefault()

        const { input } = this.state
        const { loading, onSearch } = this.props

        if (!loading) {
            onSearch({
                ...input,
                page: 1,
            })
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : '',
        })
    }

    onReset = event => {
        const { loading, onSearch } = this.props
        event.preventDefault()

        if (loading) {
            return
        }

        const filter = this.defaultInput
        this.changeInput(filter)
        onSearch(filter)
    }

    render() {
        const { t, loadingWarehouses, warehouses, loading } = this.props
        const { input } = this.state
        return (
            <div
                className="a-content--search-header search-default"
                id="list-search">
                <Form
                    className="a-form "
                    onSubmit={this.onSubmit}>
                    <Row gutter={{ lg: 12, xl: 24, xxl: 24 }}>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('bag:label.bagging_request_code')}
                                labelCol={{ span: 24 }}>
                                <Input.Search
                                    value={input.code}
                                    placeholder={t('bag:label.bagging_request_code')}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('bag:label.order_package_code')}
                                labelCol={{ span: 24 }}>
                                <Input.Search
                                    value={input.code_search}
                                    placeholder={t('bag:label.order_package_code')}
                                    onChange={this.onChangeInput.bind(this, 'code_search')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('bag:label.midmile_shipping_partner')}
                                labelCol={{ span: 24 }}>
                                <SelectSortPartnerUserContainer
                                    allowClear
                                    dropdownClassName="_dropdown_form_bag_shipping_container"
                                    placeholder={t('bag:label.midmile_shipping_partner')}
                                    id_shipping_partner={parseInt(input.id_shipping_partner, 10)}
                                    onChange={value => this.changeShipping(value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('bag:label.id_warehouse_input')}
                                labelCol={{ span: 24 }}>
                                <SelectWarehouseContainer
                                    allowClear
                                    className="a-select a-select--search-content _id-warehouse-current"
                                    dropdownClassName="_dropdown_id_warehouse_input"
                                    getDataWhenMounted={false}
                                    loading={loadingWarehouses}
                                    placeholder={t('bag:label.id_warehouse_input')}
                                    value={input.id_warehouse}
                                    warehouses={warehouses}
                                    onChange={value => this.onChangeSelect('id_warehouse', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={12}
                            xl={12}>
                            <Form.Item
                                className="a-form__item"
                                label={t('bag:label.created_at')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                            onChange={value => this.onChangeTime('created_at[from]', value)}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                            }}
                                            value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                            onChange={value => this.onChangeTime('created_at[to]', value)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                        <Col
                            lg={12}
                            xl={12}>
                            <Form.Item
                                className="a-form__item"
                                label={t('bag:label.finished_at')}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _finished-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            value={input['completed_time[from]'] ? moment(input['completed_time[from]']) : null}
                                            onChange={value => this.onChangeTime('completed_time[from]', value)}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                            }}
                                            value={input['completed_time[to]'] ? moment(input['completed_time[to]']) : null}
                                            onChange={value => this.onChangeTime('completed_time[to]', value)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="search--action gjustify-end">
                        <a
                            className="link-reload _btn-reset"
                            href="/"
                            onClick={this.onReset}>
                            <Icon type="sync" />
                            {t('label.refresh_filter')}
                        </a>
                        <Button
                            className="a-btn a-btn--primary a-btn--search-list _btn-search"
                            htmlType="submit"
                            loading={loading}
                            type="primary">
                            {t('btn.search')}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}
export default withTranslation()(
    withWarehouses(FormSearch, {
        getDataWhenMounted: true,
    })
)
