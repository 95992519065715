import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import Log from './components';

class LogContainer extends React.Component {
    componentDidMount() {
        this.props.fetchLogs();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.object !== prevProps.object
            || this.props.objectId !== prevProps.objectId
        ) {
            this.props.clearState();
            this.props.fetchLogs();
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        return <Log {...this.props}/>
    }
}

const mapStateToProps = (state) => ({
    ...selectors.getState(),
    logs: state.common.log.logs || {}
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchLogs: (filter) => {
        if (props.objectId) {
            setTimeout(() => dispatch(actions.fetchLogs(props.object, props.objectId, filter)), props.delayTime);
        }
    },
    clearState: () => dispatch(actions.clearState(props.object)),
});

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(LogContainer);

Container.defaultProps = {
    delayTime: 0,
    object: null,
    objectId: null,
};

Container.propTypes = {
    delayTime: PropTypes.number,
    object: PropTypes.string.isRequired,
    objectId: PropTypes.any,
};

export default Container;
