import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { withTranslation } from 'react-i18next';

import { formatWeight } from './../../../../system/support/helpers';

import Link from './../../../../system/routing/Link';

const RemainingPackagesModal = ({ packages, t, visible, onCancel, onSubmit }) => {
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);
    const [columns] = useState([{
        className: 'a-text--nowrap',
        dataIndex: 'code',
        key: 'code',
        title: t('delivery_note:label.code_package'),
        render: text => (
            <Link
                className="a-text--blue"
                isNewTab
                params={{
                    id: text
                }}
                to="packages.detail"
            >
                {text}
            </Link>
        )
    }, {
        className: 'a-text--nowrap',
        dataIndex: 'code_order',
        key: 'code_order',
        title: t('order_code'),
        render: (text, row) => text ? (
            <Link
                className="a-text--blue"
                href={row.url_order}
                isNewTab
            >
                {text}
            </Link>
        ) : '--'
    }, {
        className: 'a-text--nowrap',
        dataIndex: 'weight_net',
        key: 'weight_net',
        title: t('weight'),
        render: text => formatWeight(text)
    }, {
        dataIndex: 'customer_address',
        key: 'customer_address',
        title: t('delivery_note:label.address'),
        render: text => text || '--'
    }]);

    const handleSelectPackages = (selectedRowKeys, selectedRows) => {
        setSelectedPackageIds(selectedRowKeys);
        setSelectedPackages(selectedRows);
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleSubmit = () => {
        onSubmit(selectedPackageIds, selectedPackages);
    };

    useEffect(() => {
        if (!visible) {
            setSelectedPackageIds([]);
            setSelectedPackages([]);
        }
    }, [visible]);

    return (
        <Modal
            centered
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {t('btn.cancel')}
                </Button>,
                <Button
                    className="a-btn a-btn--primary"
                    disabled={!selectedPackageIds.length}
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    {t('create_order')}
                </Button>
            ]}
            title={t('remaining_package_list')}
            visible={visible}
            width={800}
            onCancel={handleCancel}
        >
            <Table
                columns={columns}
                dataSource={packages}
                pagination={false}
                rowKey={row => row.id}
                rowSelection={{
                    selectedRowKeys: selectedPackageIds,
                    onChange: handleSelectPackages
                }}
            />
        </Modal>
    );
};

RemainingPackagesModal.defaultProps = {
    packages: []
};

export default withTranslation()(RemainingPackagesModal);
