import apiService from './apiService';
import { FETCH_SUGGEST_AGENCIES, GET_AGENCIES} from './constants';
import { processApiRequest } from './../Common/saga';

export function* getAgencies(action) {
    yield processApiRequest(GET_AGENCIES, () => apiService.get(action.payload), action.payload);
}

export function* fetchSuggestAgencies(action) {
    yield processApiRequest(FETCH_SUGGEST_AGENCIES, () => apiService.getAgencySuggest(action.payload), action.payload);
}
