/* eslint-disable */
import React from 'react';
import {Button, Icon, Row} from "antd";

class BagBlockRight extends React.PureComponent {
    render() {
        return (
            <div className={"aml-24 alog "}>
                <div className="title">
                    <span>Log hành động của bao</span>
                </div>
                <div className="list-log">
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>
                    <div className="log-block">
                        <img className="img-ads" src={require("../../../resources/images/ngoc-trinh-be-9.jpg")} alt="Oánh"/>
                        <div className="log-detail">
                            <p className="title">Mao Trạch Đông, 13:34 - 15/02/2019</p>
                            <p className="detail"><b>Nguyễn Ngọc Định</b> đã thêm 1238124 kiện vào
                                bao <b>H12313-12837</b>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default BagBlockRight;