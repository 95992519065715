import { connect } from 'react-redux';

import * as historyListSelectors from './selectors';
import * as selectorsInventoryBarcode from './../InventoryBarcode/selectors';
import * as actions from './../InventoryScan/actions';
import * as selectorsInventoryScan from './../InventoryScan/selectors';
import * as selectorsWarehouse from './../../Warehouse/selectors';

import HistoryList from './components';

const mapStateToProps = state => {
	return {
		loading: selectorsInventoryScan.getState(state, 'loading') || selectorsWarehouse.getState(state, 'loading'),
		scanLoading: selectorsInventoryBarcode.getState(state, 'loading'),
		inventory: selectorsInventoryScan.getState(state, 'detail'),
		inventoryPackages: historyListSelectors.getState(state, 'inventoryPackages', []),
		inventoryTrackingBills: historyListSelectors.getState(state, 'inventoryTrackingBills', []),
		warehouse: selectorsWarehouse.getState(state, 'list')
	};
};

const mapDispatchToProps = {
	fetchInventory: actions.fetchInventory
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HistoryList);
