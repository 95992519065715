import lodash from 'lodash';
import {setInputFocus} from '../../actions';
import {dispatch} from '../../../../../system/store';
import {ELEMENT} from './constants';

export default class InputFocusHandler {
    /**
     * @param {InputFocusDetector} detector
     * @param {InputFocusParser} parser
     */
    constructor(detector, parser) {
        this.detector = detector;
        this.parser = parser;
    }

    /**
     * Event khi bắt đầu vào page
     */
    onInit() {
        this.focus(ELEMENT.BARCODE);
    }

    /**
     * Event khi thay đổi danh sách kiện (khi quét mã, tạo kiện tương tự, tạo kiện cùng vận đơn)
     *
     * @param {Array} packages
     * @param {Array} orderSuggests
     */
    onChangePackages(packages, orderSuggests) {
        this.focus(this.detector.detect(packages, orderSuggests));
    }

    /**
     * Event khi khớp kiện với đơn
     *
     * @param {Object} packageData
     * @param {String} orderId
     */
    onLinkOrderPackage(packageData, orderId) {
        let element = this.detector.detectForPackage({...packageData, order_id: orderId});
        this.focusPackageInput(packageData.id, element);
    }

    /**
     * Event khi bỏ khớp kiện với đơn
     *
     * @param {Object} packageData
     */
    onUnlinkOrderPackage(packageData) {
        this.focusPackageInput(packageData.id, ELEMENT.PACKAGE_ORDER_CODE);
    }

    /**
     * Event khi enter package input
     *
     * @param {Object} packageData
     * @param {String} param
     */
    onEnterPackageInput(packageData, param) {
        let element = lodash.get({
            [ELEMENT.PACKAGE_NET_WEIGHT]: ELEMENT.PACKAGE_BTN_PRINT_BARCODE,
            [ELEMENT.PACKAGE_LENGTH]: ELEMENT.PACKAGE_WIDTH,
            [ELEMENT.PACKAGE_WIDTH]: ELEMENT.PACKAGE_HEIGHT,
            [ELEMENT.PACKAGE_HEIGHT]: ELEMENT.PACKAGE_BTN_PRINT_BARCODE,
        }, param);

        this.focusPackageInput(packageData.id, element);
    }

    /**
     * Dispatch event focus vào element của package
     *
     * @param {int} packageId
     * @param {string} element
     */
    focusPackageInput(packageId, element) {
        this.focus(this.parser.make(packageId, element));
    }

    /**
     * Dispatch event focus vào input
     *
     * @param {string} input
     */
    focus(input) {
        dispatch(setInputFocus(input));
    }
}
