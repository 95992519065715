import userSettingService from '../../Auth/services/userSettingService';
import {HISTORY_COLUMN} from './constants';

const SETTING_OPTIONS = [
    {
        key: HISTORY_COLUMN.BARCODE,
        selected: true,
        disabled: true,
    },
    {
        key: HISTORY_COLUMN.SCAN_TIME,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.BARCODE_TYPE,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.TYPE_SCAN,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.PACKAGE,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.ACTION,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.WAREHOUSE_SCAN,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.WAREHOUSE_DESTINATION,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.ORDER,
        selected: true,
    },
    {
        key: HISTORY_COLUMN.CREATOR,
        selected: true,
    },

];

class HistorySettingService {
    constructor(userSetting, options) {
        this.userSetting = userSetting;
        this.options = options;
    }

    getDefault() {
        return this.options.filter(option => option.selected).map(option => option.key);
    }

    get() {
        return this.userSetting.get('barcode_scanning_history') || this.getDefault();
    }

    set(setting) {
        this.userSetting.set('barcode_scanning_history', setting);
    }
}

let settingService = new HistorySettingService(userSettingService, SETTING_OPTIONS);

export default settingService;