/* eslint-disable */
import React from 'react';
import {Button, Empty, Modal, notification, Alert, Table, Typography} from "antd";
import lodash from "lodash";
import {ORDER_REQUEST_COLUMN} from "../constants";
import {withTranslation} from "react-i18next";
import CancelDeliveryRequest from "../../CancelDeliveryRequest/CancelDeliveryRequest";
import PERMISSIONS from "../../../Auth/permissions";

import apiService from './../../apiService';
import { handleDeliveringDeliveryRequestError } from './../../helper';

const { Paragraph } = Typography;

class DetailDeliveryRequest extends React.Component {
	state = {
		deliveringDeliveryRequest: false
	};

	onCancel() {
		this.props.hideDetailDeliveryRequest();
		this.props.clearState();
	}

	componentWillUnmount() {
		this.props.hideDetailDeliveryRequest();
		this.props.clearState();
	}

	getColumns() {
		const arrSort = [];

		return lodash.values(ORDER_REQUEST_COLUMN)
			.map(column => {
				let res = {
					className: `a-text--nowrap _column-${column}`,
					key: column,
					dataIndex: column,
					title: this.props.t(`delivery_request:column.${column}`),
				};

				if (arrSort.indexOf(column) > -1) {
					res = {
						...res,
						sorter: true,
						sortOrder: column === this.props.filter.sort_by
							? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
							: null,
					}
				}

				return res;
			});
	}

	renderDeliveringDeliveryRequestButton = deliveryRequest => {
		const { deliveringDeliveryRequest } = this.state;
		const { t } = this.props;

		return (
			<Button
				className="a-btn--link a-text--link _btn-delivering"
				loading={deliveringDeliveryRequest}
				type="link"
				onClick={this.deliveringDeliveryRequest.bind(this, deliveryRequest)}
			>
				{t('processed')}
			</Button>
		);
	};

	deliveringDeliveryRequest = (deliveryRequest, event) => {
        const { t } = this.props;

		event.stopPropagation();

		Modal.confirm({
			cancelButtonProps: {
				className: '_btn-cancel-delivering-delivery-request'
			},
			cancelText: t('btn.cancel'),
			className: 'a-modal a-modal--warning',
            content: t('delivery_request:delivering.confirm'),
            okButtonProps: {
				className: '_btn-confirm-delivering-delivery_request'
			},
			okText: t('btn.ok'),
            onOk: this.confirmDeliveringDeliveryRequest.bind(undefined, deliveryRequest.id)
        });
	};

	confirmDeliveringDeliveryRequest = async deliveryRequestId => {
		const { onSuccessDeliveringDeliveryRequest, t } = this.props;

		this.setState({
			deliveringDeliveryRequest: true
		});

		try {
			await apiService.delivering(deliveryRequestId);

			if (lodash.isFunction(onSuccessDeliveringDeliveryRequest)) {
				onSuccessDeliveringDeliveryRequest();
			}

			notification.success({
				message: t('delivery_request:delivering.success')
			});

			this.onCancel();
		} catch (error) {
			handleDeliveringDeliveryRequestError(error);
		}

		this.setState({
			deliveringDeliveryRequest: false
		});
	};

	render() {
		const {detailDeliveryRequest, listOrderRequest, loading, t, permissions, route} = this.props;

		return (
			<React.Fragment>
				<Modal
                    title={<div className="title-modal "><span
						className="a-text--uppercase">{t("delivery_request:label.detail_delivery_request")}</span>
					</div>}
                    visible={this.props.visible}
                    className="a-modal a-modal--package-in-bag request-deliverynote"
                    width={700}
                    footer={null}

                    onCancel={this.onCancel.bind(this)}
				>
					{!!detailDeliveryRequest.note && (
						<Alert
							className="a-alert--warning mb-4"
							message={detailDeliveryRequest.note}
							type="warning"
						/>	
					)}
                    <Table
						className="a-table--antd a-table--modal-list-package _a-table-list-package"
						columns={this.getColumns()}
						dataSource={listOrderRequest}
						pagination={false}
						loading={loading}
						locale={{
							emptyText: (
								<Empty
									image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
									description={<span>{t('label.no_data')}</span>}
								/>
							)
						}}
					/>
					<div className="action-modal gflex gjustify-space-between galign-center">
						<Paragraph className="mb-0">{t('total_package')}: {listOrderRequest ? listOrderRequest.length : 0}</Paragraph>
						<div>
							{this.renderDeliveringDeliveryRequestButton(detailDeliveryRequest)}
							{lodash.includes(permissions, PERMISSIONS.DELIVERY_REQUEST_CANCEL) && (
								<CancelDeliveryRequest
									detailDeliveryRequest={detailDeliveryRequest}
									route={route}
									type={"detail"}
								/>
							)}
						</div>
					</div>
				</Modal>
			</React.Fragment>
		)
	}
}

export default withTranslation()(DetailDeliveryRequest);
