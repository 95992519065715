import React, {Component} from 'react';
import {Button, Empty, Icon, Input, Layout, Table} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {trans} from '../../../../system/i18n';
import Link from '../../../../system/routing/Link';

const Search = Input.Search;
const Content = Layout.Content;

const listAction = (id) => {
    return (
        <React.Fragment>
            <Link href={`/config/bag-sizes/edit/${id}`} className={"_bnt-edit _bnt-edit-" + id}>
                <Icon type="edit" className={"mr-3 ahover-pointer a-text--blue"} />
            </Link>
        </React.Fragment>
    )
};
const columns = [
    {
        title: trans("bag_size:label.name"),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
            return {
                props: {
                    className: `a-notification-dot a-notification-dot--table active ${record.active ? 'active': 'unactive'}`
                },
                children: <Link href={`/config/bag-sizes/${record.id}`} className="a-text--nowrap _name">{text}</Link>,
            };
        },
    },{
        title: trans("bag_size:label.length"),
        dataIndex: 'length_convert',
        key: 'length_convert',
        render: text => <span className={"_length"}>{text} (cm)</span>,
    },{
        title: trans("bag_size:label.width"),
        dataIndex: 'width_convert',
        key: 'width_convert',
        render: text => <span className={"_width"}>{text} (cm)</span>,
    },{
        title: trans("bag_size:label.height"),
        dataIndex: 'height_convert',
        key: 'height_convert',
        render: text => <span className={"_height"}>{text} (cm)</span>,
    },{
        title: trans("bag_size:label.volume"),
        dataIndex: 'volume_convert',
        key: 'volume_convert',
        render: text => <span className={"_volume"}>{text} (cm3)</span>,
    },{
        title: trans("bag_size:label.weight_max"),
        dataIndex: 'weight_max',
        key: 'weight_max',
        render: text => <span className={"_weight_max"}>{text} (kg)</span>,
    },{
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: "a-text--nowrap a-text--right _action",
        // fixed: 'right',
        width: 24,
        render: (_, record) => listAction(record.id),
    }
];

class ListBagSize extends Component {

    handleSearch(value){
        this.props.onSearch({name: value});
    }

    onChangeSearch = (e) => {
        if(e.type === 'click') {
            this.props.onSearch({});
        }
    };

    render() {
        let {t, list, filter, loading} = this.props;
        return (
            <Content className={"aml-24 aml-16-lg a-content-config  height-100 gflex gflex-direction-column"}>
                <div className="a-content__top a-content__top--page-list mt-0">
                    <div className="a-content--page-list__title gflex galign-center">
                        <h3>{t("bag_size:label.list")} ({list.length})</h3>
                        <div className={"a-form__search-inline-title"}>
                            <Search
                                allowClear={true}
                                defaultValue={filter.name}
                                placeholder={t("bag_size:placeholder.size")}
                                className={"a-input--search-inline-title _input-search-bag-size"}
                                onSearch={this.handleSearch.bind(this)}
                                onChange={this.onChangeSearch}
                            />
                        </div>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button type="primary"
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-size">
                            <Link href={"/config/bag-sizes/create"}>{t("bag_size:btn.create_size")}</Link>
                        </Button>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  height-100">
                    <Table
                        className={"a-table--antd a-table--antd-scroll-x _a-table-bag-size"}
                        columns={columns}
                        dataSource={list}
                        pagination={false}
                        scroll={{ x: 'fit-content'}}
                        rowKey='id'
                        loading={loading}
                        locale={{
                            emptyText: (
                            <Empty
                                className={"empty"}
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                description={<span>{t("common:label.no_data")}</span>}
                            >
                            </Empty>)
                        }}
                    />
                </div>
            </Content>
        )
    }
}

ListBagSize.defaultProps = {
    list: [],
    filter: {}
};

ListBagSize.propTypes = {
    list: PropTypes.array,
    filter: PropTypes.object
};

export default withTranslation()(ListBagSize);
