import React from 'react'
import SearchableComponent from '../../../Common/components/SearchableComponent'
import { Layout, Tabs } from 'antd'
import { BAG_REQUEST_STATUS } from '../constants'
import { withTranslation } from 'react-i18next'
import pageService from '../../../Common/Page/pageService'
import { setMenuActive } from '../../../Common/actions'
import FormSearch from './FormSearch'
import apiService from '../../apiService'
import lodash from 'lodash'
import BagList from './BagList'
import ColumnSetting from './ColumnSetting'
import settingService from './settingService'
import { dateFormatter } from '../../../Common/services/format'
import { trans } from '../../../../system/i18n'
import { Link } from '../../../../system/routing'
import { getVar } from '../../../../system/support/helpers'

const { Content } = Layout
const TabPane = Tabs.TabPane

class BaggingRequestList extends SearchableComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            bags: [],
            pagination: {},
            isOpenListSetting: false,
            activeColumns: [],
        }
    }
    getCurrentFilter = () => {
        const filter = {
            page: 1,
            ...this.getFilterFromLocation(this.props.location),
        }
        return filter
    }

    onChangeFilter = filter => {
        this.setState({ loading: true })
        apiService
            .getBaggingRequestList(filter)
            .then(res => {
                const data = lodash.get(res, 'data.data', [])
                const bags = this.refactorData(data)
                this.setState({ bags, pagination: lodash.get(res, 'data.meta.pagination', {}) })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    refactorData = data => {
        return data.map(item => {
            const warehouse = lodash.get(item, 'warehouse', {})
            const shipping_partner = lodash.get(item, 'shipping_partner', {})
            const completedTime = lodash.get(item, 'completed_time')
            const createdAt = lodash.get(item, 'created_at')
            const status = lodash.get(item, 'status', '---')
            return {
                id: item.id,
                bagging_request_code: (
                    <Link
                        isNewTab
                        to="bags.request-detail"
                        params={{ id: item.id }}>
                        {item.code}
                    </Link>
                ),
                midmile_shipping_partner: lodash.get(shipping_partner, 'name', '---'),
                warehouse_input: lodash.get(warehouse, 'code', '---'),
                created_at: createdAt ? dateFormatter.dateTime(createdAt) : '---',
                finished_at: completedTime ? dateFormatter.dateTime(completedTime) : '---',
                status: status ? trans(`bag:bag_request_status.${status}`) : '---',
                num_package: lodash.get(item, 'packages_count', '---'),
            }
        })
    }

    onSearch = filter => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }
    componentDidMount() {
        super.componentDidMount()
        pageService.setTitle(this.props.t('bag:title.bag_request_list'))
        setMenuActive('bags/request-list')
    }

    onChangeTab(activeKey, currentFilter) {
        this.onSearch({ ...currentFilter, status: activeKey })
    }

    render() {
        const { loading, isOpenListSetting, activeColumns, bags, pagination } = this.state
        const filter = this.getCurrentFilter()
        const status = getVar(filter, 'status', '')
        return (
            <Layout>
                <Content className={`a-content a-content--page-list gflex gflex-direction-column`}>
                    <FormSearch
                        onSearch={this.onSearch}
                        input={filter}
                        inputVersion={lodash.toInteger(filter.i)}
                        loading={loading}
                    />
                    <Tabs
                        activeKey={status ? status : ''}
                        onChange={activeKey => this.onChangeTab(activeKey, filter)}
                        className="a-tabs a-tabs--container"
                        tabBarExtraContent={
                            <ColumnSetting
                                options={settingService.options}
                                onChange={activeColumns => this.setState({ activeColumns })}
                                loading={loading}
                                isOpenListSetting={isOpenListSetting}
                                onOpenListSetting={isOpenListSetting => this.setState({ isOpenListSetting })}
                            />
                        }>
                        <TabPane
                            key=""
                            tab={this.props.t(`label.all`)}>
                            <BagList
                                filter={filter}
                                loading={loading}
                                bags={bags}
                                pagination={pagination}
                                activeColumns={activeColumns}
                                onSearch={this.onSearch}
                            />
                        </TabPane>
                        {Object.values(BAG_REQUEST_STATUS).map(item => {
                            return (
                                <TabPane
                                    key={item}
                                    tab={this.props.t(`bag:bag_request_status.${item}`)}>
                                    <BagList
                                        filter={filter}
                                        loading={loading}
                                        bags={bags}
                                        pagination={pagination}
                                        activeColumns={activeColumns}
                                        onSearch={this.onSearch}
                                    />
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Content>
            </Layout>
        )
    }
}
export default withTranslation()(BaggingRequestList)
