import React from 'react';

const Scale = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <style>
        {`.a,
        .c {
          fill: rgba(15, 15, 15, 0.65);
        }`}

        {`.a {
          stroke: #707070;
        }`}

        {`.b {
          clip-path: url(#a);
        }`}
      </style>
      <clipPath id="a">
        <rect className="a" width="16" height="16" />
      </clipPath>
    </defs>
    <g className="b">
      <g transform="translate(0 0)">
        <path className="c" d="M15.456,14.384,1,9.051A.667.667,0,1,0,.544,10.3l6.879,2.54A.167.167,0,0,1,7.5,13.1l-1.89,2.342a.333.333,0,0,0,.259.543h4.267a.333.333,0,0,0,.259-.543l-1.363-1.69a.167.167,0,0,1,.187-.261L15,15.637a.667.667,0,1,0,.456-1.253Z" />
        <path className="c" d="M16,7.677a.333.333,0,0,0-.333-.333H14.333A.333.333,0,0,1,14,7.011V5.677a.333.333,0,0,0-.333-.333h-2a.333.333,0,0,0-.333.333V7.011A.333.333,0,0,1,11,7.344H9.667a.333.333,0,0,0-.333.333v2a.333.333,0,0,0,.333.333H11a.333.333,0,0,1,.333.333v1.333a.333.333,0,0,0,.333.333h2A.333.333,0,0,0,14,11.677V10.344a.333.333,0,0,1,.333-.333h1.333A.333.333,0,0,0,16,9.677Z" />
        <path className="c" d="M4.333,8.677A4.333,4.333,0,1,0,0,4.344,4.333,4.333,0,0,0,4.333,8.677ZM3.7,1.877a.166.166,0,0,0,.133-.163v-.2a.5.5,0,0,1,1,0v.167A.167.167,0,0,0,5,1.844h.333a.5.5,0,1,1,0,1H3.978a.394.394,0,0,0-.147.76l1.377.551a1.39,1.39,0,0,1-.241,2.654.166.166,0,0,0-.133.163v.205a.5.5,0,0,1-1,0V7.011a.167.167,0,0,0-.167-.167H3.333a.5.5,0,1,1,0-1H4.689a.395.395,0,0,0,.147-.761l-1.377-.55A1.39,1.39,0,0,1,3.7,1.879Z" />
      </g>
    </g>
  </svg>
);

export default Scale;
