import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import get from 'lodash/get';
import parseInt from 'lodash/parseInt';
import moment from 'moment';

import { NOTE_TYPES, STAGES } from './../constants';
import { t } from './../../../system/i18n';

const { Search } = Input;
const { Option } = Select;

class FormSearch extends Component {
    constructor(props) {
        super(props);

        let form = {};

        if (props.object === props.filter.object) {
            form = {
                ...props.filter,
                id_creator: props.filter.id_creator && Number(props.filter.id_creator)
            };
        }

        this.state = {
            form
        };
    }

    onChangeInput = (field, e) => {
        const value = e.target.value;

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [field]: value
            }
        }));
    };

    onChangeSelect = (field, value) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [field]: value
            }
        }));
    };

    onChangeTime = (field, value) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
            }
        }));
    };

    filter = event => {
        const { form } = this.state;
        const { object, onSearch } = this.props;

        event.preventDefault();

        onSearch({
            object,
            ...form
        });
    };

    refreshFilter = event => {
        const { filter, object, onSearch } = this.props;

        event.preventDefault();

        this.setState({form: {i: parseInt(filter.id) + 1}}, () => {
            onSearch({
                object
            });
        });
    };

    componentDidUpdate(prevProps) {
        const { filter, type } = this.props;

        if (filter.object !== prevProps.filter.object && filter.object !== type) {
            this.setState({
                form: {}
            });
        }
	}

    render() {
        const { form } = this.state;
        const { object, listUser, loading, loadingUsers } = this.props;

        return (
            <div className={`a-content--search-header search-default form-search-${object}`}>
                <Form className="a-form" onSubmit={this.filter}>
                    <Row gutter={{lg: 12, xl: 24}}>
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item
                                className={`a-form__item search-content item--special`}
                                label={object === NOTE_TYPES.ORDER ? t('order_id_code') : (object === NOTE_TYPES.TRACKING ? t('freight_bill') : (object === NOTE_TYPES.PACKAGE ? t('package_code') : t('customer_username')))}
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search _id-object"
                                    value={form.id_object}
                                    onChange={this.onChangeInput.bind(this, 'id_object')}
                                    onPressEnter={this.filter}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('note:label.created_attention_at')}
                                labelCol={{span: 24}}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={form['created_at[from]'] ? moment(form['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={form['created_at[to]'] ? moment(form['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('expired_time')}
                                labelCol={{span: 24}}
                            >
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _expired-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={form['expired_at[from]'] ? moment(form['expired_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'expired_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _expired-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={form['expired_at[to]'] ? moment(form['expired_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'expired_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={5}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t("note:label.id_creator_attention")}
                                labelCol={{span: 24}}>
                                <Select allowClear
                                        className="a-select a-select--search-content _id-creator"
                                        dropdownClassName="_dropdown-creator"
                                        disabled={loadingUsers}
                                        placeholder={t('note:label.id_creator_attention')}
                                        value={loadingUsers ? undefined : form.id_creator}
                                        onChange={this.onChangeSelect.bind(this, 'id_creator')}
                                        loading={loadingUsers}
                                        showSearch
                                        optionFilterProp="children"
                                >
                                    {listUser.map((user, index) => (
                                        <Option
                                            className="_creator-item"
                                            key={index}
                                            value={get(user, 'id')}
                                        >
                                            {(get(user, 'username') || '(--)') + ' (' + (get(user, 'name', '--') || '--') + ')'}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={7} xl={5}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('note:column.stages')}
                                labelCol={{span: 24}}
                            >
                                <Select
                                    allowClear
                                    className="a-select a-select--search-content _stages"
                                    dropdownClassName="_dropdown-stages"
                                    mode="multiple"
                                    optionFilterProp="children"
                                    placeholder={t('note:column.stages')}
                                    showSearch={true}
                                    value={form.stages}
                                    onChange={this.onChangeSelect.bind(this, 'stages')}
                                >
                                    {Object.keys(STAGES).map(key => (
                                        <Option className="_stage" key={key} value={STAGES[key]}>
                                            {t(`note:stages.${STAGES[key]}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="search--action">
                        <a
                            className="link-reload a-text--black-45 mr-3"
                            href="/"
                            onClick={this.refreshFilter}
                        >
                            <Icon className="a-text--black-45" type="sync" />{t('common:label.refresh_filter')}
                        </a>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="a-btn a-btn--primary a-btn--search-list _search_follow"
                            loading={loading}
                        >
                            {t('btn.search')}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default FormSearch;
