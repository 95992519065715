/**
 *
 * Middleware dùng chung cho tất cả router, list chức năng chạy chung: <br />
 * 1. Thay đổi language khi có thay đổi trên url ( mặc định là english )
 *
 * @param payload
 * @param next
 * @param router
 * @param i18n
 * @returns {*}
 */
let commonMiddleware = (payload, next, router, i18n) => {
    changeLanguage(router, i18n);
    return (next(payload));
};

/**
 * Thay đổi language khi có thay đổi trên url ( mặc định là english ) <br />
 * @param router
 * @param i18n
 */
function changeLanguage(router, i18n) {
    let search = router.history.location.search;

    // i18n.changeLanguage('vi');

    if (localStorage.getItem("i18nextLng") === "en") {
        i18n.changeLanguage('en');
    }

    if (localStorage.getItem("i18nextLng") === "vi") {
        i18n.changeLanguage('vi');
    }

    if (search.includes("lang=en")) {
        i18n.changeLanguage('en');
    }

    if (search.includes("lang=vi")) {
        i18n.changeLanguage('vi');
    }
}


export default commonMiddleware;
