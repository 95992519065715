import React, { Fragment, memo } from 'react';

import { dateFormatter } from './../../../../Common/services/format';
import { getVar } from './../../../../../system/support/helpers';

import { Link } from './../../../../../system/routing';

const SuccessItem = ({ item, index }) => (
	<tbody key={index}>
		<tr>
			<td>{index + 1}</td>
			<td>
				{item.inventory_tracking_bill.tracking_bill}
			</td>
			<td>{getVar(item, 'inventory_tracking_bill.created_at', '--') === '--' ? '--' : dateFormatter.dateTime(item.inventory_tracking_bill.created_at)} </td>
			<td>
				{getVar(item, 'inventory_tracking_bill.related_packages') && item.inventory_tracking_bill.related_packages.length ? item.inventory_tracking_bill.related_packages.map((pkg, index) => (
					<Fragment key={pkg}>
						<Link isNewTab params={{id: pkg}} to="packages.detail">{pkg}</Link>
						{index < item.inventory_tracking_bill.related_packages.length - 1 ? ', ' : null}
					</Fragment>
				)) : '--'}
			</td>
		</tr>
	</tbody>
);

export default memo(SuccessItem);
