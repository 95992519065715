import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from "lodash";
import * as actions from './actions';
import * as selectors from './selectors';
import FormShippingUser from './components';
import * as commonAction from "../../Common/actions";

import apiService from './../../User/apiService';

const mapStateToProps = (state) => ({
    errors: selectors.getErrors(lodash.get(state, "shippingUser.form.errors", {})),
    loading: state.shippingUser.form.loading
});

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(actions.clearState()),
    resetError: (params) => dispatch(actions.resetError(params)),
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu))
});

class FormShippingUserContainer extends React.Component {
    state = {
        users: []
    };

    getUsers = async () => {
        try {
            const { data } = await apiService.getUsers({
                is_shipping_user: false
            });

            const users = lodash.map(data.users, 'user');

            this.setState({
                users
            });
        } catch (error) {
        }
    };

    componentDidMount() {
        this.props.setMenuActive("config/shipping-users");
        this.getUsers();
    }

    render() {
        const { users } = this.state;

        return (
            <FormShippingUser {...this.props} users={users} />
        );
    }
}

FormShippingUserContainer.defaultProps = {
    errors: {},
    loading: false
};

FormShippingUserContainer.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormShippingUserContainer);
