import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Select} from 'antd';
import lodash from 'lodash';

const Option = Select.Option;

class SelectUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.autoSelected = false;
    }

    componentDidMount() {
        this.autoSelect();
    }

    componentDidUpdate() {
        this.autoSelect();
    }

    /**
     * Xử lý tự động select reason đầu tiên khi không truyền vào users hiện tại
     */
    autoSelect() {
        let {value, users} = this.props;

        if (this.props.autoSelect && !this.autoSelected && !value && users.length) {
            this.autoSelected = true;
            this.changeInput({value: lodash.get(lodash.first(users), 'id')});
        }
    }

    changeInput(input) {
        this.props.onChange(input);
    }

    render() {
        const {t, allowClear, dropdownClassName, emptyOption, value, users, showName, loading} = this.props;
        return (
            <Select
                allowClear={allowClear}
                value={!value ?
                    (this.autoSelected ? lodash.get(lodash.first(users), 'name') : this.props.placeholder !== '' ? undefined : '') :
                    (!loading ? parseInt(value, 0) : this.props.placeholder !== '' ? undefined :'')
                }
                loading={loading}
                showSearch={true}
                optionFilterProp="children"
                disabled={this.props.disabled}
                className={this.props.className}
                dropdownClassName={dropdownClassName}
                placeholder={this.props.placeholder}
                validateStatus={this.props.error ? 'error' : null}
                help={this.props.error}
                onChange={this.props.onChange}
                {...this.props.selectProps}
            >
                {emptyOption ? (
                    <Option key="" value="" className={"_person__item"}>{emptyOption === true ? t('label.all') : emptyOption}</Option>
                ) : null}

                {this.props.users.map(user => (
                    <Option
                        key={user.id}
                        value={user.id}
                        className={"_person__item"}
                    >{(showName) ? user.full_name + '(' + user.username + ')' : user.username}</Option>
                ))}
            </Select>
        );
    }
}

SelectUser.defaultProps = {
    users: [],
    error: null,
    disabled: false,
    emptyOption: true,
    showName: false,
    className: '',
    selectProps: {},
    onChange: () => {
    },
};

SelectUser.propTypes = {
    users: PropTypes.array,
    value: PropTypes.any,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    emptyOption: PropTypes.any,
    showName: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default withTranslation()(SelectUser);
