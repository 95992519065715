import React from 'react';
import {Input} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import FormProcessing from './../../../Common/components/FormProcessing';
class FormScan extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                code: '',
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit({code: this.state.input.code.trim(), stage: 'CHECKING'});
    };

    render() {
        const { inputRef, t } = this.props;

        return (
            <form onSubmit={this.onSubmit} className="a-content__top amb-16">
                <div className="a-content__top__title">
                    <h3 className="a-text--uppercase">{t('barcode:scanning_history.column.SCAN_PACKAGE_INVENTORY')}</h3>
                </div>

                <div className="a-flex">
                    <Input
                        autoFocus
                        value={this.state.input.code || ''}
                        onChange={this.onChangeInput.bind(this, 'code')}
                        className="a-input w-26rem checking-package__scan-code"
                        placeholder={t('scan_code')}
                        ref={inputRef}
                    />
                </div>
            </form>
        )
    }
}

FormScan.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: () => {},
};

FormScan.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    onSubmit: PropTypes.func,
};

export default withTranslation()(FormScan);
