/* eslint-disable */

import React from 'react';
import Employee from "./components/Employee";
import Partner from "./components/Partner";
import Customer from "./components/Customer";
import { Tabs } from 'antd';

const TabPane = Tabs.TabPane;


class ListDeliveryNote extends React.PureComponent{
    render() {
        return(
            <Tabs defaultActiveKey="1" className="a-tabs a-tabs--container">
                <TabPane tab="Xuất cho nhân viên" key="1"><Employee/></TabPane>
                <TabPane tab="Xuất cho đối tác" key="2"><Partner/></TabPane>
                <TabPane tab="Xuất cho khách hàng" key="3"><Customer/></TabPane>
            </Tabs>
        )
    }
}
export default ListDeliveryNote;