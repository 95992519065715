import React, {Component} from 'react';
import {connect} from 'react-redux';
import lodash from 'lodash';
import {notification} from "antd";
import PrintOfficialPackageStamp from "./PrintOfficialPackageStamp";
import apiService from '../../../Package/apiService';
import {printOfficialPackageStamp} from "../actions";
import {t} from '../../../../system/i18n';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    printOfficialPackageStamp: (data) => {
        dispatch(printOfficialPackageStamp(data));
    }
});

class PrintOfficialPackageStampTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }


    componentDidMount() {
        const { match: { params: { code }} } = this.props;

        document.getElementsByTagName('body')[0].classList.add('A5');

        apiService.getDataPrintOfficialPackageStamp(code)
            .then(response => {
                const data = lodash.get(response, 'data');
                this.setState({data});
            })
            .catch(() => {
                notification.error({
                    message: t('common:message.server_error')
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }


    render() {
        const {data} = this.state;
        if (Object.keys(data).length) {
            return (
                <>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"/>
                    <section className="sheet">
                        <PrintOfficialPackageStamp
                            {...data}
                        />
                    </section>
                </>
            );
        }
        return <div>123</div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOfficialPackageStampTest);