import lodash from 'lodash';
import { connect } from 'react-redux';

import { beginCreateAlonePackage, createByBarcode } from './actions';
import { getInputFocus } from './../CreatePackageViaScan/selectors';
import { clearPrintState } from './../../Common/PrintStamp/actions';
import { fetchSuggestWarehouses } from './../../Warehouse/actions';

import FormScanBarcode from './components/FormScanBarcode';

const mapStateToProps = (state) => ({
    ...getInputFocus(state),
    warehouses: lodash.get(state, 'warehouse.suggest', []),
    selectedWarehouse: parseInt(localStorage.getItem('selectedWarehouse')),
    isLoadingPackages: lodash.get(state, 'package.createPackageViaScan.loadingPackages.createPackageByScan', false),
    permissions: lodash.get(state, 'auth.user.permissions', [])
});

const mapDispatchToProps = (dispatch, props) => ({
    beginCreateAlonePackage: (idWarehouse) => {
        dispatch(beginCreateAlonePackage(idWarehouse))
    },
    getListWarehouse: () => {
        dispatch(fetchSuggestWarehouses({active: true, warehouse_permission: true}));
    },
    setSelectedWarehouse: (value) => {
        localStorage.setItem("selectedWarehouse", parseInt(value));
        props.onChange({id_warehouse: value});
    },
    submitForm: (params) => {
        dispatch(clearPrintState());
        props.onSubmit(params.data);
        if (lodash.get(params, 'data.barcode')) {
            dispatch(createByBarcode(params));
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormScanBarcode);
