import { notification } from 'antd';
import { get, includes, toLower } from 'lodash';

import { t } from './../../system/i18n';

export const isExportExcelToEmail = data => get(data, 'export_bags_file') === 'send to email';
export const isExportManifestToEmail = data => get(data, 'message') === 'send_mail';

export const handleExportExcelError = error => {
    const reader = new FileReader();
    let message = t('message.export_excel_failed');

    reader.addEventListener('loadend', event => {
        const text = event.target.result;

        try {
            const parsedError = JSON.parse(text).data;

            if (get(parsedError, 'message') === 'limited') {
                message = t('bag:message.export_file_errors.limit', {
                    limit: get(parsedError, 'limit')
                });
            }
        } catch (e) {
        }

        notification.error({
            message
        });
    });

    reader.readAsText(get(error, 'response.data'));
};

export const handleExportBagDetailError = error => {
    const reader = new FileReader();
    let message = t('export_bag_detail.failed');

    reader.addEventListener('loadend', event => {
        const text = event.target.result;

        try {
            const parsedError = JSON.parse(text).data;

            if (get(parsedError, 'message') === 'limited') {
                message = t('bag:message.export_file_errors.limit', {
                    limit: get(parsedError, 'limit')
                });
            }
        } catch (e) {
        }

        notification.error({
            message
        });
    });

    reader.readAsText(get(error, 'response.data'));
};

export const handleExportManifestsError = error => {
    const reader = new FileReader();
    let message = t('bag:message.error_export_manifest');

    reader.addEventListener('loadend', event => {
        const text = event.target.result;

        try {
            const parsedError = JSON.parse(text).data;

            if (get(parsedError, 'message') === 'limited') {
                message = t('bag:message.export_file_errors.limit', {
                    limit: get(parsedError, 'limit')
                });
            }
        } catch (e) {
        }

        notification.error({
            message
        });
    });

    reader.readAsText(get(error, 'response.data'));
};

export const isNoCheckLanguage = language => {
    return includes(toLower(language), 'nocheck');
};
