import React, { PureComponent } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Icon from './../../../../components/icons/Icon';

const Option = Select.Option;

class FormMultiConsignServices extends PureComponent {
    onSelect = (serviceId, selected, event) => {
        event.preventDefault();
        this.changeService(serviceId, selected);
    };

    changeService = (serviceId, selected) => {
        const { values, onChange } = this.props;

        const newValues = selected
            ? [...values, serviceId]
            : values.filter(value => value !== serviceId);

        onChange(newValues);
    };

    render() {
        const { className, consignServices, disabled, dropdownClassName, emptyOption, error, loading, placeholder, t, values, onChange } = this.props;

        return (
            <Select
                value={values && !loading ? values : []}
                showSearch={true}
                optionFilterProp="children"
                mode="multiple"
                loading={loading}
                disabled={disabled || loading}
                className={className}
                placeholder={placeholder}
                validateStatus={error ? 'error' : null}
                help={error}
                onChange={onChange}
                dropdownClassName={dropdownClassName}
            >
                {emptyOption ? (
                    <Option key="" value="" className="_consign_service_muliti__item">
                        {emptyOption === true ? t('label.all') : emptyOption}
                    </Option>
                ) : null}
                {consignServices.map(service => (
                    <Option
                        className="_consign_service_muliti__item"
                        key={service.id}
                        value={service.id}
                    >
                        <Icon className="mr-2" type={service.icon} />
                        {service.name}
                    </Option>
                ))}
            </Select>
        );
    }
}

FormMultiConsignServices.defaultProps = {
    loading: false,
    services: [],
    values: [],
    onChange: () => {
    },
};

FormMultiConsignServices.propTypes = {
    loading: PropTypes.bool,
    services: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default withTranslation()(FormMultiConsignServices);