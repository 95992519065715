import {all, fork} from 'redux-saga/effects';
import deliveryNoteBarcode from './DeliveryNoteBarcode/sagaWatcher';
import detailDeliveryNote from './DetailDeliveryNote/sagaWatcher';
import updateDeliveryNote from './UpdateDeliveryNote/sagaWatcher';
import listDeliveryNote from './ListDeliveryNote/sagaWatcher';
import deliveryNotePrint from './DeliveryNotePrint/sagaWatcher';
import create from './Create/sagaWatcher';
import formSelectCustomer from './FormSelectCustomer/sagaWatcher';
import formCustomer from './FormCustomer/sagaWatcher';
import returnWatcher from './Return/sagaWatcher';

export default function*() {
    yield all([
        fork(deliveryNoteBarcode),
        fork(detailDeliveryNote),
        fork(updateDeliveryNote),
        fork(listDeliveryNote),
        fork(deliveryNotePrint),
        fork(create),
        fork(formSelectCustomer),
        fork(formCustomer),
        fork(returnWatcher)
    ]);
}
