/**
 *
 * @param input
 * @returns {*}
 * @private
 */
export function converValue2Int(input, excludes = []) {
    Object.keys(input).forEach(function (property) {
            if (!excludes.includes(property) && Array.isArray(input[property])) {
                input[property] = input[property].map(value => {
                    return parseInt(value, 10);
                })
            }

            if (input[property] === 'false') {
                input[property] = false;
            }

            if (input[property] === 'true') {
                input[property] = true;
            }

            return property;
        }
    );
    return input;
}