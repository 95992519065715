import {combineReducers} from 'redux';

import { CREATE_INVENTORY } from './constants';

const adding = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case CREATE_INVENTORY.REQUEST: {
            return true;
        }
        case CREATE_INVENTORY.SUCCESS:
        case CREATE_INVENTORY.FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    adding
});

