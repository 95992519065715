import React from 'react';
import * as _ from 'lodash';
import {Button, Col, Form, Icon, Input, Layout, Row, Switch} from "antd";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import InputNumber from "../../../Common/components/InputNumber";
import {t} from "../../../../system/i18n";
import {router} from "../../../../system/routing";

const Content = Layout.Content;
const TextArea = Input.TextArea;

class FormBagSize extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id: 0,
            name: '',
            length: null,
            width: null,
            height: null,
            weight_min: null,
            weight_max: null,
            note: '',
            active: true
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let bagSizeDetail = nextProps.bagSizeDetail;
        let nextState = {...prevState};
        if(bagSizeDetail.id && bagSizeDetail.id !== prevState.id) {
            nextState.id = bagSizeDetail.id;
            nextState.name = bagSizeDetail.name;
            nextState.length = bagSizeDetail.length * 100;
            nextState.width = bagSizeDetail.width * 100;
            nextState.height = bagSizeDetail.height * 100;
            nextState.weight_min = bagSizeDetail.weight_min;
            nextState.weight_max = bagSizeDetail.weight_max;
            nextState.note = bagSizeDetail.note;
            nextState.active = bagSizeDetail.active;
        }
        return nextState;
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onSubmit() {
        let state = this.state;
        let data = _.omitBy(state, (value) => value === null || value === "" || value === undefined);

        data = {
            ...data,
            name: this.state.name,
            active: this.state.active ? 1 : 0
        };

        if(!state.weight_min) data.weight_min = 0;
        if(!state.weight_max) data.weight_max = 0;

        const {bagSizeDetail} = this.props;

        if(!this.props.bagSizeDetail.id){
            this.props.createBagSize(data);
        } else {
            this.props.editBagSize(bagSizeDetail.id, data);
        }
    }

    onCancel() {
        const {bagSizeDetail} = this.props;
        if(bagSizeDetail.id) {
            return router.redirect(`/config/bag-sizes/${bagSizeDetail.id}`);
        } else {
            return router.redirect('/config/bag-sizes');
        }
    }

    changeNumberValue(name,value) {
        this.setState({[name]: value ? parseInt(value) : null});
        this.props.resetError(name);
    }

    changeTextValue(event) {
        let {name, value} = event.target;
        this.setState({[name]: value});
        this.props.resetError(name);
    }

    changeActiveValue(checked) {
        this.setState({
            active: checked
        })
    }

    render() {
        let {errors, loading, bagSizeDetail} = this.props;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <Link to="/config/bag-sizes" className={"a-text--gray _btn-back"}>
                        <Icon type="arrow-left" className={"mr-2"}/>{t("bag_size:label.back_bag_size")}</Link>
                </div>
                <Form className="a-block-config amt-16 a-form">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t("bag_size:label.info")}</h3>
                        <div>
                            <label className={"a-text--fz-12 a-text--gray mr-2"}>{t("bag_size:label.status")}</label>
                            <Switch checked={this.state.active}
                                    className={"_switch-active"}
                                    size="small" onChange={this.changeActiveValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <Row type="flex" gutter={{lg: 24, xl: 32}} className="a-block-info ">
                            <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 7}}
                                    className="a-form--group">
                                <Form.Item className="a-form__item " label={t("bag_size:label.name")}
                                            labelCol={{span: 24}} required={true}
                                            help={errors.name}
                                            validateStatus={errors.name ? 'error' : null}>
                                    <Input className={"a-input _name"}
                                           value={this.state.name}
                                           name="name"
                                           onChange={this.changeTextValue.bind(this)}
                                           placeholder={t("bag_size:placeholder.name")}/>
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 4}} xxl={{span: 3}} className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={`${t("bag_size:label.length")} (cm)`}
                                    labelCol={{span: 24}}
                                    help={errors.length}
                                    validateStatus={errors.length ? 'error' : null}
                                >
                                    <InputNumber className={"a-input _length"}
                                                 value={this.state.length}
                                                 name="length"
                                                 onChange={this.changeNumberValue.bind(this, "length")}
                                                 placeholder={t("bag_size:placeholder.length")}/>
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 4}} xxl={{span: 3}} className="a-form--group">
                                <Form.Item
                                    className="a-form__item"
                                    label={`${t("bag_size:label.width")} (cm)`}
                                    labelCol={{span: 24}}
                                    help={errors.width}
                                    validateStatus={errors.width ? 'error' : null}
                                >
                                    <InputNumber className={"a-input _width"}
                                                 value={this.state.width} name="width"
                                                 onChange={this.changeNumberValue.bind(this, "width")}
                                                 placeholder={t("bag_size:placeholder.width")}/>
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 4}} xl={{span: 4}} xxl={{span: 3}} className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={`${t("bag_size:label.height")} (cm)`}
                                    labelCol={{span: 24}}
                                    help={errors.height}
                                    validateStatus={errors.height ? 'error' : null}
                                >
                                    <InputNumber className={"a-input _height"}
                                                 value={this.state.height}
                                                 name="height"
                                                 onChange={this.changeNumberValue.bind(this, "height")}
                                                 placeholder={t("bag_size:placeholder.height")}/>
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}} className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={`${t("bag_size:label.weight_max")} (kg)`}
                                    labelCol={{span: 24}}
                                    help={errors.weight_max}
                                    validateStatus={errors.weight_max ? 'error' : null}
                                >
                                    <InputNumber className={"a-input _weight_max"}
                                                 value={this.state.weight_max}
                                                 name="weight_max"
                                                 onChange={this.changeNumberValue.bind(this, "weight_max")}
                                                 placeholder={t("bag_size:placeholder.weight_max")}/>
                                </Form.Item>
                            </Col>
                            <Col lg={{span: 12}} xl={{span: 24}} xxl={{span: 24}} className="a-form--group">
                                <Form.Item
                                    className="a-form__item "
                                    label={t("bag_size:label.note")}
                                    labelCol={{span: 24}}
                                    help={errors.note}
                                    validateStatus={errors.note ? 'error' : null}
                                >
                                    <TextArea className="a-input--textarea _btn-note"
                                              autosize={{minRows: 1, maxRows: 3}}
                                              spellCheck={false}
                                              value={this.state.note}
                                              name="note"
                                              onChange={this.changeTextValue.bind(this)}
                                              placeholder={t("bag_size:placeholder.note")} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={48}>
                            <Col lg={{span: 24}} xl={{span: 24}}
                                    className="a-package--action only-action">
                                <Button className="a-btn a-btn--back-package a-btn--default _btn-cancel"
                                        onClick={this.onCancel.bind(this)}>{t("common:btn.cancel")}</Button>
                                <Button type="primary" htmlType="submit"
                                        className="a-btn a-btn--primary a-btn--save-package _btn-save"
                                        onClick={this.onSubmit.bind(this)}
                                        loading={loading}>{bagSizeDetail.id ? t("common:btn.save") : t("common:btn.add")}</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Content>
        )
    }
}

FormBagSize.defaultProps = {
    bagSizeDetail: {},
};

FormBagSize.propTypes = {
    bagSizeDetail: PropTypes.object,
};

export default FormBagSize;
