import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearState, getReturns } from './actions';
import { getState } from './selectors';

import { setMenuActive } from './../../../Common/actions';

import List from './components';

const ListContainer = props => {
    return (
        <List {...props} />
    );
};

const mapStateToProps = state => {
    return {
        deliveryNotesReturn: getState(state, 'deliveryNotesReturn'),
        loading: getState(state, 'loading'),
        pagination: getState(state, 'pagination')
    };
};

const mapDispatchToProps = {
    setMenuActive,
    clearState,
    getReturns
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListContainer));
