import React from 'react';
import {Button, Checkbox, Col, Input, Layout, Row, Icon} from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import settingHistory from './../settingHistory';
import authService from './../../../Auth/authService';
import permissions from './../../../Auth/permissions';
import {SCAN_ACTION} from './../../../Barcode/constants';
import FormProcessing from './../../../Common/components/FormProcessing';
import ChooseWarehouseContainer from './../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';
import UpdatePackageInfo from "../../component/UpdatePackageInfo";

const {Sider} = Layout;

class ScanBarcodeForm extends FormProcessing {
    state = {
        input: {},
        inputVersion: 0,
        changedParams: [],
        openBagCodes: false,
        bag_codes: [],
        visible: false,
        barcodeInputRef: React.createRef()
    };

    componentDidMount() {
        const {user_id} = this.props;
        const input = localStorage.getItem(`optionScanBarcode_${user_id}`);
        let parsedInput = JSON.parse(input);

        if (lodash.get(parsedInput, 'status_warehouse') === SCAN_ACTION.RECEIVED && !authService.can(permissions.BARCODE_RECEIVE)) {
            parsedInput = {};
        }

        if ((!authService.can(permissions.BARCODE_SCAN_SHIPMENT) || ![SCAN_ACTION.IN, SCAN_ACTION.OUT].includes(lodash.get(parsedInput, 'status_warehouse'))) && lodash.get(parsedInput, 'status_action') === 'shipment') {
            delete parsedInput.status_action;
            delete parsedInput.id_warehouse_destination;
        }

        if (lodash.get(parsedInput, 'status_warehouse') !== SCAN_ACTION.IN && lodash.get(parsedInput, 'status_action') === 'shipment') {
            delete parsedInput.id_warehouse_destination;
        }

        this.changeInput(parsedInput);
    }

    componentDidUpdate(prevProps) {
        const {barcodeScanData} = this.props;
        if (!lodash.isEmpty(barcodeScanData) && !lodash.isEqual(barcodeScanData, prevProps.barcodeScanData)) {
            const {code_warehouse_destination, code_warehouse_scan} = barcodeScanData;
            if (code_warehouse_destination && code_warehouse_destination === code_warehouse_scan) {
                this.setState({
                    visible: true
                });
            }
        }
    }

    /**
     * handle status_warehouse select warehouse
     * @param id_warehouse_scan
     */
    selectWarehouse = id_warehouse_scan => {
        const {input} = this.state;
        const {user_id} = this.props;

        this.changeInput({
            id_warehouse_scan
        });
        this.setStorageBarcode({
            ...input,
            id_warehouse_scan
        });
        localStorage.setItem(`selectedWarehouse_${user_id}`, id_warehouse_scan);
    };

    onChangeAction = (statusWarehouse, action) => {
        const {input} = this.state;

        const newInput = {
            action,
            status_warehouse: statusWarehouse
        };

        if (statusWarehouse === SCAN_ACTION.RECEIVED) {
            newInput.barcode_type = 'FREIGHT_BILL';
        } else if (input.status_warehouse === SCAN_ACTION.RECEIVED) {
            newInput.barcode_type = null;
        }

        if (statusWarehouse === SCAN_ACTION.RECEIVED_BAG) {
            newInput.barcode_type = 'RECEIVED_BAG';
        } 


        if (statusWarehouse !== SCAN_ACTION.IN && input.status_action === 'wrong_flow') {
            newInput.status_action = undefined;
        }

        if (statusWarehouse !== SCAN_ACTION.IN && input.status_action === 'shipment') {
            newInput.id_warehouse_destination = undefined;
        }

        if (![SCAN_ACTION.IN, SCAN_ACTION.OUT].includes(statusWarehouse) && input.status_action === 'shipment') {
            newInput.status_action = undefined;
            newInput.id_warehouse_destination = undefined;
        }

        this.changeInput(newInput, () => {
            const {input} = this.state;

            this.setStorageBarcode(input);
        });
    };

    onScanBarcode = () => {
        const {input} = this.state;
        const {warehouses} = this.props;
        let code_warehouse_scan = null;
        let request_id = 1;

        if (input.barcode) {
            let listDataLocalStorage = settingHistory.getDataHistory();
            listDataLocalStorage = lodash.head(listDataLocalStorage);
            if (!lodash.isEmpty(listDataLocalStorage)) {
                request_id = lodash.get(listDataLocalStorage, 'request_id', 0);
                request_id++;
            }
            let warehouseCurrent = warehouses.filter(dataWarehouseItem => dataWarehouseItem.id === lodash.toInteger(input.id_warehouse_scan));
            warehouseCurrent = lodash.head(warehouseCurrent);
            if (!lodash.isEmpty(warehouseCurrent)) {
                code_warehouse_scan = warehouseCurrent.code;
            }
            if (input.barcode_type === "RECEIVED_BAG") {
                input.bag_code = input.barcode
            }
            this.props[input.action || 'scanBarcode']({
                ...input,
                request_id,
                code_warehouse_scan,
                stage: 'SCAN_IO'
            });
            this.changeInput({
                barcode: ''
            });
        }
    };

    onSubmitMultiBagCode = () => {
        const {input, bag_codes} = this.state;
        const {barcode_type} = input;
        const {warehouses} = this.props;
        let code_warehouse_scan = null;
        let request_id = 1;

        if (bag_codes.length > 0) {
            let listDataLocalStorage = settingHistory.getDataHistory();
            listDataLocalStorage = lodash.head(listDataLocalStorage);
            if (!lodash.isEmpty(listDataLocalStorage)) {
                request_id = lodash.get(listDataLocalStorage, 'request_id', 0);
                request_id++;
            }
            let warehouseCurrent = warehouses.filter(dataWarehouseItem => dataWarehouseItem.id === lodash.toInteger(input.id_warehouse_scan));
            warehouseCurrent = lodash.head(warehouseCurrent);
            if (!lodash.isEmpty(warehouseCurrent)) {
                code_warehouse_scan = warehouseCurrent.code;
            }
            const barcodeTypeValue = (barcode_type === 'BAG' ? "bag_codes" : "package_codes");
            this.props[input.action || 'scanBarcode']({
                ...input,
                bag_codes: barcode_type === 'BAG' ? bag_codes : undefined,
                [barcodeTypeValue]: bag_codes,
                barcode: undefined,
                request_id,
                code_warehouse_scan,
                stage: 'SCAN_IO',

            });
            this.setState(prevState => {
                return {
                    ...prevState,
                    bag_codes: []
                }
            })
        }
    };

    setStorageBarcode = input => {
        const {user_id} = this.props;

        localStorage.setItem(`optionScanBarcode_${user_id}`, JSON.stringify(lodash.omit(input, ['barcode'])));
    };

    onChangeBarcodeType = barcode_type => {
        let {bag_codes} = this.state.input;
        if (barcode_type !== 'BAG' || this.state.input.barcode_type === barcode_type) {
            bag_codes = []
        }
        this.setState({
            input: {
                ...this.state.input,
                bag_codes,
                barcode_type: this.state.input.barcode_type === barcode_type ? null : barcode_type
            }
        })
    };

    handleChange = (field, value) => {
        this.setState(prevState => {
            const newInput = {
                ...prevState.input,
                [field]: prevState.input[field] === value ? undefined : value
            };

            if (newInput.status_action !== 'shipment') {
                newInput.id_warehouse_destination = undefined;
            }

            return {
                input: newInput
            };
        });
    };

    handleOpenBagCodes = () => {
        const {openBagCodes, bag_codes} = this.state;
        if (!openBagCodes) {

        }
        this.setState(prevState => {
            return {
                ...prevState,
                openBagCodes: !openBagCodes
            }
        })
    };

    onChangeBagCodes = (event) => {
        const {value} = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                bag_codes: value.split("\n")
            }
        })
    };

    handleCloseModal = () => {
        this.setState(preState => {
            return {
                ...preState,
                visible: false
            }
        });
        const {barcodeInputRef} = this.state;
        if (barcodeInputRef.current) {
            barcodeInputRef.current.focus();
        }
    };

    render() {
        const {scroll, t, user_id, warehouses, setting, barcodeScanData, dimensionSetting, agencySetting, barcodeScanDataPackage
        } = this.props;
        const id_agency = lodash.get(barcodeScanDataPackage, "id_agency");
        const canSimpleOperation = lodash.get(lodash.find(agencySetting, {code: "CONFIG:SIMPLE_OPERATION", id_agency}), "value", lodash.get(lodash.find(setting, ["code", "CONFIG:SIMPLE_OPERATION"]), "value", ));
        const importRequiredNet = lodash.get(lodash.find(agencySetting, {code : "PACKAGE:IMPORT_REQUIRE_WEIGHT", id_agency}), "value", lodash.get(lodash.find(setting, ["code", "PACKAGE:IMPORT_REQUIRE_WEIGHT"]), "value", false));
        const canScanMultiplePackage = lodash.get(lodash.find(setting, ["code", "PACKAGE:MULTIPLE_SCANS"]), "value", false);
        const canScanMultipleBag = lodash.get(lodash.find(setting, ["code", "BAG:MULTIPLE_SCANS"]), "value", false);

        const isConfigFreightBillIsPackage = true;

        let {input, openBagCodes, bag_codes, visible, barcodeInputRef} = this.state;
        let code_warehouse_scan = null;
        let warehouseCurrent = warehouses.filter(dataWarehouseItem => dataWarehouseItem.id === input.id_warehouse_scan);
        warehouseCurrent = lodash.head(warehouseCurrent);

        let canScanMultiple = false

        if (input.barcode_type == 'BAG' && canScanMultipleBag && authService.can('BAG:MULTIPLE_SCANS')) {
            canScanMultiple = true
        }

        if (input.barcode_type == 'PACKAGE' && canScanMultiplePackage && authService.can('PACKAGE:MULTIPLE_SCANS')) {
            canScanMultiple = true
        }

        if (!lodash.isEmpty(warehouseCurrent)) {
            code_warehouse_scan = warehouseCurrent.code;
        }

        if (!code_warehouse_scan) {
            input = {
                ...input,
                id_warehouse_scan: undefined
            };
        }

        const statusWarehouse = input.status_warehouse
        const barcodeType = input.barcode_type
        let labelMultiScan = 'scan_multi_bag_in'
        let placeholderMultiScan = 'placeholder.scan_multi_bag'

        if (barcodeType == 'BAG') {
            statusWarehouse == 'IN' ? labelMultiScan = 'scan_multi_bag_in' : labelMultiScan = 'scan_multi_bag_out'
        }

        if (barcodeType == 'PACKAGE') {
            statusWarehouse == 'IN' ? labelMultiScan = 'scan_multi_package_in' : labelMultiScan = 'scan_multi_package_out'
            placeholderMultiScan = 'placeholder.scan_multi_package'
        }

        return (
            <Sider className={clsx('a-sider-sub a-sider-sub--radius', {
                scroll
            })}>
                <div className="a-sider-sub__block mt-5">
                    <h4 className="a-sider-sub__block__title">{t('action')}</h4>
                    <Row gutter={8} type="flex">
                        {lodash.values(SCAN_ACTION).map(actionItem => {
                            if (actionItem === SCAN_ACTION.CHECKING || (actionItem === SCAN_ACTION.RECEIVED && !authService.can(permissions.BARCODE_RECEIVE))) {
                                return null;
                            }

                            return (
                                <Col key={actionItem}>
                                    <Button
                                        className={clsx(`a-btn a-btn--w-100 _btn_action_barcode_scan _btn_action_barcode_scan_${actionItem.toLowerCase()} mb-3`, {
                                            'a-btn--primary': input.status_warehouse === actionItem,
                                            'a-btn--default': input.status_warehouse !== actionItem
                                        })}
                                        type={input.status_warehouse === actionItem ? 'primary' : 'default'}
                                        onClick={this.onChangeAction.bind(this, actionItem, actionItem === SCAN_ACTION.RECEIVED ? 'scanReceived' : actionItem === SCAN_ACTION.RECEIVED_BAG ?  'scanReceivedBag' : 'scanBarcode')}
                                    >
                                        {t(`scan:label.${actionItem}`)}
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                {!!input.status_warehouse && (
                    <div className="a-sider-sub__block mt-5">
                        <div>
                            {/*{input.status_warehouse === SCAN_ACTION.IN && (*/}
                            {/*    <Checkbox*/}
                            {/*        checked={input.status_action === 'wrong_flow'}*/}
                            {/*        className="_checkbox-status-action _wrong-flow mb-3"*/}
                            {/*        onChange={this.handleChange.bind(this, 'status_action', 'wrong_flow')}*/}
                            {/*    >*/}
                            {/*        {t('scan:wrong_flow')}*/}
                            {/*    </Checkbox>*/}
                            {/*)}*/}
                            {authService.can(permissions.BARCODE_SCAN_SHIPMENT) && [SCAN_ACTION.IN, SCAN_ACTION.OUT].includes(input.status_warehouse) && (
                                <Checkbox
                                    checked={input.status_action === 'shipment'}
                                    className="_checkbox-status-action _shipment ml-0"
                                    onChange={this.handleChange.bind(this, 'status_action', 'shipment')}
                                >
                                    {t('shipment')}
                                </Checkbox>
                            )}
                        </div>
                    </div>
                )}
                {input.status_action === 'shipment' && input.status_warehouse === SCAN_ACTION.IN && authService.can(permissions.BARCODE_SCAN_SHIPMENT) && (
                    <div className="a-sider-sub__block ">
                        <h4 className="a-sider-sub__block__title">{t('destination_warehouse')}</h4>
                        <ChooseWarehouseContainer
                            allowClear
                            autoSelect={false}
                            buttonClassName="_select-warehouse-destination"
                            dropdownClassName="_dropdown-warehouse-destination"
                            isPermission={false}
                            selectClassName="_select-warehouse-destination"
                            value={input.id_warehouse_destination}
                            onChange={this.handleChange.bind(undefined, 'id_warehouse_destination')}
                        />
                    </div>
                )}
                <div className="a-sider-sub__block ">
                    <h4 className="a-sider-sub__block__title">{t('scan:label.operation')}</h4>
                    <ChooseWarehouseContainer
                        buttonClassName="_select-create-warehouse"
                        isPermission={true}
                        value={input.id_warehouse_scan}
                        disabled={!input.status_warehouse}
                        selectClassName="_select-create-warehouse"
                        onChange={this.selectWarehouse}
                        active={true}
                        keyLocalStorage={`selectedWarehouse_${user_id}`}
                    />
                </div>
                <div className="a-sider-sub__block">
                    <h4 className="a-sider-sub__block__title">{t('scan')}</h4>
                    {isConfigFreightBillIsPackage && (
                        <>
                            <div className="a-sider-sub__block pl-2">
                                {input.status_warehouse === SCAN_ACTION.RECEIVED ? (
                                    <Checkbox
                                        checked={input.barcode_type === 'FREIGHT_BILL'}
                                        className="_checkbox-input-freight-bill"
                                        disabled={!input.status_warehouse}
                                    >
                                        {t('freight_bill')}
                                    </Checkbox>
                                ) : input.status_warehouse === SCAN_ACTION.RECEIVED_BAG  ? 
                                    <Checkbox
                                            checked={input.barcode_type === 'RECEIVED_BAG'}
                                            className="_checkbox-input-bag"
                                            disabled={true}
                                            onChange={this.onChangeBarcodeType.bind(this, 'RECEIVED_BAG')}
                                        >
                                            {t('bag')}
                                    </Checkbox> : ( 
                                        <>
                                            <Checkbox
                                                checked={input.barcode_type === 'BAG'}
                                                className="_checkbox-input-bag"
                                                disabled={!input.status_warehouse}
                                                onChange={this.onChangeBarcodeType.bind(this, 'BAG')}
                                            >
                                                {t('bag')}
                                            </Checkbox>
                                            <Checkbox
                                                checked={input.barcode_type === 'PACKAGE'}
                                                className="_checkbox-input-package"
                                                disabled={!input.status_warehouse}
                                                onChange={this.onChangeBarcodeType.bind(this, 'PACKAGE')}
                                            >
                                                {t('package')}
                                            </Checkbox>
                                        </>
                                )}
                            </div>

                            {/*{*/}
                            {/*    input.barcode_type === 'BAG' && input.status_warehouse === SCAN_ACTION.IN &&*/}
                            {/*    <div className="a-sider-sub__block pl-2">*/}
                            {/*        <Checkbox*/}
                            {/*            checked={!!input.import_package_by_bag}*/}
                            {/*            className="_checkbox-import-package-by-bag"*/}
                            {/*            disabled={!input.status_warehouse}*/}
                            {/*            onChange={this.onChangeInput.bind(this, "import_package_by_bag",)}*/}
                            {/*        >*/}
                            {/*            {t('import_package_by_bag')}*/}
                            {/*        </Checkbox>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </>
                    )}


                    <Input
                        ref={barcodeInputRef}
                        className={clsx('a-input--scan-code _input_scan_code', {
                            'a-input--disable': (!input.status_warehouse || !input.id_warehouse_scan || openBagCodes)
                        })}
                        value={input.barcode}
                        onChange={this.onChangeInput.bind(this, 'barcode')}
                        onPressEnter={this.onScanBarcode}
                    />
                </div>
                {
                    canScanMultiple &&
                    <div className="a-sider-sub__block">
                        <div className="gflex a-sider-sub__block__title cursor-pointer"
                             onClick={this.handleOpenBagCodes}>
                            <div>{t(`${labelMultiScan}`)}</div>
                            <div className="ml-3">
                                {openBagCodes ?
                                    <Icon type="up"/> : <Icon type="down"/>}
                            </div>
                        </div>
                        {
                            openBagCodes &&
                            <>
                                <Input.TextArea
                                    className={clsx('_input_multi_bag_codes', {
                                        'a-input--disable': !input.status_warehouse || !input.id_warehouse_scan
                                    })}
                                    value={bag_codes.join("\n")}
                                    onChange={this.onChangeBagCodes.bind(this)}
                                    placeholder={t(`${placeholderMultiScan}`)}
                                    autosize={{minRows: 5, maxRows: 8}}
                                />
                                <Button className="mt-3 float-right" type="primary"
                                        onClick={this.onSubmitMultiBagCode}>{t("btn.confirm")}</Button>
                            </>
                        }

                    </div>
                }

                {
                    visible && (canSimpleOperation || importRequiredNet) && input.status_warehouse === SCAN_ACTION.IN && input.barcode_type === 'PACKAGE' &&
                    <UpdatePackageInfo visible={visible} closeModal={this.handleCloseModal}
                                       barcodeScanData={barcodeScanData} dimensionSetting={dimensionSetting}/>
                }

            </Sider>
        )
    }
}

ScanBarcodeForm.defaultProps = {
    setSelectedWarehouse: () => {
    },
    scanBarcode: () => {
    }
};

ScanBarcodeForm.propTypes = {
    setSelectedWarehouse: PropTypes.func,
    scanBarcode: PropTypes.func
};

export default withTranslation()(ScanBarcodeForm);
