import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Button} from 'antd';
import lodash from 'lodash';
import PopupDeletePackage from "./PopupDeletePackage";

class DeletePackage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenDeleteConfirm: false,
        }
    }

    /**
     * handle action show modal confirm
     */
    confirmDelete() {
        this.setState({isOpenDeleteConfirm: true});
    }

    /**
     * handle action click ok to unlink order
     */
    handleOnOk = (data) => {
        let {packageData} = this.props;
        this.setState({isOpenDeleteConfirm: true});
        this.props.onDeletePackage(packageData.code, data);
    };

    /**
     * handle action close modal
     */
    handleOnClose = () => {
        this.setState({isOpenDeleteConfirm: false});
        this.props.endDeletePackage();
    };

    render() {
        let {t, loading, packageData} = this.props;
        const {isOpenDeleteConfirm} = this.state;
        return lodash.get(packageData, 'permissions.can_delete') ? (
            <span>
                <Button loading={loading} onClick={this.confirmDelete.bind(this)}
                        className="a-btn--button-link a-text--blue _btn_delete_package">
                    {t('package:label.delete_package')}
                </Button>

                {isOpenDeleteConfirm && (
                    <PopupDeletePackage
                        loading={loading}
                        packageData={packageData}
                        onOk={this.handleOnOk}
                        onCancel={this.handleOnClose}
                    />
                )}
            </span>
        ) : null;
    }
}

DeletePackage.defaultProps = {
    packageData: {},
    isModalProcessing: false,
    loading: false
};

DeletePackage.propTypes = {
    packageData: PropTypes.object,
    onDeletePackage: PropTypes.func,
    isModalProcessing: PropTypes.bool,
    loading: PropTypes.bool,
    beginDeletePackage: PropTypes.func,
    endDeletePackage: PropTypes.func,

};

export default withTranslation()(DeletePackage);
