import React, { Fragment, memo } from 'react';
import { Icon } from 'antd/lib/index';
import { withTranslation } from 'react-i18next';

import { dateFormatter } from './../../../../Common/services/format';
import { getVar } from './../../../../../system/support/helpers';

import { Link } from './../../../../../system/routing';

const WarningItem = ({ item, index, t }) => (
	<tbody key={index} className="warning">
		<tr className="data">
			<td>{index + 1}</td>
			<td>
				{item.inventory_tracking_bill.tracking_bill}
			</td>
			<td>{getVar(item, 'inventory_tracking_bill.created_at', '--') === '--' ? '--' : dateFormatter.dateTime(item.inventory_tracking_bill.created_at)} </td>
			<td>
				{getVar(item, 'inventory_tracking_bill.related_packages') && item.inventory_tracking_bill.related_packages.length ? item.inventory_tracking_bill.related_packages.map((pkg, index) => (
					<Fragment key={pkg}>
						<Link isNewTab params={{id: pkg}} to="packages.detail">{pkg}</Link>
						{index < item.inventory_tracking_bill.related_packages.length - 1 ? ', ' : null}
					</Fragment>
				)) : '--'}
			</td>
		</tr>
		<tr className="message">
			<td colSpan="4">
				<span className="a-text--black-45 gflex">
					<Icon type='info-circle' theme="filled" className="a-text--orange mr-2" />
					<div>
						{item.warnings.map((warning, index) => {
							const children = [];

							if (warning.order) {
								children.push(t(`inventory:warning.order`, {
									order: warning.order
								}));
							} else {
								children.push(t(`inventory:warning.${warning}`));
							}

							if (index !== item.warnings.length - 1) {
								children.push(<br />);
							}

							return children;
						})}
					</div>
				</span>
			</td>
		</tr>
	</tbody>
);

export default memo(withTranslation()(WarningItem));
