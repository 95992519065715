/* eslint-disable */
import React, { Component } from 'react';
import { Icon, Select } from 'antd';
import { isFunction, map } from 'lodash';

const { Option } = Select;

class InlineEditSelect extends Component {
    state = {
        editing: false,
        loading: false,
        value: this.props.value
    };

    handleEditButtonClick = () => {
        this.setState({
            editing: true
        });
    };

    handleCancelButtonClick = () => {
        this.cancelEdit();
    };

    cancelEdit = () => {
        this.setState({
            editing: false,
            value: this.props.value
        });
    };

    handleChange = value => {
        this.setState({
            value
        });
    };

    handleSaveButtonClick = async () => {
        const { value } = this.state;
        const { onSubmit } = this.props;

        this.setState({
            loading: true
        });

        try {
            if (isFunction(onSubmit)) {
                await onSubmit(value);
            }

            this.cancelEdit();
        } catch (error) {
        }

        this.setState({
            loading: false
        });
    };

    render() {
        const { editing, loading, value } = this.state;
        const { canEdit, label, options, renderValue } = this.props;

        return (
            <div className="a-inline-edit">
                <label className="a-inline-edit--label">
                    {label}

                    {canEdit && !editing && (
                        <Icon
                            className="icon-inline"
                            type="edit"
                            onClick={this.handleEditButtonClick}
                        />
                    )}

                    {editing && (
                        <>
                            <Icon
                                className="icon-inline"
                                type="close"
                                onClick={this.handleCancelButtonClick}
                            />
                            <Icon
                                className="icon-inline"
                                type={loading ? 'loading' : 'check'}
                                onClick={this.handleSaveButtonClick}
                            />
                        </>
                    )}
                </label>

                {!editing && (
                    <div className="a-inline-edit--value">
                        {isFunction(renderValue) ? renderValue(value) : value}
                    </div>
                )}
                {editing && (
                    <div className="a-inline-edit--input">
                        <Select
                            className="a-select a-select--w-100"
                            value={value}
                            onChange={this.handleChange}
                        >
                            {map(options, option => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        );
    }
}

export default InlineEditSelect;