import {combineReducers} from 'redux';
import * as ACTION from '../constants';

let detailPackage = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGE_DETAIL.SUCCESS:
            return {...action.payload};
        default:
            return state;
    }
};


let loading = (state = false, action) => {
    switch (action.type) {
        case ACTION.FETCH_PACKAGE_DETAIL.REQUEST:
            return true;
        case ACTION.FETCH_PACKAGE_DETAIL.SUCCESS:
        case ACTION.FETCH_PACKAGE_DETAIL.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    detailPackage,
    loading,
});
