import { Empty, Table, Button, notification } from 'antd'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Pagination from '../../../Common/components/Pagination'
import { toInteger, values } from 'lodash'
import { PACKAGE_IN_BAG_REQUEST_COLUMN } from '../constants'
import ColumnSetting from './ColumnSetting'
import settingService from './settingService'
import apiService from '../../apiService'
import { convertBlobDataToExcel } from '../../../../system/support/helpers'

function ListPackage(props) {
    const { t, packages, loading, pagination, refetch, data } = props
    const [filter, setFilter] = useState({})
    const [activeColumns, setActiveColumns] = useState([])
    const [isOpenListSetting, setIsOpenListSetting] = useState(false)
    const [exporting, setExporting] = useState(false)

    const onChangePage = (page, pageSize) => {
        const filter = { page, per_page: pageSize }
        if (!loading) {
            setFilter(filter)
            refetch(filter)
        }
    }

    const getColumns = () => {
        let arrSort = [PACKAGE_IN_BAG_REQUEST_COLUMN.CREATED_AT]
        return values(PACKAGE_IN_BAG_REQUEST_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    className: column === PACKAGE_IN_BAG_REQUEST_COLUMN.PACKAGE_CODE ? 'a-text--nowrap _tracking_no_column' : 'a-text--nowrap',
                    key: column,
                    dataIndex: column,
                    title: t('package:column.' + column),
                }

                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === filter.sort_by ? (filter.sort_direction === 'desc' ? 'descend' : 'ascend') : null,
                    }
                }
                return res
            })
    }

    const handleDownloadList = async () => {
        const { id, code } = data
        setExporting(true)
        try {
            const { data } = await apiService.downloadPackageListPackingRequest(id)
            convertBlobDataToExcel(data, `export_packages_of_packing_request_${code}`)
            notification.success({
                message: t('message.export_excel_success'),
            })
        } catch (e) {
            notification.error({
                message: t('message.export_excel_failed'),
            })
        } finally {
            setExporting(false)
        }
    }

    return (
        <div className="list-package-in-bag">
            <div className="a-content__top a-content__top--page-list mr-0">
                <div
                    className="a-content--page-list__title"
                    id="title-search">
                    <h3>{t('bag:packing.package_list')}</h3>
                </div>
                <div
                    className="a-content--page-list__top-action gflex galign-center"
                    id="content-list">
                    <Button
                        type="primary"
                        className="mr-2 a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                        loading={exporting}
                        onClick={handleDownloadList}>
                        {t('bag:btn.download_list')}
                    </Button>
                    <ColumnSetting
                        options={settingService.options}
                        onChange={activeColumns => setActiveColumns(activeColumns)}
                        loading={loading}
                        isOpenListSetting={isOpenListSetting}
                        onOpenListSetting={isOpenListSetting => setIsOpenListSetting(isOpenListSetting)}
                    />
                </div>
            </div>
            <div className="a-content__body bg-white a-content__body--page-list mr-0">
                <div className="a-table--responsive">
                    <Table
                        className={'a-table--page-list'}
                        loading={loading}
                        columns={getColumns()}
                        rowKey={record => record.id}
                        dataSource={packages}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('common:label.no_data')}</span>}></Empty>
                            ),
                        }}
                    />
                    <Pagination
                        page={toInteger(filter.page) || 1}
                        pageSize={toInteger(filter.per_page)}
                        total={pagination ? pagination.total : 0}
                        loading={loading}
                        onChange={onChangePage}
                    />
                </div>
            </div>
        </div>
    )
}
export default withTranslation()(ListPackage)
