import {message} from 'antd';
import {t} from '../../../system/i18n';
import apiService from './../apiService';
import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';

export function* fetchPrintPackage(action) {
    yield processApiRequest(ACTION.FETCH_PRINT_PACKAGE, () => apiService.getPackagePrint(action.payload.id), action.payload);
}

export function* onFetchPrintPackageFailed(action) {
    if (action.payload) {
        yield message.error(t('package:create.message_cant_load_barcode_packages'));
    }
}