import { api } from './../../system/api';

const BASE_URL = '/storage-fees';

export default {
    getStorageFees: params => api.get(BASE_URL, {
        params
    }),
    add: data => api.post(BASE_URL, data),
    update: data => api.put(`${BASE_URL}/${data.id}`, data)
};
