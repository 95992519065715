import React, { Component } from 'react';
import { Button, Empty, Icon, Modal, notification } from 'antd';
import {get, map, find, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { url } from './../../../../../system/routing';
import { convertBlobDataToExcel } from './../../../../../system/support/helpers';

import ItemDeliveryNoteExport from './ItemDeliveryNoteExport';

const TYPE_EXPORT_NORMAL = 'export_normal';
const TYPE_EXPORT_LIST_BAGS = 'export_list_bags';

class ListDeliveryNoteExport extends Component {
    state = {
        exportingExcel: false,
        exportingListBags: false,
    };

    onEdit = () => {
        const { deliveryNoteTransportWarehouse } = this.props;
        url.redirectTo('delivery-notes.transport-warehouse.edit', {
            id: get(deliveryNoteTransportWarehouse, 'id', 0)
        });
    };

    handleCancel = () => {
        const { t, onCancel } = this.props;

        Modal.confirm({
            cancelText: t('delivery_note:label.cancel'),
            centered: true,
            content: <p>{t('delivery_note:label.warning_confirm_cancel')}</p>,
            icon: <Icon type="question-circle" theme="filled" />,
            okText: t('delivery_note:label.ok'),
            title: t('delivery_note:label.warning'),
            onOk: onCancel
        });
    };

    handleExport = () => {
        const { t, onExport } = this.props;

        Modal.confirm({
            cancelText: t('delivery_note:label.cancel'),
            content: <p>{t('delivery_note:label.warning_confirm_export')}</p>,
            okText: t('delivery_note:label.ok'),
            title: t('delivery_note:label.warning'),
            onOk: onExport
        });
    };

    handlePrintButtonClick = () => {
        this.props.onPrint();
    };

    handleExportExcelButtonClick = async () => {
        const { deliveryNoteTransportWarehouse, t } = this.props;

        this.setState({
            exportingExcel: true
        });

        try {
            const { data } = await apiService.exportExcelForTransportWarehouse(
                get(deliveryNoteTransportWarehouse, 'id'),
                TYPE_EXPORT_NORMAL
            );

            convertBlobDataToExcel(data, 'transport-warehouse');

            notification.success({
                message: t('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_failed')
            });
        }

        this.setState({
            exportingExcel: false
        });
    };

    handleExportExcelListBagsButtonClick = async () => {
        const { deliveryNoteTransportWarehouse, t } = this.props;

        this.setState({
            exportingListBags: true
        });

        try {
            const { data } = await apiService.exportExcelForTransportWarehouse(
                get(deliveryNoteTransportWarehouse, 'id'),
                TYPE_EXPORT_LIST_BAGS
            );

            convertBlobDataToExcel(data, 'transport-warehouse-list-bags');

            notification.success({
                message: t('message.export_excel_list_bags_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_list_bags_failed')
            });
        }

        this.setState({
            exportingListBags: false
        });
    };

    render() {
        const { exportingExcel, exportingListBags } = this.state;
        const { cancelling, deliveryNote, deliveryNoteTransportWarehouse, exporting, permissions, t } = this.props;
        const status = get(deliveryNoteTransportWarehouse, 'status');
        const canPrint = !!(get(permissions, 'can_print') && status === 'EXPORTED');
        const isCustomWarehouse = get(deliveryNote, 'warehouse.is_custom_warehouse', false);
        const packagesData = get(deliveryNote, "packages", []);
        const bagsData = get(deliveryNote, "bags", []);
        const bagCodes = map(bagsData, "bag.code")
        const data = [];
        get(deliveryNote, 'outside_bags', []).map(item => {
            const code = get(item, "outside_bag.code")
            const bagInfo = find(bagsData, ['bag.code', code])
            let packageInfo = find(packagesData, ['package.code', code])
            if( isEmpty(packageInfo) ) {
                packageInfo = find(packagesData, ['package.tracking_no', code])
            }

            if ( isEmpty(packageInfo) ) {
                if (bagCodes.includes(code)){
                    data.push({...item, ...bagInfo}) 
                } else {
                    if (isCustomWarehouse) {
                        data.push({
                            ...item,
                            bag: isEmpty(bagInfo) ? item.outside_bag : get(bagInfo, "bag", {}), 
                        })
                    }
                }
            } else {
                data.push({...item, ...packageInfo});
            }
            return true;
        })
        return (
            <>
                <div className="a-content__top a-content__top--page-list asticky-top abg-white a-zindex--10 mr-0 mt-0">
                    <div className="a-content--page-list__title">
                        <h3>{t('delivery_note:label.list_package_export')} ({data.length})</h3>
                    </div>
                    <div className="a-content--page-list__top-action">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3"
                            loading={exportingListBags}
                            type="primary"
                            onClick={this.handleExportExcelListBagsButtonClick}
                        >
                            {t('btn.export_list_bag_excel')}
                        </Button>
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3"
                            loading={exportingExcel}
                            type="primary"
                            onClick={this.handleExportExcelButtonClick}
                        >
                            {t('btn.export_excel')}
                        </Button>

                        {canPrint && (
                            <Button
                                className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular ml-3"
                                type="primary"
                                onClick={this.handlePrintButtonClick}
                            >
                                {t('delivery_note:label.print')}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list  mr-0 aflex-1 pb-0">
                    <div className="a-table--responsive">
                        {data.length ? (
                            <table className="a-table a-table--isdata--warning-error">
                                <thead>
                                    <tr>
                                        <th>{t('delivery_note:label.export_code')}</th>
                                        <th></th>
                                        <th>{t('barcode:scanning_history.column.created_at')}</th>
                                        <th>{t('status')}</th>
                                        <th>{t('weight')}</th>
                                        <th>{t('package:label.volume')}</th>
                                    </tr>
                                </thead>

                                {data.map((item, index) => (
                                    <ItemDeliveryNoteExport
                                        {...this.props}
                                        data={item}
                                        key={index}
                                    />
                                ))}
                            </table>
                        ) : (
                            <div className="mb-3">
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t('message.no_data')}</span>}
                                />
                            </div>
                        )}
                    </div>

                    {(get(permissions, 'can_update') || get(permissions, 'can_cancel') || get(permissions, 'can_export')) ? (
                        <div className="a-pagination a-pagination--fixed a-pagination--height-action gflex gjustify-space-between p-5">
                            {get(permissions, 'can_update') ? (
                                <Button
                                    className="a-btn a-btn--button-link a-text--blue"
                                    onClick={this.onEdit}
                                >
                                    {t('delivery_note:label.edit')}
                                </Button>
                            ) : null}
                            <div>
                                {get(permissions, 'can_cancel') ? (
                                    <Button
                                        className="a-btn a-btn--button-link a-text--blue mr-4"
                                        loading={cancelling}
                                        onClick={this.handleCancel}
                                    >
                                        {t('delivery_note:label.cancel')}
                                    </Button>
                                ) : null}

                                {get(permissions, 'can_export') ? (
                                    <Button
                                        className="ant-btn a-btn a-btn--primary"
                                        loading={exporting}
                                        size="large"
                                        type="primary"
                                        onClick={this.handleExport}
                                    >
                                        {t('delivery_note:label.export_warehouse')}
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

ListDeliveryNoteExport.defaultProps = {
    permission: []
};

ListDeliveryNoteExport.propTypes = {
    permission: PropTypes.array
};

export default withTranslation()(ListDeliveryNoteExport);
