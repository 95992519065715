/* eslint-disable */
import React, { Component } from 'react';
import { Button, Checkbox, Dropdown, Empty, Icon, Menu, notification, Table } from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LIST_COLUMN } from './../constants';
import apiService from './../../apiService';
import Pagination from './../../../../Common/components/Pagination';
import { convertBlobDataToExcel } from './../../../../../system/support/helpers';
class ListDeliveryNote extends Component {
    constructor (props) {
        super(props);
        let activeColumns = lodash.values(LIST_COLUMN);
        let defaultSetting = lodash.values(activeColumns).map(column => ({
            key: column,
            disabled: column === LIST_COLUMN.CODE,
            selected: true
        }));
        this.state = {
            activeColumns,
            listSetting: defaultSetting,
            dropdownVisible: false,
            selectedRowKeys: [],
            exporting: false
        }
    }

    onChangePage = (page, pageSize) => {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    };

    componentDidMount () {
        let listSetting = localStorage.getItem('list_delivery_note_global_setting_' + this.props.user.id);
        if (listSetting) {
            this.setState({listSetting: JSON.parse(listSetting)})
        }
    }

    onChangeSetting (key, selected) {
        let {listSetting} = this.state;
        listSetting = lodash.values(listSetting).map(setting => ({
            ...setting,
            selected: key === setting.key ? !selected : setting.selected,
        }));
        localStorage.setItem('list_delivery_note_global_setting_' + +this.props.user.id, JSON.stringify(listSetting));
        this.setState({listSetting})
    }


    onOpenSetting = () => {
        this.setState({
            dropdownVisible: !this.state.dropdownVisible
        });
    };

    getMenu = () => {
        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={this.onOpenSetting} type="close" />
                </div>
                {this.state.listSetting.map(setting => setting.key !== LIST_COLUMN.ACTION ? (
                    <Menu.Item
                        key={setting.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            checked={setting.selected}
                            className="a-checkbox--set-field"
                            disabled={setting.disabled}
                            onChange={this.onChangeSetting.bind(this, setting.key, setting.selected)}
                        >
                            {this.props.t(`delivery_note:column.${setting.key}`)}
                        </Checkbox>
                    </Menu.Item>
                ) : null)}
            </Menu>
        );
    };

    getColumns () {
        let arrSort = [LIST_COLUMN.CREATE_AT, LIST_COLUMN.EXPORTED_AT, LIST_COLUMN.PACKET_NUMBER];
        let listSetting = this.state.listSetting.filter(setting => setting.selected);
        let activeColumns = listSetting.map(setting => setting.key);
        return lodash.values(LIST_COLUMN)
            .filter(column => activeColumns.includes(column))
            .map(column => {
                let res = {
                    className: column === LIST_COLUMN.ACTION ? "a-text--right" : "a-text--nowrap",
                    key: column,
                    dataIndex: column,
                    title: column === LIST_COLUMN.ACTION ? "" : this.props.t('delivery_note:column.' + column),
                };

                if (arrSort.indexOf(column) > -1) {
                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: column === this.props.filter.sort_by
                            ? (this.props.filter.sort_direction === 'desc' ? 'descend' : 'ascend')
                            : null,
                    }
                }
                return res;
            });
    }

    onChange = (pagination, filters, sorter) => {
        this.search({
            sort_by: sorter.field || 'created_at',
            sort_direction: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    };

    search (input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            });
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({
            selectedRowKeys
        });
    };

    handleExportExcelButtonClick = async () => {
        const { selectedRowKeys } = this.state;
        const { t } = this.props;

        this.setState({
            exporting: true
        });

        try {
            const { data } = await apiService.exportExcelForTransportWarehouses({
                ids: selectedRowKeys
            });

            convertBlobDataToExcel(data, 'transport-warehouses');

            notification.success({
                message: t('message.export_excel_success')
            });
        } catch (error) {
            notification.error({
                message: t('message.export_excel_failed')
            });
        }

        this.setState({
            exporting: false
        });
    };

    render () {
        const { exporting, selectedRowKeys } = this.state;
        const { delivery_notes, filter, loading, pagination, t } = this.props;

        return (
            <>
                <div className="a-content__top a-content__top--page-list">
                    <div className="a-content--page-list__title">
                        <h3>{t('transport_warehouse_delivery_note_list')} ({lodash.get(pagination, 'total', 0)})</h3>
                    </div>
                    <div className="a-content--page-list__top-action gflex">
                        <Button
                            className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3"
                            loading={exporting}
                            type="primary"
                            onClick={this.handleExportExcelButtonClick}
                        >
                            {t('btn.export_excel')}
                        </Button>
                        <Dropdown
                            overlay={this.getMenu()}
                            overlayClassName={'a-dropdown--set-field'}
                            trigger={['click']}
                            visible={this.state.dropdownVisible}
                            onClick={this.onOpenSetting}
                        >
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                    ghost>{t('delivery_note:btn.setting_option')}
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
                    <div className="a-table--responsive">
                        <Table
                            className="a-table--antd a-table--delivery_note"
                            columns={this.getColumns()}
                            dataSource={delivery_notes}
                            loading={loading}
                            locale={{
                                emptyText: (
                                    <Empty
                                        description={(
                                            <span>{t('label.no_data')}</span>
                                        )}
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    />
                                )
                            }}
                            pagination={false}
                            rowKey={record => record.key}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: this.onSelectChange
                            }}
                            onChange={this.onChange}
                        />
                    </div>
                    <Pagination
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        total={pagination ? pagination.total : 0}
                        loading={loading}
                        onChange={this.onChangePage}
                    />
                </div>
            </>
        )
    }
}

ListDeliveryNote.defaultProps = {
    filter: {},
    loading: false,
    delivery_notes: [],
};

ListDeliveryNote.propTypes = {
    filter: PropTypes.object,
    delivery_notes: PropTypes.array,
    loading: PropTypes.bool,
};

export default withTranslation()(ListDeliveryNote);
