import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash, { get } from 'lodash';
import queryString from 'query-string';
import PrintStampPackage from '../../../Common/PrintStamp/components/PrintStampPackage';

class PrintPackageStamp extends React.PureComponent {
    componentDidMount() {
        const {location, match} = this.props;
        let filters = {...queryString.parse(location.search), ...match.params};
        this.props.fetchPackage(get(match,'params.code'))
        this.props.getDataPrintPackage(filters);
    }


    render() {
        let {packageData} = this.props;
        return (
            <PrintStampPackage numberStamp={1}
                               warehouseCode={lodash.get(packageData, 'destination_warehouse.code', '')}
                               packageData={packageData}
                               classPrint="link-stamp"/>
        )
    }
}

PrintPackageStamp.defaultProps = {
    packageData: {},
};

PrintPackageStamp.propTypes = {
    packageData: PropTypes.object,
};

export default withTranslation()(PrintPackageStamp);
