/* eslint-disable */
import React, {Component} from 'react';
import {
    Button,
    Input,
    Row,
    Col,
    Icon,
    Tabs,
    Pagination, Collapse, Form, Upload
} from 'antd';

const Search = Input.Search;
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;


const datas = [];
for (let i = 0; i < 6; i++) {
    datas.push({
        id: i,
        code: `322161995195${i}`,
    });
}

class DetailPackage extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            codeOrder: datas[0].code,
            scroll:0,
        }
    }
    onScroll = () => {
        const scrollY = window.scrollY;
        const scroll = this.myRef.current.scrollTop;
        this.setState({
            scroll : scroll
        })
    }

    onchageOrder = (codeOrder) => {
        this.setState({codeOrder})
    }

    render() {
        const {codeOrder} = this.state;
        return (
            <Row type="flex">
                <div className="a-content--ischatbox a-package--detail">
                    <div className="list-order">
                        <div className="list-order--top">
                            <h3 className="title">Đơn hàng <span>- 1231285894</span></h3>
                            <div className="list-order--top--right">
                                <Search
                                    placeholder="Tìm kiếm"
                                    className="a-input--search-order"
                                />
                                <Pagination defaultCurrent={1} total={40} className="a-pagination--order-package"/>
                            </div>
                        </div>
                        <ul className="list-order--detail">
                            {
                                datas.map((data, key) =>
                                    <li onClick={e => this.onchageOrder(data.code)}
                                        className={codeOrder === data.code ? "active" : ""}
                                    >
                                        <span>{data.code}</span>
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                    <div className="block--primary a-package pb-4">
                        <div className="title">
                            <div className="left">
                                <span className="block-mr-50"><strong>Mã kiện</strong> | 12385948459</span>
                                <span>Kho hiện tại: <strong>BT-CNGZ</strong> / Kho đích: <strong>HN-VNHH</strong></span>
                            </div>
                            <div className="right">
                                <Button type="button" className="a-btn a-btn--transparent-white" ghost>Lịch sử
                                    quét </Button>
                            </div>
                        </div>
                        <div className="body a-package__body ">
                            <Row gutter={{lg: 24, xl: 48}} className="a-block-info">
                                <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                    <h4>Thông tin kiện hàng</h4>
                                </Col>
                                <Col lg={{span: 16}} xl={{span: 19}} xxl={{span: 19}}>
                                    <Row gutter={{lg: 24, xl: 32}}  className="a-block-info bg-gray info--detail">
                                        <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                            <h4>Thông tin kiện hàng</h4>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Mã vận đơn</label>
                                            <a href="#" className="info--value a-text--blue">798273948274982</a>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Trạng thái</label>
                                            <span className="info--value ">Lưu kho( 12:15:56 29/02/2019)</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Đơn hàng</label>
                                            <span className="info--value ">---</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Thời gian tạo</label>
                                            <span className="info--value ">12:15 29/02/2019</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Tình trạng</label>
                                            <span className="info--value ">Lưu kho</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Lý do lưu kho</label>
                                            <span className="info--value ">Không rõ nguồn gốc</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Nhóm ngành hàng</label>
                                            <span className="info--value ">XYZ</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Đặc tính</label>
                                            <span className="info--value ">Đồ mỏng / Dễ vỡ / Cần nâng niu</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Chi tiết lưu kho</label>
                                            <span className="info--value ">Không rõ nguồn gốc </span>
                                        </Col>
                                        <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 16}}
                                             className="info--group">
                                            <label className="info--label">Ghi chú</label>
                                            <span className="info--value ">Đây là khách hàng rất VIP</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={{lg: 24, xl: 32}} className="a-block-info bg-gray info--detail mt-4">
                                        <Col lg={{span: 24}} xl={{span: 24}} className="info--title">
                                            <h4>Thông số kiện hàng</h4>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Cân nặng</label>
                                            <span className="info--value ">32.21 kg</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Kích thước</label>
                                            <span className="info--value ">32210 cm3</span>
                                        </Col>
                                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                                             className="info--group">
                                            <label className="info--label">Cân nặng quy đổi</label>
                                            <span className="info--value ">1245.6 kg</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={{span: 8}} xl={{span: 5}} xxl={{span: 5}}
                                     className="a-package--service only-view">
                                    <h2 className="title">Dịch vụ sử dụng</h2>
                                    <div className="service--body ">
                                        <div className="service--block">
                                            <div className="service--block--body">
                                                <ul>
                                                    <li>
                                                        <span><img
                                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA"
                                                            alt=""/>Chuyển thẳng</span>
                                                        <span><img
                                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA"
                                                            alt=""/>Đóng gỗ riêng </span>
                                                        <span><img
                                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA"
                                                            alt=""/>Kiểm hàng </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={48} className={"a-block-info"}>
                                <Col lg={{span: 24}} xl={{span: 24}} className="info--title iscollapse">
                                    <div className="title">
                                        <h4>Thông tin khách hàng</h4>
                                    </div>
                                    <div className="title--collapse">
                                        <span>Thu gọn<Icon type="shrink" className="icon-collapse"/></span>
                                    </div>
                                </Col>
                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group">
                                    <label className="info--label">Username</label>
                                    <span className="info--value--small ">tronglevan</span>
                                </Col>
                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}} className="info--group">
                                    <label className="info--label">Tên khách hàng</label>
                                    <span className="info--value--small ">Lê Văn Trọng</span>
                                </Col>
                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                     className="info--group">
                                    <label className="info--label">Số điện thoại</label>
                                    <span className="info--value--small ">03639977112</span>
                                </Col>
                                <Col lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                                    <Collapse defaultActiveKey={['1']}
                                              className="a-collapse--info-customer">
                                        <Panel key="1"
                                               showArrow={false}
                                        >
                                            <Row gutter={48}>
                                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                     className="info--group">
                                                    <label className="info--label">Quốc gia</label>
                                                    <span className="info--value--small ">Việt Nam</span>
                                                </Col>
                                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                     className="info--group">
                                                    <label className="info--label">Tỉnh thành</label>
                                                    <span className="info--value--small ">Hà Nội</span>
                                                </Col>
                                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                     className="info--group">
                                                    <label className="info--label">Quận huyện</label>
                                                    <span className="info--value--small ">Nam Từ
                                                                Liêm</span>
                                                </Col>
                                                <Col lg={{span: 8}} xl={{span: 6}} xxl={{span: 6}}
                                                     className="info--group">
                                                    <label className="info--label">Kho đích</label>
                                                    <span className="info--value--small ">CNPZ</span>
                                                </Col>
                                                <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                                                     className="info--group">
                                                    <label className="info--label">Địa chỉ nhận</label>
                                                    <span className="info--value--small ">Hà Nội</span>
                                                </Col>
                                                <Col lg={{span: 24}} xl={{span: 12}} xxl={{span: 12}}
                                                     className="info--group">
                                                    <label className="info--label">Ghi chú</label>
                                                    <span className="info--value--small ">Nam Từ
                                                                Liêm</span>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row gutter={48}>
                                <Col lg={{span: 24}} xl={{span: 24}} className="a-package--action">
                                    <ul className="list-action list-action--left">
                                        <li>
                                            <Button className="a-btn--button-link a-text--blue">Sửa
                                                kiện</Button>
                                        </li>
                                    </ul>
                                    <ul className="list-action list-action--right">
                                        <li>
                                            <Button className="a-btn--button-link a-text--blue">Tạo kiện tương
                                                tự</Button>
                                        </li>
                                        <li>
                                            <Button className="a-btn--button-link a-text--blue">In
                                                4 tem</Button>
                                        </li>
                                        <li className="not-line-space">
                                            <Button className="a-btn--button-link a-text--blue">Link
                                                In</Button>
                                        </li>
                                        <li>
                                            <Button type="primary" htmlType="submit"
                                                    className="a-btn a-btn--primary a-btn--print-tem ">In
                                                Tem</Button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="a-chatbox">
                    <div className="a-chatbox--input ">
                        <Input placeholder="Nhập thông tin cần hỗ trợ..." className="a-input a-input--chatbox"/>
                        <Upload name="logo" action="/upload.do" listType="picture" className="a-btn--chat-upload">
                            <Button>
                                <Icon type="camera"/>
                            </Button>
                        </Upload>
                        <Button type="primary" htmlType="submit"
                                className="a-btn a-btn--primary a-btn--submit-chatbox ">Gửi</Button>
                    </div>
                    <div className="a-chatbox--body">
                        <Tabs className="a-tabs a-tabs--chatbox-tab">
                            <TabPane className="a-tabs--chatbox--panel" tab="Thông tin hệ thống" key="3">
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>

                            </TabPane>
                            <TabPane className="a-tabs--chatbox--panel" tab="Thông tin hệ thống" key="2">
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>
                                <div className="a-chatbox--log system">
                                    <img className="img-ads" src={require('../../../resources/images/log-sys.svg')}
                                         alt="Log system"/>
                                    <div className="a-chatbox--log--detail">
                                        <p className="title">Hệ thống, 16:54 CH</p>
                                        <span className="detail">Chuyển trạng thái kiện hàng <span
                                            className="a-text--medium">980980492</span> sang <span
                                            className="a-text--medium">Vận chuyển</span></span>
                                    </div>
                                </div>

                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Row>
        )
    }
}

export default DetailPackage;