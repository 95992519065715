import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getState } from './../selectors';
import { getState as getReExecutionState } from './../re-execution/selectors';
import { setMenuActive } from './../../Common/actions';

import ShippingFee from './components';

class ShippingFeeListContainer extends Component {
    render() {
        return (
            <ShippingFee
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        paginations: {
            main: getState(state, 'pagination'),
            reExecution: getReExecutionState(state, 'pagination')
        }
    };
};

const mapDispatchToProps = {
    setMenuActive
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingFeeListContainer));
