import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const printPackageStamp = createAction(ACTION.PRINT_PACKAGE_STAMP);
export const clearPrintState = createAction(ACTION.CLEAR_PRINT_STATE, () => ({}));
export const printIssue = createAction(ACTION.PRINT_ISSUE);
export const printDeliveryNoteReturn = createAction(ACTION.PRINT_DELIVERY_NOTE_RETURN);
export const printDeliveryNoteTransportWarehouse = createAction(ACTION.PRINT_DELIVERY_NOTE_TRANSPORT_WAREHOUSE);
export const printOfficialPackageStamp = createAction(ACTION.PRINT_OFFICIAL_PACKAGE_STAMP);
export const printPackageUnOfficialStamp = createAction(ACTION.PRINT_PACKAGE_UNOFFICIAL_STAMP);
export const printBagStamp = createAction(ACTION.PRINT_BAG_STAMP);
export const printBatchStamp = createAction(ACTION.PRINT_BATCH_STAMP);
export const loadPrintTemplate = createAction(ACTION.LOAD_PRINT_TEMPLATE);
export const finishLoadPrintTemplate = createAction(ACTION.FINISH_LOAD_PRINT_TEMPLATE);
export const printIframe = createAction(ACTION.PRINT_IFRAME);
export const printMultiPackageStamp = createAction(ACTION.PRINT_MULTI_PACKAGE_STAMP);
export const printFBUStamp = createAction(ACTION.PRINT_FBU_STAMP)
export const printWarehouseAreaStamp = createAction(ACTION.PRINT_WAREHOUSE_AREA_STAMP)
export const print9710Stamp = createAction(ACTION.PRINT_9710_STAMP)
