import React, {PureComponent} from 'react';
import {Layout} from 'antd';
import PageTitle from '../Page/PageTitleContainer';
import UserPanel from '../../Auth/UserPanel/UserPanelContainer';
import Language from "../Language"

const {Header} = Layout;

class HeaderX extends PureComponent {
    render() {
        return (
            <Header className="a-header _a-header">
                <div className="a-header__left _a-header__left">
                    <PageTitle/>
                </div>
                <ul className="a-header__right _a-header__right">
                    <li className="info__user _info__user">
                        <Language/>
                    </li>
                    {/*<li className="info__user _info__user">*/}
                        {/*<SearchGlobal/>*/}
                    {/*</li>*/}
                    {/*<li className="info__user _info__user">*/}
                        {/*<Notification/>*/}
                    {/*</li>*/}
                    <li className="info__user _info__user">
                        <UserPanel/>
                    </li>
                </ul>
            </Header>
        );
    }
}

export default HeaderX;
