import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import * as actions from './actions';
import ListSameDayBags from './components/ListSameDayBags';

const mapStateToProps = (state, props) => {
    return {
        ...state.bag.listSameDayBags,
        ...lodash.pick(props, ['bagId']),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchSameDayBags: () => dispatch(actions.fetchSameDayBags(props.bagId)),
        clearState: () => dispatch(actions.clearState()),
    };
};

class ListSameDayBagsContainer extends React.PureComponent {
    componentDidMount() {
        this.fetchSameDayBags();
    }

    componentDidUpdate(prevProps) {
        if (this.props.bagId !== prevProps.bagId) {
            this.fetchSameDayBags();
        }
    }

    fetchSameDayBags() {
        if (!lodash.find(this.props.bags, {id: this.props.bagId})) {
            this.props.fetchSameDayBags();
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        return <ListSameDayBags
            {...lodash.pick(this.props, [
                'bagId',
                'bags',
                'loading',
            ])}
        />;
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListSameDayBagsContainer);

Container.defaultProps = {
    bagId: null,
};

Container.propTypes = {
    bagId: PropTypes.any,
};

export default Container;

