/* eslint-disable */
import React, {Component} from 'react';
import {Layout, Form, Select, Table, Pagination, Button, Menu, Dropdown, Row, Col, Icon, Checkbox, Input} from 'antd';

const {Content} = Layout;
const Option = Select.Option;
const Search = Input.Search;
const columns = [{
    title: 'Mã kiện',
    dataIndex: 'packagecode',
    key: 'packagecode',
    render: text => <a href="javascript:void(0)" className="a-text--nowrap">{text}</a>,
}, {
    title: 'Thời gian tạo',
    dataIndex: 'timecreate',
    key: 'timecreate',
    className: "a-text--nowrap",
}, {
    title: 'Trạng thái kho hiện tại',
    dataIndex: 'statuswarehouse',
    key: 'statuswarehouse',
    render: i => <span>{i % 2 == 0 ? "Trong kho" : "Xuất kho"}</span>,
}, , {
    title: 'Thời gian',
    dataIndex: 'time',
    key: 'time',
    className: "a-text--nowrap"
}, , {
    title: 'Đơn hàng',
    dataIndex: 'order',
    key: 'order',
    render: text => <a href="javascript:void(0)" className="a-text--nowrap"><img className="logo-shop"
                                                                                 src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0Hf2mgQMefYOfouTjyup7z__t6apm6B-lgaimKMh6jcxZe4R4uA'
                                                                                 alt=""/>{text}</a>,
}, , {
    title: 'Kho hiện tại',
    dataIndex: 'presentwarehouse',
    key: 'presentwarehouse',
    render: text => <span>{text}</span>,
}, , {
    title: 'Kho đích',
    dataIndex: 'destinationwarehouse',
    key: 'destinationwarehouse',
    render: text => <span>{text}</span>,
}, , {
    title: 'Cân nặng',
    dataIndex: 'weight',
    key: 'weight',
    render: text => <span className="a-text--nowrap">{text}</span>,
}, , {
    title: 'Thể tích',
    dataIndex: 'volume',
    key: 'volume',
    render: text => <span className="a-text--nowrap">{text + " cm"}<sup>3</sup></span>,
}];

const data = [];
for (let i = 0; i < 100; i++) {
    data.push({
        packagecode: `030219956${i}`,
        timecreate: `00.00 03/02/1995`,
        time: `00.00 03/02/1995`,
        statuswarehouse: i,
        order: `3221619951995${i}`,
        presentwarehouse: `CNGZ${i}`,
        destinationwarehouse: `CNGZ${i}`,
        weight: `${i} kg`,
        volume: `${i + 1}0`,
    });
}
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

class ListPackage extends Component {
    render() {
        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <div className="a-content--search-header search-default">
                        <Row gutter={24}>
                            <Form className="a-form">
                                <Col lg={18} xl={17} xxl={18} className="search--left">
                                    <Row gutter={24}>
                                        <Col lg={4} xl={4} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Hành động"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} xl={4} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Kho tạo"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="CNGZ">
                                                    <Option value="CNGZ">CNGZ</Option>
                                                    <Option value="VNSG">VNSG</Option>
                                                    <Option value="VNHN">VNHN</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} xl={4} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Kho hiện tại"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="CNGZ">
                                                    <Option value="CNGZ">CNGZ</Option>
                                                    <Option value="VNSG">VNSG</Option>
                                                    <Option value="VNHN">VNHN</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} xl={4} xxl={3}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Kho đích"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="CNGZ">
                                                    <Option value="CNGZ">CNGZ</Option>
                                                    <Option value="VNSG">VNSG</Option>
                                                    <Option value="VNHN">VNHN</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={4}>
                                            <Form.Item
                                                className="a-form__item search-content"
                                                label="Trạng thái kho hiện tại"
                                                labelCol={{span: 24}}>
                                                <Select className="a-select a-select--search-content"
                                                        defaultValue="Tất cả">
                                                    <Option value="Tất cả">Tất cả</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col lg={6} xl={7} xxl={6} className="search--right">
                                    <Form.Item
                                        className="a-form__item search-content item--special"
                                        label="Mã quét / kiện / đơn"
                                        labelCol={{span: 24}}>
                                        <Search
                                            placeholder="Mã quét / kiện / đơn"
                                            className="a-input--search"
                                        />
                                    </Form.Item>
                                    <div className="search--action">
                                        <a href="#" className="link-reload "><Icon type="sync"/> Làm mới bộ lọc</a>
                                        <Button type="primary" htmlType="submit"
                                                className="a-btn a-btn--primary a-btn--search-list ">Tìm
                                            kiếm</Button>
                                    </div>
                                </Col>
                            </Form>
                        </Row>
                    </div>
                    <div className="a-content__top a-content__top--page-list">
                        <div className="a-content--page-list__title">
                            <h3>Danh Sách Kiện - <span>32216</span></h3>
                        </div>
                        <div className="a-content--page-list__top-action">
                            <Button type="primary"
                                    className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular mr-3 "
                                    ghost>Xuất CSV
                            </Button>
                            <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                                <Button type="primary"
                                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular"
                                        ghost>Thiết lập cột thông tin
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="a-content__body bg-white a-content__body--page-list ">
                        <Table className={"a-table--antd"} columns={columns} dataSource={data}
                               pagination={false}
                        />
                        <div className="a-pagination">
                            <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                            <div className="a-pagination--select-number-item">
                                <span className="text-show">Hiển thị</span>
                                <Select defaultValue="10" className="a-select--select-number-show">
                                    <Option value="10">10</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        )
    }
}

export default ListPackage;