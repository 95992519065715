import React, { forwardRef } from 'react';
import { Select } from 'antd';
import values from 'lodash/values';

import { STAGES } from './../constants';
import { t } from './../../../system/i18n';

const { Option } = Select;

const options = values(STAGES).map(stage => (
    <Option key={stage} value={stage}>{t(`note:stages.${stage}`)}</Option>
));

const StageWarnings = forwardRef(({
    className,
    value,
    onChange
}, ref) => (
    <Select
        allowClear
        className={className}
        mode="multiple"
        ref={ref}
        value={value}
        onChange={onChange}
    >
        {options}
    </Select>
));

export default StageWarnings;
