import React from 'react';
import ReactDOM from 'react-dom';
import StoreProvider from './system/store/StoreProvider';
import I18nProvider from './system/i18n/I18nProvider';
import RoutingProvider from './system/routing/RoutingProvider';

import './resources/scss/main.scss';

ReactDOM.render(
    <StoreProvider>
        <I18nProvider>
            <RoutingProvider/>
        </I18nProvider>
    </StoreProvider>,
    document.getElementById('root')
);