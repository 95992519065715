import React, { Component } from 'react';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';

import apiService from './../../apiService';
import { downloadBlobData } from './../../../../system/support/helpers';

import UploadForm from './LostPackagesTab/UploadForm';
import UploadResultTable from './LostPackagesTab/UploadResultTable';

class LostPackagesTab extends Component {
    state = {
        downloadingMiaTemplate: false,
        uploadingMia: false,
        uploadResult: undefined
    };

    downloadMiaTemplate = async () => {
        const { t } = this.props;

        this.setState({
            downloadingMiaTemplate: true
        });

        try {
            const response = await apiService.downloadMiaTemplate();

            downloadBlobData(response.data, 'mia-template', 'xlsx');
        } catch (error) {
            notification.error({
                message: t('package:download_sample_file.failed')
            });
        }

        this.setState({
            downloadingMiaTemplate: false
        });
    };

    uploadMia = (form, event) => {
        event.preventDefault();

        form.validateFields((error, values) => {
            if (error) {
                return;
            }

            this.doUploadMia(values.file.file);
        });
    };

    doUploadMia = async file => {
        const { t } = this.props;

        this.setState({
            uploadingMia: true,
            uploadResult: undefined
        });

        try {
            const response = await apiService.importMia(file);

            this.setState({
                uploadResult: response.data
            });
        } catch (error) {
            notification.error({
                message: t('package:upload_file.failed')
            });
        }

        this.setState({
            uploadingMia: false
        });
    };

    render() {
        const { downloadingMiaTemplate, uploadingMia, uploadResult } = this.state;

        return (
            <>
                <UploadForm
                    downloadingMiaTemplate={downloadingMiaTemplate}
                    uploadingMia={uploadingMia}
                    onDownloadMiaTemplate={this.downloadMiaTemplate}
                    onUploadMia={this.uploadMia}
                />

                {uploadResult ? (
                    <UploadResultTable
                        uploadResult={uploadResult}
                    />
                ) : null}
            </>
        );
    }
}

export default withTranslation()(LostPackagesTab);
