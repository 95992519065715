import React, { useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useDeepCompareEffect } from './../../../../hooks';

const { Option } = Select;

const BagTemplatesSelect = ({ bagTemplates, className, dropdownClassName, value, ...other }) => {
    const [formattedValue, setFormattedValue] = useState();

    useDeepCompareEffect(() => {
        let formattedValue = value && Number(value);
        const index = bagTemplates.findIndex(bagTemplate => bagTemplate.bag_template.id === formattedValue);
    
        if (index === -1) {
            formattedValue = undefined;
        }

        setFormattedValue(formattedValue);
    }, [bagTemplates, value]);

    return (
        <Select
            {...other}
            className={clsx(className, '_bag-templates-select')}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-bag-templates')}
            value={formattedValue}
        >
            {bagTemplates.map(bagTemplate => (
                <Option
                    className="_bag-template-item"
                    key={bagTemplate.bag_template.id}
                    value={bagTemplate.bag_template.id}
                >
                    {bagTemplate.bag_template.name}
                </Option>
            ))}
        </Select>
    );
}

BagTemplatesSelect.propTypes = {
    bagTemplates: PropTypes.array
};

BagTemplatesSelect.defaultProps = {
    bagTemplates: []
};

export default BagTemplatesSelect;
