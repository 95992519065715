import lodash from 'lodash';
import {createSelector} from 'reselect';
import {translateValidationErrors} from "../Common/services/helps";
import {t} from "../../system/i18n";

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'setting' + (param ? '.' + param : ''), defaultValue);

export const getSetting = createSelector(
    state => getState(state, 'setting', []),
    setting => lodash.mapValues(lodash.keyBy(setting, 'code'), item => item.value)
);

export const getSettingItemByCode = (code, defaultValue) => createSelector(
    getSetting,
    setting => {
        const key = Object.keys(setting).find(key => key === code);

        if (key) {
            return setting[key];
        }

        return defaultValue;
    }
);

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        let err = {};
        if (param === 'id_location') {
            lodash.mapKeys(rules, (r, p) => {
                switch (p) {
                    case 'district_is_require':
                        err = {
                            id_district: lodash.first(lodash.values(translateValidationErrors(t('config:label.id_district_warehouse'), {required: []})))
                        };
                        break;
                    case 'province_is_require':
                        err = {id_city: lodash.first(lodash.values(translateValidationErrors(t('config:label.id_city_warehouse'), {required: []})))};
                        break;
                    default:
                        err = {
                            id_country: lodash.first(lodash.values(translateValidationErrors(t('config:label.id_country_warehouse'), {required: []})))
                        };
                        break
                }
            });
            rules = {required: []};
        }
        let messages = translateValidationErrors(t('config:label.' + param + '_location'), rules);
        return param === 'id_location' ? {...err} : lodash.first(lodash.values(messages));
    });
};
