import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { fetchPackageDetail } from './../PackageDetail/actions';
import { getState } from './../PackageDetail/selectors';

import UploadDamagedImages from './components';

const UploadDamagedImagesContainer = props => {
    return (
        <UploadDamagedImages
            {...props}
        />
    );
};

const mapStateToProps = state => {
    return {
        loading: getState(state, 'loading'),
        package: getState(state, 'package')
    };
};

const mapDispatchToProps = {
    fetchPackageDetail
};

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadDamagedImagesContainer));
