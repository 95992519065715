import React from 'react';
import { Col, Row } from 'antd';
import { withTranslation } from 'react-i18next';

import { currentFormatter, dateFormatter } from './../../../../Common/services/format';
import { formatWeight, getVar } from './../../../../../system/support/helpers';

const Info  = ({ deliveryNoteReturn, t }) => (
    <div className="block--primary a-block-info abg-white detail-delivery-note">
        <div className="title">
            <span>{t('delivery_note:title.detail_delivery_note')}</span>
        </div>
        <div className="body">
            <Row className="mx-0" gutter={{lg: 24, xl: 32}}>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('partner')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'shipping_return_partner.name', '--')}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.creator')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'creator.name', '--')} ({getVar(deliveryNoteReturn, 'creator.username', '--')}) 
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.created_at')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.created_at') ? dateFormatter.full(deliveryNoteReturn.delivery_note_return.created_at) : '--'}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.status')}</label>
                    <span className="info--value">
                        {t(`delivery_note:status.${getVar(deliveryNoteReturn, 'delivery_note_return.status')}`)}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.exporter')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'exporter') ? (
                            `${getVar(deliveryNoteReturn, 'exporter.name', '--')} (${getVar(deliveryNoteReturn, 'exporter.username', '--')})`
                        ) : '--'}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.exported_at')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.exported_at') ? dateFormatter.full(getVar(deliveryNoteReturn, 'delivery_note_return.exported_at')) : '--'}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.warehouse')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'warehouse.name', '--')}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.package_number')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.package_number', '--')}
                    </span>
                </Col>
                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.package_weight')}</label>
                    <span className="info--value">
                        {formatWeight(getVar(deliveryNoteReturn, 'delivery_note_return.package_weight'))}
                    </span>
                </Col>

                <Col className="info--group" lg={{span: 12}} xl={{span: 16}}>
                    <label className="info--label">{t('delivery_note:label.note')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.note', '--')}
                    </span>
                </Col>

                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:label.tracking_number')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.partner_tracking_number', '--')}
                    </span>
                </Col>

                <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                    <label className="info--label">{t('delivery_note:return.fee')}</label>
                    <span className="info--value">
                        {getVar(deliveryNoteReturn, 'delivery_note_return.return_fee') ? currentFormatter.toLocaleStringCurrency(deliveryNoteReturn.delivery_note_return.return_fee) : '--'}
                    </span>
                </Col>
            </Row>
        </div>
    </div>
);

export default withTranslation()(Info);
