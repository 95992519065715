import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import LogMessage from './LogMessage';
import { dateFormatter } from '../../../Common/services/format';
import avatar from '../../../../resources/images/log-sys.svg';

class Log extends React.PureComponent {
    render() {
        const {log, object} = this.props;
        const creator = log.creator || {};

        return (
            <div className={['delivery-notes', 'delivery-note-returns', 'delivery-note-transport-warehouses'].includes(object) ? 'log-block' : 'a-chatbox--log system'}>
                <img className="img-ads _img-ads-avatar" src={avatar} alt={creator.name} />
                <div className="a-chatbox--log--detail">
                    <p className="title _title">
                        <span className={clsx({
                            'a-text--blue': !!creator.is_admin
                        })}>{creator.name}{creator.from ? ` (${creator.from})` : ''}</span>, {dateFormatter.full(log.time)}
                    </p>
                    <span className="detail _detail"><LogMessage log={log}/></span>
                </div>
            </div>
        );
    }
}

Log.defaultProps = {
    log: {},
};

Log.propTypes = {
    log: PropTypes.object.isRequired,
};

export default withTranslation()(Log);
