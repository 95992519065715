import { notification } from 'antd'
import { get, map } from 'lodash'
import React from 'react'
import apiService from '../../../modules/Setting/apiService'
import { t } from '../../../system/i18n'
import { getState } from '../../../system/store'
import { canGlobalSetting } from '../../../system/support/helpers'
import permissions from '../../Auth/permissions'
import Page403 from './../../Common/components/403'
import Page404 from './../../Common/components/404'

export default (payload, next) => {
    let settings = getState('setting')
    if (Array.isArray(settings.setting) && settings.setting.length === 0) {
        apiService
            .getSetting()
            .then(res => {
                const globalSetting = get(res, 'data.settings.globalSetting', [])

                if (canGlobalSetting(globalSetting,permissions.CONFIG_EXPEDITED_OPERATION)) {
                    return next(payload)
                } else {
                    notification.error({ message: t('message.403') })
                    return payload.response.render(<Page403 />)
                }
            })
            .catch(error => {
                const statusCode = get(error, 'response.status')
                if (statusCode === 403) {
                    notification.error({ message: t('message.403') })

                    return payload.response.render(<Page403 />)
                } else {
                    notification.error({ message: t('message.404') })

                    return payload.response.render(<Page404 />)
                }
            })
    } else {
        if (canGlobalSetting(getState("setting.setting"),permissions.CONFIG_EXPEDITED_OPERATION)) {
            return next(payload)
        }
        notification.error({ message: t('message.403') })
        return payload.response.render(<Page403 />)
    }
}
