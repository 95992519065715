import {all, fork, takeEvery, takeLatest} from 'redux-saga/effects';
import InventoryBarcode from './InventoryBarcode/sagaWatcher';
import CheckingInventory from './CheckingInventory/sagaWatcher';
import updateStatusInventory from './UpdateStatusInventory/sagaWatcher';
import * as ACTION from './constants';
import * as saga from './saga';

export default function* () {
	yield all([
		fork(InventoryBarcode),
		fork(CheckingInventory),
		fork(updateStatusInventory),
	]);
	yield takeLatest(ACTION.FETCH_INVENTORY_DETAIL.REQUEST, saga.fetchInventoryDetail);
	yield takeLatest(ACTION.FETCH_INVENTORY_DETAIL.SUCCESS, saga.fetchInventorySuccess);
	yield takeLatest(ACTION.FETCH_INVENTORY_DETAIL.FAILED, saga.fetchInventoryFailed);
	yield takeLatest(ACTION.UPDATE_INVENTORY_STATUS.REQUEST, saga.updateInventoryUpdate);
	yield takeEvery(ACTION.UPDATE_INVENTORY_STATUS.SUCCESS, saga.updateInventoryUpdateSuccess);
	yield takeLatest(ACTION.UPDATE_INVENTORY_STATUS.FAILED, saga.updateInventoryUpdateFailed);
	yield takeLatest(ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.REQUEST, saga.resolveDisappearInventoryPackages);
	yield takeEvery(ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.SUCCESS, saga.resolveDisappearInventoryPackagesSuccess);
	yield takeLatest(ACTION.RESOLVE_DISAPPEAR_INVENTORY_PACKAGES.FAILED, saga.resolveDisappearInventoryPackagesFailed);
	yield takeLatest(ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.REQUEST, saga.resolveRemainderInventoryPackages);
	yield takeEvery(ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.SUCCESS, saga.resolveRemainderInventoryPackagesSuccess);
	yield takeLatest(ACTION.RESOLVE_REMAINDER_INVENTORY_PACKAGES.FAILED, saga.resolveRemainderInventoryPackagesFailed);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILL.REQUEST, saga.resolveInventoryTrackingBill);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILL.SUCCESS, saga.resolveInventoryTrackingBillSuccess);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILL.FAILED, saga.resolveInventoryTrackingBillFailed);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.REQUEST, saga.resolveInventoryTrackingBills);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.SUCCESS, saga.resolveInventoryTrackingBillsSuccess);
	yield takeLatest(ACTION.RESOLVE_INVENTORY_TRACKING_BILLS.FAILED, saga.resolveInventoryTrackingBillsFailed);
	yield takeLatest(ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.REQUEST, saga.updateInventoryPackageStatus);
	yield takeLatest(ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.SUCCESS, saga.updateInventoryPackageStatusSuccess);
	yield takeLatest(ACTION.UPDATE_INVENTORY_PACKAGE_STATUS.FAILED, saga.updateInventoryPackageStatusFailed);
	yield takeLatest(ACTION.FETCH_INVENTORIES.REQUEST, saga.fetchInventories);
}
