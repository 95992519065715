import {createSelector} from "reselect";
import lodash from 'lodash';

export const getDetailWarehouseStaff = createSelector(
    state => lodash.get(state, "configWarehouse.listWarehouseStaff.list"),
    state => lodash.get(state, "configWarehouse.updateWarehouseStaff.warehouseStaffId", 0),
    (warehouseStaffs, id) => filterWarehouseStaff(warehouseStaffs, id)
);

function filterWarehouseStaff(warehouseStaffs, id) {
    let arrWarehouseStaffs = warehouseStaffs.filter(warehouseStaff => "" + warehouseStaff.user.id === id);
    return arrWarehouseStaffs.length > 0 ? arrWarehouseStaffs[0] : {};
}

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'configWarehouse.updateWarehouseStaff' + (param ? '.' + param : ''), defaultValue);
