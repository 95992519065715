import React from 'react';
import {Icon, Layout, Switch} from "antd";
import {connect} from "react-redux";
import * as _ from 'lodash';
import {EditForm} from "./EditForm";
import Link from "../../../system/routing/Link";
import {t} from "../../../system/i18n";
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as shippingPartnerSelectors from '../../ShippingPartner/selectors';
import * as shippingPartnerActions from '../../ShippingPartner/ListShippingPartner/actions';
import * as shippingUserSelectors from '../../ShippingUser/selectors';
import * as shippingUserActions from '../../ShippingUser/actions';
import * as warehouseSelectors from '../../Warehouse/selectors';
import {getBagSizes} from '../../BagSize/selectors';
import pageService from "../../Common/Page/pageService";
import {setMenuActive} from "../../Common/actions";

const Content = Layout.Content;


const mapStateToProps = (state) => {
    return {
        updating: state.templateBag.updating,
        errors: selectors.getErrors(_.get(state, 'templateBag.updateErrors', {})),
        shippingPartners: shippingPartnerSelectors.getShippingPartnersSuggest(state),
        shippingUsers: shippingUserSelectors.getShippingUsersSuggest(state),
        loadingShippingPartner: shippingPartnerSelectors.getLoadingList(state),
        loadingShippingUser: shippingUserSelectors.getLoading(state),
        templateBag: selectors.getTemplateBagDetail(state),
        warehouses: warehouseSelectors.getState(state, 'suggest', []),
        bagSizes: getBagSizes(state)
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateTemplateBag: (id, data) => dispatch(actions.updateTemplateBag(id, data)),
    resetError: (field) => dispatch(actions.resetError(field)),
    fetchShippingPartners: () => dispatch(shippingPartnerActions.fetchShippingPartnersSuggest()),
    fetchShippingUsers: () => dispatch(shippingUserActions.fetchShippingUsersSuggest()),
    fetchTemplateBagDetail: (id, params) => dispatch(actions.fetchTemplateBagDetail(id, params)),
    clearStateTemplateBag: () => dispatch(actions.clearStateTemplateBag()),
    setMenuActive: (menu) => dispatch(setMenuActive(menu)),
});

class TemplateBagEditContainer extends React.Component {
    state = {
        active: null
    };

    static getDerivedStateFromProps (props, state) {
        let newState = {...state};
        if(state.active === null && _.has(props, 'templateBag.bag_template.active')) {
            newState.active = _.get(props, 'templateBag.bag_template.active');
            return {...newState};
        }
        return null;
    }

    componentDidMount() {
        this.props.fetchShippingPartners();
        this.props.fetchShippingUsers();
        this.props.fetchTemplateBagDetail(this.getId());
        pageService.setTitle(t("template_bag:page_edit_title"));
        this.props.setMenuActive("config/template-bags");

    }

    componentWillUnmount() {
        this.props.resetError('all');
        this.props.clearStateTemplateBag();
    }

    getId = () => {
        return this.props.match.params.id;
    };

    onChangeStatus = (checked) => {
        this.setState({active: checked});
    };

    render() {
        const {active} = this.state;
        return (
            <Content className={"aml-16-lg aml-24 amr-24 amr-16-lg a-content-config "}>
                <div className="a-breadcrumb">
                    <Link to="config.template-bags.list" className={"a-text--gray _btn-back"}>
                        <Icon type="arrow-left" className={"mr-2 "}/> {t('template_bag:back_to_list')}
                    </Link>
                </div>
                <div className="a-block-config amt-16">
                    <div className="a-block-config__title gflex gjustify-space-between">
                        <h3 className="title">{t('template_bag:info')}</h3>
                        <div>
                            <label className={"a-text--fz-12 a-text--gray mr-2"}>{t('template_bag:status')}</label>
                            <Switch
                                checked={!!active}
                                size="small"
                                className={"_switch-active"}
                                onChange={this.onChangeStatus}
                            />
                        </div>
                    </div>
                    <div className="a-block-config__body">
                        <EditForm
                            {...this.props}
                            id={this.getId()}
                            active={active}
                        />
                    </div>
                </div>
            </Content>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateBagEditContainer);
