import {combineReducers} from 'redux';
import lodash from 'lodash';
import {FETCH_PRINT_PACKAGE} from './constants';

let packagePrint = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PRINT_PACKAGE.REQUEST:
        case FETCH_PRINT_PACKAGE.FAILED:
            return state;
        case FETCH_PRINT_PACKAGE.SUCCESS:
            return {...lodash.get(action,'payload.origin_package',{}), ...lodash.get(action, 'payload', {})};
        default:
            return state;
    }
};

export default combineReducers({
    packagePrint,
});
