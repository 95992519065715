import React, { useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { useDeepCompareEffect } from './../../../../hooks';

const { Option } = Select;

const ShippingDomesticPartnersSelect = ({
    className,
    dropdownClassName,
    placeholder,
    shippingDomesticPartners,
    value,
    t,
    ...other
}) => {
    const [formattedValue, setFormattedValue] = useState();

    useDeepCompareEffect(() => {
        let formattedValue = value && Number(value);
        let partners = [];

        Object.keys(shippingDomesticPartners).forEach(type => {
            partners = partners.concat(shippingDomesticPartners[type]);
        });

        const index = partners.findIndex(partner => partner.id === formattedValue);
    
        if (index === -1) {
            formattedValue = undefined;
        }

        setFormattedValue(formattedValue);
    }, [shippingDomesticPartners, value]);

    return (
        <Select
            {...other}
            className={clsx(className, '_shipping-domestic-partners-select')}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-shipping-domestic-partners')}
            placeholder={placeholder || t('shipping_partner')}
            value={formattedValue}
        >
            {/* {Object.keys(shippingDomesticPartners).map(type => {
                if (type === 'carriers') {
                    return shippingDomesticPartners[type].map(shippingPartner => (
                        <Option
                            className={`_shipping-domestic-partner-item _${type}-shipping-domestic-partner-item`}
                            key={shippingPartner.id}
                            label={shippingPartner.connect_name}
                            value={shippingPartner.id}
                        >
                            {`${shippingPartner.connect_name} (${shippingPartner.carrier})`}
                        </Option>
                    ));
                } */}
            {
                shippingDomesticPartners.map(shippingPartner => (
                    <Option
                        className={`_shipping-domestic-partner-item _shipping-domestic-partner-item`}
                        key={shippingPartner.id}
                        label={shippingPartner.name}
                        value={shippingPartner.id}
                    >
                        {shippingPartner.name}
                    </Option>
                ))
            }
            {/* })} */}
        </Select>
    );
};

export default withTranslation()(ShippingDomesticPartnersSelect);
