import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {notification} from "antd";
import {t} from "../../../system/i18n";
import {router} from "../../../system/routing";
import * as _ from "lodash";
import React from "react";

export function* updateStatusInventoryRequest(action) {
	yield processApiRequest(ACTION.UPDATE_STATUS_INVENTORY, () => apiService.updateStatusInventory(action.payload.data), action.payload);
}

export function* updateStatusInventorySuccess() {
	yield notification.success({
		message: t("inventory:message.update_status_inventory_success")
	});
	setTimeout(() => {
		router.redirect(`/inventory/create`);
	}, 1000)
}

export function* updateStatusInventoryFailed(action) {
	let error = _.get(action, 'payload.data');
	if (_.get(error, 'warehouse')) {
		return yield notification['error']({
			message: <span>{t('message.warehouse_not_is_staff')}</span>,
		});
	}
	if(action.status_code !== 403) {
		return yield notification.error({
			message: t("inventory:message.update_status_inventory_failed")
		})
	}
}
