import React, {useEffect} from 'react';
import {connect} from "react-redux";
import lodash, { get } from 'lodash';
import {finishLoadPrintTemplate, loadPrintTemplate} from "../actions";
import {renderLengthWidthHeight} from "../../../Package/helper";

function PrintBatchStamp(props) {
    const {onDidMount, order, package: packageInfo, products, package_count} = props;
    useEffect(() => {
        onDidMount();
    }, []);

    const firstProduct = lodash.first(products);
    const merchantContact = lodash.get(firstProduct, "merchant_contact", "");
    const merchantName = lodash.get(firstProduct, "merchant_name", "");
    const originalName = lodash.get(firstProduct, "original_name", "");
    const weightNet = lodash.get(packageInfo, "weight_net", 0);

    const orderIdNote = lodash.get(order, "staff_note", "");

    function lengthWidthHeight () {
        if (get(packageInfo, 'length') && packageInfo.width && packageInfo.height) {
            return renderLengthWidthHeight(packageInfo);
        }
        return '--';
    }

    return (
        <div className="batch-stamp-content">
            <table>
                <tbody>
                <tr className="a-text--center a-text--fz-36">
                    <td colSpan={2}>SHIPPING MARK</td>
                    <td>{merchantContact}</td>
                </tr>
                <tr>
                    <td>Manufacturer</td>
                    <td colSpan={2}
                        style={{fontWeight: 500}}>{merchantName ? merchantName : "SHENZHEN XINBAILI IMPORT AND EXPORT CO.,LTD"}</td>
                </tr>
                <tr>
                    <td>Name of goods</td>
                    <td colSpan={2} className="a-text--fz-36" style={{fontWeight: 500}}>{originalName}</td>
                </tr>
                <tr>
                    <td>Total of package</td>
                    <td className="a-text--fz-36" style={{fontWeight: 500}}>{package_count}</td>
                    <td>Package</td>
                </tr>
                <tr>
                    <td>Gross weight</td>
                    <td>{weightNet}</td>
                    <td>Kg</td>
                </tr>
                <tr>
                    <td>Dimension</td>
                    <td>{lengthWidthHeight()}</td>
                    <td>Cm</td>
                </tr>
                <tr>
                    <td>Order ID note</td>
                    <td colSpan={2}>
                        {
                            !!orderIdNote &&
                            <>
                                <span className="a-text--fz-36"
                                      style={{fontWeight: 500}}>{orderIdNote.substr(0, 3)}</span>
                                {
                                    orderIdNote.substr(3, orderIdNote.length - 7)
                                }
                                <span className="a-text--fz-36"
                                      style={{fontWeight: 500}}>{orderIdNote.substr(orderIdNote.length - 4)}</span>
                            </>
                        }
                    </td>
                </tr>
                <tr>
                    <td>Origin</td>
                    <td colSpan={2}>MADE IN CHINA</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default connect(
    undefined,
    {
        finishLoadPrintTemplate,
        loadPrintTemplate
    },
)(PrintBatchStamp);
