import {combineReducers} from 'redux';
import * as ACTION from './constants';

/**
 * State tự tăng mỗi lần update page title để render lại component page title
 */
let titleVersion = (state = 0, action) => {
    switch (action.type) {
        case ACTION.UPDATE_PAGE_TITLE:
            return state + 1;

        default:
            return state;
    }
};

export default combineReducers({
    titleVersion,
});
