import {first, mapValues, values, get, hasIn} from 'lodash';

import {translateValidationErrors} from './../Common/services/helps';
import {t} from './../../system/i18n';
import {notification} from "antd";

export const getErrors = error => {
    if (get(error, "data.package.message", "") === "package_unlink_order") {
        notification.error({message: t('scan:error.package_unlink_order', {packages: (get(error, "data.package.packages", [])).join(", ")})});
        return {};
    }
    if (get(error, "data.packages.message", "") === "package_into_bag") {
        notification.error({message: t('scan:error.package_into_bag', {packages: (get(error, "data.packages.items", [])).join(", ")})});
        return {};
    }
    return mapValues(error.data, (rules, param) => {
        const messages = translateValidationErrors(t(`delivery_note:label.${param}`), rules);

        return first(values(messages));
    });
};
