import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { withTranslation } from 'react-i18next';

const ProductInfoLoading = ({ t }) => {
    return (
        <Row className="a-block-info a-item-package m-0 amb-16 gflex-direction-column" gutter={32} type="flex">
            <div className="a-item-package__top">
                <Col lg={{span: 12}} xl={{span: 7}} xxl={{span: 8}} className="pl-0">
                    <div className="gflex">
                        <div className="a-item-package__avatar">
                            <Skeleton avatar active paragraph={{ rows: 1 }} className="askeleton-avatar" />
                        </div>
                        <div className="a-item-package__info">
                            <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                            <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                        </div>
                    </div>

                </Col>
                <Col lg={{span: 6}} xl={{span: 5}} xxl={{span: 4}}
                        className="info--group amb-14">
                    <label className="info--label a-text--nowrap">{t('package:checking.unit_price')} (VND)</label>
                    <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                </Col>
                <Col lg={{span: 6}} xl={{span: 3}} xxl={{span: 4}}
                        className="info--group amb-14">
                    <label className="info--label a-text--nowrap">{t('package:checking.purchased_quantity')}</label>
                    <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                </Col>
                <Col lg={{span: 6}} xl={{span: 4}} xxl={{span: 4}}
                        className="info--group amb-14 pr-0">
                    <label className="info--label">{t('package:checking.order_over_package_received_quantity')}</label>
                    <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                </Col>
                <Col lg={{span: 8}} xl={{span: 5}} xxl={{span: 4}}
                        className="info--group mb-0 pr-0">
                    <label className="info--label">{t('package:checking.package_quantity')}</label>
                    <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                </Col>
            </div>
            <div className="a-item-package__bottom gflex">
                <Col className="info--group pl-0 mb-0 border-right" lg={{span: 10}}>
                    <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                </Col>
                <Col className="info--group pr-0 mb-0" lg={{span: 14}}>
                    <Row>
                        <Col className="info--group">
                            <label className="info--label">{t('package:checking.customer_note')}:</label>
                            <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                        </Col>
                        <Col className="info--group mb-0">
                            <label className="info--label">{t('package:checking.staff_note')}:</label>
                            <Skeleton active className="askeleton-list" paragraph={{ rows: 1 }} title={false} />
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row>
    );
};

export default withTranslation()(ProductInfoLoading);
