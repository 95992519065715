import {notification} from 'antd';
import {put} from 'redux-saga/effects';
import {t, trans} from '../../../system/i18n';
import {processApiRequest} from '../../Common/saga';
import {makeModelStateFromApiRes} from '../../Common/services/helps';
import * as ACTION from './constants';
import apiUpdateService from './apiService';
import apiService from '../apiService';
import {BARCODE_TYPE} from '../DeliveryNoteBarcode/constants';
import * as actions from './actions';
import {url} from "../../../system/routing";
import lodash from "lodash";
import pageService from "../../Common/Page/pageService";
import React from "react";
import { DELIVERY_OBJECT } from './../constants'
import customerApiService from './../../Customer/apiService';

export function* fetchDeliveryNote(action) {
    yield processApiRequest(ACTION.FETCH_DELIVERY_NOTE, () => apiService.getDeliveryNote(action.payload.id), action.payload);
}
export function* updateDeliveryNote(action) {
    yield processApiRequest(ACTION.UPDATE_DELIVERY_NOTE, () => apiUpdateService.updateDeliveryNote(action.payload), action.payload);
}

export function* onFetchDeliveryNoteSuccess(action) {
    const barcodes = [];
    const customerId = lodash.get(action.payload, 'customer.id');
    const warehouseId = lodash.get(action.payload, 'warehouse.id');
    const deliveryNoteId = lodash.get(action.payload, 'delivery_note.id');

    if (!customerId) {
        return yield;
    }

    lodash.forEach(action.payload.delivery_note_packages, packageRes => {
        const packageData = makeModelStateFromApiRes(packageRes, 'package');

        barcodes.push({
            ...packageRes,
            code: packageData.code,
            type: BARCODE_TYPE.PACKAGE
        });
    });

    lodash.forEach(action.payload.delivery_note_bags, item => {
        const data = makeModelStateFromApiRes(item, 'bag');

        barcodes.push({
            ...item,
            code: data.code,
            type: BARCODE_TYPE.BAG
        });
    });

    yield put(actions.setBarcodes(barcodes));
    yield put(actions.getCustomerBagPackages({
        id_customer: customerId,
        id_delivery_note: deliveryNoteId,
        id_warehouse: warehouseId
    }));
    yield put(actions.getCustomerDetailInfo(customerId));
}

export function* onFetchBagDetailFailed(action) {
    if (action.payload) {
        yield notification.error({message: t('deliveryNote:message.cant_load_deliveryNote')});
    }
}
export function* updateDeliveryNoteSuccess(action) {
    yield notification.success({message: t('delivery_note:message.update_delivery_note_success')});
    url.redirectTo('delivery-notes.customer.detail', {
        id: lodash.get(action, 'payload.delivery_note.id', 0)
    });
}

export function* updateDeliveryNoteFailed(action) {
    let error = lodash.get(action, 'payload.data');
    if (lodash.hasIn(error, 'package.package_not_same_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_same_delivery_request')}</span>,
        });
    }

    if (lodash.hasIn(error, 'package.package_all_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_all_not_in_delivery_request')}</span>,
        });
    }

    if (lodash.hasIn(error, 'package.package_not_in_delivery_request')) {
        return yield notification.error({
            message: <span>{trans('delivery_note:message.package_not_in_delivery_request')}</span>,
        });
    }
    if (lodash.get(error, 'warehouse')) {
        return yield notification['error']({
            message: <span>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
}

export function* setPageTitle(action) {
    yield pageService.setTitle(trans("menu.update_delivery_note", {
        code: action.payload.delivery_note.code
    }));
}

export function* getCustomerBagPackages(action) {
    yield processApiRequest(ACTION.GET_CUSTOMER_BAG_PACKAGES, () => apiService.getCustomerBagPackages(action.payload.id_customer, action.payload), action.payload);
}

export function* getCustomerDetailInfo(action) {
    yield processApiRequest(ACTION.GET_CUSTOMER_DETAIL_INFO, () => customerApiService.fetchDetailCustomer(action.payload), action.payload);
}

export function* succcessScanBarcode(action)  {
    const { payload, request } = action;

    if (lodash.get(request, 'params.type') === DELIVERY_OBJECT.RETURN) {
        return yield;
    }

    if (!lodash.get(payload, 'customer.id')) {
        return yield notification.error({
            message: trans('scan:error.not_belong_to_any_customer', {
                type: trans((payload.bag ? BARCODE_TYPE.BAG : BARCODE_TYPE.PACKAGE).toLowerCase())
            })
        });
    }

    if (lodash.get(request, 'params.id_customer') && Number(payload.customer.id) !== Number(request.params.id_customer)) {
        return yield notification.error({
            message: trans('scan:error.not_belong_to_customer', {
                type: trans((payload.bag ? BARCODE_TYPE.BAG : BARCODE_TYPE.PACKAGE).toLowerCase())
            })
        });
    }

    return yield;
}
