import axios from 'axios';
import {dispatch} from '../store';
import SingleRequestHandler from './SingleRequestHandler';
import {API} from './constants';

class ApiFactory {
    constructor(baseUrl, auth = null) {
        this.baseUrl = baseUrl;
        this.auth = auth;
    }

    make() {
        let auth = this.auth;
        let singleRequestHandler = new SingleRequestHandler();

        var api = axios.create({
            baseURL: this.baseUrl,
        });

        api.interceptors.request.use(config => {
            config.params = config.params || {};
            // config.params.lang = localStorage.getItem('i18nextLng');

            if (auth) {
                config.headers = {
                    ...config.headers,
                    'Access-Control-Allow-Headers': 'Authorization',
                    'Authorization': 'Bearer ' + auth.getToken(),
                };
            }

            let cancelToken = axios.CancelToken.source();
            config.cancelToken = cancelToken.token;

            // Handle single request
            if (config.singleRequest) {
                singleRequestHandler.handle(config.method + '::' + config.url, cancelToken.cancel);
            }

            dispatch({
                type: API.REQUEST,
                payload: {
                    request: {
                        ...config,
                        cancel: cancelToken.cancel,
                    }
                },
            });

            return config;
        }, error => {
            return Promise.reject(error);
        });

        api.interceptors.response.use(response => {
            dispatch({
                type: API.SUCCESS,
                payload: {response},
            });

            return response;
        }, error => {
            if (!axios.isCancel(error)) {
                dispatch({
                    type: API.ERROR,
                    payload: error,
                });

                return Promise.reject(error);
            }
        });

        return api;
    }

}

export default ApiFactory;
