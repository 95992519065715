import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import * as actions from '../actions';
// import * as selectors from '../selectors';
import SelectUser from './components/SelectUser';
import * as shippingUserSelectors from '../../ShippingUser/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

const mapStateToProps = (state, props) => {
    return {
        ...props,
        users: selectors.getState(state, 'users', []),
        loading: shippingUserSelectors.getLoading(state),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchUsers: () => dispatch(actions.fetchUsers()),
});

class SelectUserContainer extends React.PureComponent {
    componentDidMount() {
        if (!this.props.users.length) {
            this.props.fetchUsers();
        }
    }

    render() {
        return <SelectUser {...this.props}/>
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectUserContainer);

Container.defaultProps = {
    autoSelect: false,
    onChange: () => {
    },
};

Container.propTypes = {
    autoSelect: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default Container;
