import React, { useState } from 'react';
import { Form, Layout } from 'antd/lib/index';
import { withTranslation } from 'react-i18next';

import { CURRENT_PAGE, DELIVERY_OBJECT } from './../../constants';
import { useDeepCompareEffect } from './../../../../hooks';

import FormCustomer from './../../FormCustomer/FormCustomerContainer';
import FormShippingReturnPartner from './../../FormShippingReturnPartner/FormShippingReturnPartnerContainer';
import FormSelectCustomer from './../../FormSelectCustomer/FormSelectCustomerContainer';
import FormSelectShippingReturnPartner from './../../FormSelectShippingReturnPartner/FormSelectShippingReturnPartnerContainer';

const { Content } = Layout;

const FormDeliveryObject = ({ input, object, t, onOpenPackageListByBagPopup, getLastmileCarrierSuggest }) => {
    const [page, setPage] = useState();

    const renderContent = () => {
        switch (page) {
            case CURRENT_PAGE.CUSTOMER:
            case CURRENT_PAGE.RETURN: {
                return (
                    <Content className="a-sub-main gflex gflex-direction-column">
                        <Form className="a-block">
                            <div className="block--primary">
                                <div className="title">
                                    <div className="left">
                                        <span className="a-text--bold">{t('delivery_note:label.title_create_page')}</span>
                                    </div>
                                </div>
                                <div className="body not-boxshadow">
                                    <div className="a-block-info gflex galign-center gjustify-center apy-40">
                                        {page === CURRENT_PAGE.RETURN && (
                                            <FormSelectShippingReturnPartner
                                                input={input}
                                            />
                                        )}

                                        {page === CURRENT_PAGE.CUSTOMER && (
                                            <FormSelectCustomer
                                                input={input}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Content>
                );
            }
            case CURRENT_PAGE.CUSTOMER_DETAIL: {
                return (
                    <FormCustomer
                        getLastmileCarrierSuggest={getLastmileCarrierSuggest}
                        input={input}
                        onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                    />
                );
            }
            case CURRENT_PAGE.RETURN_DETAIL: {
                return (
                    <FormShippingReturnPartner
                        input={input}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    useDeepCompareEffect(() => {
        let newPage = page;

        switch (object) {
            case DELIVERY_OBJECT.CUSTOMER: {
                newPage =  input.id_customer ? CURRENT_PAGE.CUSTOMER_DETAIL : CURRENT_PAGE.CUSTOMER;

                break;
            }
            case DELIVERY_OBJECT.RETURN: {
                newPage = input.id_shipping_return_partner ? CURRENT_PAGE.RETURN_DETAIL : CURRENT_PAGE.RETURN;

                break;
            }
            default: {
                break;
            }
        }

        setPage(newPage);
    }, [input]);

    return (
        <div className="a-flex-1 gflex gflex-direction-column min-w-0">
            {renderContent()}
        </div>
    );
};

export default withTranslation()(FormDeliveryObject);
