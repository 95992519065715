import { api } from './../../system/api';

const BASE_URL = '/notes';

export default {
    getNotes: params => api.get(BASE_URL, {
        params
    }),
    detail: id => api.get(`${BASE_URL}/${id}`),
    add: data => api.post(BASE_URL, data),
    update: data => api.put(`${BASE_URL}/${data.id}`, data),
    delete: id => api.delete(`${BASE_URL}/${id}`)
};
