import { Button, Col, notification, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'
import { dateFormatter } from '../../../Common/services/format'
import { getVar } from '../../../../system/support/helpers'
import { BAG_REQUEST_STATUS } from '../constants'
import apiService from '../../apiService'
import { dispatch } from '../../../../system/store'
import { fetchLogs } from '../../../Common/Log/actions'

function BagRequestInfo(props) {
    const { data, t, loading } = props
    const [info, setInfo] = useState({})
    const [loadingAction, setLoadingAction] = useState(false)

    useEffect(() => {
        setInfo(data)
    }, [data])

    const handleChangeStatus = status => {
        let nextStatus = ''
        switch (status) {
            case BAG_REQUEST_STATUS.NEW:
                nextStatus = BAG_REQUEST_STATUS.PROCESSING
                break
            case BAG_REQUEST_STATUS.PROCESSING:
                nextStatus = BAG_REQUEST_STATUS.COMPLETED
                break
            default:
                nextStatus = ''
        }
        const requestId = getVar(info, 'id')
        setLoadingAction(true)
        const response = status === BAG_REQUEST_STATUS.NEW ? apiService.processPackingRequest(requestId) : apiService.completePackingRequest(requestId)
        response
            .then(res => {
                setInfo(get(res, 'data', {}))
                setTimeout(() => {
                    dispatch(fetchLogs('packaging-requests', requestId))
                }, 1000)
                notification.success({ message: t('bag:message.change_packing_request_status.success', { status: t(`bag:bag_request_status.${nextStatus}`) }) })
            })
            .catch(() => {
                notification.error({ message: t('bag:message.change_packing_request_status.failed', { status: t(`bag:bag_request_status.${nextStatus}`) }) })
            })
            .finally(() => {
                setLoadingAction(false)
            })
    }

    const status = getVar(info, 'status', BAG_REQUEST_STATUS.NEW)

    return (
        <Spin spinning={loading}>
            <div className="block--primary a-package package-detail">
                <div className="title a-content--bag--title gflex galign-center gjustify-space-between">
                    <div>{t('bag:title.bag_request_info')}</div>
                    <div>
                        {!loading && [BAG_REQUEST_STATUS.NEW, BAG_REQUEST_STATUS.PROCESSING].includes(status) ? (
                            <Button
                                loading={loadingAction}
                                onClick={() => handleChangeStatus(status)}>
                                {status === BAG_REQUEST_STATUS.NEW ? t('bag:btn.processing_packing_request') : t('bag:btn.completed_packing_request')}
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="body a-package__body ">
                    <Row
                        gutter={{ lg: 24, xl: 48 }}
                        className="a-block-info pb-3">
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.bagging_request_code')}</label>
                            <span className="info--value _value">{get(info, 'code') || '---'}</span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.midmile_shipping_partner')}</label>
                            <span className="info--value _value">
                                {get(info, 'shipping_partner.name') ? `${get(info, 'shipping_partner.name')} (${get(info, 'shipping_partner.code')})` : '---'}
                            </span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.warehouse_input')}</label>
                            <span className="info--value _value">{get(info, 'warehouse.code') || '---'}</span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.created_at')}</label>
                            <span className="info--value _value">{get(info, 'created_at') ? dateFormatter.dateTime(get(info, 'created_at')) : '---'}</span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.processing_at')}</label>
                            <span className="info--value _value">
                                {get(info, 'processing_time') ? dateFormatter.dateTime(get(info, 'processing_time')) : '---'}
                            </span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('bag:label.finished_at')}</label>
                            <span className="info--value _value">
                                {get(info, 'completed_time') ? dateFormatter.dateTime(get(info, 'completed_time')) : '---'}
                            </span>
                        </Col>
                        <Col
                            className="info--group _code-entry"
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}>
                            <label className="info--label _label">{t('status')}</label>
                            <span className="info--value _value">{get(info, 'status') ? t(`bag:bag_request_status.${get(info, 'status')}`) : '---'}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    )
}
export default withTranslation()(BagRequestInfo)
