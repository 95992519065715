import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import clsx from 'clsx';

import { BAG_TYPE, PACKAGE_TYPE, TYPES } from './../../../StorageFees/constants';

import BagStorageFees from './BagStorageFees/BagStorageFeesContainer';
import PackageStorageFees from './PackageStorageFees/PackageStorageFeesContainer';
import Tabs from './../../../Common/components/Tabs/Tabs';
import TabPane from './../../../Common/components/Tabs/TabPane';

const { Content } = Layout;

const StorageFees = (
    { match: { params },
    history,
    loadings,
    paginations,
    storageFees,
    t,
    tableDisplayStorageFees,
    handleCreateButtonClick,
    setMenuActive,
    getStorageFees
}) => {
    const [editModalVisibles, setEditModalVisibles] = useState({
        bag: 0,
        package: 0
    });

    handleCreateButtonClick = () => {
        let { type } = params;

        if (!TYPES.includes(type)) {
            type = PACKAGE_TYPE;
        }

        setEditModalVisibles({
            ...editModalVisibles,
            [type]: editModalVisibles[type] + 1
        });
    };

    useEffect(() => {
        setMenuActive('config/storage-fees');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTab = tab => {
        history.push(`/config/storage-fees/${tab}`);
    };

    return (
        <Content className="aml-24 aml-16-lg a-content-config height-100 gflex gflex-direction-column">
            <Tabs
                activeKey={TYPES.includes(params.type) ? params.type : PACKAGE_TYPE}
                className="a-tabs a-tabs--container"
                defaultActiveKey="main"
                keepMounted={false}
                rightTopBarContent={(
                    <Button
                        className="a-btn a-btn--primary a-btn--h-25 a-btn--font-small a-text--regular _btn-create-storage-fee"
                        type="primary"
                        onClick={handleCreateButtonClick}
                    >
                        {t('btn.create_new')}
                    </Button>
                )}
                onChange={handleChangeTab}
            >
                <TabPane
                    key={PACKAGE_TYPE}
                    tab={(
                        <>
                            {t('storage_fee:types.package')}
                            <span className={clsx('ml-2', {
                                invisible: params.type === BAG_TYPE
                            })}>
                                ({paginations.package.total})
                            </span>
                        </>
                    )}
                >
                    <PackageStorageFees
                        editModalVisible={editModalVisibles[PACKAGE_TYPE]}
                        loading={loadings[PACKAGE_TYPE]}
                        pagination={paginations[PACKAGE_TYPE]}
                        storageFees={storageFees[PACKAGE_TYPE]}
                        tableDisplayStorageFees={tableDisplayStorageFees[PACKAGE_TYPE]}
                        getStorageFees={getStorageFees.bind(undefined, PACKAGE_TYPE)}
                    />
                </TabPane>

                <TabPane
                    key={BAG_TYPE}
                    tab={(
                        <>
                            {t('storage_fee:types.bag')}
                            <span className={clsx('ml-2', {
                                invisible: params.type === PACKAGE_TYPE
                            })}>
                                ({paginations.bag.total})
                            </span>
                        </>
                    )}
                >
                    <BagStorageFees
                        editModalVisible={editModalVisibles[BAG_TYPE]}
                        loading={loadings[BAG_TYPE]}
                        pagination={paginations[BAG_TYPE]}
                        storageFees={storageFees[BAG_TYPE]}
                        tableDisplayStorageFees={tableDisplayStorageFees[BAG_TYPE]}
                        getStorageFees={getStorageFees.bind(undefined, BAG_TYPE)}
                    />
                </TabPane>
            </Tabs>
        </Content>
    );
};

export default StorageFees;
