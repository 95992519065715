import { connect } from 'react-redux';

import { successUpdateNote } from './../actions';

import ActionAttention from './components';

const mapDispatchToProps = {
    successUpdateNote
};

export default connect(
    undefined,
    mapDispatchToProps
)(ActionAttention);
