import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import lodash from 'lodash'
import { trans } from '../../../../../system/i18n'
import { Link } from '../../../../../system/routing'
import { getVar } from '../../../../../system/support/helpers'

const BAG_ACTION = {
    CREATE: 'BAG.CREATE',
    PACKING: 'BAG.PACKING',
    REMOVE_PACKAGE: 'BAG.REMOVE_PACKAGE',
    PACKING_COMPLETE: 'BAG.PACKING_COMPLETE',
    UPDATE: 'BAG.UPDATE',
    SCAN: 'BAG.SCAN',
    CHANGE_TRANSPORT_STATUS: 'BAG.CHANGE_TRANSPORT_STATUS',
    VOIDED_SERVICE: 'BAG.VOIDED_SERVICE',
    EXPORT_MANIFEST: 'BAG.EXPORT_MANIFEST',
    PRINT_BARCODE: 'BAG.PRINT_BARCODE',
    ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: 'BAG.ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE',
    REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: 'BAG.REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE',
    RECEIVED: 'BAG.RECEIVED',
}

class BagLogMessage extends React.PureComponent {
    render() {
        const { log, t } = this.props
        const { payload } = log

        switch (log.action) {
            case BAG_ACTION.CREATE:
                if (lodash.has(log, 'payload.create_same_bag')) {
                    return trans('bag:log.CREATE_SAME_BAG', {
                        warehouse: <b>{lodash.get(log, 'payload.warehouse_input.name', '')}</b>,
                        bag: (
                            <Link
                                to="bags.detail"
                                params={{ id: lodash.get(log, 'payload.create_same_bag') }}>
                                {lodash.get(log, 'payload.create_same_bag')}
                            </Link>
                        ),
                    })
                }
                return trans('bag:log.CREATE', {
                    warehouse: <b>{lodash.get(log, 'payload.warehouse_input.name', '')}</b>,
                })

            case BAG_ACTION.PACKING_COMPLETE:
                return trans('bag:log.PACKING_COMPLETE')

            case BAG_ACTION.UPDATE:
            case BAG_ACTION.CHANGE_TRANSPORT_STATUS:
                return (
                    <span>
                        {trans('bag:log.UPDATE')}
                        {this.renderMessageDetailUpdate(log)}
                    </span>
                )

            case BAG_ACTION.PACKING:
                return trans('bag:log.PACKING', {
                    bag_packing: this.linkToPackage(lodash.get(log, 'payload.bag_packing')),
                })

            case BAG_ACTION.REMOVE_PACKAGE:
                let code_package = lodash.get(log, 'payload.package.code', '')

                return trans('bag:log.REMOVE_PACKAGE', {
                    bag_packing: (
                        <Link
                            to="packages.detail"
                            params={{ id: code_package }}>
                            {code_package}
                        </Link>
                    ),
                })
            case BAG_ACTION.SCAN:
                let bag = lodash.get(log, 'payload.bag')
                if (lodash.has(log, 'payload.bag.delivery_note')) {
                    let deliveryNoteCode = lodash.get(log, 'payload.bag.delivery_note')
                    return trans('bag:log.bag_scan_warehouse_delivery_note', {
                        action_scan: t('scan:label.' + bag.status_warehouse),
                        warehouse_current: <b>{bag.warehouse_current}</b>,
                        delivery_note: (
                            <Link
                                to="delivery-notes"
                                params={{ id: deliveryNoteCode }}>
                                {deliveryNoteCode}
                            </Link>
                        ),
                    })
                } else {
                    return trans('bag:log.SCAN', {
                        action_scan: t('scan:label.' + bag.status_warehouse),
                        warehouse_current: <b>{bag.warehouse_current}</b>,
                    })
                }
            case BAG_ACTION.VOIDED_SERVICE: {
                return trans('bag:log.VOIDED_SERVICE', {
                    payload: lodash.get(payload, 'void_services', []).join(', '),
                })
            }
            case BAG_ACTION.EXPORT_MANIFEST: {
                const language = getVar(payload, 'language', '')
                return trans('bag:log.EXPORT_MANIFEST', {
                    exporter: <b>{`${lodash.get(log, 'creator.name')} (${lodash.get(log, 'creator.username')})`}</b>,
                    language: <b>{language && language !== 'en' ? trans(`bag:EXPORT_MANIFEST_LANGUAGE.${language}`) : ''}</b>,
                })
            }
            case BAG_ACTION.PRINT_BARCODE: {
                return trans('bag:log.PRINT_BARCODE')
            }
            case BAG_ACTION.ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
                return trans('bag:log.ADD_DELIVERY_NOTE_TRANSPORT_WAREHOUSE')
            }
            case BAG_ACTION.REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
                return trans('bag:log.REMOVE_DELIVERY_NOTE_TRANSPORT_WAREHOUSE')
            }
            case BAG_ACTION.RECEIVED: {
                return trans('bag:log.RECEIVED', { warehouse: lodash.get(payload, 'warehouse_code') })
            }
            default:
                return log.action
        }
    }

    linkToBag({ id, code } = {}) {
        return (
            <Link
                to="bags.detail"
                params={{ id: code }}>
                {code}
            </Link>
        )
    }

    linkToPackage({ id_package, code_package } = {}) {
        return (
            <Link
                to="packages.detail"
                params={{ id: code_package }}>
                {code_package}
            </Link>
        )
    }

    /**
     * Render ra log cập nhật thông tin bao
     * @param log
     * @returns {string}
     */
    renderMessageDetailUpdate(log) {
        let { payload } = log
        let properties = {}
        let service_use = {}
        let id_agencies = {}
        let change_fields = []
        let oldValue
        let newValue
        let field
        change_fields = lodash.get(payload, 'change_fields', [])
        change_fields = lodash.mapValues(change_fields, function (o, key) {
            let unit, newValue, reason
            field = undefined

            switch (key) {
                case 'weight_real':
                    oldValue = lodash.get(o, 'old') || 0
                    newValue = lodash.get(o, 'new') || 0
                    unit = 'kg'
                    field = trans('bag:label.' + key).toLowerCase()
                    break
                case 'length':
                case 'height':
                case 'width':
                    oldValue = 100 * lodash.get(o, 'old') || 0
                    newValue = 100 * lodash.get(o, 'new') || 0
                    oldValue = Number(oldValue.toFixed(3))
                    newValue = Number(newValue.toFixed(3))
                    unit = 'cm'
                    field = trans('package:label.' + key).toLowerCase()
                    break
                case 'volume':
                    oldValue = 1000000 * lodash.get(o, 'old') || 0
                    newValue = 1000000 * lodash.get(o, 'new') || 0
                    oldValue = Number(oldValue.toFixed(3))
                    newValue = Number(newValue.toFixed(3))
                    unit = (
                        <span>
                            cm<sup>3</sup>
                        </span>
                    )
                    field = trans('package:label.' + key).toLowerCase()
                    break
                case 'status':
                    oldValue = lodash.get(o, 'old')
                    newValue = lodash.get(o, 'new')
                    oldValue = oldValue ? trans(`bag:statuses.${lodash.get(o, 'old')}`) : null
                    newValue = newValue ? trans(`bag:statuses.${lodash.get(o, 'new')}`) : null
                    field = trans('bag:label.' + key).toLowerCase()
                    break
                case 'type':
                    oldValue = lodash.get(o, 'old')
                    newValue = lodash.get(o, 'new')
                    oldValue = oldValue ? trans('bag:label.' + lodash.get(o, 'old')) : null
                    newValue = newValue ? trans('bag:label.' + lodash.get(o, 'new')) : null
                    field = trans('bag:label.' + key).toLowerCase()
                    break
                case 'code':
                    oldValue = lodash.get(o, 'old')
                    newValue = lodash.get(o, 'new')
                    field = trans(`bag:label.${key}`).toLowerCase()
                    reason = trans('bag:message.agency_change_bag_area', {
                        agency: `${lodash.get(log, 'creator.name')} (${lodash.get(log, 'creator.username')})`,
                    })

                    break
                default:
                    oldValue = lodash.get(o, 'old')
                    newValue = lodash.get(o, 'new')
                    field = trans('bag:label.' + key).toLowerCase()
                    break
            }

            return {
                old: oldValue,
                new: newValue,
                unit,
                reason,
            }
        })

        lodash.map(lodash.get(payload, 'change_properties', []), (item, key) => {
            oldValue = null
            newValue = null
            if (key === 'add') {
                newValue = lodash.join(lodash.values(item), ', ')
                properties = { ...properties, ...{ new: newValue, unit: null } }
            }
            if (key === 'remove') {
                oldValue = lodash.join(lodash.values(item), ', ')
                properties = { ...properties, ...{ old: oldValue, unit: null } }
            }
        })

        if (!lodash.isEmpty(properties)) {
            change_fields.properties = properties
        }

        lodash.map(lodash.get(payload, 'change_services', []), (item, key) => {
            oldValue = null
            newValue = null
            if (key === 'add') {
                newValue = lodash.join(lodash.values(item), ', ')
                service_use = { ...service_use, ...{ new: newValue, unit: null } }
            }
            if (key === 'remove') {
                oldValue = lodash.join(lodash.values(item), ', ')
                service_use = { ...service_use, ...{ old: oldValue, unit: null } }
            }
        })

        if (!lodash.isEmpty(service_use)) {
            change_fields.service_use = service_use
        }

        const change_agencies = lodash.get(payload, 'change_agencies', {})
        if (!lodash.isEmpty(change_agencies)) {
            newValue = lodash.get(change_agencies, 'add.name')
            oldValue = lodash.get(change_agencies, 'remove.name')
            id_agencies = { new: newValue, old: oldValue, unit: null }

            if (!lodash.isEmpty(id_agencies)) {
                change_fields.id_agencies = id_agencies
            }
        }

        return Object.keys(change_fields).map(param => {
            let data = change_fields[param] || {}
            let langKey = 'bag:log.update_param'
            if (data.reason) {
                langKey = 'bag:log.update_param_with_reason'
            }
            if (!data.old) {
                langKey = 'bag:log.set_param'
            } else if (!data.new) {
                if (lodash.isNumber(data.old) || lodash.isString(data.old)) {
                    langKey = 'bag:log.remove_param_with_value'
                } else {
                    langKey = 'bag:log.remove_param'
                }
            }

            field = trans('bag:label.' + param)

            if (!data.old && data.new) {
                if (lodash.isNumber(data.new) || lodash.isString(data.new)) {
                    langKey = 'bag:log.add_param_with_value'
                } else {
                    langKey = 'bag:log.add_param'
                }
                field = field.toString().toLowerCase()
            }

            return (
                <span
                    className="ml-1"
                    key={Math.random() + '_' + Math.random()}>
                    {trans(langKey, {
                        param: !data.new ? field.toLowerCase() : field,
                        old_value: data.old ? (
                            <b>
                                {data.old}
                                {data.unit}
                            </b>
                        ) : null,
                        new_value: (
                            <b>
                                {data.new}
                                {data.unit}
                            </b>
                        ),
                        reason: data.reason ? <b>{data.reason}</b> : null,
                    })}
                </span>
            )
        })
    }
}

BagLogMessage.defaultProps = {
    log: {},
}

BagLogMessage.propTypes = {
    log: PropTypes.object,
}

export default withTranslation()(BagLogMessage)
