import React from 'react';
import Background from '../../../resources/images/403.png';
import {t} from "../../../system/i18n"
import Link from "../../../system/routing/Link";

export default () => (
    <div className="a-page--403 a-select--w-100" style={{
        backgroundImage: `url(${Background})`, backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }}>
        <h1 className="company-name">Xlogistic</h1>
        <div className="a-page--403__detail">
            <div className="title">
                <h1><span>4</span><span className="special">0</span><span>3</span></h1>
            </div>
            <h2>{t('message.403')}</h2>
            <button className="button-back">
                <Link to="home" className={'a-text--white'}>{t('btn.back_home')}</Link>
            </button>
        </div>
    </div>
);
