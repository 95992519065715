import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { AutoComplete, Icon, Input, Modal } from 'antd'
import { trans } from '../../../../system/i18n'
import ElementManager from '../../../Common/services/ElementManager'
import { inputFocusParser } from '../../CreatePackageViaScan/services'
import { ELEMENT } from '../../CreatePackageViaScan/services/inputFocus/constants'
import { find, isEmpty, get } from 'lodash'

class LinkOrderPackage extends React.PureComponent {
    constructor(props) {
        super(props)
        this.elements = new ElementManager()
        this.state = {
            open: false,
        }
    }

    componentDidMount() {
        this.handleFocusInput()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputFocusId !== this.props.inputFocusId) {
            this.handleFocusInput()
        }
    }

    handleFocusInput = () => {
        const element = inputFocusParser.parse(this.props.inputFocus, this.props.package.id)

        if (element === ELEMENT.PACKAGE_ORDER_CODE) {
            this.elements.focus(element)
        }
    }

    onSearch = keyword => {
        this.props.onSearch(keyword)
    }

    onSelect = orderCode => {
        this.linkOrder(orderCode)
    }

    onSubmit = event => {
        event.preventDefault()
        this.linkOrder(this.props.orderCode)
    }

    linkOrder = orderCode => {
        const { linking, searching, onLink, showWarning } = this.props

        if (!orderCode || linking || searching) {
            return
        }

        if (showWarning) {
            return this.confirm(orderCode)
        }

        return onLink(orderCode)
    }

    confirm = orderId => {
        const { t, onLink, orderCodes, orderSuggestCodes } = this.props
        let listOrderCodes = orderCodes
        if (!orderCodes.length) {
            listOrderCodes = orderSuggestCodes
        }
        const orderInfo = find(listOrderCodes, ['order_id', orderId])

        const agency = get(orderInfo, 'info.agency', {})
        const orderCode = get(orderInfo, 'info.order.code', '')
        const orderContent = orderCode + `${isEmpty(agency) ? '' : ' (' + get(agency, 'name', '') + ' )'}`

        Modal.confirm({
            title: trans('package:link_order.message_confirm', {
                package: <b>{this.props.package.code}</b>,
                order: <b>{orderContent}</b>,
            }),
            content: t('package:link_order.message_confirm_description'),
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: onLink.bind(undefined, {
                id_order: orderId,
                order_code: orderCode,
            }),
        })
    }

    onChange = value => {
        this.props.onChange(value)
    }

    handleFocus = () => {
        const { onFocus } = this.props

        this.setState({
            open: true,
        })

        onFocus()
    }

    handleBlur = () => {
        this.setState({
            open: false,
        })
    }

    render() {
        const { open } = this.state
        const { t, canLink, linking, orderCode, orderCodes, orderSuggestCodes, searching, className } = this.props
        return (
            <form
                onSubmit={this.onSubmit}
                className="a-form--loading loading-input">
                <AutoComplete
                    size="large"
                    placeholder={t('package:label.order_matching_code')}
                    className={`a-input a-input--matching-code _package_matching_code ${className ? className : ''}`}
                    disabled={!canLink || linking}
                    dropdownClassName="_dropdown-order"
                    dataSource={orderCodes.length ? orderCodes : orderSuggestCodes}
                    open={open}
                    value={orderCode}
                    onBlur={this.handleBlur}
                    onSearch={this.onSearch}
                    onSelect={this.onSelect}
                    onFocus={this.handleFocus}
                    onChange={this.onChange}
                    ref={this.elements.set.bind(this.elements, ELEMENT.PACKAGE_ORDER_CODE)}
                    allowClear={true}
                    children={<Input tabIndex={5} />}
                />
                {linking || searching ? (
                    <Icon
                        type="loading-3-quarters"
                        className="icon-loading a-text--black"
                    />
                ) : null}
            </form>
        )
    }
}

LinkOrderPackage.defaultProps = {
    package: {},
    orderCodes: [],
    canLink: false,
    showWarning: true,
    searching: false,
    linking: false,
    onSearch: () => {},
    onLink: () => {},
    onFocus: () => {},
    onChange: () => {},
}

LinkOrderPackage.propTypes = {
    package: PropTypes.object,
    orderCodes: PropTypes.array,
    canLink: PropTypes.bool,
    showWarning: PropTypes.bool,
    searching: PropTypes.bool,
    linking: PropTypes.bool,
    onSearch: PropTypes.func,
    onLink: PropTypes.func,
    onFocus: PropTypes.func,
}

export default withTranslation()(LinkOrderPackage)
