import React from 'react';
import {get, map} from 'lodash';
import {withTranslation} from 'react-i18next';

import Log from './Log';

class LogPage extends React.Component {
    render() {
        const {logs, object} = this.props;

        return (
            <div className="list-log __list-log">
                {map(get(logs, object), (log, index) => (
                    <Log key={index} log={log} object={object} />
                ))}
            </div>
        );
    }
}

export default withTranslation()(LogPage);
