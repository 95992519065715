import React from 'react';
import { Layout } from 'antd';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as actions from './../actions';
import { setMenuActive } from './../../Common/actions';
import pageService from './../../Common/Page/pageService';

import FormSearch from './components/FormSearch';
import ListInventory from './components/ListInventory';
import SearchableComponent from './../../Common/components/SearchableComponent';

const { Content } = Layout;

const mapStateToProps = (state) => {
    return {
        inventories: get(state, 'inventory.list.inventories'),
        pagination: get(state, 'inventory.list.pagination'),
        loading: get(state, 'inventory.loading')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInventories: (filter) => dispatch(actions.fetchInventories(filter)),
        setMenuActive: (menu) => dispatch(setMenuActive(menu))
    };
};

class ListInventoryContainer extends SearchableComponent {
    onSearch = (filter) => {
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    };

    onChangeFilter = (filter) => {
        this.props.fetchInventories(filter);
    };

    componentDidMount() {
        const { t, setMenuActive } = this.props;

        setMenuActive('inventory/');
        pageService.setTitle(t('inventory:inventory_history_page_title'));
        super.componentDidMount();
    }

    getCurrentFilter = () => {
        const { sort_by, sort_direction, location } = this.props;

        return {
            page: 1,
            sort_by: sort_by || 'created_at',
            sort_direction: sort_direction || 'desc',
            ...this.getFilterFromLocation(location),
        };
    };

    render() {
        return (
            <Layout>
                <Content className="a-content a-content--page-list">
                    <FormSearch
                        {...this.props}
                        onSearch={this.onSearch}
                        filter={this.getCurrentFilter()}
                    />
                    <ListInventory
                        {...this.props}
                        filter={this.getCurrentFilter()}
                        onSearch={this.onSearch}
                    />
                </Content>
            </Layout>
        );
    }
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListInventoryContainer));
