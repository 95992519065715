import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { withTranslation } from 'react-i18next';

import { get } from 'lodash';
import moment from 'moment';

import ChooseWarehouse from '../../../Warehouse/ChooseWarehouse/ChooseWarehouseContainer';
import SelectUserContainer from "../../../User/SelectUser/SelectUserContainer";

const Search = Input.Search;

class FormSearch extends Component {
    state = {
        filter: {
            ...this.props.filter
        }
    };

    resetFilter = () => {
        this.setState({filter: {}});
        this.props.onSearch({});
    };

    onFilter = () => {
        this.props.onSearch(this.state.filter);
    };

    onChangeInput = (field, e) => {
        const { filter } = this.state;

        filter[field] = e.target.value;
        this.setState({
            filter
        });
    };

    onChangeSelect = (field, value) => {
        const { filter } = this.state;

        filter[field] = value;
        this.setState({
            filter
        });
    };

    onChangeTime = (field, value) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
            }
        }));
    };

    render() {
        const { filter } = this.state;
        const { t } = this.props;

        return (
            <div className="a-content--search-header search-default" id="list-search">
                <Row gutter={{lg: 12, xl: 24, xxl: 24}}>
                    <Form className="a-form">
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content item--special"
                                label={t('inventory:code_check')}
                                labelCol={{span: 24}}>
                                <Search
                                    className="a-input--search _packages_search"
                                    value={get(filter, 'code')}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                    onPressEnter={this.onFilter}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} xl={3} xxl={2}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('inventory:warehouse')}
                                labelCol={{span: 24}}>
                                <ChooseWarehouse
                                    allowClear
                                    active={true}
                                    autoSelect={false}
                                    dropdownClassName="_dropdown_id_warehouse"
                                    isPermission={true}
                                    isSelect={true}
                                    className="a-select a-select--search-content"
                                    value={filter.id_warehouse}
                                    onChange={this.onChangeSelect.bind(this, 'id_warehouse')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('inventory:check_time')}
                                labelCol={{span: 24}}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={filter['created_at[from]'] ? moment(filter['created_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _created-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={filter['created_at[to]'] ? moment(filter['created_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={11} xl={9}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('inventory:reconciliation_time')}
                                labelCol={{span: 24}}>
                                <Row gutter={4}>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _inventoried-at-from"
                                            locale={locale}
                                            placeholder={t('label.time_start')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            value={filter['inventoried_at[from]'] ? moment(filter['inventoried_at[from]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'inventoried_at[from]')}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            className="width-100-pc _inventoried-at-to"
                                            locale={locale}
                                            placeholder={t('label.time_end')}
                                            format="HH:mm:ss DD/MM/YYYY"
                                            showTime={{
                                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                                            }}
                                            value={filter['inventoried_at[to]'] ? moment(filter['inventoried_at[to]']) : null}
                                            onChange={this.onChangeTime.bind(this, 'inventoried_at[to]')}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('inventory:checker')}
                                labelCol={{span: 24}}>
                                <SelectUserContainer
                                    allowClear
                                    className="a-select a-select--search-content"
                                    dropdownClassName="_dropdown_select_user"
                                    value={filter.id_creator}
                                    onChange={this.onChangeSelect.bind(this, 'id_creator')}
                                    emptyOption={false}
                                    placeholder={t('select_employee')}
                                />
                            </Form.Item>
                        </Col>
                    </Form>
                </Row>
                <div className="search--action">
                    <span onClick={this.resetFilter} className="link-reload cursor-pointer"><Icon
                        type="sync"/> {t('inventory:refresh_filter')}</span>
                    <Button type="primary"
                            onClick={this.onFilter}
                            className="a-btn a-btn--primary a-btn--search-list ">{t('inventory:filter')}</Button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(FormSearch);
