import React from 'react';
import { Col, Form, Select } from 'antd';
import { get, isFunction, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class LocationShippingPartnerForm extends React.PureComponent {
    /**
     * handle action change country
     * @param value
     */
    onChangeCountry = value => {
        const { form, fetchProvince, onChangeLocation, resetError, setCurrentCountry } = this.props;

        resetError('id_location');
        setCurrentCountry(value);
        fetchProvince(value);

        const data = form.getFieldsValue();
        data.id_country = value;
        data.id_city = undefined;
        data.id_district = undefined;
        data.id_ward = undefined;

        form.setFieldsValue(data);

        if (isFunction(onChangeLocation)) {
            onChangeLocation(data);
        }
    }

    /**
     * handle action change city
     * @param value
     */
    onChangeCity = value => {
        const { form, fetchDistrict, onChangeLocation, resetError, setCurrentCity } = this.props;

        resetError('id_location');
        setCurrentCity(value);
        fetchDistrict(value);

        const data = form.getFieldsValue();
        data.id_city = value;
        data.id_location = value;
        data.id_district = undefined;
        data.id_ward = undefined;

        form.setFieldsValue(data);

        if (isFunction(onChangeLocation)) {
            onChangeLocation(data);
        }
    }

    /**
     * handle action change district
     * @param value
     */
    onChangeDistrict = value => {
        const { form, fetchWard, onChangeLocation, resetError, setCurrentDistrict } = this.props;

        setCurrentDistrict(value);
        resetError('id_location');
        fetchWard(value);

        const data = form.getFieldsValue();
        data.id_district = value;
        data.id_location = value;
        data.id_ward = undefined;

        form.setFieldsValue(data);

        if (isFunction(onChangeLocation)) {
            onChangeLocation(data);
        }
    };

    onChangeWard = value => {
        const { form, onChangeLocation, resetError } = this.props;

        resetError('id_location');

        const data = form.getFieldsValue();
        data.id_location = value;
        data.id_ward = value;

        form.setFieldsValue(data);

        if (isFunction(onChangeLocation)) {
            onChangeLocation(data);
        }
    };

    componentDidUpdate(prevProps) {
        const { cities, countries, districts, form, idCity, idCountry, idDistrict, idWard, wards, fetchDistrict, fetchProvince, fetchWard } = this.props;

        if (idCountry && idCountry !== prevProps.idCountry) {
            fetchProvince(idCountry);

            if (countries && countries.length) {
                form.setFieldsValue({
                    id_country: idCountry
                });
            }
        }

        if (idCity && idCity !== prevProps.idCity) {
            fetchDistrict(idCity);

            if (cities && cities.length) {
                form.setFieldsValue({
                    id_city: idCity
                });
            }
        }

        if (idDistrict && idDistrict !== prevProps.idDistrict) {
            fetchWard(idDistrict);

            if (districts && districts.length) {
                form.setFieldsValue({
                    id_district: idDistrict
                });
            }
        }

        if (idWard && idWard !== prevProps.idWard) {
            if (wards && wards.length) {
                form.setFieldsValue({
                    id_ward: idWard
                });
            }
        }

        if (idCountry && !isEqual(countries, prevProps.countries)) {
            if (countries && countries.length) {
                form.setFieldsValue({
                    id_country: idCountry
                });
            } else {
                form.setFieldsValue({
                    id_country: undefined
                });
            }
        }

        if (idCity && !isEqual(cities, prevProps.cities)) {
            if (cities && cities.length) {
                form.setFieldsValue({
                    id_city: idCity
                });
            } else {
                form.setFieldsValue({
                    id_city: undefined
                });
            }
        }

        if (idDistrict && !isEqual(districts, prevProps.districts)) {
            if (districts && districts.length) {
                form.setFieldsValue({
                    id_district: idDistrict
                });
            } else {
                form.setFieldsValue({
                    id_district: undefined
                });
            }
        }

        if (idWard && !isEqual(wards, prevProps.wards)) {
            if (wards && wards.length) {
                form.setFieldsValue({
                    id_ward: idWard
                });
            } else {
                form.setFieldsValue({
                    id_ward: undefined
                });
            }
        }
    }

    componentDidMount() {
        const { cities, countries, districts, form, idCity, idCountry, idDistrict, idWard, wards, fetchCountry, fetchDistrict, fetchProvince, fetchWard } = this.props;

        if (Array.isArray(countries) && countries.length === 0) {
            fetchCountry(0);
        }

        if (idCountry) {
            fetchProvince(idCountry);

            if (countries && countries.length && countries.find(country => country.location.id === idCountry)) {
                form.setFieldsValue({
                    id_country: idCountry
                });
            }
        }

        if (idCity) {
            fetchDistrict(idCity);

            if (cities && cities.length && cities.find(city => city.location.id === idCity)) {
                form.setFieldsValue({
                    id_city: idCity
                });
            }
        }

        if (idDistrict) {
            fetchWard(idDistrict);

            if (districts && districts.length && districts.find(district => district.location.id === idDistrict)) {
                form.setFieldsValue({
                    id_district: idDistrict
                });
            }
        }

        if (idWard) {
            if (wards && wards.length && wards.find(ward => ward.location.id === idWard)) {
                form.setFieldsValue({
                    id_ward: idWard
                });
            }
        }
    }

    render() {
        const { cities, countries, districts, errors, form, isLoadingLocation, t, wards } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        let errorLocationType;

        if (errors.id_location && errors.id_location.indexOf('Quốc gia') !== -1) {
            errorLocationType = 'country';
        } else if (errors.id_location && errors.id_location.indexOf('Tỉnh / Thành phố') !== -1) {
            errorLocationType = 'city';
        } else if (errors.id_location && errors.id_location.indexOf('Quận / Huyện') !== -1) {
            errorLocationType = 'district';
        }

        return (
            <>
                <Col className="info--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errorLocationType && errorLocationType === 'country' ? get(errors, 'id_location') : undefined}
                        label={t('package:label.country')}
                        labelCol={{span: 24}}
                        validateStatus={errorLocationType && errorLocationType === 'country' ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_country', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('package:label.country')
                                }),
                                required: true
                            }]
                        })(
                            <Select
                                className="a-select a-select--w-100 _country"
                                disabled={countries.length === 0 && isLoadingLocation}
                                loading={countries.length === 0 && isLoadingLocation}
                                optionFilterProp="children"
                                placeholder={t('package:placeholder.country')}
                                showSearch
                                onChange={this.onChangeCountry}
                            >
                                {countries.map((country) => (
                                    <Option
                                        className="_country__item"
                                        value={country.location.id}
                                        key={country.location.id}
                                    >
                                        {country.location.label}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col className="info--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errorLocationType && errorLocationType === 'city' ? get(errors, 'id_location') : undefined}
                        labelCol={{span: 24}}
                        label={t('location:label.city')}
                        validateStatus={errorLocationType && errorLocationType === 'city' ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_city', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('location:label.city')
                                }),
                                required: true
                            }]
                        })(
                            <Select
                                className="a-select a-select--w-100 _city"
                                disabled={!getFieldValue('id_country') || (cities.length === 0 && isLoadingLocation)}
                                loading={cities.length === 0 && isLoadingLocation}
                                optionFilterProp="children"
                                placeholder={t('location:placeholder.city')}
                                showSearch
                                onChange={this.onChangeCity}
                            >
                                {cities.map((city) => (
                                    <Option
                                        className="_city__item"
                                        key={city.location.id}
                                        value={city.location.id}
                                    >
                                        {city.location.label}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col className="info--group" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        help={errorLocationType && errorLocationType === 'district' ? get(errors, 'id_location') : undefined}
                        label={t('location:label.district')}
                        labelCol={{span: 24}}
                        validateStatus={errorLocationType && errorLocationType === 'district' ? 'error' : undefined}
                    >
                        {getFieldDecorator('id_district', {
                            rules: [{
                                message: t('validation:required', {
                                    attribute: t('location:label.district')
                                }),
                                required: true
                            }]
                        })(
                            <Select
                                className="a-select a-select--w-100 _district"
                                disabled={!getFieldValue('id_country') || !getFieldValue('id_city') || (districts.length === 0 && isLoadingLocation)}
                                loading={districts.length === 0 && isLoadingLocation}
                                optionFilterProp="children"
                                placeholder={t('location:placeholder.district')}
                                showSearch
                                onChange={this.onChangeDistrict}
                            >
                                {districts.map(district => (
                                    <Option
                                        className="_district__item"
                                        key={district.location.id}
                                        value={district.location.id}
                                    >
                                        {district.location.label}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col className="info--group clear-both--lg" lg={{span: 6}}>
                    <Form.Item
                        className="a-form__item"
                        label={t('location:label.ward')}
                        labelCol={{span: 24}}
                    >
                        {getFieldDecorator('id_ward')(
                            <Select
                                className="a-select a-select--w-100 _ward"
                                disabled={!getFieldValue('id_country') || !getFieldValue('id_city') || !getFieldValue('id_district') || (wards.length === 0 && isLoadingLocation)}
                                loading={wards.length === 0 && isLoadingLocation}
                                optionFilterProp="children"
                                placeholder={t('location:placeholder.ward')}
                                showSearch={true}
                                onChange={this.onChangeWard}
                            >
                                {wards.map(ward => (
                                    <Option
                                        className="_district__item"
                                        key={ward.location.id}
                                        value={ward.location.id}
                                    >
                                        {ward.location.label}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </>
        );
    }
}

LocationShippingPartnerForm.defaultProps = {
    warehouseData: {},
    isLoadingProperty: false,
    isLoadingLocation: false,
    countries: [],
    cities: [],
    districts: [],
    errors: {}
};

LocationShippingPartnerForm.propTypes = {
    warehouseData: PropTypes.object,
    isLoadingLocation: PropTypes.bool,
    countries: PropTypes.array,
    cities: PropTypes.array,
    districts: PropTypes.array,
    setCurrentCountry: PropTypes.func,
    setCurrentCity: PropTypes.func,
    onSubmit: PropTypes.func,
    resetError: PropTypes.func,
    errors: PropTypes.object,
};

export default withTranslation()(LocationShippingPartnerForm);
