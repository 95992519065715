import InputFocusHandler from './inputFocus/InputFocusHandler';
import InputFocusDetector from './inputFocus/InputFocusDetector';
import InputFocusParser from './inputFocus/InputFocusParser';

let inputFocusParser = new InputFocusParser();
let inputFocusDetector = new InputFocusDetector(inputFocusParser);
let inputFocusHandler = new InputFocusHandler(inputFocusDetector, inputFocusParser);

export {
    inputFocusParser,
    inputFocusDetector,
    inputFocusHandler,
};