import React, { forwardRef, useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { useDeepCompareEffect } from './../../../../hooks';

const { Option } = Select;

const ComplaintSellerReasonsSelect = forwardRef(({ className, complaintSellerReasons, dropdownClassName, t, value, ...other }, ref) => {
    const [formattedValue, setFormattedValue] = useState();

    useDeepCompareEffect(() => {
        let formattedValue = value && Number(value);
        const index = complaintSellerReasons.findIndex(complantSellerReason => complantSellerReason.id === formattedValue);
    
        if (index === -1) {
            formattedValue = undefined;
        }

        setFormattedValue(formattedValue);
    }, [complaintSellerReasons, value]);

    return (
        <Select
            {...other}
            className={clsx(className, '_complaint-seller-reasons-select')}
            dropdownClassName={clsx(dropdownClassName, '_dropdown-complaint-seller-reasons')}
            ref={ref}
            value={formattedValue}
        >
            {complaintSellerReasons.map(complaintSellerReason => (
                <Option
                    className="_complaint-seller-reasons-item"
                    key={complaintSellerReason.id}
                    value={complaintSellerReason.id}
                >
                    {t(`package:complaint_seller_reasons.${complaintSellerReason.code}`)}
                </Option>
            ))}
        </Select>
    );
});

ComplaintSellerReasonsSelect.propTypes = {
    complaintSellerReasons: PropTypes.array
};

ComplaintSellerReasonsSelect.defaultProps = {
    complaintSellerReasons: []
};

export default withTranslation(undefined, {
    withRef: true
})(ComplaintSellerReasonsSelect);
