import React from 'react';
import clsx from 'clsx';

const TabPane = ({ className, children }) => (
    <div className={clsx('ant-tabs-tabpane', className)}>
        {children}
    </div>
);

export default TabPane;
