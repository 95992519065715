import lodash from 'lodash';
import {createSelector} from "reselect";

export const getState = (state, param = null, defaultValue = null) =>
    lodash.get(state, 'user' + (param ? '.' + param : ''), defaultValue);

/**
 * Lấy danh sách user là shipper
 */
export const getShipper = createSelector(
    state => getState(state, "users", []),
    users => users.filter(user => {
        return user.is_shipper;
    })
);