import { Form, Tag } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import useFilter from '../../../hooks/useFilter'
import { t } from '../../../system/i18n'
import InputNumber from '../ScanSplitPackage/InputNumber'

const AdjustSuggest = ({ adjustNum, getDetachSuggest, history,location, countPackaged }) => {
    const { filters, addFilter } = useFilter(history,location, {})

    const [value, setValue] = useState()

    const count = useRef(0)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        setValue(urlParams.get('number') || '')
    }, [])

    useEffect(() => {
        count.current = adjustNum
    }, [adjustNum])

    const onPressEnter = e => {
        const value = get(e, 'target.value')
        getDetachSuggest(false, true, { number: value }, false)
        addFilter({ ...filters, number: value })
    }

    return (
        <div className="">
            <div className="gflex galign-center gjustify-end">
                <Tag color="gray">
                    {t('package:message.split_package_count', {
                        count: countPackaged,
                    })}
                </Tag>
                {/* <Button
                    className="mr-2"
                    onClick={onChangeIncrement}
                    loading={loadingDetachAuto}
                    disabled={loadingDetachAuto}
                    type="primary">
                    {t('package:btn.increase_size_packing')}
                </Button> */}
                {/* <Button onClick={onChangeDecrement}>-</Button> */}
            </div>
            <div className="mt-3">
                <span>{t('package:label.max_product_package')}: </span>
                <InputNumber
                    onPressEnter={onPressEnter}
                    value={value}
                    className="w-130"
                    onChange={value => setValue(value)}
                    min={1}
                />
            </div>
        </div>
    )
}

export default Form.create()(AdjustSuggest)
