import {connect} from 'react-redux';
import ScanBarcodeForm from './components/';
import {scanBarcode, scanReceived, scanReceivedBag} from './actions';
import authService from './../../Auth/authService';
import * as selectorWarehouse from './../../Warehouse/selectors';
import lodash from "lodash";
import * as selectors from '../../Package/UpdatePackageParams/selectors';
import { getSetting } from '../../Setting/selectors';

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isLoading: state.scanBarcodeForm.isLoading,
        user_id: authService.user('id'),
        warehouses: selectorWarehouse.getState(state, 'suggest') || [],
        setting: lodash.get(state, "setting.setting", []),
        agencySetting: lodash.get(state, "setting.agencySetting", []),
        dimensionSetting: selectors.getDimensionSetting(getSetting(state)),
        barcodeScanData: lodash.get(state, 'scanBarcodeForm.barcodeScanData', {}),
        barcodeScanDataPackage: lodash.get(state, 'scanBarcodeForm.barcodeScanDataPackage', {})
    }
};

const mapDispatchToProps = {
    scanBarcode,
    scanReceived,
    scanReceivedBag
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanBarcodeForm);
