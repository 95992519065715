import {all, fork} from 'redux-saga/effects';
import createBagScanWatcher from './CreateBag/sagaWatcher';
import listBagWatcher from './ListBag/sagaWatcher';
import bagPacking from './BagPacking/sagaWatcher';
import bagPackingAction from './BagPackingAction/sagaWatcher';
import bagPackingLastmileAction from './BagPackingLastmile/sagaWatcher';
import deletePackageWatcher from './DeletePackage/sagaWatcher';
import updateWeightRealBag from './UpdateWeightRealBag/sagaWatcher';
import completePackingBag from './CompletePackingBag/sagaWatcher';
import bagDetailWatcher from './BagDetail/sagaWatcher';
import listSameDayBagsWatcher from './ListSameDayBags/sagaWatcher';
import updateBagParamWatcher from './UpdateBagParam/sagaWatcher';

export default function*() {
    yield all([
        fork(createBagScanWatcher),
        fork(listBagWatcher),
        fork(bagPacking),
        fork(bagPackingAction),
        fork(bagPackingLastmileAction),
        fork(deletePackageWatcher),
        fork(updateWeightRealBag),
        fork(completePackingBag),
        fork(bagDetailWatcher),
        fork(listSameDayBagsWatcher),
        fork(updateBagParamWatcher),
    ]);
}
