import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Print from './components';

class PrintContainer extends Component {
    componentDidMount() {
        const { onDidMount } = this.props;

        onDidMount();
    }

    render() {
        const { dataPrint } = this.props;
        return (
            <Print
                {...this.props}
                deliveryNoteTransportWarehouse={dataPrint.deliveryNoteTransportWarehouse}
            />
        );
    }
}

PrintContainer.defaultProps = {
    onDidMount: () => {}
};

PrintContainer.propTypes = {
    onDidMount: PropTypes.func
};

export default withTranslation()(PrintContainer);
