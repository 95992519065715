/* eslint-disable */

import React from 'react';
import FormSearch from "./FormSearch";
import ListDeliveryNotePartner from "./ListDeliveryNote";
import {Layout} from "antd";
const {Content} = Layout;

class DeliveryNotePartner extends React.PureComponent{
    render() {
        return(
            <Content className="a-content a-content--page-list">
                <FormSearch/>
                <ListDeliveryNotePartner/>
            </Content>
        )
    }
}
export default DeliveryNotePartner;