import { Checkbox, Form, Input, Modal, notification, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { t } from '../../../system/i18n'
import { router } from '../../../system/routing'
import pageService from '../../Common/Page/pageService'

import { setMenuActive } from '../../Common/actions'
import apiService from '../apiService'
import { get, trim } from 'lodash'
import scanAutoService from './ScanService'
import moment from 'moment'
import InputNumber from './InputNumber'

const mapStateToProps = state => {
    return {}
}

const { Option } = Select

const ScanSplitPackage = ({ setMenuActive }) => {
    const [loading, setLoading] = useState(false)
    const [typeStamp, setTypeStamp] = useState('print_official_stamp')
    const [autoPrint, setAutoPrint] = useState(true)
    const [option, setOption] = useState({ group_by_product: true })
    const [count, setCount] = useState("")

    const onScan = async ({ target: { value } }) => {
        const newValue = trim(value)
        if (!newValue) return

        setLoading(true)
        try {
            const res = await apiService.getDetachSuggest(newValue)
            const isChecked = get(res, 'data.package.is_checked')

            notification.success({
                message: t(`bag:lastmile.message.packing_scan_success`),
            })

            const number = count ? { number : count} : {} 

            const result = '?' + new URLSearchParams({ typeStamp, ...option, ...number}).toString()
            
            if (!isChecked) {
                Modal.confirm({
                    title: t('package:label.package_not_checked_continue_detach'),
                    content: '',
                    onOk() {
                        if (autoPrint) {
                            scanAutoService.addItemsToData({
                                packageCode: newValue,
                                event: [
                                    {
                                        name: 'scan',
                                        timestamp: moment().unix(),
                                        time_date: moment().format('L h:mm:ss.SSS'),
                                    },
                                ],
                            })

                            router.redirect(`/packages/auto-split-package/${newValue}${result}`)
                        } else {
                            router.redirect(`/packages/split-package/${newValue}${result}`)
                        }
                    },
                    okText: t('btn.ok'),
                    cancelText: t('btn.cancel'),
                })
            } else {
                if (autoPrint) {
                    scanAutoService.addItemsToData({
                        packageCode: newValue,
                        event: [
                            {
                                name: 'scan',
                                timestamp: moment().unix(),
                                time_date: moment().format('L h:mm:ss.SSS'),
                            },
                        ],
                    })

                    router.redirect(`/packages/auto-split-package/${newValue}${result}`)
                } else {
                    router.redirect(`/packages/split-package/${newValue}${result}`)
                }
            }
        } catch (error) {
            const code = get(error, 'response.data.code')
            const data = get(error, 'response.data.data')

            if (code === 'INPUT_INVALID') {
                Object.entries(data).forEach(([key, value]) => {
                    const message = get(value, "message", Object.keys(value)[0])
                    const packages = get(value, "packages", [])
                    notification.error({
                        message: t(`package:create_shipment_order.errors.${key}.${message}`, {packages: packages.join(", "), count: packages.length}),
                    })
                })
            } else {
                notification.error({
                    message: t(`message.server_error`),
                })
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        pageService.setTitle(t('package:detail.split_package'))
        setMenuActive('packages/scan-split-package')
    }, [])

    const handleChangeOptionStamp = value => {
        setTypeStamp(value)
    }

    const handleChangeOption = (name, value) => {
        setOption(prev => ({ ...prev, [name]: value }))
    }

    return (
        <div className="a-content__body bg-white a-content__body--page-list is-pagination-fixed">
            <div className="a-content__top__title mb-6">
                <h3 className="a-text--uppercase">{t('bag:label.scan_package')}</h3>
            </div>

            <Form>
                <Form.Item
                    label={t('package:label.option')}
                    className="mb-2">
                    <div>
                        {/* <div>
                            <Switch
                                size="small"
                                checked={get(option, 'without_origin')}
                                onChange={checked => handleChangeOption('without_origin', checked)}
                            />
                            <span className="ml-2">{t('package:label.without_origin')}</span>
                        </div> */}
                        <div>
                            <Switch
                                size="small"
                                checked={get(option, 'group_by_product')}
                                onChange={checked => handleChangeOption('group_by_product', checked)}
                            />
                            <span className="ml-2">{t('package:label.group_by_product')}</span>
                        </div>
                    </div>
                </Form.Item>
                <>
                    <div>
                        <Switch
                            size="small"
                            checked={autoPrint}
                            onChange={checked => setAutoPrint(checked)}
                        />
                        <span className="ant-typography ml-1">{t('package:label.auto_print_stamp')} </span>
                        {/* <Checkbox
                                placeholder={t('scan_code')}
                                checked={autoPrint}
                                className="ml-2 my-3"
                                onChange={e => setAutoPrint(e.target.checked)}
                            /> */}
                    </div>
                    {autoPrint ? (
                        <Form.Item
                            label={t('package:placeholder.select_type_stamp')}
                            className="mb-2">
                            <Select
                                value={typeStamp}
                                style={{ width: 180 }}
                                onChange={handleChangeOptionStamp}>
                                <Option value="print_official_stamp">{t('package:btn.print_official_stamp')}</Option>
                                <Option value="print_stamp">{t('package:label.print_stamp')}</Option>
                                <Option value="print_batch_stamp">{t('package:label.print_batch_stamp')}</Option>
                            </Select>
                        </Form.Item>
                    ) : null}
                </>
                <Form.Item label={t('package:label.max_product_package')} className='mb-0'>
                    <InputNumber
                        value={count}
                        className="w-130"
                        onChange={value => setCount(value)}
                        min={1}
                    />
                </Form.Item>

                <Form.Item label={t('scan_code')}>
                    <Input
                        autoFocus
                        className="a-input w-200"
                        placeholder={t('scan_code')}
                        onPressEnter={onScan}
                        loading={loading}
                        disabled={loading}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}

export default connect(mapStateToProps, { setMenuActive })(ScanSplitPackage)
