import React from 'react';
import { notification } from 'antd';
import { get } from 'lodash';

import apiService from './../../../../../StorageFees/apiService';
import { PACKAGE_TYPE } from './../../../../../StorageFees/constants';

import EditModal from './EditModal';
import StorageFeesTable from './StorageFeesTable';
import SearchForm from './SearchForm';
import LogsModal from './../../LogsModal';
import SearchableComponent from './../../../../../Common/components/SearchableComponent';

class PackageStorageFees extends SearchableComponent {
    state = {
        adding: false,
        editModalVisible: false,
        filter: {},
        logsModalVisible: false,
        selectedStorageFee: {},
        updating: false
    };

    handleChangePage = pagination => {
        const { filter } = this.state;

        this.pushFilter({
            ...filter,
            page: pagination.current,
            per_page: pagination.pageSize
        });
    };

    onChangeFilter = filter => {
        const { location, getStorageFees } = this.props;

        getStorageFees(filter);

        this.setState({
            filter: this.getFilterFromLocation(location)
        });
    };

    handleSearch = filter => {
        const { pagination } = this.props;

        this.pushFilter({
            ...filter,
            page: pagination.page ? 1 : undefined,
            per_page: pagination.per_page
        });

        this.setState({
            filter
        });
    };

    handleRefreshFilter = () => {
        this.setState({
            filter: {}
        });

        this.pushFilter();
    };

    handleCreateButtonClick = () => {
        this.setState({
            editModalVisible: true
        });
    };

    handleCancelEditStorageFee = () => {
        const { form } = this.props;

        this.setState({
            editModalVisible: false,
            selectedStorageFee: {}
        });

        form.resetFields();
    };

    handleSubmit = async data => {
        const { adding, filter, updating } = this.state;
        const { t, getStorageFees } = this.props;

        if (adding || updating) {
            return;
        }

        let action = 'add';

        if (data.id) {
            action = 'update';
        }

        try {
            await this[`${action}StorageFee`]({
                ...data,
                object: PACKAGE_TYPE
            });

            this.handleCancelEditStorageFee();
            getStorageFees(filter);

            notification.success({
                message: t(`storage_fee:${action}.success`)
            });
        } catch (error) {
            notification.error({
                message: t(`storage_fee:${action}.failed`)
            });
        }
    };

    addStorageFee = async data => {
        this.setState({
            adding: true
        });

        try {
            await apiService.add(data);

            this.setState({
                adding: false
            });
        } catch (error) {
            this.setState({
                adding: false
            });

            throw error;
        }
    };

    updateStorageFee = async data => {
        this.setState({
            updating: true
        });

        try {
            await apiService.update(data);

            this.setState({
                updating: false
            });
        } catch (error) {
            this.setState({
                updating: false
            });

            throw error;
        }
    };

    handleEditButtonClick = index => {
        const { storageFees } = this.props;

        this.setState({
            editModalVisible: true,
            selectedStorageFee: storageFees[index]
        });
    };

    handleViewLogsButtonClick = index => {
        const { storageFees } = this.props;

        this.setState({
            logsModalVisible: true,
            selectedStorageFee: storageFees[index]
        });
    };

    handleCloseLogsButtonClick = () => {
        this.setState({
            logsModalVisible: false,
            selectedStorageFee: {}
        });
    };

    componentDidUpdate(prevProps) {
        const { editModalVisible } = this.props;

        super.componentDidUpdate(prevProps);

        if (editModalVisible !== prevProps.editModalVisible) {
            this.handleCreateButtonClick();
        }
    }

    componentDidMount() {
        const { location } = this.props;
        const filter = this.getFilterFromLocation(location);

        super.componentDidMount();

        this.setState({
            filter
        });
    }

    render() {
        const { adding, editModalVisible, filter, logsModalVisible, selectedStorageFee, updating } = this.state;
        const { form, loading, pagination, tableDisplayStorageFees } = this.props;

        return (
            <div className="a-content__body bg-white a-content__body--page-list mt-0 height-100">
                <SearchForm
                    filter={filter}
                    onRefresh={this.handleRefreshFilter}
                    onSearch={this.handleSearch}
                />

                <StorageFeesTable
                    loading={loading}
                    pagination={pagination}
                    storageFees={tableDisplayStorageFees}
                    onChangePage={this.handleChangePage}
                    onEditButtonClick={this.handleEditButtonClick}
                    onViewLogButtonClick={this.handleViewLogsButtonClick}
                />

                <EditModal
                    form={form}
                    loading={adding || updating}
                    storageFee={selectedStorageFee}
                    visible={editModalVisible}
                    onCancel={this.handleCancelEditStorageFee}
                    onOk={this.handleSubmit}
                />

                <LogsModal
                    storageFeeId={get(selectedStorageFee, 'storage_fee.id')}
                    visible={logsModalVisible}
                    onCancel={this.handleCloseLogsButtonClick}
                />
            </div>
        );
    }
}

export default PackageStorageFees;
