import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as NOTE_BARCODE from '../../DeliveryNote/DeliveryNoteBarcode/constants';
import lodash from "lodash";
import * as CANCEL_ACTION from "../CancelDeliveryRequest/constants";
import {currentFormatter} from '../../Common/services/format';

let deliveryCustomerRequests = (state = {list: []}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.SUCCESS:
            return {
                ...state,
                list: lodash.map(action.payload.customers, customer => ({
                    ...customer,
                    can_handle: lodash.get(action, 'payload.permissions.can_handle', 0)
                }))
            };
        case ACTION.RESET_LIST_DELIVERY_CUSTOMER_REQUEST:
            return [];
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS:
            return {
                list: lodash.compact(lodash.map(state.list, value => {
                    let customer = lodash.get(value, 'customer', {});
                    let customerId = lodash.get(customer, 'id', 0);
                    let deliveryRequests = lodash.get(value, 'delivery_requests', []);

                    if (customerId === lodash.get(action, "payload.delivery_request.id_customer", '')) {
                        let removeDeliveryRequest = deliveryRequests.filter(data => {
                            let deliveryRequest = lodash.get(data, 'delivery_request');
                            return lodash.get(deliveryRequest, 'code') === lodash.get(action, 'request.data.code');
                        });
                        if (Array.isArray(removeDeliveryRequest) && removeDeliveryRequest.length > 0) {
                            value.total_package = value.total_package - removeDeliveryRequest[0].total_package;
                            value.total_weight = value.total_weight - removeDeliveryRequest[0].total_weight;
                            value.total_weight = currentFormatter.toLocaleStringCurrency(value.total_weight, 2);
                            value.total_request = value.total_request - 1;
                        }
                        lodash.remove(deliveryRequests, data => {
                            let deliveryRequest = lodash.get(data, 'delivery_request');
                            return lodash.get(deliveryRequest, 'code') === lodash.get(action, 'request.data.code');
                        });
                        return deliveryRequests.length > 0 && ({
                            ...value,
                            deliveryRequests
                        });
                    }

                    return {...value};
                }))
            };
        case ACTION.UPDATE.SUCCESS: {
            const { payload } = action;
            let deliveryRequestIndex;

            const customerRequestIndex = state.list.findIndex(item => item.delivery_requests.find((deliveryRequest, index) => {
                if (deliveryRequest.delivery_request.id === payload.id) {
                    deliveryRequestIndex = index;

                    return true;
                }

                return false;
            }));

            if (customerRequestIndex === -1) {
                return state;
            }

            const newState = {
                ...state,
                list: [
                    ...state.list.slice(0, customerRequestIndex),
                    {
                        ...state.list[customerRequestIndex],
                        delivery_requests: [
                            ...state.list[customerRequestIndex].delivery_requests.slice(0, deliveryRequestIndex),
                            {
                                ...state.list[customerRequestIndex].delivery_requests[deliveryRequestIndex],
                                delivery_request: payload
                            },
                            ...state.list[customerRequestIndex].delivery_requests.slice(deliveryRequestIndex + 1)
                        ]
                    },
                    ...state.list.slice(customerRequestIndex + 1)
                ]
            };

            return newState;
        }
        default:
            return state;
    }
};
let deliveryRequests = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_REQUESTS.SUCCESS:
            return action.payload.delivery_requests;
        case ACTION.RESET_LIST_DELIVERY_REQUEST:
            return [];
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS:
            lodash.remove(state, deliveryRequest =>
                lodash.get(deliveryRequest, 'delivery_request.code', '') === action.request.data.code);
            return state;
        default:
            return state;
    }
};

let pagination = (state = {deliveryRequests: {}, deliveryCustomerRequests: {}}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_REQUESTS.SUCCESS:
            return {...state, deliveryRequests: action.payload.pagination};
        case ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.SUCCESS:
            return {...state, deliveryCustomerRequests: action.payload.pagination};
        case ACTION.RESET_LIST_DELIVERY_REQUEST:
            return {};
        case ACTION.RESET_LIST_DELIVERY_CUSTOMER_REQUEST:
            return {};
        default:
            return state;
    }
};

let loading = (state = {loadingDeliveryCustomerRequest: false, loadingDeliveryRequest: false}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DELIVERY_REQUESTS.REQUEST:
            return {...state, loadingDeliveryRequest: true};
        case ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.REQUEST:
            return {...state, loadingDeliveryRequest: true};
        case ACTION.FETCH_DELIVERY_REQUESTS.SUCCESS:
            return {
                ...state,
                loadingDeliveryRequest: action.request ? action.request.from !== 'delivery_request' : false
            };
        case ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.SUCCESS:
            return {
                ...state,
                loadingDeliveryRequest: action.request ? action.request.from !== 'delivery_request' : false
            };
        case ACTION.FETCH_DELIVERY_REQUESTS.FAILED:
        case ACTION.FETCH_DELIVERY_REQUESTS_BY_CUSTOMER.FAILED:
        case NOTE_BARCODE.SET_BARCODES:
            return {...state, loadingDeliveryRequest: false};
        case ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.REQUEST:
            return {...state, loadingDeliveryCustomerRequest: true};
        case ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.SUCCESS:
        case ACTION.FETCH_DELIVERY_CUSTOMER_REQUESTS.FAILED:
            return {...state, loadingDeliveryCustomerRequest: false};
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.REQUEST:
            return {...state, loadingDeliveryRequest: true};
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.SUCCESS:
        case CANCEL_ACTION.ON_CANCEL_DELIVERY_REQUEST.FAILED:
            return {...state, loadingDeliveryRequest: false};


        default:
            return state;
    }
};

export default combineReducers({
    deliveryCustomerRequests,
    deliveryRequests,
    pagination,
    loading,
});
