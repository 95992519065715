import { api } from '../../system/api'

export default {
    suggest: params => api.get('orders/suggest', { params, singleRequest: true }),
    getOrderPackages: orderId => api.get(`orders/${orderId}/packages`, { singleRequest: true }),
    getCommentsById: (id, params) => api.get(`orders/${id}/comments`, { params, singleRequest: true }),
    addCommentById: (id, data) => api.post(`orders/${id}/comments`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),

    getInternalCommentsById: (id, params) => api.get(`orders/${id}/internal-comments`, { params, singleRequest: true }),
    addInternalCommentById: (id, data) => api.post(`orders/${id}/internal-comments`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    autoGeneratorOrder: data => api.post(`orders/from-customer-code`, data),
    createOrderFromCustomerCode: (packageId, data) => api.post(`packages/${packageId}/create-order-from-customer-code`, data),
}
