import { Select } from 'antd'
import { filter, get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { t } from '../../../../system/i18n'
import apiService from '../../../Agencies/apiService'
import permissions from '../../../Auth/permissions'

const { Option } = Select

const mapStateToProps = state => {
    return {
        agencySetting: get(state, 'setting.agencySetting', []),
    }
}

const SelectAgency = props => {
    const { agencySetting } = props

    const [loading, setLoading] = useState(false)
    const [agencies, setAgencies] = useState([])

    useEffect(() => {
        fetchAgencies()
    }, [agencySetting])

    const fetchAgencies = () => {
        setLoading(true)
        apiService
            .getAgencySuggest()
            .then(res => {
                setAgencies(processData(res.data))
            })
            .catch(error => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const processData = data => {
        const newData = map(data.agencies, item => item.agency)
        const result = newData.filter(item => {
            const filterSettings = filter(agencySetting, { id_agency: item.id, code: permissions.CONFIG_EXPEDITED_OPERATION })
            if (!get(filterSettings, '0.value') && !isEmpty(filterSettings)) {
                return false
            } else {
                return true
            }
        })
        return result
    }

    return (
        <Select
            {...props}
            className={'a-select a-select--w-100 _select_agency'}
            placeholder={t('agency:placeholder.choose_agency')}
            dropdownClassName={'_dropdown-agency'}
            showSearch={false}
            loading={loading}>

            {map(agencies, (agency, index) => (
                <Option
                    className="_agency-item"
                    key={index}
                    value={agency.id}>
                    {agency.name}
                </Option>
            ))}
        </Select>
    )
}
export default connect(mapStateToProps, undefined)(SelectAgency)
