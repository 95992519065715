/* eslint-disable */
import React, {Component} from 'react';
import { Row} from 'antd';
import BagInfo from './components/BagInfo';
import ListPackageInBag from './components/ListPackageInBag';
import BagBlockRight  from './components/BagBlockRight';
class DetailBagContainer extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Row type="flex">
                <div className="a-content--ischatbox a-package--detail pt-0">
                    <BagInfo/>
                    <ListPackageInBag/>
                </div>
                <BagBlockRight/>
            </Row>
        )
    }
}

export default DetailBagContainer;