/* eslint-disable */

import React from 'react';
import { Layout} from "antd";
import ListInventory from './components';
class ListInventoryContainer extends React.PureComponent{
    render() {
        return(
            <Layout>
                <ListInventory/>
            </Layout>
        )
    }
}
export default ListInventoryContainer;