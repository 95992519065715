import { BAG_TYPE, PACKAGE_TYPE } from './../../StorageFees/constants';

export const TABLE_COLUMNS = {
    [BAG_TYPE]: {
        AGENCY: 'agency',
        UNIT_PRICE: 'unit_price',
        APPLIED_AT: 'applied_at',
        DAY_FREE: 'day_free'
    },
    [PACKAGE_TYPE]: {
        AGENCY: 'agency',
        UNIT_PRICE: 'unit_price',
        WEIGHT_BY: 'weight_by',
        APPLIED_AT: 'applied_at',
        DAY_FREE: 'day_free'
    }
};
