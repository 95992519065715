export const FETCH_DETAIL_BAG_SIZE = {
    REQUEST: 'FETCH_DETAIL_BAG_SIZE.REQUEST',
    SUCCESS: 'FETCH_DETAIL_BAG_SIZE.SUCCESS',
    FAILED: 'FETCH_DETAIL_BAG_SIZE.FAILED'
};
export const FETCH_BAG_SIZE_SUGGEST = {
    REQUEST: 'FETCH_BAG_SIZE_SUGGEST.REQUEST',
    SUCCESS: 'FETCH_BAG_SIZE_SUGGEST.SUCCESS',
    FAILED: 'FETCH_BAG_SIZE_SUGGEST.FAILED'
};
export const FETCH_BAG_SIZE = {
    REQUEST: "FETCH_BAG_SIZE.REQUEST",
    SUCCESS: "FETCH_BAG_SIZE.SUCCESS",
    FAILED: "FETCH_BAG_SIZE.FAILED",
};

export const UPDATE_BAG_SIZE = {
    REQUEST: 'UPDATE_BAG_SIZE.REQUEST',
    SUCCESS: 'UPDATE_BAG_SIZE.SUCCESS',
    FAILED: 'UPDATE_BAG_SIZE.FAILED'
};

export const CLEAR_STATE_DETAIL_BAG_SIZE = "CLEAR_STATE_DETAIL_BAG_SIZE";
