import React, { PureComponent } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { get } from 'lodash';
import {withTranslation} from 'react-i18next';

import { INVENTORY_STATUS_DONE, INVENTORY_STATUS_PROCESSING } from './../../../constants';
import { dateFormatter } from './../../../../Common/services/format';

import UpdateStatusInventory from './../../../UpdateStatusInventory/UpdateStatusInventoryContainer';

import LoadingCheckingPackageImg from './../../../../../resources/images/LoadingCheckingPackage.png';

class FormSearch extends PureComponent {
    showModal = () => {
        const { inventory, updateInventoryStatus } = this.props;

        updateInventoryStatus(inventory.id, INVENTORY_STATUS_DONE);
    };

    closeModal = () => {
        this.setState({
            visible: false
        });
    };

    handleOk = () => {
        this.closeModal();
    };

    handleCancel = () => {
        this.closeModal();
    };

    render() {
        const { inventory, countInventoryPackages, creator, inventoryType, loading, loadingUpdatePackageStatus, loadingUpdateStatus, resolvingDisappearInventoryPackages, resolvingRemainderInventoryPackages, t, warehouse } = this.props;
        const countPackageUnresolved = get(countInventoryPackages, 'package_unresolved', 0) || 0;
        const loadingButton = loadingUpdateStatus || loadingUpdatePackageStatus || resolvingDisappearInventoryPackages || resolvingRemainderInventoryPackages;
        const disabled = countPackageUnresolved > 0 || loading || inventory.status !== INVENTORY_STATUS_PROCESSING || loadingUpdateStatus || loadingUpdatePackageStatus || resolvingDisappearInventoryPackages || resolvingRemainderInventoryPackages;

        return (
            <div className="a-content--search-header search-crosscheck search-default " id="list-search">
                <Row gutter={24}>
                    <div className="search-crosscheck--title">
                        <span className="title">{t('inventory:title.title_information_inventory')}</span>
                        <div className={"a-notification-dot blue pl-5"}>
                            {!loading &&
                            <span>{t(`inventory:inventory_status_${inventory.status}`)}</span>}
                        </div>
                    </div>
                    <Form className="a-form">
                        <Col lg={18} xl={21} className="search--left">
                            <Row gutter={{lg: 12, xl: 24}} className="a-block-info">
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t('inventory:code')}</label>
                                    <span className="info--value">{get(inventory, 'code')}</span>
                                </Col>
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t('creator')}</label>
                                    <p className="info--value">{get(creator, 'username', '--')}</p>
                                </Col>
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t('inventory:warehouse')}</label>
                                    <span className="info--value">{get(warehouse, 'code')}</span>
                                </Col>
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t('inventory:column.created_at')}</label>
                                    <span className="info--value">{dateFormatter.dateTime(inventory.created_at)}</span>
                                </Col>
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t('inventory:reconciliation_time')}</label>
                                    <span className="info--value">{get(inventory, 'inventoried_at') ? dateFormatter.dateTime(inventory.inventoried_at): '--'}</span>
                                </Col>
                                <Col lg={6} xl={2} className="info--group">
                                    <label className="info--label">{t(`inventory:disappear_count.${inventoryType}`)}</label>
                                    <span className="info--value">{get(countInventoryPackages, 'package_disappear', 0)}</span>
                                </Col>
                                <Col lg={6} xl={2} className="info--group">
                                    <label className="info--label">{t(`inventory:remainder_count.${inventoryType}`)}</label>
                                    <span className="info--value">{get(countInventoryPackages, 'package_remainder', 0)}</span>
                                </Col>
                                <Col lg={6} xl={2} className="info--group">
                                    <label className="info--label">{t(`inventory:normal_count.${inventoryType}`)}</label>
                                    <span className="info--value">{get(countInventoryPackages, 'package_normal', 0)}</span>
                                </Col>
                                <Col lg={6} xl={3} className="info--group">
                                    <label className="info--label">{t(`inventory:unresolved_count.${inventoryType}`)}</label>
                                    <span className="info--value">{countPackageUnresolved}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={3} className="a-text--center">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="a-btn a-btn--primary gw-100 a-text--upppercase primary-special"
                                onClick={this.showModal}
                                loading={loadingButton}
                                disabled={disabled}
                            >
                                {t('inventory:btn.done')}
                            </Button>
	                        <UpdateStatusInventory page={INVENTORY_STATUS_PROCESSING}/>
                        </Col>
                    </Form>
                </Row>

                <Modal
                    footer={false}
                    visible={loadingUpdateStatus}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    centered
                    closable={false}
                    bodyStyle={{height: '300px'}}
                >
                    <div className="a-text--center">
                        <img src={LoadingCheckingPackageImg} alt="Loading"/>
                        <p className="a-text--fz-24 a-text--uppercase a-text--black mt-5">
                            {t('inventory:reconciliation')}
                        </p>
                        <div className="mt-5">
                            <p className="mb-2 a-text--black">
                                {t('inventory:in_progress_of_reconciling')}<span className="loading-check-package" />
                            </p>
                            <p className="a-text--black">
                                {t('inventory:notice_in_progress_of_reconciling')}
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(FormSearch);
