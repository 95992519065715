import React, { useState } from 'react'
import { Alert, Button, Divider, Typography } from 'antd'
import clsx from 'clsx'
import { get } from 'lodash'
import { withTranslation } from 'react-i18next'

const { Paragraph } = Typography
const allowRow = 4
const BoxSizesSuggestByVolume = ({ boxSizes, className, hintParams, t }) => {
    console.log("boxSizes.lengt", boxSizes.length)
    const [expand, setExpand] = useState(false)
    return (
        <Alert
            className={clsx('a-alert--warning', className)}
            type="warning"
            message={
                <>
                    <Paragraph className="mb-0">{t('package:suggest_box_sizes.hint', hintParams)}</Paragraph>
                    {boxSizes && boxSizes.length
                        ? boxSizes.map((boxSize, index) => {
                              const rows = expand ? boxSizes.length  : allowRow
                              if (index + 2 > rows) return null
                              return (
                                  <>
                                      <Paragraph
                                          className={clsx('mb-0', {
                                              'a-text--green': get(boxSize, 'box_size.service.type') === 'consign',
                                          })}
                                          key={get(boxSize, 'box_size.id')}>
                                          {get(boxSize, 'box_size.service.name')}: {get(boxSize, 'box_size.code')}
                                      </Paragraph>
                                  </>
                              )
                          })
                        : null}
                        {
                            boxSizes.length > allowRow && 
                            <Divider className="mb-0 mt-0">
                                <Button
                                    type="link"
                                    onClick={() => setExpand(!expand)}
                                    icon={expand ? "shrink" : "arrows-alt"}>
                                    {expand ? t('collapse_info') : t('view_full_info')}
                                </Button>
                            </Divider>
                        }
                    
                </>
            }
        />
    )
}

BoxSizesSuggestByVolume.defaultProps = {
    hint: 1,
}

export default withTranslation()(BoxSizesSuggestByVolume)
