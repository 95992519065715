import * as ACTION from "./constants";
import {combineReducers} from 'redux';
import lodash from "lodash";

let list = (state = [], action) => {
    switch (action.type) {
        case ACTION.FETCH_DETAIL_DELIVERY_REQUEST.SUCCESS:
            let orderCodes = [];
            let data = lodash.get(action, 'payload.delivery_request', {});

            lodash.each(data.packages, packageData => {
                let obj =  {};
                let packages = data.packages.filter(pack => pack.order.code === packageData.order.code);
                let total_package = packages.length;
                let total_weight = 0;
                lodash.each(packages, pack => {
                    let weight = pack.weight_net > pack.weight_converted ? pack.weight_net : pack.weight_converted;
                    total_weight = total_weight + weight + pack.weight_box;
                });
                obj.orderCode = packageData.order.code;
                obj.code = data.code;
                obj.total_weight = total_weight;
                obj.total_package = total_package;
                obj.packages = packages;
                obj.agency = data.agency
                if (lodash.filter(orderCodes, code => code.orderCode === packageData.order.code).length === 0) {
                    orderCodes.push(obj)
                }
            });
            return orderCodes;
        case ACTION.RESET_DETAIL_DELIVERY_REQUEST:
            return [];
        default:
            return state;
    }
};
let detail = (state = {}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DETAIL_DELIVERY_REQUEST.SUCCESS:
            return {...action.payload.delivery_request, agency: action.request.agency};
        case ACTION.RESET_DETAIL_DELIVERY_REQUEST:
            return {};
        default:
            return state;
    }
};
let showDetailDeliveryRequest = (state = false, action) => {
    switch (action.type) {
        case ACTION.SHOW_DETAIL_DELIVERY_REQUEST:
            return true;
        case ACTION.HIDE_DETAIL_DELIVERY_REQUEST:
            return false;
        default:
            return state;
    }
};

let loading = (state = {detail: false}, action) => {
    switch (action.type) {
        case ACTION.FETCH_DETAIL_DELIVERY_REQUEST.REQUEST:
            return {...state, detail: true};
        case ACTION.FETCH_DETAIL_DELIVERY_REQUEST.SUCCESS:
        case ACTION.FETCH_DETAIL_DELIVERY_REQUEST.FAILED:
            return {...state, detail: false};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    list,
    showDetailDeliveryRequest,
    detail
});

