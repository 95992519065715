/* eslint-disable */
import React, {Component} from 'react';
import { Icon, Input} from "antd";
import {Form as AntForm} from 'antd';

const {TextArea} = Input;

class QuickEditUpdateDelivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowInputEdit: false
        }
    }

    onClickEditInline = (status) => {
        this.setState({
            isShowInputEdit: status
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateDelivery(this.props.id,values);
                this.onClickEditInline(false)
            }
        });
    };

    render() {
        const {value,permissions,t,loadingQuickUpdate} = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <AntForm>
                <div className="a-inline-edit">
                    <label className="a-inline-edit--label">{t("delivery_note:label.note")}
                        {!this.state.isShowInputEdit && (
                            <>
                                {!!permissions && (
                                    <>
                                        {loadingQuickUpdate && (
                                            <Icon type="loading-3-quarters" className="icon-loading icon-inline"/>
                                        )}

                                        {!loadingQuickUpdate && (
                                            <Icon type="edit" onClick={() => {this.onClickEditInline(true)}} className={`icon-inline`}/>
                                        )}
                                    </>
                                )}
                                <div className={`a-inline-edit--value`}>{value?value:'--'}</div>
                            </>
                        )}

                        {this.state.isShowInputEdit && (
                            <>
                                <Icon type="close" onClick={() => {this.onClickEditInline(false)}} className="icon-inline"/>
                                <Icon type="check" onClick={this.handleSubmit} className="icon-inline ml-0"/>
                                <div className={`a-inline-edit--input`}>
                                    <AntForm.Item
                                        className="note _note"
                                    >
                                        {getFieldDecorator('note', {
                                            rules: [{ max: 255, message: t("delivery_note:delivery.length_required_maximum_255") }],
                                            initialValue: value
                                        })(
                                            <TextArea
                                                onKeyDown={(e) => { if ( e.keyCode === 13) { this.handleSubmit(e) }}}
                                                autoFocus
                                            />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </>
                        )}
                    </label>
                </div>
            </AntForm>

        )
    }
}

QuickEditUpdateDelivery = AntForm.create()(QuickEditUpdateDelivery);
export default QuickEditUpdateDelivery;