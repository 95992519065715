export const CHECKING_INVENTORY = {
	REQUEST: 'CHECKING_INVENTORY.REQUEST',
	SUCCESS: 'CHECKING_INVENTORY.SUCCESS',
	FAILED: 'CHECKING_INVENTORY.FAILED',
};

export const COMPARING_TRACKING_BILL = {
	REQUEST: 'COMPARING_TRACKING_BILL.REQUEST',
	SUCCESS: 'COMPARING_TRACKING_BILL.SUCCESS',
	FAILED: 'COMPARING_TRACKING_BILL.FAILED',
};
