import {processApiRequest} from '../../Common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {notification} from 'antd';
import {router} from '../../../system/routing';
import {t} from './../../../system/i18n';
import React from "react";
import lodash from "lodash";

export function* createBag(action) {
    yield processApiRequest(
        ACTION.CREATE_BAG,
        () => apiService.createBag(action.payload),
        action.payload
    );
}

export function* createBagSuccess(action) {
    yield notification['success']({
        message: t('common:message.create_bag_success'),
        className:'_create_bag_success'
    });
    if(action.request.redirect){
        yield router.redirect('/bags/packing/' + action.payload.bag.id);
    }
}

export function* createBagFailed(action) {
    let error = action.payload.data;
    if (lodash.get(error, 'warehouse')) {
        return yield notification['error']({
            message: <span className={"_scan_package_failed"}>{t('message.warehouse_not_is_staff')}</span>,
        });
    }
    if (lodash.has(error, 'id_shipping_partner.exists_active')) {
        return yield notification['error']({
            message: <span className={"_create_bag_failed"}>{t('bag:message.shipping_partner_exists_active')}</span>,
        });
    }
    if (lodash.has(error, 'id_shipping_user.exists_active')) {
        return yield notification['error']({
            message: <span className={"_create_bag_failed"}>{t('bag:message.shipping_user_exists_active')}</span>,
        });
    }
    return yield notification['error']({
        message: <span className={"_create_bag_failed"}>{t('bag:message.create_bag_failed')}</span>,
    });
}


export function* getTemplateBags(action) {
    yield processApiRequest(
        ACTION.GET_TEMPLATE_BAGS,
        () => apiService.getTemplateBags(action.payload),
        action.payload
    );
}
