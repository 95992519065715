import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../DetailDeliveryNote/actions';
import {fetchDeliveryNoteDetail} from '../DetailDeliveryNote/actions';
import * as selectors from '../DetailDeliveryNote/selectors';
import {setMenuActive} from './../../Common/actions';
import authService from "../../Auth/authService";
import {withTranslation} from "react-i18next";
import * as deliveryNotePrintSelector from './selectors';

import DeliveryNotePrintForCustomer from './components/DeliveryNotePrintForCustomer';

const mapStateToProps = (state) => {
    return {
        loggedUser: authService.user(),
        partner: authService.partner(),
        deliveryNote: selectors.getDeliveryNoteDetail(state),
        loading: selectors.getState(state, 'loading'),
        agencySetting: deliveryNotePrintSelector.getAgencySetting(state),
        setting:deliveryNotePrintSelector.getSetting(state),
    };
};

const mapDispatchToProps = {
    clearState: actions.clearState,
    fetchDeliveryNoteDetail,
    setMenuActive
};

class DetailDeliveryNotePrintContainer extends React.Component {
    componentDidMount() {
        const { deliveryNote, fetchDeliveryNoteDetail, onDidMount } = this.props;

        window.onafterprint = () => {
            fetchDeliveryNoteDetail(deliveryNote.delivery_note.id);
        };

        onDidMount();
    }

    render() {
        return (
            <DeliveryNotePrintForCustomer
                {...this.props}
            />
        );
    }
}

DetailDeliveryNotePrintContainer.defaultProps = {
    onDidMount: () => {}
};

DetailDeliveryNotePrintContainer.propTypes = {
    onDidMount: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(DetailDeliveryNotePrintContainer));
